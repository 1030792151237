import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { hasIntersection } from "@ea/shared_components/utils/array";
import { ROLES, FEATURES, GLOBAL_SETTINGS_KEYS } from "@ea/shared_types/types";
import { ApplicationState } from "../app.reducers";
import { isFeatureDisabledSelector } from "../globalSettings/globalSettings.selectors";

export const isAllowedAccess = (userRoles) => (allowedRoles, disallowedRoles) => {
  const allowed = allowedRoles.length === 0 || hasIntersection(allowedRoles, userRoles);
  const disallowed = disallowedRoles && hasIntersection(disallowedRoles, userRoles);

  return userRoles.includes(ROLES.admin) || (!disallowed && allowed);
};

type HideableFeatureProps = {
  feature: FEATURES;
  globalSettingKey?: GLOBAL_SETTINGS_KEYS;
  children(injectedProps: any): JSX.Element;
};

class HideableFeature extends React.Component<IConnectProps> {
  render() {
    const { isFeatureDisabled, globalSettingKey, globalSettings, ...rest } = this.props;
    if (isFeatureDisabled) {
      return null;
    }

    if (globalSettingKey && !globalSettings?.[globalSettingKey]) {
      return null;
    }

    return this.props.children(rest);
  }
}

const connectCreator = connect((state: ApplicationState, props: HideableFeatureProps) => ({
  ...props,
  globalSettings: state.globalSettings,
  isFeatureDisabled: isFeatureDisabledSelector(state, props.feature),
}));

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(HideableFeature);
