import * as React from "react";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { getTranslationKey } from "@app/translations/translations.helpers";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faArrowCircleDown, faArrowCircleUp } from "@fortawesome/fontawesome-free-solid";
import { DataTestIds } from "@app/utils/dataTestIds";
interface LocalizationCommandBarProps {
  onRemoveClick: (ids: number[]) => void;
  onEditClick: () => void;
  onEditTranslationsClick: () => void;
  onNewClick: () => void;
  onReload: () => void;
  onImport: () => void;
  onExport: () => void;
  selected: any[];
  isExporting?: boolean;
  isDefaultLocale?: boolean;
}
class LocalizationCommandBar extends React.Component<LocalizationCommandBarProps> {
  public render() {
    return (
      <CommandBar data-testid={DataTestIds.COMMANDBAR_LOCALIZATION}>
        <CommandBarButton
          name="new"
          onClick={this.props.onNewClick}
          text={getTranslationKey("commandBar", "add")}
          icon="PlusOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_NEW}
        />
        <CommandBarButton
          name="edit"
          onClick={this.props.onEditClick}
          text={getTranslationKey("commandBar", "edit")}
          icon="EditOutlined"
          disabled={this.props.selected.length !== 1 || this.props.isDefaultLocale}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_EDIT}
        />
        <CommandBarButton
          name="editTranslations"
          onClick={this.props.onEditTranslationsClick}
          text={getTranslationKey(
            "commandBar",
            this.props.isDefaultLocale ? "showTranslations" : "editTranslations",
          )}
          icon="ReadOutlined"
          disabled={this.props.selected.length !== 1}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_EDIT_TRANSLATIONS}
        />
        <CommandBarButton
          name="remove"
          onClick={() => this.props.onRemoveClick(this.props.selected)}
          text={getTranslationKey("commandBar", "remove")}
          icon="DeleteOutlined"
          disabled={this.props.selected.length !== 1 || this.props.isDefaultLocale}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE}
        />
        <CommandBarButton
          name="refresh"
          onClick={this.props.onReload}
          text={getTranslationKey("commandBar", "refresh")}
          icon="ReloadOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
        />
        <CommandBarButton
          name="export"
          onClick={this.props.onExport}
          text={getTranslationKey("commandBar", "export")}
          icon={<FontAwesomeIcon icon={faArrowCircleDown} />}
          disabled={this.props.selected.length !== 1}
          loading={this.props.isExporting}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_EXPORT}
        />
        <CommandBarButton
          name="import"
          onClick={this.props.onImport}
          text={getTranslationKey("commandBar", "import")}
          icon={<FontAwesomeIcon icon={faArrowCircleUp} />}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_IMPORT}
        />
      </CommandBar>
    );
  }
}
export default LocalizationCommandBar;
