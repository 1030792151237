import {
  IssueTrackingToolConfiguration,
  PlainObject,
  IssueTrackingToolConfigurationState,
} from "@ea/shared_types/types";
import { CustomColumn, ColumnConfig } from "@ea/shared_components/src/Table/common.tables";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { ColumnFilters } from "@ea/shared_components/src/redux/common.filters";

export const ITS_CONFIGURATIONS_COLUMNS_CONFIG: PlainObject<
  CustomColumn<IssueTrackingToolConfiguration>
> = {
  name: {
    label: getTranslationKey("table", "name"),
    dataIndex: "name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  description: {
    label: getTranslationKey("table", "description"),
    dataIndex: "description",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  project: {
    label: getTranslationKey("table", "project"),
    dataIndex: "integrationMetadata",
    render: (text, record) => {
      return record.integrationMetadata?.azureDevops?.project;
    },
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  state: {
    label: getTranslationKey("table", "state"),
    dataIndex: "state",
    sortable: true,
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        {
          label: getTranslationKey("its", "label", "active"),
          value: IssueTrackingToolConfigurationState.active,
        },
        {
          label: getTranslationKey("its", "label", "inactive"),
          value: IssueTrackingToolConfigurationState.inactive,
        },
        {
          label: getTranslationKey("its", "label", "removed"),
          value: IssueTrackingToolConfigurationState.removed,
        },
      ],
    },
  },
};

export const ITS_CONFIGURATIONS_COLUMNS: ColumnConfig<IssueTrackingToolConfiguration>[] = [
  {
    props: ITS_CONFIGURATIONS_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: ITS_CONFIGURATIONS_COLUMNS_CONFIG.description,
    frameworkProps: {
      width: "50%",
    },
  },
  {
    props: ITS_CONFIGURATIONS_COLUMNS_CONFIG.project,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: ITS_CONFIGURATIONS_COLUMNS_CONFIG.state,
    frameworkProps: {
      width: "10%",
    },
  },
];

export const ITS_CONFIGURATIONS_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_ITS_CONFIGURATIONS",
  },
  ALL: {
    id: () => "ALL",
    preferencesId: "ALL_ITS_CONFIGURATIONS",
  },
};
