import * as React from "react";
import styled from "@emotion/styled";
import { COLORS } from "@ea/shared_components/styles/consts";

const borderType = `1px solid ${COLORS.GRAY_OVERLAY}`;

export const SpinnerContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  width: "100%",
  height: "100%",
  left: 0,
  zIndex: 99,
  backgroundColor: "#fff",
  opacity: 0.7,
});

export const RunnerBarContainer = styled.div({
  position: "relative",
  gridArea: "runnerbar",
});

export const RunnerDataContainer = styled.div({
  gridArea: "runnerdata",
  borderBottom: borderType,
});

export const FrameBarContainer = styled.div(
  {
    borderRight: borderType,
    borderLeft: borderType,
    gridArea: "framebar",
  },
  ({ visible }: { visible?: boolean }) => (visible ? {} : { display: "none" }),
);

export const FrameContainer = styled.div(
  {
    gridArea: "frame",
    borderRight: borderType,
    borderLeft: borderType,
    borderBottom: borderType,
  },
  ({ visible }: { visible?: boolean }) => (visible ? {} : { display: "none" }),
);

export const StepLabelContainer = styled.div({
  color: "white",
  marginLeft: "10px",
});
