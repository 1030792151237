import { objectValues } from "@ea/shared_components/utils/object";

import * as storageActions from "./storage.actions";
import storageEpicsMap from "./storage.epics";
import * as storageReducers from "./storage.reducers";

const storageEpics = objectValues(storageEpicsMap);

export { storageActions, storageReducers, storageEpics };

export { default as StorageTableContainer } from "./StorageTable.container";
