import actionCreatorFactory, { ActionCreator, ActionCreatorFactory } from "typescript-fsa";
import { GlobalSettings } from "@ea/shared_types/types";
import { EditGlobalSettingsRequest } from "@app/services/api/api.models";

const actionCreator = actionCreatorFactory("GLOBAL_SETTINGS_MODULE");

const loadGlobalSettings = actionCreator.async<void, GlobalSettings>("LOAD_SETTINGS");
const editGlobalSettings = actionCreator.async<EditGlobalSettingsRequest<any>, any>(
  "EDIT_SETTINGS",
);

export const globalSettingsActions = {
  loadGlobalSettings: loadGlobalSettings.started,
  editGlobalSettings: editGlobalSettings.started,
  editGlobalSettingsDone: editGlobalSettings.done,
};

export const globalSettingsActionCreators = {
  loadGlobalSettings,
  editGlobalSettings,
};
