import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { IssueTrackingToolRule } from "@ea/shared_types/types";
import { moduleActions } from "./itsRules.actions";

const initialState = {
  ...getDataReducerInitialState<IssueTrackingToolRule>(),
};

export type ItsRulesReducer = typeof initialState;

const dataReducer = createDataReducer<IssueTrackingToolRule>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
