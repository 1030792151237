import styled from "@emotion/styled";
import * as React from "react";

import { MessageConfig } from "./MessageBar.types";
import { FormattedMessage } from "react-intl";
import StepMessageIcon from "./StepMessageIcon";
import { MESSAGE_TYPE } from "@ea/shared_types/types";

export interface IMessageProps {
  type: MESSAGE_TYPE;
  text: string;
}

const MessageContainer = styled.div(
  {
    display: "flex",
    alignItems: "center",
    padding: "10px 15px",
    whiteSpace: "pre-line",
  },
  ({ background }: { background: string }) => ({
    backgroundColor: background,
  }),
);

const IconContainer = styled.div(
  {
    paddingRight: "5px",
  },
  ({ color }: { color: string }) => ({
    color,
  }),
);

const Message: React.FunctionComponent<IMessageProps> = ({ type, text }) => {
  return (
    <MessageContainer background={MessageConfig[type].colors.background}>
      <IconContainer color={MessageConfig[type].colors.icon}>
        <StepMessageIcon messageType={type} />
      </IconContainer>
      <FormattedMessage id={text} />
    </MessageContainer>
  );
};

export default Message;
