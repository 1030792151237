import {
  createDataReducer,
  getDataReducerInitialState,
  getTableParams,
  setTableParams,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { StepLog } from "@ea/shared_types/types";
import { moduleActions, stepLogsActionCreators } from "./stepLogs.actions";

const initialState = {
  ...getDataReducerInitialState<StepLog>(),
};

export type StepLogsReducer = typeof initialState;

const dataReducer = createDataReducer<StepLog>({
  ...moduleActions.table,
  ...moduleActions.data,
});

const stepLogsDataReducer = dataReducer(initialState);

export const reducer = stepLogsDataReducer.case(
  stepLogsActionCreators.setIsGeneratingReport,
  (state, payload) => {
    const tableParams = getTableParams(state, payload);
    return setTableParams(state, payload, {
      ...tableParams,
      isGeneratingReport: payload.isGeneratingReport,
    });
  },
);
