import { globalSettingsActionCreators } from "./globalSettings.actions";
import { API } from "@app/services/api/api";
import { makeAsyncEpic } from "../app.reducers";
import { loadHotJar } from "@app/utils/hotjar";
import { GlobalSettings, INTERNAL_SETTINGS_KEYS } from "@ea/shared_types/types";

const epics = {
  load: makeAsyncEpic(globalSettingsActionCreators.loadGlobalSettings, async (payload) => {
    const globalSettingsArray = await API.getGlobalSettings({});
    const internalSettingsArray = await API.getInternalSettings({});
    const internalSettings: GlobalSettings["internal"] = (internalSettingsArray as any).reduce(
      (container, item) => {
        container[item.id] = item.value;
        return container;
      },
      {},
    );

    const hotJarSettings = internalSettings![INTERNAL_SETTINGS_KEYS.HOTJAR];
    if (hotJarSettings && hotJarSettings.enabled) {
      loadHotJar(hotJarSettings.siteId, hotJarSettings.version);
    }

    return {
      ...globalSettingsArray.reduce((container, item) => {
        container[item.id] = item.value;
        return container;
      }, {}),
      internal: internalSettings,
    };
  }),
  edit: makeAsyncEpic(globalSettingsActionCreators.editGlobalSettings, async (payload) => {
    const response = await API.editGlobalSettings(payload);

    return response;
  }),
};

export default epics;
