import { createDataSelectors } from "@app/modules/app.reducers";
import { AggregatedJobLog } from "@ea/shared_types/types";
import { createSelector } from "reselect";
export const logsDataSelectors = createDataSelectors<AggregatedJobLog>()("aggregatedJobLogs");

export const getSelectedSelector = logsDataSelectors.getSelectedSelector;
export const getSelectedItemsSelector = logsDataSelectors.getSelectedItemsSelector;

export const getSortedDescSelectedItemsSelector = createSelector(
  getSelectedItemsSelector,
  (aggregatedJobLogs) => [...aggregatedJobLogs].sort((a, b) => (a.id > b.id ? 1 : -1)),
);
