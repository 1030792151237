import * as React from "react";

import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { System, PLAY_MODE, Script } from "@ea/shared_types/types";
import PlayModeForm from "@app/modules/common/components/PlayModeForm";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { ITS_CONFIGURATIONS_TABLES_CONFIG } from "../issueTrackingToolConfigurations/itsConfigurations.table";
import { getItsConfigurationOptions } from "../issueTrackingToolConfigurations/itsConfigurations.selectors";
import { ApplicationState } from "../app.reducers";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getItsConfigurationsTableActions } from "../issueTrackingToolConfigurations/itsConfigurations.actions";
import { currentUserSettingsSelector } from "@ea/shared_components/auth/auth.selectors";

interface PlayModeContainerProps {
  visibility: boolean;
  onClose: () => void;
  play: (mode?: PLAY_MODE, optionalParams?: any) => void;
  script?: Script;
}

interface SystemFormOption extends System {
  text: string;
  value: number;
}
interface PlayModeContainerState {
  systemOptions: SystemFormOption[];
}

class PlayModeContainer extends React.Component<IConnectProps, PlayModeContainerState> {
  componentDidMount() {
    this.props.itsConfigurations.load({});
  }

  onOkClick = ({ playMode, ...optionalParams }) => {
    const { play, onClose } = this.props;
    play(playMode, optionalParams);
    onClose();
  };

  render() {
    const { visibility, script, configurationOptions, userSettings } = this.props;
    const { autoStart, runner } = userSettings;
    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.MODAL}
        customWidth={500}
        headerText={getTranslationKey("playMode", "selectPlayMode")}
        onCancelClick={this.props.onClose}
        onOkClick={this.onOkClick}
        cancelButtonText={getTranslationKey("button", "cancel")}
        okButtonText={getTranslationKey("button", "run")}
        initialValues={{ playMode: PLAY_MODE.FOREGROUND, runner, autoStart }}
        allowPrisitineSubmit
        render={({ change, values }) => (
          <PlayModeForm
            values={values}
            change={change}
            script={script}
            configurationOptions={configurationOptions}
          />
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  itsConfigurations: {
    ...bindActionCreators(
      getItsConfigurationsTableActions(ITS_CONFIGURATIONS_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ),
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: PlayModeContainerProps) => ({
    ...props,
    configurationOptions: getItsConfigurationOptions(
      state,
      ITS_CONFIGURATIONS_TABLES_CONFIG.MAIN.id(),
    ),
    userSettings: currentUserSettingsSelector(state),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(PlayModeContainer);
