import { createSelector } from "reselect";
import { ApplicationState } from "../app.reducers";
import { INTERNAL_SETTINGS_KEYS } from "@ea/shared_types/types";

export const globalSettingsSelector = (state: ApplicationState) => state.globalSettings;

export const internalSettingsSelector = createSelector(
  globalSettingsSelector,
  (settings) => settings && settings.internal,
);

export const reportUrlSelector = createSelector(
  globalSettingsSelector,
  (settings) => settings?.REPORTS_LINK,
);

export const areGlobalSettingsLoaded = createSelector(
  globalSettingsSelector,
  (settings) =>
    settings && Object.keys(settings).length > 0 && !!settings.DEFAULT_APP_USER_SETTINGS,
);

export const disabledFeaturesSelector = createSelector(
  globalSettingsSelector,
  (settings) =>
    (settings &&
      settings.internal &&
      settings.internal[INTERNAL_SETTINGS_KEYS.DISABLED_FEATURES]) ||
    [],
);

export const isFeatureDisabledSelector = createSelector(
  globalSettingsSelector,
  (state, featureName) => featureName,
  (settings, featureName) =>
    !!settings?.internal?.[INTERNAL_SETTINGS_KEYS.DISABLED_FEATURES].includes(featureName),
);
