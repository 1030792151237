import React, { useState, useEffect, useCallback } from "react";
import { InputField } from "../Form/Fields/InputField";
import { Tooltip, Input } from "antd";
import { CodeParams, CodeParamType } from "@ea/shared_types/types";
import { DataTestIds } from "../utils/dataTestHelpers";

import { VariablesGroup } from "../redux/common.models";
import ValuePickerField from "../CodeField/CodePickerField";
import RadioField from "../Form/Fields/RadioField";

interface ICodeTemplateFieldProps {
  values?: any;
  name?: string;
  readOnly?: boolean;
  prefix: string;
  change?: (name: string, value) => void;
  paramsCode: CodeParams;
  paramDescription: string;
  variablesGroups?: VariablesGroup[];
  paramType?: CodeParamType;
}

export const CodeTemplateParamField: React.FunctionComponent<ICodeTemplateFieldProps> = ({
  paramsCode,
  values,
  prefix,
  change,
  paramDescription,
  variablesGroups,
  paramType,
}) => {
  const [type, setType] = useState<CodeParamType | undefined>(undefined);

  useEffect(() => {
    const type = paramType || CodeParamType.CONSTANT;
    change?.(`${prefix}.type`, type);
    setType(type);
  }, [paramType]);

  const getField = useCallback(() => {
    return type === CodeParamType.CONSTANT ? (
      <Tooltip title={paramDescription}>
        <InputField name={`${prefix}.value`} required />
      </Tooltip>
    ) : (
      <Tooltip title={paramDescription}>
        <ValuePickerField
          name={`${prefix}.value`}
          variablesGroups={variablesGroups || []}
          required
          onChange={() => {}}
          data-testid={DataTestIds.FORM_VALUE_PICKER}
        />
      </Tooltip>
    );
  }, [type, paramDescription, variablesGroups, prefix]);

  const options = [
    { value: CodeParamType.CONSTANT, text: "Constant" },
    { value: CodeParamType.VARIABLE, text: "Picker" },
  ];

  return (
    <Input.Group>
      <RadioField
        name={`${prefix}.type`}
        onChange={(e) => {
          const value = e?.target?.value;
          change?.(`${prefix}.type`, value);
          change?.(`${prefix}.value`, undefined);
          setType(value);
        }}
        required
        labelCol={{ span: 24 }}
        label={paramsCode.name}
        options={options}
      />
      {getField()}
    </Input.Group>
  );
};
