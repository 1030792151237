import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import {
  VirtualUser,
  VIRTUAL_USER_AUTH_TYPE,
  VIRTUAL_USER_STATUS,
  TOKEN_TYPE,
  Platform,
} from "@ea/shared_types/types";
import CreateEditVirtualUser from "@app/modules/virtualUsers/components/CreateEditVirtualUser";
import { API } from "@app/services/api/api";
interface CreateEditVirtualUserProps {
  visibility: boolean;
  onClose: () => void;
  onCreate: (user: VirtualUser) => void;
  selected?: VirtualUser;
  isEdit?: boolean;
}

class CreateEditVirtualUserContainer extends React.Component<CreateEditVirtualUserProps> {
  onOk = async (values) => {
    const { isEdit, onCreate, onClose } = this.props;

    if (isEdit) {
      await API.editVirtualUser(values);
    } else {
      const result = (await API.createVirtualUser(values)) as any; // todo: NEW_TYPES

      if (onCreate) {
        onCreate(result);
      }
    }

    onClose();
  };

  render() {
    const { visibility, isEdit, onClose, selected } = this.props;
    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.MEDIUM}
        headerText={getTranslationKey("virtualUsers", "header", isEdit ? "edit" : "create")}
        onCancelClick={onClose}
        key={isEdit ? "Edit" : "Create"}
        onOkClick={this.onOk}
        cancelButtonText={getTranslationKey("button", "cancel")}
        okButtonText={getTranslationKey("button", isEdit ? "save" : "create")}
        initialValues={isEdit ? selected : undefined}
        render={({ values, form }) => (
          <CreateEditVirtualUser isEditMode={isEdit} values={values} form={form} />
        )}
      />
    );
  }
}

export default CreateEditVirtualUserContainer;
