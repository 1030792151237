import AntdCheckbox, { CheckboxProps, CheckboxOptionType } from "antd/lib/checkbox";
import * as React from "react";

import createFormField from "../createFormField";

interface ICheckboxProps extends CheckboxProps {
  readOnly?: boolean;
  type?: string;
}

const CheckboxWithReadonly: React.FunctionComponent<ICheckboxProps> = ({
  readOnly,
  ...restProps
}) => {
  return <AntdCheckbox {...restProps} disabled={readOnly || restProps.disabled} />;
};

export const CheckboxField = createFormField(
  CheckboxWithReadonly,
  ({ name, onChange, value, onBlur, onFocus, type, ...rest }) => ({
    ...rest,
    onChange,
    value,
    checked: value,
    type: type || "checkbox",
  }),
);

export default CheckboxField;
