import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Tree, Select, Modal, Button, Checkbox } from "antd";
import TreeCommandBar from "@app/modules/projects/components/ProjectTreeCommandBar";
import { ProjectTreeNode, PROJECT_ROOT, EXECUTION_STATUS } from "@ea/shared_types/types";
import NewNodeCell from "./components/NewNodeCell";
import { ApplicationState } from "../app.reducers";
import { Dispatch, bindActionCreators } from "redux";
import { projectActions } from "./projects.actions";
import { PROJECTS_TABLES_CONFIG } from "./projects.tables";
import { getProjectsTreeSelector, projectSelectors } from "./projects.selectors";
import { API } from "@app/services/api/api";
import { withRouter, RouteComponentProps } from "react-router";
import TreeSearch from "@ea/shared_components/common/TreeSearch";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { PROJECT_NEW_NODE_ID } from "./projects.helpers";
import {
  isCurrentUserAdmin,
  currentUserIdSelector,
  isCurrentUserDocumentationReader,
} from "@ea/shared_components/auth/auth.selectors";
import "./ProjectsTree.css";
import { projectCategoryDataSelectors } from "../projectCategory/projectCategory.selectors";
import { getProjectCategoryTableActions } from "../projectCategory/projectCategory.actions";
import { PROJECT_CATEGORY_TABLES_CONFIG } from "../projectCategory/projectCategory.table";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { COLORS } from "@ea/shared_components/styles/consts";
import { DataTestIds } from "../../utils/dataTestIds";
import { ReactNode } from "react";

const Option = Select.Option;
const { TreeNode } = Tree;

type ProjectTreeProps = {
  id?: number;
  disableCommandBar?: boolean;
  rootPath: string;
} & (
  | {
      withActions: true;
      onJobExecute: () => void;
      onJobAbort: () => void;
      onExportToTestPlans: () => void;
      isExecuting: boolean;
      isBeingDispatched: boolean;
    }
  | {
      withActions: false;
      onJobExecute?: () => void;
      onJobAbort?: () => void;
      onExportToTestPlans?: () => void;
      isExecuting?: boolean;
      isBeingDispatched?: boolean;
    }
);

interface ProjectTreeState {
  selectedKeys: string[];
  newNodeParentId: number | undefined | typeof PROJECT_ROOT;
  editNodeId: number | undefined;
  nodes: any;
  selectedCategories: number[];
  removeDialog: {
    visible: boolean;
    removeTestPlan?: boolean;
  };
}

class ProjectsTreeContainer extends React.Component<
  IConnectProps & RouteComponentProps<any>,
  ProjectTreeState
> {
  state: ProjectTreeState = {
    selectedKeys: [],
    newNodeParentId: undefined,
    nodes: null,
    editNodeId: undefined,
    selectedCategories: [],
    removeDialog: {
      visible: false,
      removeTestPlan: false,
    },
  };

  eventSource;

  onSelect = (selectedKeys) => {
    if (selectedKeys && selectedKeys.length === 1 && selectedKeys[0] === PROJECT_NEW_NODE_ID) {
      return;
    }
    this.setState({
      selectedKeys,
    });
    const { pathname } = this.props.location;
    const chunks = pathname.split("/");

    if (selectedKeys[0]) {
      // On selecting another project we want to reset routing path to the main chunk.
      // For example if user navigates to some deep path like project/11/history/012313123/...
      // we want to reset routing to the 'history' and get 'project/12/history'.
      // We do it because very often deeper nested parts are correlated with selected project
      // http://localhost:5000/projects/11/history/0b128e6e-67de-44e7-b22f-6079cbae7347/
      // onProjectSelect we want change path to http://localhost:5000/projects/99/history/

      this.props.history.push(
        `${this.props.rootPath}/${selectedKeys[0]}${chunks[3] ? `/${chunks[3]}` : ""}`,
      );
    } else {
      this.returnToRootPath();
    }
  };

  async componentDidMount() {
    this.props.actions.load({});
    this.props.actions.projectCategories.load({});
    this.setState({
      nodes: this.loop(this.props.data, undefined),
    });

    if (this.props.id) {
      this.setState({
        selectedKeys: [this.props.id.toString()],
      });

      this.props.actions.loadSingle({ id: this.props.id });
    }
  }

  async componentDidUpdate(prevProps: IConnectProps, prevState: ProjectTreeState) {
    if (
      prevProps.data !== this.props.data ||
      prevState.newNodeParentId !== this.state.newNodeParentId ||
      prevState.selectedCategories !== this.state.selectedCategories
    ) {
      this.setState({
        nodes: this.loop(this.props.data, undefined),
      });
    }
  }

  isDisabled = (item: ProjectTreeNode) => {
    const { selectedCategories } = this.state;

    if (item.disabled || selectedCategories.length === 0) {
      return item.disabled;
    }

    if (!item.category) {
      return true;
    }

    return selectedCategories.findIndex((cat) => cat === item.category) === -1;
  };

  loop = (data: ProjectTreeNode[], parent: ProjectTreeNode | undefined) => {
    const { newNodeParentId } = this.state;

    const innerNodes = data.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            key={item.id.toString()}
            title={item.name}
            disabled={this.isDisabled(item)}
            data-testid={DataTestIds.getProjectTestId(item.id, item.name)}
          >
            {this.loop(item.children, item)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.id.toString()}
          title={item.name}
          disabled={this.isDisabled(item)}
          data-testid={DataTestIds.getProjectTestId(item.id, item.name)}
        />
      );
    });

    if (
      newNodeParentId &&
      ((newNodeParentId === PROJECT_ROOT && parent === undefined) ||
        (parent && parent.id === newNodeParentId))
    ) {
      innerNodes.push(
        <TreeNode
          key="new"
          expanded={true}
          data-testid={DataTestIds.TREE_NEW_NODE}
          className="newNode"
          title={
            <NewNodeCell
              mode="NEW"
              parent={parent}
              data-testid={DataTestIds.TREE_NEW_NODE_CELL}
              onDone={async (value, p) => {
                const created = await API.createProject({
                  name: value,
                  path: p ? `${p.path}` : `${PROJECT_ROOT}`,
                });
                this.setState({
                  newNodeParentId: undefined,
                });
                this.props.actions.load({});
              }}
              onCancel={() => {
                this.setState({
                  newNodeParentId: undefined,
                });
              }}
              onUnfocus={() => {
                this.setState({
                  newNodeParentId: undefined,
                });
              }}
            />
          }
        />,
      );
    }

    return innerNodes;
  };

  create = () => {
    const { selectedKeys } = this.state;

    const newNodeParentId = selectedKeys.length > 0 ? parseInt(selectedKeys[0], 10) : PROJECT_ROOT;
    this.setState({
      newNodeParentId,
      editNodeId: undefined,
    });
  };

  returnToRootPath = () => {
    this.props.history.push(this.props.rootPath);
  };

  onRemoveClick = () => {
    this.setState({
      removeDialog: { visible: true },
    });
  };

  onRemoveConfirm = () => {
    const { removeDialog, selectedKeys } = this.state;
    this.setState({
      removeDialog: { visible: false, removeTestPlan: false },
    });
    this.props.actions.delete({
      ids: selectedKeys.map((s) => parseInt(s, 10)),
      options: { removeTestPlan: removeDialog.removeTestPlan },
    });
    this.setState({
      selectedKeys: [],
    });
    this.returnToRootPath();
  };

  edit = () => {
    const { selectedKeys } = this.state;

    const editNodeId = selectedKeys.length > 0 ? parseInt(selectedKeys[0], 10) : undefined;
    this.setState({
      editNodeId,
      newNodeParentId: undefined,
    });
  };

  onCategoryChange = (values) => {
    this.setState({
      selectedCategories: values,
    });
  };

  categoriesOptions = () => {
    const { categories } = this.props;

    return categories.length > 0
      ? categories.map((cat) => (
          <Option
            key={`${cat.id}`}
            value={cat.id}
            data-testid={DataTestIds.getProjectCategoryTestId(cat.id, cat.name)}
          >
            {cat.name}
          </Option>
        ))
      : null;
  };

  scheduleExecution = async () => {
    const { onJobExecute } = this.props;

    if (onJobExecute) {
      await onJobExecute();
    }
  };

  terminateExecution = async () => {
    const { onJobAbort } = this.props;

    if (onJobAbort) {
      await onJobAbort();
    }
  };

  onRemoveCancel = () => {
    this.setState({
      removeDialog: { visible: false, removeTestPlan: false },
    });
  };

  getCloseConfirmFooter = () => {
    const footer = [
      <Button key="cancel" onClick={this.onRemoveCancel} data-testid={DataTestIds.MODAL_BUTTON_NO}>
        <FormattedMessage id={getTranslationKey("button", "no")} />
      </Button>,
      <Button
        danger
        key="ok"
        onClick={this.onRemoveConfirm}
        data-testid={DataTestIds.MODAL_BUTTON_YES}
      >
        <FormattedMessage id={getTranslationKey("button", "yes")} />
      </Button>,
    ];

    if (this.isSelectedMappedWithTestPlans()) {
      footer.push(
        <Checkbox
          style={{ marginLeft: 10 }}
          key="removeConnectedTestPlan"
          data-testid={DataTestIds.CHECKBOX_REMOVE_CONNECTED_TEST_PLAN}
          onChange={(e) => {
            this.setState({
              removeDialog: {
                removeTestPlan: e.target.checked,
                visible: this.state.removeDialog.visible,
              },
            });
          }}
        >
          <FormattedMessage id={getTranslationKey("projects", "removeTestPlanSuite")} />
        </Checkbox>,
      );
    }

    return footer;
  };

  isSelectedMappedWithTestPlans = () =>
    !!this.props.selectedProject?.integrationMetadata?.azureDevops?.testPlanId;

  render() {
    const { selectedKeys, nodes, newNodeParentId, removeDialog } = this.state;
    const { isAdmin, data, id, isDocumentationReader, globalSettings } = this.props;

    let CommandBar: ReactNode = null;

    if (this.props.withActions && !isDocumentationReader) {
      const { isExecuting, isBeingDispatched, onExportToTestPlans } = this.props;

      CommandBar = (
        <TreeCommandBar
          isAdmin={isAdmin}
          onCreate={this.create}
          onRemove={this.onRemoveClick}
          onEdit={this.edit}
          selected={selectedKeys}
          onScheduleExecution={this.scheduleExecution}
          isExecuting={isExecuting}
          isBeingDispatched={isBeingDispatched}
          onTerminateExecution={this.terminateExecution}
          onExportToTestPlans={
            globalSettings && globalSettings.TEST_PLANS_INTEGRATION ? onExportToTestPlans : null
          }
        />
      );
    }

    return (
      <>
        {CommandBar}
        <Select
          style={{ width: "100%" }}
          mode="multiple"
          onChange={this.onCategoryChange}
          showSearch={false}
          showArrow
          placeholder={
            <FormattedMessage
              id={getTranslationKey("projects", "details", "placeholder", "category")}
            />
          }
          data-testid={DataTestIds.SELECT_PROJECT_CATEGORY}
        >
          {this.categoriesOptions()}
        </Select>
        <TreeSearch
          selected={selectedKeys}
          onSelect={this.onSelect}
          values={data}
          initialExpandedKeys={id ? [id.toString()] : []}
          defaultExpandedKeys={[PROJECT_NEW_NODE_ID]}
          autoExpandOnNewItems
          newNodeParentId={newNodeParentId}
        >
          {nodes}
        </TreeSearch>
        <Modal
          visible={removeDialog.visible}
          closable={false}
          footer={this.getCloseConfirmFooter()}
          bodyStyle={{ display: "flex", justifyContent: "center" }}
        >
          <span style={{ marginLeft: "15px" }}>
            <QuestionCircleOutlined
              style={{
                marginRight: 30,
                color: COLORS.STATUS[EXECUTION_STATUS.WARNING],
                fontSize: 25,
              }}
            />
            <FormattedMessage id={getTranslationKey("projects", "removeProject")} />
          </span>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(projectActions, dispatch),
    projectCategories: {
      ...bindActionCreators(
        getProjectCategoryTableActions(PROJECT_CATEGORY_TABLES_CONFIG.ALL_CATEGORIES.id()),
        dispatch,
      ),
    },
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: ProjectTreeProps & RouteComponentProps<any>) => ({
    ...props,
    data: getProjectsTreeSelector(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    isAdmin: isCurrentUserAdmin(state),
    userId: currentUserIdSelector(state),
    globalSettings: state.globalSettings,
    selectedProject: projectSelectors.getItemSelector(state, props.match.params.id),
    categories: projectCategoryDataSelectors.getOrderedDataSelector(
      state,
      PROJECT_CATEGORY_TABLES_CONFIG.ALL_CATEGORIES.id(),
    ),
    isDocumentationReader: isCurrentUserDocumentationReader(state),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(ProjectsTreeContainer));
