import { getTranslationKey } from "@app/translations/translations.helpers";
import { SelectField } from "@ea/shared_components";
import BaseModalForm from "@ea/shared_components/ModalForm/BaseModalForm";
import { DataSourceMetadata } from "@ea/shared_types/next/ea.types";
import styled from "@emotion/styled";
import { Button } from "antd";
import * as PropTypes from "prop-types";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { RouterChildContext } from "react-router";
import { API } from "../../services/api/api";
import { DataTestIds } from "../../utils/dataTestIds";

interface IAssignGlobalExcelContainerState {
  isModalOpen: boolean;
  globalExcels: any[];
}
interface IAssignGlobalExcelContainerProps {
  projectId?: number;
  scriptId: number;
  onGlobalExcelAssign: (datasource: DataSourceMetadata) => void;
}

const Container = styled.div({ padding: "10px 0", display: "flex" });

class AssignGlobalExcelContainer extends React.Component<
  IAssignGlobalExcelContainerProps,
  IAssignGlobalExcelContainerState
> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state: IAssignGlobalExcelContainerState = { isModalOpen: false, globalExcels: [] };
  context: RouterChildContext<any>;

  async componentDidMount() {
    if (this.props.projectId) {
      const globalExcels = await API.getProjectGlobalExcels({ projectId: this.props.projectId });
      this.setState({ globalExcels });
    }
  }

  onOkClick = async (values) => {
    const datasource = await API.assignScriptToDatasource({
      datasourceId: values.datasourceId,
      scriptId: this.props.scriptId,
    });
    this.closeModal();
    this.props.onGlobalExcelAssign(datasource);
  };
  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  generateOptions = () => {
    return this.state.globalExcels.map((ge) => ({ value: ge.id, text: ge.originalFilename }));
  };

  render() {
    return (
      <Container>
        <Button
          key="cancel"
          type="primary"
          onClick={this.openModal}
          data-testid={DataTestIds.MODAL_BUTTON_CANCEL}
          style={{ flex: 1 }}
        >
          <FormattedMessage
            id={getTranslationKey("step", "detailsForm", "label", "assignGlobalExcel")}
          />
        </Button>

        <BaseModalForm
          visible={this.state.isModalOpen}
          headerText={
            <FormattedMessage id={getTranslationKey("common", "label", "assignGlobalExcel")} />
          }
          onCancelClick={this.closeModal}
          onOkClick={this.onOkClick}
          allowPrisitineSubmit={true}
          cancelButtonText={<FormattedMessage id={getTranslationKey("button", "cancel")} />}
          okButtonText={"Assign"}
          initialValues={{}}
          render={({ change, values }) => (
            <SelectField
              name="datasourceId"
              placeholder={"Datasource"}
              required
              label={"Datasource"}
              options={this.generateOptions()}
              data-testid={DataTestIds.FORM_SELECT_DATASOURCE}
            />
          )}
        />
      </Container>
    );
  }
}

export default AssignGlobalExcelContainer;
