import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { CustomColumn } from "@ea/shared_components/Table/common.tables";
import { Variable, PlainObject } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";

export const VARIABLES_COLUMN_CONFIG: PlainObject<CustomColumn<Variable>> = {
  parentId: {
    label: getTranslationKey("table", "parentId"),
    dataIndex: "parentId",
    filter: {
      filterType: ColumnFilters.SELECT,
    },
  },
};
