import * as React from "react";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { faPlus, faTrash, faPlayCircle } from "@fortawesome/fontawesome-free-solid";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import CommandBarDropdown from "@ea/shared_components/CommandBar/CommandBarDropdown";
import { getTranslationKey } from "@app/translations/translations.helpers";
import {
  ScheduleOutlined,
  LoadingOutlined,
  ExperimentOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { HideWhenDocumentationWizard } from "@app/modules/common/HideWhenDocumentationWizard";
import { DataTestIds } from "@app/utils/dataTestIds";
interface IProjectTreeCommandBarProps {
  onCreate: () => void;
  onRemove: () => void;
  onScheduleExecution: () => void;
  onTerminateExecution: () => void;
  onEdit: () => void;
  onExportToTestPlans: (() => void) | null;
  selected: string[];
  isAdmin: boolean;
  isExecuting: boolean;
  isBeingDispatched: boolean;
}

const ProjectTreeCommandBar: React.FunctionComponent<IProjectTreeCommandBarProps> = ({
  onCreate,
  onRemove,
  selected,
  onScheduleExecution,
  isAdmin,
  isExecuting,
  isBeingDispatched,
  onTerminateExecution,
  onExportToTestPlans,
}) => {
  return (
    <div>
      <CommandBar data-testid={DataTestIds.COMMANDBAR_PROJECT_TREE}>
        {isAdmin && (
          <>
            <CommandBarButton
              name="add"
              onClick={onCreate}
              style={{ paddingRight: "5px", paddingLeft: "10px" }}
              text={getTranslationKey("commandBar", "create")}
              icon={<FontAwesomeIcon icon={faPlus} />}
              disabled={selected.length > 1}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_PROJECT_TREE_CREATE}
            />
            <CommandBarButton
              name="delete"
              style={{ padding: "5px" }}
              onClick={onRemove}
              text={getTranslationKey("commandBar", "remove")}
              icon={<FontAwesomeIcon icon={faTrash} />}
              disabled={selected.length === 0}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_PROJECT_TREE_REMOVE}
            />
          </>
        )}

        <CommandBarDropdown
          name="actions"
          style={{ padding: "7px" }}
          text={getTranslationKey("commandBar", "actions")}
          icon={<FontAwesomeIcon icon={faPlayCircle} />}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_PROJECT_TREE_ACTIONS}
        >
          {!isExecuting ? (
            !isBeingDispatched ? (
              <CommandBarButton
                name="scheduleExecution"
                onClick={onScheduleExecution}
                text={getTranslationKey("projects", "scheduleExecution")}
                icon={<ScheduleOutlined style={{ fontSize: 14 }} />}
                size="default"
                disabled={selected.length === 0}
                data-testid={DataTestIds.COMMANDBAR_BUTTON_PROJECT_TREE_SCHEDULE_EXECUTION}
              />
            ) : (
              <CommandBarButton
                name="dispatching"
                text={getTranslationKey("projects", "dispatching")}
                icon={<LoadingOutlined style={{ fontSize: 14 }} />}
                size="default"
                disabled
                data-testid={DataTestIds.COMMANDBAR_BUTTON_PROJECT_TREE_DISPATCHING}
              />
            )
          ) : (
            <CommandBarButton
              name="terminateExecution"
              onClick={onTerminateExecution}
              text={getTranslationKey("projects", "terminateExecution")}
              icon={<LoadingOutlined style={{ fontSize: 14 }} />}
              size="default"
              disabled={selected.length === 0}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_PROJECT_TREE_TERMINATE_EXECUTION}
            />
          )}
        </CommandBarDropdown>
        <HideWhenDocumentationWizard>
          {() =>
            onExportToTestPlans && (
              <CommandBarDropdown
                name="addons"
                style={{ padding: "7px" }}
                text={getTranslationKey("commandBar", "addons")}
                icon={<ExperimentOutlined style={{ fontSize: 14 }} />}
                data-testid={DataTestIds.COMMANDBAR_BUTTON_PROJECT_TREE_ADDONS}
              >
                <CommandBarButton
                  name="export"
                  onClick={onExportToTestPlans}
                  text={getTranslationKey("projects", "exportToDevOps")}
                  icon={<ExportOutlined style={{ fontSize: 14 }} />}
                  size="default"
                  data-testid={DataTestIds.COMMANDBAR_BUTTON_PROJECT_TREE_EXPORT_TO_TEST_PLAN}
                />
              </CommandBarDropdown>
            )
          }
        </HideWhenDocumentationWizard>
      </CommandBar>
    </div>
  );
};

export default ProjectTreeCommandBar;
