import * as PropTypes from "prop-types";
import * as React from "react";
import { RouterChildContext } from "react-router";
import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { User, IssueTrackingToolRule, IssueTrackingTool } from "@ea/shared_types/types";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import CreateItsRuleForm from "./components/CreateItsRuleForm";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@ea/shared_components/redux/state";
import { currentUserSettingsSelector } from "@ea/shared_components/auth/auth.selectors";

interface ItsRuleContainerProps {
  visibility: boolean;
  onClose: () => void;
  onCreate: (itsRule: IssueTrackingToolRule) => void;
  selected?: IssueTrackingToolRule;
  isEdit?: boolean;
  user: User | undefined;
  issueTrackingSystems: IssueTrackingTool[];
}

class CreateEditItsRule extends React.Component<ItsRuleContainerProps & IConnectProps> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  context: RouterChildContext<any>;

  onOk = async (values) => {
    const { isEdit, onCreate, onClose, issueTrackingSystems } = this.props;
    const its = issueTrackingSystems.find((i) => i.id === values.itsId);
    const itsName = its ? its.name : "";

    if (isEdit) {
      await API.editItsRule({ ...values, itsName });
    } else {
      if (this.props.user) {
        const result = await API.createItsRule({
          ...values,
          itsName,
        });
        if (onCreate) {
          onCreate(result);
        }
      }
    }
    onClose();
  };

  itsOptions = () =>
    this.props.issueTrackingSystems.map((its) => ({ text: its.name, value: its.id }));

  render() {
    const { visibility, isEdit, onClose, userSettings, issueTrackingSystems, selected } =
      this.props;
    const its = issueTrackingSystems.find((i) => i.id === userSettings.its);

    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.MEDIUM}
        headerText={getTranslationKey("its", "header", isEdit ? "editRule" : "createRule")}
        onCancelClick={onClose}
        onOkClick={this.onOk}
        cancelButtonText={getTranslationKey("button", "cancel")}
        key={isEdit ? "edit" : "create"}
        okButtonText={getTranslationKey("button", isEdit ? "save" : "create")}
        initialValues={
          isEdit
            ? selected
            : { itsId: userSettings.its, config: { type: its?.type, severity: its?.severity } }
        }
        render={({ values, change }) => (
          <CreateItsRuleForm values={values} change={change} itsOptions={this.itsOptions()} />
        )}
      />
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState) => ({
    userSettings: currentUserSettingsSelector(state),
  }),
  () => ({}),
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(CreateEditItsRule);
