import { Spin } from "antd";
import styled from "@emotion/styled";
import * as React from "react";
import { API } from "@app/services/api/api";
import { AggregatedJobLog } from "@ea/shared_types/types";
import { InjectedIntlProps, injectIntl, FormattedMessage } from "react-intl";
import AggregatedJobLogsBarChart from "../components/AggregatedJobLogsBarChart";
import { toast } from "react-toastify";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { EXECUTION_STATE } from "@ea/shared_types/types";

interface AggregatedJobExecutionBarChartProps {
  data?: AggregatedJobLog[];
  projectId: number;
  title: string;
  autoReload?: boolean | number;
  resultsLimit?: number;
}

interface AggregatedJobExecutionBarChartState {
  data?: AggregatedJobLog[];
  width: number;
  height: number;
  isLoading: boolean;
}

const SpinContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minWidth: "450px",
  paddingTop: "20%",
});

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
  textAlign: "center",
});

const timer = {};
const BAR_CHART_MIN_DATA_POINTS_COUNT = 2;
const DEFAULT_BAR_CHART_DATA_LIMIT = 5;

class AggregatedJobExecutionBarChart extends React.Component<
  InjectedIntlProps & AggregatedJobExecutionBarChartProps,
  any
> {
  state: AggregatedJobExecutionBarChartState = {
    data: undefined,
    height: 0,
    width: 0,
    isLoading: false,
  };

  componentDidMount = async () => {
    const { data, projectId } = this.props;
    if (!data && projectId) {
      await this.loadData();
    }
  };

  componentDidUpdate(prevProps: InjectedIntlProps & AggregatedJobExecutionBarChartProps) {
    if (prevProps.projectId !== this.props.projectId) {
      this.setState({ data: undefined });
      this.loadData();
    }
    if (prevProps.autoReload !== this.props.autoReload) {
      if (this.props.autoReload) {
        clearInterval(timer[this.props.title]);
        this.setAutoReload();
      } else {
        this.loadData();
        clearInterval(timer[this.props.title]);
      }
    }
  }

  setAutoReload = () => {
    const { autoReload } = this.props;
    const timeout =
      autoReload &&
      typeof autoReload === "number" &&
      Number.isInteger(autoReload as number) &&
      autoReload > 0
        ? autoReload
        : 10000;

    timer[this.props.title] = setInterval(() => {
      if (document.visibilityState === "visible") {
        this.loadData();
      }
    }, timeout);
  };

  loadData = async () => {
    const { projectId } = this.props;
    const limit = this.props.resultsLimit || DEFAULT_BAR_CHART_DATA_LIMIT;
    const where = { projectId, state: { neq: EXECUTION_STATE.TERMINATED }, isExcluded: false };
    try {
      this.setState({
        isLoading: true,
      });
      const aggregatedJobLogs = await API.getAggregatedJobLogs({
        filter: { where, order: ["startTime desc"], limit },
      });
      this.setState({ data: aggregatedJobLogs });
    } catch (e) {
      toast.error(<FormattedMessage id={getTranslationKey("messages", "error", "loading")} />);
    }
    this.setState({
      isLoading: false,
    });
  };

  componentWillUnmount() {
    clearInterval(timer[this.props.title]);
  }

  render() {
    const { height, width, data } = this.state;
    const { title, intl } = this.props;
    const dataToChart = this.props.data || data;

    return dataToChart ? (
      <Container>
        <h2>{title}</h2>
        {dataToChart && dataToChart.length >= BAR_CHART_MIN_DATA_POINTS_COUNT ? (
          <AggregatedJobLogsBarChart data={dataToChart} height={height} width={width} />
        ) : (
          <h3>{<FormattedMessage id={getTranslationKey("common", "notEnoughData")} />}</h3>
        )}
      </Container>
    ) : (
      <SpinContainer>
        <Spin
          tip={intl.formatMessage({
            id: getTranslationKey("common", "loadingStatistics"),
          })}
        />
      </SpinContainer>
    );
  }
}

export default injectIntl(AggregatedJobExecutionBarChart);
