import * as React from "react";
import { Tag } from "antd";
import { COLORS } from "@app/styles/consts";
import { AllowedWorkItemTypes } from "@ea/shared_types/types";
import { injectIntl, InjectedIntlProps } from "react-intl";

interface IWorkItemTypeFieldProps {
  type: AllowedWorkItemTypes;
}

const style = {
  cursor: "default",
};

const WorkItemTypeField: React.FunctionComponent<IWorkItemTypeFieldProps & InjectedIntlProps> = ({
  type,
  intl,
}) => {
  if (!type) {
    return null;
  }

  return (
    <Tag style={style} color={COLORS.WORK_ITEM_TYPE[type]}>
      {type}
    </Tag>
  );
};

export default injectIntl(WorkItemTypeField);
