import Col, { ColProps } from "antd/lib/grid/col";
import classNames from "classnames";
import * as React from "react";

interface IFormItemLabelProps {
  label?: React.ReactNode;
  labelCol?: ColProps;
  colon?: boolean;
  required?: boolean;
  readOnly?: boolean;
  labelStyle?: any;
}

const FormItemLabel: React.FunctionComponent<IFormItemLabelProps> = ({
  label,
  labelCol,
  colon,
  required,
  readOnly,
  labelStyle,
}) => {
  const labelClassName = classNames({
    "ant-form-item-required": !readOnly && required,
  });

  const labelColClassName = classNames({
    "ant-form-item-label": true,
    [labelCol && labelCol.className ? labelCol.className : ""]: labelCol && !!labelCol.className,
  });

  if (!label) {
    return <Col {...labelCol} className={labelColClassName} style={labelStyle} />;
  }

  const getLabelElement = (innerLabel: React.ReactNode) => {
    if (typeof innerLabel !== "string") {
      return {
        element: innerLabel,
        title: "",
      };
    }

    const element =
      colon && innerLabel.trim() !== "" ? innerLabel.replace(/[：|:]\s*$/, "") : innerLabel;

    return {
      element,
      title: innerLabel,
    };
  };

  const labelElement = getLabelElement(label);

  return (
    <Col {...labelCol} className={labelColClassName} style={labelStyle}>
      <label className={labelClassName} title={labelElement.title}>
        {labelElement.element}
      </label>
    </Col>
  );
};

export default FormItemLabel;
