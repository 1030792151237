import * as React from "react";
import { AggregatedJobLog } from "@ea/shared_types/types";
import { COLORS } from "@app/styles/consts";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

interface IAggregatedJobLogsAreaChartProps {
  data?: AggregatedJobLog[];
  width: number;
  height: number;
}

const AggregatedJobLogsAreaChart: React.FunctionComponent<
  IAggregatedJobLogsAreaChartProps & InjectedIntlProps
> = ({ data, height, width, intl }) => {
  // in database we have successCount, warningCount etc, but in chart wa want SUCCESS, WARNING
  // thats why we are cutting off "Count" part.
  const cutCount = (name) => name.substring(0, name.indexOf("Count"));
  const toolTipDataFormatter = (value, name, props) => [value, `${cutCount(name).toUpperCase()}`];

  return (
    <AreaChart
      height={height}
      width={width}
      data={data}
      margin={{
        top: 10,
        right: 60,
        left: 30,
        bottom: 50,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="id"
        label={{
          value: intl.formatMessage({ id: getTranslationKey("charts", "jobId") }),
          position: "insideBottom",
          fontSize: 16,
          dy: 10,
        }}
      />

      <YAxis
        label={{
          value: intl.formatMessage({ id: getTranslationKey("charts", "scriptCount") }),
          position: "insideLeft",
          angle: -90,
          fontSize: 16,
        }}
        allowDecimals={false}
        domain={["dataMin", "dataMax"]}
      />

      <Tooltip
        labelStyle={{ fontSize: "20px" }}
        labelFormatter={(name) => "ID: " + name}
        formatter={toolTipDataFormatter}
      />
      <Area
        type="monotone"
        dataKey="successCount"
        stackId="1"
        stroke={COLORS.STATUS.SUCCESS}
        fill={COLORS.STATUS.SUCCESS}
      />
      <Area
        type="monotone"
        dataKey="warningCount"
        stackId="1"
        stroke={COLORS.STATUS.WARNING}
        fill={COLORS.STATUS.WARNING}
      />
      <Area
        type="monotone"
        dataKey="errorCount"
        stackId="1"
        stroke={COLORS.STATUS.ERROR}
        fill={COLORS.STATUS.ERROR}
      />
    </AreaChart>
  );
};

export default injectIntl(AggregatedJobLogsAreaChart);
