import { objectValues } from "@ea/shared_components/utils/object";

import * as globalExcelsActions from "./globalExcels.actions";
import globalExcelsEpicsMap from "./globalExcels.epics";
import * as globalExcelsReducers from "./globalExcels.reducers";

const globalExcelsEpics = objectValues(globalExcelsEpicsMap);

export { globalExcelsActions, globalExcelsEpics, globalExcelsReducers };

export { default as GlobalExcelsTableContainer } from "./GlobalExcelsTable.container";
