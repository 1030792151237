import * as React from "react";
import { PanelType } from "@ea/shared_components/Panel";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { GlobalVariable } from "@ea/shared_types/types";
import { Panel } from "@ea/shared_components/Panel";
import { Button } from "antd";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { ApplicationState } from "@app/modules/app.reducers";
import { connect, ConnectedProps } from "react-redux";
import {
  GLOBAL_VARIABLES_TABLES_CONFIG,
  GLOBAL_VARIABLES_COLUMNS,
} from "@app/modules/globalVariables/globalVariables.table";
import { getGlobalVariablesTableActions } from "@app/modules/globalVariables/globalVariables.actions";
import { globalVariablesDataSelectors } from "@app/modules/globalVariables/globalVariables.selectors";
import { FormattedMessage } from "react-intl";
import { DataTestIds } from "@app/utils/dataTestIds";

interface IAssignGlobalVariablesPanelProps {
  visibility: boolean;
  onClose: () => void;
  onAssign: (globalVariablesIds: number[]) => void;
  onEdit?: (globalVariable: GlobalVariable) => void;
  selected?: GlobalVariable;
  isEdit?: boolean;
  scriptId: number;
  globalVariablesIds: number[];
}
interface IAssignGlobalVariablesPanelState {
  persistentQuery: any;
}

class AssignGlobalVariablesPanel extends React.Component<
  IAssignGlobalVariablesPanelProps & IConnectProps,
  IAssignGlobalVariablesPanelState
> {
  state = {
    persistentQuery:
      this.props.globalVariablesIds.length > 0
        ? {
            id: { nin: this.props.globalVariablesIds },
          }
        : undefined,
  };
  componentDidUpdate(prevProps: IAssignGlobalVariablesPanelProps) {
    if (
      JSON.stringify(prevProps.globalVariablesIds) !== JSON.stringify(this.props.globalVariablesIds)
    ) {
      this.setPersistentQuery();
    }
  }

  setPersistentQuery() {
    const { globalVariablesIds } = this.props;
    this.setState({
      persistentQuery:
        globalVariablesIds.length > 0 ? { id: { nin: globalVariablesIds } } : undefined,
    });
  }
  onOk = () => {
    const { onAssign, selectedGlobalVariables } = this.props;
    onAssign(selectedGlobalVariables);
  };
  renderFooterContent = () => {
    const { selectedGlobalVariables, onClose } = this.props;
    return [
      <Button
        type="primary"
        onClick={this.onOk}
        disabled={selectedGlobalVariables.length === 0}
        key="add"
        data-testid={DataTestIds.PANEL_BUTTON_SUBMIT}
      >
        <FormattedMessage id={getTranslationKey("button", "add")} />
      </Button>,
      <Button
        style={{ marginLeft: "8px" }}
        key="cancel"
        onClick={onClose}
        data-testid={DataTestIds.PANEL_BUTTON_CANCEL}
      >
        <FormattedMessage id={getTranslationKey("button", "cancel")} />
      </Button>,
    ];
  };

  render() {
    const { visibility, scriptId } = this.props;
    const { persistentQuery } = this.state;
    return (
      <Panel
        visibility={visibility}
        headerText={
          <FormattedMessage id={getTranslationKey("globalVariable", "header", "assign")} />
        }
        isFooterAtBottom
        type={PanelType.MEDIUM}
        onRenderFooterContent={this.renderFooterContent}
      >
        <ConnectedTable
          pageable
          columnsConfig={GLOBAL_VARIABLES_COLUMNS}
          tableId={GLOBAL_VARIABLES_TABLES_CONFIG.SCRIPT_VARIABLES_FORM.id(scriptId)}
          preferencesId={GLOBAL_VARIABLES_TABLES_CONFIG.SCRIPT_VARIABLES_FORM.preferencesId}
          stateKey={"globalVariables"}
          tableActions={getGlobalVariablesTableActions}
          persistentQuery={persistentQuery}
        />
      </Panel>
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: IAssignGlobalVariablesPanelProps) => ({
    selectedGlobalVariables: globalVariablesDataSelectors.getSelectedSelector(
      state,
      GLOBAL_VARIABLES_TABLES_CONFIG.SCRIPT_VARIABLES_FORM.id(props.scriptId),
    ),
  }),
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(AssignGlobalVariablesPanel);
