import AzureFormChunk from "@app/modules/issueTrackingTool/components/AzureFormChunk";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";
import { TreeSelectField } from "@ea/shared_components";
import CheckboxField from "@ea/shared_components/Form/Fields/CheckboxField";
import { InputField } from "@ea/shared_components/Form/Fields/InputField";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import validators from "@ea/shared_components/Form/validators";
import { FEATURES, ProjectTreeNode, ScriptStatus } from "@ea/shared_types/types";
import { Select } from "antd";
import { getIn } from "final-form";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import HideableFeature from "../HideableFeature";
import StatusTag from "./StatusTag";

interface IChangeStatusFormProps {
  values: any;
  statusTransitionOptions: { text: string; value: ScriptStatus }[];
  tagOptions: { text: string; value: number }[];
  itsOptions: OptionType[];
  change: (name: string, value: any) => void;
  confirmPublishingWithCredentials?: boolean | null;
  testPlansIntegration?: boolean | null;
  currentUserName?: string;
  projects: ProjectTreeNode[];
  displayProjectSelection?: boolean;
  initialStatus: ScriptStatus;
  scriptExportedToTestCase: boolean;
  isAADUser: boolean;
}

type Props = IChangeStatusFormProps & InjectedIntlProps;
class ChangeStatusForm extends React.Component<Props> {
  renderOption = (option: { text: string; value: ScriptStatus }): JSX.Element => {
    return (
      <Select.Option value={option.value} key={`${option.value}`}>
        <StatusTag item={{ status: ScriptStatus[option.value] }} />
      </Select.Option>
    );
  };

  componentDidUpdate(prevProps) {
    const { status: prevStatus } = prevProps.values;
    const { status } = this.props.values;
    if (prevStatus === ScriptStatus.PUBLISHED && status !== ScriptStatus.PUBLISHED) {
      this.clearFormFields();
    }
  }
  clearFormFields = () => {
    const { change } = this.props;
    change("exportToTestCase", undefined);
    change("integrationMetadata", undefined);
    change("password", undefined);
    change("username", undefined);
  };

  render() {
    const {
      statusTransitionOptions,
      tagOptions,
      values,
      change,
      itsOptions,
      confirmPublishingWithCredentials,
      testPlansIntegration,
      intl,
      currentUserName,
      projects,
      displayProjectSelection,
      initialStatus,
      scriptExportedToTestCase,
      isAADUser,
    } = this.props;
    const exportToTestCase = getIn(values, "exportToTestCase");
    const isPublishStatus = getIn(values, "status") === ScriptStatus.PUBLISHED;
    const { userNameMatchValidator } = validators(intl);

    return (
      <>
        <SelectField
          name="status"
          required
          label={getTranslationKey("scripts", "label", "status")}
          placeholder={getTranslationKey("scripts", "placeholder", "status")}
          data-testid={DataTestIds.FORM_SELECT_STATUS}
        >
          {statusTransitionOptions.map(this.renderOption)}
        </SelectField>
        {displayProjectSelection && (
          <TreeSelectField
            label={getTranslationKey("common", "label", "project")}
            name="projectId"
            required
            treeDefaultExpandAll
            nodes={projects}
            placeholder={getTranslationKey("common", "placeholder", "project")}
            data-testid={DataTestIds.FORM_SELECT_PROJECT}
          />
        )}
        {confirmPublishingWithCredentials && !isAADUser ? (
          <HideableFeature feature={FEATURES.ELECTRONIC_SIGNOFF}>
            {() => (
              <>
                <InputField
                  name="username"
                  label={getTranslationKey("common", "label", "username")}
                  placeholder={getTranslationKey("common", "placeholder", "username")}
                  required
                  validate={userNameMatchValidator(currentUserName)}
                  data-testid={DataTestIds.FORM_INPUT_USERNAME}
                />
                <InputField
                  name="password"
                  label={getTranslationKey("common", "label", "password")}
                  placeholder={getTranslationKey("common", "placeholder", "password")}
                  required
                  type="password"
                  data-testid={DataTestIds.FORM_INPUT_PASSWORD}
                />
              </>
            )}
          </HideableFeature>
        ) : null}
        <TextAreaField
          name="comment"
          label={getTranslationKey("common", "label", "comment")}
          placeholder={getTranslationKey("common", "placeholder", "comment")}
          autoSize={{ minRows: 2, maxRows: 6 }}
          defaultValue={""}
          data-testid={DataTestIds.FORM_TEXTAREA_COMMENT}
        />
        <HideableFeature feature={FEATURES.GXP_TAGS}>
          {() => (
            <SelectField
              name="tags"
              mode="multiple"
              label={getTranslationKey("common", "label", "tags")}
              placeholder={getTranslationKey("common", "placeholder", "tags")}
              options={tagOptions}
              data-testid={DataTestIds.FORM_SELECT_TAGS}
            />
          )}
        </HideableFeature>

        {testPlansIntegration && getIn(values, "status") === ScriptStatus.PUBLISHED && (
          <CheckboxField
            label={getTranslationKey("scripts", "label", "exportToTestCase")}
            name="exportToTestCase"
            data-testid={DataTestIds.FORM_CHECKBOX_EXPORT_TO_TEST_CASE}
          />
        )}
        {testPlansIntegration &&
          scriptExportedToTestCase &&
          (getIn(values, "status") === ScriptStatus.CLOSED ||
            (initialStatus === ScriptStatus.CLOSED &&
              getIn(values, "status") === ScriptStatus.DRAFT)) && (
            <CheckboxField
              label={getTranslationKey("scripts", "label", "updateTestCaseStatus")}
              name="updateTestCaseStatus"
              data-testid={DataTestIds.FORM_CHECKBOX_UPDATE_TEST_CASE_STATUS}
            />
          )}
        {testPlansIntegration && exportToTestCase && (
          <>
            <SelectField
              name="integrationMetadata.azureDevops.itsId"
              placeholder={getTranslationKey("its", "placeholder", "its")}
              required
              label={getTranslationKey("its", "label", "its")}
              options={itsOptions}
              data-testid={DataTestIds.FORM_SELECT_ITS}
            />
            <AzureFormChunk
              {...this.props}
              itsId={getIn(values, "integrationMetadata.azureDevops.itsId")}
              prefix="integrationMetadata.azureDevops."
              change={change}
            />
          </>
        )}
      </>
    );
  }
}

export default injectIntl(ChangeStatusForm);
