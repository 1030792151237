import * as React from "react";
import { Tag } from "antd";
import { COLORS } from "@app/styles/consts";
import { SYSTEM_TYPE } from "@ea/shared_types/types";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { DataTestIds } from "@app/utils/dataTestIds";

interface IStatusFieldProps {
  type: SYSTEM_TYPE;
}

const style = {
  cursor: "default",
};

const SystemTypeField: React.FunctionComponent<IStatusFieldProps & InjectedIntlProps> = ({
  type,
  intl,
}) => {
  if (!type) {
    return null;
  }

  return (
    <Tag
      style={style}
      color={COLORS.SYSTEM_TYPE[type]}
      data-testid={DataTestIds.getTagTestId(type)}
    >
      {intl.formatMessage({ id: `systemDictionary.type.${type}` })}
    </Tag>
  );
};

export default injectIntl(SystemTypeField);
