import { MarkOptional } from "ts-essentials";
import { SchedulerGroup, SchedulerScript, SchedulerRunTypes } from "@ea/shared_types/types";
import { GanttTask } from "./SchedulerGantt/gantt.types";
import {
  takeGroupId,
  takeScriptId,
  isItGroupId,
  isItScriptId,
  isScriptGanttTask,
} from "./SchedulerGantt/gantt.helpers";
import { partition } from "@ea/shared_components/utils/array";

function getScriptsToMove(allGroups: SchedulerGroup[], selectedTasks: GanttTask[]) {
  const singleGroupsIds = selectedTasks
    .filter((s) => isItGroupId(s.id))
    .map((s) => takeGroupId(s.id));
  const scriptTasks = selectedTasks.filter(isScriptGanttTask);

  const groupsToRemove = allGroups.filter((eG) => singleGroupsIds.indexOf(eG.id) > -1);

  const scriptsMap = scriptTasks.reduce((container, next) => {
    if (!next.parent) {
      return container;
    }
    const parentId = takeGroupId(next.parent);
    const scriptId = takeScriptId(next.id);
    if (!container[parentId]) {
      container[parentId] = [];
    }
    container[parentId].push(scriptId);

    return container;
  }, {});

  const modifiedGroups: MarkOptional<SchedulerGroup, "id">[] = [...allGroups];

  const scriptsToMove: SchedulerScript[] = [];

  groupsToRemove.forEach((g) => {
    scriptsToMove.push(...g.scripts);
    g.scripts = [];
  });

  modifiedGroups
    .filter((nG) => scriptsMap[nG.id!])
    .forEach((nG) => {
      const [pass, fail] = partition(
        nG.scripts,
        (script) => scriptsMap[nG.id!].indexOf(script.id) > -1,
      );

      scriptsToMove.push(...pass);
      nG.scripts = fail;
      if (fail.length === 1) {
        nG.runParams.repeater = undefined;
      }
      if (fail.length === 0 && nG.id) {
        if (!groupsToRemove.find((gR) => gR.id === nG.id)) {
          groupsToRemove.push(nG as any);
        }
      }
    });

  const scriptsToMoveIds = scriptsToMove.reduce((container, next) => {
    container[next.id] = true;
    return container;
  }, {});

  modifiedGroups.forEach((g) =>
    g.scripts.forEach((s) => {
      if (s.runParams.run.type === SchedulerRunTypes.DEPENDANT) {
        s.runParams.run.dependantIds = s.runParams.run.dependantIds.filter(
          (dId) => !scriptsToMoveIds[dId],
        );
      }
    }),
  );

  return { groupsToRemove, modifiedGroups, scriptsToMove };
}

export function createGroupWithExistingSchedulerScripts(
  newGroup: MarkOptional<SchedulerGroup, "id">,
  allGroups: SchedulerGroup[],
  selectedTasks: GanttTask[],
) {
  const { groupsToRemove, modifiedGroups, scriptsToMove } = getScriptsToMove(
    allGroups,
    selectedTasks,
  );
  newGroup.scripts = scriptsToMove;
  modifiedGroups.push(newGroup);

  modifiedGroups.forEach((g) => {
    g.scripts = g.scripts.map((s, index) => ({ ...s, lineNum: index }));
  });

  return { groupsToRemove, modifiedGroups };
}

export function moveSchedulerScriptsToGroup(
  groupIdToMove: number,
  allGroups: SchedulerGroup[],
  selectedTasks: GanttTask[],
) {
  const { groupsToRemove, modifiedGroups, scriptsToMove } = getScriptsToMove(
    allGroups,
    selectedTasks,
  );

  modifiedGroups.find((mG) => mG.id === groupIdToMove)?.scripts.push(...scriptsToMove);

  modifiedGroups.forEach((g) => {
    g.scripts = g.scripts.map((s, index) => ({ ...s, lineNum: index }));
  });

  return { groupsToRemove, modifiedGroups };
}
