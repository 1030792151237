import * as React from "react";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import styled from "@emotion/styled";
import onClickOutside from "react-click-outside";
import { ProjectTreeNode } from "@ea/shared_types/types";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { DataTestIds } from "@app/utils/dataTestIds";

type CellMode = "NEW" | "EDIT";

interface INewNodeCellProps {
  onDone: (value: string, parent: ProjectTreeNode | undefined, mode: CellMode) => void;
  onUnfocus: () => void;
  onCancel: () => void;
  parent: ProjectTreeNode | undefined;
  mode: CellMode;
  initialValue?: string;
}

interface INewNodeCellState {
  value: string;
}

const Container = styled.div({
  position: "relative",
  display: "inline-block",
  marginRight: "5px",
});

const InputWrapper = styled.div({
  paddingRight: "44px",
  display: "inline-block",
});

// todo: we do casting here because of the problems with emotion and typescript. This pr should fix it: https://github.com/emotion-js/emotion/pull/1514
const CheckButton = styled(Button)({
  position: "absolute",
  right: -4,
  width: "25px",
  cursor: "pointer",
  background: "white",
  ":hover": {
    color: "#108ee9",
  },
  border: "1px solid #d9d9d9",
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderTopRightRadius: "4px",
  borderBottomRightRadius: "4px",
  paddingLeft: "5px",
  paddingRight: "5px",
}) as any as typeof Button;

const CloseButton = styled(Button)({
  paddingLeft: "5px",
  paddingRight: "5px",
  position: "absolute",
  right: 19,
  width: "25px",
  cursor: "pointer",
  background: "white",
  ":hover": {
    color: "#108ee9",
  },
  borderTop: "1px solid #d9d9d9",
  borderBottom: "1px solid #d9d9d9",
  borderLeft: "0px",
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
});

class NewNodeCell extends React.Component<INewNodeCellProps, INewNodeCellState> {
  input: any;

  constructor(props: INewNodeCellProps) {
    super(props);

    this.state = {
      value: props.initialValue ? props.initialValue : "",
    };
  }

  componentDidMount() {
    if (this.input) {
      this.input.focus();
    }
  }

  handleClickOutside = (event) => {
    this.props.onUnfocus();
  };

  handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    this.setState({ value });
    event.stopPropagation();
  };

  done = (event) => {
    if (this.state.value) {
      this.props.onDone(this.state.value, this.props.parent, this.props.mode);
      event.stopPropagation();
    }
  };

  render() {
    const { value } = this.state;
    const { onCancel: onDelete, onUnfocus } = this.props;

    return (
      <Container>
        <InputWrapper>
          <Input
            value={value}
            onChange={this.handleChange}
            onPressEnter={this.done}
            onClick={(e) => e.stopPropagation()}
            ref={(input) => (this.input = input)}
            data-testid={DataTestIds.TREE_NEW_PROJECT_INPUT}
          />
          <CloseButton onClick={onDelete} data-testid={DataTestIds.TREE_NEW_PROJECT_CLOSE}>
            <CloseOutlined />
          </CloseButton>
          <CheckButton
            disabled={this.state.value ? false : true}
            onClick={this.done}
            data-testid={DataTestIds.TREE_NEW_PROJECT_CHECK}
          >
            <CheckOutlined />
          </CheckButton>
        </InputWrapper>
      </Container>
    );
  }
}

export default onClickOutside(NewNodeCell) as typeof NewNodeCell;
