import { CheckboxField } from "@ea/shared_components/Form/Fields/CheckboxField";
import { InputField } from "@ea/shared_components/Form/Fields/InputField";
import { VariablesGroup } from "@ea/shared_components/redux/common.models";
import {
  BoolValue,
  CodeTemplateWithGroup,
  GlobalVariable,
  LabelParams,
  ROLES,
  Script,
  Step,
  System,
  Variable,
  VirtualUser,
} from "@ea/shared_types/types";
import { getIn } from "final-form";
import * as React from "react";

import WithAuthorization from "@app/modules/common/WithAuthorization";
import { getStepCommand } from "@app/packs/packs.helpers";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { InputNumberField, RadioField } from "@ea/shared_components";
import { RadioType } from "@ea/shared_components/Form/Fields/RadioField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import { LayoutContext } from "@ea/shared_components/Form/FormLayoutContext";
import { isDebugMode } from "@ea/shared_components/utils/env";
import { CoreCommandsIds } from "@ea/shared_types/core.commands.types";
import { Collapse } from "antd";
import { FormattedMessage } from "react-intl";
import StepTerminationForm from "./Chunks/StepTimeoutFormChunk";
import { inputPositiveNumbersProps } from "./CreateStepCommonForm";

import { CaretRightOutlined } from "@ant-design/icons";
import { DataTestIds } from "@app/utils/dataTestIds";
import {
  formatMilisecondsToSeconds,
  parseFromSecondsStringToMiliseconds,
} from "@app/utils/transformations";
import { DeepPartial } from "@ea/shared_types/basic.types";
import i18next from "../../../../translations/backendTranslations";
import PathsTableForm from "./Chunks/PathsTableForm";

interface IStepDetailsFormProps {
  values: DeepPartial<Step>;
  variables: Variable[];
  variablesGroups: VariablesGroup[];
  systems: System[];
  system?: System;
  virtualUser?: VirtualUser;
  script: Script;
  steps: Step[];
  // eslint-disable-next-line
  change: any;
  readOnly: boolean;
  virtualUsers?: VirtualUser[];
  linkedScript?: Script;
  labelParams?: LabelParams;
  openLinked: () => void;
  disableOpenLinkedScript?: boolean;
  globalMutables: GlobalVariable[];
  globalConstants: GlobalVariable[];
  codeTemplates: CodeTemplateWithGroup[];
}

const { Panel: CollapsePanel } = Collapse;

const AuthorizedCheckboxField = WithAuthorization([], [ROLES.freedocs])(CheckboxField);
const AuthorizedInputField = WithAuthorization([], [ROLES.freedocs])(InputField);

const StepDetailsForm: React.FunctionComponent<IStepDetailsFormProps> = (props) => {
  const { values, readOnly, change } = props;

  const commandId = getIn(values, "commandId");
  const platform = getIn(values, "platform");
  const manualLabel = getIn(values, "manualLabel");
  const isTimeoutEnabled = getIn(values, "isTimeoutEnabled");

  const command = getStepCommand({ commandId, platform });

  const hasDynamicLabel = command && !!command.getLabel;

  // TODO pass form and use context to do it?
  const commandFormChunk = command
    ? command.getFormChunk(props)
    : () => (
        <div>
          <FormattedMessage id={getTranslationKey("messages", "info", "commandNotInstalled")} />
        </div>
      );

  const BoolValueParse = {
    [BoolValue.TRUE]: true,
    [BoolValue.FALSE]: false,
    [BoolValue.NOT_DEFINED]: null,
  };

  const BoolValueFormat = {
    true: BoolValue.TRUE,
    false: BoolValue.FALSE,
    null: BoolValue.NOT_DEFINED,
    undefined: BoolValue.NOT_DEFINED,
  };

  const onManualLabelChange = (evt) => {
    if (evt.target.checked) {
      const labelKey = getIn(values, "labelKey");
      const labelParams = getIn(values, "labelParams");
      change("label", i18next.t(labelKey, labelParams));
    }
  };

  return (
    <>
      <LayoutContext.Consumer>
        {(layout) => (
          <InputField
            name="label"
            label={getTranslationKey("step", "detailsForm", "label", "label")}
            readOnly={layout.readOnly || (hasDynamicLabel && !manualLabel)}
            format={(value) => value}
            defaultValue={""}
            data-testid={DataTestIds.FORM_INPUT_LABEL}
          />
        )}
      </LayoutContext.Consumer>

      {hasDynamicLabel && (
        <AuthorizedCheckboxField
          name="manualLabel"
          label={getTranslationKey("step", "detailsForm", "label", "manualLabel")}
          onChange={onManualLabelChange}
          data-testid={DataTestIds.FORM_CHECKBOX_MANUEL_LABEL}
        />
      )}

      <AuthorizedInputField
        label={getTranslationKey("step", "detailsForm", "label", "delay")}
        name="delay"
        addonAfter="seconds"
        type="number"
        data-testid={DataTestIds.FORM_INPUT_DELAY}
        {...inputPositiveNumbersProps}
      />

      {commandId !== CoreCommandsIds.start && commandId !== CoreCommandsIds.end && (
        <>
          <AuthorizedCheckboxField
            name="isOptional"
            label={getTranslationKey("step", "detailsForm", "label", "isOptional")}
            data-testid={DataTestIds.FORM_CHECKBOX_IS_OPTIONAL}
          />
          <AuthorizedCheckboxField
            name="isDisabled"
            label={getTranslationKey("step", "detailsForm", "label", "isDisabled")}
            data-testid={DataTestIds.FORM_CHECKBOX_IS_DISABLED}
          />
          <AuthorizedCheckboxField
            name="isOneTime"
            label={getTranslationKey("step", "detailsForm", "label", "isOneTime")}
            data-testid={DataTestIds.FORM_CHECKBOX_IS_ONE_TIME}
          />
          <AuthorizedCheckboxField
            name="isTimeoutEnabled"
            label={getTranslationKey("step", "detailsForm", "label", "isTimeoutEnabled")}
            data-testid={DataTestIds.FORM_CHECKBOX_IS_TIMEOUT_ENABLED}
          />
          {isTimeoutEnabled && (
            <InputNumberField
              required
              defaultValue={0}
              step={1000}
              min={0}
              parser={parseFromSecondsStringToMiliseconds}
              formatter={formatMilisecondsToSeconds}
              name="timeout"
              label={getTranslationKey("step", "detailsForm", "label", "timeout")}
              data-testid={DataTestIds.FORM_INPUT_TIMEOUT}
            />
          )}

          <RadioField
            name="takeScreenshots"
            label={getTranslationKey("common", "label", "takeScreenshots")}
            defaultValue={null}
            type={RadioType.Normal}
            options={[
              {
                text: getTranslationKey("step", "detailsForm", "label", "scriptSettings"),
                value: BoolValue.NOT_DEFINED,
              },
              {
                text: getTranslationKey("button", "yes"),
                value: BoolValue.TRUE,
              },
              {
                text: getTranslationKey("button", "no"),
                value: BoolValue.FALSE,
              },
            ]}
            parse={(value) => BoolValueParse[value]}
            format={(value) => BoolValueFormat[value]}
            readOnly={readOnly}
          />
        </>
      )}

      <TextAreaField
        name="comment"
        label={getTranslationKey("step", "detailsForm", "label", "comment")}
        autoSize={{ minRows: 1, maxRows: 4 }}
        defaultValue={""}
        data-testid={DataTestIds.FORM_TEXTAREA_COMMENT}
      />

      <StepTerminationForm values={values} change={change} />

      {
        // TODO ADD RULE METADATA
        isDebugMode() && (
          <AuthorizedInputField
            name="version"
            label={getTranslationKey("step", "detailsForm", "label", "version")}
            readOnly
            data-testid={DataTestIds.FORM_INPUT_VERSION}
          />
        )
      }

      {commandFormChunk()}

      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            rotate={isActive ? 90 : 0}
            data-testid={DataTestIds.ICON_COLLAPSE_ADVANCED_STEP_FIELDS}
          />
        )}
      >
        <CollapsePanel
          header={
            <FormattedMessage id={getTranslationKey("step", "detailsForm", "advancedFields")} />
          }
          key="debugFields"
        >
          <AuthorizedInputField
            name="commandId"
            label={getTranslationKey("step", "detailsForm", "label", "commandId")}
            readOnly
            data-testid={DataTestIds.FORM_INPUT_COMMAND_ID}
          />
          <AuthorizedInputField
            name="rule.id"
            label={getTranslationKey("step", "detailsForm", "label", "rule")}
            readOnly
            data-testid={DataTestIds.FORM_INPUT_RULE_ID}
          />
          <AuthorizedInputField
            name="rule.platform"
            label={getTranslationKey("step", "detailsForm", "label", "rulePlatform")}
            readOnly
            data-testid={DataTestIds.FORM_INPUT_RULE_PLATFORM}
          />
          <AuthorizedInputField
            name="rule.version"
            label={getTranslationKey("step", "detailsForm", "label", "ruleVersion")}
            readOnly
            data-testid={DataTestIds.FORM_INPUT_RULE_VERSION}
          />
          <AuthorizedInputField
            name="platform.version"
            label={getTranslationKey("step", "detailsForm", "label", "platformVersion")}
            readOnly
            data-testid={DataTestIds.FORM_INPUT_PLATFORM_VERSION}
          />
          <PathsTableForm />
        </CollapsePanel>
      </Collapse>
    </>
  );
};

export default StepDetailsForm;
