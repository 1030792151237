import styled from "@emotion/styled";
import * as React from "react";
import { IFilterControlProps } from "./FilterContainer";

import { Tree, Input } from "antd";
import { NodeFormItem } from "../../Form/Form.common";
import TreeSearch from "../../common/TreeSearch";

interface ITreeFilterProps extends IFilterControlProps {
  values: NodeFormItem[];
}

const Container = styled.div({
  height: "100%",
  width: "100%",
});

const TreeNode = Tree.TreeNode;
const Search = Input.Search;

const generateNodes = (nodes: NodeFormItem[] = []) => {
  return nodes.map((n) => (
    <TreeNode title={n.name} key={`${n.id}`} disabled={n.disabled}>
      {n.children.length === 0 ? null : generateNodes(n.children)}
    </TreeNode>
  ));
};

class TreeFilter extends React.Component<ITreeFilterProps> {
  onCheck = (checked): void => {
    this.props.setValue(checked);
  };

  onRemove = (removedValue): void => {
    const { value } = this.props;
    const newValues = (value || []).filter((v) => v !== removedValue.toString());
    this.props.setValue(newValues);
  };

  render() {
    const { values, value } = this.props;

    return (
      <Container>
        <TreeSearch
          withTags
          multi
          checkStrictly
          values={values || []}
          selected={value || []}
          onSelect={this.onCheck}
          onRemove={this.onRemove}
        >
          {generateNodes(values)}
        </TreeSearch>
      </Container>
    );
  }
}

export default TreeFilter;
