import React from "react";
import { FormSpy } from "react-final-form";
import diff from "object-diff";
import { FormApi } from "final-form";

type AutoSaveFormModifierProps = {
  debounce: number;
  values: any;
  save: any;
  customEqual?: any;
  fields?: string[];
  handleSubmit: any;
  form: FormApi;
};

type AutoSaveFormModifierState = {
  values: any;
  submitting: boolean;
};

class AutoSaveFormModifier extends React.Component<
  AutoSaveFormModifierProps,
  AutoSaveFormModifierState
> {
  timeout: null | ReturnType<typeof setTimeout> = null;
  promise: Promise<any>;

  constructor(props) {
    super(props);
    this.state = { values: props.values, submitting: false };
  }

  componentWillUnmount(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(this.save, this.props.debounce);
  }

  save = async () => {
    if (this.promise) {
      await this.promise;
    }
    const { values, save, customEqual, fields } = this.props;
    const getObject = (obj) => {
      if (!fields) {
        return obj;
      }

      const newObj = {};

      fields.forEach((f) => (newObj[f] = obj[f]));

      return newObj;
    };

    const difference = customEqual
      ? diff.custom({ equal: customEqual }, getObject(this.state.values), getObject(values))
      : diff(getObject(this.state.values), getObject(values));

    if (Object.keys(difference).length) {
      this.setState({ submitting: true, values });
      // @ts-ignore
      this.promise = this.props.form.submit(difference);
      await this.promise;
      // @ts-ignore
      delete this.promise;
      this.setState({ submitting: false });
    }
  };

  render() {
    return this.state.submitting && <div className="submitting">Submitting...</div>;
  }
}

export default (props) => (
  <FormSpy {...props} subscription={{ values: true }} component={AutoSaveFormModifier} />
);
