export default class ClientUserError extends Error {
  type: string;
  constructor(...args: any[]) {
    super(...args);
    Error.captureStackTrace(this, ClientUserError);
    this.constructor = ClientUserError;
    (this as any).__proto__ = ClientUserError.prototype;
    this.type = "USER";
    this.name = "UserError";
  }
}
