import {
  getDataReducerInitialState,
  createDataReducer,
} from "@ea/shared_components/redux/reducers/common.data.reducer";
import { System } from "@ea/shared_types/types";
import { moduleActions } from "./systemDictionary.actions";

const initialState = {
  ...getDataReducerInitialState<System>(),
};

export type SystemReducer = typeof initialState;

const dataReducer = createDataReducer<System>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
