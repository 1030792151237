import { Button, Modal, Spin } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { KpiChart } from "./KpiChart";
import { KpiTable } from "./KpiTable";
import { useAsync } from "react-use";
import { API } from "@app/services/api/api";
import styled from "@emotion/styled";
import moment from "moment";
import { KpiChartStatistics } from "@ea/shared_types/types";

interface KpiStatisticsModalProps {
  visible: boolean;
  onClose: () => void;
  executionLogId?: number;
  jobId?: string;
}

const ChartContainer = styled.div({
  "@media (min-height: 850px)": {
    height: "400px",
  },
  "@media (min-height: 1080px)": {
    height: "500px",
  },
  "@media (min-height: 1200px)": {
    height: "600px",
  },
  gridArea: "Chart",
  height: "300px",
});
const TableContainer = styled.div({
  gridArea: "Table",
  width: "100%",
  height: "100%",
  overflowY: "auto",
});

const convertTimestapsToDate = (stats: KpiChartStatistics[]) =>
  stats.map((s) => ({ ...s, timestamp: moment(s.timestamp).format("DD.MM.YYYY HH:mm:ss") }));

const KpiStatisticsModal = React.memo(
  ({ visible, onClose, executionLogId, jobId }: KpiStatisticsModalProps) => {
    const response = useAsync(async () => {
      if (executionLogId) {
        return API.getExecutionStatistics({ executionIds: [executionLogId] });
      } else if (jobId) {
        return API.getExecutionJobStatistics({ jobId });
      }
    }, [executionLogId, jobId]);

    const { chartStats, tableStats } = response.value || {};

    const isError = response.error;
    const isEmpty = !chartStats || chartStats.length === 0;
    const getModalBody = () => {
      if (response.loading) {
        return <Spin />;
      } else if (isError) {
        return <FormattedMessage id={getTranslationKey("kpi", "errors", "loadingStats")} />;
      } else if (isEmpty) {
        return <FormattedMessage id={getTranslationKey("kpi", "errors", "noStatsAvailable")} />;
      }
      return (
        <>
          <ChartContainer>
            <KpiChart data={convertTimestapsToDate(chartStats) || []} />
          </ChartContainer>
          <TableContainer>
            <KpiTable data={tableStats || []} />
          </TableContainer>
        </>
      );
    };

    const errorOrEmpty = isError || isEmpty;

    return (
      <Modal
        title={
          <FormattedMessage id={getTranslationKey("kpi", "header", "performanceCountersStats")} />
        }
        visible={visible}
        closable={false}
        width={errorOrEmpty ? "400px" : "80vw"}
        footer={[
          <Button key="close" type="primary" onClick={onClose}>
            <FormattedMessage id={getTranslationKey("commandBar", "close")} />
          </Button>,
        ]}
        bodyStyle={{
          maxHeight: "70vh",
          alignItems: "start",
          display: "grid",
          gap: errorOrEmpty ? 0 : "60px",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "1fr 1fr",
          gridAutoFlow: "row",
          gridTemplateAreas: `"Chart"
              "Table"`,
        }}
      >
        {getModalBody()}
      </Modal>
    );
  },
);

export default KpiStatisticsModal;
