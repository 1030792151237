import * as React from "react";

import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import CommandBarDropdown from "@ea/shared_components/CommandBar/CommandBarDropdown";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { GanttTask } from "./SchedulerGantt/gantt.types";
import { GanttFunctions } from "../SchedulerGantt.container";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faEdit, faSave, faTimes } from "@fortawesome/fontawesome-free-solid";
import {
  ClockCircleOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  SelectOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { DataTestIds } from "@app/utils/dataTestIds";
import { FormattedMessage } from "react-intl";
interface SchedulerFlowCommandBarProps {
  onAddClick: () => void;
  onGanttSaveClick: () => void;
  onCreateGroupClick?: () => void;
  onRemoveClick?: () => void;
  onMoveToGroupClick?: () => void;
  onAddToGroupClick?: () => void;
  onCancelClick: () => void;
  onEditClick: () => void;
  isEditing: boolean;
  readOnly: boolean;
  hasDataChanged: boolean;
  selectedLinks: string[];
  onLinksRemoveClick?: () => void;
  selectedTasks: GanttTask[];
  onFunctionClick: (mode: GanttFunctions) => void;
}

class SchedulerGanttCommandBar extends React.Component<SchedulerFlowCommandBarProps> {
  public render() {
    const {
      onAddClick,
      hasDataChanged,
      onGanttSaveClick,
      selectedTasks,
      onAddToGroupClick,
      onCreateGroupClick,
      onMoveToGroupClick,
      onRemoveClick,
      onFunctionClick,
      onLinksRemoveClick,
      onCancelClick,
      selectedLinks,
      onEditClick,
      isEditing,
      readOnly,
    } = this.props;

    return (
      <CommandBar data-testid={DataTestIds.COMMANDBAR_SCHEDULER_GANTT}>
        {!isEditing ? (
          <CommandBarButton
            name="edit"
            onClick={onEditClick}
            text={getTranslationKey("commandBar", "edit")}
            icon={<FontAwesomeIcon icon={faEdit} />}
            disabled={readOnly}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_EDIT}
          />
        ) : (
          <>
            <CommandBarButton
              name="save"
              onClick={onGanttSaveClick}
              text={getTranslationKey("commandBar", "save")}
              icon={<FontAwesomeIcon icon={faSave} />}
              type={hasDataChanged ? "primary" : undefined}
              disabled={readOnly || !hasDataChanged}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_SAVE}
            />
            <CommandBarButton
              name="cancel"
              onClick={onCancelClick}
              text={getTranslationKey("button", "cancel")}
              icon={<FontAwesomeIcon icon={faTimes} />}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_CANCEL}
            />

            <CommandBarButton
              name="createGroup"
              onClick={onCreateGroupClick}
              text={getTranslationKey("commandBar", "createGroup")}
              icon={<PlusSquareOutlined />}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_CREATE_GROUP}
              tooltip={
                hasDataChanged
                  ? ((
                      <FormattedMessage
                        id={getTranslationKey("messages", "tooltip", "saveBeforeCreateGroup")}
                      />
                    ) as any)
                  : undefined
              }
              disabled={
                readOnly ||
                hasDataChanged ||
                !(
                  selectedTasks.length > 1 &&
                  !selectedTasks.find((s) => s.kind === "GROUP" && !(s.scriptMode === "SINGLE"))
                )
              }
            />
            <CommandBarButton
              name="moveToGroup"
              onClick={onMoveToGroupClick}
              text={getTranslationKey("commandBar", "moveGroup")}
              icon={<SelectOutlined />}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_MOVE_TO_GROUP}
              disabled={
                readOnly ||
                !(
                  selectedTasks.length > 0 &&
                  !selectedTasks.find((s) => s.kind === "GROUP" && !(s.scriptMode === "SINGLE"))
                )
              }
            />
            <CommandBarButton
              name="remove"
              onClick={onRemoveClick}
              text={getTranslationKey("commandBar", "remove")}
              icon={<DeleteOutlined />}
              disabled={readOnly || !(selectedTasks.length >= 1)}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE}
            />
            <CommandBarDropdown
              name="functions"
              text={getTranslationKey("commandBar", "functions")}
              icon={<SettingOutlined />}
              disabled={readOnly}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_FUNCTIONS}
            >
              <CommandBarButton
                data-testid={DataTestIds.COMMANDBAR_BUTTON_START_TIME}
                key="startTime"
                name="startTime"
                onClick={() => onFunctionClick(GanttFunctions.CHANGE_GANTT_START)}
                text={getTranslationKey("commandBar", "ganttStartTime")}
                size="default"
                icon={<ClockCircleOutlined />}
              />
              <CommandBarButton
                key="scriptsStartTime"
                name="scriptsStartTime"
                onClick={() => onFunctionClick(GanttFunctions.CHANGE_SCRIPTS_START)}
                text={getTranslationKey("commandBar", "scriptsStartTime")}
                size="default"
                icon={<ClockCircleOutlined />}
                disabled={selectedTasks.length < 2}
                data-testid={DataTestIds.COMMANDBAR_BUTTON_SCRIPT_START_TIME}
              />
            </CommandBarDropdown>
            <CommandBarButton
              name="removeLinks"
              onClick={onLinksRemoveClick}
              text={getTranslationKey("commandBar", "removeLinks")}
              icon={<DeleteOutlined />}
              disabled={readOnly || !(selectedLinks.length > 0)}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE_LINKS}
            />
          </>
        )}
        <CommandBarButton
          far
          name="new"
          onClick={onAddClick}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_NEW}
          text={getTranslationKey("commandBar", "add")}
          icon={<PlusOutlined />}
          disabled={readOnly || hasDataChanged}
        />

        <CommandBarButton
          name="addToGroup"
          onClick={onAddToGroupClick}
          text={getTranslationKey("commandBar", "addToGroup")}
          icon={<PlusCircleOutlined />}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_ADD_TO_GROUP}
          disabled={
            readOnly ||
            hasDataChanged ||
            !(
              selectedTasks.length === 1 &&
              selectedTasks[0].kind === "GROUP" &&
              !(selectedTasks[0].scriptMode === "SINGLE")
            )
          }
        />
      </CommandBar>
    );
  }
}

export default SchedulerGanttCommandBar;
