import * as React from "react";

import { CodeTemplateWithGroup, PlainObject } from "@ea/shared_types/types";
import { CustomColumn, ColumnConfig } from "@ea/shared_components/src/Table/common.tables";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { ColumnFilters } from "@ea/shared_components/src/redux/common.filters";
import { GetAsTime } from "../common/time";
import { Tag } from "antd";

export const CODE_TEMPLATES_COLUMNS_CONFIG: PlainObject<CustomColumn<CodeTemplateWithGroup>> = {
  name: {
    label: getTranslationKey("table", "name"),
    dataIndex: "name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  description: {
    label: getTranslationKey("table", "description"),
    dataIndex: "description",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  activeVersion: {
    label: getTranslationKey("table", "activeVersion"),
    dataIndex: "activeVersion",
    sortable: true,
    filter: {
      filterType: ColumnFilters.RANGE,
    },
  },
  functionName: {
    label: getTranslationKey("codeTemplates", "label", "functionName"),
    dataIndex: "functionName",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  group: {
    label: getTranslationKey("codeTemplates", "label", "group"),
    dataIndex: "codeTemplateGroupId",
    sortable: false,
    render: (text, record, index) => {
      const { id, name } = (record as any).codeTemplateGroup || {};

      if (name) {
        return <Tag key={id}>{name}</Tag>;
      }
      return null;
    },
  },
  createdAt: {
    label: getTranslationKey("table", "createdAt"),
    dataIndex: "createdAt",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "createdAt" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
  updatedAt: {
    label: getTranslationKey("table", "updatedAt"),
    dataIndex: "updatedAt",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "updatedAt" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
};

export const CODE_TEMPLATES_COLUMNS: ColumnConfig<CodeTemplateWithGroup>[] = [
  {
    props: CODE_TEMPLATES_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "14%",
    },
  },
  {
    props: CODE_TEMPLATES_COLUMNS_CONFIG.description,
    frameworkProps: {
      width: "16%",
    },
  },
  {
    props: CODE_TEMPLATES_COLUMNS_CONFIG.functionName,
    frameworkProps: {
      width: "14%",
    },
  },
  {
    props: CODE_TEMPLATES_COLUMNS_CONFIG.activeVersion,
    frameworkProps: {
      width: "14%",
    },
  },
  {
    props: CODE_TEMPLATES_COLUMNS_CONFIG.group,
    frameworkProps: {
      width: "14%",
    },
  },
  {
    props: CODE_TEMPLATES_COLUMNS_CONFIG.createdAt,
    frameworkProps: {
      width: "14%",
    },
  },
  {
    props: CODE_TEMPLATES_COLUMNS_CONFIG.updatedAt,
    frameworkProps: {
      width: "14%",
    },
  },
];

export const CODE_TEMPLATES_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_CODE_TEMPLATES",
  },
};
