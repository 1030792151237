import { TableCellDataGetterParams } from "react-virtualized";

import { toSeconds } from "@ea/shared_components/utils/formatters";

export const GetAsSeconds = ({ dataKey, rowData }: TableCellDataGetterParams) => {
  const time = rowData[dataKey];
  if (time) {
    return `${toSeconds(time)} s`;
  }
  return time;
};

export const GetAsTime = ({ dataKey, rowData }: TableCellDataGetterParams) => {
  const time = rowData[dataKey];
  if (time) {
    return new Date(time).toLocaleString();
  }
  return time;
};

export const calcTimeDifference = (end: Date | string, start: Date | string) => {
  if (!end || !start) {
    return 0;
  }
  return new Date(end).getTime() - new Date(start).getTime();
};
