import * as React from "react";

import BreadcrumbContainer from "@ea/shared_components/common/Breadcrumb.container";
import { BreadcrumbSection, MainSection, Page } from "@ea/shared_components/common/LayoutElements";
import SettingsContainer from "@app/modules/settings/Settings.container";
import { withRouter, RouteComponentProps } from "react-router";
import { camelCaseToRegular } from "@ea/shared_components/utils/formatters";
import { userGroupDataSelectors } from "@app/modules/userGroups/userGroups.selectors";
import { ApplicationState } from "@app/modules/app.reducers";
import { connect, ConnectedProps } from "react-redux";

interface ISettingsProps {}

class Settings extends React.Component<
  IConnectProps & ISettingsProps & RouteComponentProps<any>,
  any
> {
  generateBreadcrumbText = (chunk: string, index: number) => {
    const baseChunk = camelCaseToRegular(chunk);
    const { userGroup } = this.props;

    if (index !== 2 || !userGroup) {
      return baseChunk;
    }

    return `${baseChunk} - ${userGroup.name}`;
  };

  render() {
    return (
      <Page>
        <BreadcrumbSection>
          <BreadcrumbContainer transformText={this.generateBreadcrumbText} />
        </BreadcrumbSection>

        <MainSection style={{ flexDirection: "row" }}>
          <SettingsContainer {...this.props} />
        </MainSection>
      </Page>
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: RouteComponentProps<any>) => ({
    ...props,
    userGroupId: props.match.params.id,
    userGroup: userGroupDataSelectors.getItemSelector(state, props.match.params.id),
  }),
  {},
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(Settings));
