import * as React from "react";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";

interface ItsRuleCommandBarProps {
  onNewClick: () => void;
  onRemoveClick: (ids: number[]) => void;
  onEditClick: () => void;
  onReload: () => void;
  onOverrideChange?: () => void;
  selected: number[];
  overrideItsRules?: boolean;
}
class ItsRuleCommandBar extends React.Component<ItsRuleCommandBarProps> {
  public render() {
    return (
      <CommandBar data-testid={DataTestIds.COMMANDBAR_ITS_RULE}>
        {this.props.onOverrideChange && (
          <CommandBarButton
            name="Override"
            text={getTranslationKey("commandBar", "override")}
            onClick={this.props.onOverrideChange}
            size="default"
            icon="LockOutlined"
            checkable
            checked={this.props.overrideItsRules}
            checkedColor="#1890ff"
            data-testid={DataTestIds.COMMANDBAR_BUTTON_OVERRIDE}
          />
        )}
        <CommandBarButton
          name="new"
          onClick={this.props.onNewClick}
          text={getTranslationKey("commandBar", "add")}
          icon="PlusOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_NEW}
        />
        <CommandBarButton
          name="remove"
          onClick={() => this.props.onRemoveClick(this.props.selected)}
          text={getTranslationKey("commandBar", "remove")}
          icon="DeleteOutlined"
          disabled={this.props.selected.length === 0}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE}
        />
        <CommandBarButton
          name="refresh"
          onClick={this.props.onReload}
          text={getTranslationKey("commandBar", "refresh")}
          icon="ReloadOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
        />
        <CommandBarButton
          name="edit"
          onClick={this.props.onEditClick}
          text={getTranslationKey("commandBar", "edit")}
          icon="EditOutlined"
          disabled={this.props.selected.length !== 1}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_EDIT}
        />
      </CommandBar>
    );
  }
}
export default ItsRuleCommandBar;
