import * as React from "react";
import { DatePickerField } from "@ea/shared_components/Form/Fields/DatePickerField";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import OnFormChange from "@ea/shared_components/Form/OnFormChange";
import { VariableDefaultValueType } from "@ea/shared_types/types";
import validators, { composeValidators } from "@ea/shared_components/Form/validators";
import { EmptyField } from "@ea/shared_components/Form/Fields/EmptyField";
import { getTranslationKey } from "@app/translations/translations.helpers";

const typeOptions = () => [
  {
    value: VariableDefaultValueType.Text,
    text: getTranslationKey("variable", "type", "text"),
    type: "text",
  },
  {
    value: VariableDefaultValueType.Number,
    text: getTranslationKey("variable", "type", "number"),
    type: "number",
  },
  {
    value: VariableDefaultValueType.Date,
    text: getTranslationKey("variable", "type", "date"),
    type: "date",
  },
];
export const getVariableColumns = (props, intl) => {
  const { whiteSpaceAndSpecialCharValidator, mustStartWithLetter } = validators(intl);
  return [
    {
      title: getTranslationKey("table", "name"),
      dataIndex: "name",
      key: "name",
      required: true,
      width: "45%",
      render: (text, record) => {
        return (
          <InputField
            name={`${record}.name`}
            placeholder={getTranslationKey("common", "placeholder", "name")}
            required
            validate={composeValidators(whiteSpaceAndSpecialCharValidator, mustStartWithLetter)}
          />
        );
      },
    },
    {
      title: getTranslationKey("table", "valueType"),
      dataIndex: "valueType",
      key: "valueType",
      width: "15%",
      render: (text, record) => {
        return (
          <SelectField
            name={`${record}.valueType`}
            placeholder={getTranslationKey("variable", "placeholder", "selectType")}
            allowClear
            onChange={() => {
              props.change(`${record}.defaultValue`, null);
            }}
            options={typeOptions()}
          />
        );
      },
    },
    {
      title: getTranslationKey("table", "defaultValue"),
      dataIndex: "defaultValue",
      key: "defaultValue",
      render: (text, record) => {
        return (
          <OnFormChange name={`${record}.valueType`}>
            {(value) => {
              switch (value) {
                case VariableDefaultValueType.Text:
                case VariableDefaultValueType.Number: {
                  return (
                    <InputField
                      name={`${record}.defaultValue`}
                      placeholder={getTranslationKey("variable", "placeholder", "enterValue")}
                      type={value}
                      readOnly={props.readOnly}
                    />
                  );
                }
                case VariableDefaultValueType.Date: {
                  return (
                    <DatePickerField
                      name={`${record}.defaultValue`}
                      placeholder={getTranslationKey("variable", "placeholder", "enterValue")}
                      readOnly={props.readOnly}
                    />
                  );
                }
                default:
                  return <EmptyField name={`${record}.defaultValue`} />;
              }
            }}
          </OnFormChange>
        );
      },
    },
  ];
};
