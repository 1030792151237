import { Cascader } from "antd";

import createFormField from "../createFormField";
import withReadonly from "./withReadonly";
import { CascaderProps } from "antd/lib/cascader";
import { ReadonlyFormItem } from "../Form.common";

export const PickerField = createFormField<CascaderProps & ReadonlyFormItem>(
  withReadonly(Cascader),
  ({ name, onChange, value, onBlur, onFocus, ...rest }) => {
    const readOnlyProps = rest.readOnly
      ? {
          popupVisible: false,
          allowClear: false,
          disabled: true,
        }
      : {};

    return {
      ...rest,
      onChange,
      value,
      ...readOnlyProps,
    };
  },
);

export default PickerField;
