import { CheckboxOptionType } from "antd/lib/checkbox";
import CheckboxGroup, { CheckboxValueType } from "antd/lib/checkbox/Group";
import styled from "@emotion/styled";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { IFilterControlProps } from "./FilterContainer";

interface IInputFilterProps extends IFilterControlProps {
  values: Array<CheckboxOptionType | string>;
  noValueLabel?: string;
}

const Container = styled.div({});

class SelectFilter extends React.Component<IInputFilterProps> {
  onChange = (checkedValues: Array<CheckboxValueType>) => {
    this.props.setValue(checkedValues);
  };

  onContainerClick = (event) => {
    event.stopPropagation();
  };

  translateLabels = (val) => ({
    value: val.value,
    label: <FormattedMessage id={val.label} />,
  });

  render() {
    const { values, value, noValueLabel } = this.props;

    const displayValues = values.map(this.translateLabels);

    // No value filter will only work now in tables which use manyToMany middleware
    // because of this issue of
    // loopback postgres connector https://github.com/strongloop/loopback-connector-postgresql/issues/340
    // null value sent here won't be parsed correctly and converted to proper sql, thus -1 is sent
    const options = noValueLabel
      ? displayValues.concat({
          label: <FormattedMessage id={noValueLabel} />,
          value: -1,
        })
      : displayValues;

    return (
      <Container onClick={this.onContainerClick}>
        <CheckboxGroup options={options} value={value} onChange={this.onChange} />
      </Container>
    );
  }
}

export default SelectFilter;
