import { Children, ComponentType, ReactNode } from "react";

export const noChildren = (children: ReactNode) => Children.count(children) === 0;
export const isFunction = <T extends Function>(value: any): value is T =>
  typeof value === "function";
export const getComponentName = (component: ComponentType<any>) =>
  component.displayName || (component as any).name;
export const getHOCName = (hocName: string, component: ComponentType<any>) =>
  `${hocName}(${getComponentName(component)})`;

export function provideDisplayName(prefix: string, Component: React.ComponentType) {
  const componentName = Component.displayName || Component.name;

  return componentName ? `${prefix}(${componentName})` : prefix;
}

export const withDefaultProps = <P extends object, DP extends Partial<P> = Partial<P>>(
  defaultProps: DP,
  Cmp: ComponentType<P>,
) => {
  type RequiredProps = ConditionalOmit<P, keyof DP>;
  type Props = Partial<DP> & Required<RequiredProps>;

  Cmp.defaultProps = defaultProps;

  return Cmp as ComponentType<any> as ComponentType<Props>;
};
