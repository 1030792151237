import * as React from "react";
import { Space, InputNumber, Button, Progress, Modal, TimePicker, Radio, Skeleton } from "antd";
import styled from "@emotion/styled";
import moment from "moment";
import { useEffect } from "react";
import { API } from "@app/services/api/api";
import {
  RemovingScreenshotsStats,
  GlobalSettingsAutoScreenshotsCleanup,
  WebsocketMessageTypes,
  WebsocketData,
} from "@ea/shared_types/types";
import { FormattedMessage, InjectedIntlProps } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { CheckboxOptionType } from "antd/lib/checkbox/Group";
import { COLORS } from "@ea/shared_components/styles/consts";
import { toast } from "react-toastify";
import { registerWebsocketHandler } from "../common/websocket";

const Container = styled.div({
  margin: "15px",
  width: "100%",
  height: "100%",
});
const LoaderText = styled.span({
  color: COLORS.BLUE1,
});
const ErrorText = styled.span({
  color: COLORS.DARK_RED,
});

enum CleanupScreenshotsModes {
  repeat = "repeat",
  once = "once",
}

const { useState } = React;

const ProcessingSection: React.FunctionComponent<{
  onProcessingChange: (isProcessing: boolean) => void;
}> = ({ onProcessingChange }) => {
  const [statsConfig, setStatsConfig] = useState<RemovingScreenshotsStats>({
    isProcessing: false,
    stats: { done: 0, total: 0 },
    days: 0,
  });

  useEffect(() => {
    async function getInitState() {
      const response = await API.getScreenshotsStats(undefined);
      setStatsConfig(response);

      if (statsConfig.isProcessing !== response.isProcessing) {
        onProcessingChange(response.isProcessing);
      }
    }

    const unregister = registerWebsocketHandler(
      WebsocketMessageTypes.GLOBAL_ACTIONS_SCREENSHOTS_CLEANUP_UPDATE,
      (value: WebsocketData[WebsocketMessageTypes.GLOBAL_ACTIONS_SCREENSHOTS_CLEANUP_UPDATE]) => {
        if (value.stats.hasBeenCompleted) {
          toast.info(
            <FormattedMessage
              id={getTranslationKey("globalActions", "screenshots", "info", "complete")}
            />,
          );
        }

        if (value.stats.error) {
          toast.error(value.stats.error);
        }

        setStatsConfig(value.stats);
      },
    );

    getInitState();

    return () => unregister();
  }, []);

  useEffect(() => {
    onProcessingChange(statsConfig.isProcessing);
  }, [statsConfig.isProcessing, onProcessingChange]);

  return (
    <>
      {statsConfig.isProcessing && (
        <div>
          <div>
            <FormattedMessage id={getTranslationKey("globalActions", "screenshots", "progress1")} />
            {" " + statsConfig.stats.total + " "}
            <FormattedMessage id={getTranslationKey("globalActions", "screenshots", "progress2")} />
            {" " + statsConfig.days + " "}
            <FormattedMessage id={getTranslationKey("globalActions", "screenshots", "progress3")} />
          </div>
          <Progress
            percent={Math.round((statsConfig.stats.done * 100) / statsConfig.stats.total)}
          />
        </div>
      )}
    </>
  );
};

export const GlobalActionsContainer: React.FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  // todo: antd_upgrade we need to upgrade antd to change this any to number | string
  const [oneTimeModeDays, setOneTimeModeDays] = useState<any>(30);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [mode, setMode] = useState<CleanupScreenshotsModes>(CleanupScreenshotsModes.once);
  const [error, setError] = useState<string | null>(null);
  const [screenshotsAutoModeData, setScreenshotsAutoModeData] =
    useState<GlobalSettingsAutoScreenshotsCleanup>({
      days: 30,
      startTime: new Date(),
      enabled: false,
    });

  useEffect(() => {
    async function getScreenshotsAutoCleanup() {
      try {
        const response = await API.getScreenshotsAutoCleanup(undefined);
        const stats = await API.getScreenshotsStats(undefined);

        setIsProcessing(stats.isProcessing);
        setScreenshotsAutoModeData(response);

        setIsLoaded(true);

        if (response.enabled) {
          setMode(CleanupScreenshotsModes.repeat);
        }
      } catch (err) {
        setError(err);
      }
    }

    getScreenshotsAutoCleanup();
  }, []);

  async function buttonClick() {
    try {
      if (mode === CleanupScreenshotsModes.once) {
        const count = await API.deleteScreenshots({ days: oneTimeModeDays });

        toast.info(
          `${intl.formatMessage({
            id: getTranslationKey("globalActions", "screenshots", "action", "deleteOnce1"),
          })}${count}${intl.formatMessage({
            id: getTranslationKey("globalActions", "screenshots", "action", "deleteOnce2"),
          })}`,
        );
      } else {
        const newData = {
          ...screenshotsAutoModeData,
          enabled: !screenshotsAutoModeData.enabled,
        };
        await API.switchScreenshotsAutoCleanup(newData);
        setScreenshotsAutoModeData(newData);
      }
    } catch (err) {
      setError(err);
    }
  }

  function showConfirmModal() {
    Modal.confirm({
      title: intl.formatMessage({
        id: getTranslationKey("globalActions", "screenshots", "modal", "title"),
      }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({
        id: getTranslationKey("globalActions", "screenshots", "modal", "content"),
      }),
      okText: intl.formatMessage({
        id: getTranslationKey("globalActions", "screenshots", "modal", "okText"),
      }),
      cancelText: intl.formatMessage({
        id: getTranslationKey("globalActions", "screenshots", "modal", "cancelText"),
      }),
      onOk: async () => {
        buttonClick();
      },
    });
  }

  const radioOptions: CheckboxOptionType[] = [
    {
      label: (
        <FormattedMessage id={getTranslationKey("globalActions", "screenshots", "mode", "once")} />
      ),
      value: CleanupScreenshotsModes.once,
    },
    {
      label: (
        <FormattedMessage id={getTranslationKey("globalActions", "screenshots", "mode", "every")} />
      ),
      value: CleanupScreenshotsModes.repeat,
    },
  ];

  const buttonLabelKey =
    mode === CleanupScreenshotsModes.once
      ? "remove"
      : screenshotsAutoModeData.enabled
      ? "disable"
      : "enable";
  const format = "HH:mm";
  // todo: upgrade to new antd and add addonAfter={"Days"}
  return (
    <Container>
      {isLoaded ? (
        <Space>
          <FormattedMessage
            id={getTranslationKey("globalActions", "screenshots", "info", "title")}
          />
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={radioOptions}
            onChange={(e) => {
              setMode(e.target.value);
            }}
            value={mode}
          />
          <FormattedMessage
            id={getTranslationKey("globalActions", "screenshots", "info", "older")}
          />
          <InputNumber
            min={1}
            value={
              mode === CleanupScreenshotsModes.repeat
                ? screenshotsAutoModeData.days
                : oneTimeModeDays
            }
            disabled={mode === CleanupScreenshotsModes.repeat && screenshotsAutoModeData.enabled}
            onChange={(value) => {
              if (mode === CleanupScreenshotsModes.repeat) {
                setScreenshotsAutoModeData((prevState) => ({
                  ...prevState,
                  days: value as number,
                }));
              } else {
                setOneTimeModeDays(value);
              }
            }}
          />
          <FormattedMessage
            id={getTranslationKey("globalActions", "screenshots", "info", "days")}
          />
          {mode === CleanupScreenshotsModes.repeat && (
            <>
              <FormattedMessage
                id={getTranslationKey("globalActions", "screenshots", "info", "hour")}
              />
              <TimePicker
                disabled={screenshotsAutoModeData.enabled}
                value={moment(screenshotsAutoModeData.startTime)}
                format={format}
                onChange={(val, str) => {
                  setScreenshotsAutoModeData((prevState) => ({
                    ...prevState,
                    startTime: val?.toDate() || new Date(),
                  }));
                }}
              />
            </>
          )}
          <Button
            danger
            type="primary"
            disabled={isProcessing && mode === CleanupScreenshotsModes.once}
            loading={!isLoaded}
            onClick={() => {
              if (mode === CleanupScreenshotsModes.repeat) {
                buttonClick();
              } else {
                showConfirmModal();
              }
            }}
          >
            {isLoaded ? (
              <FormattedMessage
                id={getTranslationKey("globalActions", "screenshots", "button", buttonLabelKey)}
              />
            ) : (
              <FormattedMessage
                id={getTranslationKey("globalActions", "screenshots", "button", "loading")}
              />
            )}
          </Button>
          <ProcessingSection
            onProcessingChange={(isProcessing2) => setIsProcessing(isProcessing2)}
          />
          <ErrorText>{error}</ErrorText>
        </Space>
      ) : (
        <Space>
          <FormattedMessage
            id={getTranslationKey("globalActions", "screenshots", "info", "title")}
          />
          <Skeleton.Input style={{ width: 100 }} active />
          <Skeleton.Input style={{ width: 150 }} active />
          <Skeleton.Button style={{ width: 70 }} active />
          <LoaderText>
            <FormattedMessage id={getTranslationKey("loadingLabel")!} />
          </LoaderText>
        </Space>
      )}
    </Container>
  );
};
