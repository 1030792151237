import * as React from "react";
import { AggregatedJobLog } from "@ea/shared_types/types";
import { COLORS } from "@app/styles/consts";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { ComposedChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar } from "recharts";
import { getTranslationKey } from "@app/translations/translations.helpers";

interface IAggregatedJobLogsBarChartProps {
  data: AggregatedJobLog[];
  width?: number;
  height?: number;
}

const AggregatedJobLogsBarChart: React.FunctionComponent<
  IAggregatedJobLogsBarChartProps & InjectedIntlProps
> = ({ data, height, width, intl }) => {
  // in database we have successCount, warningCount etc, but in chart wa want SUCCESS, WARNING
  // thats why we are cutting off "Count" part.
  const cutCount = (name) => name.substring(0, name.indexOf("Count"));
  const toolTipDataFormatter = (value, name, props) => [value, `${cutCount(name).toUpperCase()}`];
  const legendFormatter = (value, entry, index) => cutCount(value).toUpperCase();

  return (
    <ComposedChart
      layout="vertical"
      width={width || 600}
      height={height || 400}
      data={data}
      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
    >
      <CartesianGrid stroke={COLORS.GRAY_OVERLAY} />
      <XAxis
        type="number"
        label={{
          value: intl.formatMessage({ id: getTranslationKey("charts", "scriptCount") }),
          position: "insideBottom",
          fontSize: 16,
          height: 20,
          dy: 10,
        }}
        allowDecimals={false}
        domain={["dataMin", "dataMax"]}
      />
      <YAxis
        dataKey="id"
        type="category"
        label={{
          value: intl.formatMessage({ id: getTranslationKey("charts", "jobId") }),
          position: "insideLeft",
          angle: -90,
          fontSize: 16,
        }}
      />
      <Tooltip formatter={toolTipDataFormatter} />
      <Legend
        formatter={legendFormatter}
        wrapperStyle={{
          paddingTop: "15px",
        }}
      />

      <Bar dataKey="successCount" stackId="1" barSize={20} fill={COLORS.STATUS.SUCCESS} />
      <Bar dataKey="warningCount" stackId="1" barSize={20} fill={COLORS.STATUS.WARNING} />
      <Bar dataKey="errorCount" stackId="1" barSize={20} fill={COLORS.STATUS.ERROR} />
    </ComposedChart>
  );
};

export default injectIntl(AggregatedJobLogsBarChart);
