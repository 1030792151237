import { objectValues } from "@ea/shared_components/utils/object";

import * as sequencesActions from "./sequences.actions";
import sequencesEpicsMap from "./sequences.epics";
import * as sequencesReducers from "./sequences.reducers";

const sequencesEpics = objectValues(sequencesEpicsMap);

export { sequencesActions, sequencesReducers, sequencesEpics };

export { default as SequencesTableContainer } from "./SequencesTable.container";
