import { UploadOutlined } from "@ant-design/icons";
import { Step } from "@ea/shared_types/next/ea.types";
import { EAFile } from "@ea/shared_types/types";
import { Button, Upload } from "antd";
import { UploadProps } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import React from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { getTranslationKey } from "../../AssignVariablePicker/toMigrate";
import { downloadStepFile } from "../../helpers/file";
import { CommonAPI } from "../../services/api.common";
import createFormField from "../createFormField";
import withReadonly from "./withReadonly";

const UploadWrapped = (
  props: UploadProps & {
    children?: any;
    buttonName?: string;
    limitFiles?: number;
    values?: Step;
  },
) => {
  return (
    <Upload {...props}>
      {React.Children.count(props.children) ? (
        props.children
      ) : (
        <Button disabled={props.disabled} icon={<UploadOutlined />}>
          {props.buttonName}
        </Button>
      )}
    </Upload>
  );
};

export const UploadField = createFormField(
  withReadonly(UploadWrapped),
  ({ name, onChange, value, onBlur, onFocus, limitFiles, values, ...rest }) => ({
    ...rest,
    name,
    onChange: (data) => {
      onChange(limitFiles ? data.fileList.slice(-limitFiles) : data.fileList);
    },
    onPreview: async (file) => {
      try {
        const typedFile = (file as any).eaProps as EAFile;
        await downloadStepFile(values!.id, typedFile.originalFilename);
      } catch (err) {
        console.error(err);
        toast.error(<FormattedMessage id={getTranslationKey("messages", "error", "fileUpload")} />);
      }
    },
    customRequest: async ({ file, onError, onSuccess }) => {
      try {
        const response = await CommonAPI.uploadTemporaryFile({ files: [file] });
        onSuccess(response, file);
      } catch (err) {
        onError(err);
      }
    },
    fileList: value,
    onBlur: onBlur as any,
    onFocus: onFocus as any,
    autoComplete: "off",
  }),
  (intl) => ({
    customParse: (value) => {
      const merged = [].concat.apply(
        value
          .filter((v) => !v.response)
          .map((v) => {
            return {
              ...v.eaProps,
              name: v.uid,
              originalFilename: v.name,
              status: v.status,
              path: v.path,
            };
          }),
        value.map((v) => v.response).filter((v) => v),
      );
      return merged;
    },
    customFormat: (v: EAFile[]) => {
      if (!v) {
        return v;
      }

      return v.map((s) => {
        const { name, originalFilename } = s;
        return {
          uid: name,
          name: originalFilename,
          // download logic is in onPreview
          url: "fake url to make file downloadable",
          // we tmp keep status to show loading icon, its only for internal control use
          status: (s as any).status,
          eaProps: {
            ...s,
          },
        };
      });
    },
    customValidate: (value: UploadFile<any>[] | undefined) => {
      if (!value) {
        return;
      }
      const isUploading = value.find((v) => v.status === "uploading");
      const isError = value.find((v) => v.status === "error");

      const validationMessages: any[] = [];

      if (isUploading) {
        validationMessages.push(
          intl.formatMessage({ id: getTranslationKey("messages", "info", "uploadingFiles") }),
        );
      }

      if (isError) {
        validationMessages.push(
          intl.formatMessage({ id: getTranslationKey("messages", "error", "fileUpload") }),
        );
      }

      return validationMessages.length ? validationMessages.join("\n") : undefined;
    },
  }),
);

export default UploadField;
