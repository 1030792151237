export const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);
export const toSeconds = (miliseconds: string | number) => {
  const mili = Number(miliseconds);
  const sec = mili / 1000;
  return Math.round(sec * 100) / 100;
};

export const camelCaseToRegular = (text: String) =>
  text.replace(/([A-Z])/g, " $1").replace(/^./, (words) => {
    return words.toUpperCase();
  });

export const scriptNameToLinkName = (text: string) => text.replace(/[\W_\s]+/g, "");

export const unescapeSpecialCharacters = (text) => {
  return text && text.replace(/\\(.)/g, "$1");
};
