import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { Log } from "@ea/shared_types/types";
import { TableActionParams } from "@ea/shared_components/redux/common.models";

const actionCreator = actionCreatorFactory("LOGS_MODULE_2");

export const moduleActions = createDataModuleActions<Log>(actionCreator);

const otherTableActions = {
  setIsGeneratingReport:
    actionCreator<TableActionParams<{ isGeneratingReport: boolean }>>("SET_GENERATING_REPORT"),
};

export const getLogsTableActions = createTableActionsGetter(
  normalizeActions({ ...moduleActions.table, ...otherTableActions }),
);
export const logsActions = {
  ...normalizeActions(moduleActions.data),
  ...otherTableActions,
};
export const logsActionCreators = {
  ...moduleActions,
  ...otherTableActions,
};
