import { createModuleEpics } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { SchedulerJobMapping } from "@ea/shared_types/types";
import { moduleActions } from "./schedulerFlow.actions";
import { schedulerFlowDataSelectors } from "./schedulerFlow.selectors";
import { SCHEDULER_FLOW_COLUMNS_CONFIG } from "./schedulerFlow.table";

const DEFAULT_ORDER = ["lineNum ASC"];

const schedulerFlowModuleEpics = createModuleEpics<SchedulerJobMapping>(moduleActions, {
  normalizeItem: (role) => role,
  getRequestParams: (state, tableId) => {
    const tableParams = schedulerFlowDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, SCHEDULER_FLOW_COLUMNS_CONFIG, {
      defaultOrder: DEFAULT_ORDER,
    });
    delete params.filter?.limit;
    delete params.filter?.offset;
    return params;
  },
  getSingleRequestParams: (query) => {
    return query;
  },
  api: {
    createItem: () => {
      throw new Error("Not implemented");
    },
    deleteItem: API.deleteSchedulerFlowItem,
    editItem: () => {
      throw new Error("Not implemented");
    },
    getItems: API.getSchedulerFlowItems,
    getItemsCount: API.getSchedulerFlowItemCount,
  },
});

export default schedulerFlowModuleEpics;
