import * as React from "react";
import styled from "@emotion/styled";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { LOCALIZATION_TABLES_CONFIG, LOCALIZATION_COLUMNS } from "./localization.table";
import { localizationDataSelectors } from "./localization.selectors";
import { getLocalizationTableActions, localizationActions } from "./localization.actions";
import LocalizationCommandBar from "./components/LocalizationCommandBar";
import CreateEditLocalization from "./CreateEditLocalization.container";
import EditTranslations from "./EditTranslations.container";
import { API } from "@app/services/api/api";
import ImportLocalizationContainer from "./ImportLocalization.container";
import { DEFAULT_LOCALE } from "@ea/shared_types/types";
import { downloadFileBlob } from "@ea/shared_components/helpers/file";

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

interface ILocalizationTableProps {}

interface ILocalizationTableState extends ModalVisibility {
  isExporting: boolean;
}

interface ModalVisibility {
  editWindowVisibility: boolean;
  createWindowVisibility: boolean;
  editTranslationsVisibility: boolean;
  importWindowVisibility: boolean;
}

class LocalizationTableContainer extends React.Component<IConnectProps, ILocalizationTableState> {
  connectedTable: any;

  constructor(props: IConnectProps) {
    super(props);

    this.state = {
      isExporting: false,
      editWindowVisibility: false,
      createWindowVisibility: false,
      editTranslationsVisibility: false,
      importWindowVisibility: false,
    };
  }

  componentDidUpdate(prevProps: ILocalizationTableProps) {}

  openModal = (modal: keyof ModalVisibility) => () => {
    this.setState<never>({
      [modal]: true,
    });
  };

  closeModal = (modal: keyof ModalVisibility) => () => {
    this.setState<never>({
      [modal]: false,
    });
    this.reload();
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
  };

  export = async () => {
    const { selected, selectedLocalization } = this.props;

    if (selected && selected.length > 0) {
      this.setState({ isExporting: true });

      const blob = await API.exportTranslations({ id: selected });

      if (selectedLocalization) {
        downloadFileBlob(blob, `${selectedLocalization.locale}.json`);
      } else {
        downloadFileBlob(blob, `Locales.zip`);
      }

      this.setState({ isExporting: false });
    }
  };

  isDefaultLocale = () => {
    const { selectedLocalization } = this.props;
    return selectedLocalization && selectedLocalization.locale === DEFAULT_LOCALE;
  };

  render() {
    const {
      editWindowVisibility,
      createWindowVisibility,
      editTranslationsVisibility,
      importWindowVisibility,
      isExporting,
    } = this.state;
    const { selected, selectedLocalization } = this.props;
    const isDefaultLocale = this.isDefaultLocale();
    return (
      <Container>
        <LocalizationCommandBar
          onRemoveClick={this.remove}
          selected={selected}
          onEditClick={this.openModal("editWindowVisibility")}
          onEditTranslationsClick={this.openModal("editTranslationsVisibility")}
          onNewClick={this.openModal("createWindowVisibility")}
          onImport={this.openModal("importWindowVisibility")}
          onExport={this.export}
          onReload={this.reload}
          isExporting={isExporting}
          isDefaultLocale={isDefaultLocale}
        />
        <CreateEditLocalization
          visibility={createWindowVisibility || editWindowVisibility}
          onClose={
            editWindowVisibility
              ? this.closeModal("editWindowVisibility")
              : this.closeModal("createWindowVisibility")
          }
          onCreate={this.reload}
          selected={selectedLocalization}
          isEdit={editWindowVisibility}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={LOCALIZATION_COLUMNS}
          tableId={LOCALIZATION_TABLES_CONFIG.MAIN.id()}
          preferencesId={LOCALIZATION_TABLES_CONFIG.MAIN.preferencesId}
          stateKey={"localization"}
          tableActions={getLocalizationTableActions}
        />
        <EditTranslations
          isDefaultLocale={isDefaultLocale}
          locale={selectedLocalization?.locale}
          localizationId={selectedLocalization && selectedLocalization.id}
          visible={editTranslationsVisibility}
          onClose={this.closeModal("editTranslationsVisibility")}
        />
        <ImportLocalizationContainer
          visibility={importWindowVisibility}
          onClose={this.closeModal("importWindowVisibility")}
          onReload={this.reload}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: ILocalizationTableProps) => ({
  actions: {
    ...bindActionCreators(localizationActions, dispatch),
    ...bindActionCreators(
      getLocalizationTableActions(LOCALIZATION_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ),
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: ILocalizationTableProps) => ({
    ...props,
    selected: localizationDataSelectors.getSelectedSelector(
      state,
      LOCALIZATION_TABLES_CONFIG.MAIN.id(),
    ),
    selectedLocalization: localizationDataSelectors.getSelectedItemSelector(
      state,
      LOCALIZATION_TABLES_CONFIG.MAIN.id(),
    ),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(LocalizationTableContainer);
