import * as React from "react";

import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { Guard, CONDITIONAL_TYPE } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";

export const GUARDS_COLUMNS_CONFIG: PlainObject<CustomColumn<Guard>> = {
  id: {
    label: "table.id",
    dataIndex: "id",
  },
  name: {
    dataIndex: "name",
    label: "table.name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  type: {
    label: "table.type",
    dataIndex: "type",
    sortable: true,
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        { label: getTranslationKey("conditionals", "SUCCESS"), value: CONDITIONAL_TYPE.SUCCESS },
        {
          label: getTranslationKey("conditionals", "INTERRUPTION"),
          value: CONDITIONAL_TYPE.INTERRUPTION,
        },
      ],
    },
  },
};

export const GUARDS_COLUMNS: ColumnConfig<Guard>[] = [
  {
    props: GUARDS_COLUMNS_CONFIG.id,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: GUARDS_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "40%",
    },
  },
  {
    props: GUARDS_COLUMNS_CONFIG.type,
    frameworkProps: {
      width: "40%",
    },
  },
];

export const GUARDS_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_GUARDS",
  },
};
