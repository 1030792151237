export default class EaError extends Error {
  type: string = "";
  constructor(...args: any[]) {
    super(...args);
    Error.captureStackTrace(this, EaError);
    this.constructor = EaError;
    (this as any).__proto__ = EaError.prototype;
    this.name = "";
  }
}
