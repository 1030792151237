import { Log, ReportExtension } from "@ea/shared_types/types";
import { sanitizeScriptNameToFilename } from "./transformations";

export const generateReportName = (
  { scriptId, scriptName }: Partial<Log>,
  extension: ReportExtension,
) =>
  `${
    scriptName ? sanitizeScriptNameToFilename(scriptName) : "script"
  }_${scriptId}_report.${extension}`;
