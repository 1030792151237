import { getTranslationKey } from "@app/translations/translations.helpers";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { UserGroup, VirtualUserPool } from "@ea/shared_types/types";

export const VIRTUAL_USER_POOL_COLUMNS_CONFIG: PlainObject<CustomColumn<VirtualUserPool>> = {
  id: {
    label: getTranslationKey("table", "id"),
    dataIndex: "id",
  },
  name: {
    dataIndex: "name",
    label: getTranslationKey("table", "name"),
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  description: {
    dataIndex: "description",
    label: getTranslationKey("table", "description"),
  },
};

export const VIRTUAL_USER_POOL_COLUMNS: ColumnConfig<UserGroup>[] = [
  {
    props: VIRTUAL_USER_POOL_COLUMNS_CONFIG.id,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: VIRTUAL_USER_POOL_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "25%",
    },
  },
  {
    props: VIRTUAL_USER_POOL_COLUMNS_CONFIG.description,
    frameworkProps: {
      width: "75%",
    },
  },
];

export const VIRTUAL_USER_POOL_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_VIRTUAL_USER_POOL",
  },
  ALL: {
    id: () => "ALL",
    preferencesId: "VIRTUAL_USER_POOL_ALL",
  },
  PROJECT_ASSIGNMENT_DISPLAY: {
    id: (id: number | string) => `PROJECT_ASSIGNMENT_DISPLAY_${id}`,
    preferencesId: "VIRTUAL_USER_POOL_PROJECT_ASSIGNMENT",
  },
  PROJECT_ASSIGNMENT: {
    id: (id: number | string) => `PROJECT_ASSIGNMENT_${id}`,
    preferencesId: "VIRTUAL_USER_POOL_PROJECT_ASSIGNMENT_DISPLAY",
  },
};
