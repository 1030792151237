import classNames from "classnames";
import { css } from "@emotion/core";
import hoistNonReactStatics from "hoist-non-react-statics";
import * as React from "react";

import { COLORS } from "../../styles/consts";
import { getHOCName } from "../../utils/helpers";

type InjectedProps = {
  disabled?: boolean;
  className?: string;
  placeholder?: any;
  ref?: any;
};

type OwnProps = {
  readOnly?: boolean;
  placeholder?: any;
};

const readonlyClass = css({
  cursor: "default",
  color: COLORS.ANTD_DISABLED_TEXT_COLOR,
}).toString();

const withReadonly = <OriginalProps extends object>(
  Component: React.ComponentType<OriginalProps & InjectedProps>,
) => {
  type Props = ConditionalOmit<OriginalProps, keyof InjectedProps> & OwnProps;
  class WithReadonlyElement extends React.Component<Props> {
    static displayName = getHOCName((WithReadonlyElement as any).displayName, Component);
    static WrappedComponent = Component;

    render() {
      const { readOnly, forwardedRef, validationError, hoverColor, className, ...rest } = this
        .props as any;

      if (!readOnly) {
        return <Component {...rest} readOnly={readOnly} ref={forwardedRef} />;
      }
      return (
        <Component
          {...rest}
          readOnly={readOnly}
          disabled
          className={classNames(className, readonlyClass)}
          ref={forwardedRef}
        />
      );
    }
  }

  const WithReadonlyElementComplete = hoistNonReactStatics(
    WithReadonlyElement,
    Component as any,
  ) as React.ComponentType<Props>;

  return React.forwardRef((props, ref) => (
    <WithReadonlyElementComplete {...props} {...({ forwardedRef: ref } as any)} />
  )) as typeof WithReadonlyElementComplete;
};

export default withReadonly;
