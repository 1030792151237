import React, { Component } from "react";
import { GANTT_ZOOM } from "../gantt.types";

interface IToolBarProps {
  onZoomChange: (arg1: any) => void;
  zoom: GANTT_ZOOM;
}
const zoomValues = Object.values(GANTT_ZOOM);
export default class Toolbar extends Component<IToolBarProps> {
  handleZoomChange = (e) => {
    if (this.props.onZoomChange) {
      this.props.onZoomChange(e.target.value);
    }
  };

  render() {
    const zoomRadios = zoomValues.map((value) => {
      const isActive = this.props.zoom === value;
      return (
        <label key={value} className={`radio-label ${isActive ? "radio-label-active" : ""}`}>
          <input type="radio" checked={isActive} onChange={this.handleZoomChange} value={value} />
          {value}
        </label>
      );
    });

    return (
      <div className="tool-bar">
        <b>Zoom: </b>
        {zoomRadios}
      </div>
    );
  }
}
