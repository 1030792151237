import { Button, Modal } from "antd";
import * as React from "react";

interface IChartModalProps {
  visible: boolean;
  onClose: () => void;
  title: string | React.ReactNode;
}

const ChartModal: React.FunctionComponent<IChartModalProps> = ({
  visible,
  onClose,
  title,
  children,
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      closable={false}
      width={"80vw"}
      footer={[
        <Button key="close" type="primary" onClick={onClose}>
          Close
        </Button>,
      ]}
      bodyStyle={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {children}
    </Modal>
  );
};

export default ChartModal;
