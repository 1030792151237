import { Component } from "react";
import { GanttTask as GanttTaskType } from "../gantt.types";
import { GanttStatic } from "@ea/dhtmlx-gantt";

interface IGanttTaskProps {
  gantt: GanttStatic;
  task: GanttTaskType;
}
interface IGanttTaskState {
  task: GanttTaskType;
}

class GanttTask extends Component<IGanttTaskProps, IGanttTaskState> {
  componentWillUnmount() {
    try {
      const { gantt } = this.props;
      const { id } = this.props.task;
      if (id) {
        gantt.deleteTask(id);
      }
    } catch (e) {}
  }

  shouldComponentUpdate(nextProps) {
    const { text, description, runParams, start_date } = this.props.task;
    const { task } = nextProps;

    return (
      text !== task.text ||
      description !== task.description ||
      start_date?.toISOString() !== task.start_date?.toISOString() ||
      runParams.repeater?.repeaterMultiplier !== task.runParams.repeater?.repeaterMultiplier ||
      runParams.repeater?.repeaterType !== task.runParams.repeater?.repeaterType
    );
  }
  componentDidUpdate(prevProps) {
    const { task, gantt } = this.props;
    let newTask: any = task;

    if (prevProps.task?.start_date?.toISOString() !== (task?.start_date as any)?.toISOString()) {
      newTask = {
        ...task,
        end_date: task?.start_date ? (gantt.calculateEndDate(task) as any) : undefined,
      };
    }
    gantt.updateTask(task.id, newTask);
  }

  render() {
    return null;
  }
}

export default GanttTask;
