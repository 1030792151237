import { createDataSelectors, ApplicationState } from "@app/modules/app.reducers";
import { Step, RunnerState, EXECUTION_STATE } from "@ea/shared_types/types";
import { createSelector } from "reselect";
import { CoreCommandsIds } from "@ea/shared_types/core.commands.types";
import { ExecutionStep } from "@ea/shared_types/runner.common.types";
import { buildStepTree, isRootStep } from "./runner.helpers";
import { findStep } from "@ea/shared_types/utils/runner.data";

export const runnerDataSelectors = createDataSelectors<ExecutionStep>()("runner");
export const getSessionParams = (state: ApplicationState, sessionId: string) =>
  runnerDataSelectors.getParamsSelector(state, sessionId) as any as RunnerState;
export const getRunnerSelected = createSelector(
  runnerDataSelectors.getSelectedSelector,
  (selected) =>
    selected.reduce((map: any, obj: any) => {
      map[obj] = obj;
      return map;
    }, {}),
);
export const getNotSelectableIds = createSelector(runnerDataSelectors.getDataSelector, (steps) =>
  Object.keys(steps)
    .filter(
      (id) =>
        steps[id].commandId === CoreCommandsIds.start ||
        steps[id].commandId === CoreCommandsIds.end,
    )
    .map((id) => steps[id].id),
);

export const getRunnerMode = createSelector(getSessionParams, (params: RunnerState) => params.mode);

export const isDirtySelector = createSelector(
  getSessionParams,
  (params: RunnerState) => !!params.isDirty,
);

export const isSyncingSelector = createSelector(
  getSessionParams,
  (params: RunnerState) => !!params.syncing,
);

export const isSomethingNotSynced = createSelector(
  runnerDataSelectors.getOrderedDataSelector,
  (steps) => steps.some((s) => s.synced === false),
);

export const syncErrorSelector = createSelector(
  getSessionParams,
  (params: RunnerState) => params.syncError,
);

export const isNestedStepSelectedSelector = createSelector(
  runnerDataSelectors.getSelectedItemsSelector,
  (selected) => selected.some((step) => !isRootStep(step)),
);

export const getAllLinkedScriptsIds = createSelector(
  runnerDataSelectors.getOrderedDataSelector,
  (steps) => steps.filter((step) => !!step.linkedScriptId).map((step) => step.linkedScriptId!),
);

export const getStepsTreeData = createSelector(
  runnerDataSelectors.getOrderedDataSelector,
  getSessionParams,
  (steps, sessionParams) => buildStepTree(steps, sessionParams),
);

export const getRootSteps = createSelector(
  runnerDataSelectors.getOrderedDataSelector,
  getSessionParams,
  (steps, sessionParams) => buildStepTree(steps, sessionParams, true),
);

export const getRecordedStep = createSelector(
  getRootSteps,
  getSessionParams,
  (steps, sessionParams) => {
    return steps.find((s) => s.execution.path === sessionParams.recorder.recordingPath);
  },
);

export const stepsToTreeSelectorsModifier = (runnerSelectors: any) => {
  runnerSelectors.getOrderedDataSelector = getStepsTreeData;

  return runnerSelectors;
};

export const getPlayMode = createSelector(
  getSessionParams,
  (sessionParams) => sessionParams.playMode,
);

export const getPlayerState = createSelector(
  getSessionParams,
  (sessionParams) => sessionParams.player?.state,
);

export const getRecordingPath = createSelector(
  getSessionParams,
  (sessionParams) => sessionParams.recorder?.recordingPath,
);

export const getLastRecorderStep = createSelector(
  runnerDataSelectors.getOrderedDataSelector,
  getRecordingPath,
  (steps, recordingPath) => {
    return steps.find((s) => s.execution.path === recordingPath);
  },
);

export const getCurrentPlayingStep = createSelector(
  runnerDataSelectors.getOrderedDataSelector,
  getSessionParams,
  (steps, sessionParams) => {
    const path = (sessionParams.flatSteps || [])[sessionParams.player.position]?.path;
    if (!path) {
      return undefined;
    }
    return findStep(steps, path);
  },
);

export const getPlayerScriptData = createSelector(
  getSessionParams,
  (sessionParams) => sessionParams.player?.script,
);
