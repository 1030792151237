import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { NumberSequence } from "@ea/shared_types/next/ea.types";
import { moduleActions } from "./sequences.actions";

const initialState = {
  ...getDataReducerInitialState<NumberSequence>(),
};

export type SequencesReducer = typeof initialState;

const dataReducer = createDataReducer<NumberSequence>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
