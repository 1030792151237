import { objectValues } from "@ea/shared_components/utils/object";

import * as allEpics from "./auth.epics";
import * as authReducer from "./auth.reducers";

export { default as PrivateRouteContainer } from "./PrivateRoute.container";
export { default as PublicRouteContainer } from "./PublicRoute.container";
export { default as LoginContainer } from "./Login.container";

export { authReducer };

export const authEpics = objectValues(allEpics);
