import styled from "@emotion/styled";
import { Spin } from "antd";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { scriptsActions } from "@app/modules/scripts/scripts.actions";
import { getScriptWithBasicVariablesSelector } from "@app/modules/scripts/scripts.selectors";
import { API } from "@app/services/api/api";
import { GlobalVariable } from "@ea/shared_types/types";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { getTranslationKey } from "@app/translations/translations.helpers";
import {
  GLOBAL_VARIABLES_TABLES_CONFIG,
  GLOBAL_VARIABLES_COLUMNS,
} from "@app/modules/globalVariables/globalVariables.table";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import CommandBar from "./components/AssignGlobalVariablesCommandBar";
import CreateEditGlobalVariableContainer from "../globalVariables/CreateEditGlobalVariable.container";
import { getGlobalVariablesTableActions } from "@app/modules/globalVariables/globalVariables.actions";
import { globalVariablesDataSelectors } from "@app/modules/globalVariables/globalVariables.selectors";
import { getGlobalVariablesIds } from "@app/modules/scripts/scripts.selectors";
import AssignGlobalVariablesPanel from "./components/AssignGlobalVariablesPanel.container";
import { isSame } from "@ea/shared_components/utils/array";
import { runnerCommunicator } from "@ea/shared_types/utils/iframes.communication";
import { IFrameRunnerAsyncMessages } from "@ea/shared_types/communication.types";
import { LiveVariablesModifyData } from "@ea/shared_types/runner.common.types";
import { PlayerVariables } from "@ea/shared_types/runner.common.types";
import { bindActionCreators } from "redux";
import { getRunnerTableActions } from "../runner/runner.actions";
import { getSessionParams } from "../runner/runner.selectors";
import { GlobalVariableTypes } from "@ea/shared_types/next/ea.types";
interface IScriptGlobalVariablesContainerProps {
  scriptId: number;
  sessionId?: string;
}
interface IScriptGlobalVariablesContainerState {
  persistentQuery?: { id: { inq: number[] } | null };
  openedPanel?: Panels;
}

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

enum Panels {
  CREATE_AND_ASSIGN = "createAndAssign",
  ASSIGN = "assign",
}

class ScriptGlobalVariablesContainer extends React.Component<
  IScriptGlobalVariablesContainerProps & IConnectProps,
  IScriptGlobalVariablesContainerState
> {
  connectedTable: any;
  state = { persistentQuery: undefined, openedPanel: undefined };
  componentDidMount() {
    this.setPersistentQuery();
  }
  componentDidUpdate(prevProps: IConnectProps) {
    const { globalVariablesIds, sessionId } = this.props;
    const changed = !isSame(prevProps.globalVariablesIds, globalVariablesIds);
    if (changed) {
      this.setPersistentQuery();
    }
    if (sessionId && changed) {
      const newIds = globalVariablesIds.filter(
        (gv) => prevProps.globalVariablesIds.indexOf(gv) === -1,
      );
      const removedIds = prevProps.globalVariablesIds.filter(
        (gv) => globalVariablesIds.indexOf(gv) === -1,
      );
      const newGlobals = this.props.script?.globalVariables?.filter((gv) => newIds.includes(gv.id));
      const globalsToRemove = prevProps.script?.globalVariables?.filter((gv) =>
        removedIds.includes(gv.id),
      );
      this.syncGlobalVariables({ newGlobals, globalsToRemove });
    }
  }

  syncGlobalVariables = ({
    newGlobals = [],
    globalsToRemove = [],
  }: {
    newGlobals?: GlobalVariable[];
    globalsToRemove?: GlobalVariable[];
  }) => {
    const { liveVariables, runnerActions } = this.props;
    if (runnerActions) {
      const variablesOutput: PlayerVariables = {
        ...liveVariables!,
      };

      const toRemove: LiveVariablesModifyData[] = globalsToRemove.map((g) => ({
        variableId: { variableId: g.id },
        type: g.type,
        actionType: "remove",
      }));
      const toAdd: LiveVariablesModifyData[] = newGlobals.map((ng) => ({
        variableId: { variableId: ng.id },
        type: ng.type,
        value: ng.value,
        name: ng.name,
        actionType: "add",
      }));

      if (newGlobals.length > 0) {
        newGlobals.forEach((gv) => {
          const type = gv.type.toLowerCase();
          if (variablesOutput.global && !variablesOutput.global[type]) {
            variablesOutput.global[type] = {};
          }
          if (variablesOutput.global) {
            variablesOutput.global[type][gv.id] = { name: gv.name, value: gv.value };
          }
        });
      }
      if (globalsToRemove.length > 0) {
        globalsToRemove.forEach((gv) => {
          const type = gv.type.toLowerCase();
          delete variablesOutput.global[type][gv.id];
        });
      }

      runnerCommunicator.administration.send(IFrameRunnerAsyncMessages.modify_variables_state, [
        ...toAdd,
        ...toRemove,
      ]);
      runnerActions.updateLiveVariables({ variables: variablesOutput });
    }
  };

  setPersistentQuery = () => {
    const { globalVariablesIds } = this.props;

    this.setState({
      persistentQuery: {
        id: globalVariablesIds.length > 0 ? { inq: globalVariablesIds } : { inq: [-1] },
      },
    });
  };
  assignGlobalsToScript = async (ids: number[]) => {
    const { scriptId, globalVariablesIds, scriptActions } = this.props;
    const patch: any = {
      id: scriptId,
      globalVariables: [...globalVariablesIds, ...ids],
    };
    try {
      await API.patchScript({ script: patch });
      this.closePanel();
      scriptActions.loadSingle({ id: scriptId });
      this.onReloadClick();
    } catch (e) {
      console.error(e);
      toast.error(
        <FormattedMessage id={getTranslationKey("globalVariable", "errors", "assign")} />,
      );
    }
  };
  closePanel = () => {
    this.setState({
      openedPanel: undefined,
    });
  };

  onAddClick = () => {
    this.setState({
      openedPanel: Panels.ASSIGN,
    });
  };
  onCreateAndAddClick = () => {
    this.setState({
      openedPanel: Panels.CREATE_AND_ASSIGN,
    });
  };

  onCreate = async (values: GlobalVariableTypes["createData"]) => {
    try {
      const result = await API.createGlobalVariable(values);
      if (result) {
        await this.assignGlobalsToScript([result.id]);
      }
    } catch (e) {
      console.log(e);
      toast.error(
        <FormattedMessage id={getTranslationKey("globalVariable", "errors", "create")} />,
      );
    }
  };

  onReloadClick = () => {
    const { scriptId, scriptActions } = this.props;
    scriptActions.loadSingle({ id: scriptId });
  };

  onUnassignClick = async () => {
    const { scriptId, globalVariablesIds, selected, scriptActions } = this.props;
    const newGlobalVariables = globalVariablesIds.filter((id) => selected.indexOf(id) === -1);
    const patch: any = {
      id: scriptId,
      globalVariables: newGlobalVariables,
    };
    try {
      const result = await API.patchScript({ script: patch });
      scriptActions.loadSingle({ id: scriptId });
      this.onReloadClick();
    } catch (e) {
      toast.error(e.message);
    }
  };

  render() {
    const { isLoading, script, selected, selectedVariable, globalVariablesIds, sessionId } =
      this.props;
    const { openedPanel, persistentQuery } = this.state;
    if (isLoading) {
      return (
        <Container>
          <Spin />
        </Container>
      );
    }

    if (!script) {
      return (
        <Container>
          <h3>
            <FormattedMessage id={getTranslationKey("messages", "error", "noScriptSelect")} />
          </h3>
        </Container>
      );
    }

    return (
      <Container>
        <CommandBar
          onAddClick={this.onAddClick}
          onCreateAndAddClick={this.onCreateAndAddClick}
          onUnassignClick={this.onUnassignClick}
          onReloadClick={this.onReloadClick}
          selected={selected}
        />
        <CreateEditGlobalVariableContainer
          visibility={openedPanel === Panels.CREATE_AND_ASSIGN}
          onClose={this.closePanel}
          selected={selectedVariable}
          reload={this.onReloadClick}
          assignGlobalsToScript={this.assignGlobalsToScript}
        />
        <AssignGlobalVariablesPanel
          visibility={openedPanel === Panels.ASSIGN}
          scriptId={this.props.scriptId}
          onAssign={this.assignGlobalsToScript}
          onClose={this.closePanel}
          globalVariablesIds={globalVariablesIds}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={GLOBAL_VARIABLES_COLUMNS}
          tableId={GLOBAL_VARIABLES_TABLES_CONFIG.SCRIPT_ASSIGNMENT.id(this.props.scriptId)}
          preferencesId={GLOBAL_VARIABLES_TABLES_CONFIG.SCRIPT_ASSIGNMENT.preferencesId}
          stateKey={"globalVariables"}
          tableActions={getGlobalVariablesTableActions}
          persistentQuery={persistentQuery}
        />
      </Container>
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: IScriptGlobalVariablesContainerProps) => ({
    liveVariables: props.sessionId
      ? (getSessionParams(state, props.sessionId).variables as PlayerVariables) || {}
      : undefined,
    script: getScriptWithBasicVariablesSelector(state, props.scriptId),
    scriptId: props.scriptId,
    isLoading: state.scripts.isLoading,
    globalVariablesIds: getGlobalVariablesIds(state, props.scriptId),
    selected: globalVariablesDataSelectors.getSelectedSelector(
      state,
      GLOBAL_VARIABLES_TABLES_CONFIG.SCRIPT_ASSIGNMENT.id(props.scriptId),
    ),
    selectedVariable: globalVariablesDataSelectors.getSelectedItemSelector(
      state,
      GLOBAL_VARIABLES_TABLES_CONFIG.SCRIPT_ASSIGNMENT.id(props.scriptId),
    ),
  }),
  (dispatch, props) => ({
    runnerActions: props.sessionId
      ? bindActionCreators(getRunnerTableActions(props.sessionId), dispatch)
      : undefined,
    scriptActions: {
      ...bindActionCreators(scriptsActions, dispatch),
    },
  }),
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(ScriptGlobalVariablesContainer);
