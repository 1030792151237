import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { Kpi } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("KPIS_MODULE");

export const moduleActions = createDataModuleActions<Kpi>(actionCreator);
export const getKpisTableActions = createTableActionsGetter(normalizeActions(moduleActions.table));
export const kpisActions = {
  ...normalizeActions(moduleActions.data),
};
export const kpisActionCreators = {
  ...moduleActions,
};
