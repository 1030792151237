import "./Panel.css";

import { Modal } from "antd";
import * as React from "react";

export interface IPanelProps {
  visibility: boolean;
  type?: PanelType;
  headerText: React.ReactNode | string;
  onRenderFooterContent: () => React.ReactNode;
  isFooterAtBottom?: boolean;
  bodyStyle?: any;
  customWidth?: number;
}

export enum PanelType {
  XSMALL = 300,
  SMALL = 480,
  MEDIUM = 1000,
  LARGE = 1200,
  XLARGE = 1400,
  MODAL = "MODAL",
}

const AntdPanel: React.FunctionComponent<IPanelProps> = ({
  children,
  visibility,
  type,
  headerText,
  onRenderFooterContent,
  isFooterAtBottom,
  bodyStyle,
  customWidth,
}) => (
  <Modal
    title={headerText}
    style={
      type === PanelType.MODAL ? {} : { float: "right", top: 0, height: "100%", paddingBottom: 0 }
    }
    bodyStyle={
      type === PanelType.MODAL
        ? { padding: 0 }
        : { flexGrow: isFooterAtBottom ? 1 : 0, overflow: "auto", ...bodyStyle }
    }
    visible={visibility}
    footer={onRenderFooterContent()}
    maskStyle={{ backgroundColor: "rgba(255,255,255,.4)" }}
    closable={false}
    width={customWidth ? customWidth : type === PanelType.MODAL ? 400 : type}
  >
    {children}
  </Modal>
);

export default AntdPanel;
