import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { Tag } from "@ea/shared_types/types";
import { moduleActions } from "./tag.actions";

const initialState = {
  ...getDataReducerInitialState<Tag>(),
};

export type TagReducer = typeof initialState;

const dataReducer = createDataReducer<Tag>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
