import { Dropdown, Menu } from "antd";
import * as React from "react";

import CommandBarButton from "./CommandBarButton";
import CommandBarItem from "./CommandBarItem";
import { DownOutlined } from "@ant-design/icons";
import { DataTestIdProp } from "../utils/dataTestHelpers";
interface ICommandBarDropdownProps {
  name: string;
  text: string;
  icon?: string | JSX.Element;
  far?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  loading?: boolean;
  [DataTestIdProp]: string;
}
export class CommandBarDropdown extends React.Component<ICommandBarDropdownProps, any> {
  menu = (items) => (
    <Menu style={{ padding: 0 }}>
      {[].concat.apply(
        [],
        items.map((item) => {
          if (item) {
            if (Array.isArray(item)) {
              return item.map((i) => (
                <Menu.Item key={`menuItem_${i.props.name}`} style={{ padding: 0 }}>
                  {i}
                </Menu.Item>
              ));
            }

            return (
              <Menu.Item key={`menuItem_${item.props.name}`} style={{ padding: 0 }}>
                {item}
              </Menu.Item>
            );
          }
        }),
      )}
    </Menu>
  );
  render() {
    const { far, ...rest } = this.props;
    const children = Array.isArray(rest.children) ? rest.children : [rest.children];
    return (
      <CommandBarItem far={far}>
        <Dropdown overlay={this.menu(children)} trigger={["click"]} disabled={rest.disabled}>
          <CommandBarButton {...rest}>
            <DownOutlined />
          </CommandBarButton>
        </Dropdown>
      </CommandBarItem>
    );
  }
}

export default CommandBarDropdown;
