import * as React from "react";
import { PanelType } from "@ea/shared_components/Panel";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { getTranslationKey } from "@app/translations/translations.helpers";
import GlobalVariablesForm from "./components/GlobalVariablesForm";
import { GlobalVariable } from "@ea/shared_types/types";
import { API } from "@app/services/api/api";
interface ICreateEditGlobalVariablePanelProps {
  visibility: boolean;
  onClose: () => void;
  selected?: GlobalVariable;
  isEdit?: boolean;
  reload: () => void;
  assignGlobalsToScript?: (ids: number[]) => void;
}

class CreateEditGlobalVariableContainer extends React.Component<
  ICreateEditGlobalVariablePanelProps,
  any
> {
  onOk = async (values) => {
    const { isEdit, onClose, reload, assignGlobalsToScript } = this.props;

    if (isEdit) {
      await API.editGlobalVariable(values);
    } else {
      const newGlobal = await API.createGlobalVariable(values);
      if (assignGlobalsToScript && newGlobal) {
        assignGlobalsToScript([newGlobal.id]);
      }
    }
    reload();
    onClose();
  };

  render() {
    const { visibility, onClose, isEdit, selected } = this.props;

    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.SMALL}
        headerText={getTranslationKey("globalVariable", "header", isEdit ? "edit" : "create")}
        onCancelClick={onClose}
        onOkClick={this.onOk}
        key={isEdit ? "Edit" : "Create"}
        cancelButtonText={getTranslationKey("button", "cancel")}
        okButtonText={getTranslationKey("button", isEdit ? "save" : "create")}
        initialValues={isEdit ? selected : undefined}
        render={({ values, change, form }) => (
          <GlobalVariablesForm values={values} change={change} readOnly={isEdit} form={form} />
        )}
      />
    );
  }
}

export default CreateEditGlobalVariableContainer;
