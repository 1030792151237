import { createModuleEpics } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { Documentation } from "@ea/shared_types/types";
import { moduleActions } from "./documentation.actions";
import { documentationDataSelectors } from "./documentation.selectors";
import { DOCUMENTATION_COLUMNS_CONFIG } from "./documentation.table";

const noop: any = () => {};

const apiKeyModuleEpics = createModuleEpics<Documentation>(moduleActions, {
  normalizeItem: (documentation) => documentation,
  getRequestParams: (state, tableId) => {
    const tableParams = documentationDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, DOCUMENTATION_COLUMNS_CONFIG, {
      defaultOrder: ["createdAt DESC"],
    });
    params.filter = {
      ...params.filter,
      include: "TaskScript",
    };
    return params;
  },
  getSingleRequestParams: (params) => {
    params.filter = {
      ...params.filter,
      include: "TaskScript",
    };
    return params;
  },
  api: {
    createItem: noop,
    deleteItem: API.deleteDocumentation,
    editItem: API.editDocumentation as any,
    getItems: API.getDocumentation as any,
    getItemsCount: API.getDocumentationCount,
  },
});

export default apiKeyModuleEpics;
