import styled from "@emotion/styled";
import { Button } from "antd";
import * as React from "react";
import { TableCellProps } from "react-virtualized";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
interface ISelectCheckboxProps {
  onExpand: ({ id, index }: { id: number; index: number }) => void;
}

export const checkContainerClassName = "list-check-container";
const CheckContainer = styled.div(
  checkContainerClassName,
  {},
  ({ isSelected }: { isSelected: boolean }) => ({
    opacity: isSelected ? 1 : 0,
  }),
);

const ExpandButton: React.FunctionComponent<TableCellProps & ISelectCheckboxProps> = (props) => {
  return (
    <Button
      onClick={() => props.onExpand({ id: props.rowData.id, index: props.rowIndex })}
      size="small"
      shape="circle"
    >
      {props.rowData.isExpanded ? <PlusOutlined /> : <MinusOutlined />}
    </Button>
  );
};

export default ExpandButton;
