import * as React from "react";
import styled from "@emotion/styled";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import {
  SCRIPT_CHANGELOG_TABLES_CONFIG,
  SCRIPT_CHANGELOG_COLUMNS,
  SCRIPT_CHANGELOG_COLUMNS_E_SIGNOFF,
} from "./scriptChangelog.table";
import { getScriptChangelogTableActions } from "./scriptChangelog.actions";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { ScriptChangelog, FEATURES } from "@ea/shared_types/types";
import ScriptChangelogSnapshotView from "./ScriptChangelogSnapshotView";
import { ApplicationState } from "../app.reducers";
import { connect, ConnectedProps } from "react-redux";
import { scriptChangelogDataSelectors } from "./scriptChangelog.selectors";
import ScriptChangelogCompare from "./ScriptChangelogCompare";
import { disabledFeaturesSelector } from "../globalSettings/globalSettings.selectors";
import { DataTestIds } from "@app/utils/dataTestIds";

const Wrapper = styled.div({
  width: "100%",
  height: "100%",
});

interface IScriptChangelogProps {
  scriptId: number;
}

interface IScriptChangelogState {
  persistentQuery: any;
  isOpen: boolean;
}

class ScriptChangelogContainer extends React.Component<IConnectProps, IScriptChangelogState> {
  connectedTable: any;

  constructor(props) {
    super(props);

    this.state = {
      persistentQuery: { scriptId: props.scriptId },
      isOpen: false,
    };
  }

  expandedRowRender = (record: ScriptChangelog) => {
    return <ScriptChangelogSnapshotView snapshot={record.snapshot} />;
  };

  shouldRowBeExpandable = (record: ScriptChangelog) =>
    record.snapshot && record.snapshot.steps && record.snapshot.steps.length > 0;

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  compare = () => {
    this.setState({
      isOpen: true,
    });
  };

  closeCompare = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    const { isOpen } = this.state;
    const { selected, disabledFeatures } = this.props;

    return (
      <Wrapper>
        <CommandBar data-testid={DataTestIds.COMMANDBAR_SCRIPT_CHANGELOG}>
          <CommandBarButton
            name="refresh"
            onClick={this.reload}
            text={getTranslationKey("commandBar", "refresh")}
            icon="ReloadOutlined"
            data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
          />
          <CommandBarButton
            name="compare"
            onClick={this.compare}
            text={getTranslationKey("commandBar", "compare")}
            icon="SwapOutlined"
            disabled={!(selected.length === 2)}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_COMPARE}
          />
        </CommandBar>
        <ScriptChangelogCompare
          onClose={this.closeCompare}
          isOpen={isOpen}
          leftChangelog={selected[0]}
          rightChangelog={selected[1]}
        />
        <ConnectedTable
          columnsConfig={
            disabledFeatures.includes(FEATURES.ELECTRONIC_SIGNOFF)
              ? SCRIPT_CHANGELOG_COLUMNS
              : SCRIPT_CHANGELOG_COLUMNS_E_SIGNOFF
          }
          disabledFeatures={disabledFeatures}
          pageable
          tableId={SCRIPT_CHANGELOG_TABLES_CONFIG.SCRIPT.id(this.props.scriptId)}
          preferencesId={SCRIPT_CHANGELOG_TABLES_CONFIG.SCRIPT.preferencesId}
          stateKey="scriptChangelog"
          tableActions={getScriptChangelogTableActions}
          persistentQuery={this.state.persistentQuery}
          expandedRowRender={this.expandedRowRender}
          shouldRowBeExpandable={this.shouldRowBeExpandable}
          setRef={(component) => (this.connectedTable = component)}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: ApplicationState, props: IScriptChangelogProps) => {
  const tableId = SCRIPT_CHANGELOG_TABLES_CONFIG.SCRIPT.id(props.scriptId);

  return {
    ...props,
    selected: scriptChangelogDataSelectors.getSelectedItemsSelector(state, tableId),
    disabledFeatures: disabledFeaturesSelector(state),
  };
};

const connectCreator = connect(mapStateToProps);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(ScriptChangelogContainer);
