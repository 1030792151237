import { DeleteOutlined, FileWordOutlined, UploadOutlined } from "@ant-design/icons";
import { API } from "@app/services/api/api";
import { DataTestIds } from "@app/utils/dataTestIds";
import { TreeSearchField } from "@ea/shared_components/Form/Fields/TreeSearchField";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { PanelType } from "@ea/shared_components/Panel";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { isSame } from "@ea/shared_components/utils/array";
import { DataSourceMetadata } from "@ea/shared_types/next/ea.types";
import { ProjectTreeNode } from "@ea/shared_types/types";
import styled from "@emotion/styled";
import { Button, Tooltip } from "antd";
import { getIn } from "final-form";
import * as React from "react";
import Dropzone from "react-dropzone";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import { getTranslationKey } from "../../../translations/translations.helpers";

interface CreateEditGlobalExcelContainerProps {
  visibility: boolean;
  onClose: () => void;
  onReload: () => void;
  isEdit: boolean;
  selectedExcel?: DataSourceMetadata;
  projects: ProjectTreeNode[];
  projectsChildrenMap: { [key: number]: number[] };
  projectId?: number;
}

interface CreateEditGlobalExcelContainerState {
  files: any[];
  loading: boolean;
  dropping: boolean;
  selectedProjectIds: string[];
}
const DropZoneDiv = styled.div(
  {
    borderRadius: "4px",
    background: "#f4f4f4",
    border: "2px dashed #d9d9d9",
    transition: "border-color .3s",
    width: "400px",
    height: "150px",
    textAlign: "center",
    padding: "16px 0",
    cursor: "pointer",
    "&:hover": {
      background: "#dbdbdb",
      border: "2px dashed #2b2b2b",
    },
  },
  ({ isDragActive }: { isDragActive: boolean }) =>
    isDragActive ? { border: "2px dashed green" } : {},
);
const LoadedFilesContainer = styled.div({
  display: "flex",
  flexDirecion: "column",
  padding: "0 0 0 0px",
});

const LoadedFile = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  margin: "5px",
});

const formItemLayout = {
  labelCol: {
    sm: { span: 12 },
  },
  wrapperCol: {
    sm: { span: 12 },
  },
};

const formItemLayoutTree = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

class CreateEditGlobalExcelContainer extends React.Component<
  CreateEditGlobalExcelContainerProps & InjectedIntlProps
> {
  formRef: any;
  dropzoneRef: any;
  state: CreateEditGlobalExcelContainerState = {
    files: [],
    loading: false,
    dropping: false,
    selectedProjectIds: [],
  };
  componentDidMount() {
    const { selectedExcel, isEdit } = this.props;
    if (selectedExcel && isEdit) {
      this.setState({
        files: [{ name: selectedExcel.originalFilename }],
        selectedProjectIds: selectedExcel.projectIds?.map((a) => `${a}`),
      });
    }
  }
  componentDidUpdate(prevProps: CreateEditGlobalExcelContainerProps) {
    const { selectedExcel, projectId, isEdit, visibility, projectsChildrenMap } = this.props;
    if (selectedExcel && prevProps.selectedExcel?.id !== selectedExcel?.id) {
      this.setState({
        files: [{ name: selectedExcel.originalFilename }],
        selectedProjectIds: selectedExcel.projectIds?.map((a) => `${a}`),
      });
    } else if (!isEdit && projectId && prevProps.visibility !== visibility) {
      this.setState({
        selectedProjectIds: [`${projectId}`, ...projectsChildrenMap[projectId].map((a) => `${a}`)],
      });
    }
  }
  onDrop = async (files: any) => {
    this.setState({ dropping: true });
  };
  onDropAccepted = async (files: any) => {
    this.setState({ files, dropping: false });
  };
  onReject = () => {
    this.setState({ dropping: false });
    toast.warn(<FormattedMessage id={getTranslationKey("messages", "error", "fileUpload")} />);
  };
  onOk = async (values) => {
    const { isEdit, intl } = this.props;
    const { files } = this.state;
    const isNewFile = this.wasNewFileUploaded();

    if (files.length === 0) {
      throw new Error(
        intl.formatMessage({ id: getTranslationKey("messages", "error", "fileRequired") }),
      );
    }

    try {
      if (isEdit) {
        await API.editGlobalExcel({
          datasourceId: values.id,
          projectIds: this.state.selectedProjectIds,
          files: isNewFile ? this.state.files : undefined,
        } as any);
      } else {
        await API.createGlobalExcel({
          files: this.state.files,
          values: { projectIds: this.state.selectedProjectIds },
        } as any);
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      throw error;
    }
    this.setState({ files: [], projectIds: undefined, loading: false });
    this.props.onClose();
    this.props.onReload();
  };
  onDelete = () => {
    this.setState({ files: [] });
  };
  onCancel = () => {
    this.setState({ files: [], selectedProjectIds: [] });
    this.props.onClose();
  };

  onChange = (selected: string[], values) => {
    const { projectsChildrenMap } = this.props;
    if (isSame(selected, getIn(values, "projectIds"))) {
      return;
    }

    let newSelected = [...selected];

    const childSelected = (children, sourceArray) =>
      sourceArray.some((id) => children.includes(Number.parseInt(id, 10)));

    // add project children to selected (if it has any children)
    for (const projectId of selected) {
      const children = projectsChildrenMap[projectId];
      if (
        children?.length > 0 &&
        !childSelected(children, newSelected) &&
        !childSelected(children, this.state.selectedProjectIds)
      ) {
        newSelected = [...newSelected, ...projectsChildrenMap[projectId].map((id) => `${id}`)];
      }
    }
    this.setState({ selectedProjectIds: newSelected });
  };

  renderFileList = () => (
    <LoadedFile>
      <FileWordOutlined />
      <h3 style={{ margin: 15 }}>{this.state.files[0].name}</h3>
      <Tooltip title={<FormattedMessage id={getTranslationKey("button", "delete")} />}>
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() => this.onDelete()}
          data-testid={DataTestIds.BUTTON_DELETE}
        />
      </Tooltip>
    </LoadedFile>
  );
  wasNewFileUploaded = () => this.state.files.length === 1 && !!this.state.files[0].size;
  render() {
    const { visibility, isEdit, selectedExcel, projects } = this.props;
    const { files, dropping, selectedProjectIds } = this.state;

    return (
      <PanelFormFinal
        initialValues={isEdit ? selectedExcel : { projectIds: selectedProjectIds }}
        visibility={visibility}
        panelType={PanelType.SMALL}
        headerText={getTranslationKey("globalExcel", "header", isEdit ? "edit" : "create")}
        cancelButtonText={getTranslationKey("button", "cancel")}
        okButtonText={getTranslationKey("button", isEdit ? "edit" : "create")}
        onCancelClick={this.onCancel}
        allowPrisitineSubmit={this.wasNewFileUploaded()}
        onOkClick={this.onOk}
        render={({ values }) => (
          <>
            <FormLayout {...formItemLayout}></FormLayout>
            <LoadedFilesContainer>
              {files.length > 0 ? (
                this.renderFileList()
              ) : (
                <Dropzone
                  ref={(element) => {
                    this.dropzoneRef = element;
                  }}
                  multiple={false}
                  onDrop={this.onDrop}
                  onDropAccepted={this.onDropAccepted}
                  onDropRejected={this.onReject}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                >
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <DropZoneDiv {...(getRootProps() as any)} isDragActive={isDragActive}>
                      <input {...(getInputProps() as any)} data-testid={DataTestIds.DROPZONE} />
                      <UploadOutlined style={{ fontSize: 50 }} />
                      <p>
                        {!dropping ? (
                          <FormattedMessage
                            id={getTranslationKey("messages", "info", "dropZipZone")}
                          />
                        ) : (
                          <FormattedMessage id={getTranslationKey("loadingLabel")!} />
                        )}
                      </p>
                    </DropZoneDiv>
                  )}
                </Dropzone>
              )}
            </LoadedFilesContainer>
            <FormLayout {...formItemLayoutTree}>
              <TreeSearchField
                label={getTranslationKey("globalExcel", "label", "assign")}
                name="projectIds"
                required
                onChange={(selected) => this.onChange(selected, values)}
                nodes={projects}
                withTags
                multi
                checkStrictly
                values={projects}
                selected={selectedProjectIds}
                defaultExpandedKeys={selectedProjectIds}
              />
            </FormLayout>
          </>
        )}
      />
    );
  }
}

export default injectIntl(CreateEditGlobalExcelContainer);
