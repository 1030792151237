import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";
import {
  faBan,
  faBolt,
  faCopy,
  faHandRock,
  faPaste,
  faPlus,
  faSync,
  faTrash,
  faFileAlt,
  faCheckCircle,
} from "@fortawesome/fontawesome-free-solid";
import { faCircle, faDotCircle } from "@fortawesome/fontawesome-free-regular";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import CommandBarDropdown from "@ea/shared_components/CommandBar/CommandBarDropdown";
import { STEPS_COPY_STORAGE_KEY } from "../steps.utils";
import WithAuthorization from "@app/modules/common/WithAuthorization";
import { ROLES, Step } from "@ea/shared_types/types";
import { CoreCommandsIds } from "@ea/shared_types/core.commands.types";
import { CommandDefinitionWeb } from "@ea/runner_loader/ea.internal.types";
import { PlusCircleOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { DataTestIds } from "@app/utils/dataTestIds";
interface StepsCommandBarProps {
  onRemoveClick: (ids: number[]) => void;
  onReload: () => void;
  selected: number[];
  selectedSteps: Step[];

  openPanel: (id: string) => void;
  createableSteps: CommandDefinitionWeb[];

  isDragEnabled: boolean;
  toggleDragMode: () => void;

  copy: () => void;
  paste: () => void;
  toggleDisable: (value: boolean) => void;
  toggleOptional: (value: boolean) => void;

  scriptId: number;
  reworkDisabled: boolean;
  rework: () => void;

  openTokenPanel: () => void;
  openAssignStepPanel: () => void;
  openAssertStepPanel: () => void;
  openLoginStepPanel: () => void;
  openScriptStepPanel: () => void;
  openShortcutModal: () => void;
}

const AuthorizedCommandBarDropdown = WithAuthorization([], [ROLES.freedocs])(CommandBarDropdown);
const AuthorizedCommandBarButton = WithAuthorization([], [ROLES.freedocs])(CommandBarButton);

const startOrEndSelected = (steps) =>
  steps.find(
    (step) => step.commandId === CoreCommandsIds.start || step.commandId === CoreCommandsIds.end,
  );

class StepsCommandBar extends React.Component<StepsCommandBarProps> {
  public render() {
    const {
      openScriptStepPanel,
      openPanel,
      rework,
      selected,
      onReload,
      onRemoveClick,
      toggleDisable,
      toggleDragMode,
      toggleOptional,
      paste,
      isDragEnabled,
      copy,
      selectedSteps,
      createableSteps,
    } = this.props;

    return (
      <div>
        <CommandBar data-testid={DataTestIds.COMMANDBAR_STEPS}>
          <AuthorizedCommandBarDropdown
            name="new"
            text={getTranslationKey("commandBar", "newStep")}
            icon={<FontAwesomeIcon icon={faPlus} />}
            disabled={selected.length > 1}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_NEW}
          >
            <CommandBarButton
              name="rework"
              onClick={rework}
              text={getTranslationKey("commandBar", "rework")}
              icon={<PlusCircleOutlined style={{ fontSize: 14 }} />}
              size="default"
              data-testid={DataTestIds.COMMANDBAR_BUTTON_REWORK}
            />

            {createableSteps.map((s) => (
              <CommandBarButton
                key={s.id}
                name={s.id}
                onClick={() => openPanel(s.id)}
                text={s.createableManually!.text}
                icon={<FontAwesomeIcon icon={s.createableManually!.icon} />}
                size="default"
                data-testid={DataTestIds.getNewStepButtonTestId(s.id)}
              />
            ))}

            <CommandBarButton
              name="stepScript"
              text={getTranslationKey("commandBar", "script")}
              size="default"
              icon={<FontAwesomeIcon icon={faFileAlt} />}
              onClick={openScriptStepPanel}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_STEP_SCRIPT}
            />
          </AuthorizedCommandBarDropdown>
          <CommandBarButton
            name="remove"
            onClick={() => onRemoveClick(selected)}
            text={getTranslationKey("commandBar", "remove")}
            icon={<FontAwesomeIcon icon={faTrash} />}
            disabled={selected.length === 0}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE}
          />
          <CommandBarButton
            name="refresh"
            onClick={onReload}
            text={getTranslationKey("commandBar", "refresh")}
            icon={<FontAwesomeIcon icon={faSync} />}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
          />
          <AuthorizedCommandBarButton
            name="copy"
            onClick={copy}
            text={getTranslationKey("commandBar", "copy")}
            icon={<FontAwesomeIcon icon={faCopy} />}
            disabled={selected.length === 0 || startOrEndSelected(selectedSteps)}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_COPY}
          />
          <AuthorizedCommandBarButton
            name="paste"
            onClick={paste}
            text={getTranslationKey("commandBar", "paste")}
            icon={<FontAwesomeIcon icon={faPaste} />}
            disabled={
              sessionStorage.getItem(STEPS_COPY_STORAGE_KEY) === undefined || selected.length > 1
            }
            tooltip={
              sessionStorage.getItem(STEPS_COPY_STORAGE_KEY) === undefined
                ? getTranslationKey("messages", "info", "nothingCopied")
                : undefined
            }
            data-testid={DataTestIds.COMMANDBAR_BUTTON_PAST}
          />
          <AuthorizedCommandBarButton
            name="reorder"
            onClick={toggleDragMode}
            text={
              !isDragEnabled
                ? getTranslationKey("commandBar", "reorder")
                : getTranslationKey("commandBar", "stopReorder")
            }
            icon={<FontAwesomeIcon icon={faHandRock} />}
            tooltip={
              (<FormattedMessage id={getTranslationKey("messages", "tooltip", "reorder")} />) as any
            }
            data-testid={DataTestIds.COMMANDBAR_BUTTON_REORDER}
            far
          />
          <AuthorizedCommandBarDropdown
            name="other"
            text={getTranslationKey("commandBar", "quickAction")}
            icon={<FontAwesomeIcon icon={faBolt} />}
            disabled={selected.length === 0 || startOrEndSelected(selectedSteps)}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_OTHER}
          >
            <CommandBarButton
              name="enableSteps"
              onClick={() => toggleDisable(false)}
              text={getTranslationKey("commandBar", "enableSteps")}
              icon={<FontAwesomeIcon icon={faCheckCircle} />}
              size="default"
              data-testid={DataTestIds.COMMANDBAR_BUTTON_ENABLE_STEPS}
            />
            <CommandBarButton
              name="disableSteps"
              onClick={() => toggleDisable(true)}
              text={getTranslationKey("commandBar", "disbaleSteps")}
              icon={<FontAwesomeIcon icon={faBan} />}
              size="default"
              data-testid={DataTestIds.COMMANDBAR_BUTTON_DISABLE_STEPS}
            />
            <CommandBarButton
              name="unsetStepsOptional"
              text={getTranslationKey("commandBar", "unsetStepsOptional")}
              size="default"
              icon={<FontAwesomeIcon icon={faDotCircle} />}
              onClick={() => toggleOptional(false)}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_UNSET_OPTIONAL}
            />
            <CommandBarButton
              name="setStepsOptional"
              text={getTranslationKey("commandBar", "setStepsOptional")}
              size="default"
              icon={<FontAwesomeIcon icon={faCircle} />}
              onClick={() => toggleOptional(true)}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_SET_OPTIONAL}
            />
          </AuthorizedCommandBarDropdown>
        </CommandBar>
      </div>
    );
  }
}

export default StepsCommandBar;
