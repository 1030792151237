import * as React from "react";
import { Modal, Button } from "antd";
import { VirtualUser } from "@ea/shared_types/types";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { COLORS } from "@ea/shared_components/styles/consts";
import { MESSAGE_TYPE } from "@ea/shared_types/types";
import StepMessageIcon from "@ea/shared_components/MessageBar/StepMessageIcon";

interface IErrorAuthBoxProps {
  visible: boolean;
  onClose: () => void;
  selectedVirtualUser: VirtualUser;
}

class ErrorAuthBox extends React.Component<IErrorAuthBoxProps & InjectedIntlProps> {
  render() {
    const { visible, onClose } = this.props;
    return (
      <Modal
        title={this.props.intl.formatMessage({ id: "virtualUsers.authErrorBox" })}
        visible={visible}
        closable={false}
        footer={[
          <Button key="close" type="primary" onClick={onClose}>
            {this.props.intl.formatMessage({ id: "button.close" })}{" "}
          </Button>,
        ]}
        width={"70%"}
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <StepMessageIcon
          messageType={MESSAGE_TYPE.ERROR}
          style={{ color: COLORS.STATUS[MESSAGE_TYPE.ERROR], marginRight: "10px" }}
        />
        {this.props.selectedVirtualUser.errorMessage}
        <br />
      </Modal>
    );
  }
}

export default injectIntl(ErrorAuthBox);
