import { createDataSelectors } from "@app/modules/app.reducers";
import { GlobalVariable, GlobalVariableType } from "@ea/shared_types/types";
import { createSelector } from "reselect";

export const globalVariablesDataSelectors =
  createDataSelectors<GlobalVariable>()("globalVariables");

export const getGlobalMutable = createSelector(
  globalVariablesDataSelectors.getOrderedDataSelector,
  (variables) => {
    if (variables?.length > 0) {
      return variables.filter((v) => v.type === GlobalVariableType.MUTABLE);
    }
    return [];
  },
);
export const getGlobalConstant = createSelector(
  globalVariablesDataSelectors.getOrderedDataSelector,
  (variables) => {
    if (variables?.length > 0) {
      return variables.filter((v) => v.type === GlobalVariableType.CONSTANT);
    }
    return [];
  },
);
