import {
  CONDITIONAL_ACTION_TYPE,
  EXECUTION_STATUS,
  AssertActions,
  DataSourceComparison,
  CheckValueComparison,
  AssertionType,
} from "./types";

// todo: R2.0 is there a better way to define this type?
export type ActivityAction = (
  | {
      type: AssertActions.CONTINUE;
      values: [];
    }
  | {
      type: AssertActions.WARNING;
      values: [string];
    }
  | {
      type: AssertActions.ERROR;
      values: [string];
    }
  | {
      type: AssertActions.JUMP;
      values: [number];
    }
  | {
      // [STEP_ID_TO_JUMP, SHEET_NAME, RESET_ON_LAST]
      type: AssertActions.DATA_SOURCE_AND_JUMP;
      values: [number, string, boolean];
    }
  | {
      type: AssertActions.LOG;
      values: [string];
    }
  | {
      type: AssertActions.REPEAT;
      values: [];
    }
  | {
      type: AssertActions.INCREASE_KPI;
      values: number[];
    }
) & {
  overrideStatus?: boolean;
  overrideStatusTo?: EXECUTION_STATUS;
};

export type Comparison = DataSourceComparison | CheckValueComparison;

export function isDataSourceComparison(comparison: Comparison): comparison is DataSourceComparison {
  return comparison.type === AssertionType.DATA_SOURCE;
}

export type SimpleActivity = {
  type: CONDITIONAL_ACTION_TYPE.SIMPLE; // todo: rename
  action: ActivityAction;
};

export type AdvancedActivity = {
  ifAction: ActivityAction;
  elseAction: ActivityAction;
  comparison: Comparison[];
  type: CONDITIONAL_ACTION_TYPE.ADVANCED;
};

export type Activity = SimpleActivity | AdvancedActivity;
export type ActionResult = {
  jump?: number;
  repeat?: boolean;
  overrideStatusTo?: EXECUTION_STATUS;
  preventTermination?: boolean;
  kpi?: number[];
};

export enum PlayModes {
  NORMAL = "NORMAL",
  STEP_BY_STEP = "STEP_BY_STEP",
  UNTIL = "UNTIL",
}

export type PlayMode =
  | {
      mode: PlayModes.STEP_BY_STEP;
      executionId?: string;
    }
  | {
      mode: PlayModes.NORMAL;
      fromExecutionId?: string;
    }
  | {
      mode: PlayModes.UNTIL;
      executionId: string;
      fromExecutionId?: string;
    };

export const ERROR_TYPES = {
  NoElementError: "NoElementError",
  TerminationError: "TerminationError",
  TerminationWarning: "TerminationWarning",
};

export enum MoveDirections {
  IN = "IN",
  OUT = "OUT",
}

export type MoveDirection = { moveDirection: MoveDirections; from: string; to: string };
