import { createModuleEpics } from "@app/modules/app.reducers";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { ProjectCategory } from "@ea/shared_types/types";
import { projectCategoryDataSelectors } from "./projectCategory.selectors";
import { moduleActions } from "./projectCategory.actions";
import { PROJECT_CATEGORY_COLUMNS_CONFIG } from "./projectCategory.table";

const guardModuleEpics = createModuleEpics<ProjectCategory>(moduleActions, {
  normalizeItem: (guard) => guard,
  getRequestParams: (state, tableId) => {
    const tableParams = projectCategoryDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, PROJECT_CATEGORY_COLUMNS_CONFIG);

    return params;
  },
  getSingleRequestParams: (query) => {
    return query;
  },
  api: {
    createItem: API.createProjectCategory,
    deleteItem: API.deleteProjectCategory,
    editItem: API.editProjectCategory,
    getItems: API.getProjectCategories,
    getItemsCount: API.getProjectCategoriesCount,
  },
});

export default guardModuleEpics;
