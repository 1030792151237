import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { ReportTemplate } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("REPORT_TEMPLATES_MODULE");

export const moduleActions = createDataModuleActions<ReportTemplate>(actionCreator);
export const getReportTemplatesTableActions = createTableActionsGetter(
  normalizeActions(moduleActions.table),
);
export const reportTemplatesActions = {
  ...normalizeActions(moduleActions.data),
};
export const reportTemplatesActionCreators = {
  ...moduleActions,
};
