import { Breadcrumb } from "antd";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";

import { capitalizeFirstLetter } from "../utils/formatters";

interface IBreadcrumbContainerProps {
  transformText?: (chunk: string, index: number) => string | React.ReactNode;
  disabled?: boolean;
}

class BreadcrumbContainer extends React.Component<
  IBreadcrumbContainerProps & RouteComponentProps<any>,
  any
> {
  getItems = () => {
    const { location, transformText, disabled } = this.props;

    if (!location) {
      return [];
    }

    const chunks = location.pathname.split("/").slice(1);
    return chunks.map((chunk, index) => {
      const url = "/" + chunks.slice(0, index + 1).join("/");
      const capitalizedChunk = chunk
        .split("_")
        .map((c) => capitalizeFirstLetter(c))
        .join(" ");
      const text = transformText ? transformText(capitalizedChunk, index) : capitalizedChunk;
      if (chunks.length - 1 === index || disabled) {
        return <Breadcrumb.Item key={url}>{text}</Breadcrumb.Item>;
      }
      return (
        <Breadcrumb.Item key={url}>
          <Link to={url}>{text}</Link>
        </Breadcrumb.Item>
      );
    });
  };

  render() {
    const { location } = this.props;
    if (!location) {
      return null;
    }

    return <Breadcrumb separator=">">{this.getItems()}</Breadcrumb>;
  }
}

export default withRouter(BreadcrumbContainer);
