import loadable from "@loadable/component";

const DynamicIcon = loadable((props: { type: string }) =>
  import(`@ant-design/icons/es/icons/${props.type}.js`).catch((err) => {
    console.error(`Error while loading icon: ${props.type} - ${err}`);
    return import(`@ant-design/icons/es/icons/WarningOutlined.js`);
  }),
);

export default DynamicIcon;
