import { getIn } from "final-form";
import * as React from "react";
import { injectIntl, InjectedIntlProps } from "react-intl";

import {
  Variable,
  VariableType,
  AssignedVariableType,
  GlobalVariable,
} from "@ea/shared_types/types";
import { getTranslationKey } from "../AssignVariablePicker/toMigrate";
import OnFormChange from "../Form/OnFormChange";
import SelectField from "../Form/Fields/SelectField";
import AutoCompleteField from "../Form/Fields/AutoCompleteField";
import RadioField, { RadioType } from "../Form/Fields/RadioField";
import { FieldStatus } from "../Form/FormItem/FormItem";
import validators, { composeValidators } from "../Form/validators";
import { DataTestIds } from "../utils/dataTestHelpers";

interface IAssignVariablePickerProps {
  variables: Variable[];
  values: any;
  name: string;
  readOnly?: boolean;
  label?: string;
  globalMutables?: GlobalVariable[];
  change: any;
}

export const isVariableNew = (value) => value !== undefined && value !== "" && isNaN(value);

const generateOptions = (arr: (Variable | GlobalVariable)[] = []) =>
  arr.map((v) => ({
    text: v.name,
    label: v.name,
    value: v.id,
  }));

const variableTypeOptions = [
  {
    value: AssignedVariableType.LOCAL,
    text: getTranslationKey("variable", "label", "local"),
  },
  {
    value: AssignedVariableType.GLOBAL,
    text: getTranslationKey("variable", "label", "global"),
  },
];

const AssignVariableWithGlobals: React.FunctionComponent<
  IAssignVariablePickerProps & InjectedIntlProps
> = ({ variables, name, values, readOnly, label, intl, change, globalMutables = [] }) => {
  const filterdLocal = variables.filter((v) => v.type === VariableType.Normal);

  const localOptions = generateOptions(filterdLocal);
  const globalOptions = generateOptions(globalMutables);

  const localMap = filterdLocal.reduce((map, v) => {
    map[v.name] = v.id;
    return map;
  }, {});

  const value = getIn(values, name);
  const isNew = isVariableNew(value);

  const { whiteSpaceAndSpecialCharValidator, mustStartWithLetter } = validators(intl);
  const isIdOrStartsLetterValidator = (val) => {
    if (!isNaN(val)) {
      return !localOptions.find((s) => s.value === val) ? mustStartWithLetter(val) : false;
    }
    return mustStartWithLetter(val);
  };

  return (
    <>
      <OnFormChange name={"value.code.assignedVariableType"}>
        {(va) => {
          switch (va) {
            case AssignedVariableType.GLOBAL: {
              return (
                <SelectField
                  name={name}
                  label={label}
                  options={globalOptions}
                  required
                  placeholder={getTranslationKey("step", "placeholder", "selectVariable")}
                  help={getTranslationKey("messages", "help", "pick")}
                  data-testid={DataTestIds.FORM_SELECT_GLOBAL_VARIABLE}
                />
              );
            }
            default:
            case AssignedVariableType.LOCAL: {
              return (
                <AutoCompleteField
                  name={name}
                  label={label}
                  options={localOptions}
                  required
                  format={(v) => {
                    const variable = localOptions.find((val) => val.value === v);
                    if (variable) {
                      return variable.text;
                    }
                    return v;
                  }}
                  parse={(v) => {
                    return +v || v;
                  }}
                  onChange={(v) => {
                    return localMap[v] || v;
                  }}
                  overwriteOnChange
                  status={isNew ? FieldStatus.WARNING : undefined}
                  validate={composeValidators(
                    whiteSpaceAndSpecialCharValidator,
                    isIdOrStartsLetterValidator,
                  )}
                  showHelp={isNew}
                  placeholder={getTranslationKey("step", "placeholder", "pick")}
                  help={getTranslationKey("messages", "help", "pick")}
                  data-testid={DataTestIds.FORM_AUTOCOMPLETE_VARIABLE}
                />
              );
            }
          }
        }}
      </OnFormChange>
      <RadioField
        name="value.code.assignedVariableType"
        required
        type={RadioType.Normal}
        defaultValue={AssignedVariableType.LOCAL}
        options={variableTypeOptions}
        onChange={() => change(name, undefined)}
      />
    </>
  );
};

export const AssignVariableWithGlobalsPicker = injectIntl(AssignVariableWithGlobals);
