import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { User } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("USERS_MODULE");

export const moduleActions = createDataModuleActions<User>(actionCreator);
export const getUsersTableActions = createTableActionsGetter(normalizeActions(moduleActions.table));
export const userActions = {
  ...normalizeActions(moduleActions.data),
};
export const userActionCreators = {
  ...moduleActions,
};
