import { createModuleEpics } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { CodeTemplateWithGroup } from "@ea/shared_types/types";
import { moduleActions } from "./codeTemplates.actions";
import { codeTemplatesDataSelectors } from "./codeTemplates.selectors";
import { CODE_TEMPLATES_COLUMNS_CONFIG } from "./codeTemplates.table";

const includeFilter = [
  {
    relation: "CodeTemplateGroup",
    scope: {
      fields: ["name", "id"],
    },
  },
];

const codeTemplatesModuleEpics = createModuleEpics<CodeTemplateWithGroup>(moduleActions, {
  normalizeItem: (role) => role,
  getRequestParams: (state, tableId) => {
    const tableParams = codeTemplatesDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, CODE_TEMPLATES_COLUMNS_CONFIG);
    params.filter = {
      ...params.filter,
      include: includeFilter,
    };

    return params;
  },
  getSingleRequestParams: (query) => {
    return query;
  },
  api: {
    createItem: API.createCodeTemplatesItem as any,
    deleteItem: API.deleteCodeTemplatesItem,
    editItem: API.editCodeTemplatesItem as any,
    getItems: API.getCodeTemplatesItems as any,
    getItemsCount: API.getCodeTemplatesItemCount,
  },
});

export default codeTemplatesModuleEpics;
