import styled from "@emotion/styled";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators, Dispatch } from "redux";

import { ApplicationState } from "@app/modules/app.reducers";
import { LogsState } from "@app/modules/logs";
import CommandBar from "@app/modules/logs/components/LogsCommandBar";

import { itsDataSelectors } from "@app/modules/issueTrackingTool/its.selectors";
import KpiStatisticsModal from "@app/modules/kpis/components/KpiStatisticsModal";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { generateReportName } from "@app/utils/reports";
import {
  currentUserIdSelector,
  currentUserSettingsSelector,
} from "@ea/shared_components/auth/auth.selectors";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import {
  ExportToCsvModels,
  Log,
  ReportExtension,
  StepLog,
  StepScreenshots,
} from "@ea/shared_types/types";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import { exportToCsv } from "../../../utils/exportCsv";
import ExportingModal from "../../common/components/ExportingModal";
import CapturedFilesModal from "../components/CapturedFilesModal";
import ErrorWarningList from "../components/ErrorWarningList";
import MessagesRow, { shouldDisplayMessagesRow } from "../components/MessagesRow";
import ScreenshotGallery from "../components/ScreenshotGallery";
import VariableLog, { shouldDisplayVariableLogs } from "../components/VariableLog";
import { logsDataSelectors as scriptLogsDataSelectors } from "../logs.selectors";
import { LOGS_TABLES_CONFIG } from "../logs.table";
import { getStepLogsTableActions } from "./stepLogs.actions";
import { stepLogsDataSelectors } from "./stepLogs.selectors";
import { STEP_LOGS_COLUMNS, STEP_LOGS_COLUMNS_CONFIG } from "./stepLogs.table";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
  height: "100%",
});

interface IStepLogsProps extends RouteComponentProps<any> {
  hideCommandBar?: boolean;
  scriptLogId: number;
  parentStepLogId?: number;
  pageable?: boolean;
  showHeader?: boolean;
}
interface IStepLogsState extends LogsState {
  screenshotGalleryVisible: boolean;
  clickedStepLogId?: number;
  isExporting?: boolean;
  errorWarningListVisible: boolean;
  scriptLog?: any;
  capturedFilesVisible: boolean;
  kpiVisible: boolean;
  screenshots: StepScreenshots;
}
class StepLogs extends React.Component<IConnectProps & InjectedIntlProps, IStepLogsState> {
  connectedTable: any;

  constructor(props: IConnectProps & InjectedIntlProps) {
    super(props);
    this.state = {
      screenshotGalleryVisible: false,
      persistentQuery: {
        executionId: props.scriptLogId,
        parentStepLogId: props.parentStepLogId || 0,
      },
      isExporting: false,
      errorWarningListVisible: false,
      capturedFilesVisible: false,
      scriptLog: props.scriptLog,
      kpiVisible: false,
      screenshots: {},
    };
  }

  async componentDidMount() {
    if (this.props.scriptLogId) {
      const scriptLog = await this.loadScriptLog();
      this.setState({
        scriptLog,
        screenshots: await API.getExecutionScreenshots({ id: this.props.scriptLogId }),
      });
    }
  }

  async componentDidUpdate(prevProps: IConnectProps) {
    if (
      prevProps.scriptLogId !== this.props.scriptLogId ||
      prevProps.parentStepLogId !== this.props.parentStepLogId
    ) {
      const scriptLog = await this.loadScriptLog();
      this.setState({
        persistentQuery: {
          executionId: this.props.scriptLogId,
          parentStepLogId: this.props.parentStepLogId || 0,
        },
        screenshots: this.props.scriptLogId
          ? await API.getExecutionScreenshots({ id: this.props.scriptLogId })
          : {},
        scriptLog,
      });
    }
  }

  loadScriptLog = async () => {
    const scriptLogs = await API.getLogs({ filter: { where: { id: this.props.scriptLogId } } });
    if (scriptLogs.length > 0) {
      return scriptLogs[0];
    }
    return undefined;
  };

  compareQuery = (currentQuery, prevQuery) =>
    currentQuery.executionId !== prevQuery.executionId ||
    currentQuery.parentStepLogId !== prevQuery.parentStepLogId;

  reload = async () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  shouldRowBeExpandable = (record) =>
    shouldDisplayVariableLogs(record.variableLogs) ||
    shouldDisplayMessagesRow(record.messages) ||
    record.linkedScriptId;

  expandedRowRender = (record) => {
    const shouldDisplayVariablesLog = shouldDisplayVariableLogs(record.variableLogs);
    const variables = <VariableLog logs={record.variableLogs} />;
    const messages = (
      <MessagesRow
        messages={record.messages}
        stepLabelParams={record.labelParams}
        withHeader={shouldDisplayVariablesLog}
      />
    );

    const linkedLogs = record.linkedScriptId ? (
      <StepLogs
        {...this.props}
        tableId={LOGS_TABLES_CONFIG.STEP_LOGS.id(`LINKED_${record.id}`)}
        parentStepLogId={record.id}
        hideCommandBar
        showHeader={false}
      />
    ) : null;

    return (
      <div>
        {variables}
        {messages}
        {linkedLogs}
      </div>
    );
  };

  openGallery = (record?: StepLog) => {
    this.setState({
      screenshotGalleryVisible: true,
      clickedStepLogId: record && record.id,
    });
  };

  closeScreenshotGallery = () => {
    this.setState({
      screenshotGalleryVisible: false,
    });
  };

  generateReport = async () => {
    const { scriptLogId, userSettings, tableId, actions } = this.props;
    const { scriptLog: selectedScriptLog } = this.state;
    try {
      const extension = userSettings.reportExtension || ReportExtension.docx;

      const scriptLog: Log = selectedScriptLog;

      if (!scriptLog) {
        throw new Error(this.props.intl.formatMessage({ id: "messages.error.export" }));
      }

      actions.setIsGeneratingReport({
        isGeneratingReport: true,
      });

      await API.generateReport({
        logId: scriptLogId,
        extension,
        tableId,
        reportName: generateReportName(scriptLog, extension),
      });
    } catch (error) {
      toast.error(error.message);
      actions.setIsGeneratingReport({
        isGeneratingReport: false,
      });
    }
  };

  exportToExcel = async () => {
    this.setState({ isExporting: true });
    const { tableParams, userSettings, userId, preferencesId } = this.props;
    const stepLogsColumnConfig = STEP_LOGS_COLUMNS_CONFIG();
    const params = createRequestParams(tableParams, stepLogsColumnConfig, {
      defaultOrder: ["startTime ASC"],
    });
    const fields = STEP_LOGS_COLUMNS({})
      .map((log) => log.props.dataIndex)
      .filter((field) => field !== stepLogsColumnConfig.screenshot.dataIndex);

    if (!params.filter) {
      params.filter = {};
    }
    if (params.filter.where) {
      delete params.filter.where.parentStepLogId;
    }
    await exportToCsv({
      params,
      fields: [...fields],
      onExportStart: () => this.setState({ isExporting: true }),
      onExportFinish: () => this.setState({ isExporting: false }),
      modelName: ExportToCsvModels.STEP_LOGS,
      overrideFileName: `ExecutionHistory_${this.props.match.params.sessionId}.csv`,
      localeId: userSettings.documentationLocale,
      userId,
      preferencesId,
    });
  };

  openErrorWarningList = () => {
    this.setState({
      errorWarningListVisible: true,
    });
  };

  closeErrorWarningList = () => {
    this.setState({
      errorWarningListVisible: false,
    });
  };

  onPerformanceCountersClick = () => {
    this.setState(({ kpiVisible }) => ({
      kpiVisible: !kpiVisible,
    }));
  };

  render() {
    const { tableId, hideCommandBar, pageable, showHeader, its, preferencesId, tableParams } =
      this.props;
    const {
      isExporting,
      persistentQuery,
      clickedStepLogId,
      screenshotGalleryVisible,
      scriptLog,
      errorWarningListVisible,
      capturedFilesVisible,
      kpiVisible,
      screenshots,
    } = this.state;

    return (
      <Container>
        <ExportingModal visible={isExporting} />
        {hideCommandBar || (
          <CommandBar
            onReload={this.reload}
            onReportGenerate={this.generateReport}
            scriptLogId={this.props.scriptLogId}
            onOpenScreenshotGallery={this.openGallery}
            onExport={this.exportToExcel}
            isExporting={isExporting}
            isGeneratingReport={tableParams.isGeneratingReport}
            onOpenErrorWarningList={this.openErrorWarningList}
            selectedLog={scriptLog}
            selectedLogs={[scriptLog]}
            onOpenCapturedFilesModal={() => {
              this.setState({ capturedFilesVisible: true });
            }}
            onPerformanceCountersClick={() => this.onPerformanceCountersClick()}
          />
        )}
        {kpiVisible && (
          <KpiStatisticsModal
            visible={kpiVisible}
            onClose={this.onPerformanceCountersClick}
            executionLogId={this.props.scriptLogId}
          />
        )}
        <CapturedFilesModal
          visible={!!capturedFilesVisible}
          title={<FormattedMessage id={getTranslationKey("commandBar", "capturedFiles")} />}
          onClose={() => {
            this.setState({ capturedFilesVisible: false });
          }}
          selectedLog={scriptLog}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable={pageable === false ? pageable : true}
          showHeader={showHeader === false ? showHeader : true}
          columnsConfig={STEP_LOGS_COLUMNS({ onScreenshotClick: this.openGallery })}
          persistentQuery={persistentQuery}
          comparePersistentQuery={this.compareQuery}
          tableId={tableId}
          preferencesId={preferencesId}
          stateKey="stepLogs"
          tableActions={getStepLogsTableActions}
          selectable={false}
          onReload={async () => {
            this.setState({
              screenshots: await API.getExecutionScreenshots({ id: this.props.scriptLogId }),
            });
          }}
          expandedRowRender={this.expandedRowRender}
          shouldRowBeExpandable={this.shouldRowBeExpandable}
          autoReload
        />
        <ScreenshotGallery
          onClose={this.closeScreenshotGallery}
          currentStepLogId={clickedStepLogId}
          visible={screenshotGalleryVisible}
          screenshots={screenshots}
        />
        <ErrorWarningList
          onClose={this.closeErrorWarningList}
          visible={errorWarningListVisible}
          selectedLogs={scriptLog ? [scriptLog] : []}
          its={its}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, { scriptLogId }: IStepLogsProps) => ({
  actions: {
    ...bindActionCreators(
      getStepLogsTableActions(LOGS_TABLES_CONFIG.STEP_LOGS.id(scriptLogId)),
      dispatch,
    ),
  },
});

const mapStateToProps = (state: ApplicationState, props: IStepLogsProps) => {
  const userSettings = currentUserSettingsSelector(state);

  return {
    ...state.stepLogs,
    ...props,
    allStepLogs: stepLogsDataSelectors.getOrderedDataSelector(
      state,
      LOGS_TABLES_CONFIG.STEP_LOGS.id(props.scriptLogId),
    ),
    selectedStepLogs: stepLogsDataSelectors.getSelectedItemsSelector(
      state,
      LOGS_TABLES_CONFIG.STEP_LOGS.id(props.scriptLogId),
    ),
    tableId: LOGS_TABLES_CONFIG.STEP_LOGS.id(props.scriptLogId),
    tableParams: stepLogsDataSelectors.getParamsSelector(
      state,
      LOGS_TABLES_CONFIG.STEP_LOGS.id(props.scriptLogId),
    ),
    scriptLog: scriptLogsDataSelectors.getItemSelector(state, props.scriptLogId),
    userSettings,
    its: userSettings.its ? itsDataSelectors.getItemSelector(state, userSettings.its) : undefined,
    userId: currentUserIdSelector(state),
    preferencesId: LOGS_TABLES_CONFIG.STEP_LOGS.preferencesId,
  };
};

const connectCreator = connect(mapStateToProps, mapDispatchToProps);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(injectIntl(StepLogs)));
