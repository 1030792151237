import { objectValues } from "@ea/shared_components/utils/object";

import * as localizationActions from "./localization.actions";
import localizationEpicsMap from "./localization.epics";
import * as localizationReducers from "./localization.reducers";

const localizationEpics = objectValues(localizationEpicsMap);

export { localizationActions, localizationReducers, localizationEpics };

export { default as LocalizationTableContainer } from "./LocalizationTable.container";
