import { URL_SELECT_MODE } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";
import EaError from "@ea/shared_types/eaError";

export const normalizeEnvironmentInfo = (formValues) => {
  let startUrl: string | null = null;
  let environmentId: number | null = null;
  if (formValues.urlSwitch === URL_SELECT_MODE.MANUAL) {
    startUrl = formValues.url;
  } else {
    // when picked default project env
    if (!formValues.url) {
      return {
        startUrl,
        environmentId,
      };
    }

    startUrl = formValues.url.envAddress;
    environmentId = formValues.url.id;
  }

  return {
    startUrl,
    environmentId,
  };
};

export const validateEnvironmentFormValues = (formValues) => {
  if (
    formValues.urlSwitch !== URL_SELECT_MODE.MANUAL &&
    !formValues.defaultSystemId &&
    formValues.defaultSystem
  ) {
    throw new EaError(getTranslationKey("messages", "info", "scriptNoProjectSystem"));
  }
  if (
    formValues.useVirtualUser &&
    formValues.urlSwitch !== URL_SELECT_MODE.MANUAL &&
    formValues.defaultVirtualUser &&
    !formValues.defaultVirtualUserId
  ) {
    throw new EaError(getTranslationKey("messages", "info", "scriptNoProjectDefaultVu"));
  }

  if (formValues.defaultSystem && formValues.urlSwitch !== URL_SELECT_MODE.MANUAL) {
    formValues.environmentId = null;
  }

  if (formValues.urlSwitch !== URL_SELECT_MODE.MANUAL) {
    formValues.startUrl = null;
  }

  if (formValues.urlSwitch === URL_SELECT_MODE.MANUAL) {
    formValues.environmentId = null;
  }

  if (formValues.defaultVirtualUser || formValues.urlSwitch === URL_SELECT_MODE.MANUAL) {
    formValues.virtualUserId = null;
  }
};
