import actionCreatorFactory from "typescript-fsa";

import {
  createFilterActions,
  getAsyncStartedActions,
} from "@ea/shared_components/redux/common.actions";
import {
  createDataActions,
  createTableActions,
  createTableAsyncActions,
  wrapTableActions,
} from "@ea/shared_components/redux/common.data.actions";
import {
  NotifiableAction,
  SelectMode,
  TableActionParams,
} from "@ea/shared_components/redux/common.models";
import { Step } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("STEP_MODULE");

export const tableActionsCreators = createTableActions<Step>(actionCreator);
export const tableAsyncActionsCreators = createTableAsyncActions<Step>(actionCreator);
export const dataAsyncActionsCreators = createDataActions<Step>(actionCreator);

const dataAsyncActionsStarted = getAsyncStartedActions(dataAsyncActionsCreators);

const actionCreators = {
  ...tableActionsCreators,
};

const otherTableActions = {
  moveTo: actionCreator.async<TableActionParams<{ id: number; from: number; to: number }>, any>(
    "MOVE_TO",
  ),
  pasteSteps: actionCreator.async<
    TableActionParams<{
      taskScriptId: number;
      fromStepId: number;
      copiedSteps: Step[];
      sourceScriptId: number;
    }>,
    any
  >("PASTE_STEPS"),
};

const asyncActionCreators = {
  ...tableAsyncActionsCreators,
  ...dataAsyncActionsCreators,
};

export const stepsActionCreators = {
  ...actionCreators,
  ...asyncActionCreators,
  ...otherTableActions,
};

export const stepsTableActions = (tableId: string) =>
  wrapTableActions(
    {
      ...tableActionsCreators,
      ...getAsyncStartedActions(tableAsyncActionsCreators),
      ...getAsyncStartedActions(otherTableActions),
    },
    tableId,
  );

export const stepsActions = {
  ...dataAsyncActionsStarted,
  editDone: dataAsyncActionsCreators.edit.done,
};

export const notifiableActions: NotifiableAction[] = [
  asyncActionCreators.load,
  {
    action: otherTableActions.pasteSteps,
    onSuccessMessage: "Steps have been pasted",
  },
];
