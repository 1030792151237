import { ApplicationState } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { PanelType } from "@ea/shared_components/Panel";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { VirtualUserPool } from "@ea/shared_types/types";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import VirtualUserPoolForm from "./components/VirtualUserPoolForm";

interface IVirtualUserPoolNewPanelProps {
  visibility: boolean;
  onClose: any;
  onOk?: any;
  selected?: VirtualUserPool;
  isEdit?: boolean;
}

class VirtualUserPoolNewPanelContainer extends React.Component<
  IVirtualUserPoolNewPanelProps & IConnectProps,
  any
> {
  onOk = async (values) => {
    const { isEdit, onOk, onClose } = this.props;

    if (isEdit) {
      await API.editVirtualUserPool({
        ...values,
        virtualUsers: values.virtualUsers?.map((vu) => vu.id),
      });
    } else {
      const result = await API.createVirtualUserPool(values);

      if (onOk) {
        onOk(result);
      }
    }

    onClose();
  };

  render() {
    const { visibility, onClose, isEdit, selected } = this.props;

    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.SMALL}
        headerText={getTranslationKey("virtualUserPool", "header", isEdit ? "edit" : "create")}
        key={isEdit ? "Edit" : "Create"}
        onCancelClick={onClose}
        onOkClick={this.onOk}
        cancelButtonText={getTranslationKey("button", "cancel")}
        okButtonText={getTranslationKey("button", isEdit ? "save" : "create")}
        initialValues={isEdit ? selected : undefined}
        render={({ values }) => <VirtualUserPoolForm values={values} />}
      />
    );
  }
}

const connectCreator = connect((state: ApplicationState) => ({}), {});

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(VirtualUserPoolNewPanelContainer);
