import { LiveVariablesUpdateData, LiveVariablesModifyData } from "./runner.common.types";
import { EXECUTION_STATE, RecorderStep, RunnerMode, Path, Step, GlobalVariable } from "./types";
import { PlayMode } from "./newRunner.types";

export type IFrameMessage<Z, T> = {
  type: Z;
  value: T;
};

type IFrameAsyncRequest<Z = string, T = {}> = IFrameMessage<Z, T>;
type IFrameSyncRequest<Z = string, T = {}, K = {}> = {
  request: IFrameMessage<Z, T>;
  response: IFrameMessage<Z, K>;
};

export type SyncMessageHandler<T = any, K = any, P = any> = {
  key?: string;
  callback: (value: T, meta?: K) => Promise<P>;
};

export type AsyncMessageHandler<T = any, K = any> = {
  key?: string;
  callback: (value: T, meta?: K) => void;
};

type IFrameRequests<T, K> = {
  async: T;
  sync: K;
};

export enum RunneIFrameEndpoints {
  administration = "administration",
  runner = "runner",
}

export type CreateRunnerIFrameAPI<
  AdministrationRequests extends IFrameRequests<any, any>,
  RunnerRequests extends IFrameRequests<any, any>,
> = {
  [RunneIFrameEndpoints.administration]: AdministrationRequests;
  [RunneIFrameEndpoints.runner]: RunnerRequests;
};

export type RunnerIFrameAPI = CreateRunnerIFrameAPI<
  RunnerAdministrationIFrameAPI,
  RunnerHostIFrameAPI
>;

export enum IFrameRunnerAsyncMessages {
  step_recorded = "step_recorded",
  start_recording = "start_recording",
  pause_recording = "pause_recording",
  toggle_inspect = "toggle_inspect",

  back_navigation = "back_navigation",
  refresh_navigation = "refresh_navigation",
  url_change = "url_change",

  start_player = "start_player",
  player_state = "player_state",
  player_position = "player_position",
  player_step_status = "player_step_status",
  pause_player = "pause_player",
  restart_player = "restart_player",
  runner_loaded = "runner_loaded",
  variable_changed = "variable_changed",
  variables_init = "variables_init",
  set_variable = "set_variable",
  modify_variables_state = "modify_variables_state",

  draw_overlay = "draw_overlay",
}

export enum IFrameRunnerSyncMessages {
  get_initial_state = "get_initial_state",
  set_initial_state = "set_initial_state",
  load_controller = "load_controller",
}

interface IFrameRunnerAsyncMessagesTypes {
  [IFrameRunnerAsyncMessages.step_recorded]: RecorderStep;
  [IFrameRunnerAsyncMessages.start_recording]: {};
  [IFrameRunnerAsyncMessages.pause_recording]: {};
  [IFrameRunnerAsyncMessages.toggle_inspect]: {};

  [IFrameRunnerAsyncMessages.back_navigation]: {};
  [IFrameRunnerAsyncMessages.refresh_navigation]: {};
  [IFrameRunnerAsyncMessages.url_change]: string;

  [IFrameRunnerAsyncMessages.start_player]: PlayMode | undefined;
  [IFrameRunnerAsyncMessages.pause_player]: {};
  [IFrameRunnerAsyncMessages.restart_player]: {};
  [IFrameRunnerAsyncMessages.player_state]: EXECUTION_STATE;
  [IFrameRunnerAsyncMessages.player_position]: number;
  [IFrameRunnerAsyncMessages.player_step_status]: { path; status };
  [IFrameRunnerAsyncMessages.runner_loaded]: {};

  [IFrameRunnerAsyncMessages.variable_changed]: {};
  [IFrameRunnerAsyncMessages.variables_init]: {};
  [IFrameRunnerAsyncMessages.set_variable]: LiveVariablesUpdateData;
  [IFrameRunnerAsyncMessages.modify_variables_state]: LiveVariablesModifyData[];
  [IFrameRunnerAsyncMessages.draw_overlay]: Step | undefined;
}

interface IFrameRunnerSyncMessagesTypes {
  [IFrameRunnerSyncMessages.get_initial_state]: {
    request: {};
    response: any;
  };
  [IFrameRunnerSyncMessages.set_initial_state]: {
    request: {};
    response: any;
  };
  [IFrameRunnerSyncMessages.load_controller]: {
    request: RunnerMode;
    response: void;
  };
}

type CreateAsyncRequest<T extends IFrameRunnerAsyncMessages> = {
  [P in T]: IFrameAsyncRequest<P, IFrameRunnerAsyncMessagesTypes[P]>;
};

type CreateSyncRequest<T extends IFrameRunnerSyncMessages> = {
  [P in T]: IFrameSyncRequest<
    P,
    IFrameRunnerSyncMessagesTypes[P]["request"],
    IFrameRunnerSyncMessagesTypes[P]["response"]
  >;
};

type RunnerAdministrationIFrameAPI = IFrameRequests<
  CreateAsyncRequest<
    | IFrameRunnerAsyncMessages.start_player
    | IFrameRunnerAsyncMessages.pause_player
    | IFrameRunnerAsyncMessages.restart_player
    | IFrameRunnerAsyncMessages.start_recording
    | IFrameRunnerAsyncMessages.pause_recording
    | IFrameRunnerAsyncMessages.toggle_inspect
    | IFrameRunnerAsyncMessages.back_navigation
    | IFrameRunnerAsyncMessages.refresh_navigation
    | IFrameRunnerAsyncMessages.set_variable
    | IFrameRunnerAsyncMessages.modify_variables_state
    | IFrameRunnerAsyncMessages.draw_overlay
  >,
  CreateSyncRequest<
    IFrameRunnerSyncMessages.set_initial_state | IFrameRunnerSyncMessages.load_controller
  >
>;

type RunnerHostIFrameAPI = IFrameRequests<
  CreateAsyncRequest<
    | IFrameRunnerAsyncMessages.step_recorded
    | IFrameRunnerAsyncMessages.player_position
    | IFrameRunnerAsyncMessages.player_state
    | IFrameRunnerAsyncMessages.player_step_status
    | IFrameRunnerAsyncMessages.url_change
    | IFrameRunnerAsyncMessages.runner_loaded
    | IFrameRunnerAsyncMessages.variable_changed
    | IFrameRunnerAsyncMessages.variables_init
    | IFrameRunnerAsyncMessages.modify_variables_state
  >,
  CreateSyncRequest<IFrameRunnerSyncMessages.get_initial_state>
>;
