import * as React from "react";

import FormItemWrapper from "@ea/shared_components/Form/FormItem/FormItemWrapper";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { Row, Col } from "antd";
import CheckboxField from "@ea/shared_components/Form/Fields/CheckboxField";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { EXECUTION_STATUS } from "@ea/shared_types/types";

const statusOptions = [
  {
    value: EXECUTION_STATUS.SUCCESS,
    text: getTranslationKey("conditionals", EXECUTION_STATUS.SUCCESS),
  },
  {
    value: EXECUTION_STATUS.WARNING,
    text: getTranslationKey("conditionals", EXECUTION_STATUS.WARNING),
  },
  {
    value: EXECUTION_STATUS.ERROR,
    text: getTranslationKey("conditionals", EXECUTION_STATUS.ERROR),
  },
];

export const OverrideStatus: React.FunctionComponent<{
  prefix: string;
  readOnly?: boolean;
}> = ({ prefix, readOnly }) => (
  <FormItemWrapper
    formItem={{
      formItemRowStyle: { marginBottom: 0 },
    }}
  >
    {() => (
      <FormLayout wrapperCol={{ span: 24 }} labelCol={{ span: 0 }} readOnly={readOnly}>
        <Row>
          <Col span={6}>
            <CheckboxField name={`${prefix}.overrideStatus`}>
              <FormattedMessage id={getTranslationKey("conditionals", "overrideStatusTo")} />
            </CheckboxField>
          </Col>
          <Col span={5}>
            <SelectField
              defaultValue={EXECUTION_STATUS.SUCCESS}
              name={`${prefix}.overrideStatusTo`}
              options={statusOptions}
            />
          </Col>
        </Row>
      </FormLayout>
    )}
  </FormItemWrapper>
);
