import * as React from "react";
import { Field } from "react-final-form";

type OnChangeFieldProps = {
  children: (value: any) => React.ReactNode;
  input: {
    value: any;
  };
};

type OnChangeFieldWrapperProps = {
  name: string;
  children: OnChangeFieldProps["children"];
};

class OnChangeField extends React.Component<OnChangeFieldProps> {
  render() {
    return this.props.children(this.props.input.value);
  }
}

export const OnFormChange = ({ name, children }: OnChangeFieldWrapperProps) =>
  React.createElement(Field, {
    name,
    subscription: { value: true },
    allowNull: true,
    render: (props) => React.createElement(OnChangeField, { ...props, children }),
  });

export default OnFormChange;
