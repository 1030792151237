import "react-virtualized/styles.css";
import "./VirtualizedTable.css";

import { css } from "@emotion/core";
import styled from "@emotion/styled";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { SortableContainer } from "../../Sortable/react-sortable-hoc";
import {
  AutoSizer,
  Column,
  ColumnProps,
  defaultTableHeaderRowRenderer,
  RowMouseEventHandlerParams,
  SortDirectionType,
  SortIndicator,
  Table,
  TableCellProps,
  TableRowProps,
} from "react-virtualized";
import { TableHeaderRowProps } from "react-virtualized/dist/es/Table";

import { PlainObject, SelectMode, SortDirection } from "../../redux/common.models";
import { Z_INDEXES } from "../../shared.consts";

import SavingArea from "../../common/SavingArea";
import ExpandButton from "./ExpandButton";
import HeaderRenderer from "./HeaderRenderer";
import { DefaultRowRenderer } from "./RowRenderer";
import SelectCheckbox from "./SelectCheckbox";
import SortableElement from "./SortableElement";

import { faLock } from "@fortawesome/fontawesome-free-solid";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";

interface TableProperty {
  items: any[];
  columns: ColumnProps[];
  isLoading: boolean;
  markedElement?: number;
  selectItem?: any;
  alwaysVisible?: boolean;
  isDragEnabled?: boolean;
  onDoubleClick?: (item: any) => void;
  moveTo?: ({ id, from, to }: { id: number; from: number; to: number }) => void;
  sortBy?: string;
  sortDirection?: SortDirection;
  onSort?: (sortBy: string, sortDirection: SortDirection) => void;
  isExpandable?: boolean;
  onExpand?: ({ id, index }: { id: number; index: number }) => void;
  selectedMap?: PlainObject<number>;
  idProperty?: string;
  notSelectableIds?: (string | number)[];
}

const SortableTableRowRenderer = SortableElement(DefaultRowRenderer);

const Container = styled.div({
  flex: 1,
});

const headerStyles = {
  borderBottom: "1px solid #d6d6d6",
};

const dragClass = css({
  boxShadow: "0 5px 5px -5px rgba(0,0,0,0.2), 0 -5px 5px -5px rgba(0,0,0,0.2)",
  background: "#f4f4f4 !important", // "rgba(255,255,255,0.8)",
  cursor: "row-resize",
  zIndex: Z_INDEXES.VIRTUALIZED_TABLE,
  color: "black",
}).toString();

const MessageContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
});

class VirtualTable extends React.PureComponent<TableProperty, any> {
  static defaultProps: Partial<TableProperty> = {
    isDragEnabled: false,
  };

  table: Table | null;

  componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.items !== this.props.items ||
      nextProps.isDragEnabled !== this.props.isDragEnabled ||
      nextProps.selectedMap !== this.props.selectedMap
    ) {
      if (this.table) {
        this.table.forceUpdateGrid();
      }
    }
  }

  renderHeaderRowRenderer = (props: TableHeaderRowProps) => {
    props.style = Object.assign({}, props.style, headerStyles);
    return defaultTableHeaderRowRenderer(props);
  };

  isSelected = (index) => {
    const idProperty = this.props.idProperty ? this.props.idProperty : "id";
    return (
      this.props.selectedMap &&
      this.props.selectedMap[this.props.items[index][idProperty]] !== undefined
    );
  };

  rowRenderer = (props: TableRowProps) => {
    const isDragDisabled =
      !this.props.isDragEnabled || this.props.items[props.index].isDragDisabled;
    if (this.props.isDragEnabled && this.props.items[props.index].isDragDisabled) {
      props.style.backgroundColor = "#f4f4f4";
    }

    if (this.props.items[props.index].isDisabled) {
      props.style.opacity = 0.5;
    }

    const isSelectable: Boolean = this.props.notSelectableIds
      ? this.props.notSelectableIds.indexOf(props.rowData.id) > -1
        ? false
        : true
      : true;

    return (
      <SortableTableRowRenderer
        {...props}
        isOptional={!!this.props.items[props.index].isOptional}
        dragDisabled={isDragDisabled}
        isSelected={this.isSelected(props.index)}
        isSelectable={isSelectable}
        markedElement={this.props.markedElement}
      />
    );
  };

  checkboxColumnRenderer = (selectItem: any) => (props: TableCellProps) => {
    if (this.props.notSelectableIds && this.props.notSelectableIds.indexOf(props.rowData.id) > -1) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <FontAwesomeIcon icon={faLock} />
        </div>
      );
    }
    return (
      <SelectCheckbox
        {...props}
        alwaysVisible={this.props.alwaysVisible}
        onClick={(rowData, rowIndex) => {
          const id = (props.rowData as any).id;
          selectItem({
            mode: SelectMode.Ctrl,
            ids: [id],
            index: props.rowIndex,
          });
        }}
        isSelected={this.isSelected(props.rowIndex)}
      />
    );
  };

  checkboxColumnHeaderRenderer = (selectItem: any) => (props: any) => {
    const ids = this.props.items
      .filter(
        (item) =>
          !(this.props.notSelectableIds && this.props.notSelectableIds.indexOf(item.id) > -1),
      )
      .map((item) => item.id);
    const isSelected =
      this.props.selectedMap !== undefined && Object.keys(this.props.selectedMap).length > 0;
    const areAllSelected = isSelected && Object.keys(this.props.selectedMap!).length === ids.length;

    return (
      <SelectCheckbox
        {...props}
        onClick={(rowData, rowIndex) => {
          selectItem({
            mode: isSelected && !areAllSelected ? SelectMode.Replace : SelectMode.Ctrl,
            ids: ids,
            index: props.rowIndex,
          });
        }}
        alwaysVisible
        isSelected={isSelected}
      />
    );
  };

  expandColumnRenderer = (props: TableCellProps) => (
    <ExpandButton {...props} onExpand={this.props.onExpand!} />
  );

  selectItem = (rowInfo: RowMouseEventHandlerParams) => {
    // const mode = (rowInfo.event as any).shiftKey
    //   ? SelectMode.Shift
    //   : (rowInfo.event as any).ctrlKey ? SelectMode.Ctrl : SelectMode.Single;
    const id = (rowInfo.rowData as any).id;
    if (this.props.selectItem) {
      this.props.selectItem({
        mode: SelectMode.Replace,
        ids: [id],
        index: rowInfo.index,
      });
    }
  };

  onDoubleClick = (rowInfo: RowMouseEventHandlerParams) => {
    if (this.props.onDoubleClick) {
      this.props.onDoubleClick(rowInfo.rowData);
    }
  };

  onSort = (info: { sortBy: string; sortDirection: SortDirectionType }) => {
    if (this.props.onSort) {
      this.props.onSort(info.sortBy, info.sortDirection);
    }
  };

  getRowHeight = ({ index }: { index: number }) => {
    return 42;
  };

  render() {
    const { items, columns, sortBy, sortDirection, selectItem, isExpandable } = this.props;

    const rowGetter = ({ index }: any) => this._getDatum(items, index);

    const defaultColumns: any[] = [];

    if (selectItem) {
      defaultColumns.push(
        <Column
          key="select"
          cellRenderer={this.checkboxColumnRenderer(selectItem)}
          className="ea-vt-checkbox"
          dataKey=""
          headerRenderer={this.checkboxColumnHeaderRenderer(selectItem)}
          headerClassName="ea-vt-checkbox"
          width={30}
        />,
      );
    }

    if (isExpandable) {
      defaultColumns.push(
        <Column
          key="expand"
          cellRenderer={this.expandColumnRenderer}
          dataKey=""
          headerRenderer={HeaderRenderer()}
          width={30}
        />,
      );
    }

    return (
      <Container>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              className="ea-vt-table"
              ref={(table) => (this.table = table)}
              headerHeight={42}
              headerClassName="ea-vt-header"
              headerRowRenderer={this.renderHeaderRowRenderer}
              height={height}
              scrollToIndex={this.props.markedElement ? this.props.markedElement + 1 : undefined}
              noRowsRenderer={this.noRowsRenderer}
              rowHeight={this.getRowHeight}
              rowRenderer={this.rowRenderer}
              rowGetter={rowGetter}
              rowCount={items.length}
              onRowClick={this.selectItem}
              onRowDoubleClick={this.onDoubleClick}
              rowClassName="ea-vt-row"
              width={width}
              sortBy={sortBy}
              sortDirection={sortDirection}
              sort={this.onSort}
            >
              {defaultColumns.concat(
                columns.map((column) => (
                  <Column
                    key={column.dataKey}
                    headerRenderer={HeaderRenderer((column as any).filterElement)}
                    {...column}
                  />
                )),
              )}
            </Table>
          )}
        </AutoSizer>
      </Container>
    );
  }

  _getDatum = (list: any, index: any) => {
    const data = this.props.items[index % list.length];
    return data;
  };

  headerRenderer = ({ dataKey, sortBy, sortDirection }: any) => {
    return (
      <div>
        Full Name
        {sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
      </div>
    );
  };

  noRowsRenderer = () => {
    return (
      <MessageContainer>
        <h4>No data</h4>
      </MessageContainer>
    );
  };
}

const SortableTable = SortableContainer(VirtualTable, { withRef: true }) as any;

export default class SortableComponent extends React.PureComponent<TableProperty, any> {
  onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (this.props.moveTo && oldIndex !== newIndex) {
      const { items } = this.props;
      this.props.moveTo({ id: (items[oldIndex] as any).id, from: oldIndex, to: newIndex });
      // todo: generic in react properties?
    }
  };

  render() {
    if (this.props.isLoading) {
      return <SavingArea isSaving label="Loading..." />;
    }

    return (
      <SortableTable
        {...this.props}
        helperClass={dragClass}
        disableAnimation={true}
        onSortEnd={this.onSortEnd}
        getContainer={(wrappedInstance) =>
          ReactDOM.findDOMNode((wrappedInstance as any).table.Grid) as any
        }
      />
    );
  }
}
