import { ApplicationState } from "@app/modules/app.reducers";
import { getGlobalExcelsTableActions } from "@app/modules/globalExcels/globalExcels.actions";
import { globalExcelsDataSelectors } from "@app/modules/globalExcels/globalExcels.selectors";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIdProp, DataTestIds } from "@app/utils/dataTestIds";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { unpackErrorMessage } from "@ea/shared_components/utils/error";
import styled from "@emotion/styled";
import { Modal } from "antd";
import * as React from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { toast } from "react-toastify";
import { Dispatch, bindActionCreators } from "redux";
import { API } from "../../services/api/api";
import { getProjectsChildrenMap, getProjectsTreeSelector } from "../projects/projects.selectors";
import { PROJECTS_TABLES_CONFIG } from "../projects/projects.tables";
import CreateEditGlobalExcelContainer from "./components/CreateEditGlobalExcel.container";
import { GlobalExcelUsedInPanel } from "./components/GlobalExcelUsedInPanel";
import CommandBar from "./components/GlobalExcelsCommandBar";
import { GLOBAL_EXCELS_COLUMNS, GLOBAL_EXCELS_TABLES_CONFIG } from "./globalExcels.table";
enum Panels {
  CREATE = "create",
  EDIT = "edit",
  USED_IN = "used_in",
}

interface IGlobalExcelsTableState {
  openedPanel?: Panels;
  persistentQuery: any;
}
interface IGlobalExcelsTableProps {
  projectId?: number;
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

const { confirm } = Modal;
class GlobalExcelsTableContainer extends React.Component<
  IGlobalExcelsTableProps & IConnectProps,
  IGlobalExcelsTableState
> {
  connectedTable: any;

  state = {
    openedPanel: undefined,
    persistentQuery: undefined,
  };

  componentDidMount() {
    if (this.props.projectId) {
      this.setState({
        persistentQuery: {
          projectId: this.props.projectId,
        },
      });
    }
  }

  componentDidUpdate(prevProps: IGlobalExcelsTableProps) {
    if (prevProps.projectId !== this.props.projectId) {
      this.setState({
        persistentQuery: {
          projectId: this.props.projectId,
        },
      });
    }
  }

  onAddClick = () => {
    this.setState({ openedPanel: Panels.CREATE });
  };
  onEditClick = () => {
    const projectsWhereUserHasNoAccess = (this.props.selectedGlobalExcel?.projectIds || []).filter(
      (projectIdAssignedToExcel) =>
        this.props.projectsChildrenMap[projectIdAssignedToExcel] === undefined,
    );
    if (projectsWhereUserHasNoAccess.length > 0) {
      toast.info(
        <FormattedMessage id={getTranslationKey("messages", "info", "editGlobalExcel")} />,
      );
    }

    this.setState({ openedPanel: Panels.EDIT });
  };
  onUsedInClick = () => {
    this.setState({ openedPanel: Panels.USED_IN });
  };

  closePanel = () => {
    this.setState({
      openedPanel: undefined,
    });
    this.onReloadClick();
  };

  onReloadClick = () => {
    if (!!this.connectedTable?.reload) {
      this.connectedTable.reload();
    }
  };

  onRemoveClick = () => {
    const { actions, selected, intl } = this.props;
    const { formatMessage } = intl;
    confirm({
      title: formatMessage({
        id: getTranslationKey(
          "messages",
          "confirm",
          selected.length > 1 ? "delGlobalExcels" : "delGlobalExcel",
        ),
      }),
      okText: formatMessage({ id: getTranslationKey("button", "delete") }),
      okType: "danger",
      cancelText: formatMessage({ id: getTranslationKey("button", "cancel") }),
      onOk: () => {
        actions.delete({ ids: selected });
      },
      onCancel() {},
      okButtonProps: { [`${DataTestIdProp}`]: DataTestIds.MODAL_BUTTON_DELETE } as any,
      cancelButtonProps: { [`${DataTestIdProp}`]: DataTestIds.MODAL_BUTTON_CANCEL } as any,
    });
  };

  onDownloadClick = async () => {
    const { selectedGlobalExcel } = this.props;

    if (selectedGlobalExcel) {
      const { originalFilename, name, id } = selectedGlobalExcel;
      try {
        API.downloadFile({ id, originalFilename, fileName: name });
      } catch (err) {
        console.error(unpackErrorMessage(err));
        return;
      }
    }
  };

  render() {
    const { openedPanel, persistentQuery } = this.state;
    const {
      selected,
      selectedGlobalExcel,
      projectsChildrenMap,
      preferencesId,
      tableId,
      projectId,
    } = this.props;

    return (
      <Container>
        <CommandBar
          onAddClick={this.onAddClick}
          onEditClick={this.onEditClick}
          isEditDisabled={!selectedGlobalExcel}
          onReloadClick={this.onReloadClick}
          onRemoveClick={this.onRemoveClick}
          onUsedInClick={this.onUsedInClick}
          onDownloadClick={this.onDownloadClick}
          selected={selected}
        />
        <CreateEditGlobalExcelContainer
          visibility={openedPanel !== undefined}
          onClose={this.closePanel}
          onReload={this.onReloadClick}
          selectedExcel={openedPanel === Panels.EDIT ? selectedGlobalExcel : undefined}
          isEdit={openedPanel === Panels.EDIT}
          projects={this.props.projectTree}
          projectsChildrenMap={projectsChildrenMap}
          projectId={projectId}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={GLOBAL_EXCELS_COLUMNS}
          persistentQuery={persistentQuery}
          tableId={tableId}
          preferencesId={preferencesId}
          stateKey={"globalExcels"}
          tableActions={getGlobalExcelsTableActions}
        />
        <GlobalExcelUsedInPanel
          visible={openedPanel === Panels.USED_IN}
          onCancel={this.closePanel}
          datasourceId={selectedGlobalExcel?.id}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: IGlobalExcelsTableProps) => {
  const tableId = props.projectId
    ? GLOBAL_EXCELS_TABLES_CONFIG.PROJECT_ASSIGNMENT.id(props.projectId)
    : GLOBAL_EXCELS_TABLES_CONFIG.MAIN.id();
  return {
    actions: {
      ...bindActionCreators(getGlobalExcelsTableActions(tableId), dispatch),
    },
  };
};

const mapStateToProps = (state: ApplicationState, props: IGlobalExcelsTableProps) => {
  const tableId = props.projectId
    ? GLOBAL_EXCELS_TABLES_CONFIG.PROJECT_ASSIGNMENT.id(props.projectId)
    : GLOBAL_EXCELS_TABLES_CONFIG.MAIN.id();
  const preferencesId = props.projectId
    ? GLOBAL_EXCELS_TABLES_CONFIG.PROJECT_ASSIGNMENT.preferencesId
    : GLOBAL_EXCELS_TABLES_CONFIG.MAIN.preferencesId;

  return {
    selected: globalExcelsDataSelectors.getSelectedSelector(state, tableId),
    selectedGlobalExcel: globalExcelsDataSelectors.getSelectedItemSelector(state, tableId),
    projectTree: getProjectsTreeSelector(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    projectsChildrenMap: getProjectsChildrenMap(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    tableId,
    preferencesId,
  };
};
const connectCreator = connect(mapStateToProps, mapDispatchToProps);

type IConnectProps = ConnectedProps<typeof connectCreator> & InjectedIntlProps;

export default connectCreator(injectIntl(GlobalExcelsTableContainer));
