import { objectValues } from "@ea/shared_components/utils/object";

import * as kpisActions from "./kpis.actions";
import kpisEpicsMap from "./kpis.epics";
import * as kpisReducers from "./kpis.reducers";

const kpisEpics = objectValues(kpisEpicsMap);

export { kpisActions, kpisReducers, kpisEpics };

export { default as KpisTableContainer } from "./KpisTable.container";
