import {
  createDataReducer,
  getDataReducerInitialState,
  getTableParamsWithoutInitialData,
  setTableParams,
} from "@ea/shared_components/redux/reducers/common.data.reducer";
import { moveTo } from "@ea/shared_components/utils/array";

import { SchedulerJobMapping } from "@ea/shared_types/types";
import { moduleActions } from "./schedulerFlow.actions";

const initialState = {
  ...getDataReducerInitialState<SchedulerJobMapping>(),
};

export type SchedulerFlowReducer = typeof initialState;

const dataReducer = createDataReducer<SchedulerJobMapping>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState).case(
  moduleActions.table.moveTo,
  (state, payload) => {
    const { from, to } = payload;

    const tableParams = getTableParamsWithoutInitialData<any, {}, any>(state, payload);
    const newState = {
      ...state,
      items: {
        ...state.items,
      },
    };

    const newOrder = moveTo(tableParams.order, from, to);

    for (let index = 0; index < newOrder.length; index++) {
      const id = newOrder[index];

      if (newState.items[id].lineNum !== index + 1) {
        newState.items[id] = {
          ...newState.items[id],

          lineNum: index + 1,
        };
      }
    }

    return setTableParams<any, {}, any>(newState, payload, {
      ...tableParams,
      order: newOrder,
    });
  },
);
