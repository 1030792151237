import * as PropTypes from "prop-types";
import * as React from "react";
import { RouterChildContext } from "react-router";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { NumberSequence } from "@ea/shared_types/next/ea.types";
import CreateSequenceForm from "@app/modules/sequences/components/CreateSequenceForm";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { API } from "@app/services/api/api";

interface CreateEditSequenceContainerProps {
  visibility: boolean;
  onCreate?: (sequence: NumberSequence) => void;
  onEdit?: (sequence: NumberSequence) => void;
  onClose: () => void;

  selected?: NumberSequence;
  isEdit?: boolean;
}

// todo: global layout for all panel forms?
const formItemLayout = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};
class CreateEditSequenceContainer extends React.Component<CreateEditSequenceContainerProps> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  context: RouterChildContext<any>;

  editSequence = async (values) => {
    const { onEdit, onClose } = this.props;

    const result = await API.editSequence(values);
    onEdit?.(result);
    onClose();
  };

  createSequence = async (values) => {
    const { onCreate, onClose } = this.props;
    const result = await API.createSequence(values);

    if (onCreate) {
      onCreate(result);
    }

    onClose();
  };

  render() {
    const { visibility, isEdit, onClose, selected } = this.props;
    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.SMALL}
        headerText={getTranslationKey("sequences", "header", isEdit ? "edit" : "create")}
        onCancelClick={onClose}
        onOkClick={isEdit ? this.editSequence : this.createSequence}
        cancelButtonText={getTranslationKey("button", "close")}
        key={getTranslationKey("button", isEdit ? "edit" : "create")}
        okButtonText={getTranslationKey("button", isEdit ? "save" : "create")}
        initialValues={isEdit ? selected : undefined}
        render={() => (
          <FormLayout {...formItemLayout}>
            <CreateSequenceForm edit={isEdit} initialValues={isEdit ? selected : undefined} />
          </FormLayout>
        )}
      />
    );
  }
}

export default CreateEditSequenceContainer;
