import * as React from "react";
import { RouteComponentProps, withRouter, Route } from "react-router";
import { Tabs } from "antd";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";

const Tab = Tabs.TabPane;

export enum UnknownRoute {
  Unknown = "UnkownRoute",
}

export type RouteTabParams<T> = {
  path?: string;
  value: T;
  label: string;
  render: ({ params }) => React.ReactNode;
};

interface IRouteTabsProps<T> {
  tabs: RouteTabParams<T>[];
  urlPart: number;
  onChange: (key: T | UnknownRoute) => void;

  renderUnknown?: () => React.ReactNode;
  renderRightBar?: (key: T | UnknownRoute) => React.ReactNode;
  defaultActiveKey?: T | UnknownRoute;
}

interface IRouteTabsState<T> {
  activeKey: T | UnknownRoute;
}

const Bar = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "60px",
});

const SectionContainer = styled.div({
  padding: "5px",
  marginBottom: "20px",
  width: "100%",
  height: "100%",
  maxHeight: "calc(100% - 60px)",
  display: "flex",
});

class RouteTabs<T> extends React.Component<
  IRouteTabsProps<T> & RouteComponentProps<any>,
  IRouteTabsState<T>
> {
  componentWillMount() {
    this.setState({
      activeKey: this.props.defaultActiveKey || this.getPivotKey(),
    });
  }

  componentDidUpdate(prevProps: IRouteTabsProps<T> & RouteComponentProps<any>) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        activeKey: this.getPivotKey(),
      });
    }
  }

  navigate = (item: T) => {
    const { history, match } = this.props;

    history.push(`${match.url}${item ? "/" : ""}${item}`);

    this.setState({
      activeKey: item,
    });
  };

  getPivotKey = () => {
    const { tabs, urlPart } = this.props;

    const pathChunks = location.pathname.split("/");
    const chunk: T = (pathChunks.length > urlPart ? pathChunks[urlPart] : "") as any;

    const paths = tabs.map((tab) => tab.value);

    if (paths.indexOf(chunk) === -1) {
      return UnknownRoute.Unknown;
    }

    return chunk;
  };

  defaultRenderUnkown() {
    return (
      <div>
        {" "}
        <FormattedMessage id={getTranslationKey("navigation", "unknownRoute")} />{" "}
      </div>
    );
  }

  renderSection() {
    const { tabs, match } = this.props;

    return tabs.map((t) => (
      <Route
        exact
        key={t.label}
        path={`${match.url}/${t.path || t.value}`}
        render={() => t.render({ params: match.params })}
      />
    ));
  }

  render() {
    const { tabs, renderRightBar } = this.props;
    const { activeKey } = this.state;

    return (
      <>
        <Bar>
          <Tabs onChange={this.navigate as any} activeKey={activeKey as any} animated={false}>
            {tabs.map((t) => (
              <Tab
                tab={
                  <span data-testid={DataTestIds.getTabTestId(t.label)}>
                    <FormattedMessage id={t.label} />
                  </span>
                }
                key={t.value as any}
              />
            ))}
          </Tabs>
          {renderRightBar && renderRightBar(activeKey)}
        </Bar>
        <SectionContainer>{this.renderSection()}</SectionContainer>
      </>
    );
  }
}

export default withRouter(RouteTabs);
