import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router";

import { ApplicationState } from "@app/modules/app.reducers";

interface IPrivateRouteProps {
  component: any;
}

class PublicRoute extends React.Component<IPrivateRouteProps & IConnectProps & RouteProps, any> {
  render() {
    const { component: Component, isLoggedIn, location, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (
            isLoggedIn &&
            location &&
            location.pathname !== "/login" &&
            location.pathname !== "/login/" &&
            location.pathname !== "/auth" &&
            location.pathname !== "/auth/"
          ) {
            return null;
          }
          return <Component {...props} />;
        }}
      />
    );
  }
}

const connectCreator = connect((state: ApplicationState) => ({ ...state.auth }));

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(PublicRoute);
