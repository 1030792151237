import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";

import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { DataTestIds } from "@app/utils/dataTestIds";

interface GlobalVariablesCommandBarProps {
  onAddClick: () => void;
  onCreateAndAddClick: () => void;
  onReloadClick: () => void;
  onUnassignClick: () => void;
  selected: number[];
}

class GlobalVariablesCommandBar extends React.Component<GlobalVariablesCommandBarProps> {
  public render() {
    const { onAddClick, onCreateAndAddClick, onUnassignClick, onReloadClick, selected } =
      this.props;

    return (
      <CommandBar data-testid={DataTestIds.COMMANDBAR_GLOBAL_VARIABLES}>
        <CommandBarButton
          name="add"
          onClick={onAddClick}
          text={getTranslationKey("commandBar", "assign")}
          icon="PlusOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_NEW}
        />
        <CommandBarButton
          name="createAndAdd"
          onClick={onCreateAndAddClick}
          text={getTranslationKey("commandBar", "createAndAssign")}
          icon="PlusCircleOutlined"
          disabled={false}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_CREATE_AND_ADD}
        />
        <CommandBarButton
          name="unassign"
          onClick={onUnassignClick}
          text={getTranslationKey("commandBar", "unassign")}
          icon="MinusOutlined"
          disabled={selected.length === 0}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_UNASSIGN}
        />
        <CommandBarButton
          name="refresh"
          onClick={onReloadClick}
          text={getTranslationKey("commandBar", "refresh")}
          icon="ReloadOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
        />
      </CommandBar>
    );
  }
}

export default GlobalVariablesCommandBar;
