import * as React from "react";
import { FieldRenderProps } from "react-final-form";

import { LayoutContext } from "../FormLayoutContext";
import FormItem, { FieldStatus, IFormItemProps } from "./FormItem";

export const AUDIT_TRAIL_MODE_ERROR = "AUDIT_TRAIL_MODE_ERROR";

export type IFormItemWrapperProps = FieldRenderProps<any>["meta"] & {
  formItem?: Partial<IFormItemProps>;
} & { children: (props: { readOnly?: boolean }) => React.ReactNode };

export const getValidationStatus = ({ touched, error, valid }: FieldRenderProps<any>["meta"]) => {
  if (error === AUDIT_TRAIL_MODE_ERROR) {
    return FieldStatus.ERROR;
  }

  if (!touched) {
    return FieldStatus.NONE;
  }

  if (error) {
    return FieldStatus.ERROR;
  }

  if (valid) {
    return FieldStatus.SUCCESS;
  }

  return FieldStatus.WARNING;
};

export const FormItemWrapper: React.FunctionComponent<IFormItemWrapperProps> = ({
  children,
  formItem,
  ...meta
}) => {
  const normalized = formItem ? formItem : {};
  const { showHelp, status, help, ...rest } = normalized;
  const itemStatus = meta.error ? getValidationStatus(meta) : status;

  return (
    <LayoutContext.Consumer>
      {(layout) => (
        <FormItem
          {...layout}
          {...rest}
          showHelp={showHelp || (meta.error && meta.touched)}
          status={itemStatus}
          help={meta.error || help}
        >
          {children({ readOnly: layout.readOnly })}
        </FormItem>
      )}
    </LayoutContext.Consumer>
  );
};

export default FormItemWrapper;
