import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { Localization } from "@ea/shared_types/types";
import * as React from "react";
import { GetAsTime } from "@app/modules/common/time";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { Tag } from "antd";
import { COLORS } from "@ea/shared_components/styles/consts";
import { FormattedMessage } from "react-intl";

export const LOCALIZATION_COLUMNS_CONFIG: PlainObject<CustomColumn<Localization>> = {
  locale: {
    label: getTranslationKey("table", "name"),
    dataIndex: "locale",
    sortable: true,
    render: (text, record, index) =>
      record.needsAttention ? (
        <div>
          {text}{" "}
          <Tag color={COLORS.STATUS.ERROR}>
            <FormattedMessage id={getTranslationKey("localization", "needsTranslation")} />
          </Tag>
        </div>
      ) : (
        text
      ),

    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  description: {
    label: getTranslationKey("table", "description"),
    dataIndex: "description",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  createdAt: {
    label: getTranslationKey("table", "createdAt"),
    dataIndex: "createdAt",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "createdAt" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
  updatedAt: {
    label: getTranslationKey("table", "updatedAt"),
    dataIndex: "updatedAt",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "updatedAt" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
};

export const LOCALIZATION_COLUMNS: ColumnConfig<Localization>[] = [
  {
    props: LOCALIZATION_COLUMNS_CONFIG.locale,
    frameworkProps: {
      width: "25%",
    },
  },
  {
    props: LOCALIZATION_COLUMNS_CONFIG.description,
    frameworkProps: {
      width: "25%",
    },
  },
  {
    props: LOCALIZATION_COLUMNS_CONFIG.createdAt,
    frameworkProps: {
      width: "25%",
    },
  },
  {
    props: LOCALIZATION_COLUMNS_CONFIG.updatedAt,
    frameworkProps: {
      width: "25%",
    },
  },
];

export const LOCALIZATION_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_LOCALIZATION",
  },
};
