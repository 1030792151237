import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { VirtualUserPool } from "@ea/shared_types/types";
import styled from "@emotion/styled";
import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch, bindActionCreators } from "redux";
import VirtualUserPoolNewPanelContainer from "./VirtualUserPoolNewPanel.container";
import VirtualUserPoolCommandBar from "./components/VirtualUserPoolCommandBar";
import { getVirtualUserPoolTableActions, virtualUserPoolActions } from "./virtualUserPool.actions";
import { virtualUserPoolDataSelectors } from "./virtualUserPool.selectors";
import {
  VIRTUAL_USER_POOL_COLUMNS,
  VIRTUAL_USER_POOL_TABLES_CONFIG,
} from "./virtualUserPool.table";

interface IUserGroupsTableProps {}

interface IUserGroupsTableState {
  createWindowVisibility: boolean;
  editWindowVisibility: boolean;
  persistentQuery: any;
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

class UserGroupsTableContainer extends React.Component<
  IUserGroupsTableProps & IConnectProps & RouteComponentProps<any>,
  IUserGroupsTableState
> {
  connectedTable: any;

  state: IUserGroupsTableState = {
    createWindowVisibility: false,
    editWindowVisibility: false,
    persistentQuery: {},
  };

  openCreateWindow = () => {
    this.setState({
      createWindowVisibility: true,
    });
  };

  closeCreateWindow = () => {
    this.setState({
      createWindowVisibility: false,
    });
  };
  openEditWindow = () => {
    this.setState({
      editWindowVisibility: true,
    });
  };

  closeEditWindow = () => {
    this.setState({
      editWindowVisibility: false,
    });
    this.reload();
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
    this.reload();
  };

  openSelected = () => {
    if (this.props.selectedVirtualUserPool) {
      this.openDetails(this.props.selectedVirtualUserPool);
    }
  };

  openDetails = (item?: VirtualUserPool) => {
    const { selectedVirtualUserPool } = this.props;
    const selectedItem = item || selectedVirtualUserPool;
    if (selectedItem) {
      this.props.history.push(`virtualUserPool/${selectedItem.id}`);
    }
  };

  render() {
    return (
      <Container>
        <VirtualUserPoolCommandBar
          onNewClick={this.openCreateWindow}
          onRemoveClick={this.remove}
          selected={this.props.selected}
          onReload={this.reload}
          onEditClick={this.openEditWindow}
          onOpen={this.openSelected}
        />
        <VirtualUserPoolNewPanelContainer
          onOk={this.props.actions.reload}
          onClose={this.state.editWindowVisibility ? this.closeEditWindow : this.closeCreateWindow}
          visibility={this.state.createWindowVisibility || this.state.editWindowVisibility}
          selected={this.props.selectedVirtualUserPool}
          isEdit={this.state.editWindowVisibility}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          persistentQuery={this.state.persistentQuery}
          columnsConfig={VIRTUAL_USER_POOL_COLUMNS}
          tableId={VIRTUAL_USER_POOL_TABLES_CONFIG.MAIN.id()}
          preferencesId={VIRTUAL_USER_POOL_TABLES_CONFIG.MAIN.preferencesId}
          stateKey="virtualUserPool"
          tableActions={getVirtualUserPoolTableActions}
          onRowDoubleClick={this.openDetails}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(virtualUserPoolActions, dispatch),
    ...bindActionCreators(
      getVirtualUserPoolTableActions(VIRTUAL_USER_POOL_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ),
  },
});

const connectCreator = connect(
  (state: ApplicationState) => ({
    selected: virtualUserPoolDataSelectors.getSelectedSelector(
      state,
      VIRTUAL_USER_POOL_TABLES_CONFIG.MAIN.id(),
    ),
    selectedVirtualUserPool: virtualUserPoolDataSelectors.getSelectedItemSelector(
      state,
      VIRTUAL_USER_POOL_TABLES_CONFIG.MAIN.id(),
    ),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(UserGroupsTableContainer));
