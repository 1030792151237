import * as React from "react";
import {
  Step,
  Script,
  VirtualUser,
  RecorderStep,
  RunnerMode,
  AssertActions,
  INITIAL_STRUCTURE_VERSION,
  ComparisonsOperators,
  ConditionTypes,
  AssertionType,
  AssignedVariableType,
} from "@ea/shared_types/types";
import { Modal, Spin } from "antd";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { getTranslationKey as localesGetTranslationKey } from "@ea/shared_types/translations";
import { withTranslation, WithTranslation } from "react-i18next";
import RecorderStepsCommandBar from "./RecorderStepsCommandBar";
import {
  RunnerPostMessages,
  RunnerPostMessageActions,
  RunnerPostSetInitialState,
} from "@ea/shared_types/runner.common.types";
import { Dispatch, bindActionCreators } from "redux";
import { getRunnerTableActions } from "../runner.actions";
import { ApplicationState } from "@app/modules/app.reducers";
import { connect, ConnectedProps } from "react-redux";
import {
  getPlayerScriptData,
  isNestedStepSelectedSelector,
  runnerDataSelectors,
  getRootSteps,
} from "../runner.selectors";
import { variableActions, VARIABLES_SECTIONS } from "@app/modules/variables/variables.actions";
import { Project, System } from "@ea/shared_types";
import RunnerStepsContainer from "../RunnerSteps.container";
import RecorderControlPanel from "./RecorderControlPanel";
import NewStepContainer from "./NewStep.container";
import { isSame } from "@ea/shared_components/utils/array";
import { CoreCommandsIds, CommandConditionType } from "@ea/shared_types/core.commands.types";
import BaseModalForm from "@ea/shared_components/ModalForm/BaseModalForm";
import InspectForm from "./InspectForm";
import { detectAndCreateNewVariables } from "@app/modules/variables/variables.helpers";
import { variableDataSelectors } from "@app/modules/variables/variables.selectors";
import { postMessageToEaApp } from "../communication/iframes.communication";
import { runnerCommunicator } from "@ea/shared_types/utils/iframes.communication";
import {
  IFrameRunnerSyncMessages,
  IFrameRunnerAsyncMessages,
} from "@ea/shared_types/communication.types";
import { buildLocalScriptVariables } from "../runner.helpers";
import { ActivityAction } from "@ea/shared_types/newRunner.types";
import { DEFAULT_PLATFORM_ID } from "@ea/runner_loader/ea.internal.types";
import { drawStepOverlayOnStepSelect } from "../communication/iframes.utils";
import {
  RunnerBarContainer,
  RunnerDataContainer,
  SpinnerContainer,
} from "../RunnerComponentLayout";
import { DataTestIds, DataTestIdProp } from "@app/utils/dataTestIds";
import { getCodeTemplatesTableActions } from "@app/modules/codeTemplates/codeTemplates.actions";
import { CODE_TEMPLATES_TABLES_CONFIG } from "@app/modules/codeTemplates/codeTemplates.table";

const { confirm } = Modal;

interface IRecorderManagerProps {
  sessionId: string;
  script: Script;
  project: Project;
  system?: System;
  systems: System[];
  virtualUser?: VirtualUser;
  virtualUsers: VirtualUser[];
  toggleStepEdit: () => void;
  toggleDataView: () => void;
  isRecording: boolean;
  isDataViewVisible: boolean;
  isEditingStep: boolean;
  loadIFrame: () => void;
  mode: RunnerMode;
  onModeToggle: () => void;
  onDrawingOverlayToggle: () => void;
  isDrawingOverlayEnabled: boolean;
  minimalistic: boolean;
  runnerLoadedFirstTime: boolean;
}

interface IRecorderManagerState {
  openedPanel: string | undefined;
  isDragEnable: boolean;
  isInspecting: boolean;
  inspectModalVisible: boolean;
  newInspectStep?: RecorderStep;
  isExpandedEditModeOn: boolean;
  isLoadingController: boolean;
}

type ComponentProps = IRecorderManagerProps & WithTranslation & InjectedIntlProps;
class RecorderManager extends React.Component<IConnectProps, IRecorderManagerState> {
  destroyMessageListener: any;
  connectedTable: any;

  constructor(props) {
    super(props);
    this.onMessage = this.onMessage.bind(this);
    this.state = {
      openedPanel: undefined,
      isDragEnable: false,
      isInspecting: false,
      inspectModalVisible: false,
      newInspectStep: undefined,
      isExpandedEditModeOn: false,
      isLoadingController: false,
    };
  }

  async componentDidMount() {
    this.props.actions.setPersistentQuery({ query: { sessionId: this.props.sessionId } });
    await this.loadController();
    this.handleCommunication();
    this.props.loadIFrame();
    this.props.codeTemplateActions.load({});
  }

  componentDidUpdate(prevProps) {
    drawStepOverlayOnStepSelect(this.props, prevProps);
  }

  loadController = async () => {
    try {
      // Blocking control panel is turned off because switching is very fast. Uncomment line below to enable it
      // this.setState({
      //   isLoadingController: true,
      // });

      await runnerCommunicator.administration.sendPromise(
        IFrameRunnerSyncMessages.load_controller,
        RunnerMode.RECORDER,
        "ALL",
      );
    } catch (err) {
      console.error(err);
    }

    this.setState({
      isLoadingController: false,
    });
  };

  shouldComponentUpdate(nextProps: IConnectProps, nextState: IRecorderManagerState) {
    return (
      !isSame(this.props.selectedItems, nextProps.selectedItems) ||
      this.props.minimalistic !== nextProps.minimalistic ||
      this.props.selectedItem?.id !== nextProps.selectedItem?.id ||
      this.props.sessionId !== nextProps.sessionId ||
      this.props.isDataViewVisible !== nextProps.isDataViewVisible ||
      this.props.isEditingStep !== nextProps.isEditingStep ||
      this.props.isDrawingOverlayEnabled !== nextProps.isDrawingOverlayEnabled ||
      this.state.isLoadingController !== nextState.isLoadingController ||
      this.state.openedPanel !== nextState.openedPanel ||
      this.state.isDragEnable !== nextState.isDragEnable ||
      this.state.isInspecting !== nextState.isInspecting ||
      this.state.inspectModalVisible !== nextState.inspectModalVisible ||
      this.state.isExpandedEditModeOn !== nextState.isExpandedEditModeOn ||
      this.props.mode !== nextProps.mode
    );
  }

  componentWillMount() {
    runnerCommunicator.administration.registerMessageHandlerPromise(
      IFrameRunnerSyncMessages.get_initial_state,
      {
        key: "getInitialState",
        callback: async () => {
          return {
            isRecording: this.props.isRecording,
            isInspecting: this.state.isInspecting,
            script: this.props.scriptData,
          };
        },
      },
    );
  }

  componentWillUnmount() {
    if (this.state.isInspecting) {
      runnerCommunicator.administration.send(IFrameRunnerAsyncMessages.toggle_inspect, {}, "ALL");
    }
  }

  handleCommunication = () => {
    runnerCommunicator.administration.registerMessageHandler<IFrameRunnerAsyncMessages.step_recorded>(
      IFrameRunnerAsyncMessages.step_recorded,
      {
        key: "stepRecorded",
        callback: (step) => {
          // todo: R2.0 why ts hints string | number | recorded step?
          if (step.commandId === CoreCommandsIds.inspect) {
            const newInspectStep = { ...step };
            // set null to assignedVariableId to ensure empty initial variable value of inspect form
            newInspectStep.value.code.assignedVariableId =
              newInspectStep.value.code.assignedVariableId || null;
            newInspectStep.value.code.assignedVariableType = AssignedVariableType.LOCAL;
            newInspectStep.label = this.props.t(
              localesGetTranslationKey("recorder", "variableModal", "getValue"),
            );
            this.setState({ inspectModalVisible: true, newInspectStep });
            return;
          }

          this.props.actions.addNewStep({ newStep: step });
        },
      },
    );
  };

  onMessage(data: RunnerPostMessages) {
    switch (data.action) {
      case RunnerPostMessageActions.get_initial_state:
        const message: RunnerPostSetInitialState = {
          action: RunnerPostMessageActions.set_initial_state,
          source: "runner_child",
          value: { isRecording: this.props.isRecording, isInspecting: this.state.isInspecting },
        };
        postMessageToEaApp(message);
        return;
      case RunnerPostMessageActions.step_recorded:
        if (data.value.commandId === CoreCommandsIds.inspect) {
          this.setState({ inspectModalVisible: true, newInspectStep: data.value });
          return;
        }
        const newStep = data.value;
        this.props.actions.addNewStep({ newStep });
        return;
      default:
        return;
    }
  }

  onStepsRemove = (ids: (number | string)[]) => {
    const { intl, actions } = this.props;
    const { deleteWithUnselect } = actions;

    const length = ids.length;

    confirm({
      title: intl.formatMessage({
        id:
          length > 1
            ? getTranslationKey("messages", "confirm", "delSteps")
            : getTranslationKey("messages", "confirm", "delStep"),
      }),
      okText: intl.formatMessage({ id: getTranslationKey("button", "delete") }),
      okType: "danger",
      cancelText: intl.formatMessage({ id: getTranslationKey("button", "cancel") }),
      onOk: () => {
        deleteWithUnselect({ ids });
      },
      onCancel() {},
      okButtonProps: { [`${DataTestIdProp}`]: DataTestIds.MODAL_BUTTON_DELETE } as any,
      cancelButtonProps: { [`${DataTestIdProp}`]: DataTestIds.MODAL_BUTTON_CANCEL } as any,
    });
  };

  setOpenPanel = (panel) =>
    this.setState({
      openedPanel: panel,
    });

  toggleDragMode = () => {
    this.setState({
      isDragEnable: !this.state.isDragEnable,
    });
  };

  toggleDisable = (value: boolean) => {
    const { selectedItems } = this.props;

    selectedItems.forEach((item) => {
      this.props.actions.updateStep({ step: { ...item, isDisabled: value } });
    });
  };

  toggleOptional = (value: boolean) => {
    const { selectedItems } = this.props;

    selectedItems.forEach((item) => {
      this.props.actions.updateStep({ step: { ...item, isOptional: value } });
    });
  };

  onMarkClick = () => {
    const { selectedItem, rootSteps } = this.props;
    const recordingPath = selectedItem?.execution.path || rootSteps[0].execution.path;
    this.props.actions.setRunnerRecorderParams({
      recordingPath,
    } as any);
  };

  onRecord = () => {
    runnerCommunicator.administration.send(IFrameRunnerAsyncMessages.start_recording, {}, "ALL");

    this.props.actions.setRunnerParams({ isRecording: true });
  };

  onPause = () => {
    runnerCommunicator.administration.send(IFrameRunnerAsyncMessages.pause_recording, {}, "ALL");

    this.setState({ isInspecting: false });

    this.props.actions.setRunnerParams({ isRecording: false });
  };

  syncSteps = () => {
    this.props.actions.syncSteps({});
  };

  closePanel = () => this.setOpenPanel(undefined);

  onInspect = () => {
    runnerCommunicator.administration.send(IFrameRunnerAsyncMessages.toggle_inspect, {}, "ALL");

    this.setState((state) => ({ isInspecting: !state.isInspecting }));
  };

  onInspectStepCreate = async (
    step: Step & {
      conditionStep: {
        label: string;
        createConditionalStep: boolean;
        condition: { ifAction: ActivityAction; elseAction: ActivityAction };
      };
    },
  ) => {
    const { script, actions } = this.props;
    const { conditionStep, ...editedStep } = step;
    const { condition, createConditionalStep, label } = conditionStep;

    const createdVariables = await detectAndCreateNewVariables(
      editedStep.value,
      editedStep.commandId,
      script.id,
      editedStep.platform.id,
    );

    if (createdVariables) {
      actions.variable.loadLocal(script.id);
      const localVars = buildLocalScriptVariables(createdVariables);
      actions.updateLiveVariables({ variables: localVars });
    }

    actions.addNewStep({ newStep: editedStep });

    if (createConditionalStep) {
      actions.addNewStep({
        newStep: {
          structureVersion: INITIAL_STRUCTURE_VERSION,
          temporaryStepId: 0,
          label,
          commandId: CoreCommandsIds.condition,
          paths: [],
          platform: {
            id: DEFAULT_PLATFORM_ID,
          },
          value: {
            comparison: [
              {
                type: AssertionType.CHECK_VALUE,
                leftCondition: {
                  type:
                    editedStep.value.code.assignedVariableType === AssignedVariableType.GLOBAL
                      ? ConditionTypes.GLOBAL_MUTABLE
                      : ConditionTypes.VARIABLE,
                  value: editedStep.value.code.assignedVariableId,
                },
                rightCondition: {
                  type: ConditionTypes.CONST,
                  value: editedStep.value.originalValue,
                },
                operator: ComparisonsOperators.EQUAL,
              },
            ],
            ifAction: condition.ifAction,
            elseAction: condition.elseAction,
          },
        } as RecorderStep<CommandConditionType>,
      });
    }

    this.setState({ inspectModalVisible: false, newInspectStep: undefined });
  };

  afterCreateStep = async (newStep) => {
    this.props.actions.addNewStep({ newStep, specificLineNum: newStep.lineNum });
  };

  toggleEditExpandedRows = () => {
    this.setState((state) => ({ isExpandedEditModeOn: !state.isExpandedEditModeOn }));
  };

  render() {
    const {
      sessionId,
      project,
      script,
      system,
      systems,
      virtualUsers,
      virtualUser,
      toggleStepEdit,
      toggleDataView,
      selectedArray,
      variables,
      isDataViewVisible,
      isNestedStepSelected,
      mode,
      onModeToggle,
      isDrawingOverlayEnabled,
      onDrawingOverlayToggle,
      isEditingStep,
      rootSteps,
      minimalistic,
      runnerLoadedFirstTime,
    } = this.props;
    const {
      isDragEnable,
      openedPanel,
      isInspecting,
      inspectModalVisible,
      newInspectStep,
      isExpandedEditModeOn,
      isLoadingController,
    } = this.state;

    if (!sessionId) {
      return "No session";
    }

    return (
      <>
        <RunnerBarContainer>
          {!runnerLoadedFirstTime ||
            (isLoadingController && (
              <SpinnerContainer>
                <Spin />
              </SpinnerContainer>
            ))}

          <RecorderControlPanel
            script={script}
            project={project}
            sessionId={sessionId}
            isInspecting={isInspecting}
            onInspect={this.onInspect}
            onRecord={this.onRecord}
            onPause={this.onPause}
            onSave={this.syncSteps}
            onModeToggle={onModeToggle}
            mode={mode}
            minimalistic={minimalistic}
          />
        </RunnerBarContainer>
        <RunnerDataContainer>
          {!minimalistic && (
            <>
              <NewStepContainer
                script={script}
                project={project}
                system={system}
                systems={systems}
                virtualUser={virtualUser}
                virtualUsers={virtualUsers}
                sessionId={sessionId}
                close={this.closePanel}
                openedPanel={openedPanel}
              />
              <RecorderStepsCommandBar
                isEditingStep={isEditingStep}
                openPanel={this.setOpenPanel}
                isDragEnabled={isDragEnable}
                selected={selectedArray}
                onMarkClick={this.onMarkClick}
                onRemoveClick={this.onStepsRemove}
                toggleDragMode={this.toggleDragMode}
                toggleDisable={this.toggleDisable}
                toggleOptional={this.toggleOptional}
                toggleDataView={toggleDataView}
                isDataViewVisible={isDataViewVisible}
                toggleEditExpandedRows={this.toggleEditExpandedRows}
                isExpandedEditModeOn={isExpandedEditModeOn}
                quickActionsDisabled={isNestedStepSelected}
                newStepDisabled={isNestedStepSelected}
                onDrawingOverlayToggle={onDrawingOverlayToggle}
                isDrawingOverlayEnabled={isDrawingOverlayEnabled}
                disabled={mode === RunnerMode.PLAYER}
              />
              <RunnerStepsContainer
                sessionId={sessionId}
                isDragEnabled={isDragEnable}
                script={script}
                isExpandedEditModeOn={isExpandedEditModeOn}
                systems={systems}
                system={system}
                virtualUsers={virtualUsers}
                toggleEdit={toggleStepEdit}
              />
            </>
          )}
          <BaseModalForm
            visible={inspectModalVisible}
            onCancelClick={() => this.setState({ inspectModalVisible: false })}
            onOkClick={this.onInspectStepCreate}
            allowPrisitineSubmit={true}
            initialValues={{
              conditionStep: {
                label: this.props.intl.formatMessage({
                  id: getTranslationKey("step", "createStep", "conditional", "defaultLabel"),
                }),
                condition: {
                  ifAction: {
                    values: [],
                    type: AssertActions.CONTINUE,
                  },
                  elseAction: {
                    values: [],
                    type: AssertActions.WARNING,
                  },
                },
              },
              ...newInspectStep,
            }}
            headerText={getTranslationKey("runner", "inspect", "header")}
            cancelButtonText={getTranslationKey("button", "cancel")}
            okButtonText={getTranslationKey("button", "create")}
            render={({ change, values }) => (
              <InspectForm
                steps={rootSteps}
                script={script}
                change={change}
                values={values}
                variables={variables}
              />
            )}
          />
        </RunnerDataContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: ComponentProps) => ({
  actions: {
    ...bindActionCreators(getRunnerTableActions(props.sessionId), dispatch),
    variable: {
      ...bindActionCreators(variableActions, dispatch),
    },
  },
  codeTemplateActions: {
    ...bindActionCreators(
      getCodeTemplatesTableActions(CODE_TEMPLATES_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ),
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: ComponentProps) => ({
    selectedItem: runnerDataSelectors.getSelectedItemSelector(state, props.sessionId),
    selectedItems: runnerDataSelectors.getSelectedItemsSelector(state, props.sessionId),
    selectedArray: runnerDataSelectors.getSelectedSelector(state, props.sessionId),
    isNestedStepSelected: isNestedStepSelectedSelector(state, props.sessionId),
    variables: variableDataSelectors.getOrderedDataSelector(state, VARIABLES_SECTIONS.LOCAL),
    scriptData: getPlayerScriptData(state, props.sessionId),
    rootSteps: getRootSteps(state, props.sessionId),
    ...props,
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withTranslation()(injectIntl(connectCreator(RecorderManager)));
