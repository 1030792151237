import { createDataSelectors } from "@app/modules/app.reducers";
import { IssueTrackingToolConfiguration } from "@ea/shared_types/types";
import { createSelector } from "reselect";

export const itsConfigurationsDataSelectors =
  createDataSelectors<IssueTrackingToolConfiguration>()("itsConfigurations");

export const getItsConfigurationOptions = createSelector(
  itsConfigurationsDataSelectors.getOrderedDataSelector,
  (configurations) =>
    configurations
      .filter((c) => !!c.integrationMetadata?.azureDevops?.id)
      .map((c) => ({
        text: `[${c.integrationMetadata.azureDevops.project}] ${c.name}`,
        value: c.id,
        project: c.integrationMetadata.azureDevops.project!,
      })),
);
