import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { Role, Token } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("APIKEY_MODULE");

export const moduleActions = createDataModuleActions<Token>(actionCreator);
export const getTokensTableActions = createTableActionsGetter(
  normalizeActions(moduleActions.table),
);
export const apiKeyActions = {
  ...normalizeActions(moduleActions.data),
};
export const apiKeyActionCreators = {
  ...moduleActions,
};
