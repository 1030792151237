import { Step } from "@ea/shared_types/types";
import { getWebCommandsPack } from "@ea/runner_loader/commands.loader";
import { DEFAULT_PLATFORM_ID } from "@ea/runner_loader/ea.internal.types";
import { getWebPlatforms } from "@ea/runner_loader/runner.loader";
import { toast } from "react-toastify";

export function getStepCommand(value: Pick<Step, "commandId" | "platform">) {
  const commandsPack = getWebCommandsPack(value.platform.id);
  return commandsPack[value.commandId];
}

export function getCreateableManually(platform?: string) {
  const commandsPack = getWebCommandsPack(platform || DEFAULT_PLATFORM_ID);
  const createableCommands = Object.keys(commandsPack)
    .map((k) => commandsPack[k])
    .filter((command) => command.createableManually);

  return createableCommands;
}

export function getValidatedPlatforms(platformId?: string) {
  const platforms = getWebPlatforms()
    .map((p) => {
      if (!p.getFriendlyName) {
        toast.info(`Platform with id: ${p.id} has been loaded incorrectly.`);
      }
      return p;
    })
    .filter((p) => p.getFriendlyName);

  if (platformId) {
    const platform = platforms.find((p) => p.id === platformId);

    if (!platform) {
      throw new Error(`Couldn't find a platform with id: ${platformId}`);
    }

    return [platform];
  }

  return platforms.sort((a, b) => {
    if (a.id === DEFAULT_PLATFORM_ID) {
      return -1;
    }
    if (b.id === DEFAULT_PLATFORM_ID) {
      return 1;
    }

    const aName = a.getFriendlyName();
    const bName = b.getFriendlyName();

    if (aName === bName) {
      return 0;
    }
    return aName < bName ? -1 : 1;
  });
}
