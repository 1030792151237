import "rxjs/add/observable/merge";
import "rxjs/add/operator/mergeMap";

import { Action, Store } from "redux";
import { ActionsObservable } from "redux-observable";

import { ApplicationState, makeAsyncEpic } from "@app/modules/app.reducers";
import { stepsDataSelectors } from "@app/modules/steps/steps.selectors";
import { STEPS_TABLE_ID } from "@app/modules/steps/steps.table";
import { API } from "@app/services/api/api";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";

import { guardsActionCreators } from "@app/modules/guards/guards.actions";
import { scriptsActions } from "../scripts/scripts.actions";
import { variableActions } from "../variables/variables.actions";
import { stepsActionCreators } from "./steps.actions";

export const loadStepsEpic = makeAsyncEpic(stepsActionCreators.load, async (payload, state) => {
  const tableParams = stepsDataSelectors.getParamsSelector(state, payload.tableId);
  const params = createRequestParams(
    tableParams,
    {},
    {
      defaultOrder: [`lineNum ASC`],
    },
  );
  params.filter = {
    ...params.filter,
    include: [{ relation: "Guard" }, { relation: "CodeTemplate" }],
  };
  const response = await API.getSteps(params, payload.reload ? { cache: "reload" } : undefined);

  return response as any; // TODO: NEW_TYPES
});

export const pasteStepsEpic = makeAsyncEpic(
  stepsActionCreators.pasteSteps,
  async (payload) => await API.pasteSteps(payload as any), // TODO: NEW_TYPES
);

export const createStepEpic = makeAsyncEpic(stepsActionCreators.create, async (payload) => {
  const createdStep = await API.createStep(payload.item as any); // TODO: NEW_TYPES

  return createdStep;
});

export const deleteStepEpic = makeAsyncEpic(stepsActionCreators.delete, async (payload) => {
  const result = await API.deleteSteps({ ids: payload.ids });
  return true;
});

export const editStepEpic = makeAsyncEpic(stepsActionCreators.edit, async (payload) => {
  const result = await API.editStep(payload as any); // TODO: NEW_TYPES

  return result;
});

export const reloadItemsEpic = (
  action$: ActionsObservable<Action>,
  store: Store<ApplicationState>,
) =>
  action$
    .ofType(stepsActionCreators.setPersistentQuery)
    .map((_: any) => stepsActionCreators.load.started({ tableId: _.payload.tableId }));

export const reloadStepsEpic = (
  action$: ActionsObservable<Action>,
  store: Store<ApplicationState>,
) =>
  action$
    .ofType(
      stepsActionCreators.delete.done,
      stepsActionCreators.create.done,
      stepsActionCreators.moveTo.done,
      guardsActionCreators.data.commitCreate,
      guardsActionCreators.data.commitEdit,
      guardsActionCreators.table.delete.done,
    )
    .map((_) =>
      stepsActionCreators.load.started({
        tableId: STEPS_TABLE_ID,
      }),
    );

export const reloadGuardOnDeleteEpic = (
  action$: ActionsObservable<Action>,
  store: Store<ApplicationState>,
) =>
  action$
    .ofType(stepsActionCreators.delete.done)
    .map((_) => guardsActionCreators.data.invalidate({}));

export const moveToEpic = makeAsyncEpic(stepsActionCreators.moveTo, async (payload) => {
  const result = await API.moveToStep({
    id: payload.id,
    lineNum: payload.to + 1,
  });

  return true;
});

export const onPasteStepsEpic = (
  action$: ActionsObservable<Action>,
  store: Store<ApplicationState>,
) =>
  action$.ofType(stepsActionCreators.pasteSteps.done).mergeMap((_) => [
    stepsActionCreators.load.started({
      tableId: STEPS_TABLE_ID,
    }),
    variableActions.loadLocal(
      store.getState().steps.params[STEPS_TABLE_ID].persistentQuery.taskScriptId,
    ),
    scriptsActions.loadSingle({
      id: store.getState().steps.params[STEPS_TABLE_ID].persistentQuery.taskScriptId,
    }),
  ]);
