import { Script, Log } from "@ea/shared_types/types";

export const normalizeScript = (item: Script) => {
  const itemClone = { ...item };
  if ((item as any).environment) {
    itemClone.envName = (item as any).environment.envName;
    itemClone.envType = (item as any).environment.envType;
    itemClone.startUrl = (item as any).environment.envAddress;
    delete (itemClone as any).environment;
  }
  if ((item as any).appUser) {
    itemClone.creator = (item as any).appUser.username;
    delete (itemClone as any).appUser;
  }
  return itemClone;
};

export const normalizeLog = (item: Log) => {
  const itemClone = { ...item };
  if (item.scheduler) {
    itemClone.schedulerJobName = item.scheduler.name;
    delete itemClone.scheduler;
  }
  if (item.datasource) {
    itemClone.datasourcePath = item.datasource.name;
    delete itemClone.datasource;
  }
  if (item.apiKey) {
    itemClone.apiKeyName = item.apiKey.name;
    delete itemClone.apiKey;
  }
  return itemClone;
};
