export function objectValues<T extends object>(obj: T): Array<any> {
  return Object.keys(obj).map((key) => obj[key]);
}
export function isObjectEmpty(obj: any) {
  return !Object.keys(obj).length;
}

const dateObjectPattern = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
export function jsonDateReviver(key: string, value: string) {
  if (typeof value == "string" && dateObjectPattern.exec(value)) {
    return new Date(value);
  }
  return value;
}
