import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { ReportTemplate } from "@ea/shared_types/types";
import * as React from "react";
import { GetAsTime } from "@app/modules/common/time";
import { getTranslationKey } from "@app/translations/translations.helpers";

export const REPORT_TEMPLATES_COLUMNS_CONFIG: PlainObject<CustomColumn<ReportTemplate>> = {
  name: {
    label: getTranslationKey("table", "name"),
    dataIndex: "name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  originalFilename: {
    label: getTranslationKey("table", "filename"),
    dataIndex: "originalFilename",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  description: {
    label: getTranslationKey("table", "description"),
    dataIndex: "description",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  createdAt: {
    label: getTranslationKey("table", "createdAt"),
    dataIndex: "createdAt",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "createdAt" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
  updatedAt: {
    label: getTranslationKey("table", "updatedAt"),
    dataIndex: "updatedAt",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "updatedAt" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
};

export const REPORT_TEMPLATES_COLUMNS: ColumnConfig<ReportTemplate>[] = [
  {
    props: REPORT_TEMPLATES_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: REPORT_TEMPLATES_COLUMNS_CONFIG.originalFilename,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: REPORT_TEMPLATES_COLUMNS_CONFIG.description,
    frameworkProps: {
      width: "30%",
    },
  },
  {
    props: REPORT_TEMPLATES_COLUMNS_CONFIG.createdAt,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: REPORT_TEMPLATES_COLUMNS_CONFIG.updatedAt,
    frameworkProps: {
      width: "20%",
    },
  },
];

export const REPORT_TEMPLATES_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_REPORT_TEMPLATES",
  },
};
