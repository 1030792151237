export enum ColumnFilters {
  "TEXT" = "TEXT",
  "SELECT" = "SELECT",
  "DATE_RANGE" = "DATE_RANGE",
  "RANGE" = "RANGE",
  "TREE" = "TREE",
}
export type ColumnFilterType = keyof typeof ColumnFilters;
export type ColumnFilterConfig = {
  filterKey?: string;
  filterType: ColumnFilterType;
  values?: { label: string; value: string }[];
  converter?: (value: any) => any;
  noValueLabel?: string;
};

export type Filter<T> = { fieldName: keyof T; fieldValue: any };
export type Filters<T> = {
  filters: Filter<T>[];
};
