import { VariablesGroup } from "../../redux/common.models";
import { Variable, CodeType, CodeTemplateWithGroup } from "@ea/shared_types/types";
import { IPosition } from "@timkendrick/monaco-editor";
import { createVersionKey } from "@ea/shared_types/helpers/codeTemplates.helpers";

const generateDeclarationForVariableGroupVariables = (variables) =>
  variables.map((variable) => `${variable.name}: any`).join(",\n");
const generateDeclarationForVariableGroupGroups = (groups: VariablesGroup[]) => {
  return groups
    .map((group) => {
      if (group.variables) {
        return `
      ${group.id}: {
        ${generateDeclarationForVariableGroupVariables(group.variables)}
      }`;
      }
      if (group.groups) {
        return `
        ${group.id}: {
          ${generateDeclarationForVariableGroupGroups(group.groups)}
        }`;
      }
      return "";
    })
    .join(",\n");
};

export const generateDeclarationForVariablesGroup = ({ variables, groups, id }: VariablesGroup) => {
  const variablesBody =
    variables && variables.length > 0
      ? generateDeclarationForVariableGroupVariables(variables)
      : "";
  const groupsBody =
    groups && groups.length > 0 ? generateDeclarationForVariableGroupGroups(groups) : "";
  const declaration = `
    declare const ${id} = {
      ${[variablesBody, groupsBody].filter((v) => v !== "").join(",\n")}
    }
  `;

  return declaration;
};
export const generateDeclarationForCodeTemplates = (codeTemplates: CodeTemplateWithGroup[]) => {
  const createCodeTemplatesMap = (codeTemplates: CodeTemplateWithGroup[]) =>
    codeTemplates.reduce((container, codeTemplate) => {
      const groupName = codeTemplate.codeTemplateGroup.name;
      if (!container[groupName]) {
        container[groupName] = {};
      }

      container[groupName][codeTemplate.functionName] =
        codeTemplate.definitions[createVersionKey(codeTemplate.activeVersion)];
      return container;
    }, {});
  const codeTemplatesGroupMap = createCodeTemplatesMap(codeTemplates);
  const codeTemplateGroupNames = Object.keys(codeTemplatesGroupMap);
  const declaration = `declare const CodeTemplates = {${codeTemplateGroupNames
    .map((groupName) => {
      return `${groupName}: {${Object.keys(codeTemplatesGroupMap![groupName])
        .map((functionName) => {
          return `${functionName}: (${(
            codeTemplatesGroupMap![groupName][functionName].params || []
          ).map((a) => a.name)}) => any`;
        })
        .join(",")}}`;
    })
    .join(",")}}`;

  return declaration;
};

export const generateDeclarationForVariables = (variables: Variable[]) => {
  return variables.map((variable) => `declare const ${variable.name}: any;`).join(",\n");
};

// This function assumes that in expression mode value looks like: "`\nUSER_INPUT\n`"
// and in advanced mode: "function awxExecuteVariableValue() { \nUSER_INPUT\n}"
export const getStartingPosition = (value: string, mode: CodeType): IPosition => {
  let column;
  let lineNumber;
  const lines = value.split("\n");
  if (mode === "EXPRESSION") {
    lineNumber = 2;
  } else {
    lineNumber = lines.length - 1;
  }
  column = lines[lines.length - 2].length + 1;
  return { column, lineNumber };
};

export const loadMonaco = (callback: () => void = () => {}) => {
  const context = window as any;
  if (context.monaco !== undefined) {
    callback();
    return;
  }

  context.require.config({
    url: "/public/vs/loader.js",
    paths: {
      vs: "/public/vs",
    },
  });

  // Load monaco
  context.require(["/public/vs/editor/editor.main"], () => {
    callback();
  });
};

export function loadMonacoLib(lib, name) {
  const libArray = lib.split("\n");
  // we need to remove the last two lines to delete an export line in the declaration
  libArray.splice(libArray.length - 2, 2);

  const disposeFunction = monaco.languages.typescript.javascriptDefaults.addExtraLib(
    libArray.join("\n"),
    name,
  );

  return disposeFunction;
}
