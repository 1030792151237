import { RouterState } from "connected-react-router";
import { Reducer } from "redux";

import * as aggregatedJobLogsReducers from "@app/modules/logs/AggregatedJobLogs/aggregatedJobLogs.reducers";
import * as stepLogsReducers from "@app/modules/logs/StepLogs/stepLogs.reducers";
import * as logsReducers from "@app/modules/logs/logs.reducers";
import * as usersReducers from "@app/modules/users/users.reducers";

import * as apiKeyReducers from "@app/modules/apiKey/apiKey.reducers";
import * as auditTrailReducers from "@app/modules/auditTrail/auditTrail.reducers";
import * as auditTrailScriptOverviewReducers from "@app/modules/auditTrail/script/auditTrailScriptOverview.reducers";
import * as codeTemplateGroupsReducers from "@app/modules/codeTemplateGroups/codeTemplateGroups.reducers";
import * as codeTemplatesReducers from "@app/modules/codeTemplates/codeTemplates.reducers";
import * as documentationReducers from "@app/modules/documentation/documentation.reducers";
import * as globalExcelsReducers from "@app/modules/globalExcels/globalExcels.reducers";
import * as globalVariablesReducers from "@app/modules/globalVariables/globalVariables.reducers";
import * as guardReducers from "@app/modules/guards/guards.reducers";
import * as kpisReducers from "@app/modules/kpis/kpis.reducers";
import * as localizationReducers from "@app/modules/localization/localization.reducers";
import * as projectCategoryReducers from "@app/modules/projectCategory/projectCategory.reducers";
import * as projectsReducers from "@app/modules/projects/projects.reducers";
import * as reportTemplatesReducers from "@app/modules/reportTemplate/reportTemplates.reducers";
import * as roleReducers from "@app/modules/roles/roles.reducers";
import * as runnerReducers from "@app/modules/runner/runner.reducers";
import * as schedulerReducers from "@app/modules/scheduler/scheduler.reducers";
import * as schedulerFlowReducers from "@app/modules/schedulerFlow/schedulerFlow.reducers";
import * as sequencesReducers from "@app/modules/sequences/sequences.reducers";
import * as storageReducers from "@app/modules/storage/storage.reducers";
import * as systemDictionaryReducers from "@app/modules/systemDictionary/systemDictionary.reducers";
import * as tagReducers from "@app/modules/tags/tag.reducers";
import * as userGroupReducers from "@app/modules/userGroups/userGroups.reducers";
import * as virtualUsersReducers from "@app/modules/virtualUsers/virtualUsers.reducers";
import * as authReducer from "./auth/auth.reducers";
import * as globalSettingsReducers from "./globalSettings/globalSettings.reducers";
import * as itsReducers from "./issueTrackingTool/its.reducers";
import * as itsConfigurationsReducers from "./issueTrackingToolConfigurations/itsConfigurations.reducers";
import * as itsRulesReducers from "./issueTrackingToolRules/itsRules.reducers";
import * as scriptChangelogReducers from "./scriptChangelog/scriptChangelog.reducers";
import * as scriptsReducers from "./scripts/scripts.reducers";
import * as stepsReducers from "./steps/steps.reducers";
import * as variablesReducers from "./variables/variables.reducers";
import * as virtualUserPoolReducers from "./virtualUserPool/virtualUserPool.reducers";
import * as workItemReducers from "./workItems/workItem.reducers";

import { createModuleEpics as createModuleEpicsCreator } from "@ea/shared_components/redux/common.epic";
import { createDataSelectors as createDataSelectorsCreator } from "@ea/shared_components/redux/common.selectors";
import { makeAsyncEpic as makeAsyncEpicCreator } from "@ea/shared_components/redux/makeAsyncEpic";
import { DataApplicationState as RootDataApplicationState } from "@ea/shared_components/redux/state";

export interface DataApplicationState extends RootDataApplicationState {
  scripts: scriptsReducers.ScriptsReducer;
  steps: stepsReducers.StepsReducer;
  users: usersReducers.UserReducer;
  logs: logsReducers.LogsReducer;
  stepLogs: stepLogsReducers.StepLogsReducer;
  aggregatedJobLogs: aggregatedJobLogsReducers.AggregatedJobLogsReducer;
  scheduler: schedulerReducers.SchedulerReducer;
  systemDictionary: systemDictionaryReducers.SystemReducer;
  storage: storageReducers.StorageReducer;
  sequences: sequencesReducers.SequencesReducer;
  virtualUsers: virtualUsersReducers.VirtualUsersReducer;
  guards: guardReducers.GuardReducer;
  apiKey: apiKeyReducers.ApiKeyReducer;
  projects: projectsReducers.ProjectReducer;
  variables: variablesReducers.VariableReducer;
  userGroups: userGroupReducers.UserGroupReducer;
  roles: roleReducers.RoleReducer;
  projectCategory: projectCategoryReducers.ProjectCategoryReducer;
  its: itsReducers.IstReducer;
  itsRules: itsRulesReducers.ItsRulesReducer;
  itsConfigurations: itsConfigurationsReducers.ItsConfigurationsReducer;
  workItems: workItemReducers.WorkItemReducer;
  scriptChangelog: scriptChangelogReducers.ScriptChangelogReducer;
  tag: tagReducers.TagReducer;
  auditTrail: auditTrailReducers.AuditTrailReducer;
  auditTrailScriptOverview: auditTrailScriptOverviewReducers.AuditTrailScriptOverviewReducer;
  documentation: documentationReducers.DocumentationReducer;
  localization: localizationReducers.LocalizationReducer;
  reportTemplates: reportTemplatesReducers.ReportTemplatesReducer;
  globalVariables: globalVariablesReducers.GlobalVariablesReducer;
  runner: runnerReducers.RunnerReducer;
  kpis: kpisReducers.KpisReducer;
  codeTemplateGroups: codeTemplateGroupsReducers.CodeTemplateGroupReducer;
  schedulerFlow: schedulerFlowReducers.SchedulerFlowReducer;
  codeTemplates: codeTemplatesReducers.CodeTemplatesReducer;
  globalExcels: globalExcelsReducers.GlobalExcelsReducer;
  virtualUserPool: virtualUserPoolReducers.VirtualUserPoolReducer;
}

export interface ApplicationState extends DataApplicationState {
  auth: authReducer.AuthReducer;
  router: RouterState;
  globalSettings: globalSettingsReducers.GlobalSettingsReducer;
}

export const createModuleEpics = createModuleEpicsCreator<ApplicationState>();
export const makeAsyncEpic = makeAsyncEpicCreator<ApplicationState>();
export const createDataSelectors = createDataSelectorsCreator<
  DataApplicationState,
  ApplicationState
>();

type ApplicationReducer = { [P in keyof ApplicationState]: Reducer<ApplicationState[P]> };

export const reducers: Omit<ApplicationReducer, "router"> = {
  auth: authReducer.reducer,
  scripts: scriptsReducers.reducer,
  steps: stepsReducers.reducer,
  users: usersReducers.reducer,
  variables: variablesReducers.reducer,
  logs: logsReducers.reducer,
  stepLogs: stepLogsReducers.reducer,
  aggregatedJobLogs: aggregatedJobLogsReducers.reducer,
  scheduler: schedulerReducers.reducer,
  systemDictionary: systemDictionaryReducers.reducer,
  storage: storageReducers.reducer,
  sequences: sequencesReducers.reducer,
  virtualUsers: virtualUsersReducers.reducer,
  guards: guardReducers.reducer,
  apiKey: apiKeyReducers.reducer,
  projects: projectsReducers.reducer,
  userGroups: userGroupReducers.reducer,
  roles: roleReducers.reducer,
  projectCategory: projectCategoryReducers.reducer,
  its: itsReducers.reducer,
  itsRules: itsRulesReducers.reducer,
  itsConfigurations: itsConfigurationsReducers.reducer,
  workItems: workItemReducers.reducer,
  scriptChangelog: scriptChangelogReducers.reducer,
  tag: tagReducers.reducer,
  auditTrail: auditTrailReducers.reducer,
  auditTrailScriptOverview: auditTrailScriptOverviewReducers.reducer,
  globalSettings: globalSettingsReducers.reducer,
  documentation: documentationReducers.reducer,
  localization: localizationReducers.reducer,
  runner: runnerReducers.reducer,
  reportTemplates: reportTemplatesReducers.reducer,
  globalVariables: globalVariablesReducers.reducer,
  kpis: kpisReducers.reducer,
  codeTemplateGroups: codeTemplateGroupsReducers.reducer,
  schedulerFlow: schedulerFlowReducers.reducer,
  codeTemplates: codeTemplatesReducers.reducer,
  globalExcels: globalExcelsReducers.reducer,
  virtualUserPool: virtualUserPoolReducers.reducer,
};
