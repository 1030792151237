import styled from "@emotion/styled";
import hoistNonReactStatics from "hoist-non-react-statics";
import * as React from "react";
import { getHOCName } from "@ea/shared_components/utils/react";

type InjectedProps = {};

type OwnProps = {
  onClick: () => void;
  disabled?: boolean;
  pressed?: boolean;
  style?: any;
};

export const IconBase = styled.div(
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ({ disabled, pressed }: { disabled?: boolean; pressed?: boolean }) =>
    disabled
      ? {
          cursor: "not-allowed",
          opacity: 0.8,
        }
      : pressed
      ? {
          cursor: "progress",
        }
      : {
          cursor: "pointer",
          ":hover": {
            opacity: 0.8,
          },
        },
);

const withIconButton = <OriginalProps extends object>(
  Component: React.ComponentType<OriginalProps & InjectedProps>,
) => {
  type Props = Omit<OriginalProps, keyof InjectedProps> & OwnProps;
  class WithIconButton extends React.Component<Props> {
    static displayName = getHOCName((WithIconButton as any).displayName, Component);
    static WrappedComponent = Component;

    render() {
      const { onClick, disabled, pressed, style } = this.props;
      return (
        <IconBase
          style={{ textAlign: "center", ...(style as any) }}
          onClick={disabled || pressed ? () => {} : onClick}
          disabled={disabled}
          pressed={pressed}
        >
          <Component {...this.props} />
        </IconBase>
      );
    }
  }

  return hoistNonReactStatics(WithIconButton, Component as any) as React.ComponentType<Props>;
};

export default withIconButton;
