import { createDataSelectors } from "@app/modules/app.reducers";
import { PlainObject, Project, SchedulerJobMapping, Script } from "@ea/shared_types/types";
import { createSelector } from "reselect";
import { projectSelectors } from "../projects/projects.selectors";
import { scriptsDataSelectors } from "../scripts/scripts.selectors";

export const schedulerFlowDataSelectors =
  createDataSelectors<SchedulerJobMapping>()("schedulerFlow");

export const schedulerFlowSelectorsModifier = (
  defaultSchedulerFlowDataSelector: typeof schedulerFlowDataSelectors,
) => ({
  ...defaultSchedulerFlowDataSelector,
  getOrderedDataSelector: createSelector(
    defaultSchedulerFlowDataSelector.getOrderedDataSelector,
    scriptsDataSelectors.getDataSelector,
    projectSelectors.getDataSelector,
    (
      schedulerFlow: SchedulerJobMapping[],
      scripts: PlainObject<Script>,
      projects: PlainObject<Project>,
    ) =>
      schedulerFlow.map((sf) => {
        const { isManualUrl, startUrl, envName, envType } = scripts[sf.taskScriptId] || {};
        return {
          ...sf,
          isManualUrl,
          url: startUrl,
          projectDefinition: sf.projectId && projects[sf.projectId],
          envName,
          envType,
        };
      }),
  ),
});
