import { getTranslationKey } from "@app/translations/translations.helpers";
import * as React from "react";

import { DataTestIds } from "@app/utils/dataTestIds";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { faLink } from "@fortawesome/fontawesome-free-solid";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
interface GlobalExcelsCommandBarProps {
  onAddClick: () => void;
  onEditClick: () => void;
  onReloadClick: () => void;
  onRemoveClick: () => void;
  isEditDisabled: boolean;
  selected: number[];
  onUsedInClick: () => void;
  onDownloadClick: () => void;
}

const GlobalExcelsCommandBar = ({
  onAddClick,
  onEditClick,
  isEditDisabled,
  onReloadClick,
  onRemoveClick,
  selected,
  onUsedInClick,
  onDownloadClick,
}: GlobalExcelsCommandBarProps) => (
  <CommandBar data-testid={DataTestIds.COMMANDBAR_GLOBAL_VARIABLES}>
    <CommandBarButton
      name="add"
      onClick={onAddClick}
      text={getTranslationKey("commandBar", "add")}
      icon="PlusOutlined"
      data-testid={DataTestIds.COMMANDBAR_BUTTON_NEW}
    />
    <CommandBarButton
      name="remove"
      onClick={onRemoveClick}
      text={getTranslationKey("commandBar", "remove")}
      icon="DeleteOutlined"
      disabled={selected.length === 0}
      data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE}
    />
    <CommandBarButton
      name="refresh"
      onClick={onReloadClick}
      text={getTranslationKey("commandBar", "refresh")}
      icon="ReloadOutlined"
      data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
    />
    <CommandBarButton
      name="edit"
      onClick={onEditClick}
      text={getTranslationKey("commandBar", "edit")}
      icon="EditOutlined"
      disabled={isEditDisabled || selected.length !== 1}
      data-testid={DataTestIds.COMMANDBAR_BUTTON_EDIT}
    />
    <CommandBarButton
      name="usedIn"
      onClick={onUsedInClick}
      disabled={!(selected.length === 1)}
      text={getTranslationKey("commandBar", "usedIn")}
      icon={<FontAwesomeIcon icon={faLink} />}
      data-testid={DataTestIds.COMMANDBAR_BUTTON_USED_IN}
    />
    <CommandBarButton
      name="download"
      onClick={onDownloadClick}
      disabled={!(selected.length === 1)}
      text={getTranslationKey("commandBar", "download")}
      icon={<FontAwesomeIcon icon={faLink} />}
      data-testid={DataTestIds.COMMANDBAR_BUTTON_DOWNLOAD}
    />
  </CommandBar>
);

export default GlobalExcelsCommandBar;
