import * as React from "react";

import { BreadcrumbSection, MainSection, Page } from "@ea/shared_components/common/LayoutElements";
import ReportsContainer from "@app/modules/reports/Reports.container";
import BreadcrumbContainer from "@ea/shared_components/common/Breadcrumb.container";

interface IScriptsPageProps {}

const ReportsPage: React.FunctionComponent<IScriptsPageProps> = (props) => {
  return (
    <Page>
      <BreadcrumbSection>
        <BreadcrumbContainer />
      </BreadcrumbSection>

      <MainSection>
        <ReportsContainer />
      </MainSection>
    </Page>
  );
};

export default ReportsPage;
