import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import * as React from "react";
interface IUserGroupFormProps {
  values: any;
}

const UserGroupForm: React.FunctionComponent<IUserGroupFormProps> = ({ values }) => {
  return (
    <>
      <InputField
        name="name"
        required
        placeholder={getTranslationKey("common", "placeholder", "name")}
        label={getTranslationKey("common", "label", "name")}
        data-testid={DataTestIds.FORM_INPUT_NAME}
      />
      <TextAreaField
        name="description"
        defaultValue={""}
        placeholder={getTranslationKey("common", "placeholder", "description")}
        label={getTranslationKey("common", "label", "description")}
        data-testid={DataTestIds.FORM_TEXTAREA_DESCRIPTION}
      />
    </>
  );
};

export default UserGroupForm;
