import React from "react";
import Hideable from "./Hideable";

export const IS_DOCUMENTATION_WIZARD = !(
  process.env.REACT_APP_DOCUMENTATION_WIZARD === undefined ||
  process.env.REACT_APP_DOCUMENTATION_WIZARD === "false"
);

type HideWhenDocumentationWizardProps = {
  children(injectedProps: any): JSX.Element | null;
};

class HideWhenDocumentationWizard extends React.Component<HideWhenDocumentationWizardProps> {
  render() {
    if (IS_DOCUMENTATION_WIZARD) {
      return null;
    }

    return this.props.children(this.props);
  }
}

const HideWhenDocumentationWizardHOC = Hideable(() => IS_DOCUMENTATION_WIZARD);

export { HideWhenDocumentationWizardHOC, HideWhenDocumentationWizard };
