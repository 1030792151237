import React from "react";

import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "./translations/translations.helpers";
import { ERROR_PAGE_BACKGOUND_COLOR, ERROR_PAGE_LOGO } from "./styles/styles";

const Container = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: ERROR_PAGE_BACKGOUND_COLOR,
  height: "100vh",
  flexDirection: "column",
});

const ImageContainer = styled.div({
  flex: 0.8,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const TextContainer = styled.div({
  color: "white",
  fontSize: 25,
  wordWrap: "break-word",
  flex: 0.2,
});

class ErrorPage extends React.Component<{}, any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error | null, info: object) {
    this.setState({ hasError: true });
  }

  detectIE() {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf("MSIE ");
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    const trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }

    // other browser
    return false;
  }

  logoContainer = () => (
    <ImageContainer>
      <div style={{ height: "250px" }}>
        <img src={ERROR_PAGE_LOGO} height={"100%"} width={"auto"} />
      </div>
    </ImageContainer>
  );

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          {this.logoContainer()}
          <TextContainer>
            <FormattedMessage id={getTranslationKey("messages", "info", "error")} />
          </TextContainer>
        </Container>
      );
    }

    if (this.detectIE()) {
      return (
        <Container>
          {this.logoContainer()}
          <TextContainer>
            <FormattedMessage id={getTranslationKey("messages", "info", "browserOutdated")} />
          </TextContainer>
        </Container>
      );
    }
    return this.props.children;
  }
}
export default ErrorPage;
