import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { Guard } from "@ea/shared_types/types";
import { moduleActions } from "@app/modules/guards/guards.actions";

const initialState = {
  ...getDataReducerInitialState<Guard>(),
};

export type GuardReducer = typeof initialState;

const dataReducer = createDataReducer<Guard>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
