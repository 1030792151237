export const ROUTES = {
  main: "/",
  scripts: "/scripts",
  login: "/login",
  history: "/history",
  scheduler: "/scheduler",
  settings: "/settings",
  projects: "/projects",
  reports: "/reports",
  documentation_tree: "/documentation_tree",
  documentation: "/documentation",
  runner: "/runner",
};
