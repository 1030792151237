import React from "react";
import SchedulerSelectScriptsForm from "@app/modules/scheduler/components/SchedulerFlowPanel/SchedulerSelectScriptsForm";
import { getTranslationKey } from "@app/translations/translations.helpers";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { PanelType } from "@ea/shared_components/Panel";
import { SchedulerJobMapping, Script } from "@ea/shared_types/types";
import { API } from "@app/services/api/api";

const createSchedulerJobMappingItem = (
  selectedScript,
  lineNum,
  schedulerJobId: number,
): Partial<SchedulerJobMapping> => ({
  name: selectedScript.name,
  schedulerJobId,
  taskScriptId: selectedScript.taskScriptId,
  lineNum,
  projectId: selectedScript.projectId,
  repeatCount: 1,
});

export const SchedulerFlowAddScriptsPanel = ({
  visible,
  onCancel,
  onOk,
  schedulerJobId,
  schedulerJobMapping,
}) => {
  const onOkClick = async (values: { schedulerScripts: Partial<Script>[] }) => {
    const startingLineNum = schedulerJobMapping.length + 1;

    const normalizedValues = values.schedulerScripts.map((s, index) =>
      createSchedulerJobMappingItem(s, startingLineNum + index + 1, schedulerJobId),
    );
    await API.createSchedulerJobMapping({
      schedulerJobId,
      schedulerJobMappings: [...schedulerJobMapping, ...normalizedValues],
    });
    onOk();
  };

  return (
    <PanelFormFinal
      visibility={visible}
      panelType={PanelType.XLARGE}
      headerText={getTranslationKey("scheduler", "flow", "addPanel", "addScripts")}
      onCancelClick={onCancel}
      onOkClick={onOkClick}
      allowPrisitineSubmit={true}
      cancelButtonText={getTranslationKey("button", "cancel")}
      okButtonText={getTranslationKey("button", "save")}
      render={(props) => <SchedulerSelectScriptsForm {...props} />}
    />
  );
};
