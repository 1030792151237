import { CheckOutlined } from "@ant-design/icons";
import { ApplicationState, createDataSelectors } from "@app/modules/app.reducers";
import { projectActions } from "@app/modules/projects/projects.actions";
import { projectSelectors } from "@app/modules/projects/projects.selectors";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import EditeableArea from "@ea/shared_components/EditeableArea/EditeableArea";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import ManyToMany from "@ea/shared_components/Table/ManyToMany";
import { ColumnConfig } from "@ea/shared_components/Table/common.tables";
import { isCurrentUserAdmin } from "@ea/shared_components/auth/auth.selectors";
import { isSame } from "@ea/shared_components/utils/array";
import { capitalizeFirstLetter } from "@ea/shared_components/utils/formatters";
import { Project } from "@ea/shared_types/types";
import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import { isRootProject } from "./projects.helpers";

// todo: improve typings
interface IProjectMapperContainerProps {
  projectId: number;
  relation: string;
  edit: (item: Partial<Project>) => Promise<Project>;
  stateKey: any;
  tableActions: (id: string) => any;
  columns: any;
  displayTableColumns?: any;
  flowTableId: string;
  flowPreferencesId: string;
  tableId: string;
  preferencesId: string;
  markDefault: boolean;
  displayTableSelectable?: boolean;
}

interface IProjectMapperContainerState {
  displayTablePersistentQuery: any;
}

const initialState = {
  displayTablePersistentQuery: { id: null },
};

const defaultColumn: ColumnConfig<any> = {
  props: {
    label: "table.default",
    dataIndex: "isDefault",
    render: (value) => (value ? <CheckOutlined data-testid={DataTestIds.ICON_CHECKED} /> : null),
  },
  frameworkProps: {
    width: "10%",
  },
};

class ProjectMapperContainer extends React.Component<
  IProjectMapperContainerProps & IConnectProps,
  IProjectMapperContainerState
> {
  displayTable;
  state: IProjectMapperContainerState = initialState;
  editAreaContainer: any;

  async componentDidMount() {
    const { project, projectId, loadSingle } = this.props;

    if (!projectId) {
      return;
    }

    if (!project) {
      loadSingle({ id: projectId });
      return;
    }

    this.setDisplayTableQuery();
  }

  componentDidUpdate(prevProps: IProjectMapperContainerProps & IConnectProps) {
    const { project, projectId, relation, loadSingle } = this.props;

    if (projectId !== prevProps.projectId || !projectId) {
      if (this.editAreaContainer) {
        this.editAreaContainer.cancel();
      }
      loadSingle({ id: projectId });
    }

    if (
      (prevProps.project === undefined && project !== undefined) ||
      (prevProps.project && project && !isSame(project[relation], prevProps.project[relation]))
    ) {
      this.setDisplayTableQuery();
    }

    if (prevProps.projectId !== this.props.projectId && this.displayTable) {
      this.displayTable.resetSelection();
    }
  }

  setDisplayTableQuery = () => {
    const { relation, project } = this.props;
    this.setState({
      displayTablePersistentQuery: {
        id:
          project[relation] && project[relation].length > 0
            ? { inq: project[relation].map((u) => u.id) }
            : { inq: [-1] },
      },
    });
  };

  compareQuery = (nextQuery, prevQuery) => {
    if (!(nextQuery.id && nextQuery.id.inq && prevQuery.id && prevQuery.id.inq)) {
      return nextQuery !== prevQuery;
    }

    return !isSame(nextQuery.id.inq, prevQuery.id.inq);
  };

  onOverrideChange = async () => {
    const { load, loadSingle, project, edit, relation, refreshChildBranch } = this.props;
    const toggleField = `override${relation.charAt(0).toUpperCase() + relation.slice(1)}`;
    const newOverrideValue = !project[toggleField];
    await edit({
      id: project.id,
      [toggleField]: newOverrideValue,
    });

    await loadSingle({ id: project.id });
  };

  onSave = async (item) => {
    const { edit, loadSingle } = this.props;
    await edit(item);

    await loadSingle({ id: item.id });
  };

  mapItems = (items) => {
    const { project } = this.props;

    if (!project) {
      return items;
    }

    const defaultField = this.getDefaultRelationField();
    const defaultValue = project[defaultField];

    return items.map((i) => ({
      ...i,
      isDefault: i.id === defaultValue,
    }));
  };

  getDefaultRelationField = () => {
    const { relation } = this.props;
    return `default${relation.charAt(0).toUpperCase() + relation.slice(1, relation.length - 1)}Id`;
  };

  onMarkDefault = async () => {
    const { load, loadSingle, project, edit, relation, selected, refreshChildBranch } = this.props;
    const defaultField = this.getDefaultRelationField();

    await edit({
      id: project.id,
      [defaultField]: selected.id,
    });

    await loadSingle({ id: project.id });
  };

  renderFlowEdit = (editProps) => {
    const { flowTableId, flowPreferencesId, columns, tableActions, relation, stateKey } =
      this.props;
    return (
      <ManyToMany
        {...editProps}
        pageable
        columnsConfig={columns}
        tableId={flowTableId}
        preferencesId={flowPreferencesId}
        stateKey={stateKey}
        parentItemId={this.props.projectId}
        parentItemStateKey="projects"
        tableActions={tableActions}
        loadParentItem={this.props.loadSingle}
        relationName={relation}
        onSave={this.onSave}
      />
    );
  };

  renderFlowDisplay = () => {
    const {
      tableId,
      preferencesId,
      columns,
      displayTableColumns,
      tableActions,
      stateKey,
      projectId,
      markDefault,
      displayTableSelectable,
    } = this.props;

    const overrideField = `override${capitalizeFirstLetter(this.props.relation)}`;
    const overrideValue = this.props.project[overrideField];
    let className = "";
    if (!overrideValue) {
      className += " table-readonly";
    }

    const columnsConfig = (displayTableColumns || columns).concat(
      markDefault ? [defaultColumn] : [],
    );

    return (
      <ConnectedTable
        selectable={displayTableSelectable !== undefined ? displayTableSelectable : true}
        multiselect={false}
        mapItems={this.mapItems}
        pageable
        setRef={(component) => (this.displayTable = component)}
        key={tableId}
        columnsConfig={columnsConfig}
        tableId={tableId}
        preferencesId={preferencesId}
        stateKey={stateKey}
        tableActions={tableActions}
        className={className}
        persistentQuery={this.state.displayTablePersistentQuery}
        comparePersistentQuery={this.compareQuery}
      />
    );
  };

  render() {
    if (!this.props.project) {
      return null;
    }

    const overrideField = `override${capitalizeFirstLetter(this.props.relation)}`;

    const isRoot = isRootProject(this.props.project);

    const markDefaultButton = this.props.markDefault ? (
      <CommandBarButton
        name="MarkDefault"
        text={getTranslationKey("commandBar", "markDefault")}
        onClick={this.onMarkDefault}
        size="default"
        icon="SwapOutlined"
        far
        disabled={!this.props.selected}
        data-testid={DataTestIds.COMMANDBAR_BUTTON_MARK_DEFAULT}
      />
    ) : null;

    const overrideButton = isRoot ? null : (
      <CommandBarButton
        name="Override"
        text={getTranslationKey("commandBar", "override")}
        onClick={this.onOverrideChange}
        size="default"
        icon="LockOutlined"
        checkable
        checked={this.props.project[overrideField]}
        checkedColor="#1890ff"
        data-testid={DataTestIds.COMMANDBAR_BUTTON_OVERRIDE}
      />
    );

    const renderCustomEditModeCommandButtons = (editCommandButton) => (
      <>
        {overrideButton}
        {React.cloneElement(editCommandButton, {
          disabled: !this.props.project[overrideField],
        })}
        {isRoot || this.props.project[overrideField] ? markDefaultButton : null}
      </>
    );

    return (
      <EditeableArea
        ref={(editArea) => (this.editAreaContainer = editArea)}
        areaContainerStyles={{ paddingTop: "0px", height: "100%" }}
        renderDisplayArea={() => this.renderFlowDisplay()}
        renderEditArea={this.renderFlowEdit}
        renderCustomEditModeCommandButtons={renderCustomEditModeCommandButtons}
        readOnly={!this.props.isAdmin}
      />
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: IProjectMapperContainerProps) => {
    const dataSelectors = createDataSelectors<any>()(props.stateKey);

    return {
      ...props,
      project: projectSelectors.getItemSelector(state, props.projectId),
      selected: dataSelectors.getSelectedItemSelector(state, props.tableId),
      isAdmin: isCurrentUserAdmin(state),
    };
  },
  {
    loadSingle: projectActions.loadSingle,
    load: projectActions.load,
    refreshChildBranch: projectActions.refreshChildBranch,
  },
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(ProjectMapperContainer);
