import styled from "@emotion/styled";
import { Button, Input, Tag, Tree } from "antd";
import * as React from "react";

import { CheckboxValueType } from "antd/lib/checkbox/Group";

import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Omit } from "@ea/shared_types/basic.types";
import { PROJECT_ROOT } from "@ea/shared_types/types";
import { NodeFormItem } from "../Form/Form.common";
import { DataTestIds } from "../utils/dataTestHelpers";
import { generateList, searchParentsByValue } from "../utils/tree";
export interface ITreeSearchProps {
  values: NodeFormItem[];
  onSelect?: (checked: string[]) => void | Promise<void>;
  onRemove?: (value: string | number) => void;
  selected: string[];
  defaultExpandedKeys?: string[];
  initialExpandedKeys?: string[];
  autoExpandOnNewItems?: boolean;
  multi?: boolean;
  withTags?: boolean;
  checkStrictly?: boolean;
  newNodeParentId?: number | undefined | typeof PROJECT_ROOT;
}

type ProjectListProps = {
  maxHeight: string;
};

interface ITreeSearchState {
  searchValue: string;
  autoExpandParent: boolean;
  expandedKeys: any[];
  flatValues: Omit<NodeFormItem, "children">[];
}

const Container = styled.div({
  width: "100%",
});

const ProjectsList = styled.div(
  {
    overflow: "auto",
  },
  (props: ProjectListProps) => ({ maxHeight: props.maxHeight }),
);
const TagText = styled.div({
  maxWidth: "310px",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const TagContainer = styled.div({
  maxHeight: "125px",
  overflowY: "auto",
  overflowX: "hidden",
  display: "flex",
  flexWrap: "wrap",
});

const TreeUtilitiesContainer = styled.div({
  display: "flex",
});

const Search = Input.Search;

class TreeSearch extends React.Component<ITreeSearchProps, ITreeSearchState> {
  manualExpandedKeys: any = [];

  constructor(props) {
    super(props);

    this.state = {
      searchValue: "",
      autoExpandParent: true,
      expandedKeys: [],
      flatValues: generateList(props.values),
    };
  }

  componentDidMount() {
    if (this.props.initialExpandedKeys) {
      this.setState({
        expandedKeys: this.props.initialExpandedKeys,
      });
    }
  }

  collapseAll = () => {
    this.setState({
      expandedKeys: [],
    });
  };

  expandAll = () => {
    const expandedKeys = this.state.flatValues.map((v) => v.id.toString());
    this.setState({
      expandedKeys,
    });
  };

  componentDidUpdate(prevProps: ITreeSearchProps) {
    if (prevProps.values !== this.props.values) {
      const oldDataList = this.state.flatValues;
      const newDataList = generateList(this.props.values);
      const diff = newDataList.filter(
        (n) => oldDataList.find((n2) => n2.id === n2.id) === undefined,
      );

      this.setState({
        flatValues: generateList(this.props.values),
        expandedKeys: this.props.autoExpandOnNewItems
          ? this.state.expandedKeys.concat(
              diff,
              this.props.newNodeParentId ? [this.props.newNodeParentId] : [],
            )
          : this.state.expandedKeys,
        autoExpandParent: true,
      });
    }

    if (prevProps.newNodeParentId !== this.props.newNodeParentId) {
      this.setState({
        autoExpandParent: true,
        expandedKeys: this.state.expandedKeys.concat(
          this.props.newNodeParentId ? [this.props.newNodeParentId] : [],
        ),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.values !== nextProps.values) {
      this.setState({
        flatValues: generateList(nextProps.values),
      });
    }
  }

  onSelect = (checked: string[] | { checked: string[] }): void => {
    this.manualExpandedKeys = this.state.expandedKeys;
    if (this.props.onSelect) {
      const normalizedChecked: string[] = this.props.checkStrictly
        ? (checked as any).checked
        : checked;
      this.props.onSelect(normalizedChecked);
    }
  };

  onExpand = (expandedKeys: Array<CheckboxValueType>): void => {
    this.manualExpandedKeys = expandedKeys;
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  onRemoveTag = (id: number) => {
    if (this.props.onRemove) {
      this.props.onRemove(id);
    }

    this.setState({
      autoExpandParent: false,
    });
  };

  filterTreeNode = (node: any): boolean => {
    const { searchValue } = this.state;
    if (!searchValue.length) {
      return false;
    }
    const nodeTitle = node.props.title;
    const isNewNode = !(typeof nodeTitle === "string" || nodeTitle instanceof String);

    return isNewNode
      ? false
      : nodeTitle
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(
            this.state.searchValue
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase(),
          );
  };

  onChangeSearch = (e) => {
    const searchValue = e.target.value;

    if (!searchValue.length) {
      return this.setState({
        expandedKeys: this.manualExpandedKeys,
        searchValue,
        autoExpandParent: false,
      });
    }

    const searchKeys = searchParentsByValue(
      this.state.flatValues,
      this.props.values,
      searchValue
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase(),
    ).map((key) => `${key}`);

    this.setState({
      expandedKeys: this.manualExpandedKeys.concat(searchKeys),
      searchValue,
      autoExpandParent: true,
    });
  };

  getTags = (checked: string[]) => {
    const { flatValues: dataList } = this.state;

    if (!dataList.length) {
      return null;
    }

    const items = checked
      .map((ch) => dataList.find((item) => item.id === parseInt(ch, 10)))
      .filter((a) => a); // in case of having no access to project

    if (items.length < 1) {
      return null;
    }
    return (
      <TagContainer>
        {items.map((item) => (
          <Tag
            closable
            key={item!.id}
            onClose={() => this.onRemoveTag(item!.id)}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TagText>{item!.name}</TagText>
          </Tag>
        ))}
      </TagContainer>
    );
  };

  render() {
    const { multi, selected, withTags, children, defaultExpandedKeys, checkStrictly } = this.props;
    const { expandedKeys, autoExpandParent } = this.state;

    const selectProps = multi
      ? {
          checkable: true,
          checkedKeys: selected,
          onCheck: this.onSelect as any, // antd typings bug,
          multiple: true,
        }
      : {
          onSelect: this.onSelect as any, // antd typings bug,
          selectedKeys: selected,
        };

    return (
      <Container>
        <TreeUtilitiesContainer>
          <Search
            style={{ marginBottom: 8 }}
            onChange={this.onChangeSearch}
            placeholder="Search projects"
            data-testid={DataTestIds.PROJECT_TREE_INPUT_SEARCH}
          />
          <Button
            icon={<PlusSquareOutlined />}
            style={{ border: 0 }}
            onClick={this.expandAll}
            data-testid={DataTestIds.PROJECT_TREE_BUTTON_PLUS}
          />
          <Button
            icon={<MinusSquareOutlined />}
            style={{ border: 0 }}
            onClick={this.collapseAll}
            data-testid={DataTestIds.PROJECT_TREE_BUTTON_MINUS}
          />
        </TreeUtilitiesContainer>
        {withTags && this.getTags(selected)}
        {/* value 282px is height from navbar to project list
         value 500px is height from navbar to filter list */}
        <ProjectsList maxHeight={`calc(100vh - ${withTags ? "500" : "282"}px)`}>
          <Tree
            {...selectProps}
            checkStrictly={checkStrictly}
            autoExpandParent={autoExpandParent}
            expandedKeys={
              defaultExpandedKeys ? expandedKeys.concat(defaultExpandedKeys) : expandedKeys
            }
            filterTreeNode={this.filterTreeNode}
            onExpand={this.onExpand}
          >
            {children}
          </Tree>
        </ProjectsList>
      </Container>
    );
  }
}

export default TreeSearch;
