import styled from "@emotion/styled";
import * as React from "react";
import { COLORS } from "../styles/consts";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

interface IHTMLFieldProps {
  html: string;
  key: string;
  header: string | React.ReactNode;
}

const HTMLFieldContainer = styled.div({
  border: `1px solid ${COLORS.GRAY_OVERLAY}`,
  padding: "10px",
  marginBottom: "10px",
});

const { Panel: CollapsePanel } = Collapse;

export const HTMLField: React.FunctionComponent<IHTMLFieldProps> = ({ html, key, header }) => (
  <Collapse
    bordered={false}
    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
  >
    <CollapsePanel header={header} key={key}>
      <HTMLFieldContainer dangerouslySetInnerHTML={{ __html: html }} />
    </CollapsePanel>
  </Collapse>
);
