import * as React from "react";
import { addLocaleData, IntlProvider } from "react-intl";
import * as en from "react-intl/locale-data/en";
import * as pl from "react-intl/locale-data/pl";
import { connect, ConnectedProps } from "react-redux";

import { ApplicationState } from "@app/modules/app.reducers";
import translations from "@app/translations/translations";
import { flattenTranslations } from "@app/utils/languages";
import { SupportedLanguages } from "@ea/shared_types/types";
import { getTranslations } from "@ea/runner_loader/commands.loader";

addLocaleData([...en, ...pl]);

// TODO pass environmental config from api and tedect browser code if config enables it
// const getBrowserLanguageCode = () => {
//   const browserLanguage = getDefaultLanguage();
//   return translations[browserLanguage] ? browserLanguage : "en-US";
// };
type TranslatorProviderState = { messages: any };

class TranslatorProvider extends React.Component<
  IConnectProps & {
    render: ({ reloadTranslations }) => React.ReactNode;
  },
  TranslatorProviderState
> {
  state = {
    messages: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      messages: this.combineTranslations(this.getUserLanguageCode(props.user?.language)),
    };
  }

  combineTranslations(languageCode: string) {
    const appTranslations = flattenTranslations(translations[languageCode]);

    const packsTranslations = getTranslations();
    const packsTranslationsForCurrentLanguage = packsTranslations
      .filter((t) => t[languageCode])
      .map((t) => flattenTranslations(t[languageCode]));

    const allTranslations = packsTranslationsForCurrentLanguage.reduce((prev, current) => {
      return {
        ...prev,
        ...current,
      };
    }, appTranslations);

    return allTranslations;
  }

  reloadTranslations = () => {
    this.setState({
      messages: this.combineTranslations(this.getUserLanguageCode(this.props.user?.language)),
    });
  };

  getUserLanguageCode = (language: keyof typeof SupportedLanguages | undefined) => {
    switch (language) {
      case "EN":
        return "en-US";
      case "PL":
        return "pl-PL";
      default:
        return "en-US";
    }
  };

  render() {
    const { messages } = this.state;
    const languageCode = this.getUserLanguageCode(this.props.user?.language);

    return (
      <IntlProvider locale={languageCode} messages={messages}>
        {this.props.render({ reloadTranslations: this.reloadTranslations })}
      </IntlProvider>
    );
  }
}

const connectCreator = connect((state: ApplicationState, props) => ({
  ...props,
  user: state.auth.user,
}));

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(TranslatorProvider);
