import React, { PureComponent } from "react";
import { Form } from "react-final-form";
import { EditableFormProps } from "@ea/shared_components/EditeableArea/EditeableAreaFinal";
import AutoSaveFormModifier from "@ea/shared_components/Form/AutoSaveFormModifier";
import { FormHint, Hint, printFormValues } from "@ea/shared_components/Form/FormDebugElements";
import styled from "@emotion/styled";
import { GanttTask } from "../../components/SchedulerGantt/gantt.types";

type SchedulerGanttFormSectionProps = {
  render: (props: EditableFormProps<any>) => React.ReactNode;
  save: (values: GanttTask) => void;
  initialValues: Partial<GanttTask>;
};

const Container = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const AreaContainer = styled.div({
  position: "relative",
  padding: "10px",
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

export class SchedulerGanttFormSection extends PureComponent<SchedulerGanttFormSectionProps, any> {
  render() {
    const { render: renderProp, save, initialValues } = this.props;
    return (
      <Container>
        <AreaContainer>
          <Form
            key={initialValues.id}
            onSubmit={save /* NOT USED, but required */}
            initialValues={JSON.parse(JSON.stringify(initialValues))}
            // subscription={{} /* No need to subscribe to anything */}
          >
            {(formProps) => {
              return (
                <div className="form">
                  <AutoSaveFormModifier
                    fields={["text", "runParams", "startDate", "start_date", "description"]}
                    debounce={500}
                    customEqual={(a, b) => {
                      if (a?.repeater) {
                        const repeaterTheSame =
                          a?.repeater?.repeaterMultiplier === b?.repeater?.repeaterMultiplier &&
                          a?.repeater?.repeaterType === b?.repeater?.repeaterType;

                        if (!repeaterTheSame) {
                          return repeaterTheSame;
                        }
                      }

                      if (
                        typeof a === "object" &&
                        a !== null &&
                        Object.keys(a).length === 0 &&
                        typeof b === "object" &&
                        b !== null &&
                        Object.keys(b).length === 0
                      ) {
                        return true;
                      }

                      if (a?.toJSON || b?.toJSON) {
                        const parsedA = a?.toJSON ? a.toJSON() : a;
                        const parsedB = b?.toJSON ? b.toJSON() : b;
                        return parsedA === parsedB;
                      }

                      if (typeof a === "object" && typeof b === "object") {
                        try {
                          return JSON.stringify(a) === JSON.stringify(b);
                        } catch (e) {}
                      }

                      return a === b;
                    }}
                  />

                  {renderProp({
                    ...formProps.form,
                    readOnly: false,
                    values: formProps.values,
                  })}

                  {process.env.NODE_ENV !== "production" && (
                    <FormHint>
                      <Hint>{printFormValues(formProps.values)}</Hint>
                    </FormHint>
                  )}
                </div>
              );
            }}
          </Form>
        </AreaContainer>
      </Container>
    );
  }
}

export default SchedulerGanttFormSection;
