import { createDataSelectors } from "@app/modules/app.reducers";
import { Documentation, DocumentationStatus } from "@ea/shared_types/types";
import { createSelector } from "reselect";

export const documentationDataSelectors = createDataSelectors<Documentation>()("documentation");

export const getDocumentationsFilterValues = createSelector(
  documentationDataSelectors.getOrderedDataSelector,
  (documentations) => {
    return documentations.map((documentation) => ({
      label: documentation.name,
      value: documentation.id,
    }));
  },
);

export const getSelectedPendingDocumentations = createSelector(
  documentationDataSelectors.getSelectedItemsSelector,
  (documentations) => {
    return documentations.filter((d) => d.status === DocumentationStatus.PENDING);
  },
);

export const getNonPendingDocumentations = createSelector(
  documentationDataSelectors.getSelectedItemsSelector,
  (documentations) => {
    return documentations.filter((d) => d.status !== DocumentationStatus.PENDING);
  },
);
