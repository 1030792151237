import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { moduleActions } from "./scriptChangelog.actions";
import { ScriptChangelog } from "@ea/shared_types/types";

const initialState = {
  ...getDataReducerInitialState<ScriptChangelog>(),
};

export type ScriptChangelogReducer = typeof initialState;

const dataReducer = createDataReducer<ScriptChangelog>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
