"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTranslationKey = getTranslationKey;
// ...and so on...
function getTranslationKey() {
    var props = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        props[_i] = arguments[_i];
    }
    return props.join(".");
}
