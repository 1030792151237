import { isSame } from "@ea/shared_components/utils/array";
import { getEndpoint } from "@ea/shared_types/next/ea.endpoints";
import { StepScreenshots } from "@ea/shared_types/types";
import { Button, Modal, Spin } from "antd";
import * as React from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { BASE_API_PATH } from "../../../services/api/api";
interface IScreenshotGalleryProps {
  visible: boolean;
  currentStepLogId?: number;
  onClose: () => void;
  screenshots: StepScreenshots | null;
}

interface IScreenshotGalleryState {
  screenshotIndex: number;
}

class ScreenshotGallery extends React.Component<
  IScreenshotGalleryProps & InjectedIntlProps,
  IScreenshotGalleryState
> {
  state: IScreenshotGalleryState = {
    screenshotIndex: 0,
  };

  componentWillReceiveProps(nextProps: IScreenshotGalleryProps) {
    if (
      ((this.props.currentStepLogId !== nextProps.currentStepLogId ||
        this.hasScreenshotsChanged(nextProps)) &&
        nextProps.screenshots) ||
      (this.state.screenshotIndex === 0 && nextProps.screenshots)
    ) {
      this.setState({
        screenshotIndex: nextProps.currentStepLogId
          ? nextProps.currentStepLogId
          : parseInt(Object.keys(nextProps.screenshots)[0], 10),
      });
    }
  }

  hasScreenshotsChanged(nextProps: IScreenshotGalleryProps) {
    if (
      (!this.props.screenshots && nextProps.screenshots) ||
      (this.props.screenshots && !nextProps.screenshots)
    ) {
      return true;
    }
    if (this.props.screenshots && nextProps.screenshots) {
      return !isSame(Object.keys(this.props.screenshots), Object.keys(nextProps.screenshots));
    }
  }

  findNext(key) {
    const keys = Object.keys(this.props.screenshots!).map((keyString) => parseInt(keyString, 10));
    return keys[(keys.indexOf(key) + 1) % keys.length];
  }

  findPrevious(key) {
    const keys = Object.keys(this.props.screenshots!).map((keyString) => parseInt(keyString, 10));
    return keys[(keys.indexOf(key) + keys.length - 1) % keys.length];
  }

  getPath(screenshotPath: string) {
    if (!screenshotPath) {
      return "";
    }
    return (
      "/" +
      BASE_API_PATH +
      "/" +
      getEndpoint("logs.sub.step.sub.screenshot").path.replace(":sessionId/:fileName", "") +
      screenshotPath
    );
  }

  renderLightbox() {
    const { screenshots, onClose } = this.props;
    const { screenshotIndex } = this.state;
    const mainSrc = screenshots?.[screenshotIndex]?.path;

    if (screenshots === null) {
      return (
        <Modal
          title={this.props.intl.formatMessage({
            id: "screenshotGallery.loading",
          })}
          visible
          footer={null}
          bodyStyle={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spin />
        </Modal>
      );
    }

    if (Object.keys(screenshots).length === 0 || mainSrc === undefined) {
      return (
        <Modal
          title={this.props.intl.formatMessage({
            id: "screenshotGallery.noScreenshots",
          })}
          visible
          bodyStyle={{
            display: "flex",
            justifyContent: "center",
          }}
          footer={[
            <Button key="ok" onClick={onClose}>
              Ok
            </Button>,
          ]}
          onCancel={onClose}
        >
          {this.props.intl.formatMessage({
            id: "screenshotGallery.noScreenshots",
          })}
        </Modal>
      );
    }

    return (
      <Lightbox
        mainSrc={this.getPath(mainSrc)}
        nextSrc={this.getPath(screenshots[this.findNext(screenshotIndex)]?.path)}
        prevSrc={this.getPath(screenshots[this.findPrevious(screenshotIndex)]?.path)}
        onCloseRequest={onClose}
        onMovePrevRequest={() =>
          this.setState({
            screenshotIndex: this.findPrevious(screenshotIndex),
          })
        }
        onMoveNextRequest={() =>
          this.setState({
            screenshotIndex: this.findNext(screenshotIndex),
          })
        }
        imageTitle={screenshots[screenshotIndex].caption}
        imageLoadErrorMessage={this.props.intl.formatMessage({
          id: "screenshotGallery.errorLoadingScreenshots",
        })}
      />
    );
  }

  render() {
    const { visible } = this.props;
    return visible && this.renderLightbox();
  }
}

export default injectIntl(ScreenshotGallery);
