import styled from "@emotion/styled";
import * as React from "react";

import CommandBarButton from "./CommandBarButton";
import { DataTestIdProp } from "../utils/dataTestHelpers";

interface ICommandBarButtonProps {
  name: string;
  onClick?: () => void;
  text: string;
  icon?: string | JSX.Element;
  far?: boolean;
  disabled?: boolean;
  size?: "small" | "default" | "large" | undefined;
  checkable?: boolean;
  checked?: boolean;
  style?: React.CSSProperties;
  tooltip?: string;
  className?: string;
  [DataTestIdProp]: string;
}

const ToggleButton = styled(CommandBarButton)({}, ({ toggled }: { toggled: boolean }) => ({
  background: toggled ? "#a6a6a6" : "",
  boxShadow: toggled ? "0 0 0 1px rgba(0,0,0,.15) inset, 0 0 6px rgba(0,0,0,.2) inset" : "",
}));

const ToggleCommandBarButton: React.FunctionComponent<
  { toggled: boolean } & ICommandBarButtonProps
> = (props) => {
  return <ToggleButton {...props} />;
};

export default ToggleCommandBarButton;
