import { AsyncActionCreators } from "typescript-fsa";
import {
  isNotifiableActionWithMessages,
  NotifiableActionWithMessages,
} from "@ea/shared_components/redux/common.models";
import { stepsActions } from "@app/modules/steps";
import { storageActions } from "@app/modules/storage";

export const notifiableActions = [...stepsActions.notifiableActions];

export const getNotifiableActionMessage = (action: AsyncActionCreators<any, any, any>) => {
  const actionFound = notifiableActions.find((notifiableAction) => {
    if (
      isNotifiableActionWithMessages(notifiableAction) &&
      (notifiableAction.action.done.type === action.type ||
        notifiableAction.action.failed.type === action.type ||
        notifiableAction.action.started.type === action.type)
    ) {
      return true;
    }
    return false;
  }) as NotifiableActionWithMessages;

  if (!actionFound) {
    return undefined;
  }

  return {
    errorMessage: actionFound.onErrorMessage,
    successMessage: actionFound.onSuccessMessage,
  };
};
