import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { currentUserRolesSelector } from "@ea/shared_components/auth/auth.selectors";
import { hasIntersection } from "@ea/shared_components/utils/array";
import { ROLES } from "@ea/shared_types/types";
import { ApplicationState } from "../app.reducers";

export const isAllowedAccess = (userRoles) => (allowedRoles, disallowedRoles) => {
  const allowed = allowedRoles.length === 0 || hasIntersection(allowedRoles, userRoles);
  const disallowed = disallowedRoles && hasIntersection(disallowedRoles, userRoles);

  return userRoles.includes(ROLES.admin) || (!disallowed && allowed);
};

const WithAuthorization =
  (allowedRoles: ROLES[], disallowedRoles?: ROLES[], notAuthorizedRender?: () => JSX.Element) =>
  <OriginalProps extends any>(
    WrappedComponent: React.ComponentType<OriginalProps>,
  ): React.ComponentType<OriginalProps> => {
    class WithRoleCheck extends Component<IConnectProps> {
      constructor(props) {
        super(props);
      }
      render() {
        const { userRoles, dispatch, ...passProps } = this.props as any;
        if (!passProps.user) {
          return null;
        }

        return isAllowedAccess(userRoles)(allowedRoles, disallowedRoles) ? (
          <WrappedComponent {...passProps} />
        ) : notAuthorizedRender ? (
          notAuthorizedRender()
        ) : null;
      }
    }

    const connector = connect((state: ApplicationState, props: OriginalProps) => ({
      ...(props as object),
      user: state.auth.user,
      userRoles: currentUserRolesSelector(state),
    }));

    type IConnectProps = ConnectedProps<typeof connector>;

    return connector(WithRoleCheck);
  };

export default WithAuthorization;
