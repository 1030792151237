import { createModuleEpics, ApplicationState } from "@app/modules/app.reducers";
import { moduleActions, localizationActionCreators } from "./localization.actions";
import { Localization } from "@ea/shared_types/types";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { LOCALIZATION_COLUMNS_CONFIG } from "./localization.table";
import { localizationDataSelectors } from "./localization.selectors";
import i18next from "../../translations/backendTranslations";
import { Action, Store } from "redux";
import { ActionsObservable } from "redux-observable";
import { currentUserSettingsSelector } from "@ea/shared_components/auth/auth.selectors";

const localizationModuleEpics = createModuleEpics<Localization>(moduleActions, {
  normalizeItem: (localization) => localization,
  getRequestParams: (state, tableId) => {
    const tableParams = localizationDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, LOCALIZATION_COLUMNS_CONFIG, {
      defaultOrder: ["createdAt DESC"],
    });
    return params;
  },
  getSingleRequestParams: (params) => {
    return params;
  },
  api: {
    createItem: API.createLocalization,
    deleteItem: API.deleteLocalization,
    editItem: API.editLocalization,
    getItems: API.getLocalization,
    getItemsCount: API.getLocalizationCount,
  },
});

export const setLanguageLocaleLoad = (
  action$: ActionsObservable<Action>,
  store: Store<ApplicationState>,
) =>
  action$
    .ofType(localizationActionCreators.table.load.done)
    .do((action: any) => {
      const state = store.getState();
      const userSettings = currentUserSettingsSelector(state);
      const localization = userSettings.documentationLocale
        ? localizationDataSelectors.getItemSelector(state, userSettings.documentationLocale)
        : undefined;
      // localization is undefined when HOT RELOADING
      if (localization) {
        i18next.loadLanguages(localization.locale);
        i18next.reloadResources(localization.locale);
        i18next.changeLanguage(localization.locale);
      }
    })
    .ignoreElements();

export default { ...localizationModuleEpics, setLanguageLocaleLoad };
