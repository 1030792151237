import {
  SYSTEM_TYPE,
  VIRTUAL_USER_STATUS,
  JOB_TRIGGER_TYPE,
  STORAGE_TYPE,
  TTL,
  EXECUTION_STATUS,
  EXECUTION_STATE,
  AllowedWorkItemTypes,
  ScriptStatus,
  DOCUMENTATION_TYPE,
} from "@ea/shared_types/types";

export const NAVBAR_HEIGHT = "40px";
export const TABLE_ROW_HEIGHT = 43;

export const COLORS = {
  BLUE1: "#0078d7",
  WHITE: "white",
  DARK: "#001529",
  GRAY_OVERLAY: "rgba(0,0,0,0.05)",
  WHITE_OVERLAY: "rgba(255,255,255,.4)",
  TRANSPARENT: "rgba(0, 0, 0, 0)",
  DARK_GREEN: "#004b1c",
  DARK_RED: "#a80000",
  DISABLED_BUTTON_TEXT: "rgba(0,0,0,.25)",
  BUTTON_TEXT: "rgba(0,0,0,.65)",
  HOVER_BUTTON_TEXT: "#40a9ff",
  HOVER_BUTTON_BACKGROUND: "#eaeaea",
  DISABLE_BACKGROUND: "#f5f5f5",

  SCRIPT_STATUS: {
    [ScriptStatus.DRAFT]: "#2db7f5",
    [ScriptStatus.READY_FOR_REVIEW]: "orange",
    [ScriptStatus.PUBLISHED]: "#87d068",
    [ScriptStatus.CLOSED]: "#333333",
    [ScriptStatus.IN_PROGRESS]: "green",
    [ScriptStatus.READY_TO_TEST]: "#004b1c",
  },

  STATUS: {
    [EXECUTION_STATUS.ERROR]: "red",
    [EXECUTION_STATUS.WARNING]: "orange",
    [EXECUTION_STATUS.SUCCESS]: "green",
    [EXECUTION_STATUS.NONE]: "gray",
    [EXECUTION_STATUS.UNTESTED]: "#d9d9d9",
    INFO: "gray",
    TERMINATED: "#595959",
    RUNNING: "#00B294",
    "RUNNING IN BACKGROUND": "#004b50",
    ACTIVE: "green",
    CLOSED: "#333333",
    COMPLETED: "#004b50",
  },

  STATE: {
    [EXECUTION_STATE.NONE]: "gray",
    [EXECUTION_STATE.RUNNING]: "#00B294",
    [EXECUTION_STATE.PAUSED]: "blue",
    [EXECUTION_STATE.TERMINATED]: "#666666",
    [EXECUTION_STATE.FINISHED]: "green",
  },

  SYSTEM_TYPE: {
    [SYSTEM_TYPE.PROD]: "green",
    [SYSTEM_TYPE.DEV]: "#666666",
    [SYSTEM_TYPE.TEST]: "orange",
    [SYSTEM_TYPE.UAT]: "blue",
  },
  WORK_ITEM_TYPE: {
    [AllowedWorkItemTypes.Bug]: "#CC293D",
    [AllowedWorkItemTypes.Task]: "#F2CB1D",
    [AllowedWorkItemTypes.Issue]: "#B4009E",
  },
  TTL: {
    [TTL.T90]: "#002b80",
    [TTL.T180]: "#134fc6",
    [TTL.T365]: "#80aaff",
  },
  STORAGE_TYPE: {
    [STORAGE_TYPE.AZURE]: "blue",
  },

  TRIGGER_TYPE: {
    [JOB_TRIGGER_TYPE.TIME]: "blue",
    [JOB_TRIGGER_TYPE.STORAGE]: "orange",
  },

  DOCUMENTATION_TYPE: {
    [DOCUMENTATION_TYPE.DOCUMENT]: "orange",
    [DOCUMENTATION_TYPE.VIDEO]: "green",
  },

  VIRTUAL_USER_STATUS: {
    [VIRTUAL_USER_STATUS.AUTHENTICATED]: "green",
    [VIRTUAL_USER_STATUS.ERROR]: "red",
    [VIRTUAL_USER_STATUS.NOT_AUTHENTICATED]: "blue",
  },
};

export const Z_INDEXES = {
  TABLE_SETTINGS: 10,
  NAVBAR: 100,
  SAVING_AREA: 500,
  VIRTUALIZED_TABLE: 5000,
  VARIABLE_PICKER: 5000,
};

export const ADVANCED_RUNNER_VARIABLES_TABS_EA = "card-container";
export const ADVANCED_RUNNER_VARIABLES_TABS_AB = "card-container-ab";
