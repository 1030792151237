import * as React from "react";
import styled from "@emotion/styled";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { TAG_TABLES_CONFIG, TAG_COLUMNS } from "./tag.table";
import { tagDataSelectors } from "./tag.selectors";
import { getTagTableActions, tagActions } from "./tag.actions";
import TagCommandBar from "./components/TagCommandBar";
import CreateEditTag from "./CreateEditTag.container";

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

interface ITagTableProps {}

interface ITagTableState {
  createWindowVisibility: boolean;
  editWindowVisibility: boolean;
}

class TagTableContainer extends React.Component<ITagTableProps & IConnectProps, ITagTableState> {
  connectedTable: any;

  state: ITagTableState = {
    createWindowVisibility: false,
    editWindowVisibility: false,
  };

  openEditWindow = () => {
    this.setState({
      editWindowVisibility: true,
    });
  };

  closeEditWindow = () => {
    if (this.props.selectedTag) {
      this.props.actions.loadSingle({ id: this.props.selectedTag.id });
    }
    this.setState({
      editWindowVisibility: false,
    });
  };

  openCreateWindow = () => {
    this.setState({
      createWindowVisibility: true,
    });
  };

  closeCreateWindow = () => {
    this.setState({
      createWindowVisibility: false,
    });
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
  };

  render() {
    return (
      <Container>
        <TagCommandBar
          onNewClick={this.openCreateWindow}
          onRemoveClick={this.remove}
          selected={this.props.selected}
          onEditClick={this.openEditWindow}
          onReload={this.reload}
        />
        <CreateEditTag
          visibility={this.state.createWindowVisibility || this.state.editWindowVisibility}
          onClose={this.state.editWindowVisibility ? this.closeEditWindow : this.closeCreateWindow}
          onCreate={this.reload}
          selected={this.props.selectedTag}
          isEdit={this.state.editWindowVisibility}
          user={this.props.user}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={TAG_COLUMNS}
          tableId={TAG_TABLES_CONFIG.MAIN.id()}
          preferencesId={TAG_TABLES_CONFIG.MAIN.preferencesId}
          stateKey={"tag"}
          tableActions={getTagTableActions}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(tagActions, dispatch),
    ...bindActionCreators(getTagTableActions(TAG_TABLES_CONFIG.MAIN.id()), dispatch),
  },
});

const connectCreator = connect(
  (state: ApplicationState) => ({
    selected: tagDataSelectors.getSelectedSelector(state, TAG_TABLES_CONFIG.MAIN.id()),
    selectedTag: tagDataSelectors.getSelectedItemSelector(state, TAG_TABLES_CONFIG.MAIN.id()),
    user: state.auth.user,
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(TagTableContainer);
