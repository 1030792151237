import React, { useState } from "react";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { PanelType } from "@ea/shared_components/Panel";
import Dropzone from "react-dropzone";
import styled from "@emotion/styled";
import { getTranslationKey } from "./../../translations/translations.helpers";
import { toast } from "react-toastify";
import { Button, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";

import { COLORS } from "@app/styles/consts";
import { css } from "@emotion/core";
import { API } from "@app/services/api/api";
import { FileExcelOutlined, UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { codeTemplateExtension } from "@ea/shared_types/helpers/codeTemplates.helpers";

interface ImportCodeTemplateProps {
  visibility: boolean;
  onClose: () => void;
}

const DropZoneDiv = styled.div(
  {
    borderRadius: "4px",
    background: "#f4f4f4",
    border: "2px dashed #d9d9d9",
    transition: "border-color .3s",
    width: "400px",
    height: "150px",
    textAlign: "center",
    padding: "16px 0",
    cursor: "pointer",
    "&:hover": {
      background: "#dbdbdb",
      border: "2px dashed #2b2b2b",
    },
  },
  ({ isDragActive }: { isDragActive: boolean }) =>
    isDragActive ? { border: "2px dashed green" } : {},
);
const LoadedFilesContainer = styled.div({
  display: "flex",
  flexDirecion: "column",
  padding: "0 0 0 0px",
});

const LoadedFile = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  margin: "5px",
});
const FileNameContainer = styled.h3({
  margin: "15px",
});

const documentIcon = css({
  fontSize: 30,
  margin: "10px",
  color: COLORS.DARK_GREEN,
  cursor: "pointer",
  ":hover": {
    textShadow: "0px 0px 5px lightgreen",
  },
});

interface ImportCodeTemplateState {
  files: any[];
  loading: boolean;
  error: string | undefined;
}

const ImportCodeTemplate = ({ onClose, visibility }: ImportCodeTemplateProps) => {
  let dropzoneRef: any = null;

  const [files, setFiles] = useState<ImportCodeTemplateState["files"]>([]);
  const [error, setError] = useState<ImportCodeTemplateState["error"]>(undefined);
  const [loading, setLoading] = useState<ImportCodeTemplateState["loading"]>(false);

  const onDrop = async (files: any) => {
    setFiles(files);
  };

  const onReject = () => {
    toast.warn(getTranslationKey("messages", "error", "fileUpload"));
  };

  const onOk = async (values) => {
    try {
      setLoading(true);
      const importedCodeTemplates = await API.importCodeTemplate({ data: files });

      const failedToImport = importedCodeTemplates
        .filter((impCodeTemplate) => !impCodeTemplate.imported)
        .map((impCodeTemplate) => {
          console.error(impCodeTemplate.error);
          return impCodeTemplate.name;
        });
      if (failedToImport.length) {
        toast.error(
          <FormattedMessage
            id={getTranslationKey("codeTemplates", "error", "failedToImport")}
            values={{
              codeTemplateNames: `${failedToImport.join(",")}`,
            }}
          />,
        );
      }
    } catch (err) {
      setError(err);
      setLoading(false);
      return;
    }
    onClose();
    onDelete();
  };
  const onDelete = () => {
    setFiles([]);
  };

  const onCloseClick = () => {
    setFiles([]);
    onClose();
  };
  const renderFileList = () => (
    <LoadedFile>
      <FileExcelOutlined css={documentIcon} />
      <FileNameContainer>{files[0].name}</FileNameContainer>
      <Tooltip title={<FormattedMessage id={getTranslationKey("button", "delete")} />}>
        <Button danger icon={<DeleteOutlined />} onClick={() => onDelete()} />
      </Tooltip>
    </LoadedFile>
  );

  return (
    <PanelFormFinal
      visibility={visibility}
      panelType={PanelType.SMALL}
      headerText={getTranslationKey("codeTemplates", "header", "import")}
      cancelButtonText={getTranslationKey("button", "cancel")}
      okButtonText={getTranslationKey("button", "save")}
      onCancelClick={onCloseClick}
      allowPrisitineSubmit={files.length > 0 ? true : false}
      onOkClick={onOk}
      render={() => (
        <>
          <LoadedFilesContainer>
            {files.length > 0 ? (
              renderFileList()
            ) : (
              <Dropzone
                ref={(element) => {
                  dropzoneRef = element;
                }}
                multiple={false}
                onDropAccepted={onDrop}
                onDropRejected={onReject}
                accept={`.${codeTemplateExtension}`}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <DropZoneDiv {...(getRootProps() as any)} isDragActive={isDragActive}>
                    <input {...(getInputProps() as any)} />
                    <UploadOutlined style={{ fontSize: 50 }} />
                    <p>
                      <FormattedMessage id={getTranslationKey("messages", "info", "dropZipZone")} />
                    </p>
                  </DropZoneDiv>
                )}
              </Dropzone>
            )}
          </LoadedFilesContainer>
        </>
      )}
    />
  );
};

export default ImportCodeTemplate;
