import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { DataSourceMetadata } from "@ea/shared_types/next/ea.types";

const actionCreator = actionCreatorFactory("GLOBAL_EXCELS_MODULE");

export const moduleActions = createDataModuleActions<DataSourceMetadata>(actionCreator);
export const getGlobalExcelsTableActions = createTableActionsGetter(
  normalizeActions(moduleActions.table),
);
export const globalExcelsActions = {
  ...normalizeActions(moduleActions.data),
};
export const globalExcelsActionCreators = {
  ...moduleActions,
};
