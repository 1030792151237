import * as React from "react";
import styled from "@emotion/styled";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import WorkItemsCommandBar from "./components/WorkItemsCommandBar";
import { WORK_ITEM_TABLES_CONFIG, WORK_ITEM_COLUMNS } from "./workItem.table";
import { getWorkItemTableActions } from "./workItem.actions";
import { withRouter, RouteComponentProps } from "react-router";
import { WorkItem } from "@ea/shared_types/types";
import { ROUTES } from "@app/routes";

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

interface IWorkItemTableProps {
  scriptId: number;
}

interface IWorkItemTableState {
  persistentQuery: any;
}

class WorkItemTableContainer extends React.Component<
  IWorkItemTableProps & RouteComponentProps<any>,
  IWorkItemTableState
> {
  connectedTable: any;

  constructor(props: IWorkItemTableProps & RouteComponentProps<any>) {
    super(props);

    this.state = {
      persistentQuery: {
        scriptId: props.scriptId,
      },
    };
  }

  componentDidUpdate(prevProps: IWorkItemTableProps) {
    if (prevProps.scriptId !== this.props.scriptId) {
      this.setState({
        persistentQuery: {
          scriptId: this.props.scriptId,
        },
      });
    }
  }

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  openLog = (item: WorkItem) => {
    this.props.history.push({
      pathname: `${ROUTES.scripts}/${this.props.scriptId}/history/${item.sessionId}`,
      search: `?scriptLogId=${item.logId}`,
    });
  };

  render() {
    return (
      <Container>
        <WorkItemsCommandBar onReload={this.reload} />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={WORK_ITEM_COLUMNS}
          tableId={WORK_ITEM_TABLES_CONFIG.SCRIPT.id(this.props.scriptId)}
          preferencesId={WORK_ITEM_TABLES_CONFIG.SCRIPT.preferencesId}
          onRowDoubleClick={this.openLog}
          stateKey={"workItems"}
          persistentQuery={this.state.persistentQuery}
          tableActions={getWorkItemTableActions}
          selectable={false}
        />
      </Container>
    );
  }
}

export default withRouter(WorkItemTableContainer);
