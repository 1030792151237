import * as React from "react";

import { Page } from "@ea/shared_components/common/LayoutElements";
import DocumentationProjectTreeContainer from "./DocumentationProjectTree.container";

interface IScriptsPageProps {}

const ScriptsPage: React.FunctionComponent<IScriptsPageProps> = () => {
  return (
    <Page>
      <DocumentationProjectTreeContainer />
    </Page>
  );
};

export default ScriptsPage;
