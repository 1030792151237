import React from "react";
import { Spin } from "antd";

interface IImageWithStatusTextProps {
  src: string;
  width?: number;
  onClick?: () => void;
}

interface IImageWithStatusTextState {
  imageStatus: ImageLoadingStatus;
}

export enum ImageLoadingStatus {
  LOADING = "LOADING",
  LOADED = "LOADED",
  ERROR = "ERROR",
}

class ImageWithStatusText extends React.Component<
  IImageWithStatusTextProps,
  IImageWithStatusTextState
> {
  state = { imageStatus: ImageLoadingStatus.LOADING };

  componentDidUpdate(prevProps: IImageWithStatusTextProps) {
    if (prevProps.src !== this.props.src) {
      this.setState({
        imageStatus: ImageLoadingStatus.LOADING,
      });
    }
  }

  handleImageLoaded() {
    this.setState({ imageStatus: ImageLoadingStatus.LOADED });
  }

  handleImageErrored() {
    this.setState({ imageStatus: ImageLoadingStatus.ERROR });
  }

  renderMessage() {
    const { imageStatus } = this.state;

    switch (imageStatus) {
      case ImageLoadingStatus.LOADING:
        return (
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Spin style={{ marginRight: "25px" }} />
          </div>
        );

      default:
        return null;
    }
  }

  render() {
    const { imageStatus } = this.state;
    return (
      <>
        <img
          {...this.props}
          onLoad={() => this.handleImageLoaded()}
          onError={() => this.handleImageErrored()}
        />
        {imageStatus === ImageLoadingStatus.LOADING && (
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Spin style={{ marginRight: "25px" }} />
          </div>
        )}
      </>
    );
  }
}
export default ImageWithStatusText;
