import * as React from "react";
import { EXECUTION_STATE, RunnerMode } from "@ea/shared_types/types";
import { FormattedMessage } from "react-intl";
import { Tag, Tooltip } from "antd";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { getStepLabelParams } from "../steps/steps.utils";
import { CoreCommandsIds } from "@ea/shared_types/core.commands.types";
import { COLORS } from "@ea/shared_components/styles/consts";
import styled from "@emotion/styled";
import {
  BorderOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined,
  ForkOutlined,
  SyncOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { EXECUTION_STATUS } from "@ea/shared_types";
import { ExecutionStepTreeNode } from "@ea/shared_types/runner.common.types";

const TextContainer = styled.div(
  {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginTop: "auto",
    marginBottom: "auto",
    maxWidth: "100%",
  },
  ({ containsLink }: { containsLink: boolean }) => ({
    flex: containsLink ? 0.6 : 0.85,
  }),
);

const AdditionalWrapper = styled.div(
  {
    maxWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  ({ containsLink }: { containsLink: boolean }) => ({
    flex: containsLink ? 0.4 : 0.15,
  }),
);
const TagsWrapper = styled.div({
  display: "flex",
  alignItems: "center",
  maxWidth: "200px",
  overflow: "hidden",
});

const StatusIconWrapper = styled.div({
  minWidth: "22px",
});

const LabelContainer = styled.div(
  {
    display: "flex",
    justifyContent: "space-between",
  },
  ({ isNotSynced }: { isNotSynced: boolean }) => ({
    fontWeight: isNotSynced ? "bold" : "normal",
    fontStyle: isNotSynced ? "italic" : "normal",
  }),
);

const LinkTag = styled(Tag)(
  {
    marginLeft: "15px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  ({ clickable, containsGuards }: { clickable?: boolean; containsGuards: boolean }) => ({
    maxWidth: containsGuards ? "70%" : "90%",
    cursor: clickable ? "pointer" : "default",
  }),
);

const getStatusIcon = (status: EXECUTION_STATUS, executing?: boolean) => {
  if (executing) {
    return <SyncOutlined spin style={{ fontSize: "18px", color: "gray" }} />;
  }

  switch (status) {
    case EXECUTION_STATUS.SUCCESS:
      return <CheckSquareOutlined style={{ fontSize: "18px", color: "green" }} />;
    case EXECUTION_STATUS.ERROR:
      return <CloseSquareOutlined style={{ fontSize: "18px", color: "red" }} />;
    case EXECUTION_STATUS.WARNING:
      return <WarningOutlined style={{ fontSize: "18px", color: "orange" }} />;
    default:
      return <BorderOutlined style={{ fontSize: "18px", color: "gray" }} />;
  }
};

export function getRunnerColumnsRenderers() {
  return {
    labelRenderer: (
      rowData: ExecutionStepTreeNode,
      t: any,
      notSelectableIds: number[],
      openDetails: (stepId: number) => void,
    ) => {
      const {
        commandId,
        isOptional,
        synced,
        guards,
        execution,
        mode,
        executionState,
        executionPath,
        id,
      } = rowData;

      const isEditableStep = notSelectableIds.indexOf(id) === -1;
      const isNotSynced = synced === false;
      const containsGuards = !!guards && guards.length > 0;
      const containsLink = commandId === CoreCommandsIds.script;

      const linkingStepIsPending =
        commandId === CoreCommandsIds.script && executionPath?.includes(execution.path);

      const isStepBeingExecuted =
        mode === RunnerMode.PLAYER &&
        executionState === EXECUTION_STATE.RUNNING &&
        (executionPath === execution.path || linkingStepIsPending);

      return (
        <>
          <LabelContainer isNotSynced={isNotSynced}>
            <TextContainer containsLink={containsLink}>
              {isOptional ? "[O] " : ""}
              {rowData.label || t(rowData.labelKey, getStepLabelParams(rowData))}
            </TextContainer>

            {isEditableStep && (
              <AdditionalWrapper id={"ea-step-additional-wrapper"} containsLink={containsLink}>
                <TagsWrapper>
                  {containsLink &&
                    (mode === RunnerMode.RECORDER ? (
                      <Tooltip
                        placement="bottomRight"
                        title={<FormattedMessage id={getTranslationKey("runner", "stepDetails")} />}
                      >
                        <LinkTag
                          color={COLORS.LINK_STEP_TAG}
                          clickable
                          containsGuards={containsGuards}
                          onClick={(event) => {
                            event.stopPropagation();
                            openDetails(id);
                          }}
                        >
                          LINK - {rowData.linkName}
                        </LinkTag>
                      </Tooltip>
                    ) : (
                      <Tooltip placement="bottomRight" title={`LINK - ${rowData.linkName}`}>
                        <LinkTag
                          color={COLORS.LINK_STEP_TAG}
                          containsGuards={containsGuards}
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        >
                          LINK - {rowData.linkName}
                        </LinkTag>
                      </Tooltip>
                    ))}
                  {containsGuards && (
                    <Tooltip
                      title={<FormattedMessage id={getTranslationKey("step", "hasConditions")} />}
                    >
                      <Tag
                        style={{
                          cursor: "default",
                        }}
                        color={COLORS.GUARD_TAG}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <ForkOutlined style={{ fontSize: 16 }} />
                      </Tag>
                    </Tooltip>
                  )}
                </TagsWrapper>
              </AdditionalWrapper>
            )}
            <StatusIconWrapper>
              {getStatusIcon(execution?.status, isStepBeingExecuted)}
            </StatusIconWrapper>
          </LabelContainer>
        </>
      );
    },
  };
}
