import { Step } from "@ea/shared_types/types";
import { runnerCommunicator } from "@ea/shared_types/utils/iframes.communication";
import { IFrameRunnerAsyncMessages } from "@ea/shared_types/communication.types";
import { ExecutionStep } from "@ea/shared_types/runner.common.types";
import { EXECUTION_STATE, RunnerState } from "@ea/shared_types";

type MarkProps = {
  selectedItems: ExecutionStep[];
  selectedItem?: ExecutionStep;
  isDrawingOverlayEnabled?: boolean;
  sessionParams?: RunnerState;
};

export function drawStepOverlay(step?: Step) {
  runnerCommunicator.administration.send(IFrameRunnerAsyncMessages.draw_overlay, step);
}

export function drawStepOverlayOnStepSelect(props: MarkProps, prevProps: MarkProps) {
  if (props.sessionParams?.player.state === EXECUTION_STATE.RUNNING) {
    return;
  }
  if (!props.isDrawingOverlayEnabled) {
    drawStepOverlay(undefined);
    return;
  }

  if (props.selectedItems.length === 1) {
    if (props.isDrawingOverlayEnabled && !prevProps.isDrawingOverlayEnabled && props.selectedItem) {
      drawStepOverlay(props.selectedItem);
      return;
    }

    if (!props.selectedItem) {
      drawStepOverlay(undefined);
      return;
    }
    if (
      prevProps.selectedItem === undefined ||
      prevProps.selectedItem?.id !== props.selectedItem?.id
    ) {
      drawStepOverlay(props.selectedItem);
    }
  }
  if (props.selectedItems.length > 1) {
    drawStepOverlay(undefined);
  }
}
