import * as React from "react";
import { SelectField } from "@ea/shared_components/Form/Fields/SelectField";
import { FormattedMessage } from "react-intl";
import { API } from "@app/services/api/api";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import { toast } from "react-toastify";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { itsDataSelectors } from "../its.selectors";
import {
  GetAreasRequest,
  GetIterationsRequest,
  GetProjectsRequest,
} from "@app/services/api/api.models";
import { getIn } from "final-form";
import { DataTestIds } from "@app/utils/dataTestIds";

interface IAzureFormChunkState {
  projectOptions: OptionType[];
  iterationOptions: OptionType[];
  areaOptions: OptionType[];
  workItemTypesOptions: OptionType[];
  loading: boolean;
}

interface IAzureFormChunkProps {
  readOnly?: boolean;
  values: any;
  change: (name: string, value: any) => void;
  itsId: number;
  prefix?: string;
  formItemLayout?: any;
  useIssueTrackingParams?: boolean;
}

const priorityOptions = Array.from(Array(4).keys()).map((num) => ({
  text: `${num + 1}`,
  value: num + 1,
}));

class AzureFormChunk extends React.Component<
  IAzureFormChunkProps & IConnectProps,
  IAzureFormChunkState
> {
  state = {
    projectOptions: [],
    iterationOptions: [],
    areaOptions: [],
    workItemTypesOptions: [],
    loading: false,
  };

  async componentDidMount() {
    const { its } = this.props;
    if (!its) {
      return;
    }
    await this.reloadData();
  }

  async componentDidUpdate(prevProps: IAzureFormChunkProps & IConnectProps) {
    const { itsId, fieldsPrefix, change, its } = this.props;

    if (!its) {
      change(`${fieldsPrefix}project`, undefined);
      change(`${fieldsPrefix}iteration`, undefined);
      change(`${fieldsPrefix}area`, undefined);
      return;
    }
    if (prevProps.itsId !== itsId) {
      await this.reloadData(true);
    }
  }

  reloadData = async (reset?: boolean) => {
    const { fieldsPrefix, values, change, its, useIssueTrackingParams } = this.props;
    const { host, token, integrationMetadata } = its;
    if (!integrationMetadata) {
      return;
    }

    const itsIntegrationMetadata = useIssueTrackingParams ? its : integrationMetadata.azureDevops;

    let { project, iteration, priority, area } = itsIntegrationMetadata;

    if (!reset) {
      project = getIn(values, `${fieldsPrefix}project`) || itsIntegrationMetadata.project;
      if (project !== itsIntegrationMetadata.project) {
        iteration = getIn(values, `${fieldsPrefix}iteration`);
        priority = getIn(values, `${fieldsPrefix}priority`);
        area = getIn(values, `${fieldsPrefix}area`);
      }
    }

    if (its || (host && token)) {
      try {
        await this.reloadProjects({ itsId: its.id });
      } catch (error) {
        change(`${fieldsPrefix}project`, undefined);
        change(`${fieldsPrefix}iteration`, undefined);

        toast.error(<FormattedMessage id={getTranslationKey("its", "cannotFetchProjects")} />);
        this.setState({ loading: false, projectOptions: [], iterationOptions: [] });
        return;
      }
    }
    change(`${fieldsPrefix}project`, project);
    const { iterationOptions, areaOptions } = await this.onProjectChange(project);
    if (iterationOptions.length > 0) {
      change(`${fieldsPrefix}iteration`, iteration);
      change(`${fieldsPrefix}priority`, priority);
    }
    if (areaOptions.length > 0) {
      change(`${fieldsPrefix}area`, area);
    }
  };

  reloadProjects = async (projectRequestPrams: GetProjectsRequest) => {
    if (!projectRequestPrams) {
      return;
    }

    this.setState({ loading: true });
    const projects = await API.getItsProjects(projectRequestPrams);
    this.setState({
      loading: false,
      projectOptions: projects.map((i) => ({ text: i.name, value: i.name })),
    });
  };

  reloadIterations = async (
    iterationRequestParams: GetIterationsRequest,
  ): Promise<OptionType[]> => {
    const { change, fieldsPrefix } = this.props;

    if (!iterationRequestParams) {
      return [];
    }
    try {
      this.setState({ loading: true });
      const iterations = await API.getIterations(iterationRequestParams);
      this.setState({ loading: false });
      const iterationOptions = iterations.map((i) => ({ text: i.path, value: i.path }));
      return iterationOptions;
    } catch (error) {
      change(`${fieldsPrefix}iteration`, undefined);
      toast.error(<FormattedMessage id={getTranslationKey("its", "cannotFetchIterations")} />);
      this.setState({ loading: false });
      return [];
    }
  };

  reloadAreas = async (areaRequestParams: GetAreasRequest): Promise<OptionType[]> => {
    const { change, fieldsPrefix } = this.props;

    if (!areaRequestParams) {
      return [];
    }
    try {
      this.setState({ loading: true });
      const areas = await API.getAreas(areaRequestParams);
      this.setState({ loading: false });

      const areaOptions = areas.map((i) => ({ text: i.path, value: i.path }));

      return areaOptions;
    } catch (error) {
      change(`${fieldsPrefix}area`, undefined);
      toast.error(<FormattedMessage id={getTranslationKey("its", "cannotFetchAreas")} />);
      this.setState({ loading: false });
      return [];
    }
  };

  onProjectChange = async (
    project,
  ): Promise<{ iterationOptions: OptionType[]; areaOptions: OptionType[] }> => {
    const { its, fieldsPrefix, change } = this.props;
    let iterationOptions: OptionType[] = [];
    let areaOptions: OptionType[] = [];
    change(`${fieldsPrefix}iteration`, undefined);
    if (project && its) {
      iterationOptions = await this.reloadIterations({ itsId: its.id, project });
      areaOptions = await this.reloadAreas({ itsId: its.id, project });
      this.setState({
        iterationOptions,
        areaOptions,
      });
    }
    return { iterationOptions, areaOptions };
  };

  render() {
    const { readOnly, fieldsPrefix } = this.props;
    const { loading, projectOptions, iterationOptions, areaOptions } = this.state;
    return (
      <>
        <SelectField
          loading={loading}
          name={`${fieldsPrefix}project`}
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("common", "placeholder", "project")}
          label={getTranslationKey("common", "label", "project")}
          options={projectOptions}
          disabled={projectOptions.length === 0 || loading}
          onChange={this.onProjectChange}
          data-testid={DataTestIds.FORM_SELECT_PROJECT}
        />
        <SelectField
          loading={loading}
          name={`${fieldsPrefix}iteration`}
          data-testid={DataTestIds.FORM_SELECT_ITERATION}
          required
          placeholder={getTranslationKey("its", "placeholder", "iteration")}
          label={getTranslationKey("its", "label", "iteration")}
          options={iterationOptions}
          disabled={iterationOptions.length === 0 || loading}
        />
        <SelectField
          loading={loading}
          name={`${fieldsPrefix}area`}
          data-testid={DataTestIds.FORM_SELECT_AREA}
          placeholder={getTranslationKey("its", "placeholder", "area")}
          label={getTranslationKey("its", "label", "area")}
          options={areaOptions}
          disabled={areaOptions.length === 0 || loading}
        />
        <SelectField
          name={`${fieldsPrefix}priority`}
          data-testid={DataTestIds.FORM_SELECT_PRIORITY}
          placeholder={getTranslationKey("its", "placeholder", "priority")}
          required
          label={getTranslationKey("its", "label", "priority")}
          options={priorityOptions}
        />
      </>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = (state: ApplicationState, { itsId, prefix }: IAzureFormChunkProps) => {
  return {
    its: itsDataSelectors.getItemSelector(state, itsId),
    fieldsPrefix: prefix || "",
  };
};

const connectCreator = connect(mapStateToProps, mapDispatchToProps);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(AzureFormChunk);
