import { Input } from "antd";
import * as React from "react";

import { withDefaultProps } from "../../utils/react";

import { IFilterControlProps } from "./FilterContainer";

interface IInputFilterDefaultProps {
  placeholder: string;
}

const defaultProps: IInputFilterDefaultProps = {
  placeholder: "Search text",
};
interface IInputFilterProps extends IFilterControlProps, IInputFilterDefaultProps {}

const Search = Input.Search;

class InputFilter extends React.Component<IInputFilterProps> {
  inputElement: any;

  componentDidMount() {
    if (this.props.isOpen && this.inputElement) {
      this.inputElement.focus();
    }
  }

  componentDidUpdate(prevProps: IInputFilterProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      if (this.inputElement) {
        this.inputElement.focus();
      }
    }
  }

  onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    this.props.setValue(event.currentTarget.value);
  };

  onContainerClick = (event) => {
    event.stopPropagation();
  };

  render() {
    const { value, filter, placeholder } = this.props;

    return (
      <div className="custom-filter-dropdown" onClick={this.onContainerClick}>
        <Search
          ref={(element) => (this.inputElement = element)}
          placeholder={placeholder}
          value={value}
          onSearch={filter}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default withDefaultProps(defaultProps, InputFilter);
