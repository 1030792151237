import * as React from "react";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { getScriptsTableActions } from "@app/modules/scripts/scripts.actions";
import { SCRIPTS_TABLES_CONFIG, SCRIPTS_COLUMNS_SHORT } from "@app/modules/scripts/scripts.tables";
import {
  scriptsDataSelectors,
  scriptsSelectorsModifier,
} from "@app/modules/scripts/scripts.selectors";
import { ApplicationState } from "@app/modules/app.reducers";
import { connect, ConnectedProps } from "react-redux";
import { RenderProps } from "@ea/shared_components/PanelForm/PanelFormFinal";
import { ColumnConfig } from "@ea/shared_components/Table/common.tables";
import { disabledFeaturesSelector } from "@app/modules/globalSettings/globalSettings.selectors";
import { overrideColumnProps } from "@ea/shared_components/Table/common.tables";
import { getScriptColumnsRenderers } from "@app/modules/scripts/ScriptColumnsRenderers";
import { projectSelectors, getProjectsTreeSelector } from "../../../projects/projects.selectors";
import { PROJECTS_TABLES_CONFIG } from "../../../projects/projects.tables";
import { Script } from "@ea/shared_types/types";

interface ISchedulerSelectScriptsFormState {
  persistentQuery: any;
  columns: ColumnConfig<Script>[];
}

class SchedulerSelectScriptsForm extends React.Component<
  IConnectProps,
  ISchedulerSelectScriptsFormState
> {
  constructor(props: IConnectProps) {
    super(props);
    const scriptRenderers = getScriptColumnsRenderers({ projectsMap: props.projectsMap });
    const columns = overrideColumnProps<Script>(SCRIPTS_COLUMNS_SHORT, [
      {
        dataIndex: "envType",
        frameworkProps: {
          render: (_, record) => scriptRenderers.envTypeRenderer(record),
        },
      },
      {
        dataIndex: "envName",
        frameworkProps: {
          render: (_, record) => scriptRenderers.envNameRenderer(record),
        },
      },
    ]);

    this.state = {
      persistentQuery: {
        status: {
          neq: "CLOSED",
        },
      },
      columns,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected.length > 0 && prevProps.selected !== this.props.selected) {
      this.props.change(
        "schedulerScripts",
        this.props.selected.map(({ id, projectId, virtualUserId, name }) => ({
          taskScriptId: id,
          projectId,
          virtualUserId,
          name,
        })),
      );
    }
  }

  render() {
    const { disabledFeatures, projects } = this.props;
    const { columns, persistentQuery } = this.state;
    return (
      <>
        <ConnectedTable
          pageable
          columnsConfig={columns}
          disabledFeatures={disabledFeatures}
          tableId={SCRIPTS_TABLES_CONFIG.SCHEDULER_FLOW_FORM.id()}
          preferencesId={SCRIPTS_TABLES_CONFIG.SCHEDULER_FLOW_FORM.preferencesId}
          stateKey="scripts"
          className="panelTable"
          tableActions={getScriptsTableActions}
          persistentQuery={persistentQuery}
          modifySelectors={scriptsSelectorsModifier}
          dynamicFilterValues={{
            projectId: projects,
          }}
          multiselect
        />
      </>
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: RenderProps) => ({
    selected: scriptsDataSelectors.getSelectedItemsSelector(
      state,
      SCRIPTS_TABLES_CONFIG.SCHEDULER_FLOW_FORM.id(),
    ),
    projectsMap: projectSelectors.getDataSelector(state),
    projects: getProjectsTreeSelector(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    disabledFeatures: disabledFeaturesSelector(state),
    ...props,
  }),
  null,
  null,
  { forwardRef: true },
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(SchedulerSelectScriptsForm);
