import { ApplicationState } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";
import EditeableArea from "@ea/shared_components/EditeableArea/EditeableArea";
import { FilterOption } from "@ea/shared_components/Form/Form.common";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import ManyToMany from "@ea/shared_components/Table/ManyToMany";
import { isSame } from "@ea/shared_components/utils/array";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { getUsersTableActions } from "../users/users.actions";
import { USERS_COLUMNS, USERS_COLUMNS_MINIMUM, USERS_TABLES_CONFIG } from "../users/users.table";
import { userGroupActions } from "./userGroups.actions";
import { userGroupDataSelectors } from "./userGroups.selectors";

interface IUserGroupsDetailsContainerProps {
  userGroupId: number;
}

interface IUserGroupsDetailsContainerState {
  displayTablePersistentQuery: any;
  groups: FilterOption[];
}

const initialState = {
  groups: [],
  displayTablePersistentQuery: { id: null },
};

class UserGroupsDetailsContainer extends React.Component<
  IUserGroupsDetailsContainerProps & IConnectProps,
  IUserGroupsDetailsContainerState
> {
  sourceTable: any;

  state: IUserGroupsDetailsContainerState = initialState;
  editAreaContainer: any;

  async componentDidMount() {
    const { userGroup, userGroupId, loadSingle } = this.props;
    const groups = await API.getUserGroups({});
    if (!userGroup) {
      loadSingle({ id: userGroupId });
      this.setState({
        groups: groups.map((g) => ({ label: g.name, value: g.id })),
      });
      return;
    }

    this.setState({
      displayTablePersistentQuery: {
        id: userGroup.users.length > 0 ? { inq: userGroup.users.map((u) => u.id) } : -1,
      },
      groups: groups.map((g) => ({ label: g.name, value: g.id })),
    });
  }

  async componentDidUpdate(prevProps: IUserGroupsDetailsContainerProps & IConnectProps) {
    const { userGroup, userGroupId } = this.props;

    if (userGroupId !== prevProps.userGroupId) {
      if (this.editAreaContainer) {
        this.editAreaContainer.cancel();
      }
    }

    if (
      (prevProps.userGroup === undefined && userGroup !== undefined) ||
      (prevProps.userGroup && userGroup && !isSame(userGroup.users, prevProps.userGroup.users))
    ) {
      this.setState({
        displayTablePersistentQuery: {
          id: userGroup.users.length > 0 ? { inq: userGroup.users.map((u) => u.id) } : -1,
        },
      });
    }
  }

  onSave = async (item) => {
    const { loadSingle, userGroupId } = this.props;
    await API.editUserGroup(item);
    loadSingle({ id: userGroupId });
  };

  renderFlowEdit = (props) => (
    <ManyToMany
      {...props}
      pageable
      columnsConfig={USERS_COLUMNS_MINIMUM}
      tableId={USERS_TABLES_CONFIG.USERS_GROUP_ASSIGNMENT.id(this.props.userGroupId)}
      preferencesId={USERS_TABLES_CONFIG.USERS_GROUP_ASSIGNMENT.preferencesId}
      stateKey="users"
      parentItemId={this.props.userGroupId}
      parentItemStateKey="userGroups"
      tableActions={getUsersTableActions}
      loadParentItem={this.props.loadSingle}
      relationName="users"
      onSave={this.onSave}
      dynamicFilterValues={{
        groups: this.state.groups.filter((g) => `${g.value}` !== this.props.userGroupId),
      }}
    />
  );

  renderFlowDisplay = () => (
    <ConnectedTable
      pageable
      columnsConfig={USERS_COLUMNS}
      tableId={USERS_TABLES_CONFIG.USERS_GROUP_ASSIGNMENT_DISPLAY.id(this.props.userGroupId)}
      preferencesId={USERS_TABLES_CONFIG.USERS_GROUP_ASSIGNMENT_DISPLAY.preferencesId}
      stateKey={"users"}
      tableActions={getUsersTableActions}
      persistentQuery={this.state.displayTablePersistentQuery}
      selectable={false}
    />
  );

  render() {
    return (
      <EditeableArea
        ref={(editArea) => (this.editAreaContainer = editArea)}
        renderDisplayArea={() => this.renderFlowDisplay()}
        renderEditArea={this.renderFlowEdit}
      />
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: RouteComponentProps<any>) => ({
    ...props,
    userGroupId: props.match.params.id,
    userGroup: userGroupDataSelectors.getItemSelector(state, props.match.params.id),
  }),
  {
    loadSingle: userGroupActions.loadSingle,
  },
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(UserGroupsDetailsContainer));
