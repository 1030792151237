import { Col, Row } from "antd";
import { FormItemProps } from "antd/lib/form";
import { ColProps } from "antd/lib/grid/col";
import classNames from "classnames";
import Animate from "rc-animate";
import * as React from "react";
import "./FormItem.css";

import { withDefaultProps } from "../../utils/react";
import FormItemLabel from "./FormItemLabel";
import { AUDIT_TRAIL_MODE_ERROR } from "./FormItemWrapper";

export enum FieldStatus {
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  ERROR = "ERROR",
  VALIDATING = "VALIDATING",
  NONE = "NONE",
}

type IFormItemOptionalProps = {
  hasFeedback: boolean;
  status: FieldStatus;
  required: boolean;
  colon: boolean;

  showHelp?: boolean;
  help?: React.ReactNode;
  label?: string;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  formItemClassName?: string;
  formItemRowStyle?: React.CSSProperties;
  formItemControlStyle?: React.CSSProperties;
};

type IFormItemRequiredProps = {};

export type IFormItemProps = IFormItemRequiredProps & IFormItemOptionalProps;

const defaultProps: IFormItemOptionalProps = {
  hasFeedback: false,
  status: FieldStatus.NONE,
  required: false,
  colon: true,
};

const FormItem: React.FunctionComponent<IFormItemProps> = (props) => {
  const { showHelp, colon, formItemClassName, wrapperCol, hasFeedback, status, children, help } =
    props;

  const formItemClasses = classNames({
    "ant-form-item": true,
    "ant-form-item-with-help": showHelp,
    "ant-form-item-no-colon": !colon,
    [`${formItemClassName}`]: !!formItemClassName,
  });

  const wrapperClasses = classNames(
    "ant-form-item-control-wrapper",
    wrapperCol && wrapperCol.className,
  );

  const validateWrapperClasses = classNames({
    "ant-form-item-control": true,
    "ant-form-item-has-feedback": hasFeedback || status === FieldStatus.VALIDATING,
    "ant-form-item-has-success": status === FieldStatus.SUCCESS,
    "ant-form-item-has-warning": status === FieldStatus.WARNING,
    "ant-form-item-has-error": status === FieldStatus.ERROR,
    "ant-form-item-is-validating": status === FieldStatus.VALIDATING,
    "audit-trail-diff": help === AUDIT_TRAIL_MODE_ERROR,
  });

  const showHelpClasses = classNames({
    "ant-form-item-explain": true,
    "ant-form-item-explain-error": true,
  });

  const helpElement =
    showHelp && typeof help === "string" ? <div className={showHelpClasses}>{help}</div> : null;

  return (
    <Row
      className={formItemClasses}
      style={{ marginBottom: "6px", ...props.formItemRowStyle } as any}
    >
      <>
        <FormItemLabel {...props} />
        <Col {...wrapperCol} className={wrapperClasses}>
          <div className={validateWrapperClasses} style={props.formItemControlStyle as any}>
            <span className={`"ant-form-item-children`}>{children}</span>
            <Animate transitionName="show-help" component="" transitionAppear>
              {helpElement}
            </Animate>
          </div>
        </Col>
      </>
    </Row>
  );
};

export default withDefaultProps(defaultProps, FormItem);
