import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";

import { Script, System, SchedulerRepeater } from "@ea/shared_types/types";
import TableForm from "@ea/shared_components/Form/TableForm";
import { getSchedulerEditSelectedColumns } from "./SchedulerEditSelectedColumns";
import CheckboxField from "@ea/shared_components/Form/Fields/CheckboxField";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import { Row, Col } from "antd";
import { getIn } from "final-form";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { SchedulerAddScriptPanelModeType } from "./SchedulerAddScriptsPanel";
import EmptyField from "@ea/shared_components/Form/Fields/EmptyField";
import { DataTestIds } from "@app/utils/dataTestIds";

interface ISchedulerEditSelectedFormProps {
  scripts: Script[];
  system?: System;
  values: any;
  mode: SchedulerAddScriptPanelModeType;
  isCreating?: boolean;
}

export type SchedulerSelectedScript = {
  taskScriptId: number;
  projectId: number;
  virtualUserId: number;
  name: string;
  scriptLabel: string;
  runParams?: { repeater?: SchedulerRepeater };
};

const SchedulerEditSelectedForm: React.FunctionComponent<
  ISchedulerEditSelectedFormProps & InjectedIntlProps
> = ({ scripts, system, values, intl, mode, ...form }) => {
  const createAsGroup = getIn(values, `createAsGroup`);
  const schedulerScripts = getIn(values, `schedulerScripts`);
  return (
    <>
      {mode !== "ADD_TO_EXISTING" ? (
        <Row gutter={10}>
          <Col span={7}>
            <CheckboxField
              disabled={schedulerScripts?.length < 2}
              name="createAsGroup"
              label={getTranslationKey("scheduler", "label", "createAsGroup")}
              data-testid={DataTestIds.CHECKBOX_CREATE_AS_GROUP}
            />
          </Col>
          <Col span={17}>
            <InputField
              name="groupName"
              readOnly={!createAsGroup}
              label={getTranslationKey("scheduler", "label", "groupName")}
              data-testid={DataTestIds.INPUT_GROUP_NAME}
            />
          </Col>
        </Row>
      ) : (
        <EmptyField name="createAsGroup" defaultValue={true} />
      )}

      <TableForm<SchedulerSelectedScript>
        name="schedulerScripts"
        staticMode
        columns={getSchedulerEditSelectedColumns({ scripts, values }, intl)}
      />
    </>
  );
};

export default injectIntl(SchedulerEditSelectedForm);
