import { createModuleEpics } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { ScriptChangelog } from "@ea/shared_types/types";
import { moduleActions } from "./scriptChangelog.actions";
import { scriptChangelogDataSelectors } from "./scriptChangelog.selectors";
import { SCRIPT_CHANGELOG_COLUMNS_CONFIG } from "./scriptChangelog.table";

const apiKeyModuleEpics = createModuleEpics<ScriptChangelog>(moduleActions, {
  normalizeItem: (its) => its,
  getRequestParams: (state, tableId) => {
    const tableParams = scriptChangelogDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, SCRIPT_CHANGELOG_COLUMNS_CONFIG, {
      defaultOrder: ["createdAt DESC"],
    });
    return params;
  },
  getSingleRequestParams: (params) => {
    return params;
  },
  api: {
    createItem: API.createScriptChangelog,
    deleteItem: (() => {}) as any,
    editItem: (() => {}) as any,
    getItems: API.getScriptChangelog as any,
    getItemsCount: API.getScriptChangelogCount,
  },
});

export default { ...apiKeyModuleEpics };
