import { ws } from "@app/modules/common/websocket";
import { downloadFileBlob } from "@ea/shared_components/helpers/file";
import { endpointsCreator } from "@ea/shared_components/utils/endpointCreator";
import { CreateOptions } from "@ea/shared_types/api.types";
import { FORMDATA_FIELD_NAMES } from "@ea/shared_types/next/ea.consts";
import { EndpointType, getEndpoint } from "@ea/shared_types/next/ea.endpoints";
import moment from "moment";
import {
  CreateScriptChangelogRequest,
  CreateScriptChangelogResponse,
  CreateStorageRequest,
  CreateStorageResponse,
  DeleteExportCsvRequest,
  DeleteExportCsvResponse,
  DeleteSchedulerJobsRequest,
  DeleteStorageRequest,
  EditStorageRequest,
  EditStorageResponse,
  GetGroupRequest,
  GetGroupResponse,
  GetVariableMapRequest,
  GetVariableMapResponse,
  KillBackgroundExecutionJobRequest,
  KillBackgroundExecutionJobResponse,
  KillInBackgroundExecutionRequest,
  KillInBackgroundExecutionResponse,
  LicenseResponse,
  LocalizationAPITypes,
  PatchGroupRequest,
  PatchGroupResponse,
  PayPerUseCounterResponse,
  PayPerUseStatisticsRequest,
  PayPerUseStatisticsResponse,
  SchedulerFlowAPITypes,
} from "./api.models";

export const BASE_API_PATH = "api";
const apiCreator = endpointsCreator(BASE_API_PATH);

const createEndpoint = <T extends EndpointType>(
  endpoint: T,
  options?: Partial<
    {
      pathCreator?: (path: string, params: T["requestType"] & { id?: number }) => string;
    } & CreateOptions<T["requestType"]>
  >,
) => {
  const { pathCreator, ...rest } = options || {};
  const isDefined = Object.keys(rest).length;
  return apiCreator<T["requestType"], T["responseType"]>(
    endpoint.method,
    pathCreator ? (params) => pathCreator(endpoint.path, params) : endpoint.path,
    isDefined ? rest : undefined,
  );
};

const BACKGROUND_ENDPOINT_ENDING = "background";
const SCRIPTS_ENDPOINT_ENDING = "TaskScript";
const USERS_ENDPOINT_ENDING = "users";
const STEPS_ENDPOINT_ENDING = "TaskSteps";
const SCHEDULER_ENDPOINT_ENDING = "Scheduler";
const SCHEDULER_JOB_MAPPING_ENDPOINT_ENDING = "SchedulerJobMappings";
const SCHEDULER_GROUPS_ENDPOINT_ENDING = "SchedulerGroups";
const STORAGE_ENDPOINT_ENDING = "Storage";
const LOGS_ENDPOINT_ENDING = "ExecutionLogs";
const STEP_LOGS_ENDPOINT_ENDING = "ExecutionStepLogs";
const JOB_LOGS_ENDPOINT_ENDING = "ExecutionJobLogs";
const ITS_CONFIGURATIONS_ENDPOINT_ENDING = "ItsConfigurations";
const VARIABLE_MAP_ENDPOINT_ENDING = "VariableMaps";
const SCRIPT_CHANGELOG_ENDPOINT_ENDING = "ScriptChangelog";
const INTEGRATION_ENDPOINT_ENDING = "integration";
const AUDIT_TRAIL_ENDPOINT_ENDING = "AuditTrails";
const AUDIT_TRAIL_SCRIPT_ENDPOINT_ENDING = "auditTrailScriptView";
const AUDIT_TRAIL_PROJECT_ENDPOINT_ENDING = "auditTrailProjectView";
const AUDIT_TRAIL_SETTINGS_ENDPOINT_ENDING = "auditTrailSettingsView";
const DOCUMENTATION_ENDPOINT_ENDING = "Documentation";
const LOCALIZATION_ENDPOINT_ENDING = "localizations";

const VARIABLES_ENDPOINT_ENDING = "Variables";
const LICENSE_ENDPOINT_ENDING = "license";
const CODE_TEMPLATES_ENDPOINT_ENDING = "CodeTemplates";

export const API = {
  runner: {
    meta: createEndpoint(getEndpoint("runner.sub.meta")),
    init: createEndpoint(getEndpoint("runner.sub.init")),
    player: {
      init: createEndpoint(getEndpoint("runner.sub.player.sub.init")),
    },
    recorder: {
      init: createEndpoint(getEndpoint("runner.sub.recorder.sub.init")),
      save: createEndpoint(getEndpoint("runner.sub.recorder.sub.finish")),
      syncSteps: createEndpoint(getEndpoint("runner.sub.recorder.sub.syncSteps")),
    },
  },
  startSession: createEndpoint(getEndpoint("runner.sub.start")),
  login: createEndpoint(getEndpoint("users.sub.login"), { isAuthenticated: false }),
  changePassword: createEndpoint(getEndpoint("users.sub.changePassword")),
  refreshToken: createEndpoint(getEndpoint("oauth.sub.refresh"), {
    pathCreator: (path, params) => path.replace(":id", `${params.provider}`),
  }),
  getGroups: apiCreator<GetGroupRequest, GetGroupResponse>("GET", "Groups"),
  patchGroup: apiCreator<PatchGroupRequest, PatchGroupResponse>("PATCH", "Groups"),
  getInternalSettings: createEndpoint(
    getEndpoint("settings.sub.internal.sub.public", { joinPaths: true }),
  ),
  getPublicInternalSettings: createEndpoint(getEndpoint("settings.sub.internal.sub.public"), {
    isAuthenticated: false,
  }),

  getGlobalSettings: createEndpoint(getEndpoint("settings.sub.global.sub.get")),
  editGlobalSettings: createEndpoint(getEndpoint("settings.sub.global.sub.update")),

  getScripts: createEndpoint(getEndpoint("scripts.sub.get")),
  getScriptsCount: createEndpoint(getEndpoint("scripts.sub.count")),
  scriptAutocomplete: createEndpoint(getEndpoint("scripts.sub.autocomplete")),
  closeScript: createEndpoint(getEndpoint("scripts.sub.close")),
  createScript: createEndpoint(getEndpoint("scripts.sub.create")),
  editScript: createEndpoint(getEndpoint("scripts.sub.update"), {
    pathCreator: (path, params) => path.replace(":id", `${params.script.id!}`),
  }),
  deleteScript: createEndpoint(getEndpoint("scripts.sub.delete"), {
    pathCreator: (path, params) => path.replace(":id", `${params.id}`),
  }),
  patchScript: createEndpoint(getEndpoint("scripts.sub.update"), {
    pathCreator: (path, params) => path.replace(":id", `${params.script.id!}`),
  }), // WHY PATCH AND EDIT?

  pasteSteps: createEndpoint(getEndpoint("steps.sub.paste")),
  createStep: createEndpoint(getEndpoint("steps.sub.create")),
  getSteps: createEndpoint(getEndpoint("steps.sub.get")),
  editStep: createEndpoint(getEndpoint("steps.sub.update"), {
    pathCreator: (path, params) => path.replace(":id", `${params.id!}`),
  }),
  deleteStep: createEndpoint(getEndpoint("steps.sub.delete"), {
    pathCreator: (path, params) => path.replace(":id", `${params.id}`),
  }),
  deleteSteps: createEndpoint(getEndpoint("steps.sub.deleteMany")),
  moveToStep: createEndpoint(getEndpoint("steps.sub.move"), {
    pathCreator: (path, params) => path.replace(":id", `${params.id}`),
  }),

  getScriptChangelog: createEndpoint(getEndpoint("scriptChangeLog.sub.get")),
  getScriptChangelogCount: createEndpoint(getEndpoint("scriptChangeLog.sub.count")),
  createScriptChangelog: apiCreator<CreateScriptChangelogRequest, CreateScriptChangelogResponse>(
    "POST",
    SCRIPT_CHANGELOG_ENDPOINT_ENDING,
  ),

  createUser: createEndpoint(getEndpoint("users.sub.create")),
  auth: createEndpoint(getEndpoint("users.sub.auth")),

  getUsers: createEndpoint(getEndpoint("users.sub.get")),
  editUser: createEndpoint(getEndpoint("users.sub.update"), {
    pathCreator: (path, params) => path.replace(":id", `${params.id}`),
  }),
  deleteUser: createEndpoint(getEndpoint("users.sub.delete"), {
    pathCreator: (path, params) => path.replace(":id", `${params.id}`),
  }),
  getUsersCount: createEndpoint(getEndpoint("users.sub.count")),
  getLogsStats: createEndpoint(getEndpoint("logs.sub.execution.sub.stats")),
  getProjectExecutionStatistics: createEndpoint(getEndpoint("logs.sub.execution.sub.project")),
  getExecutionScreenshots: createEndpoint(getEndpoint("logs.sub.step.sub.executionScreenshots")),

  getVariableCount: createEndpoint(getEndpoint("variables.sub.count")),
  getVariables: createEndpoint(getEndpoint("variables.sub.get")),
  createVariable: createEndpoint(getEndpoint("variables.sub.create")),
  deleteVariable: createEndpoint(getEndpoint("globalVariables.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  patchVariable: createEndpoint(getEndpoint("variables.sub.update"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  patchVariables: createEndpoint(getEndpoint("variables.sub.patchDiff")),
  createGlobalVariable: createEndpoint(getEndpoint("globalVariables.sub.create")),
  getGlobalVariables: createEndpoint(getEndpoint("globalVariables.sub.get")),
  getGlobalVariableCount: createEndpoint(getEndpoint("globalVariables.sub.count")),
  editGlobalVariable: createEndpoint(getEndpoint("globalVariables.sub.update"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  deleteGlobalVariable: createEndpoint(getEndpoint("globalVariables.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  createCodeTemplateGroup: createEndpoint(getEndpoint("codeTemplates.sub.groups.sub.create")),
  getCodeTemplateGroups: createEndpoint(getEndpoint("codeTemplates.sub.groups.sub.get")),
  editCodeTemplateGroup: createEndpoint(getEndpoint("codeTemplates.sub.groups.sub.update"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  deleteCodeTemplateGroup: createEndpoint(getEndpoint("codeTemplates.sub.groups.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  getCodeTemplateGroupCount: createEndpoint(getEndpoint("codeTemplates.sub.groups.sub.count")),
  createKpi: createEndpoint(getEndpoint("kpis.sub.create")),
  getKpi: createEndpoint(getEndpoint("kpis.sub.get")),
  editKpi: createEndpoint(getEndpoint("kpis.sub.update"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  deleteKpi: createEndpoint(getEndpoint("kpis.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  getKpiCount: createEndpoint(getEndpoint("kpis.sub.count")),
  getExecutionStatistics: createEndpoint(getEndpoint("kpis.sub.statistics.sub.execution")),

  getExecutionJobStatistics: createEndpoint(getEndpoint("kpis.sub.statistics.sub.job")),

  uploadFile: createEndpoint(getEndpoint("dataSources.sub.local.sub.upload"), {
    pathCreator: (path, params) => `${path.replace(":scriptId", `${params.scriptId}`)}`,
    defaultContentType: false,
    bodyBuilder: (params: any) => {
      const formData = new FormData();

      if (params && params.files) {
        params.files.forEach((file) =>
          formData.append(FORMDATA_FIELD_NAMES.datasources, file, file.name),
        );
      }
      if (params && params.values) {
        Object.keys(params.values).forEach((key) => {
          formData.append(key, params.values[key]);
        });
      }
      return formData;
    },
  }),

  requestDatasourceFile: createEndpoint(getEndpoint("dataSources.sub.download"), {
    fileRequest: true,
    isAuthenticated: true,
  }),

  generateTemplate: createEndpoint(getEndpoint("dataSources.sub.generate"), {
    fileRequest: true,
    isAuthenticated: true,
  }),

  downloadFile: async ({
    id,
    originalFilename,
    fileName,
  }: {
    id: number;
    originalFilename?: string;
    fileName?: string;
  }) => {
    const name = originalFilename || fileName || "excel.xlsx";
    const blob = await API.requestDatasourceFile({ id: id });
    if (blob) {
      downloadFileBlob(blob, name);
    } else {
      console.warn(`Download aborted`);
    }
  },

  getReportTemplate: createEndpoint(getEndpoint("reportTemplates.sub.download"), {
    fileRequest: true,
    isAuthenticated: true,
    pathCreator: (path, params: any) => `${path.replace(":filename", `${params.fileName}`)}`,
  }),

  downloadReportTemplate: async (fileName, originalFilename) => {
    const blob = await API.getReportTemplate({ fileName });
    downloadFileBlob(blob, originalFilename || fileName);
  },

  generateReport: async (params) =>
    createEndpoint(getEndpoint("documentations.sub.reports.sub.generate"), {
      fileRequest: true,
      isAuthenticated: true,
    })({ ...params, utcOffset: moment().utcOffset(), wsId: ws?.id }),

  downloadReport: createEndpoint(getEndpoint("documentations.sub.reports.sub.download"), {
    fileRequest: true,
    isAuthenticated: true,
  }),

  getDataSources: createEndpoint(getEndpoint("dataSources.sub.get")),

  deleteDataSource: createEndpoint(getEndpoint("dataSources.sub.delete"), {
    pathCreator: (path, params: any) => `${path.replace(":id", `${params.id}`)}`,
  }),
  getProjectGlobalExcels: createEndpoint(getEndpoint("dataSources.sub.global.sub.projectExcels")),
  assignScriptToDatasource: createEndpoint(
    getEndpoint("dataSources.sub.global.sub.assignToScript"),
    {
      pathCreator: (path, params: any) =>
        `${path
          .replace(":scriptId", `${params.scriptId}`)
          .replace(":datasourceId", `${params.datasourceId}`)}`,
    },
  ),
  unassignDatasourceFromScript: createEndpoint(
    getEndpoint("dataSources.sub.global.sub.unassignFromScript"),
    { pathCreator: (path, params: any) => `${path.replace(":scriptId", `${params.scriptId}`)}` },
  ),
  getGlobalExcels: createEndpoint(getEndpoint("dataSources.sub.get")),
  getGlobalExcelsCount: createEndpoint(getEndpoint("dataSources.sub.count")),

  editGlobalExcel: createEndpoint(getEndpoint("dataSources.sub.global.sub.update"), {
    pathCreator: (path, params: any) =>
      `${path.replace(":datasourceId", `${params.datasourceId}`)}`,
    defaultContentType: false,
    bodyBuilder: (params: any) => {
      const { files, ...rest } = params;
      const formData = new FormData();
      if (files) {
        files.forEach((file) => formData.append(FORMDATA_FIELD_NAMES.datasources, file, file.name));
      }
      if (rest) {
        Object.keys(rest).forEach((key) => {
          formData.append(key, rest[key]);
        });
      }

      return formData;
    },
  }),
  createGlobalExcel: createEndpoint(getEndpoint("dataSources.sub.global.sub.upload"), {
    defaultContentType: false,
    bodyBuilder: (params: any) => {
      const formData = new FormData();
      if (params && params.files) {
        params.files.forEach((file) =>
          formData.append(FORMDATA_FIELD_NAMES.datasources, file, file.name),
        );
      }
      if (params && params.values) {
        Object.keys(params.values).forEach((key) => {
          formData.append(key, params.values[key]);
        });
      }

      return formData;
    },
  }),

  deleteGlobalExcel: createEndpoint(getEndpoint("dataSources.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  cloneSchedulerJob: createEndpoint(getEndpoint("schedulers.sub.clone")),
  createSchedulerJob: createEndpoint(getEndpoint("schedulers.sub.create")),
  createSchedulerJobMapping: createEndpoint(getEndpoint("schedulers.sub.mappings.sub.diff"), {
    pathCreator: (path, params) => path.replace(":id", params.schedulerJobId.toString()),
  }),
  getSchedulerFlowItems: createEndpoint(getEndpoint("schedulers.sub.mappings.sub.get"), {
    pathCreator: (path, params) => path.replace(":id", params.filter.where.schedulerJobId),
  }),
  deleteSchedulerFlowItem: createEndpoint(getEndpoint("schedulers.sub.deleteMapping"), {
    pathCreator: (path, params) => path.replace(":id", params.id.toString()),
  }),

  getSchedulerJobMappings: createEndpoint(getEndpoint("schedulers.sub.mappings.sub.get"), {
    pathCreator: (path, params) => path.replace(":id", params.filter.where.schedulerJobId),
  }),
  getSchedulerFlowItemCount: createEndpoint(getEndpoint("schedulers.sub.mappings.sub.count"), {
    pathCreator: (path, params) => {
      console.log(params);
      return path.replace(":id", params.filter.where.schedulerJobId);
    },
  }),
  getSchedulerJobs: createEndpoint(getEndpoint("schedulers.sub.get")),
  getSchedulerJobCount: createEndpoint(getEndpoint("schedulers.sub.count")),

  editSchedulerJob: createEndpoint(getEndpoint("schedulers.sub.update"), {
    pathCreator: (path, params) => path.replace(":id", params.id!.toString()),
  }),
  runNowScheduler: createEndpoint(getEndpoint("schedulers.sub.runNow"), {
    pathCreator: (path, params) => path.replace(":id", params.schedulerId!.toString()),
  }),
  importScheduler: createEndpoint(getEndpoint("schedulers.sub.import")),
  closeSchedulerJob: createEndpoint(getEndpoint("schedulers.sub.close")),

  deleteSchedulerJob: apiCreator<DeleteSchedulerJobsRequest, any>(
    "DELETE",
    (params) => `${SCHEDULER_ENDPOINT_ENDING}/${params.id}`,
  ),
  terminateProjectJob: createEndpoint(getEndpoint("schedulers.sub.terminateProject")),
  runProjectJob: createEndpoint(getEndpoint("schedulers.sub.runProject")),
  currentExecution: createEndpoint(getEndpoint("schedulers.sub.checkProjectRun")),

  playInBackground: createEndpoint(getEndpoint("execution.sub.run")),

  killInBackgroundExecution: apiCreator<
    KillInBackgroundExecutionRequest,
    KillInBackgroundExecutionResponse
  >("GET", `${BACKGROUND_ENDPOINT_ENDING}/kill`),

  killBackgroundJob: apiCreator<
    KillBackgroundExecutionJobRequest,
    KillBackgroundExecutionJobResponse
  >("GET", `${BACKGROUND_ENDPOINT_ENDING}/killJob`),

  terminateExecution: createEndpoint(getEndpoint("execution.sub.terminate")),

  getVirtualUsers: createEndpoint(getEndpoint("virtualUsers.sub.get")),
  getVirtualUsersCount: createEndpoint(getEndpoint("virtualUsers.sub.count")),
  createVirtualUser: createEndpoint(getEndpoint("virtualUsers.sub.create")),
  editVirtualUser: createEndpoint(getEndpoint("virtualUsers.sub.update"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  deleteVirtualUser: createEndpoint(getEndpoint("virtualUsers.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  authenticateVirtualUser: createEndpoint(getEndpoint("virtualUsers.sub.authenticate")),

  getVirtualUserPool: createEndpoint(getEndpoint("virtualUsers.sub.pool.sub.get")),
  getVirtualUserPoolCount: createEndpoint(getEndpoint("virtualUsers.sub.pool.sub.count")),
  createVirtualUserPool: createEndpoint(getEndpoint("virtualUsers.sub.pool.sub.create")),
  editVirtualUserPool: createEndpoint(getEndpoint("virtualUsers.sub.pool.sub.update"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  deleteVirtualUserPool: createEndpoint(getEndpoint("virtualUsers.sub.pool.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),

  getSystems: createEndpoint(getEndpoint("systems.sub.get")),
  getSystemCount: createEndpoint(getEndpoint("systems.sub.count")),
  createSystem: createEndpoint(getEndpoint("systems.sub.create")),
  editSystem: createEndpoint(getEndpoint("systems.sub.update"), {
    pathCreator: (path, params) => path.replace(":id", `${params.id!}`),
  }),
  deleteSystem: createEndpoint(getEndpoint("systems.sub.delete"), {
    pathCreator: (path, params) => path.replace(":id", `${params.id}`),
  }),

  createStorage: apiCreator<CreateStorageRequest, CreateStorageResponse>(
    "POST",
    STORAGE_ENDPOINT_ENDING,
  ),
  getStorages: async () => [] as CreateStorageResponse[],
  editStorage: apiCreator<EditStorageRequest, EditStorageResponse>(
    "PATCH",
    (params) => `${STORAGE_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteStorage: apiCreator<DeleteStorageRequest, any>(
    "DELETE",
    (params) => `${STORAGE_ENDPOINT_ENDING}/${params.id}`,
  ),
  getStorageCount: async () => ({ count: 0 }),
  createSequence: createEndpoint(getEndpoint("numberSequence.sub.create")),
  getSequences: createEndpoint(getEndpoint("numberSequence.sub.get")),
  editSequence: createEndpoint(getEndpoint("numberSequence.sub.update"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  deleteSequence: createEndpoint(getEndpoint("numberSequence.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  getSequenceCount: createEndpoint(getEndpoint("numberSequence.sub.count")),

  getGuards: createEndpoint(getEndpoint("guards.sub.get")),
  getGuardsCount: createEndpoint(getEndpoint("guards.sub.count")),
  createGuard: createEndpoint(getEndpoint("guards.sub.create")),
  editGuard: createEndpoint(getEndpoint("guards.sub.update"), {
    pathCreator: (path, params) => path.replace(":id", `${params.id!}`),
  }),
  deleteGuard: createEndpoint(getEndpoint("guards.sub.delete"), {
    pathCreator: (path, params) => path.replace(":id", `${params.id!}`),
  }),

  getRoles: createEndpoint(getEndpoint("users.sub.roles.sub.get")),
  getRolesCount: createEndpoint(getEndpoint("users.sub.roles.sub.count")),

  // required in epic, but we dont allow these actions
  createRole: () => {},
  editRole: () => {},
  deleteRole: () => {},

  createProject: createEndpoint(getEndpoint("projects.sub.create")),
  getProjects: createEndpoint(getEndpoint("projects.sub.get")),
  editProject: createEndpoint(getEndpoint("projects.sub.update"), {
    pathCreator: (path, params) => path.replace(":id", `${params.id!}`),
  }),
  deleteProject: createEndpoint(getEndpoint("projects.sub.delete"), {
    pathCreator: (path, params) => path.replace(":id", `${params.id}`),
  }),

  getProjectCategories: createEndpoint(getEndpoint("projectsCategory.sub.get")),
  getProjectCategoriesCount: createEndpoint(getEndpoint("projectsCategory.sub.count")),
  createProjectCategory: createEndpoint(getEndpoint("projectsCategory.sub.create")),
  editProjectCategory: createEndpoint(getEndpoint("projectsCategory.sub.update"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  deleteProjectCategory: createEndpoint(getEndpoint("projectsCategory.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),

  getUserGroups: createEndpoint(getEndpoint("users.sub.groups.sub.get")),
  getUserGroupsCount: createEndpoint(getEndpoint("users.sub.groups.sub.count")),
  createUserGroup: createEndpoint(getEndpoint("users.sub.groups.sub.create")),
  editUserGroup: createEndpoint(getEndpoint("users.sub.groups.sub.update"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  deleteUserGroup: createEndpoint(getEndpoint("users.sub.groups.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),

  getLogs: createEndpoint(getEndpoint("logs.sub.execution.sub.get")),
  getLogsCount: createEndpoint(getEndpoint("logs.sub.execution.sub.count")),
  getStepLogScreenshot: createEndpoint(getEndpoint("logs.sub.step.sub.screenshot")),
  getStepLogs: createEndpoint(getEndpoint("logs.sub.step.sub.get")),
  getStepLogsCount: createEndpoint(getEndpoint("logs.sub.step.sub.count")),
  getLogOverview: createEndpoint(getEndpoint("logs.sub.execution.sub.overview"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),

  getAggregatedJobLogs: createEndpoint(getEndpoint("logs.sub.job.sub.aggregated.sub.get")),
  getAggregatedJobLogsCount: createEndpoint(getEndpoint("logs.sub.job.sub.aggregated.sub.count")),
  exportData: async (params) =>
    createEndpoint(getEndpoint("export.sub.generate"))({ ...params, wsId: ws?.id }),
  downloadExport: createEndpoint(getEndpoint("export.sub.download"), {
    fileRequest: true,
    isAuthenticated: true,
  }),
  toggleJobLogsExclusion: createEndpoint(getEndpoint("logs.sub.job.sub.aggregated.sub.exclusion")),
  deleteTemporaryCsv: apiCreator<DeleteExportCsvRequest, DeleteExportCsvResponse>(
    "POST",
    `exportToCsv/deleteFile`,
  ),
  createToken: createEndpoint(getEndpoint("tokens.sub.create")),
  getTokens: createEndpoint(getEndpoint("tokens.sub.get")),
  editToken: createEndpoint(getEndpoint("tokens.sub.update"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  deleteToken: createEndpoint(getEndpoint("tokens.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  getTokenCount: createEndpoint(getEndpoint("tokens.sub.count")),

  getStoragesWithContainers: async (params: any) => [],
  createWorkItem: createEndpoint(
    getEndpoint("issueTrackingTools.sub.azureDevops.sub.createWorkitem"),
  ),
  getIterations: createEndpoint(getEndpoint("issueTrackingTools.sub.azureDevops.sub.iterations")),
  getAreas: createEndpoint(getEndpoint("issueTrackingTools.sub.azureDevops.sub.areas")),
  getFields: createEndpoint(getEndpoint("issueTrackingTools.sub.azureDevops.sub.fields")),
  getWorkItemTypes: createEndpoint(
    getEndpoint("issueTrackingTools.sub.azureDevops.sub.workitemTypes"),
  ),
  getItsProjects: createEndpoint(getEndpoint("issueTrackingTools.sub.azureDevops.sub.projects")),
  getIts: createEndpoint(getEndpoint("issueTrackingTools.sub.get")),
  getItsCount: createEndpoint(getEndpoint("issueTrackingTools.sub.count")),
  createIts: createEndpoint(getEndpoint("issueTrackingTools.sub.create")),
  editIts: createEndpoint(getEndpoint("issueTrackingTools.sub.update"), {
    pathCreator: (path, params: any) => `${path.replace(":id", `${params.id}`)}`,
  }),
  deleteIts: createEndpoint(getEndpoint("issueTrackingTools.sub.delete"), {
    pathCreator: (path, params: any) => `${path.replace(":id", `${params.id}`)}`,
  }),

  fetchItsConfigurations: createEndpoint(
    getEndpoint("issueTrackingTools.sub.configurations.sub.fetch"),
  ),

  getItsConfigurations: createEndpoint(
    getEndpoint("issueTrackingTools.sub.configurations.sub.get"),
  ),
  getItsConfigurationCount: createEndpoint(
    getEndpoint("issueTrackingTools.sub.configurations.sub.count"),
  ),

  createItsConfiguration: createEndpoint(
    getEndpoint("issueTrackingTools.sub.configurations.sub.create"),
  ),
  editItsConfiguration: createEndpoint(
    getEndpoint("issueTrackingTools.sub.configurations.sub.update"),
    {
      pathCreator: (path, params: any) => `${path.replace(":id", `${params.id}`)}`,
    },
  ),
  deleteItsConfiguration: createEndpoint(
    getEndpoint("issueTrackingTools.sub.configurations.sub.delete"),
    {
      pathCreator: (path, params: any) => `${path.replace(":id", `${params.id}`)}`,
    },
  ),

  getItsRule: createEndpoint(getEndpoint("issueTrackingTools.sub.rules.sub.get")),
  getItsRuleCount: createEndpoint(getEndpoint("issueTrackingTools.sub.rules.sub.count")),
  createItsRule: createEndpoint(getEndpoint("issueTrackingTools.sub.rules.sub.create")),
  editItsRule: createEndpoint(getEndpoint("issueTrackingTools.sub.rules.sub.update"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  deleteItsRule: createEndpoint(getEndpoint("issueTrackingTools.sub.rules.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  getWorkItems: createEndpoint(getEndpoint("workItems.sub.get")),
  getWorkItemCount: createEndpoint(getEndpoint("workItems.sub.count")),
  importScript: createEndpoint(getEndpoint("scripts.sub.import"), {
    pathCreator: (path, params) => `${path.replace(":projectId", `${params.projectId}`)}`,
    defaultContentType: false,
    bodyBuilder: (params) => {
      let formData = new FormData();
      if (params && params.data) {
        params.data.forEach((d) => formData.append(d.name, d));
      }
      formData.append("globalExcelOption", params.globalExcelOption);
      return formData;
    },
  }),
  cloneScript: createEndpoint(getEndpoint("scripts.sub.clone")),
  exportScript: createEndpoint(getEndpoint("scripts.sub.export"), {
    fileRequest: true,
    isAuthenticated: true,
  }),
  getVariableMap: apiCreator<GetVariableMapRequest, GetVariableMapResponse>(
    "GET",
    `${VARIABLE_MAP_ENDPOINT_ENDING}`,
  ),
  getTag: createEndpoint(getEndpoint("tags.sub.get")),
  getTagCount: createEndpoint(getEndpoint("tags.sub.count")),

  createTag: createEndpoint(getEndpoint("tags.sub.create")),
  editTag: createEndpoint(getEndpoint("tags.sub.update"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  deleteTag: createEndpoint(getEndpoint("tags.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  exportProjectsToTestPlans: createEndpoint(
    getEndpoint("issueTrackingTools.sub.integration.sub.export"),
  ),

  getTestPlans: createEndpoint(getEndpoint("issueTrackingTools.sub.integration.sub.testPlans")),

  getTestSuiteSiblings: createEndpoint(
    getEndpoint("issueTrackingTools.sub.integration.sub.testSuiteSiblings"),
  ),

  getTestCases: createEndpoint(getEndpoint("issueTrackingTools.sub.integration.sub.testCases")),

  exportScriptToTestCase: createEndpoint(
    getEndpoint("issueTrackingTools.sub.integration.sub.exportScript"),
  ),

  getAuditTrail: createEndpoint(getEndpoint("auditTrail.sub.get")),
  getAuditTrailCount: createEndpoint(getEndpoint("auditTrail.sub.count")),

  getAuditTrailScript: createEndpoint(getEndpoint("auditTrail.sub.view.sub.script.sub.get")),
  getAuditTrailScriptCount: createEndpoint(getEndpoint("auditTrail.sub.view.sub.script.sub.count")),

  getAuditTrailProject: createEndpoint(getEndpoint("auditTrail.sub.view.sub.project.sub.get")),
  getAuditTrailProjectCount: createEndpoint(
    getEndpoint("auditTrail.sub.view.sub.project.sub.count"),
  ),

  getAuditTrailSettings: createEndpoint(getEndpoint("auditTrail.sub.view.sub.settings.sub.get")),
  getAuditTrailSettingsCount: createEndpoint(
    getEndpoint("auditTrail.sub.view.sub.settings.sub.count"),
  ),

  getScriptDocumentationFiles: createEndpoint(
    getEndpoint("documentations.sub.downloadFromScripts"),
    {
      fileRequest: true,
      isAuthenticated: true,
    },
  ),
  getDocumentationFile: createEndpoint(getEndpoint("documentations.sub.download"), {
    fileRequest: true,
    isAuthenticated: true,
  }),
  getDocumentation: createEndpoint(getEndpoint("documentations.sub.get")),
  getDocumentationCount: createEndpoint(getEndpoint("documentations.sub.count")),
  editDocumentation: createEndpoint(getEndpoint("documentations.sub.update"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  deleteDocumentation: createEndpoint(getEndpoint("documentations.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),

  getCapturedFiles: createEndpoint(getEndpoint("logs.sub.execution.sub.capturedFiles"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),

  getCaptureFile: createEndpoint(getEndpoint("logs.sub.execution.sub.downloadCapturedFile"), {
    fileRequest: true,
    isAuthenticated: true,
    pathCreator: (path, params: any) =>
      `${path.replace(":sessionId", `${params.sessionId}`).replace(":filename", params.filename)}`,
  }),

  downloadCapturedFile: async (params: { filename: string; sessionId: string }) => {
    const blob = await API.getCaptureFile(params);
    downloadFileBlob(blob, params.filename);
  },

  createLocalization: apiCreator<
    LocalizationAPITypes["createRequest"],
    LocalizationAPITypes["createResponse"]
  >("POST", LOCALIZATION_ENDPOINT_ENDING),

  getLocalization: createEndpoint(getEndpoint("localizations.sub.get")),
  getLocalizationCount: createEndpoint(getEndpoint("localizations.sub.count")),
  editLocalization: createEndpoint(getEndpoint("localizations.sub.update"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  deleteLocalization: createEndpoint(getEndpoint("localizations.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  getTranslations: createEndpoint(getEndpoint("localizations.sub.translations")),

  exportTranslations: createEndpoint(getEndpoint("localizations.sub.export"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
    fileRequest: true,
    isAuthenticated: true,
  }),

  importTranslation: createEndpoint(getEndpoint("localizations.sub.import"), {
    defaultContentType: false,
    bodyBuilder: (params: any) => {
      let formData = new FormData();
      if (params && params.data) {
        params.data.forEach((d) => formData.append(FORMDATA_FIELD_NAMES.translation, d, d.name));
      }
      if (params && params.values) {
        Object.keys(params.values).forEach((key) => {
          formData.append(key, params.values[key]);
        });
      }
      return formData;
    },
  }),

  reportTemplateUpload: createEndpoint(getEndpoint("reportTemplates.sub.upload"), {
    defaultContentType: false,
    bodyBuilder: (params: any) => {
      const formData = new FormData();

      if (params && params.files) {
        params.files.forEach((file) => formData.append(file.name, file));
      }
      if (params && params.values) {
        Object.keys(params.values).forEach((key) => {
          formData.append(key, params.values[key]);
        });
      }
      return formData;
    },
  }),

  getReportTemplates: createEndpoint(getEndpoint("reportTemplates.sub.get")),
  getReportTemplatesCount: createEndpoint(getEndpoint("reportTemplates.sub.count")),
  editReportTemplate: createEndpoint(getEndpoint("reportTemplates.sub.update"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  deleteReportTemplate: createEndpoint(getEndpoint("reportTemplates.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),

  upsertSchedulerGroups: createEndpoint(getEndpoint("schedulers.sub.groups.sub.update")),
  removeSchedulerGroup: createEndpoint(getEndpoint("schedulers.sub.groups.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),

  removeSchedulerGroupsAndScripts: createEndpoint(
    getEndpoint("schedulers.sub.groups.sub.deleteAll"),
  ),
  addToSchedulerGroup: createEndpoint(getEndpoint("schedulers.sub.groups.sub.scripts")),

  closeSessions: createEndpoint(getEndpoint("runner.sub.player.sub.close")),

  getLicense: apiCreator<{}, LicenseResponse>("GET", LICENSE_ENDPOINT_ENDING),
  setNewLicense: apiCreator<{ licenseId: string }, {}>("POST", `${LICENSE_ENDPOINT_ENDING}/id`),

  logout: createEndpoint(getEndpoint("users.sub.logout")),
  getPayPerUseCounter: apiCreator<any, PayPerUseCounterResponse>(
    "GET",
    `${STEP_LOGS_ENDPOINT_ENDING}/getPayPerUseCounter`,
  ),
  getPayPerUseStatistics: apiCreator<PayPerUseStatisticsRequest, PayPerUseStatisticsResponse>(
    "GET",
    `${STEP_LOGS_ENDPOINT_ENDING}/getPayPerUseStatistics`,
  ),
  createSchedulerFlowItem: apiCreator<
    SchedulerFlowAPITypes["createRequest"],
    SchedulerFlowAPITypes["createResponse"]
  >("POST", SCHEDULER_JOB_MAPPING_ENDPOINT_ENDING),

  editSchedulerFlowItem: apiCreator<
    Partial<SchedulerFlowAPITypes["editRequest"]>,
    SchedulerFlowAPITypes["editResponse"]
  >("PATCH", (params) => `${SCHEDULER_JOB_MAPPING_ENDPOINT_ENDING}/${params.id}`),
  deleteScreenshots: createEndpoint(getEndpoint("screenshots.sub.delete")),
  getScreenshotsStats: createEndpoint(
    getEndpoint("settings.sub.internal.sub.screenshot.sub.stats"),
  ),
  getScreenshotsAutoCleanup: createEndpoint(
    getEndpoint("settings.sub.internal.sub.screenshot.sub.get"),
  ),
  switchScreenshotsAutoCleanup: createEndpoint(
    getEndpoint("settings.sub.internal.sub.screenshot.sub.set"),
  ),

  createCodeTemplatesItem: createEndpoint(getEndpoint("codeTemplates.sub.create")),
  getCodeTemplatesItems: createEndpoint(getEndpoint("codeTemplates.sub.get")),
  getCodeTemplatesItemCount: createEndpoint(getEndpoint("codeTemplates.sub.count")),
  editCodeTemplatesItem: createEndpoint(getEndpoint("codeTemplates.sub.update"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  deleteCodeTemplatesItem: createEndpoint(getEndpoint("codeTemplates.sub.delete"), {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  }),
  exportCodeTemplate: createEndpoint(getEndpoint("codeTemplates.sub.export"), {
    fileRequest: true,
    isAuthenticated: true,
  }),
  importCodeTemplate: createEndpoint(getEndpoint("codeTemplates.sub.import"), {
    defaultContentType: false,
    bodyBuilder: (params) => {
      let formData = new FormData();
      if (params && params.data) {
        params.data.forEach((d) => formData.append(d.name, d));
      }
      return formData;
    },
  }),
};
