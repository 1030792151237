import { z } from "zod";
import {
  AssertActions,
  AssertionType,
  CONDITIONAL_ACTION_TYPE,
  CONDITIONAL_TYPE,
  ComparisonsOperators,
  ConditionTypes,
  DataSourceComparisonType,
  EXECUTION_STATE,
  FEATURES,
  GlobalSettingsResolversModes,
  INTERNAL_SETTINGS_KEYS,
  INTERRUPTION_TYPE,
  JOB_STATUS_TYPE,
  JOB_TRIGGER_TYPE,
  LicenseType,
  MESSAGE_TYPE,
  PLAY_MODE,
  PUBLIC_SETTINGS_KEYS,
  PathType,
  PlayerVisibility,
  ROLES,
  RUNNER_OPEN_MODE,
  RUNNER_POSITION,
  RUNNER_TYPE,
  RecurrenceType,
  ReportExtension,
  SSO,
  SYSTEM_TYPE,
  SaveVideoCondition,
  SchedulerMode,
  ScriptSpecialExecutionModes,
  StepTerminationAction,
  SubscribeUpdateModelNames,
  SupportedLanguages,
  VIRTUAL_USER_AUTH_TYPE,
  VIRTUAL_USER_STATUS,
} from "../types";

import { numberSequenceFormatValidator } from "./ea.validators";

import { PROJECT_DEFAULT_PATH } from "./ea.consts";
import {
  AllowedWorkItemTypes,
  AssignedVariableType,
  ComparisonLogicalOperator,
  DOCUMENTATION_TYPE,
  DatasourceType,
  DocumentationStatus,
  EXECUTION_SCRIPT_TYPES,
  EXECUTION_STATUS,
  GLOBAL_SETTINGS_KEYS,
  GlobalVariableType,
  IssueTrackingToolConfigurationState,
  IssueTrackingToolTrigger,
  RepeaterTypes,
  ScriptStatus,
  StepTerminationType,
  VariableDefaultValueType,
  WebsocketMessageTypes,
  WorkItemSeverity,
} from "./ea.enums";
import { beginWithLettersThenNumbersAndLetters } from "./ea.regex";
import { CodeExecutionLogType } from "./ea.runner.types";
import { GENERATED_BY_DB_PROPERTIES, ZodUtils } from "./ea.schema.helpers";
// https://github.com/colinhacks/zod/pull/1675
// don't use zod.any, use z.record(z.unknown()) for now

const DateZodType = z.union([
  z
    .string()
    .datetime()
    .transform((d) => new Date(d)),
  z.coerce.date(),
]);

export const booleanSchema = z
  .union([z.boolean(), z.literal("true"), z.literal("false")])
  .transform((value) => value === true || value === "true");

const DecimalZodType = z.preprocess((v) => (v ? parseFloat(v.toString()) : v), z.number());

const DatabaseId = z.number();

const CommonProperties = {
  id: DatabaseId,
  createdAt: DateZodType,
  updatedAt: DateZodType,
  modifiedBy: z.string().default("Unknown"),
};

export const TokenSchema = z.object({
  ttl: z.number().nonnegative(),
  created: DateZodType,
  name: z.string().default(""),
  userId: z.number(),
  sso: z.nativeEnum(SSO).optional().nullable(),
  // type: z.optional(z.enum(["Token", "ApiToken"])), //TODO transform apiKey property into type = ApiToken
  id: z.string(),
  apiKey: z.boolean(),
});

export const TokenSchemas = {
  general: TokenSchema,
  createData: ZodUtils.makeDefaultPropsOptional(TokenSchema).omit({ id: true, created: true }),
  create: ZodUtils.makeOptionalPropsNullable(TokenSchema).omit({ id: true, created: true }),
  update: TokenSchema.pick({ ttl: true, name: true, created: true }).partial(),
};

const RoleSchema = z.object({
  id: DatabaseId,
  name: z.nativeEnum(ROLES),
  description: z.string().optional(),
});

export const RoleSchemas = ZodUtils.generateEntitySchemas(RoleSchema);

const RolesZodType = z.union([
  z.array(z.number()),
  z
    .array(RoleSchema)
    .default([])
    .transform((roles) => roles.map((r) => r.id)),
]);

const UserSchema = z.object({
  ...CommonProperties,

  realm: z.optional(z.string()).nullish(),
  password: z.string(),
  username: z.string(),
  email: z.union([z.literal(""), z.string().email()]),
  emailVerified: z.boolean().default(false),
  language: z.nativeEnum(SupportedLanguages).default(SupportedLanguages.EN),

  sso: z.nativeEnum(SSO).nullish(),
  isActive: z.boolean().default(false),

  settings: z.object({
    playMode: z.nativeEnum(PLAY_MODE).optional(),
    table: z
      .record(
        z.object({
          hiddenColumns: z.array(z.string()).optional(),
          columns: z.array(z.string()).optional(),
        }),
      )
      .optional(),
    displayOnlyPublishedDocuments: z.boolean().optional(),
    reportExtension: z.nativeEnum(ReportExtension),
    hideAggregatedLogsView: z.boolean().optional(),
    documentationLocale: z.number(),
    its: z.number().optional().nullable(),
    runner: z
      .object({
        type: z.nativeEnum(RUNNER_TYPE).optional(),
        openMode: z.nativeEnum(RUNNER_OPEN_MODE).optional(),
        position: z.nativeEnum(RUNNER_POSITION).optional(),
      })
      .optional(),
    autoStart: z.boolean().optional(),
    slowMode: z.boolean().optional(),
    useSpecialMode: z.boolean().default(false),
    specialMode: z.nativeEnum(ScriptSpecialExecutionModes).optional().nullable(),
    playerVisibilityMode: z.nativeEnum(PlayerVisibility).optional().nullable(),
    slowExecution: z.number().optional().nullable(),
    saveVideoCondition: z.nativeEnum(SaveVideoCondition).optional().nullable(),
  }),
  roles: z.array(RoleSchema).default([]),
});
const UserSchemaWithoutPass = UserSchema.omit({ password: true });

export const UserGroupSchema = z.object({
  ...CommonProperties,
  name: z.string(),
  users: z.array(UserSchemaWithoutPass).default([]).optional(),
  description: z.string().default(""),
});

const UserGroupUsersZodType = z.union([
  z.array(z.number()),
  z
    .array(UserSchemaWithoutPass)
    .default([])
    .transform((users) => users.map((r) => r.id)),
]);

const UserGroupEdit = {
  users: UserGroupUsersZodType.optional(),
};

const UserGroupCreate = ZodUtils.makeOptionalPropsNullable(UserGroupSchema)
  .extend(UserGroupEdit)
  .omit(GENERATED_BY_DB_PROPERTIES)
  .omit({ users: true });

export const UserGroupSchemas = {
  general: UserGroupSchema,
  createData: ZodUtils.makeDefaultPropsOptional(UserGroupCreate),
  create: UserGroupCreate,
  update: ZodUtils.makeOptionalPropsNullable(UserGroupSchema)
    .extend(UserGroupEdit)
    .omit(GENERATED_BY_DB_PROPERTIES)
    .partial(),
};

const UserEdit = {
  groups: z
    .union([
      z.array(z.number()),
      z
        .array(UserGroupSchema)
        .default([])
        .transform((users) => users.map((r) => r.id)),
    ])
    .optional(),
  roles: RolesZodType.optional(),
};
const UserCreateSchema = ZodUtils.makeOptionalPropsNullable(UserSchema.extend(UserEdit)).omit(
  GENERATED_BY_DB_PROPERTIES,
);

export const UserSchemas = {
  general: UserSchemaWithoutPass.merge(z.object({ groups: z.array(UserGroupSchema).default([]) })),
  createData: ZodUtils.makeDefaultPropsOptional(UserCreateSchema),
  create: UserCreateSchema,
  update: ZodUtils.makeOptionalPropsNullable(UserCreateSchema).partial(),
  login: UserSchema.pick({ username: true, password: true }).extend({
    withLogout: booleanSchema.optional(),
  }),
};

export const DataSourceMetadataSchema = z.object({
  ...CommonProperties,
  name: z.string(),
  originalFilename: z.string(),
  sheetsMeta: z.record(z.unknown()),
  jobId: z.number().nullish(),
  type: z.nativeEnum(DatasourceType),
  projectIds: z.array(z.number()).optional(),
  migrated: z.boolean().optional().default(false),
});

export const GlobalSettingsAutoScreenshotsCleanupSchema = z.object({
  days: z.number(),
  startTime: DateZodType,
  enabled: z.boolean().default(false),
});

export const HotJarSettingsSchema = z.object({
  enabled: z.boolean(),
  siteId: z.number(),
  version: z.number().optional(),
});

const ThrottlingSchema = z.object({
  beforeExecution: z.number().optional(), // todo: should not be optional but there are errors if we remove it, do we have corrupted data?
  afterExecution: z.number().optional(), // todo: should not be optional but there are errors if we remove it, do we have corrupted data?
});

export const GlobalSettingsInternalValuesSchema = z.object({
  [INTERNAL_SETTINGS_KEYS.DISABLED_FEATURES]: z.array(z.nativeEnum(FEATURES)),
  [INTERNAL_SETTINGS_KEYS.AUTO_SCREENSHOTS_CLEANUP]: GlobalSettingsAutoScreenshotsCleanupSchema,
  [INTERNAL_SETTINGS_KEYS.HOTJAR]: HotJarSettingsSchema,
});

const GlobalSettingsPublicValuesSchema = z.object({
  [PUBLIC_SETTINGS_KEYS.RECAPTCHA_PUBLIC_KEY]: z.string().optional(),
  [PUBLIC_SETTINGS_KEYS.RECAPTCHA_ENABLED]: z.boolean().optional(),
  [PUBLIC_SETTINGS_KEYS.SSO_AZURE_ENABLED]: z.boolean().optional(),
  [PUBLIC_SETTINGS_KEYS.SSO_SAML_ENABLED]: z.boolean().optional(),
});

export const GlobalSettingsValuesSchema = z.object({
  [GLOBAL_SETTINGS_KEYS.STATUS_TRANSITIONS]: z.record(
    z.nativeEnum(ScriptStatus),
    z.object({ initial: z.boolean().optional(), to: z.array(z.nativeEnum(ScriptStatus)) }),
  ),
  [GLOBAL_SETTINGS_KEYS.PUBLISHING_CREDENTIALS_CONFIRMATION]: z.boolean(),
  [GLOBAL_SETTINGS_KEYS.DEFAULT_APP_USER_SETTINGS]: z.object({
    playMode: z.nativeEnum(PLAY_MODE).default(PLAY_MODE.FOREGROUND),
    reportExtension: z.nativeEnum(ReportExtension).default(ReportExtension.docx),
    runner: z.object({
      type: z.nativeEnum(RUNNER_TYPE).default(RUNNER_TYPE.ADVANCED),
      openMode: z.nativeEnum(RUNNER_OPEN_MODE).default(RUNNER_OPEN_MODE.NEW_WINDOW),
      position: z.nativeEnum(RUNNER_POSITION).default(RUNNER_POSITION.LEFT),
    }),
  }),
  [GLOBAL_SETTINGS_KEYS.TEST_PLANS_INTEGRATION]: z.boolean().default(true),
  [GLOBAL_SETTINGS_KEYS.TIMEOUTS]: z.object({
    inactivityTimeout: z.number(),
    step: z.object({ findElementTimeout: z.number() }),
    optionalStep: z.object({ findElementTimeout: z.number() }),
    redirectTimeout: z.number(),
  }),
  [GLOBAL_SETTINGS_KEYS.REPORT_TEMPLATE]: z.number().optional().nullable(),
  [GLOBAL_SETTINGS_KEYS.DOCUMENTATION_TEMPLATE]: z.number().optional().nullable(),
  [GLOBAL_SETTINGS_KEYS.ADMINISTRATION_ADDRESS]: z.string().optional(),
  [GLOBAL_SETTINGS_KEYS.REPORTS_LINK]: z.string().optional().nullable(),
  [GLOBAL_SETTINGS_KEYS.RESOLVERS_MODE]: z.nativeEnum(GlobalSettingsResolversModes),
  [GLOBAL_SETTINGS_KEYS.POSITION_RESOLVER_META_SCORE_THRESHOLD]: z.number().optional(),
  [GLOBAL_SETTINGS_KEYS.GENERATE_RESOLVERS_ON_PLAYBACK]: z.boolean().optional(),
  internal: GlobalSettingsInternalValuesSchema.optional(),
  public: GlobalSettingsPublicValuesSchema.optional(),
});

export const InternalSettingSchema = z.discriminatedUnion("id", [
  z.object({
    id: z.literal(INTERNAL_SETTINGS_KEYS.AUTO_SCREENSHOTS_CLEANUP),
    value:
      GlobalSettingsInternalValuesSchema.shape[INTERNAL_SETTINGS_KEYS.AUTO_SCREENSHOTS_CLEANUP],
  }),
  z.object({
    id: z.literal(INTERNAL_SETTINGS_KEYS.DISABLED_FEATURES),
    value: GlobalSettingsInternalValuesSchema.shape[INTERNAL_SETTINGS_KEYS.DISABLED_FEATURES],
  }),
  z.object({
    id: z.literal(INTERNAL_SETTINGS_KEYS.HOTJAR),
    value: GlobalSettingsInternalValuesSchema.shape[INTERNAL_SETTINGS_KEYS.HOTJAR],
  }),

  z.object({
    id: z.literal(PUBLIC_SETTINGS_KEYS.RECAPTCHA_PUBLIC_KEY),
    value: GlobalSettingsPublicValuesSchema.shape[PUBLIC_SETTINGS_KEYS.RECAPTCHA_PUBLIC_KEY],
  }),

  z.object({
    id: z.literal(PUBLIC_SETTINGS_KEYS.RECAPTCHA_ENABLED),
    value: GlobalSettingsPublicValuesSchema.shape[PUBLIC_SETTINGS_KEYS.RECAPTCHA_ENABLED],
  }),

  z.object({
    id: z.literal(PUBLIC_SETTINGS_KEYS.SSO_AZURE_ENABLED),
    value: GlobalSettingsPublicValuesSchema.shape[PUBLIC_SETTINGS_KEYS.SSO_AZURE_ENABLED],
  }),

  z.object({
    id: z.literal(PUBLIC_SETTINGS_KEYS.SSO_SAML_ENABLED),
    value: GlobalSettingsPublicValuesSchema.shape[PUBLIC_SETTINGS_KEYS.SSO_SAML_ENABLED],
  }),
]);

export const InternalSettingsSchema = z.array(InternalSettingSchema);

export const GlobalSettingSchema = z.discriminatedUnion("id", [
  z.object({
    id: z.literal(GLOBAL_SETTINGS_KEYS.STATUS_TRANSITIONS),
    value: GlobalSettingsValuesSchema.shape[GLOBAL_SETTINGS_KEYS.STATUS_TRANSITIONS],
  }),
  z.object({
    id: z.literal(GLOBAL_SETTINGS_KEYS.PUBLISHING_CREDENTIALS_CONFIRMATION),
    value:
      GlobalSettingsValuesSchema.shape[GLOBAL_SETTINGS_KEYS.PUBLISHING_CREDENTIALS_CONFIRMATION],
  }),
  z.object({
    id: z.literal(GLOBAL_SETTINGS_KEYS.DEFAULT_APP_USER_SETTINGS),
    value: GlobalSettingsValuesSchema.shape[GLOBAL_SETTINGS_KEYS.DEFAULT_APP_USER_SETTINGS],
  }),
  z.object({
    id: z.literal(GLOBAL_SETTINGS_KEYS.TEST_PLANS_INTEGRATION),
    value: GlobalSettingsValuesSchema.shape[GLOBAL_SETTINGS_KEYS.TEST_PLANS_INTEGRATION],
  }),
  z.object({
    id: z.literal(GLOBAL_SETTINGS_KEYS.TIMEOUTS),
    value: GlobalSettingsValuesSchema.shape[GLOBAL_SETTINGS_KEYS.TIMEOUTS],
  }),
  z.object({
    id: z.literal(GLOBAL_SETTINGS_KEYS.REPORT_TEMPLATE),
    value: GlobalSettingsValuesSchema.shape[GLOBAL_SETTINGS_KEYS.REPORT_TEMPLATE],
  }),
  z.object({
    id: z.literal(GLOBAL_SETTINGS_KEYS.DOCUMENTATION_TEMPLATE),
    value: GlobalSettingsValuesSchema.shape[GLOBAL_SETTINGS_KEYS.DOCUMENTATION_TEMPLATE],
  }),
  z.object({
    id: z.literal(GLOBAL_SETTINGS_KEYS.ADMINISTRATION_ADDRESS),
    value: GlobalSettingsValuesSchema.shape[GLOBAL_SETTINGS_KEYS.ADMINISTRATION_ADDRESS],
  }),
  z.object({
    id: z.literal(GLOBAL_SETTINGS_KEYS.REPORTS_LINK),
    value: GlobalSettingsValuesSchema.shape[GLOBAL_SETTINGS_KEYS.REPORTS_LINK],
  }),
  z.object({
    id: z.literal(GLOBAL_SETTINGS_KEYS.RESOLVERS_MODE),
    value: GlobalSettingsValuesSchema.shape[GLOBAL_SETTINGS_KEYS.RESOLVERS_MODE],
  }),
  z.object({
    id: z.literal(GLOBAL_SETTINGS_KEYS.POSITION_RESOLVER_META_SCORE_THRESHOLD),
    value:
      GlobalSettingsValuesSchema.shape[GLOBAL_SETTINGS_KEYS.POSITION_RESOLVER_META_SCORE_THRESHOLD],
  }),
  z.object({
    id: z.literal(GLOBAL_SETTINGS_KEYS.GENERATE_RESOLVERS_ON_PLAYBACK),
    value: GlobalSettingsValuesSchema.shape[GLOBAL_SETTINGS_KEYS.GENERATE_RESOLVERS_ON_PLAYBACK],
  }),
]);

export const GlobalSettingsSchema = z.array(GlobalSettingSchema);
export const DatasourceSchema = z.object({
  ...CommonProperties,
  name: z.string(),
  originalFilename: z.string(),
  storageModifiedTime: z
    .union([
      z
        .string()
        .datetime()
        .transform((p) => new Date(p)),
      z.date(),
    ])
    .optional()
    .nullable(),
  sheetsMeta: z.any(), // TODO sheetsMeta schema
  jobId: z.number().optional().nullable(),
  temporary: z.boolean().optional().default(false),
  migrated: z.boolean().optional().default(false),
  type: z.string(),
});

export const GlobalSettingSchemas = {
  general: GlobalSettingSchema,
  update: GlobalSettingSchema,
};

export const DataSourceDataSchema = z.object({
  id: DatabaseId,
  sheet: z.string(),
  rowNumber: z.number(),
  dataSourceId: z.number(),
  rowData: z.record(z.any()),
});

const KpiSchema = z.object({
  ...CommonProperties,
  name: z
    .string()
    .regex(
      beginWithLettersThenNumbersAndLetters,
      "Kpi name, must start with letter and contain only letter and numbers",
    ),
  description: z.string().default(""),
});

export const KpiLogDataSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const KpiSchemas = ZodUtils.generateEntitySchemas(KpiSchema);

export const LabelParamsSchema = z.record(
  z.union([z.string(), z.number(), z.undefined(), z.null(), z.boolean()]),
); // TODO: type for LabelParams;

export const MessageSchema = z.object({
  text: z
    .string()
    .optional()
    .nullable()
    .transform((v) => (v ? v : "")),
  type: z.nativeEnum(MESSAGE_TYPE),
  errorType: z.string().optional(),
  labelKey: z.string().optional(),
  labelParams: LabelParamsSchema.optional(),
  showToast: z.boolean().optional(),
  isPlatformMessage: z.boolean().optional(),
  overrideStatus: z.nativeEnum(EXECUTION_STATUS).optional(),
});

const RuleMetadataSchema = z.object({
  id: z.string().optional(), // todo: optional?
  version: z.string().optional(),
  eventType: z.string().optional(), // todo: optional?
  platform: z.string().optional(),
  metadata: z.any().optional(),
});

export const WorkItemSchema = z.object({
  ...CommonProperties,
  title: z.string(),
  externalId: z.number(),
  type: z.nativeEnum(AllowedWorkItemTypes),
  url: z.string(),
  itsId: z.number().optional().nullable(),
  itsName: z.string(),
  scriptId: z.number().optional(),
  scriptName: z.string(),
  stepLabel: z.string(),
  logId: z.number().optional(),
  stepLogId: z.number().optional().nullable(),
  sessionId: z.string().optional(),
});

export const WorkItemSchemas = {
  general: WorkItemSchema,
  createData: ZodUtils.makeOptionalPropsNullable(WorkItemSchema).omit(GENERATED_BY_DB_PROPERTIES),
  create: ZodUtils.makeOptionalPropsNullable(WorkItemSchema).omit(GENERATED_BY_DB_PROPERTIES),
};

export const VariableLogsSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal(CodeExecutionLogType.CALCULATE),
    output: z.coerce.string(),
    input: z.string(),
    executedCode: z.string(),
  }),
  z.object({
    type: z.literal(CodeExecutionLogType.ASSIGN),
    output: z.record(z.coerce.string()),
    input: z.string(),
    executedCode: z.string(),
    changedVariables: z.record(
      z.object({
        name: z.string(),
        value: z.coerce.string(),
        type: z.nativeEnum(AssignedVariableType).optional(),
      }),
    ),
  }),
]);

export const ExecutionStepLogSchema = z.object({
  id: DatabaseId,
  executionId: z.number(),
  status: z.nativeEnum(EXECUTION_STATUS).default(EXECUTION_STATUS.NONE),
  label: z.string().default(""),
  lineNum: z.number().default(0),
  screenshotPath: z.string().default(""),
  variableLogs: VariableLogsSchema.optional(),
  messages: z.array(MessageSchema).optional(),
  linkedScriptId: z.number().optional(),
  parentStepLogId: z.number().default(0),
  stepData: z
    .object({
      id: z.number(),
      commandId: z.string(),
      platform: z.object({
        id: z.string(),
        version: z.string().optional(),
      }),
      rule: RuleMetadataSchema.optional().nullable(),
    })
    .optional(),
  workItem: WorkItemSchema.optional(),
  virtualUserId: z.number().optional(),
  labelKey: z.string().optional(),
  labelParams: z.any().optional(),
  kpi: z.array(KpiLogDataSchema).optional(),
  startTime: DateZodType.optional(),
  endTime: DateZodType.optional(),
  duration: z.coerce.bigint().optional(),
});

export const ExecutionStepLogSchemas = {
  general: ExecutionStepLogSchema,
  createData: ZodUtils.makeDefaultPropsOptional(
    ZodUtils.makeOptionalPropsNullable(ExecutionStepLogSchema).omit({ id: true }),
  ),
  create: ZodUtils.makeOptionalPropsNullable(ExecutionStepLogSchema).omit({ id: true }),
  update: ZodUtils.makeOptionalPropsNullable(ExecutionStepLogSchema).partial(),
};

export const ExecutionJobLogSchema = z.object({
  id: DatabaseId,
  jobId: z.string().optional(),
  jobExecutionId: z.string().optional(),
  startTime: DateZodType.optional(),
  endTime: DateZodType.optional(),
  status: z.nativeEnum(EXECUTION_STATUS),
  state: z.nativeEnum(EXECUTION_STATE),
  successCount: z.number().optional(),
  warningCount: z.number().optional(),
  errorCount: z.number().optional(),
  allCount: z.number().optional(),
  duration: z.coerce.bigint().optional(),
  projectId: z.number().optional(),
  projectName: z.string().optional(),
  schedulerJobName: z.string().optional(),
  schedulerJobId: z.number().optional(),
  isExcluded: z.boolean().default(false),
});

export const CodeTemplateCodeParamsSchema = z.object({
  name: z.string(),
  description: z.string().nullable().optional(),
});
export const CodeTemplateDefinitionSchema = z.object({
  params: z.array(CodeTemplateCodeParamsSchema).optional(),
  code: z.string(),
});

export const CodeTemplateGroupSchema = z.object({
  ...CommonProperties,
  name: z
    .string()
    .regex(
      beginWithLettersThenNumbersAndLetters,
      "Code template group name, must start with letter and contain only letter and numbers",
    ),
  description: z.string().default(""),
});
export const CodeTemplateGroupSchemas = ZodUtils.generateEntitySchemas(CodeTemplateGroupSchema);

export const CodeTemplateSchema = z.object({
  ...CommonProperties,
  name: z.string(),
  codeTemplateGroupId: z.number(),
  activeVersion: z.number(),
  functionName: z
    .string()
    .regex(
      beginWithLettersThenNumbersAndLetters,
      "Code template function name, must start with letter and contain only letter and numbers",
    ),
  definitions: z.record(z.string().startsWith("v"), CodeTemplateDefinitionSchema),
  description: z.string().optional().default(""),
  codeTemplateGroup: CodeTemplateGroupSchema.partial().optional(), //TODO change how schema should work with transform when you fetch from database with select fields query eg.: {select: {name: true, id: true}}
});

export const CodeTemplatesSchemas = ZodUtils.generateEntitySchemas(CodeTemplateSchema);

const GlobalVariableSchema = z.object({
  ...CommonProperties,
  name: z
    .string()
    .regex(
      beginWithLettersThenNumbersAndLetters,
      "Global variable name must start with letter and contain only letter and numbers",
    ),
  type: z.nativeEnum(GlobalVariableType),
  valueType: z.nativeEnum(VariableDefaultValueType).optional(),
  value: z.string().default(""),
  isSensitive: z.boolean().default(false),
  description: z.string().default(""),
});

export const GlobalVariableSchemas = ZodUtils.generateEntitySchemas(GlobalVariableSchema);

const NumberSequenceSchema = z.object({
  name: z
    .string()
    .regex(
      beginWithLettersThenNumbersAndLetters,
      "Number sequence name must start with letter and contain only letter and numbers",
    ),
  format: z.string().superRefine((format, ctx) => {
    const validateResult = numberSequenceFormatValidator(format);
    if (!validateResult) {
      return;
    }

    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: validateResult,
    });
  }),
  nextval: z.union([
    z.string(),
    z.number().transform((a) => `${a}`),
    z.bigint().transform((a) => a.toString()),
  ]),
  ...CommonProperties,
});

export const NumberSequenceSchemas = {
  general: NumberSequenceSchema,
  create: ZodUtils.makeOptionalPropsNullable(NumberSequenceSchema)
    .omit(GENERATED_BY_DB_PROPERTIES)
    .extend({
      nextval: z.union([
        z.string(),
        z.number().transform((a) => `${a}`),
        z.bigint().transform((a) => a.toString()),
        z.undefined(),
      ]),
    }),
  update: ZodUtils.makeOptionalPropsNullable(NumberSequenceSchema).partial(),
};

export const VariableSchema = z.object({
  ...CommonProperties,
  parentId: z.number(),
  parentType: z.string(),
  name: z
    .string()
    .regex(
      beginWithLettersThenNumbersAndLetters,
      "Variable name must start with letter and contain only letter and numbers",
    ),
  groupName: z.string().optional().nullable(),
  valueType: z.string().optional().nullable(),
  type: z.string().default("Normal"),
  defaultValue: z.string().optional().nullable(),
});

export const VariableMapSchema = z.object({
  stepId: z.number().optional(),
  linkId: z.number().optional().nullable(),
  sourceVariableId: z.number().optional().nullable(),
  destVariableId: z.number().optional().nullable(),
  variableType: z.nativeEnum(AssignedVariableType).optional().nullable(),
  sourceVariableType: z.nativeEnum(AssignedVariableType).optional().nullable(),
  destVariableType: z.nativeEnum(AssignedVariableType).optional().nullable(),
});

export const PatchVariablesSchema = z.object({
  parentId: z.number(),
  parentType: z.string(),
  variables: z
    .array(
      ZodUtils.makeDefaultPropsOptional(
        VariableSchema.omit(GENERATED_BY_DB_PROPERTIES).merge(
          z.object({ id: DatabaseId.optional() }),
        ),
      ),
    )
    .superRefine((variables, ctx) => {
      const names = variables.map((e) => e.name);
      if (variables.length !== new Set(names).size) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Variable names cannot be duplicated`,
        });
      }
    }),
});

export const VariableSchemas = {
  general: VariableSchema,
  createData: ZodUtils.makeDefaultPropsOptional(VariableSchema).omit(GENERATED_BY_DB_PROPERTIES),
  create: ZodUtils.makeOptionalPropsNullable(VariableSchema).omit(GENERATED_BY_DB_PROPERTIES),
  update: ZodUtils.makeOptionalPropsNullable(VariableSchema).partial(),
  patchDiff: PatchVariablesSchema,
};

export const LocalizationSchema = z.object({
  id: DatabaseId,
  locale: z.string(),
  description: z.string().default(""),
  needsAttention: z.boolean().default(false),
  translations: z.record(z.unknown()),
});

export const ConditionValueSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.enum([
      ConditionTypes.STEP_VALUE,
      ConditionTypes.GLOBAL_CONSTANT,
      ConditionTypes.GLOBAL_MUTABLE,
      ConditionTypes.VARIABLE,
    ]),
    value: z.coerce.number(),
  }),
  z.object({
    type: z.literal(ConditionTypes.CONST),
    value: z.string(),
  }),
]);

const DataSourceComparisonSchema = z.object({
  type: z.literal(AssertionType.DATA_SOURCE),
  comparisonType: z.nativeEnum(DataSourceComparisonType),
  value: z.string(),
  resetOnLast: z.boolean().optional(),
  logicalOperator: z.nativeEnum(ComparisonLogicalOperator).optional(),
});

export const CheckValueComparisonSchema = z.object({
  type: z.literal(AssertionType.CHECK_VALUE),
  operator: z.nativeEnum(ComparisonsOperators),
  leftCondition: ConditionValueSchema,
  rightCondition: ConditionValueSchema,
  logicalOperator: z.nativeEnum(ComparisonLogicalOperator).optional(),
});

export const ActivityActionSchema = z.object({
  type: z.nativeEnum(AssertActions),
  values: z.array(z.any()),
  message: z.string().optional(),
  overrideStatus: z.boolean().optional(),
  overrideStatusTo: z.nativeEnum(EXECUTION_STATUS).optional(),
});

export const ActivityExecution = z.object({
  ifAction: ActivityActionSchema,
  elseAction: ActivityActionSchema,
});

export const ActivitySchema = ActivityExecution.merge(
  z.object({
    comparison: z.array(z.union([DataSourceComparisonSchema, CheckValueComparisonSchema])),
    ifAction: ActivityActionSchema,
    elseAction: ActivityActionSchema,
  }),
);

export const ReactionSchema = z.discriminatedUnion("activityType", [
  z.object({
    activityType: z.literal(CONDITIONAL_ACTION_TYPE.SIMPLE),
    reactOn: z.union([z.nativeEnum(INTERRUPTION_TYPE), z.literal(CONDITIONAL_TYPE.SUCCESS)]),
    activity: ActivityActionSchema,
  }),
  z.object({
    activityType: z.literal(CONDITIONAL_ACTION_TYPE.ADVANCED),
    reactOn: z.union([z.nativeEnum(INTERRUPTION_TYPE), z.literal(CONDITIONAL_TYPE.SUCCESS)]),
    activity: ActivitySchema,
  }),
]);

const GuardSchemaChunk = z.object({
  ...CommonProperties,
  name: z.string().default(""),
  scriptId: z.number(),
  type: z.nativeEnum(CONDITIONAL_TYPE),
  reactions: z.array(ReactionSchema),
  kpis: z.array(KpiSchema).optional(),
});

const StepSchema = z.object({
  ...CommonProperties,

  paths: z
    .array(
      z.object({
        path: z.string(),
        type: z.nativeEnum(PathType),
      }),
    )
    .default([]),
  resolvers: z
    .object({
      metadata: z.array(z.record(z.any())),
      data: z.array(z.any()),
    })
    .optional(),
  isDisabled: z.boolean().default(false),
  isOptional: z.boolean().default(false),
  isOneTime: z.boolean().default(false),
  isTimeoutEnabled: z.boolean().default(false),
  takeScreenshots: z.boolean().optional().nullable(),
  manualLabel: z.boolean().default(false),
  comment: z.string().default(""),
  commandId: z.string(),
  rule: RuleMetadataSchema.optional(),
  label: z.string(),
  labelKey: z.string().optional(),
  hasBeenLabelModified: z.boolean().optional(),
  lineNum: z.number(),
  value: z.any().optional(),
  platform: z
    .object({
      id: z.string(),
      version: z.string().default(""),
      options: z
        .object({
          forceOpenPopup: z.boolean().nullable().optional(),
        })
        .optional(),
    })
    .default({
      id: "DEFAULT_PLATFORM", // todo: can't get DEFAULT_PLATFORM_ID here, ADD TO DB
      version: "",
    }),
  termination: z
    .object({
      type: z.nativeEnum(StepTerminationType),
      value: z.coerce.number(),
      action: z.nativeEnum(StepTerminationAction),
    })
    .optional(),
  timeout: z.number().optional(),

  taskScriptId: z.number(),
  linkedScriptId: z.number().optional(),
  linkName: z.string().optional(),

  executionCount: z.number().optional(),
  averageDuration: DecimalZodType.optional(),
  structureVersion: z.string().default("0.0.1"),

  triggersRefresh: z.boolean().default(false),

  disableLinkedStartStep: z.boolean().default(false),
  delay: z.coerce.number().optional(),
  frames: z
    .array(
      z.object({
        className: z.string().optional(),
        id: z.string().optional(),
        name: z.string().optional(),
        parentId: z.string().optional(),
        parentTagName: z.string().optional(),
        parentAttributes: z
          .array(
            z.object({
              name: z.string(),
              value: z.string(),
            }),
          )
          .optional(),
        iframeIndex: z.number().optional(),
        ariaLabel: z.string().optional().optional().nullable(),
      }),
    )
    .optional(),

  throttling: ThrottlingSchema.optional(),

  labelParams: LabelParamsSchema.optional(),
  guards: z.array(GuardSchemaChunk).default([]),
  codeTemplates: z.array(CodeTemplateSchema).optional(),
  files: z.array(z.string()).default([]).optional(),
  version: z.string().optional(),
});

const StepCreateSchema = ZodUtils.makeOptionalPropsNullable(StepSchema)
  .omit(GENERATED_BY_DB_PROPERTIES)
  .partial({
    lineNum: true,
    label: true,
    guards: true,
  });

export const StepSchemas = {
  general: StepSchema,
  created: ZodUtils.makeOptionalPropsNullable(StepSchema).omit(GENERATED_BY_DB_PROPERTIES),
  createData: ZodUtils.makeDefaultPropsOptional(StepCreateSchema),
  create: StepCreateSchema,
  update: ZodUtils.makeOptionalPropsNullable(StepSchema).partial(),
};

const GuardSchema = GuardSchemaChunk.extend({ steps: z.array(StepSchema).optional() });
const GuardCreateSchema = ZodUtils.makeOptionalPropsNullable(
  GuardSchemaChunk.extend({ steps: z.array(z.number()) }).omit(GENERATED_BY_DB_PROPERTIES),
);

export const GuardSchemas = {
  general: GuardSchema,
  createData: ZodUtils.makeDefaultPropsOptional(GuardCreateSchema),
  create: GuardCreateSchema,
  update: ZodUtils.makeOptionalPropsNullable(GuardCreateSchema).partial(),
};

export const LogMessageSchema = z.object({
  labels: z.array(
    StepSchema.pick({ label: true, lineNum: true, labelKey: true, labelParams: true }).merge(
      z.object({
        label: z.string().nullable().optional(),
        labelKey: z.string().nullable().optional(),
        labelParams: LabelParamsSchema.optional().nullable(),
      }),
    ),
  ),
  level: z.number(),
  path: z.string(),
  messages: z.array(
    MessageSchema.pick({ type: true, text: true, labelKey: true, labelParams: true }),
  ),
});

export const ExecutionLogSchema = z.object({
  id: DatabaseId,
  sessionId: z.string(),
  startTime: DateZodType.optional(),
  endTime: DateZodType.optional(),
  isBackground: z.boolean().default(false),
  type: z.nativeEnum(EXECUTION_SCRIPT_TYPES).optional(),
  internal: z.boolean().default(false),
  state: z.nativeEnum(EXECUTION_STATE),
  scriptStatus: z.union([z.nativeEnum(ScriptStatus), z.literal("")]).optional(),
  status: z.nativeEnum(EXECUTION_STATUS).default(EXECUTION_STATUS.NONE),
  url: z.string().default(""),
  duration: z.coerce.bigint().optional(),
  callerId: z.number().optional(),
  iteration: z.number().default(0),
  scriptId: z.number().optional(),
  scriptName: z.string().default(""),
  scriptFlowId: z.string().optional(),
  schedulerJobId: z.number().optional(),
  schedulerJobName: z.string().default(""),
  apiKeyName: z.string().default(""),
  projectId: z.number().optional(),
  projectName: z.string().default(""),
  messages: z.array(LogMessageSchema).default([]),
  scriptVersion: z.string().default(""),
  jobId: z.string().optional(),
  jobExecutionId: z.string().optional(),
  tags: z.array(z.string()).default([]),
  integrationMetadata: z.object({}).catchall(z.any()).optional(), // TODO: type for ExecutionLogIntegrationMetadata
  mappedWithDevops: z.boolean().default(false),
  envName: z.string().default(""),
  virtualUserName: z.string().default(""),
  executorName: z.string().default(""),
  isSessionActive: z.boolean().default(false),
  scriptDescription: z.string().default(""),
  scriptSignedBy: z.string().optional(),
});

export const ExecutionLogSchemas = {
  general: ExecutionLogSchema,
  createData: ZodUtils.makeDefaultPropsOptional(
    ZodUtils.makeOptionalPropsNullable(ExecutionLogSchema).omit({ id: true }),
  ),
  create: ZodUtils.makeOptionalPropsNullable(ExecutionLogSchema).omit({ id: true }),
  update: ZodUtils.makeOptionalPropsNullable(ExecutionLogSchema).partial(),
};

export const SystemUrlSchema = z.object({
  id: DatabaseId,
  name: z.string(),
  url: z.string(),
});

const SystemSchema = z.object({
  ...CommonProperties,
  envName: z.string(),
  envAddress: z.string(), // todo: NEW_TYPES .url(), doesn't pass
  envType: z.nativeEnum(SYSTEM_TYPE).default(SYSTEM_TYPE.OTHER),
  applicationName: z.string().default(""),
  description: z.string().default(""),
  userId: z.number().optional(),
  systemPlatform: z.string().default("DEFAULT_PLATFORM"),
  options: z
    .object({
      enableMultiWindowsSupport: z.boolean().optional(),
      disableWebSecurity: z.boolean().optional(),
    })
    .strict()
    .optional(),
  meta: z.object({}).catchall(z.any()).optional(), // discriminated union by systemPlatform?? or overkill?
});

const SystemCreateSchema = ZodUtils.makeOptionalPropsNullable(SystemSchema).omit(
  GENERATED_BY_DB_PROPERTIES,
);

export const SystemSchemas = {
  general: SystemSchema,
  createData: ZodUtils.makeDefaultPropsOptional(SystemCreateSchema),
  create: SystemCreateSchema,
  update: ZodUtils.makeOptionalPropsNullable(SystemSchema).partial(),
};

const CommonSystemDataSchema = z.discriminatedUnion("reauthenticate", [
  z.object({
    reauthenticate: z.literal(true),
    isManual: z.literal(false).optional(),
    rememberSensitiveData: z.boolean().optional(),
    reauthenticateInterval: z
      .union([z.string(), z.number()])
      .transform((i) => parseInt(`${i}`, 10)),
  }),
  z.object({
    reauthenticate: z.literal(false),
    isManual: z.boolean().optional(),
    rememberSensitiveData: z.boolean().optional(),
    reauthenticateInterval: z
      .union([z.string(), z.number()])
      .transform((i) => parseInt(`${i}`, 10))
      .optional(),
  }),
  z.object({
    reauthenticate: z.literal(undefined),
    isManual: z.boolean().optional(),
    rememberSensitiveData: z.boolean().optional(),
    reauthenticateInterval: z.undefined(),
  }),
]);

export const SystemDataSchema = z
  .discriminatedUnion("useCustomScript", [
    z.object({
      useCustomScript: z.literal(true),
      customScriptId: z.number(),
      values: z.array(
        z.object({
          key: z.string(),
          value: z.string(),
          isSensitive: z.boolean().optional(),
          isSystemLogin: z.boolean().optional(),
        }),
      ),
    }),
    z.object({
      useCustomScript: z.literal(false),
      platform: z.string(),
      values: z.record(z.object({ value: z.any(), isSensitive: z.boolean().optional() })),
    }),
    z.object({
      useCustomScript: z.literal(undefined),
      platform: z.string(),
      values: z.record(z.object({ value: z.any(), isSensitive: z.boolean().optional() })),
    }),
  ])
  .and(CommonSystemDataSchema);

const SystemDataSchemaChunk = z.discriminatedUnion("isSystemAuthEnabled", [
  z.object({ isSystemAuthEnabled: z.literal(true), systemData: SystemDataSchema }),
  z.object({
    isSystemAuthEnabled: z.literal(false),
    systemData: z.object({}).optional().nullable(),
  }),
  z.object({
    isSystemAuthEnabled: z.undefined(),
    systemData: z.undefined(),
  }),
]);

export const ServerDataSchemaChunk = z.discriminatedUnion("serverAuthType", [
  z.object({
    serverAuthType: z.literal(VIRTUAL_USER_AUTH_TYPE.BASIC_AUTH),
    serverData: z.object({
      login: z.string(),
      password: z.string(),
    }),
  }),
  z.object({
    serverAuthType: z.literal(VIRTUAL_USER_AUTH_TYPE.NTLM),
    serverData: z.object({
      login: z.string(),
      password: z.string(),
      workstation: z.string().optional(),
      domain: z.string().optional(),
    }),
  }),
  z.object({
    serverAuthType: z.literal(VIRTUAL_USER_AUTH_TYPE.NONE),
    serverData: z
      .object({
        login: z.string().optional().nullable(),
        password: z.string().optional().nullable(),
      })
      .partial()
      .optional()
      .nullable(),
  }),
  z.object({
    serverAuthType: z.undefined(),
    serverData: z
      .object({
        login: z.string().optional().nullable(),
        password: z.string().optional().nullable(),
      })
      .partial()
      .optional()
      .nullable(),
  }),
]);

const VirtualUserCommon = {
  name: z.string(),
  description: z.string().optional(),
  status: z.nativeEnum(VIRTUAL_USER_STATUS).default(VIRTUAL_USER_STATUS.NOT_AUTHENTICATED),
};

const VirtualUserDb = {
  ...CommonProperties,
  lastAuthenticationDate: DateZodType.optional(),
  errorMessage: z.string().optional(),
  tokens: z.object({}).catchall(z.any()).optional(),
};

export const VirtualUserSchemaBase = z.object({
  ...VirtualUserDb,
  ...VirtualUserCommon,
});

const VirtualUserSchemaBaseNullable = ZodUtils.makeOptionalPropsNullable(
  VirtualUserSchemaBase,
).omit(GENERATED_BY_DB_PROPERTIES);

export const VirtualUserSchemas = {
  // virtual user uses discriminated union types and we need to call null => undefined here
  generalMapper: ZodUtils.convertNullsToUndefinedInOptionalProps(VirtualUserSchemaBase)
    .deepPartial()
    .and(ServerDataSchemaChunk)
    .and(SystemDataSchemaChunk),
  general: VirtualUserSchemaBase.and(ServerDataSchemaChunk).and(SystemDataSchemaChunk),
  createData: ZodUtils.makeDefaultPropsOptional(VirtualUserSchemaBaseNullable)
    .and(ServerDataSchemaChunk)
    .and(SystemDataSchemaChunk),
  create: VirtualUserSchemaBaseNullable.and(ServerDataSchemaChunk).and(SystemDataSchemaChunk),
  update: VirtualUserSchemaBaseNullable.partial()
    .and(ServerDataSchemaChunk)
    .and(SystemDataSchemaChunk),
};

export const LicenseSchema = z.object({
  type: z.nativeEnum(LicenseType),
  expirationDate: DateZodType.optional(),
  supportExpirationDate: DateZodType.optional(),
  maximumParallelSessions: z.coerce.number().min(1).optional(),
  maximumConcurrentUsers: z.coerce.number().min(1).optional(),
  licenseNumber: z.string().optional(),
});

// todo: finish schema
export const BackgroundStartParams = z.object({});

export const ProjectCategorySchema = z.object({
  ...CommonProperties,
  name: z.string(),
  description: z.string().default(""),
});

export const ProjectCategorySchemas = ZodUtils.generateEntitySchemas(ProjectCategorySchema);

export const TagSchema = z.object({
  ...CommonProperties,
  name: z.string(),
  description: z.string().default(""),
});

export const TagSchemas = ZodUtils.generateEntitySchemas(TagSchema);

export const TaskScriptTagSchema = z.object({
  id: DatabaseId,
  taskScriptId: z.number().optional(),
  tagId: z.number().optional(),
});

export const AdditionalJobParamSchema = z.object({
  noPageCache: z.boolean().default(false),
  recordVideo: z.boolean().default(false),
});

export const BackgroundStartParamsScheme = z.object({
  specialMode: z.nativeEnum(ScriptSpecialExecutionModes).nullable(),
  useSpecialMode: z.boolean().default(false).nullable(),
  playerVisibilityMode: z.nativeEnum(PlayerVisibility).nullable(),
  saveVideoCondition: z.nativeEnum(SaveVideoCondition).nullable(),
  slowExecution: z.number().nullable(),
  jobId: z.string(),
  documentationLocale: z.number().nullable(),
  schedulerJobId: z.number(),
  notify: z.boolean().default(false),
  datasourceId: z.number().nullable(),
  additionalStartParams: AdditionalJobParamSchema,
  dispatchStartTime: DateZodType.nullable(),
  azureConfigurationId: z.number().nullable(),
});

export const IssueTrackingToolCustomFieldSchema = z.object({
  name: z.string(),
  value: z.any(),
});

export const AzureDevopsIntegrationMetadataSchema = z.object({
  iteration: z.string(),
  project: z.string(),
  priority: z.number(),
  area: z.string().optional(),
});

const IssueTrackingIntegrationMetadata = z.object({
  azureDevops: AzureDevopsIntegrationMetadataSchema,
});

const IssueTrackingToolConnectionParams = z.object({
  host: z.string(),
  token: z.string(),
  project: z.string(),
});

export const BaseAzureIntegrationMetadataSchema = z.object({
  testPlanId: z.string(),
  testSuiteId: z.string(),
  itsId: z.number(),
  project: z.string(),
});

export const ScriptIntegrationMetadataSchema = z.object({
  azureDevops: z
    .object({
      testCaseId: z.string(),
      testCaseUrl: z.string(),
    })
    .merge(BaseAzureIntegrationMetadataSchema)
    .optional(),
});

export const IssueTrackingToolSchema = z
  .object({
    id: DatabaseId,
    // project: z.string(), // probably should not be used anymore > moved to integrationmetadata
    name: z.string(),
    severity: z.nativeEnum(WorkItemSeverity),
    priority: z.preprocess((v) => `${v}`, z.string()),
    iteration: z.string(),
    customFields: z.array(IssueTrackingToolCustomFieldSchema),
    type: z.nativeEnum(AllowedWorkItemTypes),
    integrationMetadata: IssueTrackingIntegrationMetadata,
    area: z.string().optional(),
  })
  .merge(IssueTrackingToolConnectionParams);

export const DevopsProjectsExportParamsSchema = z.object({
  itsId: z.coerce.number(),
  projectIds: z.array(z.coerce.number()),
  iteration: z.string().optional(),
  project: z.string().optional(),
  priority: z.number().optional(),
  area: z.string().optional(),
  fixedTestplanMapping: z.object({}).optional(),
});

export const DevopsScriptExportParamsSchema = z.object({
  itsId: z.number(),
  scriptId: z.number(),
  iteration: z.string().optional(),
  project: z.string().optional(),
  priority: z.number().optional(),
  area: z.string().optional(),
});

const IssueTrackingRuleSchema = z.object({
  name: z.string(),
  itsId: z.number(),
  triggers: z.array(z.nativeEnum(IssueTrackingToolTrigger)),
  config: z
    .object({
      severity: z.nativeEnum(WorkItemSeverity),
      priority: z.coerce.number(),
      iteration: z.string(),
      customFields: z.array(IssueTrackingToolCustomFieldSchema),
      type: z.nativeEnum(AllowedWorkItemTypes),
      project: z.string(),
      area: z.string(),
    })
    .partial(),
  itsName: z.string().default(""),
  ...CommonProperties,
});

export const IssueTrackingRuleSchemas = {
  general: IssueTrackingRuleSchema,
  createData: ZodUtils.makeDefaultPropsOptional(IssueTrackingRuleSchema).omit(
    GENERATED_BY_DB_PROPERTIES,
  ),
  create: ZodUtils.makeOptionalPropsNullable(IssueTrackingRuleSchema).omit(
    GENERATED_BY_DB_PROPERTIES,
  ),
  // .partial({ itsName: true }),
  update: ZodUtils.makeOptionalPropsNullable(IssueTrackingRuleSchema).partial(),
};

export const IssueTrackingConfigurationSchema = z.object({
  ...CommonProperties,
  name: z.string(),
  description: z.string().optional(),
  itsId: z.number(),
  state: z.nativeEnum(IssueTrackingToolConfigurationState),
  integrationMetadata: z.object({
    azureDevops: z.object({ id: z.number().optional(), project: z.string().optional() }),
  }),
});

export const IssueTrackingConfigurationSchemas = {
  general: IssueTrackingConfigurationSchema,
  createData: ZodUtils.makeDefaultPropsOptional(IssueTrackingConfigurationSchema).omit(
    GENERATED_BY_DB_PROPERTIES,
  ),
  create: IssueTrackingConfigurationSchema.omit(GENERATED_BY_DB_PROPERTIES),
  update: IssueTrackingConfigurationSchema.omit(GENERATED_BY_DB_PROPERTIES),
};

export const TaskScriptSchema = z.object({
  ...CommonProperties,
  name: z.string(),
  startUrl: z.string().optional(),
  version: z.string().optional(),
  creatorId: z.number().optional(),
  description: z.string().default(""),
  hideComments: z.boolean().default(false),
  showActionHint: z.boolean().default(false),
  isManualUrl: z.boolean().default(false),
  status: z.nativeEnum(ScriptStatus).default("DRAFT"),
  environmentId: z.number().optional(),
  takeScreenshots: z.boolean().default(false),
  documents: z.number().default(0),
  publishedDocuments: z.number().default(0),
  skipLinkedScriptsInDocumentation: z
    .boolean()
    .nullable()
    .transform((v) => v!!)
    .default(false),
  disableStartStep: z
    .boolean()
    .nullable()
    .transform((v) => v!!)
    .default(false),
  projectId: z.number().optional(),
  timeout: z.coerce.number().optional(),
  clickOnDisabledButtons: z.boolean().default(false),
  integrationMetadata: z.any(), // ScriptIntegrationMetadataSchema.optional(), // TODO: type for integration metadata
  reportTemplate: z.number().optional(),
  documentationTemplate: z.number().optional(),
  virtualUserId: z.number().optional(),
  groupid: z.number().optional(),
  autoRefreshDatasource: z.boolean().default(false),
  throttling: ThrottlingSchema.optional(), // TODO: type for throttling data
  useVirtualUser: z.boolean().default(false),
  screenshotsOnlyOnInterruption: z.boolean().default(false),
  resolvers: z
    .object({
      enabled: z.boolean().optional(),
      mode: z.nativeEnum(GlobalSettingsResolversModes).optional(),
    })
    .optional(),
  environment: SystemSchemas["general"]
    .pick({ envName: true, envAddress: true, envType: true })
    .optional(),
  tags: z.array(TagSchema).optional(),
  globalVariables: z.array(GlobalVariableSchema).optional(),
  stepCount: z.number().optional(),
  lastExecutionStatus: z.nativeEnum(EXECUTION_STATUS).optional(),
  lastExecutorName: z.string().optional(),
  lastExecutionDate: DateZodType.optional(),
  appUser: UserSchemas["general"].pick({ id: true, username: true }).partial().optional(),
  datasourceId: z.number().optional(),
  datasource: DataSourceMetadataSchema.optional(),
});

const ScriptCreateSchema = ZodUtils.makeOptionalPropsNullable(TaskScriptSchema).omit(
  GENERATED_BY_DB_PROPERTIES,
);

export const ScriptSchemas = {
  general: TaskScriptSchema,
  createData: ZodUtils.makeDefaultPropsOptional(
    ZodUtils.makeOptionalPropsNullable(
      TaskScriptSchema.omit(GENERATED_BY_DB_PROPERTIES).merge(
        z.object({ tags: z.array(z.number()) }),
      ),
    ).partial(),
  ),
  create: ScriptCreateSchema,
  update: ZodUtils.makeOptionalPropsNullable(
    TaskScriptSchema.omit(GENERATED_BY_DB_PROPERTIES).merge(
      z.object({ globalVariables: z.array(z.number()), tags: z.array(z.number()) }),
    ),
  ).partial(),
};

export const WorkItemDefinitionSchema = z.object({
  title: z.string(),
  priority: z.coerce.number(),
  type: z.nativeEnum(AllowedWorkItemTypes),
  stepLabel: z.string(),
  itsId: z.number(),
  id: z.string().optional(),
  severity: z.nativeEnum(WorkItemSeverity).optional(),
  reproSteps: z.string().optional(),
  stepLogId: z.number().optional().nullable(),
  iteration: z.string().optional(),
  area: z.string().nullable().optional(),
  screenshotPath: z.string().optional(),
  description: z.string().optional(),
});

export const TestCaseDefinitionSchema = WorkItemDefinitionSchema.omit({ stepLabel: true }).merge(
  z.object({
    testCaseSteps: z.string(),
  }),
);

export const BaseAzureIntegrationMetadata = z.object({
  testPlanId: z.union([z.string(), z.number(), z.null()]).transform((id) => (id || "").toString()), // todo: can be string or number or null
  testSuiteId: z.string(),
  itsId: z.number(),
  project: z.string(),
});

export const ProjectIntegrationMetadataSchema = z.object({
  azureDevops: z.any(),
  // BaseAzureIntegrationMetadata.extend({  todo: FIX MANY PROBLEMS
  //   testSuiteDefineUrl: z.string().optional(),
  //   testSuiteExecuteUrl: z.string().optional(),
  // }).optional(),
});

export const ProjectSchema = z.object({
  ...CommonProperties,

  path: z.string().default(PROJECT_DEFAULT_PATH),
  name: z.string().default("unknown name"), // todo: what to dooooo with types

  overrideGroups: z.boolean().default(false),
  overrideVirtualUsers: z.boolean().default(false),
  overrideSystems: z.boolean().default(false),
  overrideItsRules: z.boolean().default(false),

  description: z.string().default(""),

  category: z.number().optional(),
  dueDate: DateZodType.optional(),
  startDate: DateZodType.optional(),
  version: z.string().default(""),

  defaultSystemId: z.number().optional(),
  defaultVirtualUserId: z.number().optional(),

  runMode: z.string().default("SEQUENTIAL"),
  lastSchedulerId: z.number().optional(),
  statusesToRun: z.array(z.nativeEnum(ScriptStatus)).default([]),
  tagsToRun: z.array(z.number()).default([]),

  maximumParallelSessions: z.number().optional(),

  integrationMetadata: ProjectIntegrationMetadataSchema.optional(),
  executionParams: BackgroundStartParamsScheme.extend({
    mapResultWithDevops: z.boolean().default(false),
    azureConfigurationId: z.number().nullable().optional(),
  })
    .partial()
    .nullable()
    .optional(), // todo: figure our what options can be available
  documentationTemplate: z.number().optional(),
  reportTemplate: z.number().optional(),

  defaultVirtualUser: VirtualUserSchemas.general.optional(),
  defaultSystem: SystemSchema.optional(),

  // todo: attach normal types later
  owners: z.array(UserSchemaWithoutPass).default([]).optional(),
  groups: z.array(UserGroupSchema).default([]).optional(),
  virtualUsers: z.array(VirtualUserSchemas.general).default([]).optional(),
  systems: z.array(SystemSchema).default([]).optional(),
  itsRules: z.array(IssueTrackingRuleSchema).default([]).optional(),
});

const ProjectEdit = {
  owners: z.array(z.number()).default([]).optional(),
  groups: z.array(z.number()).default([]).optional(),
  virtualUsers: z.array(z.number()).default([]).optional(),
  systems: z.array(z.number()).default([]).optional(),
  itsRules: z.array(z.number()).default([]).optional(),
};

const ProjectCreateSchema = ZodUtils.makeOptionalPropsNullable(ProjectSchema.extend(ProjectEdit))
  .omit(GENERATED_BY_DB_PROPERTIES)
  .partial({
    path: true,
  });

export const ProjectSchemas = {
  editRelations: z.object(ProjectEdit),
  general: ProjectSchema,
  createData: ZodUtils.makeDefaultPropsOptional(ProjectCreateSchema),
  create: ProjectCreateSchema,
  update: ZodUtils.makeOptionalPropsNullable(ProjectSchema.extend(ProjectEdit)).partial(),
};

export const DateRangeSchema = z.object({
  from: DateZodType,
  to: DateZodType,
});

const SchedulerJobMappingSchema = z.object({
  ...{ id: CommonProperties.id, modifiedBy: CommonProperties.modifiedBy },
  name: z.string(),
  projectId: z.number().optional(),
  repeatCount: z.number(),
  lineNum: z.number(),
  taskScriptId: z.number(),
  schedulerJobId: z.number(),
  overrideRunParams: z.boolean().default(false),
  script: TaskScriptSchema.optional(),
  projectDefinition: ProjectSchema.optional(),
  runParams: z.any(),
});

const SchedulerJobMappingSchemasBase = ZodUtils.generateEntitySchemas(SchedulerJobMappingSchema);
export const SchedulerJobMappingSchemas = {
  ...SchedulerJobMappingSchemasBase,
  diff: ZodUtils.makeDefaultPropsOptional(
    ZodUtils.makeOptionalPropsNullable(SchedulerJobMappingSchemasBase.general),
  ).partial(GENERATED_BY_DB_PROPERTIES),
};

const SchedulerScriptSchema = z.object({
  ...CommonProperties,
  name: z.string(),
  lineNum: z.number(),
  description: z.string().default(""),
  scriptId: z.number(),
  runParams: z.any(),
});

export const SchedulerScriptSchemas = ZodUtils.generateEntitySchemas(SchedulerScriptSchema);

const SchedulerGroupSchema = z.object({
  ...CommonProperties,
  schedulerJobId: z.number(),
  lineNum: z.number(),
  description: z.string().default(""),
  runParams: z.any(),
  name: z.string().default(""),
  scripts: z.array(SchedulerScriptSchema),
  overrideRunParams: z.any(),
});

export const SchedulerGroupSchemas = ZodUtils.generateEntitySchemas(SchedulerGroupSchema);

const SchedulerSchema = z.object({
  ...CommonProperties,
  name: z.string(),
  mode: z.nativeEnum(SchedulerMode).default(SchedulerMode.BASIC),
  runMode: z.nativeEnum(RepeaterTypes).default(RepeaterTypes.SEQUENTIAL),
  description: z.string().default(""),
  status: z.nativeEnum(JOB_STATUS_TYPE).default(JOB_STATUS_TYPE.INACTIVE),
  startDateTime: DateZodType.optional(),
  endDateTime: DateZodType.optional(),
  offset: z.number().optional(),
  occurrences: z.number().optional(),
  recurrenceType: z.nativeEnum(RecurrenceType).optional(),
  userId: z.number().optional(),
  triggerType: z.nativeEnum(JOB_TRIGGER_TYPE).optional(),
  storageId: z.number().optional(),
  storageTriggerContainer: z.string().optional(),
  notificationEmails: z.preprocess((value) => (value ? value : ""), z.string().default("")),
  mapResultWithDevops: z.preprocess((value) => value ?? false, z.boolean().default(false)),
  isInternal: z.boolean().default(false),
  activatedBy: z.number().optional(),
  maximumParallelSessions: z.number().optional(),
  schedulerJobMapping: z.array(SchedulerJobMappingSchema.partial()).default([]).optional(),
  groups: z.array(SchedulerGroupSchema).default([]),
  integrationMetadata: z.preprocess(
    (value) => (value ? value : {}),
    z
      .object({ azureDevops: z.object({ configurations: z.array(z.coerce.number()) }).optional() })
      .optional(),
  ),
  runParams: z.object({}).catchall(z.any()).optional(), // TODO: type forrunParams
  // resolvers: z.object({}).catchall(z.any()), // TODO: type for resolvers
});

export const SchedulerSchemas = ZodUtils.generateEntitySchemas(SchedulerSchema);
const ReportTemplateSchema = z.object({
  ...CommonProperties,
  name: z.string(),
  filename: z.string(),
  originalFilename: z.string(),
  description: z.string().default(""),
});

export const ReportTemplateSchemas = ZodUtils.generateEntitySchemas(ReportTemplateSchema);

export const DefinedIssueTrackingSchema = z.object({
  itsId: z.coerce.number(),
  project: z.string().optional(),
});

export const NewIssueTrackingSchema = z.object({
  token: z.string(),
  host: z.string(),
  project: z.string().optional(),
});

export const AzureDevopsConnectionDataSchema = z.union([
  DefinedIssueTrackingSchema,
  NewIssueTrackingSchema,
]);

export const ScriptChangeLogSchema = z.object({
  ...CommonProperties,
  scriptId: z.number(),
  oldValue: z.nativeEnum(ScriptStatus).optional(),
  newValue: z.nativeEnum(ScriptStatus),
  user: z.string(),
  comment: z.string().default("").optional(),
  tags: z.array(z.string()).optional(),
  version: z.string().optional(),
  snapshot: z.any(),
});

export const VirtualUserPoolSchema = z.object({
  ...CommonProperties,
  name: z.string(),
  description: z.string().default(""),
  virtualUsers: z.array(VirtualUserSchemas["general"]).optional(),
});

const VirtualUserPoolEdit = VirtualUserPoolSchema.extend({
  virtualUsers: z.array(z.number()),
});

const VirtualUserPoolCreateSchema = ZodUtils.makeOptionalPropsNullable(VirtualUserPoolEdit).omit(
  GENERATED_BY_DB_PROPERTIES,
);

export const VirtualUserPoolSchemas = {
  general: VirtualUserPoolSchema,
  createData: ZodUtils.makeDefaultPropsOptional(VirtualUserPoolCreateSchema),
  create: VirtualUserPoolCreateSchema,
  update: ZodUtils.makeOptionalPropsNullable(VirtualUserPoolEdit)
    .omit(GENERATED_BY_DB_PROPERTIES)
    .partial(),
};

const WebsocketDataCommon = {
  wsId: z.string().optional(),
};

export const RemovingScreenshotsStatsSchema = z.object({
  isProcessing: z.boolean(),
  days: z.number(),
  stats: z.object({
    total: z.number(),
    done: z.number(),
  }),
});

export const SubscribeUpdateQuerySchema = z.object({
  taskScriptId: z.coerce.number().optional(),
  parentId: z.coerce.number().optional(),
});

export const WebsocketDataSchemas = {
  [WebsocketMessageTypes.OPEN_CONNECTION]: z.object({
    userId: z.coerce.number(),
    wsId: z.string(),
  }),

  [WebsocketMessageTypes.NOTIFICATION]: z.object({
    status: z.string().optional(),
    scriptName: z.string().optional(),
    message: z.string().optional(),
    ...WebsocketDataCommon,
  }),

  [WebsocketMessageTypes.REMOVE_CLIENT_COOKIES]: z.object({
    sessionId: z.string(),
    ...WebsocketDataCommon,
  }),

  [WebsocketMessageTypes.SCHEDULER]: z.object({
    ...WebsocketDataCommon,
    projectIds: z.array(z.number()),
    schedulerJobId: z.coerce.number(),
    schedulerJobName: z.string(),
    status: z.nativeEnum(EXECUTION_STATUS),
    jobId: z.string(),
    type: z.enum(["END", "START"]),
  }),

  [WebsocketMessageTypes.SUBSCRIBE_TO_UPDATES]: z.object({
    queryId: z.string(),
    wsId: z.string(),
    modelName: z.nativeEnum(SubscribeUpdateModelNames),
    query: SubscribeUpdateQuerySchema,
  }),

  [WebsocketMessageTypes.UNSUBSCRIBE_TO_UPDATES]: z.object({
    queryId: z.string(),
    ...WebsocketDataCommon,
  }),

  [WebsocketMessageTypes.PERFORM_REFRESH]: z.object({
    ...WebsocketDataCommon,
    queryId: z.string(),
  }),

  [WebsocketMessageTypes.HEARTBEAT]: z.object({ ...WebsocketDataCommon }),
  [WebsocketMessageTypes.EXPORT_GENERATION_START]: z.object({
    ...WebsocketDataCommon,
    jobId: z.string(),
  }),
  [WebsocketMessageTypes.EXPORT_GENERATION_PROGRESS]: z.object({
    ...WebsocketDataCommon,
    jobId: z.string(),
    progress: z.number(),
  }),
  [WebsocketMessageTypes.EXPORT_GENERATION_FAIL]: z.object({
    ...WebsocketDataCommon,
    jobId: z.string(),
    reason: z.string(),
  }),
  [WebsocketMessageTypes.EXPORT_GENERATION_SUCCESS]: z.object({
    ...WebsocketDataCommon,
    jobId: z.string(),
    fileName: z.string(),
  }),

  [WebsocketMessageTypes.REPORT_GENERATION_SUCCESS]: z.object({
    ...WebsocketDataCommon,
    tableId: z.string(),
    reportName: z.string(),
    originalReportName: z.string(),
  }),

  [WebsocketMessageTypes.GLOBAL_ACTIONS_SCREENSHOTS_CLEANUP_UPDATE]: z.object({
    ...WebsocketDataCommon,
    stats: RemovingScreenshotsStatsSchema.extend({
      hasBeenCompleted: z.boolean().optional(),
      error: z.string().optional(),
    }),
  }),
  [WebsocketMessageTypes.REPORT_GENERATION_FAIL]: z.object({
    ...WebsocketDataCommon,
    tableId: z.string(),
  }),
};

const DocumentationSchema = z.object({
  ...CommonProperties,
  name: z.string(),
  prettyName: z.string(),
  scriptId: z.number(),
  sessionId: z.string().optional(),
  description: z.string(),
  path: z.string(),
  version: z.string(),
  locale: z.string(),
  signedBy: z.string().optional(),
  type: z.nativeEnum(DOCUMENTATION_TYPE),
  status: z.nativeEnum(DocumentationStatus),
  script: TaskScriptSchema.optional(),
});

export const DocumentationSchemas = ZodUtils.generateEntitySchemas(DocumentationSchema);

export const TestPlansQuerySchema = z.object({
  itsId: z.coerce.number(),
  project: z.string().optional(),
});
export const TestCasesQuerySchema = z
  .object({
    testPlanId: z.coerce.number(),
    testSuiteId: z.coerce.number(),
  })
  .merge(TestPlansQuerySchema);

export const TestSuiteSiblingsQuerySchema = z
  .object({
    testPlanId: z.coerce.number(),
    currentTestSuiteId: z.coerce.number(),
  })
  .merge(TestPlansQuerySchema);

export type UpdateProjectDevopsOptions = {
  shouldTriggerDevopsMappingUpdate: boolean;
  updateTestPlanOrSuite: boolean;
  remapTestPlan: boolean;
  remapTestSuite: boolean;
  isRootProject: boolean;
};
