import { objectValues } from "@ea/shared_components/utils/object";

import * as documentationActions from "./documentation.actions";
import documentationEpicsMap from "./documentation.epics";
import * as documentationReducers from "./documentation.reducers";

const documentationEpics = objectValues(documentationEpicsMap);

export { documentationActions, documentationReducers, documentationEpics };

export { default as DocumentationScriptsTableContainer } from "./DocumentationScriptsTable.container";
