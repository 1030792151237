import * as React from "react";
import { WarningFilled, ExclamationCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";
import { MESSAGE_TYPE } from "@ea/shared_types/types";

type IconProps = React.ComponentProps<typeof ExclamationCircleOutlined>;

const STEP_MESSAGE_ICON_MAP = {
  [MESSAGE_TYPE.WARNING]: (props: IconProps) => (
    <WarningFilled {...props} key={props.key === null ? undefined : props.key} />
  ),
  [MESSAGE_TYPE.ERROR]: (props: IconProps) => (
    <ExclamationCircleOutlined {...props} key={props.key === null ? undefined : props.key} />
  ),
  [MESSAGE_TYPE.INFO]: (props: IconProps) => (
    <InfoCircleOutlined {...props} key={props.key === null ? undefined : props.key} />
  ),
};

type IStepMessageIconProps = IconProps & {
  messageType: MESSAGE_TYPE;
};

const StepMessageIcon: React.FunctionComponent<IStepMessageIconProps> = ({
  messageType,
  ...rest
}) => {
  return STEP_MESSAGE_ICON_MAP[messageType](rest);
};

export default StepMessageIcon;
