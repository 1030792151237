import * as React from "react";
import { Item } from "@ea/shared_types/types";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { ColumnConfig } from "./common.tables";
import { ColumnProps } from "antd/lib/table";
import Table from "./elements/Table";
import { SelectMode, pageSizes, DEFAULT_PAGE_SIZE } from "../redux/common.models";

interface IClientTableProps<T extends Item> {
  columnsConfig: ColumnConfig<T>[];
  data: T[];
  className?: string;
  multiselect?: boolean;
  selectable?: boolean;
  pageable?: boolean;
  onSelect?: (selected: number[]) => void;
}
interface IClientTableState<T> {
  columns: ColumnProps<T>[];
  selected: number[];
  pagination:
    | false
    | {
        pageSizes: string[];
        pageSize: number;
        total: number;
        current: number;
        onPageChange: (page: number) => void;
        onPageSizeChange: (pageSize: number) => void;
        showSizeChanger: boolean;
      };
}

class ClientTable<T extends Item> extends React.Component<
  IClientTableProps<T> & InjectedIntlProps,
  IClientTableState<T>
> {
  constructor(props: IClientTableProps<T> & InjectedIntlProps) {
    super(props);

    this.state = {
      columns: [],
      selected: [],
      pagination: {
        pageSizes: pageSizes.map((p) => p.toString()),
        pageSize: DEFAULT_PAGE_SIZE,
        total: props.data ? props.data.length : 0,
        current: 1,
        onPageSizeChange: (size) => this.onChangePageSize(size),
        onPageChange: (page) => this.onPageChange(page),
        showSizeChanger: true,
      },
    };
  }

  componentDidMount() {
    this.setState({
      columns: this.generateColumns(),
    });
  }

  componentDidUpdate(prevProps: IClientTableProps<T>, prevState: IClientTableState<T>) {
    if (prevProps.columnsConfig !== this.props.columnsConfig) {
      this.setState({
        columns: this.generateColumns(),
      });
    }
    if (prevProps.data !== this.props.data) {
      this.setState({
        selected: this.state.selected.filter((id) =>
          this.props.data.find((item) => item.id === id),
        ),
      });
    }
  }

  onChangePageSize = (size) => {
    const pagination = Object.assign({}, this.state.pagination, { pageSize: size });

    this.setState({
      ...this.state,
      pagination,
    });
  };

  onPageChange = (page) => {
    const pagination = Object.assign({}, this.state.pagination, { current: page });

    this.setState({
      ...this.state,
      pagination,
    });
  };

  generateColumns = () => {
    const { columnsConfig } = this.props;

    return columnsConfig.map((columnConfig) => this.generateColumnFromConfig(columnConfig));
  };

  generateColumnFromConfig = (columnConfig: ColumnConfig<T>) => {
    const { props, frameworkProps } = columnConfig;
    let createdColumn: ColumnProps<T> = {
      dataIndex: props.dataIndex,
      key: props.dataIndex,
      title: this.props.intl.formatMessage({ id: props.label }) || props.label,
      render: props.render,
      width: props.width,
    };

    return {
      ...createdColumn,
      ...frameworkProps,
    };
  };

  onSelect({ mode, ids }) {
    const { selected } = this.state;
    const { onSelect } = this.props;
    if (mode === SelectMode.Replace) {
      this.setState({
        selected: ids,
      });
      if (onSelect) {
        onSelect(ids);
      }
      return;
    }
    const existed: (number | string)[] = [];
    const filtered = selected.filter((id) => {
      const result = ids.indexOf(id) === -1;
      if (!result) {
        existed.push(id);
      }
      return result;
    });
    const filteredIds = ids.filter((id) => existed.indexOf(id) === -1);
    const newSelected = filteredIds.concat(filtered);
    this.setState({
      selected: newSelected,
    });

    if (onSelect) {
      onSelect(newSelected);
    }
  }

  render() {
    const { columns, selected, pagination } = this.state;
    const { multiselect, selectable, data, pageable, className } = this.props;

    return (
      <Table
        selected={selected}
        onSelect={(selectedParams) => this.onSelect(selectedParams)}
        dataSource={data}
        columns={columns}
        selectable={selectable}
        multiselect={multiselect !== undefined ? multiselect : true}
        pagination={(pageable && pagination) || undefined}
        className={className}
      />
    );
  }
}

export default injectIntl(ClientTable) as any;
