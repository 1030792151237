import { z } from "zod";
import { TestRunMap } from "../types";
import {
  AssignedVariableType,
  EXECUTION_SCRIPT_TYPES,
  EXECUTION_STATE,
  EXECUTION_STATUS,
  GLOBAL_SETTINGS_KEYS,
  GlobalSettingsResolversModes,
  MESSAGE_TYPE,
  RunnerMode,
  SaveVideoCondition,
} from "./ea.enums";
import {
  CodeTemplateDefinition,
  ExecutionLog,
  ExecutionLogTypes,
  ExecutionStepLog,
  ExecutionStepLogTypes,
  GlobalSettingsValues,
  GlobalVariable,
  ScriptIntegrationMetadata,
  Step,
  StepCommandBase,
  System,
  TaskScript,
  Variable,
  VirtualUser,
} from "./ea.types";
import { Extend, Require } from "./ea.utils.types";

export enum RECORDER_EXECUTION_STATE {
  NONE = "NONE",
  RECORDING = "RECORDING",
  PAUSED = "PAUSED",
  FINISHED = "FINISHED",
}

export interface IFrame {
  className?: string;
  id?: string;
  name?: string;
  parentId?: string;
  parentTagName?: string;
  parentAttributes?: {
    name: string;
    value: string;
  }[];
  iframeIndex?: number;
  ariaLabel?: string | null;
}

export type ExecutionStepMetadata = {
  numberOfExecutions: number;
  status: EXECUTION_STATUS;
  path: string;
  steps: ExecutionStep[];
  forceStatus?: EXECUTION_STATUS;
  usedVariables: UsedVariable[];
};

export type Message = {
  text: string;
  type: MESSAGE_TYPE;
  // todo R2.0 - give me better name, errorType should contains error / warning type like NoElementError,
  errorType?: string;
  labelKey?: string;
  labelParams?: Record<string, string>;
  showToast?: boolean;
  isPlatformMessage?: boolean;
  overrideStatus?: EXECUTION_STATUS;
};

export type RunnerExecutionLogModelTypes = Extend<
  ExecutionLogTypes,
  {
    steps: RunnerExecutionStepLogModel[];
  }
>;
export type RunnerExecutionLogModel = ExecutionLog & {
  steps: RunnerExecutionStepLogModel[];
};

export type RunnerExecutionStepLogModelTypes = Extend<
  ExecutionStepLogTypes,
  {
    path: string;
    type?: EXECUTION_SCRIPT_TYPES.VIRTUAL_USER_AUTH;
  }
>;
export type RunnerExecutionStepLogModel = ExecutionStepLog & {
  path: string;
  type?: EXECUTION_SCRIPT_TYPES.VIRTUAL_USER_AUTH;
};

export type ExecutionStep<T extends StepCommandBase = any> = Step<T> & {
  execution: ExecutionStepMetadata;
  temporaryStepId?: number | string;
};

export type RecorderStep<T extends StepCommandBase = any> = Pick<
  Step<T>,
  | "structureVersion"
  | "paths"
  | "label"
  | "commandId"
  | "value"
  | "resolvers"
  | "platform"
  | "delay"
  | "triggersRefresh"
  | "isOptional"
  | "frames"
  | "files"
  | "rule"
  | "labelKey"
  | "labelParams"
  | "guards"
> & {
  messages: Message[];
  temporaryStepId?: string;
  modified?: boolean;
  synced?: boolean;
  lastModified?: Date;
};

export type FlatExecutionStep = {
  path: string;
  id: number;
};

export interface RecorderVariable {
  value?: any;
  name: string;
  temporaryStepId?: number;
}

export type PlayerVariable = {
  value: any;
  name: string;
  groupName?: string;
};

export type PlayerVariables = {
  script: Record<string, PlayerVariable>;
  global: {
    sequence: Record<string, PlayerVariable>;
    mutable: Record<string, PlayerVariable>;
    constant: Record<string, PlayerVariable>;
  };
  dataSource: Record<string, PlayerVariable>;
};

export type UsedVariable = {
  variableId: number;
  linkId?: number;
  variableType?: AssignedVariableType;
};

export type ScriptDataSourceConfig = {
  hasNextRow: boolean;
  totalRows: number;
  currentRow: number;
};

export type ScriptData = {
  id: number;
  name: string;
  variables: PlayerVariables;
  dataSource: Record<string, ScriptDataSourceConfig>;
  // todo: R2.0 we dont need it
  steps: ExecutionStep[];
  links: Record<string, ScriptData>;
  throttling?: Step["throttling"];
  system?: System;
  projectId?: number;
  version?: string;
  kpis?: Record<string, string>;
  codeTemplates?: Record<string, Record<string, CodeTemplateDefinition>>;
};

export type LinkScriptDataConfig = {
  disableStartStep?: boolean;
};

export type ExecutionCache = {
  sessionId: string;
  url?: string;
  mode: RunnerMode;
  // todo: R2.0 remove from scriptdata
  steps: ExecutionStep[];
  flatSteps: FlatExecutionStep[];
  // todo: R2.0 hmm variables are also in script data?
  variables: Variable[];
  globalMutables: GlobalVariable[];
  scriptId: number;

  recorder: {
    steps: RecorderStep[];
    variables: RecorderVariable[];

    // todo: R2.0 do we need this duplication?
    scriptId: number;

    hasBeenAuthenticated: boolean;
    hasBeenInitialized: boolean;
    hasBeenCacheByClient: boolean;

    recordingPath: string;

    state: RECORDER_EXECUTION_STATE;
  };

  player: {
    stickyMessages: Message[];
    globalMutableVariables: {
      initial: Record<string, PlayerVariable>;
      final: Record<string, PlayerVariable>;
    };
    externalScript?: any;
    hasBeenInitialized: boolean;
    hasBeenEverStarted: boolean;
    hasBeenAuthenticated: boolean;
    hasBeenClientInitialized: boolean;

    forceReset?: boolean;
    iteration: number;
    position: number;
    oldPosition: number;
    lockMovement: boolean;
    state: EXECUTION_STATE;
    authenticatedVU?: number;

    // todo: R2.0 maybe we should move it to the root?
    // needs to be refreshed after every sync?
    // we keep some dynamic data in scriptData, maybe we should move it to another container
    script: ScriptData;

    // not send to client;
    log: RunnerExecutionLogModel;

    test?: {
      runId: number;
      runResultId: number;
    };

    timeout?: {
      start: number;
      totalTime: number;
      timeout: number;
    };
  };
};

export type PlayerExecutionCache = Omit<ExecutionCache, "recorder">;
export type RecorderExecutionCache = Omit<ExecutionCache, "player">;

// todo: implement
export type ExternalScript = Partial<TaskScript> & Require<TaskScript, "name"> & { steps: any[] };
export type PlayerStep<T extends StepCommandBase = any> = ExecutionStep<T>;

export type AdvancedRecorderSyncResponse = {
  steps: Step[];
  flatSteps: FlatExecutionStep[];
  executionSteps: ExecutionStep[];
  scriptData: ScriptData;
};

export enum CodeExecutionLogType {
  CALCULATE = "CALCULATE",
  ASSIGN = "ASSIGN",
}

type BaseCodeExecutionLog = {
  input: string;
  executedCode: string;
};

export type CalculateCodeExecutionLog = BaseCodeExecutionLog & {
  type: CodeExecutionLogType.CALCULATE;
  output: string;
};

export type ChangedVariables = Record<
  string,
  {
    name: string;
    value: string;
    type?: AssignedVariableType;
  }
>;

export type AssignCodeExecutionLog = BaseCodeExecutionLog & {
  type: CodeExecutionLogType.ASSIGN;
  changedVariables: ChangedVariables;
  output: Record<string, string>;
};

export type CodeExecutionLog = CalculateCodeExecutionLog | AssignCodeExecutionLog;

export const RunnerBaseSchema = z.object({ sessionId: z.string() });

export type ScriptStartOptions = {
  startUrl: string;
  takeScreenshots: boolean;
  screenshotsOnlyOnInterruption?: boolean;
  hideComments: boolean;
  showActionHint: boolean;
  timeout: number;
  locale?: string;
  clickOnDisabledButtons?: boolean;
  mapResultWithDevops?: boolean;
  envName?: string;
  autoRefreshDatasource?: boolean;
};

export enum RUNNER_TYPE {
  BASIC = "Basic",
  ADVANCED = "Advanced",
}

export enum RUNNER_OPEN_MODE {
  NEW_WINDOW = "New window",
  CURRENT_WINDOW = "Current window",
}

export enum RUNNER_POSITION {
  LEFT = "Left",
  RIGHT = "Right",
}

export type RunnerParams = {
  type: RUNNER_TYPE;
  openMode: RUNNER_OPEN_MODE;
  position: RUNNER_POSITION;
};

export enum RunnerVersion {
  BASIC = "Basic",
  ADVANCED = "Advanced",
}

export enum RunMode {
  SILENT = "SILENT",
  FULL = "FULL",
}

export type CommonStartParams = {
  autoStart: boolean;
  actionHint: boolean;
  hideComments: boolean;
  takeScreenshots: boolean;
  slowMode: boolean;
};

export type ForegroundStartParams = {
  stepByStep: boolean;
  reworkStepId: number;
  runMode: RunMode;
  runner: RunnerParams;
  isCtrlEvt?: boolean;
  newRunner?: boolean;
};

export enum ScriptSpecialExecutionModes {
  DOCUMENT = "DOCUMENT",
  VIDEO = "VIDEO",
  DOC_AND_VIDEO = "DOC_AND_VIDEO",
}
export enum PlayerVisibility {
  VISIBLE = "VISIBLE",
  HIDDEN = "HIDDEN",
  ONLY_CURRENT_STEP = "ONLY_CURRENT_STEP",
}

export type AdditionalJobParams = {
  noPageCache?: boolean;
  recordVideo?: boolean;
};

export interface UserRunnerSettings {
  autoStart: boolean;
  stepByStep: boolean;
  slowMode: boolean;
}

export type RequiredAdministrationStartParams = {
  script: number | Partial<TaskScript>;
};

export type RequiredForegroundStartParams = {
  mode: RunnerMode;
};

export type ScriptSpecialExecutionMode = ScriptSpecialExecutionModes;

export type BackgroundStartParams = {
  specialMode?: ScriptSpecialExecutionMode;
  useSpecialMode?: boolean;
  playerVisibilityMode?: PlayerVisibility;
  saveVideoCondition?: SaveVideoCondition;
  slowExecution?: number;
  jobId: string;
  documentationLocale?: number;
  schedulerJobId: number;
  notify: boolean;
  datasourceId?: number;
  additionalStartParams: AdditionalJobParams;
  dispatchStartTime?: Date;
  azureConfigurationId?: number;
  token?: string;
};

type TestPlansRunnerParams = {
  testRunMap: TestRunMap;
};

type InternalRunnerParam =
  | {
      internal: false;
      calledBy?: {
        id?: number;
        username?: string;
      };
      authenticatedVU?: number;
      virtualUserId?: number;
    }
  | {
      internal: EXECUTION_SCRIPT_TYPES.VIRTUAL_USER_AUTH;
      authenticatedVU?: number;
      calledBy?: {
        id?: number;
        username?: string;
      };
      virtualUserId: number;
    };

export type CustomSchedulerParams = Partial<{
  virtualUserPoolId: number;
  virtualUserId?: number;
  environmentId?: number;
  virtualUserMode?: "POOL" | "SINGLE";
}>;

type SchedulerRunnerParams = {
  jobId?: string;
  jobExecutionId?: string;
  scriptFlowId?: string;
  schedulerJobName?: string;
  schedulerOverride?: CustomSchedulerParams;
};

export type StartForegroundSessionParams = {
  isBackground: false;
} & RequiredAdministrationStartParams &
  RequiredForegroundStartParams &
  Partial<ForegroundStartParams> &
  Partial<ScriptStartOptions> &
  Partial<UserRunnerSettings>;

export type StartBackgroundSessionParams = {
  isBackground: true;
  mode: RunnerMode.PLAYER;
} & RequiredAdministrationStartParams &
  Partial<BackgroundStartParams> &
  Partial<ScriptStartOptions> &
  Partial<UserRunnerSettings>;

export type StartScriptParams = { script: number | ExternalScript } & {
  mode?: RunnerMode;
  variables: { name: string; value: any }[];
};

export type StartExternalSessionParams = StartScriptParams &
  Partial<ScriptStartOptions> &
  Partial<UserRunnerSettings>;

export type StartParams = StartExternalSessionParams & {
  isBackground: boolean;
  mode: RunnerMode;
} & Partial<ScriptStartOptions> &
  Partial<BackgroundStartParams> &
  Partial<ForegroundStartParams> &
  Partial<TestPlansRunnerParams> &
  Partial<InternalRunnerParam> &
  SchedulerRunnerParams;

export type RunnerClientSessionData = Partial<ScriptStartOptions> &
  Partial<UserRunnerSettings> & {
    newRunner?: boolean;
    sessionId: string;
    runMode: RunMode;
    resolversMode: GlobalSettingsResolversModes;
    positionResolverThreshold: number;
    generateResolversOnPlayback?: boolean;
    runnerVersion: RunnerVersion;
    token: string;
    internal: boolean;
    timeouts: GlobalSettingsValues[GLOBAL_SETTINGS_KEYS.TIMEOUTS];
    api: string;
    proxy?: string;
    virtualUser: VirtualUser | undefined;
    enableMultiWindowsSupport?: boolean;
    disableWebSecurity?: boolean;
    systemPlatform?: string;
    system?: System;
    translations?: any; // TODO types?
    maximumParallelSessions?: number;
    playerVisibilityMode?: PlayerVisibility;
    saveVideoCondition?: SaveVideoCondition;
    slowExecution?: number;
  } & { startUrl: string; isBackground: boolean; mode: RunnerMode };

export type ExecutionControllerParams = StartExternalSessionParams &
  Partial<
    {
      isBackground: boolean;
    } & ForegroundStartParams &
      BackgroundStartParams
  > & {
    sessionId: string;
    token: string;
    startUrl: string;
    url: string;
    integrationMetadata?: ScriptIntegrationMetadata;
    skipLinkedScriptsInDocumentation?: boolean;
  } & Partial<InternalRunnerParam> &
  Partial<TestPlansRunnerParams> &
  SchedulerRunnerParams;
