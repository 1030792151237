import { createDataSelectors } from "@app/modules/app.reducers";
import { VirtualUser } from "@ea/shared_types/types";
import { createSelector } from "reselect";

export const virtualUsersDataSelectors = createDataSelectors<VirtualUser>()("virtualUsers");

export const getVirtualUserFormOptions = createSelector(
  virtualUsersDataSelectors.getOrderedDataSelector,
  (virtualUsers) =>
    Object.keys(virtualUsers).map((key) => ({
      text: `[${virtualUsers[key].id}] ${virtualUsers[key].name}`,
      value: virtualUsers[key].id,
    })),
);
