import { Input } from "antd";

import createFormField from "../createFormField";
import withReadonly from "./withReadonly";
import { ReadonlyFormItem } from "../Form.common";

export const TextAreaField = createFormField<
  React.ComponentProps<typeof Input.TextArea> & ReadonlyFormItem
>(withReadonly(Input.TextArea), ({ name, onChange, value, onBlur, onFocus, ...rest }) => ({
  ...rest,
  name,
  onChange,
  value,
  onBlur: onBlur as any,
  onFocus: onFocus as any,
}));

export default TextAreaField;
