import { ApplicationState } from "@app/modules/app.reducers";
import {
  getGanttData,
  getSchedulerGroups,
  schedulerDataSelectors,
} from "@app/modules/scheduler/scheduler.selectors";
import { API } from "@app/services/api/api";
import {
  JOB_STATUS_TYPE,
  PlainObject,
  SchedulerGroup,
  SchedulerJobMapping,
  SchedulerMode,
  SchedulerRunTypes,
  SchedulerScript,
} from "@ea/shared_types/types";
import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { DeepOmit } from "ts-essentials";
import SchedulerAddScriptsPanel, {
  SchedulerAddScriptPanelModes,
  SchedulerAddScriptPanelModeType,
} from "./components/SchedulerFlowPanel/SchedulerAddScriptsPanel";
import { SchedulerSelectedScript } from "./components/SchedulerFlowPanel/SchedulerEditSelectedForm";
import {
  GANTT_ZOOM,
  GanttData,
  GanttTask,
  GanttTaskGroup,
  GanttTaskScript,
} from "./components/SchedulerGantt/gantt.types";
import GanttChart from "./components/SchedulerGantt/GanttChart";
import Toolbar from "./components/SchedulerGantt/Toolbar";
import CommandBar from "./components/SchedulerGanttCommandBar";
import { schedulerActions } from "./scheduler.actions";

import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";
import { DatePickerField } from "@ea/shared_components/Form/Fields/DatePickerField";
import BaseModalForm from "@ea/shared_components/ModalForm/BaseModalForm";
import moment from "moment";
import { bindActionCreators, Dispatch } from "redux";
import { getItsConfigurationsTableActions } from "../issueTrackingToolConfigurations/itsConfigurations.actions";
import { getItsConfigurationOptions } from "../issueTrackingToolConfigurations/itsConfigurations.selectors";
import { ITS_CONFIGURATIONS_TABLES_CONFIG } from "../issueTrackingToolConfigurations/itsConfigurations.table";
import { getSystemTableActions } from "../systemDictionary/systemDictionary.actions";
import { getSystemFormOptions } from "../systemDictionary/systemDictionary.selectors";
import { SYSTEM_DICTIONARY_TABLES_CONFIG } from "../systemDictionary/systemDictionary.table";
import { getVirtualUsersTableActions } from "../virtualUsers/virtualUsers.actions";
import { getVirtualUserFormOptions } from "../virtualUsers/virtualUsers.selectors";
import { VIRTUAL_USERS_TABLES_CONFIG } from "../virtualUsers/virtualUsers.table";
import { SchedulerGanttFormSection } from "./components/Form/SchedulerGanttFormSection";
import GanttTaskEditForm from "./components/GanttTaskEditForm";
import GroupManipulationPanel, {
  GroupActionTypes,
  GroupManipulationPanelModes,
  GroupManipulationPanelModeType,
  GroupManipulationPanelOnSave,
} from "./components/GroupManipulationPanel";
import { createGroupWithExistingSchedulerScripts } from "./components/groupManipulationUtils";
import {
  convertSingleGroupToScript,
  getGanttGroupId,
  getGanttTaskGroupId,
  isGroupGanttTask,
  takeGroupId,
  transformGanttDataToSchedulerGroups,
} from "./components/SchedulerGantt/gantt.helpers";

type SchedulerGanttProps = {
  schedulerJobId: number;
};

export enum GanttFunctions {
  CHANGE_GANTT_START = "changeGanttStart",
  CHANGE_SCRIPTS_START = "changeScriptsStart",
}

type SchedulerGanttState = {
  selectedTask?: GanttTask;
  selectedTasksIds: string[];
  tasksMap: PlainObject<GanttTask>;
  ganttData: GanttData;
  hasDataChanged: boolean;
  isEditing: boolean;
  openedPanel?: "addScripts";
  currentZoom: GANTT_ZOOM;
  selectedLinksIds: string[];

  addScriptPanelVisibility: boolean;
  addScriptPanelMode: SchedulerAddScriptPanelModeType;
  groupPanelVisibility: boolean;
  groupPanelMode: GroupManipulationPanelModeType;
  functionsModal?: GanttFunctions;
  isReloadingData: boolean;
};

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});
const GanttContainer = styled.div({
  display: "flex",
  flex: 1,
});
const ScalableContainer = styled.div(
  {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  ({ isTaskSelected }: { isTaskSelected: boolean }) =>
    isTaskSelected ? { width: "75%" } : { width: "100%" },
);

class SchedulerGanttContainer extends React.Component<IConnectProps, SchedulerGanttState> {
  ganttComponent: any;
  constructor(props: IConnectProps) {
    super(props);
    this.state = {
      selectedLinksIds: [],
      selectedTasksIds: [],
      tasksMap: this.getTasksMap(props.ganttData.data),
      ganttData: this.props.ganttData,
      selectedTask: undefined,
      hasDataChanged: false,
      isEditing: false,
      currentZoom: GANTT_ZOOM.MIN_1,
      addScriptPanelVisibility: false,
      addScriptPanelMode: SchedulerAddScriptPanelModes.ADD,
      groupPanelVisibility: false,
      groupPanelMode: GroupManipulationPanelModes.CREATE,
      functionsModal: undefined,
      isReloadingData: false,
    };
  }

  getTasksMap = (data: GanttTask[]) => {
    return data.reduce((container, next) => {
      container[next.id] = next;
      return container;
    }, {});
  };

  componentDidMount() {
    this.props.itsConfigurationsActions.load({});
    this.props.virtualUsersActions.load({});
    this.props.environmentsActions.load({});
  }

  componentDidUpdate(prevProps, prevState) {
    const { ganttData } = this.state;

    if (ganttData.data !== prevState.ganttData.data) {
      this.setState({
        tasksMap: this.getTasksMap(ganttData.data),
      });
    }
    if (this.props.ganttData.data !== prevProps.ganttData.data) {
      this.refreshData();
    }
  }

  refreshData = () => {
    this.ganttComponent.setIsRestarting(true);
    if (this.state.isEditing) {
      this.disableGanttEdit();
    }

    const data = this.ganttComponent.resetData(this.props.ganttData);
    this.setState(
      {
        ganttData: data,
        hasDataChanged: false,
        tasksMap: this.getTasksMap(data.data),
      },
      () =>
        // delay because of gantt autoscheduling
        setTimeout(() => {
          this.ganttComponent.setIsRestarting(false);
        }, 500),
    );
  };

  getSelected = () => {
    const { selectedTasksIds, tasksMap } = this.state;

    return selectedTasksIds.map((s) => tasksMap[s]).filter((t) => t);
  };

  getSelectedGroups = (): SchedulerGroup[] => {
    const ids = this.getSelected()
      .filter((t) => isGroupGanttTask(t) && t.scriptMode === "NORMAL")
      .map((t) => takeGroupId(t.id));
    return this.props.schedulerGroups.filter((sG) => ids.indexOf(sG.id) !== -1);
  };

  onGanttSaveClick = async () => {
    const { scheduler } = this.props;
    const { ganttData } = this.state;
    if (!ganttData || scheduler.mode !== SchedulerMode.GANTT) {
      return;
    }

    await API.upsertSchedulerGroups({
      groups: transformGanttDataToSchedulerGroups(ganttData) as any,
      schedulerId: this.props.schedulerJobId,
      replaceExisting: true,
    });
    this.props.loadSingle({ id: this.props.schedulerJobId });
    this.disableGanttEdit();
    // clear data in state
    this.setState({ hasDataChanged: false });
  };

  onDataChange = (ganttData, ganttLinks) => {
    this.setState({ hasDataChanged: true, ganttData: { data: ganttData, links: ganttLinks } });
  };

  renumerate = (schedulerJobMappingItems: SchedulerJobMapping[]) =>
    schedulerJobMappingItems.map((element, index) => ({
      ...element,
      lineNum: index + 1,
    }));

  addSchedulerJobId = (values: SchedulerJobMapping[]) => {
    return values.map((v) => ({ ...v, schedulerJobId: this.props.schedulerJobId }));
  };

  onGroupManipulationPanelSave: GroupManipulationPanelOnSave = async (actionType, params) => {
    const { tasksMap } = this.state;
    const newTaskMap = { ...tasksMap };
    if (actionType === GroupActionTypes.EXISTING) {
      const { existingGroupId, selectedTasks } = params;

      selectedTasks.forEach((task: GanttTask) => {
        const parentId = getGanttTaskGroupId(existingGroupId);
        const unlinkedTask = this.ganttComponent.unlinkElement(task);

        const transformTask = () => {
          if (!(task.kind === "GROUP" && task.scriptMode === "SINGLE")) {
            return unlinkedTask;
          }
          const t = convertSingleGroupToScript(unlinkedTask, parentId);

          this.ganttComponent.deleteTask(unlinkedTask.id);

          return t;
        };

        const taskToMove = transformTask();

        this.ganttComponent.updateTask(taskToMove);
        this.ganttComponent.moveToGroup(taskToMove, parentId);
      });

      this.setState({
        tasksMap: this.removeEmptyGroups(newTaskMap),
      });
    } else {
      const { newGroup, selectedTasks, existingGroups } = params;

      const { groupsToRemove, modifiedGroups } = createGroupWithExistingSchedulerScripts(
        newGroup,
        existingGroups,
        selectedTasks,
      );

      await API.upsertSchedulerGroups({
        groups: modifiedGroups as any,
        schedulerId: this.props.schedulerJobId,
      });
      await Promise.all(
        groupsToRemove.map(async (g) => await API.removeSchedulerGroup({ id: g.id })),
      );

      this.props.loadSingle({ id: this.props.schedulerJobId });
    }

    this.resetSelection();
    this.closePanel();
  };

  resetSelection = () => {
    this.setState({
      selectedTasksIds: [],
      selectedLinksIds: [],
    });
  };

  onPanelSave = async (
    schedulerScripts: SchedulerSelectedScript[],
    createAsGroup: boolean,
    group?: DeepOmit<
      SchedulerGroup,
      { id: never; lineNum: never; schedulerJobId: never; scripts: { id: never } }
    >,
  ) => {
    const { schedulerJobId } = this.props;

    const transformSelectedScripts = (
      scripts: SchedulerSelectedScript[],
    ): DeepOmit<SchedulerScript, { id: never }>[] => {
      return scripts.map((s, index) => ({
        name: s.scriptLabel || s.name,
        description: "",
        lineNum: index,
        runParams: {
          run: {
            type: SchedulerRunTypes.TIME,
            time: new Date(),
          },
          repeater: s.runParams?.repeater ? s.runParams.repeater : undefined,
        },
        scriptId: s.taskScriptId,
      }));
    };

    const packInGroups = (): DeepOmit<SchedulerGroup, { id: never; scripts: { id: never } }>[] => {
      if (createAsGroup && group) {
        return [
          {
            ...group,
            lineNum: 0,
            schedulerJobId,
            scripts: (group.scripts || []).concat(transformSelectedScripts(schedulerScripts)),
          },
        ];
      }

      return schedulerScripts.map((s, index) => ({
        name: s.scriptLabel || s.name,
        schedulerJobId,
        lineNum: index,
        runParams: {
          run: undefined,
          repeater: s.runParams?.repeater ? s.runParams.repeater : undefined,
        },
        overrideRunParams: false,
        scripts: transformSelectedScripts([s]),
      }));
    };

    const schedulerGroups = packInGroups();

    const createdGroups = await API.upsertSchedulerGroups({
      groups: schedulerGroups as any,
      schedulerId: schedulerJobId,
    });
    this.props.loadSingle({ id: schedulerJobId });

    this.resetSelection();
    this.closePanel();
  };

  closePanel = () => {
    this.setState({
      groupPanelVisibility: false,
      openedPanel: undefined,
      addScriptPanelVisibility: false,
    });
  };

  handleZoomChange = (zoom) => {
    this.setState({
      currentZoom: zoom,
    });
  };

  onTaskSelect = (taskId: string, isSelected: boolean) => {
    this.setState((state) => {
      const selectedTasksIds = isSelected
        ? state.selectedTasksIds.concat([taskId])
        : state.selectedTasksIds.filter((id) => id !== taskId);

      return {
        selectedTasksIds,
      };
    });
  };

  onTaskClick = (id: string | number, item: GanttTask, e: Event) => {
    // this.setState({ selectedTask: item });
  };

  onTaskDblClick = (id: string | number, e: Event) => {
    // blocking doubleclick
    return false;
  };

  onReorder = (data) => {
    const { tasksMap } = this.state;

    const newTaskMap = { ...tasksMap };

    this.setState({
      hasDataChanged: true,
      ganttData: data,
      tasksMap: this.removeEmptyGroups(newTaskMap),
    });
  };

  removeEmptyGroups = (tasksMap) => {
    const newTaskMap = { ...tasksMap };

    Object.keys(tasksMap).forEach((taskId) => {
      const task = tasksMap[taskId];
      if (task.kind === "GROUP" && task.scriptMode === "NORMAL") {
        let children = Object.keys(tasksMap).some(
          (tid) => (tasksMap[tid] as GanttTaskScript).parent === taskId,
        );
        if (!children) {
          this.ganttComponent.deleteTask(taskId);
          delete newTaskMap[taskId];
        }
      }
    });
    return newTaskMap;
  };

  onBeforeTaskAdd = (id: string | number, item: GanttTask) => {};

  onAfterTaskAdd = (id: string | number, item: GanttTask) => {};

  onBeforeTaskDelete = (id: string | number, item: GanttTask) => {};

  onAfterTaskDelete = (id: string | number, item: GanttTask) => {};
  onLinkDblClick = (id: string | number, e: Event) => {
    const { data, links } = this.state.ganttData;
    const newLinks = links.filter((l) => {
      return l.id?.toString() !== id.toString();
    });

    this.setState({ hasDataChanged: true, ganttData: { data: [...data], links: newLinks } });
  };

  onTaskSave = (task: GanttTask & { end_date: string }) => {
    const { data, links } = this.state.ganttData;
    const { id, start_date, end_date, ...rest } = task;
    const index = data.findIndex((t) => t.id === id);
    const newData = [...data];
    newData[index] = { ...data[index], start_date: new Date(start_date!) as any, ...rest };

    this.setState({
      hasDataChanged: true,
      ganttData: { data: newData, links: [...links] },
      selectedTask: { ...newData[index] },
    });
  };

  onRemove = async () => {
    const { selectedTasksIds, tasksMap } = this.state;
    const newTaskMap = { ...tasksMap };
    selectedTasksIds.forEach((id) => {
      this.ganttComponent.deleteTask(id);
      let task = tasksMap[id];
      if (task.kind === "GROUP") {
        let children = Object.keys(tasksMap).filter(
          (taskId) => (tasksMap[taskId] as GanttTaskScript).parent === id,
        );
        children.forEach((childTaskId) => {
          delete newTaskMap[childTaskId];
        });
      }
      delete newTaskMap[id];
    });

    this.setState({ tasksMap: this.removeEmptyGroups(newTaskMap) });
  };

  openAddScriptPanel = (mode: SchedulerAddScriptPanelModeType) => {
    this.setState({
      addScriptPanelVisibility: true,
      addScriptPanelMode: mode,
    });
  };

  openGroupPanel = (mode: GroupManipulationPanelModeType) => {
    this.setState({
      groupPanelVisibility: true,
      groupPanelMode: mode,
    });
  };

  onFunctionClick = (mode: GanttFunctions) => {
    this.setState({ functionsModal: mode });
  };

  onFunctionModalClose = () => {
    this.setState({ functionsModal: undefined });
  };

  getGanttFirstDate = () => {
    const startDates: Date[] = this.state.ganttData.data.map((d) => d.start_date as any);

    startDates.sort(function (a, b) {
      return new Date(a).getTime() - new Date(b).getTime();
    });
    const firstDate = startDates[0];
    return moment(firstDate);
  };
  onStartGanttDateChange = ({ start_date }) => {
    const firstDate = this.getGanttFirstDate();
    const diff = moment.duration(start_date.diff(firstDate));
    const newTasks: GanttTask[] = this.state.ganttData.data.map((t) => ({
      ...t,
      start_date: new Date(moment(t.start_date).add(diff).toISOString()),
    }));

    this.setState({
      ganttData: { data: newTasks, links: this.state.ganttData.links },
      functionsModal: undefined,
    });
  };

  onScriptsStartDateChange = ({ start_date }) => {
    const { selectedTasksIds } = this.state;

    const newTasks = this.state.ganttData.data.map((t) => {
      if (selectedTasksIds.indexOf(t.id) > -1) {
        return { ...t, start_date: new Date(start_date) };
      }
      return t;
    });

    this.setState({
      ganttData: { data: newTasks, links: this.state.ganttData.links },
      functionsModal: undefined,
    });
  };

  onEmptyClick = () => {
    this.setState({
      selectedLinksIds: [],
    });
  };

  onLinkRemoveClick = () => {
    this.state.selectedLinksIds.forEach((linkId) => this.ganttComponent.deleteLink(linkId));

    this.setState({
      selectedLinksIds: [],
    });
  };

  onLinkClick = (linkId) => {
    this.setState((state) => {
      const isAlreadyMarked = state.selectedLinksIds.indexOf(linkId) > -1;

      if (isAlreadyMarked) {
        return {
          selectedLinksIds: state.selectedLinksIds.filter((id) => id !== linkId),
        };
      }

      return {
        selectedLinksIds: state.selectedLinksIds.concat([linkId]),
      };
    });
  };

  enableGanttEdit = () => {
    this.setState({ isEditing: true });
  };

  disableGanttEdit = () => {
    this.setState({ isEditing: false });
  };

  onFormSave = (values: GanttTask) => {
    const { selectedTasksIds, ganttData, isEditing } = this.state;
    if (!isEditing) {
      return;
    }
    if (selectedTasksIds.length !== 1) {
      return;
    }

    const newGanttData: GanttData = {
      data: ganttData.data.map((t) => {
        if (t.id !== selectedTasksIds[0]) {
          return t;
        }

        const formatDate = (d) =>
          d?.toDate ? d.toDate() : typeof d === "string" ? new Date(d) : d;
        return {
          ...t,
          description: values.description || t.description,
          text: values.text || t.text,
          start_date: formatDate(values.start_date) || t.start_date,
          runParams: (values.runParams || t.runParams) as any,
          overrideRunParams:
            (values as GanttTaskGroup).overrideRunParams || (t as GanttTaskGroup).overrideRunParams,
        };
      }),
      links: ganttData.links,
    };

    this.setState({ ganttData: newGanttData, hasDataChanged: true });
  };

  render() {
    const {
      hasDataChanged,
      currentZoom,
      ganttData,
      groupPanelMode,
      groupPanelVisibility,
      addScriptPanelMode,
      addScriptPanelVisibility,
      functionsModal,
      selectedLinksIds,
      isEditing,
      tasksMap,
    } = this.state;
    const {
      schedulerJobId,
      schedulerGroups,
      schedulerJob,
      configurationOptions,
      environmentOptions,
      virtualUserOptions,
    } = this.props;
    const selectedTasks = this.getSelected();
    const selectedGroups = this.getSelectedGroups();

    const readOnly = schedulerJob.status === JOB_STATUS_TYPE.ACTIVE;
    const existingGroups = schedulerGroups.filter((sg) => !!tasksMap[getGanttGroupId(sg.id)]);
    return (
      <Container>
        <CommandBar
          onGanttSaveClick={this.onGanttSaveClick}
          hasDataChanged={hasDataChanged}
          selectedTasks={selectedTasks}
          onCancelClick={this.refreshData}
          onEditClick={this.enableGanttEdit}
          isEditing={isEditing}
          readOnly={readOnly}
          selectedLinks={selectedLinksIds}
          onLinksRemoveClick={this.onLinkRemoveClick}
          onRemoveClick={() => this.onRemove()}
          onAddClick={() => this.openAddScriptPanel(SchedulerAddScriptPanelModes.ADD)}
          onAddToGroupClick={() =>
            this.openAddScriptPanel(SchedulerAddScriptPanelModes.ADD_TO_EXISTING)
          }
          onCreateGroupClick={() => this.openGroupPanel(GroupManipulationPanelModes.CREATE)}
          onMoveToGroupClick={() => this.openGroupPanel(GroupManipulationPanelModes.MOVE)}
          onFunctionClick={this.onFunctionClick}
        />
        <GroupManipulationPanel
          close={this.closePanel}
          onSave={this.onGroupManipulationPanelSave}
          isOpen={groupPanelVisibility}
          mode={groupPanelMode}
          tasks={ganttData.data}
          selectedTasks={selectedTasks}
          existingGroups={existingGroups}
          schedulerJobId={schedulerJobId}
        />
        <SchedulerAddScriptsPanel
          mode={addScriptPanelMode}
          isOpen={addScriptPanelVisibility}
          close={this.closePanel}
          onPanelSave={this.onPanelSave}
          selectedGroup={selectedGroups.length === 1 ? selectedGroups[0] : undefined}
        />
        <BaseModalForm
          visible={functionsModal === GanttFunctions.CHANGE_GANTT_START}
          headerText={getTranslationKey("scheduler", "header", "setGanttStart")}
          onCancelClick={this.onFunctionModalClose}
          onOkClick={this.onStartGanttDateChange}
          allowPrisitineSubmit={true}
          cancelButtonText={getTranslationKey("button", "cancel")}
          okButtonText={getTranslationKey("button", "change")}
          initialValues={{ start_date: this.getGanttFirstDate() }}
          render={({ change, values }) => (
            <DatePickerField
              label={getTranslationKey("scheduler", "settings", "startDateTime")}
              placeholder={getTranslationKey("scheduler", "placeholder", "startDate")}
              name="start_date"
              showTime={{ format: "HH:mm:ss" }}
              timeFormat="YYYY-MM-DD HH:mm:ss"
              required
              data-testid={DataTestIds.FORM_DATEPICKER_START_DATE_TIME}
            />
          )}
        />
        <BaseModalForm
          visible={functionsModal === GanttFunctions.CHANGE_SCRIPTS_START}
          headerText={getTranslationKey("scheduler", "header", "setScriptsStart")}
          onCancelClick={this.onFunctionModalClose}
          onOkClick={this.onScriptsStartDateChange}
          allowPrisitineSubmit={true}
          cancelButtonText={getTranslationKey("button", "cancel")}
          okButtonText={getTranslationKey("button", "change")}
          render={({ change, values }) => (
            <DatePickerField
              label={getTranslationKey("scheduler", "settings", "startDateTime")}
              placeholder={getTranslationKey("scheduler", "placeholder", "startDate")}
              name="start_date"
              showTime={{ format: "HH:mm:ss" }}
              timeFormat="YYYY-MM-DD HH:mm:ss"
              required
              data-testid={DataTestIds.FORM_DATEPICKER_START_DATE_TIME}
            />
          )}
        />
        <GanttContainer>
          <ScalableContainer isTaskSelected={selectedTasks.length === 1}>
            <div className="zoom-bar">
              <Toolbar zoom={currentZoom} onZoomChange={this.handleZoomChange} />
            </div>
            <GanttChart
              onLoad={(data) => this.setState({ ganttData: data })}
              setRef={(component) => (this.ganttComponent = component)}
              tasks={ganttData}
              selectedLinks={selectedLinksIds}
              zoom={currentZoom}
              onEmptyClick={this.onEmptyClick}
              onLinkClick={this.onLinkClick}
              onDataChange={this.onDataChange}
              onTaskClick={this.onTaskClick}
              onTaskSelect={this.onTaskSelect}
              onBeforeTaskAdd={this.onBeforeTaskAdd}
              onAfterTaskAdd={this.onAfterTaskAdd}
              onBeforeTaskDelete={this.onBeforeTaskDelete}
              onAfterTaskDelete={this.onAfterTaskDelete}
              onTaskDblClick={this.onTaskDblClick}
              onLinkDblClick={this.onLinkDblClick}
              onReorder={this.onReorder}
              readOnly={readOnly || !isEditing}
            />
          </ScalableContainer>
          {selectedTasks.length === 1 && (
            <SchedulerGanttFormSection
              save={this.onFormSave}
              initialValues={selectedTasks[0]}
              render={(props) => (
                <GanttTaskEditForm
                  {...props}
                  schedulerJob={schedulerJob}
                  readOnly={readOnly || !isEditing}
                  configurationOptions={configurationOptions}
                  environmentOptions={environmentOptions}
                  virtualUserOptions={virtualUserOptions}
                />
              )}
            />
          )}
        </GanttContainer>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(schedulerActions, dispatch),
  itsConfigurationsActions: {
    load: bindActionCreators(
      getItsConfigurationsTableActions(ITS_CONFIGURATIONS_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ).load,
  },
  virtualUsersActions: {
    load: bindActionCreators(
      getSystemTableActions(SYSTEM_DICTIONARY_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ).load,
  },
  environmentsActions: {
    load: bindActionCreators(
      getVirtualUsersTableActions(VIRTUAL_USERS_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ).load,
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: RouteComponentProps<any> & SchedulerGanttProps) => ({
    ...props,
    schedulerJob: schedulerDataSelectors.getItemSelector(state, props.match.params.id),
    ganttData: getGanttData(state, props.match.params.id),
    schedulerGroups: getSchedulerGroups(state, props.match.params.id),
    scheduler: schedulerDataSelectors.getItemSelector(state, props.match.params.id),
    configurationOptions: getItsConfigurationOptions(
      state,
      ITS_CONFIGURATIONS_TABLES_CONFIG.MAIN.id(),
    ),
    environmentOptions: getSystemFormOptions(state, SYSTEM_DICTIONARY_TABLES_CONFIG.MAIN.id()),
    virtualUserOptions: getVirtualUserFormOptions(state, VIRTUAL_USERS_TABLES_CONFIG.MAIN.id()),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(SchedulerGanttContainer));
