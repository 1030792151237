import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { System } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("SYSTEM_DICTIONARY_MODULE");

export const moduleActions = createDataModuleActions<System>(actionCreator);
export const getSystemTableActions = createTableActionsGetter(
  normalizeActions(moduleActions.table),
);
export const systemActions = {
  ...normalizeActions(moduleActions.data),
};
export const systemActionCreators = {
  ...moduleActions,
};
