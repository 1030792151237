import { objectValues } from "@ea/shared_components/utils/object";

import * as tagActions from "./tag.actions";
import tagEpicsMap from "./tag.epics";
import * as tagReducers from "./tag.reducers";

const tagEpics = objectValues(tagEpicsMap);

export { tagActions, tagReducers, tagEpics };

export { default as TagTableContainer } from "./TagTable.container";
