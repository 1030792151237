import styled from "@emotion/styled";
import { Tabs, Tag } from "antd";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { Redirect, Route, RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators } from "redux";

import { ApplicationState } from "@app/modules/app.reducers";
import StatusTag from "@app/modules/common/components/StatusTag";
import BreadcrumbContainer from "@ea/shared_components/common/Breadcrumb.container";
import { BreadcrumbSection, MainSection, Page } from "@ea/shared_components/common/LayoutElements";

import ScriptDetailsContainer from "@app/modules/scripts/ScriptDetails.container";
import ScriptLinkedInContainer from "@app/modules/scripts/ScriptLinkedIn.container";

import GuardDetailsContainer from "@app/modules/guards/GuardDetails.container";
import GuardsTableContainer from "@app/modules/guards/GuardsTable.container";
import { SCRIPTS_TABLES_CONFIG } from "@app/modules/scripts/scripts.tables";
import { StepDetailsContainer, StepsTableContainer } from "@app/modules/steps";
import { stepsTableActions } from "@app/modules/steps/steps.actions";
import { STEPS_TABLE_ID } from "@app/modules/steps/steps.table";
import { getSystemTableActions } from "@app/modules/systemDictionary/systemDictionary.actions";
import { variableActions } from "@app/modules/variables/variables.actions";
import ScriptLogsPage from "@app/pages/ScriptLogs.page";
import { API } from "@app/services/api/api";
import { formatScriptName } from "@app/utils/script";
import {
  currentUserRolesSelector,
  isCurrentUserDocumentationReader,
} from "@ea/shared_components/auth/auth.selectors";
import NonIdealState from "@ea/shared_components/common/NonIdealState";
import {
  FEATURES,
  ROLES,
  SubscribeUpdateModelNames,
  SubscribeUpdateQuery,
  System,
  VirtualUser,
} from "@ea/shared_types/types";
import { Dispatch } from "redux";
import { getCodeTemplatesTableActions } from "../codeTemplates/codeTemplates.actions";
import WithAuthorization, { isAllowedAccess } from "../common/WithAuthorization";
import { WorkItemTableContainer } from "../workItems";
import ScriptActionsContainer from "./ScriptActions.container";
import ScriptVariablesLoaderContainer from "./ScriptVariablesLoader.container";
import { getScriptsTableActions, scriptsActions } from "./scripts.actions";
import { scriptsDataSelectors } from "./scripts.selectors";

import { tryParseInt } from "@app/utils/transformations";
import { Project } from "@ea/shared_types/types";
import { TabPaneProps } from "antd/lib/tabs";
import { CODE_TEMPLATES_TABLES_CONFIG } from "../codeTemplates/codeTemplates.table";
import {
  HideWhenDocumentationWizard,
  HideWhenDocumentationWizardHOC,
  IS_DOCUMENTATION_WIZARD,
} from "../common/HideWhenDocumentationWizard";
import HideableFeature from "../common/HideableFeature";
import { subscribeToModelUpdates } from "../common/websocket";
import DocumentationScriptTableContainer from "../documentation/DocumentationTable.container";
import { isFeatureDisabledSelector } from "../globalSettings/globalSettings.selectors";
import WithTestPlans from "../issueTrackingTool/components/WithTestPlans";
import { ScriptChangelogContainer } from "../scriptChangelog";

const TableSection = styled.div({
  display: "flex",
  flex: 1,
});

const DetailsSection = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

const TopBar = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const PivotSection = styled.div({
  width: "90vw",
  display: "flex",
  height: "70vh",
  flex: 1,
});

enum ScriptPaths {
  GENERAL = "general",
  STEPS = "steps",
  GUARDS = "conditionals",
  HISTORY = "history",
  VARIABLES = "variables",
  DIAGRAM = "diagram",
  LINKED_IN = "linked",
  WORK_ITEMS = "workItems",
  DOCUMENTATION = "documentation",
  META = "meta",
  CHANGELOG = "changelog",
}

const RoutePaths = {
  [ScriptPaths.GENERAL]: "general",
  [ScriptPaths.STEPS]: "steps",
  [ScriptPaths.HISTORY]: "history",
  [ScriptPaths.VARIABLES]: "variables",
  // [ScriptPaths.DIAGRAM]: "diagram",
  [ScriptPaths.GUARDS]: "conditionals",
  [ScriptPaths.LINKED_IN]: "linked",
  [ScriptPaths.WORK_ITEMS]: "workItems",
  [ScriptPaths.DOCUMENTATION]: "documentation",
  [ScriptPaths.META]: "meta",
  [ScriptPaths.CHANGELOG]: "changelog",
};

const PathsLabels = {
  [ScriptPaths.GENERAL]: "common.label.general",
  [ScriptPaths.STEPS]: "common.label.steps",
  [ScriptPaths.HISTORY]: "common.label.history",
  [ScriptPaths.VARIABLES]: "common.label.variables",
  // [ScriptPaths.DIAGRAM]: "common.label.diagram",
  [ScriptPaths.GUARDS]: "common.label.guards",
  [ScriptPaths.LINKED_IN]: "common.label.linked",
  [ScriptPaths.WORK_ITEMS]: "common.label.workItems",
  [ScriptPaths.DOCUMENTATION]: "common.label.documentation",
  [ScriptPaths.META]: "common.label.meta",
  [ScriptPaths.CHANGELOG]: "common.label.changelog",
};

const SectionContainer = styled.div({
  padding: "5px",
  marginBottom: "20px",
  width: "100%",
  height: "100%",
  display: "flex",
});

const TabPane = Tabs.TabPane;
interface IScriptLoaderState {
  openedPanel?: "assert" | "assign" | "login" | "scriptStep" | "shortcut";
  virtualUsers: VirtualUser[];
  systems: System[];
  project?: Project;
}

const History = WithAuthorization([ROLES.logs], [ROLES.documentationReader])(PivotSection);
const NoDocumentationRoute = WithAuthorization([], [ROLES.documentationReader])(Route);

const ScriptDetailsWithTestPlans = WithTestPlans("script")(ScriptDetailsContainer);
class ScriptLoaderContainer extends React.Component<IConnectProps> {
  state: IScriptLoaderState = {
    openedPanel: undefined,
    virtualUsers: [],
    systems: [],
  };
  unsubscribeTaskStep;
  unsubscribeVariable;

  componentDidMount() {
    this.loadScriptData(this.props);
    this.loadProjectData(this.props);

    const { scriptId } = this.props;

    const queryTaskStep: SubscribeUpdateQuery = {
      taskScriptId: scriptId,
    };
    const queryVariable: SubscribeUpdateQuery = {
      parentId: scriptId,
    };

    this.unsubscribeTaskStep = subscribeToModelUpdates(
      SubscribeUpdateModelNames.TASK_STEP,
      queryTaskStep,
      this.reloadSteps,
    );
    this.unsubscribeVariable = subscribeToModelUpdates(
      SubscribeUpdateModelNames.VARIABLE,
      queryVariable,
      this.reloadVariables,
    );
  }

  componentWillUnmount() {
    this.unsubscribeTaskStep();
    this.unsubscribeVariable();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.scriptId !== this.props.scriptId) {
      this.loadScriptData(this.props);
    }

    if (prevProps.script !== this.props.script) {
      this.loadProjectData(this.props);
    }
  }

  reloadVariables = () => {
    const { scriptId, variablesActions } = this.props;

    this.props.loadSingle({ id: scriptId }); // we have to reload script because it maybe be new datasource uploaded
    variablesActions.loadLocal(scriptId);
  };

  reloadSteps = () => {
    const { scriptId, loadSteps } = this.props;
    loadSteps({
      query: {
        taskScriptId: scriptId,
      },
    });
  };

  loadProjectData = async (props) => {
    const { script } = props;

    if (script) {
      const project = (
        await API.getProjects({
          filter: {
            where: {
              id: {
                inq: [script.projectId],
              },
            },
            include: [
              "ProjectVirtualUserMap.VirtualUser",
              "ProjectSystemDictionaryMap.SystemDictionary",
            ],
          },
        })
      )[0];

      if (!project) {
        return;
      }

      this.setState({
        virtualUsers: project.virtualUsers!,
        systems: project.systems!,
        project,
      });
    }
  };

  loadScriptData = async (props) => {
    const { scriptId } = props;
    this.props.loadSingle({ id: scriptId });
    this.props.loadSteps({
      query: {
        taskScriptId: scriptId,
      },
    });
    this.props.variablesActions.loadDataSource(scriptId);
    this.props.variablesActions.loadLocal(scriptId);
    this.props.variablesActions.loadSequence();
    this.props.loadSystems({});
    this.props.codeTemplateActons.load({});
  };

  navigate = (item: string) => {
    const { history, match } = this.props;
    if (item) {
      history.push(`${match.url}/${RoutePaths[item]}`);
    }
  };

  generateBreadcrumbText = (chunk: string, index: number) => {
    if (index !== 1) {
      return chunk;
    }

    const { script } = this.props;
    if (!script) {
      return chunk;
    }
    return `${chunk} (${formatScriptName(script)})`;
  };

  getPivotKey = (currentPath: string) => {
    const paths = Object.keys(RoutePaths).map((key) => RoutePaths[key]);

    if (paths.indexOf(currentPath) === -1) {
      return "";
    }

    return currentPath;
  };

  getAssignedVU = () => {
    if (this.props.script.isManualUrl) {
      return undefined;
    }

    const virtualUserId = this.props.script.virtualUserId;
    if (virtualUserId) {
      return this.state.virtualUsers.find((s) => s.id === virtualUserId);
    }

    if (!this.state.project) {
      return undefined;
    }

    return this.state.virtualUsers.find((s) => s.id === this.state.project!.defaultVirtualUserId);
  };

  getAssignedSystem = () => {
    if (this.props.script.isManualUrl) {
      return undefined;
    }

    const environmentId = this.props.script.environmentId;
    if (environmentId) {
      return this.state.systems.find((s) => s.id === environmentId);
    }

    if (!this.state.project) {
      return undefined;
    }

    return this.state.systems.find((s) => s.id === this.state.project!.defaultSystemId);
  };

  setOpenPanel = (panel) =>
    this.setState({
      openedPanel: panel,
    });

  getSection = (section) => {
    const { match, globalSettings, isDocumentationDisabled } = this.props;
    const { virtualUsers, systems } = this.state;
    const virtualUser = this.getAssignedVU();
    const system = this.getAssignedSystem();
    switch (section) {
      case ScriptPaths.STEPS:
        return (
          <NoDocumentationRoute
            path={`${match.url}/${RoutePaths[ScriptPaths.STEPS]}`}
            render={() => (
              <PivotSection>
                <TableSection>
                  <StepsTableContainer
                    virtualUser={virtualUser}
                    system={system}
                    scriptId={tryParseInt(match.params.id)!}
                    openedPanel={this.state.openedPanel}
                    setOpenPanel={this.setOpenPanel}
                    virtualUsers={virtualUsers}
                    systems={systems}
                  />
                </TableSection>
                <DetailsSection>
                  <StepDetailsContainer
                    scriptId={tryParseInt(match.params.id)!}
                    virtualUsers={virtualUsers}
                    virtualUser={virtualUser}
                    system={system}
                    systems={systems}
                  />
                </DetailsSection>
              </PivotSection>
            )}
          />
        );
      case ScriptPaths.GUARDS:
        return (
          <HideWhenDocumentationWizard>
            {() => (
              <NoDocumentationRoute
                path={`${match.url}/${RoutePaths[ScriptPaths.GUARDS]}`}
                render={() => (
                  <PivotSection>
                    <TableSection>
                      <GuardsTableContainer scriptId={tryParseInt(match.params.id)!} />
                    </TableSection>
                    <DetailsSection>
                      <GuardDetailsContainer scriptId={tryParseInt(match.params.id)!} />
                    </DetailsSection>
                  </PivotSection>
                )}
              />
            )}
          </HideWhenDocumentationWizard>
        );

      case ScriptPaths.VARIABLES:
        return (
          <NoDocumentationRoute
            path={`${match.url}/${RoutePaths[ScriptPaths.VARIABLES]}`}
            render={() => (
              <PivotSection>
                <ScriptVariablesLoaderContainer scriptId={tryParseInt(match.params.id)!} />
              </PivotSection>
            )}
          />
        );
      case ScriptPaths.HISTORY:
        return (
          <History>
            <ScriptLogsPage id={tryParseInt(match.params.id)!} />
          </History>
        );
      case ScriptPaths.CHANGELOG:
        return (
          <HideWhenDocumentationWizard>
            {() => (
              <NoDocumentationRoute
                path={`${match.url}/${RoutePaths[ScriptPaths.CHANGELOG]}`}
                render={() => (
                  <PivotSection>
                    <ScriptChangelogContainer scriptId={tryParseInt(match.params.id)!} />
                  </PivotSection>
                )}
              />
            )}
          </HideWhenDocumentationWizard>
        );

      case ScriptPaths.GENERAL:
        return (
          <PivotSection>
            {globalSettings && globalSettings.TEST_PLANS_INTEGRATION ? (
              <ScriptDetailsWithTestPlans scriptId={tryParseInt(match.params.id)!} />
            ) : (
              <ScriptDetailsContainer scriptId={tryParseInt(match.params.id)!} />
            )}
          </PivotSection>
        );
      // We're disabling diagrams due to massive changes in conditionals which destroyed diagrams logic.
      // To be revived in future
      // case ScriptPaths.DIAGRAM:
      //   return (
      //     <Route
      //       path={`${match.url}/${RoutePaths[ScriptPaths.DIAGRAM]}`}
      //       render={() => (
      //         <PivotSection>
      //           <ScriptDiagramContainer scriptId={tryParseInt(match.params.id)!} />
      //         </PivotSection>
      //       )}
      //     />
      //   );

      case ScriptPaths.LINKED_IN:
        return (
          <NoDocumentationRoute
            path={`${match.url}/${RoutePaths[ScriptPaths.LINKED_IN]}`}
            render={() => (
              <PivotSection>
                <ScriptLinkedInContainer scriptId={tryParseInt(match.params.id)!} />
              </PivotSection>
            )}
          />
        );

      case ScriptPaths.WORK_ITEMS:
        return (
          <HideWhenDocumentationWizard>
            {() => (
              <NoDocumentationRoute
                path={`${match.url}/${RoutePaths[ScriptPaths.WORK_ITEMS]}`}
                render={() => (
                  <PivotSection>
                    <WorkItemTableContainer scriptId={tryParseInt(match.params.id)!} />
                  </PivotSection>
                )}
              />
            )}
          </HideWhenDocumentationWizard>
        );

      case ScriptPaths.DOCUMENTATION:
        if (isDocumentationDisabled) {
          return null;
        }
        return (
          <Route
            path={`${match.url}/${RoutePaths[ScriptPaths.DOCUMENTATION]}`}
            render={() => (
              <PivotSection>
                <DocumentationScriptTableContainer scriptId={tryParseInt(match.params.id)!} />
              </PivotSection>
            )}
          />
        );
      default:
        return <Redirect to={`${match.url}/${RoutePaths[ScriptPaths.STEPS]}`} />;
    }
  };

  render() {
    const {
      location,
      match,
      script,
      isSingleLoading,
      userRoles,
      isDocumentationReader,
      areTagsDisabled,
      isDocumentationDisabled,
    } = this.props;
    const pathChunks = location.pathname.split("/");
    const chunk = pathChunks.length > 3 ? pathChunks[3] : "";
    const path = this.getPivotKey(chunk);

    if (isSingleLoading && !script) {
      return (
        <Page>
          <BreadcrumbSection>
            <BreadcrumbContainer transformText={this.generateBreadcrumbText} />
          </BreadcrumbSection>
          <MainSection>
            <NonIdealState text={this.props.intl.formatMessage({ id: "loadingLabel" })} />
          </MainSection>
        </Page>
      );
    }

    if (!isSingleLoading && !script) {
      return (
        <Page>
          <BreadcrumbSection>
            <BreadcrumbContainer transformText={this.generateBreadcrumbText} />
          </BreadcrumbSection>
          <MainSection>
            <NonIdealState text={this.props.intl.formatMessage({ id: "scripts.state.notFound" })} />
          </MainSection>
        </Page>
      );
    }

    const HistoryTabPane = WithAuthorization([ROLES.logs])(TabPane);

    type SectionType = {
      id: ScriptPaths;
      customPane?: React.ComponentType<TabPaneProps>;
      disallowedRoles?: ROLES[];
      allowedRoles?: ROLES[];
    };

    const allowedSections: (SectionType | undefined)[] = [
      { id: ScriptPaths.GENERAL },
      { id: ScriptPaths.VARIABLES, disallowedRoles: [ROLES.documentationReader] },
      { id: ScriptPaths.STEPS, disallowedRoles: [ROLES.documentationReader] },
      { id: ScriptPaths.LINKED_IN, disallowedRoles: [ROLES.documentationReader] },
      IS_DOCUMENTATION_WIZARD
        ? undefined
        : {
            id: ScriptPaths.GUARDS,
            customPane: HideWhenDocumentationWizardHOC(TabPane),
            disallowedRoles: [ROLES.documentationReader],
          },
      {
        id: ScriptPaths.HISTORY,
        customPane: HistoryTabPane,
        allowedRoles: [ROLES.logs],
        disallowedRoles: [ROLES.documentationReader],
      },
      IS_DOCUMENTATION_WIZARD
        ? undefined
        : { id: ScriptPaths.WORK_ITEMS, disallowedRoles: [ROLES.documentationReader] },
      IS_DOCUMENTATION_WIZARD
        ? undefined
        : {
            id: ScriptPaths.CHANGELOG,
            customPane: HideWhenDocumentationWizardHOC(TabPane),
            disallowedRoles: [ROLES.documentationReader],
          },
      isDocumentationDisabled ? undefined : { id: ScriptPaths.DOCUMENTATION },
    ];

    // TODO: after removing pivot change the editableareafinal handleClickOutside condition
    const pivotItems = allowedSections
      .filter((s) => s && isAllowedAccess(userRoles)(s.allowedRoles || [], s.disallowedRoles || []))
      .map((s: SectionType) => {
        const Section = s.customPane || TabPane;
        return (
          <Section key={s.id} tab={this.props.intl.formatMessage({ id: PathsLabels[s.id] })} />
        );
      });

    return (
      <Page>
        <BreadcrumbSection>
          <BreadcrumbContainer
            transformText={this.generateBreadcrumbText}
            disabled={isDocumentationReader}
          />
          <div style={{ marginLeft: "5px" }}>
            <StatusTag item={script} />
            <HideableFeature feature={FEATURES.SCRIPT_VERSIONING}>
              {() => <>{`v${script.version}`}</>}
            </HideableFeature>
          </div>
          {!areTagsDisabled &&
            script &&
            script.tags &&
            script.tags.map((tag) => (
              <div key={tag.id} style={{ marginLeft: "5px" }}>
                <Tag>{tag.name}</Tag>
              </div>
            ))}
        </BreadcrumbSection>
        <MainSection>
          <TopBar>
            <Tabs onChange={this.navigate} activeKey={path}>
              {pivotItems}
            </Tabs>
            {script ? <ScriptActionsContainer scriptId={tryParseInt(match.params.id)!} /> : null}
          </TopBar>
          <SectionContainer>
            {script ? this.getSection(path) : this.props.intl.formatMessage({ id: "loadingLabel" })}
          </SectionContainer>
        </MainSection>
      </Page>
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: RouteComponentProps<any>) => ({
    ...props,
    isSingleLoading: state.scripts.isSingleLoading,
    script: scriptsDataSelectors.getItemSelector(state, props.match.params.id),
    scriptId: props.match.params.id,
    userRoles: currentUserRolesSelector(state),
    isDocumentationReader: isCurrentUserDocumentationReader(state),
    globalSettings: state.globalSettings,
    areTagsDisabled: isFeatureDisabledSelector(state, FEATURES.GXP_TAGS),
    isDocumentationDisabled: isFeatureDisabledSelector(state, FEATURES.DOCUMENTATION),
  }),
  (dispatch: Dispatch, { match }: RouteComponentProps<any>) => ({
    ...{
      ...bindActionCreators(scriptsActions, dispatch),
      variablesActions: {
        ...bindActionCreators(variableActions, dispatch),
      },
      ...bindActionCreators(
        {
          loadSystems: getSystemTableActions(STEPS_TABLE_ID).load,
        },
        dispatch,
      ),
      loadSteps: bindActionCreators(stepsTableActions(STEPS_TABLE_ID), dispatch).setPersistentQuery,
      ...bindActionCreators(
        getScriptsTableActions(SCRIPTS_TABLES_CONFIG.SINGLE_SCRIPT.id(match.params.id)),
        dispatch,
      ),
      codeTemplateActons: {
        ...bindActionCreators(
          getCodeTemplatesTableActions(CODE_TEMPLATES_TABLES_CONFIG.MAIN.id()),
          dispatch,
        ),
      },
    },
  }),
);

type IConnectProps = ConnectedProps<typeof connectCreator> & InjectedIntlProps;

export default withRouter(connectCreator(injectIntl(ScriptLoaderContainer)));
