import React from "react";
import { gantt } from "@ea/dhtmlx-gantt";
import "@ea/dhtmlx-gantt/codebase/dhtmlxgantt.css";

import {
  GanttTask as GanttTaskType,
  GanttLink as GanttLinkType,
  GanttData,
  GANTT_ZOOM,
} from "../gantt.types";
import "./GanttChart.css";
import GanttTask from "./GanttTask";
import GanttLink from "./GanttLink";
import { isDate } from "@app/utils/objects";

interface IGanttProps {
  onDataChange: (ganttData: GanttTaskType[], ganttLinks: GanttLinkType[]) => void;
  zoom: GANTT_ZOOM;
  tasks: GanttData;
  setRef?: (comp: any) => void;
  onTaskClick?: (id: string | number, item: GanttTaskType, e: Event) => void;
  onTaskDblClick?: (id: string | number, e: Event) => void;
  onTaskSelect?: (id: string, isSelected: boolean) => void;
  onBeforeTaskAdd?: (id: string | number, item: GanttTaskType) => void;
  onAfterTaskAdd?: (id: string | number, item: GanttTaskType) => void;
  onBeforeTaskDelete?: (id: string | number, item: GanttTaskType) => void;
  onAfterTaskDelete?: (id: string | number, item: GanttTaskType) => void;
  onLinkDblClick?: (id: string | number, e: Event) => void;
  onReorder?: (data: GanttData) => void;
  onLoad: (data: GanttData) => void;
  onLinkClick?: (id: string) => void;
  onEmptyClick?: () => void;
  selectedLinks: string[];
  readOnly?: boolean;
}

interface IGanttState {
  isGanttReady: boolean;
}

// gantt calls this function when the drag ends to round dates according to the selected scale
// we don't want this functionality and there is no option to disable it so we override function
(gantt as any).roundDate = function (config) {
  if (isDate(config)) {
    return config;
  }

  return config.date;
};

export default class Gantt extends React.Component<IGanttProps, IGanttState> {
  dataProcessor: any = null;
  ganttRef;
  isRestartingData: boolean = false;
  constructor(props) {
    super(props);
    this.initZoom();
    this.state = { isGanttReady: false };
    this.ganttRef = React.createRef();
  }

  updateTask = (task) => {
    gantt.updateTask(task.id, task);
  };

  moveToGroup = (taskId, groupId) => {
    gantt.setParent(taskId, groupId);
    gantt.refreshTask(taskId.id);
  };

  deleteTask = (id) => {
    gantt.deleteTask(id);
  };

  deleteLink = (id) => {
    gantt.deleteLink(id);
  };

  unlinkElement = (task: GanttTaskType) => {
    const links = gantt.getLinks();

    links.forEach((link) => {
      if (link.source === task.id || link.target === task.id) {
        gantt.deleteLink(link.id);
      }
    });

    return {
      ...task,
      $source: [],
      $target: [],
    };
  };

  resetData = (data) => {
    gantt.clearAll();
    gantt.parse(data);

    return this.getData();
  };

  setIsRestarting = (value: boolean) => {
    this.isRestartingData = value;
  };

  initZoom() {
    gantt.ext.zoom.init({
      element: function () {
        return (gantt as any).$root.querySelector(".gantt_task");
      },
      useKey: "ctrlKey",
      trigger: "wheel",
      levels: [
        {
          name: GANTT_ZOOM.MIN_1,
          scale_height: 60,
          min_column_width: 100,
          scales: [
            { unit: "minute", step: 1, format: "%i" },
            // { unit: "minute", step: 15, format: "%H" },
            { unit: "hour", step: 1, format: "%H" },
          ],
        },
        {
          name: GANTT_ZOOM.MIN_5,
          scale_height: 60,
          min_column_width: 100,
          scales: [
            { unit: "minute", step: 5, format: "%i" },
            { unit: "hour", step: 1, format: "%H" },
          ],
        },
        {
          name: GANTT_ZOOM.MIN_10,
          scale_height: 60,
          min_column_width: 100,
          scales: [
            { unit: "minute", step: 10, format: "%i" },
            { unit: "hour", step: 1, format: "%H" },
          ],
        },
        {
          name: GANTT_ZOOM.MIN_15,
          scale_height: 60,
          min_column_width: 100,
          scales: [
            { unit: "minute", step: 15, format: "%i" },
            { unit: "hour", step: 1, format: "%H" },
          ],
        },
        {
          name: GANTT_ZOOM.MIN_30,
          scale_height: 60,
          min_column_width: 100,
          scales: [
            { unit: "minute", step: 30, format: "%i" },
            { unit: "hour", step: 1, format: "%H" },
          ],
        },
        {
          name: "Hours",
          scale_height: 60,
          min_column_width: 100,
          scales: [
            { unit: "day", step: 1, format: "%d %M" },
            { unit: "hour", step: 1, format: "%H" },
          ],
        },
      ],
    });
  }

  setZoom(value: GANTT_ZOOM) {
    gantt.ext.zoom.setLevel(value);
  }

  initGanttDataProcessor = () => {
    const { onDataChange } = this.props;
    this.dataProcessor = gantt.createDataProcessor((type, action, item, id) => {
      return new Promise<void>((resolve, reject) => {
        if (this.state.isGanttReady && onDataChange && !this.isRestartingData) {
          gantt.autoSchedule();
          const { data, links } = this.getData();
          onDataChange(data, links);
        }
        return resolve();
      });
    });
  };

  attachEventListeners() {
    const {
      onTaskClick,
      onAfterTaskAdd,
      onBeforeTaskAdd,
      onAfterTaskDelete,
      onBeforeTaskDelete,
      onTaskDblClick,
      onLinkDblClick,
      onTaskSelect,
      onReorder,
      onEmptyClick,
      onLinkClick,
    } = this.props;

    if (onBeforeTaskAdd) {
      gantt.attachEvent(
        "onBeforeTaskAdd",
        (id: string | number, item: GanttTaskType) => {
          onBeforeTaskAdd(id, item);
          return true;
        },
        {},
      );
    }

    if (onAfterTaskAdd) {
      gantt.attachEvent(
        "onAfterTaskAdd",
        (id: string | number, item: GanttTaskType) => {
          onAfterTaskAdd(id, item);
          return true;
        },
        {},
      );
    }

    if (onTaskClick) {
      gantt.attachEvent(
        "onTaskClick",
        (id: string, e: Event) => {
          const item = gantt.getTask(id);
          onTaskClick(id, item, e);
          return true;
        },
        {},
      );
    }

    if (onBeforeTaskDelete) {
      gantt.attachEvent(
        "onBeforeTaskDelete",
        (id: string | number, item: GanttTaskType) => {
          onBeforeTaskDelete(id, item);
          return true;
        },
        {},
      );
    }

    if (onLinkClick) {
      gantt.attachEvent(
        "onLinkClick",
        function (id, e) {
          onLinkClick(id);
        },
        {},
      );
    }

    if (onEmptyClick) {
      gantt.attachEvent(
        "onEmptyClick",
        function (event) {
          onEmptyClick();
        },
        {},
      );
    }

    gantt.attachEvent(
      "onBeforeTaskMove",
      function (id, parent, tindex) {
        if (parent && typeof parent === "string") {
          const parentTask = gantt.getTask(parent);
          if (parentTask && parentTask.scriptMode === "SINGLE") {
            return false;
          }
        }

        return true;
      },
      {},
    );

    gantt.attachEvent(
      "onBeforeRowDragEnd",
      function (id, parent, tindex) {
        const task = gantt.getTask(id);
        if (parent && typeof task.parent === "string") {
          const parentTask = gantt.getTask(task.parent);
          if (parentTask && parentTask.scriptMode === "SINGLE") {
            return false;
          }
        }
        return true;
      },
      {},
    );

    if (onReorder) {
      gantt.attachEvent(
        "onRowDragEnd",
        (id, target) => {
          onReorder(this.getData());
        },
        {},
      );
    }

    gantt.attachEvent("onBeforeTaskAutoSchedule", function (task, start, link, predecessor) {}, {});

    if (onTaskSelect) {
      gantt.attachEvent(
        "onTaskMultiSelect",
        (taskId, isSelected, event) => {
          onTaskSelect(taskId, isSelected);
        },
        {},
      );
    }

    if (onAfterTaskDelete) {
      gantt.attachEvent(
        "onAfterTaskDelete",
        (id: string | number, item: GanttTaskType) => {
          onAfterTaskDelete(id, item);
          return true;
        },
        {},
      );
    }

    if (onTaskDblClick) {
      gantt.attachEvent(
        "onTaskDblClick",
        (id: string | number, e: Event) => {
          onTaskDblClick(id, e);
          return false;
        },
        {},
      );

      if (onLinkDblClick) {
        gantt.attachEvent(
          "onLinkDblClick",
          (id: string | number, e: Event) => {
            onLinkDblClick(id, e);
            return false;
          },
          {},
        );
      }
    }

    gantt.attachEvent(
      "onBeforeLinkAdd",
      (id, link) => {
        if (gantt.isCircularLink(link)) {
          return false;
        }
        // prevent from creating links different then from task end to task start
        if (link.type !== "0") {
          return false;
        }

        const source = gantt.getTask(link.source);
        const target = gantt.getTask(link.target);

        // allow creating links to tasks without parent (group)
        if (!target.parent && !source.parent) {
          return true;
        }

        // prevent from creating links between task in different groups
        if (source.parent !== target.parent) {
          return false;
        }

        return true;
      },
      {},
    );
  }

  setGanttSettings() {
    if ((gantt.config as any).isInitialized) {
      return;
    }
    gantt.config.readonly = !!this.props.readOnly;
    gantt.config.duration_unit = "minute";
    gantt.config.duration_step = 1;
    gantt.config.scale_height = 75;
    gantt.config.scales = [
      { unit: "minute", step: 1, format: "%i" },
      { unit: "hour", step: 1, format: "%g %a" },
    ];
    gantt.templates.task_text = function (start, end, task) {
      const { runParams } = task;

      if (runParams?.repeater?.repeaterType) {
        const { repeaterType, repeaterMultiplier } = runParams.repeater;
        return `${task.text} <span style="margin-left: 10px;"> ${repeaterType.substring(
          0,
          3,
        )} x ${repeaterMultiplier}</span>`;
      }
      return task.text;
    };
    gantt.templates.parse_date = function (date) {
      return new Date(date);
    };
    gantt.templates.format_date = function (date) {
      return date.toISOString();
    };

    gantt.templates.link_class = (link) => {
      if (this.props.selectedLinks.indexOf(link.id.toString()) > -1) {
        return "selected_link";
      }
      return "";
    };

    gantt.plugins({
      auto_scheduling: true,
      tooltip: true,
      multiselect: true,
    });
    gantt.templates.tooltip_text = (start, end, task) => {
      return `<b>Task:</b> ${
        task.text
      } <br/> <b>Start time:</b> ${start.toLocaleString()} <br/> <b>End time:</b> ${end.toLocaleString()} <br/>  <b>Duration: </b> ${
        task.duration
      } min`;
    };
    gantt.config.drag_resize = false;
    gantt.config.auto_types = true;
    gantt.config.auto_scheduling = true;
    // gantt.config.auto_scheduling_strict = true;
    gantt.config.auto_scheduling_compatibility = true;

    gantt.config.round_dnd_dates = false;
    gantt.config.fit_tasks = false;
    // ability to move projects (groups)
    gantt.config.order_branch = true;
    gantt.config.drag_project = true;
    gantt.config.multiselect = true;
    // ability to resize task by 1 minute:
    gantt.config.min_duration = 60 * 1000;
    gantt.config.show_progress = false;
    gantt.config.correct_work_time = false;
    gantt.config.columns = [
      { name: "text", label: "Task name", width: 150, tree: true },

      {
        name: "start_date",
        label: "Start time",
        align: "center",
        template: (obj) => {
          return `${obj.start_date?.toLocaleString()}`;
        },
        width: "*",
        min_width: 150,
      },
      {
        name: "duration",
        label: "Duration",
        align: "center",
        template: (obj) => {
          return `${obj.duration} min`;
        },
      },
    ];

    (gantt.config as any).isInitialized = true;
  }

  getData = (): { data: GanttTaskType[]; links: GanttLinkType[] } => {
    return { data: gantt.getTaskByTime(), links: gantt.getLinks() };
  };

  componentDidMount() {
    const { setRef, tasks } = this.props;
    if (setRef) {
      setRef(this);
    }

    this.setGanttSettings();
    gantt.init(this.ganttRef.current);
    this.initGanttDataProcessor();
    this.attachEventListeners();
    gantt.parse(tasks);
    this.props.onLoad(this.getData());
    this.setState({ isGanttReady: true });
  }

  componentWillUnmount() {
    if (this.dataProcessor) {
      this.dataProcessor.destructor();
      this.dataProcessor = null;
    }
  }
  getTaskData = (selectedTaskId) => {
    return gantt.getTask(selectedTaskId);
  };

  onTaskSave = (values) => {
    const { start_date, end_date, ...rest } = values;
    const id = values.id;
    const task = gantt.getTask(id);
    const updatedTask = { ...task, ...rest };
    gantt.updateTask(id, updatedTask);
  };

  componentDidUpdate(prevProps: IGanttProps) {
    if (prevProps.zoom !== this.props.zoom) {
      this.setZoom(this.props.zoom);
    }
    if (prevProps.readOnly !== this.props.readOnly) {
      gantt.config.readonly = !!this.props.readOnly;
    }
    if (prevProps.selectedLinks !== this.props.selectedLinks) {
      Array.from(new Set([...prevProps.selectedLinks, ...this.props.selectedLinks]))
        .filter((linkId) => gantt.isLinkExists(linkId))
        .forEach((linkId) => gantt.refreshLink(linkId));
    }
  }

  render() {
    const { tasks } = this.props;
    const { isGanttReady } = this.state;

    return (
      <div ref={this.ganttRef} style={{ width: "100%", height: "100%" }}>
        {isGanttReady && tasks.data.map((t) => <GanttTask key={t.id} gantt={gantt} task={t} />)}
        {isGanttReady && tasks.links.map((l) => <GanttLink key={l.id} gantt={gantt} link={l} />)}
      </div>
    );
  }
}
