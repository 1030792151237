import * as React from "react";

import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { System, SYSTEM_TYPE } from "@ea/shared_types/types";
import SystemTypeField from "@app/modules/systemDictionary/components/SystemTypeField";

import { getTranslationKey } from "@app/translations/translations.helpers";
export const SYSTEM_DICTIONARY_COLUMNS_CONFIG: PlainObject<CustomColumn<System>> = {
  envName: {
    label: getTranslationKey("table", "name"),
    dataIndex: "envName",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  envAddress: {
    label: getTranslationKey("table", "envAddress"),
    dataIndex: "envAddress",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  envType: {
    label: getTranslationKey("table", "envType"),
    dataIndex: "envType",
    sortable: true,
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        {
          label: getTranslationKey("systemDictionary", "type", "development"),
          value: SYSTEM_TYPE.DEV,
        },
        { label: getTranslationKey("systemDictionary", "type", "test"), value: SYSTEM_TYPE.TEST },
        {
          label: getTranslationKey("systemDictionary", "type", "production"),
          value: SYSTEM_TYPE.PROD,
        },
        { label: getTranslationKey("systemDictionary", "type", "uat"), value: SYSTEM_TYPE.UAT },
        { label: getTranslationKey("systemDictionary", "type", "other"), value: SYSTEM_TYPE.OTHER },
      ],
    },
    render: (text) => <SystemTypeField type={text} />,
  },
  applicationName: {
    label: getTranslationKey("table", "applicationName"),
    dataIndex: "applicationName",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  envTypeShort: {
    label: getTranslationKey("table", "envTypeShort"),
    dataIndex: "envType",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    render: (text) => <SystemTypeField type={text} />,
  },
  applicationNameShort: {
    label: getTranslationKey("table", "applicationNameShort"),
    dataIndex: "applicationName",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  description: {
    label: getTranslationKey("table", "description"),
    dataIndex: "description",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
};

export const SYSTEM_DICTIONARY_COLUMNS: ColumnConfig<System>[] = [
  {
    props: SYSTEM_DICTIONARY_COLUMNS_CONFIG.envName,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: SYSTEM_DICTIONARY_COLUMNS_CONFIG.envAddress,
    frameworkProps: {
      width: "45%",
    },
  },
  {
    props: SYSTEM_DICTIONARY_COLUMNS_CONFIG.envType,
    frameworkProps: {
      width: "13%",
    },
  },
  {
    props: SYSTEM_DICTIONARY_COLUMNS_CONFIG.applicationName,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: SYSTEM_DICTIONARY_COLUMNS_CONFIG.description,
  },
];

export const SYSTEM_PROJECT_ASSIGNMENT_COLUMNS: ColumnConfig<System>[] = [
  {
    props: SYSTEM_DICTIONARY_COLUMNS_CONFIG.envName,
    frameworkProps: {
      width: "25%",
    },
  },
  {
    props: SYSTEM_DICTIONARY_COLUMNS_CONFIG.envAddress,
    frameworkProps: {
      width: "25%",
    },
  },
  {
    props: SYSTEM_DICTIONARY_COLUMNS_CONFIG.envTypeShort,
  },
  {
    props: SYSTEM_DICTIONARY_COLUMNS_CONFIG.applicationNameShort,
  },
];

export const SYSTEM_DICTIONARY_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_SYSTEM_DICTIONARY",
  },
  ALL: {
    id: () => "ALL",
    preferencesId: "SYSTEM_DICTIONARY_ALL",
  },
  PROJECT_ASSIGNMENT: {
    id: (projectId) => `PROJECT_ASSIGNMENT_${projectId}`,
    preferencesId: "SYSTEM_DICTIONARY_PROJECT_ASSIGNMENT",
  },
  PROJECT_ASSIGNMENT_DISPLAY: {
    id: (projectId) => `PROJECT_ASSIGNMENT_DISPLAY_${projectId}`,
    preferencesId: "SYSTEM_DICTIONARY_PROJECT_ASSIGNMENT_DISPLAY",
  },
};
