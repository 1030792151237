import styled from "@emotion/styled";
import * as React from "react";
import { ApplicationState } from "../app.reducers";
import { connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { reportUrlSelector } from "../globalSettings/globalSettings.selectors";

const Container = styled.div({
  marginLeft: "10px",
  width: "100%",
  display: "flex",
  height: "100%",
});

class ReportsContainer extends React.Component<IConnectProps> {
  render() {
    const { url } = this.props;
    return (
      <>
        <Container>
          {!url ? (
            <p>
              <h3>
                <FormattedMessage id={getTranslationKey("messages", "error", "noReportLink")} />
              </h3>
            </p>
          ) : (
            <iframe src={url} width="100%" frameBorder={0} id="reportId" height="100%" />
          )}
        </Container>
      </>
    );
  }
}

const connectCreator = connect((state: ApplicationState) => {
  const url = reportUrlSelector(state);
  return {
    url,
  };
});

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(ReportsContainer);
