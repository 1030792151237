import { createDataSelectors } from "@app/modules/app.reducers";
import { kpisDataSelectors } from "@app/modules/kpis/kpis.selectors";
import { StepLog } from "@ea/shared_types/types";
import moment from "moment";
import { createSelector } from "reselect";
export const stepLogsDataSelectors = createDataSelectors<StepLog>()("stepLogs");

export const getLogsPerformanceCountersData = createSelector(
  stepLogsDataSelectors.getOrderedDataSelector,
  kpisDataSelectors.getDataSelector,
  (stepLogs, kpiDictionary) => {
    if (!stepLogs || stepLogs.length === 0) {
      return undefined;
    }
    const performanceData: any[] = [];
    const kpisUsed = new Set<string>();
    stepLogs
      .filter((sl) => sl.kpi && sl.kpi.length > 0 && sl.endTime)
      .forEach((sl, index) => {
        const kpis = sl.kpi!.reduce((summary, kpiId) => {
          summary[kpiDictionary[kpiId]?.name || kpiId] = index + 1;
          kpisUsed.add(kpiDictionary[kpiId]?.name || `${kpiId}`);
          return summary;
        }, {});

        performanceData.push({
          timestamp: moment(sl.endTime).format("DD.MM.YYYY HH:mm:ss"),
          ...kpis,
        });
      });
    let defaultKpiData = Array.from(kpisUsed).reduce((data, kpi) => {
      data[kpi] = 0;
      return data;
    }, {});
    return performanceData.map((pd) => {
      const output = { ...defaultKpiData, ...pd };
      defaultKpiData = { ...output };
      return output;
    });
  },
);

export const getLogsPerformanceCountersTableData = createSelector(
  stepLogsDataSelectors.getOrderedDataSelector,
  kpisDataSelectors.getDataSelector,
  (stepLogs, kpiDictionary) => {
    if (!stepLogs || stepLogs.length === 0) {
      return [];
    }

    const start = moment(stepLogs[0].startTime);
    const end = moment(stepLogs[stepLogs.length - 1].endTime);
    const duration = moment.duration(end.diff(start));
    const hoursDuration = duration.asHours();
    const minutesDuration = duration.asMinutes();
    const totals: any = stepLogs
      .filter((sl) => sl.kpi && sl.kpi.length > 0 && sl.endTime)
      .reduce((prev, curr) => {
        curr.kpi?.forEach((kpi) => {
          if (prev[kpi]) {
            prev[kpi] += 1;
          } else {
            prev[kpi] = 1;
          }
        });

        return prev;
      }, {});

    const stats = Object.keys(totals).map((kpiId) => ({
      occurrencesPerHour: totals[kpiId] / hoursDuration,
      occurrencesPerMinute: totals[kpiId] / minutesDuration,
      totalOccurrences: totals[kpiId],
      name: kpiDictionary[kpiId].name,
    }));

    return stats;
  },
);
