export const NAVBAR_HEIGHT = "40px";
export const COLORS = {
  BLUE2: "#108ee9",
  BLUE1: "#0078d7",
  WHITE: "white",
  DARK: "#001529",
  GRAY: "#aaa",
  BOOST_GREEN: "#00afb9",
  GRAY_OVERLAY: "rgba(0,0,0,0.05)",
  WHITE_OVERLAY: "rgba(255,255,255,.4)",
  TRANSPARENT: "rgba(0, 0, 0, 0)",
  ADD_BADGE: "#52c41a",
  DARK_GREEN: "#004b1c",
  DARK_RED: "#a80000",
  DISABLED_BUTTON_TEXT: "rgba(0,0,0,.25)",
  BUTTON_TEXT: "rgba(0,0,0,.65)",
  HOVER_BUTTON_TEXT: "#40a9ff",
  HOVER_BUTTON_BACKGROUND: "#eaeaea",
  PRIMARY_HOVER: "rgb(0, 120, 212)",
  ANTD_BOX_SHADOW: "0 0 0 2px rgba(24, 144, 255, 0.2)",
  ANTD_BORDER_COLOR: "#40a9ff",
  ANTD_ERROR_BORDER_COLOR: "#f5222d",
  ANTD_DISABLED_TEXT_COLOR: "rgba(0, 0, 0, 0.65)",
  ANTD_DISABLED_BACKGROUND_COLOR: "#f5f5f5",
  ANTD_BORDER: "1px solid #d9d9d9",
  LINK_STEP_TAG: "#004b1c",
  GUARD_TAG: "#2db7f5",
  CODE_TEMPLATE_TAG: "#696969",
  NAVBAR_COLOR_LIGHTER: "#1a2c3e",
  NAVBAR_COLOR_LIGHTER_AB: "#797979",

  STATUS: {
    ERROR: "red",
    WARNING: "orange",
    SUCCESS: "green",
    INFO: "green",
    TERMINATED: "#333333",
    RUNNING: "#00B294",
    "RUNNING IN BACKGROUND": "#004b50",
  },
};

export const Z_INDEXES = {
  NAVBAR: 100,
  SAVING_AREA: 500,
  VIRTUALIZED_TABLE: 5000,
  VARIABLE_PICKER: 5000,
};
