import * as React from "react";
import { Route } from "react-router";
import VirtualUserPoolTableContainer from "./VirtualUserPoolTable.container";
import VirtualUserPoolDetailsContainer from "./VirtualUserPoolDetails.container";

const VirtualUserPoolLoaderContainer = () => {
  return (
    <>
      <Route exact path="/settings/virtualUserPool/" component={VirtualUserPoolTableContainer} />
      <Route path="/settings/virtualUserPool/:id" component={VirtualUserPoolDetailsContainer} />
    </>
  );
};

export default VirtualUserPoolLoaderContainer;
