import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { Project } from "@ea/shared_types/types";
import { moduleActions, projectsActionCreators } from "./projects.actions";

const initialState = {
  ...getDataReducerInitialState<Project>(),
};

export type ProjectReducer = typeof initialState;

const dataReducer = createDataReducer<Project>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState)
  .case(projectsActionCreators.refreshParentBranch.done, (state, payload) => {
    const newState = {
      ...state,
      isLoading: false,
      items: payload.result.reduce(
        (prev, curr) => {
          prev[curr.id] = curr;
          return prev;
        },
        { ...state.items },
      ),
    };

    return newState;
  })
  .case(projectsActionCreators.refreshChildBranch.done, (state, payload) => {
    const newState = {
      ...state,
      isLoading: false,
      items: payload.result.reduce(
        (prev, curr) => {
          prev[curr.id] = curr;
          return prev;
        },
        { ...state.items },
      ),
    };

    return newState;
  });
