import styled from "@emotion/styled";
import { Spin } from "antd";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { scriptsActions } from "@app/modules/scripts/scripts.actions";
import { getScriptWithBasicVariablesSelector } from "@app/modules/scripts/scripts.selectors";
import { getVariableColumns } from "@app/modules/variables/components/VariableColumns";
import { API } from "@app/services/api/api";
import EditeableAreaFinal from "@ea/shared_components/EditeableArea/EditeableAreaFinal";
import TableForm from "@ea/shared_components/Form/TableForm";
import { EntityType, Variable } from "@ea/shared_types/types";
import { variableActions } from "../variables/variables.actions";
import { isScriptReadonly } from "@app/utils/script";

interface IScriptDetailsProps {
  scriptId: number;
  additionalButtons?: React.ReactElement;
  onSave?: (variables: Variable[]) => void;
}

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

class ScriptVariables extends React.Component<IScriptDetailsProps & IConnectProps, any> {
  save = async (values) => {
    const { onSave } = this.props;
    const variables = values.variables;

    const result = await API.patchVariables({
      parentId: this.props.scriptId,
      parentType: "TaskScript",
      variables,
    });

    this.props.loadLocal(this.props.scriptId);
    this.props.invalidate({});
    if (onSave) {
      onSave(result);
    }
  };

  render() {
    const { isLoading, script, intl, additionalButtons } = this.props;

    if (isLoading) {
      return (
        <Container>
          <Spin />
        </Container>
      );
    }

    if (!script) {
      return (
        <Container>
          <h3>{this.props.intl.formatMessage({ id: "scripts.noScriptSelect" })}</h3>
        </Container>
      );
    }

    const { variables } = script;
    return (
      <Container>
        <EditeableAreaFinal
          onSave={this.save}
          disableEditing={isScriptReadonly(script)}
          initialValues={{ variables }}
          subscription={{ submitting: true, pristine: true, submitError: true }}
          additionalButtons={additionalButtons}
          render={(props) => (
            <TableForm<{ name: string }>
              name="variables"
              allowEmptyForm
              initialRecord={{
                name: "",
                valueType: undefined,
                defaultValue: undefined,
                parentType: EntityType.SCRIPT,
                parentId: script.id,
              }}
              columns={getVariableColumns(props, intl)}
            />
          )}
        />
      </Container>
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: IScriptDetailsProps) => ({
    script: getScriptWithBasicVariablesSelector(state, props.scriptId),
    scriptId: props.scriptId,
    isLoading: state.scripts.isLoading,
  }),
  {
    ...scriptsActions,
    ...variableActions,
  },
);

type IConnectProps = ConnectedProps<typeof connectCreator> & InjectedIntlProps;

export default connectCreator(injectIntl(ScriptVariables));
