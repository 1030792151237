import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { Kpi } from "@ea/shared_types/types";
import { PanelType } from "@ea/shared_components/Panel";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import CodeTemplateForm from "./CodeTemplateForm";
import { useSelector } from "react-redux";
import { getTemplateGroupsFormOptions } from "../../codeTemplateGroups/codeTemplateGroups.selectors";
import { ApplicationState } from "../../app.reducers";
import { CODE_TEMPLATE_GROUPS_TABLES_CONFIG } from "../../codeTemplateGroups/codeTemplateGroups.table";
import { OptionType } from "@ea/shared_components/Form/Form.common";

interface ICreateEditCodeTemplatePanelProps {
  visibility: boolean;
  onClose: () => void;
  selected?: Kpi;
  isEdit: boolean;
  onSave: (values) => void;
  versionOptions: OptionType[];
  initialValues: any;
}

const CreateEditCodeTemplatePanel: React.FunctionComponent<ICreateEditCodeTemplatePanelProps> = ({
  isEdit,
  visibility,
  onClose,
  initialValues,
  versionOptions,
  onSave,
}) => {
  const codeTemplateGroupOptions = useSelector((state: ApplicationState) =>
    getTemplateGroupsFormOptions(state, CODE_TEMPLATE_GROUPS_TABLES_CONFIG.MAIN.id()),
  );

  return (
    <PanelFormFinal
      visibility={visibility}
      panelType={PanelType.MEDIUM}
      headerText={getTranslationKey("codeTemplates", "header", isEdit ? "edit" : "create")}
      onCancelClick={onClose}
      onOkClick={onSave}
      key={isEdit ? "Edit" : "Create"}
      cancelButtonText={getTranslationKey("button", "cancel")}
      okButtonText={getTranslationKey("button", isEdit ? "save" : "create")}
      initialValues={initialValues}
      render={({ values, change, form }) => (
        <CodeTemplateForm
          values={values}
          form={form}
          codeTemplateGroupOptions={codeTemplateGroupOptions}
          versionOptions={versionOptions}
          isEdit={!!isEdit}
        />
      )}
    />
  );
};

export default CreateEditCodeTemplatePanel;
