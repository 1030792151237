import * as React from "react";
import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { GetAsSeconds, GetAsTime } from "@app/modules/common/time";
import { STATUS_TYPE, StepLog, StepsLogsColumnsConfigActions } from "@ea/shared_types/types";
import StatusField from "../components/StatusField";
import ScreenshotDisplay from "@app/modules/logs/components/ScreenshotDisplay";
import i18next from "../../../translations/backendTranslations";
export const STEP_LOGS_COLUMNS_CONFIG = (
  customColumnActions: StepsLogsColumnsConfigActions = {},
): PlainObject<CustomColumn<StepLog>> => ({
  label: {
    dataIndex: "label",
    label: "table.label",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    render: (text, record) => {
      return (
        record.label || (record.labelKey ? i18next.t(record.labelKey, record.labelParams) : text)
      );
    },
  },
  lineNum: {
    label: "table.lineNum",
    dataIndex: "lineNum",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  startTime: {
    label: "table.startTime",
    dataIndex: "startTime",
    sortable: true,
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
    render: (text, record, index) => {
      return <span>{GetAsTime({ dataKey: "startTime", rowData: record })}</span>;
    },
  },
  status: {
    label: "table.status",
    dataIndex: "status",
    sortable: true,
    render: (text, record, index) => <StatusField status={text} />,
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        { label: STATUS_TYPE.SUCCESS, value: STATUS_TYPE.SUCCESS },
        { label: STATUS_TYPE.WARNING, value: STATUS_TYPE.WARNING },
        { label: STATUS_TYPE.ERROR, value: STATUS_TYPE.ERROR },
      ],
    },
  },
  endTime: {
    label: "table.endTime",
    dataIndex: "endTime",
    sortable: true,
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
    render: (text, record, index) => {
      return <span>{GetAsTime({ dataKey: "endTime", rowData: record })}</span>;
    },
  },
  duration: {
    label: "table.duration",
    dataIndex: "duration",
    sortable: true,
    filter: {
      filterType: ColumnFilters.RANGE,
      converter: (value) => value * 1000,
    },
    render: (text, record, index) => {
      return <span>{GetAsSeconds({ dataKey: "duration", rowData: record })}</span>;
    },
  },
  screenshot: {
    label: "table.screenshotPath",
    dataIndex: "screenshotPath",
    render: (text, record, index) => {
      return (
        record.screenshotPath && (
          <ScreenshotDisplay
            onClick={() =>
              customColumnActions.onScreenshotClick && customColumnActions.onScreenshotClick(record)
            }
          />
        )
      );
    },
  },
});

export const STEP_LOGS_COLUMNS = (customColumnActions): ColumnConfig<StepLog>[] => {
  const columnConfg = STEP_LOGS_COLUMNS_CONFIG(customColumnActions);
  return [
    {
      props: columnConfg.lineNum,
      frameworkProps: {
        width: "8%",
      },
    },
    {
      props: columnConfg.label,
      frameworkProps: {
        width: "22%",
      },
    },
    {
      props: columnConfg.startTime,
      frameworkProps: {
        width: "15%",
      },
    },
    {
      props: columnConfg.endTime,
      frameworkProps: {
        width: "15%",
      },
    },
    {
      props: columnConfg.duration,
      frameworkProps: {
        width: "15%",
      },
    },
    {
      props: columnConfg.status,
      frameworkProps: {},
    },
    {
      props: columnConfg.screenshot,
      frameworkProps: {},
    },
  ];
};
