import * as React from "react";
import EmptyField from "@ea/shared_components/Form/Fields/EmptyField";

import { getIn } from "final-form";
import { VirtualUser, System } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { SwitchField } from "@ea/shared_components/";
import { VirtualUserField } from "./VirtualUserField";
import { EnvironmentField } from "./EnvironmentField";
import { DataTestIds } from "@app/utils/dataTestIds";
interface IScriptSystemAndVUFormChunkProps {
  values: any;
  virtualUsers?: VirtualUser[];
  systems?: System[];
  prefix?: string;
  readOnly?: boolean;
}

const ScriptSystemAndVUFormChunk: React.FunctionComponent<IScriptSystemAndVUFormChunkProps> = ({
  values,
  prefix,
  virtualUsers = [],
  systems = [],
  readOnly,
}) => {
  const getName = (name) => (prefix ? `${prefix}.${name}` : name);

  const useVirtualUser = getIn(values, getName("useVirtualUser"));

  return (
    <>
      <EnvironmentField readOnly={readOnly} systems={systems} prefix={prefix} values={values} />
      <SwitchField
        name={getName("useVirtualUser")}
        label={getTranslationKey("common", "label", "useVirtualUser")}
        data-testid={DataTestIds.FORM_SWITCH_USE_VIRTUAL_USER}
      />

      {useVirtualUser && (
        <VirtualUserField
          readOnly={readOnly}
          prefix={prefix}
          values={values}
          virtualUsers={virtualUsers}
        />
      )}
      <EmptyField name={getName("defaultSystemId")} />
      <EmptyField name={getName("defaultVirtualUserId")} />
    </>
  );
};

export default ScriptSystemAndVUFormChunk;
