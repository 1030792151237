// tslint:disable:whitespace
import {
  Activity,
  CodeValue,
  EAFile,
  INITIAL_STRUCTURE_VERSION,
  PlainObject,
  SwitchMode,
  VirtualUser,
} from "./types";

export enum CoreCommandsIds {
  start = "start",
  end = "end",
  script = "script", // A lot of internal code to handle connection between scripts
  inspect = "inspect", // We have a special UI for this command
  login = "login", // Player / Recorder need to detect login step
  condition = "condition",
  assign = "assign",
  click = "click",
  code = "code",
  type = "type",
  change = "change",
  upload = "upload",
  shortcut = "shortcut",
  clickWithFocus = "clickWithFocus",
  tokens = "tokens",
  mouseup = "mouseup",
  mousedown = "mousedown",
  password = "password",
  check = "check",
  hover = "hover",
}

export type CommandType<T extends { value: any; id: string; version: string }> = {
  value: T["value"];
  id: T["id"];
  version: T["version"];
};

export const CommandClickVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandClickType = CommandType<{
  value: undefined | { controlName: string; containerName?: string; value?: string };
  id: CoreCommandsIds.click;
  version: (typeof CommandClickVersions)[0];
}>;

export const CommandClickWithFocusVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandClickWithFocusType = CommandType<{
  value: undefined | { controlName: string };
  id: CoreCommandsIds.clickWithFocus;
  version: (typeof CommandClickVersions)[0];
}>;

export const CommandShortcutVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandShortcutType = CommandType<{
  value: {
    shortcut: string;
    shortcutLabel: string;
  };
  id: CoreCommandsIds.shortcut;
  version: (typeof CommandShortcutVersions)[0];
}>;

export const CommandChangeVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandChangeType = CommandType<{
  value: {
    code: CodeValue;
  };
  id: CoreCommandsIds.change;
  version: (typeof CommandChangeVersions)[0];
}>;

export const CommandUploadVersions = <const>[INITIAL_STRUCTURE_VERSION, "0.0.2"];

// todo: TS should change value type based on structureVersion, is it possible?
// CommandType<{
//   value: {
//     files: string[];
//   };
//   id: CoreCommandsIds.upload;
//   version: typeof CommandUploadVersions[0];
// }>
export type CommandUploadType = CommandType<{
  value: {
    files: EAFile[];
  };
  id: CoreCommandsIds.upload;
  version: (typeof CommandUploadVersions)[1];
}>;

export const CommandAssignVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandAssignType = CommandType<{
  value: {
    code: CodeValue;
  };
  id: CoreCommandsIds.assign;
  version: (typeof CommandAssignVersions)[0];
}>;

export const CommandStartVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandStartType = CommandType<{
  value: { url: string };
  id: CoreCommandsIds.start;
  version: (typeof CommandStartVersions)[0];
}>;

export const CommandEndVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandEndType = CommandType<{
  value: undefined;
  id: CoreCommandsIds.end;
  version: (typeof CommandEndVersions)[0];
}>;

export const CommandTypeVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandTypeType = CommandType<{
  value: {
    code: CodeValue;
    controlName?: string;
  };
  id: CoreCommandsIds.type;
  version: (typeof CommandTypeVersions)[0];
}>;

export const CommandLoginVersions = <const>[INITIAL_STRUCTURE_VERSION];

export type CommandLoginType = CommandType<{
  value: {
    defaultSystem: boolean;
    defaultVirtualUser: boolean;
    environmentId?: number;
    virtualUserId?: number;
    virtualUserName?: string;
    systemName?: string;
    url?: string;
    defaultSystemId?: number;
    defaultVirtualUserId?: number;
    useVirtualUser?: boolean;
    virtualUser?: Pick<VirtualUser, "tokens" | "id">;
  };
  id: CoreCommandsIds.login;
  version: (typeof CommandLoginVersions)[0];
}>;

export const CommandScriptVersions = <const>[INITIAL_STRUCTURE_VERSION];

export type CommandScriptType = CommandType<{
  value: {
    overrodeVariableId: number;
    code: CodeValue;
    variableId: number;
  }[];
  id: CoreCommandsIds.script;
  version: (typeof CommandScriptVersions)[0];
}>;

export const CommandInspectVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandInspectType = CommandType<{
  value: {
    originalValue: string;
    code: CodeValue;
  };
  id: CoreCommandsIds.inspect;
  version: (typeof CommandInspectVersions)[0];
}>;

export const CommandConditionVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandConditionType = CommandType<{
  value: Activity;
  id: CoreCommandsIds.condition;
  version: (typeof CommandConditionVersions)[0];
}>;

export const CommandMouseUpVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandMouseUpType = CommandType<{
  value: undefined; // TODO TYPE?
  id: CoreCommandsIds.mouseup;
  version: (typeof CommandMouseUpVersions)[0];
}>;

export const CommandMouseDownVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandMouseDownType = CommandType<{
  value: undefined | { controlName: string };
  id: CoreCommandsIds.mousedown;
  version: (typeof CommandMouseDownVersions)[0];
}>;

export const CommandPasswordVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandPasswordType = CommandType<{
  value: {
    code: CodeValue;
    controlName?: string;
  };
  id: CoreCommandsIds.password;
  version: (typeof CommandPasswordVersions)[0];
}>;

export const CommandCodeVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandExecuteCodeType = CommandType<{
  value: {
    code: CodeValue;
  };
  id: CoreCommandsIds.code;
  version: (typeof CommandCodeVersions)[0];
}>;

export type CommandsTokensValueType = {
  sessionStorage: {
    omit: string[];
    take: string[];
    override: PlainObject<any>;
  };
  localStorage: {
    omit: string[];
    take: string[];
    override: PlainObject<any>;
  };
  cookies: {
    omit: string[];
    take: string[];
    override: PlainObject<any>;
  };
};

export const CommandTokensVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandTokensType = CommandType<{
  value: CommandsTokensValueType;
  id: CoreCommandsIds.tokens;
  version: (typeof CommandCodeVersions)[0];
}>;

export const CommandCheckVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandCheckType = CommandType<{
  value: { value: SwitchMode; controlName: string };
  id: CoreCommandsIds.check;
  version: (typeof CommandCheckVersions)[0];
}>;

export const CommandHoverVersions = <const>[INITIAL_STRUCTURE_VERSION];
export type CommandHoverType = CommandType<{
  value: undefined | { controlName: string; containerName?: string; value?: string };
  id: CoreCommandsIds.hover;
  version: (typeof CommandClickVersions)[0];
}>;

export enum RulesDefault {
  Input = "Input",
  PasswordInput = "PasswordInput",
  ButtonRule = "ButtonRule",
}
