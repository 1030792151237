import * as React from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators, Dispatch } from "redux";
import { registerWebsocketHandler } from "./modules/common/websocket";
import { logsActions } from "./modules/logs/logs.actions";
import { LOGS_TABLES_CONFIG } from "./modules/logs/logs.table";
import { stepLogsActions } from "./modules/logs/StepLogs/stepLogs.actions";
import { API } from "./services/api/api";
import { getTranslationKey } from "./translations/translations.helpers";
import { downloadFileBlob } from "@ea/shared_components/helpers/file";
import { WebsocketMessageTypes } from "@ea/shared_types/types";

class GlobalNotifications extends React.Component<IConnectProps> {
  toasts: Record<string, any>;
  constructor(props) {
    super(props);
    this.toasts = {};
    registerWebsocketHandler(WebsocketMessageTypes.REPORT_GENERATION_SUCCESS, (data) =>
      this.onReportGenerationSuccess(data),
    );
    registerWebsocketHandler(WebsocketMessageTypes.REPORT_GENERATION_FAIL, (data) =>
      this.onReportGenerationFail(data),
    );
    registerWebsocketHandler(WebsocketMessageTypes.EXPORT_GENERATION_START, (data) =>
      toast.update(this.getToast(data.jobId), {
        render: `Exporting data with id ${data.jobId}: 0/100%`,
      }),
    );
    registerWebsocketHandler(WebsocketMessageTypes.EXPORT_GENERATION_PROGRESS, (data) =>
      toast.update(this.getToast(data.jobId), {
        render: `Exporting data with id ${data.jobId}: ${data.progress}/100%`,
      }),
    );
    registerWebsocketHandler(WebsocketMessageTypes.EXPORT_GENERATION_SUCCESS, (data) => {
      toast.update(this.getToast(data.jobId), {
        render: `Exporting data with id ${data.jobId}: 100/100%`,
      });
      this.onExportGenerationSuccess(data);
      this.deleteToast(data.jobId);
    });
    registerWebsocketHandler(WebsocketMessageTypes.EXPORT_GENERATION_FAIL, (data) => {
      this.deleteToast(data.jobId, true);
      toast.error(`Exporting data with id ${data.jobId} has failed with error: ${data.reason}`);
    });
  }

  deleteToast = (id: string, dismiss?: boolean) => {
    if (this.toasts[id]) {
      if (dismiss) {
        toast.dismiss(this.toasts[id]);
      }
      delete this.toasts[id];
    }
  };

  getToast = (id: string) => {
    if (!this.toasts[id]) {
      this.toasts[id] = toast.info("");
    }
    return this.toasts[id];
  };

  onExportGenerationSuccess = async ({ jobId, fileName }) => {
    const blob = await API.downloadExport({ fileName });
    downloadFileBlob(blob, fileName);
  };

  onReportGenerationSuccess = async ({ tableId, reportName, originalReportName }) => {
    const blob = await API.downloadReport({ reportName });
    downloadFileBlob(blob, originalReportName);
    this.clearReportGeneration(tableId);
  };

  onReportGenerationFail = async ({ tableId }) => {
    this.clearReportGeneration(tableId);
    toast.error(
      <FormattedMessage id={getTranslationKey("messages", "error", "reportGenerationFailed")} />,
    );
  };

  clearReportGeneration = (tableId: string) => {
    const { actions } = this.props;
    if (tableId.includes(LOGS_TABLES_CONFIG.STEP_LOGS.preferencesId)) {
      actions.setIsGeneratingReportStepLogs({
        isGeneratingReport: false,
        tableId,
      });
    } else {
      actions.setIsGeneratingReportLogs({
        isGeneratingReport: false,
        tableId,
      });
    }
  };

  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    setIsGeneratingReportLogs: bindActionCreators(logsActions, dispatch).setIsGeneratingReport,
    setIsGeneratingReportStepLogs: bindActionCreators(stepLogsActions, dispatch)
      .setIsGeneratingReport,
  },
});

const connectCreator = connect(() => ({}), mapDispatchToProps);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(GlobalNotifications);
