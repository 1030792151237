import * as React from "react";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { getScriptsTableActions } from "@app/modules/scripts/scripts.actions";
import { SCRIPTS_TABLES_CONFIG, SCRIPTS_COLUMNS_SHORT } from "@app/modules/scripts/scripts.tables";
import {
  scriptsDataSelectors,
  scriptsSelectorsModifier,
} from "@app/modules/scripts/scripts.selectors";
import { ApplicationState } from "@app/modules/app.reducers";
import { connect, ConnectedProps } from "react-redux";
import { RenderProps } from "@ea/shared_components/PanelForm/PanelFormFinal";
import { ColumnConfig } from "@ea/shared_components/Table/common.tables";
import EmptyField from "@ea/shared_components/Form/Fields/EmptyField";
import { disabledFeaturesSelector } from "@app/modules/globalSettings/globalSettings.selectors";
import { overrideColumnProps } from "@ea/shared_components/Table/common.tables";
import { getScriptColumnsRenderers } from "@app/modules/scripts/ScriptColumnsRenderers";
import { projectSelectors } from "../../../../projects/projects.selectors";
import { Script } from "@ea/shared_types/types";
interface IPickScriptFormProps {
  scriptId: number;
}

interface IPickScriptFormState {
  persistentQuery: any;
  columns: ColumnConfig<Script>[];
}

const persistentQuery = (scriptId) => ({
  and: [
    {
      status: {
        neq: "CLOSED",
      },
    },
    { id: { neq: scriptId } },
  ],
});

class PickScriptForm extends React.Component<
  IPickScriptFormProps & IConnectProps,
  IPickScriptFormState
> {
  constructor(props: IPickScriptFormProps & IConnectProps) {
    super(props);
    const scriptRenderers = getScriptColumnsRenderers({ projectsMap: props.projectsMap });
    const columns = overrideColumnProps<Script>(SCRIPTS_COLUMNS_SHORT, [
      {
        dataIndex: "envType",
        frameworkProps: {
          render: (_, record) => scriptRenderers.envTypeRenderer(record),
        },
      },
      {
        dataIndex: "envName",
        frameworkProps: {
          render: (_, record) => scriptRenderers.envNameRenderer(record),
        },
      },
    ]);

    this.state = {
      persistentQuery: {},
      columns,
    };
  }

  isNextEnabled = () => {
    return this.props.selected.length > 0;
  };

  componentDidMount() {
    this.setState({ persistentQuery: persistentQuery(this.props.scriptId) });
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected.length > 0 && prevProps.selected !== this.props.selected) {
      this.props.change(
        "linkedScriptIds",
        this.props.selected.map(({ id }) => ({
          linkedScriptId: id,
        })),
      );
    }
    if (prevProps.scriptId !== this.props.scriptId) {
      this.setState({ persistentQuery: persistentQuery(this.props.scriptId) });
    }
  }

  render() {
    const { disabledFeatures } = this.props;
    const { columns } = this.state;
    return (
      <>
        <EmptyField name="linkedScriptIds" />
        <ConnectedTable
          pageable
          columnsConfig={columns}
          disabledFeatures={disabledFeatures}
          tableId={SCRIPTS_TABLES_CONFIG.STEP_FORM.id()}
          preferencesId={SCRIPTS_TABLES_CONFIG.STEP_FORM.preferencesId}
          stateKey="scripts"
          className="panelTable"
          tableActions={getScriptsTableActions}
          persistentQuery={this.state.persistentQuery}
          modifySelectors={scriptsSelectorsModifier}
          multiselect
        />
      </>
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: RenderProps) => ({
    selected: scriptsDataSelectors.getSelectedItemsSelector(
      state,
      SCRIPTS_TABLES_CONFIG.STEP_FORM.id(),
    ),
    projectsMap: projectSelectors.getDataSelector(state),
    disabledFeatures: disabledFeaturesSelector(state),
    ...props,
  }),
  null,
  null,
  { forwardRef: true },
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(PickScriptForm);
