import { API } from "@app/services/api/api";
import { Log, StepScreenshots } from "@ea/shared_types/types";
import * as React from "react";
import ScreenshotGallery from "./ScreenshotGallery";

interface IShowGalleryContainerProps {
  visible: boolean;
  onClose: () => void;
  selectedLog?: Log;
}

interface IShowGalleryContainerState {
  screenshots: StepScreenshots | null;
}

class ShowGalleryContainer extends React.Component<
  IShowGalleryContainerProps,
  IShowGalleryContainerState
> {
  state = {
    screenshots: null,
  };

  async componentDidUpdate(prevProps) {
    if (prevProps.visible && !this.props.visible) {
      this.setState({
        screenshots: null,
      });
    }

    if (!prevProps.visible && this.props.visible) {
      const { selectedLog } = this.props;
      if (selectedLog) {
        const stepLogs = await API.getStepLogs({
          filter: {
            where: {
              executionId: selectedLog.id,
              screenshotPath: { neq: "" },
            },
          },
        });
        this.setState({
          screenshots: stepLogs.reduce((acc, stepLog, index) => {
            acc[stepLog.id] = {
              path: stepLog.screenshotPath,
              caption: `${stepLog.lineNum}. ${stepLog.label}`,
            };
            return acc;
          }, {}),
        });
      }
    }
  }

  getScreenshots() {
    const { selectedLog } = this.props;

    if (!selectedLog) {
      return [];
    }

    return this.state.screenshots;
  }

  render() {
    const { onClose, visible } = this.props;

    return (
      <ScreenshotGallery onClose={onClose} visible={visible} screenshots={this.getScreenshots()} />
    );
  }
}

export default ShowGalleryContainer;
