import * as React from "react";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import { SelectField } from "@ea/shared_components/Form/Fields/SelectField";
import { FormattedMessage } from "react-intl";
import { FieldArray } from "react-final-form-arrays";
import { API } from "@app/services/api/api";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import { FormApi, getIn } from "final-form";
import { toast } from "react-toastify";
import FormButton from "@ea/shared_components/Form/FormButton";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { Tabs, Spin } from "antd";
import styled from "@emotion/styled";
import { severityOptions, priorityOptions } from "../its.helpers";
import { DeleteOutlined, PlusOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  GetAreasRequest,
  GetIterationsRequest,
  GetProjectsRequest,
  GetWorkItemFieldsRequest,
  GetWorkItemTypesRequest,
} from "@app/services/api/api.models";
import { IssueTrackingToolConnectionParams, ItsConnectionParams } from "@ea/shared_types";
import { DataTestIds } from "@app/utils/dataTestIds";

const { TabPane } = Tabs;
interface ICreateItsFormState {
  projectOptions: OptionType[];
  iterationOptions: { [key: string]: OptionType[] };
  areaOptions: { [key: string]: OptionType[] };
  workItemTypesOptions: OptionType[];
  fieldsOptions: OptionType[];
  activeTab: ItsTabs;
  loading: boolean;
  loadingCustomFields: boolean;
}

interface IItsProps {
  readOnly?: boolean;
  values: any;
  change: (name: string, value: any) => void;
  form: FormApi;
  testPlansIntegration: boolean | null;
}

const SpinContainer = styled.div({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 15,
  marginBottom: 15,
});

type Props = IItsProps;

const renderCustomFields =
  (fieldsOptions, loading) =>
  ({ fields }) => {
    return (
      <>
        <FormButton
          onClick={() => {
            fields.push({});
          }}
          icon={<PlusOutlined />}
          loading={loading}
          disabled={fieldsOptions.length === 0}
          data-testid={DataTestIds.FORM_BUTTON_ADD_CUSTOM_FIELDS}
        >
          <FormattedMessage id="its.addCustomField" />
        </FormButton>
        {fields
          ? fields.map((pathObject, index) => (
              <div key={index}>
                <SelectField
                  loading={loading}
                  name={`customFields[${index}].name`}
                  label="Name"
                  options={fieldsOptions}
                  data-testid={DataTestIds.getCustomFieldTestId("name", index)}
                />
                <InputField
                  name={`customFields[${index}].value`}
                  label="Value"
                  data-testid={DataTestIds.getCustomFieldTestId("value", index)}
                />
                <FormButton
                  loading={loading}
                  onClick={() => {
                    fields.remove(index);
                  }}
                  icon={<DeleteOutlined />}
                  data-testid={DataTestIds.getCustomFieldTestId("delete", index)}
                >
                  <FormattedMessage id="its.removeCustomField" />
                </FormButton>
                {fields.length > 1 && <hr />}
              </div>
            ))
          : null}
      </>
    );
  };

enum ItsTabs {
  ISSUES = "ISSUES",
  TEST_PLANS = "TEST_PLANS",
}

class CreateItsForm extends React.Component<Props, ICreateItsFormState> {
  state = {
    projectOptions: [],
    iterationOptions: { [ItsTabs.ISSUES]: [], [ItsTabs.TEST_PLANS]: [] },
    areaOptions: { [ItsTabs.ISSUES]: [], [ItsTabs.TEST_PLANS]: [] },
    workItemTypesOptions: [],
    fieldsOptions: [],
    activeTab: ItsTabs.ISSUES,
    loading: false,
    loadingCustomFields: false,
  };

  componentDidMount() {
    const { values, testPlansIntegration } = this.props;
    const host = getIn(values, "host");
    const token = getIn(values, "token");
    const project = getIn(values, "project");
    const itsId = getIn(values, "id");
    const testPlansProject = getIn(values, "integrationMetadata.azureDevops.project");

    const params = this.getAzureRequestParams(values);

    if (itsId || (host && token)) {
      this.reloadProjects(params);
    }
    if (project && host && token) {
      this.reloadIterations(params);
      this.reloadAreas(params);
      this.reloadWorkItemTypesOptions(params);
      this.reloadFieldsOptions(params);
    }
    if (testPlansIntegration && testPlansProject && host && token) {
      this.reloadIterations(params, ItsTabs.TEST_PLANS);
      this.reloadAreas(params, ItsTabs.TEST_PLANS);
    }
  }

  reloadProjects = async (projectRequestPrams: GetProjectsRequest) => {
    const { change } = this.props;

    if (!projectRequestPrams) {
      return;
    }

    try {
      this.setState({ loading: true });

      const projects = await API.getItsProjects(projectRequestPrams);
      this.setState({
        projectOptions: projects.map((i) => ({ text: i.name, value: i.name })),
      });
    } catch (error) {
      change("project", undefined);
      console.error(error);
      toast.error(<FormattedMessage id={getTranslationKey("its", "cannotFetchProjects")} />);
      this.setState({ loading: false });
      return;
    }
    this.setState({ loading: false });
  };

  reloadIterations = async (
    iterationRequestParams: GetIterationsRequest,
    tab = this.state.activeTab,
  ) => {
    const { change } = this.props;

    if (!iterationRequestParams) {
      return;
    }
    try {
      this.setState({ loading: true });

      const iterations = await API.getIterations(iterationRequestParams);

      this.setState({
        iterationOptions: {
          ...this.state.iterationOptions,
          [tab]: iterations.map((i) => ({ text: i.path, value: i.path })),
        },
      });
    } catch (error) {
      change("iteration", undefined);
      console.error(error);
      toast.error(<FormattedMessage id={getTranslationKey("its", "cannotFetchIterations")} />);
      this.setState({ loading: false });
      return;
    }
    this.setState({ loading: false });
  };

  reloadAreas = async (areaRequestParams: GetAreasRequest, tab = this.state.activeTab) => {
    const { change } = this.props;

    if (!areaRequestParams) {
      return;
    }
    try {
      this.setState({ loading: true });

      const areas = await API.getAreas(areaRequestParams);

      this.setState({
        areaOptions: {
          ...this.state.areaOptions,
          [tab]: areas.map((i) => ({ text: i.path, value: i.path })),
        },
      });
    } catch (error) {
      change("area", undefined);
      console.error(error);
      toast.error(<FormattedMessage id={getTranslationKey("its", "cannotFetchAreas")} />);
      this.setState({ loading: false });
      return;
    }
    this.setState({ loading: false });
  };

  reloadWorkItemTypesOptions = async (params: GetWorkItemTypesRequest) => {
    const { change } = this.props;

    if (!params) {
      return;
    }
    try {
      this.setState({ loading: true });
      const wiTypes = await API.getWorkItemTypes(params);
      this.setState({
        workItemTypesOptions: wiTypes.map((i) => ({ text: i.name, value: i.name })),
      });
    } catch (error) {
      change("type", undefined);
      console.error(error);
      toast.error(<FormattedMessage id={getTranslationKey("its", "cannotFetchWorkItemTypes")} />);
      this.setState({ loading: false });
      return;
    }
    this.setState({ loading: false });
  };

  reloadFieldsOptions = async (params: GetWorkItemFieldsRequest) => {
    const { change } = this.props;

    if (!params) {
      return;
    }
    try {
      this.setState({ loadingCustomFields: true });
      const wiTypes = await API.getFields(params);
      this.setState({
        fieldsOptions: wiTypes.map((i) => ({ text: i.name, value: i.referenceName })),
      });
    } catch (error) {
      change("type", undefined);
      console.error(error);
      toast.error(<FormattedMessage id={getTranslationKey("its", "cannotFetchWorkItemFields")} />);
      this.setState({ loadingCustomFields: false });
      return;
    }
    this.setState({ loadingCustomFields: false });
  };

  onProjectChange = async (project) => {
    const { values, change } = this.props;
    const host = getIn(values, "host");
    const token = getIn(values, "token");
    const itsId = getIn(this.props.values, "id");

    change("iteration", undefined);
    change("area", undefined);
    if ((project && host && token) || (project && itsId)) {
      const params = this.getAzureRequestParams(values, project);
      await this.reloadIterations(params);
      await this.reloadAreas(params);
      await this.reloadWorkItemTypesOptions(params);
      await this.reloadFieldsOptions(params);
    }
  };

  onTestPlansProjectChange = async (project) => {
    const { values, change } = this.props;
    const host = getIn(values, "host");
    const token = getIn(values, "token");
    const itsId = getIn(this.props.values, "id");

    change("integrationMetadata.azureDevops.iteration", undefined);
    change("integrationMetadata.azureDevops.area", undefined);
    if ((project && host && token) || (project && itsId)) {
      const params = this.getAzureRequestParams(values, project);
      await this.reloadIterations(params);
      await this.reloadAreas(params);
    }
  };

  onTabChange = (activeTab) => {
    this.setState({ activeTab });
  };

  getAzureRequestParams = (
    formValues,
    specificProject?,
  ): IssueTrackingToolConnectionParams | ItsConnectionParams => {
    const { form } = this.props;

    const host = getIn(formValues, "host");
    const token = getIn(formValues, "token");
    const itsId = getIn(formValues, "id");
    const project = specificProject || getIn(formValues, "project");

    const hasTokenBeenModified = form.getFieldState("token");

    if (itsId && project && !hasTokenBeenModified) {
      return { itsId, project };
    }

    return project ? { project, host, token } : { host, token };
  };

  render() {
    const { readOnly, testPlansIntegration, values } = this.props;
    const {
      loadingCustomFields,
      fieldsOptions,
      areaOptions,
      iterationOptions,
      projectOptions,
      loading,
      workItemTypesOptions,
    } = this.state;
    const host = getIn(values, "host");
    const token = getIn(values, "token");
    const itsId = getIn(values, "id");
    return (
      <>
        <InputField
          name="name"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("common", "placeholder", "name")}
          label={getTranslationKey("common", "label", "name")}
          data-testid={DataTestIds.FORM_INPUT_NAME}
        />
        <InputField
          name="host"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("its", "placeholder", "host")}
          label={getTranslationKey("its", "label", "host")}
          data-testid={DataTestIds.FORM_INPUT_HOST}
        />
        <InputField
          name="token"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("its", "placeholder", "token")}
          label={getTranslationKey("its", "label", "token")}
          type="password"
          data-testid={DataTestIds.FORM_INPUT_TOKEN}
        />
        <FormButton
          onClick={() => {
            if (itsId || (host && token)) {
              const params = this.getAzureRequestParams(values);
              this.reloadProjects(params);
            }
          }}
          icon={<DownloadOutlined />}
          loading={loading}
          disabled={!(host && token) || loading}
          data-testid={DataTestIds.FORM_BUTTON_FETCH_PROJECTS}
        >
          <FormattedMessage id="its.fetchProjects" />
        </FormButton>

        <Tabs defaultActiveKey={ItsTabs.ISSUES} onChange={this.onTabChange}>
          <TabPane
            disabled={loading}
            tab={<FormattedMessage id={getTranslationKey("its", "label", "issueTracking")} />}
            key={ItsTabs.ISSUES}
          >
            <SelectField
              name="project"
              required
              readOnly={readOnly}
              placeholder={getTranslationKey("common", "placeholder", "project")}
              label={getTranslationKey("common", "label", "project")}
              options={projectOptions}
              disabled={projectOptions.length === 0}
              onChange={this.onProjectChange}
              loading={loading}
              data-testid={DataTestIds.FORM_SELECT_PROJECT}
            />
            <SelectField
              name="iteration"
              required
              placeholder={getTranslationKey("its", "placeholder", "defaultIteration")}
              label={getTranslationKey("its", "label", "defaultIteration")}
              options={iterationOptions[ItsTabs.ISSUES]}
              disabled={iterationOptions[ItsTabs.ISSUES].length === 0}
              loading={loading}
              data-testid={DataTestIds.FORM_SELECT_ITERATION}
            />
            <SelectField
              name="area"
              placeholder={getTranslationKey("its", "placeholder", "defaultArea")}
              label={getTranslationKey("its", "label", "defaultArea")}
              options={areaOptions[ItsTabs.ISSUES]}
              disabled={areaOptions[ItsTabs.ISSUES].length === 0}
              loading={loading}
              data-testid={DataTestIds.FORM_SELECT_AREA}
            />
            <SelectField
              name="type"
              required
              placeholder={getTranslationKey("its", "placeholder", "defaultWorkItemType")}
              label={getTranslationKey("its", "label", "defaultWorkItemType")}
              options={workItemTypesOptions}
              disabled={workItemTypesOptions.length === 0}
              loading={loading}
              data-testid={DataTestIds.FORM_SELECT_WORKITEM_TYPE}
            />
            <SelectField
              name="priority"
              placeholder={getTranslationKey("its", "placeholder", "defaultPriority")}
              required
              label={getTranslationKey("its", "label", "defaultPriority")}
              options={priorityOptions}
              data-testid={DataTestIds.FORM_SELECT_DEFAULT_PRIORITY}
            />
            <SelectField
              name="severity"
              placeholder={getTranslationKey("its", "placeholder", "defaultSeverity")}
              required
              label={getTranslationKey("its", "label", "defaultSeverity")}
              options={severityOptions}
              data-testid={DataTestIds.FORM_SELECT_DEFAULT_SEVERITY}
            />
            {loadingCustomFields ? (
              <SpinContainer>
                <Spin
                  tip={
                    (
                      <FormattedMessage id={getTranslationKey("its", "loadingCustomFields")} />
                    ) as any
                  }
                />
              </SpinContainer>
            ) : (
              <FieldArray
                name={"customFields"}
                render={renderCustomFields(fieldsOptions, loadingCustomFields)}
              />
            )}
          </TabPane>
          {testPlansIntegration && (
            <TabPane
              tab={<FormattedMessage id={getTranslationKey("its", "label", "testPlans")} />}
              disabled={loading}
              key={ItsTabs.TEST_PLANS}
            >
              <SelectField
                name="integrationMetadata.azureDevops.project"
                required
                readOnly={readOnly}
                placeholder={getTranslationKey("common", "placeholder", "project")}
                label={getTranslationKey("common", "label", "project")}
                options={projectOptions}
                disabled={projectOptions.length === 0}
                onChange={this.onTestPlansProjectChange}
                loading={loading}
                data-testid={DataTestIds.FORM_SELECT_DEVOPS_PROJECT}
              />
              <SelectField
                name="integrationMetadata.azureDevops.iteration"
                required
                placeholder={getTranslationKey("its", "placeholder", "defaultIteration")}
                label={getTranslationKey("its", "label", "defaultIteration")}
                options={iterationOptions[ItsTabs.TEST_PLANS]}
                disabled={iterationOptions[ItsTabs.TEST_PLANS].length === 0}
                loading={loading}
                data-testid={DataTestIds.FORM_SELECT_DEVOPS_ITERATION}
              />
              <SelectField
                name="integrationMetadata.azureDevops.area"
                placeholder={getTranslationKey("its", "placeholder", "defaultArea")}
                label={getTranslationKey("its", "label", "defaultArea")}
                options={areaOptions[ItsTabs.TEST_PLANS]}
                disabled={areaOptions[ItsTabs.TEST_PLANS].length === 0}
                loading={loading}
                data-testid={DataTestIds.FORM_SELECT_DEVOPS_AREA}
              />
              <SelectField
                name="integrationMetadata.azureDevops.priority"
                placeholder={getTranslationKey("its", "placeholder", "defaultPriority")}
                required
                label={getTranslationKey("its", "label", "defaultPriority")}
                options={priorityOptions}
                data-testid={DataTestIds.FORM_SELECT_DEVOPS_PRIORITY}
              />
            </TabPane>
          )}
        </Tabs>
      </>
    );
  }
}

export default CreateItsForm;
