import * as PropTypes from "prop-types";
import * as React from "react";
import { RouterChildContext } from "react-router";
import CreateSystemForm from "@app/modules/systemDictionary/components/CreateSystemForm";
import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { System } from "@ea/shared_types/types";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { getValidatedPlatforms } from "@app/packs/packs.helpers";

interface CreateEditSystemContainerProps {
  visibility: boolean;
  onClose: () => void;
  onCreate: (system: System) => void;
  selected?: System;
  isEdit?: boolean;
}

class CreateEditSystemContainer extends React.Component<CreateEditSystemContainerProps> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  context: RouterChildContext<any>;

  onOk = async (values) => {
    const { isEdit, onCreate, onClose } = this.props;

    const [platform] = getValidatedPlatforms(values.systemPlatform);

    values.meta = {
      ...platform.systemExtension?.getDefaultMeta?.(),
      ...values.meta,
    };

    if (isEdit) {
      await API.editSystem(values);
    } else {
      const result = await API.createSystem(values);

      if (onCreate) {
        onCreate(result);
      }
    }

    onClose();
  };

  render() {
    const { visibility, isEdit, onClose, selected } = this.props;

    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.MEDIUM}
        headerText={getTranslationKey("systemDictionary", "header", isEdit ? "edit" : "create")}
        onCancelClick={onClose}
        onOkClick={this.onOk}
        key={isEdit ? "Edit" : "Create"}
        cancelButtonText={getTranslationKey("button", "cancel")}
        okButtonText={getTranslationKey("button", isEdit ? "save" : "create")}
        initialValues={isEdit ? selected : undefined}
        render={({ values }) => <CreateSystemForm values={values} />}
      />
    );
  }
}

export default CreateEditSystemContainer;
