import { createDataSelectors } from "@app/modules/app.reducers";
import { System } from "@ea/shared_types/types";
import { createSelector } from "reselect";

export const systemDictionaryDataSelectors = createDataSelectors<System>()("systemDictionary");

export const getSystemFormOptions = createSelector(
  systemDictionaryDataSelectors.getOrderedDataSelector,
  (systems) =>
    Object.keys(systems).map((key) => ({
      text: `[${systems[key].id}] - ${systems[key].envName}`,
      value: systems[key].id,
      address: systems[key].envAddress,
    })),
);
