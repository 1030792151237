"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.streamPromise = exports.IsJsonString = void 0;
var IsJsonString = function (str) {
    try {
        JSON.parse(str);
    }
    catch (e) {
        return false;
    }
    return true;
};
exports.IsJsonString = IsJsonString;
var streamPromise = function (stream) {
    return new Promise(function (resolve, reject) {
        stream.on("finish", function () {
            resolve("finish");
        });
        stream.on("error", function (error) {
            reject(error);
        });
    });
};
exports.streamPromise = streamPromise;
