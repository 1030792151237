import React from "react";
import { getIn } from "final-form";

import { scriptNameToLinkName } from "@ea/shared_components/utils/formatters";
import { formatScriptName } from "@app/utils/script";

import EmptyField from "@ea/shared_components/Form/Fields/EmptyField";
import { InputField } from "@ea/shared_components/Form/Fields/InputField";
import { InputNumberField } from "@ea/shared_components/Form/Fields/InputNumberField";
import validators, { composeValidators } from "@ea/shared_components/Form/validators";
import { getTranslationKey } from "@app/translations/translations.helpers";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import { SchedulerJobRunMode } from "@ea/shared_types/types";
import { DataTestIds } from "@app/utils/dataTestIds";

export const getSchedulerEditSelectedColumns = ({ scripts, values }, intl) => {
  const { whiteSpaceAndSpecialCharValidator, mustStartWithLetter } = validators(intl);

  return [
    {
      title: "",
      dataIndex: "taskScriptId",
      width: 0,
      key: "taskScriptId",
      render: (_, fieldName) => {
        const taskScriptId = getIn(values, `${fieldName}.taskScriptId`);

        return <EmptyField name={`${fieldName}.taskScriptId`} defaultValue={taskScriptId} />;
      },
    },
    {
      title: "",
      dataIndex: "projectId",
      width: 0,
      key: "projectId",
      render: (_, fieldName) => {
        const projectId = getIn(values, `${fieldName}.projectId`);

        return <EmptyField name={`${fieldName}.projectId`} defaultValue={projectId} />;
      },
    },
    {
      title: "",
      dataIndex: "virtualUserId",
      width: 0,
      key: "virtualUserId",
      render: (_, fieldName) => {
        const projectId = getIn(values, `${fieldName}.virtualUserId`);

        return <EmptyField name={`${fieldName}.virtualUserId`} defaultValue={projectId} />;
      },
    },
    {
      title: getTranslationKey("scheduler", "flow", "addPanel", "scriptName"),
      dataIndex: "name",
      key: "name",
      required: true,
      render: (_, fieldName) => {
        const taskScriptId = getIn(values, `${fieldName}.taskScriptId`);
        const script = scripts[`${taskScriptId}`];

        const defaultLabel = script ? formatScriptName(scripts[`${taskScriptId}`]) : "";
        return (
          <>
            <InputField
              name={`${fieldName}.name`}
              defaultValue={defaultLabel}
              required
              readOnly
              data-testid={DataTestIds.FORM_INPUT_NAME}
            />
          </>
        );
      },
    },
    {
      title: getTranslationKey("scheduler", "flow", "addPanel", "scriptLabel"),
      dataIndex: "scriptLabel",
      key: "scriptLabel",
      required: true,
      render: (_, fieldName) => {
        const taskScriptId = getIn(values, `${fieldName}.taskScriptId`);
        const script = scripts[`${taskScriptId}`];
        const defaultLinkName = script ? scriptNameToLinkName(formatScriptName(script)) : "";
        return (
          <InputField
            name={`${fieldName}.scriptLabel`}
            defaultValue={defaultLinkName}
            required={true}
            validate={composeValidators(whiteSpaceAndSpecialCharValidator, mustStartWithLetter)}
            data-testid={DataTestIds.FORM_INPUT_LABEL}
          />
        );
      },
    },
    {
      title: getTranslationKey("common", "label", "runMode"),
      dataIndex: "runParams.repeater.repeatType",
      key: "runParams.repeater.repeatType",
      required: true,
      render: (_, fieldName) => {
        return (
          <SelectField
            name={`${fieldName}.runParams.repeater.repeaterType`}
            options={[
              {
                text: getTranslationKey("scheduler", "runMode", "sequential"),
                value: SchedulerJobRunMode.SEQUENTIAL,
              },
              {
                text: getTranslationKey("scheduler", "runMode", "parallel"),
                value: SchedulerJobRunMode.PARALLEL,
              },
            ]}
            allowClear
            data-testid={DataTestIds.FORM_SELECT_REPEATER_TYPE}
          />
        );
      },
    },
    {
      title: getTranslationKey("scheduler", "flow", "addPanel", "repeatCount"),
      dataIndex: "runParams.repeater.repeaterMultiplier",
      key: "runParams.repeater.repeaterMultiplier",
      required: true,
      render: (_, fieldName) => {
        const repeaterType = getIn(values, `${fieldName}.runParams.repeater.repeaterType`);

        return (
          repeaterType && (
            <InputNumberField
              name={`${fieldName}.runParams.repeater.repeaterMultiplier`}
              step={1}
              max={25}
              min={1}
              required
              data-testid={DataTestIds.FORM_INPUT_REPEATER_MULTIPLIER}
            />
          )
        );
      },
    },
  ];
};
