import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { IssueTrackingTool } from "@ea/shared_types/types";
import * as React from "react";
import { GetAsTime } from "@app/modules/common/time";
import { getTranslationKey } from "@app/translations/translations.helpers";

import ApiKeyTokenBox from "@app/modules/common/ApiKeyTokenBox";

export const ITS_COLUMNS_CONFIG: PlainObject<CustomColumn<IssueTrackingTool>> = {
  name: {
    label: getTranslationKey("table", "name"),
    dataIndex: "name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  token: {
    label: getTranslationKey("table", "token"),
    dataIndex: "token",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    render: (text) => <ApiKeyTokenBox token={text} />,
  },
  host: {
    label: getTranslationKey("table", "host"),
    dataIndex: "host",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  project: {
    label: getTranslationKey("table", "project"),
    dataIndex: "project",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  createdAt: {
    label: getTranslationKey("table", "createdAt"),
    dataIndex: "createdAt",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "createdAt" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
};

export const ITS_COLUMNS: ColumnConfig<IssueTrackingTool>[] = [
  {
    props: ITS_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: ITS_COLUMNS_CONFIG.host,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: ITS_COLUMNS_CONFIG.project,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: ITS_COLUMNS_CONFIG.token,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: ITS_COLUMNS_CONFIG.createdAt,
    frameworkProps: {
      width: "20%",
    },
  },
];

export const ITS_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_ITS",
  },
};
