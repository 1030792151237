import "./index.css";
import * as React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import { getVersionInfo } from "./utils/version";
import { loadImages } from "./styles/styles";
import "./translations/backendTranslations";

const rootEl = document.getElementById("root");

async function start() {
  console.warn(getVersionInfo());
  await loadImages();
  ReactDOM.render(
    <div>
      <Root />
    </div>,
    rootEl,
  );
}

start();
// service worker caches main HTML file and currently it's impossible to load another site
// using window.open and url:localhost:port/proxy...
// it can be reenabled when we change our mechanism of redirecting from window.open to fetch
//
// tldr: it breaks Play / Record functionality
// registerServiceWorker();
