import "@ea/shared_types/api.types";
import { CreateOptions } from "@ea/shared_types/api.types";
import { FORMDATA_FIELD_NAMES } from "@ea/shared_types/next/ea.consts";
import { EndpointType, getEndpoint } from "@ea/shared_types/next/ea.endpoints";
import { StartForegroundSessionParams } from "@ea/shared_types/types";
import { endpointsCreator } from "../utils/endpointCreator";

const apiCreator = endpointsCreator("api");

const createEndpoint = <T extends EndpointType>(
  endpoint: T,
  options?: Partial<
    {
      pathCreator?: (path: string, params: T["requestType"] & { id?: number }) => string;
    } & CreateOptions<T["requestType"]>
  >,
) => {
  const { pathCreator, ...rest } = options || {};
  const isDefined = Object.keys(rest).length;
  return apiCreator<T["requestType"], T["responseType"]>(
    endpoint.method,
    pathCreator ? (params) => pathCreator(endpoint.path, params) : endpoint.path,
    isDefined ? rest : undefined,
  );
};

// TODO: refactor and remove edit user request
export const CommonAPI = {
  startSession: apiCreator<StartForegroundSessionParams, { url: string }>("POST", "runner/start"),
  editUser: apiCreator<any, any>("PATCH", (params) => `users/${params.id}`),
  getUsers: apiCreator<any, any>("GET", "users"),
  downloadFile: apiCreator<{ filePath: string }, any>("POST", `files/download-file`, {
    fileRequest: true,
  }),
  downloadStepFile: createEndpoint(getEndpoint("steps.sub.downloadFile"), {
    pathCreator: (path, params) => path.replace(":id", `${params.stepId}`),
    fileRequest: true,
    defaultContentType: false,
  }),
  uploadTemporaryFile: createEndpoint(getEndpoint("steps.sub.uploadTemporaryFile"), {
    defaultContentType: false,
    bodyBuilder: (params) => {
      const formData = new FormData();
      if (params && params.files) {
        params.files.forEach((file) =>
          formData.append(FORMDATA_FIELD_NAMES.stepFiles, file, file.name),
        );
      }
      return formData;
    },
  }),
};
