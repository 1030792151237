declare global {
  var EA_INTERNAL: EA_INTERNAL;
  namespace NodeJS {
    interface Global {
      EA_INTERNAL: EA_INTERNAL;
    }
  }
  interface Window {
    EA_INTERNAL: EA_INTERNAL;
  }
}

import {
  CommandResult,
  ExecutionApi,
  ScriptData,
  UsedVariable,
} from "@ea/shared_types/runner.common.types";
import {
  AdditionalJobParams,
  CHECK_REFERENCE_TYPE,
  CodeTemplateWithGroup,
  CodeValue,
  EAFile,
  ExternalScript,
  GlobalVariable,
  Kpi,
  LabelParams,
  Message,
  PlainObject,
  PlatfromElementMetadata,
  PlayerStep,
  Project,
  RecorderStep,
  ResolversData,
  RunnerClientSessionData,
  RunnerMode,
  Script,
  Step,
  System,
  Variable,
  VariablesGroup,
  VirtualUser,
  XPATH_SEARCH_TYPE,
} from "@ea/shared_types/types";

type EA_INTERNAL = {
  currentPlatform: Platform | undefined;
  defaultPlatform: Platform;
  platforms: (Platform | PlatformWeb | PlatformServer)[];
  events: {
    type: (element: HTMLElement, text: string) => void;
    click: (element: HTMLElement) => void;
    fullClick: (element: HTMLElement) => void;
  };
  rules: {
    packs: Pack<RulesPack>;
  };
  commands: {
    packs: Pack<CommandPack>;
    api: CommandsAPI;
    translations: any;
  };
  lookForFunction?: (entryObject: object, functionNamePattern: string) => string[];
};

export function initDefaults() {
  if (typeof EA_INTERNAL === "undefined") {
    const defaultValue = {
      platforms: [],
      rules: {
        packs: {},
      },
      commands: {
        packs: {},
        api: {},
        translations: {},
      },
    };

    if (typeof window !== "undefined") {
      // @ts-ignore
      window.EA_INTERNAL = defaultValue;
    }

    if (typeof global !== "undefined") {
      // @ts-ignore
      global.EA_INTERNAL = defaultValue;
    }
  }
}

export type CommandsRunnerAPI = {};
export type CommandsWebAPI = {};
export type CommandsServerAPI = {};

export type CommandsAPI = CommandsRunnerAPI | CommandsWebAPI | CommandsServerAPI;

export type CommandDefinitionBaseType = { value: any; id: any; version: any };

export type CommandDefinitionBase<K extends CommandDefinitionBaseType = CommandDefinitionBaseType> =
  {
    id: K["id"];
    // TODO: IT should be required but then we need to define it in every command creation in commands.base files
    // figure out how to do it
    platformId?: string;
    // Mark this option if you don't want to log a command value to the logs
    isSensitiveData?: boolean;
    // Implement getInitialValue if there is a possibility to create a command entity from a Panel
    getInitialValue?: (data: {
      project: Project;
      script: Script;
    }) => Partial<K["value"]> | undefined;
    getCodeValues?: (value: K["value"]) => CodeValue[];
    getLabel?: (step: Step<K>) => { label: string; labelKey: string };
    getLabelParams?: LabelParamsFunction<K>;
  };

export type ExecutionFunction<K extends CommandDefinitionBaseType = CommandDefinitionBaseType> = (
  executionApi: ExecutionApi,
  step: PlayerStep<K>,
  variableCalculator: {
    calculateValue: (code: CodeValue, usedVariablesIds: UsedVariable[], obfuscate?: boolean) => any;
    calculateVariable: (
      variableId: UsedVariable,
      code: CodeValue,
      usedVariablesIds: UsedVariable[],
    ) => any;
  },
  sessionData: RunnerClientSessionData,
  scriptData: ScriptData,
) => Promise<CommandResult | void>;

export type VersionedExecutionFunction<
  K extends CommandDefinitionBaseType = CommandDefinitionBaseType,
> = {
  from: string;
  to: string;
  execute: ExecutionFunction<K>;
};

export type LabelParamsFunction<K extends CommandDefinitionBaseType = CommandDefinitionBaseType> = (
  step: PlayerStep<K> | RecorderStep<K> | Step<K>,
) => LabelParams; // TODO can we make it specific?

export type CommandDefinitionRunner<
  K extends CommandDefinitionBaseType = CommandDefinitionBaseType,
> = {
  execute: ExecutionFunction<K> | VersionedExecutionFunction<K>[];
} & CommandDefinitionBase<K>;

export type FormChunkApi = {
  system?: System;
  virtualUser?: VirtualUser;
  steps: Step[];
  script: Script;
  openLinked: () => void;
  disableOpenLinkedScript?: boolean;
  linkedScript?: Script;
  variables: Variable[];
  globalMutables?: GlobalVariable[];
  globalConstants?: GlobalVariable[];
  values: any;
  change: any;
  virtualUsers?: VirtualUser[];
  systems?: System[];
  variablesGroups: VariablesGroup[];
  codeTemplates?: CodeTemplateWithGroup[];
  kpis?: Kpi[];
};

export type CommandDefinitionWeb<K extends CommandDefinitionBaseType = CommandDefinitionBaseType> =
  {
    createableManually?: {
      text: string;
      icon: any;
    };
    disableQuickEdit?: boolean;
    getFormChunk: (api: FormChunkApi) => any; // reactnode
    validate?: (step: Step<K>) => any | Promise<any>; // WRITE BETTER TYPES, CAST ENTIRE OBJECT TO [KEY]: STRING
  } & CommandDefinitionBase<K>;

export type CommandDefinitionServer<
  K extends CommandDefinitionBaseType = CommandDefinitionBaseType,
  Z extends string = any,
> = {
  getMigrations: () => Record<Z, (steps: Step<K>[]) => Promise<Step<K>[]>>;
  onExport?: (step: Step<K>, destinationProject?: Project) => Step;
  includeInScriptTemplate?: boolean;
  onExportFilePack?: (step: Step<K>, zipFolder: any) => Promise<any>;
  getFiles?: (step: Step<K>) => EAFile[];
  onClone?: (
    step: Step<K>,
    maps: {
      variablesMap: { [key: number]: number };
      stepsMap?: { [key: number]: number };
      scriptsMap?: { sourceId: number; destId };
      globalVariablesMap?: { [key: number]: number };
    },
    config: { fileDest: string; fileSrc: string },
    utils: { addFileNameQuantifier: (fileName: string) => string },
    destinationProject?: Project,
  ) => Promise<Step | void> | Step;
  checkReferences?: (
    step: Step<K>,
    toCheck: {
      variableId?: number;
      stepId?: number;
      sheetNames?: string[];
      globalVariableId?: number;
      systemDefinitionId?: number;
      virtualUserId?: number;
    },
  ) => {
    [CHECK_REFERENCE_TYPE.STEP_REFERENCE]: boolean;
    [CHECK_REFERENCE_TYPE.VARIABLE_REFERENCE]: boolean;
    [CHECK_REFERENCE_TYPE.GLOBALVARIABLE_REFERENCE]: boolean;
    [CHECK_REFERENCE_TYPE.DATASOURCE_REFERENCE]: boolean;
    [CHECK_REFERENCE_TYPE.VIRTUAL_USER_REFERENCE]: boolean;
    [CHECK_REFERENCE_TYPE.SYSTEM_DEFINITION_REFERENCE]: boolean;
  };
} & CommandDefinitionBase<K>;

type Pack<T> = {
  [platformId: string]: T;
};

export type RulesPack = {
  version: string;
  id: string;
  rules: Rule[];
};

export type CommandPack<T extends CommandDefinitionBase = CommandDefinitionBase> = {
  [commandId: string]: T;
};

export type PlatformAPIWebType = {
  getFriendlyName: () => string;
  getSystemFormChunk: (props: any) => any;
  getVirtualUserFormChunk: (props: any) => any;
  getVirtualUserInitData: (defaultInitData) => any;
};

export type ScreenshotParams = {
  clipToBody: boolean;
};

export type LoadResolvers<T = unknown> = () => T;

export type PlatformAPIType<T extends PlatformTypes = any> = {
  loadResolvers?: LoadResolvers<T["resolvers"]>;
  validateVersionRange: (versionedExecution: VersionedExecutionFunction) => boolean;
  waitForProcessing: any;
  onStartRecording?: (sessionData: RunnerClientSessionData) => Promise<void>;
  getElementLabel?: (element: HTMLElement) => string | undefined;
  onStopRecording?: (sessionData: RunnerClientSessionData) => Promise<void>;
  isReady: (
    step: Step,
    logMessages: (messages: Message[]) => void,
    sessionData: RunnerClientSessionData,
  ) => Promise<void>;
  init?: (mode: RunnerMode) => Promise<void>;
  playerInit?: any;
  systemInit?: any;
  analyze?: (step: Step) => Promise<any>;
  beforeExecution?: (step: Step, scriptData: ScriptData) => Promise<void>;
  getElementsByXPath?: (
    xpath: string,
    htmlDocument: Document,
    searchType: XPATH_SEARCH_TYPE,
  ) => Node[];
  getScreenshotParams?: () => ScreenshotParams;
  removeHiddenElementsOnInspect?: (element: HTMLElement) => void;
  createMessagesWatcher?: (onMessages: (messages: Message[]) => void) => void;
  generateSmartId?: (id: string, tag: string) => string | undefined;
  auth?: (virtualUser: Partial<VirtualUser>, isAutenthicated: boolean) => Promise<boolean>;
  blockEvents?: () => boolean;
  blockBasicPlayerStepsUpdate?: () => boolean;
  processXpath?: (xpath: string) => string;
  getThrottling?: () => { afterExecution: number; beforeExecution: number };
  getCustomInspectElementValue?: (
    element: HTMLElement,
    defaultValue?: string,
  ) => string | undefined;
  validateGetElement?: (element: HTMLElement) => boolean;
  getElementMetadata?: (element: EventTarget) => PlatfromElementMetadata;
  getEventsToBlockOnInspect?: () => string[];
  getCustomEventsToRecord?: () => string[];
};

export type PlatformTypes<T = unknown> = {
  resolvers: T;
};

export type Platform<T extends PlatformTypes = any> = {
  id: string;
  condition: () => boolean;
  version: () => string;
  api: PlatformAPIType<T>;
};

type EAPlatform = Platform;

export type PlatformWeb<T extends {} = {}> = {
  id: string;
  getFriendlyName: () => string;
  virtualUserExtension?: {
    getVirtualUserFormChunk: (props: any) => any;
    getVirtualUserInitData: (defaultInitData) => any;
  };
  systemExtension?: {
    getDefaultMeta: () => T;
    getSystemFormChunk: (props: any) => any;
  };
};

type ServerAPI = any;

export type PlatformServer = {
  id: string;
  api: {
    getAdditionalStartParam?: () => AdditionalJobParams;
    getTranslations?: () => any;
    getScript: ({ customUrl }: { customUrl?: string }) => ExternalScript;
    getVariables: () => PlainObject<{
      value: any;
      name: string;
    }>;
    createPlayerPlatformEndpoints?: (
      creator: (
        type: "get" | "post",
        platformId: string,
        name: string,
        handler: (cache: any) => (params: any) => Promise<any>,
      ) => void,
      serverAPI: ServerAPI,
    ) => void;
  };
};

export const DEFAULT_PLATFORM_ID = "DEFAULT_PLATFORM";

type ParseFunction<T extends CommandDefinitionBaseType, P extends PlatformTypes = any> = (
  evt: Event,
  resolvers: P["resolvers"],
) => Partial<RecorderStep<T>> &
  Pick<
    RecorderStep<T>,
    "label" | "value" | "commandId" | "structureVersion" | "paths" | "labelKey"
  >;

export type RuleCondition = (evt: Event) => boolean;
export type RuleParse<
  T extends CommandDefinitionBaseType | undefined = any,
  P extends PlatformTypes = any,
> = [T] extends [CommandDefinitionBaseType]
  ? ParseFunction<T, P>
  : (evt: Event, resolvers: P["resolvers"]) => void;
export type NormalizeFunction = (steps: RecorderStep[], newStep: RecorderStep) => RecorderStep[];

export type Rule<
  T extends CommandDefinitionBaseType | undefined = any,
  P extends PlatformTypes = any,
> = {
  condition: RuleCondition;
  getResolversData?: (resolvers: P["resolvers"], element: HTMLElement) => ResolversData;
  parse: RuleParse<T, P>;
  normalize?: NormalizeFunction;
  priority?: number;
  name?: string;
  id?: string;
};

initDefaults();
