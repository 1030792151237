import * as React from "react";
import { InputField, AssignVariableWithGlobalsPicker } from "@ea/shared_components";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { Variable, Script, Step, GlobalVariableType } from "@ea/shared_types/types";
import AssertActionPicker from "@app/modules/steps/components/Forms/Assert/AssertActionPicker";
import { CheckboxField } from "@ea/shared_components/Form/Fields/CheckboxField";
import { FormHeader } from "@ea/shared_components/Form/CommonFormBlocks";
import { getIn } from "final-form";
import { FormattedMessage } from "react-intl";
import { LayoutContext } from "@ea/shared_components/Form/FormLayoutContext";
import { Row, Col, Input } from "antd";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faEquals } from "@fortawesome/fontawesome-free-solid";
import { HeaderText } from "@ea/shared_components/Form/Fields/FormHeader";
interface IInspectFormProps {
  readOnly?: boolean;
  change: (name: string, value: any) => void;
  values: any;
  variables: Variable[];
  script: Script;
  steps: Step[];
}

const conditionStepPrefix = "conditionStep";
const conditionPrefix = "condition";

function InspectForm(props: IInspectFormProps) {
  const { values, variables, change, script, steps } = props;
  const globalMutables = (script.globalVariables || []).filter(
    (gv) => gv.type === GlobalVariableType.MUTABLE,
  );
  return (
    <>
      <InputField
        name="label"
        placeholder={getTranslationKey("runner", "inspect", "stepLabelPlaceholder")}
        required
        label={getTranslationKey("runner", "inspect", "stepLabel")}
      />
      <AssignVariableWithGlobalsPicker
        variables={variables}
        values={values}
        change={change}
        globalMutables={globalMutables}
        name="value.code.assignedVariableId"
        label={getTranslationKey("runner", "inspect", "variableName")}
      />

      <CheckboxField
        label={getTranslationKey("step", "createStep", "conditional", "checkbox")}
        name={`${conditionStepPrefix}.createConditionalStep`}
      />

      <br />
      {getIn(values, `${conditionStepPrefix}.createConditionalStep`) ? (
        <>
          <FormHeader
            text={
              <FormattedMessage
                id={`${getTranslationKey("step", "createStep", "conditional", "title")}`}
              />
            }
          />
          <InputField
            name={`${conditionStepPrefix}.label`}
            placeholder={getTranslationKey("runner", "inspect", "stepLabelPlaceholder")}
            required
            label={getTranslationKey("step", "createStep", "label", "label")}
          />

          <LayoutContext.Consumer>
            {() => (
              <Row>
                <Col span={8}>
                  <HeaderText style={{ display: "inline-block", textAlign: "left" }}>
                    <>
                      <FormattedMessage id={getTranslationKey("step", "assert", "header", "if")} />
                      &nbsp;
                      <FormattedMessage
                        id={getTranslationKey(
                          "step",
                          "createStep",
                          "conditional",
                          "inspectCondition",
                        )}
                      />
                    </>
                  </HeaderText>
                  <FontAwesomeIcon icon={faEquals} />
                </Col>
                <Col span={15} style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    disabled
                    readOnly
                    value={getIn(values, `value.originalValue`)}
                    name={`value.originalValue`}
                  />
                </Col>
              </Row>
            )}
          </LayoutContext.Consumer>
          <AssertActionPicker
            steps={steps}
            values={values}
            script={script}
            change={change}
            prefix={`${conditionStepPrefix}.${conditionPrefix}.ifAction`}
          />
          <FormHeader
            text={<FormattedMessage id={getTranslationKey("step", "assert", "header", "else")} />}
          />
          <AssertActionPicker
            steps={steps}
            values={values}
            script={script}
            change={change}
            prefix={`${conditionStepPrefix}.${conditionPrefix}.elseAction`}
          />
        </>
      ) : null}
    </>
  );
}

export default InspectForm;
