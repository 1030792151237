import { ROUTES } from "@app/routes";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import delay from "@ea/shared_components/utils/delay";
import {
  ErrorCodes,
  RUNNER_OPEN_MODE,
  RUNNER_TYPE,
  StartBackgroundSessionParams,
  StartForegroundSessionParams,
} from "@ea/shared_types/types";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Zoom, toast } from "react-toastify";
import { openSessions } from "../utils/openSessions";
import history from "./history";

export async function startSession(
  scriptName: string,
  params: StartForegroundSessionParams | StartBackgroundSessionParams,
) {
  return params.isBackground
    ? await startBackgroundSession(scriptName, params)
    : await startForegroundSession(params);
}

async function startForegroundSession(params: Partial<StartForegroundSessionParams>) {
  try {
    const { url, id, error } = await API.startSession(params as any);
    if (!url) {
      toast.error(
        <FormattedMessage
          id={getTranslationKey(
            "messages",
            "error",
            error?.code === ErrorCodes.SESSIONS_LIMIT_REACHED
              ? "sessionLimitReached"
              : "sessionStart",
          )}
        />,
      );
      return;
    }

    if (params?.runner?.type === RUNNER_TYPE.ADVANCED) {
      if (params.runner.openMode === RUNNER_OPEN_MODE.NEW_WINDOW || params.isCtrlEvt) {
        const { origin } = window.location;
        const newWindow = window.open(
          `${origin}${ROUTES.runner}?sessionId=${id}&position=${params.runner.position}`,
        );
        if (newWindow) {
          openSessions.push({ w: newWindow, sessionId: id });
          (newWindow as any).runnerParams = params.runner;
        }
        return;
      }
      history.push({
        pathname: ROUTES.runner,
        search: `?sessionId=${id}`,
        state: { opener: window.location.pathname, runnerParams: params.runner },
      });
    } else {
      const w = window.open(url);
      openSessions.push({ w: w!, sessionId: id });
    }
  } catch (e) {
    console.error(e);
    toast.error(<FormattedMessage id={getTranslationKey("messages", "error", "checkConsole")} />);
  }
}

export async function startBackgroundSession(
  scriptName: string,
  params?: Partial<StartBackgroundSessionParams>,
) {
  const toastId = toast.info(`Dispatching ${scriptName}...`, {
    autoClose: false,
  });

  try {
    const res = await API.playInBackground({
      isBackground: true,
      ...(params as any),
    });

    // new api is too fast
    await delay(1000);

    toast.update(toastId, {
      type: "success",
      render: `${scriptName} dispatched for execution`,
      transition: Zoom,
      autoClose: 5000,
    });
    return res;
  } catch (error) {
    toast.update(toastId, {
      type: "error",
      render: (
        <FormattedMessage
          id={getTranslationKey(
            "messages",
            "error",
            error?.message === "Not enough worker capacity"
              ? "notEnoughWorkerCapacity"
              : "dispatchingFailed",
          )}
        />
      ),
      transition: Zoom,
      autoClose: 5000,
    });
    console.error(error);
    return;
  }
}
