export const hasAttribute = (target: any, attributeName: string) =>
  target.getAttribute !== undefined && target.getAttribute(attributeName) !== null;

export const checkAttribute = (target: any, attributeName: string, attributeValue: string) =>
  target.getAttribute !== undefined && target.getAttribute(attributeName) === attributeValue;

export const isTagName = (target: HTMLElement, expectedTagName: string) =>
  target.tagName !== undefined && target.tagName.toLowerCase() === expectedTagName.toLowerCase();

export const hasClass = (target: any, className: string) =>
  target && target.classList ? target.classList.contains(className) : false;
