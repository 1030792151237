import styled from "@emotion/styled";
import * as React from "react";

import { COLORS } from "@app/styles/consts";
import { Message, STEP_MESSAGE_ICON_MAP, LabelParams } from "@ea/shared_types/types";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { useTranslation } from "react-i18next";
import StepMessageIcon from "@ea/shared_components/MessageBar/StepMessageIcon";

interface IMessagesRowProps {
  messages: Message[];
  withHeader?: boolean;
  stepLabelParams?: LabelParams;
}

const MessageRowContainer = styled.div({
  width: "100%",
  display: "flex",
  alignItems: "center",
});

const MessagesHeader = styled.h3({
  marginTop: "5px",
});

const MessageRow: React.SFC<{ message: Message; stepLabelParams?: LabelParams }> = ({
  message,
  stepLabelParams,
}) => {
  const { t } = useTranslation();

  let messageLabelParams = message.labelParams || {};

  // if message includes step label we need to translate it first and pass ready label to message translation
  if (message.labelParams?.stepLabelKey) {
    const stepLabel = t(message.labelParams.stepLabelKey as string, stepLabelParams || {});
    messageLabelParams = { ...messageLabelParams, stepLabel };
  }

  return (
    <MessageRowContainer>
      <StepMessageIcon
        messageType={message.type}
        style={{ color: COLORS.STATUS[message.type], marginRight: "10px" }}
      />
      <span>{message.labelKey ? t(message.labelKey, messageLabelParams) : message.text}</span>
    </MessageRowContainer>
  );
};

export const shouldDisplayMessagesRow = (messages) => messages.length > 0;

const MessagesRow: React.FunctionComponent<IMessagesRowProps> = ({
  messages,
  withHeader,
  stepLabelParams,
}) => {
  if (!shouldDisplayMessagesRow(messages)) {
    return null;
  }

  const Messages = (
    <div>
      {messages.map((message, index) => (
        <MessageRow key={index} message={message} stepLabelParams={stepLabelParams} />
      ))}
    </div>
  );

  if (withHeader) {
    return (
      <div>
        <MessagesHeader>
          <FormattedMessage id={getTranslationKey("logs", "header", "message")} />
        </MessagesHeader>
        {Messages}
      </div>
    );
  }

  return Messages;
};

export default MessagesRow;
