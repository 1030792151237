import React from "react";
import { Input, Form } from "antd";

const EditableContext = React.createContext({});

export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface IEditableCellProps {
  title: string;
  editable: boolean;
  children: any;
  dataIndex: string;
  record: any;
  handleSave: (values) => void;
  translateLabel: (values) => string;
}

interface IEditableCellState {
  isEditing: boolean;
  isCellEditable: boolean;
}

class EditableCell extends React.Component<IEditableCellProps, IEditableCellState> {
  static contextType = EditableContext;
  editableForm: any;
  inputRef: any;

  constructor(props) {
    super(props);
    const { editable } = props;
    this.inputRef = React.createRef();
    this.state = {
      isEditing: false,
      isCellEditable: editable,
    };
  }
  componentDidMount() {
    this.editableForm = this.context;
  }

  componentDidUpdate(prevProps: IEditableCellProps, prevState: IEditableCellState) {
    if (!prevState.isEditing && this.state.isEditing) {
      this.inputRef?.current?.focus();
    }
  }

  getInitialValues = () => ({
    [this.props.dataIndex]:
      this.props.record[this.props.dataIndex] || this.props.translateLabel(this.props.record),
  });

  toggleEdit = () => {
    this.setState((state) => ({
      isEditing: !state.isEditing,
    }));
    this.editableForm.setFieldsValue(this.getInitialValues());
  };

  isValueDifferent = (values) =>
    values[this.props.dataIndex] !== this.getInitialValues()[this.props.dataIndex];

  save = async (e) => {
    const { handleSave, record } = this.props;
    try {
      const values = await this.editableForm.validateFields();

      if (this.isValueDifferent(values)) {
        handleSave({ ...record, ...values });
      }
      this.toggleEdit();
    } catch (err) {
      console.log("Save failed:", err);
    }
  };

  render() {
    const { isCellEditable, isEditing } = this.state;
    const {
      title,
      editable,
      children,
      dataIndex,
      record,
      handleSave,
      translateLabel,
      ...restProps
    } = this.props;

    const renderColumn = (element) => <td {...restProps}>{element}</td>;

    if (!isCellEditable) {
      return renderColumn(children);
    }

    return isEditing
      ? renderColumn(
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
          >
            <Input ref={this.inputRef} onPressEnter={this.save} onBlur={this.save} />
          </Form.Item>,
        )
      : renderColumn(<div onClick={this.toggleEdit}>{children}</div>);
  }
}

export default EditableCell;
