import * as PropTypes from "prop-types";
import * as React from "react";
import { RouterChildContext } from "react-router";
import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { User, IssueTrackingTool } from "@ea/shared_types/types";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import CreateItsForm from "./components/CreateItsForm";

interface ItsContainerProps {
  visibility: boolean;
  onClose: () => void;
  onCreate: (its: IssueTrackingTool) => void;
  selected?: IssueTrackingTool;
  isEdit?: boolean;
  user: User | undefined;
  testPlansIntegration: boolean | null;
}

class CreateEditIts extends React.Component<ItsContainerProps> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  context: RouterChildContext<any>;

  onOk = async (values) => {
    const { isEdit, onCreate, onClose } = this.props;
    if (isEdit) {
      await API.editIts(values);
    } else {
      if (this.props.user) {
        const result = await API.createIts(values);
        if (onCreate) {
          onCreate(result);
        }
      }
    }
    onClose();
  };

  render() {
    const { visibility, isEdit, onClose, selected, testPlansIntegration } = this.props;
    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.MEDIUM}
        headerText={getTranslationKey("its", "header", isEdit ? "edit" : "create")}
        onCancelClick={onClose}
        onOkClick={this.onOk}
        cancelButtonText={getTranslationKey("button", "cancel")}
        key={isEdit ? "edit" : "create"}
        okButtonText={getTranslationKey("button", isEdit ? "save" : "create")}
        initialValues={isEdit ? selected : { customFields: [] }}
        render={({ values, change, form }) => (
          <CreateItsForm
            values={values}
            change={change}
            testPlansIntegration={testPlansIntegration}
            form={form}
          />
        )}
      />
    );
  }
}

export default CreateEditIts;
