import * as React from "react";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";

interface ApiKeyCommandBarProps {
  onNewClick: () => void;
  onRemoveClick: (ids: number[]) => void;
  onEditClick: () => void;
  onReload: () => void;
  onRefreshClick: () => void;
  selected: any[];
}
class ApiKeyCommandBar extends React.Component<ApiKeyCommandBarProps & InjectedIntlProps> {
  private container: HTMLElement;

  public render() {
    return (
      <CommandBar data-testid={DataTestIds.COMMANDBAR_API_KEY}>
        <CommandBarButton
          name="new"
          onClick={this.props.onNewClick}
          text={getTranslationKey("commandBar", "add")}
          icon="PlusOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_NEW}
        />
        <CommandBarButton
          name="remove"
          onClick={() => this.props.onRemoveClick(this.props.selected)}
          text={getTranslationKey("commandBar", "remove")}
          icon="DeleteOutlined"
          disabled={this.props.selected.length === 0}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE}
        />
        <CommandBarButton
          name="refresh"
          onClick={this.props.onReload}
          text={getTranslationKey("commandBar", "refresh")}
          icon="ReloadOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
        />
        <CommandBarButton
          name="edit"
          onClick={this.props.onEditClick}
          text={getTranslationKey("commandBar", "edit")}
          icon="EditOutlined"
          disabled={this.props.selected.length !== 1}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_EDIT}
        />
        <CommandBarButton
          name="refreshApiKey"
          onClick={this.props.onRefreshClick}
          text={getTranslationKey("commandBar", "refreshApiKey")}
          icon="SyncOutlined"
          far
          type="primary"
          disabled={this.props.selected.length !== 1}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH_APIKEY}
        />
      </CommandBar>
    );
  }
}
export default injectIntl(ApiKeyCommandBar);
