import * as PropTypes from "prop-types";
import * as React from "react";
import { findDOMNode } from "react-dom";

import { provideDisplayName } from "../../utils/react";

// Export Higher Order Sortable Element Component
export default function sortableElement(WrappedComponent: any, config: any = { wrapRef: false }) {
  return class extends React.Component<any> {
    static displayName = provideDisplayName("sortableElement", WrappedComponent);

    static contextTypes = {
      manager: PropTypes.object.isRequired,
    };

    static defaultProps = {
      collection: 0,
    };

    node: any;
    ref: any;

    componentDidMount() {
      const { collection, dragDisabled, index } = this.props;

      if (!dragDisabled) {
        this.setDraggable(collection, index);
      }
    }

    componentWillReceiveProps(nextProps: any) {
      if (this.props.index !== nextProps.index && this.node) {
        this.node.sortableInfo.index = nextProps.index;
      }
      if (this.props.dragDisabled !== nextProps.dragDisabled) {
        const { collection, dragDisabled, index } = nextProps;
        if (dragDisabled) {
          this.removeDraggable(collection);
        } else {
          this.setDraggable(collection, index);
        }
      } else if (this.props.collection !== nextProps.collection) {
        this.removeDraggable(this.props.collection);
        this.setDraggable(nextProps.collection, nextProps.index);
      }
    }

    componentWillUnmount() {
      const { collection, dragDisabled } = this.props;

      if (!dragDisabled) {
        this.removeDraggable(collection);
      }
    }

    setDraggable(collection: any, index: any) {
      const node: any = (this.node = findDOMNode(this));

      node.sortableInfo = {
        index,
        collection,
        manager: this.context.manager,
      };

      this.ref = { node };
      this.context.manager.add(collection, this.ref);
    }

    removeDraggable(collection: any) {
      this.context.manager.remove(collection, this.ref);
    }

    getWrappedInstance() {
      return this.refs.wrappedInstance;
    }

    render() {
      const ref = config.wrapRef ? "wrappedInstance" : null;

      return <WrappedComponent ref={ref} {...this.props} />;
    }
  };
}
