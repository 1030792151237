import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { Step, Script, RunnerMode, System, VirtualUser } from "@ea/shared_types/types";
import { overrideColumnProps } from "@ea/shared_components/Table/common.tables";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { RUNNER_STEPS_COLUMNS, RUNNER_STEPS_TABLES_CONFIG } from "./runner.table";
import {
  getRunnerMode,
  getSessionParams,
  getStepsTreeData,
  stepsToTreeSelectorsModifier,
} from "./runner.selectors";
import { getRunnerTableActions } from "./runner.actions";
import { getRunnerColumnsRenderers } from "./RunnerColumnsRenderers";
import { withTranslation, WithTranslation } from "react-i18next";
import { getNotSelectableIds } from "@app/modules/runner/runner.selectors";
import { isSame } from "@ea/shared_components/utils/array";
import diff from "object-diff";
import StepQuickEditContainer from "./StepQuickEdit.container";
import { Dispatch, bindActionCreators } from "redux";
import { getStepLabelParams } from "../steps/steps.utils";
import { ExecutionStep, ExecutionStepTreeNode } from "@ea/shared_types/runner.common.types";
import { isRootStep } from "./runner.helpers";
import { scroller } from "react-scroll";
import { STEP_MARK_CLASS, STEP_MARK_CLASS_RIGHT_BORDER } from "@ea/shared_components/Table/config";
import { SelectMode } from "@ea/shared_components/redux/common.models";

interface RunnerStepsContainerProps {
  sessionId: string;
  isDragEnabled?: boolean;
  script: Script;
  isExpandedEditModeOn: boolean;
  systems: System[];
  system?: System;
  virtualUsers: VirtualUser[];
  toggleEdit?: () => void;
}
interface RunnerStepsContainerState {
  expandedRowKeys: number[] | string[] | undefined;
}
const CONTROL_PANELS_HEIGHT = "40px";
const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: `calc(100% - ${CONTROL_PANELS_HEIGHT})`,
});
type ComponentProps = RunnerStepsContainerProps & WithTranslation;

const AUTO_SCROLL_CONTAINER_ID = "autoScrollContainer";
const AUTO_SCROLL_ANCHOR_CLASS = "auto-scroll-anchor";

class RunnerStepsContainer extends React.Component<IConnectProps, RunnerStepsContainerState> {
  connectedTable;

  constructor(props) {
    super(props);

    this.state = {
      expandedRowKeys: undefined,
    };
  }
  isRowDisabled = (record) => record.isDisabled;

  shouldComponentUpdate(nextProps: IConnectProps, nextState: RunnerStepsContainerState) {
    return (
      !isSame(this.props.notSelectableIds, nextProps.notSelectableIds) ||
      this.props.isDragEnabled !== nextProps.isDragEnabled ||
      Object.keys(diff(this.props.sessionParams, nextProps.sessionParams)).length > 0 ||
      this.props.sessionId !== nextProps.sessionId ||
      this.props.isExpandedEditModeOn !== nextProps.isExpandedEditModeOn ||
      this.state.expandedRowKeys !== nextState.expandedRowKeys ||
      this.props.mode !== nextProps.mode
    );
  }

  componentDidMount() {
    this.scrollToHighlighted(
      this.props.mode === RunnerMode.PLAYER ? AUTO_SCROLL_ANCHOR_CLASS : STEP_MARK_CLASS,
    );
  }
  scrollToHighlighted = (anchor: string) => {
    scroller.scrollTo(anchor, {
      duration: 150,
      smooth: true,
      containerId: AUTO_SCROLL_CONTAINER_ID,
      offset: -450,
    });
  };

  componentDidUpdate(prevProps: IConnectProps) {
    if (!prevProps.isDragEnabled && this.props.isDragEnabled) {
      this.setState({
        expandedRowKeys: [],
      });
    }
    if (prevProps.isDragEnabled && !this.props.isDragEnabled) {
      this.setState({
        expandedRowKeys: undefined,
      });
    }

    const playerPositionChanged =
      prevProps.sessionParams.player.position !== this.props.sessionParams.player.position;
    const recordingPathFirstSet =
      prevProps.sessionParams.recorder.recordingPath === undefined &&
      this.props.sessionParams.recorder.recordingPath !== undefined;

    if (playerPositionChanged) {
      this.scrollToHighlighted(AUTO_SCROLL_ANCHOR_CLASS);
    }

    if (recordingPathFirstSet) {
      this.scrollToHighlighted(STEP_MARK_CLASS);
    }
  }

  expandedRowRender = (record: ExecutionStep) => {
    const { actions, sessionId, script, isExpandedEditModeOn, systems, system, virtualUsers } =
      this.props;

    // todo: migrate steps from children to execution.steps
    if ((record as any).children?.length === 0) {
      return (
        <StepQuickEditContainer
          step={record}
          updateStep={actions.updateStep}
          sessionId={sessionId}
          script={script}
          isExpandedEditModeOn={isRootStep(record as any) ? isExpandedEditModeOn : false}
          css={{ backgroundColor: "red" }}
          systems={systems}
          system={system}
          virtualUsers={virtualUsers}
          toggleEdit={this.props.toggleEdit}
        />
      );
    } else {
      return null;
    }
  };

  shouldRowBeExpandable = (record: Step) => {
    if (this.props.isDragEnabled) {
      return false;
    }
    const { notSelectableIds } = this.props;

    if (notSelectableIds) {
      return !notSelectableIds.includes(record.id);
    }
    return true;
  };

  markExecutionClass = (record: ExecutionStep) => {
    const { recorder, mode } = this.props.sessionParams;
    return (
      (recorder?.recordingPath === record.execution.path &&
        (mode === RunnerMode.RECORDER ? STEP_MARK_CLASS : STEP_MARK_CLASS_RIGHT_BORDER)) ||
      ""
    );
  };

  openDetails = (stepId) => {
    const { toggleEdit, actions } = this.props;
    actions.select({ ids: [stepId], mode: SelectMode.Replace });
    toggleEdit?.();
  };

  render() {
    const { t, notSelectableIds, sessionId, isDragEnabled, sessionParams } = this.props;

    const runnerColumnsRenderers = getRunnerColumnsRenderers();
    const Columns = overrideColumnProps<ExecutionStepTreeNode>(RUNNER_STEPS_COLUMNS, [
      {
        dataIndex: "label",
        frameworkProps: {
          render: (_, record) =>
            runnerColumnsRenderers.labelRenderer(record, t, notSelectableIds, this.openDetails),
        },
        columnProps: {
          handleSave: (values: ExecutionStep) =>
            this.props.actions.updateStep({
              step: { ...values, manualLabel: values.label ? true : undefined },
            }),
          translateLabel: (record) =>
            record.label || record.labelKey ? t(record.labelKey!, getStepLabelParams(record)) : "",
        },
      },
    ]);

    return (
      <Container>
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable={false}
          columnsConfig={Columns}
          tableId={sessionId}
          preferencesId={RUNNER_STEPS_TABLES_CONFIG.SESSION.preferencesId}
          stateKey={"runner"}
          tableActions={getRunnerTableActions}
          notSelectableIds={notSelectableIds}
          isRowDisabled={this.isRowDisabled}
          isDragEnabled={isDragEnabled}
          markPositionClass={this.markExecutionClass}
          expandedRowRender={this.expandedRowRender}
          shouldRowBeExpandable={this.shouldRowBeExpandable}
          selectable={true}
          modifySelectors={stepsToTreeSelectorsModifier}
          disableSelectAll
          expandedRowKeys={this.state.expandedRowKeys}
          disbaleExpandingOnDragEnabled
          nestedInnerShadow={(record: ExecutionStepTreeNode) => {
            if (isRootStep(record)) {
              return "";
            } else {
              return record.commandId === "start"
                ? "inner-shadow-top"
                : record.commandId === "end"
                ? "inner-shadow-bottom"
                : "";
            }
          }}
          isExpandedRowEmpty={(record: ExecutionStepTreeNode) =>
            (record as any).children?.length !== 0
          }
          id={AUTO_SCROLL_CONTAINER_ID}
          autoScrollAnchor={(record) =>
            sessionParams.mode === RunnerMode.PLAYER &&
            record.executionPath === record.execution.path
              ? AUTO_SCROLL_ANCHOR_CLASS
              : ""
          }
          disableRowSelection
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: ComponentProps) => ({
  actions: {
    ...bindActionCreators(getRunnerTableActions(props.sessionId), dispatch),
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: ComponentProps) => ({
    sessionParams: getSessionParams(state, props.sessionId),
    notSelectableIds: getNotSelectableIds(state),
    getStepsTreeData: getStepsTreeData(state, props.sessionId),
    mode: getRunnerMode(state, props.sessionId),
    ...props,
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withTranslation()(connectCreator(RunnerStepsContainer));
