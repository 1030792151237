import { getIn } from "final-form";
import * as React from "react";
import {
  Variable,
  CodeTemplate,
  CodeTemplateDefinition,
  CodeTemplateGroup,
} from "@ea/shared_types/types";
import { DataTestIds } from "../utils/dataTestHelpers";
import PickerField from "../Form/Fields/PickerField";
import { CodeTemplateParamField } from "./CodeFieldParamField";
import { VariablesGroup } from "../redux/common.models";
interface ICodeTemplateFieldProps {
  variables?: Variable[];
  values?: any;
  name?: string;
  readOnly?: boolean;
  prefix: string;
  change?: (name: string, value) => void;
  codeTemplates?: CodeTemplate[];
  variablesGroups?: VariablesGroup[];
}

type CodeTemplateWithGroup = CodeTemplate & { codeTemplateGroup: CodeTemplateGroup };

const generateGroups = (codeTemplates: CodeTemplateWithGroup[]) => {
  const prep = codeTemplates.reduce((container, curr) => {
    const groupName = curr.codeTemplateGroup?.name;

    if (groupName) {
      if (!container[groupName]) {
        container[groupName] = [];
      }
      container[groupName] = [
        ...container[groupName],
        { value: curr.functionName, label: curr.functionName },
      ];
    }
    return container;
  }, {});

  const groups = Object.keys(prep).map((key) => ({ value: key, label: key, children: prep[key] }));
  return groups;
};

export const CodeTemplateField: React.FunctionComponent<ICodeTemplateFieldProps> = ({
  codeTemplates = [],
  values,
  prefix,
  change,
  variablesGroups,
}) => {
  const getParamsFields = () => {
    const functionName = getIn(values, `${prefix}.value`)?.split(".")?.[1];
    const codeTemplate = codeTemplates.find((ct) => ct.functionName === functionName);
    if (codeTemplate) {
      const usedDefinition: CodeTemplateDefinition =
        codeTemplate.definitions[`v${codeTemplate.activeVersion}`];
      return usedDefinition.params?.map((p, i) => {
        const type = getIn(values, `${prefix}.params[${i}].type`);
        return (
          <CodeTemplateParamField
            name={`${prefix}.params[${i}]`}
            prefix={`${prefix}.params[${i}]`}
            change={change}
            paramsCode={p}
            paramDescription={p.description}
            variablesGroups={variablesGroups || []}
            paramType={type}
          />
        );
      });
    }

    return null;
  };

  const options = generateGroups(codeTemplates as CodeTemplateWithGroup[]);

  const parse = (value) => (value ? value.join(".") : value);

  const format = (value) => {
    if (value) {
      return value.split(".");
    }
    return value;
  };

  return (
    <>
      <PickerField
        onChange={(e) => {
          change?.(`${prefix}.params`, undefined);
        }}
        name={`${prefix}.value`}
        data-testid={DataTestIds.FORM_PICKER_CODE_TEMPLATE}
        parse={parse}
        format={format}
        required
        options={options}
      />
      {getParamsFields()}
    </>
  );
};
