import * as React from "react";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import { capitalizeFirstLetter } from "@ea/shared_components/utils/formatters";
import { VirtualUser } from "@ea/shared_types/types";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";

interface ISensitiveDataFormProps {
  virtualUser: VirtualUser;
}

type Props = ISensitiveDataFormProps;

class SensitiveDataForm extends React.Component<Props> {
  getSensitiveFields = () => {
    const { virtualUser } = this.props;
    if (!virtualUser || !virtualUser.systemData) {
      return null;
    }
    const values = virtualUser.systemData.values;

    if (Array.isArray(values)) {
      return values.filter((v) => v.isSensitive);
    } else {
      return Object.keys(values)
        .filter((field) => values[field].isSensitive)
        .map((field) => ({ name: field, ...values[field] }));
    }
  };

  render() {
    const sensitiveFields = this.getSensitiveFields();
    return (
      <>
        {sensitiveFields && sensitiveFields.length > 0 ? (
          (sensitiveFields as any).map((field, index) => (
            <InputField
              key={index}
              name={field.name || field.key}
              required
              type="password"
              label={capitalizeFirstLetter(field.name || field.key)}
            />
          ))
        ) : (
          <FormattedMessage id={getTranslationKey("virtualUsers", "noSensitiveData")} />
        )}
      </>
    );
  }
}

export default SensitiveDataForm;
