import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import { Step } from "@ea/shared_types/types";
import { getStepOptions, omitEndStep } from "@app/utils/getStepOptions";
import { DataTestIds } from "@app/utils/dataTestIds";

interface ICreateStepCommonFormProps {
  steps: Step[];
}

export const inputPositiveNumbersProps = {
  onKeyPress: (event) => event.charCode >= 48,
  min: 1,
};

const CreateStepCommonForm: React.FunctionComponent<ICreateStepCommonFormProps> = ({ steps }) => {
  const stepOptions = getStepOptions(omitEndStep(steps), "lineNum");
  return (
    <>
      <InputField
        name="label"
        label={getTranslationKey("step", "createStep", "label", "label")}
        placeholder={getTranslationKey("step", "createStep", "placeholder", "enterLabel")}
        required
        data-testid={DataTestIds.FORM_INPUT_LABEL}
      />
      <InputField
        label={getTranslationKey("step", "createStep", "label", "delay")}
        name="delay"
        addonAfter="seconds"
        type="number"
        data-testid={DataTestIds.FORM_INPUT_DELAY}
        {...inputPositiveNumbersProps}
      />
      <SelectField
        name="lineNum"
        label={getTranslationKey("step", "createStep", "label", "lineNum")}
        placeholder={getTranslationKey("step", "createStep", "placeholder", "selectStep")}
        options={stepOptions}
        required
        data-testid={DataTestIds.FORM_INPUT_LINENUM}
      />
    </>
  );
};

export default CreateStepCommonForm;
