import { Col, Row } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { getIn } from "final-form";
import styled from "@emotion/styled";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import RadioField, { RadioType } from "@ea/shared_components/Form/Fields/RadioField";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import FormItemWrapper from "@ea/shared_components/Form/FormItem/FormItemWrapper";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { LayoutContext } from "@ea/shared_components/Form/FormLayoutContext";
import {
  AssertionType,
  DataSourceComparisonType,
  Step,
  Variable,
  Script,
  initialConditionActions,
  initialCheckValueCondition,
  initialDataSourceCondition,
  GlobalVariable,
  Kpi,
} from "@ea/shared_types/types";

import AssertActionPicker from "./AssertActionPicker";
import AssertConditionOperatorPicker from "./AssertConditionOperatorPicker";
import AssertConditionPicker from "./AssertConditionPicker";
import CheckboxField from "@ea/shared_components/Form/Fields/CheckboxField";
import { FieldArray } from "react-final-form-arrays";
import { OverrideStatus } from "./OverrideStatus";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

type AssertFormChunkProps = {
  prefix: string;
  readOnly?: boolean;
  variables: Variable[];
  globalMutable?: GlobalVariable[];
  globalConstant?: GlobalVariable[];
  kpis?: Kpi[];
  script: Script;
  steps: Step[];
  change: any;
  values: any;

  labelCol?: ColProps;
  wrapperCol?: ColProps;
};

const HeaderText = styled.h4({
  textAlign: "right",
  marginRight: "15px",
  fontWeight: "bold",
  marginTop: "15px",
});

const Labels = {
  [AssertionType.CHECK_VALUE]: getTranslationKey("step", "assert", "assertionType", "checkValue"),
  [AssertionType.DATA_SOURCE]: getTranslationKey("step", "assert", "assertionType", "dataSource"),
};

export const parseAssertValue = (value?: string | undefined) => {
  try {
    if (!value) {
      throw new Error("Asser value is undefined");
    }
    const assertValue = JSON.parse(value);
    return assertValue;
  } catch (err) {
    return {
      ...initialConditionActions(),
      ...initialCheckValueCondition(),
    };
  }
};

const Header = (text: string | React.ReactNode) => (
  <LayoutContext.Consumer>
    {(value) => (
      <Row>
        <Col {...value.labelCol}>
          <HeaderText> {text} </HeaderText>
        </Col>
      </Row>
    )}
  </LayoutContext.Consumer>
);

class AssertFormChunk extends React.Component<AssertFormChunkProps> {
  onComparisonTypeChange(value, index) {
    const { change, prefix } = this.props;
    if (value === AssertionType.CHECK_VALUE) {
      change(`${prefix}.comparison[${index}]`, initialCheckValueCondition().comparison[0]);
      return;
    }
    change(`${prefix}.comparison[${index}]`, initialDataSourceCondition().comparison[0]);
  }

  render() {
    const { prefix, values, readOnly, script } = this.props;
    getTranslationKey("step", "label", "condition");
    return (
      <>
        {Header(<FormattedMessage id={getTranslationKey("step", "assert", "header", "if")} />)}
        <FieldArray name={`${prefix}.comparison`}>
          {({ fields }) => {
            return (
              <>
                {fields && fields.length && fields.length > 0
                  ? fields.map((pathObject, index) => {
                      const comparisonType = getIn(
                        values,
                        `${prefix}.comparison[${index}].comparisonType`,
                      );
                      const type = getIn(values, `${prefix}.comparison[${index}].type`);

                      return (
                        <FormItemWrapper
                          key={index}
                          formItem={{
                            required: true,
                            label:
                              index === 0
                                ? ((<FormattedMessage id={"step.label.condition"} />) as any)
                                : undefined,
                            formItemRowStyle: { marginBottom: 0 },
                          }}
                        >
                          {() => (
                            <>
                              {index > 0 && (
                                <RadioField
                                  name={`${prefix}.comparison[${index}].logicalOperator`}
                                  required
                                  readOnly={readOnly}
                                  type={RadioType.Button}
                                  options={[
                                    { value: "and", text: "And" },
                                    { value: "or", text: "Or" },
                                  ]}
                                />
                              )}
                              <FormLayout
                                wrapperCol={{ span: 24 }}
                                labelCol={{ span: 0 }}
                                readOnly={readOnly}
                              >
                                <Row gutter={10}>
                                  <Col span={21}>
                                    <RadioField
                                      name={`${prefix}.comparison[${index}].type`}
                                      required
                                      readOnly={readOnly}
                                      defaultValue={AssertionType.CHECK_VALUE}
                                      type={RadioType.Button}
                                      options={[
                                        {
                                          text: getTranslationKey(
                                            "step",
                                            "assert",
                                            "assertionType",
                                            "checkValue",
                                          ),
                                          value: AssertionType.CHECK_VALUE,
                                        },
                                        {
                                          text: getTranslationKey(
                                            "step",
                                            "assert",
                                            "assertionType",
                                            "dataSource",
                                          ),

                                          value: AssertionType.DATA_SOURCE,
                                        },
                                      ]}
                                      onChange={({ target }) => {
                                        if (target) {
                                          this.onComparisonTypeChange(target.value, index);
                                        }
                                      }}
                                    />
                                  </Col>
                                  <Col span={3}>
                                    {!readOnly ? (
                                      index > 0 ? (
                                        <DeleteOutlined
                                          onClick={() => {
                                            fields.remove(index);
                                          }}
                                          style={{ color: "red" }}
                                        />
                                      ) : (
                                        <PlusOutlined
                                          onClick={() => {
                                            fields.push({});
                                          }}
                                        />
                                      )
                                    ) : null}
                                  </Col>
                                </Row>
                              </FormLayout>
                              <FormLayout wrapperCol={{ span: 24 }} labelCol={{ span: 0 }}>
                                {type === AssertionType.CHECK_VALUE && (
                                  <Row gutter={10}>
                                    <Col span={8}>
                                      <AssertConditionPicker
                                        {...this.props}
                                        prefix={`${prefix}.comparison[${index}].leftCondition`}
                                      />
                                    </Col>
                                    <Col span={5}>
                                      <AssertConditionOperatorPicker
                                        prefix={`${prefix}.comparison[${index}]`}
                                        readOnly={readOnly}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <AssertConditionPicker
                                        {...this.props}
                                        prefix={`${prefix}.comparison[${index}].rightCondition`}
                                      />
                                    </Col>
                                  </Row>
                                )}
                                {type === AssertionType.DATA_SOURCE && (
                                  <Row gutter={16}>
                                    <Col span={21}>
                                      <SelectField
                                        name={`${prefix}.comparison[${index}].comparisonType`}
                                        placeholder={getTranslationKey("none")}
                                        readOnly={readOnly}
                                        required
                                        options={[
                                          {
                                            value: DataSourceComparisonType.HAS_NEXT,
                                            text: getTranslationKey(
                                              "step",
                                              "assert",
                                              "conditionType",
                                              "hasNext",
                                            ),
                                          },
                                        ]}
                                      />
                                      {comparisonType === DataSourceComparisonType.HAS_NEXT && (
                                        <>
                                          <SelectField
                                            name={`${prefix}.comparison[${index}].value`}
                                            readOnly={readOnly}
                                            required
                                            placeholder={getTranslationKey(
                                              "step",
                                              "placeholder",
                                              "selectSheet",
                                            )}
                                            options={(script.datasource
                                              ? Object.keys(script.datasource.sheetsMeta) || []
                                              : []
                                            ).map((item) => ({
                                              key: item,
                                              text: item,
                                              value: item,
                                            }))}
                                          />
                                          <CheckboxField
                                            name={`${prefix}.comparison[${index}].resetOnLast`}
                                            readOnly={readOnly}
                                          >
                                            <FormattedMessage
                                              id={getTranslationKey(
                                                "step",
                                                "assert",
                                                "resetOnLastRow",
                                              )}
                                            />
                                          </CheckboxField>
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                )}
                              </FormLayout>
                            </>
                          )}
                        </FormItemWrapper>
                      );
                    })
                  : null}
              </>
            );
          }}
        </FieldArray>

        {Header(<FormattedMessage id={getTranslationKey("step", "assert", "header", "then")} />)}
        <AssertActionPicker {...this.props} prefix={`${prefix}.ifAction`} />
        <OverrideStatus prefix={`${prefix}.ifAction`} readOnly={readOnly} />
        {Header(<FormattedMessage id={getTranslationKey("step", "assert", "header", "else")} />)}
        <AssertActionPicker {...this.props} prefix={`${prefix}.elseAction`} />
        <OverrideStatus prefix={`${prefix}.elseAction`} readOnly={readOnly} />
      </>
    );
  }
}

export default AssertFormChunk;
