import * as React from "react";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import { SelectField } from "@ea/shared_components/Form/Fields/SelectField";
import { FormattedMessage } from "react-intl";
import { IssueTrackingToolTrigger } from "@ea/shared_types/types";
import { getIn } from "final-form";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import { API } from "@app/services/api/api";
import { toast } from "react-toastify";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { severityOptions } from "@app/modules/issueTrackingTool/its.helpers";
import AzureFormChunk from "@app/modules/issueTrackingTool/components/AzureFormChunk";
import { DataTestIds } from "@app/utils/dataTestIds";

interface ICreateItsRuleFormState {
  workItemTypesOptions: OptionType[];
  loading: boolean;
}

interface IItsRuleProps {
  readOnly?: boolean;
  values: any;
  itsOptions: OptionType[];
  change: (name: string, value: any) => void;
}

class CreateItsRuleForm extends React.Component<IItsRuleProps, ICreateItsRuleFormState> {
  state = {
    workItemTypesOptions: [],
    loading: false,
  };

  componentDidMount() {
    const itsId = getIn(this.props.values, "itsId");
    this.reloadWorkItemTypesOptions(itsId);
  }

  onItsChange = async (itsId) => {
    this.props.change("type", undefined);
    await this.reloadWorkItemTypesOptions(itsId);
  };

  reloadWorkItemTypesOptions = async (itsId) => {
    const { change } = this.props;

    if (!itsId) {
      return;
    }
    this.setState({ loading: true });
    try {
      const wiTypes = await API.getWorkItemTypes({ itsId });
      this.setState({
        workItemTypesOptions: wiTypes.map((i) => ({ text: i.name, value: i.name })),
      });
    } catch (error) {
      change("type", undefined);
      this.setState({ loading: false, workItemTypesOptions: [] });
      toast.error(<FormattedMessage id={getTranslationKey("its", "cannotFetchWorkItemTypes")} />);
      return;
    }
    this.setState({ loading: false });
  };

  render() {
    const { readOnly, itsOptions, values, change } = this.props;
    const { loading, workItemTypesOptions } = this.state;
    return (
      <>
        <InputField
          name="name"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("common", "placeholder", "name")}
          label={getTranslationKey("common", "label", "name")}
          data-testid={DataTestIds.FORM_INPUT_NAME}
        />
        <SelectField
          name="itsId"
          placeholder={getTranslationKey("its", "placeholder", "its")}
          required
          label={getTranslationKey("its", "label", "its")}
          options={itsOptions}
          onChange={this.onItsChange}
          data-testid={DataTestIds.FORM_SELECT_ISSUE_TRACKING}
        />
        <SelectField
          name="triggers"
          placeholder={getTranslationKey("its", "placeholder", "triggers")}
          required
          data-testid={DataTestIds.FORM_SELECT_TRIGGERS}
          mode="multiple"
          label={getTranslationKey("its", "label", "triggers")}
          options={Object.keys(IssueTrackingToolTrigger).map((mt) => ({
            text: IssueTrackingToolTrigger[mt],
            value: IssueTrackingToolTrigger[mt],
          }))}
        />
        <SelectField
          name="config.type"
          loading={loading}
          placeholder={getTranslationKey("its", "placeholder", "workItemType")}
          label={getTranslationKey("its", "label", "workItemType")}
          options={workItemTypesOptions}
          disabled={workItemTypesOptions.length === 0}
          data-testid={DataTestIds.FORM_SELECT_WORKITEM_TYPE}
        />
        <AzureFormChunk
          {...this.props}
          itsId={getIn(values, "itsId")}
          change={change}
          prefix="config."
          useIssueTrackingParams
        />
        <SelectField
          name="config.severity"
          placeholder={getTranslationKey("its", "placeholder", "severity")}
          label={getTranslationKey("its", "label", "severity")}
          options={severityOptions}
          data-testid={DataTestIds.FORM_SELECT_SEVERITY}
        />
      </>
    );
  }
}

export default CreateItsRuleForm;
