import { createModuleEpics } from "@app/modules/app.reducers";
import { moduleActions } from "@app/modules/userGroups/userGroups.actions";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { UserGroup } from "@ea/shared_types/types";
import { userGroupDataSelectors } from "./userGroups.selectors";
import { USER_GROUPS_COLUMNS_CONFIG } from "./userGroups.table";

const userGroupInclude = {
  relation: "AppUser",
};

const userGroupModuleEpics = createModuleEpics<UserGroup>(moduleActions, {
  normalizeItem: (userGroup) => userGroup,
  getRequestParams: (state, tableId) => {
    const tableParams = userGroupDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, USER_GROUPS_COLUMNS_CONFIG);

    params.filter = {
      ...params.filter,
      include: userGroupInclude,
    };

    return params;
  },
  getSingleRequestParams: (query) => {
    query.filter = {
      ...query.filter,
      include: userGroupInclude,
    };
    return query;
  },
  api: {
    createItem: API.createUserGroup as any,
    deleteItem: API.deleteUserGroup,
    editItem: API.editUserGroup as any,
    getItems: API.getUserGroups as any,
    getItemsCount: API.getUserGroupsCount,
  },
});

export default userGroupModuleEpics;
