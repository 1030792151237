import * as React from "react";
import styled from "@emotion/styled";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { REPORT_TEMPLATES_TABLES_CONFIG, REPORT_TEMPLATES_COLUMNS } from "./reportTemplates.table";
import { reportTemplatesDataSelectors } from "./reportTemplates.selectors";
import { getReportTemplatesTableActions, reportTemplatesActions } from "./reportTemplates.actions";
import ReportTemplatesCommandBar from "./components/ReportTemplatesCommandBar";
import CreateEditReportTemplate from "./CreateEditReportTemplate.container";
import { API } from "@app/services/api/api";
import { toast } from "react-toastify";
import { InjectedIntlProps, injectIntl } from "react-intl";

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

interface ModalVisibility {
  editWindowVisibility: boolean;
  createWindowVisibility: boolean;
}

interface IReportTemplatesTableProps {}

interface IReportTemplatesTableState extends ModalVisibility {}

class ReportTemplatesTableContainer extends React.Component<
  IConnectProps & InjectedIntlProps,
  IReportTemplatesTableState
> {
  connectedTable: any;

  constructor(props: IConnectProps & InjectedIntlProps) {
    super(props);

    this.state = {
      editWindowVisibility: false,
      createWindowVisibility: false,
    };
  }

  componentDidUpdate(prevProps: IReportTemplatesTableProps) {}

  openModal = (modal: keyof ModalVisibility) => () => {
    this.setState<never>({
      [modal]: true,
    });
  };

  closeModal = (modal: keyof ModalVisibility) => () => {
    this.setState<never>({
      [modal]: false,
    });
    this.reload();
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
  };

  download = () => {
    const { selectedReportTemplate: selected, intl } = this.props;
    if (selected) {
      try {
        API.downloadReportTemplate(selected.filename, selected.originalFilename);
      } catch (error) {
        toast.error(intl.formatMessage({ id: "messages.error.fileDownload" }));
      }
    }
  };

  render() {
    const { editWindowVisibility, createWindowVisibility } = this.state;
    const { selected, selectedReportTemplate } = this.props;

    return (
      <Container>
        <ReportTemplatesCommandBar
          onRemoveClick={this.remove}
          selected={selected}
          onEditClick={this.openModal("editWindowVisibility")}
          onNewClick={this.openModal("createWindowVisibility")}
          onReload={this.reload}
          onDownloadClick={this.download}
        />
        <CreateEditReportTemplate
          visibility={createWindowVisibility || editWindowVisibility}
          onClose={
            editWindowVisibility
              ? this.closeModal("editWindowVisibility")
              : this.closeModal("createWindowVisibility")
          }
          onReload={this.reload}
          selected={editWindowVisibility ? selectedReportTemplate : undefined}
          isEdit={editWindowVisibility}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={REPORT_TEMPLATES_COLUMNS}
          tableId={REPORT_TEMPLATES_TABLES_CONFIG.MAIN.id()}
          preferencesId={REPORT_TEMPLATES_TABLES_CONFIG.MAIN.preferencesId}
          stateKey={"reportTemplates"}
          tableActions={getReportTemplatesTableActions}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: IReportTemplatesTableProps) => ({
  actions: {
    ...bindActionCreators(reportTemplatesActions, dispatch),
    ...bindActionCreators(
      getReportTemplatesTableActions(REPORT_TEMPLATES_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ),
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: IReportTemplatesTableProps) => ({
    ...props,
    selected: reportTemplatesDataSelectors.getSelectedSelector(
      state,
      REPORT_TEMPLATES_TABLES_CONFIG.MAIN.id(),
    ),
    selectedReportTemplate: reportTemplatesDataSelectors.getSelectedItemSelector(
      state,
      REPORT_TEMPLATES_TABLES_CONFIG.MAIN.id(),
    ),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(injectIntl(ReportTemplatesTableContainer));
