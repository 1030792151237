import * as React from "react";
import {
  SCHEDULER_AGGREGATED_JOB_LOGS_COLUMNS,
  AGGREGATED_JOB_LOGS_TABLES_CONFIG,
} from "@app/modules/logs/AggregatedJobLogs/aggregatedJobLogs.table";
import AggregatedJobLogs from "../logs/AggregatedJobLogs/AggregatedJobLogs.container";
import { API } from "@app/services/api/api";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { bindActionCreators, Dispatch } from "redux";
import { authActions } from "../auth/auth.actions";
import { RouteComponentProps, withRouter } from "react-router";
import { AggregatedJobLog } from "@ea/shared_types/types";
import SchedulerLogsContainer from "@app/modules/scheduler/SchedulerLogs.container";
import styled from "@emotion/styled";

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: "100%",
});
interface ISchedulerHistoryProps {
  schedulerJobId: number;
}
interface ISchedulerHistoryContainerState {
  persistentQuery: any;
  hideAggregatedLogsView: boolean;
  isLogsViewChanging: boolean;
}
class SchedulerHistoryContainer extends React.Component<
  ISchedulerHistoryProps & IConnectProps & RouteComponentProps<any>,
  ISchedulerHistoryContainerState
> {
  connectedTable: any;

  constructor(props: ISchedulerHistoryProps & IConnectProps & RouteComponentProps<any>) {
    super(props);

    this.state = {
      hideAggregatedLogsView: false,
      persistentQuery: {
        schedulerJobId: props.schedulerJobId,
      },
      isLogsViewChanging: false,
    };
  }

  componentWillMount() {
    this.setState({
      persistentQuery: {
        schedulerJobId: this.props.schedulerJobId,
      },
    });
  }

  componentDidUpdate(prevProps: ISchedulerHistoryProps) {
    if (prevProps.schedulerJobId !== this.props.schedulerJobId) {
      this.setState({
        persistentQuery: {
          schedulerJobId: this.props.schedulerJobId,
        },
      });
    }
  }

  changeHideAggregatedLogsView = async () => {
    const { user } = this.props;
    if (user) {
      try {
        this.setState({
          isLogsViewChanging: true,
        });
        await API.editUser({
          ...user,
          settings: {
            ...user.settings,
            hideAggregatedLogsView: !user.settings.hideAggregatedLogsView,
          },
        });
        this.props.actions.auth.initDone({
          params: {
            ...user,
            isLoggedIn: true,
          },
          result: {
            roles: this.props.user!.roles,
            ...user,
            settings: {
              ...user.settings,
              hideAggregatedLogsView: !user.settings.hideAggregatedLogsView,
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
    this.setState({
      isLogsViewChanging: false,
    });
  };

  openJobDetails = (item?: AggregatedJobLog) => {
    if (item && item.jobExecutionId) {
      const { jobExecutionId } = item;
      const pathName = this.props.location.pathname;
      const isLastCharacterSlash = pathName.slice(-1) === "/";
      this.props.history.push({
        pathname: `${pathName}${isLastCharacterSlash ? "" : "/"}${jobExecutionId}/`,
      });
    }
  };

  getJobId = () => {
    const pathName = this.props.location.pathname;
    const chunks = pathName.split("/");
    chunks.splice(0, 4);
    const regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
    const match = regex.exec(chunks[0]);
    return !!match ? chunks[0] : undefined;
  };

  render() {
    const { schedulerJobId, user, match } = this.props;
    const { persistentQuery, isLogsViewChanging } = this.state;

    const jobExecutionId = match.params.jobExecutionId || this.getJobId();

    return (
      <Container>
        {jobExecutionId || (user && user.settings.hideAggregatedLogsView) ? (
          <SchedulerLogsContainer
            {...this.props}
            changeHideAggregatedLogsView={this.changeHideAggregatedLogsView}
            isLogsViewChanging={isLogsViewChanging}
            disableViewChangeButton={!!jobExecutionId}
            persistentQuery={jobExecutionId ? { jobExecutionId } : undefined}
          />
        ) : (
          <AggregatedJobLogs
            columns={SCHEDULER_AGGREGATED_JOB_LOGS_COLUMNS}
            tableId={AGGREGATED_JOB_LOGS_TABLES_CONFIG.SCHEDULER_AGGREGATED_JOB_LOGS.id(
              schedulerJobId,
            )}
            preferencesId={
              AGGREGATED_JOB_LOGS_TABLES_CONFIG.SCHEDULER_AGGREGATED_JOB_LOGS.preferencesId
            }
            persistentQuery={persistentQuery}
            changeHideAggregatedLogsView={this.changeHideAggregatedLogsView}
            isLogsViewChanging={isLogsViewChanging}
            onRowDoubleClick={this.openJobDetails}
          />
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: ISchedulerHistoryProps) => ({
  actions: {
    auth: {
      ...bindActionCreators(authActions, dispatch),
    },
  },
});

const connectCreator = connect(
  (state: ApplicationState) => ({
    user: state.auth.user,
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(SchedulerHistoryContainer));
