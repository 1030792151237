import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { System, VirtualUser, VIRTUAL_USER_STATUS } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("VIRTUAL_USER_MODULE");

export const moduleActions = createDataModuleActions<VirtualUser>(actionCreator);
export const getVirtualUsersTableActions = createTableActionsGetter(
  normalizeActions(moduleActions.table),
);

export const virtualUsersActionCreators = {
  ...moduleActions,
  authenticate: actionCreator.async<
    { id: number; sensitiveData: { key: string; value: string }[] },
    { status: VIRTUAL_USER_STATUS; errorMessage?: string }
  >("AUTHENTHICATE"),
};

export const virtualUsersActions = {
  ...normalizeActions(moduleActions.data),
  authenticate: virtualUsersActionCreators.authenticate.started,
};
