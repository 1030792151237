import { CodeTemplate, CodeTemplateWithGroup } from "../types";

export const defaultCodeTemplateVersion = { value: 1, text: "1" };

export const codeTemplateExtension = "eaCodeTemplate";

export const parseVersionKey = (version: string) =>
  (version || defaultCodeTemplateVersion.text).slice(1);

export const createVersionKey = (version: string | number) =>
  `v${version || defaultCodeTemplateVersion.value}`;

export const getExistingVersions = (codeTemplate: CodeTemplate) =>
  Object.keys(codeTemplate.definitions).map((v) => parseInt(parseVersionKey(v), 10));

export const getLastVersion = (codeTemplate: CodeTemplate) => {
  const existingVersions = getExistingVersions(codeTemplate);
  return Math.max(...existingVersions);
};

export const getNewVersionKey = (codeTemplate: CodeTemplate) => {
  const lastVersion = getLastVersion(codeTemplate);
  return createVersionKey(lastVersion + 1);
};

export const getCodeTemplateFileName = (codeTemplate: CodeTemplate) => {
  const groupName = (codeTemplate as CodeTemplateWithGroup).codeTemplateGroup?.name;
  return `${groupName ? `${groupName}-` : ""}${codeTemplate.functionName}.${codeTemplateExtension}`;
};
