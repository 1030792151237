import { Tag } from "antd";
import * as React from "react";

import { COLORS } from "@app/styles/consts";
import { JOB_TRIGGER_TYPE, JOB_TRIGGER_TYPE_LABELS } from "@ea/shared_types/types";

interface ITriggerTypeFieldProps {
  triggerType: JOB_TRIGGER_TYPE;
}

const style = {
  cursor: "default",
};

const JobTriggerField: React.FunctionComponent<ITriggerTypeFieldProps> = ({ triggerType }) => {
  if (!triggerType) {
    return null;
  }

  return (
    <Tag style={style} color={COLORS.TRIGGER_TYPE[triggerType]}>
      {JOB_TRIGGER_TYPE_LABELS[triggerType]}
    </Tag>
  );
};

export default JobTriggerField;
