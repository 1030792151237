import { ApplicationState } from "@app/modules/app.reducers";
import RouteTabs, { RouteTabParams } from "@app/modules/common/components/RouteTabs";
import ProjectMapperContainer from "@app/modules/projects/ProjectMapper.container";
import ProjectsTreeContainer from "@app/modules/projects/ProjectsTree.container";
import { ROUTES } from "@app/routes";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { tryParseInt } from "@app/utils/transformations";
import BaseModalForm from "@ea/shared_components/ModalForm/BaseModalForm";
import {
  currentUserSettingsSelector,
  isCurrentUserDocumentationReader,
} from "@ea/shared_components/auth/auth.selectors";
import BreadcrumbContainer from "@ea/shared_components/common/Breadcrumb.container";
import {
  BreadcrumbSection,
  Info,
  MainSection,
  Page,
} from "@ea/shared_components/common/LayoutElements";
import NonIdealState from "@ea/shared_components/common/NonIdealState";
import { formatToastText } from "@ea/shared_components/utils/toast";
import { WebsocketMessageTypes } from "@ea/shared_types/next/ea.enums";
import { WebsocketData } from "@ea/shared_types/next/ea.types";
import { FEATURES, ROLES } from "@ea/shared_types/types";
import styled from "@emotion/styled";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Dispatch, bindActionCreators } from "redux";
import { IS_DOCUMENTATION_WIZARD } from "../common/HideWhenDocumentationWizard";
import WithAuthorization from "../common/WithAuthorization";
import { registerWebsocketHandler, unregisterWebsocketHandler } from "../common/websocket";
import DocumentationScriptsTableContainer from "../documentation/DocumentationScriptsTable.container";
import { GlobalExcelsTableContainer } from "../globalExcels";
import { isFeatureDisabledSelector } from "../globalSettings/globalSettings.selectors";
import WithTestPlans from "../issueTrackingTool/components/WithTestPlans";
import { itsDataSelectors } from "../issueTrackingTool/its.selectors";
import { ITS_TABLES_CONFIG } from "../issueTrackingTool/its.table";
import { getItsRulesTableActions } from "../issueTrackingToolRules/itsRules.actions";
import {
  ITS_RULES_COLUMNS,
  ITS_RULES_TABLES_CONFIG,
} from "../issueTrackingToolRules/itsRules.table";
import AggregatedJobExecutionAreaChart from "../logs/statistics/AggregatedJobExecutionAreaChart.container";
import ScriptsTableContainer from "../scripts/ScriptsTable.container";
import { getSystemTableActions } from "../systemDictionary/systemDictionary.actions";
import {
  SYSTEM_DICTIONARY_COLUMNS,
  SYSTEM_DICTIONARY_TABLES_CONFIG,
  SYSTEM_PROJECT_ASSIGNMENT_COLUMNS,
} from "../systemDictionary/systemDictionary.table";
import { getUserGroupTableActions } from "../userGroups/userGroups.actions";
import { USER_GROUPS_COLUMNS, USER_GROUPS_TABLES_CONFIG } from "../userGroups/userGroups.table";
import { getVirtualUsersTableActions } from "../virtualUsers/virtualUsers.actions";
import {
  VIRTUAL_USERS_COLUMNS,
  VIRTUAL_USERS_TABLES_CONFIG,
} from "../virtualUsers/virtualUsers.table";
import ProjectHistoryContainer from "./ProjectHistory.container";
import ProjectsDetailsContainer from "./ProjectsDetails.container";
import ExportProjectsForm from "./components/ExportProjectsForm";
import { projectActions } from "./projects.actions";
import {
  getProjectsChildrenMap,
  getProjectsSystemsMappingMap,
  getProjectsTreeSelector,
  getProjectsUserGroupsMappingMap,
  getProjectsVirtualUsersMappingMap,
  projectSelectors,
} from "./projects.selectors";
import { PROJECTS_TABLES_CONFIG } from "./projects.tables";

interface IProjectsLoaderContainerProps {}

interface IProjectsLoaderContainerState {
  section: Routes;
  jobExecutions: { [key: string]: { backgroundExecutionId: string; isBeingDispatched: boolean } };
  isRunning: boolean;
  exportModalVisible: boolean;
}

enum Routes {
  Details = "",
  Scripts = "scripts",
  History = "history",
  UserGroups = "userGroups",
  VirtualUsers = "virtualUsers",
  Systems = "systems",
  TestResults = "testResults",
  IssueTracking = "issueTracking",
  Documentation = "documentation",
  GlobalExcel = "globalExcel",
}

const PageContainer = styled(MainSection)({
  flexDirection: "row",
});

const TreeContainer = styled.div({
  flex: 0.2,
  height: "100%",
  borderRight: "1px solid #e8e8e8",
});

const InfoContainer = styled.div({
  marginLeft: "15px",
  display: "flex",
  flex: 0.8,
  flexDirection: "column",
  height: "100%",
});

const ProjectsDetails = WithAuthorization(
  [ROLES.projects],
  [ROLES.documentationReader],
)(ProjectsDetailsContainer);
const ProjectDetailsWithTestPlans = WithTestPlans("project")(ProjectsDetails);

class ProjectsLoaderContainer extends React.Component<
  IConnectProps,
  IProjectsLoaderContainerState
> {
  listener: () => void;

  constructor(props) {
    super(props);
    this.state = {
      section: props.isDocumentationReader ? Routes.Documentation : Routes.Details,
      jobExecutions: {},
      isRunning: false,
      exportModalVisible: false,
    };
    this.listener = this.projectsWebsocketListener.bind(this);

    registerWebsocketHandler(WebsocketMessageTypes.SCHEDULER, this.listener);
  }

  async componentDidMount() {
    if (!this.props.match.params.id) {
      return;
    }

    const { isRunning } = await API.currentExecution({
      projectId: this.props.match.params.id,
    });
    this.setState({
      isRunning,
    });
    this.props.actions.refreshParentBranch(this.props.match.params.id);
  }

  async componentDidUpdate(prevProps: IConnectProps, prevState: IProjectsLoaderContainerState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      if (!this.props.match.params.id) {
        this.setState({
          isRunning: false,
        });
        return;
      }
      this.props.actions.refreshParentBranch(this.props.match.params.id);
      const { isRunning } = await API.currentExecution({
        projectId: this.props.match.params.id,
      });
      this.setState({
        isRunning,
      });
    }
  }

  async componentWillUnmount() {
    await unregisterWebsocketHandler(WebsocketMessageTypes.SCHEDULER, this.listener);
  }

  onJobExecute = async () => {
    const { selectedProject, projectsChildrenMap } = this.props;
    const projectId = selectedProject.id;
    if (projectId) {
      try {
        const { jobId } = await API.runProjectJob({
          projectId,
        });

        this.props.actions.loadSingle({ id: this.props.selectedProject.id });

        this.setState({
          isRunning: true,
        });
      } catch (error) {
        this.removeJobFromCache(projectId);
        toast.error(formatToastText(error.message));
      }
    }
  };

  onJobAbort = async () => {
    const { selectedProject } = this.props;
    const projectId = selectedProject.id;
    await API.terminateProjectJob({
      projectId: selectedProject.id,
    });
    this.removeJobFromCache(projectId);

    this.setState({
      isRunning: false,
    });
  };

  projectsWebsocketListener = (value: WebsocketData<WebsocketMessageTypes.SCHEDULER>) => {
    if (!value) {
      return;
    }

    if (value.type !== "END") {
      return;
    }

    const projectId = value.projectIds.find((projectId) => this.props.selectedProject.id);

    if (projectId) {
      this.setState({
        isRunning: false,
      });
    }
  };

  removeJobFromCache = (projectId) => {
    if (projectId) {
      const executions = { ...this.state.jobExecutions };
      delete executions[projectId];

      this.setState({
        jobExecutions: executions,
      });
    }
  };

  generateBreadcrumbText = (chunk: string, index: number) => {
    if (index !== 1) {
      return chunk;
    }

    const { selectedProject } = this.props;
    if (!selectedProject) {
      return chunk;
    }

    return `${chunk} (${selectedProject.name})`;
  };

  onSectionChange = (path) => {
    this.setState({
      section: path,
    });
  };

  isProjectBeingDispatched = () => {
    const { selectedProject } = this.props;
    const { jobExecutions } = this.state;

    return selectedProject
      ? !!(jobExecutions[selectedProject.id] && jobExecutions[selectedProject.id].isBeingDispatched)
      : false;
  };

  projectNotFound = (params) => params && params.id && !this.props.projects[params.id];

  onExportToTestPlans = () => {
    this.setState({ exportModalVisible: true });
  };

  exportProjectsToTestPlans = async (val) => {
    const { selectedProject } = this.props;
    const projectId = selectedProject && selectedProject.id;
    try {
      await API.exportProjectsToTestPlans(val);
    } catch (error) {
      toast.error("Error while exporting to Test Plans");
      this.closeExportModal();
      if (projectId) {
        this.props.actions.loadSingle({ id: projectId });
      }
      return;
    }
    toast.success("Export successful");

    this.closeExportModal();
    if (projectId) {
      this.props.actions.loadSingle({ id: projectId });
    }
  };
  closeExportModal = () => {
    this.setState({ exportModalVisible: false });
  };

  itsOptions = () =>
    this.props.issueTrackingSystems.map((its) => ({ text: its.name, value: its.id }));

  render() {
    const {
      userGroupMappings,
      virtualUsersMappings,
      systemsMapping,
      selectedProject,
      match,
      isDocumentationReader,
      isDocumentationDisabled,
      projectTree,
      globalSettings,
      userSettings,
      projectsChildrenMap,
    } = this.props;

    const { exportModalVisible, isRunning } = this.state;
    const isBeingDispatched = this.isProjectBeingDispatched();

    if (this.projectNotFound(match.params)) {
      return (
        <Page>
          <BreadcrumbSection>
            <BreadcrumbContainer transformText={this.generateBreadcrumbText} />
          </BreadcrumbSection>
          <MainSection>
            <NonIdealState
              text={<FormattedMessage id={getTranslationKey("projects", "notFound")} />}
            />
          </MainSection>
        </Page>
      );
    }

    let selectedProjectWithChildren: string[] = [];
    if (selectedProject) {
      selectedProjectWithChildren = [
        `${selectedProject.id}`,
        ...(projectsChildrenMap[selectedProject.id] || []).map((id) => `${id}`),
      ];
    }

    return (
      <>
        {exportModalVisible && (
          <BaseModalForm
            visible={exportModalVisible}
            headerText={getTranslationKey("projects", "exportToDevOps")}
            onCancelClick={this.closeExportModal}
            onOkClick={this.exportProjectsToTestPlans}
            allowPrisitineSubmit={true}
            cancelButtonText={getTranslationKey("button", "cancel")}
            okButtonText={getTranslationKey("button", "export")}
            initialValues={{
              itsId: userSettings.its,
              projectIds: selectedProjectWithChildren,
            }}
            render={({ values, change }) => (
              <ExportProjectsForm
                values={values}
                change={change}
                projects={projectTree}
                itsOptions={this.itsOptions()}
                projectsChildrenMap={projectsChildrenMap}
              />
            )}
          />
        )}
        <BreadcrumbSection>
          <BreadcrumbContainer transformText={this.generateBreadcrumbText} />
        </BreadcrumbSection>
        <PageContainer>
          <TreeContainer>
            <ProjectsTreeContainer
              rootPath={ROUTES.projects}
              withActions
              id={tryParseInt(match.params.id)!}
              onJobExecute={this.onJobExecute}
              onJobAbort={this.onJobAbort}
              isExecuting={isRunning}
              isBeingDispatched={isBeingDispatched}
              onExportToTestPlans={this.onExportToTestPlans}
            />
          </TreeContainer>
          <InfoContainer>
            {match.params.id ? (
              <RouteTabs
                onChange={this.onSectionChange}
                urlPart={3}
                defaultActiveKey={""}
                tabs={
                  isDocumentationReader
                    ? [
                        {
                          label: getTranslationKey("projects", "tabs", "documentationScripts"),
                          value: "",
                          render: ({ params }) => (
                            <DocumentationScriptsTableContainer
                              projectId={tryParseInt(match.params.id)!}
                            />
                          ),
                        },
                      ]
                    : (
                        [
                          {
                            label: getTranslationKey("projects", "tabs", "details"),
                            value: Routes.Details,
                            render: () =>
                              globalSettings && globalSettings.TEST_PLANS_INTEGRATION ? (
                                <ProjectDetailsWithTestPlans
                                  project={selectedProject}
                                  isRunning={isRunning}
                                  isBeingDispatched={isBeingDispatched}
                                />
                              ) : (
                                <ProjectsDetails
                                  project={selectedProject}
                                  isRunning={isRunning}
                                  isBeingDispatched={isBeingDispatched}
                                />
                              ),
                          },
                          {
                            label: getTranslationKey("projects", "tabs", "scripts"),
                            value: Routes.Scripts,
                            render: ({ params }) => (
                              <ScriptsTableContainer projectId={tryParseInt(match.params.id)!} />
                            ),
                          },
                          IS_DOCUMENTATION_WIZARD
                            ? undefined
                            : {
                                label: getTranslationKey("projects", "tabs", "testResults"),
                                value: Routes.TestResults,
                                render: ({ params }) => (
                                  <AggregatedJobExecutionAreaChart
                                    projectId={tryParseInt(match.params.id)!}
                                  />
                                ),
                              },
                          isDocumentationDisabled
                            ? undefined
                            : {
                                label: getTranslationKey(
                                  "projects",
                                  "tabs",
                                  "documentationScripts",
                                ),
                                value: Routes.Documentation,
                                render: ({ params }) => (
                                  <DocumentationScriptsTableContainer
                                    projectId={tryParseInt(match.params.id)!}
                                  />
                                ),
                              },
                          {
                            label: getTranslationKey("projects", "tabs", "history"),
                            path: `${Routes.History}/:jobId?`,
                            value: Routes.History,
                            render: ({ params }) => (
                              <ProjectHistoryContainer projectId={tryParseInt(match.params.id)!} />
                            ),
                          },
                          {
                            label: getTranslationKey("projects", "tabs", "userGroups"),
                            value: Routes.UserGroups,
                            render: ({ params }) => (
                              <ProjectMapperContainer
                                markDefault={false}
                                key="userMapper"
                                projectId={tryParseInt(match.params.id)!}
                                stateKey="userGroups"
                                columns={USER_GROUPS_COLUMNS}
                                flowTableId={USER_GROUPS_TABLES_CONFIG.PROJECT_ASSIGNMENT.id(
                                  userGroupMappings[params.id],
                                )}
                                flowPreferencesId={
                                  USER_GROUPS_TABLES_CONFIG.PROJECT_ASSIGNMENT.preferencesId
                                }
                                tableId={USER_GROUPS_TABLES_CONFIG.PROJECT_ASSIGNMENT_DISPLAY.id(
                                  userGroupMappings[params.id],
                                )}
                                preferencesId={
                                  USER_GROUPS_TABLES_CONFIG.PROJECT_ASSIGNMENT_DISPLAY.preferencesId
                                }
                                edit={API.editProject as any} // todo: NEW_TYPES
                                relation="groups"
                                tableActions={getUserGroupTableActions}
                                displayTableSelectable={false}
                              />
                            ),
                          },
                          {
                            label: getTranslationKey("projects", "tabs", "virtualUsers"),
                            value: Routes.VirtualUsers,
                            render: ({ params }) => (
                              <ProjectMapperContainer
                                markDefault
                                key="vuMapper"
                                projectId={tryParseInt(match.params.id)!}
                                stateKey="virtualUsers"
                                columns={VIRTUAL_USERS_COLUMNS}
                                flowTableId={VIRTUAL_USERS_TABLES_CONFIG.PROJECT_ASSIGNMENT.id(
                                  virtualUsersMappings[params.id],
                                )}
                                flowPreferencesId={
                                  VIRTUAL_USERS_TABLES_CONFIG.PROJECT_ASSIGNMENT.preferencesId
                                }
                                tableId={VIRTUAL_USERS_TABLES_CONFIG.PROJECT_ASSIGNMENT_DISPLAY.id(
                                  virtualUsersMappings[params.id],
                                )}
                                preferencesId={
                                  VIRTUAL_USERS_TABLES_CONFIG.PROJECT_ASSIGNMENT_DISPLAY
                                    .preferencesId
                                }
                                edit={API.editProject as any} // todo: NEW_TYPES
                                relation="virtualUsers"
                                tableActions={getVirtualUsersTableActions}
                              />
                            ),
                          },
                          {
                            label: getTranslationKey("projects", "tabs", "systems"),
                            value: Routes.Systems,
                            render: ({ params }) => (
                              <ProjectMapperContainer
                                markDefault
                                key="systemMapper"
                                projectId={tryParseInt(match.params.id)!}
                                stateKey="systemDictionary"
                                columns={SYSTEM_PROJECT_ASSIGNMENT_COLUMNS}
                                displayTableColumns={SYSTEM_DICTIONARY_COLUMNS}
                                flowTableId={SYSTEM_DICTIONARY_TABLES_CONFIG.PROJECT_ASSIGNMENT.id(
                                  systemsMapping[params.id],
                                )}
                                flowPreferencesId={
                                  SYSTEM_DICTIONARY_TABLES_CONFIG.PROJECT_ASSIGNMENT.preferencesId
                                }
                                tableId={SYSTEM_DICTIONARY_TABLES_CONFIG.PROJECT_ASSIGNMENT_DISPLAY.id(
                                  systemsMapping[params.id],
                                )}
                                preferencesId={
                                  SYSTEM_DICTIONARY_TABLES_CONFIG.PROJECT_ASSIGNMENT_DISPLAY
                                    .preferencesId
                                }
                                edit={API.editProject as any} // todo: NEW_TYPES
                                relation="systems"
                                tableActions={getSystemTableActions}
                              />
                            ),
                          },
                          IS_DOCUMENTATION_WIZARD
                            ? undefined
                            : {
                                label: getTranslationKey("projects", "tabs", "issueTracking"),
                                value: Routes.IssueTracking,
                                render: ({ params }) => (
                                  <ProjectMapperContainer
                                    markDefault={false}
                                    key="itsRulesMapper"
                                    projectId={tryParseInt(match.params.id)!}
                                    stateKey="itsRules"
                                    columns={ITS_RULES_COLUMNS}
                                    displayTableColumns={ITS_RULES_COLUMNS}
                                    flowTableId={ITS_RULES_TABLES_CONFIG.PROJECT_ASSIGNMENT.id(
                                      systemsMapping[params.id],
                                    )}
                                    flowPreferencesId={
                                      ITS_RULES_TABLES_CONFIG.PROJECT_ASSIGNMENT.preferencesId
                                    }
                                    tableId={ITS_RULES_TABLES_CONFIG.PROJECT_ASSIGNMENT_DISPLAY.id(
                                      systemsMapping[params.id],
                                    )}
                                    preferencesId={
                                      ITS_RULES_TABLES_CONFIG.PROJECT_ASSIGNMENT_DISPLAY
                                        .preferencesId
                                    }
                                    edit={API.editProject as any}
                                    relation="itsRules"
                                    tableActions={getItsRulesTableActions}
                                    displayTableSelectable={false}
                                  />
                                ),
                              },
                          {
                            label: getTranslationKey("projects", "tabs", "globalExcels"),
                            value: Routes.GlobalExcel,
                            render: ({ params }) => (
                              <GlobalExcelsTableContainer
                                projectId={tryParseInt(match.params.id)!}
                              />
                            ),
                          },
                        ] as (RouteTabParams<Routes> | undefined)[]
                      ).filter<RouteTabParams<Routes>>(
                        (s): s is RouteTabParams<Routes> => s !== undefined,
                      )
                }
              />
            ) : (
              <Info>
                <FormattedMessage id={getTranslationKey("messages", "info", "selectProject")} />
              </Info>
            )}
          </InfoContainer>
        </PageContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(projectActions, dispatch),
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: IProjectsLoaderContainerProps & RouteComponentProps<any>) => ({
    ...props,
    userGroupMappings: getProjectsUserGroupsMappingMap(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    virtualUsersMappings: getProjectsVirtualUsersMappingMap(
      state,
      PROJECTS_TABLES_CONFIG.TREE.id(),
    ),
    systemsMapping: getProjectsSystemsMappingMap(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    selectedProject: projectSelectors.getItemSelector(state, props.match.params.id),
    projectsChildrenMap: getProjectsChildrenMap(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    projects: state.projects.items,
    userSettings: currentUserSettingsSelector(state),
    isDocumentationReader: isCurrentUserDocumentationReader(state),
    projectTree: getProjectsTreeSelector(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    issueTrackingSystems: itsDataSelectors.getOrderedDataSelector(
      state,
      ITS_TABLES_CONFIG.MAIN.id(),
    ),
    globalSettings: state.globalSettings,
    isDocumentationDisabled: isFeatureDisabledSelector(state, FEATURES.DOCUMENTATION),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(ProjectsLoaderContainer));
