import { createDataSelectors } from "@app/modules/app.reducers";
import { currentUserSettingsSelector } from "@ea/shared_components/src/auth/auth.selectors";
import { IssueTrackingTool } from "@ea/shared_types/types";
import { createSelector } from "reselect";

export const itsDataSelectors = createDataSelectors<IssueTrackingTool>()("its");
export const getUserIts = createSelector(
  itsDataSelectors.getDataSelector,
  currentUserSettingsSelector,
  (its, settings) => (settings.its ? its[settings.its] : undefined),
);
