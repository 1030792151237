import { API } from "@app/services/api/api";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { downloadFileBlob } from "@ea/shared_components/helpers/file";
import {
  codeTemplateExtension,
  createVersionKey,
  defaultCodeTemplateVersion,
  getCodeTemplateFileName,
  getLastVersion,
  getNewVersionKey,
} from "@ea/shared_types/helpers/codeTemplates.helpers";
import { CodeTemplate, CodeTemplateGroup, CodeTemplateSaveMode } from "@ea/shared_types/types";
import styled from "@emotion/styled";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../app.reducers";
import { getCodeTemplateGroupsTableActions } from "../codeTemplateGroups/codeTemplateGroups.actions";
import { CODE_TEMPLATE_GROUPS_TABLES_CONFIG } from "../codeTemplateGroups/codeTemplateGroups.table";
import ImportCodeTemplate from "./ImportCodeTemplate";
import { getCodeTemplatesTableActions } from "./codeTemplates.actions";
import { codeTemplatesDataSelectors, getTemplateVersionOptions } from "./codeTemplates.selectors";
import { CODE_TEMPLATES_COLUMNS, CODE_TEMPLATES_TABLES_CONFIG } from "./codeTemplates.table";
import { CodeTemplateUsedInPanel } from "./components/CodeTemplateUsedInPanel";
import CodeTemplatesCommandBar from "./components/CodeTemplatesCommandBar";
import CreateEditCodeTemplatePanel from "./components/CreateEditCodeTemplatePanel";
const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: "75vh",
});

interface CodeTemplatesTableProps {}

interface CodeTemplatesTableState {
  openedPanel?: Panels;
}

enum Panels {
  CREATE = "create",
  EDIT = "edit",
  IMPORT = "import",
  AS_ACTIVE = "as_active",
  USED_IN = "used_in",
}

const CodeTemplatesTableContainer: React.FunctionComponent = ({}: CodeTemplatesTableProps) => {
  const tableId = CODE_TEMPLATES_TABLES_CONFIG.MAIN.id();
  const [openedPanel, setOpenedPanel] = useState<CodeTemplatesTableState["openedPanel"]>();

  const isEdit = openedPanel === Panels.EDIT || openedPanel === Panels.AS_ACTIVE;

  const dispatch = useDispatch();

  const selectedIds = useSelector((state: ApplicationState) =>
    codeTemplatesDataSelectors.getSelectedSelector(state, tableId),
  );

  const selectedItem = useSelector((state: ApplicationState) =>
    codeTemplatesDataSelectors.getSelectedItemSelector(state, tableId),
  );

  const versionOptions = useSelector((state: ApplicationState) =>
    getTemplateVersionOptions(state, selectedItem?.id || 0),
  );

  useEffect(() => {
    dispatch(
      getCodeTemplateGroupsTableActions(CODE_TEMPLATE_GROUPS_TABLES_CONFIG.MAIN.id()).load({}),
    );
  }, []);

  const onAddClick = useCallback(() => {
    setOpenedPanel(Panels.CREATE);
  }, []);

  const onEditClick = useCallback(() => {
    setOpenedPanel(Panels.EDIT);
  }, []);
  const onUsedInClick = useCallback(() => {
    setOpenedPanel(Panels.USED_IN);
  }, []);

  const onReloadClick = useCallback(() => {
    dispatch(getCodeTemplatesTableActions(tableId).load({}));
  }, []);

  const onOk = async (
    values: CodeTemplate & {
      currentlyModifedVersion: number;
      saveMode: CodeTemplateSaveMode;
      codeTemplateGroup: CodeTemplateGroup;
    },
  ) => {
    const { saveMode, codeTemplateGroup, activeVersion, ...rest } = values;
    let newCodeTemplate: CodeTemplate = {
      ...rest,
      activeVersion: activeVersion ? parseInt(`${activeVersion}`, 10) : activeVersion,
    };
    if (
      saveMode === CodeTemplateSaveMode.AS_NEW_VERSION ||
      saveMode === CodeTemplateSaveMode.AS_NEW_ACTIVE_VERSION
    ) {
      const newDefinition = values.definitions[createVersionKey(values.currentlyModifedVersion)];
      newCodeTemplate.definitions = {
        ...initialValues.definitions,
        [getNewVersionKey(values)]: newDefinition,
      };
    }
    if (saveMode === CodeTemplateSaveMode.AS_NEW_ACTIVE_VERSION) {
      newCodeTemplate.activeVersion = getLastVersion(newCodeTemplate);
    }

    if (isEdit) {
      await API.editCodeTemplatesItem(newCodeTemplate);
    } else {
      await API.createCodeTemplatesItem(newCodeTemplate);
    }
    onClose();
  };

  const onClose = useCallback(() => {
    setOpenedPanel(undefined);
    onReloadClick();
  }, []);

  const onRemoveClick = useCallback(() => {
    dispatch(getCodeTemplatesTableActions(tableId).delete({ ids: selectedIds }));
  }, [selectedIds]); // todo check if such optimization makes any sense?

  const initialValues = isEdit
    ? { ...selectedItem, currentlyModifedVersion: selectedItem?.activeVersion }
    : {
        activeVersion: defaultCodeTemplateVersion.value,
        currentlyModifedVersion: defaultCodeTemplateVersion.value,
      };

  const importCodeTemplate = useCallback(() => {
    setOpenedPanel(Panels.IMPORT);
  }, []);

  const exportCodeTemplate = useCallback(async () => {
    if (selectedIds) {
      const blob = await API.exportCodeTemplate({ codeTemplateIds: selectedIds });

      const fileName =
        selectedIds.length === 1 && selectedItem?.functionName
          ? getCodeTemplateFileName(selectedItem)
          : `CodeTemplates.${codeTemplateExtension}`;
      downloadFileBlob(blob, fileName);
    }
  }, [selectedIds]);

  return (
    <Container>
      <ImportCodeTemplate onClose={onClose} visibility={openedPanel === Panels.IMPORT} />
      <CreateEditCodeTemplatePanel
        visibility={openedPanel === Panels.CREATE || openedPanel === Panels.EDIT}
        versionOptions={versionOptions}
        onClose={onClose}
        onSave={onOk}
        initialValues={initialValues}
        isEdit={isEdit}
      />
      <CodeTemplatesCommandBar
        onAddClick={onAddClick}
        onEditClick={onEditClick}
        onReloadClick={onReloadClick}
        onRemoveClick={onRemoveClick}
        selected={selectedIds}
        isEditDisabled={!selectedItem}
        importCodeTemplate={importCodeTemplate}
        exportCodeTemplate={exportCodeTemplate}
        onUsedInClick={onUsedInClick}
      />
      <ConnectedTable
        pageable
        columnsConfig={CODE_TEMPLATES_COLUMNS}
        tableId={tableId}
        preferencesId={CODE_TEMPLATES_TABLES_CONFIG.MAIN.preferencesId}
        stateKey={"codeTemplates"}
        tableActions={getCodeTemplatesTableActions}
      />

      <CodeTemplateUsedInPanel
        visible={openedPanel === Panels.USED_IN}
        onCancel={onClose}
        codeTemplateId={selectedItem?.id}
      />
    </Container>
  );
};

export default CodeTemplatesTableContainer;
