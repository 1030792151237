import { Select } from "antd";
import { ColProps } from "antd/lib/grid/col";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  faEquals,
  faGreaterThan,
  faGreaterThanEqual,
  faLessThan,
  faLessThanEqual,
  faNotEqual,
} from "@fortawesome/fontawesome-free-solid";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { SelectField } from "@ea/shared_components/Form/Fields/SelectField";
import { ComparisonsOperators } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";

interface IAssertConditionOperatorPickerProps {
  prefix: string;
  wrapperCol?: ColProps;
  readOnly?: boolean;
}

const COMPARISONS_CONFIG = {
  [ComparisonsOperators.EQUAL]: {
    text: getTranslationKey("step", "assert", "comparisonOption", "equal"),
    data: {
      icon: faEquals,
    },
  },
  [ComparisonsOperators.NOT_EQUAL]: {
    text: getTranslationKey("step", "assert", "comparisonOption", "notEqual"),
    data: {
      icon: faNotEqual,
    },
  },
  [ComparisonsOperators.GREATER]: {
    text: getTranslationKey("step", "assert", "comparisonOption", "greater"),
    data: {
      icon: faGreaterThan,
    },
  },
  [ComparisonsOperators.GREATER_OR_EQUAL]: {
    text: getTranslationKey("step", "assert", "comparisonOption", "greaterOrEqual"),
    data: {
      icon: faGreaterThanEqual,
    },
  },
  [ComparisonsOperators.LESS]: {
    text: getTranslationKey("step", "assert", "comparisonOption", "less"),
    data: {
      icon: faLessThan,
    },
  },
  [ComparisonsOperators.LESS_OR_EQUAL]: {
    text: getTranslationKey("step", "assert", "comparisonOption", "lessOrEqual"),
    data: {
      icon: faLessThanEqual,
    },
  },
};

const getOption = (key: keyof typeof ComparisonsOperators) => ({
  key,
  ...COMPARISONS_CONFIG[key],
});

const comparisonOptions = Object.keys(COMPARISONS_CONFIG).map((key) => getOption(key as any));

const AssertConditionOperatorPicker: React.FunctionComponent<
  IAssertConditionOperatorPickerProps
> = ({ prefix, readOnly }) => {
  const customRender = (option: any): JSX.Element => {
    return (
      <Select.Option value={option.key}>
        {option.data && option.data.icon && <FontAwesomeIcon icon={option.data.icon} />}
        <span style={{ marginLeft: "8px" }}>
          <FormattedMessage id={getTranslationKey(option.text)} />
        </span>
      </Select.Option>
    );
  };
  return (
    <SelectField
      name={`${prefix}.operator`}
      required
      placeholder={getTranslationKey("step", "placeholder", "selectOperator")}
      readOnly={readOnly}
    >
      {comparisonOptions.map(customRender)}
    </SelectField>
  );
};

export default AssertConditionOperatorPicker;
