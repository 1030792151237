import { CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { ApplicationState } from "@app/modules/app.reducers";
import PlayModeContainer from "@app/modules/common/PlayMode.container";
import RecordModeContainer from "@app/modules/common/RecordMode.container";
import CloneForm from "@app/modules/common/components/CloneForm";
import { scriptsActions } from "@app/modules/scripts/scripts.actions";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { formatScriptName } from "@app/utils/script";
import { startBackgroundSession, startSession } from "@app/utils/start";
import BaseModalForm from "@ea/shared_components/ModalForm/BaseModalForm";
import {
  currentUserNameSelector,
  currentUserSettingsSelector,
} from "@ea/shared_components/auth/auth.selectors";
import { downloadFileBlob } from "@ea/shared_components/helpers/file";
import {
  CommitEditActionTypeParams,
  LoadSingleActionTypeParams,
} from "@ea/shared_components/redux/common.data.actions.types";
import { COLORS } from "@ea/shared_components/styles/consts";
import { DataTestIds } from "@ea/shared_components/utils/dataTestHelpers";
import { generateList } from "@ea/shared_components/utils/tree";
import {
  GLOBAL_SETTINGS_KEYS,
  PLAY_MODE,
  SSO,
  Script,
  ScriptStatus,
  StatusTransitionSettings,
} from "@ea/shared_types/types";
import styled from "@emotion/styled";
import { Button, Checkbox, Modal } from "antd";
import * as PropTypes from "prop-types";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { RouterChildContext } from "react-router";
import { toast } from "react-toastify";
import { ActionCreator } from "typescript-fsa";
import { generateExportScriptName } from "../../utils/transformations";
import { itsDataSelectors } from "../issueTrackingTool/its.selectors";
import { ITS_TABLES_CONFIG } from "../issueTrackingTool/its.table";
import { getProjectsTreeSelector, projectSelectors } from "../projects/projects.selectors";
import { PROJECTS_TABLES_CONFIG } from "../projects/projects.tables";
import "./ActionsContainer.css";
import ChangeStatusForm from "./components/ChangeStatusForm";

enum Modals {
  CLONED_MODAL = "clonedModal",
  CLONE_FORM = "cloneForm",
  PLAY_OPTIONS = "playedOptions",
  RECORD_OPTIONS = "recordOptions",
  CHANGE_STATUS_PANEL = "changeStatusPanel",
  CONFIRM_STATUS_CHANGE = "confirmStatusChagne",
}
interface IActionsContainerState {
  clonedScript: any;
  openedModal?: Modals;
  statusTransitionOptions: { text: string; value: ScriptStatus }[];
  tagOptions: { text: string; value: number }[];
  changeStatusValues?: any;
  isConfirmDisabled: boolean;
}
interface IActionsContainerProps {
  render: any;
  loadSingle: ActionCreator<LoadSingleActionTypeParams<Script>>;
  commitEdit: ActionCreator<CommitEditActionTypeParams<Script>>;
  script: Script;
  run: (evt, params?) => void;
}

const FlexContainer = styled.div({
  display: "flex",
});

const HeaderContainer = styled.h3({
  marginLeft: 10,
});

const ButtonsBox = styled.div({
  "& > button": {
    marginRight: "10px",
  },
  paddingBottom: "15px",
  display: "flex",
  justifyContent: "flex-end",
  flex: 1,
});
const ErrorModal = Modal.error;

const Container = styled.div({});

class ActionsContainer extends React.Component<
  IActionsContainerProps & IConnectProps,
  IActionsContainerState
> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state: IActionsContainerState = {
    openedModal: undefined,
    clonedScript: {},
    statusTransitionOptions: [],
    tagOptions: [],
    changeStatusValues: undefined,
    isConfirmDisabled: true,
  };
  context: RouterChildContext<any>;

  statusTransitions: StatusTransitionSettings;

  async componentDidMount() {
    const tags = await API.getTag({});
    if (!this.props.globalSettings) {
      throw new Error("Error fetching global settings");
    }
    this.statusTransitions = this.props.globalSettings[GLOBAL_SETTINGS_KEYS.STATUS_TRANSITIONS];
    this.setState({
      statusTransitionOptions: this.createStatusTransitionOptions(this.statusTransitions),
      tagOptions: this.createTagOptions(tags),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.script && prevProps.script.status !== this.props.script.status) {
      this.setState({
        statusTransitionOptions: this.createStatusTransitionOptions(this.statusTransitions),
      });
    }
  }

  createStatusTransitionOptions = (statusTransitions) =>
    statusTransitions[this.props.script.status].to.map((status) => ({
      text: <FormattedMessage id={getTranslationKey("scripts", "status", status)} />,
      value: ScriptStatus[status],
    }));

  createTagOptions = (tags) =>
    tags.map((tag) => ({
      id: tag.id,
      text: tag.name,
      value: tag.id,
    }));

  openModal = (modalName: Modals) => {
    this.setState({ openedModal: modalName });
  };

  closeModal = () => {
    this.setState({ openedModal: undefined });
  };

  handleCloneScriptOk = () => {
    const { loadSingle } = this.props;
    const { clonedScript } = this.state;
    loadSingle({ id: clonedScript.id });

    this.context.router.history.push(`/scripts/${clonedScript.id}`);
    this.closeModal();
  };

  changeStatus = async (values) => {
    const { tags, comment, username, password, updateTestCaseStatus, ...rest } = values;
    const { script, commitEdit } = this.props;
    const hasCredentials = username && password;
    let modifiedScript;
    try {
      modifiedScript = await API.patchScript({
        script: {
          id: script.id,
          ...rest,
          integrationMetadata: values.exportToTestCase ? values.integrationMetadata : undefined,
        },
        options: {
          versioning: {
            comment,
            tags: values.tags.map((t) => Number.parseInt(t, 10)),
          },
          credentials: hasCredentials
            ? {
                username,
                password,
              }
            : undefined,
          updateTestCaseStatus,
        },
      });
      if (values.exportToTestCase) {
        modifiedScript = await API.exportScriptToTestCase({
          ...values.integrationMetadata.azureDevops,
          scriptId: script.id,
        });
      }
    } catch (e) {
      toast.error(
        <FormattedMessage id={getTranslationKey("messages", "error", "publishScriptConsole")} />,
      );
      throw e;
    }
    commitEdit(modifiedScript);
    this.props.loadSingle({ id: script.id });
    toast.success(
      <FormattedMessage id={getTranslationKey("messages", "success", "scriptStatusChange")} />,
    );
    this.closeModal();
  };

  cloneScript = async ({ name, projectId, description }) => {
    const { script } = this.props;
    let clonedScript;
    if (!script) {
      return;
    }

    clonedScript = await API.cloneScript({
      scriptIds: [script.id],
      name,
      description,
      projectId,
    }).catch((error) => {
      ErrorModal({
        title: error.message,
      });
    });

    if (clonedScript) {
      this.setState({
        clonedScript: clonedScript[0],
      });
      this.closeModal();
      this.openModal(Modals.CLONED_MODAL);
    }
  };

  exportScript = async () => {
    const { script } = this.props;
    if (script.id) {
      const blob = await API.exportScript({ scriptIds: [script.id] });
      downloadFileBlob(blob, generateExportScriptName({ id: script.id, name: script.name }));
    }
  };

  renderClonedModal = () => {
    const { openedModal, clonedScript } = this.state;
    return (
      <Modal
        visible={openedModal === Modals.CLONED_MODAL}
        title={
          <div>
            <CheckOutlined style={{ fontSize: 16, color: "green", margin: "5px" }} />
            <FormattedMessage id={getTranslationKey("messageBuilder", "clone")} />{" "}
            <FormattedMessage id={getTranslationKey("messageBuilder", "succesfully")} />
          </div>
        }
        okText={<FormattedMessage id={getTranslationKey("button", "goToCloned")} />}
        cancelText={<FormattedMessage id={getTranslationKey("button", "close")} />}
        onCancel={this.closeModal}
        onOk={this.handleCloneScriptOk}
      >
        <p>
          <FormattedMessage id={getTranslationKey("common", "label", "script")} />
          {` ${clonedScript.name} `}
          <FormattedMessage id={getTranslationKey("messageBuilder", "hasBeen")} />{" "}
          <FormattedMessage id={getTranslationKey("messageBuilder", "cloned")} />
        </p>
      </Modal>
    );
  };

  displayProjectSelection = () => {
    const { projectsList, script } = this.props;
    return !projectsList.find((p) => p.id === script.projectId);
  };

  playWithOptions = async (selectedMode?: PLAY_MODE, selectedParams?: any) => {
    const { script, userSettings } = this.props;
    const { playMode, ...defaultParams } = userSettings;
    const mode = selectedMode || playMode || PLAY_MODE.FOREGROUND;
    const optionalParams = { ...defaultParams, ...selectedParams };

    if (!script) {
      return;
    }

    switch (mode) {
      case PLAY_MODE.FOREGROUND:
        startSession(formatScriptName(script), {
          mode: "PLAYER",
          script: script.id,
          isBackground: false,
          ...optionalParams,
        });

        break;
      case PLAY_MODE.BACKGROUND:
        await startBackgroundSession(formatScriptName(script), {
          mode: "PLAYER",
          script: script.id,
          isBackground: true,
          ...optionalParams,
        });
        break;

      default:
        break;
    }
  };

  // Play looks like that in order to avoid passing
  // event as playMode when setting this.play as onClick handler
  play = () => {
    this.playWithOptions();
  };

  itsOptions = () =>
    this.props.issueTrackingSystems.map((its) => ({ text: its.name, value: its.id }));

  onConfirmModalNoClick = () => {
    this.setState({
      openedModal: undefined,
      changeStatusValues: undefined,
      isConfirmDisabled: true,
    });
  };

  onModalConfirmClick = () => {
    this.changeStatus(this.state.changeStatusValues);
    this.setState({ isConfirmDisabled: true });
  };

  getCloseConfirmFooter = () => [
    <Button
      key="cancel"
      onClick={this.onConfirmModalNoClick}
      data-testid={DataTestIds.MODAL_BUTTON_CANCEL}
    >
      <FormattedMessage id={getTranslationKey("button", "no")} />
    </Button>,
    <Button
      danger
      key="ok"
      onClick={this.onModalConfirmClick}
      data-testid={DataTestIds.MODAL_BUTTON_SUBMIT}
      disabled={this.state.isConfirmDisabled}
    >
      <FormattedMessage id={getTranslationKey("button", "yes")} />
    </Button>,
  ];

  render() {
    const {
      openedModal,
      statusTransitionOptions,
      tagOptions,
      changeStatusValues,
      isConfirmDisabled,
    } = this.state;
    const {
      script,
      projects,
      globalSettings,
      currentUserName,
      run,
      userSettings,
      parentProject,
      user,
    } = this.props;

    if (!script) {
      return null;
    }
    const testPlansIntegrationEnabled = globalSettings?.TEST_PLANS_INTEGRATION;
    const scriptExportedToTestCase =
      !!script.integrationMetadata && Object.keys(script.integrationMetadata).length > 0;

    const parentIntegrationMetadata = parentProject?.integrationMetadata?.azureDevops;
    const { itsId, project } = parentIntegrationMetadata || {};
    const initialIntegrationMetadata = {
      azureDevops: {
        ...script.integrationMetadata?.azureDevops,
        ...(parentIntegrationMetadata
          ? { itsId, project }
          : {
              itsId: userSettings.its,
            }),
      },
    };
    const isAADUser = user?.sso === SSO.AzureActiveDirectory;
    const confirmPublishingWithCredentials = globalSettings?.PUBLISHING_CREDENTIALS_CONFIRMATION;

    return (
      <Container>
        <ButtonsBox>
          {this.props.render &&
            this.props.render({
              openClonePanel: () => this.openModal(Modals.CLONE_FORM),
              openPlayOptions: () => this.openModal(Modals.PLAY_OPTIONS),
              play: this.play,
              openChangeStatusPanel: () => this.openModal(Modals.CHANGE_STATUS_PANEL),
              openRecordOptions: () => this.openModal(Modals.RECORD_OPTIONS),
              onExportClick: () => this.exportScript(),
            })}
        </ButtonsBox>
        <BaseModalForm
          visible={openedModal === Modals.CLONE_FORM}
          headerText={<FormattedMessage id={getTranslationKey("scripts", "header", "clone")} />}
          onCancelClick={this.closeModal}
          onOkClick={this.cloneScript}
          allowPrisitineSubmit={true}
          cancelButtonText={<FormattedMessage id={getTranslationKey("button", "cancel")} />}
          okButtonText={<FormattedMessage id={getTranslationKey("button", "clone")} />}
          render={({ change, values }) => (
            <CloneForm
              values={values}
              change={change}
              projects={projects}
              selectedScript={script}
            />
          )}
        />
        {openedModal === Modals.CLONED_MODAL && this.renderClonedModal()}
        <BaseModalForm
          visible={openedModal === Modals.CHANGE_STATUS_PANEL}
          headerText={
            <FormattedMessage id={getTranslationKey("scripts", "header", "changeStatus")} />
          }
          onCancelClick={this.closeModal}
          onOkClick={
            isAADUser && confirmPublishingWithCredentials
              ? (values) =>
                  this.setState({
                    openedModal: Modals.CONFIRM_STATUS_CHANGE,
                    changeStatusValues: values,
                  })
              : this.changeStatus
          }
          allowPrisitineSubmit={true}
          cancelButtonText={<FormattedMessage id={getTranslationKey("button", "cancel")} />}
          okButtonText={<FormattedMessage id={getTranslationKey("button", "save")} />}
          initialValues={{
            version: script.version,
            tags: script.tags ? script.tags.map((t) => t.id) : [],
            integrationMetadata: testPlansIntegrationEnabled
              ? initialIntegrationMetadata
              : undefined,
          }}
          render={({ change, values }) => (
            <ChangeStatusForm
              values={values}
              change={change}
              statusTransitionOptions={statusTransitionOptions}
              tagOptions={tagOptions}
              itsOptions={this.itsOptions()}
              confirmPublishingWithCredentials={confirmPublishingWithCredentials}
              testPlansIntegration={testPlansIntegrationEnabled}
              scriptExportedToTestCase={scriptExportedToTestCase}
              currentUserName={currentUserName}
              projects={projects}
              displayProjectSelection={this.displayProjectSelection()}
              initialStatus={script.status}
              isAADUser={isAADUser}
            />
          )}
        />
        {isAADUser && (
          <Modal
            visible={openedModal === Modals.CONFIRM_STATUS_CHANGE}
            closable={false}
            footer={this.getCloseConfirmFooter()}
            bodyStyle={{ display: "flex", alignContent: "flex-start", flexDirection: "column" }}
          >
            <FlexContainer>
              <ExclamationCircleOutlined
                style={{
                  marginBottom: 20,
                  width: "min-content",
                  color: COLORS.BLUE1,
                  fontSize: 25,
                }}
              />
              <HeaderContainer>
                <FormattedMessage id={getTranslationKey("messages", "confirm", "confirmation")} />
              </HeaderContainer>
            </FlexContainer>
            <FormattedMessage
              id={getTranslationKey("messages", "confirm", "consentScriptInfo")}
              values={{
                name: <strong>{script.name}</strong>,
                status: <strong>{changeStatusValues?.status}</strong>,
              }}
            />
            <Checkbox
              style={{ marginTop: 10 }}
              key="consentCheckbox"
              checked={!isConfirmDisabled}
              onChange={(e) => {
                this.setState({ isConfirmDisabled: !isConfirmDisabled });
              }}
            >
              <FormattedMessage id={getTranslationKey("messages", "confirm", "consentCheckbox")} />
            </Checkbox>
          </Modal>
        )}
        <PlayModeContainer
          visibility={openedModal === Modals.PLAY_OPTIONS}
          onClose={this.closeModal}
          play={this.playWithOptions}
          script={script}
        />
        <RecordModeContainer
          visibility={openedModal === Modals.RECORD_OPTIONS}
          onClose={this.closeModal}
          script={script}
          run={run}
          runnerParams={userSettings.runner}
        />
      </Container>
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: IActionsContainerProps) => {
    const projects = getProjectsTreeSelector(state, PROJECTS_TABLES_CONFIG.TREE.id());
    return {
      globalSettings: state.globalSettings,
      userSettings: currentUserSettingsSelector(state),
      projects,
      projectsList: generateList(projects),
      parentProject: projectSelectors.getItemSelector(state, props.script?.projectId),
      issueTrackingSystems: itsDataSelectors.getOrderedDataSelector(
        state,
        ITS_TABLES_CONFIG.MAIN.id(),
      ),
      user: state.auth.user,
      currentUserName: currentUserNameSelector(state),
    };
  },
  {
    scriptInvalidate: scriptsActions.invalidate,
  },
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(ActionsContainer);
