export const DataTestIdProp = "data-testid";
export const OptionDataTestIdProp = "option-data-testid";

export const DataTestIds = {
  getOptionTestId: (text: string | number) => `option-${text}`,
  getRadioTestId: (text: string | number) => `radio-${text}`,
  getTagTestId: (text: string | number) => `tag-${text}`,

  PANEL_BUTTON_SUBMIT: "panel-button-submit",
  PANEL_BUTTON_CANCEL: "panel-button-cancel",
  PANEL_BUTTON_SELLECT_ALL: "panel-button-sellect-all",
  PANEL_BUTTON_CLEAR: "panel-button-clear",
  PANEL_BUTTON_NEXT: "panel-button-next",
  PANEL_BUTTON_PREV: "panel-button-prev",

  MODAL_BUTTON_SUBMIT: "modal-button-submit",
  MODAL_BUTTON_CANCEL: "modal-button-cancel",

  EDITABLEAREA_BUTTON_EDIT: "editablearea-button-edit",
  EDITABLEAREA_BUTTON_CANCEL: "editablearea-button-cancel",
  EDITABLEAREA_BUTTON_SAVE: "editablearea-button-save",

  PROJECT_TREE_BUTTON_PLUS: "project-tree-button-plus",
  PROJECT_TREE_BUTTON_MINUS: "project-tree-button-minus",
  PROJECT_TREE_INPUT_SEARCH: "project-tree-input-search",

  FORM_SELECT_GLOBAL_VARIABLE: "form-select-global-variable",
  FORM_PICKER_CODE_TEMPLATE: "form-picker-code-template",
  FORM_AUTOCOMPLETE_VARIABLE: "form-autocomplete-variable",

  FORM_INPUT_CODE_VALUE: "form-input-code-value",

  FORM_VALUE_PICKER: "form-value-picker",

  FORM_MONACO_EDITOR_EXPRESSION: "form-monaco-editor-expression",
  FORM_MONACO_EDITOR_ADVANCED: "form-monaco-editor-advanced",

  CODEFIELD_VALUE_WRAPPER: "codefield-value-wrapper",
  CODEFIELD_LABEL_WRAPPER: "codefield-label-wrapper",

  BUTTON_OPEN_TABLE_SETTINGS: "button-open-table-settings",
  BUTTON_ARROW_UP: "button-arrow-up",
  BUTTON_ARROW_DOWN: "button-arrow-down",
  BUTTON_ARROW_LEFT: "button-arrow-left",
  BUTTON_ARROW_RIGHT: "button-arrow-right",
};
