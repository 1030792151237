import { createDataSelectors } from "@app/modules/app.reducers";
import { VirtualUserPool } from "@ea/shared_types/types";
import { createSelector } from "reselect";

export const virtualUserPoolDataSelectors =
  createDataSelectors<VirtualUserPool>()("virtualUserPool");

export const getVirtualUserPoolsFormOptions = createSelector(
  virtualUserPoolDataSelectors.getOrderedDataSelector,
  (virtualUserPools) =>
    Object.keys(virtualUserPools).map((key) => ({
      text: `[${virtualUserPools[key].id}] ${virtualUserPools[key].name}`,
      value: virtualUserPools[key].id,
    })),
);
