import { createModuleEpics } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { DatasourceType } from "@ea/shared_types/next/ea.enums";
import { DataSourceMetadata } from "@ea/shared_types/next/ea.types";
import { moduleActions } from "./globalExcels.actions";
import { globalExcelsDataSelectors } from "./globalExcels.selectors";
import { GLOBAL_EXCELS_COLUMNS_CONFIG } from "./globalExcels.table";

const globalExcelModuleEpics = createModuleEpics<DataSourceMetadata>(moduleActions, {
  normalizeItem: (role) => role,
  getRequestParams: (state, tableId) => {
    const tableParams = globalExcelsDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, GLOBAL_EXCELS_COLUMNS_CONFIG);

    params.filter = {
      ...params.filter,
      where: {
        ...params.filter?.where,
        type: DatasourceType.GLOBAL,
      },
    };

    return params;
  },
  getSingleRequestParams: (query) => {
    query.filter = {
      ...query.filter,
      where: {
        ...query.filter?.where,
        type: DatasourceType.GLOBAL,
      },
    };
    return query;
  },
  api: {
    createItem: API.createGlobalExcel as any,
    deleteItem: API.deleteGlobalExcel,
    editItem: API.editGlobalExcel as any,
    getItems: API.getGlobalExcels,
    getItemsCount: API.getGlobalExcelsCount,
  },
});

export default globalExcelModuleEpics;
