import { getTranslationKey } from "@app/translations/translations.helpers";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { DataSourceMetadata } from "@ea/shared_types/next/ea.types";

export const GLOBAL_EXCELS_COLUMNS_CONFIG: PlainObject<CustomColumn<DataSourceMetadata>> = {
  name: {
    label: getTranslationKey("table", "name"),
    dataIndex: "name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  originalFilename: {
    label: getTranslationKey("table", "filename"),
    dataIndex: "originalFilename",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },

  updatedAt: {
    label: getTranslationKey("table", "updatedAt"),
    dataIndex: "updatedAt",
  },
  modifiedBy: {
    label: getTranslationKey("table", "username"),
    dataIndex: "modifiedBy",
  },
};

export const GLOBAL_EXCELS_COLUMNS: ColumnConfig<DataSourceMetadata>[] = [
  {
    props: GLOBAL_EXCELS_COLUMNS_CONFIG.originalFilename,
    frameworkProps: {
      width: "70%",
    },
  },
  {
    props: GLOBAL_EXCELS_COLUMNS_CONFIG.updatedAt,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: GLOBAL_EXCELS_COLUMNS_CONFIG.modifiedBy,
    frameworkProps: {
      width: "15%",
    },
  },
];

export const GLOBAL_EXCELS_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_GLOBAL_EXCELS",
  },
  PROJECT_ASSIGNMENT: {
    id: (projectId) => `PROJECT_ASSIGNMENT_${projectId}`,
    preferencesId: "GE_PROJECT_ASSIGNMENT",
  },
};
