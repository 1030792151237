import RepeatEveryInput from "@app/modules/scheduler/components/RepeatEveryInput";
import createFormField from "@ea/shared_components/Form/createFormField";

export const RepeatEveryField = createFormField(
  RepeatEveryInput,
  ({ onChange, value, size, min }) => ({
    min,
    size,
    onChange,
    value,
  }),
);

export default RepeatEveryField;
