import styled from "@emotion/styled";
import { Checkbox } from "antd";
import * as React from "react";
import { TableCellProps } from "react-virtualized";

interface ISelectCheckboxProps {
  onClick: (rowData: any, rowIndex: number) => void;
  isSelected: boolean | undefined;
  alwaysVisible?: boolean;
}

export const checkContainerClassName = "list-check-container";
const CheckContainer = styled.div(
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  ({
    isSelected,
    alwaysVisible,
  }: {
    isSelected: boolean | undefined;
    alwaysVisible: boolean | undefined;
  }) => ({
    opacity: alwaysVisible ? 1 : isSelected ? 1 : 0,
  }),
);

const SelectCheckbox: React.FunctionComponent<TableCellProps & ISelectCheckboxProps> = (props) => {
  const onClick = (event: any) => {
    props.onClick(props.rowData, props.rowIndex);
    event.stopPropagation();
  };

  return (
    <CheckContainer
      onClick={onClick}
      isSelected={props.isSelected}
      alwaysVisible={props.alwaysVisible}
      className={checkContainerClassName}
    >
      <Checkbox checked={props.isSelected} />
    </CheckContainer>
  );
};

export default SelectCheckbox;
