import { VirtualUser, System } from "@ea/shared_types/types";
import { OptionType } from "@ea/shared_components/Form/Form.common";

export function convertVirtualUsersToSelectOptions(virtualUsers: VirtualUser[] = []): OptionType[] {
  return virtualUsers.map((vU) => ({
    key: vU.id,
    value: vU.id,
    text: vU.name,
    tooltip: vU.description,
  }));
}
