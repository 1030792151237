import CreateApiKey from "@app/modules/apiKey/components/CreateApiKeyForm";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { Token, User } from "@ea/shared_types/types";
import * as PropTypes from "prop-types";
import * as React from "react";
import { RouterChildContext } from "react-router";

interface ApiKeyContainerProps {
  visibility: boolean;
  onClose: () => void;
  onCreate: (system: Token) => void;
  selected?: Token;
  isEdit?: boolean;
  user: User | undefined;
}

class CreateEditApiKey extends React.Component<ApiKeyContainerProps> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  context: RouterChildContext<any>;

  onOk = async (values) => {
    const { isEdit, onCreate, onClose } = this.props;
    if (isEdit) {
      await API.editToken(values);
    } else {
      if (this.props.user) {
        values.userId = this.props.user.id;
        values.apiKey = true;
        const result = await API.createToken(values);
        if (onCreate) {
          onCreate(result as any);
        }
      }
    }
    onClose();
  };

  render() {
    const { visibility, isEdit, onClose, selected } = this.props;
    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.SMALL}
        headerText={
          isEdit
            ? getTranslationKey("apiKey", "header", "edit")
            : getTranslationKey("apiKey", "header", "create")
        }
        onCancelClick={onClose}
        onOkClick={this.onOk}
        cancelButtonText={getTranslationKey("button", "cancel")}
        key={isEdit ? "edit" : "create"}
        okButtonText={
          isEdit ? getTranslationKey("button", "save") : getTranslationKey("button", "create")
        }
        initialValues={isEdit ? selected : undefined}
        render={({ values }) => <CreateApiKey values={values} />}
      />
    );
  }
}

export default CreateEditApiKey;
