import { Radio as AntdRadio } from "antd";
import { RadioGroupProps } from "antd/lib/radio";
import React from "react";

import { ReadonlyFormItem, SelectableFormItem } from "../Form.common";
import createFormField from "../createFormField";
import { FormattedMessage } from "react-intl";
import { DataTestIds } from "../../utils/dataTestHelpers";

const RadioGroup = AntdRadio.Group;
const RadioButton = AntdRadio.Button;

export enum RadioType {
  Normal = "Normal",
  Button = "Button",
}

const Radio: React.SFC<
  ConditionalOmit<RadioGroupProps, "options"> & { type?: RadioType } & SelectableFormItem &
    ReadonlyFormItem
> = ({ options, children, type = RadioType.Normal, ...rest }) => (
  <RadioGroup disabled={rest.disabled || rest.readOnly} {...rest}>
    {options
      ? options.map((option, index) =>
          type === RadioType.Normal ? (
            <AntdRadio
              key={option.key || index}
              value={option.value}
              disabled={option.disabled}
              data-testid={`radio-${option.value}`}
            >
              <FormattedMessage id={option.text || option.value.toString()} />
            </AntdRadio>
          ) : (
            <RadioButton
              key={option.key || index}
              value={option.value}
              disabled={option.disabled}
              data-testid={DataTestIds.getRadioTestId(option.value)}
            >
              <FormattedMessage id={option.text || option.value.toString()} />
            </RadioButton>
          ),
        )
      : children}
  </RadioGroup>
);

export const RadioField = createFormField(
  Radio,
  ({ name, onChange, value, onBlur, onFocus, type, ...rest }) => ({
    ...rest,
    onChange,
    value,
  }),
);

export default RadioField;
