import { objectValues } from "@ea/shared_components/utils/object";

import * as scriptsActions from "./scripts.actions";
import scriptsEpicsMap from "./scripts.epics";
import * as scriptsReducers from "./scripts.reducers";

const scriptsEpics = objectValues(scriptsEpicsMap);

export { scriptsActions, scriptsReducers, scriptsEpics };

export { default as ScriptsTableContainer } from "./ScriptsTable.container";
export { default as ScriptDetailsContainer } from "./ScriptDetails.container";
