import { createDataSelectors } from "@app/modules/app.reducers";
import { CodeTemplateWithGroup } from "@ea/shared_types/types";
import { parseVersionKey } from "@ea/shared_types/helpers/codeTemplates.helpers";
import { createSelector } from "reselect";
import { OptionType } from "@ea/shared_components/Form/Form.common";

export const codeTemplatesDataSelectors =
  createDataSelectors<CodeTemplateWithGroup>()("codeTemplates");

export const getTemplateVersionOptions = createSelector(
  codeTemplatesDataSelectors.getItemSelector,
  (codeTemplate: CodeTemplateWithGroup): OptionType[] => {
    if (!codeTemplate) {
      return [];
    }

    const { definitions } = codeTemplate;

    return Object.keys(definitions).map((versionKey) => {
      const parsedVersion = parseVersionKey(versionKey);
      return {
        text: parsedVersion,
        value: parsedVersion,
      };
    });
  },
);
