import { createModuleEpics } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { ReportTemplate } from "@ea/shared_types/types";
import { moduleActions } from "./reportTemplates.actions";
import { reportTemplatesDataSelectors } from "./reportTemplates.selectors";
import { REPORT_TEMPLATES_COLUMNS_CONFIG } from "./reportTemplates.table";

const reportTemplatesModuleEpics = createModuleEpics<ReportTemplate>(moduleActions, {
  normalizeItem: (reportTemplate) => reportTemplate,
  getRequestParams: (state, tableId) => {
    const tableParams = reportTemplatesDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, REPORT_TEMPLATES_COLUMNS_CONFIG, {
      defaultOrder: ["createdAt DESC"],
    });
    return params;
  },
  getSingleRequestParams: (params) => {
    return params;
  },
  api: {
    createItem: () => ({} as any),
    deleteItem: API.deleteReportTemplate,
    editItem: API.editReportTemplate as any,
    getItems: API.getReportTemplates as any,
    getItemsCount: API.getReportTemplatesCount,
  },
});

export default { ...reportTemplatesModuleEpics };
