import * as React from "react";

import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import {
  GlobalVariable,
  GlobalVariableType,
  VariableDefaultValueType,
} from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { CheckOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

const typeMap = {
  [GlobalVariableType.CONSTANT]: getTranslationKey("globalVariable", "type", "constant"),
  [GlobalVariableType.MUTABLE]: getTranslationKey("globalVariable", "type", "mutable"),
};

export const GLOBAL_VARIABLES_COLUMNS_CONFIG: PlainObject<CustomColumn<GlobalVariable>> = {
  name: {
    label: getTranslationKey("table", "name"),
    dataIndex: "name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  value: {
    label: getTranslationKey("table", "value"),
    dataIndex: "value",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  type: {
    label: getTranslationKey("table", "type"),
    dataIndex: "type",
    sortable: true,
    render: (text) => <FormattedMessage id={typeMap[text]} />,
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        {
          label: typeMap[GlobalVariableType.CONSTANT],
          value: GlobalVariableType.CONSTANT,
        },
        {
          label: typeMap[GlobalVariableType.MUTABLE],
          value: GlobalVariableType.MUTABLE,
        },
      ],
    },
  },
  valueType: {
    label: getTranslationKey("table", "valueType"),
    dataIndex: "valueType",
    sortable: true,
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        {
          label: getTranslationKey("variable", "type", "text"),
          value: VariableDefaultValueType.Text,
        },
        {
          label: getTranslationKey("variable", "type", "number"),
          value: VariableDefaultValueType.Number,
        },
        {
          label: getTranslationKey("variable", "type", "date"),
          value: VariableDefaultValueType.Date,
        },
      ],
    },
  },
  description: {
    label: getTranslationKey("table", "description"),
    dataIndex: "description",
  },
  isSensitive: {
    label: getTranslationKey("table", "isSensitive"),
    dataIndex: "isSensitive",
    render: (value) => value && <CheckOutlined />,
  },
};

export const GLOBAL_VARIABLES_COLUMNS: ColumnConfig<GlobalVariable>[] = [
  {
    props: GLOBAL_VARIABLES_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: GLOBAL_VARIABLES_COLUMNS_CONFIG.value,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: GLOBAL_VARIABLES_COLUMNS_CONFIG.type,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: GLOBAL_VARIABLES_COLUMNS_CONFIG.valueType,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: GLOBAL_VARIABLES_COLUMNS_CONFIG.description,
    frameworkProps: {
      width: "35%",
    },
  },
];

export const GLOBAL_VARIABLES_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_GLOBAL_VARIABLES",
  },
  SCRIPT_ASSIGNMENT: {
    id: (scriptId) => `SCRIPT_ASSIGNMENT_${scriptId}`,
    preferencesId: "GV_SCRIPT_ASSIGNMENT",
  },
  SCRIPT_VARIABLES_FORM: {
    id: (scriptId) => `GV_SCRIPT_FORM_${scriptId}`,
    preferencesId: "GV_SCRIPT_FORM",
  },
};
