import { initAuthCreator } from "@app/modules/auth/auth.actions";
import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";
import { User } from "@ea/shared_types/types";
import { moduleActions } from "./users.actions";

const initialState = {
  ...getDataReducerInitialState<User>(),
};

export type UserReducer = typeof initialState;

const dataReducer = createDataReducer<User>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState).case(initAuthCreator.done, (state, payload) => ({
  ...state,
  items: {
    ...(state.items as any),
    [payload.result.id]: payload.result,
  },
}));
