import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import Select from "antd/lib/select";
import * as React from "react";

import InputField from "@ea/shared_components/Form/Fields/InputField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import { getIn } from "final-form";

import { SchedulerJob, SchedulerJobRunMode } from "@ea/shared_types/types";

import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";
import { CheckboxField } from "@ea/shared_components/";
import { DatePickerField } from "@ea/shared_components/Form/Fields/DatePickerField";
import { InputNumberField } from "@ea/shared_components/Form/Fields/InputNumberField";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import { EditFormLayout } from "@ea/shared_components/Form/FormLayouts";
interface IGanttTaskEditFormProps {
  values: any;
  schedulerJob: SchedulerJob;
  readOnly?: boolean;
  change: (name: string, value: any) => void;
  configurationOptions?: (OptionType & { project: string })[];
  environmentOptions?: OptionType[];
  virtualUserOptions?: OptionType[];
}

interface IGanttTaskEditFormState {}

class GanttTaskEditForm extends React.Component<IGanttTaskEditFormProps, IGanttTaskEditFormState> {
  clearRepeaterMultiplier = (value) => {
    const { change } = this.props;
    if (!value) {
      change("runParams.repeater.repeaterMultiplier", undefined);
    }
  };

  filterConfigurationOptions = () => {
    const { values, configurationOptions } = this.props;

    const selectedConfigurations = getIn(values, "runParams.azureConfigurationIds") || [];
    const selectedConfigurationsProjects = selectedConfigurations.map(
      (sC) => configurationOptions?.find((cO) => cO.value === sC)?.project,
    );

    return (configurationOptions || []).filter((cO) => {
      return (
        selectedConfigurations.includes(cO.value) ||
        (!selectedConfigurations.includes(cO.value) &&
          !selectedConfigurationsProjects.includes(cO.project))
      );
    });
  };

  onOverrideRunParamsCheckboxChange = (event) => {
    const { change, values, schedulerJob } = this.props;
    const schedulerRunParams = schedulerJob.runParams;

    const currentEnvId = getIn(values, "runParams.environmentId");
    const currentVuId = getIn(values, "runParams.virtualUserId");

    const newCheckedValue = event.target.checked;
    if (!newCheckedValue) {
      change("runParams.environmentId", null);
      change("runParams.virtualUserId", null);
    }
    if (newCheckedValue) {
      !currentEnvId && change("runParams.environmentId", schedulerRunParams?.environmentId);
      !currentVuId && change("runParams.virtualUserId", schedulerRunParams?.virtualUserId);
    }
  };

  render() {
    const { values, readOnly, environmentOptions, virtualUserOptions, schedulerJob } = this.props;
    const kind = getIn(values, "kind");
    const scriptMode = getIn(values, "scriptMode");
    const group = kind === "GROUP" && scriptMode === "NORMAL";
    const repeaterType = getIn(values, "runParams.repeater.repeaterType");
    const overrideRunParams = getIn(values, "overrideRunParams");

    const schedulerRunParams = schedulerJob.runParams;

    const envOverridePlaceholder = !overrideRunParams
      ? environmentOptions?.find((e) => e.value === schedulerRunParams?.environmentId)?.text ||
        getTranslationKey("common", "placeholder", "environment")
      : "";
    const vuOverridePlaceholder = !overrideRunParams
      ? virtualUserOptions?.find((e) => e.value === schedulerRunParams?.virtualUserId)?.text ||
        getTranslationKey("common", "placeholder", "virtualUser")
      : "";

    return (
      <EditFormLayout readOnly={!!readOnly}>
        <InputField
          label={getTranslationKey("common", "label", "name")}
          name="text"
          required
          placeholder={getTranslationKey("common", "placeholder", "name")}
          data-testid={DataTestIds.FORM_INPUT_NAME}
        />
        <TextAreaField
          label={getTranslationKey("common", "label", "description")}
          name="description"
          defaultValue={""}
          placeholder={getTranslationKey("common", "placeholder", "description")}
          autoSize={{ minRows: 2, maxRows: 6 }}
          data-testid={DataTestIds.FORM_TEXTAREA_DESCRIPTION}
        />
        {!group && (
          <DatePickerField
            label={getTranslationKey("scheduler", "settings", "startDateTime")}
            placeholder={getTranslationKey("scheduler", "placeholder", "startDate")}
            name="start_date"
            showTime={{ format: "HH:mm:ss" }}
            timeFormat="YYYY-MM-DD HH:mm:ss"
            required
            data-testid={DataTestIds.FORM_DATEPICKER_START_DATE_TIME}
          />
        )}
        <SelectField
          name="runParams.repeater.repeaterType"
          style={{ width: "100%" }}
          onChange={this.clearRepeaterMultiplier}
          label={getTranslationKey("common", "label", "runMode")}
          options={[
            {
              text: getTranslationKey("scheduler", "runMode", "sequential"),
              value: SchedulerJobRunMode.SEQUENTIAL,
            },
            {
              text: getTranslationKey("scheduler", "runMode", "parallel"),
              value: SchedulerJobRunMode.PARALLEL,
            },
          ]}
          allowClear
          data-testid={DataTestIds.FORM_SELECT_REPEATER_TYPE}
        />
        {repeaterType && (
          <InputNumberField
            required
            style={{ width: "100%" }}
            defaultValue={1}
            step={1}
            min={1}
            name="runParams.repeater.repeaterMultiplier"
            label={getTranslationKey("table", "repeatCount")}
            data-testid={DataTestIds.FORM_INPUT_REPEATER_MULTIPLIER}
          />
        )}
        {kind === "GROUP" && (
          <>
            <SelectField
              allowClear
              name={`runParams.azureConfigurationIds`}
              label={getTranslationKey("projects", "details", "label", "configuration")}
              placeholder={getTranslationKey("projects", "details", "placeholder", "configuration")}
              mode="multiple"
            >
              {this.filterConfigurationOptions().map((configuration) => (
                <Select.Option key={configuration.value} value={configuration.value}>
                  {configuration.text}
                </Select.Option>
              ))}
            </SelectField>
            <CheckboxField
              name="overrideRunParams"
              label={getTranslationKey("commandBar", "override")}
              onChange={this.onOverrideRunParamsCheckboxChange}
            />
            <SelectField
              allowClear
              name={`runParams.environmentId`}
              label={getTranslationKey("common", "label", "environment")}
              placeholder={envOverridePlaceholder}
              disabled={!overrideRunParams}
            >
              {(environmentOptions || []).map((environment) => (
                <Select.Option key={environment.value} value={environment.value}>
                  {environment.text}
                </Select.Option>
              ))}
            </SelectField>

            <SelectField
              allowClear
              name={`runParams.virtualUserId`}
              label={getTranslationKey("common", "label", "virtualUser")}
              placeholder={vuOverridePlaceholder}
              disabled={!overrideRunParams}
            >
              {(virtualUserOptions || []).map((vU) => (
                <Select.Option key={vU.value} value={vU.value}>
                  {vU.text}
                </Select.Option>
              ))}
            </SelectField>
          </>
        )}
      </EditFormLayout>
    );
  }
}

export default GanttTaskEditForm;
