import * as React from "react";
import withIconButton from "./withIconButton";

interface IPlayIconProps {
  size?: number;
}

const PlayIcon: React.FunctionComponent<IPlayIconProps> = ({ size = 24 }) => (
  <svg
    fill="#FFFFFF"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 5v14l11-7z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

export default withIconButton(PlayIcon);
