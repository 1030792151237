import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import { TreeSelectField } from "@ea/shared_components/Form/Fields/TreeSelectField";
import { ProjectTreeNode, Script } from "@ea/shared_types/types";
import * as React from "react";

interface ICloneFormProps {
  readOnly?: boolean;
  values: any;
  change: (name: string, value: any) => void;
  projects: ProjectTreeNode[];
  selectedScript: Script | undefined;
}

const CloneForm: React.FunctionComponent<ICloneFormProps> = ({
  readOnly,
  values,
  change,
  projects,
  selectedScript,
}) => (
  <>
    <TreeSelectField
      label={getTranslationKey("common", "label", "project")}
      name="projectId"
      defaultValue={selectedScript ? selectedScript.projectId : undefined}
      required={true}
      treeDefaultExpandAll
      nodes={projects}
      placeholder={getTranslationKey("common", "placeholder", "project")}
      data-testid={DataTestIds.FORM_SELECT_PROJECT}
    />
    {selectedScript && (
      <>
        <InputField
          label={getTranslationKey("common", "label", "name")}
          name="name"
          readOnly={readOnly}
          placeholder={getTranslationKey("common", "placeholder", "name")}
          data-testid={DataTestIds.FORM_INPUT_NAME}
        />
        <TextAreaField
          label={getTranslationKey("common", "label", "description")}
          name="description"
          defaultValue={""}
          placeholder={getTranslationKey("common", "placeholder", "description")}
          readOnly={readOnly}
          autoSize={{ minRows: 2, maxRows: 6 }}
          data-testid={DataTestIds.FORM_TEXTAREA_DESCRIPTION}
        />
      </>
    )}
  </>
);

export default CloneForm;
