import * as React from "react";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";
interface WorkItemsCommandBarProps {
  onReload: () => void;
}
class WorkItemsCommandBar extends React.Component<WorkItemsCommandBarProps> {
  public render() {
    return (
      <CommandBar data-testid={DataTestIds.COMMANDBAR_WORK_ITEMS}>
        <CommandBarButton
          name="refresh"
          onClick={this.props.onReload}
          text={getTranslationKey("commandBar", "refresh")}
          icon="ReloadOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
        />
      </CommandBar>
    );
  }
}
export default WorkItemsCommandBar;
