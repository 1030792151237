import { ReadStream } from "fs";
import { PlayMode } from "./newRunner.types";
import { CodeExecutionLog, ExecutionCache, ExecutionStep, ScriptData } from "./runner.common.types";

export enum ImportMode {
  CLONE = "CLONE",
  EXPORT = "EXPORT",
}

export const DEFAULT_THROTTLING = {
  beforeExecution: 500,
  afterExecution: 500,
};

export type AdditionalJobParams = {
  noPageCache?: boolean;
  recordVideo?: boolean;
};

export type RemovingScreenshotsStats = {
  isProcessing: boolean;
  days: number;
  stats: {
    total: number;
    done: number;
  };
};

export type ExternalScript = Partial<Pick<Script, "throttling">> &
  Required<Pick<Script, "startUrl" | "name">> & {
    virtualUser?: Required<Pick<VirtualUser, "tokens" | "id">>;
    steps: Step[];
  };

export type PlainObject<T> = { [key: string]: T };

export enum AssignedVariableType {
  LOCAL = "local",
  GLOBAL = "global",
}

export type CodeValue = {
  type: CodeType;
  value: string;
  assignedVariableId?: number;
  assignedVariableType?: AssignedVariableType;
  params?: { value: string; type: CodeParamType }[];
};

export enum PathType {
  XPATH = "XPATH",
  SELECTOR = "SELECTOR",
}

export enum SwitchMode {
  OFF = "Off",
  ON = "On",
}

export enum BoolValue {
  TRUE = "true",
  FALSE = "false",
  NOT_DEFINED = "not_defined",
}

export enum OverrideType {
  VIRTUAL_USER = "virtual_user",
  SYSTEM = "system",
}

export enum RunnerMode {
  RECORDER = "RECORDER",
  PLAYER = "PLAYER",
  COMBINED = "COMBINED",
}

export enum RunnerVersion {
  BASIC = "Basic",
  ADVANCED = "Advanced",
}

export enum RunMode {
  SILENT = "SILENT",
  FULL = "FULL",
}

export enum PlayScope {
  SCRIPT = "Script",
}

export interface Item<T = number> {
  id: T;
}

export interface TimeStampItem extends Item {
  createdAt: Date;
  updatedAt: Date;
}

export type RequiredAdministrationStartParams = {
  script: number | Partial<Script>;
};

export type RequiredForegroundStartParams = {
  mode: RunnerMode;
};

export type CommonStartParams = {
  autoStart: boolean;
  actionHint: boolean;
  hideComments: boolean;
  takeScreenshots: boolean;
  slowMode: boolean;
};

export type ForegroundStartParams = {
  stepByStep: boolean;
  reworkStepId: number;
  runMode: RunMode;
  runner: RunnerParams;
  isCtrlEvt?: boolean;
  newRunner?: boolean;
};

export enum ScriptSpecialExecutionModes {
  DOCUMENT = "DOCUMENT",
  VIDEO = "VIDEO",
  DOC_AND_VIDEO = "DOC_AND_VIDEO",
}
export enum PlayerVisibility {
  VISIBLE = "VISIBLE",
  HIDDEN = "HIDDEN",
  ONLY_CURRENT_STEP = "ONLY_CURRENT_STEP",
}

export type ScriptSpecialExecutionMode = ScriptSpecialExecutionModes;

export type BackgroundStartParams = {
  specialMode?: ScriptSpecialExecutionMode;
  useSpecialMode?: boolean;
  playerVisibilityMode?: PlayerVisibility;
  saveVideoCondition?: SaveVideoCondition;
  slowExecution?: number;
  jobId: string;
  documentationLocale?: number;
  schedulerJobId: number;
  notify: boolean;
  datasourceId?: number;
  additionalStartParams: AdditionalJobParams;
  dispatchStartTime?: Date;
  azureConfigurationId?: number;
};

export type StartForegroundSessionParams = {
  isBackground: false;
} & RequiredAdministrationStartParams &
  RequiredForegroundStartParams &
  Partial<ForegroundStartParams> &
  Partial<ScriptStartOptions> &
  Partial<UserRunnerSettings>;

export type StartBackgroundSessionParams = {
  isBackground: true;
  mode: RunnerMode.PLAYER;
} & RequiredAdministrationStartParams &
  Partial<BackgroundStartParams> &
  Partial<ScriptStartOptions> &
  Partial<UserRunnerSettings>;

export type StartScriptParams = { script: number | ExternalScript };

type StartScriptParams2 =
  | { script: number; mode?: RunnerMode; variables: { name: string; value: any }[] }
  | {
      script: ExternalScript;
      mode?: RunnerMode;
      variables: { name: string; value: any }[];
    };

export type StartExternalSessionParams = StartScriptParams2 &
  Partial<ScriptStartOptions> &
  Partial<UserRunnerSettings>;

export type ScriptStartOptions = {
  startUrl: string;
  takeScreenshots: boolean;
  screenshotsOnlyOnInterruption?: boolean;
  hideComments: boolean;
  showActionHint: boolean;
  timeout: number;
  locale?: string;
  clickOnDisabledButtons?: boolean;
  mapResultWithDevops?: boolean;
  envName?: string;
  autoRefreshDatasource?: boolean;
};

export type StartBackgroundServerParams = {
  isBackground: boolean;
  ignoreLog: boolean;
  scriptOptions?: Partial<ScriptStartOptions>;
  authMode: boolean;
  script: ScriptData;
  variables: { name: string; value: any }[];
};

export enum EXECUTION_SCRIPT_TYPES {
  VIRTUAL_USER_AUTH = "VIRTUAL_USER_AUTH",
}

type InternalRunnerParam =
  | {
      internal: false;
      calledBy?: {
        id?: number;
        username?: string;
      };
      authenticatedVU?: number;
      virtualUserId?: number;
    }
  | {
      internal: EXECUTION_SCRIPT_TYPES.VIRTUAL_USER_AUTH;
      authenticatedVU?: number;
      calledBy?: {
        id?: number;
        username?: string;
      };
      virtualUserId: number;
    };

type TestPlansRunnerParams = {
  testRunMap: TestRunMap;
};

type SchedulerRunnerParams = {
  schedulerJobName?: string;
  schedulerOverride?: {
    virtualUserId?: number;
    environmentId?: number;
  };
};

export type TestRunMap = {
  [scriptId: number]: {
    itsId: number;
    testRunId: number;
    project: string;
    testRunResultId: number;
    testRunResultUrl?: string;
    configurations?: {
      id: number;
      name: string;
      itsId: number;
    }[];
  };
};

export type StartParams = StartExternalSessionParams & {
  isBackground: boolean;
  mode: RunnerMode;
} & Partial<ScriptStartOptions> &
  Partial<BackgroundStartParams> &
  Partial<ForegroundStartParams> &
  Partial<TestPlansRunnerParams> &
  Partial<InternalRunnerParam> &
  SchedulerRunnerParams;

export type RunnerClientSessionData = Partial<ScriptStartOptions> &
  Partial<UserRunnerSettings> & {
    newRunner?: boolean;
    sessionId: string;
    runMode: RunMode;
    resolversMode: GlobalSettingsResolversModes;
    positionResolverThreshold: number;
    generateResolversOnPlayback?: boolean;
    runnerVersion: RunnerVersion;
    token: string;
    internal: boolean;
    timeouts: GlobalSettings[GLOBAL_SETTINGS_KEYS.TIMEOUTS];
    api: string;
    proxy?: string;
    virtualUser: VirtualUser | undefined;
    enableMultiWindowsSupport?: boolean;
    disableWebSecurity?: boolean;
    systemPlatform?: string;
    system?: System;
    disableStartStep?: boolean;
    translations?: any; // TODO types?
    maximumParallelSessions?: number;
    playerVisibilityMode?: PlayerVisibility;
    saveVideoCondition?: SaveVideoCondition;
    slowExecution?: number;
  } & { startUrl: string; isBackground: boolean; mode: RunnerMode };

export type ExecutionControllerParams = StartExternalSessionParams &
  Partial<
    {
      isBackground: boolean;
    } & ForegroundStartParams &
      BackgroundStartParams
  > & {
    sessionId: string;
    token: string;
    startUrl: string;
    url: string;
    integrationMetadata?: ScriptIntegrationMetadata;
    skipLinkedScriptsInDocumentation?: boolean;
  } & Partial<InternalRunnerParam> &
  Partial<TestPlansRunnerParams> &
  SchedulerRunnerParams;

export enum CodeType {
  CONSTANT = "CONSTANT",
  PICK = "PICK",
  EXPRESSION = "EXPRESSION",
  ADVANCED = "ADVANCED",
  CODE_TEMPLATE = "CODE_TEMPLATE",
}

export const OBFUSCATE_PATTERN = "********";

export enum DocumentationStatus {
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
  PENDING = "PENDING",
}

export enum ScriptStatus {
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
  CLOSED = "CLOSED",
  IN_PROGRESS = "IN_PROGRESS",
  READY_TO_TEST = "READY_TO_TEST",
  READY_FOR_REVIEW = "READY_FOR_REVIEW",
}

export type StepsLogsColumnsConfigActions = {
  onScreenshotClick?: (record: StepLog) => void;
};

export enum Platform {
  D365FO = "D365FO",
  D365FS = "D365FS",
  TRANSPOREON = "TRANSPOREON",
  GOOGLE = "GOOGLE",
  STANDARD = "STANDARD",
  VEEVA = "VEEVA",
  MICROSOFT_LOGIN = "MICROSOFT_LOGIN",
  MICROSOFT_LOGIN_OKTA = "MICROSOFT_LOGIN_OKTA",
  OUTLOOK = "OUTLOOK",
  SAPUI = "SAPUI",
  OTHER = "OTHER",
  EP = "EP",
  DEFAULT_PLATFORM = "DEFAULT_PLATFORM",
  CLOUD_POS = "CLOUD_POS",
}

export enum EntityType {
  SCRIPT = "TaskScript",
}

export interface Path {
  path: string;
  type: keyof typeof PathType;
}

export interface BaseAzureIntegrationMetadata {
  testPlanId: string;
  testSuiteId: string;
  itsId: number;
  project: string;
}
export interface ExecutionLogIntegrationMetadata {
  azureDevops?: BaseAzureIntegrationMetadata & {
    testCaseId: string;
    testCaseUrl: string;
    testRunId: number;
    testRunResultId: number;
    testRunResultUrl: string;
    configurations?: IssueTrackingToolConfiguration[];
  };
}

export interface AggregatedLogIntegrationMetadata {
  azureDevops?: BaseAzureIntegrationMetadata & {
    testSuiteDefineUrl: string;
    testSuiteExecuteUrl: string;
  };
}

export interface ScriptIntegrationMetadata {
  azureDevops?: BaseAzureIntegrationMetadata & {
    testCaseId: string;
    testCaseUrl: string;
  };
}

export interface ProjectIntegrationMetadata {
  azureDevops?: BaseAzureIntegrationMetadata & {
    testSuiteDefineUrl: string;
    testSuiteExecuteUrl: string;
  };
}

export interface Project extends Item {
  name: string;
  path: string;
  groups?: UserGroup[];
  virtualUsers?: VirtualUser[];
  systems?: System[];
  defaultSystem?: System;
  overrideGroups: boolean;
  overrideVirtualUsers: boolean;
  overrideSystems: boolean;
  overrideItsRules: boolean;
  category?: number;
  description?: string;
  dueDate?: string;
  startDate?: string;
  owners?: number[];
  defaultSystemId: number;
  defaultVirtualUserId: number;
  version?: string;
  lastSchedulerId?: number; // TODO wymagane
  runMode: SchedulerJobRunMode;
  maximumParallelSessions?: number;
  statusesToRun?: ScriptStatus[];
  itsRules?: IssueTrackingToolRule[];
  tagsToRun?: number[];
  executionParams?: BackgroundStartParams & {
    mapResultWithDevops?: boolean;
    azureConfigurationId?: number;
  };
  integrationMetadata?: ProjectIntegrationMetadata;
  documentationTemplate?: number;
  reportTemplate?: number;
}
export interface ProjectCategory extends Item {
  name: string;
  description?: string;
}

export interface ProjectTreeNode extends Item {
  name: string;
  children: ProjectTreeNode[];
  path: string;
  disabled?: boolean;
  category?: number;
  lastSchedulerId?: number; // TODO wymagane
  integrationMetadata?: {
    azureDevops?: { testPlanId: string; testSuiteId: string; itsId: number; project: string };
  };
}

export interface LogItem extends Item {
  startTime: Date;
  endTime: Date;
  duration: number;
}
export interface Log extends LogItem {
  sessionId: string;
  iteration: number;
  url: string;
  schedulerJobId?: number;
  schedulerJobName?: string;
  internal?: boolean;
  state: EXECUTION_STATE;
  status: EXECUTION_STATUS;
  scriptStatus: ScriptStatus;
  scriptName?: string;
  scriptFlowId?: string;
  scriptId: number;
  datasourcePath?: string;
  isBackground?: boolean;
  apiKeyName?: string;
  scheduler?: SchedulerJob;
  datasource?: DataSource;
  apiKey?: Token;
  projectId: number;
  projectName: string;
  scriptVersion: string;
  jobId?: string;
  jobExecutionId?: string;
  integrationMetadata?: ExecutionLogIntegrationMetadata;
  envName?: string;
  virtualUserName?: string;
  executorName?: string;
  runParams?: BasicSchedulerRunParams;
  lastErrorMessage?: string;
  scriptLogUrl?: string;
  scriptDescription?: string;
  scriptSignedBy?: string;
}

export interface Statistic {
  name: string;
  value: number;
}

export interface StepScreenshots {
  [key: number]: {
    path: string;
    caption?: string;
  };
}
export interface StepsErrorData {
  title: string;
  messages: Message[];
  stepLogId: number;
  screenshot?: string;
  screenshotPath?: string;
}
export interface Guard extends TimeStampItem {
  name: string;
  type: CONDITIONAL_TYPE;
  scriptId: number;
  steps: number[];
  reactions: (
    | {
        reactOn: INTERRUPTION_TYPE;
        activityType: CONDITIONAL_ACTION_TYPE.SIMPLE;
        activity: ActivityAction;
      }
    | {
        reactOn: INTERRUPTION_TYPE;
        activityType: CONDITIONAL_ACTION_TYPE.ADVANCED;
        activity: Activity;
      }
  )[];
  kpis?: Kpi[];
}

export interface UserGroup extends Item {
  name: string;
  users: User[];
  description?: string;
}

export interface DataSource {
  id: number;
  name: string;
  originalFilename: string;
  parentId: number;
  parentType: "TaskScript";
  temporary?: boolean;
  migrated?: boolean;
}

export enum DOCUMENTATION_TYPE {
  VIDEO = "VIDEO",
  DOCUMENT = "DOCUMENT",
}
export interface Documentation extends TimeStampItem {
  id: number;
  name: string;
  prettyName: string;
  scriptId: number;
  sessionId?: string;
  script: Script;
  description: string;
  path: string;
  version: string;
  locale: string;
  signedBy?: string;
  type: DOCUMENTATION_TYPE;
  status: DocumentationStatus;
}

export interface Localization extends TimeStampItem {
  // id: number;
  locale: string;
  description: string;
  needsAttention?: boolean;
}
export interface ReportTemplate extends TimeStampItem {
  name: string;
  filename: string;
  originalFilename: string;
  description?: string;
}

export interface StepLog extends LogItem {
  // stepId: number;
  // taskScriptId: number;
  executionId: number;
  status: any;
  label: string;
  variableLogs: CodeExecutionLog[];
  lineNum: number;
  screenshotPath?: string;
  isBackground: boolean;
  linkedScriptId: number;
  parentStepLogId: number;
  sessionId: string;
  messages: Message[];
  // intermediateEndTime: Date;
  // parentId: number;
  nextStepScreenshotPath?: string;
  previousStepScreenshotPath?: string;
  workItem?: ItsWorkItem;
  labelKey?: string;
  labelParams?: LabelParams;
  kpi?: number[];
}
export interface AggregatedJobLog extends LogItem {
  jobId: string;
  jobExecutionId?: string;
  state: EXECUTION_STATE;
  successCount: number;
  warningCount: number;
  errorCount: number;
  allCount: number;
  projectId?: number;
  projectName?: string;
  schedulerJobName?: string;
  schedulerJobId?: number;
  status: EXECUTION_STATUS;
  isExcluded: boolean;
  integrationMetadata?: AggregatedLogIntegrationMetadata;
  mappedWithDevops?: boolean;
}

export interface System<T = any> extends Item {
  envName: string;
  envAddress: string;
  envType: SYSTEM_TYPE;
  applicationName: string;
  description: string;
  userId?: number;
  systemPlatform: string;
  options?: {
    enableMultiWindowsSupport?: boolean;
    disableWebSecurity?: boolean;
  };
  meta: T;
  virtualUsers?: VirtualUser[];
}
export interface SystemFormOption extends System {
  text: string;
  value: number;
}
export interface Storage extends Item {
  name: string;
  account: string;
  apiKey: string;
  storageType: string;
}

export interface VirtualUserPool extends Item {
  name: string;
  description?: string;
  virtualUsers?: VirtualUser[];
}
export interface VirtualUser extends Item {
  name: string;
  description?: string;
  isSystemAuthEnabled: boolean;
  errorMessage: string;
  systemData:
    | {
        useCustomScript: false;
        isManual?: boolean;
        platform: string;
        rememberSensitiveData?: boolean;
        values: PlainObject<{ value: any; isSensitive: boolean }>;
        reauthenticateInterval?: number;
        reauthenticate?: boolean;
      }
    | {
        useCustomScript: true;
        customScriptId: number;
        isManual?: boolean;
        rememberSensitiveData?: boolean;
        values: { key: string; value: string; isSensitive: boolean; isSystemLogin?: boolean }[];
        reauthenticateInterval?: number;
        reauthenticate?: boolean;
      };
  serverAuthType: VIRTUAL_USER_AUTH_TYPE;
  status?: VIRTUAL_USER_STATUS;
  serverData: {
    workstation?: string;
    domain?: string;
    login: string;
    password: string;
  };
  lastAuthenticationDate: Date;
  tokens?: {
    localStorage: [string[], string[]];
    sessionStorage: [string[], string[]];
    cookies: Object;
  };
}

export type EAFile = {
  originalFilename: string;
  name: string;
} & (
  | {
      temporary: false;
      path: string;
    }
  | {
      temporary: true;
      path?: string;
    }
);

export enum SSO {
  AzureActiveDirectory = "AzureActiveDirectory",
  SAML = "SAML",
}

export interface Token extends Item<string> {
  ttl: number;
  created: Date;
  name: string;
  apiKey: boolean;
  userId: number;
  sso?: SSO;
}

export interface Sequence extends TimeStampItem {
  name: string;
  format: string;
  nextval: number;
}

export enum VIRTUAL_USER_STATUS {
  NOT_AUTHENTICATED = "NOT_AUTHENTICATED",
  AUTHENTICATED = "AUTHENTICATED",
  AUTHENTICATING = "AUTHENTICATING",
  ERROR = "ERROR",
}

export enum VIRTUAL_USER_AUTH_TYPE {
  NONE = "NONE",
  BASIC_AUTH = "BASIC_AUTH",
  NTLM = "NTLM",
  SYSTEM = "SYSTEM",
}

export enum TOKEN_TYPE {
  COOKIES = "COOKIES",
  STORAGE = "STORAGE",
}

export enum SYSTEM_TYPE {
  PROD = "production",
  DEV = "development",
  TEST = "test",
  UAT = "uat",
  OTHER = "other",
}

export enum TTL {
  T90 = 7776000,
  T180 = 15552000,
  T365 = 31536000,
}

export const TTL_LABELS = {
  [TTL.T90]: "90 Days",
  [TTL.T180]: "180 Days",
  [TTL.T365]: "1 Year",
};
export enum STORAGE_TYPE {
  AZURE = "azureStorage",
}

export const STORAGE_TYPE_LABELS = {
  [STORAGE_TYPE.AZURE]: "Azure Storage",
};

export const SYSTEM_TYPE_LABELS = {
  [SYSTEM_TYPE.PROD]: "Production",
  [SYSTEM_TYPE.DEV]: "Development",
  [SYSTEM_TYPE.TEST]: "Test",
  [SYSTEM_TYPE.UAT]: "Uat",
  [SYSTEM_TYPE.OTHER]: "Other",
};

export enum URL_SELECT_MODE {
  MANUAL = "manual",
  ENVIRONMENT = "environment",
}

export enum PLAY_MODE {
  FOREGROUND = "foreground",
  BACKGROUND = "background",
}
export interface Group extends Item {
  name: string;
  type: EntityType.SCRIPT;
  publishedCount: number;
}

export enum RECORDER_EXECUTION_STATE {
  NONE = "NONE",
  RECORDING = "RECORDING",
  PAUSED = "PAUSED",
  FINISHED = "FINISHED",
}

export enum EXECUTION_STATE {
  NONE = "NONE",
  RUNNING = "RUNNING",
  PAUSED = "PAUSED",
  TERMINATED = "TERMINATED",
  FINISHED = "FINISHED",
}

export enum TEST_RUN_STATE {
  UNSPECIFIED = "Unspecified",
  NOT_STARTED = "NotStarted",
  IN_PROGRESS = "InProgress",
  COMPLETED = "Completed",
  WAITING = "Waiting",
  ABORTED = "Aborted",
  QUEUED = "Queued",
}

export enum EXECUTION_STATUS {
  ERROR = "ERROR",
  WARNING = "WARNING",
  SUCCESS = "SUCCESS",
  NONE = "NONE",
  UNTESTED = "UNTESTED",
}

export const TEST_RUN_OUTCOME_STATUS_MAP = {
  [EXECUTION_STATUS.ERROR]: "Failed",
  [EXECUTION_STATUS.SUCCESS]: "Passed",
  [EXECUTION_STATUS.WARNING]: "Warning",
};

export enum STATUS_TYPE {
  ERROR = "ERROR",
  WARNING = "WARNING",
  SUCCESS = "SUCCESS",
  RUNNING = "RUNNING",
  TERMINATED = "TERMINATED",
  NONE = "NONE",
  UNTESTED = "UNTESTED",
  // TODO: \/ to remove, mark execution as background
  RUNNING_IN_BACKGROUND = "RUNNING IN BACKGROUND",
}

export enum JOB_STATUS_TYPE {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  COMPLETED = "COMPLETED",
  CLOSED = "CLOSED",
  RUNNING = "RUNNING",
}

export const STATUS_STRENGTH = {
  [STATUS_TYPE.SUCCESS]: 0,
  [STATUS_TYPE.WARNING]: 1,
  [STATUS_TYPE.ERROR]: 2,
};

export enum MESSAGE_TYPE {
  ERROR = "ERROR",
  WARNING = "WARNING",
  INFO = "INFO",
  ANALYSIS = "ANALYSIS",
}
export const STEP_MESSAGE_ICON_MAP = {
  [MESSAGE_TYPE.WARNING]: "warning",
  [MESSAGE_TYPE.ERROR]: "exclamation-circle-o",
  [MESSAGE_TYPE.INFO]: "info-circle-o",
};

enum LabelParamsKeys {
  value = "value",
  type = "type",
  controlName = "controlName",
  counter = "counter",
  limit = "limit",
  timeout = "timeout",
  status = "status",
  result = "result",
  stepLabel = "stepLabel",
  paths = "paths",
  element = "element",
  lookup = "lookup",
  containerName = "containerName",
  scriptName = "scriptName",
  leftConditionValue = "leftConditionValue",
  operator = "operator",
  menuPathLabel = "menuPathLabel",
  menuItemName = "menuItemName",
  message = "message",
  segmentName = "segmentName",
  rightConditionValue = "rightConditionValue",
  logicalOperator = "logicalOperator",
  rightCondition = "rightCondition",
  leftCondition = "leftCondition",
  comparisonResult = "comparisonResult",
  stepLabelKey = "stepLabelKey",
  kpiNames = "kpiNames",
  virtualUserName = "virtualUserName",
  systemName = "systemName",
}

export type LabelParams = Partial<{ [key in keyof typeof LabelParamsKeys]: string | number }>;

export type Message = {
  text: string;
  type: MESSAGE_TYPE;
  // todo R2.0 - give me better name, errorType should contains error / warning type like NoElementError,
  errorType?: string;
  labelKey?: string;
  labelParams?: LabelParams;
  showToast?: boolean;
  isPlatformMessage?: boolean;
  overrideStatus?: EXECUTION_STATUS;
};

export type LogMessage = {
  labels: Pick<Step, "labelKey" | "labelParams" | "label" | "lineNum">[];
  level: number;
  path: string;
  messages: Pick<Message, "labelKey" | "labelParams" | "text" | "type">[];
};

export type MessagesWinner = {
  status: EXECUTION_STATUS;
  messages: Message[];
  isPlatform: boolean;
  isApplication: boolean;
  overrideStatus?: EXECUTION_STATUS;
};

export enum ROLES {
  scheduler = "scheduler",
  reports = "reports",
  logs = "logs",
  settings = "settings",
  freedocs = "freedocs",
  admin = "admin",
  projects = "projects",
  documentationCreator = "documentation_creator",
  documentationReader = "documentation_reader",
}

export interface Role extends Item {
  name: string;
  description: string;
}

export interface ResolversData {
  metadata: Record<string, any>[];
  data: any;
}

export interface RecorderStep<T extends { value: any; id: any; version: any } = any> {
  structureVersion: T["version"];
  paths: Path[];
  label?: string | null;
  resolvers?: ResolversData;
  commandId: T["id"]; // todo change to Command type
  value: T["value"];
  platform: PlatformMetadata; // TODO: FIX BEFORE MERGE
  messages?: Message[];
  // status?: STATUS_TYPE;
  delay?: number;
  triggersRefresh?: boolean;
  isOptional?: boolean;
  temporaryStepId?: number | string;
  frames?: IFrame[];
  files?: string[];
  rule?: RuleMetadata;
  labelKey?: string;
  labelParams?: LabelParams;
  modified?: boolean;
  synced?: boolean;
  lastModified?: Date;
  guards?: Guard[];
  metadata?: StepElementsMetadata;
}

export interface StepElementsMetadata {
  evtTarget?: ElementMetadata;
  customTarget?: ElementMetadata;
  additionalElement?: ElementMetadata;
}

export type DefaultElementMetadata = {
  id?: string;
  className?: string;
  tagName?: string;
  title?: string;
  textContent?: string;
  role?: string;
  ariaLabel?: string;
  ariaHidden?: string;
  ariaDisabled?: string;
  disabled?: string;
  name?: string;
  type?: string;
};

export type DFOElementMetadata = {
  isDFO?: boolean;
  dataDynControlname?: string;
  dataDynRole?: string;
  dataDynRowId?: string;
  dataDynContainer?: string;
  dataDynSuperTooltip?: string;
  dataDynContent?: string;
  command?: string;
  context?: {
    enabled?: string | boolean;
    visible?: string | boolean;
  };
};
export type CRMElementMetadata = {
  isCRM?: boolean;
  dataType?: string;
  dataText?: string;
  dataId?: string;
  dataLpId?: string;
  dataControlName?: string;
  dataPaNotification?: string;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  dataPreviewOrientation?: string;
  value?: string;
};

export type PlatfromElementMetadata = DFOElementMetadata | CRMElementMetadata;

export type ElementMetadata = DefaultElementMetadata & PlatfromElementMetadata;

export enum StepTerminationType {
  TIMEOUT = "TIMEOUT",
  NUMBER_OF_EXECUTIONS = "NUMBER_OF_EXECUTIONS",
}

export enum StepTerminationAction {
  IGNORE_STEP = "IGNORE_STEP",
  TERMINATE_EXECUTION = "TERMINATE_EXECUTION",
}
export interface StepTermination {
  type: StepTerminationType;
  value: number;
  action: StepTerminationAction;
}

export interface StepThrottling {
  beforeExecution: number;
  afterExecution: number;
}
export interface Step<T extends { value: any; id: any; version: any } = any>
  extends Item,
    RecorderStep<T> {
  id: number;
  taskScriptId: number;
  lineNum: number;

  throttling?: StepThrottling;

  comment?: string;
  isTimeoutEnabled?: boolean;
  timeout?: number;

  isDisabled?: boolean;
  termination?: StepTermination;
  updatedAt?: Date;
  createdAt?: Date;
  guards?: Guard[];
  manualLabel?: boolean;
  linkedScriptId?: number;
  disableLinkedStartStep?: boolean;
  linkName?: string;
  isOneTime?: boolean;
  version?: string;
  executionCount?: number;
  averageDuration?: number;
  takeScreenshots?: boolean;
  hasBeenLabelModified?: boolean;
}

export interface RuleMetadata {
  id: string;
  version?: string;
  eventType: string;
  platform?: string;
  metadata?: StepElementsMetadata;
}

export enum Extension {
  XLSX = ".xlsx",
}

export interface PlatformMetadata {
  id: string;
  version?: string;
  options?: {
    forceOpenPopup?: boolean;
  };
}

export type XPATH_SEARCH_TYPE = "SINGLE" | "MULTI";

export interface IFrame {
  className?: string;
  id?: string;
  name?: string;
  parentId?: string;
  parentTagName?: string;
  parentAttributes?: {
    name: string;
    value: string;
  }[];
  iframeIndex?: number;
  ariaLabel?: string | null;
}

export type PlayerStep<T extends { value: any; id: any; version: any } = any> = ExecutionStep<T>;

export enum VariableGroups {
  SCRIPT = "Script",
  SCRIPTS = "Scripts",
  DATA_SOURCE = "DataSource",
  SEQUENCE = "Sequence",
  GLOBAL = "Global",
}

export interface Script extends Item {
  publishedDocuments: number;
  documents: number;
  version: string;
  startUrl: string;
  isManualUrl: boolean;
  description: string;
  name: string;
  loopNumber: string;
  creatorId: number;
  createdAt: Date;
  environmentId: number;
  envName: string;
  envType: SYSTEM_TYPE;
  stepCount: number;
  creator: string;
  useVirtualUser: boolean;
  virtualUserId?: number;
  updatedAt?: Date;
  takeScreenshots?: boolean;
  screenshotsOnlyOnInterruption?: boolean;
  timeout?: number;
  showActionHint?: boolean;
  hideComments?: boolean;
  datasource?: any;
  projectId: number;
  status: ScriptStatus;
  clickOnDisabledButtons?: boolean;
  tags?: Tag[];
  integrationMetadata?: ScriptIntegrationMetadata;
  environment?: System;
  documentationTemplate?: number;
  reportTemplate?: number;
  throttling?: StepThrottling;
  autoRefreshDatasource?: boolean;
  globalVariables?: GlobalVariable[];
  lastExecutionStatus?: EXECUTION_STATUS;
  lastExecutorName?: string;
  lastExecutionDate?: Date;
  disableStartStep?: boolean;
  skipLinkedScriptsInDocumentation?: boolean;
  resolvers?: {
    enabled: boolean;
    mode: GlobalSettingsResolversModes;
  };
}

export interface ScriptGroup extends Item {
  name: string;
}

export enum SupportedLanguages {
  PL = "PL",
  EN = "EN",
}
export interface User extends Item {
  realm: string;
  username: string;
  password?: string;
  email: string;
  emailVerified: boolean;
  language: keyof typeof SupportedLanguages;
  settings: UserSettings;
  userId: number;
  groups?: UserGroup[];
  roles?: Role[];
  isActive: boolean;
  sso?: SSO;
}

export interface AuthUser extends User {
  projects: number[];
}

export const PROJECT_ROOT = "root";

export interface UserRunnerSettings {
  autoStart: boolean;
  stepByStep: boolean;
  slowMode: boolean;
}

export interface TableSettings {
  [key: string]: { hiddenColumns: string[] };
}

export interface UserSettings extends Item, UserRunnerSettings {
  playMode: PLAY_MODE;
  table?: TableSettings;
  displayOnlyPublishedDocuments?: boolean;
  reportExtension: ReportExtension;
  hideAggregatedLogsView?: boolean;
  documentationLocale?: number;
  its?: number;
  runner?: RunnerParams;
}

interface SchedulerJobBlueprint extends TimeStampItem {}

export enum SchedulerMode {
  BASIC = "BASIC",
  GANTT = "GANTT",
}

type SchedulerJobCommon = {
  name: string;
  description: string;
  suspended: boolean;
  status: JOB_STATUS_TYPE;
  userId: number;
  maximumParallelSessions?: number;
  notificationEmails: string;
  isInternal: boolean;
  mapResultWithDevops?: boolean;
  activatedByUser?: () => User;
  integrationMetadata?: SchedulerIntegrationMetadata;
  runParams?: BasicSchedulerRunParams;
} & SchedulerJobBlueprint;

export type SchedulerJobGantt = {
  mode: SchedulerMode.GANTT;
  groups: SchedulerGroup[];
} & SchedulerJobCommon;

export type SchedulerJobBasic = {
  mode: SchedulerMode.BASIC;
  startDateTime: string;
  endDateTime: string;
  offset: number;
  occurrences: number;
  recurrenceType: RecurrenceType;
  recurrenceTypeSingular: RecurrenceTypeSingular;
  schedulerJobMapping: Partial<SchedulerJobMapping>[];
  triggerType: JOB_TRIGGER_TYPE;
  storageId: number;
  storageTriggerContainer: string;
  projects: number[];
  runMode: SchedulerJobRunMode;
  repeatCount: number;
} & SchedulerJobCommon;

export type SchedulerJob = SchedulerJobGantt | SchedulerJobBasic;

export enum SchedulerJobRunMode {
  PARALLEL = "PARALLEL",
  SEQUENTIAL = "SEQUENTIAL",
}

export enum JOB_TRIGGER_TYPE {
  INSTANT = "instant",
  TIME = "time",
  STORAGE = "storage",
}

export const JOB_TRIGGER_TYPE_LABELS = {
  [JOB_TRIGGER_TYPE.TIME]: "Time",
  [JOB_TRIGGER_TYPE.STORAGE]: "Storage",
};

export enum EndType {
  afterOccurrences = "afterOccurrences",
  onDate = "onDate",
  never = "never",
}

export enum RecurrenceType {
  minutes = "minutes",
  hours = "hours",
  days = "days",
  weeks = "weeks",
  months = "months",
  years = "years",
}

export enum RecurrenceTypeSingular {
  minutes = "minute",
  hours = "hour",
  days = "day",
  weeks = "week",
  months = "month",
  years = "year",
}

export enum TimeScheluderLabel {
  once = "once",
  times = "times",
}

export interface SchedulerJobMapping extends Item {
  name: string;
  schedulerJobId: number;
  taskScriptId: number;
  groupId: number;
  lineNum: number;
  url: string;
  repeatCount: number;
  script?: Script;
  projectId?: number;
  runParams?: BasicSchedulerRunParams;
  projectEnvType?: SYSTEM_TYPE;
  projectEnvName?: string;
  overrideRunParams?: boolean;
  projectDefinition?: Project;
  envType?: SYSTEM_TYPE;
  envName?: string;
}

export interface Tag extends TimeStampItem {
  name: string;
  description: string;
}

export enum VariableDefaultValueType {
  Text = "Text",
  Number = "Number",
  Date = "Date",
}

export enum VariableType {
  Normal = "Normal",
  DataSource = "DataSource",
  Sequence = "Sequence",
}

export type VariableParentType = "TaskScript" | "Sequence" | "Global";

export interface RecorderVariable {
  value?: any;
  name: string;
  temporaryStepId?: number;
}

export interface Variable extends Item, RecorderVariable {
  groupName?: string;
  defaultValue?: any;
  parentId: number;
  parentType: VariableParentType;
  valueType?: VariableDefaultValueType;
  type: VariableType;
}

export enum GlobalVariableType {
  CONSTANT = "Constant",
  MUTABLE = "Mutable",
}
export interface GlobalVariable extends TimeStampItem {
  name: string;
  value: string;
  type: GlobalVariableType;
  valueType?: VariableDefaultValueType;
  isSensitive?: boolean;
  description?: string;
}
export interface Kpi extends TimeStampItem {
  name: string;
  description?: string;
}

export type AdvancedCodeFunctionName = "eaExecuteVariableValue";

export type VariableMapping = {
  sourceId: number;
  sourceType: VariableParentType;
  parentId: number;
  parentType: VariableParentType;
  variable: Partial<Variable>;
};

export interface Datasource extends Item {
  parentId: number;
  name: string;
  originalFilename: string;
  storageModifiedTime?: Date;
  sheetsMeta?: any;
  jobId?: number;
}

export interface VariableMap extends Item {
  stepId: number;
  linkId?: number;
  sourceVariableId?: number;
  destVariableId?: number;
  variableType?: AssignedVariableType;
  sourceVariableType?: AssignedVariableType;
  destVariableType?: AssignedVariableType;
}

export interface Sequence extends Item {
  name: string;
  format: string;
}

export enum ComparisonsOperators {
  EQUAL = "EQUAL",
  NOT_EQUAL = "NOT_EQUAL",
  LESS = "LESS",
  LESS_OR_EQUAL = "LESS_OR_EQUAL",
  GREATER = "GREATER",
  GREATER_OR_EQUAL = "GREATER_OR_EQUAL",
}

export enum ComparisonsOperatorsSymbols {
  EQUAL = "=",
  NOT_EQUAL = "≠",
  LESS = "<",
  LESS_OR_EQUAL = "<=",
  GREATER = ">",
  GREATER_OR_EQUAL = ">=",
}

export enum ConditionTypes {
  VARIABLE = "VARIABLE",
  STEP_VALUE = "STEP_VALUE",
  CONST = "CONST",
  GLOBAL_CONSTANT = "GLOBAL_CONSTANT",
  GLOBAL_MUTABLE = "GLOBAL_MUTABLE",
}

export enum AssertActions {
  JUMP = "JUMP",
  LOG = "LOG",
  WARNING = "WARNING",
  CONTINUE = "CONTINUE",
  ERROR = "ERROR",
  DATA_SOURCE_AND_JUMP = "DATA_SOURCE_AND_JUMP",
  REPEAT = "REPEAT",
  INCREASE_KPI = "INCREASE_KPI",
}

export enum AssertionType {
  CHECK_VALUE = "CHECK_VALUE",
  DATA_SOURCE = "DATA_SOURCE",
}

export enum DataSourceComparisonType {
  HAS_NEXT = "HAS_NEXT",
}

export type ConditionValue =
  | {
      type:
        | ConditionTypes.CONST
        | ConditionTypes.VARIABLE
        | ConditionTypes.GLOBAL_CONSTANT
        | ConditionTypes.GLOBAL_MUTABLE;
      value: string;
    }
  | {
      type: ConditionTypes.STEP_VALUE;
      value: number;
    };

export type CheckValueComparison = {
  type: AssertionType.CHECK_VALUE;
  operator: ComparisonsOperators;
  leftCondition: ConditionValue;
  rightCondition: ConditionValue;
  logicalOperator?: "and" | "or";
};

export type DataSourceComparison = {
  type: AssertionType.DATA_SOURCE;
  comparisonType: DataSourceComparisonType;
  value: string;
  resetOnLast?: boolean;
  logicalOperator?: "and" | "or";
};

export type ActivityAction = {
  type: AssertActions;
  values: any[];
  message?: string;
  overrideStatus?: boolean;
  overrideStatusTo?: EXECUTION_STATUS;
};

export type ActivityExecution = {
  ifAction: ActivityAction;
  elseAction: ActivityAction;
};

export type Activity = {
  comparison: (DataSourceComparison | CheckValueComparison)[];
} & ActivityExecution;

// todo: take from browser?
export const DefaultLanguage = SupportedLanguages.EN;

export enum CommandsBasic {
  start = "start",
  end = "end",
  login = "login",
  inspect = "inspect",
}

export enum CommandsCPOS {}

export enum CommandsD365FO {}

export enum CommandsD365FS {
  fastTabClick = "fastTabClick",
  gridSelectRow = "gridSelectRow",
  gridSelectRow2 = "gridSelectRow2",
  selectSegmentEntry = "selectSegmentEntry",
  lookup = "lookup",
}

export enum CommandsBEFashion {
  beFashionExcel = "beFashionExcel", // temporary
  beFashionExcelEditStart = "beFashionExcelEditStart", // temporary
  beFashionExcelEditEnd = "beFashionExcelEditEnd", // temporary
}

export enum CommandsVeeva {}

export enum Commands {
  click = "click",
  focus = "focus",

  blur = "blur",
  menuItem = "menuItem",

  mousedown = "mousedown",
  keyup = "keyup",
  executeCode = "executeCode",
  password = "password",
  hotkey = "hotkey",
  assignValue = "assignValue",
  script = "script",
  change = "change",
  assert = "assert",

  check = "check",

  upload = "upload",
  start = "start",
  end = "end",
  login = "login",
  inspect = "inspect",

  veevaLookup = "veevaLookup",

  cposAuth = "cposAuth",
  CPOSCardHoverClick = "CPOSCardHoverClick",
  CPOSToggleClick = "CPOSToggleClick",
  CPOSDisassemblyKit = "CPOSDisassemblyKit",

  d365FSAdditionsSelectValue = "d365FSAdditionsSelectValue",
  d365FSMenu = "d365FSMenu",
  d365FSLookup = "d365FSLookup",

  dialogBox = "dialogBox",
  contextmenu = "contextmenu",
  lookup = "lookup",
  gridSelectRow = "gridSelectRow",
  gridSelectRow2 = "gridSelectRow2",
  selectSegmentEntry = "selectSegmentEntry",
  fastTabClick = "fastTabClick",

  beFashionExcel = "beFashionExcel", // temporary
  beFashionExcelEditStart = "beFashionExcelEditStart", // temporary
  beFashionExcelEditEnd = "beFashionExcelEditEnd", // temporary
  slider = "slider", // cpos slider filter
}

export interface KeyCombination {
  altKey: boolean;
  ctrlKey: boolean;
  shiftKey: boolean;
  keyCode: number[] | number;
}

export const SPECIAL_KEY_CODES = {
  ENTER: 13,
  SPACE: 32,
  G: 71,
  D: 68,
  H: 72,
  L: 76,
  Q: 81,
  M: 77,
  N: 78,
  S: 83,
  DEL: 68,
  F9: 120,
  F5: 116,
  F2: 113,
  E: 69,
  F: 70,
  A: 65,
  ARROW_DOWN: 40,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_LEFT: 37,
  TAB: 9,
  HOME: 36,
  END: 35,
  ESC: 27,
};

export type Hotkey = any;

export enum MqttMessages {
  Dispatched = "dispatched",
  DispatchError = "dispatchError",
  Kill = "kill",
  Close = "close",
  Screenshot = "screenshot",
}
export interface WorkItemDefinition {
  title: string;
  priority: number;
  type: AllowedWorkItemTypes;
  stepLabel: string;
  itsId: number;
  id?: string;
  severity?: WorkItemSeverity;
  reproSteps?: string;
  stepLogId?: number;
  iteration: string;
  area?: string;
  screenshotPath?: string;
  screenshot?: ReadStream;
  description?: string;
}

export interface TestCaseDefinition extends Omit<WorkItemDefinition, "stepLabel"> {
  testCaseSteps: string;
}

export interface WorkItem extends TimeStampItem {
  title: string;
  externalId: number;
  type: AllowedWorkItemTypes;
  url: string;
  itsId: number;
  itsName: string;
  scriptId: number;
  scriptName: string;
  stepLabel: string;
  logId: number;
  stepLogId: number;
  sessionId: string;
}

export enum WorkItemSeverity {
  critical = "1 - Critical",
  high = "2 - High",
  medium = "3 - Medium",
  low = "4 - Low",
}

export interface ItsWorkItem {
  fields: {
    [key: string]: any;
  };
  id: number;
  rev: number;
  _links?: any;
}

export interface ItsIteration {
  id: number;
  path: string;
  name: string;
}

export interface ItsArea {
  id: number;
  path: string;
  name: string;
}

export interface ItsWorkItemType {
  description: string;
  referenceName: string;
  name: string;
  isDisabled: boolean;
  fields: { referenceName: string; name: string; alwaysRequired: boolean }[];
}
export interface ItsWorkItemField {
  description?: string;
  name: string;
  picklistId?: string;
  readOnly?: boolean;
  referenceName: string;
  supportedOperations?: any[];
  type?: any;
  usage?: any;
}

export interface ItsProject {
  id: number;
  name: string;
  description: string;
}

export interface CustomField {
  name: string;
  value?: any;
}

export interface IssueTrackingToolConnectionParams {
  host: string;
  token: string;
  project?: string;
}

export interface ItsConnectionParams {
  itsId: number;
  project?: string;
}

export interface ItsIntegrationMetadata {
  azureDevops: { iteration: string; project: string; priority: number; area?: string };
}

export interface SchedulerIntegrationMetadata {
  azureDevops: { configurations: number[] };
}

export interface IssueTrackingTool extends TimeStampItem, IssueTrackingToolConnectionParams {
  project: string;
  name: string;
  severity: WorkItemSeverity;
  priority: number;
  iteration: string;
  customFields: CustomField[];
  type: string;
  integrationMetadata?: ItsIntegrationMetadata;
  area?: string;
}

// values are written in this way to provide compatibility with azure devops api
export enum IssueTrackingToolConfigurationState {
  inactive = "inactive",
  active = "active",
  removed = "removed",
}
export interface IssueTrackingToolConfiguration extends TimeStampItem {
  name: string;
  description?: string;
  itsId: number;
  state: IssueTrackingToolConfigurationState;
  integrationMetadata: {
    azureDevops: { id?: number; project?: string };
  };
}

export interface IssueTrackingToolRule extends TimeStampItem {
  name: string;
  itsId: number;
  triggers: IssueTrackingToolTrigger[];
  config: Partial<{
    severity: WorkItemSeverity;
    priority: number;
    iteration: string;
    customFields: CustomField[];
    type: string;
    project: string;
    area: string;
  }>;
  itsName?: string;
}

export enum IssueTrackingToolTrigger {
  ERROR = "ERROR",
  WARNING = "WARNING",
  INFO = "INFO",
  PLATFORM_ERROR = "PLATFORM ERROR",
  PLATFORM_WARNING = "PLATFORM WARNING",
}

export interface UrlAndId {
  url: string;
  id: string;
  engineId?: string;
  scriptId?: number;
}

export interface EventSourceMessage {
  data: string;
}

export enum ExecuteEntityType {
  SCRIPT = "script",
  JOB = "job",
  PROJECT = "project",
}

export enum AllowedWorkItemTypes {
  Bug = "Bug",
  Issue = "Issue",
  Task = "Task",
  TestCase = "Test Case",
}

export enum PLATFORM {
  CPOS = "CPOS",
  D365FO = "D365FO",
  OTHER = "OTHER",
}

export enum CONDITIONAL_TYPE {
  SUCCESS = "SUCCESS",
  INTERRUPTION = "INTERRUPTION",
}

export enum CONDITIONAL_ACTION_TYPE {
  SIMPLE = "SIMPLE",
  ADVANCED = "ADVANCED",
}

export enum INTERRUPTION_TYPE {
  SYSTEM_ERROR = "SYSTEM_ERROR",
  SYSTEM_WARNING = "SYSTEM_WARNING",
  ELEMENT_NOT_FOUND = "ELEMENT_NOT_FOUND",
  ERROR = "ERROR",
  WARNING = "WARNING",
}

export const GuardsReactions = {
  [INTERRUPTION_TYPE.ERROR]: [
    INTERRUPTION_TYPE.ERROR,
    INTERRUPTION_TYPE.SYSTEM_ERROR,
    INTERRUPTION_TYPE.ELEMENT_NOT_FOUND,
  ],
  [INTERRUPTION_TYPE.SYSTEM_ERROR]: [INTERRUPTION_TYPE.SYSTEM_ERROR],
  // TODO: R2.0 I'm not adding support for elemenot_not_found. I created task in notion to refactor
  // possible reactions / interruptions for guards
  [INTERRUPTION_TYPE.ELEMENT_NOT_FOUND]: [INTERRUPTION_TYPE.ELEMENT_NOT_FOUND],
  [INTERRUPTION_TYPE.SYSTEM_WARNING]: [INTERRUPTION_TYPE.SYSTEM_WARNING],
  [INTERRUPTION_TYPE.WARNING]: [INTERRUPTION_TYPE.WARNING, INTERRUPTION_TYPE.SYSTEM_WARNING],
};

export const PredefinedVariablesContainerName = "Predefined";
export enum PredefinedVariable {
  CurrentStepValue = "CurrentStepValue",
}

export interface TransitionDefinition {
  to: ScriptStatus[];
  initial?: boolean;
}

export type StatusTransitionSettings = { [key in ScriptStatus]: TransitionDefinition };

export enum FEATURES {
  AUDIT_TRAIL = "AUDIT_TRAIL",
  GXP_TAGS = "GXP_TAGS",
  ELECTRONIC_SIGNOFF = "ELECTRONIC_SIGNOFF",
  SCRIPT_VERSIONING = "SCRIPT_VERSIONING",
  DOCUMENTATION = "DOCUMENTATION",
  REMOVABLE_DOCUMENTATION = "REMOVABLE_DOCUMENTATION",
}

export enum GLOBAL_SETTINGS_KEYS {
  STATUS_TRANSITIONS = "STATUS_TRANSITIONS",
  PUBLISHING_CREDENTIALS_CONFIRMATION = "PUBLISHING_CREDENTIALS_CONFIRMATION",
  DEFAULT_APP_USER_SETTINGS = "DEFAULT_APP_USER_SETTINGS",
  TEST_PLANS_INTEGRATION = "TEST_PLANS_INTEGRATION",
  TIMEOUTS = "TIMEOUTS",
  REPORT_TEMPLATE = "REPORT_TEMPLATE",
  DOCUMENTATION_TEMPLATE = "DOCUMENTATION_TEMPLATE",
  ADMINISTRATION_ADDRESS = "ADMINISTRATION_ADDRESS",
  REPORTS_LINK = "REPORTS_LINK",
  RESOLVERS_MODE = "RESOLVERS_MODE",
  POSITION_RESOLVER_META_SCORE_THRESHOLD = "POSITION_RESOLVER_META_SCORE_THRESHOLD",
  GENERATE_RESOLVERS_ON_PLAYBACK = "GENERATE_RESOLVERS_ON_PLAYBACK",
  internal = "internal",
}

export enum INTERNAL_SETTINGS_KEYS {
  DISABLED_FEATURES = "DISABLED_FEATURES",
  AUTO_SCREENSHOTS_CLEANUP = "AUTO_SCREENSHOTS_CLEANUP",
  HOTJAR = "HOTJAR",
}
export enum PUBLIC_SETTINGS_KEYS {
  RECAPTCHA_PUBLIC_KEY = "RECAPTCHA_PUBLIC_KEY",
  RECAPTCHA_ENABLED = "RECAPTCHA_ENABLED",
  SSO_AZURE_ENABLED = "SSO_AZURE_ENABLED",
  SSO_SAML_ENABLED = "SSO_SAML_ENABLED",
}

export type GlobalSettingsAutoScreenshotsCleanup = {
  days: number;
  startTime: Date;
  enabled?: boolean;
};

export enum GlobalSettingsResolversModes {
  SMART = "SMART",
  LEGACY = "LEGACY",
  FALLBACK = "FALLBACK",
}

export interface HotJarSettings {
  enabled: boolean;
  siteId: number;
  version?: number;
}

export interface GlobalSettings {
  [GLOBAL_SETTINGS_KEYS.STATUS_TRANSITIONS]: StatusTransitionSettings;
  [GLOBAL_SETTINGS_KEYS.PUBLISHING_CREDENTIALS_CONFIRMATION]: boolean;
  [GLOBAL_SETTINGS_KEYS.DEFAULT_APP_USER_SETTINGS]: any;
  [GLOBAL_SETTINGS_KEYS.TEST_PLANS_INTEGRATION]: boolean;
  [GLOBAL_SETTINGS_KEYS.RESOLVERS_MODE]: GlobalSettingsResolversModes;
  [GLOBAL_SETTINGS_KEYS.POSITION_RESOLVER_META_SCORE_THRESHOLD]: number | undefined;
  [GLOBAL_SETTINGS_KEYS.TIMEOUTS]: {
    inactivityTimeout: number;
    step: {
      findElementTimeout: number;
    };
    optionalStep: {
      findElementTimeout: number;
    };
    redirectTimeout: number;
  };
  [GLOBAL_SETTINGS_KEYS.REPORT_TEMPLATE]?: number;
  [GLOBAL_SETTINGS_KEYS.DOCUMENTATION_TEMPLATE]?: number;
  [GLOBAL_SETTINGS_KEYS.GENERATE_RESOLVERS_ON_PLAYBACK]?: boolean;
  [GLOBAL_SETTINGS_KEYS.ADMINISTRATION_ADDRESS]?: string;
  [GLOBAL_SETTINGS_KEYS.REPORTS_LINK]?: string;
  internal?: {
    [INTERNAL_SETTINGS_KEYS.DISABLED_FEATURES]: FEATURES[];
    [INTERNAL_SETTINGS_KEYS.AUTO_SCREENSHOTS_CLEANUP]: GlobalSettingsAutoScreenshotsCleanup;
    [INTERNAL_SETTINGS_KEYS.HOTJAR]: HotJarSettings;
  };
  public?: { [PUBLIC_SETTINGS_KEYS.RECAPTCHA_PUBLIC_KEY]: string };
}

export interface ScriptChangelog extends TimeStampItem {
  scriptId: number;
  oldValue: ScriptStatus | undefined;
  newValue: ScriptStatus;
  user: string;
  comment?: string;
  tags?: string[];
  version: string;
  snapshot: any;
}

export enum ApplicationStyles {
  DEFAULT = "default",
  EPISTA = "epista",
  DOCUMENTATION = "documentation",
}

export enum ReportExtension {
  docx = "docx",
  pdf = "pdf",
}

export type SocketMessage<T extends WebsocketMessageTypes> = {
  type: T;
  value: WebsocketData[T];
};

export enum WebsocketMessageTypes {
  OPEN_CONNECTION = "OPEN_CONNECTION",
  NOTIFICATION = "NOTIFICATION",
  REMOVE_CLIENT_COOKIES = "REMOVE_CLIENT_COOKIES",
  SCHEDULER = "SCHEDULER",
  SUBSCRIBE_TO_UPDATES = "SUBSCRIBE_UPDATES",
  UNSUBSCRIBE_TO_UPDATES = "UNSUBSCRIBE_UPDATES",
  PERFORM_REFRESH = "PERFORM_REFRESH",
  HEARTBEAT = "HEARTBEAT",
  REPORT_GENERATION_SUCCESS = "REPORT_GENERATION_SUCCESS",
  REPORT_GENERATION_FAIL = "REPORT_GENERATION_FAIL",
  EXPORT_GENERATION_START = "EXPORT_GENERATION_START",
  EXPORT_GENERATION_PROGRESS = "EXPORT_GENERATION_PROGRESS",
  EXPORT_GENERATION_FAIL = "EXPORT_GENERATION_FAIL",
  EXPORT_GENERATION_SUCCESS = "EXPORT_GENERATION_SUCCESS",
  GLOBAL_ACTIONS_SCREENSHOTS_CLEANUP_UPDATE = "GLOBAL_ACTIONS_SCREENSHOTS_CLEANUP_UPDATE",
}

export interface WebsocketData {
  [WebsocketMessageTypes.OPEN_CONNECTION]: {
    userId: number;
    wsId: string;
  };
  [WebsocketMessageTypes.NOTIFICATION]: {
    status?: string;
    scriptName?: string;
    message?: string;
    wsId?: string;
  };
  [WebsocketMessageTypes.REMOVE_CLIENT_COOKIES]: {
    sessionId: string;
    wsId?: string;
  };
  [WebsocketMessageTypes.SCHEDULER]: {
    projectId?: number;
    isBeingDispatched: boolean;
    scheduledProjectState: ScheduledProjectState;
    schedulerJobId?: number;
    schedulerJobName?: string;
    state?: EXECUTION_STATE;
    status?: EXECUTION_STATUS;
    scriptId?: number;
    sessionId?: string;
    internal?: boolean;
    startTime?: Date;
    projectName?: string;
    backgroundExecutionId?: string;
    wsId?: string;
    params?: { mapResultWithDevops: string };
  };
  [WebsocketMessageTypes.SUBSCRIBE_TO_UPDATES]: {
    queryId: string;
    wsId: string;
    modelName: SubscribeUpdateModelNames;
    query: SubscribeUpdateQuery;
  };
  [WebsocketMessageTypes.UNSUBSCRIBE_TO_UPDATES]: {
    queryId: string;
    wsId?: string;
  };
  [WebsocketMessageTypes.PERFORM_REFRESH]: {
    queryId: string;
    wsId?: string;
  };
  [WebsocketMessageTypes.HEARTBEAT]: { wsId?: string };
  [WebsocketMessageTypes.REPORT_GENERATION_SUCCESS]: {
    wsId?: string;
    tableId: string;
    reportName: string;
    originalReportName: string;
  };
  [WebsocketMessageTypes.REPORT_GENERATION_FAIL]: {
    wsId?: string;
    tableId: string;
  };
  [WebsocketMessageTypes.EXPORT_GENERATION_START]: any;
  [WebsocketMessageTypes.EXPORT_GENERATION_PROGRESS]: any;
  [WebsocketMessageTypes.EXPORT_GENERATION_FAIL]: any;
  [WebsocketMessageTypes.EXPORT_GENERATION_SUCCESS]: any;
  [WebsocketMessageTypes.GLOBAL_ACTIONS_SCREENSHOTS_CLEANUP_UPDATE]: {
    wsId?: string;
    stats: RemovingScreenshotsStats & { hasBeenCompleted?: boolean; error?: string };
  };
}

export enum SubscribeUpdateModelNames {
  TASK_STEP = "TaskStep",
  VARIABLE = "Variable",
}

export interface SubscribeUpdateQuery {
  taskScriptId?: number;
  parentId?: number;
}

export enum ScheduledProjectState {
  DISPATCHED = "projectDispatched",
  DISPATCH_FAILED = "projectDispatchFailed",
  FINISHED = "projectFinish",
  ERROR = "projectError",
}
export interface Sockets {
  userId: number;
  ws: any;
  wsId: string;
}
export interface SubscribedToUpdates {
  queryId: string;
  wsId: string;
  modelName: string;
  query: object;
}

export const initialConditionActions = (): ActivityExecution => ({
  ifAction: {
    type: undefined as any,
    values: [],
  },
  elseAction: {
    type: undefined as any,
    values: [],
  },
});

export const initialDataSourceCondition = (): { comparison: DataSourceComparison[] } => ({
  comparison: [
    {
      type: AssertionType.DATA_SOURCE,
      comparisonType: undefined as any,
      value: undefined as any,
    },
  ],
});

export const initialCheckValueCondition = (): { comparison: CheckValueComparison[] } => ({
  comparison: [
    {
      type: AssertionType.CHECK_VALUE,
      operator: undefined as any,
      leftCondition: {
        type: undefined as any,
        value: undefined as any,
      },
      rightCondition: {
        type: undefined as any,
        value: undefined as any,
      },
    },
  ],
});

export interface AuditTrailScriptOverview extends Item {
  scriptId: number;
  changesCount: number;
  lastAction: string;
  updatedAt: Date;
}

export interface AuditTrail extends Item {
  tableName: string;
  rowId: number;
  username: string;
  changedFields: any;
  action: string;
  rowData: any;
  tstampTx: Date;
  tstampStm: Date;
  tstampClk: Date;
  oldData?: any;
  newData?: any;
}

export type Tokens = {
  cookies: any;
  sessionStorage: any;
  localStorage: any;
};

export const INITIAL_STRUCTURE_VERSION = "0.0.1";

export const createInternalSteps = (steps: Omit<PlayerStep, "lineNum" | "taskScriptId" | "id">[]) =>
  steps.map((s, index) => ({ ...s, lineNum: index, id: index, taskScriptId: -1 }));
export const createInternalStep = <T extends { value: any; id: any; version: any }>(
  step: Omit<PlayerStep<T>, "lineNum" | "taskScriptId" | "id">,
): Omit<PlayerStep<T>, "lineNum" | "taskScriptId" | "id"> => step;

export type VariablesGroup = {
  id: string;
  variables?: (Variable | GlobalVariable)[];
  groups?: VariablesGroup[];
};

export type CapturedFile = {
  name: string;
  path: string;
  createdAt: number;
};

export const DEFAULT_LOCALE = "en";

export enum CHECK_REFERENCE_TYPE {
  STEP_REFERENCE = "STEP_REFERENCE",
  VARIABLE_REFERENCE = "VARIABLE_REFERENCE",
  GLOBALVARIABLE_REFERENCE = "GLOBALVARIABLE_REFERENCE",
  DATASOURCE_REFERENCE = "DATASOURCE_REFERENCE",
  VIRTUAL_USER_REFERENCE = "VIRTUAL_USER_REFERENCE",
  SYSTEM_DEFINITION_REFERENCE = "SYSTEM_DEFINITION_REFERENCE",
}

export interface JobStatusesCount {
  [EXECUTION_STATUS.SUCCESS]: number;
  [EXECUTION_STATUS.WARNING]: number;
  [EXECUTION_STATUS.ERROR]: number;
  ["ALL"]: number;
}

export type RunnerState = ExecutionCache & {
  playMode: PlayMode;
  isRecording: boolean;
  isInspecting: boolean;
  recordingMarker: number;
  isDirty: boolean;
  syncing: boolean;
  syncError?: string;
  executionItems: PlainObject<ExecutionStep["execution"]>;
  mode: RunnerMode;
};

export enum TEMPLATE_TYPE {
  REPORT_TEMPLATE = "reportTemplate",
  DOCUMENTATION_TEMPLATE = "documentationTemplate",
}

export enum RUNNER_TYPE {
  BASIC = "Basic",
  ADVANCED = "Advanced",
}

export enum RUNNER_OPEN_MODE {
  NEW_WINDOW = "New window",
  CURRENT_WINDOW = "Current window",
}

export enum RUNNER_POSITION {
  LEFT = "Left",
  RIGHT = "Right",
}

export type RunnerLocationState = {
  opener: string;
  runnerParams: RunnerParams;
};

export type RunnerParams = {
  type: RUNNER_TYPE;
  openMode: RUNNER_OPEN_MODE;
  position: RUNNER_POSITION;
};
export type SchedulerRunDependentType = {
  type: SchedulerRunTypes.DEPENDANT;
  dependantIds: number[];
};

export type SchedulerRunType =
  | {
      type: SchedulerRunTypes.TIME;
      time: Date;
    }
  | SchedulerRunDependentType;

export type SchedulerRepeater = {
  repeaterType: "PARALLEL" | "SEQUENTIAL";
  repeaterMultiplier: number;
};

export enum SchedulerRunTypes {
  TIME = "TIME",
  DEPENDANT = "DEPENDANT",
}

export type BasicSchedulerRunParams = {
  azureConfigurationIds?: number[];
  environmentId?: number;
  virtualUserId?: number;
  virtualUserPoolId?: number;
  virtualUserMode?: "SINGLE" | "POOL";
};

export type ScheduleRunParams<T> = {
  run: T;
  repeater?: SchedulerRepeater;
} & BasicSchedulerRunParams;
// export type WithoutID<T> = DeepOmit<T, { id: never }>;
export interface SchedulerScript extends Item {
  name: string;
  description?: string;
  lineNum: number;
  scriptId: number;
  runParams: ScheduleRunParams<SchedulerRunType>;
}

export interface SchedulerGroup extends Item {
  name: string;
  schedulerJobId: number;
  lineNum: number;
  description?: string;
  runParams: ScheduleRunParams<SchedulerRunDependentType | undefined>;
  scripts: SchedulerScript[];
  overrideRunParams?: boolean;
}

export const RUNNER_FRAME_ID = "EA_RUNNER_FRAME";
export enum FileConverterOutputExtension {
  PDF = "pdf",
  XLSX = "xlsx",
}

export enum BroadcastMessages {
  LOGOUT = "LOGOUT",
  LOGIN = "LOGIN",
}

export enum ExportToCsvModels {
  SCRIPTS = "TaskScript",
  LOGS = "ExecutionLog",
  STEP_LOGS = "ExecutionStepLog",
  EXECUTION_JOB_LOGS = "ExecutionJobLog",
  SCHEDULER_FLOW = "SchedulerJobMapping",
}

export enum LicenseType {
  BASIC = "Basic",
  REGULAR = "Regular",
  ENTERPRISE = "Enterprise",
  PAY_PER_USE = "Pay per use",
}
export interface License {
  type: LicenseType;
  expirationDate: string;
  supportExpirationDate: string;
  maximumConcurrentUsers?: number;
  maximumParallelSessions?: number;
  licenseNumber?: string;
  error?: LicenseErrors;
}

export interface PayPerUseCounter {
  total: number;
  thisMonth: number;
  lastMonth: number;
}

export enum PayPerUseStatisticsPrecision {
  Days = "Days",
  Months = "Months",
  Years = "Years",
}

export enum LicenseErrors {
  readingFile = "Error while reading licence file",
  decryptError = "Error while decrypting license file",
  invalidLicenseNumber = "Invalid license number",
}

export enum ErrorCodes {
  STARTING_SESSION = "STARTING_SESSION",
  SESSIONS_LIMIT_REACHED = "SESSIONS_LIMIT_REACHED",
}

export enum ScreenshotOptions {
  ON_INTERRUPTION = "ON_INTERRUPTION",
  ON_STEP = "ON_STEP",
  NO_SCREENSHOT = "NO_SCREENSHOT",
}

export interface PayPerUseStatistic {
  name: string;
  scriptsCount: number;
}

export enum SaveVideoCondition {
  ON_ERROR = "ON_ERROR",
  ON_SUCCESS = "ON_SUCCESS",
  ALWAYS = "ALWAYS",
}

export enum ScriptsCsvAdditionalFields {
  takeScreenshots = "takeScreenshots",
  screenshotsOnlyOnInterruption = "screenshotsOnlyOnInterruption",
  clickOnDisabledButtons = "clickOnDisabledButtons",
  useDefaultEnvironment = "useDefaultEnvironment",
  topProjectName = "topProjectName",
  virtualUserName = "virtualUserName",
}

export type KpiTableStatistics = {
  occurrencesPerHour: number;
  occurrencesPerMinute: number;
  totalOccurrences: number;
  name: string;
};

export interface KpiChartStatistics {
  [key: string]: string;
  timestamp: string;
}

export type KpiStatistics = {
  tableStats: KpiTableStatistics[];
  chartStats: KpiChartStatistics[];
};

export interface CodeTemplate extends TimeStampItem {
  name: string;
  description: string;
  codeTemplateGroupId: number;
  activeVersion: number;
  functionName: string;
  definitions: Record<string, CodeTemplateDefinition>;
}

export interface CodeTemplateDefinition {
  params?: CodeParams[];
  code: string;
}

export enum CodeParamType {
  CONSTANT = "CONSTANT",
  VARIABLE = "VARIABLE",
}
export interface CodeParams {
  name: string;
  description: string;
}
export interface CodeTemplateGroup extends TimeStampItem {
  name: string;
  description: string;
}

export enum CodeTemplateSaveMode {
  CURRENT = "CURRENT",
  AS_NEW_VERSION = "AS_NEW_VERSION",
  AS_NEW_ACTIVE_VERSION = "AS_NEW_ACTIVE_VERSION",
}

export type CodeTemplatesFunctionName = "CodeTemplates";
export type CodeTemplateWithGroup = CodeTemplate & { codeTemplateGroup: CodeTemplateGroup };
export enum LogCsvAdditionalFields {
  scriptLogUrl = "scriptLogUrl",
  schedulerJobName = "schedulerJobName",
  jobId = "jobId",
  lastErrorMessage = "lastErrorMessage",
}

export type CsvExportAdditionalFields = {
  [key in ScriptsCsvAdditionalFields | LogCsvAdditionalFields]?: boolean | string;
};

export type InitTranslationsParams = {
  skipSync?: boolean;
};

export type AppLogger = {
  log: (message: string) => void;
  error: (message: string, error: Error) => void;
  debug: (message: string) => void;
};

export enum IMPORT_SCRIPT_OPTIONS {
  USE_EXISTING = "use_existing",
  ALWAYS_CONVERT_DATASOURCE = "always_convert",
  ALWAYS_CLONE_DATASOURCE = "always_clone",
  CONVERT_DATASOURCE = "convert",
  CLONE_DATASOURCE = "clone",
}
