import actionCreatorFactory from "typescript-fsa";

import { LoginRequest, LoginResponse } from "@app/services/api/api.models";
import { User } from "@ea/shared_types/types";
import { removeToken } from "@ea/shared_components/utils/auth";
import { closeWebsocket } from "../common/websocket";
import { bcApi } from "../common/broadcastChannel";
import { API } from "@app/services/api/api";

const actionCreator = actionCreatorFactory("AUTH_MODULE");

export const logoutCreator = actionCreator("LOGOUT");
export const initAuthCreator = actionCreator.async<{ isLoggedIn: boolean }, User>("INIT");
export const loginCreator = actionCreator.async<LoginRequest, LoginResponse>("LOGIN");
export const clearLoginErrorCreator = actionCreator("CLEAR_LOGIN_ERROR_CREATOR");

const logout = () => {
  API.logout({});
  removeToken();
  closeWebsocket();
  bcApi.logout();
  return logoutCreator();
};

export const authActions = {
  login: loginCreator.started,
  clearLoginError: clearLoginErrorCreator,
  init: initAuthCreator.started,
  initDone: initAuthCreator.done,
  logout,
};
