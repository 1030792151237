import {
  ScheduleRunParams,
  SchedulerRunDependentType,
  SchedulerRunType,
} from "@ea/shared_types/types";

export type GanttData = {
  data: GanttTask[];
  links: GanttLink[];
};

export type GanttTaskCommon = {
  id: string;
  text: string;
  start_date?: Date;
  description?: string;
  duration?: number;
  progress?: number;
  color?: string;
  open?: boolean;
};

export type GanttTaskGroupNormal = {
  scriptMode: "NORMAL";
  type: "project";
};

export type GanttTaskGroupSingle = {
  scriptMode: "SINGLE";
  schedulerScriptId: number;
  scriptId: number;
  type: undefined;
};

export type GanttTaskGroup = (GanttTaskGroupSingle | GanttTaskGroupNormal) & {
  kind: "GROUP";
  open?: boolean;
  schedulerJobId: number;
  runParams: ScheduleRunParams<SchedulerRunDependentType | undefined>;
  overrideRunParams?: boolean;
} & GanttTaskCommon;

export type GanttTaskScript = {
  kind: "SCRIPT";
  parent: string;
  scriptId: number;
  runParams: ScheduleRunParams<SchedulerRunType>;
} & GanttTaskCommon;

export type GanttTask = GanttTaskGroup | GanttTaskScript;

export interface GanttLink {
  source: string;
  target: string;
  type: string;
  id?: number | string;
}

export const GANTT_DEFAULT_DURATION = 2;

export enum GANTT_ZOOM {
  MIN_1 = "1 min",
  MIN_5 = "5 min",
  MIN_10 = "10 min",
  MIN_15 = "15 min",
  MIN_30 = "30 min",
  HOURS = "Hours",
}
