import { routerMiddleware } from "connected-react-router";
import { connectRouter } from "connected-react-router";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";

import combinedEpics from "./modules/app.epics";
import { ApplicationState, reducers } from "./modules/app.reducers";
import { logoutCreator } from "./modules/auth/auth.actions";

const windowIfDefined = typeof window === "undefined" ? undefined : (window as any);
const devToolsExtension = windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__;

const epicMiddleware = createEpicMiddleware(combinedEpics);

export const configureStore = (history) => (preloadedState?: ApplicationState) => {
  const allReducers = buildRootReducer(reducers, history);

  const clearableReducer = (state, action) => {
    if (action.type === logoutCreator.type) {
      state = undefined;
    }

    return allReducers(state, action);
  };

  const store = createStore(
    clearableReducer,
    preloadedState,
    compose(
      applyMiddleware(
        // logger,
        routerMiddleware(history),
        epicMiddleware,
      ),
      devToolsExtension ? devToolsExtension() : (f: any) => f,
    ),
  );

  return store;
};

function buildRootReducer(allReducers: typeof reducers, history) {
  return combineReducers({
    router: connectRouter(history),
    ...allReducers,
  });
}
