import { EditFormLayout } from "@ea/shared_components/Form/FormLayouts";
import arrayMutators from "final-form-arrays";
import * as React from "react";
import { Form } from "react-final-form";
import { URL_SELECT_MODE } from "@ea/shared_types/types";
import { getAuditTrailErrorsFromDiff } from "./auditTrail.helpers";
interface IAutoValidateFormProps {
  handleSubmit?: any;
}

class AutoValidateForm extends React.Component<IAutoValidateFormProps, any> {
  myRef: any;

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.props.handleSubmit(new Event("submit", { cancelable: true }));
  }

  render() {
    return (
      <EditFormLayout readOnly>
        <form ref={this.myRef} onSubmit={this.props.handleSubmit}>
          {this.props.children}
        </form>
      </EditFormLayout>
    );
  }
}

interface IAuditTrailAutoValidateFormProps {
  recordDiff?: any;
  formProps?: any;
  initialValues?: any;
}

class AuditTrailAutoValidateForm extends React.Component<IAuditTrailAutoValidateFormProps, any> {
  myRef: any;

  constructor(props) {
    super(props);
  }

  render() {
    const { formProps, initialValues, recordDiff, children } = this.props;

    const formValues = {
      ...initialValues,
      urlSwitch: !initialValues?.isManualUrl ? URL_SELECT_MODE.ENVIRONMENT : URL_SELECT_MODE.MANUAL,
      defaultSystem: initialValues?.isManualUrl || !initialValues?.environmentId,
      defaultVirtualUser: initialValues?.isManualUrl || !initialValues?.virtualUserId,
    };
    return (
      <Form
        onSubmit={() => {}}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={formValues}
        validate={(values) =>
          recordDiff ? getAuditTrailErrorsFromDiff(recordDiff, formValues) : {}
        }
        render={({ handleSubmit }) => {
          return <AutoValidateForm handleSubmit={handleSubmit}>{children}</AutoValidateForm>;
        }}
        {...formProps}
      />
    );
  }
}

export default AuditTrailAutoValidateForm;
