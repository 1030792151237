import { Tag } from "antd";
import * as React from "react";

import { COLORS } from "@app/styles/consts";
import { JOB_STATUS_TYPE, STATUS_TYPE, EXECUTION_STATUS } from "@ea/shared_types/types";

interface IStatusFieldProps {
  status: EXECUTION_STATUS | JOB_STATUS_TYPE;
}

const style = {
  cursor: "default",
};

const StatusField: React.FunctionComponent<IStatusFieldProps> = ({ status }) => {
  if (!status) {
    return null;
  }

  return (
    <Tag style={style} color={COLORS.STATUS[status]}>
      {status}
    </Tag>
  );
};

export default StatusField;
