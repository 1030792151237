import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";

import { Step, Script, System } from "@ea/shared_types/types";
import TableForm from "@ea/shared_components/Form/TableForm";
import { getStepScriptColumns } from "./StepScriptFormColumns";
import { InputField } from "@ea/shared_components";

interface IStepScriptFormProps {
  steps: Step[];
  scripts: Script[];
  system?: System;
  values: any;
  isCreating?: boolean;
}

const StepScriptForm: React.FunctionComponent<IStepScriptFormProps & InjectedIntlProps> = ({
  scripts,
  steps,
  system,
  values,
  intl,
  ...form
}) => {
  return (
    <TableForm<{ name: string }>
      name="linkedScriptIds"
      staticMode
      columns={getStepScriptColumns({ scripts, steps, values }, intl)}
    />
  );
};

export default injectIntl(StepScriptForm);
