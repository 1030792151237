import { createModuleEpics } from "@app/modules/app.reducers";
import { moduleActions } from "@app/modules/logs/AggregatedJobLogs/aggregatedJobLogs.actions";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { AggregatedJobLog } from "@ea/shared_types/types";
import { logsDataSelectors } from "./aggregatedJobLogs.selectors";
import { AGGREGATED_JOB_LOGS_COLUMNS_CONFIG } from "./aggregatedJobLogs.table";

const DEFAULT_ORDER = ["startTime DESC"];

const logModuleEpics = createModuleEpics<AggregatedJobLog>(moduleActions, {
  normalizeItem: (aggregatedJobLog) => aggregatedJobLog,
  getRequestParams: (state, tableId) => {
    const tableParams = logsDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, AGGREGATED_JOB_LOGS_COLUMNS_CONFIG, {
      defaultOrder: DEFAULT_ORDER,
    });
    params.filter = {
      ...params.filter,
    };
    return params;
  },
  getSingleRequestParams: (params) => {
    params.filter = {
      ...params.filter,
    };
    return params;
  },
  api: {
    createItem: () => ({} as Promise<AggregatedJobLog>),
    deleteItem: () => ({} as Promise<any>),
    editItem: () => ({} as Promise<AggregatedJobLog>),
    getItems: API.getAggregatedJobLogs,
    getItemsCount: API.getAggregatedJobLogsCount,
  },
});

export default logModuleEpics;
