import { FORM_ERROR, FormApi } from "final-form";
import arrayMutators from "final-form-arrays";
import styled from "@emotion/styled";
import * as React from "react";
import onClickOutside from "react-click-outside";
import { Form, FormProps, FormRenderProps } from "react-final-form";

import { faEdit, faSave, faTimes } from "@fortawesome/fontawesome-free-solid";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";

import CommandBar from "../CommandBar/CommandBar";
import CommandBarButton from "../CommandBar/CommandBarButton";
import { finalFormNormalizedSubmit, normalizeEditForm } from "../Form/formHelpers";
import { EditFormLayout } from "../Form/FormLayouts";
import LoaderArea from "../LoaderArea/LoaderArea";
import { MessageBar } from "../MessageBar";
import { hasClass, isTagName } from "../utils/dom";
import { withDefaultProps } from "../utils/react";
import { FormHint, Hint, printFormValues } from "../Form/FormDebugElements";
import { MESSAGE_TYPE } from "@ea/shared_types/types";
import { DataTestIds } from "../utils/dataTestHelpers";

type IEditeableAreaOptionalProps = {
  disableEditing: boolean;
  initialValues?: any;
  subscription?: FormProps["subscription"];
  destroyOnUnregister?: boolean;
  onCancel: () => void;
  guard: any;
  setRef: (element: any) => any;
  additionalButtons?: React.ReactElement;
  isEditModeOn?: boolean;
};

const defaultProps: IEditeableAreaOptionalProps = {
  disableEditing: false,
  onCancel: () => {},
  guard: () => {},
  setRef: () => {},
};

export type EditableFormProps<T> = FormApi & { values: T; readOnly: boolean };

type IEditeableAreaRequiredProps = {
  onSave: (values: any) => void;
  render: (props: EditableFormProps<any>) => React.ReactNode;
};

type IEditeableAreaProps = IEditeableAreaRequiredProps & IEditeableAreaOptionalProps;

type IEditeableAreaState = {
  isEditing: boolean;
  isSaving: boolean;
  error: string | undefined;
};

const Container = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const AreaContainer = styled.div({
  position: "relative",
  padding: "20px",
  flex: 1,
  display: "flex",
  flexDirection: "column",
});
class EditeableAreaFinal extends React.Component<
  IEditeableAreaProps & { form: FormRenderProps },
  any
> {
  state: IEditeableAreaState = {
    isEditing: false,
    isSaving: false,
    error: undefined,
  };
  componentDidMount() {
    this.props.setRef(this);
  }

  componentDidUpdate(prevProps) {
    const { disableEditing, isEditModeOn } = this.props;
    const { isEditing } = this.state;

    if (disableEditing && isEditing) {
      this.setState({ isEditing: false });
    }
    if (isEditModeOn && !isEditing) {
      this.setState({ isEditing: true });
    }
    if (prevProps.isEditModeOn && !isEditModeOn) {
      this.submitForm();
    }
  }

  submitForm = () => {
    const { form } = this.props;
    if (form.pristine || !form.form.getState().valid) {
      this.cancel();
      return;
    }
    form.form.submit();
    this.disableEditing();
  };

  handleClickOutside = (evt) => {
    const { isEditModeOn, form } = this.props;
    const { isEditing } = this.state;
    if (isEditModeOn !== undefined) {
      return true;
    }
    if (isEditing && !form.pristine) {
      if (evt.target && evt.target.classList) {
        for (const className of evt.target.classList) {
          if (className.includes("ant-calendar") || className.includes("ant-picker")) {
            return;
          }
        }
      }

      if (
        isTagName(evt.target, "button") ||
        isTagName(evt.target, "a") ||
        hasClass(evt.target, "ms-Pivot-text") ||
        hasClass(evt.target, "ant-tree-title") ||
        hasClass(evt.target, "ant-tabs-tab") ||
        (evt.target.parentElement && hasClass(evt.target.parentElement, "ant-tabs-tab-btn")) ||
        this.props.guard(evt)
      ) {
        const decision = confirm(
          "You are in the edit mode. Are you sure you want to proceed and lost your changes?",
        );

        if (decision) {
          this.cancel();
        } else {
          evt.preventDefault();
          evt.stopPropagation();
        }

        return decision;
      }
    }
    return true;
  };

  enableEditing = () =>
    this.setState({
      isEditing: true,
    });

  disableEditing = () =>
    this.setState({
      isEditing: false,
    });

  componentDidCatch(error: Error) {
    this.setState({
      error: error.message,
    });
  }

  onError = (error) => {
    this.setState({
      error: error.message,
    });
  };

  cancel = async () => {
    await this.props.onCancel();
    this.props.form.form.reset();
    this.disableEditing();
  };

  render() {
    const { isEditing } = this.state;
    const { disableEditing, render, form, additionalButtons, isEditModeOn } = this.props;
    const { submitting, pristine, values } = form;

    const CommandBarButtonElements = isEditing ? (
      <>
        <CommandBarButton
          htmlType="submit"
          name="save"
          text="Save"
          icon={<FontAwesomeIcon icon={faSave} />}
          type="primary"
          disabled={submitting || pristine}
          data-testid={DataTestIds.EDITABLEAREA_BUTTON_SAVE}
        />
        <CommandBarButton
          name="cancel"
          text="Cancel"
          icon={<FontAwesomeIcon icon={faTimes} />}
          onClick={this.cancel}
          hoverColor="red"
          disabled={submitting}
          data-testid={DataTestIds.EDITABLEAREA_BUTTON_CANCEL}
        />
        {additionalButtons}
      </>
    ) : (
      <>
        <CommandBarButton
          name="edit"
          text="Edit"
          icon={<FontAwesomeIcon icon={faEdit} />}
          onClick={this.enableEditing}
          data-testid={DataTestIds.EDITABLEAREA_BUTTON_EDIT}
        />
        {additionalButtons}
      </>
    );

    const CommandBarElement = disableEditing ? null : (
      <CommandBar style={{ marginBottom: "10px" }}>{CommandBarButtonElements}</CommandBar>
    );

    return (
      <form
        style={{ width: "100%" }}
        onSubmit={(event) => {
          const submitResult = form.handleSubmit(event);

          if (submitResult === undefined) {
            return;
          }

          (submitResult as any).then((result) => {
            if (result === undefined || !result[FORM_ERROR]) {
              this.disableEditing();
            }
          });
        }}
      >
        <Container>
          {isEditModeOn === undefined && CommandBarElement}
          {form.submitError && (
            <MessageBar
              messages={[
                {
                  type: MESSAGE_TYPE.ERROR,
                  text: form.submitError,
                },
              ]}
            />
          )}
          <AreaContainer>
            <LoaderArea isSaving={submitting}>
              <EditFormLayout readOnly={!isEditing}>
                {render({
                  ...form.form,
                  readOnly: !isEditing,
                  values: form.values,
                })}
              </EditFormLayout>
            </LoaderArea>
          </AreaContainer>
          {process.env.NODE_ENV !== "production" && isEditing && (
            <FormHint>
              <Hint>{printFormValues(values)}</Hint>
            </FormHint>
          )}
        </Container>
      </form>
    );
  }
}

const EditeableAreaFinalWithClickOutside: typeof EditeableAreaFinal = onClickOutside(
  EditeableAreaFinal as any,
) as any;

const EditableAreaWrapper: React.SFC<IEditeableAreaProps> = (props) => (
  <Form
    onSubmit={finalFormNormalizedSubmit(props.onSave, normalizeEditForm)}
    mutators={{
      ...arrayMutators,
    }}
    destroyOnUnregister={!!props.destroyOnUnregister}
    subscription={props.subscription}
    initialValues={props.initialValues}
    render={(formProps) => (
      <EditeableAreaFinalWithClickOutside
        {...{ eventTypes: "click" }}
        {...props}
        form={formProps}
      />
    )}
  />
);

export default withDefaultProps(defaultProps, EditableAreaWrapper);
