import * as React from "react";

import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { User } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { CheckOutlined } from "@ant-design/icons";

export const USERS_COLUMNS_CONFIG: PlainObject<CustomColumn<User>> = {
  username: {
    dataIndex: "username",
    label: getTranslationKey("table", "username"),
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  email: {
    label: getTranslationKey("table", "email"),
    dataIndex: "email",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  groups: {
    label: getTranslationKey("table", "groupName"),
    dataIndex: "groups",
    filter: {
      filterType: ColumnFilters.SELECT,
      filterKey: "userGroupId",
      noValueLabel: "userGroups.noGroup",
    },
    render: (values, record, index) => {
      if (!values || values.length === 0) {
        return "No group";
      }

      return (
        <>
          {values.map((v) => (
            <div key={v.id}> {v.name} </div>
          ))}
        </>
      );
    },
  },
  isActive: {
    label: getTranslationKey("table", "active"),
    dataIndex: "isActive",
    sortable: true,
    render: (value) => (value ? <CheckOutlined /> : null),
  },
};

export const USERS_COLUMNS: ColumnConfig<User>[] = [
  {
    props: USERS_COLUMNS_CONFIG.username,
    frameworkProps: {
      width: "30%",
    },
  },
  {
    props: USERS_COLUMNS_CONFIG.email,
    frameworkProps: {
      width: "40%",
    },
  },
  {
    props: USERS_COLUMNS_CONFIG.groups,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: USERS_COLUMNS_CONFIG.isActive,
    frameworkProps: {
      width: "10%",
    },
  },
];

export const USERS_COLUMNS_MINIMUM: ColumnConfig<User>[] = [
  {
    props: USERS_COLUMNS_CONFIG.username,
    frameworkProps: {
      width: "45%",
    },
  },
  {
    props: USERS_COLUMNS_CONFIG.email,
    frameworkProps: {
      width: "55%",
    },
  },
];

export const USERS_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_USER_TABLES",
  },
  USERS_GROUP_ASSIGNMENT: {
    id: (id: number | string) => `USERS_GROUP_ASSIGNMENT_${id}`,
    preferencesId: "USER_TABLES_GROUP_ASSIGNMENT",
  },
  USERS_GROUP_ASSIGNMENT_DISPLAY: {
    id: (id: number | string) => `USERS_GROUP_ASSIGNMENT_DISPLAY_${id}`,
    preferencesId: "USER_TABLES_GROUP_ASSIGNMENT_DISPLAY",
  },
};
