import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";

import CommandBar from "./components/SystemDictionaryCommandBar";
import {
  SYSTEM_DICTIONARY_COLUMNS,
  SYSTEM_DICTIONARY_TABLES_CONFIG,
} from "@app/modules/systemDictionary/systemDictionary.table";

import { systemDictionaryDataSelectors } from "@app/modules/systemDictionary/systemDictionary.selectors";
import CreateEditSystemContainer from "@app/modules/systemDictionary/CreateEditSystem.container";
import { getSystemTableActions, systemActions } from "./systemDictionary.actions";

interface ISystemDictionaryTableProps {}

interface ISystemDictionaryTableState {
  createWindowVisibility: boolean;
  editWindowVisibility: boolean;
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

class SystemDictionaryTableContainer extends React.Component<
  ISystemDictionaryTableProps & IConnectProps,
  ISystemDictionaryTableState
> {
  connectedTable: any;

  state = {
    createWindowVisibility: false,
    editWindowVisibility: false,
  };

  openEditWindow = () => {
    this.setState({
      editWindowVisibility: true,
    });
  };

  closeEditWindow = () => {
    if (this.props.selectedSystem) {
      this.props.actions.loadSingle({ id: this.props.selectedSystem.id });
    }
    this.setState({
      editWindowVisibility: false,
    });
  };

  openCreateWindow = () => {
    this.setState({
      createWindowVisibility: true,
    });
  };

  closeCreateWindow = () => {
    this.setState({
      createWindowVisibility: false,
      editWindowVisibility: false,
    });
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
  };

  render() {
    return (
      <Container>
        <CommandBar
          onNewClick={this.openCreateWindow}
          onRemoveClick={this.remove}
          selected={this.props.selected}
          onEditClick={this.openEditWindow}
          onReload={this.reload}
        />
        <CreateEditSystemContainer
          visibility={this.state.createWindowVisibility || this.state.editWindowVisibility}
          onClose={this.state.editWindowVisibility ? this.closeEditWindow : this.closeCreateWindow}
          onCreate={this.reload}
          selected={this.props.selectedSystem}
          isEdit={this.state.editWindowVisibility}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={SYSTEM_DICTIONARY_COLUMNS}
          tableId={SYSTEM_DICTIONARY_TABLES_CONFIG.MAIN.id()}
          preferencesId={SYSTEM_DICTIONARY_TABLES_CONFIG.MAIN.preferencesId}
          stateKey={"systemDictionary"}
          tableActions={getSystemTableActions}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(systemActions, dispatch),
    ...bindActionCreators(
      getSystemTableActions(SYSTEM_DICTIONARY_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ),
  },
});

const connectCreator = connect(
  (state: ApplicationState) => ({
    selected: systemDictionaryDataSelectors.getSelectedSelector(
      state,
      SYSTEM_DICTIONARY_TABLES_CONFIG.MAIN.id(),
    ),
    selectedSystem: systemDictionaryDataSelectors.getSelectedItemSelector(
      state,
      SYSTEM_DICTIONARY_TABLES_CONFIG.MAIN.id(),
    ),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(SystemDictionaryTableContainer);
