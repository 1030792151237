import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Legend,
  Line,
  ResponsiveContainer,
  Label,
} from "recharts";
import React from "react";
import { getRandomDistinctColors } from "@app/utils/colors";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { KpiChartStatistics } from "@ea/shared_types/types";

interface KpiChartProps {
  data: KpiChartStatistics[];
}

export const KpiChart = ({ data }: KpiChartProps) => {
  const lines = () => {
    if (!data) {
      return null;
    }
    const { timestamp, ...kpis } = (data[0] as any) || [];
    return Object.keys(kpis).map((kpi, index) => (
      <Line type="monotone" dataKey={kpi} stroke={getRandomDistinctColors(index)} />
    ));
  };

  return (
    lines && (
      <>
        <FormattedMessage id={getTranslationKey("kpi", "label", "kpiOccurrenceInTime")} />
        <ResponsiveContainer width="100%" height="100%">
          <LineChart width={730} height={250} data={data} margin={{ top: 5, right: 30, left: 20 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="timestamp">
              <Label value={"Timestamp"} offset={0} position="insideBottom" />
            </XAxis>
            <YAxis>
              <Label value={"Occurrences"} offset={0} position="insideLeft" angle={-90} />
            </YAxis>
            <Tooltip />
            {lines()}
            <Legend wrapperStyle={{ bottom: "auto" }} />
          </LineChart>
        </ResponsiveContainer>
      </>
    )
  );
};
