import * as React from "react";
import styled from "@emotion/styled";
import { connect, ConnectedProps } from "react-redux";
import { Tooltip, Button, Radio } from "antd";
import { Script, Project } from "@ea/shared_types";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { ROUTES } from "@app/routes";
import {
  isSyncingSelector,
  getSessionParams,
  syncErrorSelector,
  isSomethingNotSynced,
  getRecordedStep,
} from "../runner.selectors";
import { LoadingOutlined, SaveFilled } from "@ant-design/icons";
import PauseIcon from "../icons/PauseIcon";
import RecordIcon from "../icons/RecordIcon";
import InspectIcon from "../icons/InspectIcon";
import { NAVBAR_COLOR } from "@app/styles/styles";
import history from "../../../utils/history";
import PlayIcon from "../icons/PlayIcon";
import { EXECUTION_STATE, RunnerMode } from "@ea/shared_types/types";
import "../RunnerControlPanel.css";
import RenderStepLabel from "@app/modules/steps/components/RenderStepLabel";
import { StepLabelContainer } from "../RunnerComponentLayout";
import ScriptTooltip from "../ScriptTooltip";
import { ApplicationState } from "@app/modules/app.reducers";

interface RecorderControlPanelProps {
  sessionId: string;
  script: Script;
  project: Project;
  isInspecting: boolean;
  onPause: () => void;
  onRecord: () => void;
  onInspect: () => void;
  onSave: () => void;
  onModeToggle: () => void;
  mode: RunnerMode;
  minimalistic?: boolean;
}

const ControlPanel = styled.div({
  display: "flex",
  height: "40px",
  backgroundColor: NAVBAR_COLOR,
  alignItems: "center",
  justifyContent: "space-between",
  paddingRight: "10px",
});

const TooltipBody = styled.div({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  color: "white",
  maxWidth: "250px",
  cursor: "pointer",
});

const Logo = styled.div({
  display: "flex",
  height: "40px",
  padding: "3px",
  justifySelf: "flex-start",
  alignItems: "center",
  justifyContent: "space-between",
});

const RunnerModeContainer = styled.span(
  {
    marginLeft: "10px",
    display: "flex",
    minWidth: "165px",
  },
  ({ disabled }: { disabled: boolean }) => ({
    color: disabled ? "hsla(0,0%,100%,.65)" : "white",
    cursor: disabled ? "not-allowed" : "pointer",
  }),
);

const controlBarIconStyle = { width: "42px" };

class RecorderControlPanel extends React.Component<RecorderControlPanelProps & IConnectProps> {
  getPlayIcon = (playerState: EXECUTION_STATE) => {
    switch (playerState) {
      case EXECUTION_STATE.RUNNING:
        return (
          <PauseIcon onClick={() => {}} style={{ ...controlBarIconStyle, marginRight: "30px" }} />
        );
      case EXECUTION_STATE.PAUSED:
        return (
          <PlayIcon onClick={() => {}} style={{ ...controlBarIconStyle, marginRight: "30px" }} />
        );
      default:
        return (
          <PlayIcon onClick={() => {}} style={{ ...controlBarIconStyle, marginRight: "30px" }} />
        );
    }
  };

  render() {
    const {
      script,
      project,
      isSyncing,
      isRecording,
      isInspecting,
      onPause,
      onRecord,
      onInspect,
      onSave,
      onModeToggle,
      mode,
      syncError,
      isNotSynced,
      minimalistic,
      recordedStep,
    } = this.props;

    return (
      <ControlPanel>
        {!minimalistic && (
          <Logo>
            <RunnerModeContainer className="runner-mode-container" disabled={isSyncing}>
              <Radio.Group
                options={[
                  {
                    label: <FormattedMessage id={getTranslationKey("runner", "mode", "player")} />,
                    value: RunnerMode.PLAYER,
                  },
                  {
                    label: (
                      <FormattedMessage id={getTranslationKey("runner", "mode", "recorder")} />
                    ),
                    value: RunnerMode.RECORDER,
                  },
                ]}
                disabled={isSyncing || isNotSynced}
                onChange={isSyncing || isNotSynced ? () => {} : onModeToggle}
                value={mode}
                optionType="button"
                buttonStyle="solid"
              />
            </RunnerModeContainer>
          </Logo>
        )}
        {!minimalistic ? (
          <Tooltip placement="topLeft" title={<ScriptTooltip script={script} project={project} />}>
            <TooltipBody
              onClick={() =>
                history.push({
                  pathname: `${ROUTES.scripts}/${script.id}`,
                })
              }
            >
              [{script.id}] {script.name}
            </TooltipBody>
          </Tooltip>
        ) : (
          <RenderStepLabel step={recordedStep}>
            {(text) => (
              <StepLabelContainer>
                {recordedStep?.lineNum}. {text}
              </StepLabelContainer>
            )}
          </RenderStepLabel>
        )}

        <Button
          icon={
            isSyncing ? (
              <LoadingOutlined style={{ color: "white" }} />
            ) : syncError ? (
              <Tooltip
                placement="bottom"
                title={<FormattedMessage id={getTranslationKey("runner", "syncError")} />}
              >
                <SaveFilled style={{ color: "red" }} />
              </Tooltip>
            ) : (
              <SaveFilled style={{ color: "white" }} />
            )
          }
          onClick={onSave}
          style={{ ...controlBarIconStyle, background: NAVBAR_COLOR, border: "none" }}
        />
        {isRecording ? (
          <PauseIcon onClick={onPause} style={controlBarIconStyle} />
        ) : (
          <RecordIcon pressed={false} isRecording={false} onClick={onRecord} />
        )}
        {isRecording ? (
          <InspectIcon
            onClick={onInspect}
            style={{ color: `${isInspecting ? "red" : "white"}`, ...controlBarIconStyle }}
          />
        ) : (
          <div style={controlBarIconStyle} />
        )}
      </ControlPanel>
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: RecorderControlPanelProps) => ({
    isSyncing: isSyncingSelector(state, props.sessionId),
    isNotSynced: isSomethingNotSynced(state, props.sessionId),
    syncError: syncErrorSelector(state, props.sessionId),
    isRecording: getSessionParams(state, props.sessionId).isRecording,
    recordedStep: getRecordedStep(state, props.sessionId),
  }),
  () => ({}),
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(RecorderControlPanel);
