import { objectValues } from "@ea/shared_components/utils/object";

import * as globalVariablesActions from "./globalVariables.actions";
import globalVariablesEpicsMap from "./globalVariables.epics";
import * as globalVariablesReducers from "./globalVariables.reducers";

const globalVariablesEpics = objectValues(globalVariablesEpicsMap);

export { globalVariablesActions, globalVariablesReducers, globalVariablesEpics };

export { default as GlobalVariablesTableContainer } from "./GlobalVariablesTable.container";
