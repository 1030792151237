import { objectValues } from "@ea/shared_components/utils/object";

import * as reportTemplatesActions from "./reportTemplates.actions";
import reportTemplatesEpicsMap from "./reportTemplates.epics";
import * as reportTemplatesReducers from "./reportTemplates.reducers";

const reportTemplatesEpics = objectValues(reportTemplatesEpicsMap);

export { reportTemplatesActions, reportTemplatesReducers, reportTemplatesEpics };

export { default as ReportTemplatesTableContainer } from "./ReportTemplatesTable.container";
