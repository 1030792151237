import { NodeFormItem } from "../Form/Form.common";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Omit } from "@ea/shared_types/basic.types";

export const getParentKey = (id: number, tree: NodeFormItem[]): number => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.id === id)) {
        parentKey = node.id;
      } else if (getParentKey(id, node.children)) {
        parentKey = getParentKey(id, node.children);
      }
    }
  }
  return parentKey;
};

export const generateList = (data: NodeFormItem[]): Omit<NodeFormItem, "children">[] => {
  let dataList: Omit<NodeFormItem, "children">[] = [];

  function generate(items: NodeFormItem[]) {
    for (let i = 0; i < items.length; i++) {
      const node = items[i];
      const { children, ...rest } = node;
      dataList.push(rest);
      if (children) {
        generate(children);
      }
    }
  }

  generate(data);

  return dataList;
};

export const searchParentsByValue = (
  flatValues: Omit<NodeFormItem, "children">[],
  tree: NodeFormItem[],
  searchValue: string,
): (CheckboxValueType | null)[] =>
  flatValues
    .map((item) => {
      const normalizeItemName = item.name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

      if (normalizeItemName.includes(searchValue)) {
        return getParentKey(item.id, tree);
      }
      return null;
    })
    .filter((item, i, self) => item && self.indexOf(item) === i);
