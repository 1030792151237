import styled from "@emotion/styled";
import { Spin } from "antd";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { Tabs } from "antd";
import ScriptVariablesContainer from "./ScriptVariables.container";
import ScriptGlobalVariablesContainer from "./ScriptGlobalVariables.container";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";

const TabPane = Tabs.TabPane;
enum VariableTypes {
  LOCAL = "Local",
  GLOBAL = "Global",
}
interface IScriptVariablesLoaderProps {
  scriptId: number;
}
interface IScriptVariablesLoaderState {
  variableType: VariableTypes;
}

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});
const TopBar = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const SectionContainer = styled.div({
  padding: "5px",
  marginBottom: "20px",
  width: "100%",
  height: "100%",
  display: "flex",
});

class ScriptVariablesLoader extends React.Component<
  IScriptVariablesLoaderProps & IConnectProps,
  IScriptVariablesLoaderState
> {
  state = {
    variableType: VariableTypes.LOCAL,
  };

  pivotItems = [
    <TabPane
      key={VariableTypes.LOCAL}
      tab={<FormattedMessage id={getTranslationKey("variable", "label", "local")} />}
    />,
    <TabPane
      key={VariableTypes.GLOBAL}
      tab={<FormattedMessage id={getTranslationKey("variable", "label", "global")} />}
    />,
  ];

  setActiveTab = (type: VariableTypes) => {
    this.setState({ variableType: type });
  };

  render() {
    const { isLoading, scriptId } = this.props;
    const { variableType } = this.state;

    if (isLoading) {
      return (
        <Container>
          <Spin />
        </Container>
      );
    }

    return (
      <Container>
        <TopBar>
          <Tabs onChange={this.setActiveTab} activeKey={variableType}>
            {this.pivotItems}
          </Tabs>
        </TopBar>
        <SectionContainer>
          {variableType === VariableTypes.LOCAL ? (
            <ScriptVariablesContainer scriptId={scriptId} />
          ) : (
            <ScriptGlobalVariablesContainer scriptId={scriptId} />
          )}
        </SectionContainer>
      </Container>
    );
  }
}

const connectCreator = connect((state: ApplicationState, props: IScriptVariablesLoaderProps) => ({
  scriptId: props.scriptId,
  isLoading: state.scripts.isLoading,
}));

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(ScriptVariablesLoader);
