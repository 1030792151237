import {
  ScriptData,
  PlayerExecutionCache,
  ExecutionStep,
  FlatExecutionStep,
} from "../runner.common.types";

import {
  EXECUTION_STATUS,
  GuardsReactions,
  CONDITIONAL_TYPE,
  INTERRUPTION_TYPE,
  Message,
  MESSAGE_TYPE,
  MessagesWinner,
  Guard,
  Activity as OldActivity,
  ActivityAction,
  CONDITIONAL_ACTION_TYPE,
} from "../types";
import { Activity, ERROR_TYPES, MoveDirection, MoveDirections } from "../newRunner.types";
import { CoreCommandsIds } from "../core.commands.types";

export function isRootStep(step: ExecutionStep) {
  return step.execution.path.split(".").length === 1;
}

export function getPosition(steps: FlatExecutionStep[], path: string): number {
  return steps.findIndex((s) => s.path === path);
}

export function getAncestorsPaths(path: string): string[] {
  const paths: string[] = [];

  let parentStepPath = getParentStepPath(path);

  while (parentStepPath) {
    paths.push(parentStepPath);
    parentStepPath = getParentStepPath(parentStepPath);
  }

  return paths;
}

export function getParentStepPath(path: string): string | undefined {
  const paths = path.split(".");

  if (paths.length < 2) {
    return;
  }

  paths.pop();
  return paths.join(".");
}

export function getOptionalStepsMap(flatSteps: FlatExecutionStep[], steps: ExecutionStep[]) {
  return flatSteps.reduce((container, flatStep) => {
    container[flatStep.path] = findStep(steps, flatStep.path)?.isOptional;
    return container;
  }, {});
}

export function findStep(steps: ExecutionStep[], path: string): ExecutionStep {
  let chunks = path.split(".");
  if (chunks.length < 1) {
    throw new Error(`Cannot find execution step for this path: ${path}`);
  }

  chunks.reverse();

  const find = (exSteps: ExecutionStep[], id: string | undefined) => {
    const getNextStep = () => {
      for (const step of exSteps) {
        if (step.id.toString() === id) {
          return step;
        }
      }
    };
    const nextStep = getNextStep();
    const nextId = chunks.pop();

    if (!nextStep) {
      throw new Error(`Cannot find execution step for this path: ${path}`);
    }

    if (!nextId) {
      return nextStep;
    }

    if (!chunks.length) {
      for (const step of nextStep.execution.steps) {
        if (step.id.toString() === nextId) {
          return step;
        }
      }
    }

    return find(nextStep.execution.steps, nextId);
  };

  return find(steps, chunks.pop());
}

export function traverseScriptData(data: ScriptData, cb: (data: ScriptData) => void) {
  const handleScriptData = (innerData: ScriptData) => {
    cb(innerData);
    Object.keys(innerData.links).forEach((key) => {
      handleScriptData(innerData.links[key]);
    });
  };

  handleScriptData(data);
}

export function modifyScriptData(
  newScriptData: ScriptData,
  oldScriptData: ScriptData,
  modifier: (newS: ScriptData, oldS: ScriptData) => void,
) {
  function traverse(newSD: ScriptData, oldSD: ScriptData) {
    modifier(newScriptData, oldScriptData);

    Object.keys(newSD.links).forEach((link) => traverse(newSD.links[link], oldSD.links[link]));
  }

  traverse(newScriptData, oldScriptData);
}

export function findGuards(currentStepPath, steps): { guards: Guard[]; guardedStepPath?: string } {
  const currentStep = findStep(steps, currentStepPath);

  if (currentStep.linkedScriptId) {
    // Only return interruption guards for linked steps. Success guards should execute after execution of entire linked script and we do it when we find 'end' step in linked script (it is handled by code a little below).
    // We want to keep interruption guards here because there can be a situation when linked script stops with error before executing itself
    //
    //  4. Type value (causes error that is visible all the time)
    //  5. Linked Script (has error guard that override status to success and continues on error, we need to return the error guard to dont break the execution here)
    //   5.1 Step 1 (handled by parent guard)
    //   5.2 Step 2 (handled by parent guard)
    //   5.3 Step 3 (handled by parent guard)
    //

    const guards = (currentStep.guards || []).filter(
      (g) => g.type === CONDITIONAL_TYPE.INTERRUPTION,
    );

    return { guards, guardedStepPath: guards.length ? currentStepPath : undefined };
  }

  if (currentStep.guards?.length) {
    return { guards: currentStep.guards!, guardedStepPath: currentStepPath };
  }

  const parentStepPath = getParentStepPath(currentStepPath);

  if (parentStepPath) {
    const parent = findStep(steps, parentStepPath);

    if (parent.guards?.length) {
      // if we're considering a regular step without a guard on itself
      // we look for guards in the parent
      // if actual step is the ending step of a script we take parent sucess guards into considerations
      // as well as interruption guards
      // if it's a step prior to the end, we take only interruption parent guards into consideration
      // as we don't want to execute parent success guard when some step in the middle of a child script
      // finishes with a success
      return {
        guards: parent.guards.filter((g: Guard) =>
          currentStep.commandId === CoreCommandsIds.end
            ? true
            : g.type === CONDITIONAL_TYPE.INTERRUPTION,
        ),
        guardedStepPath: parentStepPath,
      };
    }

    if (isRootStep(parent)) {
      return { guards: [] };
    }

    return findGuards(parent.execution.path, steps);
  }

  return { guards: [] };
}

export function findAllStepsForGivenLevel(steps: ExecutionStep[], path: string): ExecutionStep[] {
  let chunks = path.split(".");
  if (chunks.length < 1) {
    throw new Error(`Cannot find execution steps for this path: ${path}`);
  }
  // throw out step id
  chunks.pop();
  chunks.reverse();

  const find = (dSteps: ExecutionStep[], id: string | undefined) => {
    if (id === undefined) {
      return dSteps;
    }

    for (const step of dSteps) {
      if (step.id.toString() === id) {
        return find(step.execution.steps, chunks.pop());
      }
    }
    return [];
  };

  return find(steps, chunks.pop());
}

export function getMoveDirection(
  step: ExecutionStep,
  prevStep?: ExecutionStep,
): MoveDirection | undefined {
  if (!prevStep) {
    return;
  }

  const prevStepPathChunks = prevStep.execution.path.split(".");
  const currentStepPathChunks = step.execution.path.split(".");

  if (currentStepPathChunks.length === prevStepPathChunks.length) {
    return;
  }

  if (currentStepPathChunks.length > prevStepPathChunks.length) {
    return {
      moveDirection: MoveDirections.IN,
      from: prevStep.execution.path,
      to: step.execution.path,
    };
  }

  return {
    moveDirection: MoveDirections.OUT,
    from: prevStep.execution.path,
    to: step.execution.path,
  };
}

export function findAllEnabledSteps(steps: ExecutionStep[], flatSteps: FlatExecutionStep[]) {
  const stepsMap = steps.reduce((map, step) => {
    map[step.id] = step;
    return map;
  }, {});

  return flatSteps.filter((f) => stepsMap[f.path].isDisabled).map((f) => stepsMap[f.path]);
}

export function updateScriptData(rootData: ScriptData, path: string, newData: ScriptData) {
  const chunks = path.split(".");

  const stepId = chunks.pop();

  if (!stepId) {
    throw new Error("Incorrect step path");
  }

  let correctData = rootData;
  for (const chunk of chunks) {
    correctData = correctData.links[chunk] || correctData;
  }

  Object.assign(correctData, newData);
}

export function findScriptData(data: ScriptData, path: string): ScriptData {
  const chunks = path.split(".");

  const stepId = chunks.pop();

  if (!stepId) {
    throw new Error("Incorrect step path");
  }

  let correctData = data;
  for (const chunk of chunks) {
    correctData = correctData.links[chunk] || correctData;
  }

  return correctData;
}

export function getExecutionStepFromPosition(
  executionSteps: ExecutionStep[],
  flatSteps: FlatExecutionStep[],
  position: number,
) {
  return findStep(executionSteps, flatSteps[position].path);
}

export function hasGuards(
  step: ExecutionStep,
  conditionalType: CONDITIONAL_TYPE,
  interruptionType?: INTERRUPTION_TYPE,
) {
  return (
    step &&
    step.guards &&
    step.guards.length > 0 &&
    (conditionalType
      ? step.guards.some(
          (g) =>
            g.type === conditionalType &&
            (interruptionType
              ? g.reactions.some((r) => GuardsReactions[r.reactOn].includes(interruptionType))
              : true),
        )
      : true)
  );
}

export function iterateExecutionSteps(
  steps: ExecutionStep[],
  action: (step: ExecutionStep) => void,
) {
  const iterate = (nestedSteps: ExecutionStep[]) => {
    for (let step of nestedSteps) {
      action(step);
      iterate(step.execution.steps);
    }
  };

  iterate(steps);
}

export function getExecutionStepStatus(step: ExecutionStep) {
  if (step.execution.status !== undefined) {
    return step.execution.status;
  }

  return EXECUTION_STATUS.NONE;
}

export function getGuardActivity(
  guards: Guard[],
  messageWinner: MessagesWinner,
): Activity | undefined {
  const conditionalType =
    messageWinner.status === EXECUTION_STATUS.SUCCESS
      ? CONDITIONAL_TYPE.SUCCESS
      : CONDITIONAL_TYPE.INTERRUPTION;
  const guard = guards.find((g) => g.type === conditionalType);
  if (!guard) {
    return;
  }

  const mapActivity = (
    guardActivity: ActivityAction | OldActivity,
    activityType: CONDITIONAL_ACTION_TYPE,
  ): Activity => {
    if (activityType === CONDITIONAL_ACTION_TYPE.SIMPLE) {
      const act = guardActivity as ActivityAction;
      return {
        type: activityType,
        action: {
          ...act,
          overrideStatusTo: act.overrideStatus ? act.overrideStatusTo : undefined,
        },
      } as Activity as any;
    }

    const act2 = guardActivity as OldActivity;

    return {
      ...guardActivity,
      ifAction: {
        ...act2.ifAction,
        overrideStatusTo: act2.ifAction.overrideStatus ? act2.ifAction.overrideStatusTo : undefined,
      },
      elseAction: {
        ...act2.elseAction,
        overrideStatusTo: act2.elseAction.overrideStatus
          ? act2.elseAction.overrideStatusTo
          : undefined,
      },
      type: activityType,
    } as Activity as any;
  };

  if (conditionalType === CONDITIONAL_TYPE.SUCCESS) {
    return mapActivity(guard.reactions[0].activity, guard.reactions[0].activityType);
  }

  const reactionsMatcher = {
    [INTERRUPTION_TYPE.ELEMENT_NOT_FOUND]: () => {
      if (messageWinner.status !== EXECUTION_STATUS.ERROR) {
        return false;
      }
      const hasElementNotFound = messageWinner.messages.find((m) =>
        m.errorType?.includes(ERROR_TYPES.NoElementError),
      );
      return hasElementNotFound;
    },
    [INTERRUPTION_TYPE.ERROR]: () =>
      messageWinner.status === EXECUTION_STATUS.ERROR && !messageWinner.isPlatform,
    [INTERRUPTION_TYPE.SYSTEM_ERROR]: () =>
      messageWinner.status === EXECUTION_STATUS.ERROR && messageWinner.isPlatform,
    [INTERRUPTION_TYPE.SYSTEM_WARNING]: () =>
      messageWinner.status === EXECUTION_STATUS.WARNING && messageWinner.isPlatform,
    [INTERRUPTION_TYPE.WARNING]: () =>
      messageWinner.status === EXECUTION_STATUS.WARNING && !messageWinner.isPlatform,
  };

  // todo: R2.0 add matcher here
  const reaction = guard.reactions.find((r) => reactionsMatcher[r.reactOn]());

  if (!reaction) {
    return;
  }

  return mapActivity(reaction.activity, reaction.activityType);
}

export function getMessagesWinner(messages: Message[]): MessagesWinner {
  const messagesByType = {
    platformError: [] as Message[],
    error: [] as Message[],
    platformWarning: [] as Message[],
    warning: [] as Message[],
    info: [] as Message[],
    analysis: [] as Message[],
  };

  let overrideStatus: EXECUTION_STATUS | undefined = undefined;

  messages.forEach((message) => {
    if (message.overrideStatus) {
      // todo: R2.0 does order matter?
      overrideStatus = message.overrideStatus;
    }
    if (message.type === MESSAGE_TYPE.ANALYSIS) {
      messagesByType.analysis.push(message);
    } else if (message.type === MESSAGE_TYPE.ERROR && message.isPlatformMessage) {
      messagesByType.platformError.push(message);
    } else if (message.type === MESSAGE_TYPE.ERROR) {
      messagesByType.error.push(message);
    } else if (message.type === MESSAGE_TYPE.INFO) {
      messagesByType.info.push(message);
    } else if (message.type === MESSAGE_TYPE.WARNING && message.isPlatformMessage) {
      messagesByType.platformWarning.push(message);
    } else if (message.type === MESSAGE_TYPE.WARNING) {
      messagesByType.warning.push(message);
    }
  });

  if (messagesByType.error.length || messagesByType.platformError.length) {
    return {
      status: EXECUTION_STATUS.ERROR,
      messages: messagesByType.error,
      isApplication: !!messagesByType.error.length,
      isPlatform: !!messagesByType.platformError.length,
      overrideStatus,
    };
  }

  if (messagesByType.warning.length || messagesByType.platformWarning.length) {
    return {
      status: EXECUTION_STATUS.WARNING,
      messages: messagesByType.warning,
      isApplication: !!messagesByType.warning.length,
      isPlatform: !!messagesByType.platformWarning.length,
      overrideStatus,
    };
  }

  // todo: R2.0 can we handle PLATFORM SUCCESS MESSAGE?
  return {
    status: EXECUTION_STATUS.SUCCESS,
    messages: messagesByType.info,
    isApplication: true,
    isPlatform: false,
    overrideStatus,
  };
}

export function getExecutionStepsStatus(steps: ExecutionStep[]) {
  let winner = EXECUTION_STATUS.NONE;
  let hasChanceOnSuccess = true;
  for (const step of steps) {
    const s = getExecutionStepStatus(step);
    if (s === EXECUTION_STATUS.ERROR) {
      return EXECUTION_STATUS.ERROR;
    }
    if (s === EXECUTION_STATUS.WARNING) {
      hasChanceOnSuccess = false;
      winner = EXECUTION_STATUS.WARNING;
    }
    if (s === EXECUTION_STATUS.SUCCESS && hasChanceOnSuccess) {
      winner = EXECUTION_STATUS.SUCCESS;
    }
  }

  return winner;
}

export function getCurrentStep(
  state: {
    steps: PlayerExecutionCache["steps"];
    flatSteps: PlayerExecutionCache["flatSteps"];
    player: {
      position: PlayerExecutionCache["player"]["position"];
    };
  },
  customPosition?: number,
) {
  return findStep(
    state.steps,
    state.flatSteps[customPosition ?? (state.player.position === -1 ? 0 : state.player.position)]
      .path,
  );
}
