import { Spin } from "antd";
import styled from "@emotion/styled";
import * as React from "react";

import { COLORS, Z_INDEXES } from "../shared.consts";

interface ISavingAreaProps {
  isSaving: boolean;
  isHidden?: boolean;
  label?: string;
  className?: string;
}

const SpinnerContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "70%",
});

const Overlay = styled.div({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  zIndex: Z_INDEXES.SAVING_AREA,
  backgroundColor: COLORS.WHITE_OVERLAY,
});

const SavingArea: React.FunctionComponent<ISavingAreaProps> = ({
  isSaving,
  children,
  label,
  isHidden,
  className,
}) => {
  return (
    <>
      {isSaving ? (
        <Overlay className={className}>
          <SpinnerContainer>
            <Spin tip={label ? label : "Saving..."} />
          </SpinnerContainer>
        </Overlay>
      ) : null}

      {isSaving && isHidden ? null : children}
    </>
  );
};

export default SavingArea;
