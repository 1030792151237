import { getIn } from "final-form";
import * as React from "react";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { AutoCompleteField } from "../Form/Fields/AutoCompleteField";
import { FieldStatus } from "../Form/FormItem/FormItem";
import { composeValidators, validators } from "../Form/validators";
import { Variable, VariableType } from "@ea/shared_types/types";
import { getTranslationKey } from "./toMigrate";

interface IAssignVariablePickerProps {
  variables: Variable[];
  values: any;
  name: string;
  readOnly?: boolean;
  label?: string;
  required?: boolean;
}

export const isVariableNew = (value) => value !== undefined && value !== "" && isNaN(value);

const AssignVariablePickerComponent: React.FunctionComponent<
  IAssignVariablePickerProps & InjectedIntlProps
> = ({ variables, name, values, readOnly, label, intl, required }) => {
  const filteredVariables = variables.filter((v) => v.type === VariableType.Normal);
  const variableMap = filteredVariables.reduce((map, v) => {
    map[v.id] = v.name;
    return map;
  }, {});
  const source = filteredVariables.map((v) => ({ text: v.name, value: v.id.toString() }));
  const value = getIn(values, name);
  const isNew = isVariableNew(value);

  const { whiteSpaceAndSpecialCharValidator, mustStartWithLetter } = validators(intl);
  const isIdOrStartsLetterValidator = (val) => {
    if (val && !isNaN(val)) {
      return !source.find((variable) => variable.value === `${val}`)
        ? mustStartWithLetter(val)
        : false;
    }

    return val && mustStartWithLetter(val);
  };

  return (
    <AutoCompleteField
      readOnly={readOnly}
      name={name}
      label={label}
      dataSource={source}
      required={required}
      format={(v) => (v ? variableMap[v] : v)}
      overwriteOnChange
      status={isNew ? FieldStatus.WARNING : undefined}
      validate={composeValidators(whiteSpaceAndSpecialCharValidator, isIdOrStartsLetterValidator)}
      showHelp={isNew}
      placeholder={getTranslationKey("step", "placeholder", "pick")}
      help={getTranslationKey("messages", "help", "pick")}
      parse={(v) => +v || v}
    />
  );
};

export const AssignVariablePicker = injectIntl(AssignVariablePickerComponent);
