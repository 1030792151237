/** @jsx jsx */
import { jsx } from "@emotion/core";
import * as React from "react";
import styled from "@emotion/styled";
import { css as _css } from "@emotion/core";
import { Badge, Button, Col, Row } from "antd";
import { COLORS, BADGE_OVERFLOW_COUNT } from "../shared.consts";
import { css } from "@emotion/core";
import { EditeableAreaComponent } from "../EditeableArea/EditeableArea";
import { FormattedMessage } from "react-intl";
import { UpOutlined, DownOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";
import { DataTestIds } from "../utils/dataTestHelpers";
import "react-splitter-layout/lib/index.css";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const IconHorizontalContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  marginTop: "150px",
});

const IconVerticalContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "5px",
  marginBottom: "5px",
});

const Header = styled.h3({
  marginTop: "10px",
  marginBottom: "5px",
});

const Column = styled(Col)({
  height: "100%",
});

// TODO calculate offsets used in `calc` from heights of components that move
// other components down
const ShortRow = styled(Row)({ width: "100%", height: "calc(100% - 40px)" });

const VerticalLayoutRow = styled(Row)({
  width: "100%",
  height: "calc(100% - 380px)",
});

interface FlowEditProps<T, K> {
  selected: Partial<T>[];
  selectedOutputItems: K[];
  renderSourceTables: () => JSX.Element;
  renderOutputTable: () => JSX.Element;
  setRef: (element: EditeableAreaComponent) => any;
  onAdd: () => void;
  onRemove: () => void;
  onSave: () => Promise<any>;
  vertical?: boolean;
  onCancel: () => void;
  spanConfiguration?: {
    // TODO add default params for spanConfiguration
    source: number;
    middle: number;
    output: number;
  };
  sourceTableTitleKey?: string;
  destinationTableTitleKey?: string;
}

interface FlowEditState {}

class FlowEdit<T, K>
  extends React.Component<FlowEditProps<T, K>, FlowEditState>
  implements EditeableAreaComponent
{
  state: FlowEditState = {};

  componentDidMount() {
    this.props.setRef(this);
  }

  getSelectedCount() {
    return this.props.selected ? this.props.selected.length : 0;
  }

  save = () => this.props.onSave();

  cancel = () => this.props.onCancel();

  render() {
    const {
      spanConfiguration,
      sourceTableTitleKey,
      destinationTableTitleKey,
      vertical,
      onRemove,
      onAdd,
      renderOutputTable,
      renderSourceTables,
      selectedOutputItems,
    } = this.props;

    if (vertical) {
      return (
        <Container>
          <Row
            gutter={10}
            css={{
              width: "100%",
            }}
            style={{ minHeight: "250px", maxHeight: "500px" }}
          >
            <Col flex={1}>
              {destinationTableTitleKey && (
                <h3>
                  <FormattedMessage id={destinationTableTitleKey} />
                </h3>
              )}
              {renderOutputTable()}
            </Col>
          </Row>
          <Row
            gutter={10}
            css={{
              width: "100%",
            }}
          >
            <Col flex={1}>
              <IconVerticalContainer>
                <div
                  className={
                    css({
                      marginRight: "30px",
                    }) as any
                  }
                >
                  <Badge
                    count={this.getSelectedCount()}
                    showZero={false}
                    overflowCount={BADGE_OVERFLOW_COUNT}
                    style={{ backgroundColor: COLORS.ADD_BADGE }}
                  >
                    <Button
                      type="primary"
                      disabled={this.getSelectedCount() === 0}
                      onClick={onAdd}
                      data-testid={DataTestIds.BUTTON_ARROW_UP}
                    >
                      <UpOutlined />
                    </Button>
                  </Badge>
                </div>
                <Badge
                  count={selectedOutputItems.length}
                  showZero={false}
                  overflowCount={BADGE_OVERFLOW_COUNT}
                >
                  <Button
                    type="primary"
                    disabled={selectedOutputItems.length === 0}
                    onClick={onRemove}
                    data-testid={DataTestIds.BUTTON_ARROW_DOWN}
                  >
                    <DownOutlined />
                  </Button>
                </Badge>
              </IconVerticalContainer>
            </Col>
          </Row>
          <VerticalLayoutRow gutter={10} style={{ maxHeight: "500px", minHeight: "200px" }}>
            <Column flex={1}>
              {sourceTableTitleKey && (
                <h3>
                  <FormattedMessage id={sourceTableTitleKey} />
                </h3>
              )}
              {renderSourceTables()}
            </Column>
          </VerticalLayoutRow>
        </Container>
      );
    }

    return (
      <ShortRow gutter={10}>
        <Column span={(spanConfiguration && spanConfiguration.source) || 11}>
          {sourceTableTitleKey && (
            <Header>
              <FormattedMessage id={sourceTableTitleKey} />
            </Header>
          )}
          {renderSourceTables()}
        </Column>
        <Column span={(spanConfiguration && spanConfiguration.middle) || 2}>
          <IconHorizontalContainer>
            <Badge
              style={{ backgroundColor: COLORS.ADD_BADGE }}
              count={this.getSelectedCount()}
              showZero={false}
              overflowCount={BADGE_OVERFLOW_COUNT}
            >
              <Button
                type="primary"
                style={{ marginBottom: "20px" }}
                disabled={this.getSelectedCount() === 0}
                onClick={onAdd}
                data-testid={DataTestIds.BUTTON_ARROW_RIGHT}
              >
                <RightOutlined />
              </Button>
            </Badge>
            <Badge
              count={selectedOutputItems.length}
              showZero={false}
              overflowCount={BADGE_OVERFLOW_COUNT}
            >
              <Button
                type="primary"
                disabled={selectedOutputItems.length === 0}
                onClick={onRemove}
                data-testid={DataTestIds.BUTTON_ARROW_LEFT}
              >
                <LeftOutlined />
              </Button>
            </Badge>
          </IconHorizontalContainer>
        </Column>
        <Column span={(spanConfiguration && spanConfiguration.output) || 11}>
          {destinationTableTitleKey && (
            <Header>
              <FormattedMessage id={destinationTableTitleKey} />
            </Header>
          )}
          {renderOutputTable()}
        </Column>
      </ShortRow>
    );
  }
}

export default FlowEdit;
