import * as ReactIntl from "react-intl";
import * as sharedComponents from "@ea/shared_components";
import * as ReactDOM from "react-dom";
import * as React from "react";
import * as finalForm from "final-form";
// we are increasing bundle size, maybe we shouldnt use external export with antd?
import * as antd from "antd";

const registerDep = (name, requirer) => {
  // Webpack builds expects externals to be on the root.
  (window as any)[name] = requirer;

  // I'm using SystemJS to import my SPAs and it seems to expect the module to be registered here too.
  // (window as any).System.registerDynamic(name, [], false, (r, e, m) => {
  //   m.exports = requirer;
  // });
};

registerDep("react", React);
registerDep("reactDom", ReactDOM);
registerDep("reactIntl", ReactIntl);
registerDep("sharedComponents", sharedComponents);
registerDep("finalForm", finalForm);
registerDep("antd", antd);

const generateResourceScriptTag: any = (path) =>
  new Promise((resolve, reject) => {
    const script = document.createElement("script");
    document.body.appendChild(script);
    script.onload = resolve;
    script.onerror = reject;
    script.async = true;
    script.src = path;
  });

const loadResource = (path) => {
  return fetch(path).then(() => generateResourceScriptTag(path));
};

const filterDefaultPackage = (content) => {
  return content.filter((path) => path.includes("/default"));
};

const filterRestPackage = (content) => {
  return content.filter((path) => !path.includes("/default"));
};

export const loadRunnerPacks = async () => {
  return fetch("/runner-packs")
    .then((res) => res.json())
    .then(async (json) => {
      await Promise.all([
        ...filterDefaultPackage(json.commands).map((c) => loadResource(c)),
        ...filterDefaultPackage(json.platforms).map((c) => loadResource(c)),
        ...filterDefaultPackage(json.rules).map((c) => loadResource(c)),
      ]),
        await Promise.all([
          ...filterRestPackage(json.commands).map((c) => loadResource(c)),
          ...filterRestPackage(json.platforms).map((c) => loadResource(c)),
          ...filterRestPackage(json.rules).map((c) => loadResource(c)),
        ]);
    })
    .then(() => loadOtherDefaultCommands());
};

import { getWebCommandsPack } from "@ea/runner_loader/commands.loader";
import { CoreCommandsIds } from "@ea/shared_types/core.commands.types";
import AssertFormChunk from "../modules/steps/components/Forms/Assert/AssertFormChunk";
import { DEFAULT_PLATFORM_ID } from "@ea/runner_loader/ea.internal.types";
import ScriptSystemAndVUFormChunk from "@app/modules/scripts/components/ScriptSystemAndVUFormChunk";
import { LayoutContext } from "@ea/shared_components/Form/FormLayoutContext";

const loadOtherDefaultCommands = () => {
  const defaultCommands = getWebCommandsPack(DEFAULT_PLATFORM_ID);

  defaultCommands[CoreCommandsIds.condition].getFormChunk = (api) => () =>
    (
      <LayoutContext.Consumer>
        {(layout) => (
          <AssertFormChunk
            readOnly={layout.readOnly}
            change={api.change}
            values={api.values}
            prefix="value"
            steps={api.steps}
            script={api.script}
            variables={api.variables}
            globalMutable={api.globalMutables}
            globalConstant={api.globalConstants}
            kpis={api.kpis}
          />
        )}
      </LayoutContext.Consumer>
    );

  defaultCommands[CoreCommandsIds.login].getFormChunk = (api) => () =>
    <ScriptSystemAndVUFormChunk {...api} prefix="value" />;
};
