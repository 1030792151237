import { getTranslationKey } from "@app/translations/translations.helpers";
import React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";

import { HideWhenDocumentationWizard } from "@app/modules/common/HideWhenDocumentationWizard";
import HideableFeature from "@app/modules/common/HideableFeature";
import WithAuthorization from "@app/modules/common/WithAuthorization";
import { API } from "@app/services/api/api";
import { DataTestIds } from "@app/utils/dataTestIds";
import { CheckboxField } from "@ea/shared_components/Form/Fields/CheckboxField";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import { SelectField } from "@ea/shared_components/Form/Fields/SelectField";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import validators, { composeValidators } from "@ea/shared_components/Form/validators";
import {
  FEATURES,
  IssueTrackingTool,
  ROLES,
  ReportExtension,
  SSO,
  SupportedLanguages,
} from "@ea/shared_types/types";
import { getIn } from "final-form";

interface IUserBasicSettingsFormProps {
  readOnly?: boolean;
  values: any;
  change: (name: string, value: any) => void;
  isEdit: boolean;
  its: IssueTrackingTool[];
  mySettings?: boolean;
}

interface IUserBasicSettingsFormState {
  roleOptions: OptionType[];
  localizationOptions: OptionType[];
}

type Props = IUserBasicSettingsFormProps & InjectedIntlProps;

const AdminCheckboxField = WithAuthorization([ROLES.admin])(CheckboxField);
const AdminField = WithAuthorization([ROLES.admin])(SelectField);

const SSOLabels = {
  [SSO.AzureActiveDirectory]: "Azure Active Directory",
};
class UserBasicSettingsForm extends React.Component<Props, IUserBasicSettingsFormState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      roleOptions: [],
      localizationOptions: [],
    };
    this.loadRoles();
    this.loadLocales();
  }

  loadRoles = async () => {
    const results = await API.getRoles({});
    const options = results.map((element) => ({
      key: element.id,
      value: element.id,
      text: element.name,
    }));

    this.setState({
      roleOptions: options,
    });
  };

  loadLocales = async () => {
    const localizations = await API.getLocalization({});
    const createLocalizationOption = () =>
      localizations.map((l) => ({
        key: l.id,
        text: l.locale,
        value: l.id,
      }));

    this.setState({
      localizationOptions: createLocalizationOption(),
    });
  };

  onRecorderTypeChange = () => {
    this.props.change("settings.recorder.openMode", undefined);
  };

  render() {
    const { readOnly, intl, isEdit, its, values, mySettings } = this.props;
    const { localizationOptions, roleOptions } = this.state;
    const {
      emailValidator,
      stringEqualsNullValidator,
      emailValidatorWithEmptyEmail,
      passwordPolicyValidator,
      detectSpacesValidator,
    } = validators(intl);

    const isSSO = getIn(values, "sso");

    return (
      <>
        <SelectField
          allowClear
          name="sso"
          disabled={mySettings && isEdit}
          placeholder={getTranslationKey("common", "placeholder", "sso")}
          label={getTranslationKey("common", "label", "sso")}
          options={Object.keys(SSO).map((s) => ({
            value: s,
            text: SSOLabels[SSO[s]],
          }))}
          data-testid={DataTestIds.FORM_SELECT_SSO}
        />
        <InputField
          name="username"
          required
          readOnly={readOnly || (mySettings && isEdit && isSSO)}
          placeholder={getTranslationKey("common", "placeholder", "username")}
          label={getTranslationKey("common", "label", "username")}
          validate={composeValidators(stringEqualsNullValidator, detectSpacesValidator)}
          data-testid={DataTestIds.FORM_INPUT_USERNAME}
        />
        {!(isSSO && !isEdit) && (
          <InputField
            name="email"
            required={!isSSO}
            readOnly={readOnly || isSSO}
            placeholder={getTranslationKey("common", "placeholder", "email")}
            label={getTranslationKey("common", "label", "email")}
            validate={composeValidators(isSSO ? emailValidatorWithEmptyEmail : emailValidator)}
            data-testid={DataTestIds.FORM_INPUT_EMAIL}
          />
        )}
        {!isSSO && !isEdit && (
          <InputField
            name="password"
            required
            readOnly={readOnly}
            placeholder={getTranslationKey("common", "placeholder", "password")}
            label={getTranslationKey("common", "label", "password")}
            type="password"
            data-testid={DataTestIds.FORM_INPUT_PASSWORD}
            validate={passwordPolicyValidator}
          />
        )}
        <SelectField
          name="language"
          placeholder={getTranslationKey("common", "placeholder", "language")}
          required
          readOnly={readOnly}
          label={getTranslationKey("common", "label", "language")}
          options={Object.keys(SupportedLanguages).map((value) => ({ value, text: value }))}
          data-testid={DataTestIds.FORM_SELECT_LANGUAGE}
        />
        <AdminField
          name="roles"
          mode="multiple"
          format={(val) => {
            if (val && val.length > 0) {
              return val.map((v) => (v.name ? v.id : v));
            }
            return val;
          }}
          filterOption={false}
          placeholder={getTranslationKey("common", "placeholder", "roles")}
          label={getTranslationKey("common", "label", "roles")}
          options={roleOptions}
          data-testid={DataTestIds.FORM_SELECT_ROLES}
        />
        <HideWhenDocumentationWizard>
          {() => (
            <SelectField
              name="settings.its"
              placeholder={getTranslationKey("its", "placeholder", "its")}
              label={getTranslationKey("its", "label", "its")}
              options={its.map((i) => ({ value: i.id, text: i.name }))}
              allowClear
              data-testid={DataTestIds.FORM_SELECT_ITS}
            />
          )}
        </HideWhenDocumentationWizard>
        <SelectField
          name="settings.reportExtension"
          required
          placeholder={getTranslationKey("settings", "reportExtensionPlaceholder")}
          label={getTranslationKey("settings", "reportExtension")}
          data-testid={DataTestIds.FORM_SELECT_REPORT_EXTENSION}
          options={Object.keys(ReportExtension).map((extension) => ({
            value: extension,
            text: ReportExtension[extension],
          }))}
        />
        <HideableFeature feature={FEATURES.DOCUMENTATION}>
          {() => (
            <AdminCheckboxField
              name="settings.displayOnlyPublishedDocuments"
              label={getTranslationKey("settings", "displayOnlyPublishedDocuments")}
              readOnly={readOnly}
              data-testid={DataTestIds.FORM_CHECKBOX_DISPLAY_PUBLISHED}
            />
          )}
        </HideableFeature>

        <SelectField
          name="settings.documentationLocale"
          label={getTranslationKey("settings", "scriptsReportsLocale")}
          placeholder={getTranslationKey("settings", "selectScriptsReportsLocale")}
          options={localizationOptions}
          data-testid={DataTestIds.FORM_SELECT_DOCUMENTATION_LOCALE}
          required
        />
        <AdminCheckboxField
          name="isActive"
          label={getTranslationKey("common", "label", "isActive")}
          readOnly={readOnly}
          data-testid={DataTestIds.FORM_CHECKBOX_ISACTIVE}
        />
      </>
    );
  }
}

export default injectIntl(UserBasicSettingsForm);
