import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import CommandBar from "./components/GlobalVariablesCommandBar";
import {
  GLOBAL_VARIABLES_TABLES_CONFIG,
  GLOBAL_VARIABLES_COLUMNS,
} from "@app/modules/globalVariables/globalVariables.table";
import { getGlobalVariablesTableActions } from "@app/modules/globalVariables/globalVariables.actions";
import { globalVariablesDataSelectors } from "@app/modules/globalVariables/globalVariables.selectors";
import CreateEditGlobalVariableContainer from "@app/modules/globalVariables/CreateEditGlobalVariable.container";
import { Modal } from "antd";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds, DataTestIdProp } from "@app/utils/dataTestIds";
enum Panels {
  CREATE = "create",
  EDIT = "edit",
}

interface IGlobalVariablesTableState {
  openedPanel?: Panels;
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

const { confirm } = Modal;
class GlobalVariablesTableContainer extends React.Component<
  IConnectProps,
  IGlobalVariablesTableState
> {
  connectedTable: any;

  state = {
    openedPanel: undefined,
  };
  onAddClick = () => {
    this.setState({ openedPanel: Panels.CREATE });
  };
  onEditClick = () => {
    this.setState({ openedPanel: Panels.EDIT });
  };

  closePanel = () => {
    this.setState({
      openedPanel: undefined,
    });
  };

  onReloadClick = () => {
    if (!!this.connectedTable?.reload) {
      this.connectedTable.reload();
    }
  };

  onRemoveClick = () => {
    const { actions, selected, intl } = this.props;
    const { formatMessage } = intl;
    confirm({
      title: formatMessage({
        id: getTranslationKey(
          "messages",
          "confirm",
          selected.length > 1 ? "delGlobalVars" : "delGlobalVar",
        ),
      }),
      okText: formatMessage({ id: getTranslationKey("button", "delete") }),
      okType: "danger",
      cancelText: formatMessage({ id: getTranslationKey("button", "cancel") }),
      onOk: () => {
        actions.delete({ ids: selected });
      },
      onCancel() {},
      okButtonProps: { [`${DataTestIdProp}`]: DataTestIds.MODAL_BUTTON_DELETE } as any,
      cancelButtonProps: { [`${DataTestIdProp}`]: DataTestIds.MODAL_BUTTON_CANCEL } as any,
    });
  };

  render() {
    const { openedPanel } = this.state;
    const { selected, selectedVariable } = this.props;
    return (
      <Container>
        <CommandBar
          onAddClick={this.onAddClick}
          onEditClick={this.onEditClick}
          isEditDisabled={!selectedVariable}
          onReloadClick={this.onReloadClick}
          onRemoveClick={this.onRemoveClick}
          selected={selected}
        />
        <CreateEditGlobalVariableContainer
          visibility={!!openedPanel}
          onClose={this.closePanel}
          reload={this.onReloadClick}
          isEdit={openedPanel === Panels.EDIT}
          selected={selectedVariable}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={GLOBAL_VARIABLES_COLUMNS}
          tableId={GLOBAL_VARIABLES_TABLES_CONFIG.MAIN.id()}
          preferencesId={GLOBAL_VARIABLES_TABLES_CONFIG.MAIN.preferencesId}
          stateKey={"globalVariables"}
          tableActions={getGlobalVariablesTableActions}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(
      getGlobalVariablesTableActions(GLOBAL_VARIABLES_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ),
  },
});

const connectCreator = connect(
  (state: ApplicationState) => ({
    selected: globalVariablesDataSelectors.getSelectedSelector(
      state,
      GLOBAL_VARIABLES_TABLES_CONFIG.MAIN.id(),
    ),
    selectedVariable: globalVariablesDataSelectors.getSelectedItemSelector(
      state,
      GLOBAL_VARIABLES_TABLES_CONFIG.MAIN.id(),
    ),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator> & InjectedIntlProps;

export default connectCreator(injectIntl(GlobalVariablesTableContainer));
