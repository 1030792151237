import { createModuleEpics } from "@app/modules/app.reducers";
import { Storage } from "@ea/shared_types/types";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { storageDataSelectors } from "./storage.selectors";
import { moduleActions } from "./storage.actions";
import { STORAGE_COLUMNS_CONFIG } from "./storage.table";

const storageModuleEpics = createModuleEpics<Storage>(moduleActions, {
  normalizeItem: (role) => role,
  getRequestParams: (state, tableId) => {
    const tableParams = storageDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, STORAGE_COLUMNS_CONFIG);
    return params;
  },
  getSingleRequestParams: (query) => {
    return query;
  },
  api: {
    createItem: API.createStorage,
    deleteItem: API.deleteStorage,
    editItem: API.editStorage,
    getItems: API.getStorages,
    getItemsCount: API.getStorageCount,
  },
});

export default storageModuleEpics;
