import { createDataSelectors } from "@app/modules/app.reducers";
import { CodeTemplateGroup } from "@ea/shared_types/types";
import { createSelector } from "reselect";

export const codeTemplateGroupsDataSelectors =
  createDataSelectors<CodeTemplateGroup>()("codeTemplateGroups");

export const getTemplateGroupsFormOptions = createSelector(
  codeTemplateGroupsDataSelectors.getOrderedDataSelector,
  (templateGroups) =>
    Object.keys(templateGroups).map((key) => ({
      text: `[${templateGroups[key].id}] ${templateGroups[key].name}`,
      value: templateGroups[key].id,
    })),
);
