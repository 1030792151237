import * as React from "react";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { getScriptsTableActions } from "@app/modules/scripts/scripts.actions";
import { scriptsSelectorsModifier } from "@app/modules/scripts/scripts.selectors";
import { Script } from "@ea/shared_types/types";
import { SCRIPTS_TABLES_CONFIG, SCRIPTS_COLUMNS_CODE_TEMPLATE } from "../../scripts/scripts.tables";
import { RouteComponentProps, withRouter } from "react-router";

interface ICodeTemplatesUsedInTableProps {
  codeTemplateId: number;
}

const CodeTemplatesUsedInTable = ({
  codeTemplateId,
  history,
}: ICodeTemplatesUsedInTableProps & RouteComponentProps<undefined>) => {
  const openScript = (item: Script) => {
    history.push(`/scripts/${item.id}`);
  };

  return (
    <ConnectedTable
      key={"flat_table"}
      pageable
      selectable={false}
      modifySelectors={scriptsSelectorsModifier}
      columnsConfig={SCRIPTS_COLUMNS_CODE_TEMPLATE}
      onRowDoubleClick={openScript}
      tableId={SCRIPTS_TABLES_CONFIG.CODE_TEMPLATES.id(codeTemplateId)}
      preferencesId={SCRIPTS_TABLES_CONFIG.CODE_TEMPLATES.preferencesId}
      stateKey={"scripts"}
      tableActions={getScriptsTableActions}
      persistentQuery={{ codeTemplateId }}
    />
  );
};

export default withRouter(CodeTemplatesUsedInTable);
