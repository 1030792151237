import * as React from "react";
import PieChart from "recharts/es6/chart/PieChart";
import Cell from "recharts/es6/component/Cell";
import Legend from "recharts/es6/component/Legend";
import Tooltip from "recharts/es6/component/Tooltip";
import Pie from "recharts/es6/polar/Pie";

import { PlainObject } from "@ea/shared_components/redux/common.models";
import { getRandomColor } from "@app/utils/colors";
import { Statistic } from "@ea/shared_types/types";

interface ILogsPieChartProps {
  data: Statistic[];
  colors: PlainObject<string>;
}

const LogsPieChart: React.FunctionComponent<ILogsPieChartProps> = ({ data, colors }) => {
  return (
    <PieChart width={472} height={400}>
      <Pie
        dataKey="value"
        data={data}
        innerRadius={80}
        outerRadius={140}
        paddingAngle={data.length > 1 ? 5 : 0}
        label
      >
        {data.map((entry, index) => (
          <Cell key={index} fill={colors[entry.name] ? colors[entry.name] : getRandomColor()} />
        ))}
      </Pie>
      <Legend verticalAlign="bottom" height={36} />
      <Tooltip />
    </PieChart>
  );
};

export default LogsPieChart;
