import * as React from "react";
import { VirtualUser, VIRTUAL_USER_STATUS } from "@ea/shared_types/types";
import { COLORS } from "@app/styles/consts";
import { Tag, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
interface IVirtualUserStatusProps {
  status: VirtualUser["status"];
}

const style = {
  cursor: "default",
};

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const VirtualUserStatus: React.FunctionComponent<IVirtualUserStatusProps> = ({
  status = VIRTUAL_USER_STATUS.NOT_AUTHENTICATED,
}) => {
  if (status === VIRTUAL_USER_STATUS.AUTHENTICATING) {
    return <Spin indicator={antIcon} />;
  }
  return (
    <Tag style={style} color={COLORS.VIRTUAL_USER_STATUS[status]}>
      {status}
    </Tag>
  );
};

export default VirtualUserStatus;
