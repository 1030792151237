export const NAVBAR_HEIGHT = "40px";
export const TABLE_ROW_HEIGHT = 43;
export const BADGE_OVERFLOW_COUNT = 9999;

export const COLORS = {
  WHITE_OVERLAY: "rgba(255,255,255,.4)",
  ADD_BADGE: "#52c41a",
};

export const Z_INDEXES = {
  TABLE_SETTINGS: 10,
  SAVING_AREA: 500,
  VIRTUALIZED_TABLE: 5000,
};
