import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { Documentation } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("DOCUMENTATION_MODULE");

export const moduleActions = createDataModuleActions<Documentation>(actionCreator);
export const getDocumentationTableActions = createTableActionsGetter(
  normalizeActions(moduleActions.table),
);
export const documentationActions = {
  ...normalizeActions(moduleActions.data),
};
export const documentationActionCreators = {
  ...moduleActions,
};
