import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { StepLog } from "@ea/shared_types/types";
import { TableActionParams } from "@ea/shared_components/redux/common.models";

const actionCreator = actionCreatorFactory("STEP_LOGS_MODULE_2");

const otherTableActions = {
  setIsGeneratingReport:
    actionCreator<TableActionParams<{ isGeneratingReport: boolean }>>("SET_GENERATING_REPORT"),
};

export const moduleActions = createDataModuleActions<StepLog>(actionCreator);
export const getStepLogsTableActions = createTableActionsGetter(
  normalizeActions({ ...moduleActions.table, ...otherTableActions }),
);
export const stepLogsActions = {
  ...normalizeActions(moduleActions.data),
  ...otherTableActions,
};
export const stepLogsActionCreators = {
  ...moduleActions,
  ...otherTableActions,
};
