import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";

import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { DataTestIds } from "@app/utils/dataTestIds";
interface CodeTemplateGroupsCommandBarProps {
  onAddClick: () => void;
  onEditClick: () => void;
  onReloadClick: () => void;
  onRemoveClick: () => void;
  isEditDisabled: boolean;
  selected: number[];
}

const CodeTemplateGroupsCommandBar = ({
  onAddClick,
  onEditClick,
  isEditDisabled,
  onReloadClick,
  onRemoveClick,
  selected,
}: CodeTemplateGroupsCommandBarProps) => (
  <CommandBar data-testid={DataTestIds.COMMANDBAR_CODE_TEMPLATE_GROUPS}>
    <CommandBarButton
      name="add"
      onClick={onAddClick}
      text={getTranslationKey("commandBar", "add")}
      icon="PlusOutlined"
      data-testid={DataTestIds.COMMANDBAR_BUTTON_NEW}
    />
    <CommandBarButton
      name="remove"
      onClick={onRemoveClick}
      text={getTranslationKey("commandBar", "remove")}
      icon="DeleteOutlined"
      disabled={selected.length === 0}
      data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE}
    />
    <CommandBarButton
      name="refresh"
      onClick={onReloadClick}
      text={getTranslationKey("commandBar", "refresh")}
      icon="ReloadOutlined"
      data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
    />
    <CommandBarButton
      name="edit"
      onClick={onEditClick}
      text={getTranslationKey("commandBar", "edit")}
      icon="EditOutlined"
      disabled={isEditDisabled || selected.length !== 1}
      data-testid={DataTestIds.COMMANDBAR_BUTTON_EDIT}
    />
  </CommandBar>
);

export default CodeTemplateGroupsCommandBar;
