import * as React from "react";
import { faImage } from "@fortawesome/fontawesome-free-solid";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";

interface ScreenshotDisplayProps {
  onClick: () => void;
}

const ScreenshotDisplay: React.FunctionComponent<ScreenshotDisplayProps> = ({ onClick }) => {
  if (!onClick) {
    return null;
  }

  return <FontAwesomeIcon icon={faImage} onClick={onClick} />;
};

export default ScreenshotDisplay;
