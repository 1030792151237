import { AutoComplete as AntdAutoComplete } from "antd";

import createFormField from "../createFormField";
import withReadonly from "./withReadonly";

const AutoCompleteReadOnly = withReadonly(AntdAutoComplete);

export const AutoCompleteField = createFormField(
  AutoCompleteReadOnly,
  ({ name, onChange, value, onBlur, onFocus, ...rest }) => ({
    ...rest,
    onChange,
    value,
  }),
);

export default AutoCompleteField;
