import * as PropTypes from "prop-types";
import * as React from "react";
import { RouterChildContext } from "react-router";
import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { User, Tag } from "@ea/shared_types/types";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import TagForm from "./components/TagForm";

interface ItsContainerProps {
  visibility: boolean;
  onClose: () => void;
  onCreate: (tag: Tag) => void;
  selected?: Tag;
  isEdit?: boolean;
  user: User | undefined;
}

class CreateEditTag extends React.Component<ItsContainerProps> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  context: RouterChildContext<any>;

  onOk = async (values) => {
    const { isEdit, onCreate, onClose } = this.props;
    if (isEdit) {
      await API.editTag(values);
    } else {
      if (this.props.user) {
        const result = await API.createTag(values);
        if (onCreate) {
          onCreate(result);
        }
      }
    }
    onClose();
  };

  render() {
    const { visibility, isEdit, onClose, selected } = this.props;
    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.SMALL}
        headerText={getTranslationKey("tags", "header", isEdit ? "edit" : "create")}
        onCancelClick={onClose}
        onOkClick={this.onOk}
        cancelButtonText={getTranslationKey("button", "cancel")}
        key={isEdit ? "edit" : "create"}
        okButtonText={getTranslationKey("button", isEdit ? "save" : "create")}
        initialValues={isEdit ? selected : {}}
        render={({ values, change }) => <TagForm values={values} change={change} />}
      />
    );
  }
}

export default CreateEditTag;
