import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { moduleActions } from "./workItem.actions";
import { WorkItem } from "@ea/shared_types/types";

const initialState = {
  ...getDataReducerInitialState<WorkItem>(),
};

export type WorkItemReducer = typeof initialState;

const dataReducer = createDataReducer<WorkItem>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
