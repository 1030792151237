import styled from "@emotion/styled";
import React from "react";
import LoginContainer from "@app/modules/auth/Login.container";
import { LOGIN_BACKGOUND_COLOR, LOGIN_PAGE_LOGO } from "./styles/styles";

interface ILoginProps {}

const Page = styled.div({
  height: "100vh",
  width: "100%",
  display: "flex",
});

const ImageSection = styled.div({
  flex: 0.7,
  backgroundColor: LOGIN_BACKGOUND_COLOR,
  display: "flex",
  flexDirection: "column",
  minWidth: 0,
  justifyContent: "space-between",
  paddingRight: "5%",
  paddingLeft: "5%",
  height: "100%",
});

const LoginSection = styled.div({
  flex: 0.3,
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const MainImage = styled.div({
  flex: 0.9,
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ImageContainer = styled.div({
  height: "20%",
});

const Login: React.FunctionComponent<ILoginProps> = (props) => {
  return (
    <Page>
      <ImageSection>
        <MainImage>
          <ImageContainer>
            <img src={LOGIN_PAGE_LOGO} alt="Logo" height="100%" width="auto" />
          </ImageContainer>
        </MainImage>
      </ImageSection>
      <LoginSection>
        <LoginContainer />
      </LoginSection>
    </Page>
  );
};

export default Login;
