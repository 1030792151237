import { WorkItemSeverity } from "@ea/shared_types/types";

export const severityOptions = Object.keys(WorkItemSeverity).map((key) => ({
  value: WorkItemSeverity[key],
  text: WorkItemSeverity[key],
}));

export const priorityOptions = Array.from(Array(4).keys()).map((num) => ({
  text: `${num + 1}`,
  value: num + 1,
}));
