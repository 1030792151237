import { InputNumber } from "antd";
import * as React from "react";

import { IFilterControlProps } from "./FilterContainer";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";

interface IRangeFilterProps extends IFilterControlProps {}

const Container = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "5px",
});

const inputStyle = { marginLeft: 5 };

class RangeFilter extends React.Component<IRangeFilterProps> {
  inputElement: any;
  filterDropdown: any;

  componentDidMount() {
    if (this.props.isOpen && this.inputElement) {
      this.inputElement.focus();
    }
  }

  componentDidUpdate(prevProps: IRangeFilterProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      if (this.inputElement) {
        this.inputElement.focus();
      }
    }
  }

  onStartChange = (value: number) => {
    this.props.setValue([value, this.getEndValue()]);
  };

  onEndChange = (value: number) => {
    this.props.setValue([this.getStartValue(), value]);
  };

  onContainerClick = (event) => {
    event.stopPropagation();
  };

  getStartValue = () => {
    const { value } = this.props;
    if (value && value.length > 0) {
      return value[0];
    }
  };

  getEndValue = () => {
    const { value } = this.props;
    if (value && value.length > 1) {
      return value[1];
    }
  };

  render() {
    return (
      <div
        className="custom-filter-dropdown"
        onClick={this.onContainerClick}
        ref={(element) => (this.filterDropdown = element)}
      >
        <Container>
          <FormattedMessage id={"common.min"} />
          <InputNumber
            style={inputStyle}
            onChange={this.onStartChange}
            value={this.getStartValue()}
            ref={(element) => (this.inputElement = element)}
          />
        </Container>
        <Container>
          <FormattedMessage id={"common.max"} />
          <InputNumber onChange={this.onEndChange} style={inputStyle} value={this.getEndValue()} />
        </Container>
      </div>
    );
  }
}

export default RangeFilter;
