import { objectValues } from "@ea/shared_components/utils/object";

import * as stepsActions from "./steps.actions";
import * as stepsEpicsMap from "./steps.epics";
import * as stepsReducers from "./steps.reducers";

const stepsEpics = objectValues(stepsEpicsMap);

export { stepsActions, stepsReducers, stepsEpics };

export { default as StepsTableContainer } from "./StepsTable.container";
export { default as StepDetailsContainer } from "./StepDetails.container";
