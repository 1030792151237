import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { NOTIFICATIONS_LOGO } from "@app/styles/styles";
import { WebsocketData, WebsocketMessageTypes } from "@ea/shared_types/types";

export const requestNotificatonPermission = async () => {
  // tslint:disable-next-line:no-string-literal
  if (Notification["permission"] !== "granted") {
    await Notification.requestPermission();
  }

  // tslint:disable-next-line:no-string-literal
  return Notification["permission"] === "granted" ? true : false;
};

const sendNotification = (text: string, icon?) => {
  // tslint:disable-next-line:no-string-literal
  if (Notification["permission"] === "granted") {
    const options = {
      icon,
    };
    const notification = new Notification(text, options);
  } else {
    toast(text);
  }
};

export const notificationListener = (value: WebsocketData[WebsocketMessageTypes.NOTIFICATION]) => {
  if (!value) {
    return;
  }
  try {
    const { status, scriptName, message } = value;

    if (status && scriptName) {
      sendNotification(`${scriptName} finished with status ${status}`, NOTIFICATIONS_LOGO);
    }
    if (message) {
      sendNotification(message, NOTIFICATIONS_LOGO);
    }
  } catch (e) {
    console.log(e);
  }
};

export const removeClientCookies = (
  value: WebsocketData[WebsocketMessageTypes.REMOVE_CLIENT_COOKIES],
) => {
  if (!value) {
    return;
  }
  try {
    const { sessionId } = value;
    if (sessionId) {
      const cookies = Cookies.get();
      Object.keys(cookies)
        .filter((cookieName) => cookieName.includes(sessionId))
        .forEach((cookieName) => Cookies.remove(cookieName));
      return;
    }
  } catch (e) {
    console.log(e);
  }
};
