import { ColumnProps } from "antd/lib/table";

import { FEATURES, Script, ScriptStatus } from "@ea/shared_types/types";

import { ColumnFilterConfig } from "../redux/common.filters";

export type CustomColumn<T, K = T> = {
  dataIndex: Extract<keyof K, string>;
  label: string;
  filter?: ColumnFilterConfig;
  render?: (text: any, record: T, index: number) => React.ReactNode;
  sortable?: boolean;
  onCell?: ColumnProps<T>["onCell"];
  ellipsis?: any;
  width?: string | number;
  feature?: FEATURES;
  editable?: (record: T) => boolean;
  handleSave?: (values) => void;
  translateLabel?: (record: T) => string;
  shouldHide?: () => boolean;
};

export type ColumnConfig<T> = {
  name?: string;
  props: CustomColumn<T>;
  frameworkProps?: ColumnProps<T>;
};

export const emptyColumnRender = (value, row, index) => {
  const obj = {
    children: value,
    props: {
      colSpan: 0,
    },
  };

  return obj;
};

export const overrideColumnProps = <T>(
  columns: ColumnConfig<T>[],
  overrideConfigs: {
    name?: string;
    dataIndex: keyof T;
    frameworkProps: Partial<ColumnProps<T>>;
    columnProps?: Partial<CustomColumn<T>>;
  }[],
) => {
  return columns.map((col) => {
    const overrideConfig = overrideConfigs.find(
      (config) =>
        (config.name && config.name === col.name) || config.dataIndex === col.props.dataIndex,
    );
    if (!overrideConfig) {
      return col;
    }

    return {
      ...col,
      props: {
        ...col.props,
        ...overrideConfig.columnProps,
      },
      frameworkProps: {
        ...col.frameworkProps,
        ...overrideConfig.frameworkProps,
      },
    };
  });
};

export const replaceColumn = (columns: ColumnConfig<any>[], columnDataIndex, replacement) => {
  const modifiedColumns: ColumnConfig<any>[] = [];
  columns.forEach((col) => {
    if (col.props.dataIndex === columnDataIndex) {
      modifiedColumns.push(Object.assign({}, col, { props: replacement }));
    } else {
      modifiedColumns.push(col);
    }
  });

  return modifiedColumns;
};

export const filterItemsToClose = <T extends Script>(
  items: {
    [key: string]: T;
  },
  selected: number[],
): T[] => {
  return selected
    .filter((id) => items[id].status !== ScriptStatus.CLOSED)
    .map((id) => ({ ...(items[id] as any), status: ScriptStatus.CLOSED }));
};
