import {
  createDataReducer,
  getDataReducerInitialState,
  getTableParams,
  setTableParams,
} from "@ea/shared_components/redux/reducers/common.data.reducer";
import { moveTo } from "@ea/shared_components/utils/array";
import { Log, Step } from "@ea/shared_types/types";

import {
  dataAsyncActionsCreators,
  stepsActionCreators,
  tableActionsCreators,
  tableAsyncActionsCreators,
} from "./steps.actions";

interface StepsInnerState {}

const initialInnerState: StepsInnerState = {};

const initialState = {
  ...getDataReducerInitialState<Step>(),
  ...initialInnerState,
};

export type StepsReducer = typeof initialState;

const dataActions = {
  ...tableActionsCreators,
  ...tableAsyncActionsCreators,
  ...dataAsyncActionsCreators,
};

const dataReducer = createDataReducer<Step>(dataActions);

const stepDataReducer = dataReducer(initialState);

export const reducer = stepDataReducer.case(
  stepsActionCreators.moveTo.started,
  (state, payload) => {
    const tableParams = getTableParams(state, payload);
    return setTableParams(state, payload, {
      ...tableParams,
      order: moveTo(tableParams.order, payload.from, payload.to),
    });
  },
);
