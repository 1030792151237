import { getTranslationKey } from "@app/translations/translations.helpers";
import { PanelType } from "@ea/shared_components/Panel";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import React from "react";
import SchedulerImportMappingsForm from "../../scheduler/components/SchedulerFlowPanel/SchedulerImportMappingsForm";

export const SchedulerFlowImportPanel = ({ visible, onCancel, onOk, schedulerJobId }) => {
  const onOkClick = async (values: { copySettings: boolean; jobMappings: number[] }) => {
    await onOk(values);
  };

  return (
    <PanelFormFinal
      visibility={visible}
      panelType={PanelType.XLARGE}
      headerText={<div>Import from scheduler</div>}
      onCancelClick={onCancel}
      onOkClick={onOkClick}
      allowPrisitineSubmit={true}
      cancelButtonText={getTranslationKey("button", "cancel")}
      okButtonText={getTranslationKey("button", "save")}
      render={(props) => <SchedulerImportMappingsForm {...props} schedulerJobId={schedulerJobId} />}
    />
  );
};
