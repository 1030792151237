import React from "react";
import { Modal, Spin } from "antd";
import { injectIntl, InjectedIntlProps } from "react-intl";

interface IExportingModalProps {
  visible?: boolean;
}

interface IExportingModalState {}

class ExportingModal extends React.Component<
  IExportingModalProps & InjectedIntlProps,
  IExportingModalState
> {
  render() {
    return (
      <Modal
        visible={this.props.visible}
        closable={false}
        footer={[]}
        bodyStyle={{ display: "flex", justifyContent: "center" }}
      >
        <Spin />
        <span style={{ marginLeft: "15px" }}>
          {this.props.intl.formatMessage({ id: "messages.info.exporting" })}
        </span>
      </Modal>
    );
  }
}
export default injectIntl(ExportingModal);
