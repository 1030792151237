import { createDataSelectors } from "@app/modules/app.reducers";
import { createSelector } from "reselect";
import {
  JOB_STATUS_TYPE,
  SchedulerJob,
  SchedulerMode,
  SchedulerGroup,
  BasicSchedulerRunParams,
} from "@ea/shared_types/types";
import { GanttData, GanttTask, GanttLink } from "./components/SchedulerGantt/gantt.types";
import { transformSchedulerToGanttData } from "./components/SchedulerGantt/gantt.helpers";

export const schedulerDataSelectors = createDataSelectors<SchedulerJob>()("scheduler");

export const areOnlyClosedSelectedSelector = createSelector(
  schedulerDataSelectors.getDataSelector,
  schedulerDataSelectors.getSelectedSelector,
  (items, selected) => {
    const selectedClosed = selected.filter((id) => items[id].status === JOB_STATUS_TYPE.CLOSED);
    return selectedClosed.length > 0 && selectedClosed.length === selected.length;
  },
);

export const getSelectedFilterClosedSelector = createSelector(
  schedulerDataSelectors.getDataSelector,
  schedulerDataSelectors.getSelectedSelector,
  schedulerDataSelectors.getClosedSelector,
  (items, selected, showClosedElements) =>
    !showClosedElements
      ? selected.filter((id) => items[id].status !== JOB_STATUS_TYPE.CLOSED)
      : selected,
);

export const getShowInternalSelector = createSelector(
  schedulerDataSelectors.getPersistentQuerySelector,
  (persistentQuery) => persistentQuery && persistentQuery.isInternal === undefined,
);

export const areOnlyInternalSelectedSelector = createSelector(
  schedulerDataSelectors.getDataSelector,
  schedulerDataSelectors.getSelectedSelector,
  (items, selected) => {
    const selectedInternal = selected.filter((id) => items[id].isInternal);
    return selectedInternal.length > 0 && selectedInternal.length === selected.length;
  },
);

export const getSchedulerGroups = createSelector(
  schedulerDataSelectors.getItemSelector,
  (scheduler): SchedulerGroup[] => {
    if (!scheduler || scheduler.mode !== SchedulerMode.GANTT) {
      return [];
    }

    return scheduler.groups;
  },
);

export const getGanttData = createSelector(
  schedulerDataSelectors.getItemSelector,
  (scheduler): GanttData => {
    const data: GanttTask[] = [];
    const links: GanttLink[] = [];
    if (!scheduler || scheduler.mode !== SchedulerMode.GANTT) {
      return { data, links };
    }

    return transformSchedulerToGanttData(scheduler.groups);
  },
);

export const getSchedulerStatus = createSelector(
  schedulerDataSelectors.getItemSelector,
  (scheduler): JOB_STATUS_TYPE => scheduler?.status,
);

export const getSchedulerRunParams = createSelector(
  schedulerDataSelectors.getItemSelector,
  (scheduler): BasicSchedulerRunParams | undefined => scheduler.runParams,
);
