import { ApplicationState } from "../app.reducers";
import { getProjectsChildrenMap, getProjectsParentMap } from "./projects.selectors";
import { PROJECTS_TABLES_CONFIG } from "./projects.tables";

export const getProjectsParents = (projectId: number, state: ApplicationState) => {
  const projectWithParentMap = getProjectsParentMap(state, PROJECTS_TABLES_CONFIG.TREE.id());

  const projectUnique = new Set();

  projectUnique.add(projectId);
  const parentIds = projectWithParentMap[projectId];
  if (parentIds) {
    parentIds.forEach((parentId) => projectUnique.add(parentId));
  }

  return Array.from(projectUnique);
};

export const getProjectsChildren = (projectId: number, state: ApplicationState) => {
  const projectWithChildrenMap = getProjectsChildrenMap(state, PROJECTS_TABLES_CONFIG.TREE.id());

  const projectUnique = new Set();

  projectUnique.add(projectId);
  const parentIds = projectWithChildrenMap[projectId];
  if (parentIds) {
    parentIds.forEach((parentId) => projectUnique.add(parentId));
  }

  return Array.from(projectUnique);
};
