import { TagOutlined } from "@ant-design/icons";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import StepMessageIcon from "@ea/shared_components/MessageBar/StepMessageIcon";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { COLORS } from "@ea/shared_components/styles/consts";
import {
  AllowedWorkItemTypes,
  IssueTrackingTool,
  Log,
  Message,
  StepScreenshots,
  WorkItemDefinition,
} from "@ea/shared_types/types";
import { Button, Collapse, List, Modal, Spin } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import ImageWithStatusText from "./ImageWithLoader";
import ScreenshotGallery from "./ScreenshotGallery";

const { Panel } = Collapse;

interface IErrorWarningListProps {
  visible: boolean;
  onClose: () => void;
  selectedLogs?: Log[];
  its?: IssueTrackingTool;
}

interface IErrorWarningListState {
  data: Record<
    string,
    {
      steps: {
        title: string;
        content: Node;
        screenshot?: string;
      }[];
      screenshots: StepScreenshots;
    }
  >;
  selectedLogId?: number;
  screenshotGalleryVisible: boolean;
  currentStepLogId?: number;
  currentScriptLogId?: number;
  isCreatingWorkItem: PlainObject<boolean>;
  isLoading: boolean;
}

type ErrorStepData = {
  title: string;
  content: Node;
  screenshot: string;
  stepLogId: number;
  messages: Message[];
  workItem: any;
  screenshotPath?: string;
};

const prepareStepsData = ({
  title,
  messages,
  screenshot,
  stepLogId,
  workItem,
  screenshotPath,
}): ErrorStepData => ({
  title,
  content:
    messages &&
    messages.map((message, index) => (
      <React.Fragment key={index}>
        <StepMessageIcon
          messageType={message.type}
          style={{ color: COLORS.STATUS[message.type], marginRight: "10px" }}
        />
        {`${message.type} - ${message.text}`}
        <br />
      </React.Fragment>
    )),
  screenshot,
  stepLogId,
  messages,
  workItem: workItem && { id: workItem.externalId, url: workItem.url },
  screenshotPath,
});

const createWorkItemDefinition = (
  selectedLog: Log,
  item: ErrorStepData,
  { priority, severity, iteration, id, type, area }: IssueTrackingTool,
): WorkItemDefinition => ({
  title: `Script ${selectedLog!.scriptId} ${selectedLog!.scriptName} `,
  priority: priority,
  severity: severity,
  reproSteps: `Script: [${selectedLog!.scriptId}] ${selectedLog!.scriptName}
    <br>Project: [${selectedLog!.projectId}] ${selectedLog!.projectName}
    <br>Url: ${selectedLog!.url}
    <br>State: ${selectedLog!.state}
    <br>Status: ${selectedLog!.status}
    <br>Start date: ${new Date(selectedLog!.startTime).toString()}
    <br>End date: ${selectedLog!.endTime ? new Date(selectedLog!.endTime).toString() : "N/A"}
    <br>Step: ${item.title}
    <br>
    <br>Messages:
    <br>${item.messages.map((message) => `${message.type} - ${message.text}`).join("<br>")}`,
  type: type as AllowedWorkItemTypes,
  stepLogId: item.stepLogId,
  stepLabel: item.title,
  iteration: iteration,
  area: area,
  itsId: id,
  screenshotPath: item.screenshotPath,
});

class ErrorWarningList extends React.Component<IErrorWarningListProps, IErrorWarningListState> {
  state = {
    data: {},
    screenshotGalleryVisible: false,
    currentStepLogId: undefined,
    currentScriptLogId: undefined,
    isCreatingWorkItem: {},
    isLoading: false,
  } as IErrorWarningListState;

  async componentDidUpdate(prevProps) {
    if (prevProps.visible && !this.props.visible) {
      this.setState({
        data: {},
      });
    }

    if (!prevProps.visible && this.props.visible) {
      await this.getStepLogData(this.props.selectedLogs || []);
    }
  }

  async getStepLogData(logs: Log[]) {
    this.setState({ isLoading: true });

    try {
      const result = (
        await Promise.all(
          logs.map(async (log) => {
            const stepsData = await API.getLogOverview({ id: log.id });
            return { logId: log.id, stepsData };
          }),
        )
      ).filter((r) => r.stepsData.length > 0);

      this.setState({
        data: result.reduce((container, { logId, stepsData }) => {
          container[logId] = {
            steps: stepsData.map(prepareStepsData),
            screenshots: stepsData
              .filter((stepLogErrorData) => stepLogErrorData.screenshotPath)
              .reduce((obj, stepLogErrorData) => {
                const { screenshotPath, stepLogId, title, lineNum } = stepLogErrorData;
                obj[stepLogId] = { path: screenshotPath, caption: title };
                return obj;
              }, {}),
          };

          return container;
        }, {}),
        isLoading: false,
      });
    } catch (error) {
      console.error(error);
      toast.error(<FormattedMessage id={getTranslationKey("errorList", "errorWhileLoading")} />);
      this.setState({ isLoading: false });
    }
  }

  generateModalTitle() {
    return (
      <>
        <FormattedMessage id={getTranslationKey("errorList", "title")} />
      </>
    );
  }

  onScreenshotClick(scriptLogId, stepLogId) {
    this.setState({
      screenshotGalleryVisible: true,
      currentStepLogId: stepLogId,
      currentScriptLogId: scriptLogId,
    });
  }

  onScreenshotGalleryClose() {
    this.setState({
      screenshotGalleryVisible: false,
      currentStepLogId: undefined,
      currentScriptLogId: undefined,
    });
  }

  async createWorkItem(item, log: Log) {
    const { its } = this.props;

    if (!its) {
      this.setState({ isCreatingWorkItem: { [item.stepLogId]: false } });
      toast.error(
        <FormattedMessage id={getTranslationKey("messages", "error", "workItemNoIts")} />,
      );
      return;
    }

    this.setState({ isCreatingWorkItem: { [item.stepLogId]: true } });
    try {
      await API.createWorkItem(createWorkItemDefinition(log, item, its));
    } catch (error) {
      console.error(error);
      this.setState({ isCreatingWorkItem: { [item.stepLogId]: false } });
      toast.error(<FormattedMessage id={getTranslationKey("messages", "error", "workItem")} />);
      return;
    }
    this.setState({ isCreatingWorkItem: { [item.stepLogId]: false } });
    await this.getStepLogData(this.props.selectedLogs || []);
    toast.success(<FormattedMessage id={getTranslationKey("messages", "success", "workItem")} />);
  }

  openWorkItem(url: string) {
    window.open(url, "_blank");
  }

  render() {
    const { visible, onClose, selectedLogs } = this.props;
    const {
      data,
      screenshotGalleryVisible,
      currentStepLogId,
      isCreatingWorkItem,
      isLoading,
      currentScriptLogId,
    } = this.state;

    if (!selectedLogs?.length) {
      return null;
    }

    const logsWithErrors = selectedLogs.filter((selectedLog) => data[selectedLog.id]);

    return (
      <Modal
        title={this.generateModalTitle()}
        visible={visible}
        closable={false}
        footer={[
          <Button key="close" type="primary" onClick={onClose}>
            <FormattedMessage id={getTranslationKey("button", "close")} />
          </Button>,
        ]}
        width={"70%"}
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        {!isLoading ? (
          logsWithErrors.length > 0 ? (
            <Collapse defaultActiveKey={logsWithErrors.map((s) => s.id)}>
              {logsWithErrors.map((selectedLog) => {
                const { steps } = data[selectedLog.id];
                return (
                  <Panel
                    header={`${selectedLog.id}:  ${selectedLog.scriptName}`}
                    key={selectedLog.id}
                  >
                    <List
                      itemLayout="vertical"
                      size="large"
                      dataSource={steps}
                      renderItem={(item: ErrorStepData) => (
                        <List.Item
                          key={item.title}
                          actions={[
                            <Button
                              type="primary"
                              key="createWorkItem"
                              icon={<TagOutlined />}
                              onClick={
                                item.workItem
                                  ? () => this.openWorkItem(item.workItem.url)
                                  : () => this.createWorkItem(item, selectedLog)
                              }
                              loading={isCreatingWorkItem[item.stepLogId]}
                            >
                              {item.workItem ? (
                                <>
                                  <FormattedMessage
                                    id={getTranslationKey("button", "openWorkItem")}
                                  />{" "}
                                  {item.workItem.id}
                                </>
                              ) : (
                                <FormattedMessage
                                  id={getTranslationKey("button", "createWorkItem")}
                                />
                              )}
                            </Button>,
                          ]}
                          extra={
                            item.screenshot && (
                              <ImageWithStatusText
                                src={item.screenshot}
                                width={272}
                                onClick={() =>
                                  this.onScreenshotClick(selectedLog.id, item.stepLogId)
                                }
                              />
                            )
                          }
                        >
                          <List.Item.Meta title={item.title} />
                          {item.content}
                        </List.Item>
                      )}
                    />
                  </Panel>
                );
              })}
            </Collapse>
          ) : (
            <FormattedMessage id={getTranslationKey("errorList", "noErrors")} />
          )
        ) : (
          <Spin style={{ marginLeft: "50%" }} />
        )}
        <ScreenshotGallery
          visible={screenshotGalleryVisible}
          onClose={() => this.onScreenshotGalleryClose()}
          screenshots={currentScriptLogId ? data[currentScriptLogId]?.screenshots || [] : []}
          currentStepLogId={currentStepLogId}
        />
      </Modal>
    );
  }
}

export default ErrorWarningList;
