import * as React from "react";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { GanttTask } from "./SchedulerGantt/gantt.types";
import { InputField } from "@ea/shared_components";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { SchedulerGroup } from "@ea/shared_types/types";
import { getIn } from "final-form";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import EmptyField from "@ea/shared_components/Form/Fields/EmptyField";
import { DataTestIds } from "@app/utils/dataTestIds";

export enum GroupManipulationPanelModes {
  CREATE = "CREATE",
  MOVE = "MOVE",
}

export type GroupManipulationPanelModeType = GroupManipulationPanelModes;
export type GroupManipulationPanelOnSave =
  | ((
      action: GroupActionTypes.NEW,
      params: { newGroup: Partial<SchedulerGroup>; selectedTasks: GanttTask[] },
    ) => void)
  | ((
      action: GroupActionTypes.EXISTING,
      params: { existingGroupId: string; selectedTasks: GanttTask[] },
    ) => void);

interface IGroupManipulationPanelProps {
  isOpen: boolean;
  onSave: GroupManipulationPanelOnSave;
  mode: GroupManipulationPanelModeType;
  tasks: GanttTask[];
  selectedTasks: GanttTask[];
  existingGroups: SchedulerGroup[];
  close: () => void;
  schedulerJobId: number;
}

export enum GroupActionTypes {
  NEW = "NEW",
  EXISTING = "EXISTING",
}

class GroupManipulationPanel extends React.Component<IGroupManipulationPanelProps, any> {
  onCancel = () => {
    this.props.close();
  };

  onSave = async (values) => {
    const { groupName, actionType, existingGroupId } = values;
    const { schedulerJobId, selectedTasks, existingGroups, onSave } = this.props;

    const params =
      actionType === GroupActionTypes.EXISTING
        ? { existingGroupId, selectedTasks }
        : {
            newGroup: {
              lineNum: 0,
              name: groupName,
              runParams: {
                run: undefined,
              },
              schedulerJobId: schedulerJobId,
              scripts: [],
            },
            selectedTasks,
            existingGroups,
          };

    // todo: why ts doesn't have correct type?
    (onSave as any)(actionType, params);
  };

  render() {
    const { isOpen, mode, tasks, existingGroups } = this.props;

    return (
      <PanelFormFinal
        visibility={isOpen}
        onCancelClick={this.onCancel}
        onOkClick={this.onSave}
        initialValues={{
          actionType:
            mode === GroupManipulationPanelModes.CREATE
              ? GroupActionTypes.NEW
              : GroupActionTypes.EXISTING,
        }}
        headerText={
          mode === GroupManipulationPanelModes.CREATE
            ? getTranslationKey("scheduler", "header", "createGroup")
            : getTranslationKey("scheduler", "header", "moveGroup")
        }
        render={({ values }) => (
          <>
            <EmptyField name="actionType" />
            {getIn(values, "actionType") === GroupActionTypes.NEW ? (
              <InputField
                name="groupName"
                label={getTranslationKey("scheduler", "label", "groupName")}
                data-testid={DataTestIds.FORM_INPUT_GROUP_NAME}
              />
            ) : (
              <SelectField
                name="existingGroupId"
                placeholder={getTranslationKey("scheduler", "placeholder", "existingGroup")}
                required
                label={getTranslationKey("scheduler", "label", "existingGroup")}
                data-testid={DataTestIds.FORM_SELECT_GROUP}
                options={existingGroups
                  .filter((eG) => eG.scripts.length > 1)
                  .map((eG) => ({
                    value: eG.id,
                    text: eG.name,
                  }))}
              />
            )}
          </>
        )}
      />
    );
  }
}

export default GroupManipulationPanel;
