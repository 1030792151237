import ClientUserError from "@ea/shared_types/src/ea.errors";

export const unpackErrorMessage = (error: any) => {
  if (error.error && error.error.message) {
    return error.error.message;
  }

  if (error.error) {
    return error.error;
  }

  return error.message;
};

export const isUserError = (arg: any): arg is ClientUserError => arg.type === "USER";
