import {
  createDataReducer,
  getDataReducerInitialState,
  setTableParams,
  getTableParamsWithoutInitialData,
  DataReducerState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";
import { v4 as uuidv4 } from "uuid";
import { moveTo } from "@ea/shared_components/utils/array";
import { EXECUTION_STATUS, RunnerState } from "@ea/shared_types/types";
import { moduleActions } from "./runner.actions";
import { findRule } from "@ea/runner_loader/runner.loader";
import { RecorderStep, ExecutionStep } from "@ea/shared_types";
import { getDefaultExecution } from "./runner.helpers";
import { iterateExecutionSteps } from "@ea/shared_types/utils/runner.data";
import { PlayerVariables } from "@ea/shared_types/runner.common.types";
import { AdvancedRecorderSyncResponse } from "@ea/shared_types/runner.api.types";

const initialState = {
  ...getDataReducerInitialState<ExecutionStep>(),
};

type RunnerReducerType = DataReducerState<ExecutionStep, {}, RunnerState>;

export type RunnerReducer = RunnerReducerType;

const dataReducer = createDataReducer<ExecutionStep>({
  ...moduleActions.table,
  ...moduleActions.data,
});

// todo: add better typings
function syncStepsDone(
  state,
  payload: { result: AdvancedRecorderSyncResponse; params: { tableId: string } },
) {
  const { steps, flatSteps, executionSteps, scriptData } = payload.result;

  const tableParams = getTableParamsWithoutInitialData<ExecutionStep, {}, RunnerState>(
    state,
    payload.params,
  );

  const newOrder = [...tableParams.order];
  const newSelected = [...tableParams.selected];
  const newState = {
    ...state,
    items: {
      ...state.items,
    },
  };
  const { recordingPath } = (tableParams as any).recorder;
  let newRecordingPath = recordingPath;

  iterateExecutionSteps(executionSteps, (s) => {
    tableParams.executionItems[s.execution.path] = {
      ...s.execution,
      ...tableParams.executionItems[s.execution.path],
      usedVariables: s.execution.usedVariables,
      forceStatus: s.execution.forceStatus,
    };
  });

  const removedStepsExecutionIds = Object.keys(tableParams.executionItems).filter(
    (rsId) => !flatSteps.map((fs) => fs.path).includes(rsId),
  );

  removedStepsExecutionIds.forEach((rId) => delete tableParams.executionItems[rId]);

  steps.forEach((s) => {
    const currentItem = newState.items[s.id];

    if (recordingPath === `${s.temporaryStepId}`) {
      // TODO what if it's not root script - add method
      newRecordingPath = `${s.id}`;
    }

    if (currentItem) {
      if (currentItem?.lastModified! <= new Date(s.lastModified!)) {
        newState.items[s.id] = s;
      }
    } else {
      if (s.temporaryStepId && newState.items[s.temporaryStepId]) {
        if (newState.items[s.temporaryStepId]?.lastModified! > new Date(s.lastModified!)) {
          newState.items[s.id] = { ...newState.items[s.temporaryStepId], id: s.id };
        } else {
          newState.items[s.id] = s;
        }
        delete newState.items[s.temporaryStepId];
        const tempStepOrderIndex = newOrder.findIndex((id) => id === s.temporaryStepId);
        if (tempStepOrderIndex !== -1) {
          newOrder[tempStepOrderIndex] = s.id;
        }
        const tempStepIndexInSelected = newSelected.findIndex(
          (selectedId) => selectedId === s.temporaryStepId,
        );
        if (tempStepIndexInSelected !== -1) {
          newSelected[tempStepIndexInSelected] = s.id;
        }
      }
    }
  });

  return setTableParams<ExecutionStep, {}, RunnerState>(newState, payload.params, {
    flatSteps,
    steps: executionSteps,
    order: newOrder,
    selected: newSelected,
    syncing: false,
    isDirty: false,
    syncError: undefined,
    recorder: {
      ...(tableParams as any).recorder,
      recordingPath: newRecordingPath,
    },
    player: {
      ...(tableParams as any).player,
      script: scriptData,
    },
  });
}

export const reducer = dataReducer<RunnerReducerType>(initialState as any)
  .case(moduleActions.table.setRunnerParams, (state, payload) => {
    const { tableId, ...rest } = payload;
    if (!tableId) {
      console.error("Missing Table ID");
      throw new Error("Missing Table ID");
    }

    const currentParams = getTableParamsWithoutInitialData<ExecutionStep, {}, RunnerState>(state, {
      tableId,
    }) || {
      order: [],
      selected: [],
    };

    return {
      ...state,
      params: {
        ...state.params,
        [tableId]: {
          ...currentParams,
          ...rest,
          player: {
            ...currentParams.player,
            ...rest.player,
          },
          recorder: {
            ...currentParams.recorder,
            ...rest.recorder,
          },
        },
      },
    };
  })
  .case(moduleActions.table.setRunnerRecorderParams, (state, payload) => {
    return setTableParams<ExecutionStep, {}, RunnerState>(state, payload, {
      recorder: { ...(state.params[payload.tableId] as any).recorder, ...payload },
    });
  })
  .case(moduleActions.table.addNewStep, (state, payload) => {
    const params = getTableParamsWithoutInitialData<ExecutionStep, {}, RunnerState>(state, payload);
    const { newStep, specificLineNum } = payload;
    const { recordingPath } = params.recorder;
    const markedStepId = Object.keys(state.items).find(
      (id) => state.items[id]?.execution?.path === recordingPath,
    );

    const markedStep = markedStepId ? state.items[markedStepId] : undefined;
    const order = params.order;

    const currentPosition =
      specificLineNum !== undefined
        ? specificLineNum - 2
        : order.findIndex((id) => id === markedStep!.id);
    const currentItems = order.map((id) => state.items[id]);
    const beforeSteps = currentItems.slice(0, currentPosition + 1);
    const afterSteps = currentItems.slice(currentPosition + 1);

    let newBeforeSteps = [...beforeSteps, newStep];

    (newStep as ExecutionStep).execution = {
      numberOfExecutions: 0,
      path: "",
      status: EXECUTION_STATUS.NONE,
      steps: [],
      usedVariables: [],
    };
    if (newStep?.rule?.id) {
      const rule = findRule(newStep.rule, newStep.platform);
      // normalize step if normalize function is defined for step's rule
      if (rule?.normalize) {
        const stepBeingNormalized =
          beforeSteps.length > 1 ? beforeSteps[beforeSteps.length - 1] : undefined;

        try {
          newBeforeSteps =
            rule?.normalize?.([...beforeSteps], newStep as RecorderStep) || newBeforeSteps;
        } catch (error) {
          console.warn(error);
        }

        const newNormalizedStep: RecorderStep = {
          ...newBeforeSteps[newBeforeSteps.length - 1],
          synced: false,
          lastModified: new Date(),
          // normalization modified last step
          // take id from already existing step
          ...(beforeSteps.length === newBeforeSteps.length ? { id: stepBeingNormalized?.id } : {}),
        };
        newBeforeSteps = [...newBeforeSteps.slice(0, -1), newNormalizedStep];
      } else {
        newStep.synced = false;
        newStep.lastModified = new Date();
      }
    } else {
      newStep.synced = false;
      newStep.lastModified = new Date();
    }

    let newStepPath = recordingPath;

    const normalizedSteps = [...newBeforeSteps, ...afterSteps].map((s, index) => {
      const randomId = uuidv4();
      const temporaryStepId = (s as any).temporaryStepId
        ? (s as any).temporaryStepId
        : !(s as any).id
        ? randomId
        : undefined;

      if (!(s as ExecutionStep).id && newStepPath === recordingPath) {
        newStepPath = temporaryStepId;
      }

      return {
        ...s,
        id: (s as ExecutionStep).id || randomId,
        temporaryStepId,
        lineNum: index + 1,
        taskScriptId: params.scriptId,
        execution: {
          ...(s as ExecutionStep).execution,
          path:
            (s as ExecutionStep).execution?.path === ""
              ? `${(s as ExecutionStep).id || randomId}`
              : (s as ExecutionStep).execution?.path,
        },
      } as ExecutionStep;
    });

    const newState = {
      ...state,
      items: {
        ...state.items,
      },
    };

    normalizedSteps.forEach((s) => {
      newState.items[s.id] = s;
    });

    return setTableParams<ExecutionStep, {}, RunnerState>(newState, payload, {
      order: normalizedSteps.map((s) => s.id),
      recorder: { ...params.recorder, recordingPath: newStepPath },
    });
  })
  .case(moduleActions.table.updateStep, (state, payload) => {
    const { step } = payload;

    const newState = {
      ...state,
      items: {
        ...state.items,
      },
    };
    if (step.id) {
      newState.items[step.id] = {
        ...step,
        lastModified: new Date(),
        synced: payload.disableSync ? true : false,
      };
    }

    return newState;
  })
  .case(moduleActions.table.moveTo, (state, payload) => {
    const { from, to } = payload;
    const tableParams = getTableParamsWithoutInitialData<ExecutionStep, {}, RunnerState>(
      state,
      payload,
    );
    const newState = {
      ...state,
      items: {
        ...state.items,
      },
    };

    // TODO refactor below, also used in other reducers - getting marked step index in order
    const markedStepId = Object.keys(state.items).find(
      (id) => state.items[id]?.execution?.path === tableParams.recorder.recordingPath,
    );
    const markedStep = markedStepId ? state.items[markedStepId] : undefined;
    const order = tableParams.order;

    const currentPosition = order.findIndex((id) => id === markedStep!.id);

    const newOrder = moveTo(tableParams.order, from, to);

    for (let index = 0; index < newOrder.length; index++) {
      const id = newOrder[index];

      if (newState.items[id].lineNum !== index + 1) {
        newState.items[id] = {
          ...newState.items[id],
          synced: false,
          lastModified: new Date(),
          lineNum: index + 1,
        };
      }
    }
    const newRecordingPath =
      state.items[newOrder[currentPosition]]?.execution?.path ||
      `${state.items[newOrder[currentPosition]].id}`;

    return setTableParams<ExecutionStep, {}, RunnerState>(newState, payload, {
      ...tableParams,
      order: newOrder,
      recorder: {
        ...(tableParams as any).recorder,
        recordingPath: newRecordingPath,
      },
    });
  })
  .case(moduleActions.table.syncSteps.started, (state, payload) => {
    return setTableParams<ExecutionStep, {}, RunnerState>(state, payload, {
      syncing: true,
    });
  })
  // .case(moduleActions.table.syncSteps.done, (state, payload) => {
  //   const { steps, flatSteps, executionSteps } = payload.result;
  //   const tableParams = getTableParams(state, payload.params);

  //   const newOrder = [...tableParams.order];
  //   const newState = {
  //     ...state,
  //     items: {
  //       ...state.items,
  //     },
  //   };
  //   const { recordingPath } = (tableParams as any).recorder;
  //   let newRecordingPath = recordingPath;
  //   steps.forEach((s) => {
  //     const currentItem = newState.items[s.id];

  //     if (recordingPath === `${s.temporaryStepId}`) {
  //       // TODO what if it's not root script - add method
  //       newRecordingPath = `${s.id}`;
  //     }

  //     if (currentItem) {
  //       if (currentItem?.lastModified! <= new Date(s.lastModified)) {
  //         newState.items[s.id] = s;
  //       }
  //     } else {
  //       if (newState.items[s.temporaryStepId]) {
  //         if (newState.items[s.temporaryStepId]?.lastModified! > new Date(s.lastModified)) {
  //           newState.items[s.id] = { ...newState.items[s.temporaryStepId], id: s.id };
  //         } else {
  //           newState.items[s.id] = s;
  //         }
  //         delete newState.items[s.temporaryStepId];
  //         const tempStepOrderIndex = newOrder.findIndex((id) => id === s.temporaryStepId);
  //         if (tempStepOrderIndex !== -1) {
  //           newOrder[tempStepOrderIndex] = s.id;
  //         }
  //       }
  //     }
  //   });

  //   return setTableParams(newState, payload.params, {
  //     flatSteps,
  //     steps: executionSteps,
  //     order: newOrder,
  //     syncing: false,
  //     syncError: undefined,
  //     recorder: {
  //       ...(tableParams as any).recorder,
  //       recordingPath: newRecordingPath,
  //     },
  //   } as any); // TODO!!!!!
  // })
  .case(moduleActions.table.syncSteps.failed, (state, payload) => {
    return setTableParams<ExecutionStep, {}, RunnerState>(
      state,
      { tableId: payload.params.tableId },
      {
        syncing: false,
        syncError: payload.error,
      },
    );
  })
  .case(moduleActions.table.reset, (state, payload) => {
    const { executionItems, player } = getTableParamsWithoutInitialData<
      ExecutionStep,
      {},
      RunnerState
    >(state, {
      tableId: payload.tableId,
    });

    const defaultExecutionParams = getDefaultExecution("not important");
    return setTableParams<ExecutionStep, {}, RunnerState>(state, { tableId: payload.tableId }, {
      executionItems: Object.keys(executionItems).reduce<typeof executionItems>(
        (container, next) => {
          container[next] = {
            ...executionItems[next],
            numberOfExecutions: defaultExecutionParams.numberOfExecutions,
            status: defaultExecutionParams.status,
          };

          return container;
        },
        {},
      ),
      player: {
        ...player,
        position: -1,
      },
    } as any); // TODO!!!!!
  })
  .case(moduleActions.table.toggleMode.done, (state, payload) => {
    const newState = syncStepsDone(state, payload);

    return setTableParams<ExecutionStep, {}, RunnerState>(
      newState,
      { tableId: payload.params.tableId },
      {
        mode: payload.params.mode,
        isRecording: false,
        isInspecting: false,
      },
    );
  })
  .case(moduleActions.table.syncSteps.done, (state, payload) => {
    return syncStepsDone(state, payload);
  })
  .case(moduleActions.table.deleteWithUnselect, (state, payload) => {
    const newItems = { ...state.items };
    const newOrder = state.params[payload.tableId].order.filter((i) => !payload.ids.includes(i));
    const tableParams = getTableParamsWithoutInitialData<ExecutionStep, {}, RunnerState>(
      state,
      payload,
    );
    const { recordingPath } = tableParams.recorder;
    const markedStepId = Object.keys(state.items).find(
      (id) => state.items[id]?.execution?.path === recordingPath,
    );

    const markedStep = markedStepId ? state.items[markedStepId] : undefined;

    const isMarkedBeingDeleted = markedStep?.id && payload.ids.includes(markedStep.id);
    let newRecordingPath = recordingPath;
    if (markedStep && isMarkedBeingDeleted) {
      const markedStepOrderNo = state.params[payload.tableId].order.findIndex(
        (id) => id === markedStep.id,
      );

      const closestNotBeingRemoved = state.params[payload.tableId].order
        .slice(0, markedStepOrderNo)
        .filter((id) => !payload.ids.includes(id));

      newRecordingPath =
        state.items[closestNotBeingRemoved[closestNotBeingRemoved.length - 1]].execution.path;
    }

    payload.ids.forEach((id) => {
      delete newItems[id];
    });
    const normalizedItems = newOrder.reduce((normalizedOutput, id, index) => {
      normalizedOutput[id] = {
        ...newItems[id],
        lineNum: index + 1,
      };
      return normalizedOutput;
    }, {});

    const newState = {
      ...state,
      items: normalizedItems,
    };

    return setTableParams<ExecutionStep, {}, RunnerState>(newState, payload, {
      order: newOrder,
      selected: [],
      recorder: {
        ...(tableParams as any).recorder,
        recordingPath: newRecordingPath,
      },
    } as any); // TODO!!!
  })
  .case(moduleActions.table.updateLiveVariables, (state, payload) => {
    const tableParams = getTableParamsWithoutInitialData<ExecutionStep, {}, RunnerState>(
      state,
      payload,
    );

    const { init, variables } = payload;

    const existingVariables = (tableParams.variables as PlayerVariables) || {};

    if (init && Object.keys(existingVariables).length !== 0) {
      return state;
    }

    return setTableParams<ExecutionStep, {}, RunnerState>(state, payload, {
      variables: {
        script: {
          ...(existingVariables.script || {}),
          ...(variables.script || {}),
        },
        dataSource: {
          ...(existingVariables.dataSource || {}),
          ...(variables.dataSource || {}),
        },
        global: {
          mutable: {
            ...(existingVariables.global?.mutable || {}),
            ...(variables.global?.mutable || {}),
          },
          constant: {
            ...(existingVariables.global?.constant || {}),
            ...(variables.global?.constant || {}),
          },
          sequence: {
            ...(existingVariables.global?.sequence || {}),
            ...(variables.global?.sequence || {}),
          },
        },
      },
    } as any); // TODO!!!;
  })
  .case(moduleActions.table.removeLiveVariables, (state, payload) => {
    const tableParams = getTableParamsWithoutInitialData<ExecutionStep, {}, RunnerState>(
      state,
      payload,
    );

    const { ids } = payload;

    const existingVariables = (tableParams.variables as PlayerVariables) || {};

    for (const id of Object.keys(existingVariables.script)) {
      if (ids.includes(id)) {
        delete existingVariables[id];
      }
    }

    return setTableParams<ExecutionStep, {}, RunnerState>(state, payload, {
      variables: existingVariables,
    });
  });
