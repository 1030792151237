import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { moduleActions } from "./users.actions";
import { usersDataSelectors } from "@app/modules/users/users.selectors";
import { API } from "@app/services/api/api";

import { createModuleEpics } from "@app/modules/app.reducers";
import { User } from "@ea/shared_types/types";
import { USERS_COLUMNS_CONFIG } from "./users.table";

const userModuleEpics = createModuleEpics<User>(moduleActions, {
  getRequestParams: (state, tableId) => {
    const tableParams = usersDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, USERS_COLUMNS_CONFIG);

    params.filter = {
      ...params.filter,
      include: [
        {
          relation: "UserGroup",
        },
        {
          relation: "Role",
        },
      ],
    };

    return params;
  },
  getSingleRequestParams: (query) => {
    query.filter = {
      ...query.filter,
      include: [
        {
          relation: "UserGroup",
        },
        {
          relation: "Role",
        },
      ],
    };
    return query;
  },
  api: {
    createItem: API.createUser,
    deleteItem: API.deleteUser,
    editItem: API.editUser as any,
    getItems: API.getUsers as any,
    getItemsCount: API.getUsersCount,
  },
});

export default userModuleEpics;
