import { createDataSelectors } from "@app/modules/app.reducers";
import { Project } from "@ea/shared_types/types";
import { createSelector } from "reselect";
import {
  buildProjectTree,
  buildProjectMap,
  buildProjectMappingMap,
  buildProjectParentMap,
} from "./projects.helpers";
import {
  isCurrentUserAdmin,
  getAllowedProjectMap,
} from "@ea/shared_components/auth/auth.selectors";

export const projectSelectors = createDataSelectors<Project>()("projects");

export const getProjectsTreeSelector = createSelector(
  projectSelectors.getOrderedDataSelector,
  getAllowedProjectMap,
  isCurrentUserAdmin,
  (projects, allowedProjects, isAdmin) => {
    return buildProjectTree(projects, isAdmin ? undefined : allowedProjects);
  },
);

export const getProjectsParentMap = createSelector(getProjectsTreeSelector, (projectsTree) => {
  return buildProjectParentMap(projectsTree);
});

export const getProjectsChildrenMap = createSelector(getProjectsTreeSelector, (projectsTree) => {
  return buildProjectMap(projectsTree);
});

export const getProjectsUserGroupsMappingMap = createSelector(
  projectSelectors.getDataSelector,
  getProjectsTreeSelector,
  (projects, projectsTree) => {
    return buildProjectMappingMap(projectsTree, projects, "overrideGroups");
  },
);

export const getProjectsVirtualUsersMappingMap = createSelector(
  projectSelectors.getDataSelector,
  getProjectsTreeSelector,
  (projects, projectsTree) => {
    return buildProjectMappingMap(projectsTree, projects, "overrideVirtualUsers");
  },
);

export const getProjectsSystemsMappingMap = createSelector(
  projectSelectors.getDataSelector,
  getProjectsTreeSelector,
  (projects, projectsTree) => {
    return buildProjectMappingMap(projectsTree, projects, "overrideSystems");
  },
);

export const getProjectPath = createSelector(
  projectSelectors.getItemSelector,
  projectSelectors.getDataSelector,
  (project, projects) =>
    project?.path
      .split(".")
      .slice(1)
      .map((projectId) => projects[parseInt(projectId, 10)]?.name)
      .join(" / "),
);
