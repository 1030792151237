import { objectValues } from "@ea/shared_components/utils/object";

import steplogsEpicsMap from "./stepLogs.epics";
import * as stepLogsReducers from "./stepLogs.reducers";

const steplogsEpics = objectValues(steplogsEpicsMap);

export { stepLogsReducers, steplogsEpics };

export { default as StepLogs } from "./StepLogs.container";
