import { objectValues } from "@ea/shared_components/utils/object";

import * as systemDictionaryActions from "./systemDictionary.actions";
import systemDictionaryEpicsMap from "./systemDictionary.epics";
import * as systemDictionaryReducers from "./systemDictionary.reducers";

const systemDictionaryEpics = objectValues(systemDictionaryEpicsMap);

export { systemDictionaryActions, systemDictionaryReducers, systemDictionaryEpics };

export { default as SystemDictionaryTableContainer } from "./SystemDictionaryTable.container";
