import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import UserBasicSettingsForm from "@app/modules/users/components/UserBasicSettingsForm";
import { API } from "@app/services/api/api";
import EditeableAreaFinal from "@ea/shared_components/EditeableArea/EditeableAreaFinal";

import { authActions } from "../auth/auth.actions";
import { itsDataSelectors } from "../issueTrackingTool/its.selectors";
import { ITS_TABLES_CONFIG } from "../issueTrackingTool/its.table";
import { bindActionCreators, Dispatch } from "redux";
import { getIstTableActions } from "../issueTrackingTool/its.actions";
import { isFeatureDisabledSelector } from "../globalSettings/globalSettings.selectors";
import { FEATURES } from "@ea/shared_types/types";

interface IUserBasicSettingsContainerProps {}
interface IUserBasicSettingsContainerState {}

class UserBasicSettingsContainer extends React.Component<
  IUserBasicSettingsContainerProps & IConnectProps,
  IUserBasicSettingsContainerState
> {
  componentDidMount() {
    this.props.actions.its.load({});
  }

  editSettings = async (values) => {
    const editedUser = await API.editUser({
      ...values,
      settings: {
        ...this.props.user!.settings,
        its: values.settings.its,
        reportExtension: values.settings.reportExtension,
        documentationLocale: values.settings.documentationLocale,
        displayOnlyPublishedDocuments: values.settings.displayOnlyPublishedDocuments,
      },
    });

    this.props.actions.auth.initDone({
      params: {
        ...this.props.user,
        ...values,
        settings: {
          ...this.props.user!.settings,
          its: values.settings.its,
          reportExtension: values.settings.reportExtension,
          documentationLocale: values.settings.documentationLocale,
          displayOnlyPublishedDocuments: values.settings.displayOnlyPublishedDocuments,
        },
      },
      result: { roles: this.props.user!.roles, ...editedUser },
    });
  };

  render() {
    const { user, its } = this.props;

    return user ? (
      <EditeableAreaFinal
        onSave={this.editSettings}
        initialValues={user}
        onCancel={() => {}}
        render={({ change, values, readOnly }) => (
          <UserBasicSettingsForm
            values={values}
            change={change}
            readOnly={readOnly}
            isEdit
            its={its}
          />
        )}
      />
    ) : null;
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: IUserBasicSettingsContainerProps) => {
  return {
    actions: {
      auth: {
        ...bindActionCreators(authActions, dispatch),
      },
      its: {
        ...bindActionCreators(getIstTableActions(ITS_TABLES_CONFIG.MAIN.id()), dispatch),
      },
    },
  };
};

const connectCreator = connect(
  (state: ApplicationState) => ({
    user: state.auth.user,
    its: itsDataSelectors.getOrderedDataSelector(state, ITS_TABLES_CONFIG.MAIN.id()),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(UserBasicSettingsContainer);
