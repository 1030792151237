import { objectValues } from "@ea/shared_components/utils/object";

import { moduleActions as usersActions, getUsersTableActions } from "./users.actions";
import usersEpicsMap from "./users.epics";
import * as usersReducers from "./users.reducers";

const usersEpics = objectValues(usersEpicsMap);

export { usersActions, usersReducers, usersEpics, getUsersTableActions };

export { default as UsersTableContainer } from "./UsersTable.container";
