import { getTranslationKey } from "@app/translations/translations.helpers";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import { BasicSchedulerRunParams, SchedulerJobMapping } from "@ea/shared_types/types";
import styled from "@emotion/styled";
import { Checkbox, Input, Radio, Select, Tag, Tooltip } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import SystemTypeField from "../systemDictionary/components/SystemTypeField";

const Option = Select.Option;

const InlineSelect = styled(Select)({
  width: "100%",
});

const Container = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export function getSchedulerFlowColumnsRenderers() {
  return {
    runParamsDropdownRenderer: (
      rowData: SchedulerJobMapping,
      dataKey: string,
      options: OptionType[],
      onChange: (mapping: SchedulerJobMapping) => void,
      readOnly?: boolean,
      schedulerJobRunParams?: BasicSchedulerRunParams,
    ) => {
      if (!rowData) {
        return;
      }
      return (
        <InlineSelect
          disabled={readOnly || !rowData.overrideRunParams}
          value={
            !!rowData.overrideRunParams
              ? rowData.runParams?.[dataKey]
              : schedulerJobRunParams?.[dataKey]
          }
          onChange={(value) => {
            const runParams = { ...rowData.runParams, [dataKey]: value };
            onChange({
              ...rowData,
              runParams,
            });
          }}
          allowClear
        >
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.text}
            </Option>
          ))}
        </InlineSelect>
      );
    },
    repeatCountRenderer: (rowData: SchedulerJobMapping, onChange, readOnly?: boolean) => {
      if (!rowData) {
        return;
      }

      return readOnly ? (
        rowData.repeatCount
      ) : (
        <Input
          type="number"
          value={rowData.repeatCount}
          onChange={(event) =>
            onChange({
              ...rowData,
              repeatCount: event.target.value ? Number.parseInt(event.target.value, 10) : 1,
            })
          }
          onClick={(e) => e.stopPropagation()}
          readOnly={readOnly}
          min={1}
        />
      );
    },
    envTypeRenderer: (rowData: SchedulerJobMapping) => {
      if (!rowData) {
        return;
      }
      if (rowData.envType) {
        return <SystemTypeField type={rowData.envType} />;
      }
      if (!rowData.projectId || rowData.url) {
        return null;
      }

      const { defaultSystem } = rowData.projectDefinition || {};
      if (defaultSystem) {
        return <SystemTypeField type={defaultSystem.envType} />;
      }
    },
    projectNameRenderer: (rowData: SchedulerJobMapping) => {
      if (!rowData) {
        return;
      }
      if (!rowData.projectId) {
        // system start url
        return <FormattedMessage id={getTranslationKey("table", "projectUnknown")} />;
      }

      return rowData.projectDefinition?.name;
    },
    envNameRenderer: (rowData: SchedulerJobMapping) => {
      if (!rowData) {
        return;
      }

      if (rowData.envName) {
        return <Tooltip title={rowData.url}>{rowData.envName}</Tooltip>;
      }

      if (rowData.url) {
        return (
          <Tooltip title={<FormattedMessage id={getTranslationKey("table", "manualUrl")} />}>
            {rowData.url}
          </Tooltip>
        );
      }

      if (!rowData.projectId) {
        return null;
      }

      const { defaultSystem } = rowData.projectDefinition || {};
      if (defaultSystem) {
        return (
          <>
            <Tooltip
              title={<FormattedMessage id={getTranslationKey("table", "projectDefaultEnv")} />}
            >
              <Tag>PD</Tag>
            </Tooltip>
            <Tooltip title={defaultSystem.envAddress}>{defaultSystem.envName}</Tooltip>
          </>
        );
      }
    },
    checkboxRenderer: (
      rowData: SchedulerJobMapping,
      schedulerJobRunParams,
      onChange,
      readOnly?: boolean,
    ) => {
      return (
        <Checkbox
          disabled={readOnly}
          checked={!!rowData.overrideRunParams}
          onChange={(event) => {
            const newCheckedValue = event.target.checked;
            const runParams = !!newCheckedValue ? rowData.runParams || schedulerJobRunParams : null;
            onChange({ ...rowData, overrideRunParams: newCheckedValue, runParams });
          }}
        />
      );
    },
    combineElements: (elements) => <Container>{elements}</Container>,
    checkboxVirtualUserMode: (
      type: "SINGLE" | "POOL",
      rowData: SchedulerJobMapping,
      onChange,
      readOnly?: boolean,
    ) => {
      const checked =
        type === "SINGLE"
          ? !rowData.runParams?.virtualUserMode || rowData.runParams?.virtualUserMode === type
          : rowData.runParams?.virtualUserMode === type;
      return (
        <Radio
          style={{ marginRight: "5px" }}
          disabled={readOnly || !rowData.overrideRunParams}
          checked={!!checked}
          onChange={(event) => {
            const newCheckedValue = event.target.checked;
            const disabledValue = type === "SINGLE" ? "POOL" : "SINGLE";
            const runParams = !!newCheckedValue ? rowData.runParams : {};
            onChange({
              ...rowData,
              runParams: {
                ...runParams,
                virtualUserMode: !!newCheckedValue ? type : disabledValue,
              },
            });
          }}
        />
      );
    },
  };
}
