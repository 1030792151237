import * as React from "react";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { ApplicationStyles } from "@ea/shared_types/types";
import { style } from "../../styles/styles";
const Pdf = styled.object({
  height: "100%",
  width: "100%",
});

const ReleaseNotes: React.FC = () => {
  const getUrl = () => {
    switch (style) {
      case ApplicationStyles.DEFAULT:
        return "https://site.automats.cloud/deploy/releaseNotes.pdf";
      case ApplicationStyles.EPISTA:
        return "https://site.automats.cloud/deploy/ab-releaseNotes.pdf";
      case ApplicationStyles.DOCUMENTATION:
        return "https://site.automats.cloud/deploy/dw-releaseNotes.pdf";

      default:
        return "https://site.automats.cloud/deploy/releaseNotes.pdf";
    }
  };

  return (
    <>
      <h1>
        <FormattedMessage id={getTranslationKey("license", "releaseNotes")} />
      </h1>
      <Pdf data={getUrl()} type="application/pdf">
        <div>
          <FormattedMessage id={getTranslationKey("license", "errorReleaseNotes")} />
        </div>
      </Pdf>
    </>
  );
};

export default ReleaseNotes;
