import React from "react";

import FormLayout from "./FormLayout";

const panelLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};

const editLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};

export const PanelFormLayout = ({ children }) => (
  <FormLayout {...panelLayout}>{children}</FormLayout>
);

export const EditFormLayout = ({ readOnly, children }) => (
  <FormLayout {...editLayout} readOnly={readOnly}>
    {children}
  </FormLayout>
);
