import { PlainObject, PlatformMetadata, RuleMetadata } from "@ea/shared_types/types";
import {
  CommandDefinitionBaseType,
  CommandDefinitionRunner,
  DEFAULT_PLATFORM_ID,
  initDefaults,
  Platform,
  PlatformAPIType,
  PlatformServer,
  PlatformTypes,
  PlatformWeb,
  Rule,
  RulesPack,
} from "./ea.internal.types";

export const getPlatformAPI = (): PlatformAPIType => {
  if (EA_INTERNAL.currentPlatform) {
    return EA_INTERNAL.currentPlatform.api;
  }

  const defaultPlatform = getDefaultPlatform();
  if (defaultPlatform?.api) {
    return defaultPlatform.api;
  }

  return {
    validateVersionRange: () => false,
    waitForProcessing: () => {},
    isReady: async () => {},
  };
};

if (typeof window !== "undefined") {
  window.addEventListener(
    "load",
    () => {
      detectRunnerPlatform();
    },
    false,
  );
}

export function getWebPlatforms(): PlatformWeb[] {
  return (EA_INTERNAL.platforms as PlatformWeb[]) || [];
}

export function getServerPlatforms(): PlatformServer[] {
  return (EA_INTERNAL.platforms as PlatformServer[]) || [];
}

export function getRunnerPlatforms(): Platform[] {
  return (EA_INTERNAL.platforms as Platform[]) || [];
}

export function registerRunnerPlatform<T extends PlatformTypes = any>(platform: Platform<T>) {
  return registerPlatform(platform);
}

export function registerPlatform<T extends {} = {}>(
  platform: Platform | PlatformWeb<T> | PlatformServer,
) {
  const doesExist = EA_INTERNAL.platforms.find((p) => p.id === platform.id);

  if (doesExist) {
    console.warn(`Platform'${platform.id}' already exists`);
    return;
  }

  EA_INTERNAL.platforms.push(platform);
}

export function getDefaultPlatform() {
  return EA_INTERNAL.platforms.find((p) => p.id === DEFAULT_PLATFORM_ID) as Platform;
}

export function detectRunnerPlatform(): Platform {
  const currentPlatform = (EA_INTERNAL.platforms as Platform[])
    .filter((p) => p.id !== DEFAULT_PLATFORM_ID && p.condition)
    .find((p) => p.condition());

  console.log(
    `Current platform is: ${(currentPlatform && currentPlatform.id) || DEFAULT_PLATFORM_ID}`,
  );

  if (!currentPlatform) {
    return (EA_INTERNAL.platforms as Platform[]).find((p) => p.id === DEFAULT_PLATFORM_ID)!;
  }

  EA_INTERNAL.currentPlatform = currentPlatform;

  return currentPlatform;
}

export function registerRules(platformId: string, version: string, rules: PlainObject<Rule>) {
  // don't load if platform is different, how to do it?
  // if (platformId !== DEFAULT_PLATFORM_ID && (!EA_INTERNAL.currentPlatform || EA_INTERNAL.currentPlatform.id !==  platformId)) {
  //   return;
  // }

  console.log(`Loading rules for platform '${platformId}' in version '${version}'`);

  const rulesPack: RulesPack = {
    id: platformId, // platform id and rules_module_id is the same, is that a good idea? can we have two different packs that supports the same platform?
    version,
    rules: Object.keys(rules).map((k) => ({
      ...rules[k],
      id: k,
    })),
  };

  EA_INTERNAL.rules.packs[rulesPack.id] = rulesPack;
}

const sortRulesByPriority = (rules: Rule[]) => {
  return rules.sort((a, b) => {
    const aPriority = a.priority || 0;
    const bPriority = b.priority || 0;
    if (aPriority < bPriority) {
      return 1;
    }
    if (aPriority > bPriority) {
      return -1;
    }
    return 0;
  });
};

export function getDefaultCommand<T extends CommandDefinitionBaseType>(
  commandId: T["id"],
): CommandDefinitionRunner<T> {
  const { [DEFAULT_PLATFORM_ID]: defaultCommands } = EA_INTERNAL.commands.packs;

  const command = defaultCommands[commandId];

  if (!command) {
    throw new Error(`Command with id: ${commandId} not found in default commands`);
  }

  return command as any;
}

export function getDefaultRule<T extends CommandDefinitionBaseType>(ruleId: string): Rule<T> {
  const { [DEFAULT_PLATFORM_ID]: defaultRules } = EA_INTERNAL.rules.packs;

  const rule = defaultRules.rules.find((r) => r.id === ruleId);

  if (!rule) {
    throw new Error(`Rule with id: ${ruleId} not found in default rules`);
  }

  return rule;
}

export function findRule(ruleMetadata: RuleMetadata, platform?: PlatformMetadata) {
  const platformId = platform?.id || DEFAULT_PLATFORM_ID;

  const getRule = (rules) => rules.find((r) => r.id === ruleMetadata.id);

  return (
    getRule(EA_INTERNAL.rules.packs[platformId].rules) ||
    getRule(EA_INTERNAL.rules.packs[DEFAULT_PLATFORM_ID].rules)
  );
}

export function getRules() {
  const { [DEFAULT_PLATFORM_ID]: standardRules, ...customRules } = EA_INTERNAL.rules.packs;
  const rules: ReturnType<typeof parseRulesPack> = [];

  const parseRulesPack = (rulesPack: RulesPack) =>
    rulesPack.rules.map((r) => ({
      ...r,
      version: rulesPack.version,
      platform: rulesPack.id,
    }));

  Object.keys(customRules)
    .filter((k) => k === (EA_INTERNAL.currentPlatform && EA_INTERNAL.currentPlatform.id))
    .forEach((key) => rules.push(...parseRulesPack(customRules[key])));

  sortRulesByPriority(rules);

  const defaultRules = parseRulesPack(standardRules);
  sortRulesByPriority(defaultRules);
  rules.push(...defaultRules);

  return {
    matchRule: (event) => {
      return rules.find((rule) => {
        try {
          return rule.condition(event);
        } catch (error) {
          console.error(`Error while executing condition for rule ${rule.id}`);
          console.error(error);
          return false;
        }
      });
    },
    getRuleById: (id: string) => rules.find((rule) => rule.id === id),
  };
}

export type RulesAPI = ReturnType<typeof getRules>;

initDefaults();
