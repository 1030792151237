import { createSelector } from "reselect";

import { createDataSelectors } from "@app/modules/app.reducers";
import { CoreCommandsIds } from "@ea/shared_types/core.commands.types";
import { Step } from "@ea/shared_types/types";

export const stepsDataSelectors = createDataSelectors<Step>()("steps");

export const getStepsSelector = createSelector(stepsDataSelectors.getOrderedDataSelector, (steps) =>
  steps.map((step, index) =>
    index === 0 || index === steps.length - 1 ? { ...step, isDragDisabled: true } : step,
  ),
);
export const getSelectedStepSelector = stepsDataSelectors.getSelectedItemSelector;
export const getSelectedStepsMapSelector = createSelector(
  stepsDataSelectors.getSelectedSelector,
  (selected) =>
    selected.reduce((map: any, obj: any) => {
      map[obj] = obj;
      return map;
    }, {}),
);
export const getSelectedStepsSelector = createSelector(
  stepsDataSelectors.getSelectedItemsSelector,
  stepsDataSelectors.getOrderSelector,
  (selected, stepsOrder) => {
    // fix for: copying step from linked script and going back to main script by back arrow on chrome. redux still has selected from linked script
    return selected.filter((s) => stepsOrder.includes(s.id));
  },
);

export const getSelectedSelector = createSelector(
  stepsDataSelectors.getSelectedSelector,
  stepsDataSelectors.getOrderSelector,
  (selected, stepsOrder) => {
    // fix for: copying step from linked script and going back to main script by back arrow on chrome. redux still has selected from linked script
    return selected.filter((s) => stepsOrder.includes(s));
  },
);

export const getStepsOrderSelector = stepsDataSelectors.getOrderSelector;
export const getScriptStepsSelector = createSelector(
  stepsDataSelectors.getOrderedDataSelector,
  (steps) => steps.filter((s) => s.commandId === CoreCommandsIds.script),
);
export const getNotSelectableStepsSelector = createSelector(
  stepsDataSelectors.getOrderedDataSelector,
  (steps) =>
    steps
      .filter(
        (step) =>
          step.commandId === CoreCommandsIds.start || step.commandId === CoreCommandsIds.end,
      )
      .map((step) => step.id),
);
