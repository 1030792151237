import { TranslationDefinition } from "./en.translations";

export function getTranslationKey<P1 extends keyof NonNullable<TranslationDefinition>>(
  prop1: P1,
): NonNullable<TranslationDefinition>[P1] | undefined;

export function getTranslationKey<
  P1 extends keyof NonNullable<TranslationDefinition>,
  P2 extends keyof NonNullable<NonNullable<TranslationDefinition>[P1]>,
>(prop1: P1, prop2: P2): NonNullable<NonNullable<TranslationDefinition>[P1]>[P2];

export function getTranslationKey<
  P1 extends keyof NonNullable<TranslationDefinition>,
  P2 extends keyof NonNullable<NonNullable<TranslationDefinition>[P1]>,
  P3 extends keyof NonNullable<NonNullable<NonNullable<TranslationDefinition>[P1]>[P2]>,
>(
  prop1: P1,
  prop2: P2,
  prop3: P3,
): NonNullable<NonNullable<NonNullable<TranslationDefinition>[P1]>[P2]>[P3];

export function getTranslationKey<
  P1 extends keyof NonNullable<TranslationDefinition>,
  P2 extends keyof NonNullable<NonNullable<TranslationDefinition>[P1]>,
  P3 extends keyof NonNullable<NonNullable<NonNullable<TranslationDefinition>[P1]>[P2]>,
  P4 extends keyof NonNullable<
    NonNullable<NonNullable<NonNullable<TranslationDefinition>[P1]>[P2]>[P3]
  >,
>(
  prop1: P1,
  prop2: P2,
  prop3: P3,
  prop4: P4,
): NonNullable<NonNullable<NonNullable<NonNullable<TranslationDefinition>[P1]>[P2]>[P3]>[P4];

// ...and so on...

export function getTranslationKey(...props: string[]): string {
  return props.join(".");
}
