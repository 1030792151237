import { ActionCreator, ActionCreatorFactory } from "typescript-fsa";

import {
  ChangePageActionTypeParams,
  ChangePageSizeActionTypeParams,
  ChangeSortingTypeParams,
  ClearFiltersActionTypeParams,
  ClearSortingActionTypeParams,
  CreateActionTypeParams,
  CreateActionTypeResultParams,
  DeleteActionTypeParams,
  DeleteActionTypeResultParams,
  EditActionTypeParams,
  EditActionTypeResultParams,
  LoadActionTypeParams,
  LoadActionTypeResponse,
  LoadPaginationConfigActionTypeParams,
  LoadPaginationConfigActionTypeResponse,
  LoadSingleActionTypeParams,
  LoadSingleActionTypeResponse,
  SelectActionTypeParams,
  SetFilterActionTypeParams,
  SetPersistentQueryActionTypeParams,
  InvalidateTypeParams,
  CommitCreateActionTypeParams,
  CommitEditActionTypeParams,
  SetFiltersActionTypeParams,
  ClearItemsActionTypeParams,
} from "./common.data.actions.types";
import { TableActionParams, TableActionParamsBase } from "./common.models";
import {
  DataTableActions,
  AsyncDataTableActions,
  DataActions,
} from "./reducers/common.data.reducer";
import { Item } from "@ea/shared_types/types";

export type ModuleActions<T extends Item<string | number>> = {
  table: DataTableActions<T> & AsyncDataTableActions<T>;
  data: DataActions<T>;
};

export const createDataModuleActions = <T extends Item<string | number>>(
  factory: ActionCreatorFactory,
): ModuleActions<T> => ({
  table: {
    ...createTableActions<T>(factory),
    ...createTableAsyncActions<T>(factory),
  },
  data: createDataActions<T>(factory),
});

export const createTableActions = <T extends Item<string | number>>(
  factory: ActionCreatorFactory,
): DataTableActions<T> => ({
  changePage: factory<ChangePageActionTypeParams<T>>("CHANGE_PAGE"),
  changePageSize: factory<ChangePageSizeActionTypeParams<T>>("CHANGE_PAGE_SIZE"),
  changeSorting: factory<ChangeSortingTypeParams<T>>("CHANGE_SORT"),
  clearSorting: factory<ClearSortingActionTypeParams<T>>("CLEAR_SORTING"),
  select: factory<SelectActionTypeParams<T>>("SELECT_ITEM"),
  setFilter: factory<SetFilterActionTypeParams<T>>("SET_FILTER"),
  setFilters: factory<SetFiltersActionTypeParams<T>>("SET_FILTERS"),
  clearFilters: factory<ClearFiltersActionTypeParams<T>>("CLEAR_FILTERS"),
  clearItems: factory<ClearItemsActionTypeParams<T>>("CLEAR_ITEMS"),
  setPersistentQuery: factory<SetPersistentQueryActionTypeParams<T>>("SET_PERSISTENT_QUERY"),
  reload: factory<TableActionParams<{}>>("RELOAD"),
});

export const createTableAsyncActions = <T extends Item<string | number>>(
  factory: ActionCreatorFactory,
): AsyncDataTableActions<T> => ({
  load: factory.async<LoadActionTypeParams<T>, LoadActionTypeResponse<T>>("LOAD"),
  delete: factory.async<DeleteActionTypeParams<T>, DeleteActionTypeResultParams<T>, any>("DELETE"),
  create: factory.async<CreateActionTypeParams<T>, CreateActionTypeResultParams<T>, any>("CREATE"),
  loadPaginationConfig: factory.async<
    LoadPaginationConfigActionTypeParams<T>,
    LoadPaginationConfigActionTypeResponse<T>
  >("INIT_PAGINATION"),
});

export const createDataActions = <T extends Item<string | number>>(
  factory: ActionCreatorFactory,
): DataActions<T> => ({
  loadSingle: factory.async<LoadSingleActionTypeParams<T>, LoadSingleActionTypeResponse<T>>(
    "LOAD_SINGLE",
  ),
  edit: factory.async<EditActionTypeParams<T>, EditActionTypeResultParams<T>>("EDIT"),
  commitEdit: factory<CommitEditActionTypeParams<T>>("COMMIT_EDIT"),
  commitCreate: factory<CommitCreateActionTypeParams<T>>("COMMIT_CREATE"),
  invalidate: factory<InvalidateTypeParams>("INVALIDATE"),
});

type ActionCreatorNoTableId<Payload extends TableActionParamsBase> = ActionCreator<
  ConditionalOmit<Payload, "tableId">
>;

export type NoTableId<
  Payload extends TableActionParamsBase,
  T extends { [k: string]: ActionCreator<Payload> },
> = { [P in keyof T]: ActionCreatorNoTableId<T[P]["payloadType"]> };

export const createTableActionsGetter =
  <Payload extends TableActionParamsBase, T extends { [k: string]: ActionCreator<Payload> }>(
    actions: T,
  ) =>
  (tableId: string) => {
    const wrappedActions = {};
    const tableParams: TableActionParams = {
      tableId,
    };
    Object.keys(actions).forEach((key) => {
      wrappedActions[key] = (params: any) => actions[key]({ ...tableParams, ...params });
    });
    return wrappedActions as NoTableId<Payload, T>;
  };

export const wrapTableActions = <
  Payload extends TableActionParamsBase,
  T extends { [k: string]: ActionCreator<Payload> },
>(
  actions: T,
  tableId: string,
) => {
  const wrappedActions = {};
  const tableParams: TableActionParams = {
    tableId,
  };
  Object.keys(actions).forEach((key) => {
    wrappedActions[key] = (params: any) => actions[key]({ ...tableParams, ...params });
  });
  return wrappedActions as NoTableId<Payload, T>;
};
