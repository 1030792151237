import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { ProjectCategory } from "@ea/shared_types/types";
import { moduleActions } from "./projectCategory.actions";

const initialState = {
  ...getDataReducerInitialState<ProjectCategory>(),
};

export type ProjectCategoryReducer = typeof initialState;

const dataReducer = createDataReducer<ProjectCategory>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
