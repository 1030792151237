import { VariablesGroup } from "../redux/common.models";
import {
  VariableType,
  PredefinedVariable,
  PredefinedVariablesContainerName,
} from "@ea/shared_types/types";

export const PredefinedVariables: VariablesGroup[] = [
  {
    id: PredefinedVariablesContainerName,
    variables: [
      {
        name: PredefinedVariable.CurrentStepValue,
        // rest is not important, todo: make a special type?
        id: 1,
        parentId: 1,
        parentType: "TaskScript",
        type: VariableType.Normal,
      },
    ],
  },
];
