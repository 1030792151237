import * as React from "react";
import styled from "@emotion/styled";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import CommandBar from "./components/ItsCommandBar";
import CreateEditIstContainer from "@app/modules/issueTrackingTool/CreateEditIts.container";
import { ITS_TABLES_CONFIG, ITS_COLUMNS } from "./its.table";
import { itsDataSelectors } from "./its.selectors";
import { getIstTableActions, itsActions } from "./its.actions";

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

interface IIstTableProps {}

interface IIstTableState {
  createWindowVisibility: boolean;
  editWindowVisibility: boolean;
}

class IstTableContainer extends React.Component<IIstTableProps & IConnectProps, IIstTableState> {
  connectedTable: any;

  state: IIstTableState = {
    createWindowVisibility: false,
    editWindowVisibility: false,
  };

  openEditWindow = () => {
    this.setState({
      editWindowVisibility: true,
    });
  };

  closeEditWindow = () => {
    if (this.props.selectedIst) {
      this.props.actions.loadSingle({ id: this.props.selectedIst.id });
    }
    this.setState({
      editWindowVisibility: false,
    });
  };

  openCreateWindow = () => {
    this.setState({
      createWindowVisibility: true,
    });
  };

  closeCreateWindow = () => {
    this.setState({
      createWindowVisibility: false,
    });
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
  };

  render() {
    return (
      <Container>
        <CommandBar
          onNewClick={this.openCreateWindow}
          onRemoveClick={this.remove}
          selected={this.props.selected}
          onEditClick={this.openEditWindow}
          onReload={this.reload}
        />
        <CreateEditIstContainer
          visibility={this.state.createWindowVisibility || this.state.editWindowVisibility}
          onClose={this.state.editWindowVisibility ? this.closeEditWindow : this.closeCreateWindow}
          onCreate={this.reload}
          selected={this.props.selectedIst}
          isEdit={this.state.editWindowVisibility}
          user={this.props.user}
          testPlansIntegration={
            this.props.globalSettings && this.props.globalSettings.TEST_PLANS_INTEGRATION
          }
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={ITS_COLUMNS}
          tableId={ITS_TABLES_CONFIG.MAIN.id()}
          preferencesId={ITS_TABLES_CONFIG.MAIN.preferencesId}
          stateKey={"its"}
          tableActions={getIstTableActions}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(itsActions, dispatch),
    ...bindActionCreators(getIstTableActions(ITS_TABLES_CONFIG.MAIN.id()), dispatch),
  },
});

const connectCreator = connect(
  (state: ApplicationState) => ({
    selected: itsDataSelectors.getSelectedSelector(state, ITS_TABLES_CONFIG.MAIN.id()),
    selectedIst: itsDataSelectors.getSelectedItemSelector(state, ITS_TABLES_CONFIG.MAIN.id()),
    user: state.auth.user,
    globalSettings: state.globalSettings,
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(IstTableContainer);
