const LOCAL_STORAGE_NAME = "eaAuth";

type LoginParams = {
  id: string;
  userId: number;
};

export const saveToken = (loginResponse: LoginParams) =>
  localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(loginResponse));

export const getToken = () => {
  const item = localStorage.getItem(LOCAL_STORAGE_NAME);
  if (!item) {
    throw new Error("User is not logged in");
  }
  return JSON.parse(item) as LoginParams;
};

export const isLoggedIn = () => {
  try {
    getToken();
    return true;
  } catch (error) {
    return false;
  }
};

export const removeToken = () => localStorage.removeItem(LOCAL_STORAGE_NAME);
