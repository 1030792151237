import { objectValues } from "@ea/shared_components/utils/object";

import * as itsRulesActions from "./itsRules.actions";
import itsRulesEpicsMap from "./itsRules.epics";
import * as itsRulesReducers from "./itsRules.reducers";

const itsRulesEpics = objectValues(itsRulesEpicsMap);

export { itsRulesActions, itsRulesReducers, itsRulesEpics };

export { default as ItsRulesTableContainer } from "./ItsRulesTable.container";
