import React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { PanelType } from "@ea/shared_components/Panel";
import CodeTemplatesUsedInTable from "./CodeTemplatesUsedInTable";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";

export const CodeTemplateUsedInPanel = ({
  visible,
  onCancel,
  codeTemplateId,
}: {
  visible: boolean;
  onCancel: () => void;
  codeTemplateId?: number;
}) => {
  return codeTemplateId ? (
    <PanelFormFinal
      visibility={visible}
      panelType={PanelType.MEDIUM}
      headerText={getTranslationKey("codeTemplates", "header", "usedIn")}
      onCancelClick={onCancel}
      onOkClick={() => {}}
      onRenderFooterContent={() => (
        <Button key="cancel" onClick={onCancel}>
          <FormattedMessage id={getTranslationKey("button", "close")} />
        </Button>
      )}
      render={(props) => <CodeTemplatesUsedInTable codeTemplateId={codeTemplateId} />}
    />
  ) : null;
};
