export const validators = ({ formatMessage }) => {
  const requiredValidator = (value) => {
    const isArray = Array.isArray(value);
    if (isArray) {
      if (value.length > 0) {
        return undefined;
      } else {
        return formatMessage({ id: "validators.required" });
      }
    }
    if (value) {
      return undefined;
    } else {
      return formatMessage({ id: "validators.required" });
    }
  };

  const detectSpacesValidator = (value) =>
    /\s/.test(value) ? "Whitespaces are forbidden" : undefined;

  const mustBeNumberValidator = (value) =>
    isNaN(value) ? formatMessage({ id: "validators.mustBeNumber" }) : undefined;

  const mustStartWithLetter = (value) =>
    !isNaN(value) || /^[0-9]/.test(value)
      ? formatMessage({ id: "validators.startLetter" })
      : undefined;

  const minValueValidator = (min) => (value) =>
    isNaN(value) || value >= min
      ? undefined
      : `${formatMessage({ id: "validators.minValue" })} ${min}`;

  const whiteSpaceAndSpecialCharValidator = (value) =>
    /[\W_\s]+/g.test(value)
      ? formatMessage({ id: "validators.whitespacesAndSpecialChars" })
      : undefined;

  const numberSequenceFormatValidator = (value) => {
    if (!value) {
      return undefined;
    }

    const firstOccurrenceOfHash = value.indexOf("#");
    const lastOccurrenceOfHash = value.lastIndexOf("#");

    if (firstOccurrenceOfHash === -1 || lastOccurrenceOfHash === -1) {
      return formatMessage({ id: "validators.numSequenceFormat" });
    }

    if (firstOccurrenceOfHash === lastOccurrenceOfHash) {
      return undefined;
    }

    const fromHashToHash = value.substring(firstOccurrenceOfHash, lastOccurrenceOfHash);
    const format = new RegExp("[^#]+");

    if (format.test(fromHashToHash)) {
      return formatMessage({ id: "validators.numSequenceBreak" });
    }
    return undefined;
  };

  const emailListValiadtor = (value) => {
    const emails = value ? value.replace(/\s/g, "").split(",") : [];

    let valid = true;

    // tslint:disable-next-line:max-line-length
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    for (let i = 0; i < emails.length; i++) {
      if (emails[i] === "" || !regex.test(emails[i])) {
        valid = false;
      }
    }
    return valid ? undefined : formatMessage({ id: "validators.emailList" });
  };

  const emailValidator = (value) => {
    // tslint:disable-next-line:max-line-length
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(value) ? undefined : formatMessage({ id: "validators.email" });
  };

  const emailValidatorWithEmptyEmail = (value) => {
    if (value === "") {
      return undefined;
    }

    return emailValidator(value);
  };

  const monacoValidator = (value: any) => {
    const required = requiredValidator(value);
    if (required) {
      return required;
    }

    const nestedValue = value.split("\n").slice(1, -1).join().replace(/ /g, "");
    return requiredValidator(nestedValue);
  };

  const stringEqualsNullValidator = (value: string) => {
    return value.toLowerCase() === "null" ? formatMessage({ id: "validators.null" }) : undefined;
  };

  const userNameMatchValidator = (user) => (value) =>
    user === value ? undefined : formatMessage({ id: "validators.userName" });

  const passwordsDifferentValidator = (valueToCompareWith) => (value) =>
    value !== valueToCompareWith ? formatMessage({ id: "password.diffPassword" }) : undefined;

  const passwordPolicyValidator = (value: string) => {
    if (value.length < 12) {
      return formatMessage({ id: "password.length" });
    }

    if (value.search(/[a-z]/) < 0) {
      return formatMessage({ id: "password.lowerCase" });
    }

    if (value.search(/[A-Z]/) < 0) {
      return formatMessage({ id: "password.capitalLetter" });
    }

    if (value.search(/[0-9]/) < 0) {
      return formatMessage({ id: "password.digit" });
    }

    if (value.search(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/) < 0) {
      return formatMessage({ id: "password.specialCharacter" });
    }

    if (value.search(/^[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]*$/) < 0) {
      return formatMessage({ id: "password.notAllowedCharacter" });
    }

    return undefined;
  };

  return {
    monacoValidator,
    requiredValidator,
    detectSpacesValidator,
    mustBeNumberValidator,
    mustStartWithLetter,
    minValueValidator,
    whiteSpaceAndSpecialCharValidator,
    numberSequenceFormatValidator,
    emailListValiadtor,
    emailValidator,
    stringEqualsNullValidator,
    userNameMatchValidator,
    passwordsDifferentValidator,
    emailValidatorWithEmptyEmail,
    passwordPolicyValidator,
  };
};

export const composeValidators =
  (...validatorsArg) =>
  (value) =>
    validatorsArg.reduce((error, validator) => error || validator(value), undefined);

export default validators;
