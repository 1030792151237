import { createModuleEpics } from "@app/modules/app.reducers";
import { moduleActions } from "@app/modules/roles/roles.actions";
import { Role } from "@ea/shared_types/types";
import { roleDataSelectors } from "@app/modules/roles/roles.selectors";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { ROLES_COLUMNS_CONFIG } from "./roles.table";

const roleModuleEpics = createModuleEpics<Role>(moduleActions, {
  normalizeItem: (role) => role,
  getRequestParams: (state, tableId) => {
    const tableParams = roleDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, ROLES_COLUMNS_CONFIG, {
      defaultOrder: [`created DESC`],
    });
    return params;
  },
  getSingleRequestParams: (query) => {
    return query;
  },
  api: {
    createItem: API.createRole as any,
    deleteItem: API.deleteRole as any,
    editItem: API.editRole as any,
    getItems: API.getRoles as any,
  },
});

export default roleModuleEpics;
