import * as React from "react";
import { PanelType } from "@ea/shared_components/Panel";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { getTranslationKey } from "@app/translations/translations.helpers";
import CodeTemplateGroupsForm from "./components/CodeTemplateGroupsForm";
import { CodeTemplateGroup } from "@ea/shared_types/types";
import { API } from "@app/services/api/api";
interface ICreateEditCodeTemplateGroupPanelProps {
  visibility: boolean;
  onClose: () => void;
  selected?: CodeTemplateGroup;
  isEdit: boolean;
  onSave: () => void;
}

const CreateCodeTemplateGroupPanel: React.FunctionComponent<
  ICreateEditCodeTemplateGroupPanelProps
> = ({ isEdit, onClose, visibility, selected, onSave }) => {
  const onOk = async (values) => {
    if (isEdit) {
      await API.editCodeTemplateGroup(values);
    } else {
      await API.createCodeTemplateGroup(values);
    }
    onSave();
    onClose();
  };
  return (
    <PanelFormFinal
      visibility={visibility}
      panelType={PanelType.SMALL}
      headerText={getTranslationKey("codeTemplateGroup", "header", isEdit ? "edit" : "create")}
      onCancelClick={onClose}
      onOkClick={onOk}
      key={isEdit ? "Edit" : "Create"}
      cancelButtonText={getTranslationKey("button", "cancel")}
      okButtonText={getTranslationKey("button", isEdit ? "save" : "create")}
      initialValues={isEdit ? selected : undefined}
      render={({ values, change, form }) => (
        <CodeTemplateGroupsForm values={values} form={form} isEdit={isEdit} />
      )}
    />
  );
};

export default CreateCodeTemplateGroupPanel;
