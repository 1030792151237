import { GetAsTime } from "@app/modules/common/time";
import JobTriggerField from "@app/modules/logs/components/JobTriggerField";
import StatusField from "@app/modules/logs/components/StatusField";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import {
  JOB_STATUS_TYPE,
  JOB_TRIGGER_TYPE,
  RecurrenceTypeSingular,
  SchedulerJobBasic,
  SchedulerJobGantt,
  TimeScheluderLabel,
} from "@ea/shared_types/types";
import * as React from "react";

export const SCHEDULER_COLUMNS_CONFIG_GANTT: PlainObject<CustomColumn<SchedulerJobGantt>> = {};

export const SCHEDULER_COLUMNS_CONFIG: PlainObject<CustomColumn<SchedulerJobBasic>> = {
  id: {
    label: getTranslationKey("table", "jobId"),
    dataIndex: "id",
  },
  name: {
    dataIndex: "name",
    label: getTranslationKey("table", "name"),
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  description: {
    label: getTranslationKey("table", "description"),
    dataIndex: "description",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  startDateTime: {
    label: getTranslationKey("table", "startDateTime"),
    dataIndex: "startDateTime",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "startDateTime" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
  endDateTime: {
    label: getTranslationKey("table", "endDateTime"),
    dataIndex: "endDateTime",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "endDateTime" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
  triggerType: {
    label: getTranslationKey("table", "triggerType"),
    dataIndex: "triggerType",
    sortable: true,
    render: (text, record, index) => <JobTriggerField triggerType={record.triggerType} />,

    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        {
          label: getTranslationKey("scheduler", "settings", JOB_TRIGGER_TYPE.TIME),
          value: JOB_TRIGGER_TYPE.TIME,
        },
        {
          label: getTranslationKey("scheduler", "settings", JOB_TRIGGER_TYPE.STORAGE),
          value: JOB_TRIGGER_TYPE.STORAGE,
        },
      ],
    },
  },
  offset: {
    label: getTranslationKey("table", "reccurrence"),
    dataIndex: "offset",
    render: (text, record, index) => {
      return (
        <span>
          {record.offset
            ? `Every ${record.offset} ${
                record.offset > 1
                  ? record.recurrenceType
                  : RecurrenceTypeSingular[record.recurrenceType]
              } ${
                record.occurrences
                  ? record.occurrences > 1
                    ? `, ${record.occurrences} ${TimeScheluderLabel.times}`
                    : `, ${TimeScheluderLabel.once}`
                  : ""
              }`
            : record.triggerType === JOB_TRIGGER_TYPE.TIME
            ? "Single"
            : "-"}
        </span>
      );
    },
  },
  createdAt: {
    label: getTranslationKey("table", "createdAt"),
    dataIndex: "createdAt",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "createdAt" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
  status: {
    label: getTranslationKey("table", "status"),
    dataIndex: "status",
    sortable: true,
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        {
          label: getTranslationKey("scheduler", "statuses", JOB_STATUS_TYPE.ACTIVE),
          value: JOB_STATUS_TYPE.ACTIVE,
        },
        {
          label: getTranslationKey("scheduler", "statuses", JOB_STATUS_TYPE.COMPLETED),
          value: JOB_STATUS_TYPE.COMPLETED,
        },
        {
          label: getTranslationKey("scheduler", "statuses", JOB_STATUS_TYPE.INACTIVE),
          value: JOB_STATUS_TYPE.INACTIVE,
        },
        {
          label: getTranslationKey("scheduler", "statuses", JOB_STATUS_TYPE.RUNNING),
          value: JOB_STATUS_TYPE.RUNNING,
        },
      ],
    },
    render: (text, record, index) => <StatusField status={text} />,
  },
  statusWithClosed: {
    label: getTranslationKey("table", "status"),
    dataIndex: "status",
    sortable: true,
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        {
          label: getTranslationKey("scheduler", "statuses", JOB_STATUS_TYPE.ACTIVE),
          value: JOB_STATUS_TYPE.ACTIVE,
        },
        {
          label: getTranslationKey("scheduler", "statuses", JOB_STATUS_TYPE.COMPLETED),
          value: JOB_STATUS_TYPE.COMPLETED,
        },
        {
          label: getTranslationKey("scheduler", "statuses", JOB_STATUS_TYPE.INACTIVE),
          value: JOB_STATUS_TYPE.INACTIVE,
        },
        {
          label: getTranslationKey("scheduler", "statuses", JOB_STATUS_TYPE.CLOSED),
          value: JOB_STATUS_TYPE.CLOSED,
        },
      ],
    },
    render: (text, record, index) => <StatusField status={text} />,
  },
};

export const SCHEDULER_COLUMNS: ColumnConfig<SchedulerJobBasic>[] = [
  {
    props: SCHEDULER_COLUMNS_CONFIG.id,
    frameworkProps: {
      width: "5%",
    },
  },
  {
    props: SCHEDULER_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "13%",
    },
  },
  {
    props: SCHEDULER_COLUMNS_CONFIG.description,
    frameworkProps: {
      width: "11%",
    },
  },
  {
    props: SCHEDULER_COLUMNS_CONFIG.startDateTime,
    frameworkProps: {
      width: "11%",
    },
  },
  {
    props: SCHEDULER_COLUMNS_CONFIG.endDateTime,
    frameworkProps: {
      width: "11%",
    },
  },
  {
    props: SCHEDULER_COLUMNS_CONFIG.triggerType,
    frameworkProps: {
      width: "11%",
    },
  },
  {
    props: SCHEDULER_COLUMNS_CONFIG.offset,
    frameworkProps: {
      width: "11%",
    },
  },
  {
    props: SCHEDULER_COLUMNS_CONFIG.createdAt,
    frameworkProps: {
      width: "14%",
    },
  },
  {
    props: SCHEDULER_COLUMNS_CONFIG.status,
  },
];

export const SCHEDULER_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_SCHEDULER",
  },
};
