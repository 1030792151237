import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";

import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { DataTestIds } from "@app/utils/dataTestIds";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faArrowCircleDown, faArrowCircleUp, faLink } from "@fortawesome/fontawesome-free-solid";

interface CodeTemplatesCommandBarProps {
  onAddClick: () => void;
  onEditClick: () => void;
  onReloadClick: () => void;
  onRemoveClick: () => void;
  isEditDisabled: boolean;
  selected: number[];
  importCodeTemplate: () => void;
  exportCodeTemplate: () => void;
  onUsedInClick: () => void;
}

const CodeTemplatesCommandBar = ({
  onAddClick,
  onEditClick,
  isEditDisabled,
  onReloadClick,
  onRemoveClick,
  selected,
  importCodeTemplate,
  exportCodeTemplate,
  onUsedInClick,
}: CodeTemplatesCommandBarProps) => (
  <CommandBar data-testid={DataTestIds.COMMANDBAR_CODE_TEMPLATES}>
    <CommandBarButton
      name="add"
      onClick={onAddClick}
      text={getTranslationKey("commandBar", "add")}
      icon="PlusOutlined"
      data-testid={DataTestIds.COMMANDBAR_BUTTON_NEW}
    />
    <CommandBarButton
      name="remove"
      onClick={onRemoveClick}
      text={getTranslationKey("commandBar", "remove")}
      icon="DeleteOutlined"
      disabled={selected.length === 0}
      data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE}
    />
    <CommandBarButton
      name="refresh"
      onClick={onReloadClick}
      text={getTranslationKey("commandBar", "refresh")}
      icon="ReloadOutlined"
      data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
    />
    <CommandBarButton
      name="edit"
      onClick={onEditClick}
      text={getTranslationKey("commandBar", "edit")}
      icon="EditOutlined"
      disabled={isEditDisabled || selected.length !== 1}
      data-testid={DataTestIds.COMMANDBAR_BUTTON_EDIT}
    />
    <CommandBarButton
      name="export"
      onClick={exportCodeTemplate}
      text={getTranslationKey("commandBar", "export")}
      icon={<FontAwesomeIcon icon={faArrowCircleDown} />}
      disabled={selected.length === 0}
      data-testid={DataTestIds.COMMANDBAR_BUTTON_EXPORT}
    />
    <CommandBarButton
      name="import"
      onClick={importCodeTemplate}
      text={getTranslationKey("commandBar", "import")}
      icon={<FontAwesomeIcon icon={faArrowCircleUp} />}
      data-testid={DataTestIds.COMMANDBAR_BUTTON_IMPORT}
    />
    <CommandBarButton
      name="usedIn"
      onClick={onUsedInClick}
      disabled={!(selected.length === 1)}
      text={getTranslationKey("commandBar", "usedIn")}
      icon={<FontAwesomeIcon icon={faLink} />}
      data-testid={DataTestIds.COMMANDBAR_BUTTON_USED_IN}
    />
  </CommandBar>
);

export default CodeTemplatesCommandBar;
