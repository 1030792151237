import difference from "lodash.difference";

export const moveTo = (arr: any[], previousIndex: number, newIndex: number) => {
  const array = arr.slice(0);
  if (newIndex >= array.length) {
    let k = newIndex - array.length;
    while (k-- + 1) {
      array.push(undefined);
    }
  }
  array.splice(newIndex, 0, array.splice(previousIndex, 1)[0]);
  return array;
};

export const partition = <T>(array: T[], filter: (el: T, idx: number, arr: T[]) => boolean) => {
  let pass: T[] = [],
    fail: T[] = [];
  array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e));
  return [pass, fail];
};

export const remove = (arr: any[], index: number) => {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export const concatFrom = (from: number, sourceArray: number[], newItems: number[]) => {
  newItems.forEach((item, index) => {
    sourceArray[from + index] = item;
  });
  return ([] as any).concat(sourceArray);
};

export const groupBy = (xs: any, key: any) => {
  return xs.reduce((rv: any, x: any) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const take = (array: any, key: any) => {
  const taken = {};
  const result: any[] = [];
  array.forEach((element: any) => {
    const value = element[key];
    if (!taken[value]) {
      taken[value] = 1;
      result.push(value);
    }
  });
  return result;
};

export const insertBetween = <T>(arr: T[], value: T) => {
  return arr.reduce<T[]>((result, element, index, array) => {
    result.push(element);

    if (index < array.length - 1) {
      result.push(value);
    }

    return result;
  }, []);
};

export const hasIntersection = (array, arrayToCheckWith) =>
  !!array.find((val) => arrayToCheckWith.indexOf(val) >= 0);

export const isSame = (array1: any[], array2: any[]) =>
  difference(array1, array2).length === 0 && difference(array2, array1).length === 0;
