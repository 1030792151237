import { API } from "@app/services/api/api";
import {
  Project,
  RunnerMode,
  RUNNER_OPEN_MODE,
  System,
  Variable,
  VirtualUser,
} from "@ea/shared_types/types";
import styled from "@emotion/styled";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../app.reducers";
import { scriptsActions } from "../scripts/scripts.actions";
import { variableActions } from "../variables/variables.actions";
import RecorderManager from "./recorder/RecorderManager.container";
import { getRunnerTableActions } from "./runner.actions";
import RunnerFrame from "./RunnerFrame";
import RunnerStepDetailsContainer from "./RunnerStepDetails.container";

import { getTranslationKey } from "@app/translations/translations.helpers";
import { currentUserSettingsSelector } from "@ea/shared_components/auth/auth.selectors";
import { IFrameRunnerAsyncMessages } from "@ea/shared_types/communication.types";
import { LiveVariablesModifyData, PlayerVariables } from "@ea/shared_types/runner.common.types";
import { RunnerLocationState, RUNNER_POSITION } from "@ea/shared_types/types";
import { runnerCommunicator } from "@ea/shared_types/utils/iframes.communication";
import { Spin } from "antd";
import * as qs from "qs";
import { toast } from "react-toastify";
import { closeSessions } from "../../utils/openSessions";
import { getCodeTemplatesTableActions } from "../codeTemplates/codeTemplates.actions";
import { CODE_TEMPLATES_TABLES_CONFIG } from "../codeTemplates/codeTemplates.table";
import { scriptsDataSelectors } from "../scripts/scripts.selectors";
import { stepsTableActions } from "../steps/steps.actions";
import { STEPS_TABLE_ID } from "../steps/steps.table";
import DataViewContainer from "./DataView.container";
import PlayerManagerContainer from "./player/PlayerManager.container";
import { buildLocalScriptVariables } from "./runner.helpers";
import {
  getAllLinkedScriptsIds,
  getSessionParams,
  isDirtySelector,
  isSyncingSelector,
  runnerDataSelectors,
  syncErrorSelector,
} from "./runner.selectors";

interface IRunnerContainerProps {
  sessionId: string;
}

interface IRunnerContainerState {
  readyToLoadIFrame: boolean;
  iframeURL: string;
  enabledIframeURL: string;
  tmpSessionId: string;
  isEditingStep: boolean;
  virtualUsers: VirtualUser[];
  systems: System[];
  project?: Project;
  isStepsManagerVisible: boolean;
  isDataViewVisible: boolean;
  isRunnerLoaded: boolean;
  isDrawingOverlayEnabled: boolean;
  runnerLoadedFirstTime: boolean;
}

const RUNNER_PANEL_WIDTH = "520px";

const RunnerPanel = styled.div(
  {
    display: "grid",
    gridTemplateColumns: `${RUNNER_PANEL_WIDTH} 1fr`,
    gridTemplateRows: "40px calc(100% - 40px)",
    height: "100%",
    width: "100%",
    overflowY: "hidden",
  },
  ({ reverseDirection, minimalistic }: { reverseDirection: boolean; minimalistic: boolean }) => ({
    gridTemplateColumns: reverseDirection
      ? `1fr ${RUNNER_PANEL_WIDTH}`
      : `${RUNNER_PANEL_WIDTH} 1fr`,
    gridTemplateAreas: reverseDirection
      ? minimalistic
        ? `
        'framebar runnerbar'
        'frame frame'
      `
        : `
        'framebar runnerbar'
        'frame runnerdata'
      `
      : minimalistic
      ? `
        'runnerbar framebar'
        'frame frame'
      `
      : `
        'runnerbar framebar'
        'runnerdata frame'
      `,
  }),
);

const SpinnerContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
});

type ComponentProps = IRunnerContainerProps & WithTranslation;

class RunnerContainer extends React.Component<
  IRunnerContainerProps & IConnectProps,
  IRunnerContainerState
> {
  constructor(props) {
    super(props);

    this.state = {
      readyToLoadIFrame: false,
      iframeURL: "",
      tmpSessionId: props.sessionId,
      enabledIframeURL: "about:blank",
      isEditingStep: false,
      virtualUsers: [],
      systems: [],
      isStepsManagerVisible: true,
      isDataViewVisible: false,
      isRunnerLoaded: false,
      isDrawingOverlayEnabled: false,
      runnerLoadedFirstTime: false,
    };
  }

  componentWillMount() {
    runnerCommunicator.administration.registerMessageHandler<IFrameRunnerAsyncMessages.runner_loaded>(
      IFrameRunnerAsyncMessages.runner_loaded,
      {
        key: "runner_loaded",
        callback: (variables, meta) => {
          this.setState({
            runnerLoadedFirstTime: true,
          });
        },
      },
    );
  }

  closeSession = () => {
    closeSessions([this.props.sessionId]);
  };
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.closeSession);
    this.closeSession();
  }

  componentDidMount() {
    const { sessionId, actions } = this.props;

    if (sessionId) {
      this.loadSessionData();
    }
    runnerCommunicator.administration.registerMessageHandler<IFrameRunnerAsyncMessages.variable_changed>(
      IFrameRunnerAsyncMessages.variable_changed,
      {
        key: "variable_changed",
        callback: (variables, meta) => {
          actions.updateLiveVariables({ variables });
        },
      },
    );
    runnerCommunicator.administration.registerMessageHandler<IFrameRunnerAsyncMessages.variables_init>(
      IFrameRunnerAsyncMessages.variables_init,
      {
        key: "variables_init",
        callback: (variables, meta) => {
          actions.updateLiveVariables({ variables, init: true });
        },
      },
    );

    window.addEventListener("beforeunload", this.closeSession);
  }

  async componentDidUpdate(
    prevProps: IRunnerContainerProps & IConnectProps,
    prevState: IRunnerContainerState,
  ) {
    const { sessionId, sessionParams, linkedScriptsIds, script, variablesActions, selectedSteps } =
      this.props;

    const { project } = this.state;

    const scriptId = sessionParams.scriptId;

    if (prevProps.sessionId !== sessionId) {
      this.loadSessionData();
    }
    if (prevProps.sessionParams?.scriptId !== sessionParams?.scriptId) {
      this.loadScriptData();
    }
    if (linkedScriptsIds?.length !== prevProps.linkedScriptsIds?.length) {
      variablesActions.loadLocal(scriptId);
      this.loadLinkedVariables();
    }
    if (script?.id !== prevProps.script?.id || (script?.id && !project)) {
      await this.loadProjectData();
    }
    if (prevState.isEditingStep && selectedSteps?.length !== prevProps.selectedSteps?.length) {
      this.setState({ isEditingStep: false });
    }
    if (prevProps.sessionParams.mode !== sessionParams.mode) {
      this.setState({ isDataViewVisible: false });
    }

    // reload steps when someting went wrong while syncing in order to restore preious state
    if (prevProps.isSyncing && !this.props.isSyncing && this.props.syncError) {
      this.props.actions.load({});
    }
  }

  initOnToggle = async () => {
    this.props.actions.load({});
  };

  loadSessionData = async () => {
    try {
      const data = await API.runner.meta({ sessionId: this.props.sessionId });
      this.props.actions.setRunnerParams({ scriptId: data.scriptId, mode: data.mode });
      this.loadScriptData();
      this.setState({ enabledIframeURL: data.url, iframeURL: data.url });
      this.props.actions.load({});
    } catch (error) {
      toast.error(<FormattedMessage id={getTranslationKey("runner", "sessionLoadError")} />);
      this.props.history.push("/");
    }
  };

  loadProjectData = async () => {
    const { script } = this.props;

    if (!script) {
      return;
    }

    if (script) {
      const project = (
        await API.getProjects({
          filter: {
            where: {
              id: {
                inq: [script.projectId],
              },
            },
            include: [
              "ProjectVirtualUserMap.VirtualUser",
              "ProjectSystemDictionaryMap.SystemDictionary",
            ],
          },
        })
      )[0];

      if (!project) {
        return;
      }

      this.setState({
        virtualUsers: project.virtualUsers! as any, // todo: NEW_TYPES
        systems: project.systems! as any, // todo: NEW_TYPES
        project: project as any, // todo: NEW_TYPES
      });
    }
  };

  loadIFrame = () => {
    this.setState({
      readyToLoadIFrame: true,
    });
  };

  loadLinkedVariables = () => {
    const { linkedScriptsIds, variablesActions } = this.props;
    if (linkedScriptsIds?.length > 0) {
      variablesActions.loadLinked(linkedScriptsIds);
    }
  };

  loadScriptData = () => {
    const scriptId = this.props.sessionParams.scriptId;

    if (!scriptId) {
      return;
    }

    this.props.scriptActions.loadSingle({ id: scriptId });
    this.props.loadSteps({
      query: {
        taskScriptId: scriptId,
      },
    });
    this.props.variablesActions.loadDataSource(scriptId);
    this.props.variablesActions.loadLocal(scriptId);
    this.loadLinkedVariables();
    this.props.variablesActions.loadSequence();
    this.props.codeTemplateActons.load({});
  };

  getAssignedVU = () => {
    const { script } = this.props;
    const { project, virtualUsers } = this.state;

    if (script.isManualUrl) {
      return undefined;
    }

    const virtualUserId = script.virtualUserId;
    if (virtualUserId) {
      return virtualUsers.find((s) => s.id === virtualUserId);
    }

    if (!project) {
      return undefined;
    }

    return virtualUsers.find((s) => s.id === project!.defaultVirtualUserId);
  };

  getAssignedSystem = () => {
    const { script } = this.props;
    const { project, systems } = this.state;

    if (script.isManualUrl) {
      return undefined;
    }

    const environmentId = script.environmentId;
    if (environmentId) {
      return systems.find((s) => s.id === environmentId);
    }

    if (!project) {
      return undefined;
    }

    return systems.find((s) => s.id === project!.defaultSystemId);
  };

  toggleMode = () => {
    const { mode } = this.props.sessionParams;
    this.props.actions.toggleMode({
      mode: mode === RunnerMode.PLAYER ? RunnerMode.RECORDER : RunnerMode.PLAYER,
    });
  };

  reloadIframe = () => {
    if (this.state.iframeURL === this.state.enabledIframeURL) {
      (document.getElementById("eaApp")! as any).src = this.state.enabledIframeURL;
    } else {
      this.setState({
        enabledIframeURL: this.state.iframeURL,
      });
    }
  };

  onDrawingOverlayToggle = () => {
    this.setState((state) => ({
      isDrawingOverlayEnabled: !state.isDrawingOverlayEnabled,
    }));
  };

  toggleStepEdit = () => {
    this.setState((state) => ({
      isEditingStep: !state.isEditingStep,
      isDataViewVisible: false,
    }));
  };

  toggleDataView = () => {
    this.setState((state) => ({
      isDataViewVisible: !state.isDataViewVisible,
      isEditingStep: false,
    }));
  };

  toggleStepsManagerVisibility = () => {
    this.setState((state) => ({ isStepsManagerVisible: !state.isStepsManagerVisible }));
  };

  onClose = () => {
    const { userSettings, history, location } = this.props;
    const { runner } = userSettings;
    const { state } = location;
    const { opener, runnerParams } = (state as RunnerLocationState) || {};
    const openMode = runnerParams?.openMode || runner?.openMode;

    if (openMode === RUNNER_OPEN_MODE.CURRENT_WINDOW && history.length > 1 && opener) {
      history.push(opener);
      return;
    }
    if (window.opener) {
      window.close();
      return;
    }
    history.push("/");
  };

  onLocalVariableSave = (variables: Variable[]) => {
    const { sessionParams, actions } = this.props;

    const removed = Object.keys((sessionParams.variables as PlayerVariables).script).filter(
      (id) => !variables.find((variable) => id === `${variable.id}`),
    );
    const newVariables = variables.filter(
      (v) => !Object.keys((sessionParams.variables as PlayerVariables).script).includes(`${v.id}`),
    );
    const localVars = buildLocalScriptVariables(newVariables);

    actions.updateLiveVariables({ variables: localVars });
    actions.removeLiveVariables({ ids: removed });

    const toRemove: LiveVariablesModifyData[] = removed.map((r) => ({
      variableId: { variableId: parseInt(r, 10) },
      type: "Local",
      actionType: "remove",
    }));
    const toAdd: LiveVariablesModifyData[] = newVariables.map((v) => ({
      variableId: { variableId: v.id },
      type: "Local",
      value: v.value,
      name: v.name,
      actionType: "add",
    }));

    runnerCommunicator.administration.send(IFrameRunnerAsyncMessages.modify_variables_state, [
      ...toAdd,
      ...toRemove,
    ]);
  };

  onDatasourceUpload = () => {
    const { actions, variablesActions } = this.props;

    variablesActions.loadDataSource(this.props.script.id);
    variablesActions.loadSequence();

    runnerCommunicator.administration.send(IFrameRunnerAsyncMessages.restart_player, {});
    actions.reset({});
  };

  render() {
    const {
      isEditingStep,
      systems,
      virtualUsers,
      project,
      isStepsManagerVisible,
      isDataViewVisible,
      readyToLoadIFrame,
      isDrawingOverlayEnabled,
      runnerLoadedFirstTime,
    } = this.state;
    const { sessionId, sessionParams, script, userSettings, location, isDirty } = this.props;
    const { scriptId, steps } = sessionParams;

    const searchParams = qs.parse(location.search.substr(1));

    const runnerPosition =
      (searchParams?.position as RUNNER_POSITION) ||
      (location.state as RunnerLocationState)?.runnerParams?.position ||
      (window as any).runnerParams?.position ||
      userSettings?.runner?.position;
    const isRunnerOnRightSide = runnerPosition === RUNNER_POSITION.RIGHT;

    if (!sessionId || !scriptId || !project || !script || !steps || steps.length === 0) {
      return (
        <SpinnerContainer>
          <Spin />
        </SpinnerContainer>
      );
    }

    const system = this.getAssignedSystem();

    return (
      <RunnerPanel reverseDirection={isRunnerOnRightSide} minimalistic={!isStepsManagerVisible}>
        {!sessionParams.mode ? null : sessionParams.mode === RunnerMode.PLAYER ? (
          <PlayerManagerContainer
            loadIFrame={this.loadIFrame}
            sessionId={sessionId}
            script={script}
            project={project}
            onModeToggle={this.toggleMode}
            isDrawingOverlayEnabled={isDrawingOverlayEnabled}
            mode={RunnerMode.PLAYER}
            systems={systems}
            system={system}
            virtualUsers={virtualUsers}
            minimalistic={!isStepsManagerVisible}
            runnerLoadedFirstTime={runnerLoadedFirstTime}
          />
        ) : (
          <RecorderManager
            mode={RunnerMode.RECORDER}
            loadIFrame={this.loadIFrame}
            sessionId={sessionId}
            project={project}
            script={script}
            system={system}
            systems={systems}
            virtualUsers={virtualUsers}
            virtualUser={this.getAssignedVU()}
            toggleStepEdit={this.toggleStepEdit}
            toggleDataView={this.toggleDataView}
            isRecording={sessionParams.isRecording}
            isDataViewVisible={isDataViewVisible}
            isEditingStep={isEditingStep}
            onModeToggle={this.toggleMode}
            isDrawingOverlayEnabled={isDrawingOverlayEnabled}
            onDrawingOverlayToggle={this.onDrawingOverlayToggle}
            minimalistic={!isStepsManagerVisible}
            runnerLoadedFirstTime={runnerLoadedFirstTime}
          />
        )}

        <RunnerFrame
          visibility={!isEditingStep && !isDataViewVisible}
          url={this.state.enabledIframeURL}
          sessionId={sessionId}
          reloadIframe={this.reloadIframe}
          readyToLoadIFrame={readyToLoadIFrame}
          toggleStepsManagerVisibility={this.toggleStepsManagerVisibility}
          isStepsManagerVisible={isStepsManagerVisible}
          onClose={this.onClose}
          isRunnerOnRightSide={isRunnerOnRightSide}
        />

        {isDataViewVisible && (
          <DataViewContainer
            isDirty={isDirty}
            scriptId={scriptId}
            script={script}
            toggleVariablesViewVisibility={this.toggleDataView}
            sessionId={sessionId}
            onLocalVariableSave={this.onLocalVariableSave}
            onDatasourceUpload={this.onDatasourceUpload}
          />
        )}

        {isEditingStep && (
          <RunnerStepDetailsContainer
            systems={systems}
            project={project}
            virtualUsers={virtualUsers}
            scriptId={scriptId}
            sessionId={sessionId}
            toggleEdit={this.toggleStepEdit}
          />
        )}
      </RunnerPanel>
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: ComponentProps & RouteComponentProps<any>,
) => ({
  actions: {
    ...bindActionCreators(getRunnerTableActions(props.sessionId), dispatch),
  },
  variablesActions: {
    ...bindActionCreators(variableActions, dispatch),
  },
  scriptActions: {
    ...bindActionCreators(scriptsActions, dispatch),
  },
  loadSteps: bindActionCreators(stepsTableActions(STEPS_TABLE_ID), dispatch).setPersistentQuery,
  codeTemplateActons: {
    ...bindActionCreators(
      getCodeTemplatesTableActions(CODE_TEMPLATES_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ),
  },
});

const connectCreator = connect(
  (
    state: ApplicationState,
    props: ComponentProps & RouteComponentProps<{ opener: string; params }>,
  ) => {
    const sessionParams = getSessionParams(state, props.sessionId);
    return {
      sessionParams,
      isLoading: runnerDataSelectors.getIsLoadingSelector(state),
      isDirty: isDirtySelector(state, props.sessionId),
      isSyncing: isSyncingSelector(state, props.sessionId),
      script: scriptsDataSelectors.getItemSelector(state, sessionParams.scriptId),
      selectedSteps: runnerDataSelectors.getSelectedItemsSelector(state, props.sessionId),
      userSettings: currentUserSettingsSelector(state),
      linkedScriptsIds: getAllLinkedScriptsIds(state, props.sessionId),
      syncError: syncErrorSelector(state, props.sessionId),
      ...props,
    };
  },
  mapDispatchToProps,
);

// todo: ts error, workaround
type IConnectProps = any; // ConnectedProps<typeof connectCreator>;

export default withRouter<any>(withTranslation()(connectCreator(RunnerContainer)));
