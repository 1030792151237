import * as React from "react";
import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { GetAsTime, GetAsSeconds } from "@app/modules/common/time";
import { EXECUTION_STATE, AggregatedJobLog, EXECUTION_STATUS } from "@ea/shared_types/types";
import StatusField from "../components/StatusField";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { Tag } from "antd";
import { CheckOutlined } from "@ant-design/icons";

export const AGGREGATED_JOB_LOGS_COLUMNS_CONFIG: PlainObject<CustomColumn<AggregatedJobLog>> = {
  id: {
    dataIndex: "id",
    label: getTranslationKey("table", "id"),
  },
  jobId: {
    label: getTranslationKey("table", "jobId"),
    dataIndex: "jobId",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  jobExecutionId: {
    label: getTranslationKey("table", "jobExecutionId"),
    dataIndex: "jobExecutionId",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  startTime: {
    label: getTranslationKey("table", "startTime"),
    dataIndex: "startTime",
    sortable: true,
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
    render: (text, record, index) => {
      return <span>{GetAsTime({ dataKey: "startTime", rowData: record })}</span>;
    },
  },
  endTime: {
    label: getTranslationKey("table", "endTime"),
    dataIndex: "endTime",
    sortable: true,
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
    render: (text, record, index) => {
      return <span>{GetAsTime({ dataKey: "endTime", rowData: record })}</span>;
    },
  },
  state: {
    label: getTranslationKey("table", "state"),
    dataIndex: "state",
    sortable: true,
    render: (text, record, index) => <StatusField status={text} />,
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        { label: EXECUTION_STATE.RUNNING, value: EXECUTION_STATE.RUNNING },
        { label: EXECUTION_STATE.FINISHED, value: EXECUTION_STATE.FINISHED },
        { label: EXECUTION_STATE.TERMINATED, value: EXECUTION_STATE.TERMINATED },
      ],
    },
  },
  status: {
    label: getTranslationKey("table", "status"),
    dataIndex: "status",
    sortable: true,
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        { label: getTranslationKey("table", "states", "none"), value: EXECUTION_STATUS.NONE },
        { label: getTranslationKey("table", "states", "success"), value: EXECUTION_STATUS.SUCCESS },
        { label: getTranslationKey("table", "states", "warning"), value: EXECUTION_STATUS.WARNING },
        { label: getTranslationKey("table", "states", "error"), value: EXECUTION_STATUS.ERROR },
      ],
    },
    render: (text) => <StatusField status={text} />,
  },
  successCount: {
    dataIndex: "successCount",
    label: getTranslationKey("table", "statesCapitalLetter", "success"),
    sortable: true,
    render: (text) => <Tag>{text}</Tag>,
  },
  warningCount: {
    dataIndex: "warningCount",
    label: getTranslationKey("table", "statesCapitalLetter", "warning"),
    sortable: true,
    render: (text) => <Tag>{text}</Tag>,
  },
  errorCount: {
    dataIndex: "errorCount",
    label: getTranslationKey("table", "statesCapitalLetter", "error"),
    sortable: true,
    render: (text) => <Tag>{text}</Tag>,
  },
  allCount: {
    dataIndex: "allCount",
    label: getTranslationKey("table", "all"),
    sortable: true,
    render: (text) => <Tag>{text}</Tag>,
  },
  duration: {
    label: getTranslationKey("table", "duration"),
    dataIndex: "duration",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsSeconds({ dataKey: "duration", rowData: record })}</span>;
    },
  },
  projectId: {
    dataIndex: "projectId",
    label: getTranslationKey("table", "projectId"),
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  projectName: {
    dataIndex: "projectName",
    label: getTranslationKey("table", "projectName"),
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  schedulerJobName: {
    dataIndex: "schedulerJobName",
    label: getTranslationKey("table", "schedulerJobName"),
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  schedulerJobId: {
    dataIndex: "schedulerJobId",
    label: getTranslationKey("table", "schedulerJobId"),
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  isExcluded: {
    dataIndex: "isExcluded",
    label: getTranslationKey("table", "isExcluded"),
    render: (value) => (value ? <CheckOutlined /> : null),
  },
};

export const AGGREGATED_JOB_LOGS_COLUMNS: ColumnConfig<AggregatedJobLog>[] = [
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.id,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.jobId,
    frameworkProps: {
      width: "22%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.jobExecutionId,
    frameworkProps: {
      width: "22%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.startTime,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.endTime,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.state,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.status,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.successCount,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.warningCount,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.errorCount,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.allCount,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.duration,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.projectId,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.projectName,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.schedulerJobName,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.schedulerJobId,
    frameworkProps: {
      width: "10%",
    },
  },
];
export const PROJECT_AGGREGATED_JOB_LOGS_COLUMNS: ColumnConfig<AggregatedJobLog>[] = [
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.id,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.startTime,
    frameworkProps: {
      width: "12%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.endTime,
    frameworkProps: {
      width: "12%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.state,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.status,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.successCount,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.warningCount,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.errorCount,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.allCount,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.duration,
    frameworkProps: {
      width: "7%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.isExcluded,
    frameworkProps: {
      width: "7%",
    },
  },
];
export const SCHEDULER_AGGREGATED_JOB_LOGS_COLUMNS: ColumnConfig<AggregatedJobLog>[] = [
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.id,
    frameworkProps: {
      width: "4%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.startTime,
    frameworkProps: {
      width: "11%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.endTime,
    frameworkProps: {
      width: "11%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.state,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.status,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.successCount,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.warningCount,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.errorCount,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.allCount,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.duration,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.isExcluded,
    frameworkProps: {
      width: "6%",
    },
  },
];

export const AGGREGATED_JOB_LOGS_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_AGGREGATED_JOB_LOGS_TABLES",
  },
  PROJECT_AGGREGATED_JOB_LOGS: {
    id: (id: number) => `PROJECT_AGGREGATED_JOB_LOGS${id}`,
    preferencesId: "PROJECT_AGGREGATED_JOB_LOGS",
  },
  SCHEDULER_AGGREGATED_JOB_LOGS: {
    id: (id: number) => `SCHEDULER_AGGREGATED_JOB_LOGS${id}`,
    preferencesId: "SCHEDULER_AGGREGATED_JOB_LOGS",
  },
};
