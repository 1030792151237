import * as PropTypes from "prop-types";
import * as React from "react";
import { RouterChildContext } from "react-router";
import CreateStorageForm from "@app/modules/storage/components/CreateStorageForm";
import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { Storage } from "@ea/shared_types/types";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";

interface CreateEditStorageContainerProps {
  visibility: boolean;
  onClose: () => void;
  onCreate: (storage: Storage) => void;
  selected?: Storage;
  isEdit?: boolean;
}

class CreateEditStorageContainer extends React.Component<CreateEditStorageContainerProps> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  context: RouterChildContext<any>;

  onOk = async (values) => {
    const { isEdit, onCreate, onClose } = this.props;
    if (isEdit) {
      await API.editStorage(values);
    } else {
      const result = await API.createStorage(values);
      if (onCreate) {
        onCreate(result);
      }
    }
    onClose();
  };

  render() {
    const { visibility, isEdit, onClose, selected } = this.props;
    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.SMALL}
        headerText={getTranslationKey("storage", "header", isEdit ? "edit" : "create")}
        onCancelClick={onClose}
        onOkClick={this.onOk}
        cancelButtonText={getTranslationKey("button", "close")}
        key={isEdit ? "Edit" : "Create"}
        okButtonText={getTranslationKey("button", isEdit ? "save" : "create")}
        initialValues={isEdit ? selected : undefined}
        render={({ values }) => <CreateStorageForm values={values} />}
      />
    );
  }
}

export default CreateEditStorageContainer;
