import { createModuleEpics } from "@app/modules/app.reducers";
import { moduleActions } from "./workItem.actions";
import { API } from "@app/services/api/api";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { workItemDataSelectors } from "./workItem.selectors";
import { WorkItem } from "@ea/shared_types/types";

const workItemModuleEpics = createModuleEpics<WorkItem>(moduleActions, {
  normalizeItem: (workItem) => workItem,
  getRequestParams: (state, tableId) => {
    const tableParams = workItemDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, {});

    return params;
  },
  getSingleRequestParams: (params) => {
    return params;
  },
  api: {
    createItem: () => ({} as Promise<any>),
    deleteItem: () => ({} as Promise<any>),
    editItem: () => ({} as Promise<any>),
    getItems: API.getWorkItems as any,
    getItemsCount: API.getWorkItemCount,
  },
});

export default workItemModuleEpics;
