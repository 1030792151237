import StatusTag from "@app/modules/common/components/StatusTag";
import { GetAsTime } from "@app/modules/common/time";
import SystemTypeField from "@app/modules/systemDictionary/components/SystemTypeField";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { formatScriptName } from "@app/utils/script";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { FEATURES, SYSTEM_TYPE, Script, ScriptStatus } from "@ea/shared_types/types";
import { Tag } from "antd";
import * as React from "react";
import StatusField from "../logs/components/StatusField";

export const SCRIPTS_COLUMNS_CONFIG: PlainObject<CustomColumn<Script>> = {
  id: {
    label: "table.id",
    sortable: true,
    dataIndex: "id",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  name: {
    label: "table.name",
    dataIndex: "name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    render: (text, record, index) => <span>{formatScriptName(record)}</span>,
  },
  projectId: {
    label: "table.projectName",
    dataIndex: "projectId",
    filter: {
      filterType: ColumnFilters.TREE,
    },
    render: (text, record, index) => (
      <span> {(record as any).projectName || "Unknown project"} </span>
    ),
  },
  envName: {
    label: "table.envName",
    dataIndex: "envName",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  envNameShort: {
    label: "table.envNameShort",
    dataIndex: "envName",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  envType: {
    label: "table.envType",
    dataIndex: "envType",
    render: (text) => <SystemTypeField type={text} />,
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        {
          label: getTranslationKey("systemDictionary", "type", "development"),
          value: SYSTEM_TYPE.DEV,
        },
        { label: getTranslationKey("systemDictionary", "type", "test"), value: SYSTEM_TYPE.TEST },
        {
          label: getTranslationKey("systemDictionary", "type", "production"),
          value: SYSTEM_TYPE.PROD,
        },
        { label: getTranslationKey("systemDictionary", "type", "uat"), value: SYSTEM_TYPE.UAT },
        { label: getTranslationKey("systemDictionary", "type", "other"), value: SYSTEM_TYPE.OTHER },
      ],
    },
  },
  envTypeShort: {
    label: "table.envTypeShort",
    dataIndex: "envType",
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        {
          label: getTranslationKey("systemDictionary", "type", "development"),
          value: SYSTEM_TYPE.DEV,
        },
        { label: getTranslationKey("systemDictionary", "type", "test"), value: SYSTEM_TYPE.TEST },
        {
          label: getTranslationKey("systemDictionary", "type", "production"),
          value: SYSTEM_TYPE.PROD,
        },
        { label: getTranslationKey("systemDictionary", "type", "uat"), value: SYSTEM_TYPE.UAT },
        { label: getTranslationKey("systemDictionary", "type", "other"), value: SYSTEM_TYPE.OTHER },
      ],
    },
    render: (text) => <SystemTypeField type={text} />,
  },
  startUrl: {
    label: "table.startUrl",
    dataIndex: "startUrl",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  status: {
    label: "table.status",
    dataIndex: "status",
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        {
          label: getTranslationKey("scripts", "status", ScriptStatus.DRAFT),
          value: ScriptStatus.DRAFT,
        },
        {
          label: getTranslationKey("scripts", "status", ScriptStatus.PUBLISHED),
          value: ScriptStatus.PUBLISHED,
        },
        {
          label: getTranslationKey("scripts", "status", ScriptStatus.IN_PROGRESS),
          value: ScriptStatus.IN_PROGRESS,
        },
        {
          label: getTranslationKey("scripts", "status", ScriptStatus.READY_FOR_REVIEW),
          value: ScriptStatus.READY_FOR_REVIEW,
        },
        {
          label: getTranslationKey("scripts", "status", ScriptStatus.READY_TO_TEST),
          value: ScriptStatus.READY_TO_TEST,
        },
      ],
    },
    render: (text, record, index) => <StatusTag item={record} />,
  },
  statusWithClosed: {
    label: "table.status",
    dataIndex: "status",
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        {
          label: getTranslationKey("scripts", "status", ScriptStatus.DRAFT),
          value: ScriptStatus.DRAFT,
        },
        {
          label: getTranslationKey("scripts", "status", ScriptStatus.PUBLISHED),
          value: ScriptStatus.PUBLISHED,
        },
        {
          label: getTranslationKey("scripts", "status", ScriptStatus.IN_PROGRESS),
          value: ScriptStatus.IN_PROGRESS,
        },
        {
          label: getTranslationKey("scripts", "status", ScriptStatus.READY_FOR_REVIEW),
          value: ScriptStatus.READY_FOR_REVIEW,
        },
        {
          label: getTranslationKey("scripts", "status", ScriptStatus.READY_TO_TEST),
          value: ScriptStatus.READY_TO_TEST,
        },
        {
          label: getTranslationKey("scripts", "status", ScriptStatus.CLOSED),
          value: ScriptStatus.CLOSED,
        },
      ],
    },
    render: (text, record, index) => <StatusTag item={record} />,
  },
  createdAt: {
    label: "table.createdAt",
    dataIndex: "createdAt",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "createdAt" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
  creator: {
    label: "table.creator",
    dataIndex: "creator",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  tags: {
    label: "table.tags",
    dataIndex: "tags",
    filter: {
      filterType: ColumnFilters.SELECT,
      filterKey: "tagId",
      noValueLabel: "tags.noTags",
    },
    feature: FEATURES.GXP_TAGS,
    render: (text, record, index) => record.tags?.map((t) => <Tag key={t.id}>{t.name}</Tag>),
  },
  lastExecutionStatus: {
    label: getTranslationKey("table", "lastExecutionStatus"),
    dataIndex: "lastExecutionStatus",
    render: (text) => <StatusField status={text} />,
  },
  lastExecutorName: {
    label: getTranslationKey("table", "lastExecutorName"),
    dataIndex: "lastExecutorName",
  },
  lastExecutionDate: {
    label: getTranslationKey("table", "lastExecutionDate"),
    dataIndex: "lastExecutionDate",
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "lastExecutionDate" })}</span>;
    },
  },
};

export const SCRIPTS_COLUMNS: ColumnConfig<Script>[] = [
  {
    props: SCRIPTS_COLUMNS_CONFIG.id,
    frameworkProps: {
      width: "6%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.name,
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.projectId,
    frameworkProps: {
      width: "13%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.envName,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.envTypeShort,
    frameworkProps: {
      width: "6%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.status,
    frameworkProps: {
      width: "9%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.createdAt,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.creator,
    frameworkProps: {
      width: "6%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.tags,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.lastExecutionStatus,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.lastExecutorName,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.lastExecutionDate,
    frameworkProps: {
      width: "8%",
    },
  },
];

export const PROJECT_SCRIPTS_COLUMNS: ColumnConfig<Script>[] = [
  {
    props: SCRIPTS_COLUMNS_CONFIG.id,
    frameworkProps: {
      width: "6%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.name,
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.envName,
    frameworkProps: {
      width: "11%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.envTypeShort,
    frameworkProps: {
      width: "7%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.status,
    frameworkProps: {
      width: "11%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.createdAt,
    frameworkProps: {
      width: "12%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.creator,
    frameworkProps: {
      width: "7%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.tags,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.lastExecutionStatus,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.lastExecutorName,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.lastExecutionDate,
    frameworkProps: {
      width: "8%",
    },
  },
];

export const SCRIPTS_COLUMNS_SHORT: ColumnConfig<Script>[] = [
  {
    props: SCRIPTS_COLUMNS_CONFIG.id,
    frameworkProps: {
      width: "5%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.name,
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.projectId,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.envNameShort,
    frameworkProps: {
      width: "11%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.envTypeShort,
    frameworkProps: {
      width: "12%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.status,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.tags,
    frameworkProps: {
      width: "11%",
    },
  },
];

export const SCRIPTS_COLUMNS_MINIMAL: ColumnConfig<Script>[] = [
  {
    props: SCRIPTS_COLUMNS_CONFIG.id,
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.name,
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.startUrl,
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.createdAt,
  },
];

export const SCRIPTS_COLUMNS_CODE_TEMPLATE: ColumnConfig<Script>[] = [
  {
    props: SCRIPTS_COLUMNS_CONFIG.id,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "90%",
    },
  },
];

export const SCRIPTS_COLUMNS_GLOBAL_EXCELS: ColumnConfig<Script>[] = [
  {
    props: SCRIPTS_COLUMNS_CONFIG.id,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: SCRIPTS_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "90%",
    },
  },
];

export const SCRIPTS_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_SCRIPTS",
  },
  PROJECT: {
    id: (projectId: number) => `PROJECT_${projectId}_SCRIPTS`,
    preferencesId: "PROJECT_SCRIPTS",
  },
  PUBLISHED_SCRIPT: {
    id: (id: number) => `PUBLISHED_SCRIPTS_${id}`,
    preferencesId: "PUBLISHED_SCRIPTS",
  },
  SCHEDULER_JOB_FLOW: {
    id: () => "SCHEDULER_JOB_FLOW",
    preferencesId: "SCHEDULER_JOB_FLOW",
  },
  SINGLE_SCRIPT: {
    id: (id: number) => `SINGLE_SCRIPT_${id}`,
    preferencesId: "SINGLE_SCRIPTS",
  },
  STEP_FORM: {
    id: () => "STEP_FORM",
    preferencesId: "STEP_FORM",
  },
  SCHEDULER_FLOW_FORM: {
    id: () => "SCHEDULER_FLOW_FORM",
    preferencesId: "SCHEDULER_FLOW_FORM",
  },
  SCHEDULER_FLOW_DISPLAY: {
    id: (schedulerJobId) => `SCHEDULER_${schedulerJobId}_FLOW_DISPLAY`,
    preferencesId: "SCHEDULER_FLOW_DISPLAY",
  },
  LINKED_SCRIPT: {
    id: () => "LINKED_SCRIPTS",
    preferencesId: "LINKED_SCRIPTS",
  },
  DOCUMENTATION: {
    id: (projectId: number) => `PROJECT_${projectId}_DOCUMENTATION_SCRIPTS`,
    preferencesId: "PROJECT_DOCUMENTATION_SCRIPTS",
  },
  CODE_TEMPLATES: {
    id: (codeTemplateId: number) => `CODE_TEMPLATE_${codeTemplateId}_SCRIPTS`,
    preferencesId: "CODE_TEMPLATE_SCRIPTS",
  },
  GLOBAL_EXCELS: {
    id: (datasourceId: number) => `GLOBAL_EXCEL_${datasourceId}_SCRIPTS`,
    preferencesId: "GLOBAL_EXCEL_SCRIPTS",
  },
};
