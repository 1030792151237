import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { CodeTemplateWithGroup } from "@ea/shared_types/types";
import { moduleActions } from "./codeTemplates.actions";

const initialState = {
  ...getDataReducerInitialState<CodeTemplateWithGroup>(),
};

const dataReducer = createDataReducer<CodeTemplateWithGroup>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export type CodeTemplatesReducer = typeof initialState;

export const reducer = dataReducer(initialState);
