import * as React from "react";
import { FormattedMessage } from "react-intl";
import { TreeSelectField } from "@ea/shared_components/Form/Fields/TreeSelectField";
import { getTranslationKey } from "@app/translations/translations.helpers";
import FormItemWrapper from "@ea/shared_components/Form/FormItem/FormItemWrapper";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { Row, Col, Tooltip } from "antd";
import FormButton from "@ea/shared_components/Form/FormButton";
import { ArrowRightOutlined } from "@ant-design/icons";
import { DataTestIds } from "@app/utils/dataTestIds";

export const ProjectTreeWithGoTo: React.FunctionComponent<{
  readOnly?: boolean;
  onGoToClick: () => void;
  onProjectChange: (projectId) => void;
  projects: any;
  projectPath?: string;
}> = ({ readOnly, onGoToClick, projects, onProjectChange, projectPath }) => (
  <FormItemWrapper
    formItem={{
      formItemRowStyle: { marginBottom: 0 },
      label: (<FormattedMessage id={getTranslationKey("common", "label", "project")} />) as any,
    }}
  >
    {() => (
      <FormLayout wrapperCol={{ span: 24 }} readOnly={readOnly}>
        <Row>
          <Col span={21}>
            <Tooltip title={projectPath}>
              <TreeSelectField
                readOnly={readOnly}
                name="projectId"
                required
                treeDefaultExpandAll
                onChange={onProjectChange}
                nodes={projects}
                placeholder={getTranslationKey("common", "placeholder", "project")}
                data-testid={DataTestIds.FORM_SELECT_PROJECT}
              />
            </Tooltip>
          </Col>
          <Col span={3}>
            <Tooltip
              title={
                <FormattedMessage id={getTranslationKey("messages", "tooltip", "goToProject")} />
              }
            >
              <FormButton
                onClick={onGoToClick}
                icon={<ArrowRightOutlined />}
                disabled={!readOnly}
                data-testid={DataTestIds.FORM_BUTTON_GO_TO}
              >
                <FormattedMessage id={getTranslationKey("commandBar", "goTo")} />
              </FormButton>
            </Tooltip>
          </Col>
        </Row>
      </FormLayout>
    )}
  </FormItemWrapper>
);
