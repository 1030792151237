import { ApplicationState } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";
import EditeableArea from "@ea/shared_components/EditeableArea/EditeableArea";
import { FilterOption } from "@ea/shared_components/Form/Form.common";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import ManyToMany from "@ea/shared_components/Table/ManyToMany";
import { isSame } from "@ea/shared_components/utils/array";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { getVirtualUsersTableActions } from "../virtualUsers/virtualUsers.actions";
import {
  VIRTUAL_USERS_COLUMNS,
  VIRTUAL_USERS_COLUMNS_MINIMUM,
  VIRTUAL_USERS_TABLES_CONFIG,
} from "../virtualUsers/virtualUsers.table";
import { virtualUserPoolActions } from "./virtualUserPool.actions";
import { virtualUserPoolDataSelectors } from "./virtualUserPool.selectors";

interface IVirtualUserPoolDetailsContainerProps {
  virtualUserPoolId: number;
}

interface IVirtualUserPoolDetailsContainerState {
  displayTablePersistentQuery: any;
  pools: FilterOption[];
}

const initialState = {
  pools: [],
  displayTablePersistentQuery: { id: -1 },
};

class VirtualUserPoolDetailsContainer extends React.Component<
  IVirtualUserPoolDetailsContainerProps & IConnectProps,
  IVirtualUserPoolDetailsContainerState
> {
  sourceTable: any;

  state: IVirtualUserPoolDetailsContainerState = initialState;
  editAreaContainer: any;

  async componentDidMount() {
    const { virtualUserPool, virtualUserPoolId, loadSingle } = this.props;
    const virtualUserPools = await API.getVirtualUserPool({});
    if (!virtualUserPool) {
      loadSingle({ id: virtualUserPoolId });
      this.setState({
        pools: virtualUserPools.map((g) => ({ label: g.name, value: g.id })),
      });
      return;
    }

    this.setState({
      displayTablePersistentQuery: {
        id:
          (virtualUserPool.virtualUsers || []).length > 0
            ? { inq: virtualUserPool.virtualUsers!.map((u) => u.id) }
            : -1,
      },
      pools: virtualUserPools.map((g) => ({ label: g.name, value: g.id })),
    });
  }

  async componentDidUpdate(prevProps: IVirtualUserPoolDetailsContainerProps & IConnectProps) {
    const { virtualUserPool, virtualUserPoolId } = this.props;

    if (virtualUserPoolId !== prevProps.virtualUserPoolId) {
      if (this.editAreaContainer) {
        this.editAreaContainer.cancel();
      }
    }

    if (
      (prevProps.virtualUserPool === undefined && virtualUserPool !== undefined) ||
      (prevProps.virtualUserPool &&
        virtualUserPool &&
        !isSame(virtualUserPool.virtualUsers || [], prevProps.virtualUserPool.virtualUsers || []))
    ) {
      this.setState({
        displayTablePersistentQuery: {
          id:
            (virtualUserPool.virtualUsers || []).length > 0
              ? { inq: virtualUserPool.virtualUsers!.map((u) => u.id) }
              : -1,
        },
      });
    }
  }

  onSave = async (item) => {
    const { loadSingle, virtualUserPoolId } = this.props;
    await API.editVirtualUserPool(item);
    loadSingle({ id: virtualUserPoolId });
  };

  renderFlowEdit = (props) => (
    <ManyToMany
      {...props}
      pageable
      columnsConfig={VIRTUAL_USERS_COLUMNS_MINIMUM}
      tableId={VIRTUAL_USERS_TABLES_CONFIG.VIRTUAL_USERS_POOL_ASSIGNMENT.id(
        this.props.virtualUserPoolId,
      )}
      preferencesId={VIRTUAL_USERS_TABLES_CONFIG.VIRTUAL_USERS_POOL_ASSIGNMENT.preferencesId}
      stateKey="virtualUsers"
      parentItemId={this.props.virtualUserPoolId}
      parentItemStateKey="virtualUserPool"
      tableActions={getVirtualUsersTableActions}
      loadParentItem={this.props.loadSingle}
      relationName="virtualUsers"
      onSave={this.onSave}
      dynamicFilterValues={{
        pools: this.state.pools.filter((g) => `${g.value}` !== this.props.virtualUserPoolId),
      }}
    />
  );

  renderFlowDisplay = () => (
    <ConnectedTable
      pageable
      columnsConfig={VIRTUAL_USERS_COLUMNS}
      tableId={VIRTUAL_USERS_TABLES_CONFIG.VIRTUAL_USERS_POOL_ASSIGNMENT_DISPLAY.id(
        this.props.virtualUserPoolId,
      )}
      preferencesId={
        VIRTUAL_USERS_TABLES_CONFIG.VIRTUAL_USERS_POOL_ASSIGNMENT_DISPLAY.preferencesId
      }
      stateKey={"virtualUsers"}
      tableActions={getVirtualUsersTableActions}
      persistentQuery={this.state.displayTablePersistentQuery}
      selectable={false}
    />
  );

  render() {
    return (
      <EditeableArea
        ref={(editArea) => (this.editAreaContainer = editArea)}
        renderDisplayArea={() => this.renderFlowDisplay()}
        renderEditArea={this.renderFlowEdit}
      />
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: RouteComponentProps<any>) => ({
    ...props,
    virtualUserPoolId: props.match.params.id,
    virtualUserPool: virtualUserPoolDataSelectors.getItemSelector(state, props.match.params.id),
  }),
  {
    loadSingle: virtualUserPoolActions.loadSingle,
  },
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(VirtualUserPoolDetailsContainer));
