import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { AuditTrail } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("AUDIT_TRAIL_MODULE");

export const moduleActions = createDataModuleActions<AuditTrail>(actionCreator);
export const getAuditTrailTableActions = createTableActionsGetter(
  normalizeActions(moduleActions.table),
);
export const auditTrailActions = {
  ...normalizeActions(moduleActions.data),
};
export const auditTrailActionCreators = {
  ...moduleActions,
};
