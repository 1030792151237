import { CaretRightOutlined } from "@ant-design/icons";
import { ApplicationState } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";
import {
  formatMilisecondsToSeconds,
  parseFromSecondsStringToMiliseconds,
} from "@app/utils/transformations";
import {
  CheckboxField,
  InputField,
  InputNumberField,
  RadioField,
  RadioType,
} from "@ea/shared_components";
import EditeableAreaFinal from "@ea/shared_components/EditeableArea/EditeableAreaFinal";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import {
  FEATURES,
  GlobalSettingsResolversModes,
  GLOBAL_SETTINGS_KEYS,
  ReportTemplate,
} from "@ea/shared_types/types";
import { Collapse, Tooltip } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import HideableFeature from "../common/HideableFeature";
import { globalSettingsActions } from "./globalSettings.actions";

const { Panel: CollapsePanel } = Collapse;

interface IGlobalSettingsContainerProps {}
interface IGlobalSettingsContainerState {
  templatesOptions: OptionType[];
}

class GlobalSettingsContainer extends React.Component<
  IGlobalSettingsContainerProps & IConnectProps,
  any
> {
  state = {
    templatesOptions: [],
  };

  async componentDidMount() {
    await this.loadTemplates();
  }

  onSave = async (values) => {
    const editableGlobalParams = [
      GLOBAL_SETTINGS_KEYS.PUBLISHING_CREDENTIALS_CONFIRMATION,
      GLOBAL_SETTINGS_KEYS.TIMEOUTS,
      GLOBAL_SETTINGS_KEYS.DOCUMENTATION_TEMPLATE,
      GLOBAL_SETTINGS_KEYS.REPORT_TEMPLATE,
      GLOBAL_SETTINGS_KEYS.REPORTS_LINK,
      GLOBAL_SETTINGS_KEYS.RESOLVERS_MODE,
      GLOBAL_SETTINGS_KEYS.POSITION_RESOLVER_META_SCORE_THRESHOLD,
      GLOBAL_SETTINGS_KEYS.GENERATE_RESOLVERS_ON_PLAYBACK,
    ];

    await Promise.all(
      editableGlobalParams.map(
        async (k) => await API.editGlobalSettings({ id: k, value: values[k] } as any),
      ),
    );

    this.props.actions.loadGlobalSettings();
  };

  loadTemplates = async () => {
    const templates: ReportTemplate[] = await API.getReportTemplates({});
    const createTemplatesOptions = () =>
      templates.map((l) => ({
        key: l.id,
        text: l.name,
        value: l.id,
      }));

    this.setState({
      templatesOptions: createTemplatesOptions(),
    });
  };

  render() {
    const { templatesOptions } = this.state;

    if (!this.props.settings) {
      return <FormattedMessage id={getTranslationKey("messages", "error", "loading")} />;
    }

    const editableSettings = {
      ...this.props.settings,
    };

    return (
      <EditeableAreaFinal
        onSave={this.onSave}
        initialValues={editableSettings}
        render={(props) => (
          <>
            <HideableFeature feature={FEATURES.ELECTRONIC_SIGNOFF}>
              {() => (
                <CheckboxField
                  name={GLOBAL_SETTINGS_KEYS.PUBLISHING_CREDENTIALS_CONFIRMATION}
                  label={getTranslationKey("globalSettings", "publishingCredentialsConfirmation")}
                  data-testid={DataTestIds.FORM_CHECKBOX_CREDENTIAL_CONFIRMATION}
                />
              )}
            </HideableFeature>
            <InputNumberField
              data-testid={DataTestIds.FORM_INPUT_INACTIVE_TIMEOUT}
              parser={(val: string) => {
                const parsed = parseInt(val.replace("min", ""), 10) * 1000 * 60;
                if (isNaN(parsed)) {
                  return 1 * 1000 * 60;
                }
                return parsed;
              }}
              formatter={(val: number) => `${Math.round(val / (1000 * 60))}min`}
              step={1000 * 60}
              min={1}
              name={`${GLOBAL_SETTINGS_KEYS.TIMEOUTS}.inactivityTimeout`}
              label={getTranslationKey("globalSettings", "timeouts", "inactivityTimeout")}
            />
            <InputNumberField
              step={1000}
              min={0}
              parser={parseFromSecondsStringToMiliseconds}
              formatter={formatMilisecondsToSeconds}
              name={`${GLOBAL_SETTINGS_KEYS.TIMEOUTS}.step.findElementTimeout`}
              label={getTranslationKey("globalSettings", "timeouts", "step", "findElementTimeout")}
              data-testid={DataTestIds.FORM_INPUT_FIND_ELEMENT_TIMEOUT}
            />
            <InputNumberField
              step={1000}
              min={0}
              parser={parseFromSecondsStringToMiliseconds}
              formatter={formatMilisecondsToSeconds}
              name={`${GLOBAL_SETTINGS_KEYS.TIMEOUTS}.optionalStep.findElementTimeout`}
              data-testid={DataTestIds.FORM_INPUT_OPTIONAL_FIND_ELEMENT_TIMEOUT}
              label={getTranslationKey(
                "globalSettings",
                "timeouts",
                "optionalStep",
                "findElementTimeout",
              )}
            />
            <InputNumberField
              step={1000}
              min={0}
              parser={parseFromSecondsStringToMiliseconds}
              formatter={formatMilisecondsToSeconds}
              name={`${GLOBAL_SETTINGS_KEYS.TIMEOUTS}.redirectTimeout`}
              data-testid={DataTestIds.FORM_INPUT_OPTIONAL_REDIRECT_TIMEOUT}
              label={getTranslationKey("globalSettings", "timeouts", "redirectTimeout")}
            />
            <HideableFeature feature={FEATURES.DOCUMENTATION}>
              {() => (
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined
                      rotate={isActive ? 90 : 0}
                      data-testid={DataTestIds.ICON_COLLAPSE_DOC_SETTINGS}
                    />
                  )}
                >
                  <CollapsePanel
                    header={
                      <FormattedMessage
                        id={getTranslationKey("globalSettings", "documentationSettings")}
                      />
                    }
                    key="debugFields"
                  >
                    <SelectField
                      name={GLOBAL_SETTINGS_KEYS.DOCUMENTATION_TEMPLATE}
                      label={getTranslationKey("globalSettings", "documentationTemplate")}
                      placeholder={getTranslationKey(
                        "globalSettings",
                        "selectDocumentationTemplate",
                      )}
                      options={templatesOptions}
                      data-testid={DataTestIds.FORM_SELECT_DOCUMENTATION_TEMPLATE}
                      allowClear
                    />
                    <SelectField
                      name={GLOBAL_SETTINGS_KEYS.REPORT_TEMPLATE}
                      label={getTranslationKey("globalSettings", "reportTemplate")}
                      placeholder={getTranslationKey("globalSettings", "selectReportTemplate")}
                      options={templatesOptions}
                      data-testid={DataTestIds.FORM_SELECT_REPORT_TEMPLATE}
                      allowClear
                    />
                  </CollapsePanel>
                </Collapse>
              )}
            </HideableFeature>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined
                  rotate={isActive ? 90 : 0}
                  data-testid={DataTestIds.ICON_COLLAPSE_REPORT_SETTINGS}
                />
              )}
            >
              <CollapsePanel
                header={
                  <FormattedMessage id={getTranslationKey("globalSettings", "powerBiSettings")} />
                }
                key="reportsFields"
              >
                <InputField
                  name={GLOBAL_SETTINGS_KEYS.REPORTS_LINK}
                  defaultValue={""}
                  label={getTranslationKey("globalSettings", "powerBiSettings")}
                  placeholder={getTranslationKey(
                    "globalSettings",
                    "placeholder",
                    "powerBiPlaceholder",
                  )}
                  data-testid={DataTestIds.FORM_INPUT_REPORT}
                  allowClear
                />
              </CollapsePanel>
            </Collapse>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined
                  rotate={isActive ? 90 : 0}
                  data-testid={DataTestIds.ICON_COLLAPSE_ELEMENT_DETECTION_SETTINGS}
                />
              )}
            >
              <CollapsePanel
                header={
                  <FormattedMessage id={getTranslationKey("globalSettings", "elementDetection")} />
                }
                key="elementDetection"
              >
                <Tooltip
                  title={
                    <div>
                      <div>
                        <FormattedMessage
                          id={getTranslationKey("globalSettings", "resolvers", "info", "intro")}
                        />
                      </div>
                      <br></br>
                      <br></br>
                      <div>
                        <FormattedMessage
                          id={getTranslationKey(
                            "globalSettings",
                            "resolvers",
                            "info",
                            GlobalSettingsResolversModes.SMART,
                          )}
                        />
                      </div>
                      <br></br>
                      <div>
                        <FormattedMessage
                          id={getTranslationKey(
                            "globalSettings",
                            "resolvers",
                            "info",
                            GlobalSettingsResolversModes.FALLBACK,
                          )}
                        />
                      </div>
                      <br></br>
                      <div>
                        <FormattedMessage
                          id={getTranslationKey(
                            "globalSettings",
                            "resolvers",
                            "info",
                            GlobalSettingsResolversModes.LEGACY,
                          )}
                        />
                      </div>
                    </div>
                  }
                >
                  <RadioField
                    name={GLOBAL_SETTINGS_KEYS.RESOLVERS_MODE}
                    data-testid={DataTestIds.FORM_INPUT_RESOLVERS_MODE}
                    label={getTranslationKey("globalSettings", "resolvers", "mode")}
                    type={RadioType.Button}
                    options={[
                      {
                        text: getTranslationKey(
                          "globalSettings",
                          "resolvers",
                          "modes",
                          GlobalSettingsResolversModes.SMART,
                        ),
                        value: GlobalSettingsResolversModes.SMART,
                      },
                      {
                        text: getTranslationKey(
                          "globalSettings",
                          "resolvers",
                          "modes",
                          GlobalSettingsResolversModes.FALLBACK,
                        ),
                        value: GlobalSettingsResolversModes.FALLBACK,
                      },
                      {
                        text: getTranslationKey(
                          "globalSettings",
                          "resolvers",
                          "modes",
                          GlobalSettingsResolversModes.LEGACY,
                        ),
                        value: GlobalSettingsResolversModes.LEGACY,
                      },
                    ]}
                  />
                </Tooltip>
                <Tooltip
                  title={
                    <FormattedMessage
                      id={getTranslationKey(
                        "globalSettings",
                        "resolvers",
                        "positionResolverThresholdInfo",
                      )}
                    />
                  }
                >
                  <InputNumberField
                    name={GLOBAL_SETTINGS_KEYS.POSITION_RESOLVER_META_SCORE_THRESHOLD}
                    data-testid={DataTestIds.FORM_INPUT_POSITION_RESOLVER_META_SCORE_THRESHOLD}
                    label={getTranslationKey(
                      "globalSettings",
                      "resolvers",
                      "positionResolverThreshold",
                    )}
                    min={1}
                  />
                </Tooltip>
                <Tooltip
                  title={
                    <FormattedMessage
                      id={getTranslationKey(
                        "globalSettings",
                        "resolvers",
                        "generateOnPlaybackInfo",
                      )}
                    />
                  }
                >
                  <CheckboxField
                    name={GLOBAL_SETTINGS_KEYS.GENERATE_RESOLVERS_ON_PLAYBACK}
                    data-testid={DataTestIds.FORM_INPUT_GENERATE_RESOLVERS_ON_PLAYBACK}
                    label={getTranslationKey("globalSettings", "resolvers", "generateOnPlayback")}
                  />
                </Tooltip>
              </CollapsePanel>
            </Collapse>
          </>
        )}
      />
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState) => ({
    settings: state.globalSettings,
  }),
  (dispatch: Dispatch) => ({
    actions: {
      ...bindActionCreators(globalSettingsActions, dispatch),
    },
  }),
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(GlobalSettingsContainer);
