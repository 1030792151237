import { Menu } from "antd";
import styled from "@emotion/styled";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { RouteProps } from "react-router";
import { Link } from "react-router-dom";

import UserOptionsContainer from "@app/modules/users/UserOptions.container";
import { ROUTES } from "@app/routes";
import { NAVBAR_HEIGHT, Z_INDEXES } from "@app/styles/consts";

import { ROLES, FEATURES } from "@ea/shared_types/types";
import { DataTestIds } from "../../../utils/dataTestIds";

import WithAuthorization from "../WithAuthorization";
import { getVersionInfoLogo } from "@app/utils/version";
import { COLORS } from "@ea/shared_components/styles/consts";
import { NAVBAR_COLOR, NAVBAR_LOGO } from "@app/styles/styles";
import { ApplicationState } from "@ea/shared_components/redux/state";
import { currentUserRolesSelector } from "@ea/shared_components/auth/auth.selectors";
import { connect, ConnectedProps } from "react-redux";
import { getDefaultRoute } from "@app/utils/routing";
import {
  ProjectOutlined,
  MenuUnfoldOutlined,
  ScheduleOutlined,
  FileTextOutlined,
  HddOutlined,
  SettingOutlined,
  ReadOutlined,
} from "@ant-design/icons";
import { HideWhenDocumentationWizardHOC } from "../HideWhenDocumentationWizard";
import HideableFeature from "../HideableFeature";
import { ContactOptions } from "./ContactOptions";
interface INavProps {
  logout: () => any;
}

const MenuContainer = styled.div({
  width: "100%",
});

const Container = styled.div({
  position: "fixed",
  top: 0,
  width: "100%",
  height: NAVBAR_HEIGHT,
  backgroundColor: NAVBAR_COLOR,
  color: COLORS.WHITE,
  display: "flex",
  zIndex: Z_INDEXES.NAVBAR,
  justifyContent: "space-between",
});

const History = HideWhenDocumentationWizardHOC(
  WithAuthorization([ROLES.logs], [ROLES.documentationReader])(Menu.Item),
);
const Scheduler = HideWhenDocumentationWizardHOC(
  WithAuthorization([ROLES.scheduler], [ROLES.documentationReader])(Menu.Item),
);
const Reports = WithAuthorization([ROLES.reports])(Menu.Item);
const Settings = WithAuthorization([ROLES.settings], [ROLES.documentationReader])(Menu.Item);
const Projects = WithAuthorization([ROLES.projects])(Menu.Item);
const Scripts = WithAuthorization([], [ROLES.documentationReader])(Menu.Item);
const Documentation = WithAuthorization(
  [ROLES.projects, ROLES.documentationReader, ROLES.documentationCreator],
  [],
)(Menu.Item);

const Nav: React.FunctionComponent<INavProps & RouteProps & IConnectProps> = (props) => {
  const chunks = location.pathname.split("/");

  const MenuItems = [
    ...[],
    <Projects
      key="projects"
      className={process.env.REACT_APP_STYLE}
      data-testid={DataTestIds.NAV_PROJECTS}
    >
      <Link to={ROUTES.projects}>
        <ProjectOutlined />
        <FormattedMessage id={getTranslationKey("topbar", "projects")} />
      </Link>
    </Projects>,
    <Scripts
      key="scripts"
      className={process.env.REACT_APP_STYLE}
      data-testid={DataTestIds.NAV_SCRIPTS}
    >
      <Link to={ROUTES.scripts}>
        <MenuUnfoldOutlined />
        <FormattedMessage id={getTranslationKey("topbar", "scripts")} />
      </Link>
    </Scripts>,
    <Scheduler
      key="scheduler"
      className={process.env.REACT_APP_STYLE}
      data-testid={DataTestIds.NAV_SCHEDULER}
    >
      <Link to={ROUTES.scheduler}>
        <ScheduleOutlined />
        <FormattedMessage id={getTranslationKey("topbar", "scheduler")} />
      </Link>
    </Scheduler>,
    <HideableFeature
      feature={FEATURES.DOCUMENTATION}
      key="documentation_tree"
      data-testid={DataTestIds.NAV_DOCUMENTATION_TREE}
    >
      {(p) => (
        <Documentation {...p} className={process.env.REACT_APP_STYLE}>
          <Link to={ROUTES.documentation_tree}>
            <FileTextOutlined />
            <FormattedMessage id={getTranslationKey("topbar", "documentation")} />
          </Link>
        </Documentation>
      )}
    </HideableFeature>,
    <History
      key="history"
      className={process.env.REACT_APP_STYLE}
      data-testid={DataTestIds.NAV_HISTORY}
    >
      <Link to={ROUTES.history}>
        <HddOutlined />
        <FormattedMessage id={getTranslationKey("topbar", "history")} />
      </Link>
    </History>,
    <Reports
      key="reports"
      className={process.env.REACT_APP_STYLE}
      data-testid={DataTestIds.NAV_REPORTS}
    >
      <Link to={ROUTES.reports}>
        <ReadOutlined />
        <FormattedMessage id={getTranslationKey("topbar", "reports")} />
      </Link>
    </Reports>,
    <Settings
      key="settings"
      className={process.env.REACT_APP_STYLE}
      data-testid={DataTestIds.NAV_SETTINGS}
    >
      <Link to={ROUTES.settings}>
        <SettingOutlined />
        <FormattedMessage id={getTranslationKey("topbar", "settings")} />
      </Link>
    </Settings>,
  ];

  return (
    <Container>
      <Link to={getDefaultRoute(props.userRoles)}>
        <div style={{ display: "inline-block", height: "40px", padding: "3px" }}>
          <img
            src={NAVBAR_LOGO}
            title={getVersionInfoLogo()}
            style={{
              height: "100%",
              width: "auto",
            }}
          />
        </div>
      </Link>
      <MenuContainer>
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[chunks.length > 0 && chunks[1] !== "" ? chunks[1] : "scripts"]}
          style={{ lineHeight: "40px", backgroundColor: NAVBAR_COLOR }}
        >
          {MenuItems}
        </Menu>
      </MenuContainer>
      <ContactOptions />
      <UserOptionsContainer />
    </Container>
  );
};

const connectCreator = connect((state: ApplicationState) => ({
  userRoles: currentUserRolesSelector(state),
}));

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(Nav);
