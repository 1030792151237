import * as React from "react";
import ConnectedTable from "./ConnectedTable";
import { EditeableAreaComponent } from "../EditeableArea/EditeableArea";
import FlowEdit from "./FlowEdit";
import { connect, ConnectedProps } from "react-redux";
import { Item, PlainObject } from "@ea/shared_types/types";
import { isSame } from "../utils/array";
import { createDataSelectors } from "../redux/common.selectors";
import { ColumnConfig } from "./common.tables";
import { CallableTableActions } from "../redux/reducers/common.data.reducer";
import { bindActionCreators, Dispatch } from "redux";
import ClientTable from "./ClientTable";
import { SelectMode } from "../redux/common.models";

interface IManyToManyProps<T extends Item, DataApplicationState> {
  pageable: boolean;
  columnsConfig: ColumnConfig<T>[];
  tableId: string;
  preferencesId: string;
  parentItemId: number;
  stateKey: Extract<keyof DataApplicationState, string>;
  parentItemStateKey: Extract<keyof DataApplicationState, string>;
  tableActions: (id: string) => CallableTableActions<T>;
  loadParentItem: any;
  relationName: string;
  onSave: (parentItem: T) => void;
  setRef: (element: EditeableAreaComponent) => any;
  dynamicFilterValues?: PlainObject<any>;
}

interface IManyToManyState {
  selectedOutputItems: number[]; // selected in output table (checkboxes)
  outputItems: any[]; // datasource for output table
  sourcePersistentQuery: any;
}

const initialState = {
  selectedOutputItems: [],
  outputItems: [],
  sourcePersistentQuery: undefined,
};

class ManyToMany<T extends Item, K>
  extends React.Component<IManyToManyProps<T, K> & IConnectProps, IManyToManyState>
  implements EditeableAreaComponent
{
  sourceTable: any;
  state: IManyToManyState = initialState;
  flowEdit: any;

  async componentDidMount() {
    const { parentItem, parentItemId, loadParentItem, relationName } = this.props;
    this.props.setRef(this);

    if (!parentItem) {
      loadParentItem({ id: parentItemId });
      return;
    }

    this.setState({
      outputItems: parentItem[relationName].slice(),
      sourcePersistentQuery: {
        id: {
          nin: parentItem[relationName].map((i) => i.id),
        },
      },
    });
  }

  async componentDidUpdate(prevProps: IManyToManyProps<T, K> & IConnectProps) {
    const { parentItem, relationName } = this.props;

    if (
      (prevProps.parentItem === undefined && parentItem !== undefined) ||
      (prevProps.parentItem &&
        parentItem &&
        !isSame(parentItem[relationName], prevProps.parentItem[relationName]))
    ) {
      this.setState({
        outputItems: parentItem[relationName].slice(),
        sourcePersistentQuery: {
          id: {
            nin: parentItem[relationName].map((i) => i.id),
          },
        },
      });
    }
  }

  reloadSource = () => {
    if (this.sourceTable && this.sourceTable.reload) {
      this.sourceTable.reload();
    }
  };

  getSelectedFromActiveTable = () =>
    this.props.sourceItems.filter(
      (parentItem) => this.props.selectedSourceItems.indexOf(parentItem.id) !== -1,
    );

  unselectAll = () => {
    this.props.actions.select({
      mode: SelectMode.Replace,
      ids: [],
    });
  };

  save = async () => {
    const { parentItem: parentItem, relationName } = this.props;
    const { outputItems } = this.state;
    this.unselectAll();
    await this.props.onSave({
      [relationName]: outputItems.map((i) => i.id),
      id: parentItem.id,
    });
  };

  cancel = () => {
    this.unselectAll();
  };

  onAdd = () => {
    const { sourceItems, selectedSourceItems } = this.props;
    const { outputItems } = this.state;

    const newOutputItems = outputItems.concat(
      sourceItems
        .filter((parentItem) => selectedSourceItems.indexOf(parentItem.id) !== -1)
        .map((parentItem) => ({ ...parentItem })),
    );

    this.setState({
      outputItems: newOutputItems.slice(),
      sourcePersistentQuery: {
        id: {
          nin: newOutputItems.map((parentItem) => parentItem.id),
        },
      },
    });
    this.reloadSource();
    this.unselectAll();
  };

  onRemove = () => {
    const { selectedOutputItems, outputItems } = this.state;

    const newOutputItems = outputItems.filter(
      (parentItem) => selectedOutputItems.indexOf(parentItem.id) === -1,
    );
    this.setState({
      outputItems: newOutputItems.slice(),
      selectedOutputItems: [],
      sourcePersistentQuery: {
        id: {
          nin: newOutputItems.map((parentItem) => parentItem.id),
        },
      },
    });
    this.reloadSource();
  };

  onOutputTableSelect = (selected) => {
    this.setState({
      selectedOutputItems: selected,
    });
  };

  renderSourceTable = () => (
    <ConnectedTable
      pageable
      key={this.props.tableId}
      columnsConfig={this.props.columnsConfig}
      tableId={this.props.tableId}
      preferencesId={this.props.preferencesId}
      stateKey={this.props.stateKey}
      tableActions={this.props.tableActions}
      persistentQuery={this.state.sourcePersistentQuery}
      setRef={(component) => (this.sourceTable = component)}
      dynamicFilterValues={this.props.dynamicFilterValues}
    />
  );

  renderOutputTable = () => (
    <ClientTable
      columnsConfig={this.props.columnsConfig}
      data={this.state.outputItems}
      pageable
      onSelect={this.onOutputTableSelect}
    />
  );

  renderFlowEdit = () => (
    <FlowEdit
      selected={this.getSelectedFromActiveTable()}
      selectedOutputItems={this.state.selectedOutputItems as any}
      renderSourceTables={this.renderSourceTable}
      renderOutputTable={this.renderOutputTable}
      onAdd={this.onAdd}
      onRemove={this.onRemove}
      onSave={this.save}
      onCancel={this.cancel}
      setRef={(component) => (this.flowEdit = component)}
      destinationTableTitleKey={`manyToMany.destination.${this.props.stateKey}`}
      sourceTableTitleKey={`manyToMany.source.${this.props.stateKey}`}
    />
  );

  render() {
    return this.props.parentItem ? this.renderFlowEdit() : null;
  }
}

const mapStateToProps = (state: any, props: IManyToManyProps<any, any>) => {
  const { stateKey, tableId, parentItemStateKey } = props;
  const dataSelectors = createDataSelectors<any, any>()()(stateKey);
  const parentItemDataSelectors = createDataSelectors<any, any>()()(parentItemStateKey);
  return {
    parentItem: parentItemDataSelectors.getItemSelector(state, props.parentItemId),
    selectedSourceItems: dataSelectors.getSelectedSelector(state, tableId),
    sourceItems: dataSelectors.getOrderedDataSelector(state, tableId),
    ...props,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, props: IManyToManyProps<any, any>) => ({
  actions: {
    ...bindActionCreators(props.tableActions(props.tableId), dispatch),
    loadParentItem: props.loadParentItem,
  },
});

const connectCreator = connect(mapStateToProps, mapDispatchToProps);

type IConnectProps = ConnectedProps<typeof connectCreator>;

// @ts-ignore
export default connectCreator<any>(ManyToMany);
