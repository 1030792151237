import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";

import InputField from "@ea/shared_components/Form/Fields/InputField";
import { getIn } from "final-form";
import { injectIntl, InjectedIntlProps } from "react-intl";
import validators from "@ea/shared_components/Form/validators";

interface IUserPasswordFormProps {
  readOnly?: boolean;
  values: any;
  change: (name: string, value: any) => void;
  adminMode?: boolean;
}

interface IUserPasswordFormState {}

type Props = IUserPasswordFormProps & InjectedIntlProps;

class UserPasswordForm extends React.Component<Props, IUserPasswordFormState> {
  render() {
    const { readOnly, adminMode, values, change, intl } = this.props;
    const { passwordsDifferentValidator, passwordPolicyValidator } = validators(intl);

    const newPassword = getIn(values, "newPassword");
    return (
      <>
        {!adminMode && (
          <InputField
            name="currentPassword"
            required
            readOnly={readOnly}
            placeholder={getTranslationKey("user", "placeholder", "currentPassword")}
            label={getTranslationKey("password", "currentPassword")}
            type="password"
          />
        )}
        <InputField
          name="newPassword"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("user", "placeholder", "newPassword")}
          label={getTranslationKey("password", "newPassword")}
          type="password"
          onChange={() => {
            change("repeatedNewPassword", undefined);
          }}
          validate={passwordPolicyValidator}
        />
        <InputField
          name="repeatedNewPassword"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("user", "placeholder", "repeatedNewPassword")}
          label={getTranslationKey("password", "repeatPassword")}
          type="password"
          validate={passwordsDifferentValidator(newPassword)}
        />
      </>
    );
  }
}

export default injectIntl(UserPasswordForm);
