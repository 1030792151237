import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { VirtualUserPool } from "@ea/shared_types/types";
import { moduleActions } from "./virtualUserPool.actions";

const initialState = {
  ...getDataReducerInitialState<VirtualUserPool>(),
};

export type VirtualUserPoolReducer = typeof initialState;

const dataReducer = createDataReducer<VirtualUserPool>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
