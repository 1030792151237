import { InputNumber } from "antd";

import createFormField from "../createFormField";
import withReadonly from "./withReadonly";
import { ReadonlyFormItem } from "../Form.common";

export const InputNumberField = createFormField<
  React.ComponentProps<typeof InputNumber> & ReadonlyFormItem
>(
  withReadonly(InputNumber),
  ({ name, onChange, value, onBlur, onFocus, ...rest }) => ({
    ...rest,
    name,
    onChange,
    value,
  }),
  () => ({
    customParse: (v) => (v === undefined || v === "" ? null : v),
  }),
);

export default InputNumberField;
