import styled from "@emotion/styled";

import createFormField from "../createFormField";
import { ReactElement } from "react";
import { ReadonlyFormItem } from "../Form.common";

const Div = styled.div(
  {
    display: "none",
  },
  ({ readOnly }: { readOnly?: boolean }) => ({}),
);

export const EmptyField = createFormField<any>(Div, (props) => props);

export default EmptyField;
