import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { Kpi } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";

export const KPIS_COLUMNS_CONFIG: PlainObject<CustomColumn<Kpi>> = {
  name: {
    label: getTranslationKey("table", "name"),
    dataIndex: "name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  description: {
    label: getTranslationKey("table", "description"),
    dataIndex: "description",
  },
};

export const KPIS_COLUMNS: ColumnConfig<Kpi>[] = [
  {
    props: KPIS_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: KPIS_COLUMNS_CONFIG.description,
    frameworkProps: {
      width: "80%",
    },
  },
];

export const KPIS_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_KPIS",
  },
};
