import { createModuleEpics } from "@app/modules/app.reducers";
import { moduleActions } from "./its.actions";
import { IssueTrackingTool } from "@ea/shared_types/types";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { ITS_COLUMNS_CONFIG } from "./its.table";
import { itsDataSelectors } from "./its.selectors";

const apiKeyModuleEpics = createModuleEpics<IssueTrackingTool>(moduleActions, {
  normalizeItem: (its) => its,
  getRequestParams: (state, tableId) => {
    const tableParams = itsDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, ITS_COLUMNS_CONFIG, {
      defaultOrder: ["createdAt DESC"],
    });
    return params;
  },
  getSingleRequestParams: (params) => {
    return params;
  },
  api: {
    createItem: API.createIts,
    deleteItem: API.deleteIts,
    editItem: API.editIts,
    getItems: API.getIts,
    getItemsCount: API.getItsCount,
  },
});

export default apiKeyModuleEpics;
