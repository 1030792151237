import { createModuleEpics, makeAsyncEpic } from "@app/modules/app.reducers";
import {
  moduleActions,
  virtualUsersActionCreators,
} from "@app/modules/virtualUsers/virtualUsers.actions";
import { virtualUsersDataSelectors } from "@app/modules/virtualUsers/virtualUsers.selectors";
import { VIRTUAL_USERS_COLUMNS_CONFIG } from "@app/modules/virtualUsers/virtualUsers.table";
import { API } from "@app/services/api/api";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { VirtualUser } from "@ea/shared_types/types";

const virtualUsersEpics = {
  ...createModuleEpics<VirtualUser>(moduleActions, {
    normalizeItem: (userGroup) => userGroup,
    getRequestParams: (state, tableId) => {
      const tableParams = virtualUsersDataSelectors.getParamsSelector(state, tableId!);
      const params = createRequestParams(tableParams, VIRTUAL_USERS_COLUMNS_CONFIG);

      return params;
    },
    getSingleRequestParams: (params) => {
      return params;
    },
    api: {
      createItem: API.createVirtualUser as any,
      deleteItem: API.deleteVirtualUser,
      editItem: API.editVirtualUser as any,
      getItems: API.getVirtualUsers as any,
      getItemsCount: API.getVirtualUsersCount,
    },
  }),
  authenticateVirtualUserDictionaryEpic: makeAsyncEpic(
    virtualUsersActionCreators.authenticate,
    (payload) => API.authenticateVirtualUser(payload),
  ),
};

export default virtualUsersEpics;
