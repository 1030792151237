import { ApplicationState } from "@app/modules/app.reducers";
import { schedulerActions } from "@app/modules/scheduler";
import { schedulerDataSelectors } from "@app/modules/scheduler/scheduler.selectors";
import styled from "@emotion/styled";
import { Button } from "antd";
import * as PropTypes from "prop-types";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouterChildContext } from "react-router";

import { API } from "@app/services/api/api";
import { DataTestIds } from "@app/utils/dataTestIds";
import delay from "@ea/shared_components/utils/delay";
import { isGanttScheduler } from "@ea/shared_types/typeGuards";
import { JOB_STATUS_TYPE } from "@ea/shared_types/types";
import debounce from "lodash.debounce";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { toast, Zoom } from "react-toastify";
import { bindActionCreators, Dispatch } from "redux";

interface IScriptDetailsProps {
  schedulerJobId: number;
}

const Container = styled.div({});

interface IScriptDetailsState {}

const ButtonsBox = styled.div({
  "& > button": {
    marginRight: "10px",
  },
});

const RunButton = styled(Button)({
  width: "100px",
  backgroundColor: "#4dc3ff",
  color: "white",
  ":hover,:active,:focus": {
    backgroundColor: "#0099e6",
  },
}) as any as typeof Button;

// todo: we do casting here because of the problems with emotion and typescript. This pr should fix it: https://github.com/emotion-js/emotion/pull/1514
const ActivateButton = styled(Button)({
  width: "100px",
  backgroundColor: "#f6ffed",
  borderColor: "#b7eb8f",
  color: "#52c41a",
  ":hover,:active,:focus": {
    color: "#f6ffed",
    backgroundColor: "#b7eb8f",
    borderColor: "#b7eb8f",
  },
}) as any as typeof Button;

// todo: we do casting here because of the problems with emotion and typescript. This pr should fix it: https://github.com/emotion-js/emotion/pull/1514
const DeactivateButton = styled(Button)({
  width: "100px",
}) as any as typeof Button;

class SchedulerJobActionsContainer extends React.Component<
  IConnectProps & InjectedIntlProps,
  IScriptDetailsState
> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state: IScriptDetailsState = {};

  context: RouterChildContext<any>;

  constructor(props) {
    super(props);

    this.onRunNow = debounce(this.onRunNow, 3000);
  }

  onRunNow = async () => {
    const { schedulerJob } = this.props;
    const toastId = toast.info(`Running ${schedulerJob.name}...`, {
      autoClose: false,
    });

    try {
      await API.runNowScheduler({ schedulerId: schedulerJob.id });

      // new api is too fast
      await delay(1000);

      toast.update(toastId, {
        type: "success",
        render: `${schedulerJob.name} is running`,
        transition: Zoom,
        autoClose: 5000,
      });
    } catch (error) {
      toast.update(toastId, {
        type: "error",
        render: `Error while starting ${schedulerJob.name}`,
        transition: Zoom,
        autoClose: 5000,
      });
    }
  };

  onActivate = async () => {
    const { schedulerJob } = this.props;
    const newStatus =
      schedulerJob.status === JOB_STATUS_TYPE.INACTIVE
        ? JOB_STATUS_TYPE.ACTIVE
        : JOB_STATUS_TYPE.INACTIVE;
    await API.editSchedulerJob({ ...schedulerJob, status: newStatus } as any); // todo: NEW_TYPES
    this.props.loadSingle({ id: this.props.schedulerJobId });
  };

  render() {
    const { schedulerJob } = this.props;

    if (!schedulerJob) {
      return null;
    }

    const { status } = schedulerJob;

    const disabled =
      !isGanttScheduler(schedulerJob) && schedulerJob.schedulerJobMapping.length === 0;

    const Buttons: any[] = [];
    if (status !== JOB_STATUS_TYPE.CLOSED) {
      Buttons.push(
        <RunButton key="runNow" type="primary" disabled={disabled} onClick={this.onRunNow}>
          {this.props.intl.formatMessage({ id: "scheduler.runNow" })}
        </RunButton>,
      );
    }

    if (
      status === JOB_STATUS_TYPE.INACTIVE ||
      status === JOB_STATUS_TYPE.ACTIVE ||
      status === JOB_STATUS_TYPE.RUNNING
    ) {
      Buttons.push(
        status === JOB_STATUS_TYPE.INACTIVE ? (
          <ActivateButton
            key="activate"
            type="primary"
            onClick={this.onActivate}
            disabled={disabled}
            data-testid={DataTestIds.BUTTON_ACTIVATE}
          >
            {this.props.intl.formatMessage({ id: "scheduler.activate" })}
          </ActivateButton>
        ) : (
          <DeactivateButton
            key="deactivate"
            danger
            onClick={this.onActivate}
            data-testid={DataTestIds.BUTTON_DEACTIVATE}
          >
            {this.props.intl.formatMessage({ id: "scheduler.deactivate" })}
          </DeactivateButton>
        ),
      );
    }

    return (
      <Container>
        <ButtonsBox>{Buttons}</ButtonsBox>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(schedulerActions, dispatch);

const connectCreator = connect(
  (state: ApplicationState, props: IScriptDetailsProps) => ({
    ...props,
    schedulerJob: schedulerDataSelectors.getItemSelector(state, props.schedulerJobId),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(injectIntl(SchedulerJobActionsContainer));
