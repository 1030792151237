import {
  getDataReducerInitialState,
  createDataReducer,
} from "@ea/shared_components/redux/reducers/common.data.reducer";
import { Script } from "@ea/shared_types/types";
import { moduleActions, scriptsActionCreators } from "./scripts.actions";

const initialState = {
  ...getDataReducerInitialState<Script>(),
};

export type ScriptsReducer = typeof initialState;

const dataReducer = createDataReducer<Script>({
  ...moduleActions.table,
  ...moduleActions.data,
});

const scriptDataReducer = dataReducer(initialState);

export const reducer = scriptDataReducer.case(
  scriptsActionCreators.loadGroupsCounter.done,
  (state, payload) => ({
    ...state,
  }),
);
