import { getTranslationKey } from "@app/translations/translations.helpers";
import * as React from "react";

import { DataTestIds } from "@app/utils/dataTestIds";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";

interface ITagFormProps {
  readOnly?: boolean;
  values: any;
  change: any;
}

class TagForm extends React.Component<ITagFormProps, any> {
  render() {
    const { readOnly } = this.props;

    return (
      <>
        <InputField
          name="name"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("common", "placeholder", "name")}
          label={getTranslationKey("common", "label", "name")}
          parse={(value) => value.trimStart()}
          data-testid={DataTestIds.FORM_INPUT_NAME}
        />
        <TextAreaField
          name="description"
          defaultValue={""}
          placeholder={getTranslationKey("common", "placeholder", "description")}
          readOnly={readOnly}
          autoSize={{ minRows: 2, maxRows: 6 }}
          label={getTranslationKey("common", "label", "description")}
          data-testid={DataTestIds.FORM_INPUT_DESCRIPTION}
        />
      </>
    );
  }
}

export default TagForm;
