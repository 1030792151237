import styled from "@emotion/styled";
import * as React from "react";

import { COLORS } from "../styles/consts";
import { DataTestIdProp } from "../utils/dataTestHelpers";

interface ICommandBarProps {
  style?: any;
  [DataTestIdProp]?: string;
}

const CommandBarContainer = styled.div({
  height: "40px",
  minHeight: "40px",
  maxHeight: "40px",
  width: "100%",
  display: "flex",
  backgroundColor: COLORS.GRAY_OVERLAY,
});

const CommandBar: React.FunctionComponent<ICommandBarProps> = (props) => {
  return (
    <CommandBarContainer data-testid={props[DataTestIdProp]} style={props.style}>
      {props.children}
    </CommandBarContainer>
  );
};

export default CommandBar;
