import { LayoutContext } from "antd/lib/layout/layout";
import { Row, Col } from "antd";
import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { ColProps } from "antd/lib/col";

const HeaderText = styled.h4({
  textAlign: "right",
  marginRight: "15px",
  fontWeight: "bold",
  marginTop: "15px",
});

export const FormHeader = ({
  text,
  colProps,
}: {
  text: string | ReactNode;
  colProps?: ColProps;
}) => (
  <LayoutContext.Consumer>
    {(value) => (
      <Row>
        <Col {...colProps}>
          <HeaderText> {text} </HeaderText>
        </Col>
      </Row>
    )}
  </LayoutContext.Consumer>
);
