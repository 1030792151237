import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";
import EditeableAreaFinal from "@ea/shared_components/EditeableArea/EditeableAreaFinal";
import { authActions } from "../auth/auth.actions";
import PlayModeForm from "@app/modules/common/components/PlayModeForm";
import RecordModeForm from "@app/modules/common/components/RecordModeForm";
import { User, UserSettings, PLAY_MODE } from "@ea/shared_types/types";
import { getIn } from "final-form";

interface IUserExecutionSettingsContainerProps {}
interface IUserExecutionSettingsContainerState {}

class UserExecutionSettingsContainer extends React.Component<
  IUserExecutionSettingsContainerProps & IConnectProps,
  IUserExecutionSettingsContainerState
> {
  editSettings = async (values) => {
    const { user } = this.props;

    const editedUser = await API.editUser({
      ...(user as User),
      settings: { ...(user as User).settings, ...(values as UserSettings) },
    });

    this.props.initDone({
      params: {
        isLoggedIn: true,
      },
      result: { roles: this.props.user!.roles, ...editedUser },
    });
  };

  render() {
    const { user } = this.props;
    return user && user.settings ? (
      <EditeableAreaFinal
        onSave={this.editSettings}
        initialValues={user.settings}
        onCancel={() => {}}
        render={({ change, values, readOnly }) => (
          <>
            <PlayModeForm
              values={values}
              change={change}
              readOnly={readOnly}
              displayRunnerOptions={false}
            />
            {getIn(values, "playMode") !== PLAY_MODE.BACKGROUND && (
              <RecordModeForm values={values} change={change} readOnly={readOnly} />
            )}
          </>
        )}
      />
    ) : null;
  }
}

const connectCreator = connect(
  (state: ApplicationState) => ({
    user: state.auth.user,
  }),
  {
    ...authActions,
  },
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(UserExecutionSettingsContainer);
