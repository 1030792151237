import * as React from "react";
import { List } from "antd";
import { Step, RecorderStep, PlayerStep } from "@ea/shared_types/types";
import { withTranslation, WithTranslation } from "react-i18next";
import { getStepLabelParams } from "../steps.utils";

interface IRenderStepLabelProps {
  step: Step<any> | RecorderStep<any> | PlayerStep<any> | undefined;
  children: (text: string) => React.ReactNode;
}

class RenderStepLabel extends React.Component<IRenderStepLabelProps & WithTranslation, any> {
  render() {
    const { t, step, children } = this.props;

    return children(step ? step.label || t(step.labelKey!, getStepLabelParams(step)) : "");
  }
}

export default withTranslation()(RenderStepLabel);
