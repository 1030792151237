import styled from "@emotion/styled";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { withRouter, RouteComponentProps } from "react-router";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { getScriptsTableActions } from "@app/modules/scripts/scripts.actions";
import { SCRIPTS_TABLES_CONFIG, SCRIPTS_COLUMNS } from "@app/modules/scripts/scripts.tables";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "../app.reducers";
import { scriptsDataSelectors, scriptsSelectorsModifier } from "./scripts.selectors";
import { disabledFeaturesSelector } from "../globalSettings/globalSettings.selectors";
import { getScriptColumnsRenderers } from "@app/modules/scripts/ScriptColumnsRenderers";
import { overrideColumnProps } from "@ea/shared_components/Table/common.tables";
import { Script } from "@ea/shared_types/types";
import { projectSelectors } from "../projects/projects.selectors";
import { ColumnConfig } from "@ea/shared_components/Table/common.tables";
import { DataTestIds } from "@app/utils/dataTestIds";
import { API } from "@app/services/api/api";

const Wrapper = styled.div({
  width: "100%",
  height: "100%",
});

interface IScriptLinkedInProps {
  scriptId: number;
}

interface IScriptLinkedInState {
  persistentQuery: any;
  columns: ColumnConfig<Script>[];
}

class ScriptLinkedIn extends React.Component<
  IScriptLinkedInProps & IConnectProps & RouteComponentProps<any>,
  IScriptLinkedInState
> {
  constructor(props) {
    super(props);

    const scriptRenderers = getScriptColumnsRenderers({ projectsMap: props.projectsMap });
    const columns = overrideColumnProps<Script>(SCRIPTS_COLUMNS, [
      {
        dataIndex: "envType",
        frameworkProps: {
          render: (_, record) => scriptRenderers.envTypeRenderer(record),
        },
      },
      {
        dataIndex: "envName",
        frameworkProps: {
          render: (_, record) => scriptRenderers.envNameRenderer(record),
        },
      },
      {
        dataIndex: "id",
        frameworkProps: {},
        columnProps: {
          filter: undefined,
        },
      },
    ]);

    this.state = {
      persistentQuery: {},
      columns,
    };
  }

  async componentDidMount() {
    const { scriptId } = this.props;

    const steps = await API.getSteps({
      filter: { where: { linkedScriptId: scriptId } },
    });

    // if there is no steps, as a workaround search id -1 to get empty table
    this.setState({
      persistentQuery: {
        id: { inq: steps.length > 0 ? steps.map((s) => s.taskScriptId) : [-1] },
      },
    });
  }

  openScript = () => {
    if (this.props.selected) {
      this.props.history.push(`/scripts/${this.props.selected.id}`);
    }
  };

  render() {
    const { disabledFeatures } = this.props;
    const { columns } = this.state;
    return (
      <Wrapper>
        <CommandBar data-testid={DataTestIds.COMMANDBAR_SCRIPT_LINKEDIN}>
          <CommandBarButton
            name="open"
            onClick={this.openScript}
            text={getTranslationKey("commandBar", "open")}
            disabled={!this.props.selected}
            icon="LoginOutlined"
            data-testid={DataTestIds.COMMANDBAR_BUTTON_OPEN}
            far
          />
        </CommandBar>
        <ConnectedTable
          columnsConfig={columns}
          disabledFeatures={disabledFeatures}
          pageable
          modifySelectors={scriptsSelectorsModifier}
          onRowDoubleClick={this.openScript}
          tableId={SCRIPTS_TABLES_CONFIG.LINKED_SCRIPT.id()}
          preferencesId={SCRIPTS_TABLES_CONFIG.LINKED_SCRIPT.preferencesId}
          stateKey="scripts"
          tableActions={getScriptsTableActions}
          persistentQuery={this.state.persistentQuery}
          multiselect={false}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  selected: scriptsDataSelectors.getSelectedItemSelector(
    state,
    SCRIPTS_TABLES_CONFIG.LINKED_SCRIPT.id(),
  ),
  projectsMap: projectSelectors.getDataSelector(state),
  disabledFeatures: disabledFeaturesSelector(state),
});

const connectCreator = connect(mapStateToProps);

type IConnectProps = ConnectedProps<typeof connectCreator> & InjectedIntlProps;

export default connectCreator(withRouter(injectIntl(ScriptLinkedIn)));
