import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { SchedulerJob } from "@ea/shared_types/types";
import { moduleActions } from "./scheduler.actions";

const initialState = {
  ...getDataReducerInitialState<SchedulerJob>(),
};

export type SchedulerReducer = typeof initialState;

const dataReducer = createDataReducer<SchedulerJob>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
