import { objectValues } from "@ea/shared_components/utils/object";

import * as workItemActions from "./workItem.actions";
import workItemEpicsMap from "./workItem.epics";
import * as workItemReducers from "./workItem.reducers";

const workItemEpics = objectValues(workItemEpicsMap);

export { workItemActions, workItemReducers, workItemEpics };

export { default as WorkItemTableContainer } from "./WorkItemTable.container";
