import * as React from "react";
import { PanelType } from "@ea/shared_components/Panel";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import ProjectCategoryForm from "./components/ProjectCategoryForm";
import { ProjectCategory } from "@ea/shared_types/types";

interface IProjectCategoryNewPanelProps {
  visibility: boolean;
  onClose: () => void;
  onCreate: (system: ProjectCategory) => void;
  selected?: ProjectCategory;
  isEdit?: boolean;
}

class ProjectCategoryPanelContainer extends React.Component<IProjectCategoryNewPanelProps, any> {
  onOk = async (values) => {
    const { isEdit, onCreate, onClose } = this.props;

    if (isEdit) {
      await API.editProjectCategory(values);
    } else {
      const result = await API.createProjectCategory(values);

      if (onCreate) {
        onCreate(result);
      }
    }

    onClose();
  };

  render() {
    const { visibility, onClose, isEdit, selected } = this.props;

    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.SMALL}
        headerText={getTranslationKey("projectCategory", "header", isEdit ? "edit" : "create")}
        onCancelClick={onClose}
        onOkClick={this.onOk}
        key={isEdit ? "Edit" : "Create"}
        cancelButtonText={getTranslationKey("button", "cancel")}
        okButtonText={getTranslationKey("button", isEdit ? "save" : "create")}
        initialValues={isEdit ? selected : undefined}
        render={({ values }) => <ProjectCategoryForm values={values} />}
      />
    );
  }
}

export default ProjectCategoryPanelContainer;
