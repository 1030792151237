import { getIn } from "final-form";
import * as React from "react";

import Select from "antd/lib/select";

import { RadioField } from "@ea/shared_components/Form/Fields/RadioField";
import { CheckboxField } from "@ea/shared_components/Form/Fields/CheckboxField";
import { PLAY_MODE, ROLES, Script, FEATURES, RunnerMode } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";
import WithAuthorization from "../WithAuthorization";
import RunnerTypeFormChunk from "./RunnerTypeFormChunk";
import HideableFeature from "../HideableFeature";
import { DataTestIds } from "@app/utils/dataTestIds";
import SpecialExecutionModeFormChunk from "./SpecialModeFormChunk";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import { SelectField } from "@ea/shared_components/";

interface IPlayModeFormProps {
  readOnly?: boolean;
  values: any;
  change: (name: string, value: any) => void;
  script?: Script;
  displayRunnerOptions?: boolean;
  configurationOptions?: (OptionType & { project: string })[];
}
const AuthorizedCheckboxField = WithAuthorization([ROLES.admin, ROLES.logs], [])(CheckboxField);

class PlayModeForm extends React.Component<IPlayModeFormProps> {
  onPlayModeSwitchChange = (event) => {
    const { values, change } = this.props;
    for (let fieldName in values) {
      if (fieldName !== "playMode" && fieldName !== "runner") {
        change(fieldName, undefined);
      }
    }
    if (event?.target?.value === PLAY_MODE.FOREGROUND) {
      change("specialMode", null);
      change("useSpecialMode", false);
    }
  };

  filterConfigurationOptions = () => {
    const { script, configurationOptions } = this.props;

    return (configurationOptions || []).filter(
      (cO) => cO.project === script?.integrationMetadata?.azureDevops?.project,
    );
  };

  render() {
    const { readOnly, values, script, displayRunnerOptions } = this.props;
    const isForeground = getIn(values, "playMode") === PLAY_MODE.FOREGROUND;
    const isBackground = getIn(values, "playMode") === PLAY_MODE.BACKGROUND;
    const mapResultWithDevops = getIn(values, "mapResultWithDevops");

    return (
      <>
        <RadioField
          name="playMode"
          label={getTranslationKey("playMode", "playMode")}
          optionType="button"
          buttonStyle="solid"
          options={[
            {
              text: getTranslationKey("playMode", PLAY_MODE.FOREGROUND),
              value: PLAY_MODE.FOREGROUND,
            },
            {
              text: getTranslationKey("playMode", PLAY_MODE.BACKGROUND),
              value: PLAY_MODE.BACKGROUND,
            },
          ]}
          onChange={this.onPlayModeSwitchChange}
          readOnly={readOnly}
        />
        {isBackground && (
          <HideableFeature feature={FEATURES.DOCUMENTATION}>
            {() => <SpecialExecutionModeFormChunk {...this.props} />}
          </HideableFeature>
        )}
        {isForeground && (
          <>
            <CheckboxField
              name="autoStart"
              label={getTranslationKey("playMode", "autoStart")}
              readOnly={readOnly}
              data-testid={DataTestIds.FORM_CHECKBOX_AUTO_START}
            />
            <CheckboxField
              name="slowMode"
              label={getTranslationKey("playMode", "slowMode")}
              readOnly={readOnly}
              data-testid={DataTestIds.FORM_CHECKBOX_SLOW_MODE}
            />
          </>
        )}
        {script?.integrationMetadata?.azureDevops?.testCaseId && (
          <AuthorizedCheckboxField
            name="mapResultWithDevops"
            label={getTranslationKey("playMode", "mapWithDevops")}
            readOnly={readOnly}
            data-testid={DataTestIds.FORM_CHECKBOX_MAP_TO_DEVOPS}
          />
        )}
        {mapResultWithDevops && (
          <SelectField
            allowClear
            name={`azureConfigurationId`}
            label={getTranslationKey("projects", "details", "label", "configuration")}
            placeholder={getTranslationKey("projects", "details", "placeholder", "configuration")}
          >
            {this.filterConfigurationOptions().map((configuration) => (
              <Select.Option key={configuration.value} value={configuration.value}>
                {configuration.text}
              </Select.Option>
            ))}
          </SelectField>
        )}
        {displayRunnerOptions === false || isBackground ? null : (
          <RunnerTypeFormChunk {...this.props} readOnly={isBackground} mode={RunnerMode.PLAYER} />
        )}
      </>
    );
  }
}

export default PlayModeForm;
