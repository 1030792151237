import { createModuleEpics } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { VirtualUserPool } from "@ea/shared_types/types";
import { moduleActions } from "./virtualUserPool.actions";
import { virtualUserPoolDataSelectors } from "./virtualUserPool.selectors";
import { VIRTUAL_USER_POOL_COLUMNS_CONFIG } from "./virtualUserPool.table";

const virtualUserPoolInclude = {
  relation: "VirtualUser",
};

const virtualUserPoolModuleEpics = createModuleEpics<VirtualUserPool>(moduleActions, {
  normalizeItem: (virtualUserPool) => {
    return {
      ...virtualUserPool,
      virtualUsers: virtualUserPool.virtualUsers || [],
    };
  },
  getRequestParams: (state, tableId) => {
    const tableParams = virtualUserPoolDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, VIRTUAL_USER_POOL_COLUMNS_CONFIG);

    params.filter = {
      ...params.filter,
      include: virtualUserPoolInclude,
    };

    return params;
  },
  getSingleRequestParams: (query) => {
    query.filter = {
      ...query.filter,
      include: virtualUserPoolInclude,
    };
    return query;
  },
  api: {
    createItem: API.createVirtualUserPool as any,
    deleteItem: API.deleteVirtualUserPool,
    editItem: API.editVirtualUserPool as any,
    getItems: API.getVirtualUserPool as any,
    getItemsCount: API.getVirtualUserPoolCount,
  },
});

export default virtualUserPoolModuleEpics;
