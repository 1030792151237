import * as React from "react";
import { Button, Input } from "antd";
import { faEye, faEyeSlash, faCopy } from "@fortawesome/fontawesome-free-solid";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { CopyToClipboard } from "react-copy-to-clipboard";
interface ApiKeyTokenBoxProps {
  token: string;
}
interface ApiKeyTokenBoxState {
  type: string;
}

const ELEMENTS_HEIGHT = "40px";
const ApiKeyContainer = styled.div({
  height: ELEMENTS_HEIGHT,
  minHeight: ELEMENTS_HEIGHT,
  maxHeight: ELEMENTS_HEIGHT,
  width: "100%",
  display: "flex",
});

const IconButton = styled(Button)({
  height: ELEMENTS_HEIGHT,
});

class ApiKeyTokenBox extends React.Component<ApiKeyTokenBoxProps, ApiKeyTokenBoxState> {
  state: ApiKeyTokenBoxState = {
    type: "password",
  };
  constructor(props) {
    super(props);
  }
  show = () => {
    this.setState({
      type: "input",
    });
  };

  hide = () => {
    this.setState({
      type: "password",
    });
  };

  render() {
    return (
      <ApiKeyContainer>
        <Input type={this.state.type} value={this.props.token} disabled={false} />
        <CopyToClipboard text={this.props.token}>
          <IconButton>
            <FontAwesomeIcon icon={faCopy} />
          </IconButton>
        </CopyToClipboard>
        <IconButton onClick={this.state.type === "input" ? this.hide : this.show}>
          <FontAwesomeIcon icon={this.state.type === "input" ? faEyeSlash : faEye} />
        </IconButton>
      </ApiKeyContainer>
    );
  }
}

export default ApiKeyTokenBox;
