import * as React from "react";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";

interface ItsConfigurationsCommandBarProps {
  onNewClick: () => void;
  onRemoveClick: (ids: number[]) => void;
  onEditClick: () => void;
  onFetchClick: () => void;
  onReload: () => void;
  selected: any[];
  isLoading: boolean;
}

const ItsConfigurationsCommandBar: React.FunctionComponent<ItsConfigurationsCommandBarProps> = ({
  isLoading,
  onEditClick,
  onRemoveClick,
  onReload,
  selected,
  onFetchClick,
  onNewClick,
}) => {
  return (
    <CommandBar>
      <CommandBarButton
        name="fetch"
        onClick={onFetchClick}
        text={getTranslationKey("commandBar", "fetch")}
        icon="ArrowDownOutlined"
        disabled={isLoading}
        data-testid={DataTestIds.COMMANDBAR_BUTTON_CONFIGURATIONS_FETCH}
      />
      <CommandBarButton
        name="new"
        onClick={onNewClick}
        text={getTranslationKey("commandBar", "add")}
        icon="PlusOutlined"
        disabled={isLoading}
        data-testid={DataTestIds.COMMANDBAR_BUTTON_NEW}
      />
      <CommandBarButton
        name="remove"
        onClick={() => onRemoveClick(selected)}
        text={getTranslationKey("commandBar", "remove")}
        icon="DeleteOutlined"
        disabled={isLoading || selected.length === 0}
        data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE}
      />
      <CommandBarButton
        name="refresh"
        onClick={onReload}
        text={getTranslationKey("commandBar", "refresh")}
        icon="ReloadOutlined"
        disabled={isLoading}
        data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
      />
      <CommandBarButton
        name="edit"
        onClick={onEditClick}
        text={getTranslationKey("commandBar", "edit")}
        icon="EditOutlined"
        disabled={isLoading || selected.length !== 1}
        data-testid={DataTestIds.COMMANDBAR_BUTTON_EDIT}
      />
    </CommandBar>
  );
};

export default ItsConfigurationsCommandBar;
