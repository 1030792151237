import { RunnerMode, Step } from "@ea/shared_types/types";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { getStepLabelParams } from "../steps/steps.utils";
import diff from "object-diff";
import { isRootStep } from "./runner.helpers";
import { ExecutionStepTreeNode } from "@ea/shared_types/runner.common.types";

export const RUNNER_STEPS_TABLE_ID = "RUNNER_STEPS";
export const RUNNER_STEPS_COLUMNS_CONFIG: PlainObject<CustomColumn<Step>> = {
  lineNum: {
    label: "table.lineNum",
    sortable: false,
    dataIndex: "lineNum",
  },
  label: {
    label: "table.label",
    dataIndex: "label",
    sortable: false,
    editable: (step: ExecutionStepTreeNode) =>
      isRootStep(step) && step.mode === RunnerMode.RECORDER,
  },
};

export const RUNNER_STEPS_COLUMNS: ColumnConfig<Step>[] = [
  {
    props: RUNNER_STEPS_COLUMNS_CONFIG.lineNum,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: RUNNER_STEPS_COLUMNS_CONFIG.label,
    frameworkProps: {
      width: "80%",
      ellipsis: true,
      shouldCellUpdate: (record, prevRecord) => {
        const condition =
          record.label !== prevRecord.label ||
          record.labelKey !== prevRecord.labelKey ||
          record.isOptional !== prevRecord.isOptional ||
          record.isDisabled !== prevRecord.isDisabled ||
          Object.keys(diff(getStepLabelParams(record), getStepLabelParams(prevRecord))).length >
            0 ||
          record.synced !== prevRecord.synced ||
          (prevRecord as any).mode !== (record as any).mode || // TODO correct types
          (prevRecord as any).executionPath !== (record as any).executionPath ||
          (prevRecord as any).executionState !== (record as any).executionState ||
          (prevRecord as any).execution.status !== (record as any).execution.status;
        return condition;
      },
    },
  },
];

// TODO figure out how to use RUNNER_STEPS_${sessiondId} as id
export const RUNNER_STEPS_TABLES_CONFIG = {
  SESSION: {
    id: (sessionId) => `${sessionId}`,
    preferencesId: "MAIN_RUNNER_STEPS",
  },
};
