import React from "react";
import { Table } from "antd";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { KpiTableStatistics } from "@ea/shared_types/types";
import "./KpiTable.css";

interface KpiTableProps {
  data: KpiTableStatistics[];
}

const columns = [
  {
    title: <FormattedMessage id={getTranslationKey("kpi", "table", "kpiName")} />,
    dataIndex: "name",
    key: "name",
  },
  {
    title: <FormattedMessage id={getTranslationKey("kpi", "table", "totalOccurrences")} />,
    dataIndex: "totalOccurrences",
    key: "totalOccurrences",
  },
  {
    title: <FormattedMessage id={getTranslationKey("kpi", "table", "occurrencesPerMinute")} />,
    dataIndex: "occurrencesPerMinute",
    key: "occurrencesPerMinute",
    render: (_, record) => record.occurrencesPerMinute?.toFixed(2),
  },
  {
    title: <FormattedMessage id={getTranslationKey("kpi", "table", "occurrencesPerHour")} />,
    dataIndex: "occurrencesPerHour",
    key: "occurrencesPerHour",
    render: (_, record) => record.occurrencesPerHour?.toFixed(2),
  },
];

export const KpiTable = ({ data }: KpiTableProps) => {
  return <Table columns={columns} dataSource={data} pagination={false} scroll={{ y: 240 }} />;
};
