import * as React from "react";
import { API } from "@app/services/api/api";
import {
  License,
  PayPerUseCounter,
  PayPerUseStatisticsPrecision,
  PayPerUseStatistic,
} from "@ea/shared_types/types";
import styled from "@emotion/styled";
import { DatePicker } from "antd";
import PayPerUseBarChart from "./PayPerUseBarChart";
import { Select, Button } from "antd";
import { FormattedMessage, injectIntl, InjectedIntlProps } from "react-intl";
import { toast } from "react-toastify";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";
import ReactPDF from "@react-pdf/renderer";
import html2canvas from "html2canvas";
import PayPerUseStatisticsPDF from "./PayPerUsePdf";
import { downloadFileBlob } from "@ea/shared_components/helpers/file";
import { PPU_CHART_ID } from "./PayPerUseBarChart";

const { Option } = Select;
const { RangePicker } = DatePicker;

const ChartSize = {
  width: 730,
  height: 250,
};
interface IPayPerUseStatisticsProps {
  license: License;
}
interface IPayPerUseStatisticsState {
  license?: License;
  payPerUseCounter?: PayPerUseCounter;
  statisticsPrecision: PayPerUseStatisticsPrecision;
  statisticsData: PayPerUseStatistic[];
}

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  rowGap: "20px",
});

const RangeButtonsContainer = styled.div({
  display: "flex",
  flexDirection: "row",
});

class PayPerUseStatistics extends React.Component<
  IPayPerUseStatisticsProps & InjectedIntlProps,
  IPayPerUseStatisticsState
> {
  state: IPayPerUseStatisticsState = {
    license: undefined,
    payPerUseCounter: undefined,
    statisticsData: [],
    statisticsPrecision: PayPerUseStatisticsPrecision.Months,
  };

  onRangeChange = async (rangeValues) => {
    const { statisticsPrecision } = this.state;

    const dates = rangeValues.map((v) => v.toISOString());

    try {
      const statisticsData = await API.getPayPerUseStatistics({
        statisticsPrecision,
        dates,
      });
      this.setState({ statisticsData });
    } catch (e) {
      toast.error(
        <FormattedMessage id={getTranslationKey("messages", "error", "failedFetchStatistics")} />,
      );
    }
  };

  getOption = (key) => (
    <Option value={key} data-testid={`option-${key}`}>
      {key}
    </Option>
  );

  getPickerType = () => {
    const { statisticsPrecision } = this.state;
    switch (statisticsPrecision) {
      case PayPerUseStatisticsPrecision.Days:
        return undefined;
      case PayPerUseStatisticsPrecision.Months:
        return "month";
      case PayPerUseStatisticsPrecision.Years:
        return "year";
      default:
        return "month";
    }
  };

  getFooter = () => {
    const { intl } = this.props;
    const { formatMessage } = intl;

    return `${formatMessage({
      id: getTranslationKey("license", "generationDate"),
    })} ${new Date().toLocaleString()}`;
  };

  getPdf = async () => {
    const { intl } = this.props;
    const { formatMessage } = intl;

    const { statisticsData } = this.state;
    const from = statisticsData[0].name;
    const to = statisticsData[statisticsData.length - 1].name;
    const canvas = await this.getCanvas();
    const chartTitle = `${formatMessage({
      id: getTranslationKey("license", "payPerUse"),
    })} ${formatMessage({
      id: getTranslationKey("license", "from"),
    })} ${from} ${formatMessage({
      id: getTranslationKey("license", "to"),
    })} ${to}`;

    const blob = await ReactPDF.pdf(
      <PayPerUseStatisticsPDF
        text={this.getLicenseText()}
        title={formatMessage({
          id: getTranslationKey("license", "licenseOverview"),
        })}
        chartTitle={chartTitle}
        chart={canvas}
        footer={this.getFooter()}
      />,
    ).toBlob();
    downloadFileBlob(blob, `ppuStatistics_${from}_${to}.pdf`);
  };

  getLicensePartText = ({ value, translationId }) =>
    value
      ? `${this.props.intl.formatMessage({
          id: translationId,
        })}: ${value}`
      : "";

  getLicenseText = () => {
    const { license } = this.props;
    if (!license) {
      return "";
    }
    const {
      type,
      expirationDate,
      supportExpirationDate,
      maximumConcurrentUsers,
      maximumParallelSessions,
    } = license;

    const valuesMap = [
      { value: type, translationId: getTranslationKey("license", "licenseType") },
      {
        value: expirationDate,
        translationId: getTranslationKey("license", "licenseExpirationDate"),
      },
      {
        value: supportExpirationDate,
        translationId: getTranslationKey("license", "supportRenewalDate"),
      },
      {
        value: maximumConcurrentUsers,
        translationId: getTranslationKey("license", "maximumConcurrentUsers"),
      },
      {
        value: maximumParallelSessions,
        translationId: getTranslationKey("license", "maximumParallelSessions"),
      },
    ];
    const text = `${valuesMap.map((v) => this.getLicensePartText(v)).join("\n")}`;
    return text;
  };

  getCanvas = async () => {
    const input = document.getElementById(PPU_CHART_ID);

    if (!input) {
      return null;
    }
    const canvas = await html2canvas(input!, {
      width: ChartSize.width,
      height: ChartSize.height,
    }).then((can) => {
      const imgData = can.toDataURL("image/png") as any;
      return imgData;
    });
    return canvas;
  };

  render() {
    const { statisticsData, statisticsPrecision } = this.state;
    return (
      <Container data-testid={DataTestIds.CONTAINER_LICENSE_STATISTICS}>
        <h1>
          <FormattedMessage id={getTranslationKey("license", "statistics")} />
        </h1>
        <RangeButtonsContainer>
          <Select
            data-testid={DataTestIds.FORM_SELECT_STATISTICS_PRECISION}
            defaultValue={statisticsPrecision}
            style={{ width: 120 }}
            onChange={(selectedPrecision) => {
              this.setState({ statisticsPrecision: selectedPrecision });
            }}
          >
            {Object.values(PayPerUseStatisticsPrecision).map((key) => this.getOption(key))}
          </Select>
          <RangePicker picker={this.getPickerType()} onChange={this.onRangeChange} />
          <Button onClick={this.getPdf} disabled={statisticsData.length === 0}>
            <FormattedMessage id={getTranslationKey("button", "generatePdf")} />
          </Button>
        </RangeButtonsContainer>
        {statisticsData?.length > 0 && (
          <PayPerUseBarChart
            data={statisticsData}
            height={ChartSize.height}
            width={ChartSize.width}
          />
        )}
      </Container>
    );
  }
}

export default injectIntl(PayPerUseStatistics);
