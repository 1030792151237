import * as React from "react";
import styled from "@emotion/styled";
import { Input, Select, InputNumber } from "antd";
import { RecurrenceType } from "@ea/shared_types/types";
import { ReadonlyFormItem } from "@ea/shared_components/Form/Form.common";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { DataTestIds } from "@app/utils/dataTestIds";
interface RepeatEveryValue {
  offset: number | undefined;
  recurrenceType: RecurrenceType;
}
interface RepeatEveryInputProps extends ReadonlyFormItem {
  onChange?: (value: RepeatEveryValue) => void;
  value?: RepeatEveryValue;
  size?: "large" | "middle" | "small" | "default";
  min?: number;
}

interface RepeatEveryInputState {
  offset: number | undefined;
  recurrenceType: RecurrenceType;
}

const Container = styled.div({
  maxWidth: "200px",
});

const options = [
  RecurrenceType.minutes,
  RecurrenceType.hours,
  RecurrenceType.days,
  RecurrenceType.weeks,
  RecurrenceType.months,
  RecurrenceType.years,
];

const Option = Select.Option;

class RepeatEveryInput extends React.Component<
  RepeatEveryInputProps & InjectedIntlProps,
  RepeatEveryInputState
> {
  constructor(props: RepeatEveryInputProps & InjectedIntlProps) {
    super(props);

    const value = props.value || { offset: undefined, recurrenceType: RecurrenceType.hours };
    this.state = value;
  }
  componentWillReceiveProps(nextProps: RepeatEveryInputProps) {
    // Should be a controlled component.
    if (nextProps.value) {
      const value = nextProps.value;
      this.setState(value);
    }
  }

  handleRepeatEveryChange = (e) => {
    let offset = e;
    if (!this.props.value) {
      this.setState({ offset });
    }
    this.triggerChange({ offset });
  };

  handleRecurrenceTypeChange = (recurrenceType) => {
    if (!this.props.value) {
      this.setState({ recurrenceType });
    }
    this.triggerChange({ recurrenceType });
  };

  triggerChange = (changedValue) => {
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(Object.assign({}, this.state, changedValue));
    }
  };
  render() {
    const { size, readOnly, intl, min } = this.props;
    const state = this.state;
    const plural = this.state.offset && this.state.offset > 1;
    return (
      <Container>
        <InputNumber
          disabled={readOnly}
          required
          size={size === "default" ? undefined : size}
          min={min}
          value={state.offset}
          onChange={this.handleRepeatEveryChange}
          style={{ width: "35%", marginRight: "3%" }}
          data-testid={DataTestIds.FORM_INPUT_RECURRENCE_NUMBER}
        />
        <Select
          disabled={readOnly}
          value={state.recurrenceType}
          size={size === "default" ? undefined : size}
          style={{ width: "62%" }}
          onChange={this.handleRecurrenceTypeChange}
          data-testid={DataTestIds.FORM_SELECT_RECURRENCE_TYPE}
        >
          {options.map((option) => (
            <Option key={option} value={option} data-testid={DataTestIds.getOptionTestId(option)}>
              {intl.formatMessage({ id: `scheduler.settings.${option}${plural ? "Plural" : ""}` })}
            </Option>
          ))}
        </Select>
      </Container>
    );
  }
}

export default injectIntl(RepeatEveryInput);
