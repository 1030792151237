import { RecorderStep, PathType, Commands, Platform } from "@ea/shared_types/types";
import {
  CommandStartType,
  CoreCommandsIds,
  CommandEndType,
} from "@ea/shared_types/core.commands.types";
import { getTranslationKey } from "@ea/shared_types/translations";

// TODO: FIX THESE TYPINGSSSSSS
export const getStartStep = ({ ...rest }: any): RecorderStep<CommandStartType> => ({
  commandId: CoreCommandsIds.start,
  labelKey: getTranslationKey("startScript"),
  paths: [{ path: "window", type: PathType.XPATH }],
  ...rest,
  platform: {
    id: Platform.DEFAULT_PLATFORM,
    version: "",
  },
  rule: {},
});

export const getEndStep = (params: any): RecorderStep<CommandEndType> => ({
  commandId: CoreCommandsIds.end,
  labelKey: getTranslationKey("endScript"),
  paths: [{ path: "window", type: PathType.XPATH }],
  value: undefined,
  ...params,
  platform: {
    id: Platform.DEFAULT_PLATFORM,
    version: "",
  },
  rule: {},
});

export const normalizeUrl = (url: string) => {
  if (
    url.indexOf("file://") !== 0 &&
    url.indexOf("http://") !== 0 &&
    url.indexOf("https://") !== 0
  ) {
    let normalizedUrl = `http://${url}`;

    return normalizedUrl;
  }
  return url;
};
