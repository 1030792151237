import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router";

import { ApplicationState } from "@app/modules/app.reducers";

interface IPrivateRouteProps {
  component: any;
  componentProps: any;
}

class PrivateRoute extends React.Component<IPrivateRouteProps & IConnectProps & RouteProps, any> {
  render() {
    const { component: Component, isLoggedIn, location, componentProps, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (!isLoggedIn && location && location.pathname === "/login") {
            return null;
          }

          return isLoggedIn ? (
            <Component {...props} {...componentProps} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { fromLocation: location },
              }}
            />
          );
        }}
      />
    );
  }
}

const connectCreator = connect((state: ApplicationState) => ({ ...state.auth }));

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(PrivateRoute);
