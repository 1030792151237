import moment from "moment";

export const range = (start: number, end: number) => {
  const result: number[] = [];
  for (let i: number = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export const isDateInPast = (current) => {
  return current && current < moment().startOf("day");
};

export const calculateTimeDifferenceFromNow = (timestampTime: number): number =>
  Date.now() - timestampTime;

export const getDisabledTimeRanges = (current) => {
  if (current && current.isAfter(moment(), "day")) {
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    };
  }
  return {
    disabledHours: () => range(0, 24).splice(moment().startOf("day").hour(), moment().hour()),
    disabledMinutes: () => {
      if (current && current.hour() > moment().hour()) {
        return [];
      }
      return range(moment().startOf("hour").minutes(), moment().minutes());
    },
  };
};
