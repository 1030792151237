import { ActionCreator, ActionCreatorFactory } from "typescript-fsa";

import { SortDirection } from "./common.models";

export const createFilterActions = <T>(
  actionCreatorFactory: ActionCreatorFactory,
): FilterActions<T> => ({
  setFilter: actionCreatorFactory<{ fieldName: keyof T; fieldValue: any }>("SET_FILTER"),
  clearFilters: actionCreatorFactory<(keyof T)[]>("CLEAR_FILTERS"),
});

export const createPaginationActions = <T>(actionCreatorFactory: ActionCreatorFactory) => ({
  changePage: actionCreatorFactory<number>("CHANGE_PAGE"),
  changePageSize: actionCreatorFactory<number>("CHANGE_PAGE_SIZE"),
  // todo: init
});

export const createSortingActions = <T>(actionCreatorFactory: ActionCreatorFactory) => ({
  changeSort:
    actionCreatorFactory<{ sortBy: keyof T; sortDirection: SortDirection }>("CHANGE_SORT"),
  clearSorting: actionCreatorFactory<{}>("CLEAR_SORTING"),
});

type FilterActions<T> = {
  setFilter: ActionCreator<{ fieldName: keyof T; fieldValue: any }>;
  clearFilters: ActionCreator<(keyof T)[]>;
};

export const makeRegexTextFilter = <T>(filterActions: FilterActions<T>) => {
  return ({ fieldName, text }: { fieldName: keyof T; text: string }) => {
    if (!text || text === "") {
      return filterActions.clearFilters([fieldName]);
    } else {
      return filterActions.setFilter({ fieldName, fieldValue: { ilike: `%${text}%` } });
    }
  };
};

export const makeTextFilter = <T>(filterActions: FilterActions<T>) => {
  return ({ fieldName, text }: { fieldName: keyof T; text: string }) => {
    if (!text || text === "") {
      return filterActions.clearFilters([fieldName]);
    } else {
      return filterActions.setFilter({ fieldName, fieldValue: text });
    }
  };
};

export type NormalizeAction<T extends { [k: string]: { started: any } | ActionCreator<any> }> = {
  [P in keyof T]: T[P] extends { started: any } ? T[P]["started"] : T[P];
};

export function normalizeActions<T extends { [k: string]: { started: any } | ActionCreator<any> }>(
  actionCreators: T,
) {
  return Object.keys(actionCreators).reduce((result, currentKey) => {
    const currentAction = actionCreators[currentKey] as any;
    if (!currentAction.started) {
      result[currentKey] = currentAction;
    } else {
      result[currentKey] = currentAction.started;
    }
    return result;
  }, {}) as NormalizeAction<T>;
}

export type StartedActions<T extends { [k: string]: { started: any } | ActionCreator<any> }> = {
  [P in keyof T]: T[P] extends { started: any } ? T[P]["started"] : T[P];
};

export function getAsyncStartedActions<
  T extends { [k: string]: { started: any } | ActionCreator<any> },
>(actionCreators: T) {
  return Object.keys(actionCreators).reduce((result, currentKey) => {
    const currentAction = actionCreators[currentKey] as any;
    if (!currentAction.started) {
      result[currentKey] = currentAction;
    } else {
      result[currentKey] = currentAction.started;
    }
    return result;
  }, {}) as StartedActions<T>;
}

export type DoneActions<T extends { [k: string]: { done: any } | ActionCreator<any> }> = {
  [P in keyof T]: T[P] extends { done: any } ? T[P]["done"] : T[P];
};

export function getAsyncDoneActions<T extends { [k: string]: { done: any } | ActionCreator<any> }>(
  actionCreators: T,
) {
  return Object.keys(actionCreators).reduce((result, currentKey) => {
    const currentAction = actionCreators[currentKey] as any;
    if (!currentAction.done) {
      result[currentKey] = currentAction;
    } else {
      result[currentKey] = currentAction.done;
    }
    return result;
  }, {}) as DoneActions<T>;
}

export const createGlobalErrorAction = (err: Error | string) => ({
  type: "GLOBAL_ERROR",
  payload: err,
});
