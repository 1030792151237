import { ActionCreator, AsyncActionCreators } from "typescript-fsa";
import { ReducerBuilder, reducerWithInitialState } from "typescript-fsa-reducers/dist";

import { StartedActions } from "../common.actions";
import { NoTableId } from "../common.data.actions";
import {
  ChangePageActionTypeParams,
  ChangePageSizeActionTypeParams,
  ChangeSortingTypeParams,
  ClearSortingActionTypeParams,
  DeleteActionTypeParams,
  DeleteActionTypeResultParams,
  EditActionTypeParams,
  EditActionTypeResultParams,
  LoadActionTypeParams,
  LoadActionTypeResponse,
  LoadPaginationConfigActionTypeParams,
  LoadPaginationConfigActionTypeResponse,
  LoadSingleActionTypeParams,
  LoadSingleActionTypeResponse,
  SelectActionTypeParams,
  SetFilterActionTypeParams,
  SetPersistentQueryActionTypeParams,
  InvalidateTypeParams,
  CommitEditActionTypeParams,
  CommitCreateActionTypeParams,
  CreateActionTypeParams,
  CreateActionTypeResultParams,
  SetFiltersActionTypeParams,
  ClearItemsActionTypeParams,
  ClearFiltersActionTypeParams,
} from "../common.data.actions.types";
import { DEFAULT_PAGE_SIZE, PlainObject, SelectMode, SortDirection } from "../common.models";
import { Handler, Handlers } from "./common.reducer.utils";
import { Whereable } from "../../services/api.common.models";
import { Item } from "@ea/shared_types/types";

export type SortSection<T> = {
  sortBy: keyof T;
  sortDirection: SortDirection;
};

export type DataReducerState<T extends Item<string | number>, K = {}, P = {}> = {
  items: {
    [key: string]: T;
  };
  isLoading: boolean;
  isSingleLoading: boolean;
  params: PlainObject<DataParams<T> & P>;
} & K;

export type Paging = {
  total: number;
  pageSize: number;
  currentPage: number;
};

export type DataParams<T extends Item<string | number>> = {
  order: T["id"][];
  selected: T["id"][];
  query?: Whereable<T>;
  sort?: SortSection<T>;
  paging?: Paging;
  persistentQuery?: any;
  loadedTime?: number;
  isGeneratingReport?: boolean;
};

export const INITIAL_DATA_PARAMS = {
  order: [],
  selected: [],
  query: undefined,
  sort: undefined,
  paging: undefined,
  persistentQuery: undefined,
  loadedTime: undefined,
};

export const getDataReducerInitialState = <
  T extends Item<string | number>,
>(): DataReducerState<T> => ({
  items: {},
  isLoading: false,
  isSingleLoading: false,
  params: {},
});

// special version of getTableParams that doesn't return initial_data_params. Returning
// initial_data_params breaks typings when we want to specify K and P
// todo: try to write one implementation of getTableParams
export const getTableParamsWithoutInitialData = <T extends Item<string | number>, K = {}, P = {}>(
  state: DataReducerState<T, K, P>,
  { tableId }: { tableId?: string },
) => {
  if (!tableId) {
    console.error("Missing Table ID");
    throw new Error("Missing Table ID");
  }
  const tableParams = state.params[tableId];
  return tableParams;
};

export const getTableParams = <T extends Item<string | number>, K = {}, P = {}>(
  state: DataReducerState<T, K, P>,
  { tableId }: { tableId?: string },
) => {
  if (!tableId) {
    console.error("Missing Table ID");
    throw new Error("Missing Table ID");
  }
  const tableParams = state.params[tableId];
  return tableParams ? tableParams : INITIAL_DATA_PARAMS;
};

export const setTableParams = <T extends Item<string | number>, K = {}, P = {}>(
  state: DataReducerState<T, K, P>,
  { tableId }: { tableId?: string },
  dataParams: Partial<DataParams<T> & P>,
) => {
  if (!tableId) {
    console.error("Missing Table ID");
    throw new Error("Missing Table ID");
  }
  return {
    ...state,
    params: {
      ...state.params,
      [tableId]: {
        ...getTableParams(state, { tableId }),
        ...dataParams,
      },
    },
  };
};

type ClearItemsActionType<T> = ActionCreator<ClearItemsActionTypeParams<T>>;
type CommonDataSetFilterActionType<T> = ActionCreator<SetFilterActionTypeParams<T>>;
type SetFiltersActionType<T> = ActionCreator<SetFiltersActionTypeParams<T>>;
type ClearFiltersActionType<T> = ActionCreator<ClearFiltersActionTypeParams<T>>;
export type CommonInvalidateActionType<T> = ActionCreator<InvalidateTypeParams>;

type SetPersistentQueryActionType<T> = ActionCreator<SetPersistentQueryActionTypeParams<T>>;

export type CommonDataChangeSortingActionType<T> = ActionCreator<ChangeSortingTypeParams<T>>;
type ClearSortingActionType<T> = ActionCreator<ClearSortingActionTypeParams<T>>;

type ChangePageActionType<T> = ActionCreator<ChangePageActionTypeParams<T>>;
type ChangePageSizeActionType<T> = ActionCreator<ChangePageSizeActionTypeParams<T>>;

type CommitCreateActionType<T> = ActionCreator<CommitCreateActionTypeParams<T>>;
type CommitEditActionType<T> = ActionCreator<CommitEditActionTypeParams<T>>;
export type CommonDataLoadPaginationConfigActionType<T> = AsyncActionCreators<
  LoadPaginationConfigActionTypeParams<T>,
  LoadPaginationConfigActionTypeResponse<T>,
  any
>;

export type CommonDataLoadSingleActionType<T extends Item<string | number>> = AsyncActionCreators<
  LoadSingleActionTypeParams<T>,
  LoadSingleActionTypeResponse<T>,
  any
>;
export type CommonDataLoadActionType<T extends Item<string | number>> = AsyncActionCreators<
  LoadActionTypeParams<T>,
  LoadActionTypeResponse<T>,
  any
>;

type CreateActionType<T extends Item<string | number>> = AsyncActionCreators<
  CreateActionTypeParams<T>,
  CreateActionTypeResultParams<T>,
  any
>;
type DeleteActionType<T extends Item<string | number>> = AsyncActionCreators<
  DeleteActionTypeParams<T>,
  DeleteActionTypeResultParams<T>,
  any
>;

export type CommonDataEditActionType<T> = AsyncActionCreators<
  EditActionTypeParams<T>,
  EditActionTypeResultParams<T>,
  any
>;

type SelectActionType<T extends Item<string | number>> = ActionCreator<SelectActionTypeParams<T>>;

export const commitCreateHandler =
  <T extends Item<string | number>>(): Handler<
    DataReducerState<T>,
    CommitCreateActionType<T>["payloadType"]
  > =>
  (state, payload) => {
    // epic should call load data after detecting commit create
    console.warn("Action commitCreate is not implemented!");
    return state;
  };

export const commitEditHandler =
  <T extends Item<string | number>>(): Handler<
    DataReducerState<T>,
    CommitEditActionType<T>["payloadType"]
  > =>
  (state, payload) => {
    return {
      ...state,
      items: {
        ...(state.items as any),
        [payload.id]: payload,
      },
    };
  };

export const setPersistentQueryHandler =
  <T extends Item<string | number>>(): Handler<
    DataReducerState<T>,
    SetPersistentQueryActionType<T>["payloadType"]
  > =>
  (state, payload) => {
    const tableParams = getTableParams(state, payload);
    return setTableParams(state, payload, {
      ...tableParams,
      persistentQuery: payload.query,
    });
  };

export const setFilterHandler =
  <T extends Item<string | number>>(): Handler<
    DataReducerState<T>,
    CommonDataSetFilterActionType<T>["payloadType"]
  > =>
  (state, payload) => {
    const tableParams = getTableParams(state, payload);
    const query = {
      ...(tableParams.query as any),
      [payload.fieldName]: payload.fieldValue,
    };
    return setTableParams(state, payload, {
      ...tableParams,
      query: query as Whereable<T>,
    });
  };

export const setFiltersHandler =
  <T extends Item<string | number>>(): Handler<
    DataReducerState<T>,
    SetFiltersActionType<T>["payloadType"]
  > =>
  (state, payload) => {
    const tableParams = getTableParams(state, payload);
    const query = {
      ...(tableParams.query as any),
      ...(payload.values as any),
    };
    return setTableParams(state, payload, {
      ...tableParams,
      query: query as Whereable<T>,
    });
  };

export const clearItemsHandler =
  <T extends Item<string | number>>(): Handler<
    DataReducerState<T>,
    ClearItemsActionType<T>["payloadType"]
  > =>
  (state, payload) => {
    return setTableParams(state, payload, {
      order: [],
    });
  };

export const clearFiltersHandler =
  <T extends Item<string | number>>(): Handler<
    DataReducerState<T>,
    ClearFiltersActionType<T>["payloadType"]
  > =>
  (state, payload) => {
    const tableParams = { ...getTableParams(state, payload) };
    let query = tableParams.query;
    if (!query) {
      return state;
    }
    if (!payload.fields) {
      query = undefined;
    } else {
      payload.fields.forEach((property) => delete query![property]);
    }

    return setTableParams(state, payload, {
      query,
    });
  };

export const changeSortingHandler =
  <T extends Item<string | number>>(): Handler<
    DataReducerState<T>,
    CommonDataChangeSortingActionType<T>["payloadType"]
  > =>
  (state, payload) => {
    const tableParams = getTableParams(state, payload);
    return setTableParams(state, payload, {
      sort: {
        ...tableParams.sort,
        sortBy: payload.sortBy,
        sortDirection: payload.sortDirection,
      },
    });
  };

export const clearSortingHandler =
  <T extends Item<string | number>>(): Handler<
    DataReducerState<T>,
    ClearSortingActionType<T>["payloadType"]
  > =>
  (state, payload) => {
    return setTableParams(state, payload, {
      sort: undefined,
    });
  };

export const changePageHandler =
  <T extends Item<string | number>>(): Handler<
    DataReducerState<T>,
    ChangePageActionType<T>["payloadType"]
  > =>
  (state, payload) => {
    const tableParams = getTableParams(state, payload);
    if (!tableParams.paging) {
      return state;
    }
    if (
      !(
        payload.page <= Math.ceil(tableParams.paging.total / tableParams.paging.pageSize) &&
        payload.page >= 1
      )
    ) {
      return state;
    }
    return setTableParams(state, payload, {
      paging: tableParams.paging
        ? {
            ...tableParams.paging,
            currentPage: payload.page,
          }
        : undefined,
    });
  };

export const changePageSizeHandler =
  <T extends Item<string | number>>(): Handler<
    DataReducerState<T>,
    ChangePageSizeActionType<T>["payloadType"]
  > =>
  (state, payload) => {
    const tableParams = getTableParams(state, payload);
    if (!tableParams.paging) {
      return state;
    }
    const maxPageWithNewSize = Math.ceil(tableParams.paging.total / payload.pageSize);
    const page =
      tableParams.paging.currentPage > maxPageWithNewSize
        ? maxPageWithNewSize
        : tableParams.paging.currentPage;

    return setTableParams(state, payload, {
      paging: tableParams.paging
        ? {
            ...tableParams.paging,
            pageSize: payload.pageSize,
            currentPage: page,
          }
        : undefined,
    });
  };

export const invalidateHandler =
  <T extends Item<string | number>>(): Handler<
    DataReducerState<T>,
    CommonInvalidateActionType<T>["payloadType"]
  > =>
  (state, payload) => {
    let newParams = {};
    const keys = Object.keys(state.params);
    keys.forEach((key) => {
      newParams = {
        ...newParams,
        [key]: {
          ...state.params[key],
          loadedTime: undefined,
        },
      };
    });
    return {
      ...state,
      params: newParams,
    };
  };

type LoadPaginationConfigHandlers<T extends Item<string | number>> = Handlers<
  DataReducerState<T>,
  CommonDataLoadPaginationConfigActionType<T>
>;
const loadPaginationConfigHandlersCreator = <
  T extends Item<string | number>,
>(): LoadPaginationConfigHandlers<T> => ({
  started: (state, payload) => ({
    ...state,
    isLoading: !payload.backgroundReload && true,
  }),
  failed: (state, payload) => ({
    ...state,
    isLoading: false,
  }),
  done: (state, payload) => {
    const tableParams = getTableParams(state, payload.params);

    const currentPage =
      (payload.params.persistCurrentPage &&
        state.params[payload.params.tableId] &&
        state.params[payload.params.tableId].paging &&
        state.params[payload.params.tableId].paging!.currentPage) ||
      1;

    return setTableParams(state, payload.params, {
      paging: {
        ...tableParams.paging,
        total: payload.result.count,
        currentPage,
        pageSize: tableParams.paging ? tableParams.paging.pageSize : DEFAULT_PAGE_SIZE,
      },
    });
  },
});

export const selectHandler =
  <T extends Item<string | number>>(): Handler<
    DataReducerState<T>,
    SelectActionType<T>["payloadType"]
  > =>
  (state, payload): DataReducerState<T> => {
    // todo: why removing Data<T> from function result breaks typechecking?
    const tableParams = getTableParams(state, payload);

    if (payload.mode === SelectMode.Replace) {
      return setTableParams(state, payload, {
        selected: payload.ids,
      });
    }

    const existed: (number | string)[] = [];

    const filtered = tableParams.selected.filter((id) => {
      const result = payload.ids.indexOf(id) === -1;
      if (!result) {
        existed.push(id);
      }
      return result;
    });

    const filteredIds = payload.ids.filter((id) => existed.indexOf(id) === -1);

    return setTableParams(state, payload, {
      selected: filteredIds.concat(filtered),
    });
  };

type LoadHandlers<T extends Item<string | number>> = Handlers<
  DataReducerState<T>,
  CommonDataLoadActionType<T>
>;
export const loadHandlersCreator = <T extends Item<string | number>>(): LoadHandlers<T> => ({
  started: (state, payload) => ({
    ...state,
    isLoading: !payload.backgroundReload && true,
  }),
  failed: (state, payload) => ({
    ...state,
    isLoading: false,
  }),
  done: (state, payload) => {
    const tableParams = getTableParams(state, payload.params);
    const newState = {
      ...state,
      isLoading: false,
      items: payload.result.reduce(
        (prev, curr) => {
          prev[curr.id] = curr;
          return prev;
        },
        payload.params.clearPrevious ? {} : { ...state.items },
      ),
    };

    return setTableParams(newState, payload.params, {
      order: payload.result.map((item) => item.id),
      loadedTime: Date.now(),
    });
  },
});

type LoadSingleHandlers<T extends Item<string | number>> = Handlers<
  DataReducerState<T>,
  CommonDataLoadSingleActionType<T>
>;
export const loadSingleHandlersCreator = <
  T extends Item<string | number>,
>(): LoadSingleHandlers<T> => ({
  started: (state, payload) => ({
    ...state,
    isSingleLoading: true,
  }),
  failed: (state, payload) => ({
    ...state,
    isSingleLoading: false,
  }),
  done: (state, payload) =>
    payload.result
      ? {
          ...state,
          isSingleLoading: false,
          items: {
            ...(state.items as any),
            [payload.result.id]: payload.result,
          },
        }
      : {
          ...state,
          isSingleLoading: false,
        },
});

type DeleteHandlers<T extends Item<string | number>> = Handlers<
  DataReducerState<T>,
  DeleteActionType<T>
>;
export const deleteHandlersCreator = <T extends Item<string | number>>(): DeleteHandlers<T> => ({
  started: (state, payload) => state,
  failed: (state, payload) => state,
  done: (state, payload) => {
    return setTableParams(
      state,
      { tableId: payload.params.tableId },
      {
        selected: [],
      },
    );
  },
});

type EditHandlers<T extends Item<string | number>> = Handlers<
  DataReducerState<T>,
  CommonDataEditActionType<T>
>;
export const editHandlersCreator = <T extends Item<string | number>>(): EditHandlers<T> => ({
  started: (state, payload) => state,
  failed: (state, payload) => state,
  done: (state, payload) => ({
    ...state,
    items: {
      ...(state.items as any),
      [payload.result.id]: payload.result,
    },
  }),
});

export type DataActions<T extends Item<string | number>> = {
  edit: CommonDataEditActionType<T>;
  commitCreate: CommitCreateActionType<T>;
  commitEdit: CommitEditActionType<T>;
  invalidate: CommonInvalidateActionType<T>;
  loadSingle: CommonDataLoadSingleActionType<T>;
};

export type AsyncDataTableActions<T extends Item<string | number>> = {
  loadPaginationConfig: CommonDataLoadPaginationConfigActionType<T>;
  load: CommonDataLoadActionType<T>;
  delete: DeleteActionType<T>;
  create: CreateActionType<T>;
};

export type DataTableActions<T extends Item<string | number>> = {
  changePage: ChangePageActionType<T>;
  changePageSize: ChangePageSizeActionType<T>;
  changeSorting: CommonDataChangeSortingActionType<T>;
  clearSorting: ClearSortingActionType<T>;
  clearItems: ClearItemsActionType<T>;
  select: SelectActionType<T>;
  setFilter: CommonDataSetFilterActionType<T>;
  setFilters: SetFiltersActionType<T>;
  clearFilters: ClearFiltersActionType<T>;
  setPersistentQuery: SetPersistentQueryActionType<T>;
  reload: any;
};

export type CallableTableActions<T extends Item<string | number>> = NoTableId<
  any,
  StartedActions<AsyncDataTableActions<T>>
> &
  NoTableId<any, DataTableActions<T>>;

type Actions<T extends Item<string | number>> = Partial<
  DataActions<T> & DataTableActions<T> & AsyncDataTableActions<T>
>;

export const createDataReducer =
  <T extends Item<string | number>>({
    setFilter,
    setFilters,
    clearFilters,
    changeSorting,
    clearSorting,
    clearItems,
    changePage,
    changePageSize,
    loadPaginationConfig,
    loadSingle,
    load,
    select,
    edit,
    invalidate,
    commitCreate,
    commitEdit,
    setPersistentQuery,
    delete: deleteActions,
  }: Actions<T>) =>
  <K extends DataReducerState<T>>(initialState: K): ReducerBuilder<K, K> => {
    const reducer = reducerWithInitialState<DataReducerState<T>>(initialState);

    if (load) {
      const loadHandlers = loadHandlersCreator<T>();
      reducer
        .case(load.started, loadHandlers.started)
        .case(load.failed, loadHandlers.failed)
        .case(load.done, loadHandlers.done);
    }

    if (loadSingle) {
      const loadSingleHandlers = loadSingleHandlersCreator<T>();
      reducer
        .case(loadSingle.started, loadSingleHandlers.started)
        .case(loadSingle.failed, loadSingleHandlers.failed)
        .case(loadSingle.done, loadSingleHandlers.done);
    }

    if (edit) {
      const editHandlers = editHandlersCreator<T>();
      reducer
        .case(edit.started, editHandlers.started)
        .case(edit.failed, editHandlers.failed)
        .case(edit.done, editHandlers.done);
    }

    if (deleteActions) {
      const deleteHandlers = deleteHandlersCreator<T>();
      reducer
        .case(deleteActions.started, deleteHandlers.started)
        .case(deleteActions.failed, deleteHandlers.failed)
        .case(deleteActions.done, deleteHandlers.done);
    }

    if (loadPaginationConfig) {
      const loadPaginationConfigHandlers = loadPaginationConfigHandlersCreator<T>();
      reducer
        .case(loadPaginationConfig.started, loadPaginationConfigHandlers.started)
        .case(loadPaginationConfig.failed, loadPaginationConfigHandlers.failed)
        .case(loadPaginationConfig.done, loadPaginationConfigHandlers.done);
    }

    if (clearItems) {
      reducer.case(clearItems, clearItemsHandler<T>());
    }

    if (commitCreate) {
      reducer.case(commitCreate, commitCreateHandler<T>());
    }

    if (commitEdit) {
      reducer.case(commitEdit, commitEditHandler<T>());
    }

    if (invalidate) {
      reducer.case(invalidate, invalidateHandler<T>());
    }

    if (setFilter) {
      reducer.case(setFilter, setFilterHandler<T>());
    }

    if (setFilters) {
      reducer.case(setFilters, setFiltersHandler<T>());
    }

    if (clearFilters) {
      reducer.case(clearFilters, clearFiltersHandler<T>());
    }
    if (changeSorting) {
      reducer.case(changeSorting, changeSortingHandler<T>());
    }
    if (clearSorting) {
      reducer.case(clearSorting, clearSortingHandler<T>());
    }
    if (changePage) {
      reducer.case(changePage, changePageHandler<T>());
    }
    if (changePageSize) {
      reducer.case(changePageSize, changePageSizeHandler<T>());
    }
    if (select) {
      reducer.case(select, selectHandler<T>());
    }
    if (setPersistentQuery) {
      reducer.case(setPersistentQuery, setPersistentQueryHandler<T>());
    }

    return reducer as any;
  };
