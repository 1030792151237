import {
  Step,
  PlainObject,
  EXECUTION_STATE,
  EXECUTION_STATUS,
  Message,
  Item,
  PlayerStep,
  Variable,
  RECORDER_EXECUTION_STATE,
  RecorderStep,
  EXECUTION_SCRIPT_TYPES,
  RecorderVariable,
  Tokens,
  ExecutionLogIntegrationMetadata,
  PlatformMetadata,
  RuleMetadata,
  RunnerMode,
  StepThrottling,
  ScriptStatus,
  AssignedVariableType,
  GlobalVariable,
  CodeValue,
  GlobalVariableType,
  ScreenshotOptions,
  System,
  CodeTemplateDefinition,
  GlobalSettingsResolversModes,
  Guard,
  LogMessage,
} from "./types";
import {
  TokensResponse,
  AuthResponse,
  VirtualUserDataResponse,
  LogOutResponse,
} from "./runner.api.types";
import { Merge } from "ts-essentials";

export interface CustomEvent<T> extends Event {
  meta: T;
}

export type PlayerVariable = {
  value: any;
  name: string;
  groupName?: string;
};

export interface ExecutionStepLogModel extends Item {
  executionId: number;
  status: EXECUTION_STATUS;
  label: string;
  startTime: Date;
  endTime?: Date;
  duration?: Number;
  lineNum: number;
  screenshotPath?: string;
  variableLogs?: CodeExecutionLog;
  messages: Message[];
  linkedScriptId?: number;
  parentStepLogId?: number;
  labelKey?: string;
  labelParams?: any;
  stepData?: StepLogMetadata;
  kpi?: KpiLogData[];
}

export interface StepLogMetadata extends Item {
  commandId: string;
  platform: PlatformMetadata;
  rule: RuleMetadata;
}

export interface ExecutionLogModel extends Item {
  sessionId: string;
  startTime: Date;
  endTime?: Date;
  type?: EXECUTION_SCRIPT_TYPES.VIRTUAL_USER_AUTH;
  internal: boolean;
  state: EXECUTION_STATE;
  scriptStatus?: ScriptStatus;
  status: EXECUTION_STATUS;
  url: string;
  duration?: number;
  callerId: number;
  iteration: number;
  isBackground: boolean;
  scriptId: number;
  scriptName: string;
  schedulerJobId: number;
  schedulerJobName: string;
  apiKeyName: string;
  projectId?: number;
  projectName?: string;
  tags?: string[];
  messages: LogMessage[];
  scriptVersion?: string;
  jobId: string;
  integrationMetadata?: ExecutionLogIntegrationMetadata;
  mappedWithDevops?: boolean;
  envName?: string;
  virtualUserName?: string;
  executorName?: string;
  isSessionActive?: boolean;
  scriptDescription?: string;
  scriptSignedBy?: string;
}
export interface ExecutionJobLogModel extends Item {
  jobId: string;
  startTime: Date;
  endTime?: Date;
  status?: EXECUTION_STATUS;
  state: EXECUTION_STATE;
  successCount?: number;
  warningCount?: number;
  errorCount?: number;
  allCount?: number;
  duration?: number;
  projectId?: number;
  projectName?: string;
  schedulerJobName?: string;
  schedulerJobId?: number;
  isExcluded?: boolean;
}

export type FlatExecutionStep = {
  path: string;
  id: number;
};

export interface ExecutionStepTreeNode<T extends { value: any; id: any; version: any } = any>
  extends ExecutionStep<T> {
  mode: RunnerMode;
  executionPath: string;
  executionState: EXECUTION_STATE;
}
export interface ExecutionStep<T extends { value: any; id: any; version: any } = any>
  extends Step<T> {
  execution: ExecutionStepMetadata;
  guards?: Guard[];
}

export type DisplayStep = {
  label: Step["label"];
  isOptional: Step["isOptional"];
  commandId: Step["commandId"];
  platform: Step["platform"];
  path: string;
  status: EXECUTION_STATUS;
  steps: DisplayStep[];
  id: number;
  labelKey: Step["labelKey"];
  value: Step["value"];
};

export type ExecutionStepMetadata = {
  numberOfExecutions: number;
  status: EXECUTION_STATUS;
  path: string;
  steps: ExecutionStep[];
  forceStatus?: EXECUTION_STATUS;
  usedVariables: UsedVariable[];
};

export type ScriptDataSourceConfig = {
  hasNextRow: boolean;
  totalRows: number;
  currentRow: number;
};

export type PlayerVariables = {
  script: PlainObject<PlayerVariable>;
  global: {
    sequence: PlainObject<PlayerVariable>;
    mutable: PlainObject<PlayerVariable>;
    constant: PlainObject<PlayerVariable>;
  };
  dataSource: PlainObject<PlayerVariable>;
};

export type LinkScriptDataConfig = {
  disableStartStep?: boolean;
};

export type ScriptData = {
  id: number;
  name: string;
  variables: PlayerVariables;
  dataSource: PlainObject<ScriptDataSourceConfig>;
  // todo: R2.0 we dont need it
  steps: ExecutionStep[];
  links: PlainObject<ScriptData>;
  throttling?: StepThrottling;
  linkConfig?: LinkScriptDataConfig;
  system?: System;
  projectId?: number;
  version?: string;
  kpis?: PlainObject<string>;
  codeTemplates?: PlainObject<PlainObject<CodeTemplateDefinition>>;
};

type ExecutionStepLog = {
  path: string;
  status: EXECUTION_STATUS;
  startTime: number;
  endTime: number;
  messages: Message[];
};

type BaseCodeExecutionLog = {
  input: string;
  executedCode: string;
};

export type ChangedVariables = PlainObject<{
  name: string;
  value: string;
  type: AssignedVariableType;
}>;
export enum CodeExecutionLogType {
  CALCULATE = "CALCULATE",
  ASSIGN = "ASSIGN",
}

export type CalculateCodeExecutionLog = BaseCodeExecutionLog & {
  type: CodeExecutionLogType.CALCULATE;
  output: string;
};

export type AssignCodeExecutionLog = BaseCodeExecutionLog & {
  type: CodeExecutionLogType.ASSIGN;
  changedVariables: ChangedVariables;
  output: PlainObject<string>;
};

export type CodeExecutionLog = CalculateCodeExecutionLog | AssignCodeExecutionLog;

export interface RunnerExecutionStepLogModel extends ExecutionStepLogModel {
  path: string;
  type?: EXECUTION_SCRIPT_TYPES.VIRTUAL_USER_AUTH;
}
export interface RunnerExecutionLogModel extends ExecutionLogModel {
  steps: RunnerExecutionStepLogModel[];
}

export type ExecutionCache = {
  sessionId: string;
  url?: string;
  mode: RunnerMode;
  // todo: R2.0 remove from scriptdata
  steps: ExecutionStep[];
  flatSteps: FlatExecutionStep[];
  // todo: R2.0 hmm variables are also in script data?
  variables: PlayerVariables | Variable[];
  globalMutables: GlobalVariable[];
  scriptId: number;

  recorder: {
    steps: RecorderStep[];
    variables: RecorderVariable[];

    // todo: R2.0 do we need this duplication?
    scriptId: number;

    hasBeenAuthenticated: boolean;
    hasBeenInitialized: boolean;
    hasBeenCacheByClient: boolean;

    recordingPath: string;

    state: RECORDER_EXECUTION_STATE;
  };

  player: {
    globalMutableVariables: {
      initial: PlainObject<PlayerVariable>;
      final: PlainObject<PlayerVariable>;
    };
    externalScript?: any;
    hasBeenInitialized: boolean;
    hasBeenEverStarted: boolean;
    hasBeenAuthenticated: boolean;
    hasBeenClientInitialized: boolean;

    iteration: number;
    position: number;
    oldPosition: number;
    lockMovement: boolean;
    state: EXECUTION_STATE;
    authenticatedVU?: number;

    // todo: R2.0 maybe we should move it to the root?
    // needs to be refreshed after every sync?
    // we keep some dynamic data in scriptData, maybe we should move it to another container
    script: ScriptData;

    // not send to client;
    log: RunnerExecutionLogModel;

    test: {
      runId: number;
      runResultId: number;
    };
  };
};

export type PlayerExecutionCache = Omit<ExecutionCache, "recorder">;
export type PlayerExecutionCacheParial = Omit<
  Partial<Merge<ExecutionCache, { player: Partial<ExecutionCache["player"]> }>>,
  "recorder"
>;
export type RecorderExecutionCache = Omit<ExecutionCache, "player">;
export type RecorderExecutionCachePartial = Omit<
  Partial<Merge<ExecutionCache, { recorder: Partial<ExecutionCache["recorder"]> }>>,
  "player"
>;

export type UsedVariable = {
  variableId: number;
  linkId?: number;
  variableType?: AssignedVariableType;
};

export type CommonExecutionControllerParams = {
  sessionId: string;
  scriptId: number;
  isBackground: boolean;
  token: string;
};

export type PlayerExecutionControllerParams = {
  schedulerJobId: number;
  datasourceId: number;
  timeout?: number;
  authMode: boolean;
  script?: ScriptData;
  variables?: { name: string; value: any }[];
};

export type RecorderExecutionControllerParams = {
  reworkStepId: number;
};

export type CommandResult = {
  jumpPosition?: number;
  reload?: boolean;
  overrideStatus?: EXECUTION_STATUS;
  repeat?: boolean;
  messages?: Message[];
};

export type ExecutionApi = {
  log: (messages: Message[]) => void;
  getElement: (
    step: Step,
    pureGet?: boolean,
    validateElement?: (element: any) => boolean,
    mode?: GlobalSettingsResolversModes,
  ) => Promise<any>;
  getElements: (step: Step<any>, pureGet?: boolean) => Promise<HTMLElement[]>;
  getStepData: (id?: number | undefined) => {
    position: number;
    step: ExecutionStep;
  };
  kpi: (kpi: number[]) => Promise<void>;
  getScriptData: () => ScriptData;
  getScreenshotOption: (step: Step) => ScreenshotOptions;
  takeScreenshot: () => Promise<void>;
  // todo: R2.0 move variable calculator to ExecutionAPI
  variables: {
    calculateValue: (code: CodeValue, usedVariablesIds: UsedVariable[], obfuscate?: boolean) => any;
    calculateVariable: (
      variableId: UsedVariable,
      code: CodeValue,
      usedVariablesIds: UsedVariable[],
    ) => void;
  };
  tokens: (
    virtualUserId: number,
    data: Pick<Tokens, "sessionStorage" | "localStorage">,
    cookiesSettings: {
      omit: string[];
      take: string[];
      override: PlainObject<any>;
    },
  ) => Promise<TokensResponse>;
  auth: (virtualUserId?: number | undefined) => Promise<AuthResponse>;
  logOut: (virtualUserId?: number | undefined) => Promise<LogOutResponse>;
  virtualUser: (virtualUserId: number, data: any) => Promise<VirtualUserDataResponse>;

  dataSource: {
    hasNext: (sheet: string) => boolean;
    reload: (sheet: string, resetOnLast?: boolean | undefined) => Promise<void>;
  };
  createPlatformRequest: (
    type: "GET" | "POST",
    platformId: any,
    name: any,
  ) => (params: any) => Promise<any>;
};

export type CommandExecute = (api: ExecutionApi, step: PlayerStep) => Promise<CommandResult | void>;

export type RecorderCacheType = {
  isRecording: boolean;
  isInspecting: boolean;
  isPlaying?: boolean;
};

export type PlayerCacheType = { isPlaying: boolean };

export enum RunnerPostMessageActions {
  "step_recorded" = "step_recorded",
  "start_recording" = "start_recording",
  "pause_recording" = "pause_recording",
  "toggle_inspect" = "toggle_inspect",
  "back_navigation" = "back_navigation",
  "refresh_navigation" = "refresh_navigation",
  "url_change" = "url_change",
  "get_initial_state" = "get_initial_state",
  "set_initial_state" = "set_initial_state",

  "start_player" = "start_player",
  "player_state" = "player_state",
  "player_position" = "player_position",
}
export type RunnerPostMessageType<K extends RunnerPostMessageActions, T = {}> = {
  source: "runner_manager" | "runner_child";
  value: T;
  action: K;
};

export type RunnerPostMessageStepRecorded = RunnerPostMessageType<
  RunnerPostMessageActions.step_recorded,
  RecorderStep
>;
export type RunnerPostMessageRecord =
  RunnerPostMessageType<RunnerPostMessageActions.start_recording>;
export type RunnerPostMessageInspect =
  RunnerPostMessageType<RunnerPostMessageActions.toggle_inspect>;
export type RunnerPostMessagePause =
  RunnerPostMessageType<RunnerPostMessageActions.pause_recording>;

export type RunnerPostMessageBack = RunnerPostMessageType<RunnerPostMessageActions.back_navigation>;
export type RunnerPostMessageRefresh =
  RunnerPostMessageType<RunnerPostMessageActions.refresh_navigation>;
export type RunnerPostUrlChange = RunnerPostMessageType<
  RunnerPostMessageActions.url_change,
  string
>;
export type RunnerPostGetInitialState =
  RunnerPostMessageType<RunnerPostMessageActions.get_initial_state>;
export type RunnerPostSetInitialState = RunnerPostMessageType<
  RunnerPostMessageActions.set_initial_state,
  RecorderCacheType
>;

export type RunnerPostStartPlayer = RunnerPostMessageType<RunnerPostMessageActions.start_player>;
export type RunnerPostPlayerState = RunnerPostMessageType<
  RunnerPostMessageActions.player_state,
  { state: EXECUTION_STATE }
>;
export type RunnerPostPlayerPosition = RunnerPostMessageType<
  RunnerPostMessageActions.player_position,
  { position: number }
>;

export type RunnerPostMessages =
  | RunnerPostMessageStepRecorded
  | RunnerPostMessageRecord
  | RunnerPostMessageInspect
  | RunnerPostMessagePause
  | RunnerPostMessageBack
  | RunnerPostMessageRefresh
  | RunnerPostUrlChange
  | RunnerPostGetInitialState
  | RunnerPostSetInitialState
  | RunnerPostStartPlayer
  | RunnerPostPlayerState
  | RunnerPostPlayerPosition;

export type LiveVariablesUpdateData = {
  usedVariable: UsedVariable;
  value: any;
}[];

export type LiveVariablesModifyData = {
  variableId: UsedVariable;
  type: "Local" | GlobalVariableType;
} & (
  | { name: string; value?: any; actionType: "add" }
  | { actionType: "remove"; name?: undefined; value?: undefined }
);

export interface KpiLogData {
  id: number;
  name: string;
}
