import * as React from "react";

import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { ProjectCategory } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";

export const PROJECT_CATEGORY_COLUMNS_CONFIG: PlainObject<CustomColumn<ProjectCategory>> = {
  id: {
    label: getTranslationKey("table", "id"),
    dataIndex: "id",
  },
  name: {
    dataIndex: "name",
    label: getTranslationKey("table", "name"),
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  description: {
    dataIndex: "description",
    label: getTranslationKey("table", "description"),
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
};

export const PROJECT_CATEGORY_COLUMNS: ColumnConfig<ProjectCategory>[] = [
  {
    props: PROJECT_CATEGORY_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "30%",
    },
  },
  {
    props: PROJECT_CATEGORY_COLUMNS_CONFIG.description,
    frameworkProps: {
      width: "70%",
    },
  },
];

export const PROJECT_CATEGORY_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_PROJECT_CATEGORY",
  },
  ALL_CATEGORIES: {
    id: () => "ALL_CATEGORIES",
    preferencesId: "PROJECT_CATEGORY_ALL_CATEGORIES",
  },
};
