export function formatMilisecondsToSeconds(val: number) {
  return `${Math.round(val / 1000)}s`;
}
export function parseFromSecondsStringToMiliseconds(val: string) {
  const value = val.replace("s", "");
  if (!value) {
    return 0;
  }
  return parseInt(value, 10) * 1000;
}

export function tryParseInt(input: string | undefined) {
  if (!input) {
    return;
  }

  const result = parseInt(input, 10);

  if (Number.isNaN(result)) {
    return undefined;
  }

  return result;
}
export const sanitizeScriptNameToFilename = (name: string) => name.replace(/[^a-z0-9]/gi, "_");

export const generateExportScriptName = ({ id, name = "script" }: { id: number; name?: string }) =>
  `${id}-${sanitizeScriptNameToFilename(name)}.ea`;

export const generateTemplateName = ({ id, name = "script" }: { id: number; name?: string }) =>
  `[${id}]-${sanitizeScriptNameToFilename(name)}.xlsx`;
