import { Tag } from "antd";
import * as React from "react";

import { COLORS } from "@app/styles/consts";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DOCUMENTATION_TYPE } from "@ea/shared_types/types";
import { DataTestIds } from "@app/utils/dataTestIds";

interface DocumentationTypeTagProps {
  item: { type: DOCUMENTATION_TYPE };
  style?: any;
}

const DocumentationTypeTag: React.FunctionComponent<DocumentationTypeTagProps> = ({
  item,
  style,
}) => {
  const customStyle = {
    cursor: "default",
    ...style,
  };

  if (!item) {
    return null;
  }

  if (!item.type) {
    return null;
  }

  return (
    <Tag
      data-testid={DataTestIds.getTagTestId(item.type)}
      style={customStyle}
      color={COLORS.DOCUMENTATION_TYPE[item.type]}
    >
      <FormattedMessage id={getTranslationKey("documentation", "type", item.type)} />
    </Tag>
  );
};

export default DocumentationTypeTag;
