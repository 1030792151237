import { Input } from "antd";
import createFormField from "../createFormField";
import withReadonly from "./withReadonly";

export const ReadonlyInput = withReadonly(Input);
export const InputField = createFormField(
  ReadonlyInput,
  ({ name, onChange, value, onBlur, onFocus, defaultValue, ...rest }) => ({
    ...rest,
    name,
    onChange,
    defaultValue,
    value,
    onBlur: onBlur as any,
    onFocus: onFocus as any,
    autoComplete: "off",
  }),
);

export default InputField;
