import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { Tag } from "@ea/shared_types/types";
import * as React from "react";
import { GetAsTime } from "@app/modules/common/time";
import { getTranslationKey } from "@app/translations/translations.helpers";

export const TAG_COLUMNS_CONFIG: PlainObject<CustomColumn<Tag>> = {
  name: {
    label: getTranslationKey("table", "name"),
    dataIndex: "name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  description: {
    label: getTranslationKey("table", "description"),
    dataIndex: "description",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  createdAt: {
    label: getTranslationKey("table", "createdAt"),
    dataIndex: "createdAt",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "createdAt" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
};

export const TAG_COLUMNS: ColumnConfig<Tag>[] = [
  {
    props: TAG_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "33%",
    },
  },
  {
    props: TAG_COLUMNS_CONFIG.description,
    frameworkProps: {
      width: "33%",
    },
  },
  {
    props: TAG_COLUMNS_CONFIG.createdAt,
    frameworkProps: {
      width: "33%",
    },
  },
];

export const TAG_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_TAG",
  },
};
