import * as React from "react";
import { Script, VirtualUser } from "@ea/shared_types/types";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { withTranslation, WithTranslation } from "react-i18next";
import { Dispatch, bindActionCreators } from "redux";
import { getRunnerTableActions } from "../runner.actions";
import { ApplicationState } from "@app/modules/app.reducers";
import { connect, ConnectedProps } from "react-redux";
import { runnerDataSelectors } from "../runner.selectors";
import { getCreateableManually } from "@app/packs/packs.helpers";
import { NewStepPanel } from "@app/modules/steps/components/NewStepPanel";
import { DEFAULT_PLATFORM_ID } from "@ea/runner_loader/ea.internal.types";
import {
  getVariablesGroupsSelector,
  variableDataSelectors,
} from "@app/modules/variables/variables.selectors";
import { VARIABLES_SECTIONS, variableActions } from "@app/modules/variables/variables.actions";
import { Project, System } from "@ea/shared_types";
import {
  getScriptGlobalConstants,
  getScriptGlobalMutables,
} from "@app/modules/scripts/scripts.selectors";
import { codeTemplatesDataSelectors } from "../../codeTemplates/codeTemplates.selectors";
import { CODE_TEMPLATES_TABLES_CONFIG } from "../../codeTemplates/codeTemplates.table";

interface INewStepContainerProps {
  sessionId: string;
  script: Script;
  project: Project;
  system?: System;
  systems: System[];
  virtualUser?: VirtualUser;
  virtualUsers: VirtualUser[];
  openedPanel: string | undefined;
  close: () => void;
}

type ComponentProps = INewStepContainerProps & WithTranslation;

class NewStepContainer extends React.Component<IConnectProps, any> {
  destroyMessageListener: any;
  connectedTable: any;

  constructor(props) {
    super(props);
    this.state = {
      openedPanel: undefined,
    };
  }

  setOpenPanel = (panel) =>
    this.setState({
      openedPanel: panel,
    });

  close = () => this.setOpenPanel(undefined);

  afterCreateStep = async (newStep) => {
    this.props.actions.addNewStep({ newStep, specificLineNum: newStep.lineNum });
  };

  render() {
    const {
      sessionId,
      variables,
      variablesGroups,
      project,
      script,
      system,
      systems,
      virtualUsers,
      virtualUser,
      actions,
      openedPanel,
      close,
      steps,
      globalMutables,
      globalConstants,
      codeTemplates,
    } = this.props;

    if (!sessionId) {
      return "No session";
    }
    const createableSteps = getCreateableManually();

    return (
      <>
        {openedPanel && (
          <NewStepPanel
            system={system}
            virtualUser={virtualUser}
            variables={variables}
            close={close}
            existingSteps={steps}
            variablesGroups={variablesGroups}
            script={script}
            loadLocalVariables={actions.variable.loadLocal}
            openLinked={() => {}}
            systems={systems}
            virtualUsers={virtualUsers}
            project={project}
            afterCreate={this.afterCreateStep}
            platform={DEFAULT_PLATFORM_ID}
            key={openedPanel}
            isOpen={!!openedPanel}
            commandId={openedPanel}
            globalConstants={globalConstants}
            globalMutables={globalMutables}
            codeTemplates={codeTemplates}
            headerText={
              <span>
                <FormattedMessage id={getTranslationKey("step", "newPanel", "create")} />
                &nbsp;
                <FormattedMessage
                  id={createableSteps.find((s) => s.id === openedPanel)?.createableManually!.text!}
                />
                &nbsp;
                <FormattedMessage id={getTranslationKey("step", "newPanel", "step")} />
              </span>
            }
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: ComponentProps) => ({
  actions: {
    ...bindActionCreators(getRunnerTableActions(props.sessionId), dispatch),
    variable: {
      ...bindActionCreators(variableActions, dispatch),
    },
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: ComponentProps) => ({
    variablesGroups: getVariablesGroupsSelector(state, props.script.id),
    variables: variableDataSelectors.getOrderedDataSelector(state, VARIABLES_SECTIONS.LOCAL),
    steps: runnerDataSelectors.getOrderedDataSelector(state, props.sessionId),
    globalMutables: getScriptGlobalMutables(state, props.script.id),
    globalConstants: getScriptGlobalConstants(state, props.script.id),
    codeTemplates: codeTemplatesDataSelectors.getOrderedDataSelector(
      state,
      CODE_TEMPLATES_TABLES_CONFIG.MAIN.id(),
    ),
    ...props,
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withTranslation()(connectCreator(NewStepContainer));
