import * as React from "react";

import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { Role } from "@ea/shared_types/types";

export const ROLES_COLUMNS_CONFIG: PlainObject<CustomColumn<Role>> = {
  id: {
    label: "Id",
    dataIndex: "id",
  },
  name: {
    dataIndex: "name",
    label: "table.name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  description: {
    dataIndex: "description",
    label: "table.description",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
};

export const ROLES_COLUMNS: ColumnConfig<Role>[] = [
  {
    props: ROLES_COLUMNS_CONFIG.id,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: ROLES_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "40%",
    },
  },
  {
    props: ROLES_COLUMNS_CONFIG.description,
    frameworkProps: {
      width: "40%",
    },
  },
];

export const ROLES_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_ROLES",
  },
  USER_ROLES: {
    id: (id: number | string) => `USER_ROLES_${id}`,
    preferencesId: "USER_ROLES",
  },
  USER_ROLES_ASSIGNMENT: {
    id: (id: number | string) => `USER_ROLES_ASSIGNMENT_${id}`,
    preferencesId: "USER_ROLES_ASSIGNMENT",
  },
};
