import * as qs from "qs";
import * as React from "react";
import { Route, RouteComponentProps, withRouter } from "react-router";

import { ScriptLogs } from "@app/modules/logs/ScriptLogs";
import { StepLogs } from "@app/modules/logs/StepLogs";
import { ROUTES } from "@app/routes";

interface IScriptsPageProps {
  id: number;
}

const ScriptLogsPage: React.FunctionComponent<IScriptsPageProps & RouteComponentProps<any>> = (
  props,
) => {
  return (
    <React.Fragment>
      <Route
        path={`${props.match.url}${ROUTES.history}`}
        exact
        render={() => <ScriptLogs id={props.id} />}
      />
      <Route
        path={`${props.match.url}${ROUTES.history}/:sessionId`}
        render={(routerProps) => {
          const scriptLogId = qs.parse(routerProps.location.search.substr(1)).scriptLogId;
          return scriptLogId ? <StepLogs scriptLogId={scriptLogId} /> : <ScriptLogs {...props} />;
        }}
      />
    </React.Fragment>
  );
};

export default withRouter(ScriptLogsPage);
