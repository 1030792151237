import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { projectSelectors } from "./projects.selectors";
import EditeableAreaFinal from "@ea/shared_components/EditeableArea/EditeableAreaFinal";
import ProjectForm from "./components/ProjectForm";
import { systemDictionaryDataSelectors } from "../systemDictionary/systemDictionary.selectors";
import { SYSTEM_DICTIONARY_TABLES_CONFIG } from "../systemDictionary/systemDictionary.table";
import { virtualUsersDataSelectors } from "../virtualUsers/virtualUsers.selectors";
import { VIRTUAL_USERS_TABLES_CONFIG } from "../virtualUsers/virtualUsers.table";
import { API } from "@app/services/api/api";
import { Project, User, FEATURES } from "@ea/shared_types/types";
import { bindActionCreators, Dispatch } from "redux";
import { projectActions } from "./projects.actions";
import { LOGS_COLUMNS_CONFIG, LOGS_TABLES_CONFIG } from "../logs/logs.table";
import { injectIntl, InjectedIntlProps } from "react-intl";
import styled from "@emotion/styled";
import ProjectExecutionChartContainer from "../logs/statistics/ProjectExecutionChart.container";
import { isSame } from "@ea/shared_components/utils/array";
import AggregatedJobExecutionBarChartContainer from "../logs/statistics/AggregatedJobExecutionBarChart.container";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { HideWhenDocumentationWizard } from "../common/HideWhenDocumentationWizard";

interface IProjectDetailsProps {
  project: Project;
  isRunning: boolean;
  isBeingDispatched: boolean;
  testPlansFormChunk?: (() => React.ReactNode)[];
}
interface IProjectDetailsState {}

type Props = IProjectDetailsProps & InjectedIntlProps & IConnectProps;

const Container = styled.div({
  display: "flex",
  flexDirection: "row",
  minWidth: "450px",
  maxWidth: "600px",
});
const ChartContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  minWidth: "300px",
});

const AUTO_RELOAD_TIME = 3000;
class ProjectDetails extends React.Component<Props, IProjectDetailsState> {
  statisticsContainer;

  state = {};

  onSave = async (values) => {
    await API.editProject({ ...values, id: this.props.project.id });
    this.props.actions.loadSingle({ id: this.props.project.id });
  };

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.project.id !== this.props.project.id ||
      prevProps.project.lastSchedulerId !== this.props.project.lastSchedulerId ||
      prevProps.isRunning !== this.props.isRunning ||
      (!prevProps.project.statusesToRun && this.props.project.statusesToRun) ||
      (prevProps.project.statusesToRun && this.props.project.statusesToRun
        ? !isSame(prevProps.project.statusesToRun!, this.props.project.statusesToRun!)
        : false) ||
      (!prevProps.project.tagsToRun && this.props.project.tagsToRun) ||
      (prevProps.project.tagsToRun && this.props.project.tagsToRun
        ? !isSame(prevProps.project.tagsToRun!, this.props.project.tagsToRun!)
        : false)
    ) {
      this.statisticsContainer?.reload();
    }
  }

  normalizeDetails = (project: Project) => {
    if (!project) {
      return null;
    }
    const {
      category,
      description,
      dueDate,
      startDate,
      owners,
      version,
      name,
      runMode,
      statusesToRun,
      tagsToRun,
      executionParams,
      integrationMetadata,
      documentationTemplate,
      reportTemplate,
      maximumParallelSessions,
    } = project;

    const normalizedOwners = (owners || []).map((o) => (typeof o === "object" ? (o as any).id : o));
    // there maybe some records in database with array of strings instead of array of numbers
    const normalizedTagsToRun = tagsToRun ? tagsToRun.map(Number) : tagsToRun;

    return {
      category,
      description,
      dueDate,
      startDate,
      owners: normalizedOwners,
      version,
      name,
      runMode,
      statusesToRun,
      integrationMetadata,
      tagsToRun: normalizedTagsToRun,
      executionParams,
      documentationTemplate,
      reportTemplate,
      maximumParallelSessions,
    };
  };

  getInitialOwners = (): User[] => {
    const { project } = this.props;
    if (!project || !project.owners) {
      return [];
    }

    return project.owners.filter((o) => typeof o === "object") as any as User[];
  };

  render() {
    const { project, intl, isRunning, isBeingDispatched, testPlansFormChunk } = this.props;
    const filter = {
      where: {
        schedulerJobId: this.props.project.lastSchedulerId,
        projectId: { inq: [this.props.project.id] },
      },
    };

    return (
      <Container>
        <div
          style={{
            minWidth: "450px",
            marginRight: "50px",
          }}
        >
          <EditeableAreaFinal
            key={project.id}
            onSave={this.onSave}
            initialValues={this.normalizeDetails(project)}
            render={(props) => (
              <ProjectForm
                {...props}
                initialOwners={this.getInitialOwners()}
                additionalFormChunks={testPlansFormChunk}
              />
            )}
          />
        </div>
        <HideWhenDocumentationWizard>
          {() => (
            <ChartContainer>
              <ProjectExecutionChartContainer
                projectId={project.id}
                columnsConfig={LOGS_COLUMNS_CONFIG}
                tableId={LOGS_TABLES_CONFIG.PROJECT_HISTORY.id(project.id)}
                title={intl.formatMessage({
                  id: getTranslationKey("common", "label", "executionStatus"),
                })}
                customFilter={filter}
                isRunning={isRunning}
                autoReload={isRunning ? AUTO_RELOAD_TIME : false}
                setRef={(component) => (this.statisticsContainer = component)}
                isBeingDispatched={isBeingDispatched}
              />
              <AggregatedJobExecutionBarChartContainer
                projectId={this.props.project.id}
                title={intl.formatMessage({
                  id: getTranslationKey("common", "label", "progression5"),
                })}
                autoReload={isRunning ? AUTO_RELOAD_TIME : false}
              />
            </ChartContainer>
          )}
        </HideWhenDocumentationWizard>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(projectActions, dispatch),
  },
});

const connectCreator = connect((state: ApplicationState, props: IProjectDetailsProps) => {
  return {
    project: projectSelectors.getItemSelector(state, props.project.id),
    systems: systemDictionaryDataSelectors.getOrderedDataSelector(
      state,
      SYSTEM_DICTIONARY_TABLES_CONFIG.ALL.id(),
    ),
    virtualUsers: virtualUsersDataSelectors.getOrderedDataSelector(
      state,
      VIRTUAL_USERS_TABLES_CONFIG.ALL.id(),
    ),
  };
}, mapDispatchToProps);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(injectIntl(ProjectDetails));
