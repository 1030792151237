import { Modal, Spin } from "antd";
import React, { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import { ApiOnlineContext } from "./ApiOnlineContext";
import {
  registerOnCloseWebsocketAction,
  registerOnOpenWebsocketAction,
  startWebSocket,
  ws,
} from "./websocket";
export interface ApiOnlineProps {
  children: React.ReactNode;
  changeOffline?: Function;
  currentUserId: number;
}

export interface ApiOnlineState {
  isOnline: boolean;
  initialConnection: boolean;
}

let reconnecting: boolean = false;
let timeoutId: number = 0;
let toastId: number | string;

class ApiOnline extends Component<ApiOnlineProps & InjectedIntlProps, ApiOnlineState> {
  static contextType = ApiOnlineContext;
  state = {
    isOnline: false,
    initialConnection: true,
  };
  modal;
  onOpenWebsocket: () => void;
  onCloseWebsocket: () => void;
  constructor(props) {
    super(props);
    this.onOpenWebsocket = this.onConnectionEstablished.bind(this);
    this.onCloseWebsocket = this.onConnectionLost.bind(this);
    registerOnOpenWebsocketAction(this.onOpenWebsocket, "onConnectionEstablished");
    registerOnCloseWebsocketAction(this.onCloseWebsocket, "onConnectionLost");
  }

  // Start new websocket when currentUserId changed (for example from undefined to defined)
  // When its initial connection and websocket state is closing or closed
  componentDidUpdate(prevProps) {
    if (
      (prevProps.currentUserId !== this.props.currentUserId ||
        !ws ||
        (ws && (ws.readyState === 2 || ws.readyState === 3))) &&
      !!this.props.currentUserId
    ) {
      startWebSocket(this.props.currentUserId);
    }
  }

  restartConnection = () => {
    reconnecting = true;
    startWebSocket(this.props.currentUserId);
    const timeout = window.setTimeout(() => {
      if (ws && (ws.readyState === 2 || ws.readyState === 3)) {
        this.restartConnection();
      } else {
        reconnecting = false;
      }
    }, 5000);
    timeoutId = timeout;
  };

  onConnectionLost = () => {
    this.restartConnection();

    if (!this.state.isOnline) {
      return;
    }

    this.modal = Modal.error({
      title: this.props.intl.formatMessage({ id: "messages.error.connectionError" }),
      content: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          <Spin size="large" style={{ marginRight: 15 }} />
          {this.props.intl.formatMessage({ id: "messages.info.reconnectAttempt" })}
        </div>
      ),
      okButtonProps: { style: { display: "none" } },
      centered: true,
      visible: !this.state.isOnline,
    });

    this.setState({
      isOnline: false,
    });
  };

  clearTimeout = () => {
    window.clearTimeout(timeoutId);
  };

  onConnectionEstablished = () => {
    this.clearTimeout();
    if (this.state.initialConnection) {
      this.setState({
        initialConnection: false,
        isOnline: true,
      });
      return;
    }
    if (!toast.isActive(toastId)) {
      toastId = toast.success(
        this.props.intl.formatMessage({ id: "messages.success.reconnected" }),
      );
    }

    this.setState({
      isOnline: true,
    });

    if (this.modal) {
      this.modal.destroy();
    }
  };

  render() {
    return (
      <ApiOnlineContext.Provider value={{ isOnline: this.state.isOnline }}>
        {this.props.children}
      </ApiOnlineContext.Provider>
    );
  }
}

export default injectIntl(ApiOnline);
