import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { CodeTemplateGroup } from "@ea/shared_types/types";
import { moduleActions } from "./codeTemplateGroups.actions";

const initialState = {
  ...getDataReducerInitialState<CodeTemplateGroup>(),
};

export type CodeTemplateGroupReducer = typeof initialState;

const dataReducer = createDataReducer<CodeTemplateGroup>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
