import * as React from "react";
import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import DynamicIcon from "../common/DynamicIcon";

const FormButton: React.FunctionComponent<ButtonProps> = ({
  onClick,
  children,
  icon,
  style,
  loading,
  ...props
}) => (
  <div className="ant-row ant-form-item">
    <div className="ant-col-8 ant-form-item-label" />
    <div className="ant-col-15 ant-form-item-control-wrapper">
      <Button
        style={{ float: "right", ...style }}
        onClick={onClick as (event: React.MouseEvent<HTMLButtonElement>) => void}
        loading={loading}
        {...props}
      >
        {!loading && icon}
        {children}
      </Button>
    </div>
  </div>
);

export default FormButton;
