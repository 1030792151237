import {
  IFrameMessage,
  SyncMessageHandler,
  RunnerIFrameAPI,
  CreateRunnerIFrameAPI,
  RunneIFrameEndpoints,
  AsyncMessageHandler,
} from "../communication.types";
import { RUNNER_FRAME_ID } from "../types";

const MAXIMUM_RESPONSE_TIME = 7 * 1000;

type SendType = "ALL" | "ROOT";

function sendToAll(message: any) {
  const allFrames: any[] = [];

  function getFrames(w) {
    for (let i = 0; i < w.frames.length; i++) {
      allFrames.push(w.frames[i]);
      getFrames(w.frames[i]);
    }
  }

  getFrames(window);

  allFrames.forEach((f) => {
    f.postMessage({ message }, "*");
  });
}

function sendToRoot(message: any) {
  const frame = document.getElementById(RUNNER_FRAME_ID) as any;

  if (!frame) {
    return;
  }

  frame.contentWindow.postMessage({ message }, "*");
}

const createWebSocket = <K extends CreateRunnerIFrameAPI<any, any>>(apiStructure: K) => {
  const sources = [RunneIFrameEndpoints.administration, RunneIFrameEndpoints.runner];
  const FILTER_KEY = "__EA_APP__";

  const onMessageHandlers: { [key: string]: SyncMessageHandler[] } = {};
  const onSyncMessageHandlers: { [key: string]: SyncMessageHandler } = {};

  const createMessage = (message: IFrameMessage<any, any>) => {
    const { type, value } = message;
    return {
      type,
      value,
      filter: FILTER_KEY,
    };
  };

  const callSyncHandler = (handler: SyncMessageHandler, source: any) => async (data: any) => {
    const { type, value } = data;
    const result = await handler.callback(value);

    const message = createMessage({
      value: result,
      type: `${type.substring(0, type.length - 8)}_response`,
    });

    source?.postMessage({ message }, "*");

    // sendToRoot(message);
  };

  const callAllHandlers = (handlers: AsyncMessageHandler[]) => (value: any) => {
    handlers.forEach((h) => h.callback(value));
  };

  const onIFrameMessage = (data: IFrameMessage<any, any>, source: any) => {
    const { type, value } = data;
    const handlers = onMessageHandlers[type];
    if (handlers) {
      callAllHandlers(handlers)(value);
    }
    const syncHandlers = onSyncMessageHandlers[type];
    if (syncHandlers) {
      callSyncHandler(syncHandlers, source)(data);
    }
  };

  const parseMessage = (data: any) => {
    if (!data) {
      return undefined;
    }

    if (data.message?.filter === FILTER_KEY) {
      return data.message;
    }

    if (data?.filter === FILTER_KEY) {
      return data;
    }

    return undefined;
  };

  const sendSync = (message: IFrameMessage<any, any>, sendFunction: (message: any) => void) => {
    return new Promise((resolve, reject) => {
      let timeoutDestroyer;

      const reaction = (event) => {
        if (!event.data) {
          return;
        }
        const data: IFrameMessage<any, any> = parseMessage(event.data);

        if (!data) {
          return;
        }

        if (data.type !== `${message.type}_response`) {
          return;
        }

        if (timeoutDestroyer) {
          clearTimeout(timeoutDestroyer);
        }
        window.removeEventListener("message", reaction, false);
        resolve(data.value);
      };

      window.addEventListener("message", reaction, false);

      timeoutDestroyer = setTimeout(() => {
        window.removeEventListener("message", reaction, false);
        reject(`Response haven't been received for message: ${JSON.stringify(message)}`);
      }, MAXIMUM_RESPONSE_TIME);

      const messageRequest = createMessage({
        ...message,
        type: `${message.type}_request`,
      });

      sendFunction(messageRequest);
    });
  };

  function listenOnIframeMessages() {
    const onMessage = (event) => {
      if (!event.data) {
        return;
      }
      const message: IFrameMessage<any, any> = parseMessage(event.data);
      if (!message) {
        return;
      }

      onIFrameMessage(message, event.source);
    };

    window.addEventListener("message", onMessage, false);

    return () => {
      window.removeEventListener("message", onMessage, false);
    };
  }

  const registerHandler =
    (handlerStorage: (SyncMessageHandler | AsyncMessageHandler)[]) =>
    (handler: SyncMessageHandler | AsyncMessageHandler) => {
      const compareHandler = (h) => {
        if (handler.key) {
          return handler.key === h.key;
        }

        return handler.callback === h.callback;
      };

      let existingHandler = handlerStorage.find(compareHandler);

      if (existingHandler) {
        existingHandler.callback = handler.callback;
      }

      if (!existingHandler) {
        handlerStorage.push(handler);
      }

      return () => {
        const index = handlerStorage.findIndex(compareHandler);
        if (index >= 0) {
          handlerStorage.splice(index, 1);
        }
      };
    };

  const destroyAllHandlers = () => {
    Object.keys(onMessageHandlers).forEach((k) => {
      onMessageHandlers[k].length = 0;
    });
  };

  listenOnIframeMessages();

  return {
    [RunneIFrameEndpoints.administration]: {
      destroyAllHandlers,
      registerMessageHandlerPromise: <P extends keyof K[RunneIFrameEndpoints.runner]["sync"]>(
        type: K[RunneIFrameEndpoints.runner]["sync"][P]["request"]["type"],
        handler: SyncMessageHandler<
          K[RunneIFrameEndpoints.runner]["sync"][P]["request"]["value"],
          {},
          K[RunneIFrameEndpoints.runner]["sync"][P]["response"]["value"]
        >,
      ) => {
        onSyncMessageHandlers[`${type}_request`] = handler;
        return;
      },
      registerMessageHandler: <P extends keyof K[RunneIFrameEndpoints.runner]["async"]>(
        type: K[RunneIFrameEndpoints.runner]["async"][P]["type"],
        handler: AsyncMessageHandler<K[RunneIFrameEndpoints.runner]["async"][P]["value"], {}>,
      ) => {
        if (!onMessageHandlers[type as string]) {
          onMessageHandlers[type as string] = [];
        }
        return registerHandler(onMessageHandlers[type as string])(handler);
      },
      sendPromise: <P extends keyof K[RunneIFrameEndpoints.administration]["sync"]>(
        type: P,
        value: K[RunneIFrameEndpoints.administration]["sync"][P]["request"]["value"],
        sendType?: SendType,
      ): Promise<K[RunneIFrameEndpoints.administration]["sync"][P]["response"]["value"]> => {
        return sendSync({ type, value }, sendType === "ALL" ? sendToAll : sendToRoot);
      },
      send: <P extends keyof K[RunneIFrameEndpoints.administration]["async"]>(
        type: P,
        value: K[RunneIFrameEndpoints.administration]["async"][P]["value"],
        sendType?: SendType,
      ) => {
        const message = createMessage({ type, value });

        if (sendType === "ALL") {
          sendToAll(message as any);
          return;
        }

        sendToRoot(message);
      },
    },
    [RunneIFrameEndpoints.runner]: {
      destroyAllHandlers,
      registerMessageHandlerPromise: <
        P extends keyof K[RunneIFrameEndpoints.administration]["sync"],
      >(
        type: K[RunneIFrameEndpoints.administration]["sync"][P]["request"]["type"],
        handler: SyncMessageHandler<
          K[RunneIFrameEndpoints.administration]["sync"][P]["request"]["value"],
          {},
          K[RunneIFrameEndpoints.administration]["sync"][P]["response"]["value"]
        >,
      ) => {
        onSyncMessageHandlers[`${type}_request`] = handler;
        return;
      },
      registerMessageHandler: <P extends keyof K[RunneIFrameEndpoints.administration]["async"]>(
        type: K[RunneIFrameEndpoints.administration]["async"][P]["type"],
        handler: AsyncMessageHandler<
          K[RunneIFrameEndpoints.administration]["async"][P]["value"],
          {}
        >,
      ) => {
        if (!onMessageHandlers[type as string]) {
          onMessageHandlers[type as string] = [];
        }

        return registerHandler(onMessageHandlers[type as string])(handler);
      },
      sendPromise: <P extends keyof K[RunneIFrameEndpoints.runner]["sync"]>(
        type: P,
        value: K[RunneIFrameEndpoints.runner]["sync"][P]["request"]["value"],
      ): Promise<K[RunneIFrameEndpoints.runner]["sync"][P]["response"]["value"]> => {
        return sendSync({ type, value }, (message) => window.top?.postMessage(message, "*"));
      },
      send: <P extends keyof K[RunneIFrameEndpoints.runner]["async"]>(
        type: P,
        value: K[RunneIFrameEndpoints.runner]["async"][P]["value"],
      ) => {
        const message = createMessage({ type, value });

        window.top?.postMessage(message, "*");
      },
    },
  };
};

const fakeObject: RunnerIFrameAPI = {} as any;

export const runnerCommunicator = createWebSocket<RunnerIFrameAPI>(fakeObject);
