import * as React from "react";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import { FormattedMessage } from "react-intl";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import {
  Step,
  CONDITIONAL_TYPE,
  INTERRUPTION_TYPE,
  Script,
  Kpi,
  GlobalVariable,
} from "@ea/shared_types/types";
import { getIn } from "final-form";
import RadioField, { RadioType } from "@ea/shared_components/Form/Fields/RadioField";
import ReactionsFormChunk from "./ReactionsFormChunk";
import { FieldArray } from "react-final-form-arrays";
import FormButton from "@ea/shared_components/Form/FormButton";
import { Variable } from "@ea/shared_types/types";
import { Collapse } from "antd";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
const { Panel } = Collapse;

import { getTranslationKey } from "@app/translations/translations.helpers";
import { getStepOptions } from "@app/utils/getStepOptions";
interface IConditionalFormProps {
  steps: Step[];
  values: any;
  change: any;
  variables: Variable[];
  script: Script;
  kpis?: Kpi[];
  readOnly?: boolean;
  globalMutable?: GlobalVariable[];
  globalConstant?: GlobalVariable[];
  expandAllCollapse?: boolean;
}

interface IConditionalFormState {
  activeKey: string | string[];
}

class ConditionalForm extends React.Component<IConditionalFormProps, IConditionalFormState> {
  state: IConditionalFormState = {
    activeKey: ["0"],
  };

  onTypeChange = ({ target }) => {
    const { change } = this.props;
    const { value } = target;
    if (value === CONDITIONAL_TYPE.SUCCESS) {
      change(`reactions`, [{ reactOn: CONDITIONAL_TYPE.SUCCESS }]);
    }
    if (value === CONDITIONAL_TYPE.INTERRUPTION) {
      change(`reactions`, [{}]);
    }
  };

  onCollapseChange(activeKey) {
    this.setState({ activeKey });
  }

  render() {
    const {
      steps,
      values,
      change,
      variables,
      script,
      readOnly,
      globalConstant,
      globalMutable,
      kpis,
      expandAllCollapse,
    } = this.props;
    const type: CONDITIONAL_TYPE = getIn(values, "type");

    return (
      <>
        <InputField
          name="name"
          required
          placeholder={getTranslationKey("common", "label", "name")}
          label={getTranslationKey("common", "placeholder", "name")}
        />
        <SelectField
          name="steps"
          required
          mode="multiple"
          allowClear
          placeholder={getTranslationKey("guards", "steps")}
          label={getTranslationKey("guards", "steps")}
          options={getStepOptions(steps, "id")}
        />
        <RadioField
          name="type"
          required
          label={getTranslationKey("conditionals", "type")}
          type={RadioType.Button}
          options={[
            {
              text: getTranslationKey("conditionals", CONDITIONAL_TYPE.SUCCESS),
              value: CONDITIONAL_TYPE.SUCCESS,
            },
            {
              text: getTranslationKey("conditionals", CONDITIONAL_TYPE.INTERRUPTION),
              value: CONDITIONAL_TYPE.INTERRUPTION,
            },
          ]}
          onChange={this.onTypeChange}
        />

        <FieldArray name={"reactions"}>
          {({ fields }) => {
            return (
              <>
                {type === CONDITIONAL_TYPE.INTERRUPTION && (
                  <FormButton
                    disabled={
                      readOnly || (fields.length || 0) === Object.keys(INTERRUPTION_TYPE).length
                    }
                    onClick={() => {
                      fields.push({});
                      this.setState({
                        activeKey: [...(this.state.activeKey || []), `${fields.length || 0}`],
                      });
                    }}
                    icon={<PlusOutlined />}
                  >
                    <FormattedMessage id={getTranslationKey("conditionals", "addReaction")} />
                  </FormButton>
                )}

                {fields && fields.length && fields.length > 0 ? (
                  <Collapse
                    activeKey={
                      expandAllCollapse
                        ? fields.map((pathObject, index) => index)
                        : this.state.activeKey
                    }
                    onChange={(key) => this.onCollapseChange(key)}
                  >
                    {fields.map((pathObject, index) => (
                      <Panel
                        disabled={readOnly}
                        header={
                          <>
                            <FormattedMessage id={getTranslationKey("conditionals", type)} />
                          </>
                        }
                        key={`${index}`}
                        extra={
                          !readOnly &&
                          type === CONDITIONAL_TYPE.INTERRUPTION && (
                            <>
                              {index > 0 && (
                                <ArrowUpOutlined
                                  onClick={(event) => {
                                    fields.move(index, index - 1);
                                    event.stopPropagation();
                                  }}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                              {fields && fields.length && index < fields.length - 1 && (
                                <ArrowDownOutlined
                                  onClick={(event) => {
                                    fields.move(index, index + 1);
                                    event.stopPropagation();
                                  }}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                              {index > 0 && (
                                <DeleteOutlined
                                  onClick={(event) => {
                                    fields.remove(index);
                                    event.stopPropagation();
                                  }}
                                  style={{ color: "red" }}
                                />
                              )}
                            </>
                          )
                        }
                      >
                        <ReactionsFormChunk
                          reactionIndex={index}
                          change={change}
                          values={values}
                          stepOptions={getStepOptions(steps, "id")}
                          steps={steps}
                          variables={variables}
                          script={script}
                          kpis={kpis}
                          readOnly={readOnly}
                          globalMutable={globalMutable}
                          globalConstant={globalConstant}
                        />
                      </Panel>
                    ))}
                  </Collapse>
                ) : null}
              </>
            );
          }}
        </FieldArray>
      </>
    );
  }
}
export default ConditionalForm;
