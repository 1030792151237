import * as React from "react";
import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { Storage } from "@ea/shared_types/types";
import StorageTypeField from "@app/modules/storage/components/StorageTypeField";
import ApiKeyTokenBox from "@app/modules/common/ApiKeyTokenBox";
import { getTranslationKey } from "@app/translations/translations.helpers";

export const STORAGE_COLUMNS_CONFIG: PlainObject<CustomColumn<Storage>> = {
  name: {
    label: getTranslationKey("table", "name"),
    dataIndex: "name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  account: {
    label: getTranslationKey("table", "account"),
    dataIndex: "account",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  apiKey: {
    label: getTranslationKey("table", "apiKey"),
    dataIndex: "apiKey",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    render: (text) => <ApiKeyTokenBox token={text} />,
  },
  type: {
    label: getTranslationKey("table", "storageType"),
    dataIndex: "storageType",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    render: (text) => <StorageTypeField type={text} />,
  },
};

export const STORAGE_COLUMNS: ColumnConfig<Storage>[] = [
  {
    props: STORAGE_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: STORAGE_COLUMNS_CONFIG.account,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: STORAGE_COLUMNS_CONFIG.apiKey,
    frameworkProps: {
      width: "45%",
    },
  },
  {
    props: STORAGE_COLUMNS_CONFIG.type,
    frameworkProps: {
      width: "25%",
    },
  },
];

export const STORAGE_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_STORAGE",
  },
};
