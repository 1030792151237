import styled from "@emotion/styled";
import { Button } from "antd";
import * as React from "react";

export interface IFilterControlProps {
  setValue: (value: any) => void;
  value: any;
  isOpen: boolean;
  filter: () => void;
  fieldKey: string;
}

interface SetFilterArgs {
  fieldName: string;
  fieldValue: any;
}

interface IFilterContainerProps {
  isOpen: boolean;
  render: (args: IFilterControlProps) => JSX.Element;
  fieldKey: string;
  setFilter: (args: SetFilterArgs) => void;
  clearFilters: ({ fields }: { fields: string[] | undefined }) => void;
  changeVisibility: (fiterName: string, visibility: boolean) => void;
  value?: any;
}

interface IFilterContainerState {
  value: any;
  error: string;
}

const Container = styled.div({
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
  background: "white",
  padding: "10px",
  maxWidth: "400px",
});

const FilterControlContainer = styled.div({});
const ButtonsContainer = styled.div({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "10px",
});

const ErrorContainer = styled.div({
  color: "red",
  marginTop: "5px",
});
class FilterContainer extends React.Component<IFilterContainerProps, IFilterContainerState> {
  constructor(props: IFilterContainerProps) {
    super(props);

    this.state = {
      value: props.value,
      error: "",
    };
  }

  componentWillReceiveProps(nextProps: IFilterContainerProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  onOk = (event?: any) => {
    const { setFilter, fieldKey, changeVisibility } = this.props;
    const { value } = this.state;

    if (!value && fieldKey.includes("id") && isNaN(+value)) {
      this.setState({
        error: "Value must be a number",
      });
      return;
    }

    if (value) {
      setFilter({ fieldName: fieldKey, fieldValue: value });
    } else {
      this.onReset();
    }
    changeVisibility(fieldKey, false);

    this.setState({
      error: "",
    });

    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
  };

  onReset = (event?: any) => {
    const { clearFilters, fieldKey, changeVisibility } = this.props;
    const { value } = this.state;
    clearFilters({ fields: [fieldKey] });
    this.setValue(undefined);
    changeVisibility(fieldKey, false);

    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
  };

  setValue = (value) => this.setState({ value });

  render() {
    const { render, isOpen, fieldKey } = this.props;
    const { value, error } = this.state;
    return (
      <Container>
        <FilterControlContainer>
          {render({
            setValue: this.setValue,
            value,
            isOpen,
            filter: this.onOk,
            fieldKey,
          })}
        </FilterControlContainer>
        <ButtonsContainer>
          <Button onClick={this.onReset}> Reset </Button>
          <Button type="primary" onClick={this.onOk}>
            {" "}
            OK{" "}
          </Button>
        </ButtonsContainer>
        {error && <ErrorContainer>{error}</ErrorContainer>}
      </Container>
    );
  }
}

export default FilterContainer;
