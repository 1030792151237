import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import StepPanelForm, { PanelStep } from "@ea/shared_components/PanelForm/StepPanelForm";
import { ApplicationState } from "@app/modules/app.reducers";
import SchedulerSelectScriptsForm from "./SchedulerSelectScriptsForm";
import SchedulerEditSelectedForm, { SchedulerSelectedScript } from "./SchedulerEditSelectedForm";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DeepOmit } from "ts-essentials";
import { SchedulerGroup } from "@ea/shared_types/types";

export enum SchedulerAddScriptPanelModes {
  ADD = "ADD",
  ADD_TO_EXISTING = "ADD_TO_EXISTING",
}

export type SchedulerAddScriptPanelModeType = SchedulerAddScriptPanelModes;
interface ISchedulerAddScriptsPanelProps {
  mode: SchedulerAddScriptPanelModeType;
  isOpen: boolean;
  close: () => void;
  scripts?: any[];
  selectedGroup?: SchedulerGroup;
  onPanelSave: (
    scripts: SchedulerSelectedScript[],
    createAsGroup: boolean,
    group?: DeepOmit<
      SchedulerGroup,
      { id: never; lineNum: never; schedulerJobId: never; scripts: { id: never } }
    >,
  ) => void;
}

class SchedulerAddScriptsPanel extends React.Component<
  ISchedulerAddScriptsPanelProps & IConnectProps,
  any
> {
  onSave = (values: {
    schedulerScripts: SchedulerSelectedScript[];
    groupName: string;
    createAsGroup: boolean;
  }) => {
    const { schedulerScripts, groupName, createAsGroup } = values;
    const { mode, selectedGroup, onPanelSave } = this.props;

    const group:
      | DeepOmit<
          SchedulerGroup,
          { id: never; lineNum: never; schedulerJobId: never; scripts: { id: never } }
        >
      | undefined =
      createAsGroup && mode !== SchedulerAddScriptPanelModes.ADD_TO_EXISTING
        ? {
            name: groupName,
            runParams: {
              run: undefined,
            },
            scripts: [],
          }
        : createAsGroup && mode === SchedulerAddScriptPanelModes.ADD_TO_EXISTING
        ? selectedGroup
        : undefined;

    onPanelSave(schedulerScripts, createAsGroup, group);
  };

  render() {
    const { isOpen, close, mode, scripts } = this.props;

    const panelSteps: PanelStep[] = [
      {
        title: getTranslationKey("scheduler", "flow", "addPanel", "selectScripts"),
        render: (props) => <SchedulerSelectScriptsForm {...props} />,
      },
      {
        title: getTranslationKey("scheduler", "flow", "addPanel", "scriptsDetails"),
        render: (props) => <SchedulerEditSelectedForm {...props} scripts={scripts} mode={mode} />,
      },
    ];

    return (
      <StepPanelForm
        visibility={isOpen}
        onCancelClick={close}
        onOkClick={this.onSave}
        headerText={getTranslationKey("scheduler", "flow", "addPanel", "addScripts")}
        steps={panelSteps}
        render={() => <> </>}
      />
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState) => ({
    scripts: state.scripts.items,
  }),
  {},
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(SchedulerAddScriptsPanel);
