import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { SchedulerJob, SchedulerJobBasic } from "@ea/shared_types/types";
import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import { Dispatch } from "redux";
import { ApplicationState } from "../../app.reducers";
import CloneSchedulerJobForm from "./CloneSchedulerJobForm";

interface SchedulerClonePanelContainerProps {
  visibility: boolean;
  close: () => void;
  onCreate?: () => void;
  schedulerToClone: SchedulerJob;
}

class SchedulerClonePanelContainer extends React.Component<
  SchedulerClonePanelContainerProps & IConnectProps
> {
  onOkClick = async (values) => {
    const { name } = values;
    const result = await API.cloneSchedulerJob({
      schedulerId: this.props.schedulerToClone.id,
      name,
    });

    if (this.props.onCreate) {
      this.props.onCreate();
    }

    this.props.close();
  };

  render() {
    const { schedulerToClone, visibility } = this.props;

    const { name, mode, triggerType } = schedulerToClone as SchedulerJobBasic;
    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.SMALL}
        headerText={<div>Clone scheduler</div>}
        onCancelClick={this.props.close}
        onOkClick={this.onOkClick}
        allowPrisitineSubmit
        cancelButtonText={getTranslationKey("commandBar", "close")}
        okButtonText={"Clone"}
        initialValues={{
          name: name + " (1)",
          mode,
          triggerType: (schedulerToClone as any).triggerType,
        }}
        render={({ change, values }) => (
          <CloneSchedulerJobForm {...this.state} values={values} change={change} />
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({});

const connectCreator = connect((state: ApplicationState) => ({}), mapDispatchToProps);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(SchedulerClonePanelContainer);
