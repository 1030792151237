import * as React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";
import withIconButton from "./withIconButton";

interface IRecordIconProps {
  isRecording?: boolean;
  size?: number;
  color?: string;
}

const bounce = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const Circle = styled.circle(
  {
    fill: "red",
    stroke: "maroon",
    strokeMiterlimit: 10 as any,
    strokeWidth: "2px",
  },
  ({ isRecording }: { isRecording: boolean }) =>
    isRecording
      ? {
          animation: `${bounce}`,
          animationDuration: "1s",
          animationDirection: "alternate",
          animationIterationCount: "infinite",
          animationTimingFunction: "cubic-bezier(0.86, 0, 0.07, 1)",
        }
      : {},
);

const RecordIcon: React.FunctionComponent<IRecordIconProps> = ({
  isRecording,
  size = 42,
  color = "red",
}) => (
  <svg
    fill={color}
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M24 24H0V0h24v24z" fill="none" />
    <Circle isRecording={isRecording || false} cx="12" cy="12" fill={color} r="8" />
  </svg>
);
export default withIconButton(RecordIcon);
