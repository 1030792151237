import styled from "@emotion/styled";
import * as qs from "qs";
import * as React from "react";
import { Route, RouteComponentProps, withRouter } from "react-router";

import BreadcrumbContainer from "@ea/shared_components/common/Breadcrumb.container";
import { BreadcrumbSection, MainSection, Page } from "@ea/shared_components/common/LayoutElements";
import { Logs } from "@app/modules/logs";
import { StepLogs } from "@app/modules/logs/StepLogs";

interface IScriptsPageProps {
  id: number;
}

const ScriptLogsPage: React.FunctionComponent<IScriptsPageProps & RouteComponentProps<any>> = (
  props,
) => {
  return (
    <Page>
      <BreadcrumbSection>
        <BreadcrumbContainer />
      </BreadcrumbSection>
      <MainSection>
        <Route path={`${props.match.url}`} exact render={() => <Logs {...props} />} />
        <Route exact path={`${props.match.url}/:id`} render={() => <Logs {...props} />} />
        <Route
          path={`${props.match.url}/:id/:sessionId`}
          render={(routerProps) => {
            const scriptLogId = qs.parse(routerProps.location.search.substr(1)).scriptLogId;
            return scriptLogId ? <StepLogs scriptLogId={scriptLogId} /> : <Logs {...props} />;
          }}
        />
      </MainSection>
    </Page>
  );
};

export default withRouter(ScriptLogsPage);
