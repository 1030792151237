import { createModuleEpics, makeAsyncEpic } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { Query } from "@ea/shared_components/services/api.common.models";
import { Project } from "@ea/shared_types/types";
import { moduleActions, projectsActionCreators } from "./projects.actions";
import { getProjectsChildren, getProjectsParents } from "./projects.query";
import { projectSelectors } from "./projects.selectors";

const includeFields = [
  "ProjectUserGroupMap.UserGroup",
  "ProjectSystemDictionaryMap.SystemDictionary",
  "ProjectOwnersMap.AppUser",
  "SystemDictionary",
  "ItsRule",
];

const includeFilter = [
  ...includeFields,
  {
    relation: "ProjectVirtualUserMap.VirtualUser",
  },
  {
    relation: "VirtualUser",
  },
];

const projectModuleEpics = {
  ...createModuleEpics<Project>(moduleActions, {
    getRequestParams: (state, tableId) => {
      const tableParams = projectSelectors.getParamsSelector(state, tableId!);
      const params = createRequestParams(tableParams, {}, { defaultOrder: ["name ASC"] });

      params.filter = {
        ...params.filter,
        include: "SystemDictionary",
      };

      return params;
    },
    normalizeItem: (item, state, mode) => {
      if (mode === "SINGLE") {
        return item;
      }

      const currentProject = state.projects.items[item.id];
      const { virtualUsers, groups, systems, owners, itsRules } = currentProject || {};
      return {
        virtualUsers: virtualUsers || [],
        groups: groups || [],
        systems: systems || [],
        owners: owners || [],
        itsRules: itsRules || [],
        ...item,
      };
    },
    getSingleRequestParams: (query, state) => {
      query.filter = {
        ...query.filter,
        include: includeFilter,
      };

      return query;
    },
    api: {
      createItem: API.createProject as any,
      deleteItem: API.deleteProject as any,
      editItem: API.editProject as any,
      getItems: API.getProjects as any,
    },
  }),
  refreshChildBranch: makeAsyncEpic(
    projectsActionCreators.refreshChildBranch,
    async (payload, state) => {
      const projectWithChildren = getProjectsChildren(payload, state);

      const params: Query<Project> = {
        filter: {
          where: {
            id: { inq: projectWithChildren },
          },
          include: includeFilter,
        },
      };

      const result = await API.getProjects(params);

      return result as any; // todo: NEW_TYPES
    },
  ),
  refreshParentBranch: makeAsyncEpic(
    projectsActionCreators.refreshParentBranch,
    async (payload, state) => {
      const projectWithParents = getProjectsParents(payload, state);
      if (projectWithParents.filter((p) => p).length === 0) {
        return [];
      }

      const params: Query<Project> = {
        filter: {
          where: {
            id: { inq: projectWithParents },
          },
          include: includeFilter,
        },
      };

      const result = await API.getProjects(params);

      return result as any; // todo: NEW_TYPES
    },
  ),
};

export default projectModuleEpics;
