import * as React from "react";
import { System } from "@ea/shared_types/types";
import SelectField, { SelectFieldProps } from "@ea/shared_components/Form/Fields/SelectField";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import Select from "antd/lib/select";
import SystemTypeField from "./SystemTypeField";
import { DataTestIds } from "@app/utils/dataTestIds";
type ISystemSelectFieldProps = Omit<SelectFieldProps, "options"> & {
  systems?: System[];
};

type SystemOption = OptionType & { envType: System["envType"] };

const convertToOptions = (systems: System[] = []): SystemOption[] =>
  systems.map(({ id, envName, envType, ...rest }) => ({
    value: id,
    text: envName,
    envType,
  }));

const renderOption = (option: SystemOption): JSX.Element => {
  return (
    <Select.Option
      value={option.value}
      key={`${option.value}`}
      data-testid={DataTestIds.getOptionTestId(option.value)}
    >
      {option.envType && <SystemTypeField type={option.envType} />}
      <span>{option.text}</span>
    </Select.Option>
  );
};

const SystemSelectField: React.FunctionComponent<ISystemSelectFieldProps> = ({
  systems = [],
  ...rest
}) => {
  const options = convertToOptions(systems || []);

  return (
    <SelectField
      data-testid={DataTestIds.FORM_SELECT_SYSTEM}
      filterOption={(input, option) =>
        options
          .filter((o) => o.value === option?.props.value)
          .some((o) => o.text!.toLowerCase().indexOf(input.toLowerCase()) > -1)
      }
      {...rest}
    >
      {options.map(renderOption)}
    </SelectField>
  );
};

export default SystemSelectField;
