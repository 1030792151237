import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { IssueTrackingToolConfiguration } from "@ea/shared_types/types";
import { moduleActions, itsConfigurationsActionCreators } from "./itsConfigurations.actions";

const initialState = {
  ...getDataReducerInitialState<IssueTrackingToolConfiguration>(),
};

export type ItsConfigurationsReducer = typeof initialState;

const dataReducer = createDataReducer<IssueTrackingToolConfiguration>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState)
  .case(itsConfigurationsActionCreators.fetch.started, (state, payload) => ({
    ...state,
    isLoading: true,
  }))
  .case(itsConfigurationsActionCreators.fetch.failed, (state, payload) => ({
    ...state,
    isLoading: false,
  }))
  .case(itsConfigurationsActionCreators.fetch.done, (state, payload) => ({
    ...state,
    isLoading: false,
  }));
