import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import BaseModalForm from "@ea/shared_components/ModalForm/BaseModalForm";
import { DEFAULT_LOCALE } from "@ea/shared_types/next/ea.consts";
import * as React from "react";
import TranslationForm from "./components/TranslationForm";

interface EditTranslationsProps {
  visible: boolean;
  onClose: () => void;
  localizationId?: number;
  locale?: string;
  isDefaultLocale?: boolean;
}

interface EditTranslationState {
  translations: any;
  loading: boolean;
}

class EditTranslations extends React.Component<EditTranslationsProps, EditTranslationState> {
  state = {
    translations: [],
    loading: false,
  };

  async componentDidMount() {
    const { visible } = this.props;
    if (!visible) {
      return;
    }

    await this.loadTranslations();
  }

  async componentDidUpdate(prevProps: EditTranslationsProps) {
    const { localizationId, visible } = this.props;
    if (!visible) {
      return;
    }
    if ((visible && !prevProps.visible) || prevProps.localizationId !== localizationId) {
      await this.loadTranslations();
    }
  }

  loadTranslations = async () => {
    const { localizationId, locale } = this.props;

    this.setState({
      loading: true,
    });
    const defaultTranslations = await API.getTranslations({
      locale: DEFAULT_LOCALE,
    });

    const translations = await API.getTranslations({ locale: locale! });
    this.setState({
      translations: Object.keys(translations).map((key) => ({
        key,
        value: translations[key],
        defaultValue: defaultTranslations[key],
      })),
      loading: false,
    });
  };

  saveTranslations = async (value) => {
    const { onClose, localizationId } = this.props;

    const translations = value.translations.reduce((prev, curr) => {
      prev[curr.key] = curr.value;
      return prev;
    }, {});

    await API.editLocalization({
      id: localizationId,
      translations,
    });

    onClose();
  };

  render() {
    const { visible, onClose, isDefaultLocale } = this.props;
    const { translations, loading } = this.state;
    return (
      <BaseModalForm
        initialValues={{ translations }}
        visible={visible}
        onCancelClick={onClose}
        onOkClick={this.saveTranslations}
        allowPrisitineSubmit={true}
        loading={loading}
        cancelButtonText={getTranslationKey("button", "cancel")}
        okButtonText={isDefaultLocale ? null : getTranslationKey("button", "save")}
        headerText={getTranslationKey("settings", "translate")}
        render={({ change, values }) => (
          <TranslationForm change={change} readOnly={isDefaultLocale} />
        )}
        formStyle={{
          display: "flex",
          padding: 0,
          flexDirection: "column",
          maxHeight: "75vh",
        }}
        width="60%"
      />
    );
  }
}

export default EditTranslations;
