import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import { SelectField } from "@ea/shared_components/Form/Fields/SelectField";
import { STORAGE_TYPE } from "@ea/shared_types/types";
import StorageTypeField from "@app/modules/storage/components/StorageTypeField";
import Select from "antd/lib/select";
import { DataTestIds } from "@app/utils/dataTestIds";

interface ICreateStorageFormProps {
  readOnly?: boolean;
  values?: any;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class CreateStorageForm extends React.Component<ICreateStorageFormProps> {
  envTypes;

  constructor(props: ICreateStorageFormProps) {
    super(props);

    this.envTypes = [
      {
        text: "azurestorage",
        value: STORAGE_TYPE.AZURE,
      },
    ];
  }

  customRender = (option: any): JSX.Element => {
    return (
      <Select.Option value={option.value} key={option.value}>
        {option.value && <StorageTypeField type={option.value} />}
      </Select.Option>
    );
  };

  render() {
    const { readOnly } = this.props;
    return (
      <FormLayout {...formItemLayout}>
        <InputField
          name="name"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("storage", "placeholder", "name")}
          label={getTranslationKey("common", "label", "name")}
          data-testid={DataTestIds.FORM_INPUT_NAME}
        />
        <SelectField
          name="storageType"
          placeholder={getTranslationKey("storage", "placeholder", "type")}
          required
          label={getTranslationKey("storage", "label", "type")}
          data-testid={DataTestIds.FORM_SELECT_STORAGE_TYPE}
        >
          {this.envTypes.map(this.customRender)}
        </SelectField>
        <InputField
          name="account"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("common", "placeholder", "account")}
          label={getTranslationKey("common", "label", "account")}
          data-testid={DataTestIds.FORM_INPUT_ACCOUNT}
        />
        <TextAreaField
          name="apiKey"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("storage", "placeholder", "apiKey")}
          label={getTranslationKey("storage", "label", "apiKey")}
          data-testid={DataTestIds.FORM_TEXTAREA_API_KEY}
        />
      </FormLayout>
    );
  }
}

export default CreateStorageForm;
