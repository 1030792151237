import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { PanelType } from "@ea/shared_components/Panel";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import UserGroupForm from "./components/UserGroupForm";
import { UserGroup } from "@ea/shared_types/types";

interface IUserGroupsNewPanelProps {
  visibility: boolean;
  onClose: any;
  onOk?: any;
  selected?: UserGroup;
  isEdit?: boolean;
}

class UserGroupsNewPanelContainer extends React.Component<
  IUserGroupsNewPanelProps & IConnectProps,
  any
> {
  onOk = async (values) => {
    const { isEdit, onOk, onClose } = this.props;

    if (isEdit) {
      await API.editUserGroup(values);
    } else {
      const result = await API.createUserGroup(values);

      if (onOk) {
        onOk(result);
      }
    }

    onClose();
  };

  render() {
    const { visibility, onClose, isEdit, selected } = this.props;

    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.SMALL}
        headerText={getTranslationKey("userGroups", "header", isEdit ? "edit" : "create")}
        key={isEdit ? "Edit" : "Create"}
        onCancelClick={onClose}
        onOkClick={this.onOk}
        cancelButtonText={getTranslationKey("button", "cancel")}
        okButtonText={getTranslationKey("button", isEdit ? "save" : "create")}
        initialValues={isEdit ? selected : undefined}
        render={({ values }) => <UserGroupForm values={values} />}
      />
    );
  }
}

const connectCreator = connect((state: ApplicationState) => ({}), {});

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(UserGroupsNewPanelContainer);
