"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_PLATFORM_ID = void 0;
exports.initDefaults = initDefaults;
const types_1 = require("@ea/shared_types/types");
function initDefaults() {
    if (typeof EA_INTERNAL === "undefined") {
        const defaultValue = {
            platforms: [],
            rules: {
                packs: {},
            },
            commands: {
                packs: {},
                api: {},
                translations: {},
            },
        };
        if (typeof window !== "undefined") {
            // @ts-ignore
            window.EA_INTERNAL = defaultValue;
        }
        if (typeof global !== "undefined") {
            // @ts-ignore
            global.EA_INTERNAL = defaultValue;
        }
    }
}
exports.DEFAULT_PLATFORM_ID = "DEFAULT_PLATFORM";
initDefaults();
