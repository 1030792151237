import { moduleActions } from "./tag.actions";
import { Tag } from "@ea/shared_types/types";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { TAG_COLUMNS_CONFIG } from "./tag.table";
import { tagDataSelectors } from "./tag.selectors";
import { createModuleEpics } from "../app.reducers";

const apiKeyModuleEpics = createModuleEpics<Tag>(moduleActions, {
  normalizeItem: (tag) => tag,
  getRequestParams: (state, tableId) => {
    const tableParams = tagDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, TAG_COLUMNS_CONFIG, {
      defaultOrder: ["createdAt DESC"],
    });
    return params;
  },
  getSingleRequestParams: (params) => {
    return params;
  },
  api: {
    createItem: API.createTag,
    deleteItem: API.deleteTag,
    editItem: API.editTag,
    getItems: API.getTag,
    getItemsCount: API.getTagCount,
  },
});

export default apiKeyModuleEpics;
