import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { VirtualUser, VIRTUAL_USER_AUTH_TYPE, VIRTUAL_USER_STATUS } from "@ea/shared_types/types";
import { DataTestIds } from "@app/utils/dataTestIds";
interface VirtualUserCommandBarProps {
  onNewClick: () => void;
  onRemoveClick: () => void;
  onEditClick: () => void;
  onReload: () => void;
  selected: VirtualUser[];
  onAuthenticateClick: () => void;
  onLogoutClick: () => void;
  onOpenErrorWarningList?: () => void;
}
class VirtualUserCommandBar extends React.Component<VirtualUserCommandBarProps> {
  private container: HTMLElement;

  public render() {
    return (
      <CommandBar data-testid={DataTestIds.COMMANDBAR_VIRTUAL_USERS}>
        <CommandBarButton
          name="new"
          onClick={this.props.onNewClick}
          text={getTranslationKey("commandBar", "add")}
          icon="PlusOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_NEW}
        />
        <CommandBarButton
          name="remove"
          onClick={() => this.props.onRemoveClick()}
          text={getTranslationKey("commandBar", "remove")}
          icon="DeleteOutlined"
          disabled={this.props.selected.length === 0}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE}
        />
        <CommandBarButton
          name="refresh"
          onClick={this.props.onReload}
          text={getTranslationKey("commandBar", "refresh")}
          icon="ReloadOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
        />
        <CommandBarButton
          name="edit"
          onClick={this.props.onEditClick}
          text={getTranslationKey("commandBar", "edit")}
          icon="EditOutlined"
          disabled={this.props.selected.length !== 1}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_EDIT}
        />
        <CommandBarButton
          name="errorWarningList"
          onClick={this.props.onOpenErrorWarningList}
          disabled={
            this.props.selected.length !== 1 ||
            this.props.selected[0].status !== VIRTUAL_USER_STATUS.ERROR
          }
          text={getTranslationKey("commandBar", "showErrors")}
          icon="ExclamationOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_ERROR_WARNING_LIST}
        />
        <CommandBarButton
          name="logout"
          onClick={this.props.onLogoutClick}
          text={getTranslationKey("commandBar", "logout")}
          far
          disabled={this.props.selected.length !== 1 || !this.props.selected[0].isSystemAuthEnabled}
          icon="LogoutOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_LOGOUT}
        />
        <CommandBarButton
          name="authenticate"
          onClick={this.props.onAuthenticateClick}
          text={getTranslationKey("commandBar", "authenticate")}
          type="primary"
          disabled={this.props.selected.length !== 1 || !this.props.selected[0].isSystemAuthEnabled}
          icon="LoginOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_AUTHENTICATE}
        />
      </CommandBar>
    );
  }
}

export default VirtualUserCommandBar;
