import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { ScriptStatus, ScriptChangelog, FEATURES } from "@ea/shared_types/types";
import * as React from "react";
import { GetAsTime } from "@app/modules/common/time";
import StatusTag from "../common/components/StatusTag";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { Tag } from "antd";

const statusFilterValues = [
  { label: getTranslationKey("scripts", "status", "DRAFT"), value: ScriptStatus.DRAFT },
  { label: getTranslationKey("scripts", "status", "PUBLISHED"), value: ScriptStatus.PUBLISHED },
  { label: getTranslationKey("scripts", "status", "IN_PROGRESS"), value: ScriptStatus.IN_PROGRESS },
  {
    label: getTranslationKey("scripts", "status", "READY_FOR_REVIEW"),
    value: ScriptStatus.READY_FOR_REVIEW,
  },
  {
    label: getTranslationKey("scripts", "status", "READY_TO_TEST"),
    value: ScriptStatus.READY_TO_TEST,
  },
];

export const SCRIPT_CHANGELOG_COLUMNS_CONFIG: PlainObject<CustomColumn<ScriptChangelog>> = {
  oldValue: {
    label: "table.previousStatus",
    dataIndex: "oldValue",
    filter: {
      filterType: ColumnFilters.SELECT,
      values: statusFilterValues,
    },
    render: (text, record, index) => <StatusTag item={{ status: record.oldValue }} />,
  },
  newValue: {
    label: "table.newStatus",
    dataIndex: "newValue",
    filter: {
      filterType: ColumnFilters.SELECT,
      values: statusFilterValues,
    },
    render: (text, record, index) => <StatusTag item={{ status: record.newValue }} />,
  },
  version: {
    label: "table.version",
    dataIndex: "version",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    feature: FEATURES.SCRIPT_VERSIONING,
    render: (text, record, index) => <Tag> {text ? `v${text}` : null} </Tag>,
  },
  user: {
    label: "virtualUsers.user",
    dataIndex: "user",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  signedBy: {
    label: "scriptChangelog.signedBy",
    dataIndex: "user",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  updatedAt: {
    label: "table.updatedAt",
    dataIndex: "createdAt",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "createdAt" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
  comment: {
    label: "table.comment",
    dataIndex: "comment",
    sortable: false,
  },
  tags: {
    label: "table.tags",
    dataIndex: "tags",
    feature: FEATURES.GXP_TAGS,
    render: (text, record, index) => record.tags && record.tags.map((t) => <Tag key={t}>{t}</Tag>),
  },
};

export const SCRIPT_CHANGELOG_COLUMNS: ColumnConfig<ScriptChangelog>[] = [
  {
    props: SCRIPT_CHANGELOG_COLUMNS_CONFIG.oldValue,
    frameworkProps: {
      width: "14.28%",
    },
  },
  {
    props: SCRIPT_CHANGELOG_COLUMNS_CONFIG.newValue,
    frameworkProps: {
      width: "14.28%",
    },
  },
  {
    props: SCRIPT_CHANGELOG_COLUMNS_CONFIG.user,
    frameworkProps: {
      width: "14.28%",
    },
  },
  {
    props: SCRIPT_CHANGELOG_COLUMNS_CONFIG.updatedAt,
    frameworkProps: {
      width: "14.28%",
    },
  },
  {
    props: SCRIPT_CHANGELOG_COLUMNS_CONFIG.version,
    frameworkProps: {
      width: "14.28%",
    },
  },
  {
    props: SCRIPT_CHANGELOG_COLUMNS_CONFIG.comment,
    frameworkProps: {
      width: "14.28%",
    },
  },
  {
    props: SCRIPT_CHANGELOG_COLUMNS_CONFIG.tags,
    frameworkProps: {
      width: "14.28%",
    },
  },
];

// replace User with Signed By when e-signoff is enabled
export const SCRIPT_CHANGELOG_COLUMNS_E_SIGNOFF: ColumnConfig<ScriptChangelog>[] =
  SCRIPT_CHANGELOG_COLUMNS.map((column) => {
    if (column.props.dataIndex === SCRIPT_CHANGELOG_COLUMNS_CONFIG.user.dataIndex) {
      return {
        props: SCRIPT_CHANGELOG_COLUMNS_CONFIG.signedBy,
        frameworkProps: {
          width: "14.28%",
        },
      };
    }
    return column;
  });

export const SCRIPT_CHANGELOG_TABLES_CONFIG = {
  SCRIPT: {
    id: (scriptId: number) => `SCRIPT_${scriptId}_CHANGELOG`,
    preferencesId: "MAIN_SCRIPT_CHANGELOG",
  },
};
