import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { SchedulerJobMapping } from "@ea/shared_types/types";
import { TableActionParams } from "@ea/shared_components/redux/common.models";

const actionCreator = actionCreatorFactory("SCHEDULER_FLOW_MODULE");

const baseModuleActions = createDataModuleActions<SchedulerJobMapping>(actionCreator);

export const moduleActions = {
  ...baseModuleActions,
  table: {
    ...baseModuleActions.table,
    moveTo: actionCreator<TableActionParams<{ id: number; from: number; to: number }>>("MOVE_TO"),
  },
};
export const getSchedulerFlowTableActions = createTableActionsGetter(
  normalizeActions(moduleActions.table),
);

export const schedulerFlowActionCreators = {
  ...moduleActions,
};

export const schedulerFlowActions = {
  ...normalizeActions(schedulerFlowActionCreators.data),
};
