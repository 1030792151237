import * as React from "react";
import styled from "@emotion/styled";

export const BreadcrumbSection = styled.div({
  display: "inline-flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "flex-start",
  height: "60px",
  minHeight: "60px",
  marginTop: "10px",
  marginBottom: "10px",
});

export const MainSection = styled.div({
  display: "flex",
  flex: 1,
  width: "100%",
  maxHeight: "calc(100% - 40px)",
  flexDirection: "column",
});

const Container = styled.div({
  display: "flex",
  height: "100%",
  width: "100%",
  justifyContent: "center",
});

const NestedContainer = styled.div({
  display: "flex",
  width: "95%",
  flexDirection: "column",
});

export const Page: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <Container>
      <NestedContainer>{children}</NestedContainer>
    </Container>
  );
};

export const Info = styled.div({
  display: "flex",
  height: "100%",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "20px",
  fontWeight: "bold",
});
