import * as qs from "qs";

import {
  CreateOptions,
  EndpointFunctionType,
  MethodType,
  OptionalRequestParams,
} from "@ea/shared_types/api.types";
import ClientUserError from "@ea/shared_types/ea.errors";
import { ErrorCodes } from "@ea/shared_types/types";
import { getToken, removeToken } from "./auth";
import { unpackErrorMessage } from "./error";

export const buildQuery = (parameters: any) => {
  return qs.stringify(parameters);
};

const defaultCreateOptions: Partial<CreateOptions> = {
  isAuthenticated: true,
  defaultContentType: true,
};

const ReturnResultErrorCodes = Object.values(ErrorCodes);

export const endpointsCreator = (baseUrl: string) => {
  const create = <T, K>(
    method: MethodType,
    endpoint: string | EndpointFunctionType<T>,
    options: Partial<CreateOptions<T>> = defaultCreateOptions,
  ) => {
    const request = async (params: T, optionalParams: OptionalRequestParams = {}): Promise<K> => {
      const normalizedEndpoint = typeof endpoint === "string" ? endpoint : endpoint(params);
      const queryParams = method === "GET" ? `?${buildQuery(params)}` : "";
      const url = `/${baseUrl}/${normalizedEndpoint}${queryParams}`;

      options = {
        ...defaultCreateOptions,
        ...options,
      };

      const headers = new Headers();
      if (options.defaultContentType) {
        headers.set("Content-Type", "application/json");
      }

      if (options.isAuthenticated) {
        const token = getToken().id;
        if (!token) {
          throw new Error("Authorization token doesn't exist");
        }
        headers.set("Authorization", token);
      }

      if (options.fileRequest) {
        headers.set("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
      }

      const fetchOptions: RequestInit = {
        method,
        headers,
      };

      fetchOptions.cache = optionalParams.cache;

      if (method !== "GET") {
        if (options.bodyBuilder) {
          fetchOptions.body = options.bodyBuilder(params);
        } else {
          fetchOptions.body = JSON.stringify(params);
        }
      }

      if (options.fileRequest) {
        fetchOptions.body = options.bodyBuilder ? options.bodyBuilder(params) : buildQuery(params);
      }

      const response = await fetch(url, fetchOptions);

      if (response.status === 503) {
        throw new Error("Connection error");
      }

      let result;
      try {
        result =
          options.fileRequest && response.status < 400
            ? await response.blob()
            : await response.json();
        // : JSON.parse(await response.text(), jsonDateReviver); IF YOU WANT TO HAVE DATES AS OBJECT
      } catch (er) {
        result = undefined;
      }

      if (
        response.status === 401 &&
        result !== undefined &&
        (result.error?.code === "INVALID_TOKEN" || result.code === "INVALID_TOKEN")
      ) {
        removeToken();
        window.location.reload(); // todo: figure out better way to do it, the best option is to call logout action
      }

      if (response.status >= 400 && result !== undefined) {
        if (typeof result === "string") {
          throw new Error(result);
        }

        if (ReturnResultErrorCodes.some((v) => v === result.error?.code || v === result?.code)) {
          return result;
        }

        const err =
          result?.type === "USER"
            ? new ClientUserError(unpackErrorMessage(result))
            : new Error(unpackErrorMessage(result));

        throw err;
      }

      if (response.status >= 400 && result === undefined) {
        throw new Error("Error");
      }

      if (options.returnHeaders) {
        return { result, headers: response.headers } as any;
      }

      return result;
    };

    return request;
  };
  return create;
};
