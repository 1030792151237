import { createModuleEpics } from "@app/modules/app.reducers";
import { CodeTemplateGroup } from "@ea/shared_types/types";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { moduleActions } from "./codeTemplateGroups.actions";
import { CODE_TEMPLATE_GROUPS_COLUMNS_CONFIG } from "./codeTemplateGroups.table";
import { codeTemplateGroupsDataSelectors } from "./codeTemplateGroups.selectors";

const codeTemplateGroupsModuleEpics = createModuleEpics<CodeTemplateGroup>(moduleActions, {
  normalizeItem: (role) => role,
  getRequestParams: (state, tableId) => {
    const tableParams = codeTemplateGroupsDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, CODE_TEMPLATE_GROUPS_COLUMNS_CONFIG);
    return params;
  },
  getSingleRequestParams: (query) => {
    return query;
  },
  api: {
    createItem: API.createCodeTemplateGroup,
    deleteItem: API.deleteCodeTemplateGroup,
    editItem: API.editCodeTemplateGroup,
    getItems: API.getCodeTemplateGroups,
    getItemsCount: API.getCodeTemplateGroupCount,
  },
});

export default codeTemplateGroupsModuleEpics;
