import * as React from "react";
import withIconButton from "./withIconButton";

interface IPauseIconProps {
  size?: number;
}

const PauseIcon: React.FunctionComponent<IPauseIconProps> = ({ size = 24 }) => (
  <svg
    fill="#FFFFFF"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

export default withIconButton(PauseIcon);
