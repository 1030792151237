import styled from "@emotion/styled";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { Button, Dropdown, Menu } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { style } from "../../../styles/styles";
import { ApplicationStyles } from "@ea/shared_types/types";

const getConfluenceUrl = () =>
  style === ApplicationStyles.EPISTA
    ? "https://xplus-product-support.atlassian.net/wiki/spaces/AB/overview"
    : "https://xplus-product-support.atlassian.net/wiki/spaces/EA/overview";

const ContactLinks = {
  confluence: getConfluenceUrl(),
  jira: "https://xplus-product-support.atlassian.net/servicedesk/customer/portals",
};

const Container = styled.div(
  {
    display: "flex",
    height: "100%",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center",
  },
  ({ visibility }: { visibility?: boolean | 1 | 0 | "true" | "false" }) => ({
    background: visibility ? "rgba(0, 0, 0, .2)" : "transparent",
  }),
);

const { useState } = React;

export const ContactOptions: React.FunctionComponent = () => {
  const [dropdownVisibility, setDropdownVisibility] = useState(false);

  const onMenuClick = ({ key }: { key: string | number }) => {
    switch (key) {
      case "confluence":
        window.open(ContactLinks.confluence, "_blank");
        break;
      case "jira":
        window.open(ContactLinks.jira, "_blank");
        break;
      default:
        return;
    }
  };

  const menu = (
    <Menu onClick={onMenuClick}>
      <Menu.Item key="confluence">
        <FormattedMessage id={getTranslationKey("common", "label", "confluence")} />
      </Menu.Item>
      <Menu.Item key="jira">
        <FormattedMessage id={getTranslationKey("common", "label", "jira")} />
      </Menu.Item>
      <Menu.Item key="email">
        <a href="mailto:support@executiveautomats.com">
          <FormattedMessage id={getTranslationKey("common", "label", "contactUs")} />
        </a>
      </Menu.Item>
    </Menu>
  );

  const onDropdownVisibilityChange = (visible) => setDropdownVisibility(visible);

  return (
    <Dropdown overlay={menu} onVisibleChange={onDropdownVisibilityChange}>
      <Container visibility={dropdownVisibility ? 1 : 0}>
        <Button shape="circle" icon={<InfoCircleOutlined />} />
      </Container>
    </Dropdown>
  );
};
