import { createModuleEpics } from "@app/modules/app.reducers";
import { moduleActions } from "@app/modules/variables/variables.actions";
import { variableDataSelectors } from "@app/modules/variables/variables.selectors";
import { API } from "@app/services/api/api";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { Variable } from "@ea/shared_types/types";
import { VARIABLES_COLUMN_CONFIG } from "./variables.table";

const variableModuleEpics = createModuleEpics<Variable>(moduleActions, {
  normalizeItem: (v) => v,
  getRequestParams: (state, tableId) => {
    const tableParams = variableDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, VARIABLES_COLUMN_CONFIG);

    return params;
  },
  getSingleRequestParams: (query) => {
    return query;
  },
  api: {
    editItem: API.patchVariable as any,
    getItems: API.getVariables as any,
    createItem: API.createVariable as any,
    deleteItem: API.deleteVariable,
  },
});

export default variableModuleEpics;
