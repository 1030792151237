import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";

import CommandBar from "./components/SequencesCommandBar";
import { SEQUENCES_COLUMNS, SEQUENCES_TABLES_CONFIG } from "@app/modules/sequences/sequences.table";
import {
  getSequencesTableActions,
  sequencesActions,
} from "@app/modules/sequences/sequences.actions";
import { sequencesDataSelectors } from "@app/modules/sequences/sequences.selectors";
import CreateEditSequenceContainer from "@app/modules/sequences/CreateEditSequence.container";

interface ISequencesTableProps {
  onChange?: () => void;
}

interface ISequencesTableState {
  createWindowVisibility: boolean;
  editWindowVisibility: boolean;
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

class SequencesTableContainer extends React.Component<
  ISequencesTableProps & IConnectProps,
  ISequencesTableState
> {
  connectedTable: any;

  state = {
    createWindowVisibility: false,
    editWindowVisibility: false,
  };

  openEditWindow = () => {
    this.setState({
      editWindowVisibility: true,
    });
  };

  closeEditWindow = () => {
    if (this.props.selectedSequence) {
      this.props.actions.loadSingle({ id: this.props.selectedSequence.id });
    }
    this.setState({
      editWindowVisibility: false,
    });
  };

  openCreateWindow = () => {
    this.setState({
      createWindowVisibility: true,
    });
  };

  closeCreateWindow = () => {
    this.setState({
      createWindowVisibility: false,
      editWindowVisibility: false,
    });
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
    this.props.onChange?.();
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
    this.props.onChange?.();
  };

  render() {
    return (
      <Container>
        <CommandBar
          onNewClick={this.openCreateWindow}
          onRemoveClick={this.remove}
          selected={this.props.selected}
          onEditClick={this.openEditWindow}
          onReload={this.reload}
        />
        <CreateEditSequenceContainer
          visibility={this.state.createWindowVisibility || this.state.editWindowVisibility}
          onClose={this.state.editWindowVisibility ? this.closeEditWindow : this.closeCreateWindow}
          onCreate={this.reload}
          onEdit={this.props.onChange}
          selected={this.props.selectedSequence}
          isEdit={this.state.editWindowVisibility}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={SEQUENCES_COLUMNS}
          tableId={SEQUENCES_TABLES_CONFIG.MAIN.id()}
          preferencesId={SEQUENCES_TABLES_CONFIG.MAIN.preferencesId}
          stateKey={"sequences"}
          tableActions={getSequencesTableActions}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(sequencesActions, dispatch),
    ...bindActionCreators(getSequencesTableActions(SEQUENCES_TABLES_CONFIG.MAIN.id()), dispatch),
  },
});

const connectCreator = connect(
  (state: ApplicationState) => ({
    selected: sequencesDataSelectors.getSelectedSelector(state, SEQUENCES_TABLES_CONFIG.MAIN.id()),
    selectedSequence: sequencesDataSelectors.getSelectedItemSelector(
      state,
      SEQUENCES_TABLES_CONFIG.MAIN.id(),
    ),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(SequencesTableContainer);
