import React from "react";
import { getIn } from "final-form";

import { scriptNameToLinkName } from "@ea/shared_components/utils/formatters";
import { formatScriptName } from "@app/utils/script";

import EmptyField from "@ea/shared_components/Form/Fields/EmptyField";
import { InputField } from "@ea/shared_components/Form/Fields/InputField";
import validators, { composeValidators } from "@ea/shared_components/Form/validators";

export const getStepScriptColumns = ({ scripts, steps, values }, intl) => {
  const { whiteSpaceAndSpecialCharValidator, mustStartWithLetter } = validators(intl);

  return [
    {
      title: "",
      dataIndex: "linkedScriptId",
      width: 0,
      key: "linkedScriptId",
      render: (_, fieldName) => {
        const linkedScriptId = getIn(values, `${fieldName}.linkedScriptId`);

        return <EmptyField name={`${fieldName}.linkedScriptId`} defaultValue={linkedScriptId} />;
      },
    },
    {
      title: intl.formatMessage({ id: "step.createStep.scriptStep.label" }),
      dataIndex: "label",
      key: "label",
      required: true,
      render: (_, fieldName) => {
        const linkedScriptId = getIn(values, `${fieldName}.linkedScriptId`);
        const script = scripts[`${linkedScriptId}`];
        const defaultLabel = script ? formatScriptName(scripts[`${linkedScriptId}`]) : "";
        return (
          <>
            <InputField name={`${fieldName}.label`} defaultValue={defaultLabel} required />
          </>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "step.createStep.scriptStep.linkName" }),
      dataIndex: "linkName",
      key: "linkName",
      required: true,
      render: (_, fieldName) => {
        const linkedScriptId = getIn(values, `${fieldName}.linkedScriptId`);
        const script = scripts[`${linkedScriptId}`];
        const defaultLinkName = script ? scriptNameToLinkName(formatScriptName(script)) : "";
        return (
          <InputField
            name={`${fieldName}.linkName`}
            defaultValue={defaultLinkName}
            required={true}
            validate={composeValidators(whiteSpaceAndSpecialCharValidator, mustStartWithLetter)}
          />
        );
      },
    },
  ];
};
