import {
  getDataReducerInitialState,
  createDataReducer,
} from "@ea/shared_components/redux/reducers/common.data.reducer";
import { VirtualUser, VIRTUAL_USER_STATUS } from "@ea/shared_types/types";
import { moduleActions, virtualUsersActionCreators } from "./virtualUsers.actions";

const initialState = {
  ...getDataReducerInitialState<VirtualUser>(),
};

export type VirtualUsersReducer = typeof initialState;

const dataReducer = createDataReducer<VirtualUser>({
  ...moduleActions.table,
  ...moduleActions.data,
});

const reducer = dataReducer(initialState);

reducer.case(virtualUsersActionCreators.authenticate.started, (state, payload) => {
  return {
    ...state,
    items: {
      ...state.items,
      [payload.id]: {
        ...state.items[payload.id],
        status: VIRTUAL_USER_STATUS.AUTHENTICATING,
      },
    },
  };
});

reducer.case(virtualUsersActionCreators.authenticate.done, (state, payload) => {
  return {
    ...state,
    items: {
      ...state.items,
      [payload.params.id]: {
        ...state.items[payload.params.id],
        status: payload.result.status,
        errorMessage: payload.result.errorMessage,
      },
    },
  };
});

export { reducer };
