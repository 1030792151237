import "./MonacoEditorField.css";

import styled from "@emotion/styled";
import * as React from "react";

import {
  generateDeclarationForVariablesGroup,
  getStartingPosition,
  loadMonaco,
  generateDeclarationForCodeTemplates,
} from "./MonacoEditorField.helpers";
import createFormField from "../../Form/createFormField";
import { ICustomFormItem } from "../../Form/Form.common";
import { COLORS } from "../../styles/consts";
import { CodeType, AdvancedCodeFunctionName, CodeTemplateWithGroup } from "@ea/shared_types/types";
import { VariablesGroup } from "../../redux/common.models";
import { withDefaultProps } from "../../utils/react";
// @ts-ignore
import eaFunctions from "@ea/shared_ea_functions/eaFunctions.d.ts";
import { DataTestIdProp } from "../../utils/dataTestHelpers";
export type MonacoSupportedValueType =
  | typeof CodeType.ADVANCED
  | typeof CodeType.EXPRESSION
  | typeof CodeType.CODE_TEMPLATE;

const LANGUAGE = "javascript";
const FUNCTION_NAME: AdvancedCodeFunctionName = "eaExecuteVariableValue";

type IMonacoEditorFieldOptionalProps = {
  mode: MonacoSupportedValueType;
  variablesGroups: VariablesGroup[];
  validationError?: boolean;
  DataTestIdProp?: string;
  codeTemplates?: CodeTemplateWithGroup[];
  nestedContainerStyle?: React.CSSProperties;
} & ICustomFormItem;

type IMonacoEditorFieldRequiredProps = {
  width: string | number;
  height: string | number;
};

const defaultProps: IMonacoEditorFieldOptionalProps = {
  mode: CodeType.ADVANCED,
  onChange: () => {},
  readOnly: false,
  value: "",
  variablesGroups: [],
};

type IMonacoEditorFieldProps = IMonacoEditorFieldRequiredProps & IMonacoEditorFieldOptionalProps;

const Container = styled.div(
  {
    border: COLORS.ANTD_BORDER,
    borderRadius: "4px",
    marginTop: "4px",
    paddingTop: "6px",
    paddingBottom: "2px",
    paddingRight: "3px",
  },
  ({
    isActive,
    isReadOnly,
    validationError,
  }: {
    isActive: boolean;
    isReadOnly: boolean;
    validationError?: boolean;
  }) =>
    !isReadOnly && !validationError && isActive
      ? {
          borderColor: COLORS.ANTD_BORDER_COLOR,
          outline: 0,
          boxShadow: COLORS.ANTD_BOX_SHADOW,
        }
      : isReadOnly
      ? {
          backgroundColor: COLORS.ANTD_DISABLED_BACKGROUND_COLOR,
          color: COLORS.ANTD_DISABLED_TEXT_COLOR,
          cursor: "default !important",
        }
      : {
          ...(validationError
            ? {
                borderColor: COLORS.ANTD_ERROR_BORDER_COLOR,
              }
            : {
                ":hover,:active,:focus": {
                  borderColor: COLORS.ANTD_BORDER_COLOR,
                  outline: 0,
                  boxShadow: COLORS.ANTD_BOX_SHADOW,
                },
              }),
        },
);

const NestedContainer = styled.div({});

class MonacoEditorField extends React.Component<IMonacoEditorFieldProps, { isActive: boolean }> {
  preventTriggerChangeEvent: boolean;
  containerElement: any;
  editor: monaco.editor.IStandaloneCodeEditor;
  variablesDispose: monaco.IDisposable;
  commonFunctionsDispose: monaco.IDisposable;
  codeTemplatesFunctionsDispose: monaco.IDisposable;
  nestedVariablesDispose: monaco.IDisposable;

  state = {
    isActive: false,
  };

  componentDidMount() {
    loadMonaco(() => this.initMonaco());
  }

  componentDidUpdate(prevProps: IMonacoEditorFieldProps) {
    const { readOnly, value, variablesGroups } = this.props;

    if (prevProps.variablesGroups !== variablesGroups) {
      this.reloadVariablesGroups();
    }
    if (
      (prevProps.readOnly === false && readOnly === true) ||
      (readOnly === true && value !== this.editor?.getValue())
    ) {
      this.editor.getModel().setValue(value);
      this.setHiddenArea(value);
    }
  }

  componentWillUnmount() {
    this.destroyMonaco();
  }

  componentWillReceiveProps(nextProps: IMonacoEditorFieldProps) {
    if (nextProps.readOnly !== this.props.readOnly) {
      this.editor.updateOptions({
        readOnly: nextProps.readOnly,
        cursorStyle: nextProps.readOnly ? "underline" : "line-thin",
      });

      monaco.editor.setTheme(nextProps.readOnly ? "disableTheme" : "vs");
    }

    // Below code doesn't work in one case. Monaco breaks when you select something from intelisense window.
    // For now, let's leave this component as controllable only during initialization
    if (false && nextProps.value !== this.props.value) {
      const value = !this.props.value ? this.getWrappedValue(nextProps) : nextProps.value;
      this.preventTriggerChangeEvent = true;
      this.editor.setValue(value);
      this.setHiddenArea(value);
      this.preventTriggerChangeEvent = false;
    }
  }

  reloadVariablesGroups = () => {
    const variablesGroups = this.props.variablesGroups;

    if (variablesGroups !== undefined && variablesGroups.length > 0) {
      try {
        if (this.nestedVariablesDispose) {
          this.nestedVariablesDispose.dispose();
        }
        const declaration = variablesGroups
          .map((variableGroup) => generateDeclarationForVariablesGroup(variableGroup))
          .join("\n");

        this.nestedVariablesDispose = monaco.languages.typescript.javascriptDefaults.addExtraLib(
          declaration,
          "variableGroups.d.ts",
        );
      } catch (err) {
        console.error(err);
      }
    }
  };

  setIntellisense = () => {
    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      noLib: true,
      allowNonTsExtensions: true,
    });
  };

  loadCodeTemplateFunctions = () => {
    const { codeTemplates } = this.props;
    if (codeTemplates) {
      const codeTemplatesDeclaration = generateDeclarationForCodeTemplates(codeTemplates);

      try {
        if (!this.codeTemplatesFunctionsDispose) {
          this.codeTemplatesFunctionsDispose =
            monaco.languages.typescript.javascriptDefaults.addExtraLib(
              codeTemplatesDeclaration,
              "codeTemplatesFunctions.d.ts",
            );
        }
      } catch (err) {}
    }
  };

  loadCommonFunctions = () => {
    // @ts-ignore
    const tsBugWorkaround = eaFunctions as any;
    // @ts-ignore
    const commonFunctionsArray = tsBugWorkaround.split("\n");
    // we need to remove the last two lines to delete an export line in the declaration
    commonFunctionsArray.splice(commonFunctionsArray.length - 2, 2);

    try {
      if (!this.commonFunctionsDispose) {
        this.commonFunctionsDispose = monaco.languages.typescript.javascriptDefaults.addExtraLib(
          commonFunctionsArray.join("\n"),
          "commonFunctions.d.ts",
        );
      }
    } catch (err) {}
  };

  setHiddenArea = (value: string) => {
    const splitted = value.split("\n");
    const ranges: monaco.Range[] = [];
    ranges.push(new monaco.Range(1, 1, 1, 20));
    ranges.push(new monaco.Range(splitted.length, 1, splitted.length, 20));

    (this.editor.getModel() as any).setEditableRange(
      new monaco.Range(2, 1, splitted.length - 1, 1500),
    );
    (this.editor as any).setHiddenAreas(ranges);
  };

  getWrappedValue = (props: IMonacoEditorFieldProps) => {
    const { mode, value } = props;
    switch (mode) {
      case CodeType.ADVANCED:
        return `function ${FUNCTION_NAME}() { \n${value}\n}`;
      case CodeType.CODE_TEMPLATE:
        return "\n" + `${value}` + "\n";
      default:
        return "`\n" + `${value}` + "\n`";
    }
  };

  onModelChange = () => {
    const value = this.editor.getValue();

    if (!this.props.readOnly && !this.preventTriggerChangeEvent) {
      this.props.onChange(value);
    }
  };

  initMonaco() {
    const value = !this.props.value ? this.getWrappedValue(this.props) : this.props.value;

    if (this.containerElement) {
      monaco.editor.defineTheme("disableTheme", {
        base: "vs",
        inherit: true,
        rules: [],
        colors: {
          "editor.background": "#f5f5f5",
        },
      });

      const options: monaco.editor.IEditorConstructionOptions = {
        language: LANGUAGE,
        contextmenu: false,
        lineNumbers: "off",
        readOnly: this.props.readOnly,
        theme: this.props.readOnly ? "disableTheme" : "vs",
        cursorStyle: this.props.readOnly ? "underline" : "line-thin",
        minimap: {
          enabled: false,
        },
        hideCursorInOverviewRuler: true,
        value,
      };

      this.setIntellisense();
      this.loadCommonFunctions();
      this.loadCodeTemplateFunctions();

      this.editor = monaco.editor.create(this.containerElement, options);

      this.editor.onDidFocusEditor(() =>
        this.setState({
          isActive: true,
        }),
      );

      this.editor.onDidBlurEditor(() =>
        this.setState({
          isActive: false,
        }),
      );

      this.setHiddenArea(value);

      this.reloadVariablesGroups();

      this.editor.onDidChangeModelContent(this.onModelChange);

      if (!this.props.readOnly) {
        this.editor.setPosition(getStartingPosition(this.getValue(), this.props.mode));
        this.editor.focus();
      }
    }
  }

  getValue = () => this.editor.getValue();

  destroyMonaco() {
    if (this.editor) {
      this.editor.dispose();
    }

    if (this.commonFunctionsDispose) {
      this.commonFunctionsDispose.dispose();
    }

    if (this.codeTemplatesFunctionsDispose) {
      this.codeTemplatesFunctionsDispose.dispose();
    }

    if (this.variablesDispose) {
      this.variablesDispose.dispose();
    }

    if (this.nestedVariablesDispose) {
      this.nestedVariablesDispose.dispose();
    }
  }

  assignRef = (component: any) => {
    this.containerElement = component;
  };

  onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (ev) => {
    if (this.props.mode === "EXPRESSION") {
      if (ev.keyCode === 13) {
        ev.preventDefault();
        ev.stopPropagation();
      }
    }
  };

  onKeyUp: React.KeyboardEventHandler<HTMLDivElement> = (ev) => {
    if (this.props.mode === "EXPRESSION") {
      if (ev.keyCode === 13) {
        ev.preventDefault();
        ev.stopPropagation();
      }
    }
  };

  render() {
    const { width, height, validationError, nestedContainerStyle } = this.props;
    const fixedWidth = width.toString().indexOf("%") !== -1 ? width : `${width}px`;
    const fixedHeight = height.toString().indexOf("%") !== -1 ? height : `${height}px`;
    const style = {
      width: fixedWidth,
      height: fixedHeight,
    };

    return (
      <Container
        isActive={this.state.isActive}
        validationError={validationError}
        style={style}
        isReadOnly={this.props.readOnly || false}
        className="react-monaco-editor-container"
        data-testid={this.props[DataTestIdProp]}
      >
        <NestedContainer
          onKeyDownCapture={this.onKeyDown}
          onKeyUpCapture={this.onKeyUp}
          style={{ height: "100%", width: "100%", ...(nestedContainerStyle || {}) }}
          ref={this.assignRef}
        />
      </Container>
    );
  }
}

export default createFormField(
  withDefaultProps(defaultProps, MonacoEditorField),
  ({ name, onChange, value, onBlur, onFocus, ...rest }) => ({
    ...rest,
    onChange,
    value,
  }),
);
