import { objectValues } from "@ea/shared_components/utils/object";

import * as schedulerFlowActions from "./schedulerFlow.actions";
import schedulerFlowEpicsMap from "./schedulerFlow.epics";
import * as schedulerFlowReducers from "./schedulerFlow.reducers";

const schedulerFlowEpics = objectValues(schedulerFlowEpicsMap);

export {
  schedulerFlowActions as itsConfigurationsActions,
  schedulerFlowReducers,
  schedulerFlowEpics,
};
