import { TreeSelect as AntdTreeSelect } from "antd";
import React from "react";

import { ReadonlyFormItem, NodeFormItem } from "../Form.common";
import createFormField from "../createFormField";
import withReadonly from "./withReadonly";
import { TreeSelectProps } from "antd/lib/tree-select";

const TreeSelectWithReadonly = withReadonly(AntdTreeSelect);
const TreeNode = AntdTreeSelect.TreeNode;

const generateNodes = (nodes: NodeFormItem[]) => {
  return nodes.map((n) => (
    <TreeNode value={n.id} title={n.name} key={n.id} disabled={n.disabled}>
      {n.children.length === 0 ? null : generateNodes(n.children)}
    </TreeNode>
  ));
};

type TreeSelectFormItemProps = TreeSelectProps<any> & { nodes: NodeFormItem[] } & ReadonlyFormItem;

const TreeSelect: React.SFC<TreeSelectFormItemProps> = ({ nodes, children, ...rest }) => (
  <TreeSelectWithReadonly {...rest}>{generateNodes(nodes)}</TreeSelectWithReadonly>
);

export const TreeSelectField = createFormField<TreeSelectFormItemProps>(
  TreeSelect,
  ({ name, onChange, value, onBlur, onFocus, ...rest }) => ({
    ...rest,
    onChange: (v) => {
      v === undefined ? onChange(null) : onChange(v);
    },
    value,
    onBlur: onBlur as any,
    onFocus: onFocus as any,
  }),
);
