import { API } from "@app/services/api/api";
import { isDevelopment } from "@ea/shared_components/utils/env";

export let openSessions: { w: Window; sessionId: string }[] = [];

const CHECK_CLOSED_SESSIONS_INTERVAL = 2000;

setInterval(() => {
  const closedSessions = openSessions.filter((os) => os.w.closed);
  if (closedSessions.length > 0) {
    const sessionIds = closedSessions.map((cs) => cs.sessionId);
    closeSessions(sessionIds);
    openSessions = openSessions.filter((os) => !os.w.closed);
  }
}, CHECK_CLOSED_SESSIONS_INTERVAL);

export const closeSessions = async (sessionIds: string[]) => {
  if (isDevelopment()) {
    console.warn("Skip clossing sessions on development environement: ", sessionIds);
    return;
  }
  await API.closeSessions({ sessionIds });
};
