import { Component } from "react";
import { GanttLink as GanttLinkType } from "../gantt.types";
import { GanttStatic } from "@ea/dhtmlx-gantt";

interface IGanttLinkProps {
  gantt: GanttStatic;
  link: GanttLinkType;
}
interface IGanttLinkState {
  link: GanttLinkType;
}

class GanttLink extends Component<IGanttLinkProps, IGanttLinkState> {
  constructor(props) {
    super(props);
    this.state = { link: this.props.link };
  }

  componentDidMount() {
    const { gantt, link } = this.props;
    const newLink = { ...link, type: "0" };
    const links = gantt.getLinks();
    const doesLinkAlreadyExist = links
      .map((l) => l.id.toString() === newLink.id?.toString())
      .includes(true);

    if (!doesLinkAlreadyExist) {
      gantt.addLink(newLink);
    }
  }

  componentWillUnmount() {
    try {
      const { link, gantt } = this.props;
      if (link.id) {
        gantt.deleteLink(link.id);
      }
    } catch (e) {}
  }

  shouldComponentUpdate() {
    return false;
  }
  render() {
    return null;
  }
}

export default GanttLink;
