import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { AuditTrailScriptOverview } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("AUDIT_TRAIL_SCRIPT_OVERVIEW_MODULE");

export const moduleActions = createDataModuleActions<AuditTrailScriptOverview>(actionCreator);

export const getAuditTrailScriptOverviewTableActions = createTableActionsGetter(
  normalizeActions(moduleActions.table),
);

export const auditTraiScriptOverviewlActions = {
  ...normalizeActions(moduleActions.data),
};

export const auditTrailScriptOverviewActionCreators = {
  ...moduleActions,
};
