import * as React from "react";

import { GetAsSeconds, GetAsTime } from "@app/modules/common/time";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import {
  EXECUTION_STATE,
  EXECUTION_STATUS,
  FEATURES,
  Log,
  ScriptStatus,
} from "@ea/shared_types/types";

import StatusTag from "@app/modules/common/components/StatusTag";
import { Tag, Tooltip } from "antd";
import { IS_DOCUMENTATION_WIZARD } from "../common/HideWhenDocumentationWizard";
import StateField from "./components/StateField";
import StatusField from "./components/StatusField";

export const LOGS_COLUMNS_CONFIG: PlainObject<CustomColumn<Log>> = {
  id: {
    dataIndex: "id",
    label: getTranslationKey("table", "id"),
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  url: {
    label: getTranslationKey("common", "label", "url"),
    dataIndex: "url",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  state: {
    dataIndex: "state",
    label: getTranslationKey("table", "state"),
    sortable: true,
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        { label: getTranslationKey("table", "states", "none"), value: EXECUTION_STATE.NONE },
        { label: getTranslationKey("table", "states", "running"), value: EXECUTION_STATE.RUNNING },
        { label: getTranslationKey("table", "states", "paused"), value: EXECUTION_STATE.PAUSED },
        {
          label: getTranslationKey("table", "states", "finished"),
          value: EXECUTION_STATE.FINISHED,
        },
        {
          label: getTranslationKey("table", "states", "terminated"),
          value: EXECUTION_STATE.TERMINATED,
        },
      ],
    },
    render: (text) => <StateField state={text} />,
  },
  scriptStatus: {
    label: getTranslationKey("table", "scriptStatus"),
    dataIndex: "scriptStatus",
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        {
          label: getTranslationKey("scripts", "status", ScriptStatus.DRAFT),
          value: ScriptStatus.DRAFT,
        },
        {
          label: getTranslationKey("scripts", "status", ScriptStatus.PUBLISHED),
          value: ScriptStatus.PUBLISHED,
        },
        {
          label: getTranslationKey("scripts", "status", ScriptStatus.IN_PROGRESS),
          value: ScriptStatus.IN_PROGRESS,
        },
        {
          label: getTranslationKey("scripts", "status", ScriptStatus.READY_FOR_REVIEW),
          value: ScriptStatus.READY_FOR_REVIEW,
        },
        {
          label: getTranslationKey("scripts", "status", ScriptStatus.READY_TO_TEST),
          value: ScriptStatus.READY_TO_TEST,
        },
      ],
    },
    render: (text, record, index) => <StatusTag item={{ status: record.scriptStatus }} />,
  },
  status: {
    label: getTranslationKey("table", "status"),
    dataIndex: "status",
    sortable: true,
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        { label: getTranslationKey("table", "states", "none"), value: EXECUTION_STATUS.NONE },
        { label: getTranslationKey("table", "states", "success"), value: EXECUTION_STATUS.SUCCESS },
        { label: getTranslationKey("table", "states", "warning"), value: EXECUTION_STATUS.WARNING },
        { label: getTranslationKey("table", "states", "error"), value: EXECUTION_STATUS.ERROR },
      ],
    },
    render: (text) => <StatusField status={text} />,
  },
  sessionId: {
    label: getTranslationKey("table", "sessionId"),
    dataIndex: "sessionId",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  startTime: {
    label: getTranslationKey("table", "startTime"),
    dataIndex: "startTime",
    sortable: true,
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
    render: (text, record, index) => {
      return <span>{GetAsTime({ dataKey: "startTime", rowData: record })}</span>;
    },
  },
  startTimeShort: {
    label: getTranslationKey("table", "startTimeShort"),
    dataIndex: "startTime",
    sortable: true,
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
    render: (text, record, index) => {
      return <span>{GetAsTime({ dataKey: "startTime", rowData: record })}</span>;
    },
  },
  endTime: {
    label: getTranslationKey("table", "endTime"),
    dataIndex: "endTime",
    sortable: true,
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
    render: (text, record, index) => {
      return <span>{GetAsTime({ dataKey: "endTime", rowData: record })}</span>;
    },
  },
  endTimeShort: {
    label: getTranslationKey("table", "endTimeShort"),
    dataIndex: "endTime",
    sortable: true,
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
    render: (text, record, index) => {
      return <span>{GetAsTime({ dataKey: "endTime", rowData: record })}</span>;
    },
  },
  duration: {
    label: getTranslationKey("table", "duration"),
    dataIndex: "duration",
    sortable: true,
    filter: {
      filterType: ColumnFilters.RANGE,
      converter: (value) => value * 1000,
    },
    render: (text, record, index) => {
      return <span>{GetAsSeconds({ dataKey: "duration", rowData: record })}</span>;
    },
  },
  durationShort: {
    label: getTranslationKey("table", "durationShort"),
    dataIndex: "duration",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    render: (text, record, index) => {
      return <span>{GetAsSeconds({ dataKey: "duration", rowData: record })}</span>;
    },
  },
  scriptName: {
    dataIndex: "scriptName",
    label: getTranslationKey("table", "scriptName"),
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  scriptId: {
    dataIndex: "scriptId",
    label: getTranslationKey("table", "scriptId"),
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  schedulerJobName: {
    label: getTranslationKey("table", "schedulerJobName"),
    dataIndex: "schedulerJobName",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  schedulerJobNameShort: {
    label: getTranslationKey("table", "schedulerJobNameShort"),
    dataIndex: "schedulerJobName",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  apiKeyName: {
    shouldHide: () => IS_DOCUMENTATION_WIZARD,
    label: "table.apiKey",
    dataIndex: "apiKeyName",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  projectId: {
    label: "common.label.project",
    dataIndex: "projectId",
    filter: {
      filterType: ColumnFilters.TREE,
    },
    render: (text, record) => {
      return <span>{`${text} - ${record.projectName}`}</span>;
    },
  },
  scriptVersion: {
    label: "table.version",
    dataIndex: "scriptVersion",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    feature: FEATURES.SCRIPT_VERSIONING,
    render: (text) => (text ? <Tag>{text}</Tag> : null),
  },
  jobId: {
    label: getTranslationKey("table", "jobId"),
    dataIndex: "jobId",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  jobExecutionId: {
    label: getTranslationKey("table", "jobExecutionId"),
    dataIndex: "jobExecutionId",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  envName: {
    label: "table.envName",
    dataIndex: "envName",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  envNameShort: {
    label: "table.envNameShort",
    dataIndex: "envName",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  executorName: {
    label: "table.username",
    dataIndex: "executorName",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  virtualUserName: {
    label: "table.virtualUser",
    dataIndex: "virtualUserName",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  virtualUserNameShort: {
    label: "table.virtualUserShort",
    dataIndex: "virtualUserName",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  integrationMetadata: {
    label: getTranslationKey("projects", "details", "label", "configuration"),
    dataIndex: "integrationMetadata",
    sortable: false,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    render: (text, record) => {
      const { configurations, project } = record.integrationMetadata?.azureDevops || {};

      return configurations && configurations?.length > 0
        ? configurations.map((c) => (
            <Tag key={c.id}>
              {project}/{c.name}
            </Tag>
          ))
        : null;
    },
  },
  lastErrorMessage: {
    label: getTranslationKey("logs", "lastErrorMessage"),
    dataIndex: "lastErrorMessage",
    sortable: false,

    render: (text: string) => (
      <Tooltip
        placement="topLeft"
        title={text?.split(/ \| /g).map((message, index) => (
          <div key={index}>
            <span>{message}</span>
            <br />
          </div>
        ))}
      >
        <div
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 200,
          }}
        >
          {text}
        </div>
      </Tooltip>
    ),
  },
};

export const LOGS_COLUMNS: ColumnConfig<Log>[] = [
  {
    props: LOGS_COLUMNS_CONFIG.sessionId,
    frameworkProps: {
      width: "7%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.scriptId,
    frameworkProps: {
      width: "7%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.projectId,
    frameworkProps: {
      width: "7%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.url,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.envName,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.virtualUserName,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.scriptName,
    frameworkProps: {
      width: "12%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.scriptVersion,
    frameworkProps: {
      width: "5%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.startTime,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.endTime,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.duration,
    frameworkProps: {
      width: "7%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.state,
    frameworkProps: {
      width: "7%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.scriptStatus,
    frameworkProps: {},
  },
  {
    props: LOGS_COLUMNS_CONFIG.status,
    frameworkProps: {},
  },
  {
    props: LOGS_COLUMNS_CONFIG.schedulerJobName,
    frameworkProps: {},
  },
  {
    props: LOGS_COLUMNS_CONFIG.apiKeyName,
    frameworkProps: {},
  },
  {
    props: LOGS_COLUMNS_CONFIG.executorName,
  },
  {
    props: LOGS_COLUMNS_CONFIG.integrationMetadata,
  },
  {
    props: LOGS_COLUMNS_CONFIG.lastErrorMessage,
  },
  {
    props: LOGS_COLUMNS_CONFIG.jobId,
  },
  {
    props: LOGS_COLUMNS_CONFIG.jobExecutionId,
  },
];

export const SCHEDULER_JOB_LOGS_COLUMNS: ColumnConfig<Log>[] = [
  {
    props: LOGS_COLUMNS_CONFIG.sessionId,
    frameworkProps: {
      width: "9%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.projectId,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.scriptId,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.scriptName,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.scriptStatus,
    frameworkProps: {},
  },
  {
    props: LOGS_COLUMNS_CONFIG.scriptVersion,
    frameworkProps: {
      width: "5%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.url,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.envName,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.virtualUserName,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.startTime,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.endTime,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.duration,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.state,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.status,
    frameworkProps: {},
  },
  {
    props: LOGS_COLUMNS_CONFIG.executorName,
  },
  {
    props: LOGS_COLUMNS_CONFIG.integrationMetadata,
  },
  {
    props: LOGS_COLUMNS_CONFIG.lastErrorMessage,
  },
  {
    props: LOGS_COLUMNS_CONFIG.jobId,
  },
  {
    props: LOGS_COLUMNS_CONFIG.jobExecutionId,
  },
];

export const PROJECT_LOGS_COLUMNS: ColumnConfig<Log>[] = [
  {
    props: LOGS_COLUMNS_CONFIG.sessionId,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.projectId,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.url,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.envNameShort,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.virtualUserNameShort,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.scriptId,
    frameworkProps: {
      width: "9%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.scriptName,
    frameworkProps: {
      width: "9%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.scriptStatus,
    frameworkProps: {},
  },
  {
    props: LOGS_COLUMNS_CONFIG.scriptVersion,
    frameworkProps: {
      width: "5%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.startTimeShort,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.endTimeShort,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.durationShort,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.state,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.status,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.schedulerJobNameShort,
    frameworkProps: {
      width: "7%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.apiKeyName,
    frameworkProps: {},
  },
  {
    props: LOGS_COLUMNS_CONFIG.executorName,
  },
  {
    props: LOGS_COLUMNS_CONFIG.integrationMetadata,
  },
  {
    props: LOGS_COLUMNS_CONFIG.lastErrorMessage,
  },
  {
    props: LOGS_COLUMNS_CONFIG.jobId,
  },
  {
    props: LOGS_COLUMNS_CONFIG.jobExecutionId,
  },
];

export const LOGS_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_LOGS",
  },
  SCRIPT_LOGS: {
    id: (id: number) => `SCRIPT_LOGS_${id}`,
    preferencesId: "SCRIPT_LOGS",
  },
  STEP_LOGS: {
    id: (id: number | string) => `STEP_LOGS_${id}`,
    preferencesId: "STEP_LOGS",
  },
  SCHEDULER_JOB_LOGS: {
    id: (id: number) => `SCHEDULER_JOB_LOGS_${id}`,
    preferencesId: "SCHEDULER_JOB_LOGS",
  },
  PROJECT_HISTORY: {
    id: (id: number) => `PROJECT_HISTORY_${id}`,
    preferencesId: "PROJECT_HISTORY",
  },
  PROJECT_TEST_RESULTS: {
    id: (id: number) => `PROJECT_TEST_RESULTS_${id}`,
    preferencesId: "PROJECT_TEST_RESULTS",
  },
};
