import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Project, Script, ROLES, IssueTrackingTool } from "@ea/shared_types/types";
import { API } from "@app/services/api/api";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import FormButton from "@ea/shared_components/Form/FormButton";
import { Button, Select, Row, Col } from "antd";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { isRootProject } from "@app/modules/projects/projects.helpers";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import { getIn } from "final-form";
import { FormattedMessage } from "react-intl";
import { getScriptWithBasicVariablesSelector } from "@app/modules/scripts/scripts.selectors";
import { CheckboxField } from "@ea/shared_components/Form/Fields/CheckboxField";
import WithAuthorization from "@app/modules/common/WithAuthorization";
import { ArrowRightOutlined } from "@ant-design/icons";
import { ApplicationState } from "@app/modules/app.reducers";
import FormItemWrapper from "@ea/shared_components/Form/FormItem/FormItemWrapper";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { toast, Flip } from "react-toastify";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/fontawesome-free-solid";
import { ITS_TABLES_CONFIG } from "../its.table";
import { itsDataSelectors } from "../its.selectors";

type OptionWithUrl = OptionType & { url: string };
interface IWithTestPlansMappingState {
  testPlansOptions: (OptionWithUrl & { rootSuite: string })[];
  testSuitesOptions: OptionWithUrl[];
  testCasesOptions: OptionWithUrl[];
  configurationOptions: OptionType[];
  loadingIntegrationData: boolean;
}

type WithTestPlansProps = { script?: Script; scriptId?: number; project?: Project };
const AuthorizedCheckboxField = WithAuthorization([ROLES.admin, ROLES.logs], [])(CheckboxField);
let errorToastId;

const WithTestPlans =
  (type: "project" | "script") =>
  <OriginalProps extends WithTestPlansProps>(
    WrappedComponent: React.ComponentType<OriginalProps>,
  ) => {
    class WithTestPlansMapping extends Component<IConnectProps, IWithTestPlansMappingState> {
      constructor(props) {
        super(props);
        this.state = {
          testPlansOptions: [],
          testSuitesOptions: [],
          testCasesOptions: [],
          configurationOptions: [],
          loadingIntegrationData: false,
        };
      }

      async componentDidMount() {
        if (type === "project" && this.props.project) {
          const isIssueTrackingAvailable = !!this.props.issueTrackingSystems.find(
            (it) => this.props.project?.integrationMetadata?.azureDevops?.itsId === it.id,
          );
          // if issue tracking was deleted
          if (isIssueTrackingAvailable) {
            await this.loadConfigurations();
            await this.loadTestPlans();
            await this.loadTestSuites();
          }
        } else {
          await this.loadTestCases();
        }
      }

      async componentDidUpdate(prevProps: IConnectProps) {
        if (type === "project" && this.props.project) {
          const isIssueTrackingAvailable = !!this.props.issueTrackingSystems.find(
            (it) => this.props.project?.integrationMetadata?.azureDevops?.itsId === it.id,
          );
          // if issue tracking was deleted
          if (isIssueTrackingAvailable) {
            if (
              this.testPlanSet(prevProps) &&
              (!prevProps.project ||
                prevProps.project.id !== this.props.project.id ||
                prevProps.project.name !== this.props.project.name)
            ) {
              await this.loadConfigurations();
              await this.loadTestPlans();
              await this.loadTestSuites();
            } else if (
              this.testSuiteSet(prevProps) &&
              (!prevProps.project ||
                prevProps.project.id !== this.props.project.id ||
                prevProps.project.name !== this.props.project.name)
            ) {
              await this.loadConfigurations();
              await this.loadTestSuites();
            }
          }
        }
        if (
          (type === "script" && prevProps.scriptId !== this.props.scriptId) ||
          this.testCaseChanged(prevProps)
        ) {
          await this.loadTestCases();
        }
      }

      testCaseChanged = (prevProps: IConnectProps) => {
        try {
          return (
            prevProps.script?.integrationMetadata?.azureDevops?.testCaseId !==
              this.props.script?.integrationMetadata?.azureDevops?.testCaseId ||
            prevProps.script?.status !== this.props.script?.status
          );
        } catch (e) {
          return true;
        }
      };

      testSuiteSet = (prevProps: IConnectProps) =>
        this.props.project?.integrationMetadata?.azureDevops?.testSuiteId;

      testPlanSet = (prevProps: IConnectProps) =>
        this.props.project?.integrationMetadata?.azureDevops?.testPlanId;

      loadTestPlans = async () => {
        const { project } = this.props;

        if (!project) {
          return;
        }

        try {
          this.setState({ loadingIntegrationData: true });

          const { itsId, project: itsProject } = project?.integrationMetadata?.azureDevops || {};
          if (!itsId) {
            return;
          }
          const testPlans = await API.getTestPlans({
            project: itsProject,
            itsId,
          });
          this.setState({
            testPlansOptions: testPlans.map((tp) => ({
              text: tp.name,
              value: tp.id,
              url: tp.url,
              rootSuite: tp.rootSuite,
            })),
            loadingIntegrationData: false,
          });
        } catch (error) {
          this.setState({
            loadingIntegrationData: false,
          });
          this.showErrorToast(getTranslationKey("its", "cannotFetchTestPlans"));
          console.error(error);
        }
      };

      loadTestSuites = async () => {
        const { project } = this.props;

        const azureDevopsintegrationMetadata = project?.integrationMetadata?.azureDevops;

        if (!azureDevopsintegrationMetadata) {
          return;
        }

        try {
          this.setState({ loadingIntegrationData: true });

          const testSuites = await API.getTestSuiteSiblings({
            itsId: azureDevopsintegrationMetadata.itsId,
            project: azureDevopsintegrationMetadata.project,
            testPlanId: parseInt(azureDevopsintegrationMetadata.testPlanId, 10),
            currentTestSuiteId: azureDevopsintegrationMetadata.testSuiteId,
          });
          this.setState({
            testSuitesOptions: testSuites.map((tp) => ({
              text: tp.name,
              value: tp.id,
              url: tp.url,
            })),
            loadingIntegrationData: false,
          });
        } catch (error) {
          this.setState({
            loadingIntegrationData: false,
          });
          this.showErrorToast(getTranslationKey("its", "cannotFetchTestSuites"));
          console.error(error);
        }
      };

      loadTestCases = async () => {
        const { script } = this.props;
        const azureDevopsintegrationMetadata = script?.integrationMetadata?.azureDevops;
        const { itsId, project, testPlanId, testSuiteId } = azureDevopsintegrationMetadata || {};
        if ([itsId, project, testPlanId, testSuiteId].some((v) => !v)) {
          return;
        }

        try {
          this.setState({ loadingIntegrationData: true });

          const testCases = await API.getTestCases({
            itsId,
            project,
            testPlanId: parseInt(testPlanId, 10),
            testSuiteId: parseInt(testSuiteId, 10),
          });

          this.setState({
            testCasesOptions: testCases.map((tp) => ({
              text: tp.name,
              value: tp.id,
              url: tp.url,
            })),
            loadingIntegrationData: false,
          });
        } catch (error) {
          this.setState({
            loadingIntegrationData: false,
          });
          this.showErrorToast(getTranslationKey("its", "cannotFetchTestCases"));
          console.error(error);
        }
      };

      onTestPlanChange = (tp, change) => {
        const { testPlansOptions } = this.state;
        const { rootSuite } = testPlansOptions.find((tpo) => tpo.value === tp) || {};
        change(`integrationMetadata.azureDevops.testSuiteId`, rootSuite);
      };

      loadConfigurations = async () => {
        const { project } = this.props;
        const azureDevopsintegrationMetadata = project?.integrationMetadata?.azureDevops;

        if (!azureDevopsintegrationMetadata) {
          return;
        }

        try {
          this.setState({ loadingIntegrationData: true });

          const configurations = await API.getItsConfigurations({
            filter: { where: { itsId: azureDevopsintegrationMetadata.itsId } },
          });

          this.setState({
            configurationOptions: configurations
              .filter(
                (c) =>
                  c.integrationMetadata.azureDevops.project ===
                  azureDevopsintegrationMetadata.project,
              )
              .map((c) => ({
                text: c.name,
                value: c.id,
              })),
            loadingIntegrationData: false,
          });
        } catch (error) {
          this.setState({
            loadingIntegrationData: false,
          });
          this.showErrorToast(getTranslationKey("its", "cannotFetchConfigurations"));
          console.error(error);
        }
      };

      getProjectMappingFormChunk = (formProps) => {
        const { project } = this.props;

        if (!project) {
          return;
        }

        if (!project.integrationMetadata?.azureDevops) {
          return null;
        }

        const { values, change } = formProps;
        const {
          testPlansOptions,
          testSuitesOptions,
          loadingIntegrationData,
          configurationOptions,
        } = this.state;
        const rootProject = isRootProject(project!);
        const integrationId = getIn(
          values,
          `integrationMetadata.azureDevops.${rootProject ? "testPlanId" : "testSuiteId"}`,
        );
        const mapResultWithDevops = getIn(values, "executionParams.mapResultWithDevops");

        const testPlanAttachmentLabel = rootProject ? "attachedTestPlan" : "attachedTestSuite";
        return (
          <>
            <SelectField
              allowClear
              loading={loadingIntegrationData}
              disabled={loadingIntegrationData}
              name={`integrationMetadata.azureDevops.${rootProject ? "testPlanId" : "testSuiteId"}`}
              onChange={rootProject ? (tp) => this.onTestPlanChange(tp, change) : undefined}
              label={getTranslationKey("projects", "details", "label", testPlanAttachmentLabel)}
              placeholder={getTranslationKey(
                "projects",
                "details",
                "placeholder",
                testPlanAttachmentLabel,
              )}
              format={(v) => (!!v ? `${v}` : v)}
            >
              {(rootProject ? testPlansOptions : testSuitesOptions).map((tp) => (
                <Select.Option key={tp.value} value={tp.value}>
                  [{tp.value}] {tp.text}
                </Select.Option>
              ))}
            </SelectField>
            <FormButton
              onClick={() => {
                const selectedOption = (rootProject ? testPlansOptions : testSuitesOptions).find(
                  (o) => `${o.value}` === integrationId,
                );
                if (selectedOption) {
                  window.open(selectedOption.url, "_blank");
                }
              }}
              icon={<ArrowRightOutlined />}
              loading={loadingIntegrationData}
              disabled={loadingIntegrationData || !integrationId}
            >
              <FormattedMessage
                id={getTranslationKey(
                  "projects",
                  "details",
                  "label",
                  rootProject ? "openAttachedTestPlan" : "openAttachedTestSuite",
                )}
              />
            </FormButton>
            <AuthorizedCheckboxField
              name="executionParams.mapResultWithDevops"
              label={getTranslationKey("playMode", "mapWithDevops")}
            />
            {mapResultWithDevops && (
              <SelectField
                allowClear
                loading={loadingIntegrationData}
                disabled={loadingIntegrationData}
                name={`executionParams.azureConfigurationId`}
                label={getTranslationKey("projects", "details", "label", "configuration")}
                placeholder={getTranslationKey(
                  "projects",
                  "details",
                  "placeholder",
                  "configuration",
                )}
              >
                {configurationOptions.map((configuration) => (
                  <Select.Option key={configuration.value} value={configuration.value}>
                    {configuration.text}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          </>
        );
      };

      getScriptMappingFormChunk = (formProps) => {
        const { values, readOnly } = formProps;
        if (!this.props.script!.integrationMetadata!.azureDevops) {
          return null;
        }
        const { testCasesOptions, loadingIntegrationData } = this.state;
        return (
          <FormItemWrapper
            formItem={{
              formItemRowStyle: { marginBottom: 0 },
              label: (
                <FormattedMessage id={getTranslationKey("scripts", "label", "attachedTestCase")} />
              ) as any,
            }}
          >
            {() => (
              <FormLayout wrapperCol={{ span: 24 }} readOnly={readOnly}>
                <Row>
                  <SelectField
                    formItemRowStyle={{ flexGrow: 1 }}
                    allowClear
                    loading={loadingIntegrationData}
                    disabled={loadingIntegrationData}
                    name={"integrationMetadata.azureDevops.testCaseId"}
                    placeholder={getTranslationKey("scripts", "placeholder", "attachedTestCase")}
                  >
                    {(testCasesOptions || []).map((tp) => (
                      <Select.Option key={tp.value} value={tp.value}>
                        [{tp.value}] {tp.text}
                      </Select.Option>
                    ))}
                  </SelectField>
                  <Button
                    onClick={() => {
                      const selectedOption = (testCasesOptions || []).find(
                        (o) =>
                          `${o.value}` ===
                          getIn(values, "integrationMetadata.azureDevops.testCaseId"),
                      );
                      if (selectedOption) {
                        window.open((selectedOption as any).url, "_blank");
                      }
                    }}
                    icon={
                      <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: "8px" }} />
                    }
                    loading={loadingIntegrationData}
                    disabled={loadingIntegrationData}
                  >
                    <FormattedMessage
                      id={getTranslationKey("scripts", "label", "openAttachedTestCase")}
                    />
                  </Button>
                </Row>
              </FormLayout>
            )}
          </FormItemWrapper>
        );
      };

      showErrorToast = (messageId) => {
        if (toast.isActive(errorToastId)) {
          toast.update(errorToastId, {
            type: "error",
            render: <FormattedMessage id={messageId} />,
            transition: Flip,
            autoClose: 5000,
          });
        } else {
          errorToastId = toast.error(<FormattedMessage id={messageId} />);
        }
      };

      render() {
        const testPlansFormChunk = [
          type === "project" && this.props.project
            ? this.getProjectMappingFormChunk
            : this.props.script
            ? this.getScriptMappingFormChunk
            : () => null,
        ];
        return (
          <WrappedComponent
            {...(this.props as any)}
            loadingIntegrationData={this.state.loadingIntegrationData}
            testPlansFormChunk={testPlansFormChunk}
          />
        );
      }
    }

    const connector = connect((state: ApplicationState, props: WithTestPlansProps) => {
      const script = props.scriptId
        ? getScriptWithBasicVariablesSelector(state, props.scriptId)
        : undefined;

      return {
        script,
        issueTrackingSystems: itsDataSelectors.getOrderedDataSelector(
          state,
          ITS_TABLES_CONFIG.MAIN.id(),
        ),
        ...props,
      };
    });

    // todo: UPGRADING REACT-REDUX Destroyed hocs - please fix me
    type IConnectProps = ConnectedProps<typeof connector> & any;

    return connector(WithTestPlansMapping);
  };

export default WithTestPlans;
