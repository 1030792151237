import * as React from "react";
import { Modal, Button, Tag } from "antd";
import ScriptChangelogSnapshotView from "./ScriptChangelogSnapshotView";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import styled from "@emotion/styled";
import { ScriptChangelog, FEATURES } from "@ea/shared_types/types";
import StatusTag from "../common/components/StatusTag";
import { faAngleDoubleRight } from "@fortawesome/fontawesome-free-solid";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import HideableFeature from "../common/HideableFeature";

interface IScriptChangelogCompareProps {
  isOpen: boolean;
  leftChangelog: ScriptChangelog;
  rightChangelog: ScriptChangelog;
  onClose: () => void;
}

const Container = styled.div({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingLeft: "50px",
});

const listStyles = {
  width: "50%",
};

const VersionContainer = styled.h4({ display: "inline" });

class ScriptChangelogCompare extends React.Component<IScriptChangelogCompareProps, any> {
  render() {
    const { isOpen, leftChangelog, rightChangelog, onClose } = this.props;

    const getContent = () => {
      if (!leftChangelog || !rightChangelog) {
        return null;
      }

      const displayChangelog = (changelog) => (
        <div style={listStyles}>
          <div>
            <HideableFeature feature={FEATURES.SCRIPT_VERSIONING}>
              {() => (
                <VersionContainer>
                  <FormattedMessage id={getTranslationKey("table", "version")} />:
                  <Tag>{changelog.version}</Tag>{" "}
                </VersionContainer>
              )}
            </HideableFeature>
            <StatusTag item={{ status: changelog.oldValue }} />
            <FontAwesomeIcon icon={faAngleDoubleRight} />
            <StatusTag style={{ marginLeft: "5px" }} item={{ status: changelog.newValue }} />
          </div>
          <ScriptChangelogSnapshotView style={listStyles} snapshot={changelog.snapshot} />
        </div>
      );

      return (
        <Container>
          {displayChangelog(leftChangelog)}
          {displayChangelog(rightChangelog)}
        </Container>
      );
    };

    return (
      <Modal
        title={<FormattedMessage id={getTranslationKey("common", "header", "compare")} />}
        visible={isOpen}
        width="70%"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
        closable={false}
        footer={[
          <Button key="close" type="primary" onClick={onClose}>
            <FormattedMessage id={getTranslationKey("button", "close")} />
          </Button>,
        ]}
      >
        {getContent()}
      </Modal>
    );
  }
}

export default ScriptChangelogCompare;
