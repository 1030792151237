import { Collapse } from "antd";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";

import { ApplicationState } from "@app/modules/app.reducers";

import { getTranslationKey } from "@ea/shared_components/AssignVariablePicker/toMigrate";
import "./DataView.css";
import EditeableAreaFinal from "@ea/shared_components/EditeableArea/EditeableAreaFinal";
import { InputField } from "@ea/shared_components/";
import { TableForm } from "@ea/shared_components/";
import { getSessionParams } from "./runner.selectors";
import { getRunnerTableActions } from "./runner.actions";
import {
  PlayerVariables,
  UsedVariable,
  LiveVariablesUpdateData,
} from "@ea/shared_types/runner.common.types";
import { runnerCommunicator } from "@ea/shared_types/utils/iframes.communication";
import { IFrameRunnerAsyncMessages } from "@ea/shared_types/communication.types";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";
import { AssignedVariableType } from "@ea/shared_types/types";

interface ILiveVariablesContainerProps {
  sessionId: string;
}
interface ILiveVariablesContainerState {}
const { Panel } = Collapse;

const getVariableColumns = (props) => {
  return [
    {
      title: getTranslationKey("table", "name"),
      dataIndex: "name",
      key: "name",
      required: true,
      width: "45%",
      render: (text, record) => (
        <InputField
          name={`${record}.name`}
          placeholder={getTranslationKey("common", "placeholder", "name")}
          required
          readOnly
        />
      ),
    },
    {
      title: getTranslationKey("variable", "label", "value"),
      dataIndex: "value",
      key: "value",
      render: (text, record) => (
        <InputField
          name={`${record}.value`}
          placeholder={getTranslationKey("variable", "placeholder", "noValue")}
          readOnly={props.readOnly}
        />
      ),
    },
  ];
};

const getVariablesEditableArea = (variableData = {}, onSave) => (
  <EditeableAreaFinal
    onSave={onSave}
    initialValues={{
      variables: Object.keys(variableData).map((id) => ({ id, ...variableData[id] })),
    }}
    render={(props) => (
      <TableForm<{ name: string; value: any; id: number }>
        name="variables"
        allowEmptyForm
        staticMode
        columns={getVariableColumns(props)}
      />
    )}
  />
);

class VariablesViewContainer extends React.Component<
  ILiveVariablesContainerProps & IConnectProps,
  ILiveVariablesContainerState
> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async onSave(values, type) {
    const { updateLiveVariables } = this.props.actions;

    const variablesOutput: Partial<PlayerVariables> = {};
    const chunks = type.split(".");

    const liveVariables: LiveVariablesUpdateData = [];

    chunks.reduce((container, chunk, index) => {
      if (index === chunks.length - 1) {
        container[chunk] = {
          ...values.variables.reduce((ouptut, variable) => {
            const { id, ...rest } = variable;

            liveVariables.push({
              value: rest.value,
              usedVariable: {
                variableId: id,
                variableType: type.includes("global")
                  ? AssignedVariableType.GLOBAL
                  : AssignedVariableType.LOCAL,
              },
            });

            ouptut[id] = rest;
            return ouptut;
          }, {}),
        };
      } else {
        container[chunk] = {};
      }

      return container[chunk];
    }, variablesOutput);

    try {
      runnerCommunicator.administration.send(IFrameRunnerAsyncMessages.set_variable, liveVariables);
    } catch (err) {
      toast.error(<FormattedMessage id={getTranslationKey("runner", "liveVariablesSaveError")} />);
      throw new Error();
    }

    updateLiveVariables({ variables: variablesOutput });
  }

  render() {
    const { script, dataSource, global } = this.props.variables;

    return (
      <Collapse>
        <Panel header="Script" key="script" style={{ padding: 0 }}>
          {getVariablesEditableArea(script, (values) => this.onSave(values, "script"))}
        </Panel>
        <Panel header="DataSource" key="dataSource" style={{ padding: 0 }}>
          {getVariablesEditableArea(dataSource, (values) => this.onSave(values, "dataSource"))}
        </Panel>
        <Panel header="Global" key="global" style={{ padding: 0 }}>
          {getVariablesEditableArea(global?.mutable, (values) =>
            this.onSave(values, "global.mutable"),
          )}
        </Panel>
      </Collapse>
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: ILiveVariablesContainerProps) => ({
    variables: (getSessionParams(state, props.sessionId).variables as PlayerVariables) || {},
  }),
  (dispatch, props) => ({
    actions: bindActionCreators(getRunnerTableActions(props.sessionId), dispatch),
  }),
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(VariablesViewContainer);
