import * as React from "react";
import { Step, Script, System, VirtualUser } from "@ea/shared_types/types";
import { ApplicationState } from "@app/modules/app.reducers";
import EditeableAreaFinal from "@ea/shared_components/EditeableArea/EditeableAreaFinal";
import StepQuickEditForm from "./StepQuickEditForm";
import { connect, ConnectedProps } from "react-redux";
import { stepsActions } from "../steps/steps.actions";
import { runnerDataSelectors } from "./runner.selectors";
import { VARIABLES_SECTIONS } from "../variables/variables.actions";
import {
  variableDataSelectors,
  getVariablesGroupsSelector,
} from "../variables/variables.selectors";
import { bindActionCreators, Dispatch } from "redux";
import { withRouter, RouteComponentProps } from "react-router";
import { getStepCommand } from "@app/packs/packs.helpers";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { SelectMode } from "@ea/shared_components/redux/common.models";
import { getRunnerTableActions } from "./runner.actions";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { getScriptGlobalMutables, getScriptGlobalConstants } from "../scripts/scripts.selectors";
import { isRootStep } from "@ea/shared_types/utils/runner.data";
import { ExecutionStep } from "@ea/shared_types/runner.common.types";
import { projectSelectors } from "../projects/projects.selectors";
import { codeTemplatesDataSelectors } from "../codeTemplates/codeTemplates.selectors";
import { CODE_TEMPLATES_TABLES_CONFIG } from "../codeTemplates/codeTemplates.table";

interface IStepQuickEditContainerProps {
  step: ExecutionStep;
  updateStep: ({ step }: { step: Step }) => void;
  sessionId: string;
  script: Script;
  isExpandedEditModeOn: boolean;
  systems: System[];
  system?: System;
  virtualUsers: VirtualUser[];
  toggleEdit?: () => void;
}

const Container = styled.div({
  display: "flex",
});

const DetailsButtonContainer = styled.div({
  flex: "0 0 20px;",
  paddingRight: "4px",
  paddingBottom: "50px",
  alignSelf: "center",
});

class StepQuickEditContainer extends React.Component<
  IStepQuickEditContainerProps & IConnectProps,
  any
> {
  editableAreaContainer: any;

  onSave = (values) => {
    const editedStep = { ...values };
    const command = getStepCommand(values);
    if (!editedStep.manualLabel && command.getLabel) {
      const { labelKey } = command.getLabel(editedStep);
      editedStep.labelKey = labelKey;
      editedStep.label = undefined;
    }
    this.props.updateStep({ step: editedStep });
  };

  openLinked = () => {
    const { linkedScriptId } = this.props.step;
    if (linkedScriptId) {
      this.props.history.push(`/scripts/${linkedScriptId}`);
    }
  };

  render() {
    const {
      step,
      localVariables,
      linkedVariables,
      variablesGroups,
      steps,
      script,
      isExpandedEditModeOn,
      virtualUsers,
      systems,
      system,
      toggleEdit,
      actions,
      globalConstants,
      globalMutables,
      project,
      codeTemplates,
    } = this.props;
    return (
      <Container key={step.id}>
        <EditeableAreaFinal
          setRef={(element) => (this.editableAreaContainer = element)}
          onSave={this.onSave}
          isEditModeOn={isExpandedEditModeOn}
          initialValues={{
            ...step,
            value: {
              ...step.value,
              defaultSystemId: project.defaultSystemId,
              defaultVirtualUserId: project.defaultVirtualUserId,
            },
          }}
          render={({ values, change, readOnly }) => (
            <StepQuickEditForm
              values={values}
              change={change}
              variables={[...localVariables, ...linkedVariables]}
              variablesGroups={variablesGroups}
              steps={steps}
              script={script}
              openLinked={this.openLinked}
              virtualUsers={virtualUsers}
              systems={systems}
              system={system}
              globalConstants={globalConstants}
              globalMutables={globalMutables}
              readOnly={readOnly}
              codeTemplates={codeTemplates}
            />
          )}
        />
        {toggleEdit && isRootStep(step as any) && (
          <DetailsButtonContainer>
            <Tooltip
              placement="bottomRight"
              title={<FormattedMessage id={getTranslationKey("runner", "stepDetails")} />}
            >
              <DoubleRightOutlined
                style={{ fontSize: "18px" }}
                onClick={() => {
                  actions.table.select({ ids: [step.id], mode: SelectMode.Replace });
                  toggleEdit();
                }}
              />
            </Tooltip>
          </DetailsButtonContainer>
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, { sessionId }) => ({
  actions: {
    ...bindActionCreators(stepsActions, dispatch),
    table: {
      ...bindActionCreators(getRunnerTableActions(sessionId), dispatch),
    },
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: IStepQuickEditContainerProps) => ({
    steps: runnerDataSelectors.getOrderedDataSelector(state, props.sessionId),
    localVariables: variableDataSelectors.getOrderedDataSelector(state, VARIABLES_SECTIONS.LOCAL),
    linkedVariables: variableDataSelectors.getOrderedDataSelector(state, VARIABLES_SECTIONS.LINKED),
    variablesGroups: getVariablesGroupsSelector(state, props.script.id),
    globalMutables: getScriptGlobalMutables(state, props.script.id),
    globalConstants: getScriptGlobalConstants(state, props.script.id),
    project: projectSelectors.getItemSelector(state, props.script.projectId),
    codeTemplates: codeTemplatesDataSelectors.getOrderedDataSelector(
      state,
      CODE_TEMPLATES_TABLES_CONFIG.MAIN.id(),
    ),
  }),
  mapDispatchToProps,
);

type IConnectProps = RouteComponentProps<any> & ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(StepQuickEditContainer));
