import * as React from "react";
import styled from "@emotion/styled";
import SchedulerGeneralDetailsContainer from "@app/modules/scheduler/SchedulerGeneralDetails.container";
import SchedulerJobActionsContainer from "@app/modules/scheduler/SchedulerJobActions.container";
import StatusField from "@app/modules/logs/components/StatusField";
import { ApplicationState } from "@app/modules/app.reducers";
import BreadcrumbContainer from "@ea/shared_components/common/Breadcrumb.container";
import { connect, ConnectedProps } from "react-redux";
import { Tabs } from "antd";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { schedulerActions } from "@app/modules/scheduler";
import { schedulerDataSelectors } from "@app/modules/scheduler/scheduler.selectors";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { BreadcrumbSection, MainSection, Page } from "@ea/shared_components/common/LayoutElements";
import SchedulerHistoryContainer from "./SchedulerHistory.container";
import NonIdealState from "@ea/shared_components/common/NonIdealState";
import WithAuthorization from "../common/WithAuthorization";
import { ROLES } from "@ea/shared_types/types";
import { currentUserRolesSelector } from "@ea/shared_components/auth/auth.selectors";
import SchedulerGanttContainer from "./SchedulerGantt.container";
import { isGanttScheduler } from "@ea/shared_types/typeGuards";
import { tryParseInt } from "@app/utils/transformations";
import SchedulerFlowTableContainer from "../schedulerFlow/SchedulerFlowTable.container";
// Temporary turned off
// import SchedulerDataContainer from "@app/modules/scheduler/SchedulerData.container";

const CustomPivot = styled(Tabs)({
  width: "80%",
});

const TopBar = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const PivotSection = styled.div({
  width: "100%",
  display: "flex",
  height: "100%",
  flex: 1,
});

enum SchedulerPaths {
  GENERAL = "general",
  FLOW = "flow",
  DATA = "data",
  GANTT = "gantt",
  HISTORY = "history",
}

const RoutePaths = {
  [SchedulerPaths.GENERAL]: "general",
  [SchedulerPaths.FLOW]: "flow",
  [SchedulerPaths.DATA]: "data",
  [SchedulerPaths.GANTT]: "gantt",
  [SchedulerPaths.HISTORY]: "history",
};

const SectionContainer = styled.div({
  marginBottom: "20px",
  width: "100%",
  height: "calc(100% - 90px)",
  display: "flex",
});
const TabPane = Tabs.TabPane;

const History = WithAuthorization([ROLES.logs])(PivotSection);

class SchedulerLoaderContainer extends React.Component<IConnectProps> {
  componentDidMount() {
    if (!this.props.schedulerJob) {
      this.props.loadSingle({ id: this.props.schedulerJobId });
    }
  }

  navigate = (item: string) => {
    const { history, match } = this.props;
    if (item) {
      history.push(`${match.url}/${RoutePaths[item]}`);
    }
  };

  generateBreadcrumbText = (chunk: string, index: number) => {
    if (index !== 1) {
      return chunk;
    }

    const { schedulerJob } = this.props;
    if (!schedulerJob) {
      return chunk;
    }

    return `${chunk} (${schedulerJob.name})`;
  };

  getPivotKey = (currentPath: string) => {
    if (
      currentPath !== "flow" &&
      currentPath !== "general" &&
      currentPath !== "history" &&
      currentPath !== "gantt" &&
      currentPath !== "data"
    ) {
      return "";
    }
    return currentPath;
  };

  getSection = (section, isGanttMode: boolean) => {
    const { match, schedulerJobId } = this.props;
    switch (section) {
      case SchedulerPaths.FLOW:
        return (
          !isGanttMode && (
            <PivotSection>
              <SchedulerFlowTableContainer schedulerJobId={parseInt(schedulerJobId, 10)} />
            </PivotSection>
          )
        );
      // Temporary turned off
      // case SchedulerPaths.DATA:
      //   return (
      //     <PivotSection>
      //       <SchedulerDataContainer schedulerJobId={parseInt(schedulerJobId, 10)} />
      //     </PivotSection>
      //   );
      case SchedulerPaths.GANTT:
        return (
          isGanttMode && (
            <PivotSection>
              <SchedulerGanttContainer schedulerJobId={parseInt(schedulerJobId, 10)} />
            </PivotSection>
          )
        );
      case SchedulerPaths.GENERAL:
        return (
          <PivotSection>
            <SchedulerGeneralDetailsContainer schedulerJobId={parseInt(schedulerJobId, 10)} />
          </PivotSection>
        );
      case SchedulerPaths.HISTORY:
        return (
          <History>
            <SchedulerHistoryContainer {...this.props} />
          </History>
        );
      default:
        return <Redirect to={`${match.url}/${RoutePaths[SchedulerPaths.GENERAL]}`} />;
    }
  };

  render() {
    const { location, match, schedulerJob, isSingleLoading, userRoles } = this.props;
    const pathChunks = location.pathname.split("/");
    const chunk = pathChunks.length > 3 ? pathChunks[3] : "";
    const path = this.getPivotKey(chunk);
    const showLogs = userRoles.includes(ROLES.logs) || userRoles.includes(ROLES.admin);

    if (isSingleLoading && !schedulerJob) {
      return (
        <Page>
          <BreadcrumbSection>
            <BreadcrumbContainer transformText={this.generateBreadcrumbText} />
          </BreadcrumbSection>
          <MainSection>
            <NonIdealState text={<FormattedMessage id={getTranslationKey("loadingLabel")!} />} />
          </MainSection>
        </Page>
      );
    }

    if (!isSingleLoading && !schedulerJob) {
      return (
        <Page>
          <BreadcrumbSection>
            <BreadcrumbContainer transformText={this.generateBreadcrumbText} />
          </BreadcrumbSection>
          <MainSection>
            <NonIdealState text={<FormattedMessage id={getTranslationKey("loadingLabel")!} />} />
          </MainSection>
        </Page>
      );
    }

    const isGanttMode = isGanttScheduler(schedulerJob);

    const pivotItems = [
      <TabPane
        key={SchedulerPaths.GENERAL}
        tab={<FormattedMessage id={getTranslationKey("common", "label", "general")} />}
      />,
      // Temporary turned off
      // isGanttMode ? (
      //   <TabPane
      //     key={SchedulerPaths.DATA}
      //     tab={<FormattedMessage id={getTranslationKey("common", "label", "data")} />}
      //   />
      // ) : null,
      isGanttMode ? (
        <TabPane
          key={SchedulerPaths.GANTT}
          tab={<FormattedMessage id={getTranslationKey("common", "label", "gantt")} />}
        />
      ) : (
        <TabPane
          key={SchedulerPaths.FLOW}
          tab={<FormattedMessage id={getTranslationKey("common", "label", "flow")} />}
        />
      ),
      showLogs ? (
        <TabPane
          key={SchedulerPaths.HISTORY}
          tab={<FormattedMessage id={getTranslationKey("common", "label", "history")} />}
        />
      ) : null,
    ];

    return (
      <Page>
        <BreadcrumbSection>
          <BreadcrumbContainer transformText={this.generateBreadcrumbText} />
          {schedulerJob && (
            <div style={{ marginLeft: "5px" }}>
              <StatusField status={schedulerJob.status} />
            </div>
          )}
        </BreadcrumbSection>
        <MainSection>
          <TopBar>
            <CustomPivot onChange={this.navigate} activeKey={path}>
              {pivotItems}
            </CustomPivot>
            <SchedulerJobActionsContainer schedulerJobId={tryParseInt(match.params.id)!} />
          </TopBar>
          <SectionContainer>
            {schedulerJob ? (
              this.getSection(path, isGanttMode)
            ) : (
              <FormattedMessage id={getTranslationKey("loadingLabel")!} />
            )}
          </SectionContainer>
        </MainSection>
      </Page>
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: RouteComponentProps<any>) => ({
    ...props,
    isSingleLoading: state.scheduler.isSingleLoading,
    schedulerJob: schedulerDataSelectors.getItemSelector(state, props.match.params.id),
    schedulerJobId: props.match.params.id,
    userRoles: currentUserRolesSelector(state),
  }),
  {
    ...{
      loadSingle: schedulerActions.loadSingle,
    },
  },
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(SchedulerLoaderContainer));
