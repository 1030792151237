import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { Step, RunnerState, RecorderStep, RunnerMode } from "@ea/shared_types/types";
import { TableActionParams } from "@ea/shared_components/redux/common.models";
import { ExecutionStep, PlayerVariables } from "@ea/shared_types/runner.common.types";
import { AdvancedRecorderSyncResponse } from "@ea/shared_types/runner.api.types";

const actionCreator = actionCreatorFactory("RUNNER_MODULE");

const baseModuleActions = createDataModuleActions<ExecutionStep>(actionCreator);

export const moduleActions = {
  ...baseModuleActions,
  table: {
    ...baseModuleActions.table,
    toggleMode: actionCreator.async<
      TableActionParams<{ mode: RunnerMode }>,
      AdvancedRecorderSyncResponse
    >("TOGGLE_MODE"),
    reset: actionCreator<TableActionParams<{}>>("RESET"),
    setRunnerParams: actionCreator<TableActionParams<Partial<RunnerState>>>("SET_RUNNER_PARAMS"),
    setRunnerRecorderParams: actionCreator<TableActionParams<Partial<RunnerState>>>(
      "SET_RUNNER_RECORDER_PARAMS",
    ),
    deleteWithUnselect:
      actionCreator<TableActionParams<{ ids: (string | number)[] }>>("DELETE_WITH_UNSELECT"),
    moveTo: actionCreator<TableActionParams<{ id: number; from: number; to: number }>>("MOVE_TO"),
    addNewStep: actionCreator<
      TableActionParams<{
        newStep: RecorderStep<any>;
        specificLineNum?: number;
      }>
    >("ADD_NEW_STEP"),
    updateStep: actionCreator<
      TableActionParams<{
        step: ExecutionStep;
        disableSync?: boolean;
      }>
    >("UPDATE_STEP"),
    updateLiveVariables:
      actionCreator<TableActionParams<{ variables: Partial<PlayerVariables>; init?: boolean }>>(
        "UPDATE_LIVE_VARIABLES",
      ),
    removeLiveVariables:
      actionCreator<TableActionParams<{ ids: string[] }>>("REMOVE_LIVE_VARIABLES"),
    syncSteps: actionCreator.async<
      TableActionParams<{}> & { idsToRemove?: number[]; recordingPath?: string },
      AdvancedRecorderSyncResponse
    >("SYNC_STEPS"),
  },
};

export const getRunnerTableActions = createTableActionsGetter(
  normalizeActions(moduleActions.table),
);
export const runnerActions = {
  ...normalizeActions(moduleActions.data),
  syncDone: moduleActions.table.syncSteps.done,
};
