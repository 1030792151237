interface Version {
  date?: string;
  name: string;
  hash?: string;
  version?: string;
}

const version: Version = require("./../../../../version.json");

export const getVersionInfo = () => {
  const date = version.date ? new Date(version.date).toLocaleString() : "";
  return `Branch Name: ${version.name}, build date: ${date}, commit hash: ${version.hash}, tag version: ${version.version}`;
};

export const getVersionInfoLogo = () => {
  const { version: tag, name } = version;

  let versionString = "";
  if (tag) {
    const lastDot = tag.lastIndexOf(".");
    versionString = lastDot > -1 ? tag.slice(0, lastDot) : tag;
  } else {
    versionString = name;
  }

  return `VERSION: ${versionString}`;
};
