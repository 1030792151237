"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RunnerPostMessageActions = exports.CodeExecutionLogType = void 0;
var CodeExecutionLogType;
(function (CodeExecutionLogType) {
    CodeExecutionLogType["CALCULATE"] = "CALCULATE";
    CodeExecutionLogType["ASSIGN"] = "ASSIGN";
})(CodeExecutionLogType || (exports.CodeExecutionLogType = CodeExecutionLogType = {}));
var RunnerPostMessageActions;
(function (RunnerPostMessageActions) {
    RunnerPostMessageActions["step_recorded"] = "step_recorded";
    RunnerPostMessageActions["start_recording"] = "start_recording";
    RunnerPostMessageActions["pause_recording"] = "pause_recording";
    RunnerPostMessageActions["toggle_inspect"] = "toggle_inspect";
    RunnerPostMessageActions["back_navigation"] = "back_navigation";
    RunnerPostMessageActions["refresh_navigation"] = "refresh_navigation";
    RunnerPostMessageActions["url_change"] = "url_change";
    RunnerPostMessageActions["get_initial_state"] = "get_initial_state";
    RunnerPostMessageActions["set_initial_state"] = "set_initial_state";
    RunnerPostMessageActions["start_player"] = "start_player";
    RunnerPostMessageActions["player_state"] = "player_state";
    RunnerPostMessageActions["player_position"] = "player_position";
})(RunnerPostMessageActions || (exports.RunnerPostMessageActions = RunnerPostMessageActions = {}));
