import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { SchedulerJob } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("SCHEDULER_MODULE");

export const moduleActions = createDataModuleActions<SchedulerJob>(actionCreator);
export const getSchedulerTableActions = createTableActionsGetter(
  normalizeActions(moduleActions.table),
);

export const schedulerActionCreators = {
  ...moduleActions,
  close: actionCreator.async<number[], SchedulerJob[], any>("CLOSE"),
};

export const schedulerActions = {
  ...normalizeActions(moduleActions.data),
  close: schedulerActionCreators.close.started,
};
