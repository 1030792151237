import { objectValues } from "@ea/shared_components/utils/object";

import * as scriptChangelogActions from "./scriptChangelog.actions";
import scriptChangelogEpicsMap from "./scriptChangelog.epics";
import * as scriptChangelogReducers from "./scriptChangelog.reducers";

const scriptChangelogEpics = objectValues(scriptChangelogEpicsMap);

export { scriptChangelogActions, scriptChangelogReducers, scriptChangelogEpics };

export { default as ScriptChangelogContainer } from "./ScriptChangelog.container";
