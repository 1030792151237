import { Schema, z } from "zod";
import {
  AzureDevopsConnectionDataSchema,
  CodeTemplateGroupSchemas,
  CodeTemplatesSchemas,
  DevopsProjectsExportParamsSchema,
  DevopsScriptExportParamsSchema,
  DocumentationSchemas,
  GlobalSettingSchemas,
  GlobalSettingsAutoScreenshotsCleanupSchema,
  GlobalVariableSchemas,
  GuardSchemas,
  IssueTrackingRuleSchemas,
  KpiSchemas,
  NumberSequenceSchemas,
  ProjectCategorySchemas,
  ProjectSchemas,
  ReportTemplateSchemas,
  RoleSchemas,
  SchedulerJobMappingSchemas,
  SchedulerSchemas,
  ScriptChangeLogSchema,
  ScriptSchemas,
  StepSchemas,
  SystemSchemas,
  TagSchemas,
  TestCasesQuerySchema,
  TestPlansQuerySchema,
  TestSuiteSiblingsQuerySchema,
  TokenSchemas,
  UserGroupSchemas,
  UserSchemas,
  VariableSchemas,
  VirtualUserSchemas,
  WorkItemDefinitionSchema,
  WorkItemSchemas,
  booleanSchema,
} from "./ea.schema";
import { CountResponseSchema, EndpointsSchemas } from "./ea.schema.endpoints";

import { Get } from "type-fest";
import { ExecuteEntityType, KpiStatistics, Statistic } from "../types";
import {
  ExecutionTerminateTypes,
  ReportExtension,
  RunnerMode,
  VIRTUAL_USER_STATUS,
} from "./ea.enums";
import { RunnerBaseSchema, StartBackgroundSessionParams } from "./ea.runner.types";

const createEndpoint = <
  P,
  U,
  Path extends string,
  Method extends "POST" | "GET" | "DELETE" | "PATCH" | "PUT",
  T extends Schema,
  K extends Schema,
>(endpoint: {
  method: Method;
  path: Path;
  request: T;
  requestType?: P;
  response: K;
  responseType?: U;
}): {
  method: Method;
  path: Path;
  request: any;
  response: any;
  responseType: U extends {} ? U : z.infer<(typeof endpoint)["response"]>;
  requestType: P extends {} ? P : z.infer<(typeof endpoint)["request"]>;
} => {
  return {
    ...endpoint,
    requestType: {},
    responseType: {},
  } as any;
};

export const EA_ENDPOINTS = {
  runner: {
    path: "runner",
    sub: {
      start: {
        endpoint: createEndpoint({
          method: "POST",
          path: "start",
          request: z.any(),
          response: z.object({
            url: z.string(),
            id: z.string(),
            error: z.object({ code: z.string().optional() }).optional(),
          }),
        }),
      },
      heartbeat: {
        endpoint: createEndpoint({
          method: "POST",
          path: "heartbeat",
          request: RunnerBaseSchema,
          response: z.any(),
        }),
      },
      meta: {
        endpoint: createEndpoint({
          method: "GET",
          path: "meta",
          request: RunnerBaseSchema,
          response: z.object({
            scriptId: z.number(),
            url: z.string(),
            mode: z.nativeEnum(RunnerMode),
          }),
        }),
      },
      init: {
        endpoint: createEndpoint({
          method: "POST",
          path: "init",
          request: RunnerBaseSchema,
          response: z.any(),
        }),
      },
      player: {
        path: "player",
        sub: {
          init: {
            endpoint: createEndpoint({
              method: "GET",
              path: "init",
              request: EndpointsSchemas.runner.player.init.request,
              response: EndpointsSchemas.runner.player.init.response,
              responseType: EndpointsSchemas.runner.player.init.responseType, // too complicated response type
            }),
          },
          moveStart: {
            endpoint: createEndpoint({
              method: "POST",
              path: "move/start",
              request: EndpointsSchemas.runner.player.moveStart.request,
              response: EndpointsSchemas.runner.player.moveStart.response,
            }),
          },
          moveFinish: {
            endpoint: createEndpoint({
              method: "POST",
              path: "move/finish",
              request: EndpointsSchemas.runner.player.moveFinish.request,
              response: EndpointsSchemas.runner.player.moveFinish.response,
            }),
          },
          close: {
            endpoint: createEndpoint({
              method: "POST",
              path: "close",
              request: z.object({
                sessionIds: z.array(z.string()),
              }),
              response: z.any(),
            }),
          },
          state: {
            endpoint: createEndpoint({
              method: "POST",
              path: "state",
              request: EndpointsSchemas.runner.player.state.request,
              response: EndpointsSchemas.runner.player.state.response,
            }),
          },
          tokens: {
            endpoint: createEndpoint({
              method: "POST",
              path: "tokens",
              requestType: EndpointsSchemas.runner.player.tokens.requestType,
              request: EndpointsSchemas.runner.player.tokens.request,
              response: EndpointsSchemas.runner.player.tokens.response,
            }),
          },
          log: {
            endpoint: createEndpoint({
              method: "POST",
              path: "log",
              request: EndpointsSchemas.runner.player.log.request,
              response: EndpointsSchemas.runner.player.log.response,
              requestType: EndpointsSchemas.runner.player.log.requestType,
            }),
          },
          kpi: {
            endpoint: createEndpoint({
              method: "POST",
              path: "kpi",
              request: EndpointsSchemas.runner.player.kpi.request,
              response: EndpointsSchemas.runner.player.kpi.response,
            }),
          },
          auth: {
            endpoint: createEndpoint({
              method: "POST",
              path: "auth",
              request: EndpointsSchemas.runner.player.auth.request,
              response: EndpointsSchemas.runner.player.auth.response,
            }),
          },
          logOut: {
            endpoint: createEndpoint({
              method: "POST",
              path: "logout",
              request: EndpointsSchemas.runner.player.logOut.request,
              response: EndpointsSchemas.runner.player.logOut.response,
            }),
          },
          resolvers: {
            endpoint: createEndpoint({
              method: "POST",
              path: "resolvers",
              request: EndpointsSchemas.runner.player.resolvers.request,
              response: EndpointsSchemas.runner.player.resolvers.response,
              requestType: EndpointsSchemas.runner.player.resolvers.requestType,
            }),
          },
          datasource: {
            endpoint: createEndpoint({
              method: "POST",
              path: "datasource",
              request: EndpointsSchemas.runner.player.datasource.request,
              response: EndpointsSchemas.runner.player.datasource.response,
            }),
          },
          backgroundScreenshot: {
            endpoint: createEndpoint({
              method: "POST",
              path: "background-screenshot",
              request: EndpointsSchemas.runner.player.backgroundScreenshot.request,
              response: EndpointsSchemas.runner.player.backgroundScreenshot.response,
            }),
          },
          backgroundVideo: {
            endpoint: createEndpoint({
              method: "POST",
              path: "background-video",
              request: EndpointsSchemas.runner.player.backgroundVideo.request,
              response: EndpointsSchemas.runner.player.backgroundVideo.response,
            }),
          },
          upload: {
            endpoint: createEndpoint({
              path: "screenshot-upload/:sessionId",
              method: "POST",
              request: z.any(),
              response: z.any(),
            }),
          },
          nextNumber: {
            endpoint: createEndpoint({
              method: "GET",
              path: "next-number",
              request: EndpointsSchemas.runner.player.nextNumber.request,
              response: EndpointsSchemas.runner.player.nextNumber.response,
            }),
          },
        },
      },
      recorder: {
        path: "recorder",
        sub: {
          init: {
            endpoint: createEndpoint({
              method: "GET",
              path: "init",
              request: EndpointsSchemas.runner.recorder.init.request,
              response: EndpointsSchemas.runner.recorder.init.response,
              responseType: EndpointsSchemas.runner.recorder.init.responseType,
            }),
          },
          auth: {
            endpoint: createEndpoint({
              method: "POST",
              path: "auth",
              request: EndpointsSchemas.runner.recorder.auth.request,
              response: EndpointsSchemas.runner.recorder.auth.response,
            }),
          },
          syncState: {
            endpoint: createEndpoint({
              method: "POST",
              path: "sync-state",
              request: EndpointsSchemas.runner.recorder.syncState.request,
              response: EndpointsSchemas.runner.recorder.syncState.response,
              requestType: EndpointsSchemas.runner.recorder.syncState.requestType,
            }),
          },
          finish: {
            endpoint: createEndpoint({
              method: "POST",
              path: "finish",
              requestType: EndpointsSchemas.runner.recorder.finish.requestType,
              request: EndpointsSchemas.runner.recorder.finish.request,
              response: EndpointsSchemas.runner.recorder.finish.response,
              responseType: EndpointsSchemas.runner.recorder.finish.responseType,
            }),
          },
          syncSteps: {
            endpoint: createEndpoint({
              method: "POST",
              path: "sync-steps",
              requestType: EndpointsSchemas.runner.recorder.syncSteps.requestType,
              request: EndpointsSchemas.runner.recorder.syncSteps.request,
              response: EndpointsSchemas.runner.recorder.syncSteps.response,
              responseType: EndpointsSchemas.runner.recorder.syncSteps.responseType,
            }),
          },
        },
      },
    },
  },
  users: {
    path: "users",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(UserSchemas["general"]),
          responseType: {} as any,
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: UserSchemas["createData"],
          response: UserSchemas["general"],
          requestType: {} as any,
          responseType: {} as any,
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: UserSchemas["update"],
          response: UserSchemas["general"],
          responseType: {} as any,
          requestType: {} as any,
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: z.any(),
          response: z.any(),
        }),
      },
      auth: {
        endpoint: createEndpoint({
          path: "auth",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
      login: {
        endpoint: createEndpoint({
          path: "login",
          method: "POST",
          request: UserSchemas["login"],
          response: z.object({ id: z.string(), userId: z.number() }),
        }),
      },
      logout: {
        endpoint: createEndpoint({
          path: "logout",
          method: "POST",
          request: z.any(),
          response: z.any(),
        }),
      },
      changePassword: {
        endpoint: createEndpoint({
          path: "change-password",
          method: "POST",
          request: z.object({
            oldPassword: z.string().optional(),
            newPassword: z.string(),
            userId: z.number(),
          }),
          response: z.any(),
        }),
      },
      groups: {
        path: "groups",
        sub: {
          get: {
            endpoint: createEndpoint({
              path: "",
              method: "GET",
              request: z.any(),
              response: z.array(UserGroupSchemas["general"]),
            }),
          },
          count: {
            endpoint: createEndpoint({
              path: "count",
              method: "GET",
              request: z.any(),
              response: CountResponseSchema,
            }),
          },
          create: {
            endpoint: createEndpoint({
              path: "",
              method: "POST",
              request: UserGroupSchemas["createData"],
              response: UserGroupSchemas["general"],
            }),
          },
          update: {
            endpoint: createEndpoint({
              path: ":id",
              method: "PATCH",
              request: UserGroupSchemas["update"],
              response: UserGroupSchemas["general"],
            }),
          },
          delete: {
            endpoint: createEndpoint({
              path: ":id",
              method: "DELETE",
              request: z.any(),
              response: z.any(),
            }),
          },
        },
      },
      roles: {
        path: "roles",
        sub: {
          get: {
            endpoint: createEndpoint({
              path: "",
              method: "GET",
              request: z.any(),
              response: z.array(RoleSchemas["general"]),
            }),
          },
          count: {
            endpoint: createEndpoint({
              path: "count",
              method: "GET",
              request: z.any(),
              response: CountResponseSchema,
            }),
          },
          create: {
            endpoint: createEndpoint({
              path: "",
              method: "POST",
              request: RoleSchemas["createData"],
              response: RoleSchemas["general"],
            }),
          },
          update: {
            endpoint: createEndpoint({
              path: ":id",
              method: "PATCH",
              request: RoleSchemas["update"],
              response: RoleSchemas["general"],
            }),
          },
          delete: {
            endpoint: createEndpoint({
              path: ":id",
              method: "DELETE",
              request: z.any(),
              response: z.any(),
            }),
          },
        },
      },
    },
  },
  tags: {
    path: "tags",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(TagSchemas["general"]),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: TagSchemas["createData"],
          response: TagSchemas["general"],
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: TagSchemas["update"],
          response: TagSchemas["general"],
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: z.any(),
          response: z.any(),
        }),
      },
    },
  },
  documentations: {
    path: "documentations",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(DocumentationSchemas["general"]),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: DocumentationSchemas["update"].extend({
            username: z.string().optional(),
            password: z.string().optional(),
          }),
          response: DocumentationSchemas["general"],
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: z.any(),
          response: z.any(),
        }),
      },
      downloadFromScripts: {
        endpoint: createEndpoint({
          path: "download/scripts",
          method: "POST",
          request: z.object({
            ids: z.array(z.coerce.number()),
          }),
          response: z.any(),
        }),
      },
      download: {
        endpoint: createEndpoint({
          path: "download",
          method: "POST",
          request: z.object({
            ids: z.array(z.coerce.number()),
          }),
          response: z.any(),
        }),
      },
      reports: {
        path: "reports",
        sub: {
          download: {
            endpoint: createEndpoint({
              path: "download",
              method: "POST",
              request: z.object({
                reportName: z.string(),
              }),
              response: z.any(),
            }),
          },
          generate: {
            endpoint: createEndpoint({
              path: "generate",
              method: "POST",
              request: z.object({
                logId: z.coerce.number(),
                wsId: z.string(),
                extension: z.nativeEnum(ReportExtension),
                tableId: z.string(),
                utcOffset: z.coerce.number(),
                reportName: z.string(),
              }),
              response: z.any(),
            }),
          },
        },
      },
    },
  },
  reportTemplates: {
    path: "report-templates",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(ReportTemplateSchemas["general"]),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: ReportTemplateSchemas["update"],
          response: ReportTemplateSchemas["general"],
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: z.any(),
          response: z.any(),
        }),
      },
      upload: {
        endpoint: createEndpoint({
          path: "upload",
          method: "POST",
          request: z.object({
            name: z.string(),
            description: z.string().optional().default(""),
            id: z.coerce.number().optional(),
          }),
          response: z.any(),
        }),
      },
      download: {
        endpoint: createEndpoint({
          path: "download/:filename",
          method: "POST",
          request: z.any(),
          response: z.any(),
        }),
      },
    },
  },
  export: {
    path: "export",
    sub: {
      download: {
        endpoint: createEndpoint({
          path: "download",
          method: "POST",
          request: z.object({
            fileName: z.string(),
          }),
          response: z.any(),
        }),
      },
      generate: {
        endpoint: createEndpoint({
          path: "generate",
          method: "POST",
          request: z.object({
            wsId: z.string(),
            meta: z
              .object({
                additionalFields: z
                  .object({
                    takeScreenshots: z.boolean().optional(),
                    screenshotsOnlyOnInterruption: z.boolean().optional(),
                    clickOnDisabledButtons: z.boolean().optional(),
                    useDefaultEnvironment: z.boolean().optional(),
                    topProjectName: z.boolean().optional(),
                    virtualUserName: z.boolean().optional(),
                    // ExecutionLog
                    scriptLogUrl: z.string(),
                    schedulerJobName: z.boolean(),
                    jobId: z.boolean(),
                    lastErrorMessage: z.boolean(),
                  })
                  .partial()
                  .optional(),
              })
              .passthrough(),
            localeId: z.coerce.number().optional(),
            modelName: z.string(),
            filter: z.any(),
          }),
          response: z.any(),
        }),
      },
    },
  },
  auditTrail: {
    path: "auditTrail",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      view: {
        path: "view",
        sub: {
          script: {
            path: "script",
            sub: {
              get: {
                endpoint: createEndpoint({
                  path: "",
                  method: "GET",
                  request: z.any(),
                  response: z.any(),
                }),
              },
              count: {
                endpoint: createEndpoint({
                  path: "count",
                  method: "GET",
                  request: z.any(),
                  response: CountResponseSchema,
                }),
              },
            },
          },
          project: {
            path: "project",
            sub: {
              get: {
                endpoint: createEndpoint({
                  path: "",
                  method: "GET",
                  request: z.any(),
                  response: z.any(),
                }),
              },
              count: {
                endpoint: createEndpoint({
                  path: "count",
                  method: "GET",
                  request: z.any(),
                  response: CountResponseSchema,
                }),
              },
            },
          },
          settings: {
            path: "settings",
            sub: {
              get: {
                endpoint: createEndpoint({
                  path: "",
                  method: "GET",
                  request: z.any(),
                  response: z.any(),
                }),
              },
              count: {
                endpoint: createEndpoint({
                  path: "count",
                  method: "GET",
                  request: z.any(),
                  response: CountResponseSchema,
                }),
              },
            },
          },
        },
      },
    },
  },
  scripts: {
    path: "scripts",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(ScriptSchemas["general"]),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: ScriptSchemas["createData"],
          response: z.any(),
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: z.object({
            script: ScriptSchemas["update"].extend({ id: z.number() }),
            options: z
              .object({
                credentials: z.object({ username: z.string(), password: z.string() }),
                versioning: z
                  .object({ comment: z.string().optional(), tags: z.array(z.number()).optional() })
                  .optional(),
                updateTestCaseStatus: z.boolean(),
              })
              .partial()
              .optional(),
          }),
          response: z.any(),
        }),
      },
      close: {
        endpoint: createEndpoint({
          path: "close",
          method: "POST",
          request: z.object({
            ids: z.array(z.number()),
            options: z.object({ updateTestCaseStatus: z.boolean().optional() }).optional(),
          }),
          response: z.any(),
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: z.object({ id: z.number() }),
          response: z.any(),
        }),
      },
      autocomplete: {
        endpoint: createEndpoint({
          path: "autocomplete",
          method: "GET",
          request: z.object({ url: z.string() }),
          response: z.array(z.string()),
        }),
      },
      export: {
        endpoint: createEndpoint({
          path: "export",
          method: "POST",
          request: z.object({
            scriptIds: z.array(z.coerce.number()),
          }),
          response: z.any(),
        }),
      },
      import: {
        endpoint: createEndpoint({
          path: "import/:projectId",
          method: "POST",
          request: z.any(),
          response: z.any(),
        }),
      },
      clone: {
        endpoint: createEndpoint({
          path: "clone",
          method: "POST",
          request: z.object({
            projectId: z.number(),
            scriptIds: z.array(z.coerce.number()),
            name: z.string().optional(),
            description: z.string().optional(),
          }),
          response: z.any(),
        }),
      },
      exportToCsv: {
        endpoint: createEndpoint({
          path: "export-to-csv",
          method: "POST",
          request: z.object({
            additionalFields: z
              .object({
                takeScreenshots: z.boolean().optional(),
                screenshotsOnlyOnInterruption: z.boolean().optional(),
                clickOnDisabledButtons: z.boolean().optional(),
                useDefaultEnvironment: z.boolean().optional(),
                topProjectName: z.boolean().optional(),
                virtualUserName: z.boolean().optional(),
              })
              .optional(),
            modelName: z.literal("TaskScript"),
            filter: z
              .object({
                select: z.object({}).catchall(z.any()),
              })
              .catchall(z.any()),
          }),
          response: z.any(),
        }),
      },
    },
  },
  scriptChangeLog: {
    path: "scriptChangeLog",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(ScriptChangeLogSchema),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
    },
  },
  projects: {
    path: "projects",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(ProjectSchemas["general"]),
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: ProjectSchemas["createData"],
          response: z.any(),
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: ProjectSchemas["update"],
          response: z.any(),
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          requestType: {} as { id: number; options?: { removeTestPlan?: boolean } },
          request: z.any(),
          response: z.any(),
        }),
      },
    },
  },
  steps: {
    path: "steps",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(StepSchemas["general"]),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: StepSchemas["createData"],
          response: z.any(),
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: StepSchemas["update"],
          response: z.any(),
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: z.object({ id: z.number() }),
          response: z.any(),
        }),
      },
      deleteMany: {
        endpoint: createEndpoint({
          path: "",
          method: "DELETE",
          request: z.object({ ids: z.array(z.number()) }),
          response: z.any(),
        }),
      },
      move: {
        endpoint: createEndpoint({
          path: ":id/move",
          method: "POST",
          request: z.object({ lineNum: z.number(), id: z.number() }),
          response: z.any(),
        }),
      },
      paste: {
        endpoint: createEndpoint({
          path: "paste",
          method: "POST",
          request: z.object({
            taskScriptId: z.number(),
            fromStepId: z.number(),
            sourceScriptId: z.number(),
            copiedSteps: z.array(
              StepSchemas["general"].omit({ labelParams: true, codeTemplates: true }),
            ),
          }),
          response: z.any(),
        }),
      },
      downloadFile: {
        endpoint: createEndpoint({
          path: "downloadFile/:id",
          method: "POST",
          request: z.any(),
          response: z.any(),
        }),
      },
      uploadTemporaryFile: {
        endpoint: createEndpoint({
          path: "uploadTemporaryFile",
          method: "POST",
          request: z.any(),
          response: z.any(),
        }),
      },
    },
  },
  numberSequence: {
    path: "number-sequences",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(NumberSequenceSchemas["general"]),
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: NumberSequenceSchemas["create"],
          response: NumberSequenceSchemas["general"],
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: NumberSequenceSchemas["update"],
          response: NumberSequenceSchemas["general"],
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: z.object({ id: z.number() }),
          response: z.any(),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      next: {
        endpoint: createEndpoint({
          path: "next",
          method: "GET",
          request: z.any(),
          response: z.object({ value: z.string() }),
        }),
      },
    },
  },
  globalVariables: {
    path: "global-variables",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(GlobalVariableSchemas["general"]),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: GlobalVariableSchemas["createData"],
          response: GlobalVariableSchemas["general"],
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: GlobalVariableSchemas["update"],
          response: GlobalVariableSchemas["general"],
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: GlobalVariableSchemas["general"].pick({ id: true }),
          response: z.any(),
        }),
      },
    },
  },
  virtualUsers: {
    path: "virtual-users",
    sub: {
      pool: {
        path: "pool",
        sub: {
          get: {
            endpoint: createEndpoint({
              path: "",
              method: "GET",
              request: z.any(),
              response: z.any(),
            }),
          },
          count: {
            endpoint: createEndpoint({
              path: "count",
              method: "GET",
              request: z.any(),
              response: CountResponseSchema,
            }),
          },
          create: {
            endpoint: createEndpoint({
              path: "",
              method: "POST",
              request: z.any(),
              response: z.any(),
            }),
          },
          update: {
            endpoint: createEndpoint({
              path: ":id",
              method: "PATCH",
              request: z.any(),
              response: z.any(),
            }),
          },
          delete: {
            endpoint: createEndpoint({
              path: ":id",
              method: "DELETE",
              request: z.any(),
              response: z.any(),
            }),
          },
        },
      },
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(VirtualUserSchemas["general"]),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: VirtualUserSchemas["createData"],
          response: VirtualUserSchemas["general"],
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: VirtualUserSchemas["update"],
          response: VirtualUserSchemas["general"],
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: z.any(),
          response: z.any(),
        }),
      },
      token: {
        endpoint: createEndpoint({
          path: "token",
          method: "POST",
          request: z.object({
            id: z.number(),
            tokens: z.object({}).catchall(z.any()),
          }),
          response: z.any(),
        }),
      },
      authenticate: {
        endpoint: createEndpoint({
          path: "authenticate",
          method: "POST",
          request: z.object({
            id: z.number(),
            sensitiveData: z
              .array(
                z.object({
                  key: z.string(),
                  value: z.string(),
                }),
              )
              .default([]),
          }),
          response: z.object({
            url: z.string().optional(),
            sessionId: z.string().optional(),
            errorMessage: z.string().optional(),
            status: z.nativeEnum(VIRTUAL_USER_STATUS),
          }),
        }),
      },
    },
  },
  codeTemplates: {
    path: "code-templates",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(CodeTemplatesSchemas["general"]),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: CodeTemplatesSchemas["createData"],
          response: CodeTemplatesSchemas["general"],
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: CodeTemplatesSchemas["update"],
          response: CodeTemplatesSchemas["general"],
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: CodeTemplatesSchemas["general"].pick({ id: true }),
          response: z.any(),
        }),
      },
      export: {
        endpoint: createEndpoint({
          path: "export",
          method: "POST",
          request: z.object({ codeTemplateIds: z.array(z.coerce.number()) }),
          response: z.any(),
        }),
      },
      import: {
        endpoint: createEndpoint({
          path: "import",
          method: "POST",
          request: z.any(),
          response: z.array(
            z.object({
              name: z.string(),
              imported: z.boolean(),
              error: z.string().optional(),
            }),
          ),
        }),
      },
      groups: {
        path: "groups",
        sub: {
          get: {
            endpoint: createEndpoint({
              path: "",
              method: "GET",
              request: z.any(),
              response: z.array(CodeTemplateGroupSchemas["general"]),
            }),
          },
          count: {
            endpoint: createEndpoint({
              path: "count",
              method: "GET",
              request: z.any(),
              response: CountResponseSchema,
            }),
          },
          create: {
            endpoint: createEndpoint({
              path: "",
              method: "POST",
              request: CodeTemplateGroupSchemas["createData"],
              response: CodeTemplateGroupSchemas["general"],
            }),
          },
          update: {
            endpoint: createEndpoint({
              path: ":id",
              method: "PATCH",
              request: CodeTemplateGroupSchemas["update"],
              response: CodeTemplateGroupSchemas["general"],
            }),
          },
          delete: {
            endpoint: createEndpoint({
              path: ":id",
              method: "DELETE",
              request: CodeTemplateGroupSchemas["general"].pick({ id: true }),
              response: z.any(),
            }),
          },
        },
      },
    },
  },
  guards: {
    path: "guards",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(GuardSchemas["general"]),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: GuardSchemas["createData"],
          response: z.any(),
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: GuardSchemas["update"],
          response: z.any(),
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: GuardSchemas["general"].pick({ id: true }),
          response: z.any(),
        }),
      },
    },
  },
  tokens: {
    path: "tokens",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(TokenSchemas["general"]),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: TokenSchemas["createData"],
          response: TokenSchemas["general"],
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: TokenSchemas["update"],
          response: TokenSchemas["general"],
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: z.any(),
          response: z.any(),
        }),
      },
    },
  },
  variables: {
    path: "variables",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(VariableSchemas["general"]),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: VariableSchemas["createData"],
          response: z.any(),
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: VariableSchemas["update"],
          response: z.any(),
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: VariableSchemas["general"].pick({ id: true }),
          response: z.any(),
        }),
      },
      patchDiff: {
        endpoint: createEndpoint({
          path: "diff",
          method: "PATCH",
          request: VariableSchemas["patchDiff"],
          response: z.any(),
        }),
      },
    },
  },
  kpis: {
    path: "kpis",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(KpiSchemas["general"]),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: KpiSchemas["createData"],
          response: KpiSchemas["general"],
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: KpiSchemas["update"],
          response: KpiSchemas["general"],
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: z.any(),
          response: z.any(),
        }),
      },
      statistics: {
        path: "statistics",
        sub: {
          execution: {
            endpoint: createEndpoint({
              path: "execution",
              method: "GET",
              request: z.object({
                executionIds: z.array(z.coerce.number()),
              }),
              response: z.any(),
              responseType: {} as KpiStatistics,
            }),
          },
          job: {
            endpoint: createEndpoint({
              path: "job",
              method: "GET",
              request: z.object({
                jobId: z.string(),
              }),
              response: z.any(),
              responseType: {} as KpiStatistics,
            }),
          },
        },
      },
    },
  },
  workItems: {
    path: "work-items",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(WorkItemSchemas["general"]),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: WorkItemSchemas["createData"],
          response: WorkItemSchemas["general"],
        }),
      },
    },
  },
  issueTrackingTools: {
    path: "issue-tracking-tools",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: z.any(),
          response: z.any(),
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: z.any(),
          response: z.any(),
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: z.any(),
          response: z.any(),
        }),
      },
      configurations: {
        path: "configurations",
        sub: {
          get: {
            endpoint: createEndpoint({
              path: "",
              method: "GET",
              request: z.any(),
              response: z.any(),
            }),
          },
          count: {
            endpoint: createEndpoint({
              path: "count",
              method: "GET",
              request: z.any(),
              response: CountResponseSchema,
            }),
          },
          create: {
            endpoint: createEndpoint({
              path: "",
              method: "POST",
              request: z.any(),
              response: IssueTrackingRuleSchemas["createData"],
            }),
          },
          update: {
            endpoint: createEndpoint({
              path: ":id",
              method: "PATCH",
              request: z.any(),
              response: IssueTrackingRuleSchemas["update"],
            }),
          },
          delete: {
            endpoint: createEndpoint({
              path: ":id",
              method: "DELETE",
              request: z.any(),
              response: z.any(),
            }),
          },
          fetch: {
            endpoint: createEndpoint({
              path: "fetch",
              method: "GET",
              request: z.any(),
              response: z.any(),
            }),
          },
        },
      },
      rules: {
        path: "rules",
        sub: {
          get: {
            endpoint: createEndpoint({
              path: "",
              method: "GET",
              request: z.any(),
              response: z.array(IssueTrackingRuleSchemas["general"]),
            }),
          },
          count: {
            endpoint: createEndpoint({
              path: "count",
              method: "GET",
              request: z.any(),
              response: CountResponseSchema,
            }),
          },
          create: {
            endpoint: createEndpoint({
              path: "",
              method: "POST",
              request: IssueTrackingRuleSchemas["createData"],
              response: IssueTrackingRuleSchemas["general"],
            }),
          },
          update: {
            endpoint: createEndpoint({
              path: ":id",
              method: "PATCH",
              request: IssueTrackingRuleSchemas["update"],
              response: IssueTrackingRuleSchemas["general"],
            }),
          },
          delete: {
            endpoint: createEndpoint({
              path: ":id",
              method: "DELETE",
              request: z.any(),
              response: z.any(),
            }),
          },
        },
      },
      azureDevops: {
        path: "azure-devops",
        sub: {
          createWorkitem: {
            endpoint: createEndpoint({
              path: "create-workitem",
              method: "POST",
              request: WorkItemDefinitionSchema,
              response: z.any(),
            }),
          },
          iterations: {
            endpoint: createEndpoint({
              path: "iterations",
              method: "GET",
              request: AzureDevopsConnectionDataSchema,
              response: z.any(),
            }),
          },
          projects: {
            endpoint: createEndpoint({
              path: "projects",
              method: "GET",
              request: AzureDevopsConnectionDataSchema,
              response: z.any(),
            }),
          },
          workitemTypes: {
            endpoint: createEndpoint({
              path: "workitem-types",
              method: "GET",
              request: AzureDevopsConnectionDataSchema,
              response: z.any(),
            }),
          },
          fields: {
            endpoint: createEndpoint({
              path: "fields",
              method: "GET",
              request: AzureDevopsConnectionDataSchema,
              response: z.any(),
            }),
          },
          areas: {
            endpoint: createEndpoint({
              path: "areas",
              method: "GET",
              request: AzureDevopsConnectionDataSchema,
              response: z.any(),
            }),
          },
        },
      },
      integration: {
        path: "integration",
        sub: {
          export: {
            endpoint: createEndpoint({
              path: "export",
              method: "POST",
              request: DevopsProjectsExportParamsSchema,
              response: z.any(),
            }),
          },
          testPlans: {
            endpoint: createEndpoint({
              path: "test-plans",
              method: "GET",
              request: TestPlansQuerySchema,
              response: z.any(),
            }),
          },
          testSuiteSiblings: {
            endpoint: createEndpoint({
              path: "test-suite-siblings",
              method: "GET",
              request: TestSuiteSiblingsQuerySchema,
              response: z.any(),
            }),
          },
          testCases: {
            endpoint: createEndpoint({
              path: "test-cases",
              method: "GET",
              request: TestCasesQuerySchema,
              response: z.any(),
            }),
          },
          exportScript: {
            endpoint: createEndpoint({
              path: "export-script",
              method: "POST",
              request: DevopsScriptExportParamsSchema,
              response: z.any(),
            }),
          },
        },
      },
    },
  },
  settings: {
    path: "settings",
    sub: {
      internal: {
        path: "internal",
        sub: {
          get: {
            endpoint: createEndpoint({
              path: "",
              method: "GET",
              request: z.any(),
              response: z.any(),
            }),
          },
          public: {
            endpoint: createEndpoint({
              path: "public",
              method: "GET",
              request: z.any(),
              response: z.any(),
            }),
          },
          screenshot: {
            path: "screenshotsAutoCleanup",
            sub: {
              get: {
                endpoint: createEndpoint({
                  path: "",
                  method: "GET",
                  request: z.any(),
                  response: GlobalSettingsAutoScreenshotsCleanupSchema,
                }),
              },
              set: {
                endpoint: createEndpoint({
                  path: "",
                  method: "POST",
                  request: GlobalSettingsAutoScreenshotsCleanupSchema,
                  response: z.any(),
                }),
              },
              stats: {
                endpoint: createEndpoint({
                  path: "stats",
                  method: "GET",
                  request: z.any(),
                  response: z.any(),
                }),
              },
            },
          },
        },
      },
      global: {
        path: "global",
        sub: {
          get: {
            endpoint: createEndpoint({
              path: "",
              method: "GET",
              request: z.any(),
              response: z.any(),
            }),
          },
          update: {
            endpoint: createEndpoint({
              path: "",
              method: "PATCH",
              request: GlobalSettingSchemas["update"],
              response: z.any(),
            }),
          },
        },
      },
    },
  },
  localizations: {
    path: "localizations",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: z.any(),
          response: z.any(),
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: z.any(),
          response: z.any(),
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: z.object({ id: z.number() }),
          response: z.any(),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
      export: {
        endpoint: createEndpoint({
          path: "export/:id",
          method: "POST",
          request: z.any(),
          response: z.any(),
        }),
      },
      import: {
        endpoint: createEndpoint({
          path: "import",
          method: "POST",
          request: z.object({ overwriteExisting: booleanSchema.optional() }),
          response: z.any(),
        }),
      },
      translations: {
        endpoint: createEndpoint({
          path: "translation",
          method: "GET",
          request: z.object({ locale: z.string(), objectFormat: booleanSchema.optional() }),
          response: z.any(),
        }),
      },
    },
  },
  systems: {
    path: "system",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(SystemSchemas["general"]),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: SystemSchemas["createData"],
          response: z.any(),
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: SystemSchemas["update"],
          response: z.any(),
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: z.object({ id: z.number() }),
          response: z.any(),
        }),
      },
    },
  },
  dataSources: {
    path: "data-sources",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: z.object({ id: z.number() }),
          response: z.any(),
        }),
      },
      local: {
        path: "local",
        sub: {
          upload: {
            endpoint: createEndpoint({
              path: "upload/:scriptId",
              method: "POST",
              request: z.any(),
              response: z.any(),
            }),
          },
        },
      },
      global: {
        path: "global",
        sub: {
          update: {
            endpoint: createEndpoint({
              path: "update/:datasourceId",
              method: "POST",
              request: z.object({
                projectIds: z.union([
                  z.array(z.number()),
                  z.preprocess(
                    (v: string) => v.split(",").map((v) => parseInt(v)),
                    z.array(z.number()),
                  ),
                ]),
              }),
              response: z.any(),
            }),
          },
          upload: {
            endpoint: createEndpoint({
              path: "upload",
              method: "POST",
              request: z.object({
                projectIds: z.preprocess(
                  (v: string) => v.split(",").map((v) => parseInt(v)),
                  z.array(z.number()),
                ),
              }),
              response: z.any(),
            }),
          },
          projectExcels: {
            endpoint: createEndpoint({
              path: "project-excels",
              method: "GET",
              request: z.object({
                projectId: z.coerce.number(),
              }),
              response: z.any(),
            }),
          },
          assignToScript: {
            endpoint: createEndpoint({
              path: ":datasourceId/script/:scriptId",
              method: "POST",
              request: z.any(),
              response: z.any(),
            }),
          },
          unassignFromScript: {
            endpoint: createEndpoint({
              path: "unassign/:scriptId",
              method: "POST",
              request: z.any(),
              response: z.any(),
            }),
          },
        },
      },
      download: {
        endpoint: createEndpoint({
          path: "download",
          method: "POST",
          request: z.object({
            id: z.coerce.number(),
          }),
          response: z.any(),
        }),
      },
      generate: {
        endpoint: createEndpoint({
          path: "generate",
          method: "POST",
          request: z.object({
            scriptId: z.coerce.number(),
          }),
          response: z.any(),
        }),
      },
    },
  },
  projectsCategory: {
    path: "projects-category",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.array(ProjectCategorySchemas["general"]),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: ProjectCategorySchemas["createData"],
          response: z.any(),
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: ProjectCategorySchemas["update"],
          response: z.any(),
        }),
      },
      delete: {
        endpoint: createEndpoint({
          path: ":id",
          method: "DELETE",
          request: z.object({ id: z.number() }),
          response: z.any(),
        }),
      },
    },
  },
  logs: {
    path: "logs",
    sub: {
      execution: {
        path: "execution",
        sub: {
          get: {
            endpoint: createEndpoint({
              path: "",
              method: "GET",
              request: z.any(),
              response: z.any(),
            }),
          },
          count: {
            endpoint: createEndpoint({
              path: "count",
              method: "GET",
              request: z.any(),
              response: CountResponseSchema,
            }),
          },
          project: {
            endpoint: createEndpoint({
              path: "project",
              method: "POST",
              responseType: {} as Statistic[],
              request: z.object({
                projectId: z.coerce.number(),
              }),
              response: z.any(),
            }),
          },
          stats: {
            endpoint: createEndpoint({
              path: "stats",
              method: "GET",
              request: z.any(),
              response: z.any(),
              responseType: {} as Statistic[],
            }),
          },
          overview: {
            endpoint: createEndpoint({
              path: ":id/overview",
              method: "GET",
              request: z.any(),
              response: z.any(),
            }),
          },
          capturedFiles: {
            endpoint: createEndpoint({
              path: ":id/capturedFiles",
              method: "GET",
              request: z.any(),
              response: z.any(),
            }),
          },
          downloadCapturedFile: {
            endpoint: createEndpoint({
              path: "download/:sessionId/capturedFiles/:filename",
              method: "POST",
              request: z.any(),
              response: z.any(),
            }),
          },
        },
      },
      step: {
        path: "step",
        sub: {
          screenshot: {
            endpoint: createEndpoint({
              path: "screenshot/:sessionId/:fileName",
              method: "GET",
              requestType: {} as { sessionId: string; fileName: string },
              request: z.any(),
              response: z.any(),
            }),
          },
          get: {
            endpoint: createEndpoint({
              path: "",
              method: "GET",
              request: z.any(),
              response: z.any(),
            }),
          },
          count: {
            endpoint: createEndpoint({
              path: "count",
              method: "GET",
              request: z.any(),
              response: CountResponseSchema,
            }),
          },
          executionScreenshots: {
            endpoint: createEndpoint({
              path: "executionScreenshots",
              method: "GET",
              request: z.object({ id: z.coerce.number() }),
              response: z.any(),
            }),
          },
        },
      },
      job: {
        path: "job",
        sub: {
          aggregated: {
            path: "aggregated",
            sub: {
              exclusion: {
                endpoint: createEndpoint({
                  path: "exclusion",
                  method: "POST",
                  request: z.object({
                    jobExecutionIds: z.array(z.string()),
                  }),
                  response: z.any(),
                }),
              },
              get: {
                endpoint: createEndpoint({
                  path: "",
                  method: "GET",
                  request: z.any(),
                  response: z.any(),
                }),
              },
              count: {
                endpoint: createEndpoint({
                  path: "count",
                  method: "GET",
                  request: z.any(),
                  response: CountResponseSchema,
                }),
              },
            },
          },
        },
      },
    },
  },
  // for legacy compatibility
  background: {
    path: "background",
    sub: {
      execute: {
        endpoint: createEndpoint({
          path: "executeAsync",
          method: "POST",
          request: z.object({
            id: z.union([z.coerce.number(), z.array(z.coerce.number())]),
            type: z.nativeEnum(ExecuteEntityType),
          }),
          response: z.any(),
        }),
      },
      status: {
        endpoint: createEndpoint({
          path: "executeAsyncStatus",
          method: "GET",
          request: z.object({ backgroundExecutionId: z.string() }),
          response: z.any(),
        }),
      },
      testCases: {
        endpoint: createEndpoint({
          path: "testCaseMap",
          method: "POST",
          request: z.any(),
          response: z.any(),
        }),
      },
      candidates: {
        endpoint: createEndpoint({
          path: "candidates",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
    },
  },
  execution: {
    path: "execution",
    sub: {
      terminate: {
        endpoint: createEndpoint({
          path: "terminate",
          method: "POST",
          request: z.object({
            type: z.nativeEnum(ExecutionTerminateTypes),
            ids: z.array(z.string()),
          }),
          response: z.any(),
        }),
      },
      run: {
        endpoint: createEndpoint({
          path: "run",
          method: "POST",
          request: z.any(),
          response: z.any(),
          requestType: {} as any as Partial<StartBackgroundSessionParams>,
        }),
      },
    },
  },
  schedulers: {
    path: "schedulers",
    sub: {
      get: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
      count: {
        endpoint: createEndpoint({
          path: "count",
          method: "GET",
          request: z.any(),
          response: CountResponseSchema,
        }),
      },
      clone: {
        endpoint: createEndpoint({
          path: "clone",
          method: "POST",
          request: z.object({ schedulerId: z.number(), name: z.string() }),
          response: z.any(),
        }),
      },
      close: {
        endpoint: createEndpoint({
          path: "close",
          method: "POST",
          request: z.object({ ids: z.array(z.number()) }),
          response: z.any(),
        }),
      },
      create: {
        endpoint: createEndpoint({
          path: "",
          method: "POST",
          request: SchedulerSchemas["createData"],
          response: z.any(),
        }),
      },
      import: {
        endpoint: createEndpoint({
          path: "import",
          method: "POST",
          request: z.object({
            fromSchedulerId: z.number(),
            toSchedulerId: z.number(),
            takeSettings: z.boolean().optional(),
            jobMappingsIds: z.array(z.number()),
          }),
          response: z.any(),
        }),
      },
      update: {
        endpoint: createEndpoint({
          path: ":id",
          method: "PATCH",
          request: SchedulerSchemas["update"],
          response: z.any(),
        }),
      },
      checkProjectRun: {
        endpoint: createEndpoint({
          path: "check-project",
          method: "GET",
          request: z.object({
            projectId: z.coerce.number(),
          }),
          response: z.object({
            isRunning: z.boolean(),
          }),
        }),
      },
      runProject: {
        endpoint: createEndpoint({
          path: "run-project",
          method: "POST",
          request: z.object({
            projectId: z.coerce.number(),
          }),
          response: z.object({
            jobId: z.string(),
          }),
        }),
      },
      terminateProject: {
        endpoint: createEndpoint({
          path: "terminate-project",
          method: "POST",
          request: z.object({
            projectId: z.coerce.number(),
          }),
          response: z.any(),
        }),
      },
      runNow: {
        endpoint: createEndpoint({
          path: ":id/run",
          method: "POST",
          request: z.object({
            schedulerId: z.number(),
          }),
          response: z.any(),
        }),
      },
      // limitation of UI, it needs to be here and not double nested
      deleteMapping: {
        endpoint: createEndpoint({
          path: "mappings/:id",
          method: "DELETE",
          request: z.object({ id: z.number() }),
          response: z.any(),
        }),
      },
      groups: {
        path: "groups",
        sub: {
          delete: {
            endpoint: createEndpoint({
              path: ":id",
              method: "DELETE",
              request: z.object({ id: z.number() }),
              response: z.any(),
            }),
          },
          deleteAll: {
            endpoint: createEndpoint({
              path: "",
              method: "DELETE",
              request: z.object({
                schedulerId: z.number(),
                groupIds: z.array(z.number()),
                scriptIds: z.array(z.number()),
              }),
              response: z.any(),
            }),
          },
          update: {
            endpoint: createEndpoint({
              path: "",
              method: "POST",
              request: z.object({
                schedulerId: z.number(),
                groups: z.array(z.any()),
                replaceExisting: z.boolean().optional(),
              }),
              response: z.any(),
            }),
          },
          scripts: {
            endpoint: createEndpoint({
              path: "scripts",
              method: "POST",
              request: z.object({
                groupId: z.number(),
                scripts: z.array(z.any()),
              }),
              response: z.any(),
            }),
          },
        },
      },
      mappings: {
        path: ":id/mappings",
        sub: {
          get: {
            endpoint: createEndpoint({
              path: "",
              method: "GET",
              request: z.any(),
              response: z.any(),
            }),
          },
          count: {
            endpoint: createEndpoint({
              path: "count",
              method: "GET",
              request: z.any(),
              response: CountResponseSchema,
            }),
          },
          diff: {
            endpoint: createEndpoint({
              path: "diff",
              method: "POST",
              request: z.object({
                schedulerJobMappings: z.array(SchedulerJobMappingSchemas["diff"]),
                schedulerJobId: z.number(),
              }),
              response: z.any(),
            }),
          },
        },
      },
    },
  },
  screenshots: {
    path: "screenshots",
    sub: {
      delete: {
        endpoint: createEndpoint({
          path: "",
          method: "DELETE",
          request: z.object({ days: z.number() }),
          response: z.any(),
        }),
      },
    },
  },
  bi: {
    path: "bi",
    sub: {
      history: {
        endpoint: createEndpoint({
          path: "history",
          method: "GET",
          request: z.object({
            filter: z.any(),
            withSteps: z.coerce.boolean().optional(),
          }),
          response: z.any(),
        }),
      },
      scriptHistory: {
        endpoint: createEndpoint({
          path: "scriptHistory",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
      scripts: {
        endpoint: createEndpoint({
          path: "scripts",
          method: "GET",
          request: z.object({
            filter: z.any(),
            withHistory: z.coerce.boolean().optional(),
            limitHistory: z.coerce.number().optional(),
          }),
          response: z.any(),
        }),
      },
      schedulers: {
        endpoint: createEndpoint({
          path: "schedulers",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
      schedulerAggregatedHistory: {
        endpoint: createEndpoint({
          path: "schedulerAggregatedHistory",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
      schedulerErrors: {
        endpoint: createEndpoint({
          path: "schedulerErrors",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
    },
  },
  oauth: {
    path: "auth",
    sub: {
      authenticate: {
        endpoint: createEndpoint({
          path: ":id",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
      redirect: {
        endpoint: createEndpoint({
          path: ":id/redirect",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
      refresh: {
        endpoint: createEndpoint({
          path: ":id/refresh",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
    },
  },
  saml: {
    path: "saml",
    sub: {
      authenticate: {
        endpoint: createEndpoint({
          path: "",
          method: "GET",
          request: z.any(),
          response: z.any(),
        }),
      },
      redirect: {
        endpoint: createEndpoint({
          path: "redirect",
          method: "POST",
          request: z.any(),
          response: z.any(),
        }),
      },
    },
  },
} as const;

type EA_ENDPOINTS_TYPE = typeof EA_ENDPOINTS;

type EA_ENDPOINT_KEYS = EndpointDeepKey<EA_ENDPOINTS_TYPE>;
export const EA_FULL_ENDPOINTS = joinEndpoints(EA_ENDPOINTS);

// EA_ENDPOINTS.labels.sub.update.endpoint.path => "create"
// EA_FULL_ENDPOINTS.labels.sub.update.endpoint.path => "test/create"
// type responseType = EndpointTypes<"runner.sub.player.sub.auth">["response"]; // => number
// getEndpoint("labels.sub.update.endpoint.path") => { path, request, response }
export function getEndpoint<T extends EA_ENDPOINT_KEYS>(
  path: T,
  params: {
    joinPaths: boolean;
  } = { joinPaths: true },
): Get<EA_ENDPOINTS_TYPE, T>["endpoint"] {
  const getValue = (path: any, obj: any) =>
    path
      .split(".")
      .concat(["endpoint"])
      .reduce((acc: any, c: any) => acc && acc[c], obj);

  // todo: we need to make it more compatible because of missing structuredClone
  return params?.joinPaths
    ? { ...getValue(path, EA_ENDPOINTS), path: getValue(path, EA_FULL_ENDPOINTS).path }
    : getValue(path, EA_ENDPOINTS);
}

type EndpointIgnoreKey = "endpoint" | "path";
type NestedEndpointKey = "sub";

export type EndpointType = {
  method: "POST" | "GET" | "DELETE" | "PATCH" | "PUT";
  path: string;
  request: Schema;
  requestType?: any;
  response: Schema;
  responseType?: any;
};

type EndpointPath = {
  path: string;
};

type Endpoint = {
  sub?: Endpoints;
  endpoint: EndpointType;
};

type ExtendedEndpoint = {
  sub?: Endpoints;
} & (
  | {
      endpoint: EndpointType;
    }
  | EndpointPath
);

type Endpoints = {
  [key: string]: ExtendedEndpoint;
};

// for future:
type CreateRoutes<T> = {
  [P in keyof T]: P extends EndpointIgnoreKey
    ? EndpointType
    : T[P] extends CreateRoutes<T[P]>
    ? Record<string, CreateRoutes<T[P]>>
    : never;
};

type EndpointDeepKey<T, K extends keyof T = keyof T> = K extends EndpointIgnoreKey | "path"
  ? never
  : K extends string | number
  ? T[K] extends infer R
    ? K extends NestedEndpointKey
      ? R extends Record<string, unknown>
        ? `${K}.${EndpointDeepKey<R>}`
        : never
      :
          | (R extends EndpointPath ? never : `${K}`)
          | (R extends Record<string, unknown> ? `${K}.${EndpointDeepKey<R>}` : never)
    : never // impossible route
  : never; // impossible route

type EndpointDeepValue<T, P extends EndpointDeepKey<T>> = P extends `${infer K}.${infer Rest}`
  ? T[(K extends `${infer R extends number}` ? R : K) & keyof T] extends infer S
    ? S extends never // make S distributive to work with union object
      ? never
      : Rest extends EndpointDeepKey<S>
      ? EndpointDeepValue<S, Rest>
      : never // impossible route
    : never // impossible route
  : T[(P extends `${infer R extends number}` ? R : P) & keyof T] extends {
      endpoint: infer Endpoint;
    }
  ? Endpoint extends EndpointType
    ? Endpoint
    : // ? InferEndpoint<Endpoint>
      never
  : never;

// commenting for performance reasons
// type EndpointTypes<P extends EndpointDeepKey<typeof EA_ENDPOINTS>> = EndpointDeepValue<
//   typeof EA_ENDPOINTS,
//   P
// >;

function joinEndpoints<T>(endpointsDefinition: T): T {
  const copy = JSON.parse(JSON.stringify(endpointsDefinition)); // change to  structuredClone(endpointsDefinition); after node migration
  const delimiter = "/";
  const shouldStartFromDelimiter = false;
  const traverse = (partialObject: any, totalPath: string = "") => {
    const keys = Object.keys(partialObject);

    const extendPath = (path: string) => {
      const beginning =
        totalPath === ""
          ? shouldStartFromDelimiter
            ? `${totalPath}${delimiter}`
            : totalPath
          : path === ""
          ? `${totalPath}`
          : `${totalPath}${delimiter}`;

      return `${beginning}${path}`;
    };

    if (partialObject.path) {
      partialObject.path = extendPath(partialObject.path);
    }

    if (partialObject.endpoint) {
      partialObject.endpoint.path = extendPath(partialObject.endpoint.path);
    }
    const nextPath = partialObject.endpoint?.path || partialObject.path || totalPath;

    for (const key of keys) {
      if (key === "endpoint" || key === "path") {
        continue;
      }

      traverse(partialObject[key], nextPath);
    }
  };

  traverse(copy);

  return copy;
}
