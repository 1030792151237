import { DatePicker } from "antd";
import styled from "@emotion/styled";
import moment from "moment";

import createFormField from "../createFormField";
import withReadonly from "./withReadonly";
import { ReadonlyFormItem } from "../Form.common";
import {
  PickerBaseProps,
  PickerDateProps,
  PickerTimeProps,
} from "antd/lib/date-picker/generatePicker";

type DatePickerProps = PickerBaseProps<moment.Moment> &
  PickerDateProps<moment.Moment> &
  PickerTimeProps<moment.Moment>;
export const DatePickerField = createFormField<
  React.ComponentProps<typeof DatePicker> & ReadonlyFormItem,
  { timeFormat?: string }
>(
  withReadonly(styled(DatePicker)({ width: "100%" })),
  ({
    name,
    onOpenChange,
    disabledDate,
    onChange,
    value,
    onBlur,
    onFocus,
    timeFormat,
    getPopupContainer,
    ...rest
  }) => ({
    ...rest,
    name,
    disabledDate,
    format: timeFormat,
    onOpenChange,
    onChange,
    value,
    getPopupContainer,
    onBlur: onBlur as any,
    onFocus: onFocus as any,
  }),
  () => ({ customFormat: (value) => value && moment(value) }),
) as any;

export const MonthPickerField = createFormField<
  React.ComponentProps<typeof DatePicker.MonthPicker> & ReadonlyFormItem,
  { timeFormat?: string }
>(
  withReadonly(styled(DatePicker.MonthPicker)({ width: "100%" })),
  ({
    name,
    onOpenChange,
    disabledDate,
    onChange,
    value,
    onBlur,
    onFocus,
    timeFormat,
    getPopupContainer,
    ...rest
  }) => ({
    ...rest,
    name,
    disabledDate,
    format: timeFormat,
    onOpenChange,
    onChange,
    value,
    getPopupContainer: getPopupContainer
      ? getPopupContainer
      : () => {
          return document.querySelector(".ant-modal") || document.body;
        },
    onBlur: onBlur as any,
    onFocus: onFocus as any,
  }),
  () => ({ customFormat: (value) => value && moment(value) }),
) as any;

export default DatePickerField;
