import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { downloadFileBlob } from "@ea/shared_components/helpers/file";
import { Query } from "@ea/shared_components/src/services/api.common.models";
import { CsvExportAdditionalFields, ExportToCsvModels, User } from "@ea/shared_types/types";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

interface DownloadCsvParams {
  params: Query<any>;
  modelName: ExportToCsvModels;
  selected?: number[];
  onExportStart: () => void;
  onExportFinish: () => void;
  fields?: string[];
  overrideFileName?: string;
  localeId?: number;
  userId: number;
  preferencesId: string;
  additionalFields?: CsvExportAdditionalFields;
}

const fileNameMap = {
  [ExportToCsvModels.SCRIPTS]: "Scripts.csv",
  [ExportToCsvModels.LOGS]: "History.csv",
  [ExportToCsvModels.EXECUTION_JOB_LOGS]: "AggregatedJobLogs.csv",
  [ExportToCsvModels.SCHEDULER_FLOW]: "SchedulerScripts.csv",
  [ExportToCsvModels.STEP_LOGS]: "ExecutionHistory.csv",
};

export const exportToCsv = async ({
  params,
  modelName,
  selected = [],
  onExportStart,
  onExportFinish,
  fields,
  overrideFileName,
  localeId,
  userId,
  preferencesId,
  additionalFields,
}: DownloadCsvParams) => {
  onExportStart();
  if (!params.filter) {
    params.filter = {};
  }
  if (!params.filter.where) {
    params.filter.where = {};
  }
  if (selected.length > 0) {
    params.filter.where = { ...params.filter.where, id: { inq: selected } };
  }
  if (fields) {
    params.filter.fields = fields;
    // for now we have to getUser from api because hidding columns is in shared components directly in database
    // so changing state of columns are not reloaded in redux
    const users: User[] = await API.getUsers({ filter: { where: { id: userId } } });
    if (users && users[0]) {
      const hiddenColumns = users[0]?.settings?.table?.[preferencesId]?.hiddenColumns || [];
      if (hiddenColumns.length > 0) {
        params.filter.fields = fields.filter((f) => hiddenColumns?.indexOf(f) === -1);
      }
    }
  }
  delete params.filter.limit;
  delete params.filter.offset;
  try {
    const response = await API.exportData({
      filter: params.filter,
      modelName,
      localeId,
      meta: {
        additionalFields,
      },
    } as any);
  } catch (error) {
    toast.error(<FormattedMessage id={getTranslationKey("messages", "error", "export")} />);
    console.error(error);
    onExportFinish();
  } finally {
    onExportFinish();
  }
};
