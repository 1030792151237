import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { IssueTrackingToolRule } from "@ea/shared_types/types";
import * as React from "react";
import { GetAsTime } from "@app/modules/common/time";
import Tag from "antd/lib/tag";
import { getTranslationKey } from "@app/translations/translations.helpers";

export const ITS_RULES_COLUMNS_CONFIG: PlainObject<CustomColumn<IssueTrackingToolRule>> = {
  name: {
    label: getTranslationKey("table", "name"),
    dataIndex: "name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  itsName: {
    label: getTranslationKey("its", "label", "its"),
    dataIndex: "itsName",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  triggers: {
    label: getTranslationKey("its", "label", "triggers"),
    dataIndex: "triggers",
    render: (text) => text.map((t, index) => <Tag key={index}>{t}</Tag>),
  },
  createdAt: {
    label: getTranslationKey("table", "createdAt"),
    dataIndex: "createdAt",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "createdAt" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
};

export const ITS_RULES_COLUMNS: ColumnConfig<IssueTrackingToolRule>[] = [
  {
    props: ITS_RULES_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: ITS_RULES_COLUMNS_CONFIG.itsName,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: ITS_RULES_COLUMNS_CONFIG.triggers,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: ITS_RULES_COLUMNS_CONFIG.createdAt,
    frameworkProps: {
      width: "20%",
    },
  },
];

export const ITS_RULES_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_ITS_RULES",
  },
  PROJECT_ASSIGNMENT: {
    id: (projectId) => `PROJECT_ASSIGNMENT_${projectId}`,
    preferencesId: "ITS_RULES_PROJECT_ASSIGNMENT",
  },
  PROJECT_ASSIGNMENT_DISPLAY: {
    id: (projectId) => `PROJECT_ASSIGNMENT_DISPLAY_${projectId}`,
    preferencesId: "ITS_RULES_PROJECT_ASSIGNMENT_DISLAY",
  },
};
