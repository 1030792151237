import { Spin, Progress } from "antd";
import styled from "@emotion/styled";
import { Alert } from "antd";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { CustomColumn } from "@ea/shared_components/Table/common.tables";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { COLORS } from "@app/styles/consts";
import { Statistic, STATUS_TYPE } from "@ea/shared_types/types";
import { InjectedIntlProps, injectIntl } from "react-intl";
import LogsPieChart from "../components/LogsPieChart";
import { logsDataSelectors } from "../logs.selectors";

interface ProjectExecutionChartProps {
  tableId: string;
  columnsConfig: PlainObject<CustomColumn<any>>;
  title?: string;
  customFilter?: any;
  autoReload?: boolean | number;
  setRef?: (comp: any) => void;
  isRunning?: boolean;
  projectId: number;
  isBeingDispatched?: boolean;
}

interface ProjectExecutionChartState {
  data: Statistic[] | undefined;
  errorMessage: string | undefined;
}

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
  textAlign: "center",
  alignItems: "center",
});

const SpinContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minWidth: "450px",
  paddingTop: "20%",
});

const timer = {};

class ProjectExecutionChart extends React.Component<
  IConnectProps & InjectedIntlProps & ProjectExecutionChartProps,
  any
> {
  state: ProjectExecutionChartState = {
    data: undefined,
    errorMessage: undefined,
  };

  componentDidMount() {
    const { setRef, autoReload } = this.props;
    if (setRef) {
      setRef(this);
    }
    this.loadData();
    if (autoReload) {
      this.setAutoReload();
    }
  }

  componentWillUnmount() {
    clearInterval(timer[this.props.tableId]);
  }

  componentDidUpdate(prevProps: IConnectProps & ProjectExecutionChartProps) {
    if (prevProps.tableId !== this.props.tableId) {
      this.setState({ data: undefined, errorMessage: undefined });
      this.loadData();
    }
    if (prevProps.autoReload !== this.props.autoReload) {
      if (this.props.autoReload) {
        clearInterval(timer[this.props.tableId]);
        this.setAutoReload();
      } else {
        clearInterval(timer[this.props.tableId]);
      }
    }
  }

  loadData = () => {
    API.getProjectExecutionStatistics({ projectId: this.props.projectId })
      .then((data) => {
        this.setState({ data, errorMessage: undefined });
      })
      .catch((error) => {
        this.setState({
          data: [],
          errorMessage: this.props.intl.formatMessage({
            id: "messages.error.failedFetchStatistics",
          }),
        });
      });
  };

  reload = () => {
    this.setState({ data: undefined });
    this.loadData();
  };

  setAutoReload = () => {
    const { autoReload } = this.props;
    const timeout =
      autoReload &&
      typeof autoReload === "number" &&
      Number.isInteger(autoReload as any) &&
      autoReload > 0
        ? autoReload
        : 10000;

    timer[this.props.tableId] = setInterval(() => {
      if (document.visibilityState === "visible") {
        this.loadData();
      }
    }, timeout);
  };

  createParams = () => {
    const params = createRequestParams(this.props.tableParams, this.props.columnsConfig, {
      defaultOrder: ["startTime DESC"],
    });
    if (params.filter) {
      delete params.filter.offset;
      delete params.filter.limit;
    }

    if (this.props.customFilter) {
      params.filter = { ...params.filter, ...this.props.customFilter };
    }

    params.groupBy = "status";

    return params;
  };

  getRunningTitle = () => {
    const { data } = this.state;
    const { title, isRunning, intl } = this.props;

    let modifiedTitle = title;
    let progress = null;
    if (data && isRunning) {
      const untested = data.find((e) => e.name === STATUS_TYPE.UNTESTED);
      const untestedCount = untested ? untested.value : 0;
      const allCount = data.reduce((prev, curr) => {
        return prev + curr.value;
      }, 0);
      const done = allCount - untestedCount;
      modifiedTitle = `${title} (${intl.formatMessage({
        id: "common.running",
      })}... ${done}/${allCount})`;
      progress = (
        <Progress percent={Math.floor(((done - 1) / allCount) * 100)} status="active" />
      ) as any;
    }

    return (
      <>
        <h2>{modifiedTitle}</h2>
        {progress}
      </>
    );
  };

  render() {
    const { data, errorMessage } = this.state;
    const { intl, isBeingDispatched } = this.props;

    if (isBeingDispatched) {
      return (
        <SpinContainer>
          <Spin tip={intl.formatMessage({ id: "projects.dispatching" })} />
        </SpinContainer>
      );
    }

    return data ? (
      <Container>
        {errorMessage && <Alert type="error" message={errorMessage} />}
        {this.getRunningTitle()}
        {data.length ? (
          <LogsPieChart data={data} colors={COLORS.STATUS} />
        ) : (
          <h3>{intl.formatMessage({ id: "common.noData" })}</h3>
        )}
      </Container>
    ) : (
      <SpinContainer>
        <Spin tip={intl.formatMessage({ id: "common.loadingStatistics" })} />
      </SpinContainer>
    );
  }
}

const connectCreator = connect((state: ApplicationState, props: ProjectExecutionChartProps) => ({
  tableParams: logsDataSelectors.getParamsSelector(state, props.tableId),
}));

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(injectIntl(ProjectExecutionChart));
