import * as React from "react";

import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { UserGroup } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";

export const USER_GROUPS_COLUMNS_CONFIG: PlainObject<CustomColumn<UserGroup>> = {
  id: {
    label: getTranslationKey("table", "id"),
    dataIndex: "id",
  },
  name: {
    dataIndex: "name",
    label: getTranslationKey("table", "name"),
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  description: {
    dataIndex: "description",
    label: getTranslationKey("table", "description"),
  },
};

export const USER_GROUPS_COLUMNS: ColumnConfig<UserGroup>[] = [
  {
    props: USER_GROUPS_COLUMNS_CONFIG.id,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: USER_GROUPS_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "25%",
    },
  },
  {
    props: USER_GROUPS_COLUMNS_CONFIG.description,
    frameworkProps: {
      width: "75%",
    },
  },
];

export const USER_GROUPS_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_USER_GROUPS",
  },
  PROJECT_ASSIGNMENT_DISPLAY: {
    id: (id: number | string) => `PROJECT_ASSIGNMENT_DISPLAY_${id}`,
    preferencesId: "USER_GROUPS_PROJECT_ASSIGNMENT",
  },
  PROJECT_ASSIGNMENT: {
    id: (id: number | string) => `PROJECT_ASSIGNMENT_${id}`,
    preferencesId: "USER_GROUPS_PROJECT_ASSIGNMENT_DISPLAY",
  },
};
