import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { toast } from "react-toastify";
import { ColumnProps, TableCellDataGetterParams } from "react-virtualized";
import { bindActionCreators, Dispatch } from "redux";
import { Modal, Tag, Tooltip } from "antd";
import { injectIntl, InjectedIntlProps, FormattedMessage } from "react-intl";
import { ApplicationState } from "@app/modules/app.reducers";
import VirtualizedTable from "@ea/shared_components/Table/elements/VirtualizedTable";
import { GetAsSeconds } from "@app/modules/common/time";
import {
  scriptsDataSelectors,
  getScriptGlobalConstants,
  getScriptGlobalMutables,
} from "@app/modules/scripts/scripts.selectors";
import {
  getSelectedSelector,
  getSelectedStepsMapSelector,
  getSelectedStepsSelector,
  getStepsSelector,
  getSelectedStepSelector,
  getStepsOrderSelector,
  getNotSelectableStepsSelector,
} from "@app/modules/steps/steps.selectors";
import { RunnerMode, VirtualUser, System } from "@ea/shared_types/types";
import StepsCommandBar from "./components/StepsCommandBar";
import { stepsActions, stepsTableActions } from "./steps.actions";
import { STEPS_COPY_STORAGE_KEY, getStepLabelParams } from "./steps.utils";
import { SelectMode } from "@ea/shared_components/redux/common.models";
import StepScriptPanel from "./components/StepScriptPanel";
import { VARIABLES_SECTIONS, variableActions } from "../variables/variables.actions";
import {
  variableDataSelectors,
  getVariablesGroupsSelector,
} from "../variables/variables.selectors";
import { cellRenderer } from "@ea/shared_components/Table/elements/CellRenderer";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { isScriptReadonly, formatScriptName } from "@app/utils/script";
import { COLORS } from "@ea/shared_components/styles/consts";
import { CoreCommandsIds } from "@ea/shared_types/core.commands.types";
import { NewStepPanel } from "./components/NewStepPanel";
import { projectSelectors } from "../projects/projects.selectors";
import { getCreateableManually } from "@app/packs/packs.helpers";
import {
  CommandDefinitionWeb,
  CommandDefinitionBaseType,
  DEFAULT_PLATFORM_ID,
} from "@ea/runner_loader/ea.internal.types";
import { currentUserSettingsSelector } from "@ea/shared_components/auth/auth.selectors";
import { withTranslation, WithTranslation } from "react-i18next";
import { startSession } from "@app/utils/start";

import { ForkOutlined, CodeOutlined } from "@ant-design/icons";
import { DataTestIds, DataTestIdProp } from "@app/utils/dataTestIds";
import { codeTemplatesDataSelectors } from "../codeTemplates/codeTemplates.selectors";
import { CODE_TEMPLATES_TABLES_CONFIG } from "../codeTemplates/codeTemplates.table";

interface IStepsContainerProps {
  virtualUsers: VirtualUser[];
  systems: System[];
  virtualUser?: VirtualUser;
  system?: System;
  scriptId: number;
  openedPanel?: string;
  setOpenPanel: (panel?: string) => void;
}
interface IStepsTableState {
  isDragEnable: boolean;
  createableSteps: CommandDefinitionWeb<CommandDefinitionBaseType>[];
  fileSelector: any;
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  padding: "5px",
});
const TextContainer = styled.div({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const GetAsTick = ({ dataKey, rowData }: TableCellDataGetterParams) => {
  const checked = rowData[dataKey];
  if (checked) {
    return "\u2714";
  }
  return "";
};

const TABLE_ID = "STEPS";

const { confirm } = Modal;
class StepsTableContainer extends React.Component<
  IStepsContainerProps & IConnectProps & WithTranslation,
  IStepsTableState
> {
  state: IStepsTableState = {
    isDragEnable: false,
    createableSteps: [],
    fileSelector: null,
  };

  componentDidMount() {
    this.state.fileSelector = this.buildFileSelector();
    if (this.props.selectedSteps.length === 1) {
      this.loadLinkedVariables();
    }

    const createableSteps = getCreateableManually(
      this.props.system && this.props.system.systemPlatform,
    );

    this.setState({
      createableSteps,
    });
  }

  componentWillUnmount() {
    // clear selection on exit
    this.props.actions.select({ ids: [], mode: SelectMode.Replace });
  }

  componentDidUpdate(prevProps: IStepsContainerProps & IConnectProps) {
    if (this.props.selectedSteps.length === 1) {
      if (this.props.selectedSteps[0] === undefined) {
        return;
      }
      if (
        prevProps.selectedSteps.length === 1 &&
        prevProps.selectedSteps[0].id === this.props.selectedSteps[0].id
      ) {
        return;
      }

      this.loadLinkedVariables();
    }

    if (this.props.system !== prevProps.system) {
      const createableSteps = getCreateableManually(
        this.props.system && this.props.system.systemPlatform,
      );

      this.setState({
        createableSteps,
      });
    }
  }

  getLabel = ({ dataKey, rowData }: TableCellDataGetterParams) => {
    const name = rowData[dataKey];
    const commandId = rowData.commandId;

    return (
      <>
        <TextContainer>
          {rowData.label ? name : this.props.t(rowData.labelKey, getStepLabelParams(rowData))}
        </TextContainer>
        {commandId === CoreCommandsIds.script && (
          <Tag
            style={{
              cursor: "default",
              marginLeft: "15px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "20%",
            }}
            color={COLORS.LINK_STEP_TAG}
          >
            LINK - {rowData.linkName}
          </Tag>
        )}
        {rowData.guards && rowData.guards.length > 0 && (
          <Tooltip title={<FormattedMessage id={getTranslationKey("step", "hasConditions")} />}>
            <Tag
              style={{
                cursor: "default",
              }}
              color={COLORS.GUARD_TAG}
            >
              <ForkOutlined style={{ fontSize: 16 }} />
            </Tag>
          </Tooltip>
        )}
        {rowData.codeTemplates && rowData.codeTemplates.length > 0 && (
          <Tooltip title={<FormattedMessage id={getTranslationKey("step", "hasCodeTemplate")} />}>
            <Tag
              style={{
                cursor: "default",
              }}
              color={COLORS.CODE_TEMPLATE_TAG}
            >
              <CodeOutlined style={{ fontSize: 16 }} />
            </Tag>
          </Tooltip>
        )}
      </>
    );
  };

  stepsColumns = () => {
    const stepsColumns: ColumnProps[] = [
      { label: "table.line", dataKey: "lineNum", width: 35 },
      { label: "table.optional", dataKey: "isOptional", width: 70, cellDataGetter: GetAsTick },
      {
        label: "table.label",
        dataKey: "label",
        width: 210,
        flexGrow: 1,
        cellDataGetter: this.getLabel,
        cellRenderer: cellRenderer,
        style: {
          display: "flex",
          justifyContent: "space-between",
        },
      },
      {
        label: "table.avgTime",
        dataKey: "averageDuration",
        width: 120,
        cellDataGetter: GetAsSeconds,
      },
    ];
    return stepsColumns;
  };

  loadLinkedVariables = () => {
    const { steps, selectedSteps } = this.props;
    const selectedStepId = selectedSteps[0].id;

    const index = steps.findIndex((s) => s.id === selectedStepId);
    const prevSteps = steps.slice(0, index + 1);
    const linkedStepsIds = prevSteps
      .filter((s) => s.commandId === CoreCommandsIds.script)
      .map((s) => s.linkedScriptId!);

    // todo: optimize
    if (linkedStepsIds.length > 0) {
      this.props.actions.variables.loadLinked(linkedStepsIds);
    } else {
      this.props.actions.variables.clearLinked();
    }
  };

  openAssignStepPanel = () => this.props.setOpenPanel("assign");

  openAssertStepPanel = () => this.props.setOpenPanel("assert");

  openLoginStepPanel = () => this.props.setOpenPanel("login");

  openScriptStepPanel = () => this.props.setOpenPanel("scriptStep");

  openShortcutModal = () => this.props.setOpenPanel("shortcut");

  openTokenPanel = () => this.props.setOpenPanel("tokens");

  close = () => this.props.setOpenPanel(undefined);

  reload = () => {
    const { selectedScript } = this.props;
    if (selectedScript) {
      this.props.actions.load({ reload: true, clearPrevious: true });
    }
  };

  toggleDragMode = () => {
    this.setState({
      isDragEnable: !this.state.isDragEnable,
    });
  };

  copy = () => {
    const { scriptId, selectedSteps } = this.props;
    const sortedSteps = selectedSteps
      .filter((s) => s.commandId !== CoreCommandsIds.start && s.commandId !== CoreCommandsIds.end)
      .filter((s) => s !== undefined)
      .sort((a: any, b: any) => a.lineNum - b.lineNum);
    sessionStorage.setItem(
      STEPS_COPY_STORAGE_KEY,
      JSON.stringify({ sourceScriptId: scriptId, sortedSteps }),
    );
    toast.success(this.props.intl.formatMessage({ id: "messages.success.stepsCopied" }));
  };

  buildFileSelector() {
    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("type", "file");
    fileSelector.setAttribute("accept", "JSON");
    return fileSelector;
  }

  delete = () => {
    const selectedStartEndStep = this.props.selectedSteps.some(
      (step) => step.commandId === CoreCommandsIds.end || step.commandId === CoreCommandsIds.start,
    );

    if (selectedStartEndStep) {
      toast.error(
        this.props.intl.formatMessage({
          id: "messages.error.noStartEndStepDelete",
        }),
      );
      return;
    }

    const { selected } = this.props;

    confirm({
      title: this.props.intl.formatMessage({
        id: `messages.confirm.${selected.length > 1 ? "delSteps" : "delStep"}`,
      }),
      okText: this.props.intl.formatMessage({ id: "button.delete" }),
      okType: "danger",
      cancelText: this.props.intl.formatMessage({ id: "button.cancel" }),
      onOk: () => {
        this.props.actions.delete({ ids: selected });
      },
      onCancel() {},
      okButtonProps: { [`${DataTestIdProp}`]: DataTestIds.MODAL_BUTTON_DELETE } as any,
      cancelButtonProps: { [`${DataTestIdProp}`]: DataTestIds.MODAL_BUTTON_CANCEL } as any,
    });
  };

  toggleDisable = (value: boolean) => {
    this.props.selectedSteps.map((step) =>
      this.props.actions.edit({
        ...step,
        isDisabled: value,
      }),
    );
  };

  toggleOptional = (value: boolean) => {
    this.props.selectedSteps.map((step) =>
      this.props.actions.edit({
        ...step,
        isOptional: value,
      }),
    );
  };

  paste = async () => {
    const stringSteps = sessionStorage.getItem(STEPS_COPY_STORAGE_KEY);
    if (!stringSteps) {
      return;
    }
    const { scriptId, selected, stepsOrder } = this.props;
    const fromStepId = selected.length < 1 ? stepsOrder[0] : selected[0];
    const { sourceScriptId, sortedSteps } = JSON.parse(stringSteps);

    this.props.actions.pasteSteps({
      taskScriptId: parseInt(scriptId as any, 10),
      fromStepId,
      copiedSteps: sortedSteps,
      sourceScriptId: parseInt(sourceScriptId as any, 10),
    });
  };

  rework = () => {
    const { selectedScript, selected, stepsOrder, userSettings } = this.props;
    if (selectedScript) {
      const selectedStepOrderIndex = stepsOrder.findIndex((id) => id === selected[0]);
      const nextStepId = stepsOrder[selectedStepOrderIndex];
      const { playMode, ...defaultParams } = userSettings;

      const reworkConfig =
        selectedStepOrderIndex > -1 && nextStepId !== undefined
          ? {
              reworkStepId: nextStepId,
              reworkPreviousStepId: selected[0].toString(),
            }
          : {
              reworkStepId: stepsOrder[0],
              reworkPreviousStepId: stepsOrder[0].toString(),
            };
      startSession(formatScriptName(selectedScript), {
        mode: RunnerMode.RECORDER,
        script: selectedScript.id,
        isBackground: false,
        ...reworkConfig,
        ...defaultParams,
      });
    }
  };

  render() {
    const { isDragEnable, createableSteps } = this.state;
    const {
      openedPanel,
      variablesGroups,
      variables,
      project,
      steps,
      selectedScript,
      actions,
      virtualUsers,
      systems,
      system,
      virtualUser,
      globalConstants,
      globalMutables,
      codeTemplates,
    } = this.props;

    const panelProps = {
      close: this.close,
      afterCreate: this.reload,
      variablesGroups: variablesGroups,
      variables: variables,
      script: selectedScript,
      virtualUsers,
      systems,
      project: project,
      existingSteps: steps,
      loadLocalVariables: actions.variables.loadLocal,
      system,
      openLinked: () => {},
      virtualUser,
    };

    return (
      <Container>
        {createableSteps.map((createableStep) => (
          <NewStepPanel
            {...panelProps}
            platform={system ? system.systemPlatform : DEFAULT_PLATFORM_ID}
            key={createableStep.id}
            isOpen={openedPanel === createableStep.id}
            commandId={createableStep.id}
            globalConstants={globalConstants}
            globalMutables={globalMutables}
            codeTemplates={codeTemplates}
            headerText={
              <span>
                <FormattedMessage id={getTranslationKey("step", "newPanel", "create")} />
                &nbsp;
                <FormattedMessage id={createableStep.createableManually!.text} />
                &nbsp;
                <FormattedMessage id={getTranslationKey("step", "newPanel", "step")} />
              </span>
            }
          />
        ))}

        <StepScriptPanel
          project={project}
          script={selectedScript}
          isOpen={openedPanel === "scriptStep"}
          close={this.close}
          afterCreate={this.reload}
          scriptId={this.props.scriptId}
        />
        {this.props.selectedScript && isScriptReadonly(this.props.selectedScript) ? null : (
          <StepsCommandBar
            openPanel={this.props.setOpenPanel}
            createableSteps={createableSteps}
            reworkDisabled={
              !(
                this.props.selected.length === 1 &&
                this.props.selectedStep &&
                this.props.selectedStep.commandId !== "end"
              )
            }
            rework={this.rework}
            openTokenPanel={this.openTokenPanel}
            openAssignStepPanel={this.openAssignStepPanel}
            openAssertStepPanel={this.openAssertStepPanel}
            openLoginStepPanel={this.openLoginStepPanel}
            openScriptStepPanel={this.openScriptStepPanel}
            onRemoveClick={this.delete}
            selectedSteps={this.props.selectedSteps}
            selected={this.props.selected}
            onReload={this.reload}
            isDragEnabled={isDragEnable}
            toggleDragMode={this.toggleDragMode}
            copy={this.copy}
            paste={this.paste}
            scriptId={this.props.scriptId}
            toggleDisable={this.toggleDisable}
            toggleOptional={this.toggleOptional}
            openShortcutModal={this.openShortcutModal}
          />
        )}

        <VirtualizedTable
          items={this.props.steps}
          selectItem={this.props.actions.select}
          isDragEnabled={isDragEnable}
          isLoading={this.props.isLoading && (!this.props.steps || this.props.steps.length === 0)}
          moveTo={this.props.actions.moveTo}
          columns={this.stepsColumns()}
          selectedMap={this.props.selectedStepsMap}
          notSelectableIds={this.props.notSelectableIds}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: ApplicationState, props: IStepsContainerProps) => {
  const selectedScript = scriptsDataSelectors.getItemSelector(state, props.scriptId);

  return {
    ...props,
    project: projectSelectors.getItemSelector(state, selectedScript.projectId),
    selectedScript,
    steps: getStepsSelector(state, TABLE_ID),
    selectedSteps: getSelectedStepsSelector(state, TABLE_ID),
    selectedStep: getSelectedStepSelector(state, TABLE_ID),
    stepsOrder: getStepsOrderSelector(state, TABLE_ID),
    selectedStepsMap: getSelectedStepsMapSelector(state, TABLE_ID),
    selected: getSelectedSelector(state, TABLE_ID),
    isLoading: state.steps.isLoading,
    scriptId: props.scriptId,
    variablesGroups: getVariablesGroupsSelector(state, props.scriptId),
    variables: variableDataSelectors.getOrderedDataSelector(state, VARIABLES_SECTIONS.LOCAL),
    notSelectableIds: getNotSelectableStepsSelector(state, TABLE_ID),
    userSettings: currentUserSettingsSelector(state),
    globalMutables: getScriptGlobalMutables(state, props.scriptId),
    globalConstants: getScriptGlobalConstants(state, props.scriptId),
    codeTemplates: codeTemplatesDataSelectors.getOrderedDataSelector(
      state,
      CODE_TEMPLATES_TABLES_CONFIG.MAIN.id(),
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(stepsActions, dispatch),
    ...bindActionCreators(stepsTableActions(TABLE_ID), dispatch),
    variables: {
      ...bindActionCreators(variableActions, dispatch),
    },
  },
});

const connectCreator = connect(mapStateToProps, mapDispatchToProps);

type IConnectProps = ConnectedProps<typeof connectCreator> & InjectedIntlProps;

export default connectCreator(withTranslation()(injectIntl(StepsTableContainer)));
