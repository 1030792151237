import { getScriptsTableActions } from "@app/modules/scripts/scripts.actions";
import { scriptsSelectorsModifier } from "@app/modules/scripts/scripts.selectors";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { Script } from "@ea/shared_types/types";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { SCRIPTS_COLUMNS_GLOBAL_EXCELS, SCRIPTS_TABLES_CONFIG } from "../../scripts/scripts.tables";

interface IGlobalExcelsUsedInTableProps {
  datasourceId: number;
}

const GlobalExcelsUsedInTable = ({
  datasourceId,
  history,
}: IGlobalExcelsUsedInTableProps & RouteComponentProps<undefined>) => {
  const openScript = (item: Script) => {
    history.push(`/scripts/${item.id}`);
  };

  return (
    <ConnectedTable
      key={"flat_table"}
      pageable
      selectable={false}
      modifySelectors={scriptsSelectorsModifier}
      columnsConfig={SCRIPTS_COLUMNS_GLOBAL_EXCELS}
      onRowDoubleClick={openScript}
      tableId={SCRIPTS_TABLES_CONFIG.GLOBAL_EXCELS.id(datasourceId)}
      preferencesId={SCRIPTS_TABLES_CONFIG.GLOBAL_EXCELS.preferencesId}
      stateKey={"scripts"}
      tableActions={getScriptsTableActions}
      persistentQuery={{ datasourceId, status: { neq: "CLOSED" } }}
    />
  );
};

export default withRouter(GlobalExcelsUsedInTable);
