export enum GLOBAL_SETTINGS_KEYS {
  STATUS_TRANSITIONS = "STATUS_TRANSITIONS",
  PUBLISHING_CREDENTIALS_CONFIRMATION = "PUBLISHING_CREDENTIALS_CONFIRMATION",
  DEFAULT_APP_USER_SETTINGS = "DEFAULT_APP_USER_SETTINGS",
  TEST_PLANS_INTEGRATION = "TEST_PLANS_INTEGRATION",
  TIMEOUTS = "TIMEOUTS",
  REPORT_TEMPLATE = "REPORT_TEMPLATE",
  DOCUMENTATION_TEMPLATE = "DOCUMENTATION_TEMPLATE",
  ADMINISTRATION_ADDRESS = "ADMINISTRATION_ADDRESS",
  REPORTS_LINK = "REPORTS_LINK",
  RESOLVERS_MODE = "RESOLVERS_MODE",
  POSITION_RESOLVER_META_SCORE_THRESHOLD = "POSITION_RESOLVER_META_SCORE_THRESHOLD",
  GENERATE_RESOLVERS_ON_PLAYBACK = "GENERATE_RESOLVERS_ON_PLAYBACK",
}

export enum INTERNAL_SETTINGS_KEYS {
  DISABLED_FEATURES = "DISABLED_FEATURES",
  AUTO_SCREENSHOTS_CLEANUP = "AUTO_SCREENSHOTS_CLEANUP",
  HOTJAR = "HOTJAR",
}
export enum PUBLIC_SETTINGS_KEYS {
  RECAPTCHA_PUBLIC_KEY = "RECAPTCHA_PUBLIC_KEY",
  RECAPTCHA_ENABLED = "RECAPTCHA_ENABLED",
  SSO_AZURE_ENABLED = "SSO_AZURE_ENABLED",
  DISABLED_FEATURES = "DISABLED_FEATURES",
  SSO_SAML_ENABLED = "SSO_SAML_ENABLED",
}

export enum FEATURES {
  AUDIT_TRAIL = "AUDIT_TRAIL",
  GXP_TAGS = "GXP_TAGS",
  ELECTRONIC_SIGNOFF = "ELECTRONIC_SIGNOFF",
  SCRIPT_VERSIONING = "SCRIPT_VERSIONING",
  DOCUMENTATION = "DOCUMENTATION",
  REMOVABLE_DOCUMENTATION = "REMOVABLE_DOCUMENTATION",
}

export enum InternalSettignsVisibility {
  ALL = "ALL",
  PUBLIC = "PUBLIC",
}

export enum ComparisonLogicalOperator {
  and = "and",
  or = "or",
}

export enum GlobalVariableType {
  CONSTANT = "Constant",
  MUTABLE = "Mutable",
}

export enum VariableDefaultValueType {
  Text = "Text",
  Number = "Number",
  Date = "Date",
}

// todo: finish types
export const ScriptStatus = {
  CLOSED: "CLOSED",
  PUBLISHED: "PUBLISHED",
  DRAFT: "DRAFT",
  IN_PROGRESS: "IN_PROGRESS",
  READY_TO_TEST: "READY_TO_TEST",
  READY_FOR_REVIEW: "READY_FOR_REVIEW",
} as const;

export enum AssignedVariableType {
  LOCAL = "local",
  GLOBAL = "global",
}

export enum CodeType {
  CONSTANT = "CONSTANT",
  PICK = "PICK",
  EXPRESSION = "EXPRESSION",
  ADVANCED = "ADVANCED",
  CODE_TEMPLATE = "CODE_TEMPLATE",
}

export enum VariableGroups {
  SCRIPT = "Script",
  SCRIPTS = "Scripts",
  DATA_SOURCE = "DataSource",
  SEQUENCE = "Sequence",
  GLOBAL = "Global",
}

export enum CodeParamType {
  CONSTANT = "CONSTANT",
  VARIABLE = "VARIABLE",
}

export enum ImportMode {
  CLONE = "CLONE",
  EXPORT = "EXPORT",
}

export enum WorkItemSeverity {
  critical = "1 - Critical",
  high = "2 - High",
  medium = "3 - Medium",
  low = "4 - Low",
}

export enum IssueTrackingToolTrigger {
  ERROR = "ERROR",
  WARNING = "WARNING",
  INFO = "INFO",
  PLATFORM_ERROR = "PLATFORM ERROR",
  PLATFORM_WARNING = "PLATFORM WARNING",
}

export enum IssueTrackingToolConfigurationState {
  inactive = "inactive",
  active = "active",
  removed = "removed",
}

export enum AllowedWorkItemTypes {
  Bug = "Bug",
  Issue = "Issue",
  Task = "Task",
  TestCase = "Test Case",
}

export enum TestCaseState {
  Design = "Design",
  Ready = "Ready",
  Closed = "Closed",
}

export enum JOB_STATUS_TYPE {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  COMPLETED = "COMPLETED",
  CLOSED = "CLOSED",
  RUNNING = "RUNNING",
}

export enum TEST_RUN_STATE {
  UNSPECIFIED = "Unspecified",
  NOT_STARTED = "NotStarted",
  IN_PROGRESS = "InProgress",
  COMPLETED = "Completed",
  WAITING = "Waiting",
  ABORTED = "Aborted",
  QUEUED = "Queued",
}

export enum MESSAGE_TYPE {
  ERROR = "ERROR",
  WARNING = "WARNING",
  INFO = "INFO",
  ANALYSIS = "ANALYSIS",
}

export enum EXECUTION_STATUS {
  ERROR = "ERROR",
  WARNING = "WARNING",
  SUCCESS = "SUCCESS",
  NONE = "NONE",
  UNTESTED = "UNTESTED",
}

export const TEST_RUN_OUTCOME_STATUS_MAP = {
  [EXECUTION_STATUS.ERROR]: "Failed",
  [EXECUTION_STATUS.SUCCESS]: "Passed",
  [EXECUTION_STATUS.WARNING]: "Warning",
};

export enum Commands {
  click = "click",
  focus = "focus",

  blur = "blur",
  menuItem = "menuItem",

  mousedown = "mousedown",
  keyup = "keyup",
  executeCode = "executeCode",
  password = "password",
  hotkey = "hotkey",
  assignValue = "assignValue",
  script = "script",
  change = "change",
  assert = "assert",

  check = "check",

  upload = "upload",
  start = "start",
  end = "end",
  login = "login",
  inspect = "inspect",

  veevaLookup = "veevaLookup",

  cposAuth = "cposAuth",
  CPOSCardHoverClick = "CPOSCardHoverClick",
  CPOSToggleClick = "CPOSToggleClick",
  CPOSDisassemblyKit = "CPOSDisassemblyKit",

  d365FSAdditionsSelectValue = "d365FSAdditionsSelectValue",
  d365FSMenu = "d365FSMenu",
  d365FSLookup = "d365FSLookup",

  dialogBox = "dialogBox",
  contextmenu = "contextmenu",
  lookup = "lookup",
  gridSelectRow = "gridSelectRow",
  gridSelectRow2 = "gridSelectRow2",
  selectSegmentEntry = "selectSegmentEntry",
  fastTabClick = "fastTabClick",

  beFashionExcel = "beFashionExcel", // temporary
  beFashionExcelEditStart = "beFashionExcelEditStart", // temporary
  beFashionExcelEditEnd = "beFashionExcelEditEnd", // temporary
  slider = "slider", // cpos slider filter
}

export enum RunTypes {
  INSTANT = "INSTANT",
  NOTHING = "NOTHING",
  TIME = "TIME",
  DEPENDANT = "DEPENDANT",
}

export enum RepeaterTypes {
  PARALLEL = "PARALLEL",
  SEQUENTIAL = "SEQUENTIAL",
}

export enum RunnerMode {
  RECORDER = "RECORDER",
  PLAYER = "PLAYER",
  COMBINED = "COMBINED",
}

export enum EXECUTION_STATE {
  NONE = "NONE",
  RUNNING = "RUNNING",
  PAUSED = "PAUSED",
  TERMINATED = "TERMINATED",
  FINISHED = "FINISHED",
}

export enum ScriptSpecialExecutionModes {
  DOCUMENT = "DOCUMENT",
  VIDEO = "VIDEO",
  DOC_AND_VIDEO = "DOC_AND_VIDEO",
}

export enum VariableParentType {
  TaskScript = "TaskScript",
  Sequence = "Sequence",
  Global = "Global",
}

export enum VariableType {
  Normal = "Normal",
  DataSource = "DataSource",
  Sequence = "Sequence",
}

export enum DOCUMENTATION_TYPE {
  VIDEO = "VIDEO",
  DOCUMENT = "DOCUMENT",
}

export enum ReportExtension {
  docx = "docx",
  pdf = "pdf",
}

export enum VIRTUAL_USER_STATUS {
  NOT_AUTHENTICATED = "NOT_AUTHENTICATED",
  AUTHENTICATED = "AUTHENTICATED",
  AUTHENTICATING = "AUTHENTICATING",
  ERROR = "ERROR",
}

export enum EXECUTION_SCRIPT_TYPES {
  VIRTUAL_USER_AUTH = "VIRTUAL_USER_AUTH",
  SINGLE = "SINGLE",
}

export enum SaveVideoCondition {
  ON_ERROR = "ON_ERROR",
  ON_SUCCESS = "ON_SUCCESS",
  ALWAYS = "ALWAYS",
}

export enum StepTerminationType {
  TIMEOUT = "TIMEOUT",
  NUMBER_OF_EXECUTIONS = "NUMBER_OF_EXECUTIONS",
}

export enum StepTerminationAction {
  IGNORE_STEP = "IGNORE_STEP",
  TERMINATE_EXECUTION = "TERMINATE_EXECUTION",
}

export enum GlobalSettingsResolversModes {
  SMART = "SMART",
  LEGACY = "LEGACY",
  FALLBACK = "FALLBACK",
}

export enum DatasourceType {
  LOCAL = "LOCAL",
  GLOBAL = "GLOBAL",
}

export enum WebsocketMessageTypes {
  OPEN_CONNECTION = "OPEN_CONNECTION",
  NOTIFICATION = "NOTIFICATION",
  REMOVE_CLIENT_COOKIES = "REMOVE_CLIENT_COOKIES",
  SCHEDULER = "SCHEDULER",
  SUBSCRIBE_TO_UPDATES = "SUBSCRIBE_UPDATES",
  UNSUBSCRIBE_TO_UPDATES = "UNSUBSCRIBE_UPDATES",
  PERFORM_REFRESH = "PERFORM_REFRESH",
  HEARTBEAT = "HEARTBEAT",
  REPORT_GENERATION_SUCCESS = "REPORT_GENERATION_SUCCESS",
  REPORT_GENERATION_FAIL = "REPORT_GENERATION_FAIL",
  EXPORT_GENERATION_START = "EXPORT_GENERATION_START",
  EXPORT_GENERATION_PROGRESS = "EXPORT_GENERATION_PROGRESS",
  EXPORT_GENERATION_FAIL = "EXPORT_GENERATION_FAIL",
  EXPORT_GENERATION_SUCCESS = "EXPORT_GENERATION_SUCCESS",
  GLOBAL_ACTIONS_SCREENSHOTS_CLEANUP_UPDATE = "GLOBAL_ACTIONS_SCREENSHOTS_CLEANUP_UPDATE",
}

export enum ExecutionTerminateTypes {
  SCRIPT = "SCRIPT",
  SCRIPT_FOREGROUND = "SCRIPT_FOREGROUND",
  SCHEDULER_RUN = "SCHEDULER_RUN",
  SCHEDULER = "SCHEDULER",
}

export enum DocumentationStatus {
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
  PENDING = "PENDING",
}

export enum CHECK_REFERENCE_TYPE {
  STEP_REFERENCE = "STEP_REFERENCE",
  VARIABLE_REFERENCE = "VARIABLE_REFERENCE",
  GLOBALVARIABLE_REFERENCE = "GLOBALVARIABLE_REFERENCE",
  DATASOURCE_REFERENCE = "DATASOURCE_REFERENCE",
  VIRTUAL_USER_REFERENCE = "VIRTUAL_USER_REFERENCE",
  SYSTEM_DEFINITION_REFERENCE = "SYSTEM_DEFINITION_REFERENCE",
}
