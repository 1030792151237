import * as React from "react";
import { Project, PlainObject, Script } from "@ea/shared_types/types";
import SystemTypeField from "../systemDictionary/components/SystemTypeField";
import { FormattedMessage } from "react-intl";
import { Tag, Tooltip } from "antd";
import { getTranslationKey } from "@app/translations/translations.helpers";

type ScriptColumndRenderersProps = {
  projectsMap: PlainObject<Project>;
};

export function getScriptColumnsRenderers({ projectsMap }: ScriptColumndRenderersProps) {
  return {
    envTypeRenderer: (record: Script) => {
      if (record.envType) {
        // system start url
        return <SystemTypeField type={record.envType} />;
      }

      if (record.isManualUrl) {
        return null;
      }

      const projectDefinition = projectsMap[record.projectId];
      const defaultSystem = projectDefinition && projectDefinition.defaultSystem;

      if (defaultSystem) {
        return <SystemTypeField type={defaultSystem.envType} />;
      }
    },
    projectNameRenderer: (record: Script) => {
      if (!record.projectId) {
        // system start url
        return <FormattedMessage id={getTranslationKey("table", "projectUnknown")} />;
      }
      const projectDefinition = projectsMap[record.projectId];
      if (projectDefinition) {
        return projectDefinition.name;
      }
    },
    envNameRenderer: (record: Script) => {
      if (record.envName) {
        // system start url
        return <Tooltip title={record.startUrl}>{record.envName}</Tooltip>;
      }
      if (record.isManualUrl) {
        return (
          <Tooltip title={<FormattedMessage id={getTranslationKey("table", "manualUrl")} />}>
            {record.startUrl}
          </Tooltip>
        );
      }
      const projectDefinition = projectsMap[record.projectId];
      const defaultSystem = projectDefinition && projectDefinition.defaultSystem;
      if (defaultSystem) {
        return (
          <>
            <Tooltip
              title={<FormattedMessage id={getTranslationKey("table", "projectDefaultEnv")} />}
            >
              <Tag>PD</Tag>
            </Tooltip>
            <Tooltip title={defaultSystem.envAddress}>{defaultSystem.envName}</Tooltip>
          </>
        );
      }
    },
    startUrlRenderer: (record: Script) => {
      if (record.environmentId) {
        // system start url
        return record.startUrl;
      }

      if (record.startUrl) {
        // manual start url
        return record.startUrl;
      }

      const projectDefinition = projectsMap[record.projectId];
      const defaultSystem = projectDefinition && projectDefinition.defaultSystem;
      if (defaultSystem) {
        return defaultSystem.envAddress;
      }
    },
  };
}
