"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SHORTCUTS = void 0;
exports.SHORTCUTS = {
    tab: {
        value: "tab",
        label: "TAB",
        labelKey: "shortcut.tab",
    },
    shiftTab: {
        value: "shift+tab",
        label: "SHIFT + TAB",
        labelKey: "shortcut.shiftTab",
    },
    altShiftRightArrow: {
        value: "alt+shift+right",
        label: "ALT + SHIFT + RIGHT",
        labelKey: "shortcut.altShiftRightArrow",
    },
    arrowDown: {
        value: "down",
        label: "DOWN",
        labelKey: "shortcut.arrowDown",
    },
    arrowUp: {
        value: "up",
        label: "UP",
        labelKey: "shortcut.arrowUp",
    },
    altShiftLeftArrow: {
        value: "alt+shift+left",
        label: "ALT + SHIFT + LEFT",
        labelKey: "shortcut.altShiftLeftArrow",
    },
    altShiftDown: {
        value: "alt+shift+down",
        label: "ALT + SHIFT + DOWN",
        labelKey: "shortcut.altShiftDown",
    },
    altShiftUp: {
        value: "alt+shift+up",
        label: "ALT + SHIFT + UP",
        labelKey: "shortcut.altShiftUp",
    },
    altShift1: {
        value: "alt+shift+1",
        label: "ALT + SHIFT + 1",
        labelKey: "shortcut.altShift1",
    },
    altShift2: {
        value: "alt+shift+2",
        label: "ALT + SHIFT + 2",
        labelKey: "shortcut.altShift2",
    },
    altShift3: {
        value: "alt+shift+3",
        label: "ALT + SHIFT + 3",
        labelKey: "shortcut.altShift3",
    },
    altShift4: {
        value: "alt+shift+4",
        label: "ALT + SHIFT + 4",
        labelKey: "shortcut.altShift4",
    },
    altShift5: {
        value: "alt+shift+5",
        label: "ALT + SHIFT + 5",
        labelKey: "shortcut.altShift5",
    },
    altShift6: {
        value: "alt+shift+6",
        label: "ALT + SHIFT + 6",
        labelKey: "shortcut.altShift6",
    },
    altShift7: {
        value: "alt+shift+7",
        label: "ALT + SHIFT + 7",
        labelKey: "shortcut.altShift7",
    },
    altShift8: {
        value: "alt+shift+8",
        label: "ALT + SHIFT + 8",
        labelKey: "shortcut.altShift8",
    },
    altShift9: {
        value: "alt+shift+9",
        label: "ALT + SHIFT + 9",
        labelKey: "shortcut.altShift9",
    },
    alt1: {
        value: "alt+1",
        label: "ALT + 1",
        labelKey: "shortcut.alt1",
    },
    alt2: {
        value: "alt+2",
        label: "ALT + 2",
        labelKey: "shortcut.alt2",
    },
    alt3: {
        value: "alt+3",
        label: "ALT + 3",
        labelKey: "shortcut.alt3",
    },
    alt4: {
        value: "alt+4",
        label: "ALT + 4",
        labelKey: "shortcut.alt4",
    },
    alt5: {
        value: "alt+5",
        label: "ALT + 5",
        labelKey: "shortcut.alt5",
    },
    alt6: {
        value: "alt+6",
        label: "ALT + 6",
        labelKey: "shortcut.alt6",
    },
    alt7: {
        value: "alt+7",
        label: "ALT + 7",
        labelKey: "shortcut.alt7",
    },
    alt8: {
        value: "alt+8",
        label: "ALT + 8",
        labelKey: "shortcut.alt8",
    },
    alt9: {
        value: "alts+9",
        label: "ALT + 9",
        labelKey: "shortcut.alt9",
    },
    alt0: {
        value: "alt+0",
        label: "ALT + 0",
        labelKey: "shortcut.alt0",
    },
    altEnter: {
        value: "alt+enter",
        label: "ALT + ENTER",
        labelKey: "shortcut.altEnter",
    },
    enter: {
        value: "enter",
        label: "ENTER",
        labelKey: "shortcut.enter",
    },
    space: {
        value: "space",
        label: "SPACE",
        labelKey: "shortcut.space",
    },
    ctrlShiftG: {
        value: "ctrl+shift+g",
        label: "CTRL + SHIFT + G",
        labelKey: "shortcut.ctrlShiftG",
    },
    ctrlShiftD: {
        value: "ctrl+shift+d",
        label: "CTRL + SHIFT + D",
        labelKey: "shortcut.ctrlShiftD",
    },
    ctrlShiftH: {
        value: "ctrl+shift+h",
        label: "CTRL + SHIFT + H",
        labelKey: "shortcut.ctrlShiftH",
    },
    ctrlShiftL: {
        value: "ctrl+shift+l",
        label: "CTRL + SHIFT + L",
        labelKey: "shortcut.ctrlShiftL",
    },
    altN: {
        value: "alt+n",
        label: "ALT + N",
        labelKey: "shortcut.altN",
    },
    altDel: {
        value: "alt+del",
        label: "ALT + DEL",
        labelKey: "shortcut.altDel",
    },
    altF9: {
        value: "alt+f9",
        label: "ALT + F9",
        labelKey: "shortcut.altF9",
    },
    altS: {
        value: "alt+s",
        label: "ALT + S",
        labelKey: "shortcut.altS",
    },
    altDown: {
        value: "alt+down",
        label: "ALT + DOWN",
        labelKey: "shortcut.altDown",
    },
    ctrlS: {
        value: "ctrl+s",
        label: "CTRL + S",
        labelKey: "shortcut.ctrlS",
    },
    ctrlShiftF5: {
        value: "ctrl+shift+f5",
        label: "CTRL + SHIFT + F5",
        labelKey: "shortcut.ctrlShiftF5",
    },
    shiftF5: {
        value: "shift+f5",
        label: "SHIFT + F5",
        labelKey: "shortcut.shiftF5",
    },
    altShiftF: {
        value: "alt+shift+f",
        label: "ALT + SHIFT + F",
        labelKey: "shortcut.altShiftF",
    },
    f2: {
        value: "f2",
        label: "F2",
        labelKey: "shortcut.f2",
    },
    ctrlShiftA: {
        value: "ctrl+shift+a",
        label: "CTRL + SHIFT + A",
        labelKey: "shortcut.ctrlShiftA",
    },
    ctrlShiftE: {
        value: "ctrl+shift+e",
        label: "CTRL + SHIFT + E",
        labelKey: "shortcut.ctrlShiftE",
    },
    ctrlShiftM: {
        value: "ctrl+shift+m",
        label: "CTRL + SHIFT + M",
        labelKey: "shortcut.ctrlShiftM",
    },
    ctrlUp: {
        value: "ctrl+up",
        label: "CTRL + ARROW UP",
        labelKey: "shortcut.ctrlUp",
    },
    ctrlDown: {
        value: "ctrl+down",
        label: "CTRL + DOWN UP",
        labelKey: "shortcut.ctrlDown",
    },
    ctrlHome: {
        value: "ctrl+home",
        label: "CTRL + HOME",
        labelKey: "shortcut.ctrlHome",
    },
    ctrlEnd: {
        value: "ctrl+end",
        label: "CTRL + END",
        labelKey: "shortcut.ctrlEnd",
    },
    esc: {
        value: "esc",
        label: "ESC",
        labelKey: "shortcut.esc",
    },
    shiftEsc: {
        value: "shift+esc",
        label: "SHIFT + ESC",
        labelKey: "shortcut.shiftEsc",
    },
    altShiftQ: {
        value: "alt+shift+q",
        label: "ALT + SHIFT + Q",
        labelKey: "shortcut.altShiftQ",
    },
};
