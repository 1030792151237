import * as React from "react";

import InputField from "@ea/shared_components/Form/Fields/InputField";
import { FieldArray } from "react-final-form-arrays";
import FormButton from "@ea/shared_components/Form/FormButton";
import { FormattedMessage } from "react-intl";
import FormLayout from "@ea/shared_components/Form/FormLayout";

interface ITranslationFormProps {
  readOnly?: boolean;
  change: (name: string, value: any) => void;
}

const renderCustomFields = ({ fields }) => {
  return (
    <>
      {fields
        ? fields.map((pathObject, index) => (
            <div key={index} style={{ display: "flex", justifyContent: "space-evenly" }}>
              <InputField
                formItemRowStyle={{ width: "100%" }}
                formItemControlStyle={{ width: "100%" }}
                name={`translations[${index}].defaultValue`}
                readOnly
              />
              <InputField
                formItemRowStyle={{ width: "100%" }}
                formItemControlStyle={{ width: "100%" }}
                // required
                name={`translations[${index}].value`}
              />
            </div>
          ))
        : null}
    </>
  );
};

class TranslationForm extends React.Component<ITranslationFormProps, any> {
  render() {
    const { readOnly } = this.props;

    return (
      <FormLayout readOnly={readOnly} labelCol={undefined} wrapperCol={{ span: 24 }}>
        <FieldArray name={"translations"} component={renderCustomFields} />
      </FormLayout>
    );
  }
}

export default TranslationForm;
