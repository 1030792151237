import * as React from "react";

import BreadcrumbContainer from "@ea/shared_components/common/Breadcrumb.container";
import { BreadcrumbSection, MainSection, Page } from "@ea/shared_components/common/LayoutElements";
import ProjectsLoaderContainer from "@app/modules/projects/ProjectsLoader.container";

interface IScriptsPageProps {}

const ScriptsPage: React.FunctionComponent<IScriptsPageProps> = (props) => {
  return (
    <Page>
      <ProjectsLoaderContainer />
    </Page>
  );
};

export default ScriptsPage;
