import * as React from "react";

import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { GetAsTime } from "@app/modules/common/time";
import WorkItemTypeField from "./components/WorkItemTypeField";
import { Button } from "antd";
import { AllowedWorkItemTypes, WorkItem } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";

export const WORK_ITEM_COLUMNS_CONFIG: PlainObject<CustomColumn<WorkItem>> = {
  title: {
    label: "table.title",
    dataIndex: "title",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  itsName: {
    label: "table.its",
    dataIndex: "itsName",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  stepLabel: {
    label: "table.stepLabel",
    dataIndex: "stepLabel",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  sessionId: {
    label: "table.sessionId",
    dataIndex: "sessionId",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  type: {
    label: "table.type",
    dataIndex: "type",
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        { label: getTranslationKey("table", "types", "bug"), value: AllowedWorkItemTypes.Bug },
        { label: getTranslationKey("table", "types", "task"), value: AllowedWorkItemTypes.Task },
        { label: getTranslationKey("table", "types", "issue"), value: AllowedWorkItemTypes.Issue },
      ],
    },
    render: (type) => <WorkItemTypeField type={type} />,
  },
  url: {
    label: "table.link",
    dataIndex: "url",
    render: (text, record) => (
      <Button
        type="primary"
        onClick={() => {
          window.open(text);
        }}
      >
        {`${record.type} ${record.externalId}`}
      </Button>
    ),
  },
  createdAt: {
    label: "table.createdAt",
    dataIndex: "createdAt",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "createdAt" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
};

export const WORK_ITEM_COLUMNS: ColumnConfig<WorkItem>[] = [
  {
    props: WORK_ITEM_COLUMNS_CONFIG.title,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: WORK_ITEM_COLUMNS_CONFIG.type,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: WORK_ITEM_COLUMNS_CONFIG.itsName,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: WORK_ITEM_COLUMNS_CONFIG.stepLabel,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: WORK_ITEM_COLUMNS_CONFIG.sessionId,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: WORK_ITEM_COLUMNS_CONFIG.createdAt,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: WORK_ITEM_COLUMNS_CONFIG.url,
    frameworkProps: {
      width: "20%",
    },
  },
];

export const WORK_ITEM_TABLES_CONFIG = {
  SCRIPT: {
    id: (id: number) => `SCRIPT_${id}_WORK_ITEMS`,
    preferencesId: "SCRIPT_WORK_ITEMS",
  },
};
