import * as React from "react";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import FormItemWrapper from "@ea/shared_components/Form/FormItem/FormItemWrapper";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { Row, Col, Input } from "antd";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import { getIn } from "final-form";
import { VirtualUser } from "@ea/shared_types/types";
import { convertVirtualUsersToSelectOptions } from "@app/utils/selectOptions";
import { SwitchField } from "@ea/shared_components/";
import { DataTestIds } from "@app/utils/dataTestIds";

export const VirtualUserField: React.FunctionComponent<{
  readOnly?: boolean;
  virtualUsers: VirtualUser[];
  prefix?: string;
  values: any;
}> = ({ readOnly, prefix, virtualUsers, values }) => {
  const getName = (name) => (prefix ? `${prefix}.${name}` : name);

  const virutalUsersOptions = convertVirtualUsersToSelectOptions(virtualUsers);
  const isDefaultVirtualUser = getIn(values, getName("defaultVirtualUser"));
  const id = getIn(values, getName("defaultVirtualUserId"));
  const defaultVUName = virtualUsers?.find((vu) => vu.id === id)?.name;

  return (
    <FormItemWrapper
      formItem={{
        formItemRowStyle: { marginBottom: 0 },
        label: (
          <FormattedMessage id={getTranslationKey("common", "label", "virtualUser")} />
        ) as any,
      }}
    >
      {() => (
        <FormLayout wrapperCol={{ span: 24 }} readOnly={readOnly}>
          <Row>
            <Col flex="80px">
              <SwitchField
                name={getName("defaultVirtualUser")}
                data-testid={DataTestIds.FORM_SWITCH_DEFAULT_VU}
                checkedChildren={<FormattedMessage id={getTranslationKey("button", "custom")} />}
                unCheckedChildren={<FormattedMessage id={getTranslationKey("button", "default")} />}
                format={(v) => !v}
                parse={(v) => !v}
              />
            </Col>
            <Col flex="auto">
              {isDefaultVirtualUser ? (
                <Input
                  disabled
                  value={defaultVUName}
                  name="defaultVUName"
                  style={{ marginBottom: "10px", marginLeft: "5px" }}
                  data-testid={DataTestIds.FORM_INPUT_DEFAULT_VU}
                />
              ) : (
                <SelectField
                  name={getName("virtualUserId")}
                  data-testid={DataTestIds.FORM_SELECT_VU}
                  disabled={isDefaultVirtualUser}
                  required={!isDefaultVirtualUser}
                  filterOption={(input, option) =>
                    virutalUsersOptions
                      .filter((o) => o.value === option?.props.value)
                      .some((o) => o.text!.toLowerCase().indexOf(input.toLowerCase()) > -1)
                  }
                  showSearch
                  format={(user) => {
                    if (user) {
                      return user.name ? user.id : user;
                    }
                    if (user === null) {
                      return undefined;
                    }
                    return user;
                  }}
                  placeholder={getTranslationKey("common", "placeholder", "virtualUser")}
                  options={virutalUsersOptions}
                />
              )}
            </Col>
          </Row>
        </FormLayout>
      )}
    </FormItemWrapper>
  );
};
