import * as React from "react";

import { ExecutionStep, PlayerVariables } from "@ea/shared_types/runner.common.types";
import { EXECUTION_STATUS, RunnerState, Variable } from "@ea/shared_types/types";

export const isRootStep = (step: ExecutionStep) => step.execution.path.split(".").length === 1;

export function getDefaultExecution(path: string) {
  const defaultExecution: ExecutionStep["execution"] = {
    numberOfExecutions: 0,
    path,
    status: EXECUTION_STATUS.NONE,
    steps: [],
    usedVariables: [],
  };

  return defaultExecution;
}

export const getRootStepsOrder = (steps: ExecutionStep[]) =>
  steps.filter((s) => isRootStep(s)).map((s) => s.id);

export const buildStepTree = (
  steps: ExecutionStep[],
  sessionParams: RunnerState,
  onlyRoot?: boolean,
): ExecutionStep[] => {
  // TODO sessionParams type
  try {
    const executingPath = (sessionParams.flatSteps || [])[sessionParams.player.position]?.path;

    const createStepTreeNode = (step: ExecutionStep, parentLineNum?: number): any => {
      const execution = sessionParams.executionItems?.[step.execution.path];

      return {
        ...step,
        key: step.execution?.path,
        lineNum: parentLineNum ? `${parentLineNum}​​​​​​​​.${step.lineNum}​​​​​​​​` : step.lineNum,
        children: [],
        mode: sessionParams.mode,
        execution: execution ? { ...execution } : getDefaultExecution(step.execution.path),
        executionPath: executingPath,
        executionState: sessionParams.player?.state,
      };
    };

    const rootSteps: any[] = steps.filter((s) => isRootStep(s)).map((s) => createStepTreeNode(s));

    const buildNode = (rootStep: any) => {
      if (!rootStep.execution.steps) {
        return rootStep;
      }

      if (rootStep.execution.steps.length > 0) {
        rootStep.children.push(
          ...rootStep.execution.steps.map((s2) => createStepTreeNode(s2, rootStep.lineNum)),
        );
      }

      rootStep.children.map((p) => buildNode(p));
      return rootStep;
    };

    if (onlyRoot) {
      return rootSteps;
    }

    const tree = rootSteps.map((p) => buildNode(p));

    return tree;
  } catch (err) {
    console.error("runnerselector", err);
    return [];
  }
};

export const buildLocalScriptVariables = (variables: Variable[]) =>
  variables.reduce(
    (prev: Partial<PlayerVariables>, { id, value, defaultValue, name }: Variable) => {
      prev.script![id] = { value: value || defaultValue, name: name };
      return prev;
    },
    { script: {} },
  );
