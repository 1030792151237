import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { Role } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("ROLES_MODULE");

export const moduleActions = createDataModuleActions<Role>(actionCreator);
export const getRolesTableActions = createTableActionsGetter(normalizeActions(moduleActions.table));
export const roleActions = {
  ...normalizeActions(moduleActions.data),
};
export const rolesActionCreators = {
  ...moduleActions,
};
