import * as React from "react";

import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { Script, RunnerParams } from "@ea/shared_types/types";
import RecordModeForm from "@app/modules/common/components/RecordModeForm";
import { getTranslationKey } from "@app/translations/translations.helpers";

interface RecordModeContainerProps {
  visibility: boolean;
  onClose: () => void;
  script?: Script;
  run: (evt, optionalParams?: any) => void;
  runnerParams?: RunnerParams;
}

class RecordModeContainer extends React.Component<RecordModeContainerProps> {
  onOkClick = ({ runner }) => {
    const { onClose, run } = this.props;
    run({}, { runner });
    onClose();
  };

  render() {
    const { visibility, onClose, runnerParams } = this.props;

    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.MODAL}
        customWidth={500}
        headerText={getTranslationKey("recordMode", "selectRecordMode")}
        onCancelClick={onClose}
        onOkClick={this.onOkClick}
        cancelButtonText={getTranslationKey("button", "cancel")}
        okButtonText={getTranslationKey("button", "record")}
        initialValues={{ runner: runnerParams }}
        allowPrisitineSubmit
        render={({ values, change }) => <RecordModeForm values={values} change={change} />}
      />
    );
  }
}

export default RecordModeContainer;
