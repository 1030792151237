import React, { useState, useEffect } from "react";
import { API } from "@app/services/api/api";
import { License, LicenseType, PayPerUseCounter } from "@ea/shared_types/types";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { getTranslationKey } from "@app/translations/translations.helpers";
import ReleaseNotes from "./ReleaseNotes";
import PayPerUseStatistics from "./PayPerUseStatistics";
import { FormattedMessage, InjectedIntlProps } from "react-intl";
import { DataTestIds } from "@app/utils/dataTestIds";
import { Space, Button, Modal, Spin, Divider, Input } from "antd";
import { toast } from "react-toastify";

const SpinContainer = styled.div({
  minWidth: "450px",
  paddingTop: "20%",
});

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
});

export const LicenseOverview: React.FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [payPerUseCounter, setPayPerUseCounter] = useState<PayPerUseCounter | undefined>(undefined);
  const [license, setLicense] = useState<License | undefined>(undefined);
  const [newLicenseNumber, setNewLicenseNumber] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function getInitialData() {
      const response = await API.getLicense({});
      if (response.type === LicenseType.PAY_PER_USE) {
        const counterResponse = await API.getPayPerUseCounter({});
        setPayPerUseCounter(counterResponse);
      }
      setLicense(response);
    }
    getInitialData();
  }, []);

  const onConfirmClick = async () => {
    if (!newLicenseNumber) {
      return;
    }
    setIsLoading(true);
    try {
      await API.setNewLicense({ licenseId: newLicenseNumber });
      toast.success(<FormattedMessage id={getTranslationKey("license", "licenseUpdated")} />);
    } catch (e) {
      toast.error(<FormattedMessage id={getTranslationKey("license", "errorWhileUpdating")} />);
    }
    setNewLicenseNumber(undefined);
    setIsLoading(false);
  };

  const showConfirmModal = () => {
    Modal.confirm({
      title: intl.formatMessage({
        id: getTranslationKey("license", "modal", "title"),
      }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({
        id: getTranslationKey("license", "modal", "content"),
      }),
      okText: intl.formatMessage({
        id: getTranslationKey("license", "modal", "okText"),
      }),
      cancelText: intl.formatMessage({
        id: getTranslationKey("license", "modal", "cancelText"),
      }),
      onOk: async () => {
        onConfirmClick();
      },
    });
  };

  const getCounterView = () => {
    if (!payPerUseCounter) {
      return null;
    }

    const { thisMonth, lastMonth, total } = payPerUseCounter;
    return (
      <Container data-testid={DataTestIds.CONTAINER_LICENSE_PPU_COUNTER}>
        <h1>
          <FormattedMessage id={getTranslationKey("license", "payPerUse")} />
        </h1>
        {total && (
          <div>
            <b>
              <FormattedMessage id={getTranslationKey("license", "total")} />:{" "}
            </b>
            {total}
          </div>
        )}
        {thisMonth && (
          <div>
            <b>
              <FormattedMessage id={getTranslationKey("license", "thisMonth")} />:{" "}
            </b>
            {thisMonth}
          </div>
        )}
        {lastMonth && (
          <div>
            <b>
              <FormattedMessage id={getTranslationKey("license", "lastMonth")} />:{" "}
            </b>
            {lastMonth}
          </div>
        )}
      </Container>
    );
  };

  const getLicenseView = () => {
    if (!license) {
      return null;
    }

    const {
      expirationDate,
      supportExpirationDate,
      maximumConcurrentUsers,
      maximumParallelSessions,
      type,
      error,
      licenseNumber,
    } = license;
    if (error) {
      console.error(error);
      return (
        <Container data-testid={DataTestIds.CONTAINER_LICENSE_ERROR}>
          <FormattedMessage id={getTranslationKey("messageBuilder", "dearUser")} />
          <br />
          <FormattedMessage id={getTranslationKey("messages", "error", "licenseFileError")} />
          <br />
          <FormattedMessage id={getTranslationKey("messageBuilder", "pleaseContact")} />{" "}
          <a href="mailto:support@executiveautomats.com"> support@executiveautomats.com</a>
        </Container>
      );
    }

    return (
      <Container data-testid={DataTestIds.CONTAINER_LICENSE_OVERVIEW}>
        <h1>
          <FormattedMessage id={getTranslationKey("license", "license")} />
        </h1>
        {type && (
          <div>
            <b>
              <FormattedMessage id={getTranslationKey("license", "licenseType")} />:{" "}
            </b>
            {type}
          </div>
        )}
        {licenseNumber && (
          <div>
            <b>
              <FormattedMessage id={getTranslationKey("license", "licenseNumber")} />:{" "}
            </b>
            {licenseNumber}
          </div>
        )}
        {expirationDate && (
          <div>
            <b>
              <FormattedMessage id={getTranslationKey("license", "licenseExpirationDate")} />:{" "}
            </b>
            {expirationDate}
          </div>
        )}
        {supportExpirationDate && (
          <div>
            <b>
              <FormattedMessage id={getTranslationKey("license", "supportRenewalDate")} />:{" "}
            </b>
            {supportExpirationDate}
          </div>
        )}
        {maximumConcurrentUsers && (
          <div>
            <b>
              <FormattedMessage id={getTranslationKey("license", "maximumConcurrentUsers")} />:{" "}
            </b>
            {maximumConcurrentUsers}
          </div>
        )}
        {maximumParallelSessions && (
          <div>
            <b>
              <FormattedMessage id={getTranslationKey("license", "maximumParallelSessions")} />:{" "}
            </b>
            {maximumParallelSessions}
          </div>
        )}
      </Container>
    );
  };

  const getNewLicenseNumberView = () => {
    return (
      <Space>
        <Input
          addonBefore={<FormattedMessage id={getTranslationKey("license", "licenseNumber")} />}
          onChange={(e) => setNewLicenseNumber(e.target.value)}
          value={newLicenseNumber}
          disabled={isLoading}
        />
        <Button
          danger
          type="primary"
          disabled={!newLicenseNumber || isLoading}
          loading={isLoading}
          onClick={showConfirmModal}
        >
          <FormattedMessage id={getTranslationKey("license", "installNewLicense")} />
        </Button>
      </Space>
    );
  };

  const isPayPerUse = license?.type === LicenseType.PAY_PER_USE;
  return license ? (
    <Container>
      {getLicenseView()}
      {getCounterView()}
      <Divider />
      {getNewLicenseNumberView()}
      <Divider />
      {isPayPerUse && (
        <>
          <PayPerUseStatistics license={license} />
          <Divider />
        </>
      )}
      <ReleaseNotes />
    </Container>
  ) : (
    <SpinContainer>
      <Spin
        tip={(<FormattedMessage id={getTranslationKey("common", "loadingLicense")} />) as any}
      />
    </SpinContainer>
  );
};

export default LicenseOverview;
