import * as React from "react";

import createFormField from "../createFormField";
import { Switch } from "antd";
import { SwitchProps } from "antd/lib/switch";

interface ISwitchProps extends SwitchProps {
  readOnly?: boolean;
}

const SwitchWithReadonly: React.FunctionComponent<ISwitchProps> = ({ readOnly, ...restProps }) => {
  return <Switch {...restProps} disabled={readOnly || restProps.disabled} />;
};

export const SwitchField = createFormField(
  SwitchWithReadonly,
  ({ name, onChange, value, onBlur, onFocus, ...rest }) => ({
    ...rest,
    disabled: rest.disabled || rest.readOnly,
    onChange,
    value,
    checked: value,
  }),
);

export default SwitchField;
