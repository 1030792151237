import { Tag } from "antd";
import * as React from "react";

import { COLORS } from "@app/styles/consts";
import { DocumentationStatus, ScriptStatus } from "@ea/shared_types/types";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";

interface IStatusTagProps {
  item: { status: ScriptStatus | DocumentationStatus | undefined };
  style?: React.CSSProperties;
}

const StatusTag: React.FunctionComponent<IStatusTagProps> = ({ item, style }) => {
  const customStyle = {
    cursor: "default",
    ...style,
  };

  if (!item) {
    return null;
  }

  if (!item.status) {
    return null;
  }

  return (
    <Tag
      style={customStyle}
      color={COLORS.SCRIPT_STATUS[item.status]}
      data-testid={DataTestIds.getTagTestId(item.status)}
    >
      <FormattedMessage id={getTranslationKey("scripts", "status", item.status)} />
    </Tag>
  );
};

export default StatusTag;
