import * as React from "react";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";
import { Documentation, DocumentationStatus, FEATURES } from "@ea/shared_types/types";
import HideableFeature from "@app/modules/common/HideableFeature";

interface DocumentationCommandBarProps {
  onRemoveClick: (ids: number[]) => void;
  onEditClick: () => void;
  onReload: () => void;
  onDocumentationDownload?: () => void;
  selected: number[];
  selectedDocumentations: Documentation[];
  selectedPendingDocumentations: Documentation[];
  isDownloadingDoc?: boolean;
  isDocumentationReader: boolean;
  playScript?: () => void;
}
class DocumentationCommandBar extends React.Component<DocumentationCommandBarProps> {
  public render() {
    return (
      <CommandBar data-testid={DataTestIds.COMMANDBAR_DOCUMENTATION}>
        {!this.props.isDocumentationReader && (
          <HideableFeature feature={FEATURES.REMOVABLE_DOCUMENTATION}>
            {() => (
              <CommandBarButton
                name="remove"
                onClick={() => this.props.onRemoveClick(this.props.selected)}
                text={getTranslationKey("commandBar", "remove")}
                icon="DeleteOutlined"
                disabled={this.props.selected.length === 0}
                data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE}
              />
            )}
          </HideableFeature>
        )}
        <CommandBarButton
          name="refresh"
          onClick={this.props.onReload}
          text={getTranslationKey("commandBar", "refresh")}
          icon="ReloadOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
        />
        {!this.props.isDocumentationReader && (
          <CommandBarButton
            name="edit"
            onClick={this.props.onEditClick}
            text={getTranslationKey("commandBar", "edit")}
            icon="EditOutlined"
            disabled={
              !(
                this.props.selected.length === 1 &&
                this.props.selectedDocumentations[0].status !== DocumentationStatus.PENDING
              )
            }
            data-testid={DataTestIds.COMMANDBAR_BUTTON_EDIT}
          />
        )}
        <CommandBarButton
          name="download"
          onClick={this.props.onDocumentationDownload}
          text={getTranslationKey("commandBar", "downloadDoc")}
          icon="FileOutlined"
          loading={this.props.isDownloadingDoc}
          disabled={
            this.props.selectedDocumentations.length -
              this.props.selectedPendingDocumentations.length ===
            0
          }
          data-testid={DataTestIds.COMMANDBAR_BUTTON_DOWNLOAD}
        />
        {this.props.playScript && (
          <CommandBarButton
            far
            name="play"
            onClick={this.props.playScript}
            text={getTranslationKey("commandBar", "play")}
            icon="PlayCircleOutlined"
            disabled={this.props.selected.length !== 1}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_PLAY}
          />
        )}
      </CommandBar>
    );
  }
}
export default DocumentationCommandBar;
