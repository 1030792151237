import * as React from "react";
import { CheckboxField } from "@ea/shared_components/Form/Fields/CheckboxField";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { LogCsvAdditionalFields } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { ROUTES } from "@app/routes";

interface ILogsCsvAdditionalFieldFormProps {
  readOnly?: boolean;
  values: any;
  change: (name: string, value: any) => void;
  displayRunnerOptions?: boolean;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 15 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
};
export const csvExportAdditionalFieldsDefinition: {
  [key in LogCsvAdditionalFields]: {
    formComponent: (key: string, formProps: any) => React.ReactNode;
  };
} = {
  scriptLogUrl: {
    formComponent: (key, formProps) => {
      const historyBaseUrl = `${window.location.origin}${ROUTES.history}`;
      return (
        <CheckboxField
          {...formProps}
          key={key}
          name={key}
          label={getTranslationKey("logs", "scriptLogUrl")}
          defaultValue={historyBaseUrl}
          format={(value) => !!value}
          parse={(value) => (value ? historyBaseUrl : undefined)}
        />
      );
    },
  },
  jobId: {
    formComponent: (key, formProps) => (
      <CheckboxField
        {...formProps}
        key={key}
        name={key}
        label={getTranslationKey("table", "jobId")}
        defaultValue={true}
      />
    ),
  },
  schedulerJobName: {
    formComponent: (key, formProps) => (
      <CheckboxField
        {...formProps}
        key={key}
        name={key}
        label={getTranslationKey("table", "schedulerJobName")}
        defaultValue={true}
      />
    ),
  },
  lastErrorMessage: {
    formComponent: (key, formProps) => (
      <CheckboxField
        {...formProps}
        key={key}
        name={key}
        label={getTranslationKey("logs", "lastErrorMessage")}
        defaultValue={true}
      />
    ),
  },
};

export const LogsCsvAdditionalFieldForm: React.FunctionComponent<
  ILogsCsvAdditionalFieldFormProps
> = (props) => (
  <FormLayout {...formItemLayout}>
    {Object.keys(LogCsvAdditionalFields).map((key) =>
      csvExportAdditionalFieldsDefinition[key].formComponent(key, props),
    )}
  </FormLayout>
);
