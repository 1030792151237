import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { UserGroup } from "@ea/shared_types/types";
import { moduleActions } from "@app/modules/userGroups/userGroups.actions";

const initialState = {
  ...getDataReducerInitialState<UserGroup>(),
};

export type UserGroupReducer = typeof initialState;

const dataReducer = createDataReducer<UserGroup>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
