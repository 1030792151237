import { DeleteOutlined, FileExcelOutlined, UploadOutlined } from "@ant-design/icons";
import { API } from "@app/services/api/api";
import { COLORS } from "@app/styles/consts";
import { SelectField } from "@ea/shared_components";
import { TreeSelectField } from "@ea/shared_components/Form/Fields/TreeSelectField";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { PanelType } from "@ea/shared_components/Panel";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { IMPORT_SCRIPT_OPTIONS, ProjectTreeNode } from "@ea/shared_types/types";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Button, Select, Tooltip } from "antd";
import * as React from "react";
import Dropzone from "react-dropzone";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { getTranslationKey } from "./../../translations/translations.helpers";

interface ImportScriptContainerProps {
  visibility: boolean;
  onClose: () => void;
  onReload: () => void;
  projects: ProjectTreeNode[];
  projectId?: number;
}

interface ImportScriptContainerState {
  files: any[];
  loading: boolean;
  error: string | undefined;
}
const DropZoneDiv = styled.div(
  {
    borderRadius: "4px",
    background: "#f4f4f4",
    border: "2px dashed #d9d9d9",
    transition: "border-color .3s",
    width: "400px",
    height: "150px",
    textAlign: "center",
    padding: "16px 0",
    cursor: "pointer",
    "&:hover": {
      background: "#dbdbdb",
      border: "2px dashed #2b2b2b",
    },
  },
  ({ isDragActive }: { isDragActive: boolean }) =>
    isDragActive ? { border: "2px dashed green" } : {},
);
const LoadedFilesContainer = styled.div({
  display: "flex",
  flexDirecion: "column",
  padding: "0 0 0 0px",
});

const LoadedFile = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  margin: "5px",
});

const documentIcon = css({
  fontSize: 30,
  margin: "10px",
  color: COLORS.DARK_GREEN,
  cursor: "pointer",
  ":hover": {
    textShadow: "0px 0px 5px lightgreen",
  },
});

const formItemLayout = {
  labelCol: {
    sm: { span: 4 },
  },
  wrapperCol: {
    sm: { span: 18 },
  },
};

const FileNameContainer = styled.h3({
  margin: "15px",
  overflowWrap: "break-word",
  width: "80%",
});

const OptionsContainer = styled.div({
  margin: "10px 0",
});

class ImportScriptContainer extends React.Component<ImportScriptContainerProps> {
  formRef: any;
  dropzoneRef: any;
  state: ImportScriptContainerState = {
    files: [],
    loading: false,
    error: undefined,
  };
  onDrop = async (files: any) => {
    this.setState({ files });
  };
  onReject = () => {
    toast.warn(getTranslationKey("messages", "error", "fileUpload"));
  };

  onOk = async (values) => {
    const { files } = this.state;
    try {
      const importedScripts = await API.importScript({ data: files, ...values });
      const failedToImport = importedScripts
        .filter((impScript) => !impScript.imported)
        .map((impScript) => {
          console.error(impScript.error);
          return impScript.name;
        });

      if (failedToImport.length) {
        toast.error(
          `Failed to import following scripts: \n ${failedToImport.join(
            "\n",
          )}. Check console for additional info.`,
        );
      }
    } catch (err) {
      this.setState({
        loading: false,
        error: `errrr`,
      });
      return;
    }
    this.props.onClose();
    this.props.onReload();
    this.onDelete();
  };
  onDelete = () => {
    this.setState({ files: [] });
  };
  renderFileList = () => (
    <LoadedFile>
      <FileExcelOutlined css={documentIcon} />
      <FileNameContainer>{this.state.files[0].name}</FileNameContainer>
      <Tooltip title={<FormattedMessage id={getTranslationKey("button", "delete")} />}>
        <Button danger icon={<DeleteOutlined />} onClick={() => this.onDelete()} />
      </Tooltip>
    </LoadedFile>
  );

  onPanelClose = () => {
    const { onClose } = this.props;
    const { files } = this.state;
    if ((files || []).length > 0) {
      this.setState({ files: [] });
    }
    onClose();
  };

  render() {
    const { projects, visibility, projectId } = this.props;
    const { files } = this.state;

    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.MEDIUM}
        headerText={getTranslationKey("scripts", "header", "import")}
        cancelButtonText={getTranslationKey("button", "cancel")}
        okButtonText={getTranslationKey("button", "save")}
        onCancelClick={this.onPanelClose}
        allowPrisitineSubmit={files.length > 0 ? true : false}
        onOkClick={this.onOk}
        render={() => (
          <>
            <FormLayout {...formItemLayout}>
              <TreeSelectField
                label={getTranslationKey("common", "label", "project")}
                name="projectId"
                required={true}
                treeDefaultExpandAll
                defaultValue={projectId}
                nodes={projects}
                placeholder={getTranslationKey("common", "placeholder", "project")}
              />
            </FormLayout>
            <LoadedFilesContainer>
              {files.length > 0 ? (
                this.renderFileList()
              ) : (
                <Dropzone
                  ref={(element) => {
                    this.dropzoneRef = element;
                  }}
                  multiple={false}
                  onDropAccepted={this.onDrop}
                  onDropRejected={this.onReject}
                  accept=".ea"
                >
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <DropZoneDiv {...(getRootProps() as any)} isDragActive={isDragActive}>
                      <input {...(getInputProps() as any)} />
                      <UploadOutlined style={{ fontSize: 50 }} />
                      <p>
                        <FormattedMessage
                          id={getTranslationKey("messages", "info", "dropZipZone")}
                        />
                      </p>
                    </DropZoneDiv>
                  )}
                </Dropzone>
              )}
            </LoadedFilesContainer>
            <OptionsContainer>
              <SelectField
                name="globalExcelOption"
                defaultValue={IMPORT_SCRIPT_OPTIONS.USE_EXISTING}
                label={getTranslationKey("scripts", "import", "globalExcelOptions")}
              >
                {[
                  {
                    text: getTranslationKey("scripts", "import", "globalExcelUseExisting"),
                    value: IMPORT_SCRIPT_OPTIONS.USE_EXISTING,
                  },
                  {
                    text: getTranslationKey("scripts", "import", "globalExcelAlwaysClone"),
                    value: IMPORT_SCRIPT_OPTIONS.ALWAYS_CLONE_DATASOURCE,
                  },
                  {
                    text: getTranslationKey("scripts", "import", "globalExcelAlwaysConvert"),
                    value: IMPORT_SCRIPT_OPTIONS.ALWAYS_CONVERT_DATASOURCE,
                  },
                  {
                    text: getTranslationKey("scripts", "import", "globalExcelConvert"),
                    value: IMPORT_SCRIPT_OPTIONS.CONVERT_DATASOURCE,
                  },
                  {
                    text: getTranslationKey("scripts", "import", "globalExcelClone"),
                    value: IMPORT_SCRIPT_OPTIONS.CLONE_DATASOURCE,
                  },
                ].map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    <FormattedMessage id={option.text} />
                  </Select.Option>
                ))}
              </SelectField>
            </OptionsContainer>
          </>
        )}
      />
    );
  }
}

export default ImportScriptContainer;
