import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getIstTableActions } from "../issueTrackingTool/its.actions";
import { getUserIts, itsDataSelectors } from "../issueTrackingTool/its.selectors";
import { ITS_TABLES_CONFIG } from "../issueTrackingTool/its.table";
import { projectActions } from "../projects/projects.actions";
import CreateEditConfigurationContainer from "./components/CreateEditConfiguration";
import ItsConfigurationsCommandBar from "./components/ItsConfigurationsCommandBar";
import {
  getItsConfigurationsTableActions,
  itsConfigurationsActions,
} from "./itsConfigurations.actions";
import { itsConfigurationsDataSelectors } from "./itsConfigurations.selectors";
import {
  ITS_CONFIGURATIONS_COLUMNS,
  ITS_CONFIGURATIONS_TABLES_CONFIG,
} from "./itsConfigurations.table";

enum Panels {
  CREATE = "create",
  EDIT = "edit",
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

interface IItsConfigurationTableProps {}

interface IItsConfigurationTableState {
  openedPanel?: Panels;
}

class ItsConfigurationTableContainer extends React.Component<
  IItsConfigurationTableProps & IConnectProps,
  IItsConfigurationTableState
> {
  connectedTable: any;

  state = {
    openedPanel: undefined,
  };

  componentDidMount() {
    this.props.actions.its.load({});
  }

  closePanel = () => {
    this.setState({
      openedPanel: undefined,
    });
  };

  openEditWindow = () => {
    this.setState({ openedPanel: Panels.EDIT });
  };

  closeEditWindow = () => {
    if (this.props.selectedItsConfiguration) {
      this.props.actions.loadSingle({ id: this.props.selectedItsConfiguration.id });
    }
    this.closePanel();
  };

  openCreateWindow = () => {
    this.setState({ openedPanel: Panels.CREATE });
  };

  closeCreateWindow = () => {
    this.closePanel();
  };

  reload = () => {
    this.connectedTable?.reload();
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
  };

  fetch = () => {
    this.props.actions.fetch({ tableId: ITS_CONFIGURATIONS_TABLES_CONFIG.MAIN.id() });
  };

  render() {
    const { openedPanel } = this.state;
    const { isLoading, user, userIts, selectedItsConfiguration } = this.props;

    return (
      <Container>
        <ItsConfigurationsCommandBar
          onNewClick={this.openCreateWindow}
          onRemoveClick={this.remove}
          selected={this.props.selected}
          onEditClick={this.openEditWindow}
          onFetchClick={this.fetch}
          onReload={this.reload}
          isLoading={isLoading}
        />
        <CreateEditConfigurationContainer
          visibility={!!openedPanel}
          onClose={openedPanel === Panels.EDIT ? this.closeEditWindow : this.closeCreateWindow}
          onCreate={this.reload}
          selected={selectedItsConfiguration}
          isEdit={openedPanel === Panels.EDIT}
          user={user}
          issueTrackingSystem={userIts}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={ITS_CONFIGURATIONS_COLUMNS}
          tableId={ITS_CONFIGURATIONS_TABLES_CONFIG.MAIN.id()}
          preferencesId={ITS_CONFIGURATIONS_TABLES_CONFIG.MAIN.preferencesId}
          stateKey={"itsConfigurations"}
          tableActions={getItsConfigurationsTableActions}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(itsConfigurationsActions, dispatch),
    ...bindActionCreators(
      getItsConfigurationsTableActions(ITS_CONFIGURATIONS_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ),
    its: {
      load: bindActionCreators(getIstTableActions(ITS_TABLES_CONFIG.MAIN.id()), dispatch).load,
    },
    projects: {
      ...bindActionCreators(projectActions, dispatch),
    },
  },
});

const connectCreator = connect(
  (state: ApplicationState) => ({
    selected: itsConfigurationsDataSelectors.getSelectedSelector(
      state,
      ITS_CONFIGURATIONS_TABLES_CONFIG.MAIN.id(),
    ),
    isLoading: itsConfigurationsDataSelectors.getIsLoadingSelector(state),
    selectedItsConfiguration: itsConfigurationsDataSelectors.getSelectedItemSelector(
      state,
      ITS_CONFIGURATIONS_TABLES_CONFIG.MAIN.id(),
    ),
    issueTrackingSystems: itsDataSelectors.getOrderedDataSelector(
      state,
      ITS_TABLES_CONFIG.MAIN.id(),
    ),
    userIts: getUserIts(state),
    user: state.auth.user,
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(ItsConfigurationTableContainer);
