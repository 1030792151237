import * as React from "react";
import { getIn } from "final-form";
import { RunnerMode, RUNNER_OPEN_MODE, RUNNER_POSITION, RUNNER_TYPE } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { RadioField, RadioType } from "@ea/shared_components/Form/Fields/RadioField";

interface IRunnerTypeFormChunkProps {
  readOnly?: boolean;
  values: any;
  change: (name: string, value: any) => void;
  mode: RunnerMode;
}

type Props = IRunnerTypeFormChunkProps;

const runnerTypeOptions = [
  {
    key: RUNNER_TYPE.BASIC,
    value: RUNNER_TYPE.BASIC,
    text: getTranslationKey("recordMode", "basic"),
  },
  {
    key: RUNNER_TYPE.ADVANCED,
    value: RUNNER_TYPE.ADVANCED,
    text: getTranslationKey("recordMode", "advanced"),
  },
];

const runnerOpenModeOptions = [
  {
    key: RUNNER_OPEN_MODE.CURRENT_WINDOW,
    value: RUNNER_OPEN_MODE.CURRENT_WINDOW,
    text: getTranslationKey("recordMode", "currentWindow"),
  },
  {
    key: RUNNER_OPEN_MODE.NEW_WINDOW,
    value: RUNNER_OPEN_MODE.NEW_WINDOW,
    text: getTranslationKey("recordMode", "newWindow"),
  },
];
const runnerPositionOptions = [
  {
    key: RUNNER_POSITION.LEFT,
    value: RUNNER_POSITION.LEFT,
    text: getTranslationKey("recorderPosition", "left"),
  },
  {
    key: RUNNER_POSITION.RIGHT,
    value: RUNNER_POSITION.RIGHT,
    text: getTranslationKey("recorderPosition", "right"),
  },
];

const RunnerTypeFormChunk: React.FunctionComponent<Props> = ({
  readOnly,
  values,
  change,
  mode,
}) => {
  const isAdvancedRunner = getIn(values, "runner.type") === RUNNER_TYPE.ADVANCED;

  const onRunnerTypeChange = () => {
    change("runner.openMode", undefined);
    change("runner.position", undefined);
  };

  return (
    <>
      <RadioField
        name="runner.type"
        label={getTranslationKey(
          "settings",
          mode === RunnerMode.PLAYER ? "playerType" : "recorderType",
        )}
        type={RadioType.Button}
        options={runnerTypeOptions}
        onChange={onRunnerTypeChange}
        readOnly={readOnly}
      />

      {isAdvancedRunner && (
        <RadioField
          name="runner.openMode"
          label={getTranslationKey(
            "settings",
            mode === RunnerMode.PLAYER ? "playerOpenMode" : "recorderOpenMode",
          )}
          type={RadioType.Button}
          options={runnerOpenModeOptions}
          readOnly={readOnly}
          required
        />
      )}
      {isAdvancedRunner && (
        <RadioField
          name="runner.position"
          label={getTranslationKey(
            "settings",
            mode === RunnerMode.PLAYER ? "playerPosition" : "recorderPosition",
          )}
          type={RadioType.Button}
          options={runnerPositionOptions}
          readOnly={readOnly}
          required
        />
      )}
    </>
  );
};

export default RunnerTypeFormChunk;
