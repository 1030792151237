import { z } from "zod";
import { MoveDirections } from "../newRunner.types";
import {
  AdvancedRecorderSyncRequest,
  RecorderFinishRequest,
  RecorderFinishResponse,
  RecorderSyncRequest,
  ResolversRequest,
  RunnerInitResponse,
  RunnerRequest,
  TokensRequest,
} from "../runner.api.types";
import { AssignedVariableType, EXECUTION_STATE, EXECUTION_STATUS } from "./ea.enums";
import {
  AdvancedRecorderSyncResponse,
  CodeExecutionLog,
  Message,
  RecorderExecutionCache,
  RunnerBaseSchema,
} from "./ea.runner.types";
import { MessageSchema, StepSchemas } from "./ea.schema";
import { GENERATED_BY_DB_PROPERTIES } from "./ea.schema.helpers";

export const EndpointsSchemas = {
  runner: {
    player: {
      init: {
        request: RunnerBaseSchema,
        response: z.any({}),
        responseType: {} as RunnerInitResponse,
      },
      moveStart: {
        request: RunnerBaseSchema.extend({
          executionId: z.string(),
          position: z.number(),
          status: z.nativeEnum(EXECUTION_STATUS).optional(),
          direction: z
            .object({
              moveDirection: z.nativeEnum(MoveDirections),
              from: z.string(),
              to: z.string(),
            })
            .optional(),
        }),
        response: z.object({}),
      },
      moveFinish: {
        request: RunnerBaseSchema.extend({
          executionId: z.string(),
          position: z.number(),
          status: z.nativeEnum(EXECUTION_STATUS).optional(),
          direction: z
            .object({
              moveDirection: z.nativeEnum(MoveDirections),
              from: z.string(),
              to: z.string(),
            })
            .optional(),
        }),
        response: z.object({}),
      },
      state: {
        request: RunnerBaseSchema.extend({
          state: z.nativeEnum(EXECUTION_STATE),
          prevState: z.nativeEnum(EXECUTION_STATE),
        }),
        response: z.object({}),
      },
      tokens: {
        requestType: {} as TokensRequest, // why z.any() is optional any?
        request: RunnerBaseSchema.extend({
          virtualUserId: z.number(),
          data: z.object({
            sessionStorage: z.any(),
            localStorage: z.any(),
          }),
          cookiesSettings: z.object({
            omit: z.array(z.string()),
            take: z.array(z.string()),
            override: z.record(z.any()),
          }),
        }),
        response: z.object({}),
      },
      log: {
        request: RunnerBaseSchema.extend({
          messages: z.array(MessageSchema).optional(),
          variables: z.array(z.any()).optional(), // use CodeExecutionLog later
        }),
        requestType: {} as RunnerRequest<{
          messages?: Message[];
          variables?: CodeExecutionLog[]; // remove later when code execution log schema will be defined
        }>,
        response: z.object({}),
      },
      kpi: {
        request: RunnerBaseSchema.extend({
          kpi: z.array(z.number()),
        }),
        response: z.object({}),
      },
      auth: {
        request: RunnerBaseSchema.extend({
          scriptId: z.number(),
          virtualUserId: z.number().optional(),
        }),
        response: z.object({
          hasBeenAuthenticated: z.boolean().optional(),
          tokenData: z.string().optional(),
          error: z.string().optional(),
        }),
      },
      logOut: {
        request: RunnerBaseSchema.extend({
          scriptId: z.number(),
          virtualUserId: z.number().optional(),
        }),
        response: z.object({
          hasBeenLoggedOut: z.boolean().optional(),
        }),
      },
      resolvers: {
        requestType: {} as ResolversRequest,
        request: RunnerBaseSchema.extend({
          stepsResolvers: z.record(
            z.object({
              data: z.any(),
              metadata: z.array(z.record(z.any())),
            }),
          ),
        }),
        response: z.object({}),
      },
      datasource: {
        request: RunnerBaseSchema.extend({
          scriptId: z.number(),
          sheet: z.string(),
          row: z.number(),
        }),
        response: z.object({}),
      },
      backgroundScreenshot: {
        request: RunnerBaseSchema,
        response: z.object({}),
      },
      backgroundVideo: {
        request: RunnerBaseSchema.extend({
          scriptId: z.coerce.number(),
        }),
        response: z.any(),
      },
      nextNumber: {
        request: RunnerBaseSchema.extend({
          name: z.string(),
        }),
        response: z.object({
          formattedNumber: z.string(),
        }),
      },
    },
    recorder: {
      init: {
        request: RunnerBaseSchema,
        response: z.object({}),
        responseType: {} as RecorderExecutionCache,
      },
      auth: {
        request: RunnerBaseSchema.extend({
          scriptId: z.number(),
          virtualUserId: z.number().optional(),
        }),
        response: z.object({
          hasBeenAuthenticated: z.boolean().optional(),
          tokenData: z.string().optional(),
          error: z.string().optional(),
        }),
      },
      syncState: {
        requestType: {} as RecorderSyncRequest,
        request: z.any(),
        response: z.object({}),
      },
      finish: {
        requestType: {} as RecorderFinishRequest,
        responseType: {} as RecorderFinishResponse,
        request: z.any(),
        response: z.any(),
      },
      syncSteps: {
        requestType: {} as AdvancedRecorderSyncRequest,
        responseType: {} as AdvancedRecorderSyncResponse,
        request: z.object({
          sessionId: z.string(),
          recordingPath: z.string().optional(),
          idsToRemove: z
            .array(
              z.number({
                invalid_type_error: "Cannot remove steps during synchronization.",
              }),
            )
            .optional(),
          order: z.array(z.union([z.number(), z.string()])).optional(),
          steps: z.array(
            StepSchemas.update
              .partial({ ...GENERATED_BY_DB_PROPERTIES, lineNum: true, label: true })
              .extend({
                temporaryStepId: z.string().optional(),
                id: z.union([z.number(), z.string()]),
                synced: z.boolean().optional(),
                lastModified: z.string().optional(),
                guards: z.array(z.any()).optional(),
                execution: z.object({
                  numberOfExecutions: z.number(),
                  status: z.nativeEnum(EXECUTION_STATUS),
                  path: z.string(),
                  steps: z.array(z.any()),
                  forceStatus: z.nativeEnum(EXECUTION_STATUS).optional().nullable(),
                  usedVariables: z.array(
                    z.object({
                      variableId: z.number(),
                      linkId: z.number().optional().nullable(),
                      variableType: z.nativeEnum(AssignedVariableType).optional().nullable(),
                    }),
                  ),
                }),
              }),
          ),
        }),
        response: z.any(),
      },
    },
  },
};

export const CountResponseSchema = z.object({ count: z.number() });
