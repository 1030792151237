import { getTranslationKey } from "@app/translations/translations.helpers";
import { getStepOptions } from "@app/utils/getStepOptions";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import { AssertActions, Kpi, Script, Step } from "@ea/shared_types/types";
import styled from "@emotion/styled";
import { ColProps } from "antd/lib/grid/col";
import { getIn } from "final-form";
import * as React from "react";
import { FieldArray } from "react-final-form-arrays";

interface IAssertActionProps {
  steps: Step[];
  script: Script;
  readOnly?: boolean;
  prefix: string;
  values: any;
  kpis?: Kpi[];
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  change: (name: string, value: any) => void;
}

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  minWidth: "200px",
});

const formItemStyle = {
  marginBottom: 0,
};

const ActionsWithParameters = [
  AssertActions.JUMP,
  AssertActions.DATA_SOURCE_AND_JUMP,
  AssertActions.LOG,
  AssertActions.ERROR,
  AssertActions.WARNING,
  AssertActions.INCREASE_KPI,
];

const AssertActionPicker: React.FunctionComponent<IAssertActionProps> = ({
  values,
  prefix,
  readOnly,
  script,
  kpis,
  steps,
  change,
}) => {
  const getActionControl = () => {
    const type = getIn(values, `${prefix}.type`);

    if (!type) {
      return null;
    }

    const stepOptions = getStepOptions(steps, "id");
    switch (type) {
      case AssertActions.JUMP:
      case AssertActions.DATA_SOURCE_AND_JUMP:
        return (
          <>
            <SelectField
              name={`${prefix}.values.[0]`}
              readOnly={readOnly}
              required
              placeholder={getTranslationKey("none")}
              label={getTranslationKey("step", "label", "selectStep")}
              options={stepOptions}
            />
            {type === AssertActions.DATA_SOURCE_AND_JUMP && (
              <SelectField
                name={`${prefix}.values.[1]`}
                readOnly={readOnly}
                required
                label={getTranslationKey("step", "placeholder", "selectSheet")}
                placeholder={getTranslationKey("step", "placeholder", "selectSheet")}
                options={(script.datasource
                  ? Object.keys(script.datasource.sheetsMeta) || []
                  : []
                ).map((item) => ({
                  key: item,
                  text: item,
                  value: item,
                }))}
              />
            )}
          </>
        );
      case AssertActions.LOG:
      case AssertActions.WARNING:
      case AssertActions.ERROR:
        return (
          <InputField
            name={`${prefix}.values.[0]`}
            defaultValue={""}
            readOnly={readOnly}
            placeholder={getTranslationKey("step", "placeholder", "enterMessage")}
            label={getTranslationKey("step", "placeholder", "enterMessage")}
          />
        );
      case AssertActions.INCREASE_KPI:
        return (
          <SelectField
            name={`${prefix}.values`}
            readOnly={readOnly}
            required
            mode="multiple"
            placeholder={getTranslationKey("none")}
            label={getTranslationKey("kpi", "label", "selectKpi")}
            options={(kpis || []).map(({ id, name }) => ({
              key: id,
              text: name,
              value: id,
            }))}
          />
        );
      default:
        return null;
    }
  };

  const actionOptions = [
    {
      value: AssertActions.CONTINUE,
      text: getTranslationKey("step", "assert", "option", "continue"),
    },
    {
      value: AssertActions.LOG,
      text: getTranslationKey("step", "assert", "option", "log"),
    },
    {
      value: AssertActions.WARNING,
      text: getTranslationKey("step", "assert", "option", "warning"),
    },
    {
      value: AssertActions.ERROR,
      text: getTranslationKey("step", "assert", "option", "error"),
    },
    {
      value: AssertActions.DATA_SOURCE_AND_JUMP,
      text: getTranslationKey("step", "assert", "option", "dataSourceAndJump"),
    },
    {
      value: AssertActions.JUMP,
      text: getTranslationKey("step", "assert", "option", "jump"),
    },
    {
      value: AssertActions.REPEAT,
      text: getTranslationKey("step", "assert", "option", "repeat"),
    },
  ];
  const type = getIn(values, `${prefix}.type`);
  if (kpis) {
    actionOptions.push({
      value: AssertActions.INCREASE_KPI,
      text: getTranslationKey("step", "assert", "option", "increaseKpi"),
    });
  }
  return (
    <Container>
      <SelectField
        name={`${prefix}.type`}
        required
        readOnly={readOnly}
        placeholder={getTranslationKey("none")}
        label={getTranslationKey("step", "label", "selectAction")}
        formItemRowStyle={formItemStyle}
        onChange={() => {
          change(`${prefix}.values`, []);
        }}
        options={actionOptions}
      />
      {ActionsWithParameters.indexOf(type) > -1 && (
        <FieldArray name={`${prefix}.values`}>{() => getActionControl()}</FieldArray>
      )}
    </Container>
  );
};

export default AssertActionPicker;
