import { QuestionCircleOutlined } from "@ant-design/icons";
import { ApplicationState } from "@app/modules/app.reducers";
import PlayModeContainer from "@app/modules/common/PlayMode.container";
import CloneForm from "@app/modules/common/components/CloneForm";
import CommandBar from "@app/modules/scripts/components/ScriptsCommandBar";
import { getScriptsTableActions, scriptsActions } from "@app/modules/scripts/scripts.actions";
import {
  areOnlyClosedSelectedSelector,
  getSelectedFilterClosedSelector,
  isAnyClosedSelectedSelector,
  isAtLeastOneSelectedMappedToTestCase,
  scriptsDataSelectors,
  scriptsSelectorsModifier,
} from "@app/modules/scripts/scripts.selectors";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { formatScriptName } from "@app/utils/script";
import { startBackgroundSession, startSession } from "@app/utils/start";
import BaseModalForm from "@ea/shared_components/ModalForm/BaseModalForm";
import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import {
  filterItemsToClose,
  overrideColumnProps,
  replaceColumn,
} from "@ea/shared_components/Table/common.tables";
import EditableCell from "@ea/shared_components/Table/elements/EditableCell";
import {
  currentUserIdSelector,
  currentUserRolesSelector,
  currentUserSettingsSelector,
} from "@ea/shared_components/auth/auth.selectors";
import { downloadFileBlob } from "@ea/shared_components/helpers/file";
import { GroupedScript, SelectMode, TableMode } from "@ea/shared_components/redux/common.models";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { COLORS } from "@ea/shared_components/styles/consts";
import { DataTestIds } from "@ea/shared_components/utils/dataTestHelpers";
import {
  EXECUTION_STATUS,
  ExportToCsvModels,
  FEATURES,
  PLAY_MODE,
  ROLES,
  RunnerMode,
  Script,
  ScriptsCsvAdditionalFields,
  StartForegroundSessionParams,
} from "@ea/shared_types/types";
import styled from "@emotion/styled";
import { Button, Checkbox, Modal } from "antd";
import * as PropTypes from "prop-types";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { RouterChildContext } from "react-router";
import { Dispatch, bindActionCreators } from "redux";
import { exportToCsv } from "../../utils/exportCsv";
import { generateExportScriptName } from "../../utils/transformations";
import RecordModeContainer from "../common/RecordMode.container";
import ExportingModal from "../common/components/ExportingModal";
import {
  disabledFeaturesSelector,
  isFeatureDisabledSelector,
} from "../globalSettings/globalSettings.selectors";
import { getProjectsTreeSelector, projectSelectors } from "../projects/projects.selectors";
import { PROJECTS_TABLES_CONFIG } from "../projects/projects.tables";
import { getTagsFilterValues } from "../tags/tag.selectors";
import { TAG_TABLES_CONFIG } from "../tags/tag.table";
import CreateScriptContainer from "./CreateScript.container";
import ImportScriptContainer from "./ImportScript.container";
import { getScriptColumnsRenderers } from "./ScriptColumnsRenderers";
import { ScriptsCsvAdditionalFieldForm } from "./components/ScriptsCsvAdditionalFieldForm";
import {
  PROJECT_SCRIPTS_COLUMNS,
  SCRIPTS_COLUMNS,
  SCRIPTS_COLUMNS_CONFIG,
  SCRIPTS_COLUMNS_MINIMAL,
  SCRIPTS_TABLES_CONFIG,
} from "./scripts.tables";

const ErrorModal = Modal.error;

interface ScriptsTableContainerProps {
  projectId?: number;
  className?: string;
  persistentQuery?: any;
}
interface ScriptsTableContainerState {
  mode: keyof typeof TableMode;
  newWindowVisibility: boolean;
  editWindowVisibility: boolean;
  importScriptContainerVisiblity: boolean;
  persistentQuery: any;
  columns: any;
  columnsMenuVisibility: boolean;
  playOptionsVisibility: boolean;
  recordOptionsVisibility: boolean;
  exportWithAdditionalFieldsVisibility: boolean;
  cloneForm: {
    isOpen: boolean;
  };
  closeDialog: {
    visible: boolean;
    updateTestCaseStatus?: boolean;
  };
  isExporting?: boolean;
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: "100%",
});

class ScriptsTableContainer extends React.Component<
  ScriptsTableContainerProps & IConnectProps,
  ScriptsTableContainerState
> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };
  connectedTable;
  context: RouterChildContext<any>;

  constructor(props: ScriptsTableContainerProps & IConnectProps) {
    super(props);
    const scriptRenderers = getScriptColumnsRenderers({ projectsMap: props.projectsMap });
    const Columns = overrideColumnProps<Script>(
      props.projectId ? PROJECT_SCRIPTS_COLUMNS : SCRIPTS_COLUMNS,
      [
        {
          dataIndex: "name",
          frameworkProps: {
            render: this.nameRenderer,
          },
        },
        {
          dataIndex: "startUrl",
          frameworkProps: {
            render: (_, record) => scriptRenderers.startUrlRenderer(record),
          },
        },
        {
          dataIndex: "envType",
          frameworkProps: {
            render: (_, record) => scriptRenderers.envTypeRenderer(record),
          },
        },
        {
          dataIndex: "envName",
          frameworkProps: {
            render: (_, record) => scriptRenderers.envNameRenderer(record),
          },
        },
      ],
    );

    const MinimalColumns = overrideColumnProps(SCRIPTS_COLUMNS_MINIMAL, [
      {
        dataIndex: "name",
        frameworkProps: {
          render: this.nameRenderer,
        },
      },
    ]);
    const ModifiedColumns = replaceColumn(
      Columns,
      "status",
      SCRIPTS_COLUMNS_CONFIG.statusWithClosed,
    );

    this.state = {
      mode: TableMode.FLAT,
      newWindowVisibility: false,
      editWindowVisibility: false,
      playOptionsVisibility: false,
      recordOptionsVisibility: false,
      importScriptContainerVisiblity: false,
      columnsMenuVisibility: false,
      exportWithAdditionalFieldsVisibility: false,
      cloneForm: {
        isOpen: false,
      },
      persistentQuery: { status: { neq: "CLOSED" }, ...props.persistentQuery },
      columns: {
        Columns,
        ModifiedColumns,
        MinimalColumns,
      },
      closeDialog: {
        visible: false,
        updateTestCaseStatus: false,
      },
      isExporting: false,
    };

    if (props.projectId) {
      this.state.persistentQuery.projectId = {
        inq: [props.projectId],
      };
    }
  }

  nameRenderer = (text, record) => (
    <EditableCell
      value={text}
      onDone={async (value) => {
        await API.patchGroup({ id: record.id, name: value }); // todo patch name
        this.reload();
      }}
    />
  );

  componentWillMount() {
    if (this.props.persistentQuery) {
      this.setState({ persistentQuery: this.props.persistentQuery });
    }
  }

  componentDidUpdate(prevProps: ScriptsTableContainerProps & IConnectProps) {
    if (prevProps.projectId !== this.props.projectId) {
      this.setState({
        persistentQuery: {
          ...this.state.persistentQuery,
          projectId: {
            inq: [this.props.projectId],
          },
        },
      });
    }
  }

  openClonePanel = () => {
    this.setState({
      cloneForm: {
        isOpen: true,
      },
    });
  };

  openEditScriptWindow = () => {
    this.setState({
      editWindowVisibility: true,
    });
  };

  closeEditScriptWindow = () => {
    this.setState({
      editWindowVisibility: false,
    });
  };

  openCreateScriptWindow = () => {
    this.setState({
      newWindowVisibility: true,
    });
  };
  closeCreateScriptWindow = () => {
    this.setState({
      newWindowVisibility: false,
    });
  };
  closeImportScriptWindow = () => {
    this.setState({
      importScriptContainerVisiblity: false,
    });
  };
  cloneScript = async ({ name, projectId, description }) => {
    const { selected } = this.props;
    if (!selected) {
      return;
    }
    if (selected) {
      await API.cloneScript({
        scriptIds: selected,
        name,
        description,
        projectId,
      }).catch((error) => {
        ErrorModal({
          title: error.message,
        });
      });
    }
    this.closePanel();
    this.reload();
  };

  importScript = async () => {
    this.setState({
      importScriptContainerVisiblity: true,
    });
  };
  exportScript = async () => {
    const { selected, selectedScript } = this.props;
    if (selected) {
      const blob = await API.exportScript({ scriptIds: selected });
      const fileName =
        selected.length === 1 && selectedScript
          ? generateExportScriptName({ id: selectedScript.id, name: selectedScript.name })
          : "Script.ea";
      downloadFileBlob(blob, fileName);
    }
  };

  openPlayOptions = () => {
    this.setState({
      playOptionsVisibility: true,
    });
  };

  openRecordOptions = () => {
    this.setState({
      recordOptionsVisibility: true,
    });
  };

  closePlayOptions = () => {
    this.setState({
      playOptionsVisibility: false,
    });
  };

  closeRecordOptions = () => {
    this.setState({
      recordOptionsVisibility: false,
    });
  };

  shouldBeExpandable = (script: GroupedScript) => {
    return script.counter > 0;
  };

  record = async (evt: React.MouseEvent, additional?: Partial<StartForegroundSessionParams>) => {
    const { selectedScript, userSettings } = this.props;
    const { playMode, ...defaultParams } = userSettings;
    const isCtrlEvt = evt.ctrlKey;

    if (selectedScript) {
      startSession(formatScriptName(selectedScript), {
        isBackground: false,
        mode: RunnerMode.RECORDER,
        script: selectedScript.id,
        ...defaultParams,
        ...additional,
        isCtrlEvt,
      });
    }
  };

  play = async (selectedMode, selectedParams) => {
    const { selectedScript, userSettings } = this.props;
    const { playMode, ...defaultParams } = userSettings;
    const mode = selectedMode || playMode;
    const optionalParams = { ...defaultParams, ...selectedParams };

    if (!selectedScript) {
      return;
    }

    switch (mode) {
      case PLAY_MODE.FOREGROUND: {
        if (selectedScript) {
          startSession(formatScriptName(selectedScript), {
            mode: RunnerMode.PLAYER,
            isBackground: false,
            script: selectedScript.id,
            ...optionalParams,
          });
        }
        break;
      }

      case PLAY_MODE.BACKGROUND:
        await startBackgroundSession(formatScriptName(selectedScript), {
          mode: RunnerMode.PLAYER,
          isBackground: true,
          script: selectedScript.id,
          ...optionalParams,
        });

        break;

      default:
        break;
    }
  };

  openCommandBar = () => {
    if (this.props.selectedScript) {
      this.openScript(this.props.selectedScript);
    }
  };

  openScript = (item: Script) => {
    this.context.router.history.push(`/scripts/${item.id}`);
  };

  openWorkItems = (item: Script) => {
    this.context.router.history.push(`/scripts/${item.id}/workItems`);
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  onClose = () => {
    this.setState({
      closeDialog: { visible: true },
    });
  };

  onCloseConfirm = async () => {
    const { items, selected, actions } = this.props;
    const { closeDialog } = this.state;
    const itemsToClose = filterItemsToClose(items, selected);
    await actions.close({
      ids: itemsToClose.map((item) => item.id),
      options: { updateTestCaseStatus: closeDialog.updateTestCaseStatus },
    });
    actions.select({ ids: [], mode: SelectMode.Replace });
    this.reload();
    this.setState({
      closeDialog: { visible: false, updateTestCaseStatus: false },
    });
  };

  onCloseCancel = () => {
    this.setState({
      closeDialog: { visible: false, updateTestCaseStatus: false },
    });
  };

  closePanel = () => {
    this.setState({
      cloneForm: {
        isOpen: false,
      },
    });
  };

  toggleShowClosed = () => {
    const persistentQuery = this.state.persistentQuery;
    const { showClosed, actions } = this.props;

    actions.clearFilters({ fields: ["status"] });
    if (showClosed) {
      this.setState({
        persistentQuery: {
          ...persistentQuery,
          status: { neq: "CLOSED" },
        },
      });
    } else {
      const { status, ...rest } = persistentQuery;
      this.setState({
        persistentQuery: rest,
      });
    }
  };

  toggleShowWithWorkItems = () => {
    const persistentQuery = this.state.persistentQuery;
    if (!this.props.showWithWorkItems) {
      this.setState({
        persistentQuery: {
          ...persistentQuery,
          withWorkItemsOnly: true,
        },
      });
    } else {
      const { withWorkItemsOnly, ...rest } = persistentQuery;
      this.setState({
        persistentQuery: rest,
      });
    }
  };

  togglePickColumns = () =>
    this.setState({ columnsMenuVisibility: !this.state.columnsMenuVisibility });

  getCloseConfirmFooter = () => {
    const footer = [
      <Button
        key="cancel"
        onClick={this.onCloseCancel}
        data-testid={DataTestIds.MODAL_BUTTON_CANCEL}
      >
        <FormattedMessage id={getTranslationKey("button", "no")} />
      </Button>,
      <Button
        danger
        key="ok"
        onClick={this.onCloseConfirm}
        data-testid={DataTestIds.MODAL_BUTTON_SUBMIT}
      >
        <FormattedMessage id={getTranslationKey("button", "yes")} />
      </Button>,
    ];

    if (this.props.isAtLeastOneSelectedMappedToTestCase) {
      footer.push(
        <Checkbox
          style={{ marginLeft: 10 }}
          key="updateTestCaseStatus"
          onChange={(e) => {
            this.setState({
              closeDialog: {
                updateTestCaseStatus: e.target.checked,
                visible: this.state.closeDialog.visible,
              },
            });
          }}
        >
          <FormattedMessage id={getTranslationKey("scripts", "label", "updateTestCaseStatus")} />
        </Checkbox>,
      );
    }

    return footer;
  };

  exportToExcel = async (additionalFields?: { [key in ScriptsCsvAdditionalFields]?: boolean }) => {
    const { tableParams, selected, userId, preferencesId, isTagsDisabled } = this.props;
    const params = createRequestParams(tableParams, SCRIPTS_COLUMNS_CONFIG);
    const fields = SCRIPTS_COLUMNS.map((script) => script.props.dataIndex).filter((dataIndex) => {
      if (isTagsDisabled) {
        return dataIndex === "tags" ? false : true;
      }
      return true;
    });

    await exportToCsv({
      fields,
      params,
      modelName: ExportToCsvModels.SCRIPTS,
      selected,
      onExportStart: () => this.setState({ isExporting: true }),
      onExportFinish: () => this.setState({ isExporting: false }),
      userId,
      preferencesId,
      additionalFields,
    });
  };

  onExportWithAdditionalFieldsClick = async () => {
    this.setState({ exportWithAdditionalFieldsVisibility: true });
  };
  closeExportWithAdditionalFields = () => {
    this.setState({ exportWithAdditionalFieldsVisibility: false });
  };

  exportWithAdditionalFields = async (additionalFields: {
    [key in ScriptsCsvAdditionalFields]?: boolean;
  }) => {
    this.closeExportWithAdditionalFields();
    this.exportToExcel(additionalFields);
  };

  render() {
    const {
      cloneForm,
      newWindowVisibility,
      columns,
      playOptionsVisibility,
      persistentQuery,
      importScriptContainerVisiblity,
      closeDialog,
      recordOptionsVisibility,
      isExporting,
    } = this.state;
    const {
      areOnlyClosedSelected,
      isAnyClosedSelected,
      showClosed,
      selectedScript,
      userRoles,
      className,
      projects,
      tableId,
      preferencesId,
      projectId,
      showWithWorkItems,
      tagFilterValues,
      disabledFeatures,
      selected,
      userSettings,
    } = this.props;
    return (
      <Container>
        <CommandBar
          onNewClick={this.openCreateScriptWindow}
          onCloseClick={this.onClose}
          selectedScripts={this.props.selected}
          selectedScript={selectedScript}
          onReload={this.reload}
          onPlayClick={this.play}
          importScript={this.importScript}
          exportScript={this.exportScript}
          onRecordClick={(evt) => this.record(evt)}
          onOpen={this.openCommandBar}
          onCloneClick={this.openClonePanel}
          showClosed={showClosed}
          onShowClosedClick={this.toggleShowClosed}
          showWithWorkItems={showWithWorkItems}
          onShowWithWorkItemsClick={this.toggleShowWithWorkItems}
          onlyClosedSelected={areOnlyClosedSelected}
          isAnyClosedSelected={isAnyClosedSelected}
          onOptionsClick={userRoles.includes(ROLES.freedocs) ? undefined : this.openPlayOptions}
          onRecordOptionsClick={
            userRoles.includes(ROLES.freedocs) ? undefined : this.openRecordOptions
          }
          onExport={(e) => this.exportToExcel()}
          isExporting={this.state.isExporting}
          onExportWithAdditionalFieldsClick={this.onExportWithAdditionalFieldsClick}
        />
        <ConnectedTable
          key={"flat_table"}
          className={className}
          setRef={(component) => (this.connectedTable = component)}
          pageable
          modifySelectors={scriptsSelectorsModifier}
          dynamicFilterValues={{
            projectId: projects,
            tags: tagFilterValues,
          }}
          columnsConfig={
            userRoles.includes(ROLES.freedocs)
              ? columns.MinimalColumns
              : showClosed
              ? columns.ModifiedColumns
              : columns.Columns
          }
          onRowDoubleClick={showWithWorkItems ? this.openWorkItems : this.openScript}
          tableId={tableId}
          preferencesId={preferencesId}
          stateKey={"scripts"}
          tableActions={getScriptsTableActions}
          persistentQuery={persistentQuery}
          disabledFeatures={disabledFeatures}
        />
        <CreateScriptContainer
          visibility={newWindowVisibility}
          onClose={this.closeCreateScriptWindow}
          onCreate={this.reload}
          projectId={projectId}
        />
        <ImportScriptContainer
          visibility={importScriptContainerVisiblity}
          onReload={this.reload}
          onClose={this.closeImportScriptWindow}
          projects={projects}
          projectId={projectId}
        />
        <BaseModalForm
          visible={cloneForm.isOpen}
          headerText={getTranslationKey("scripts", "header", "clone")}
          onCancelClick={this.closePanel}
          onOkClick={this.cloneScript}
          allowPrisitineSubmit={true}
          cancelButtonText={getTranslationKey("button", "cancel")}
          okButtonText={getTranslationKey("button", "clone")}
          initialValues={{ projectId: selectedScript?.projectId }}
          render={({ change, values }) => (
            <CloneForm
              values={values}
              change={change}
              projects={projects}
              selectedScript={this.props.selectedScript}
            />
          )}
        />
        <PanelFormFinal
          visibility={this.state.exportWithAdditionalFieldsVisibility}
          panelType={PanelType.MODAL}
          customWidth={500}
          headerText={getTranslationKey("common", "header", "exportWithAdditional")}
          onCancelClick={this.closeExportWithAdditionalFields}
          onOkClick={this.exportWithAdditionalFields}
          cancelButtonText={getTranslationKey("button", "cancel")}
          okButtonText={getTranslationKey("button", "export")}
          initialValues={{}}
          allowPrisitineSubmit
          render={({ change, values }) => (
            <ScriptsCsvAdditionalFieldForm values={values} change={change} />
          )}
        />
        <PlayModeContainer
          visibility={playOptionsVisibility}
          onClose={this.closePlayOptions}
          play={this.play}
          script={selectedScript}
        />
        <RecordModeContainer
          visibility={recordOptionsVisibility}
          onClose={this.closeRecordOptions}
          run={this.record}
          script={selectedScript}
          runnerParams={userSettings.runner}
        />
        <Modal
          visible={closeDialog.visible}
          closable={false}
          footer={this.getCloseConfirmFooter()}
          bodyStyle={{ display: "flex", justifyContent: "center" }}
        >
          <span style={{ marginLeft: "15px" }}>
            <QuestionCircleOutlined
              style={{
                marginRight: 30,
                color: COLORS.STATUS[EXECUTION_STATUS.WARNING],
                fontSize: 25,
              }}
            />
            <FormattedMessage
              id={getTranslationKey(
                "messages",
                "info",
                selected.length > 1 ? "closeScripts" : "closeScript",
              )}
            />
          </span>
        </Modal>
        <ExportingModal visible={isExporting} />
      </Container>
    );
  }
}

const mapStateToProps = (state: ApplicationState, props: ScriptsTableContainerProps) => {
  const tableId = props.projectId
    ? SCRIPTS_TABLES_CONFIG.PROJECT.id(props.projectId)
    : SCRIPTS_TABLES_CONFIG.MAIN.id();
  const preferencesId = props.projectId
    ? SCRIPTS_TABLES_CONFIG.PROJECT.preferencesId
    : SCRIPTS_TABLES_CONFIG.MAIN.preferencesId;

  return {
    ...state.scripts,
    selected: getSelectedFilterClosedSelector(state, tableId),
    selectedScript: scriptsDataSelectors.getSelectedItemSelector(state, tableId),
    areOnlyClosedSelected: areOnlyClosedSelectedSelector(state, tableId),
    isAtLeastOneSelectedMappedToTestCase: isAtLeastOneSelectedMappedToTestCase(state, tableId),
    isAnyClosedSelected: isAnyClosedSelectedSelector(state, tableId),
    showClosed: scriptsDataSelectors.getClosedSelector(state, tableId),
    showWithWorkItems: scriptsDataSelectors.getShowWithWorkItemsSelector(state, tableId),
    userSettings: currentUserSettingsSelector(state),
    userRoles: currentUserRolesSelector(state),
    projects: getProjectsTreeSelector(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    projectsMap: projectSelectors.getDataSelector(state),
    tableId,
    preferencesId,
    persistentQuery: scriptsDataSelectors.getPersistentQuerySelector(state, tableId),
    tagFilterValues: getTagsFilterValues(state, TAG_TABLES_CONFIG.MAIN.id()),
    disabledFeatures: disabledFeaturesSelector(state),
    isTagsDisabled: isFeatureDisabledSelector(state, FEATURES.GXP_TAGS),
    tableParams: scriptsDataSelectors.getParamsSelector(state, tableId),
    userId: currentUserIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, props: ScriptsTableContainerProps) => {
  const tableId = props.projectId
    ? SCRIPTS_TABLES_CONFIG.PROJECT.id(props.projectId)
    : SCRIPTS_TABLES_CONFIG.MAIN.id();
  return {
    actions: {
      ...bindActionCreators(scriptsActions, dispatch),
      ...bindActionCreators(getScriptsTableActions(tableId), dispatch),
    },
  };
};

const connectCreator = connect(mapStateToProps, mapDispatchToProps);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(ScriptsTableContainer);
