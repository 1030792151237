import * as React from "react";
import { LayoutContext } from "../FormLayoutContext";
import styled from "@emotion/styled";
import { Row, Col } from "antd";

export const HeaderText = styled.h4({
  textAlign: "right",
  marginRight: "15px",
  fontWeight: "bold",
  marginTop: "15px",
});

export const FormHeader = ({ text }: { text: string | React.ReactNode }) => (
  <LayoutContext.Consumer>
    {(value) => (
      <Row>
        <Col {...value.labelCol}>
          {typeof text === "string" ? <HeaderText> {text} </HeaderText> : { text }}
        </Col>
      </Row>
    )}
  </LayoutContext.Consumer>
);
