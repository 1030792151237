import { getTranslationKey } from "@app/translations/translations.helpers";
import * as React from "react";

import { DataTestIds } from "@app/utils/dataTestIds";
import { DatePickerField } from "@ea/shared_components/Form/Fields/DatePickerField";
import { EmptyField } from "@ea/shared_components/Form/Fields/EmptyField";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import OnFormChange from "@ea/shared_components/Form/OnFormChange";
import validators, { composeValidators } from "@ea/shared_components/Form/validators";
import { GlobalVariableType, VariableDefaultValueType } from "@ea/shared_types/types";
import { FormApi, getIn } from "final-form";
import { InjectedIntlProps, injectIntl } from "react-intl";
interface IGlobalVariableFormProps {
  readOnly?: boolean;
  values: any;
  change: (name: string, value: any) => void;
  form: FormApi;
}

const typeOptions = () => [
  {
    value: VariableDefaultValueType.Text,
    text: getTranslationKey("variable", "type", "text"),
    type: "text",
  },
  {
    value: VariableDefaultValueType.Number,
    text: getTranslationKey("variable", "type", "number"),
    type: "number",
  },
  {
    value: VariableDefaultValueType.Date,
    text: getTranslationKey("variable", "type", "date"),
    type: "date",
  },
];
const variableOptions = () => [
  {
    value: GlobalVariableType.CONSTANT,
    text: getTranslationKey("globalVariable", "type", "constant"),
    type: GlobalVariableType.CONSTANT,
  },
  {
    value: GlobalVariableType.MUTABLE,
    text: getTranslationKey("globalVariable", "type", "mutable"),
    type: GlobalVariableType.MUTABLE,
  },
];

const GlobalVariableForm = (props: IGlobalVariableFormProps & InjectedIntlProps) => {
  const { readOnly, change, intl, values } = props;
  const { whiteSpaceAndSpecialCharValidator, mustStartWithLetter } = validators(intl);
  const isValueTypeRequired = getIn(values, "type") === GlobalVariableType.CONSTANT;
  return (
    <>
      <SelectField
        name="type"
        placeholder={getTranslationKey("variable", "placeholder", "selectType")}
        label={getTranslationKey("globalVariable", "label", "variableType")}
        options={variableOptions()}
        required
        readOnly={readOnly}
        onChange={() => props.form.reset()}
        data-testid={DataTestIds.FORM_SELECT_TYPE}
      />
      <InputField
        name="name"
        required
        readOnly={readOnly}
        placeholder={getTranslationKey("common", "placeholder", "name")}
        label={getTranslationKey("common", "label", "name")}
        validate={composeValidators(whiteSpaceAndSpecialCharValidator, mustStartWithLetter)}
        data-testid={DataTestIds.FORM_INPUT_NAME}
      />
      <SelectField
        name="valueType"
        placeholder={getTranslationKey("variable", "placeholder", "selectType")}
        label={getTranslationKey("table", "valueType")}
        required={isValueTypeRequired}
        onChange={() => {
          change("value", undefined);
        }}
        options={typeOptions()}
        data-testid={DataTestIds.FORM_SELECT_VALUE_TYPE}
      />
      <OnFormChange name={"valueType"}>
        {(value) => {
          switch (value) {
            case VariableDefaultValueType.Text:
            case VariableDefaultValueType.Number: {
              return (
                <InputField
                  name={"value"}
                  placeholder={getTranslationKey("variable", "placeholder", "enterValue")}
                  label={getTranslationKey("variable", "label", "value")}
                  type={value}
                  data-testid={DataTestIds.FORM_INPUT_VALUE}
                />
              );
            }
            case VariableDefaultValueType.Date: {
              return (
                <DatePickerField
                  name={"value"}
                  label={getTranslationKey("variable", "label", "value")}
                  placeholder={getTranslationKey("variable", "placeholder", "enterValue")}
                  data-testid={DataTestIds.FORM_INPUT_DATEPICKER}
                />
              );
            }
            default:
              return <EmptyField name={"value"} />;
          }
        }}
      </OnFormChange>
      <TextAreaField
        name="description"
        defaultValue={""}
        placeholder={getTranslationKey("common", "placeholder", "description")}
        autoSize={{ minRows: 2, maxRows: 6 }}
        label={getTranslationKey("common", "label", "description")}
        data-testid={DataTestIds.FORM_INPUT_DESCRIPTION}
      />
    </>
  );
};

export default injectIntl(GlobalVariableForm);
