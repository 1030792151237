import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { DataSourceMetadata } from "@ea/shared_types/next/ea.types";
import { moduleActions } from "./globalExcels.actions";

const initialState = {
  ...getDataReducerInitialState<DataSourceMetadata>(),
};

export type GlobalExcelsReducer = typeof initialState;

const dataReducer = createDataReducer<DataSourceMetadata>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
