import { getIn } from "final-form";
import * as React from "react";

import {
  Step,
  Variable,
  Script,
  CONDITIONAL_ACTION_TYPE,
  CONDITIONAL_TYPE,
  INTERRUPTION_TYPE,
  CodeType,
  Kpi,
  GlobalVariable,
} from "@ea/shared_types/types";
import RadioField, { RadioType } from "@ea/shared_components/Form/Fields/RadioField";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import AssertFormChunk, {
  parseAssertValue,
} from "@app/modules/steps/components/Forms/Assert/AssertFormChunk";
import { getTranslationKey } from "@app/translations/translations.helpers";
import AssertActionPicker from "@app/modules/steps/components/Forms/Assert/AssertActionPicker";
import { OverrideStatus } from "@app/modules/steps/components/Forms/Assert/OverrideStatus";
import FormLayout from "@ea/shared_components/Form/FormLayout";

interface IReactionsFormChunkProps {
  reactionIndex: number;
  interruptionPrefix?: string;
  readOnly?: boolean;
  kpis?: Kpi[];
  variables: Variable[];
  script: Script;
  steps: Step[];
  change: any;
  values: any;
  stepOptions: any;
  globalMutable?: GlobalVariable[];
  globalConstant?: GlobalVariable[];
}

interface IReactionsFormChunkState {
  activeKey: string | string[] | undefined;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const reactOnTypeOptions = (values, index) =>
  Object.keys(INTERRUPTION_TYPE)
    .filter(
      (r) =>
        !getIn(values, "reactions")
          .filter((reaction, i) => i !== index)
          .map((reaction) => reaction.reactOn)
          .includes(INTERRUPTION_TYPE[r]),
    )
    .map((r) => ({
      value: INTERRUPTION_TYPE[r],
      text: getTranslationKey("conditionals", "reactionOptions", INTERRUPTION_TYPE[r]),
    }));

class ReactionsFormChunk extends React.Component<
  IReactionsFormChunkProps,
  IReactionsFormChunkState
> {
  state: IReactionsFormChunkState = {
    activeKey: ["0"],
  };

  onCollapseChange(activeKey) {
    this.setState({ activeKey });
  }

  onActivityChange({ target }) {
    const { value } = target;
    const { change, reactionIndex } = this.props;
    if (value === CONDITIONAL_ACTION_TYPE.ADVANCED) {
      change(`reactions[${reactionIndex}].activity`, {
        ...parseAssertValue(),
        mode: CodeType.CONSTANT,
      });
    } else {
      change(`reactions[${reactionIndex}].activity`, undefined);
    }
  }

  render() {
    const {
      reactionIndex,
      values,
      change,
      steps,
      variables,
      script,
      readOnly,
      globalMutable,
      globalConstant,
      kpis,
    } = this.props;

    const activityType: string = getIn(values, `reactions[${reactionIndex}].activityType`);
    const reactOn: string = getIn(values, `reactions[${reactionIndex}].reactOn`);
    return (
      <FormLayout {...formItemLayout}>
        {reactOn !== CONDITIONAL_TYPE.SUCCESS && (
          <SelectField
            name={`reactions[${reactionIndex}].reactOn`}
            required
            placeholder={getTranslationKey("conditionals", "placeholder", "reactOn")}
            label={getTranslationKey("conditionals", "label", "reactOn")}
            options={reactOnTypeOptions(values, reactionIndex)}
            readOnly={readOnly}
          />
        )}
        <RadioField
          name={`reactions[${reactionIndex}].activityType`}
          label={getTranslationKey("conditionals", "label", "activityType")}
          type={RadioType.Button}
          required
          readOnly={readOnly}
          options={[
            {
              text: getTranslationKey("conditionals", "simple"),
              value: CONDITIONAL_ACTION_TYPE.SIMPLE,
            },
            {
              text: getTranslationKey("conditionals", "advanced"),
              value: CONDITIONAL_ACTION_TYPE.ADVANCED,
            },
          ]}
          onChange={(evt) => this.onActivityChange(evt)}
        />
        {activityType === CONDITIONAL_ACTION_TYPE.SIMPLE && (
          <>
            <AssertActionPicker {...this.props} prefix={`reactions[${reactionIndex}].activity`} />
            <OverrideStatus prefix={`reactions[${reactionIndex}].activity`} readOnly={readOnly} />
          </>
        )}
        {activityType === CONDITIONAL_ACTION_TYPE.ADVANCED && (
          <AssertFormChunk
            script={script}
            steps={steps}
            variables={variables}
            prefix={`reactions[${reactionIndex}].activity`}
            change={change}
            values={values}
            kpis={kpis}
            readOnly={readOnly}
            globalMutable={globalMutable}
            globalConstant={globalConstant}
          />
        )}
      </FormLayout>
    );
  }
}

export default ReactionsFormChunk;
