import { objectValues } from "@ea/shared_components/utils/object";

import * as auditTrailActions from "./auditTrail.actions";
import auditTrailEpicsMap from "./auditTrail.epics";
import * as auditTrailReducers from "./auditTrail.reducers";

const auditTrailEpics = objectValues(auditTrailEpicsMap);

export { auditTrailActions, auditTrailReducers, auditTrailEpics };

export { default as AuditTrailContainer } from "./AuditTrail.container";
