import styled from "@emotion/styled";
import { NAVBAR_HEIGHT } from "@app/styles/consts";

export const AppSection = styled.div({
  height: "100vh",
  width: "100%",
});

export const ContentSection = styled.div(
  {
    display: "flex",
    flex: "1 1 auto",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  ({ isNavbarHidden }: { isNavbarHidden?: boolean }) => ({
    paddingTop: isNavbarHidden ? 0 : NAVBAR_HEIGHT,
    maxHeight: isNavbarHidden ? "100%" : `calc(100% - ${NAVBAR_HEIGHT})`,
  }),
);
