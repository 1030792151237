import { DeleteOutlined, FileOutlined, UploadOutlined } from "@ant-design/icons";
import { API } from "@app/services/api/api";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { PanelType } from "@ea/shared_components/Panel";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { CheckboxField } from "@ea/shared_components/src";
import styled from "@emotion/styled";
import { Button, Tooltip } from "antd";
import * as React from "react";
import Dropzone from "react-dropzone";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { getTranslationKey } from "./../../translations/translations.helpers";
interface ImportLocalizationContainerProps {
  visibility: boolean;
  onClose: () => void;
  onReload: () => void;
}

interface ImportLocalizationContainerState {
  files: any[];
  loading: boolean;
  dropping: boolean;
}
const DropZoneDiv = styled.div(
  {
    borderRadius: "4px",
    background: "#f4f4f4",
    border: "2px dashed #d9d9d9",
    transition: "border-color .3s",
    width: "400px",
    height: "150px",
    textAlign: "center",
    padding: "16px 0",
    cursor: "pointer",
    "&:hover": {
      background: "#dbdbdb",
      border: "2px dashed #2b2b2b",
    },
  },
  ({ isDragActive }: { isDragActive: boolean }) =>
    isDragActive ? { border: "2px dashed green" } : {},
);
const LoadedFilesContainer = styled.div({
  display: "flex",
  flexDirecion: "column",
  padding: "0 0 0 0px",
});

const LoadedFile = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  margin: "5px",
});

const formItemLayout = {
  labelCol: {
    sm: { span: 12 },
  },
  wrapperCol: {
    sm: { span: 12 },
  },
};

class ImportLocalizationContainer extends React.Component<ImportLocalizationContainerProps> {
  formRef: any;
  dropzoneRef: any;
  state: ImportLocalizationContainerState = {
    files: [],
    loading: false,
    dropping: false,
  };
  onDrop = async (files: any) => {
    this.setState({ dropping: true });
  };
  onDropAccepted = async (files: any) => {
    this.setState({ files, dropping: false });
  };
  onReject = () => {
    toast.warn(getTranslationKey("messages", "error", "fileUpload"));
  };

  onOk = async (values) => {
    const { files } = this.state;
    try {
      await API.importTranslation({
        data: files,
        values: { overwriteExisting: values.overwriteExisting },
      } as any);
    } catch (error) {
      this.setState({
        loading: false,
      });
      throw error;
    }
    this.setState({ files: [] });
    this.props.onClose();
    this.props.onReload();
  };
  onDelete = () => {
    this.setState({ files: [] });
  };
  renderFileList = () => (
    <LoadedFile>
      <FileOutlined />
      <h3 style={{ margin: 15 }}>{this.state.files[0].name}</h3>
      <Tooltip title={<FormattedMessage id={getTranslationKey("button", "delete")} />}>
        <Button danger icon={<DeleteOutlined />} onClick={() => this.onDelete()} />
      </Tooltip>
    </LoadedFile>
  );
  render() {
    const { onClose, visibility } = this.props;
    const { files, dropping } = this.state;

    return (
      <PanelFormFinal
        initialValues={{ overwriteExisting: false }}
        visibility={visibility}
        panelType={PanelType.SMALL}
        headerText={getTranslationKey("localization", "import")}
        cancelButtonText={getTranslationKey("button", "cancel")}
        okButtonText={getTranslationKey("button", "import")}
        onCancelClick={onClose}
        allowPrisitineSubmit={files.length > 0 ? true : false}
        onOkClick={this.onOk}
        render={() => (
          <>
            <FormLayout {...formItemLayout}>
              <CheckboxField
                name="overwriteExisting"
                label={getTranslationKey("localization", "overwriteExisting")}
              />
            </FormLayout>
            <LoadedFilesContainer>
              {files.length > 0 ? (
                this.renderFileList()
              ) : (
                <Dropzone
                  ref={(element) => {
                    this.dropzoneRef = element;
                  }}
                  multiple={false}
                  onDrop={this.onDrop}
                  onDropAccepted={this.onDropAccepted}
                  onDropRejected={this.onReject}
                  accept=".json"
                >
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <DropZoneDiv {...(getRootProps() as any)} isDragActive={isDragActive}>
                      <input {...(getInputProps() as any)} />
                      <UploadOutlined style={{ fontSize: 50 }} />
                      <p>
                        {!dropping ? (
                          <FormattedMessage
                            id={getTranslationKey("messages", "info", "dropZipZone")}
                          />
                        ) : (
                          <FormattedMessage id={getTranslationKey("loadingLabel")!} />
                        )}
                      </p>
                    </DropZoneDiv>
                  )}
                </Dropzone>
              )}
            </LoadedFilesContainer>
          </>
        )}
      />
    );
  }
}

export default ImportLocalizationContainer;
