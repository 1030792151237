import * as React from "react";
import styled from "@emotion/styled";

export const FormHint = styled.div({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const Hint = styled.pre({
  border: "1px solid #ccc",
  background: "rgba(0, 0, 0, 0.1)",
  boxShadow: "inset 1px 1px 3px rgba(0, 0, 0, 0.2)",
  padding: "20px",
  maxWidth: "100%",
  width: "100%",
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
});

export const printFormValues = (values) =>
  JSON.stringify(
    values,
    function (k, v) {
      return v === undefined ? "undefined" : v;
    },
    2,
  );
