import * as React from "react";
import styled from "@emotion/styled";
import { connect, ConnectedProps } from "react-redux";
import { Radio, Tooltip, Menu, Dropdown, Button } from "antd";
import { Script, Project } from "@ea/shared_types";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import {
  isSyncingSelector,
  isSomethingNotSynced,
  getPlayerState,
  getRecordingPath,
  runnerDataSelectors,
  getPlayMode,
  getCurrentPlayingStep,
} from "../runner.selectors";
import PauseIcon from "../icons/PauseIcon";
import { NAVBAR_COLOR } from "@app/styles/styles";
import PlayIcon from "../icons/PlayIcon";
import ResetIcon from "../icons/ResetIcon";
import { EXECUTION_STATE, ScriptStatus } from "@ea/shared_types";
import { RunnerMode } from "@ea/shared_types/types";
import { ROUTES } from "@app/routes";
import history from "../../../utils/history";
import "../RunnerControlPanel.css";
import { PlayModes, PlayMode } from "@ea/shared_types/newRunner.types";
import {
  DownOutlined,
  CheckOutlined,
  StepForwardOutlined,
  FastForwardOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { bindActionCreators, Dispatch } from "redux";
import { getRunnerTableActions } from "../runner.actions";
import { disableUserEvents } from "@app/utils/events";
import RenderStepLabel from "@app/modules/steps/components/RenderStepLabel";
import { StepLabelContainer } from "../RunnerComponentLayout";
import ScriptTooltip from "../ScriptTooltip";
import delay from "@ea/shared_components/utils/delay";
import { ApplicationState } from "@app/modules/app.reducers";

interface PlayerControlPanelProps {
  sessionId: string;
  script: Script;
  project: Project;
  onReset: () => void;
  onPause: () => void;
  onPlay: (mode: PlayMode | undefined) => void;
  onModeToggle: () => void;
  mode: RunnerMode;
  minimalistic: boolean;
}

interface PlayerControlPanelState {
  isPausing: boolean;
}

const ControlPanel = styled.div({
  display: "flex",
  height: "40px",
  backgroundColor: NAVBAR_COLOR,
  alignItems: "center",
  justifyContent: "space-between",
  paddingRight: "10px",
});

const TooltipBody = styled.div({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  color: "white",
  maxWidth: "250px",
  cursor: "pointer",
  marginLeft: "5px",
});

const RunnerModeContainer = styled.span(
  {
    marginLeft: "10px",
    display: "flex",
    minWidth: "165px",
  },
  ({ disabled }: { disabled: boolean }) => ({
    color: disabled ? "hsla(0,0%,100%,.65)" : "white",
    cursor: disabled ? "not-allowed" : "pointer",
  }),
);

const controlBarIconStyle = {
  width: "42px",
  color: "white",
  fontSize: "24px",
};

const PAUSING_DELAY_TIME = 1200;

class PlayerControlPanel extends React.Component<
  PlayerControlPanelProps & IConnectProps,
  PlayerControlPanelState
> {
  controlPanelRef;
  userEventsHandler;

  constructor(props) {
    super(props);
    this.controlPanelRef = React.createRef();
    this.state = {
      isPausing: false,
    };
  }

  componentDidUpdate() {
    if (!this.userEventsHandler) {
      this.userEventsHandler = disableUserEvents(this.controlPanelRef?.current, ["mousedown"]);
    }
  }

  componentWillUnmount() {
    this.userEventsHandler?.();
  }

  onPause = async () => {
    this.props.onPause();

    this.setState({
      isPausing: true,
    });

    await delay(PAUSING_DELAY_TIME);

    this.setState({
      isPausing: false,
    });
  };

  getPlayIconType = () => {
    const { playMode } = this.props;

    switch (playMode?.mode) {
      case PlayModes.STEP_BY_STEP:
        return <StepForwardOutlined onClick={this.onPlay} style={controlBarIconStyle} />;
      case PlayModes.UNTIL:
        return (
          <FastForwardOutlined
            onClick={this.onPlay}
            style={{ ...controlBarIconStyle, fontSize: "30px" }}
          />
        );
      default:
        return <PlayIcon onClick={this.onPlay} style={controlBarIconStyle} />;
    }
  };

  getPlayIcon = (playerState: EXECUTION_STATE) => {
    switch (playerState) {
      case EXECUTION_STATE.RUNNING:
        return <PauseIcon onClick={this.onPause} style={controlBarIconStyle} />;
      case EXECUTION_STATE.PAUSED: {
        if (this.state.isPausing) {
          return <EllipsisOutlined style={controlBarIconStyle} />;
        }
        return this.getPlayIconType();
      }
      default:
        return this.getPlayIconType();
    }
  };

  onPlay = () => {
    const { playMode, selectedItems, onPlay, selectedItem, recordingPath } = this.props;

    const fromExecutionId =
      selectedItems.length === 1 ? selectedItems[0].execution.path : undefined;

    if (playMode.mode === PlayModes.NORMAL) {
      onPlay({ mode: playMode.mode, fromExecutionId });
    }

    if (playMode.mode === PlayModes.STEP_BY_STEP) {
      onPlay({
        mode: playMode.mode,
        executionId: selectedItem?.execution.path,
      });
    }

    if (playMode.mode === PlayModes.UNTIL) {
      onPlay({ mode: playMode.mode, executionId: recordingPath, fromExecutionId });
    }
  };

  handleMenuClick = ({ key }) => {
    this.props.actions.setRunnerParams({
      playMode: {
        mode: key,
      },
    });
  };

  menu = () => {
    const { playMode } = this.props;

    if (!playMode) {
      return <div />;
    }

    return (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key={PlayModes.NORMAL}>
          <FormattedMessage id={getTranslationKey("runner", "playMode", "normal")} />{" "}
          {playMode.mode === PlayModes.NORMAL && <CheckOutlined />}
        </Menu.Item>
        <Menu.Item key={PlayModes.STEP_BY_STEP}>
          <FormattedMessage id={getTranslationKey("runner", "playMode", "stepByStep")} />{" "}
          {playMode.mode === PlayModes.STEP_BY_STEP && <CheckOutlined />}
        </Menu.Item>
        <Menu.Item key={PlayModes.UNTIL}>
          <FormattedMessage id={getTranslationKey("runner", "playMode", "until")} />{" "}
          {playMode.mode === PlayModes.UNTIL && <CheckOutlined />}
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    const {
      isSyncing,
      isNotSynced,
      onModeToggle,
      playerState,
      mode,
      onReset,
      script,
      project,
      minimalistic,
      currentPlayingStep,
    } = this.props;
    return (
      <ControlPanel ref={this.controlPanelRef}>
        {!minimalistic && script.status !== ScriptStatus.PUBLISHED && (
          <RunnerModeContainer className="runner-mode-container" disabled={isSyncing}>
            <Radio.Group
              options={[
                {
                  label: <FormattedMessage id={getTranslationKey("runner", "mode", "player")} />,
                  value: RunnerMode.PLAYER,
                },
                {
                  label: <FormattedMessage id={getTranslationKey("runner", "mode", "recorder")} />,
                  value: RunnerMode.RECORDER,
                },
              ]}
              disabled={isSyncing || isNotSynced || playerState === EXECUTION_STATE.RUNNING}
              onChange={isSyncing || isNotSynced ? () => {} : onModeToggle}
              value={mode}
              optionType="button"
              buttonStyle="solid"
            />
          </RunnerModeContainer>
        )}
        {!minimalistic ? (
          <Tooltip placement="topLeft" title={<ScriptTooltip script={script} project={project} />}>
            <TooltipBody
              onClick={() =>
                history.push({
                  pathname: `${ROUTES.scripts}/${script.id}`,
                })
              }
            >
              [{script.id}] {script.name}
            </TooltipBody>
          </Tooltip>
        ) : (
          <RenderStepLabel step={currentPlayingStep}>
            {(text) => (
              <StepLabelContainer>
                {currentPlayingStep ? `${currentPlayingStep.lineNum}.` : ""} {text}
              </StepLabelContainer>
            )}
          </RenderStepLabel>
        )}

        <ResetIcon onClick={onReset} />

        {this.getPlayIcon(playerState)}

        <Dropdown overlay={this.menu()}>
          <Button ghost>
            <DownOutlined />
          </Button>
        </Dropdown>
      </ControlPanel>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: PlayerControlPanelProps) => ({
  actions: {
    ...bindActionCreators(getRunnerTableActions(props.sessionId), dispatch),
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: PlayerControlPanelProps) => ({
    playerState: getPlayerState(state, props.sessionId),
    isSyncing: isSyncingSelector(state, props.sessionId),
    isNotSynced: isSomethingNotSynced(state, props.sessionId),
    recordingPath: getRecordingPath(state, props.sessionId),
    selectedItem: runnerDataSelectors.getSelectedItemSelector(state, props.sessionId),
    selectedItems: runnerDataSelectors.getSelectedItemsSelector(state, props.sessionId),
    playMode: getPlayMode(state, props.sessionId),
    currentPlayingStep: getCurrentPlayingStep(state, props.sessionId),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(PlayerControlPanel);
