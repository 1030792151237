import { getTranslationKey } from "@app/translations/translations.helpers";
import Select from "antd/lib/select";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";

import { RepeatEveryField } from "@app/modules/scheduler/components/RepeatEveryField";
import { DataTestIds } from "@app/utils/dataTestIds";
import { DatePickerField } from "@ea/shared_components/Form/Fields/DatePickerField";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import { InputNumberField } from "@ea/shared_components/Form/Fields/InputNumberField";
import { PickerField } from "@ea/shared_components/Form/Fields/PickerField";
import { RadioField, RadioType } from "@ea/shared_components/Form/Fields/RadioField";
import { SwitchField } from "@ea/shared_components/Form/Fields/SwitchField";
import { EndType, JOB_TRIGGER_TYPE } from "@ea/shared_types/types";
import { getIn } from "final-form";

interface ICloneSchedulerJobFormProps {
  readOnly?: boolean;
  values: any;
  change: (name: string, value: any) => void;
}

interface ICloneSchedulerJobFormState {}

type Props = ICloneSchedulerJobFormProps & InjectedIntlProps;

class CloneSchedulerJobForm extends React.Component<Props, ICloneSchedulerJobFormState> {
  triggerTypes;

  constructor(props: Props) {
    super(props);

    this.triggerTypes = [
      {
        text: getTranslationKey("scheduler", "settings", JOB_TRIGGER_TYPE.TIME),
        value: JOB_TRIGGER_TYPE.TIME,
      },
      {
        text: getTranslationKey("scheduler", "settings", JOB_TRIGGER_TYPE.STORAGE),
        value: JOB_TRIGGER_TYPE.STORAGE,
      },
    ];
  }

  disabledEndDate = (endValue) => {
    const { values } = this.props;
    const startValue = getIn(values, "startDateTime");

    if (!endValue || !startValue) {
      return true;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onStartChange = (startValue) => {
    const { change, values } = this.props;
    const endValue = getIn(values, "endDateTime");

    if (endValue && startValue.valueOf() >= endValue.valueOf()) {
      change("endDateTime", undefined);
    }
  };

  renderEndingComponent = () => {
    const { values } = this.props;
    const endType = getIn(values, "endType");

    switch (endType) {
      case EndType.afterOccurrences:
        return (
          <InputNumberField
            name="occurrences"
            required
            min={1}
            label={getTranslationKey("scheduler", "settings", "occurrences")}
            data-testid={DataTestIds.FORM_INPUT_OCCURRENCES}
          />
        );
      case EndType.onDate:
        return (
          <DatePickerField
            required
            name="endDateTime"
            data-testid={DataTestIds.FORM_DATEPICKER_END_DATE_TIME}
            disabledDate={this.disabledEndDate}
            label={getTranslationKey("scheduler", "settings", "endDateTime")}
            showTime={{ format: "HH:mm" }}
            timeFormat="YYYY-MM-DD HH:mm"
          />
        );
      default:
        return null;
    }
  };

  renderTriggerSpecificElements = () => {
    const { readOnly, values, change } = this.props;
    const triggerType = getIn(values, "triggerType");
    const recurring = getIn(values, "recurring");

    switch (triggerType) {
      case JOB_TRIGGER_TYPE.TIME:
        return (
          <>
            <SwitchField
              name="recurring"
              data-testid={DataTestIds.FORM_SWITCH_RECURRING}
              label={getTranslationKey("scheduler", "settings", "recurring")}
            />
            {recurring && (
              <>
                <RepeatEveryField
                  name="repeatsEvery"
                  data-testid={DataTestIds.FORM_INPUT_REPEAT_EVERY}
                  readOnly={readOnly}
                  min={1}
                  required
                  label={getTranslationKey("scheduler", "settings", "repeatsEvery")}
                />
                <RadioField
                  name="endType"
                  data-testid={DataTestIds.FORM_RADIO_END_TYPE}
                  label={getTranslationKey("scheduler", "settings", "endType")}
                  type={RadioType.Normal}
                  required
                  onChange={(e) => {
                    if (e.target.value === EndType.never) {
                      change("endDateTime", undefined);
                      change("occurrences", undefined);
                    }
                    if (e.target.value === EndType.onDate) {
                      change("occurrences", undefined);
                    }
                  }}
                  options={[
                    {
                      text: getTranslationKey("scheduler", "settings", EndType.never),
                      value: EndType.never,
                    },
                    {
                      text: getTranslationKey("scheduler", "settings", EndType.afterOccurrences),
                      value: EndType.afterOccurrences,
                    },
                    {
                      text: getTranslationKey("scheduler", "settings", EndType.onDate),
                      value: EndType.onDate,
                    },
                  ]}
                />
                {this.renderEndingComponent()}
              </>
            )}
          </>
        );
      case JOB_TRIGGER_TYPE.STORAGE:
        return (
          <PickerField
            name="storage"
            data-testid={DataTestIds.FORM_SELECT_STORAGE}
            label={getTranslationKey("scheduler", "settings", "storage")}
            options={[]}
            required
          />
        );
      default:
        return null;
    }
  };

  customRender = (option: any): JSX.Element => {
    return (
      <Select.Option value={option.value} key={option.value}>
        <span> {option.text}</span>
      </Select.Option>
    );
  };

  render() {
    const { intl, values } = this.props;

    const mode = getIn(values, "mode");

    return (
      <>
        <InputField
          name="name"
          data-testid={DataTestIds.FORM_INPUT_NAME}
          required
          placeholder={getTranslationKey("scheduler", "label", "jobName")}
          label={getTranslationKey("common", "label", "name")}
        />
        {/* {mode !== SchedulerMode.GANTT && (
          <>
            <DatePickerField
              label={getTranslationKey("scheduler", "settings", "startDateTime")}
              placeholder={getTranslationKey("scheduler", "placeholder", "startDate")}
              name="startDateTime"
              showTime={{ format: "HH:mm" }}
              timeFormat="YYYY-MM-DD HH:mm"
              disabledDate={isDateInPast}
              disabledTime={getDisabledTimeRanges}
              onChange={this.onStartChange}
              data-testid={DataTestIds.FORM_DATEPICKER_START_DATE_TIME}
              required
            />
            <SelectField
              name="triggerType"
              placeholder={getTranslationKey("scheduler", "placeholder", "triggerType")}
              label={getTranslationKey("scheduler", "label", "triggerType")}
              data-testid={DataTestIds.FORM_SELECT_TRIGGER_TYPE}
              options={this.triggerTypes}
            />
            {this.renderTriggerSpecificElements()}
          </>
        )} */}
      </>
    );
  }
}

export default injectIntl(CloneSchedulerJobForm);
