import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { Project } from "@ea/shared_types/types";
import { PROJECTS_TABLES_CONFIG } from "./projects.tables";

const actionCreator = actionCreatorFactory("PROJECT_MODULE");

export const moduleActions = createDataModuleActions<Project>(actionCreator);
const getProjectsTableActions = createTableActionsGetter(normalizeActions(moduleActions.table));

export const projectsActionCreators = {
  ...moduleActions,
  refreshParentBranch: actionCreator.async<number, Project[], any>("REFRESH_PARENT_BRANCH"),
  refreshChildBranch: actionCreator.async<number, Project[], any>("REFRESH_CHILD_BRANCH"),
};

export const projectActions = {
  ...normalizeActions(moduleActions.data),
  ...getProjectsTableActions(PROJECTS_TABLES_CONFIG.TREE.id()),
  refreshParentBranch: projectsActionCreators.refreshParentBranch.started,
  refreshChildBranch: projectsActionCreators.refreshChildBranch.started,
};
