import * as React from "react";
import { CheckboxField } from "@ea/shared_components/Form/Fields/CheckboxField";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { ScriptsCsvAdditionalFields } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";

interface IScriptsCsvAdditionalFieldFormProps {
  readOnly?: boolean;
  values: any;
  change: (name: string, value: any) => void;
  displayRunnerOptions?: boolean;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 15 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
};

export const ScriptsCsvAdditionalFieldForm: React.FunctionComponent<
  IScriptsCsvAdditionalFieldFormProps
> = ({ change, values, readOnly }) => (
  <FormLayout {...formItemLayout}>
    {Object.values(ScriptsCsvAdditionalFields).map((v) => (
      <CheckboxField
        key={v}
        name={ScriptsCsvAdditionalFields[v]}
        label={getTranslationKey("scripts", "label", v)}
        readOnly={readOnly}
        defaultValue={true}
      />
    ))}
  </FormLayout>
);
