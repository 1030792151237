import * as React from "react";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import FormItemWrapper from "@ea/shared_components/Form/FormItem/FormItemWrapper";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { Row, Col, Input } from "antd";
import { getIn } from "final-form";
import { System } from "@ea/shared_types/types";
import { SwitchField } from "@ea/shared_components/";
import SystemSelectField from "@app/modules/systemDictionary/components/SystemSelectField";
import { DataTestIds } from "@app/utils/dataTestIds";

export const EnvironmentField: React.FunctionComponent<{
  readOnly?: boolean;
  systems: System[];
  prefix?: string;
  values: any;
}> = ({ readOnly, prefix, systems, values }) => {
  const getName = (name) => (prefix ? `${prefix}.${name}` : name);

  const systemId = getIn(values, getName("defaultSystemId"));
  const isDefaultSystem = getIn(values, getName("defaultSystem"));
  const defaultSysName = systems.find((sys) => sys.id === systemId)?.envName;

  return (
    <FormItemWrapper
      formItem={{
        formItemRowStyle: { marginBottom: 0 },
        label: (
          <FormattedMessage id={getTranslationKey("common", "label", "environment")} /> // <FormattedMessage id={getTranslationKey("common", "label", "defaultVirtualUser")} />
        ) as any,
      }}
    >
      {() => (
        <FormLayout wrapperCol={{ span: 24 }} readOnly={readOnly}>
          <Row>
            <Col flex="80px">
              <SwitchField
                name={getName("defaultSystem")}
                data-testid={DataTestIds.FORM_SWITCH_DEFAULT_SYSTEM}
                checkedChildren={<FormattedMessage id={getTranslationKey("button", "custom")} />}
                unCheckedChildren={<FormattedMessage id={getTranslationKey("button", "default")} />}
                format={(v) => !v}
                parse={(v) => !v}
              />
            </Col>
            <Col flex="auto">
              {isDefaultSystem ? (
                <Input
                  disabled
                  data-testid={DataTestIds.FORM_INPUT_SYSTEM}
                  value={defaultSysName}
                  name="defaultSysName"
                  style={{ marginBottom: "10px", marginLeft: "5px" }}
                />
              ) : (
                <SystemSelectField
                  name={getName("environmentId")}
                  data-testid={DataTestIds.FORM_SELECT_SYSTEM}
                  placeholder={getTranslationKey("common", "placeholder", "environment")}
                  disabled={isDefaultSystem}
                  required={!isDefaultSystem}
                  systems={systems}
                  showSearch
                />
              )}
            </Col>
          </Row>
        </FormLayout>
      )}
    </FormItemWrapper>
  );
};
