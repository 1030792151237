import { API } from "@app/services/api/api";
import { GetProjectsRequest } from "@app/services/api/api.models";
import { getTranslationKey } from "@app/translations/translations.helpers";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import { SelectField } from "@ea/shared_components/Form/Fields/SelectField";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import { IssueTrackingTool } from "@ea/shared_types";
import { IssueTrackingToolConfigurationState } from "@ea/shared_types/types";
import { getIn } from "final-form";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

interface CreateEditItsConfigurationFormState {
  projectOptions: OptionType[];
  loading: boolean;
}

interface CreateEditItsConfigurationFormProps {
  readOnly?: boolean;
  values: any;
  isEdit?: boolean;
  its: IssueTrackingTool;
  change: (name: string, value: any) => void;
}

class CreateEditItsConfigurationForm extends React.Component<
  CreateEditItsConfigurationFormProps,
  CreateEditItsConfigurationFormState
> {
  state = {
    projectOptions: [],
    loading: false,
  };

  reloadProjects = async (projectRequestPrams: GetProjectsRequest) => {
    const { change } = this.props;

    if (!projectRequestPrams) {
      return;
    }

    try {
      this.setState({ loading: true });

      const projects = await API.getItsProjects(projectRequestPrams);
      this.setState({
        projectOptions: projects.map((i) => ({ text: i.name, value: i.name })),
      });
    } catch (error) {
      change("project", undefined);
      toast.error(<FormattedMessage id={getTranslationKey("its", "cannotFetchProjects")} />);
      this.setState({ loading: false });
      return;
    }
    this.setState({ loading: false });
  };

  componentDidMount() {
    this.reloadProjects(this.props.its);
  }

  stateOptions = () => [
    {
      value: IssueTrackingToolConfigurationState.active,
      text: getTranslationKey("its", "label", "active"),
    },
    {
      value: IssueTrackingToolConfigurationState.inactive,
      text: getTranslationKey("its", "label", "inactive"),
    },
  ];

  render() {
    const { readOnly, isEdit, values } = this.props;
    const { loading, projectOptions } = this.state;

    const state = getIn(values, "state");
    return (
      <>
        <SelectField
          name="integrationMetadata.azureDevops.project"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("common", "placeholder", "project")}
          label={getTranslationKey("common", "label", "project")}
          options={projectOptions}
          disabled={projectOptions.length === 0 || isEdit}
          loading={loading}
        />
        <InputField
          name="name"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("common", "placeholder", "name")}
          label={getTranslationKey("common", "label", "name")}
        />
        <InputField
          name="description"
          defaultValue={""}
          readOnly={readOnly}
          placeholder={getTranslationKey("common", "placeholder", "description")}
          label={getTranslationKey("common", "label", "description")}
        />
        {state !== IssueTrackingToolConfigurationState.removed && (
          <SelectField
            name="state"
            placeholder={getTranslationKey("common", "label", "state")}
            label={getTranslationKey("common", "placeholder", "state")}
            options={this.stateOptions()}
            required
            readOnly={readOnly}
            defaultValue={IssueTrackingToolConfigurationState.active}
          />
        )}
      </>
    );
  }
}

export default CreateEditItsConfigurationForm;
