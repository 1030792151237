import * as React from "react";

import BreadcrumbContainer from "@ea/shared_components/common/Breadcrumb.container";
import { BreadcrumbSection, MainSection, Page } from "@ea/shared_components/common/LayoutElements";
import ScriptsTableContainer from "@app/modules/scripts/ScriptsTable.container";

interface IScriptsPageProps {}

const ScriptsPage: React.FunctionComponent<IScriptsPageProps> = (props) => {
  return (
    <Page>
      <BreadcrumbSection>
        <BreadcrumbContainer />
      </BreadcrumbSection>

      <MainSection>
        <ScriptsTableContainer />
      </MainSection>
    </Page>
  );
};

export default ScriptsPage;
