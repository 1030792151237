import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { Tag } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("TAG_MODULE");

export const moduleActions = createDataModuleActions<Tag>(actionCreator);
export const getTagTableActions = createTableActionsGetter(normalizeActions(moduleActions.table));
export const tagActions = {
  ...normalizeActions(moduleActions.data),
};
export const tagActionCreators = {
  ...moduleActions,
};
