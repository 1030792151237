import { ApplicationState } from "@app/modules/app.reducers";
import {
  getScriptGlobalConstants,
  getScriptGlobalMutables,
  scriptsDataSelectors,
} from "@app/modules/scripts/scripts.selectors";
import { Info } from "@ea/shared_components/common/LayoutElements";
import styled from "@emotion/styled";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import StepDetailsForm from "@app/modules/steps/components/Forms/StepDetailsForm";
import {
  getSelectedStepSelector,
  getSelectedStepsSelector,
  getStepsSelector,
} from "@app/modules/steps/steps.selectors";
import { STEPS_TABLE_ID } from "@app/modules/steps/steps.table";
import { getStepCommand } from "@app/packs/packs.helpers";
import { API } from "@app/services/api/api";
import { isScriptReadonly } from "@app/utils/script";
import EditeableAreaFinal from "@ea/shared_components/EditeableArea/EditeableAreaFinal";
import { checkAttribute } from "@ea/shared_components/utils/dom";
import { CoreCommandsIds } from "@ea/shared_types/core.commands.types";
import { Step, System, VirtualUser } from "@ea/shared_types/types";
import { RouteComponentProps, withRouter } from "react-router";
import i18next from "../../translations/backendTranslations";
import { codeTemplatesDataSelectors } from "../codeTemplates/codeTemplates.selectors";
import { CODE_TEMPLATES_TABLES_CONFIG } from "../codeTemplates/codeTemplates.table";
import { projectSelectors } from "../projects/projects.selectors";
import { scriptsActions } from "../scripts/scripts.actions";
import { variableActions, VARIABLES_SECTIONS } from "../variables/variables.actions";
import { detectAndCreateNewVariables } from "../variables/variables.helpers";
import {
  getVariablesGroupsSelector,
  variableDataSelectors,
} from "../variables/variables.selectors";
import { stepsActions, stepsTableActions } from "./steps.actions";
import { getStepLabelParams } from "./steps.utils";

interface IStepDetailsContainerProps {
  scriptId: number;
  systems: System[];
  virtualUsers: VirtualUser[];
  system?: System;
  virtualUser?: VirtualUser;
}

interface IStepDetailsContainerState {
  formValues: any;
}

const Container = styled.div({
  padding: "5px",
  marginBottom: "20px",
});
class StepDetailsContainer extends React.Component<
  IStepDetailsContainerProps & IConnectProps & InjectedIntlProps,
  IStepDetailsContainerState
> {
  editableAreaContainer: any;

  state: IStepDetailsContainerState = {
    formValues: undefined,
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps: IStepDetailsContainerProps & IConnectProps) {
    if (
      this.props.selectedStep === undefined ||
      prevProps.selectedStep === undefined ||
      (this.props.selectedStep !== undefined &&
        prevProps.selectedStep !== undefined &&
        (this.props.selectedStep.id !== prevProps.selectedStep.id ||
          this.props.selectedStep.updatedAt !== prevProps.selectedStep.updatedAt))
    ) {
      const isFormSubmitting =
        this.editableAreaContainer &&
        this.editableAreaContainer.props &&
        this.editableAreaContainer.props.form &&
        this.editableAreaContainer.props.form.submitting;
      if (
        this.editableAreaContainer &&
        this.editableAreaContainer.state.isEditing &&
        !isFormSubmitting
      ) {
        this.editableAreaContainer.cancel();
      }

      this.load();
    }
  }

  openLinked = () => {
    this.props.history.push(`/scripts/${this.props.linkedScript!.id}`);
  };

  load = () => {
    if (this.props.selectedStep) {
      if (this.props.selectedStep.commandId === CoreCommandsIds.script) {
        this.props.actions.script.loadSingle({ id: this.props.selectedStep.linkedScriptId! });
      }
    }
  };

  onFocus = () => {
    if (
      this.editableAreaContainer === undefined ||
      (this.editableAreaContainer && !this.editableAreaContainer.state.isEditing)
    ) {
      this.reload();
    }
  };

  reload = () => {
    const { selectedScript } = this.props;
    if (selectedScript) {
      this.props.actions.load({ reload: true, clearPrevious: true });
    }
  };

  prettifyPickValue = (value) =>
    value
      .slice(3, value.length - 2)
      .split(".")
      .join(" / ");

  changeLabel = (step) => {
    const command = getStepCommand(step);
    if (command.getLabel) {
      return command.getLabel(step).label;
    }

    return step.label;
  };

  onSave = async (values: Step) => {
    const { actions, scriptId } = this.props;
    const editedStep = values;

    const anyCreated = await detectAndCreateNewVariables(
      values.value,
      values.commandId,
      scriptId,
      editedStep.platform.id,
    );

    if (anyCreated) {
      this.props.actions.variable.loadLocal(scriptId);
    }

    const response = await API.editStep(editedStep as any); // todo: NEW_TYPES

    actions.editDone({
      params: response,
      result: response,
    });
  };

  guard = (evt) =>
    checkAttribute(evt.target, "role", "gridcell") || checkAttribute(evt.target, "role", "row");

  render() {
    const {
      selectedStep,
      basicVariables,
      selectedScript,
      steps,
      selected,
      linkedScript,
      variablesGroups,
      intl,
      project,
      virtualUsers,
      systems,
      system,
      virtualUser,
      globalMutables,
      globalConstants,
      codeTemplates,
    } = this.props;
    if (selected.length > 1) {
      return <Info>{intl.formatMessage({ id: "messages.info.moreThanOneStep" })}</Info>;
    }
    if (!selectedStep) {
      return <Info>{intl.formatMessage({ id: "messages.info.noStepSelected" })}</Info>;
    }

    const labelParams = getStepLabelParams(selectedStep);

    return (
      <Container key={selectedStep.id}>
        <EditeableAreaFinal
          setRef={(element) => (this.editableAreaContainer = element)}
          guard={this.guard}
          onSave={this.onSave}
          disableEditing={isScriptReadonly(selectedScript)}
          initialValues={{
            ...selectedStep,
            labelParams,
            label: selectedStep.label || i18next.t(selectedStep.labelKey!, labelParams),
            value: selectedStep.value,
          }}
          render={(props) => (
            <div key={selectedStep.id}>
              <StepDetailsForm
                {...props}
                script={selectedScript}
                variables={basicVariables}
                steps={steps}
                variablesGroups={variablesGroups}
                systems={systems}
                system={system}
                virtualUser={virtualUser}
                virtualUsers={virtualUsers}
                linkedScript={linkedScript}
                openLinked={this.openLinked}
                globalMutables={globalMutables}
                globalConstants={globalConstants}
                codeTemplates={codeTemplates}
              />
            </div>
          )}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(stepsActions, dispatch),
    ...bindActionCreators(stepsTableActions(STEPS_TABLE_ID), dispatch),
    variable: {
      ...bindActionCreators(variableActions, dispatch),
    },
    script: {
      ...bindActionCreators(scriptsActions, dispatch),
    },
  },
});

const connectCreator = connect((state: ApplicationState, props: IStepDetailsContainerProps) => {
  const selectedStep = getSelectedStepSelector(state, STEPS_TABLE_ID);
  const selectedScript = scriptsDataSelectors.getItemSelector(state, props.scriptId);
  return {
    ...props,
    ...state.steps,
    selectedScript,
    selectedStep,
    selected: getSelectedStepsSelector(state, STEPS_TABLE_ID),
    steps: getStepsSelector(state, STEPS_TABLE_ID),
    linkedScript:
      selectedStep && selectedStep.commandId === CoreCommandsIds.script
        ? scriptsDataSelectors.getItemSelector(state, selectedStep.linkedScriptId!)
        : undefined,
    basicVariables: variableDataSelectors.getOrderedDataSelector(state, VARIABLES_SECTIONS.LOCAL),
    variablesGroups: getVariablesGroupsSelector(state, props.scriptId),
    project: projectSelectors.getItemSelector(state, selectedScript.projectId),
    globalMutables: getScriptGlobalMutables(state, props.scriptId),
    globalConstants: getScriptGlobalConstants(state, props.scriptId),
    codeTemplates: codeTemplatesDataSelectors.getOrderedDataSelector(
      state,
      CODE_TEMPLATES_TABLES_CONFIG.MAIN.id(),
    ),
  };
}, mapDispatchToProps);

type IConnectProps = RouteComponentProps<any> & ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(injectIntl(StepDetailsContainer)));
