import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { moduleActions } from "./auditTrail.actions";
import { AuditTrail } from "@ea/shared_types/types";

const initialState = {
  ...getDataReducerInitialState<AuditTrail>(),
};

export type AuditTrailReducer = typeof initialState;

const dataReducer = createDataReducer<AuditTrail>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
