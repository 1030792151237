import * as React from "react";
import styled from "@emotion/styled";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { ITS_RULES_TABLES_CONFIG, ITS_RULES_COLUMNS } from "./itsRules.table";
import { itsRulesDataSelectors } from "./itsRules.selectors";
import { getItsRulesTableActions, itsRulesActions } from "./itsRules.actions";
import { itsDataSelectors } from "../issueTrackingTool/its.selectors";
import { ITS_TABLES_CONFIG } from "../issueTrackingTool/its.table";
import CreateEditItsRuleContainer from "./CreateEditItsRule.container";
import { getIstTableActions } from "../issueTrackingTool/its.actions";
import ItsRuleCommandBar from "./components/ItsRuleCommandBar";
import { projectActions } from "../projects/projects.actions";

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

interface IItsRuleTableProps {}

interface IItsRuleTableState {
  createWindowVisibility: boolean;
  editWindowVisibility: boolean;
}

class ItsRuleTableContainer extends React.Component<
  IItsRuleTableProps & IConnectProps,
  IItsRuleTableState
> {
  connectedTable: any;

  state = {
    createWindowVisibility: false,
    editWindowVisibility: false,
  };

  componentDidMount() {
    this.props.actions.its.load({});
  }

  openEditWindow = () => {
    this.setState({
      editWindowVisibility: true,
    });
  };

  closeEditWindow = () => {
    if (this.props.selectedItsRule) {
      this.props.actions.loadSingle({ id: this.props.selectedItsRule.id });
    }
    this.setState({
      editWindowVisibility: false,
    });
  };

  openCreateWindow = () => {
    this.setState({
      createWindowVisibility: true,
    });
  };

  closeCreateWindow = () => {
    this.setState({
      createWindowVisibility: false,
    });
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
  };

  render() {
    return (
      <Container>
        <ItsRuleCommandBar
          onNewClick={this.openCreateWindow}
          onRemoveClick={this.remove}
          selected={this.props.selected}
          onEditClick={this.openEditWindow}
          onReload={this.reload}
        />
        <CreateEditItsRuleContainer
          visibility={this.state.createWindowVisibility || this.state.editWindowVisibility}
          onClose={this.state.editWindowVisibility ? this.closeEditWindow : this.closeCreateWindow}
          onCreate={this.reload}
          selected={this.props.selectedItsRule}
          isEdit={this.state.editWindowVisibility}
          user={this.props.user}
          issueTrackingSystems={this.props.issueTrackingSystems}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={ITS_RULES_COLUMNS}
          tableId={ITS_RULES_TABLES_CONFIG.MAIN.id()}
          preferencesId={ITS_RULES_TABLES_CONFIG.MAIN.preferencesId}
          stateKey={"itsRules"}
          tableActions={getItsRulesTableActions}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(itsRulesActions, dispatch),
    ...bindActionCreators(getItsRulesTableActions(ITS_RULES_TABLES_CONFIG.MAIN.id()), dispatch),
    its: {
      load: bindActionCreators(getIstTableActions(ITS_TABLES_CONFIG.MAIN.id()), dispatch).load,
    },
    projects: {
      ...bindActionCreators(projectActions, dispatch),
    },
  },
});

const connectCreator = connect(
  (state: ApplicationState) => ({
    selected: itsRulesDataSelectors.getSelectedSelector(state, ITS_RULES_TABLES_CONFIG.MAIN.id()),
    selectedItsRule: itsRulesDataSelectors.getSelectedItemSelector(
      state,
      ITS_RULES_TABLES_CONFIG.MAIN.id(),
    ),
    issueTrackingSystems: itsDataSelectors.getOrderedDataSelector(
      state,
      ITS_TABLES_CONFIG.MAIN.id(),
    ),
    user: state.auth.user,
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(ItsRuleTableContainer);
