import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import ProjectsTreeContainer from "@app/modules/projects/ProjectsTree.container";
import RouteTabs from "@app/modules/common/components/RouteTabs";
import styled from "@emotion/styled";
import {
  BreadcrumbSection,
  Info,
  MainSection,
  Page,
} from "@ea/shared_components/common/LayoutElements";
import BreadcrumbContainer from "@ea/shared_components/common/Breadcrumb.container";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { projectActions } from "../projects/projects.actions";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import {
  projectSelectors,
  getProjectsUserGroupsMappingMap,
  getProjectsVirtualUsersMappingMap,
  getProjectsSystemsMappingMap,
  getProjectsChildrenMap,
  getProjectsTreeSelector,
} from "../projects/projects.selectors";
import { PROJECTS_TABLES_CONFIG } from "../projects/projects.tables";
import NonIdealState from "@ea/shared_components/common/NonIdealState";
import { ITS_TABLES_CONFIG } from "../issueTrackingTool/its.table";
import { itsDataSelectors } from "../issueTrackingTool/its.selectors";
import { isCurrentUserDocumentationReader } from "@ea/shared_components/auth/auth.selectors";
import DocumentationProjectTableContainer from "./DocumentationProjectTable.container";
import { ROUTES } from "@app/routes";
import { tryParseInt } from "@app/utils/transformations";

interface IProjectsLoaderContainerProps {}

interface IProjectsLoaderContainerState {
  section: Routes;
}

enum Routes {
  Documentations = "documentations",
}

const PageContainer = styled.div({
  display: "flex",
  flex: 1,
  width: "100%",
});

const TreeContainer = styled.div({
  flex: 0.2,
  height: "100%",
  borderRight: "1px solid #e8e8e8",
});

const InfoContainer = styled.div({
  marginLeft: "15px",
  display: "flex",
  flex: 0.8,
  flexDirection: "column",
});

class DocumentationProjectTreeContainer extends React.Component<
  IConnectProps,
  IProjectsLoaderContainerState
> {
  listener: () => void;

  constructor(props) {
    super(props);

    this.state = {
      section: Routes.Documentations,
    };
  }

  async componentDidMount() {
    this.props.actions.refreshParentBranch(this.props.match.params.id);
  }

  async componentDidUpdate(prevProps: IConnectProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.actions.refreshParentBranch(this.props.match.params.id);
    }
  }

  generateBreadcrumbText = (chunk: string, index: number) => {
    if (index !== 1) {
      return chunk;
    }

    const { selectedProject } = this.props;
    if (!selectedProject) {
      return chunk;
    }

    return `${chunk} (${selectedProject.name})`;
  };

  onSectionChange = (path) => {
    this.setState({
      section: path,
    });
  };

  projectNotFound = (params) => params && params.id && !this.props.projects[params.id];

  render() {
    const { match } = this.props;

    if (this.projectNotFound(match.params)) {
      return (
        <Page>
          <BreadcrumbSection>
            <BreadcrumbContainer transformText={this.generateBreadcrumbText} />
          </BreadcrumbSection>
          <MainSection>
            <NonIdealState
              text={<FormattedMessage id={getTranslationKey("projects", "notFound")} />}
            />
          </MainSection>
        </Page>
      );
    }

    return (
      <>
        <BreadcrumbSection>
          <BreadcrumbContainer transformText={this.generateBreadcrumbText} />
        </BreadcrumbSection>
        <PageContainer>
          <TreeContainer>
            <ProjectsTreeContainer
              rootPath={ROUTES.documentation_tree}
              withActions={false}
              disableCommandBar
              id={tryParseInt(match.params.id)!}
            />
          </TreeContainer>
          <InfoContainer>
            {match.params.id ? (
              <RouteTabs
                onChange={this.onSectionChange}
                urlPart={3}
                defaultActiveKey={""}
                tabs={[
                  {
                    label: getTranslationKey("projects", "tabs", "documentation"),
                    value: "",
                    render: ({ params }) => (
                      <DocumentationProjectTableContainer
                        projectId={tryParseInt(match.params.id)!}
                      />
                    ),
                  },
                ]}
              />
            ) : (
              <Info>
                <FormattedMessage id={getTranslationKey("messages", "info", "selectProject")} />
              </Info>
            )}
          </InfoContainer>
        </PageContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(projectActions, dispatch),
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: IProjectsLoaderContainerProps & RouteComponentProps<any>) => ({
    ...props,
    userGroupMappings: getProjectsUserGroupsMappingMap(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    virtualUsersMappings: getProjectsVirtualUsersMappingMap(
      state,
      PROJECTS_TABLES_CONFIG.TREE.id(),
    ),
    systemsMapping: getProjectsSystemsMappingMap(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    selectedProject: projectSelectors.getItemSelector(state, props.match.params.id),
    projectsChildrenMap: getProjectsChildrenMap(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    projects: state.projects.items,
    user: state.auth.user,
    isDocumentationReader: isCurrentUserDocumentationReader(state),
    projectTree: getProjectsTreeSelector(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    issueTrackingSystems: itsDataSelectors.getOrderedDataSelector(
      state,
      ITS_TABLES_CONFIG.MAIN.id(),
    ),
    globalSettings: state.globalSettings,
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(DocumentationProjectTreeContainer));
