import { Input } from "antd";
import styled from "@emotion/styled";
import * as React from "react";
import { CloseOutlined, CheckOutlined, EditOutlined } from "@ant-design/icons";

const cellIconClassName = `cell-icon-container`;
const Container = styled.div({
  position: "relative",
  display: "inline-block",
  ":hover": {
    [`& .${cellIconClassName}`]: {
      display: "inline-block",
    },
  },
});

const InputWrapper = styled.div({
  paddingRight: "44px",
  display: "inline-block",
});

const TextWrapper = styled.div({
  display: "inline-block",
});

const CheckIcon = styled(CheckOutlined)({
  position: "absolute",
  right: 0,
  width: "20px",
  cursor: "pointer",
  lineHeight: "28px",
  ":hover": {
    color: "#108ee9",
  },
});

const CloseIcon = styled(CloseOutlined)({
  position: "absolute",
  right: 20,
  width: "20px",
  cursor: "pointer",
  lineHeight: "28px",
  ":hover": {
    color: "#108ee9",
  },
});

const EditIcon = styled(EditOutlined)(cellIconClassName, {
  position: "absolute",
  right: 0,
  width: "20px",
  cursor: "pointer",
  lineHeight: "18px",
  display: "none",
  ":hover": {
    display: "inline-block",
  },
});
(EditIcon as any).className = cellIconClassName;

interface EditableCellProps {
  value: string;
  onDone: (value: string) => void;
}

interface EditableCellState {
  value: string;
  editable: boolean;
}

class EditableCell extends React.Component<EditableCellProps, EditableCellState> {
  constructor(props: EditableCellProps) {
    super(props);

    this.state = {
      value: props.value,
      editable: false,
    };
  }

  componentWillReceiveProps(nextProps: EditableCellProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    this.setState({ value });
    event.stopPropagation();
  };

  check = (event) => {
    this.setState({ editable: false });
    this.props.onDone(this.state.value);
    event.stopPropagation();
  };

  edit = (event) => {
    this.setState({ editable: true });
    event.stopPropagation();
  };

  disableEdit = (event) => {
    this.setState({ editable: false, value: this.props.value });
    event.stopPropagation();
  };

  render() {
    const { value, editable } = this.state;
    return (
      <Container>
        {editable ? (
          <InputWrapper>
            <Input
              value={value}
              onChange={this.handleChange}
              onPressEnter={this.check}
              onClick={(e) => e.stopPropagation()}
            />
            <CloseIcon type="close" onClick={this.disableEdit} />
            <CheckIcon type="check" onClick={this.check} />
          </InputWrapper>
        ) : (
          <TextWrapper>
            {value || " "}
            <EditIcon type="edit" onClick={this.edit} />
          </TextWrapper>
        )}
      </Container>
    );
  }
}

export default EditableCell;
