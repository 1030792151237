import { objectValues } from "@ea/shared_components/utils/object";

import aggregatedJobLogsEpicsMap from "./aggregatedJobLogs.epics";
import * as aggregatedJobLogsReducers from "./aggregatedJobLogs.reducers";

const aggregatedJobLogsEpics = objectValues(aggregatedJobLogsEpicsMap);

export { aggregatedJobLogsReducers, aggregatedJobLogsEpics };

export { default as AggregatedJobLogs } from "./AggregatedJobLogs.container";
