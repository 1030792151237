import { InputField } from "@ea/shared_components/Form/Fields/InputField";
import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { FormattedMessage } from "react-intl";
import { TableForm } from "@ea/shared_components";

const pathColumnHeader = (node) => <div style={{ fontWeight: "normal" }}> {node} </div>;
const pathsColumns = [
  {
    title: pathColumnHeader(
      <FormattedMessage id={getTranslationKey("step", "detailsForm", "label", "pathType")} />,
    ),
    dataIndex: "type",
    key: "type",
    width: "20%",
    render: (text, record) => {
      return <InputField name={`${record}.type`} />;
    },
  },
  {
    title: pathColumnHeader(
      <FormattedMessage id={getTranslationKey("step", "detailsForm", "label", "path")} />,
    ),
    dataIndex: "path",
    key: "path",
    width: "80%",
    render: (text, record) => {
      return <InputField required name={`${record}.path`} />;
    },
  },
];

const PathsTableForm = () => <TableForm name="paths" columns={pathsColumns} />;

export default PathsTableForm;
