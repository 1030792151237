import React from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { SourceObject } from "@react-pdf/types";
import { LOGO_RGB } from "@app/styles/styles";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    width: "100%",
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 35,
  },
  view: {
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "white",
  },
  logo: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  title: {
    fontSize: 14,
    textAlign: "center",
    marginTop: 20,
    marginBottom: 10,
  },
  text: {
    fontSize: 10,
    textAlign: "justify",
  },
  chart: {
    objectFit: "cover",
  },
  footer: {
    position: "absolute",
    fontSize: 7,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

interface IPayPerUseBarChartProps {
  text: string;
  title: string;
  chart: SourceObject;
  chartTitle: string;
  footer: string;
}

const PayPerUseStatisticsPDF = ({
  text,
  title,
  chart,
  chartTitle,
  footer,
}: IPayPerUseBarChartProps) => (
  <Document>
    <Page style={styles.page} object-fit="fill" size="A4">
      <View style={styles.view}>
        <Image style={styles.logo} src={LOGO_RGB} />
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.text}>{text}</Text>
        <Text style={styles.title}>{chartTitle}</Text>
        <Image style={styles.chart} source={chart} />
        <Text style={styles.footer} fixed>
          {footer}
        </Text>
      </View>
    </Page>
  </Document>
);

export default PayPerUseStatisticsPDF;
