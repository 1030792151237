import { createSelector } from "reselect";

import { createDataSelectors } from "@app/modules/app.reducers";
import { variableDataSelectors } from "@app/modules/variables/variables.selectors";
import { Script, ScriptStatus, GlobalVariableType } from "@ea/shared_types/types";
import { VARIABLES_SECTIONS } from "../variables/variables.actions";
import { projectSelectors } from "../projects/projects.selectors";
import { isCurrentUserDocumentationReader } from "@ea/shared_components/auth/auth.selectors";

export const scriptsDataSelectors = createDataSelectors<Script>()("scripts");

const defualtProjectName = {
  name: "Unknown project",
};

export const scriptsSelectorsModifier = (scriptsSelectors: any) => {
  scriptsSelectors.getOrderedDataSelector = createSelector(
    scriptsSelectors.getOrderedDataSelector,
    projectSelectors.getDataSelector,
    (scripts: any, projects: any) =>
      scripts.map((s) => ({
        ...s,
        projectName: (projects[s.projectId || -1] || defualtProjectName).name,
      })),
  ) as any;

  return scriptsSelectors;
};

export const areOnlyClosedSelectedSelector = createSelector(
  scriptsDataSelectors.getDataSelector,
  scriptsDataSelectors.getSelectedSelector,
  (items, selected) => {
    const selectedClosed = selected.filter((id) => items[id]?.status === ScriptStatus.CLOSED);
    return selectedClosed.length > 0 && selectedClosed.length === selected.length;
  },
);

export const isAtLeastOneSelectedMappedToTestCase = createSelector(
  scriptsDataSelectors.getDataSelector,
  scriptsDataSelectors.getSelectedSelector,
  (items, selected) => {
    const mapped = selected.some((id) => items[id]?.integrationMetadata?.azureDevops?.testCaseId);
    return mapped;
  },
);

export const isAnyClosedSelectedSelector = createSelector(
  scriptsDataSelectors.getDataSelector,
  scriptsDataSelectors.getSelectedSelector,
  (items, selected) => {
    const selectedClosed = selected.filter((id) => items[id]?.status === ScriptStatus.CLOSED);
    return selectedClosed.length > 0;
  },
);

export const getScriptWithBasicVariablesSelector = createSelector(
  scriptsDataSelectors.getItemSelector,
  (state) => variableDataSelectors.getOrderedDataSelector(state, VARIABLES_SECTIONS.LOCAL),
  (script, variables) => {
    return script
      ? {
          ...script,
          variables: variables.filter(
            (variable) =>
              script && variable.parentType === "TaskScript" && variable.parentId === script.id,
          ),
        }
      : undefined;
  },
);

export const getSelectedFilterClosedSelector = createSelector(
  scriptsDataSelectors.getDataSelector,
  scriptsDataSelectors.getSelectedSelector,
  scriptsDataSelectors.getClosedSelector,
  (items, selected, showClosedElements) =>
    !showClosedElements
      ? selected.filter((id) => items[id]?.status !== ScriptStatus.CLOSED)
      : selected,
);

export const isNotEditableScriptSelector = createSelector(
  getScriptWithBasicVariablesSelector,
  (state) => isCurrentUserDocumentationReader(state),
  (script, isDocumentationReader) => {
    if (isDocumentationReader) {
      return true;
    }
    return script
      ? [ScriptStatus.PUBLISHED, ScriptStatus.CLOSED].includes(script.status)
      : undefined;
  },
);

export const getGlobalVariablesIds = createSelector(
  scriptsDataSelectors.getItemSelector,
  (script) => {
    if (script.globalVariables && script.globalVariables.length > 0) {
      return script.globalVariables.map((gv) => gv.id).sort((a, b) => a - b);
    }
    return [];
  },
);

export const getScriptGlobalConstants = createSelector(
  scriptsDataSelectors.getItemSelector,
  (script) => {
    if (script.globalVariables) {
      return script.globalVariables.filter((gv) => gv.type === GlobalVariableType.CONSTANT);
    }
    return [];
  },
);
export const getScriptGlobalMutables = createSelector(
  scriptsDataSelectors.getItemSelector,
  (script) => {
    if (script.globalVariables) {
      return script.globalVariables.filter((gv) => gv.type === GlobalVariableType.MUTABLE);
    }
    return [];
  },
);
