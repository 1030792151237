import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { guardDataSelectors } from "@app/modules/guards/guards.selectors";
import { GUARDS_TABLES_CONFIG } from "@app/modules/guards/guards.table";
import styled from "@emotion/styled";
import EditeableAreaFinal from "@ea/shared_components/EditeableArea/EditeableAreaFinal";
import { checkAttribute } from "@ea/shared_components/utils/dom";
import ConditionalForm from "@app/modules/guards/components/ConditionalForm";
import { Info } from "@ea/shared_components/common/LayoutElements";
import { getStepsSelector } from "@app/modules/steps/steps.selectors";
import { STEPS_TABLE_ID } from "@app/modules/steps/steps.table";
import { API } from "@app/services/api/api";
import { bindActionCreators, Dispatch } from "redux";
import { guardActions, getGuardsTableActions } from "@app/modules/guards/guards.actions";
import { variableDataSelectors } from "../variables/variables.selectors";
import { VARIABLES_SECTIONS } from "../variables/variables.actions";
import {
  scriptsDataSelectors,
  isNotEditableScriptSelector,
  getScriptGlobalMutables,
  getScriptGlobalConstants,
} from "../scripts/scripts.selectors";
import { kpisDataSelectors } from "../kpis/kpis.selectors";
import { KPIS_TABLES_CONFIG } from "../kpis/kpis.table";

interface IGuardDetailsContainerProps {
  scriptId: number;
}

const Container = styled.div({
  paddingLeft: "5px",
  marginBottom: "20px",
});

interface IGuardDetailsContainerState {
  formValues: any | undefined;
}

class GuardDetailsContainer extends React.Component<
  IGuardDetailsContainerProps & IConnectProps,
  IGuardDetailsContainerState
> {
  editableAreaContainer: any;

  state: IGuardDetailsContainerState = {
    formValues: undefined,
  };

  constructor(props: IGuardDetailsContainerProps & IConnectProps) {
    super(props);

    this.state = {
      formValues: props.selectedGuard,
    };
  }

  guardChanged = (previousGuard) => {
    const { selectedGuard } = this.props;
    if (selectedGuard && previousGuard) {
      return selectedGuard.id !== previousGuard.id;
    }
    if ((previousGuard && !selectedGuard) || (!previousGuard && selectedGuard)) {
      return true;
    }
    return false;
  };
  componentDidUpdate(prevProps: IGuardDetailsContainerProps & IConnectProps) {
    if (this.guardChanged(prevProps.selectedGuard)) {
      if (this.editableAreaContainer && this.editableAreaContainer.state.isEditing) {
        this.editableAreaContainer.cancel();
      }
      this.setState({
        formValues: this.props.selectedGuard,
      });
    }
  }

  onSave = async (values) => {
    await API.editGuard({ ...values, scriptId: this.props.scriptId });
    this.props.actions.commitEdit(values);
  };

  editingGuard = (evt) =>
    checkAttribute(evt.target, "role", "gridcell") || checkAttribute(evt.target, "role", "row");

  render() {
    const {
      selectedGuard,
      selected,
      steps,
      basicVariables,
      script,
      isNotEditable,
      globalMutable,
      globalConstant,
      kpis,
    } = this.props;

    if (selected.length > 1) {
      return <Info>Selected more than one conditional</Info>;
    }
    if (!selectedGuard) {
      return <Info>No conditional selected</Info>;
    }

    return (
      <Container>
        <EditeableAreaFinal
          setRef={(element) => (this.editableAreaContainer = element)}
          guard={this.editingGuard}
          onSave={this.onSave}
          initialValues={this.state.formValues}
          disableEditing={isNotEditable}
          render={(props) => (
            <div key={selectedGuard.id}>
              <ConditionalForm
                {...props}
                steps={steps}
                variables={basicVariables}
                script={script}
                kpis={kpis}
                globalMutable={globalMutable}
                globalConstant={globalConstant}
              />
            </div>
          )}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(guardActions, dispatch),
    ...bindActionCreators(getGuardsTableActions(GUARDS_TABLES_CONFIG.MAIN.id()), dispatch),
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: IGuardDetailsContainerProps) => ({
    selectedGuard: guardDataSelectors.getSelectedItemSelector(
      state,
      GUARDS_TABLES_CONFIG.MAIN.id(),
    ),
    selected: guardDataSelectors.getSelectedItemsSelector(state, GUARDS_TABLES_CONFIG.MAIN.id()),
    steps: getStepsSelector(state, STEPS_TABLE_ID),
    script: scriptsDataSelectors.getItemSelector(state, props.scriptId),
    isNotEditable: isNotEditableScriptSelector(state, props.scriptId),
    basicVariables: variableDataSelectors.getOrderedDataSelector(state, VARIABLES_SECTIONS.LOCAL),
    kpis: kpisDataSelectors.getOrderedDataSelector(state, KPIS_TABLES_CONFIG.MAIN.id()),
    globalMutable: getScriptGlobalMutables(state, props.scriptId),
    globalConstant: getScriptGlobalConstants(state, props.scriptId),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(GuardDetailsContainer);
