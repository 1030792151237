import { createDataSelectors } from "@app/modules/app.reducers";
import { Variable, PlainObject } from "@ea/shared_types/types";
import { getScriptStepsSelector } from "../steps/steps.selectors";
import { createSelector } from "reselect";
import { STEPS_TABLE_ID } from "../steps/steps.table";
import { ApplicationState } from "../app.reducers";
import { VARIABLES_SECTIONS } from "./variables.actions";
import { VariablesGroup } from "@ea/shared_components/redux/common.models";
import { getScriptGlobalMutables, getScriptGlobalConstants } from "../scripts/scripts.selectors";

export const variableDataSelectors = createDataSelectors<Variable>()("variables");

export const getVariablesGroupsSelector = createSelector(
  (state: ApplicationState) => getScriptStepsSelector(state, STEPS_TABLE_ID),
  (state: ApplicationState) =>
    variableDataSelectors.getOrderedDataSelector(state, VARIABLES_SECTIONS.LOCAL),
  (state: ApplicationState) =>
    variableDataSelectors.getOrderedDataSelector(state, VARIABLES_SECTIONS.DATA_SOURCE),
  (state: ApplicationState) =>
    variableDataSelectors.getOrderedDataSelector(state, VARIABLES_SECTIONS.SEQUENCE),
  (state: ApplicationState) =>
    variableDataSelectors.getOrderedDataSelector(state, VARIABLES_SECTIONS.LINKED),
  (state: ApplicationState, scriptId: number) => getScriptGlobalConstants(state, scriptId),
  (state: ApplicationState, scriptId: number) => getScriptGlobalMutables(state, scriptId),
  (
    steps,
    localVariables,
    dataSourceVariables,
    sequenceVariables,
    linkedVariables,
    globalConstants,
    globalMutables,
  ) => {
    const linkedVariablesPerParent: PlainObject<Variable[]> = linkedVariables.reduce(
      (container, linkedVariable) => {
        if (!container[linkedVariable.parentId]) {
          container[linkedVariable.parentId] = [];
        }
        container[linkedVariable.parentId].push(linkedVariable);
        return container;
      },
      {},
    );

    const dataSourceByGroup: PlainObject<Variable[]> = dataSourceVariables.reduce(
      (container, variable) => {
        // todo: after migration below line can be deleted
        if (!variable.groupName) {
          return container;
        }
        if (!container[variable.groupName]) {
          container[variable.groupName] = [];
        }
        container[variable.groupName].push(variable);
        return container;
      },
      {},
    );

    const variableGroups: VariablesGroup[] = [
      { id: "Script", variables: localVariables },
      {
        id: "DataSource",
        groups: Object.keys(dataSourceByGroup).map((key) => ({
          id: key,
          variables: dataSourceByGroup[key],
        })),
      },
      { id: "Sequence", variables: sequenceVariables },
      {
        id: "Scripts",
        groups: steps.map((s) => ({
          id: s.linkName!,
          variables: linkedVariablesPerParent[s.linkedScriptId!] || [],
        })),
      },
      {
        id: "Global",
        groups: [
          { id: "Constant", variables: globalConstants },
          { id: "Variable", variables: globalMutables },
        ],
      },
    ];

    return variableGroups;
  },
);
