import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import StepPanelForm, { PanelStep } from "@ea/shared_components/PanelForm/StepPanelForm";
import { ApplicationState } from "@app/modules/app.reducers";
import { Commands, Script, Step } from "@ea/shared_types/types";
import PickScriptForm from "./Forms/StepScript/PickScriptForm";
import StepScriptForm from "./Forms/StepScript/StepScriptForm";

import { getStepsSelector, getSelectedStepSelector } from "../steps.selectors";
import { STEPS_TABLE_ID } from "../steps.table";

import { API } from "@app/services/api/api";

import { getTranslationKey } from "@app/translations/translations.helpers";
import { DEFAULT_PLATFORM_ID } from "../../../../../runner/loader/build/ea.internal.types";
import { getStepCommand } from "@app/packs/packs.helpers";
import { Project } from "@ea/shared_types/types";

interface IStepScriptPanelProps {
  isOpen: boolean;
  close: () => void;
  afterCreate?: (steps: Step[]) => void;
  scriptId: number;
  scripts: Script[];
  script: Script;
  project: Project;
}

class StepScriptPanel extends React.Component<IStepScriptPanelProps & IConnectProps, any> {
  getLastStep = () => this.props.steps[this.props.steps.length - 1];

  getLineNum = (index) => (this.props.selectedStep?.lineNum || 1) + (index || 1);

  createStep = ({ label, linkName, linkedScriptId }, index): Partial<Step> => {
    const { script, project } = this.props;
    const command = getStepCommand({
      commandId: Commands.script,
      platform: { id: DEFAULT_PLATFORM_ID },
    });

    return {
      commandId: Commands.script,
      taskScriptId: this.props.scriptId,
      label: label,
      linkName: linkName,
      linkedScriptId,
      value: command?.getInitialValue?.({ project, script }),
      lineNum: this.getLineNum(index),
      platform: {
        id: DEFAULT_PLATFORM_ID,
      },
    };
  };
  create = async (values) => {
    const stepScripts = values.linkedScriptIds.map(this.createStep);

    const responses: Step[] = [];
    for (let script of stepScripts) {
      const response = await API.createStep(script);
      responses.push(response);
    }

    if (this.props.afterCreate) {
      this.props.afterCreate(responses);
    }

    this.props.close();
  };

  render() {
    const { isOpen, close, steps, scriptId } = this.props;

    const panelSteps: PanelStep[] = [
      {
        title: getTranslationKey("step", "createStep", "scriptStep", "select"),
        render: (props) => <PickScriptForm {...props} scriptId={scriptId} />,
      },
      {
        title: getTranslationKey("step", "createStep", "scriptStep", "stepDetails"),
        render: (props) => <StepScriptForm {...props} scripts={this.props.scripts} steps={steps} />,
      },
    ];

    return (
      <StepPanelForm
        visibility={isOpen}
        onCancelClick={close}
        onOkClick={this.create}
        headerText={getTranslationKey("step", "createStep", "scriptStep", "header")}
        steps={panelSteps}
        render={() => <> </>}
      />
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState) => ({
    steps: getStepsSelector(state, STEPS_TABLE_ID),
    scripts: state.scripts.items,
    selectedStep: getSelectedStepSelector(state, STEPS_TABLE_ID),
  }),
  {},
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(StepScriptPanel);
