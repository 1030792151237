import * as React from "react";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { Project, Script } from "@ea/shared_types/types";

const ScriptTooltip: React.FunctionComponent<{ script: Script; project: Project }> = ({
  script,
  project,
}) => (
  <>
    <span>
      <b>{<FormattedMessage id={getTranslationKey("table", "id")} />}:</b>
      {script.id}
    </span>
    <br />
    <span>
      <b>{<FormattedMessage id={getTranslationKey("table", "scriptName")} />}:</b> {script.name}
    </span>
    <br />
    <span>
      <b>{<FormattedMessage id={getTranslationKey("projects", "name")} />}:</b> {project.name}
    </span>
  </>
);

export default ScriptTooltip;
