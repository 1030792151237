import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";

import GuardsCommandBar from "@app/modules/guards/components/GuardsCommandBar";
import { GUARDS_COLUMNS, GUARDS_TABLES_CONFIG } from "@app/modules/guards/guards.table";
import { guardActions, getGuardsTableActions } from "@app/modules/guards/guards.actions";
import { guardDataSelectors } from "@app/modules/guards/guards.selectors";
import { isNotEditableScriptSelector } from "@app/modules/scripts/scripts.selectors";
import GuardsNewPanelContainer from "@app/modules/guards/GuardsNewPanel.container";
import { SelectMode } from "@ea/shared_components/redux/common.models";

interface IGuardsTableProps {
  scriptId: number;
}

interface IGuardsTableState {
  createWindowVisibility: boolean;
  persistentQuery: any;
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

class GuardsTableContainer extends React.Component<
  IGuardsTableProps & IConnectProps,
  IGuardsTableState
> {
  connectedTable: any;

  state: IGuardsTableState = {
    createWindowVisibility: false,
    persistentQuery: {},
  };

  componentDidMount() {
    this.setState({
      persistentQuery: {
        scriptId: this.props.scriptId,
      },
    });
  }

  componentWillMount() {
    this.props.actions.select({ ids: [], mode: SelectMode.Replace });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scriptId !== this.props.scriptId) {
      this.setState({
        persistentQuery: {
          scriptId: this.props.scriptId,
        },
      });
    }
  }

  openCreateWindow = () => {
    this.setState({
      createWindowVisibility: true,
    });
  };

  closeCreateWindow = () => {
    this.setState({
      createWindowVisibility: false,
    });
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
  };

  render() {
    return (
      <Container>
        {!this.props.isNotEditable && (
          <GuardsCommandBar
            onNewClick={this.openCreateWindow}
            onRemoveClick={this.remove}
            selected={this.props.selected}
            onReload={this.reload}
          />
        )}
        <GuardsNewPanelContainer
          onOk={this.props.actions.reload}
          onClose={this.closeCreateWindow}
          visibility={this.state.createWindowVisibility}
          scriptId={this.props.scriptId}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          persistentQuery={this.state.persistentQuery}
          columnsConfig={GUARDS_COLUMNS}
          tableId={GUARDS_TABLES_CONFIG.MAIN.id()}
          preferencesId={GUARDS_TABLES_CONFIG.MAIN.preferencesId}
          stateKey="guards"
          tableActions={getGuardsTableActions}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(guardActions, dispatch),
    ...bindActionCreators(getGuardsTableActions(GUARDS_TABLES_CONFIG.MAIN.id()), dispatch),
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: IGuardsTableProps) => ({
    selected: guardDataSelectors.getSelectedSelector(state, GUARDS_TABLES_CONFIG.MAIN.id()),
    isNotEditable: isNotEditableScriptSelector(state, props.scriptId),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(GuardsTableContainer);
