import * as React from "react";

export const formatToastText = (text: string) => {
  const chunks = text.split("\n");
  return (
    <div>
      {chunks.map((t, index) => (
        <div key={index}>
          {`${chunks.length > 1 ? `${index + 1}. ` : ""}${t}`}{" "}
          {index < chunks.length - 1 ? <br /> : null}
        </div>
      ))}
    </div>
  );
};

export const formatNewLineErrorToast = (text: string) => {
  const chunks = text.split("\n");

  return (
    <>
      {chunks.map((t, index) => (
        <div key={index}>
          {t}
          <br />
        </div>
      ))}
    </>
  );
};
