const DEFAULT_LANGUAGE = "en-us";

export const getDefaultLanguage = () =>
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  (navigator as any).userLanguage ||
  DEFAULT_LANGUAGE;

export const flattenTranslations = (nestedTranslations, prefix = "") => {
  return Object.keys(nestedTranslations).reduce((translations, key) => {
    const value = nestedTranslations[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      translations[prefixedKey] = value;
    } else {
      Object.assign(translations, flattenTranslations(value, prefixedKey));
    }

    return translations;
  }, {});
};
