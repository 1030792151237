import { COLORS } from "@ea/shared_components/styles/consts";
import { ApplicationStyles } from "@ea/shared_types/types";
import { ADVANCED_RUNNER_VARIABLES_TABS_AB, ADVANCED_RUNNER_VARIABLES_TABS_EA } from "./consts";

export const style =
  process.env.REACT_APP_STYLE !== undefined
    ? process.env.REACT_APP_STYLE
    : ApplicationStyles.DEFAULT;

const mainColor = () => {
  switch (style) {
    case ApplicationStyles.EPISTA:
      return "#000000";
    default:
      return COLORS.DARK;
  }
};

const mainColorLight = () => {
  switch (style) {
    case ApplicationStyles.EPISTA:
      return COLORS.NAVBAR_COLOR_LIGHTER_AB;
    default:
      return COLORS.NAVBAR_COLOR_LIGHTER;
  }
};

const loginButtonColor = () => {
  switch (style) {
    case ApplicationStyles.EPISTA:
      return COLORS.BOOST_GREEN;
    default:
      return undefined;
  }
};

const tabsClass = () => {
  switch (style) {
    case ApplicationStyles.EPISTA:
      return ADVANCED_RUNNER_VARIABLES_TABS_AB;
    default:
      return ADVANCED_RUNNER_VARIABLES_TABS_EA;
  }
};

export const NAVBAR_COLOR = mainColor();
export const LOGIN_BACKGOUND_COLOR = mainColor();
export const ERROR_PAGE_BACKGOUND_COLOR = mainColor();
export const LOGIN_BUTTON_COLOR = loginButtonColor();
export const ADVANCED_RUNNER_VARIABLES_TABS_CLASS = tabsClass();
export const NAVBAR_COLOR_LIGHT = mainColorLight();

export let ERROR_PAGE_LOGO;
export let LOGIN_PAGE_LOGO;
export let NAVBAR_LOGO;
export let SIGNET_WHITE;
export let NOTIFICATIONS_LOGO;
export let LOGO_RGB;

export async function loadImages() {
  ERROR_PAGE_LOGO = (await import(`../../images/${style}/logo_login_screen.png`)).default;
  LOGIN_PAGE_LOGO = (await import(`../../images/${style}/logo_login_screen.png`)).default;
  NAVBAR_LOGO = (await import(`../../images/${style}/logo_navbar.png`)).default;
  NOTIFICATIONS_LOGO = (await import(`../../images/${style}/logo_notifications.png`)).default;
  SIGNET_WHITE = (await import(`../../images/${style}/signet_white.png`)).default;
  LOGO_RGB = (await import(`../../images/${style}/logo_rgb.png`)).default;
}
