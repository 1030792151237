import { createSelector } from "reselect";

import { Item, JOB_STATUS_TYPE } from "@ea/shared_types/types";

import { DataReducerState, INITIAL_DATA_PARAMS } from "./reducers/common.data.reducer";

export const createDataSelectors =
  <DataApplicationState, ApplicationState extends DataApplicationState>() =>
  <T extends Item<string | number>>() =>
  <K extends keyof DataApplicationState, Y extends DataReducerState<T>>(key: K) => {
    const getStateChunk = (state: ApplicationState): Y => state[key] as any;
    const getParams = (state: ApplicationState, id: string) =>
      getStateChunk(state).params[id] || INITIAL_DATA_PARAMS;
    const getItem = (state: ApplicationState, id: number) => getStateChunk(state).items[id];
    const getItemSelector = createSelector(getItem, (item) => item);
    const getParamsSelector = createSelector(getParams, (params) => params);
    const getPersistentQuerySelector = createSelector(
      getParams,
      (params) => params.persistentQuery,
    );
    const getItems = (state: ApplicationState) => getStateChunk(state).items;
    const getOrderSelector = createSelector(getParams, (params) => params.order);
    const getSelectedSelector = createSelector(getParams, (params) => params.selected);
    const getDataSelector = createSelector(getItems, (items) => items);
    const getOrderedDataSelector = createSelector(getItems, getOrderSelector, (items, order) =>
      order.map((id) => items[id]),
    );

    const getIsLoadingSelector = createSelector(getStateChunk, (state) => state.isLoading);
    const getSelectedItemsSelector = createSelector(
      getItems,
      getSelectedSelector,
      (items, selected) => selected.filter((id) => items[id]).map((id) => items[id]),
    );

    const getSelectedItemSelector = createSelector(
      getItems,
      getSelectedSelector,
      (items, selected) =>
        selected.length === 1 && items[selected[0]] ? items[selected[0]] : undefined,
    );

    const getCurrentPageSelector = createSelector(getParams, (params) =>
      params.paging ? params.paging.currentPage : 1,
    );
    const getPageSizeSelector = createSelector(getParams, (params) =>
      params.paging ? params.paging.pageSize : 0,
    );
    const getTotalSelector = createSelector(getParams, (params) =>
      params.paging ? params.paging.total : 0,
    );
    const getOffsetSelector = createSelector(
      getCurrentPageSelector,
      getPageSizeSelector,
      (currentPage, pageSize) => ({ offset: (currentPage - 1) * pageSize, limit: pageSize }),
    );
    const getTotalPagesSelector = createSelector(
      getPageSizeSelector,
      getTotalSelector,
      (pageSize, total) => Math.ceil(total / pageSize),
    );
    const getClosedSelector = createSelector(
      getPersistentQuerySelector,
      (persistentQuery) =>
        persistentQuery &&
        (!persistentQuery.status ||
          (persistentQuery.status && persistentQuery.status.neq !== JOB_STATUS_TYPE.CLOSED)),
    );

    const getShowWithWorkItemsSelector = createSelector(
      getPersistentQuerySelector,
      (persistentQuery) => persistentQuery && persistentQuery.withWorkItemsOnly,
    );

    return {
      getItemSelector,
      getSelectedItemSelector,
      getParamsSelector,
      getOrderSelector,
      getSelectedSelector,
      getOrderedDataSelector,
      getCurrentPageSelector,
      getDataSelector,
      getPageSizeSelector,
      getTotalSelector,
      getOffsetSelector,
      getSelectedItemsSelector,
      getTotalPagesSelector,
      getIsLoadingSelector,
      getPersistentQuerySelector,
      getClosedSelector,
      getShowWithWorkItemsSelector,
    };
  };
