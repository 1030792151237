import { AsyncActionCreators } from "typescript-fsa";

import { GlobalVariable, Script, Variable } from "@ea/shared_types/types";

export type NotifiableActionWithMessages = {
  action: AsyncActionCreators<any, any, any>;
  onSuccessMessage?: string;
  onErrorMessage?: string;
};
export type NotifiableAction = AsyncActionCreators<any, any, any> | NotifiableActionWithMessages;

export function isNotifiableActionWithMessages(
  notifiableAction: NotifiableAction,
): notifiableAction is NotifiableActionWithMessages {
  // prettier-ignore
  return (<NotifiableActionWithMessages> notifiableAction).action !== undefined;
}

export function getAction(notifiableAction: NotifiableAction) {
  if (isNotifiableActionWithMessages(notifiableAction)) {
    return notifiableAction.action;
  }
  return notifiableAction;
}

export type PlainObject<T> = { [key: string]: T };
export interface SelectableItem {
  isSelected: boolean;
}

export enum SortDirections {
  ASC = "ASC",
  DESC = "DESC",
}

export type SortDirection = keyof typeof SortDirections;

export enum SelectMode {
  Replace = "Replace",
  Ctrl = "Ctrl",
  SelectAll = "SelectAll",
  SelectVisible = "SelectVisible",
}

export type OnSortHandlerParams<T> = { sortBy: keyof T; sortDirection: SortDirection };
export type OnSortHandler<T> = ({ sortBy, sortDirection }: OnSortHandlerParams<T>) => void;
export type OnSelectHandlerParams = { mode: SelectMode; ids: number[] };
export type OnSelectHandler = ({ mode, ids }: OnSelectHandlerParams) => void;

export type SelectItemParams = { mode: SelectMode; ids: number[]; index: number };
export type SelectItem = ({ mode, ids, index }: SelectItemParams) => void;

export const DEFAULT_PAGE_SIZE = 50;
export const pageSizes: ReadonlyArray<number> = [10, 25, DEFAULT_PAGE_SIZE];

// todo: we moved declaration to shared_types, remove it from here and fix all errors
export type VariablesGroup = {
  id: string;
  variables?: (Variable | GlobalVariable)[];
  groups?: VariablesGroup[];
};

export type PublishedSelected = {
  scriptId: number;
  publishId: number;
};

export type GroupedScript = {
  id: number;
  name: string;
  counter: number;
  scripts: Script[];
  startUrl: string;
};

export type PublishScript = {
  taskScriptId: number;
  name: string;
  description: string;
};
export type CloneScript = {
  projectId?: number;
  scriptIds?: number[];
  name?: string;
  description?: string;
};

export type TableActionParamsBase = {
  tableId: string;
};

export type TableActionParams<T = {}> = TableActionParamsBase & T;

export type GroupedItems<T> = {
  id: number;
  name: string;
  count: number;
  items: T[];
};

export enum TableMode {
  GROUPED = "GROUPED",
  FLAT = "FLAT",
}

export interface UploadedFile {
  field: string;
  originalFilename: string;
  size: number;
  name: string;
  type: string;
  container: string;
  id: number;
}
