import styled from "@emotion/styled";
import * as React from "react";

import Message from "./Message";
import { MESSAGE_TYPE } from "@ea/shared_types/types";

export interface IMessageBarProps {
  messages: { type: MESSAGE_TYPE; text: string }[];
}

const Container = styled.div({
  color: "black",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  fontSize: "12px",
});

class MessageBar extends React.Component<IMessageBarProps, any> {
  render() {
    const { messages } = this.props;
    return (
      <Container>
        {messages.map((m, index) => (
          <Message {...m} key={index} />
        ))}
      </Container>
    );
  }
}

export default MessageBar;
