import { createModuleEpics } from "@app/modules/app.reducers";
import { moduleActions } from "./itsRules.actions";
import { IssueTrackingToolRule } from "@ea/shared_types/types";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { ITS_RULES_COLUMNS_CONFIG } from "./itsRules.table";
import { itsRulesDataSelectors } from "./itsRules.selectors";

const itsRulesModuleEpics = createModuleEpics<IssueTrackingToolRule>(moduleActions, {
  normalizeItem: (rule) => rule,
  getRequestParams: (state, tableId) => {
    const tableParams = itsRulesDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, ITS_RULES_COLUMNS_CONFIG, {
      defaultOrder: ["createdAt DESC"],
    });
    return params;
  },
  getSingleRequestParams: (params) => params,
  api: {
    createItem: API.createItsRule as any,
    deleteItem: API.deleteItsRule,
    editItem: API.editItsRule as any,
    getItems: API.getItsRule as any,
    getItemsCount: API.getItsRuleCount,
  },
});

export default itsRulesModuleEpics;
