import { AUDIT_TRAIL_MODE_ERROR } from "@ea/shared_components/Form/FormItem/FormItemWrapper";

export const test = () => {
  return true;
};

export const shouldBeArray = (obj) => {
  const arr = Object.keys(obj);
  if (arr.length === 0) {
    return false;
  }
  for (const value of arr) {
    if (!value.match(/^-?\d+$/)) {
      return false;
    }
  }
  return true;
};

export const transformDiffObjectToAuditTrailErrors = (obj) => {
  if (typeof obj === "object" && shouldBeArray(obj)) {
    const arr: any[] = [];
    for (const prop of Object.keys(obj)) {
      arr[prop] = obj[prop];
    }
    obj = arr.map((a) =>
      typeof a === "string" ? AUDIT_TRAIL_MODE_ERROR : transformDiffObjectToAuditTrailErrors(a),
    );
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj;
  }

  if (typeof obj !== "object") {
    obj = AUDIT_TRAIL_MODE_ERROR;
    return obj;
  }
  if (typeof obj === "object") {
    if (Object.keys(obj).length === 0) {
      obj = undefined;
      return obj;
    }

    for (const k in obj) {
      if (obj[k] && typeof obj[k] === "object") {
        obj[k] = transformDiffObjectToAuditTrailErrors(obj[k]);
      } else {
        obj[k] = AUDIT_TRAIL_MODE_ERROR;
      }
    }
    return obj;
  }
};

export const getAuditTrailErrorsFromDiff = (recordDiff, newData?) => {
  const recordDiffCopy = JSON.parse(JSON.stringify(recordDiff));
  const errors = transformDiffObjectToAuditTrailErrors(recordDiffCopy) || {};

  if (newData) {
    newData.reactions?.forEach((element, index) => {
      if (
        element.activity?.type === "INCREASE_KPI" &&
        errors.reactions?.[index]?.activity?.values
      ) {
        errors.reactions[index].activity.values = AUDIT_TRAIL_MODE_ERROR;
      }
    });
  }

  if (errors.statusesToRun) {
    errors.statusesToRun = AUDIT_TRAIL_MODE_ERROR;
  }
  if (errors.tagsToRun) {
    errors.tagsToRun = AUDIT_TRAIL_MODE_ERROR;
  }
  return errors;
};
