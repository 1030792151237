import { ApplicationState } from "@app/modules/app.reducers";
import { disabledFeaturesSelector } from "@app/modules/globalSettings/globalSettings.selectors";
import { CheckboxField } from "@ea/shared_components";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import VirtualizedTable from "@ea/shared_components/Table/elements/VirtualizedTable";
import { SelectItemParams, SelectMode } from "@ea/shared_components/redux/common.models";
import { JOB_STATUS_TYPE, SchedulerJobMapping, SchedulerMode } from "@ea/shared_types/types";
import styled from "@emotion/styled";
import { InputNumber } from "antd";
import { getIn } from "final-form";
import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import { TableCellProps } from "react-virtualized";
import { Dispatch, bindActionCreators } from "redux";
import { API } from "../../../../services/api/api";
import { projectSelectors } from "../../../projects/projects.selectors";
import { getScriptColumnsRenderers } from "../../../scripts/ScriptColumnsRenderers";
import { getSchedulerTableActions } from "../../scheduler.actions";
import { schedulerDataSelectors } from "../../scheduler.selectors";
import { SCHEDULER_TABLES_CONFIG } from "../../scheduler.table";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

interface ISchedulerImportMappingsFormState {
  schedulerJobMappingItems: SchedulerJobMapping[];
  schedulerJobMappingItemsSelected: number[];
  schedulerJobMappingItemsSelectedMap: Record<string, number>;
  selected: number[];
  isLoadingSchedulerJobMappingItems: boolean;
}

interface ISchedulerImportMappingsFormProps {
  change: any;
  values: any;
  schedulerJobId: number;
}

class SchedulerImportMappingsForm extends React.Component<
  IConnectProps,
  ISchedulerImportMappingsFormState
> {
  constructor(props: IConnectProps) {
    super(props);

    this.state = {
      schedulerJobMappingItems: [],
      selected: [],
      isLoadingSchedulerJobMappingItems: false,
      schedulerJobMappingItemsSelected: [],
      schedulerJobMappingItemsSelectedMap: {},
    };
  }

  componentDidMount(): void {
    const { values, actions } = this.props;
    actions.load({});
    actions.setPersistentQuery({
      query: {
        status: { neq: JOB_STATUS_TYPE.CLOSED },
        isInternal: false,
      },
    });

    const schedulerId = getIn(values, "fromSchedulerId");

    if (schedulerId) {
      this.loadSchedulerJobMappingItems(schedulerId);
    }
  }

  loadSchedulerJobMappingItems = async (schedulerJobId: number) => {
    this.setState({
      schedulerJobMappingItems: [],
      isLoadingSchedulerJobMappingItems: true,
    });

    const schedulerJobMappingItems = await API.getSchedulerJobMappings({
      filter: {
        where: { schedulerJobId: schedulerJobId },
        order: "lineNum ASC",
      },
    });

    this.setState({
      schedulerJobMappingItems: schedulerJobMappingItems.map((sm) => sm),
      isLoadingSchedulerJobMappingItems: false,
    });
  };

  onSchedulerSelect = (value) => {
    this.loadSchedulerJobMappingItems(value);
  };

  getColumns = () => {
    const { projectsMap } = this.props;
    const columnRenderers = getScriptColumnsRenderers({ projectsMap });

    return [
      { label: "table.scriptId", dataKey: "taskScriptId", width: 100 },
      { label: "table.lineNum", dataKey: "lineNum", width: 100 },
      {
        label: "table.name",
        dataKey: "name",
        flexGrow: 1,
        width: 300,
      },
      {
        label: "common.label.project",
        dataKey: "projectName",
        width: 300,
        cellRenderer: (props: TableCellProps) => columnRenderers.projectNameRenderer(props.rowData),
      },
      {
        label: "table.envNameShort",
        dataKey: "envName",
        width: 300,
        cellRenderer: (props: TableCellProps) => columnRenderers.envNameRenderer(props.rowData),
      },
      {
        label: "table.envTypeShort",
        dataKey: "envType",
        width: 150,
        flexGrow: 1,
        cellRenderer: (props: TableCellProps) => columnRenderers.envTypeRenderer(props.rowData),
      },
      {
        label: "table.repeatCount",
        dataKey: "repeatCount",
        width: 150,
        flexGrow: 1,
        cellRenderer: (props: TableCellProps) => (
          <div onClick={(e) => e.stopPropagation()}>
            <InputNumber
              defaultValue={props.rowData.repeatCount || 1}
              value={props.rowData.repeatCount || 1}
              disabled={true}
            />
          </div>
        ),
      },
    ];
  };

  onSelectSchedulerJobItem = ({ mode, ids, index }: SelectItemParams) => {
    const { schedulerJobMappingItems } = this.state;
    const { values, change } = this.props;
    const schedulerJobMappingItemsSelectedMap: Record<string, number> = getIn(values, "selected");

    if (!schedulerJobMappingItems[index]) {
      ids = schedulerJobMappingItems.map((job) => job.id);
    } else {
      ids = [schedulerJobMappingItems[index].id!];
    }
    if (mode === SelectMode.Replace) {
      const selected = ids;
      const selectedMap = selected.reduce((map, obj) => {
        map[obj] = obj;
        return map;
      }, {});

      this.props.change("selected", selectedMap);

      return;
    } else {
      const existed: number[] = [];
      const filtered = Object.values(schedulerJobMappingItemsSelectedMap || {}).filter((id) => {
        const result = ids.indexOf(id) === -1;
        if (!result) {
          existed.push(id);
        }
        return result;
      });

      const filteredIds = ids.filter((id) => existed.indexOf(id) === -1);
      const selected = filteredIds.concat(filtered);

      const selectedMap = selected.reduce((map, obj) => {
        map[obj] = obj;
        return map;
      }, {});

      this.props.change("selected", selectedMap);
    }
  };

  render() {
    const { disabledFeatures, schedulers, values, schedulerJobId } = this.props;
    const {
      isLoadingSchedulerJobMappingItems,
      schedulerJobMappingItems,
      schedulerJobMappingItemsSelectedMap,
      schedulerJobMappingItemsSelected,
    } = this.state;

    const selected = getIn(values, "selected");

    return (
      <Container>
        <SelectField
          name="fromSchedulerId"
          label="Source scheduler"
          onChange={this.onSchedulerSelect}
          options={schedulers
            .filter((s) => s.id !== schedulerJobId)
            .map((scheduler) => ({
              value: scheduler.id,
              text: `${scheduler.id}. ${scheduler.name}`,
            }))}
        />
        <CheckboxField name="takeGeneral" label="Import general options" />
        <VirtualizedTable
          items={schedulerJobMappingItems}
          selectItem={this.onSelectSchedulerJobItem}
          isLoading={isLoadingSchedulerJobMappingItems || false}
          isDragEnabled={false}
          alwaysVisible={true}
          selectedMap={selected}
          idProperty="id"
          columns={this.getColumns()}
        />
      </Container>
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: ISchedulerImportMappingsFormProps) => ({
    schedulers: schedulerDataSelectors
      .getOrderedDataSelector(state, SCHEDULER_TABLES_CONFIG.MAIN.id())
      .filter((scheduler) => scheduler.mode !== SchedulerMode.GANTT),
    projectsMap: projectSelectors.getDataSelector(state),
    disabledFeatures: disabledFeaturesSelector(state),
    ...props,
  }),
  (dispatch: Dispatch) => ({
    actions: {
      ...bindActionCreators(getSchedulerTableActions(SCHEDULER_TABLES_CONFIG.MAIN.id()), dispatch),
    },
  }),
  null,
  { forwardRef: true },
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(SchedulerImportMappingsForm);
