import * as React from "react";
import PanelFormFinal, {
  IPanelProps,
  PanelFormRenderFooterProps,
  RenderProps,
} from "./PanelFormFinal";
import { Steps, Button } from "antd";
import { PanelType } from "../Panel/Panel";
import { FormattedMessage } from "react-intl";
import { DataTestIds } from "../utils/dataTestHelpers";

const Step = Steps.Step;

export type PanelStep = {
  title: string;
  render: (props: RenderProps) => JSX.Element;
};
interface IStepPanelFormProps extends IPanelProps {
  steps: PanelStep[];
}

interface IStepPanelFormState {
  current: number;
}

class StepPanelForm extends React.Component<IStepPanelFormProps, IStepPanelFormState> {
  state = {
    current: 0,
  };

  next = () => {
    const current = this.state.current + 1;
    this.setState({ current });
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  componentDidUpdate(prevProps: IStepPanelFormProps) {
    if (prevProps.visibility && !this.props.visibility) {
      this.setState({ current: 0 });
    }
  }

  renderFooterContent = (footerProps: PanelFormRenderFooterProps) => {
    const { form, isSaving, allowPrisitineSubmit, onCancelClick } = footerProps;
    const { submitting, pristine } = form;
    const { okButtonText, cancelButtonText, steps } = this.props;
    const { current } = this.state;

    const hasNext = current < steps.length - 1;
    const isLast = current === steps.length - 1;
    const hasPrevious = current > 0;

    return [
      hasNext && (
        <Button type="primary" onClick={this.next} data-testid={DataTestIds.PANEL_BUTTON_NEXT}>
          Next
        </Button>
      ),
      isLast && (
        <Button
          key="ok"
          type="primary"
          htmlType="submit"
          loading={isSaving}
          disabled={allowPrisitineSubmit === true ? submitting : submitting || pristine}
          data-testid={DataTestIds.PANEL_BUTTON_SUBMIT}
        >
          {okButtonText || "Ok"}
        </Button>
      ),
      hasPrevious && (
        <Button
          style={{ marginLeft: 8 }}
          onClick={() => this.prev()}
          data-testid={DataTestIds.PANEL_BUTTON_PREV}
        >
          Previous
        </Button>
      ),

      <Button
        style={{ marginLeft: "8px" }}
        key="cancel"
        onClick={onCancelClick}
        disabled={submitting}
        data-testid={DataTestIds.PANEL_BUTTON_CANCEL}
      >
        {cancelButtonText || "Cancel"}
      </Button>,
    ];
  };

  render() {
    const { steps, ...rest } = this.props;
    const { current } = this.state;

    return (
      <PanelFormFinal
        {...rest}
        onRenderFooterContent={this.renderFooterContent}
        panelType={PanelType.XLARGE}
        render={(props) => (
          <>
            <Steps size="small" current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={<FormattedMessage id={item.title} />} />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].render(props)}</div>
          </>
        )}
      />
    );
  }
}

export default StepPanelForm;
