import * as React from "react";
import styled from "@emotion/styled";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { DOCUMENTATION_TABLES_CONFIG } from "./documentation.table";
import { getDocumentationTableActions, documentationActions } from "./documentation.actions";
import { SCRIPTS_TABLES_CONFIG, PROJECT_SCRIPTS_COLUMNS } from "../scripts/scripts.tables";
import {
  scriptsSelectorsModifier,
  scriptsDataSelectors,
  getSelectedFilterClosedSelector,
} from "../scripts/scripts.selectors";
import { overrideColumnProps } from "@ea/shared_components/Table/common.tables";
import { RouterChildContext } from "react-router";
import { Script } from "@ea/shared_types/types";
import * as PropTypes from "prop-types";
import { getScriptsTableActions } from "../scripts/scripts.actions";
import { projectSelectors, getProjectsTreeSelector } from "../projects/projects.selectors";
import { getTagsFilterValues } from "../tags/tag.selectors";
import { PROJECTS_TABLES_CONFIG } from "../projects/projects.tables";
import { TAG_TABLES_CONFIG } from "../tags/tag.table";
import { getScriptColumnsRenderers } from "../scripts/ScriptColumnsRenderers";
import { API } from "@app/services/api/api";
import { ROUTES } from "@app/routes";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { currentUserSettingsSelector } from "@ea/shared_components/auth/auth.selectors";
import { disabledFeaturesSelector } from "../globalSettings/globalSettings.selectors";
import { downloadFileBlob } from "@ea/shared_components/helpers/file";
import { DataTestIds } from "@app/utils/dataTestIds";

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: "100%",
});

interface IDocumentationTableProps {
  projectId: number;
}

interface IDocumentationTableState {
  columns: any;
  persistentQuery: any;
  isDownloadingDoc: boolean;
}

class DocumentationScriptsTableContainer extends React.Component<
  IDocumentationTableProps & IConnectProps,
  IDocumentationTableState
> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  connectedTable: any;
  context: RouterChildContext<any>;

  constructor(props: IDocumentationTableProps & IConnectProps) {
    super(props);
    const scriptRenderers = getScriptColumnsRenderers({ projectsMap: props.projectsMap });

    const columns = overrideColumnProps<Script>(PROJECT_SCRIPTS_COLUMNS, [
      {
        dataIndex: "startUrl",
        frameworkProps: {
          render: (_, record) => scriptRenderers.startUrlRenderer(record),
        },
      },
      {
        dataIndex: "envType",
        frameworkProps: {
          render: (_, record) => scriptRenderers.envTypeRenderer(record),
        },
      },
      {
        dataIndex: "envName",
        frameworkProps: {
          render: (_, record) => scriptRenderers.envNameRenderer(record),
        },
      },
    ]);

    this.state = {
      isDownloadingDoc: false,
      columns,
      persistentQuery: {
        projectId: { inq: [props.projectId] },
        [props.userSettings && props.userSettings.displayOnlyPublishedDocuments
          ? "publishedDocuments"
          : "documents"]: { gt: 0 },
        ...props.persistentQuery,
      },
    };
  }

  componentDidUpdate(prevProps: IDocumentationTableProps & IConnectProps) {
    if (prevProps.projectId !== this.props.projectId) {
      this.setState({
        persistentQuery: {
          ...this.state.persistentQuery,
          projectId: {
            inq: [this.props.projectId],
          },
        },
      });
    }
  }

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
  };

  openDocumentation = (item: Script) => {
    this.context.router.history.push(`${ROUTES.scripts}/${item.id}${ROUTES.documentation}/`);
  };

  downloadDoc = async () => {
    const { selected } = this.props;
    if (selected && selected.length > 0) {
      this.setState({ isDownloadingDoc: true });

      const blob = await API.getScriptDocumentationFiles({ ids: selected });
      downloadFileBlob(blob, `Documentation.zip`);

      this.setState({ isDownloadingDoc: false });
    }
  };

  render() {
    const { columns, persistentQuery, isDownloadingDoc } = this.state;
    const { projects, tagFilterValues, tableId, preferencesId, disabledFeatures } = this.props;

    return (
      <Container>
        <CommandBar data-testid={DataTestIds.COMMANDBAR_DOC_SCRIPTS_TABLE}>
          <CommandBarButton
            name="refresh"
            onClick={this.reload}
            text={getTranslationKey("commandBar", "refresh")}
            icon="ReloadOutlined"
            data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
          />
          <CommandBarButton
            name="download"
            onClick={this.downloadDoc}
            text={getTranslationKey("commandBar", "downloadDoc")}
            icon="FileOutlined"
            loading={isDownloadingDoc}
            disabled={this.props.selected.length === 0}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_DOWNLOAD}
          />
        </CommandBar>
        <ConnectedTable
          key={"flat_table"}
          setRef={(component) => (this.connectedTable = component)}
          pageable
          modifySelectors={scriptsSelectorsModifier}
          dynamicFilterValues={{
            projectId: projects,
            tags: tagFilterValues,
          }}
          columnsConfig={columns}
          onRowDoubleClick={this.openDocumentation}
          tableId={tableId}
          preferencesId={preferencesId}
          stateKey={"scripts"}
          tableActions={getScriptsTableActions}
          persistentQuery={persistentQuery}
          disabledFeatures={disabledFeatures}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(documentationActions, dispatch),
    ...bindActionCreators(
      getDocumentationTableActions(DOCUMENTATION_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ),
  },
});

const connectCreator = connect((state: ApplicationState, props: IDocumentationTableProps) => {
  const tableId = SCRIPTS_TABLES_CONFIG.DOCUMENTATION.id(props.projectId);
  const preferencesId = SCRIPTS_TABLES_CONFIG.DOCUMENTATION.preferencesId;

  return {
    tableId,
    preferencesId,
    persistentQuery: scriptsDataSelectors.getPersistentQuerySelector(state, tableId),
    projects: getProjectsTreeSelector(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    tagFilterValues: getTagsFilterValues(state, TAG_TABLES_CONFIG.MAIN.id()),
    projectsMap: projectSelectors.getDataSelector(state),
    selected: getSelectedFilterClosedSelector(state, tableId),
    user: state.auth.user,
    userSettings: currentUserSettingsSelector(state),
    disabledFeatures: disabledFeaturesSelector(state),
  };
}, mapDispatchToProps);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(DocumentationScriptsTableContainer);
