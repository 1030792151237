import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { Role } from "@ea/shared_types/types";
import { moduleActions } from "@app/modules/roles/roles.actions";

const initialState = {
  ...getDataReducerInitialState<Role>(),
};

export type RoleReducer = typeof initialState;

const dataReducer = createDataReducer<Role>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
