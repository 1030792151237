import { createModuleEpics } from "@app/modules/app.reducers";
import { moduleActions } from "@app/modules/logs/logs.actions";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { Log } from "@ea/shared_types/types";
import { logsDataSelectors } from "./logs.selectors";
import { LOGS_COLUMNS_CONFIG } from "./logs.table";

const DEFAULT_ORDER = ["startTime DESC"];

const logModuleEpics = createModuleEpics<Log>(moduleActions, {
  normalizeItem: (log) => log,
  getRequestParams: (state, tableId) => {
    const tableParams = logsDataSelectors.getParamsSelector(state, tableId!);

    const params = createRequestParams(tableParams, LOGS_COLUMNS_CONFIG, {
      defaultOrder: DEFAULT_ORDER,
    });

    if (!params.filter) {
      params.filter = {
        where: {},
      };
    }

    params.filter!.where!.internal = false;

    params.filter = {
      ...params.filter,
    };

    return params;
  },
  getSingleRequestParams: (query) => {
    return query;
  },
  api: {
    createItem: (params: Partial<Log>) => ({} as Promise<Log>),
    deleteItem: (params: { id: number }) => ({} as Promise<any>),
    editItem: (params: Log) => ({} as Promise<Log>),
    getItems: API.getLogs,
    getItemsCount: API.getLogsCount,
  },
});

export default logModuleEpics;
