import * as React from "react";
import { List } from "antd";
import { Step } from "@ea/shared_types/types";
import { withTranslation, WithTranslation } from "react-i18next";
import { getStepLabelParams } from "../steps/steps.utils";

interface IScriptChangelogSnapshotViewProps {
  snapshot?: any;
  style?: any;
}

class ScriptChangelogSnapshotView extends React.Component<
  IScriptChangelogSnapshotViewProps & WithTranslation,
  any
> {
  render() {
    const { snapshot, style, t } = this.props;

    if (!snapshot) {
      return null;
    }

    return (
      <List
        style={style}
        itemLayout="horizontal"
        dataSource={this.props.snapshot.steps}
        renderItem={(item: Step) => (
          <List.Item>
            {item.lineNum}.{" "}
            {item.label || t(item.labelKey!, item.labelParams || getStepLabelParams(item))}
          </List.Item>
        )}
      />
    );
  }
}

export default withTranslation()(ScriptChangelogSnapshotView);
