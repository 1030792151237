import { objectValues } from "@ea/shared_components/utils/object";

import * as codeTemplateGroupsActions from "./codeTemplateGroups.actions";
import codeTemplateGroupsEpicsMap from "./codeTemplateGroups.epics";
import * as codeTemplateGroupsReducers from "./codeTemplateGroups.reducers";

const codeTemplateGroupsEpics = objectValues(codeTemplateGroupsEpicsMap);

export { codeTemplateGroupsActions, codeTemplateGroupsReducers, codeTemplateGroupsEpics };

export { default as CodeTemplateGroupsTableContainer } from "./CodeTemplateGroupsTable.container";
