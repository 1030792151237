import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { getUserGroupTableActions, userGroupActions } from "./userGroups.actions";
import { USER_GROUPS_TABLES_CONFIG, USER_GROUPS_COLUMNS } from "./userGroups.table";
import { userGroupDataSelectors } from "./userGroups.selectors";
import UserGroupsCommandBar from "./components/UserGroupsCommandBar";
import UserGroupsNewPanelContanier from "./UserGroupsNewPanel.contanier";
import { UserGroup } from "@ea/shared_types/types";
import { withRouter, RouteComponentProps, Route } from "react-router";

interface IUserGroupsTableProps {}

interface IUserGroupsTableState {
  createWindowVisibility: boolean;
  editWindowVisibility: boolean;
  persistentQuery: any;
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

class UserGroupsTableContainer extends React.Component<
  IUserGroupsTableProps & IConnectProps & RouteComponentProps<any>,
  IUserGroupsTableState
> {
  connectedTable: any;

  state: IUserGroupsTableState = {
    createWindowVisibility: false,
    editWindowVisibility: false,
    persistentQuery: {},
  };

  openCreateWindow = () => {
    this.setState({
      createWindowVisibility: true,
    });
  };

  closeCreateWindow = () => {
    this.setState({
      createWindowVisibility: false,
    });
  };
  openEditWindow = () => {
    this.setState({
      editWindowVisibility: true,
    });
  };

  closeEditWindow = () => {
    this.setState({
      editWindowVisibility: false,
    });
    this.reload();
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
    this.reload();
  };

  openSelected = () => {
    if (this.props.selectedUserGroup) {
      this.openDetails(this.props.selectedUserGroup);
    }
  };

  openDetails = (item?: UserGroup) => {
    const { selectedUserGroup } = this.props;
    const selectedItem = item || selectedUserGroup;
    if (selectedItem) {
      this.props.history.push(`userGroups/${selectedItem.id}`);
    }
  };

  render() {
    return (
      <Container>
        <UserGroupsCommandBar
          onNewClick={this.openCreateWindow}
          onRemoveClick={this.remove}
          selected={this.props.selected}
          onReload={this.reload}
          onEditClick={this.openEditWindow}
          onOpen={this.openSelected}
        />
        <UserGroupsNewPanelContanier
          onOk={this.props.actions.reload}
          onClose={this.state.editWindowVisibility ? this.closeEditWindow : this.closeCreateWindow}
          visibility={this.state.createWindowVisibility || this.state.editWindowVisibility}
          selected={this.props.selectedUserGroup}
          isEdit={this.state.editWindowVisibility}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          persistentQuery={this.state.persistentQuery}
          columnsConfig={USER_GROUPS_COLUMNS}
          tableId={USER_GROUPS_TABLES_CONFIG.MAIN.id()}
          preferencesId={USER_GROUPS_TABLES_CONFIG.MAIN.preferencesId}
          stateKey="userGroups"
          tableActions={getUserGroupTableActions}
          onRowDoubleClick={this.openDetails}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(userGroupActions, dispatch),
    ...bindActionCreators(getUserGroupTableActions(USER_GROUPS_TABLES_CONFIG.MAIN.id()), dispatch),
  },
});

const connectCreator = connect(
  (state: ApplicationState) => ({
    selected: userGroupDataSelectors.getSelectedSelector(
      state,
      USER_GROUPS_TABLES_CONFIG.MAIN.id(),
    ),
    selectedUserGroup: userGroupDataSelectors.getSelectedItemSelector(
      state,
      USER_GROUPS_TABLES_CONFIG.MAIN.id(),
    ),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(UserGroupsTableContainer));
