import * as React from "react";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";
interface UserGroupsCommandBarProps {
  onNewClick: () => void;
  onRemoveClick: (ids: number[]) => void;
  onEditClick: () => void;
  onReload: () => void;
  onOpen: () => void;
  selected: number[];
}
class UserGroupsCommandBar extends React.Component<UserGroupsCommandBarProps> {
  private container: HTMLElement;

  public render() {
    return (
      <CommandBar data-testid={DataTestIds.COMMANDBAR_USER_GROUPS}>
        <CommandBarButton
          name="new"
          onClick={this.props.onNewClick}
          text={getTranslationKey("commandBar", "add")}
          icon="PlusOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_NEW}
        />
        <CommandBarButton
          name="remove"
          onClick={() => this.props.onRemoveClick(this.props.selected)}
          text={getTranslationKey("commandBar", "remove")}
          icon="DeleteOutlined"
          disabled={this.props.selected.length === 0}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE}
        />
        <CommandBarButton
          name="edit"
          onClick={this.props.onEditClick}
          text={getTranslationKey("commandBar", "edit")}
          icon="EditOutlined"
          disabled={this.props.selected.length !== 1}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_EDIT}
        />
        <CommandBarButton
          name="refresh"
          onClick={this.props.onReload}
          text={getTranslationKey("commandBar", "refresh")}
          icon="ReloadOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
        />
        <CommandBarButton
          name="open"
          onClick={this.props.onOpen}
          text={getTranslationKey("commandBar", "open")}
          icon="LoginOutlined"
          disabled={this.props.selected.length !== 1}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_OPEN}
          far
        />
      </CommandBar>
    );
  }
}

export default UserGroupsCommandBar;
