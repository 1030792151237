import { FORM_ERROR } from "final-form";

// JSON removes keys that have undefined values. During creating that's not a problem because database generates
// default values for the fields that we don't send. It's a problem during editing when we need to send
// keys with undefined values because someone could reset some form values. That's why we convert here all
// undefined to null
export const normalizeEditForm = (values) =>
  JSON.parse(JSON.stringify(values, (k, v) => (v === undefined ? null : v)));

export const finalFormNormalizedSubmit =
  (onSubmit, normalizer?) =>
  async (values): Promise<any> => {
    try {
      const jsonValues = normalizer ? normalizer(values) : values;
      await onSubmit(jsonValues);
    } catch (err) {
      if (process.env.NODE_ENV !== "production") {
        console.error(err);
      }
      return { [FORM_ERROR]: err.toString() };
    }
  };

export const removeUndefinedKeys = (props) =>
  Object.keys(props).forEach((key) => props[key] === undefined && delete props[key]);

export const filterFalseValues = (params) =>
  Object.keys(params).reduce((prev, current) => {
    const currentValue = params[current];
    if (currentValue !== undefined && currentValue !== null && currentValue !== false) {
      prev[current] = currentValue;
    }
    return prev;
  }, {});
