import * as PropTypes from "prop-types";
import * as React from "react";
import { RouterChildContext } from "react-router";
import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { User, Localization } from "@ea/shared_types/types";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import LocalizationForm from "./components/LocalizationForm";

interface CreateEditLocalizationProps {
  visibility: boolean;
  onClose: () => void;
  onCreate: (documentation: Localization) => void;
  selected?: Localization;
  isEdit?: boolean;
  //   user: User | undefined;
}

class CreateEditLocalization extends React.Component<CreateEditLocalizationProps> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  context: RouterChildContext<any>;

  onOk = async (values) => {
    const { isEdit, onClose, onCreate } = this.props;
    let result: Localization;
    if (isEdit) {
      result = await API.editLocalization(values);
    } else {
      result = await API.createLocalization(values);
    }
    if (onCreate) {
      onCreate(result);
    }
    onClose();
  };

  render() {
    const { visibility, isEdit, onClose, selected } = this.props;
    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.SMALL}
        headerText={getTranslationKey("documentation", "header", "edit")}
        onCancelClick={onClose}
        onOkClick={this.onOk}
        cancelButtonText={getTranslationKey("button", "cancel")}
        key={"edit"}
        okButtonText={getTranslationKey("button", "save")}
        initialValues={isEdit ? selected : {}}
        render={({ change }) => <LocalizationForm change={change} />}
      />
    );
  }
}

export default CreateEditLocalization;
