import { createDataSelectors } from "@app/modules/app.reducers";
import { Tag } from "@ea/shared_types/types";
import { createSelector } from "reselect";

export const tagDataSelectors = createDataSelectors<Tag>()("tag");

export const getTagsFilterValues = createSelector(
  tagDataSelectors.getOrderedDataSelector,
  (tags) => {
    return tags.map((tag) => ({ label: tag.name, value: tag.id }));
  },
);
