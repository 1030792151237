import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { Storage } from "@ea/shared_types/types";
import { moduleActions } from "./storage.actions";

const initialState = {
  ...getDataReducerInitialState<Storage>(),
};

export type StorageReducer = typeof initialState;

const dataReducer = createDataReducer<Storage>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
