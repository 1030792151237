import { createSelector } from "reselect";
import { AuthUser, ROLES, User } from "@ea/shared_types/types";

const userSelector = (state) => state.auth.user;

// Return foreground play mode in case somehow settings are empty.
// This will prevent crashes while playing scripts.
export const currentUserSettingsSelector = createSelector(userSelector, (user): User["settings"] =>
  user ? user.settings || { playMode: "foreground" } : null,
);

export const currentUserIdSelector = createSelector(userSelector, (user) =>
  user ? user.id : undefined,
);

export const getAllowedProjectMap = createSelector(userSelector, (user: AuthUser) => {
  if (!user || (user && !user.projects)) {
    return {};
  }

  return user.projects.reduce((container, projectId) => {
    container[projectId] = true;
    return container;
  }, {});
});

export const currentUserRolesSelector = createSelector(userSelector, (user) =>
  user && user.roles ? user.roles.map((role) => role.name) : [],
);

export const isCurrentUserAdmin = createSelector(currentUserRolesSelector, (roles) =>
  roles ? roles.includes(ROLES.admin) : false,
);

export const isCurrentUserDocumentationReader = createSelector(currentUserRolesSelector, (roles) =>
  roles ? roles.includes(ROLES.documentationReader) && !roles.includes(ROLES.admin) : false,
);

export const currentUserNameSelector = createSelector(
  userSelector,
  (user) => user && user.username,
);
