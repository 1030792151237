import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { projectCategoryActions, getProjectCategoryTableActions } from "./projectCategory.actions";
import { projectCategoryDataSelectors } from "./projectCategory.selectors";

import { PROJECT_CATEGORY_COLUMNS, PROJECT_CATEGORY_TABLES_CONFIG } from "./projectCategory.table";
import ProjectCategoryCommandBar from "./components/ProjectCategoryCommandBar";
import ProjectCategoryPanelContainer from "./ProjectCategoryPanel.container";

interface IProjectCategoryTableProps {}

interface IProjectCategoryTableState {
  createWindowVisibility: boolean;
  editWindowVisibility: boolean;
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

class ProjectCategoryTableContainer extends React.Component<
  IProjectCategoryTableProps & IConnectProps,
  IProjectCategoryTableState
> {
  connectedTable: any;

  state: IProjectCategoryTableState = {
    createWindowVisibility: false,
    editWindowVisibility: false,
  };

  openEditWindow = () => {
    this.setState({
      editWindowVisibility: true,
    });
  };

  closeEditWindow = () => {
    if (this.props.selectedProjectCategory) {
      this.props.actions.loadSingle({ id: this.props.selectedProjectCategory.id });
    }
    this.setState({
      editWindowVisibility: false,
    });
  };

  openCreateWindow = () => {
    this.setState({
      createWindowVisibility: true,
    });
  };

  closeCreateWindow = () => {
    this.setState({
      createWindowVisibility: false,
      editWindowVisibility: false,
    });
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
  };

  render() {
    return (
      <Container>
        <ProjectCategoryCommandBar
          onNewClick={this.openCreateWindow}
          onRemoveClick={this.remove}
          selected={this.props.selected}
          onReload={this.reload}
          onEditClick={this.openEditWindow}
        />
        <ProjectCategoryPanelContainer
          visibility={this.state.createWindowVisibility || this.state.editWindowVisibility}
          onClose={this.state.editWindowVisibility ? this.closeEditWindow : this.closeCreateWindow}
          onCreate={this.reload}
          selected={this.props.selectedProjectCategory}
          isEdit={this.state.editWindowVisibility}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={PROJECT_CATEGORY_COLUMNS}
          tableId={PROJECT_CATEGORY_TABLES_CONFIG.MAIN.id()}
          preferencesId={PROJECT_CATEGORY_TABLES_CONFIG.MAIN.preferencesId}
          stateKey="projectCategory"
          tableActions={getProjectCategoryTableActions}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(projectCategoryActions, dispatch),
    ...bindActionCreators(
      getProjectCategoryTableActions(PROJECT_CATEGORY_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ),
  },
});

const connectCreator = connect(
  (state: ApplicationState) => ({
    selected: projectCategoryDataSelectors.getSelectedSelector(
      state,
      PROJECT_CATEGORY_TABLES_CONFIG.MAIN.id(),
    ),
    selectedProjectCategory: projectCategoryDataSelectors.getSelectedItemSelector(
      state,
      PROJECT_CATEGORY_TABLES_CONFIG.MAIN.id(),
    ),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(ProjectCategoryTableContainer);
