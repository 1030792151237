import styled from "@emotion/styled";
import * as React from "react";
import { toast } from "react-toastify";
import { faEdit, faSave, faTimes } from "@fortawesome/fontawesome-free-solid";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import SavingArea from "../common/SavingArea";
import CommandBar from "../CommandBar/CommandBar";
import CommandBarButton from "../CommandBar/CommandBarButton";
import { DataTestIds } from "../utils/dataTestHelpers";
export interface EditeableAreaComponent {
  save: () => Promise<any>;
  cancel: () => any;
  validate?: () => boolean;
}

export interface EditAreaProps {
  setRef: (element: EditeableAreaComponent) => any;
}

interface IEditeableAreaProps {
  onEdit?: () => any;
  renderDisplayArea: () => React.ReactNode;
  renderEditArea: (props: EditAreaProps) => any; // ts-loader error - React.ReactElement<EditAreaProps>;
  readOnly?: boolean;
  renderCustomEditModeCommandButtons?: (editCommandButton) => React.ReactNode;
  areaContainerStyles?: any;
}

type EditeableAreaState = {
  isEditing: boolean;
  isSaving: boolean;
};

const Container = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const AreaContainer = styled.div({
  position: "relative",
  paddingTop: "20px",
  flex: 1,
  display: "flex",
});

class EditeableArea extends React.Component<IEditeableAreaProps, EditeableAreaState> {
  editArea: EditeableAreaComponent;
  state: EditeableAreaState = {
    isEditing: false,
    isSaving: false,
  };

  enableEditing = () => {
    this.setState({
      isEditing: true,
    });

    if (this.props.onEdit) {
      this.props.onEdit();
    }
  };

  disableEditing = () =>
    this.setState({
      isEditing: false,
    });

  cancel = () => {
    if (this.editArea) {
      this.editArea.cancel();
    }
    this.disableEditing();
  };

  save = async () => {
    const isValid = this.editArea.validate ? this.editArea.validate() : true;

    if (isValid) {
      this.setState({
        isSaving: true,
      });

      try {
        await this.editArea.save();
        this.disableEditing();
      } catch (err) {
        console.error(err);
        toast.error(err.message);
      } finally {
        this.setState({
          isSaving: false,
        });
      }
    }
  };

  attachEditArea = (editArea: any) => (this.editArea = editArea);

  render() {
    const { isEditing, isSaving } = this.state;
    const { areaContainerStyles } = this.props;

    if (isEditing) {
      return (
        <Container>
          <CommandBar>
            <CommandBarButton
              name="save"
              onClick={this.save}
              text="Save"
              icon={<FontAwesomeIcon icon={faSave} />}
              data-testid={DataTestIds.EDITABLEAREA_BUTTON_SAVE}
            />
            <CommandBarButton
              name="cancel"
              onClick={this.cancel}
              text="Cancel"
              icon={<FontAwesomeIcon icon={faTimes} />}
              data-testid={DataTestIds.EDITABLEAREA_BUTTON_CANCEL}
            />
          </CommandBar>
          <AreaContainer style={areaContainerStyles}>
            <SavingArea isSaving={isSaving}>
              {this.props.renderEditArea({ setRef: this.attachEditArea })}
            </SavingArea>
          </AreaContainer>
        </Container>
      );
    }

    const EditButton = (
      <CommandBarButton
        name="edit"
        onClick={this.enableEditing}
        text="Edit"
        icon={<FontAwesomeIcon icon={faEdit} />}
        data-testid={DataTestIds.EDITABLEAREA_BUTTON_EDIT}
      />
    );
    return (
      <Container>
        {this.props.readOnly !== true && (
          <CommandBar>
            {this.props.renderCustomEditModeCommandButtons
              ? this.props.renderCustomEditModeCommandButtons(EditButton)
              : EditButton}
          </CommandBar>
        )}
        <AreaContainer style={areaContainerStyles}>{this.props.renderDisplayArea()}</AreaContainer>
      </Container>
    );
  }
}

export default EditeableArea;
