import "./TableForm.css";

import { Button } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { FieldArray } from "react-final-form-arrays";

import FormItemLabel from "./FormItem/FormItemLabel";
import FormLayout from "./FormLayout";
import { LayoutContext } from "./FormLayoutContext";
import { FormattedMessage } from "react-intl";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

interface ITableFormProps<T> {
  columns: (ColumnProps<T> & { required?: boolean })[];
  name: string;
  initialRecord?: any;
  staticMode?: boolean;
  initialValue?: any;
  parse?: any;
  format?: any;
  className?: string;
  allowEmptyForm: boolean;
}

export class TableForm<T> extends React.Component<ITableFormProps<T>, any> {
  static defaultProps = { allowEmptyForm: false };

  getActionColumn = (fields: any, allowEmptyForm: boolean): ColumnProps<T> => ({
    title: "",
    key: "deleteAction",
    align: "right",
    className: "ant-table-form-delete-column",
    render: (text, record, index) => (
      <Button
        onClick={() => {
          if (!allowEmptyForm && fields.length === 1 && this.props.initialRecord) {
            fields.update(index, this.props.initialRecord);
            return;
          }
          fields.remove(index);
        }}
        icon={<DeleteOutlined />}
        danger
      />
    ),
  });

  render() {
    const {
      columns,
      initialValue,
      name,
      initialRecord,
      staticMode,
      parse,
      format,
      className,
      allowEmptyForm,
    } = this.props;

    const tableColumns = columns.map((column) => {
      if (column.title && typeof column.title === "string") {
        column.title = <FormattedMessage id={column.title} />;
      }

      if (!column.required) {
        return column;
      }

      return {
        ...column,
        title: (
          <div className="ant-form-item-no-colon">
            <FormItemLabel
              label={column.title}
              required={true}
              colon={false}
              labelStyle={{ lineHeight: "initial" }}
            />
          </div>
        ),
      };
    });

    return (
      <LayoutContext.Consumer>
        {(oldLayout) => (
          <FormLayout labelCol={undefined} wrapperCol={{ span: 24 }} readOnly={oldLayout.readOnly}>
            <FieldArray
              parse={parse}
              format={format}
              initialValue={initialValue}
              name={name}
              subscription={{ pristine: true }}
            >
              {({ fields }) => {
                const conditionalProps = {} as any;
                if (!oldLayout.readOnly && !staticMode) {
                  conditionalProps.footer = () => (
                    <Button
                      onClick={() => fields.push(initialRecord)}
                      icon={<PlusOutlined />}
                      type="primary"
                    >
                      Add
                    </Button>
                  );
                }
                return (
                  <Table
                    className={className || "table-nested"}
                    size={"form" as any}
                    pagination={false}
                    columns={
                      oldLayout.readOnly || staticMode
                        ? columns
                        : tableColumns.concat([this.getActionColumn(fields, allowEmptyForm)])
                    }
                    dataSource={fields.map((f) => f)}
                    {...conditionalProps}
                  />
                );
              }}
            </FieldArray>
          </FormLayout>
        )}
      </LayoutContext.Consumer>
    );
  }
}

export default TableForm;
