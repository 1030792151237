import { Query } from "../services/api.common.models";

import { ColumnFilters, ColumnFilterType, ColumnFilterConfig } from "./common.filters";
import { PlainObject } from "./common.models";
import { CustomColumn } from "../Table/common.tables";
import { DataParams } from "./reducers/common.data.reducer";
import { Item } from "@ea/shared_types/types";

type CreateRequestOptions = {
  defaultOrder: string[];
};

export function getLoobpackQueryValue(
  filterType: ColumnFilterType,
  values: any,
  converter: (value: any) => any = (v) => v,
) {
  switch (filterType) {
    case ColumnFilters.TEXT: {
      return { ilike: `%${converter(values)}%` };
    }
    case ColumnFilters.RANGE:
    case ColumnFilters.DATE_RANGE: {
      const definedValues = values.filter((v) => v !== undefined && v !== null);
      if (definedValues.length === 0) {
        break;
      }

      const query: any = {};
      if (definedValues.length === 2) {
        query.between = [converter(values[0]), converter(values[1])];
      } else {
        if (values[0] !== undefined && values[0] !== null) {
          query.gte = converter(values[0]);
        }
        if (values[1] !== undefined && values[1] !== null) {
          query.lte = converter(values[1]);
        }
      }

      return query;
    }
    case ColumnFilters.SELECT:
    case ColumnFilters.TREE: {
      return { inq: values.map(converter) };
    }
    default: {
      return values;
    }
  }

  return values;
}

const generateQueryBasedOnFilter = (
  query: any = {},
  { filterType, converter }: ColumnFilterConfig,
  fieldName: string,
  values: any,
) => {
  const normalizedConverter = converter || ((v) => v);
  switch (filterType) {
    case ColumnFilters.TEXT: {
      query[fieldName] = { ilike: `%${normalizedConverter(values)}%` };
      break;
    }
    case ColumnFilters.RANGE:
    case ColumnFilters.DATE_RANGE: {
      const definedValues = values.filter((v) => v !== undefined && v !== null);
      if (definedValues.length === 0) {
        break;
      }

      query[fieldName] = {};

      if (definedValues.length === 2) {
        query[fieldName].between = [normalizedConverter(values[0]), normalizedConverter(values[1])];
      } else {
        if (values[0] !== undefined && values[0] !== null) {
          query[fieldName].gte = normalizedConverter(values[0]);
        }
        if (values[1] !== undefined && values[1] !== null) {
          query[fieldName].lte = normalizedConverter(values[1]);
        }
      }

      break;
    }
    case ColumnFilters.SELECT:
    case ColumnFilters.TREE: {
      query[fieldName] = { inq: values.map(normalizedConverter) };
      break;
    }
    default: {
      query[fieldName] = values;
    }
  }

  return query;
};

export const createRequestParams = <T extends Item<string | number>>(
  storeParams: DataParams<T>,
  columns: PlainObject<CustomColumn<T>>,
  options: Partial<CreateRequestOptions> = {},
) => {
  const { defaultOrder } = options;

  const columnsByFilterKey = Object.keys(columns).reduce((container, next) => {
    const column = columns[next];
    if (column.filter && column.filter.filterKey) {
      container[column.filter.filterKey] = column;
    }
    return container;
  }, {});

  const requestParams: Query<T> = {};

  requestParams.filter = {
    where: {},
  };

  if (storeParams.paging) {
    const { currentPage, pageSize } = storeParams.paging;
    requestParams.filter.offset = (currentPage - 1) * pageSize;
    requestParams.filter.limit = pageSize;
  }

  requestParams.filter.order = defaultOrder ? defaultOrder : ["createdAt DESC"];

  if (storeParams.sort) {
    requestParams.filter.order = [`${String(storeParams.sort.sortBy)} ${storeParams.sort.sortDirection}`];
  }

  if (storeParams.query) {
    Object.keys(storeParams.query)
      .filter((key) => storeParams.query![key] !== undefined)
      .forEach((key) => {
        const columnConfig = columnsByFilterKey[key] || columns[key];
        if (columnConfig && columnConfig.filter) {
          const values = storeParams.query![key];
          generateQueryBasedOnFilter(requestParams.filter!.where, columnConfig.filter, key, values);
        } else {
          requestParams.filter!.where![key] = storeParams.query![key];
        }
      });
  }

  if (storeParams.persistentQuery) {
    Object.keys(storeParams.persistentQuery)
      .filter((key) => storeParams.persistentQuery[key] !== undefined)
      .forEach((key) => {
        const value = storeParams.persistentQuery[key];
        // todo: typescript improve typings, don't cast to any
        const currentQuery = requestParams.filter!.where! as any;
        if (!currentQuery[key]) {
          currentQuery[key] = value;
          return;
        }

        if (!(currentQuery[key] instanceof Object)) {
          currentQuery[key] = value;
          return;
        }

        // 1. array cannot be the value of currentQuery[key] so we don't need to check for it
        // 2. loopback doesn't handle correctly AND operator with INQ and NIN operators so we need to perform
        // query merge manually
        if (
          currentQuery[key] instanceof Object &&
          (currentQuery[key].inq || currentQuery[key].nin)
        ) {
          if (currentQuery[key].inq) {
            if (Array.isArray(value)) {
              currentQuery[key].inq.push(...value);
            }
            if (value instanceof Object) {
              if (value.neq) {
                const index = currentQuery[key].inq.indexOf(value.neq);
                if (index > -1) {
                  currentQuery[key].inq.splice(index, 1);
                }
              }
            } else {
              currentQuery[key].inq.push(value);
            }
          }
          if (currentQuery[key].nin) {
            if (value instanceof Object) {
              if (value.neq) {
                currentQuery[key].inq.push(value);
              }
            } else {
              const index = currentQuery[key].inq.indexOf(value.neq);
              if (index > -1) {
                currentQuery[key].inq.splice(index, 1);
              }
            }
          }
          return;
        }

        const currentValue = currentQuery[key];
        const conditions = [
          {
            [key]: currentValue,
          },
          {
            [key]: value,
          },
        ];
        if (!currentQuery.and) {
          currentQuery.and = conditions;
        } else {
          currentQuery.and = currentQuery.and.concat(conditions);
        }
        delete currentQuery[key];
      });
  }

  return requestParams;
};
