import styled from "@emotion/styled";
import * as React from "react";
import { RowMouseEventHandlerParams, TableRowProps } from "react-virtualized";

import { checkContainerClassName } from "./SelectCheckbox";

type TableTrueRowProps = TableRowProps & {
  key: string;
  onRowRightClick?: (params: RowMouseEventHandlerParams) => void;
  isSelectable?: boolean;
};

const Row = styled.div(
  {
    borderBottom: "1px solid #f4f4f4",
    background: "#ffffff",
    color: "#767676",
    boxSizing: "border-box",
    fontSize: "14px",
    "&:focus": {
      outline: 0,
    },
  },
  ({
    isSelected,
    dragDisabled,
    isSelectable,
    isMarked,
  }: {
    isSelected: boolean;
    dragDisabled: boolean;
    isSelectable: boolean;
    isMarked: boolean;
    isOptional: boolean;
  }) => {
    return {
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
      borderBottomColor: isMarked ? "red" : isSelected ? "#ffffff" : "#f4f4f4",
      background: isSelected ? "#f4f4f4" : "transparent",
      "&:hover": {
        cursor: isSelectable ? "pointer" : "default",
        background: isSelectable ? (isSelected ? "#d6d6d6" : "#f4f4f4") : "transparent",
        [`& .${checkContainerClassName}`]: {
          opacity: dragDisabled ? 1 : 0,
        },
      },
    };
  },
);

type RowAdditionalFields = {
  dragDisabled: boolean;
  isSelected?: boolean;
  isSeletable?: boolean;
  isOptional?: boolean;
  markedElement?: number;
};
export class DefaultRowRenderer extends React.Component<TableTrueRowProps & RowAdditionalFields> {
  shouldComponentUpdate(nextProps: TableTrueRowProps & RowAdditionalFields) {
    return (
      this.props.rowData !== nextProps.rowData ||
      this.props.isSelected !== nextProps.isSelected ||
      this.props.dragDisabled !== nextProps.dragDisabled ||
      this.props.style !== nextProps.style ||
      this.props.isSelectable !== nextProps.isSelectable ||
      this.props.isOptional !== nextProps.isOptional ||
      this.props.markedElement !== nextProps.markedElement
    );
  }

  render() {
    const {
      className,
      columns,
      index,
      key,
      onRowClick,
      onRowDoubleClick,
      onRowMouseOut,
      onRowMouseOver,
      onRowRightClick,
      rowData,
      style,
      dragDisabled,
      isSelected,
      isOptional,
      isSelectable,
      markedElement,
    } = this.props;

    const a11yProps = {} as any;

    if (isSelectable) {
      if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
        a11yProps["aria-label"] = "row";
        a11yProps.tabIndex = 0;

        if (onRowClick) {
          a11yProps.onClick = (event: any) => onRowClick({ event, index, rowData });
        }
        if (onRowDoubleClick) {
          a11yProps.onDoubleClick = (event: any) => onRowDoubleClick({ event, index, rowData });
        }
        if (onRowMouseOut) {
          a11yProps.onMouseOut = (event: any) => onRowMouseOut({ event, index, rowData });
        }
        if (onRowMouseOver) {
          a11yProps.onMouseOver = (event: any) => onRowMouseOver({ event, index, rowData });
        }
        if (onRowRightClick) {
          a11yProps.onContextMenu = (event: any) => onRowRightClick({ event, index, rowData });
        }
      }
    }

    return (
      <Row
        {...a11yProps}
        className={className}
        key={key}
        role="row"
        style={style}
        isSelected={isSelected}
        isOptional={isOptional}
        dragDisabled={dragDisabled}
        isSelectable={isSelectable}
        isMarked={markedElement === index}
      >
        {columns}
      </Row>
    );
  }
}
