import { BroadcastMessages } from "@ea/shared_types/types";

let bc: BroadcastChannel | null = null;

export const bcApi = {
  logout: () => bc?.postMessage(BroadcastMessages.LOGOUT),
  login: () => bc?.postMessage(BroadcastMessages.LOGIN),
};

const handleBroadcastMessage = (ev: MessageEvent) => {
  switch (ev.data) {
    case BroadcastMessages.LOGOUT:
    case BroadcastMessages.LOGIN:
      window.location.reload();
      break;

    default:
      break;
  }
};

export const startBroadcastChannel = () => {
  bc = new BroadcastChannel("ea_channel");
  bc.onmessage = (ev: MessageEvent) => {
    handleBroadcastMessage(ev);
  };
  return bcApi;
};
