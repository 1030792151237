import { getTranslationKey } from "@app/translations/translations.helpers";
import { PanelType } from "@ea/shared_components/Panel";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { Button } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import GlobalExcelUsedInTable from "./GlobalExcelUsedInTable";

export const GlobalExcelUsedInPanel = ({
  visible,
  onCancel,
  datasourceId,
}: {
  visible: boolean;
  onCancel: () => void;
  datasourceId?: number;
}) => {
  return datasourceId ? (
    <PanelFormFinal
      visibility={visible}
      panelType={PanelType.MEDIUM}
      headerText={getTranslationKey("globalExcel", "header", "usedIn")}
      onCancelClick={onCancel}
      onOkClick={() => {}}
      onRenderFooterContent={() => (
        <Button key="cancel" onClick={onCancel}>
          <FormattedMessage id={getTranslationKey("button", "close")} />
        </Button>
      )}
      render={(props) => <GlobalExcelUsedInTable datasourceId={datasourceId} />}
    />
  ) : null;
};
