import CreateSchedulerJobForm from "@app/modules/scheduler/components/CreateSchedulerJobForm";
import {
  createStorageSelectOptions,
  schedulerFormValuesToJob,
} from "@app/modules/scheduler/formUtils";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { JOB_TRIGGER_TYPE, SchedulerJob, SchedulerJobRunMode } from "@ea/shared_types/types";
import { CascaderOptionType } from "antd/lib/cascader";
import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { ApplicationState } from "../app.reducers";
import { getItsConfigurationsTableActions } from "../issueTrackingToolConfigurations/itsConfigurations.actions";
import { getItsConfigurationOptions } from "../issueTrackingToolConfigurations/itsConfigurations.selectors";
import { ITS_CONFIGURATIONS_TABLES_CONFIG } from "../issueTrackingToolConfigurations/itsConfigurations.table";
import { getSystemTableActions } from "../systemDictionary/systemDictionary.actions";
import { getSystemFormOptions } from "../systemDictionary/systemDictionary.selectors";
import { SYSTEM_DICTIONARY_TABLES_CONFIG } from "../systemDictionary/systemDictionary.table";
import { getVirtualUsersTableActions } from "../virtualUsers/virtualUsers.actions";
import { getVirtualUserFormOptions } from "../virtualUsers/virtualUsers.selectors";
import { VIRTUAL_USERS_TABLES_CONFIG } from "../virtualUsers/virtualUsers.table";
import { getVirtualUserPoolTableActions } from "../virtualUserPool/virtualUserPool.actions";
import { VIRTUAL_USER_POOL_TABLES_CONFIG } from "../virtualUserPool/virtualUserPool.table";
import { getVirtualUserPoolsFormOptions } from "../virtualUserPool/virtualUserPool.selectors";

interface SchedulerJobCreatorProps {
  visibility: boolean;
  close: () => void;
  onCreate?: (item: SchedulerJob) => void;
  projectId?: number;
}

type SchedulerJobCreatorState = {
  storageOptions: CascaderOptionType[];
};

class SchedulerJobCreatorContainer extends React.Component<
  SchedulerJobCreatorProps & IConnectProps,
  SchedulerJobCreatorState
> {
  state: SchedulerJobCreatorState = {
    storageOptions: [],
  };

  componentDidMount() {
    this.loadStorages();
    this.props.itsConfigurations.load({});
    this.props.virtualUsersActions.load({});
    this.props.environmentsActions.load({});
    this.props.virtualUserPoolActions.load({});
  }

  componentDidUpdate(prevProps: SchedulerJobCreatorProps) {
    if (prevProps.visibility !== this.props.visibility && this.props.visibility) {
      this.loadStorages();
      this.props.itsConfigurations.load({});
    }
  }

  loadStorages = async () => {
    const result = await API.getStoragesWithContainers({});
    const storageOptions = createStorageSelectOptions(result);

    this.setState({ storageOptions });
  };

  onOkClick = async (formValues) => {
    const values = schedulerFormValuesToJob(formValues);
    const result = await API.createSchedulerJob(values);

    if (this.props.onCreate) {
      this.props.onCreate(result);
    }

    this.props.close();
  };

  render() {
    const {
      virtualUserPoolOptions,
      visibility,
      configurationOptions,
      environmentOptions,
      virtualUserOptions,
    } = this.props;
    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.SMALL}
        headerText={getTranslationKey("scheduler", "header", "create")}
        onCancelClick={this.props.close}
        onOkClick={this.onOkClick}
        cancelButtonText={getTranslationKey("commandBar", "close")}
        okButtonText={getTranslationKey("commandBar", "add")}
        initialValues={{
          triggerType: JOB_TRIGGER_TYPE.TIME,
          runMode: SchedulerJobRunMode.SEQUENTIAL,
        }}
        render={({ change, values }) => (
          <CreateSchedulerJobForm
            {...this.state}
            values={values}
            change={change}
            configurationOptions={configurationOptions}
            environmentOptions={environmentOptions}
            virtualUserOptions={virtualUserOptions}
            virtualUserPoolOptions={virtualUserPoolOptions}
          />
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  itsConfigurations: {
    ...bindActionCreators(
      getItsConfigurationsTableActions(ITS_CONFIGURATIONS_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ),
  },
  virtualUsersActions: {
    load: bindActionCreators(
      getSystemTableActions(SYSTEM_DICTIONARY_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ).load,
  },
  environmentsActions: {
    load: bindActionCreators(
      getVirtualUsersTableActions(VIRTUAL_USERS_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ).load,
  },
  virtualUserPoolActions: {
    load: bindActionCreators(
      getVirtualUserPoolTableActions(VIRTUAL_USER_POOL_TABLES_CONFIG.ALL.id()),
      dispatch,
    ).load,
  },
});

const connectCreator = connect(
  (state: ApplicationState) => ({
    configurationOptions: getItsConfigurationOptions(
      state,
      ITS_CONFIGURATIONS_TABLES_CONFIG.MAIN.id(),
    ),
    environmentOptions: getSystemFormOptions(state, SYSTEM_DICTIONARY_TABLES_CONFIG.MAIN.id()),
    virtualUserOptions: getVirtualUserFormOptions(state, VIRTUAL_USERS_TABLES_CONFIG.MAIN.id()),
    virtualUserPoolOptions: getVirtualUserPoolsFormOptions(
      state,
      VIRTUAL_USER_POOL_TABLES_CONFIG.ALL.id(),
    ),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(SchedulerJobCreatorContainer);
