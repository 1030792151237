import * as React from "react";
import { RouteProps } from "react-router";

import Nav from "./components/Nav";
import { connect, ConnectedProps } from "react-redux";

import { ApplicationState } from "@app/modules/app.reducers";
import { authActions } from "@app/modules/auth/auth.actions";

interface INavContainerProps {}

class NavContainer extends React.Component<INavContainerProps & IConnectProps & RouteProps, any> {
  render() {
    return <Nav {...this.props} />;
  }
}

const connectCreator = connect((state: ApplicationState) => ({}), {
  logout: authActions.logout,
});

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(NavContainer);
