import { objectValues } from "@ea/shared_components/utils/object";

import * as itsActions from "./its.actions";
import itsEpicsMap from "./its.epics";
import * as itsReducers from "./its.reducers";

const itsEpics = objectValues(itsEpicsMap);

export { itsActions, itsReducers, itsEpics };

export { default as ItsTableContainer } from "./ItsTable.container";
