import { createModuleEpics } from "@app/modules/app.reducers";
import { Kpi } from "@ea/shared_types/types";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { moduleActions } from "./kpis.actions";
import { KPIS_COLUMNS_CONFIG } from "./kpis.table";
import { kpisDataSelectors } from "./kpis.selectors";

const kpisModuleEpics = createModuleEpics<Kpi>(moduleActions, {
  normalizeItem: (role) => role,
  getRequestParams: (state, tableId) => {
    const tableParams = kpisDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, KPIS_COLUMNS_CONFIG);
    return params;
  },
  getSingleRequestParams: (query) => {
    return query;
  },
  api: {
    createItem: API.createKpi,
    deleteItem: API.deleteKpi,
    editItem: API.editKpi,
    getItems: API.getKpi,
    getItemsCount: API.getKpiCount,
  },
});

export default kpisModuleEpics;
