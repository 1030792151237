import { Button, Modal } from "antd";
import * as React from "react";

interface IStatisticsModalProps {
  visible: boolean;
  onClose: () => void;
  title: string | React.ReactNode;
}

const StatisticsModal: React.FunctionComponent<IStatisticsModalProps> = ({
  visible,
  onClose,
  title,
  children,
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      closable={false}
      footer={[
        <Button key="close" type="primary" onClick={onClose}>
          Close
        </Button>,
      ]}
      bodyStyle={{ display: "flex", justifyContent: "center", flexDirection: "column" }}
    >
      {children}
    </Modal>
  );
};

export default StatisticsModal;
