import { Project, ProjectTreeNode, PlainObject } from "@ea/shared_types/types";

export const isRootProject = (project: Project) => project.path.split(".").length === 2;

export const buildProjectTree = (projects: Project[], allowedProjects?: PlainObject<boolean>) => {
  const createProjectTreeNode = (p: Project): ProjectTreeNode => ({
    id: p.id,
    name: p.name,
    children: [],
    path: p.path,
    disabled: allowedProjects ? !allowedProjects[p.id] : false,
    category: p.category,
    lastSchedulerId: p.lastSchedulerId,
    integrationMetadata: p.integrationMetadata,
  });
  const rootProjects: ProjectTreeNode[] = projects
    .filter((p) => isRootProject(p))
    .map((p) => createProjectTreeNode(p));

  const projectsByParent: PlainObject<Project[]> = projects
    .filter((p) => !isRootProject(p))
    .reduce((container, project) => {
      const chunks = project.path.split(".");
      const parentPath = chunks.slice(0, chunks.length - 1).join(".");
      if (!container[parentPath]) {
        container[parentPath] = [];
      }

      container[parentPath].push(project);
      return container;
    }, {});

  const buildNode = (rootProject: ProjectTreeNode) => {
    if (projectsByParent[rootProject.path]) {
      rootProject.children.push(
        ...projectsByParent[rootProject.path].map((p2) => createProjectTreeNode(p2)),
      );
    }

    rootProject.children.map((p) => buildNode(p));
    return rootProject;
  };

  const tree = rootProjects.map((p) => buildNode(p));

  return tree;
};

export const buildProjectParentMap = (projects: ProjectTreeNode[]) => {
  const treeMap = {};
  const iterateFromRoot = (root: ProjectTreeNode) => {
    if (!treeMap[root.id]) {
      treeMap[root.id] = [];
    }

    const iterate = (ids: number[], node: ProjectTreeNode) => {
      if (!node.disabled && !treeMap[node.id]) {
        treeMap[node.id] = [];
      }

      if (!node.disabled) {
        treeMap[node.id] = ids;
      }

      for (const c of node.children) {
        iterate([...ids, node.id], c);
      }
    };

    root.children.forEach((c) => iterate([root.id], c));
  };

  projects.forEach((p) => iterateFromRoot(p));

  return treeMap;
};

export const buildProjectMap = (projects: ProjectTreeNode[]) => {
  const treeMap = {};
  const iterateFromRoot = (root: ProjectTreeNode) => {
    if (!treeMap[root.id]) {
      treeMap[root.id] = [];
    }

    const iterate = (ids, node: ProjectTreeNode) => {
      if (!node.disabled && !treeMap[node.id]) {
        treeMap[node.id] = [];
      }

      if (!node.disabled) {
        ids.forEach((i) => {
          if (!treeMap[i]) {
            treeMap[i] = [];
          }

          treeMap[i].push(node.id);
        });

        ids.push(node.id);
      }

      for (const c of node.children) {
        iterate([...ids], c);
      }
    };

    root.children.forEach((c) => iterate([root.id], c));
  };

  projects.forEach((p) => iterateFromRoot(p));

  return treeMap;
};

export const buildProjectMappingMap = (
  projectsTree: ProjectTreeNode[],
  projects: PlainObject<Project>,
  overrideField: keyof Pick<Project, "overrideGroups" | "overrideSystems" | "overrideVirtualUsers">,
) => {
  const treeMap = {};

  const iterate = (projectNode: ProjectTreeNode, mappingId?: number) => {
    let currentMappingId = mappingId ? mappingId : projectNode.id;

    const project = projects[projectNode.id];
    if (project[overrideField]) {
      currentMappingId = project.id;
    }

    treeMap[project.id] = currentMappingId;

    projectNode.children.forEach((p) => iterate(p, currentMappingId));
  };

  projectsTree.forEach((p) => iterate(p));

  return treeMap;
};

export const getProjectWithChildren = (
  selectedProject: Project,
  projectsChildrenMap: { [key: number]: number[] },
): number[] => {
  const projectId = selectedProject.id;

  const childrenIds = projectsChildrenMap[projectId];
  const projectUnique = new Set<number>();

  projectUnique.add(projectId);

  if (childrenIds) {
    childrenIds.forEach((childrenId) => projectUnique.add(childrenId));
  }

  return Array.from<number>(projectUnique);
};

export const PROJECT_NEW_NODE_ID = "new";
