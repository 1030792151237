import * as React from "react";
import { PanelType } from "@ea/shared_components/Panel";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { getTranslationKey } from "@app/translations/translations.helpers";
import KpisForm from "./components/KpiForm";
import { Kpi } from "@ea/shared_types/types";
import { API } from "@app/services/api/api";
interface ICreateEditGlobalVariablePanelProps {
  visibility: boolean;
  onClose: () => void;
  selected?: Kpi;
  isEdit?: boolean;
  onSave: () => void;
}

const CreateEditKpiContainer: React.FunctionComponent<ICreateEditGlobalVariablePanelProps> = ({
  isEdit,
  onClose,
  visibility,
  selected,
  onSave,
}) => {
  const onOk = async (values) => {
    if (isEdit) {
      await API.editKpi(values);
    } else {
      await API.createKpi(values);
    }
    onSave();
    onClose();
  };
  return (
    <PanelFormFinal
      visibility={visibility}
      panelType={PanelType.SMALL}
      headerText={getTranslationKey("kpi", "header", isEdit ? "edit" : "create")}
      onCancelClick={onClose}
      onOkClick={onOk}
      key={isEdit ? "Edit" : "Create"}
      cancelButtonText={getTranslationKey("button", "cancel")}
      okButtonText={getTranslationKey("button", isEdit ? "save" : "create")}
      initialValues={isEdit ? selected : undefined}
      render={({ values, change, form }) => <KpisForm values={values} form={form} />}
    />
  );
};

export default CreateEditKpiContainer;
