import { DatePicker } from "antd";
import moment from "moment";
import * as React from "react";

import { withDefaultProps } from "../../utils/react";

import { IFilterControlProps } from "./FilterContainer";
import { RangeValue } from "rc-picker/es/interface";

interface IDateRangeFilterDefaultProps {
  placeholder: string;
}

const defaultProps: IDateRangeFilterDefaultProps = {
  placeholder: "Search text",
};
interface IDateRangeFilterProps extends IFilterControlProps, IDateRangeFilterDefaultProps {}

const { RangePicker } = DatePicker;

class DateTimeRangeFilter extends React.Component<IDateRangeFilterProps> {
  inputElement: any;
  filterDropdown: any;

  componentDidMount() {
    if (this.props.isOpen && this.inputElement) {
      this.inputElement.focus();
    }
  }

  componentDidUpdate(prevProps: IDateRangeFilterProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      if (this.inputElement) {
        this.inputElement.focus();
      }
    }
  }

  onChange = (dates: RangeValue<moment.Moment>) => {
    this.props.setValue([
      dates ? (dates[0] ? dates[0].toJSON() : dates[0]) : null,
      dates ? (dates[1] ? dates[1].toJSON() : dates[1]) : null,
    ]);
  };

  onContainerClick = (event) => {
    event.stopPropagation();
  };

  render() {
    const { value, fieldKey } = this.props;

    const normalizeValue: [moment.Moment | null, moment.Moment | null] | null = value
      ? [value[0] ? moment(value[0]) : value[0], value[1] ? moment(value[1]) : value[1]]
      : null;

    return (
      <div
        className="custom-filter-dropdown"
        onClick={this.onContainerClick}
        ref={(element) => (this.filterDropdown = element)}
      >
        <RangePicker
          getPopupContainer={() => this.filterDropdown}
          ref={(element) => (this.inputElement = element)}
          onChange={this.onChange}
          value={normalizeValue}
          showTime={{
            format: "HH:mm",
            defaultValue: [moment().startOf("day"), moment().endOf("day")],
          }}
          format="DD-MM-YYYY HH:mm"
          ranges={{
            Today: [moment().startOf("day"), moment().endOf("day")],
            "Current Month": [moment().startOf("month"), moment().endOf("day")],
            "Last 7 Days": [moment().subtract(7, "day").startOf("day"), moment().endOf("day")],
          }}
        />
      </div>
    );
  }
}

export default withDefaultProps(defaultProps, DateTimeRangeFilter);
