import { reducerWithInitialState } from "typescript-fsa-reducers";

import {
  clearLoginErrorCreator,
  initAuthCreator,
  loginCreator,
  logoutCreator,
} from "@app/modules/auth/auth.actions";
import { User } from "@ea/shared_types/types";

export interface AuthReducer {
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  isError: boolean;
  errorMessage: string;
  user: User | undefined;
  hasActiveSession: boolean;
}

export const initialState: AuthReducer = {
  isLoggedIn: false,
  isLoggingIn: false,
  isError: false,
  errorMessage: "",
  user: undefined,
  hasActiveSession: false,
};

const logingStarted = (state: AuthReducer, payload: typeof loginCreator.started.payloadType) => ({
  ...state,
  isLoggedIn: false,
  isLoggingIn: true,
  isError: false,
  hasActiveSession: false,
});

const logingDone = (state: AuthReducer, payload: typeof loginCreator.done.payloadType) => ({
  ...state,
  isLoggedIn: true,
  isLoggingIn: false,
  isError: false,
});

const logingFailed = (state: AuthReducer, payload: typeof loginCreator.failed.payloadType) => {
  const hasActiveSession = !!payload.error?.includes("This user currently has an active session");
  return {
    ...state,
    isLoggedIn: false,
    isLoggingIn: false,
    isError: true,
    errorMessage: payload.error,
    hasActiveSession,
  };
};

export const reducer = reducerWithInitialState<AuthReducer>(initialState)
  .case(loginCreator.started, logingStarted)
  .case(loginCreator.done, logingDone)
  .case(loginCreator.failed, logingFailed)
  .case(logoutCreator, (state, payload) => ({ ...state, isLoggedIn: false }))
  .case(initAuthCreator.started, (state, payload) => ({
    ...state,
    isLoggedIn: payload.isLoggedIn,
  }))
  .case(initAuthCreator.done, (state, payload) => ({
    ...state,
    user: payload.result,
  }))
  .case(initAuthCreator.failed, (state, payload) => ({ ...state }))
  .case(clearLoginErrorCreator, (state) => ({
    ...state,
    isError: false,
    errorMessage: "",
    hasActiveSession: false,
  }));
