export const Endpoints = {
  logs: {
    endpoint: "logs",
    step: {
      endpoint: "logs/step",
    },
    execution: {
      endpoint: "logs/execution",
    },
    job: {
      endpoint: "logs/job",
    },
  },
  settings: {
    endpoint: "settings",
    internal: {
      endpoint: "settings/internal",
      public: {
        endpoint: "settings/internal/public",
      },
    },
    global: {
      endpoint: "settings/global",
    },
  },
  localizations: {
    endpoint: "localizations",
    translation: {
      endpoint: "localizations/translation",
    },
    export: {
      endpoint: "localizations/export",
    },
    import: {
      endpoint: "localizations/import",
    },
  },
  kpis: {
    endpoint: "kpis",
    statistics: {
      execution: {
        endpoint: "kpis/statistics/execution",
      },
      job: {
        endpoint: "kpis/statistics/job",
      },
    },
  },
  queueJobs: {
    endpoint: "queues",
    jobInfo: {
      endpoint: "/:queueName/jobs/:jobId",
    },
    download: {
      endpoint: "/:queueName/jobs/:jobId/download",
    },
  },
  codeTemplates: {
    endpoint: "code-templates",
    groups: {
      endpoint: "code-templates/groups",
    },
  },
  globalVariables: {
    endpoint: "global-variables",
  },
  numberSequences: {
    endpoint: "number-sequences",
  },
  variables: {
    endpoint: "variables",
    diff: {
      endpoint: "variables/local/diff",
    },
  },
  guards: {
    endpoint: "guards",
  },
  systemUrl: {
    endpoint: "system-urls",
  },
  systems: {
    endpoint: "systems",
  },
  virtualUsers: {
    endpoint: "virtualUsers",
    token: {
      endpoint: "token",
    },
  },
  license: {
    endpoint: "license",
  },
  issueTrackingTools: {
    endpoint: "issue-tracking-tools",
    count: {
      endpoint: "/count",
    },
  },
  issueTrackingRules: {
    endpoint: "issue-tracking-tools/rules",
    count: {
      endpoint: "/count",
    },
  },
  issueTrackingConfigurations: {
    endpoint: "issue-tracking-tools/configurations",
    count: {
      endpoint: "/count",
    },
  },
  workItems: {
    endpoint: "work-items",
    count: { endpoint: "count" },
  },
  integration: {
    endpoint: "integration",
    export: {
      endpoint: "/export",
    },
    testPlans: {
      endpoint: "/test-plans",
    },
    testSuiteSiblings: {
      endpoint: "/test-suite-siblings",
    },
    testCases: {
      endpoint: "/test-cases",
    },
    exportScript: {
      endpoint: "/export-script",
    },
  },
  azureDevops: {
    endpoint: "azure-devops",
    createWorkitem: {
      endpoint: "/create-workitem",
    },
    iterations: {
      endpoint: "/iterations",
    },
    projects: {
      endpoint: "/projects",
    },
    workitemTypes: {
      endpoint: "/workitem-types",
    },
    fields: {
      endpoint: "/fields",
    },
    areas: {
      endpoint: "areas",
    },
  },
  projects: {
    category: {
      endpoint: "projects-category",
    },
    endpoint: "projects",
  },
  scripts: {
    endpoint: "scripts",
    autocomplete: {
      endpoint: "url-autocomplete",
    },
    close: {
      endpoint: "close",
    },
  },
  steps: {
    endpoint: "steps",
    move: {
      endpoint: "move",
    },
  },
  bi: {
    endpoint: "api/bi",
    history: {
      endpoint: "history",
    },
    scriptHistory: {
      endpoint: "scriptHistory",
    },
    scripts: {
      endpoint: "scripts",
    },
    schedulers: {
      endpoint: "schedulers",
    },
    schedulerAggregatedHistory: {
      endpoint: "schedulerAggregatedHistory",
    },
    schedulerErrors: {
      endpoint: "schedulerErrors",
    },
  },
  runner: {
    endpoint: "runner",
    player: {
      endpoint: "player",
      init: {
        endpoint: "init",
      },
    },
    recorder: {
      endpoint: "api/recorder",
    },
  },
};

function joinEndpoints<T>(endpointsDefinition: T): T {
  const copy = JSON.parse(JSON.stringify(endpointsDefinition));
  const delimiter = "/";
  const traverse = (partialObject: any, totalPath: string = "") => {
    const keys = Object.keys(partialObject);

    if (partialObject.endpoint) {
      partialObject.endpoint = `${totalPath}${delimiter}${partialObject.endpoint}`;
    }
    const nextPath = partialObject.endpoint || "";

    for (const key of keys) {
      if (key === "endpoint") {
        continue;
      }

      traverse(partialObject[key], nextPath);
    }
  };

  traverse(copy);

  return copy;
}

export const FullEndpoints = joinEndpoints(Endpoints);

export const LAST_MOVEMENT_EXPIRATION_TIME = 1000 * 60 * 20;
export const DEFAULT_LOCALE = "en";
export const PROJECT_DEFAULT_PATH = "root";

export const FORMDATA_FIELD_NAMES = {
  datasources: "datasources[]",
  translation: "translation",
  stepFiles: "stepFiles[]",
};
