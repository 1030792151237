import { SchedulerGroup, SchedulerRunTypes, SchedulerScript } from "@ea/shared_types/types";
import {
  GANTT_DEFAULT_DURATION,
  GanttData,
  GanttLink,
  GanttTask,
  GanttTaskCommon,
  GanttTaskGroup,
  GanttTaskGroupNormal,
  GanttTaskGroupSingle,
  GanttTaskScript,
} from "./gantt.types";

export const isGroupGanttTask = (d: GanttTask): d is GanttTaskGroup => d.kind === "GROUP";
export const isScriptGanttTask = (d: GanttTask): d is GanttTaskScript => d.kind === "SCRIPT";

export const getGanttTaskGroupId = (id) => `GROUP_${id}`;
export const getGanttTaskScriptId = (id) => `SCRIPT_${id}`;

export const isItGroupId = (d: string): boolean => d?.includes("GROUP");
export const isItScriptId = (d: string): boolean => d?.includes("SCRIPT");
export const takeGroupId = (d: string) => parseInt(d.split("GROUP_")[1], 10);
export const takeScriptId = (d: string) => parseInt(d.split("SCRIPT_")[1], 10);

export const ganttColors = [
  "#2a5db8",
  "#f27f31",
  "#abaaaa",
  "#fabc00",
  "#5c99d1",
  "#6fad45",
  "#284881",
  "#a24b10",
  "#626060",
  "#9d7702",
  "#d09392",
  "#a99bbe",
];

export const convertSingleGroupToScript = (
  group: GanttTaskGroup,
  parentId: string,
): GanttTaskScript => {
  if (group.scriptMode !== "SINGLE") {
    throw new Error("Cannot convert full group to script");
  }

  const { scriptMode, schedulerJobId, schedulerScriptId, scriptId, type, ...rest } = group;
  return {
    ...group,
    id: getGanttTaskScriptId(schedulerScriptId),
    scriptId,
    kind: "SCRIPT",
    parent: parentId,
    runParams: {
      repeater: group.runParams.repeater,
      run: {
        type: SchedulerRunTypes.TIME,
        time: group.start_date!,
      },
    },
  };
};

export const getGanttGroupId = (id) => `GROUP_${id}`;

export const transformSchedulerToGanttData = (groups: SchedulerGroup[]) => {
  const data: GanttTask[] = [];
  const links: GanttLink[] = [];

  const getScriptId = (id) => `SCRIPT_${id}`;

  groups.forEach((group, index) => {
    const color = ganttColors[index % ganttColors.length];
    const groupId = getGanttGroupId(group.id);

    const isSingleGroup = group.scripts.length === 1;

    const getSingleGroupParams = (): (GanttTaskGroupSingle | GanttTaskGroupNormal) &
      Partial<GanttTaskCommon> => {
      if (!isSingleGroup) {
        return {
          scriptMode: "NORMAL",
          type: "project",
        };
      }

      const script = group.scripts[0];

      return {
        scriptMode: "SINGLE",
        schedulerScriptId: script.id,
        scriptId: script.scriptId,
        text: script.name,
        type: undefined,
      };
    };

    const groupGanttTask: GanttTaskGroup = {
      text: group.name,
      id: groupId,
      runParams: group.runParams,
      schedulerJobId: group.schedulerJobId,
      overrideRunParams: group.overrideRunParams,
      open: true,
      kind: "GROUP",
      duration: GANTT_DEFAULT_DURATION,
      start_date: isSingleGroup
        ? group.scripts[0].runParams.run.type === SchedulerRunTypes.TIME
          ? group.scripts[0].runParams.run.time
          : new Date()
        : undefined,
      color,
      ...getSingleGroupParams(),
    };

    data.push(groupGanttTask);

    if (!isSingleGroup) {
      group.scripts
        .sort((a, b) => a.lineNum - b.lineNum)
        .forEach((script) => {
          let minStartTime: Date | undefined = undefined;
          const startTimes = group.scripts
            .filter((o) => o.runParams.run.type === SchedulerRunTypes.TIME && o.runParams.run.time)
            .map((o) => (o.runParams.run as any).time);

          if (startTimes.length > 0) {
            minStartTime = new Date(Math.min.apply(null, startTimes));
          }
          const scriptId = getScriptId(script.id);
          const scriptGanttTask: GanttTask = {
            text: script.name,
            id: scriptId,
            scriptId: script.scriptId,
            kind: "SCRIPT",
            parent: groupId,
            duration: GANTT_DEFAULT_DURATION,
            runParams: script.runParams,
            color,
            start_date:
              script.runParams.run.type === SchedulerRunTypes.TIME
                ? script.runParams.run.time
                : minStartTime,
          };
          if (script.runParams.run.type === SchedulerRunTypes.DEPENDANT) {
            script.runParams.run.dependantIds.forEach((dependantId) => {
              links.push({
                type: "0",
                source: getScriptId(dependantId),
                target: scriptId,
              });
            });
          }

          data.push(scriptGanttTask);
        });
    }

    if (group?.runParams?.run?.type === SchedulerRunTypes.DEPENDANT) {
      group.runParams.run.dependantIds.forEach((dependantId) => {
        links.push({
          type: "0",
          source: getGanttGroupId(dependantId),
          target: groupId,
        });
      });
    }
  });

  return { data, links };
};

export const transformGanttDataToSchedulerGroups = (ganttData: GanttData) => {
  const groupTasks: GanttTaskGroup[] = [];
  const scriptTasks: GanttTaskScript[] = [];

  ganttData.data
    .sort((a: any, b: any) => a.$index - b.$index)
    .forEach((d) => {
      if (d.kind === "GROUP") {
        groupTasks.push(d);
      } else {
        scriptTasks.push(d);
      }
    });

  const schedulerGroups: SchedulerGroup[] = groupTasks.map((gT, index) => {
    const getRunParams = () => {
      if (gT.scriptMode === "NORMAL") {
        return gT.runParams;
      }

      if (gT.runParams.run?.type === SchedulerRunTypes.DEPENDANT) {
        return gT.runParams;
      }

      return {
        ...gT.runParams,
        run: undefined,
      };
    };

    return {
      name: gT.text,
      id: takeGroupId(gT.id),
      runParams: {
        run: undefined,
        repeater: gT.runParams.repeater,
        azureConfigurationIds: gT.runParams.azureConfigurationIds,
        virtualUserId: gT.runParams.virtualUserId,
        environmentId: gT.runParams.environmentId,
      },
      lineNum: index,
      scripts: [],
      schedulerJobId: gT.schedulerJobId,
      description: gT.description,
      overrideRunParams: gT.overrideRunParams,
    };
  });

  const schedulerScripts: SchedulerScript[] = scriptTasks.map((sT, index) => {
    const script: SchedulerScript = {
      name: sT.text,
      id: takeScriptId(sT.id),
      runParams: {
        repeater: sT.runParams.repeater,
        run: {
          type: SchedulerRunTypes.TIME,
          time: sT.start_date!,
        },
      },
      lineNum: index,
      scriptId: sT.scriptId,
      description: sT.description,
    };

    const groupId = takeGroupId(sT.parent!);

    const group = schedulerGroups.find((g) => g.id === groupId);

    group?.scripts.push(script);

    return script;
  });

  groupTasks
    .filter((gT) => gT.scriptMode === "SINGLE")
    .forEach((gT) => {
      const script = {
        id: gT.schedulerScriptId,
        name: gT.text,
        runParams: {
          run: {
            type: SchedulerRunTypes.TIME as SchedulerRunTypes.TIME,
            time: gT.start_date!,
          },
          repeater: undefined,
        },
        lineNum: 0,
        scriptId: gT.scriptId,
        description: gT.description,
      };

      const groupId = takeGroupId(gT.id);
      const group = schedulerGroups.find((g) => g.id === groupId);

      group?.scripts.push(script);

      schedulerScripts.push(script);
    });

  const isLinkValid = (link: GanttLink) => {
    const isGroupSource = isItGroupId(link.source);
    const isGroupTarget = isItGroupId(link.target);

    if (isGroupSource !== isGroupTarget) {
      console.error(`Not valid link: ${link}`);
      return false;
    }

    return true;
  };

  ganttData.links.filter(isLinkValid).forEach((link) => {
    const { source, target } = link;
    const isScript = isItScriptId(target);

    if (isScript) {
      const script = schedulerScripts.find((sC) => sC.id === takeScriptId(target));
      if (!script) {
        return;
      }

      if (script.runParams.run.type === SchedulerRunTypes.DEPENDANT) {
        script.runParams.run.dependantIds.push(takeScriptId(source));
      } else {
        script.runParams.run = {
          type: SchedulerRunTypes.DEPENDANT,
          dependantIds: [takeScriptId(source)],
        };
      }
    }

    const isGroup = isItGroupId(target);
    if (isGroup) {
      const group = schedulerGroups.find((sG) => sG.id === takeGroupId(target));
      if (!group) {
        return;
      }

      if (group?.runParams?.run?.type === SchedulerRunTypes.DEPENDANT) {
        group.runParams.run.dependantIds.push(takeGroupId(source));
      } else {
        group.runParams.run = {
          type: SchedulerRunTypes.DEPENDANT,
          dependantIds: [takeGroupId(source)],
        };
      }
    }
  });

  return schedulerGroups;
};
