import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { reducerWithInitialState, reducerWithoutInitialState } from "typescript-fsa-reducers/dist";

import { GlobalSettings } from "@ea/shared_types/types";
import { globalSettingsActionCreators } from "./globalSettings.actions";

const initialState = {};

export type GlobalSettingsReducer = GlobalSettings | null;

export const reducer = reducerWithInitialState<GlobalSettingsReducer>(null)
  .case(globalSettingsActionCreators.loadGlobalSettings.done, (state, payload) => {
    return {
      ...state,
      ...payload.result,
    };
  })
  .case(globalSettingsActionCreators.editGlobalSettings.done, (state, payload) => {
    return {
      ...state,
      ...payload.result,
    };
  });
