import * as React from "react";
import { Input, Button, Tooltip, Menu } from "antd";
import styled from "@emotion/styled";
import { Spin } from "antd";
import {
  LeftOutlined,
  ReloadOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { NAVBAR_COLOR, NAVBAR_COLOR_LIGHT } from "@app/styles/styles";
import { RUNNER_FRAME_ID, Commands } from "@ea/shared_types/types";
import { runnerCommunicator } from "@ea/shared_types/utils/iframes.communication";
import { IFrameRunnerAsyncMessages } from "@ea/shared_types/communication.types";
import { Dispatch, bindActionCreators } from "redux";
import { ApplicationState } from "../app.reducers";
import { getRunnerTableActions } from "./runner.actions";
import { connect, ConnectedProps } from "react-redux";
import { getSessionParams, getRunnerMode, getLastRecorderStep } from "./runner.selectors";
import { RunnerMode } from "@ea/shared_types";
import { FrameBarContainer, FrameContainer } from "./RunnerComponentLayout";

const InputGroup = Input.Group;
interface IRunnerFrameProps {
  url: string;
  reloadIframe: () => void;
  onClose: () => void;
  sessionId: string;
  toggleStepsManagerVisibility: () => void;
  isStepsManagerVisible: boolean;
  isRunnerOnRightSide: boolean;
  visibility: boolean;
  readyToLoadIFrame: boolean;
}

interface IRunnerFrameState {
  currentUrl: string;
  isFrameInitialized: boolean;
}

const EAIframe = styled.iframe({
  height: "100%",
  width: "100%",
  margin: 0,
  padding: 0,
  border: "none",
});

const SpinnerContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "70%",
});

const menuItemStyle = { paddingLeft: "10px", paddingRight: "10px" };

const controlPanelHeight = "40px";
class RunnerFrame extends React.Component<IConnectProps, IRunnerFrameState> {
  destroyMessageListener: any;
  locationReplace;
  constructor(props) {
    super(props);

    this.state = {
      currentUrl: props.url,
      isFrameInitialized: false,
    };
    this.locationReplace = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.readyToLoadIFrame && this.props.readyToLoadIFrame) {
      this.locationReplace.current.contentWindow.location.replace(this.props.url);

      runnerCommunicator.administration.registerMessageHandler<IFrameRunnerAsyncMessages.url_change>(
        IFrameRunnerAsyncMessages.url_change,
        {
          key: "url_change",
          callback: (url) => {
            this.setState({
              currentUrl: url,
            });
          },
        },
      );
    }
  }

  getDisplayUrl = () => {
    return this.state.currentUrl.split("/").slice(4).join("/");
  };

  onFrameLoad = () => {
    if (!this.state.isFrameInitialized) {
      this.setState({ isFrameInitialized: true });
    } else {
      const { lastRecordedStep, actions, isRecording, mode } = this.props;

      if (mode !== RunnerMode.RECORDER || !isRecording) {
        return;
      }

      if (lastRecordedStep?.commandId === Commands.start) {
        return;
      }

      if (lastRecordedStep) {
        lastRecordedStep.triggersRefresh = true;
        actions.updateStep({ step: lastRecordedStep });
      }
    }
  };

  render() {
    const {
      isStepsManagerVisible,
      toggleStepsManagerVisibility,
      onClose,
      isRunnerOnRightSide,
      readyToLoadIFrame,
      visibility,
    } = this.props;

    const hideButton = (
      <Menu.Item key="hide" onClick={toggleStepsManagerVisibility} style={menuItemStyle}>
        <Tooltip
          title={
            <FormattedMessage
              id={getTranslationKey(
                "advancedRecorder",
                "tooltip",
                isStepsManagerVisible ? "hideStepsPanel" : "showStepsPanel",
              )}
            />
          }
        >
          {isStepsManagerVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </Tooltip>
      </Menu.Item>
    );

    return (
      <>
        <FrameBarContainer visible={visibility}>
          <InputGroup compact style={{ display: "flex", borderRadius: 0 }}>
            <Menu
              theme="dark"
              mode="horizontal"
              style={{ lineHeight: "40px", backgroundColor: NAVBAR_COLOR, borderRadius: 0 }}
              selectedKeys={[]}
            >
              {!isRunnerOnRightSide && hideButton}
              <Menu.Item
                key="back"
                onClick={() => {
                  runnerCommunicator.administration.send(
                    IFrameRunnerAsyncMessages.back_navigation,
                    {},
                  );
                }}
                style={menuItemStyle}
              >
                <Tooltip
                  title={
                    <FormattedMessage
                      id={getTranslationKey("advancedRecorder", "tooltip", "back")}
                    />
                  }
                >
                  <LeftOutlined />
                </Tooltip>
              </Menu.Item>

              <Menu.Item
                key="refresh"
                onClick={() => {
                  runnerCommunicator.administration.send(
                    IFrameRunnerAsyncMessages.refresh_navigation,
                    {},
                  );
                }}
                style={menuItemStyle}
              >
                <Tooltip
                  title={
                    <FormattedMessage
                      id={getTranslationKey("advancedRecorder", "tooltip", "refreshPage")}
                    />
                  }
                >
                  <ReloadOutlined />
                </Tooltip>
              </Menu.Item>
            </Menu>
            <Input
              style={{
                height: controlPanelHeight,
                background: NAVBAR_COLOR_LIGHT,
                color: "Gainsboro",
                border: "none",
              }}
              type="text"
              value={this.getDisplayUrl()}
              disabled
            />
            <Tooltip
              title={
                <FormattedMessage id={getTranslationKey("advancedRecorder", "tooltip", "close")} />
              }
            >
              <Button
                style={{ height: controlPanelHeight, borderRadius: 0 }}
                icon={<CloseOutlined />}
                danger
                type="primary"
                onClick={onClose}
              />
            </Tooltip>
            {isRunnerOnRightSide && (
              <Menu
                theme="dark"
                mode="horizontal"
                style={{ lineHeight: "40px", backgroundColor: NAVBAR_COLOR, borderRadius: 0 }}
                selectedKeys={[]}
              >
                {hideButton}
              </Menu>
            )}
          </InputGroup>
        </FrameBarContainer>

        <FrameContainer visible={visibility}>
          {readyToLoadIFrame ? (
            <EAIframe
              ref={this.locationReplace}
              style={!this.state.isFrameInitialized ? { display: "none" } : {}}
              id={RUNNER_FRAME_ID}
              name="eaApp"
              onLoad={this.onFrameLoad}
            />
          ) : (
            <SpinnerContainer>
              <Spin />
            </SpinnerContainer>
          )}
        </FrameContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: IRunnerFrameProps) => ({
  actions: {
    ...bindActionCreators(getRunnerTableActions(props.sessionId), dispatch),
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: IRunnerFrameProps) => ({
    lastRecordedStep: getLastRecorderStep(state, props.sessionId),
    isRecording: getSessionParams(state, props.sessionId).isRecording,
    mode: getRunnerMode(state, props.sessionId),
    ...props,
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(RunnerFrame);
