import { Spin } from "antd";
import styled from "@emotion/styled";
import * as React from "react";
import { API } from "@app/services/api/api";
import { AggregatedJobLog } from "@ea/shared_types/types";
import { InjectedIntlProps, injectIntl, FormattedMessage } from "react-intl";
import AggregatedJobLogsAreaChart from "../components/AggregatedJobLogsAreaChart";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { EXECUTION_STATE } from "@ea/shared_types/types";

interface AggregatedJobExecutionAreaChartProps {
  data?: AggregatedJobLog[];
  projectId?: number;
  schedulerJobId?: number;
  isInModal?: boolean;
}

interface AggregatedJobExecutionAreaChartState {
  data?: AggregatedJobLog[];
  width: number;
  height: number;
  isLoading: boolean;
}

const SpinContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minWidth: "450px",
  paddingTop: "20%",
});

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
  textAlign: "center",
});
class AggregatedJobExecutionAreaChart extends React.Component<
  InjectedIntlProps & AggregatedJobExecutionAreaChartProps,
  any
> {
  state: AggregatedJobExecutionAreaChartState = {
    data: undefined,
    height: 0,
    width: 0,
    isLoading: false,
  };
  resize;
  widthMultiplier = this.props.isInModal ? 0.8 : 0.6;
  heightMultiplier = this.props.isInModal ? 0.7 : 0.7;

  componentDidMount = async () => {
    const { data, projectId, schedulerJobId } = this.props;
    if (!data && (projectId || schedulerJobId)) {
      await this.loadData();
    }
    const width = window.innerWidth * this.widthMultiplier;
    const height = window.innerHeight * this.heightMultiplier;
    this.setState({ width, height });
    this.resize = debounce(this.handleResize, 200);
    window.addEventListener("resize", this.resize);
  };

  componentDidUpdate(prevProps: AggregatedJobExecutionAreaChartProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && !this.props.data) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  loadData = async () => {
    const { projectId, schedulerJobId } = this.props;
    const where = projectId
      ? {
          projectId,
          state: { neq: EXECUTION_STATE.TERMINATED },
          isExcluded: false,
        }
      : { schedulerJobId };
    try {
      this.setState({
        isLoading: true,
      });
      const aggregatedJobLogs = await API.getAggregatedJobLogs({
        filter: { where, order: ["startTime asc"], limit: 50 },
      });
      this.setState({ data: aggregatedJobLogs });
    } catch (e) {
      toast.error(<FormattedMessage id={getTranslationKey("messages", "error", "loading")} />);
    }
    this.setState({
      isLoading: false,
    });
  };

  handleResize = () => {
    this.setState({
      width: window.innerWidth * this.widthMultiplier,
      height: window.innerHeight * this.heightMultiplier,
    });
  };

  render() {
    const { isLoading, height, width, data } = this.state;
    const dataToChart = this.props.data || data;

    return isLoading ? (
      <SpinContainer>
        <Spin
          tip={this.props.intl.formatMessage({
            id: getTranslationKey("common", "loadingStatistics"),
          })}
        />
      </SpinContainer>
    ) : dataToChart && dataToChart.length > 1 ? (
      <Container>
        <h3>
          {<FormattedMessage id={getTranslationKey("common", "label", "progressionChart")} />}
        </h3>
        <AggregatedJobLogsAreaChart data={dataToChart} height={height} width={width} />
      </Container>
    ) : (
      <h3>{<FormattedMessage id={getTranslationKey("common", "notEnoughData")} />}</h3>
    );
  }
}

export default injectIntl(AggregatedJobExecutionAreaChart);
