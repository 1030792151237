import { ColProps } from "antd/lib/grid/col";
import * as React from "react";

export type LayoutContextType = {
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  readOnly?: boolean;
};

const LayoutContext = React.createContext<LayoutContextType>({});
LayoutContext.displayName = "LayoutContext";

export { LayoutContext };
