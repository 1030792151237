import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { IssueTrackingToolConfiguration } from "@ea/shared_types/types";
import { TableActionParams } from "@ea/shared_components/redux/common.models";

const actionCreator = actionCreatorFactory("ITS_CONFIGURATIONS_MODULE");

export const moduleActions = createDataModuleActions<IssueTrackingToolConfiguration>(actionCreator);
export const getItsConfigurationsTableActions = createTableActionsGetter(
  normalizeActions(moduleActions.table),
);

export const itsConfigurationsActionCreators = {
  ...moduleActions,
  fetch: actionCreator.async<TableActionParams<{}>, any, any>("FETCH_CONFIGURATIONS"),
};

export const itsConfigurationsActions = {
  ...normalizeActions(moduleActions.data),
  fetch: itsConfigurationsActionCreators.fetch.started,
};
