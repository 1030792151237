export const disableUserEvents = (element, events: string[]) => {
  if (!element) {
    return;
  }

  const handler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    return false;
  };

  for (let i = 0, l = events.length; i < l; i++) {
    element.addEventListener(events[i], handler);
  }

  return () => {
    for (let i = 0, l = events.length; i < l; i++) {
      element.removeEventListener(events[i], handler);
    }
  };
};
