import * as React from "react";
import { Route } from "react-router";
import UserGroupsDetailsContainer from "./UserGroupsDetails.container";
import UserGroupsTableContainer from "./UserGroupsTable.container";

const UserGroupsLoaderContainer = () => {
  return (
    <>
      <Route exact path="/settings/userGroups/" component={UserGroupsTableContainer} />
      <Route path="/settings/userGroups/:id" component={UserGroupsDetailsContainer} />
    </>
  );
};

export default UserGroupsLoaderContainer;
