import { MESSAGE_TYPE } from "@ea/shared_types/types";

export const MessageConfig = {
  [MESSAGE_TYPE.ERROR]: {
    colors: {
      background: "#fde7e9",
      icon: "#a80000",
    },
    iconType: "exclamation-circle-o",
  },
  [MESSAGE_TYPE.WARNING]: {
    colors: {
      background: "#fff4ce",
      icon: "#666666",
    },
    iconType: "warning",
  },
  [MESSAGE_TYPE.INFO]: {
    colors: {
      background: "#f4f4f4",
      icon: "#666666",
    },
    iconType: "info-circle-o",
  },
};
