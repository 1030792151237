import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { Token } from "@ea/shared_types/types";
import { moduleActions } from "./apiKey.actions";

const initialState = {
  ...getDataReducerInitialState<Token>(),
};

export type ApiKeyReducer = typeof initialState;

const dataReducer = createDataReducer<Token>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
