import * as React from "react";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import CommandBarDropdown from "@ea/shared_components/CommandBar/CommandBarDropdown";
import ToggleCommandBarButton from "@ea/shared_components/CommandBar/ToggleCommandBarButton";
import {
  Script,
  PLAY_MODE,
  ROLES,
  ForegroundStartParams,
  ScriptStatus,
  CsvExportAdditionalFields,
} from "@ea/shared_types/types";
import WithAuthorization from "@app/modules/common/WithAuthorization";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faArrowCircleDown, faArrowCircleUp } from "@fortawesome/fontawesome-free-solid";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { HideWhenDocumentationWizard } from "@app/modules/common/HideWhenDocumentationWizard";
import { DataTestIds } from "@app/utils/dataTestIds";

interface ScriptsCommandBarProps {
  onNewClick: () => void;
  onCloseClick: (ids: number[]) => void;
  onReload: () => void;
  importScript: () => void;
  exportScript: () => void;
  onRecordClick: (evt, additional?: Partial<ForegroundStartParams>) => void;
  onCloneClick: (ids: number[]) => void;
  onPlayClick: (mode?: PLAY_MODE, optionalParams?: any) => void;
  onExportWithAdditionalFieldsClick: () => void;
  onOpen: () => void;
  onExport?: (additionalFields: CsvExportAdditionalFields) => void;
  isExporting?: boolean;
  selectedScripts: number[];
  showClosed: boolean;
  onShowClosedClick: () => void;
  showWithWorkItems: boolean;
  onShowWithWorkItemsClick: () => void;
  onOptionsClick?: () => void;
  onRecordOptionsClick?: () => void;
  onlyClosedSelected: boolean;
  isAnyClosedSelected: boolean;
  selectedScript?: Script;
}

const AuthorizedToggleCommandBarButton = WithAuthorization(
  [],
  [ROLES.freedocs],
)(ToggleCommandBarButton);
const AuthorizedCommandBarButton = WithAuthorization([], [ROLES.freedocs])(CommandBarButton);

class ScriptsCommandBar extends React.Component<ScriptsCommandBarProps> {
  private container: HTMLElement;
  isPlayDisabled() {
    return (
      !this.props.selectedScript ||
      (this.props.selectedScript && this.props.selectedScript.stepCount === 2)
    );
  }

  isRecordDisabled() {
    return (
      !this.props.selectedScript ||
      (this.props.selectedScript && this.props.selectedScript.stepCount > 2) ||
      [ScriptStatus.PUBLISHED, ScriptStatus.CLOSED].includes(this.props.selectedScript.status)
    );
  }

  public render() {
    const {
      importScript,
      exportScript,
      onExport,
      isExporting,
      onlyClosedSelected,
      selectedScripts,
      onNewClick,
      onCloneClick,
      onCloseClick,
      isAnyClosedSelected,
      onReload,
      onOptionsClick,
      onRecordClick,
      onShowClosedClick,
      showClosed,
      onShowWithWorkItemsClick,
      showWithWorkItems,
      onOpen,
      onRecordOptionsClick,
      onPlayClick,
      onExportWithAdditionalFieldsClick,
    } = this.props;
    const recordButtons = [
      ...[
        <CommandBarButton
          key="record"
          name="record"
          onClick={onRecordClick}
          text={getTranslationKey("commandBar", "record")}
          size="default"
          icon="VideoCameraOutlined"
          disabled={this.isRecordDisabled()}
          onOptionsClick={onRecordOptionsClick}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_RECORD}
        />,
      ],

      <CommandBarButton
        key="play"
        name="play"
        onClick={() => onPlayClick()}
        text={getTranslationKey("commandBar", "play")}
        size="default"
        icon="PlayCircleOutlined"
        disabled={this.isPlayDisabled()}
        onOptionsClick={onOptionsClick ? onOptionsClick : undefined}
        data-testid={DataTestIds.COMMANDBAR_BUTTON_PLAY}
      />,
    ];
    return (
      <CommandBar data-testid={DataTestIds.COMMANDBAR_SCRIPTS}>
        <CommandBarButton
          name="new"
          onClick={onNewClick}
          text={getTranslationKey("commandBar", "add")}
          icon="PlusOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_NEW}
        />
        <CommandBarButton
          name="remove"
          onClick={() => onCloseClick(selectedScripts)}
          text={getTranslationKey("commandBar", "close")}
          icon="DeleteOutlined"
          disabled={selectedScripts.length === 0 || onlyClosedSelected}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE}
        />
        <CommandBarButton
          name="clone"
          onClick={() => onCloneClick(selectedScripts)}
          text={getTranslationKey("commandBar", "clone")}
          icon="CopyOutlined"
          disabled={selectedScripts.length === 0 || isAnyClosedSelected}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_CLONE}
        />
        <AuthorizedCommandBarButton
          name="export"
          onClick={exportScript}
          text={getTranslationKey("commandBar", "export")}
          icon={<FontAwesomeIcon icon={faArrowCircleDown} />}
          disabled={selectedScripts.length === 0 || isAnyClosedSelected}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_EXPORT}
        />
        <AuthorizedCommandBarButton
          name="import"
          onClick={importScript}
          text={getTranslationKey("commandBar", "import")}
          icon={<FontAwesomeIcon icon={faArrowCircleUp} />}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_IMPORT}
        />
        <CommandBarButton
          name="refresh"
          onClick={onReload}
          text={getTranslationKey("commandBar", "refresh")}
          icon="ReloadOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
        />
        <AuthorizedToggleCommandBarButton
          name="showClosed"
          onClick={onShowClosedClick}
          text={getTranslationKey("commandBar", showClosed ? "hideClosed" : "showClosed")}
          icon="LockOutlined"
          toggled={showClosed}
          data-testid={
            showClosed
              ? DataTestIds.COMMANDBAR_BUTTON_HIDE_CLOSED
              : DataTestIds.COMMANDBAR_BUTTON_SHOW_CLOSED
          }
        />
        <HideWhenDocumentationWizard>
          {() => (
            <AuthorizedToggleCommandBarButton
              name="showWithWorkItems"
              onClick={onShowWithWorkItemsClick}
              text={getTranslationKey(
                "commandBar",
                showWithWorkItems ? "hideWithWorkItems" : "showWithWorkItems",
              )}
              icon="TagsOutlined"
              toggled={showWithWorkItems}
              data-testid={
                showWithWorkItems
                  ? DataTestIds.COMMANDBAR_BUTTON_HIDE_WORK_ITEMS
                  : DataTestIds.COMMANDBAR_BUTTON_SHOW_WORK_ITEMS
              }
            />
          )}
        </HideWhenDocumentationWizard>
        <CommandBarDropdown
          name="exportDropdown"
          text={getTranslationKey("commandBar", "exportToCsv")}
          icon="FileExcelOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_EXPORT_CSV_DROPDOWN}
          loading={isExporting}
        >
          {onExport && (
            <CommandBarButton
              name="export"
              onClick={onExport}
              text={getTranslationKey(
                "commandBar",
                selectedScripts.length > 0 ? "exportSelectedToCsv" : "exportToCsv",
              )}
              icon="FileExcelOutlined"
              loading={isExporting}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_EXPORT_CSV}
            />
          )}
          {onExportWithAdditionalFieldsClick && (
            <CommandBarButton
              name="exportWithAdditional"
              onClick={onExportWithAdditionalFieldsClick}
              text={getTranslationKey("common", "header", "exportWithAdditional")}
              icon="FileExcelOutlined"
              loading={isExporting}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_EXPORT_CSV_ADDITIONAL}
            />
          )}
        </CommandBarDropdown>
        <CommandBarButton
          name="open"
          onClick={onOpen}
          text={getTranslationKey("commandBar", "open")}
          icon="LoginOutlined"
          disabled={selectedScripts.length !== 1}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_OPEN}
          far
        />
        <CommandBarDropdown
          name="start"
          text={getTranslationKey("commandBar", "start")}
          icon="LogoutOutlined"
          disabled={selectedScripts.length !== 1 || onlyClosedSelected}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_START}
        >
          {recordButtons}
        </CommandBarDropdown>
      </CommandBar>
    );
  }
}

export default ScriptsCommandBar;
