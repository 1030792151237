import { Filters } from "@ea/shared_components/redux/common.filters";
import { objectValues } from "@ea/shared_components/utils/object";
import { Log, StepLog } from "@ea/shared_types/types";

import logsEpicsMap from "./logs.epics";
import * as logsReducers from "./logs.reducers";

const logsEpics = objectValues(logsEpicsMap);

type StepLogsState = Filters<StepLog>;
type LogsState = {
  statisticsVisible?: boolean;
  persistentQuery: any;
};

export { logsReducers, logsEpics, StepLogsState, LogsState };

export { default as Logs } from "./Logs.container";
