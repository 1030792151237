import { Button, Modal } from "antd";
import { FORM_ERROR, FormApi } from "final-form";
import styled from "@emotion/styled";
import * as React from "react";
import { Form, FormRenderProps } from "react-final-form";

import { finalFormNormalizedSubmit } from "../Form/formHelpers";
import { PanelFormLayout } from "../Form/FormLayouts";
import LoaderArea from "../LoaderArea/LoaderArea";
import { MessageBar } from "../MessageBar";
import { Panel, PanelType } from "../Panel";
import { FormHint, Hint, printFormValues } from "../Form/FormDebugElements";
import arrayMutators from "final-form-arrays";
import { FormattedMessage } from "react-intl";
import { MESSAGE_TYPE } from "@ea/shared_types/types";
import { DataTestIds } from "../utils/dataTestHelpers";

const Container = styled.div({
  flexGrow: 1,
  overflow: "auto",
  padding: "24px",
  fontSize: "14px",
  lineHeight: 1.5,
});

const FooterContainer = styled.div({
  borderTop: "1px solid #e8e8e8",
  padding: "10px 16px",
  borderRadius: "0 0 4px 4px",
  textAlign: "left",
});

export type PanelFormRenderFooterProps = {
  form: FormRenderProps;
  allowPrisitineSubmit: boolean | undefined;
  isSaving: boolean;
  onCancelClick: () => any;
};

// todo: migrate FORM API to form field
export type RenderProps = FormApi & {
  values: any;
  isBusy: boolean;
  onError: (error: any) => void;
  form: FormApi;
};
export interface IPanelProps {
  validate?: (values: any) => any | Promise<any>;
  visibility: boolean;
  panelType?: PanelType;
  headerText: string | React.ReactNode;
  okButtonText?: string;
  cancelButtonText?: string;
  onOkClick: (values: any) => void;
  onCancelClick: () => void;
  initialValues?: any;
  allowPrisitineSubmit?: boolean;
  destroyOnUnregister?: boolean;
  onRenderFooterContent?: (props: PanelFormRenderFooterProps) => React.ReactNode;
  render: (props: RenderProps) => JSX.Element;
  customWidth?: number;
}

export interface IPanelState {
  isSaving: boolean;
  error: string | undefined;
}

export class PanelWithFormIntegration extends React.Component<
  IPanelProps & { form: FormRenderProps },
  IPanelState
> {
  state = {
    isSaving: false,
    error: undefined,
  };

  componentDidCatch(error: Error) {
    this.setState({
      error: error.message,
    });
  }

  onError = (error) => {
    this.setState({
      error: error.message,
    });
  };

  onOkClick = async () => {};

  onCancelClick = () => {
    this.props.form.form.reset();
    this.props.onCancelClick();
  };

  renderFooterContent = () => {
    const { okButtonText, cancelButtonText, form, allowPrisitineSubmit } = this.props;
    const { submitting, pristine } = form;
    const { isSaving } = this.state;
    return [
      <Button
        key="ok"
        type="primary"
        htmlType="submit"
        loading={isSaving}
        disabled={allowPrisitineSubmit === true ? submitting : submitting || pristine}
        data-testid={DataTestIds.PANEL_BUTTON_SUBMIT}
      >
        <FormattedMessage id={okButtonText || "Ok"} defaultMessage="Ok" />
      </Button>,
      <Button
        style={{ marginLeft: "8px" }}
        key="cancel"
        onClick={this.onCancelClick}
        disabled={submitting}
        data-testid={DataTestIds.PANEL_BUTTON_CANCEL}
      >
        <FormattedMessage id={cancelButtonText || "Cancel"} defaultMessage="Cancel" />
      </Button>,
    ];
  };

  render() {
    const {
      visibility,
      panelType,
      headerText,
      form,
      render,
      onRenderFooterContent,
      allowPrisitineSubmit,
      customWidth,
    } = this.props;
    const { submitError, submitting, values, handleSubmit } = form;
    const { isSaving } = this.state;
    return (
      <Panel
        visibility={visibility}
        type={panelType}
        headerText={
          typeof headerText === "string" ? (
            <FormattedMessage id={headerText!} defaultMessage="Header" />
          ) : (
            headerText
          )
        }
        onRenderFooterContent={() => null}
        bodyStyle={{ height: "100%", padding: 0 }}
        isFooterAtBottom
        customWidth={customWidth}
      >
        {visibility && (
          <form
            style={{
              display: "flex",
              padding: 0,
              flexDirection: "column",
              height: "100%",
            }}
            onSubmit={(event) => {
              const submitResult = handleSubmit(event);

              if (submitResult === undefined) {
                return;
              }

              (submitResult as any).then((result) => {
                if (result === undefined || !result[FORM_ERROR]) {
                  form.form.reset();
                }
              });
            }}
          >
            <Container>
              <LoaderArea isSaving={submitting} label="Saving...">
                <PanelFormLayout>
                  {render({
                    ...form.form,
                    isBusy: submitting,
                    onError: this.onError,
                    values,
                    form: form.form,
                  })}
                </PanelFormLayout>
              </LoaderArea>
              {process.env.NODE_ENV !== "production" && (
                <FormHint>
                  <Hint>{printFormValues(values)}</Hint>
                </FormHint>
              )}
            </Container>
            {submitError ? (
              <MessageBar
                messages={[
                  {
                    type: MESSAGE_TYPE.ERROR,
                    text: submitError,
                  },
                ]}
              />
            ) : null}
            <FooterContainer>
              {onRenderFooterContent
                ? onRenderFooterContent({
                    form,
                    allowPrisitineSubmit,
                    isSaving,
                    onCancelClick: this.onCancelClick,
                  })
                : this.renderFooterContent()}
            </FooterContainer>
          </form>
        )}
      </Panel>
    );
  }
}

const PanelFormFinal: React.SFC<IPanelProps> = (props) => {
  if (!props.visibility) {
    return null;
  }

  return (
    <Form
      validate={props.validate}
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={finalFormNormalizedSubmit(props.onOkClick)}
      initialValues={props.initialValues}
      render={(formProps) => <PanelWithFormIntegration {...props} form={formProps} />}
      destroyOnUnregister={!!props.destroyOnUnregister}
    />
  );
};

export default PanelFormFinal;
