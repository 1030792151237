import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { ProjectTreeNode } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { TreeSearchField } from "@ea/shared_components/Form/Fields/TreeSearchField";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { getIn } from "final-form";
import AzureFormChunk from "@app/modules/issueTrackingTool/components/AzureFormChunk";
import { isSame } from "@ea/shared_components/utils/array";

interface ICreateScriptFormProps {
  projects: ProjectTreeNode[];
  values: any;
  itsOptions: OptionType[];
  change: (name: string, value: any) => void;
  projectsChildrenMap: { [key: number]: number[] };
}

interface ICreateEditScriptFormState {
  selected: string[];
}

type Props = ICreateScriptFormProps & InjectedIntlProps;

const formItemLayoutTree = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 15 },
    sm: { span: 15 },
  },
};

class ExportProjectsForm extends React.Component<Props, ICreateEditScriptFormState> {
  state: ICreateEditScriptFormState = { selected: [] };

  componentDidMount() {
    this.setState({ selected: getIn(this.props.values, "projectIds") || [] });
  }

  onChange = (selected: string[]) => {
    const { projectsChildrenMap, values } = this.props;

    if (isSame(selected, getIn(values, "projectIds"))) {
      return;
    }

    let newSelected = [...selected];

    const childSelected = (children, sourceArray) =>
      sourceArray.some((id) => children.includes(Number.parseInt(id, 10)));

    // add project children to selected (if it has any children)
    for (const projectId of selected) {
      const children = projectsChildrenMap[projectId];
      if (
        children?.length > 0 &&
        !childSelected(children, newSelected) &&
        !childSelected(children, this.state.selected)
      ) {
        newSelected = [...newSelected, ...projectsChildrenMap[projectId].map((id) => `${id}`)];
      }
    }

    this.setState({ selected: newSelected });
  };

  componentDidUpdate(nextProps: Props, nextState: ICreateEditScriptFormState) {
    const { change } = this.props;
    if (!isSame(this.state.selected, nextState.selected)) {
      change("projectIds", this.state.selected);
    }
  }

  render() {
    const { projects, itsOptions, values, change } = this.props;
    const { selected } = this.state;

    return (
      <>
        <FormLayout {...formItemLayout}>
          <SelectField
            name="itsId"
            placeholder={getTranslationKey("its", "placeholder", "its")}
            required
            label={getTranslationKey("its", "label", "its")}
            options={itsOptions}
          />
          <AzureFormChunk {...this.props} itsId={getIn(values, "itsId")} change={change} />
        </FormLayout>

        <FormLayout {...formItemLayoutTree}>
          <TreeSearchField
            label={getTranslationKey("common", "label", "projectsToExport")}
            name="projectIds"
            required
            onChange={this.onChange}
            nodes={projects}
            withTags
            multi
            checkStrictly
            values={projects}
            selected={selected}
            defaultExpandedKeys={selected}
          />
        </FormLayout>
      </>
    );
  }
}

export default injectIntl(ExportProjectsForm);
