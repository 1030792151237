import { Tag } from "antd";
import * as React from "react";

import { COLORS } from "@app/styles/consts";
import { EXECUTION_STATE } from "@ea/shared_types/types";

interface IStateFieldProps {
  state: EXECUTION_STATE;
}

const style = {
  cursor: "default",
};

const StateField: React.FunctionComponent<IStateFieldProps> = ({ state }) => {
  if (!state) {
    return null;
  }

  return (
    <Tag style={style} color={COLORS.STATE[state]}>
      {state}
    </Tag>
  );
};

export default StateField;
