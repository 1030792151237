import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { Token } from "@ea/shared_types/types";
import * as React from "react";
import ApiKeyTTLField from "@app/modules/apiKey/components/ApiKeyTTLField";
import { GetAsTime } from "@app/modules/common/time";
import { Input } from "antd";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import ApiKeyTokenBox from "@app/modules/common/ApiKeyTokenBox";

export const API_KEY_COLUMNS_CONFIG: PlainObject<CustomColumn<Token>> = {
  name: {
    label: "table.name",
    dataIndex: "name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  id: {
    label: "table.apiKey",
    dataIndex: "id",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    render: (text) => <ApiKeyTokenBox token={text} />,
  },
  ttl: {
    label: "table.ttl",
    dataIndex: "ttl",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    render: (text) => <ApiKeyTTLField type={text} />,
  },
  created: {
    label: "table.createdAt",
    dataIndex: "created",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "created" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
};

export const API_KEY_COLUMNS: ColumnConfig<Token>[] = [
  {
    props: API_KEY_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: API_KEY_COLUMNS_CONFIG.id,
    frameworkProps: {
      width: "52%",
    },
  },
  {
    props: API_KEY_COLUMNS_CONFIG.ttl,
    frameworkProps: {
      width: "13%",
    },
  },
  {
    props: API_KEY_COLUMNS_CONFIG.created,
    frameworkProps: {
      width: "15%",
    },
  },
];

export const API_KEY_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_API_KEY",
  },
};
