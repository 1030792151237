import hoistNonReactStatics from "hoist-non-react-statics";
import * as React from "react";
import { Field, FieldMetaState } from "react-final-form";

import { InjectedIntlProps, injectIntl } from "react-intl";
import { getHOCName } from "../utils/helpers";
import { ReadonlyFormItem } from "./Form.common";
import FormItemWrapper, { IFormItemWrapperProps } from "./FormItem/FormItemWrapper";
import { removeUndefinedKeys } from "./formHelpers";
import validators, { composeValidators } from "./validators";

export interface FieldInputProps<FieldValue, T extends HTMLElement = HTMLElement> {
  name: string;
  onBlur: (event?: React.FocusEvent<T>) => void;
  onChange: (event: React.ChangeEvent<T> | any) => void;
  onFocus: (event?: React.FocusEvent<T>) => void;
  // type?: string; todo: added in the latest final-form, breaks our compilation
  value: FieldValue;
  checked?: boolean;
  multiple?: boolean;
}
export interface FieldRenderProps<FieldValue, T extends HTMLElement = HTMLElement> {
  input: FieldInputProps<FieldValue, T>;
  meta: FieldMetaState<FieldValue>;
}

type InjectedProps = Partial<FieldRenderProps<any>["input"]>;

// props must be the same as ReactFinalForm Field props. We need to declare them as Type, not Interface,
// that's why we are copying them here
type OwnProps = {
  allowNull?: boolean;
  format?: ((value: any, name: string) => any) | null;
  parse?: ((value: any, name: string) => any) | null;
  name: string;
  isEqual?: (a: any, b: any) => boolean;
  subscription?: any;
  validate?: (value: any, allValues: object) => any;
  value?: any;
  onChange?: <T>(event: React.ChangeEvent<T> | any) => any | void;
  ref?: any;
  overwriteOnChange?: boolean;
  defaultValue?: any;
} & IFormItemWrapperProps["formItem"];

type DefaultProps = Partial<{
  customFormat: (value: any) => any;
  customParse: any;
  customValidate: any;
}>;

type MapperProps<T> = FieldRenderProps<any>["input"] & T & ReadonlyFormItem;
const createFormField = <OriginalProps extends ReadonlyFormItem, AdditionalProps = {}>(
  Component: React.ComponentType<OriginalProps>,
  mapper: (
    props: MapperProps<ConditionalOmit<OriginalProps, keyof InjectedProps> & AdditionalProps>,
  ) => OriginalProps,
  defaultProps?: (intl: InjectedIntlProps["intl"]) => DefaultProps,
) => {
  type Props = ConditionalOmit<OriginalProps & ReadonlyFormItem, keyof InjectedProps> &
    OwnProps &
    AdditionalProps;

  class WithFormField extends React.Component<Props & InjectedIntlProps> {
    static displayName = getHOCName((WithFormField as any).displayName, Component);
    static WrappedComponent = Component;

    render() {
      const {
        children,

        allowNull,
        format,
        isEqual,
        name,
        parse,
        subscription,
        validate,
        value,
        colon,
        formItemClassName,
        formItemControlStyle,
        formItemRowStyle,
        hasFeedback,
        help,
        label,
        labelCol,
        required,
        showHelp,
        status,
        wrapperCol,
        onChange,
        overwriteOnChange,
        defaultValue,
        intl,
        ...other
      } = this.props as any;

      const getDefaultProps = defaultProps ? defaultProps : () => ({} as DefaultProps);
      const { customFormat, customParse, customValidate } = getDefaultProps(intl);
      const originalProps: OriginalProps & AdditionalProps = other;

      const validateFunc = validate || customValidate;

      const fieldProps = {
        allowNull,
        format,
        isEqual,
        name,
        parse:
          parse ||
          customParse ||
          ((v) =>
            v === "" || v === undefined ? (defaultValue === undefined ? null : defaultValue) : v),
        subscription,
        validate: validateFunc,
        value,
        defaultValue,
      };
      removeUndefinedKeys(fieldProps);

      const formItemProps = {
        colon,
        formItemClassName,
        formItemRowStyle,
        formItemControlStyle,
        hasFeedback,
        help,
        label,
        labelCol,
        required,
        showHelp,
        status,
        wrapperCol,
        readOnly: other.readOnly,
      };
      removeUndefinedKeys(formItemProps);

      if (formItemProps.label) {
        formItemProps.label =
          intl.formatMessage({ id: formItemProps.label }) || formItemProps.label;
      }

      if (formItemProps.help) {
        formItemProps.help = intl.formatMessage({ id: formItemProps.help }) || formItemProps.help;
      }

      const ignoreTs: any = originalProps;
      if (ignoreTs.placeholder) {
        ignoreTs.placeholder =
          intl.formatMessage({ id: ignoreTs.placeholder }) || ignoreTs.placeholder;
      }

      const { requiredValidator } = validators(intl);

      const innerComposeValidator = (req, method) =>
        method ? composeValidators(req, method) : composeValidators(req);
      const validator = required
        ? innerComposeValidator(requiredValidator, validateFunc)
        : validateFunc;

      // default format in final-forms converts undefined / null to empty string and breaks placeholders in antd.
      // Antd display placeholder only when value is null or undefined
      const defaultFormat = (v) => (v === null ? undefined : v);
      return (
        <Field
          {...fieldProps}
          validate={validator ? (v) => validator(v, intl.formatMessage) : validator}
          format={format || customFormat || defaultFormat}
        >
          {(props) => {
            const { input, meta } = props;

            if (onChange) {
              const originalOnChange = input.onChange;
              input.onChange = (v: any) => {
                const newValue = onChange(v);
                originalOnChange(overwriteOnChange ? newValue : v);
              };
            }
            const mappedProps = mapper(
              Object.assign({}, originalProps, input, {
                validationError: meta.error && meta.touched,
                ref: (originalProps as any).forwardedRef,
              }),
            );

            return (
              <FormItemWrapper {...meta} formItem={formItemProps}>
                {(wrapperProps) => {
                  return (
                    <Component {...wrapperProps} {...mappedProps}>
                      {children}
                    </Component>
                  );
                }}
              </FormItemWrapper>
            );
          }}
        </Field>
      );
    }
  }

  const WithFormFieldComplete = injectIntl(
    hoistNonReactStatics(WithFormField, Component as any),
  ) as unknown as React.ComponentType<Props>;

  return React.forwardRef((props, ref) => (
    <WithFormFieldComplete {...props} {...({ forwardedRef: ref } as any)} />
  )) as typeof WithFormFieldComplete;
};

export default createFormField;
