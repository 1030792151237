import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";
import {
  faBan,
  faBolt,
  faHandRock,
  faPlus,
  faTrash,
  faBookmark,
  faSquare,
  faCheckCircle,
} from "@fortawesome/fontawesome-free-solid";
import { faCircle, faDotCircle } from "@fortawesome/fontawesome-free-regular";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import DefaultCommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import CommandBarDropdown from "@ea/shared_components/CommandBar/CommandBarDropdown";
import WithAuthorization from "@app/modules/common/WithAuthorization";
import { ROLES } from "@ea/shared_types/types";
import { getCreateableManually } from "@app/packs/packs.helpers";
import { SaveOutlined, EditOutlined, BarsOutlined, GatewayOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { DataTestIds } from "@app/utils/dataTestIds";
interface StepsCommandBarProps {
  selected: number[];
  onRemoveClick: (ids: number[]) => void;
  openPanel: (id: string) => void;
  isDragEnabled: boolean;
  toggleDragMode: () => void;
  toggleDisable: (value: boolean) => void;
  toggleOptional: (value: boolean) => void;
  toggleDataView: () => void;
  onMarkClick: () => void;
  isDataViewVisible: boolean;
  toggleEditExpandedRows: () => void;
  onDrawingOverlayToggle: () => void;
  isExpandedEditModeOn: boolean;
  isEditingStep: boolean;
  isDrawingOverlayEnabled?: boolean;
  quickActionsDisabled?: boolean;
  newStepDisabled?: boolean;
  disabled?: boolean;
}

const DefaultAuthorizedCommandBarDropdown = WithAuthorization(
  [],
  [ROLES.freedocs],
)(CommandBarDropdown);

const COMMAND_BAR_BUTTON_DEFAULT_STYLE = {
  padding: "4px 10px",
};

const CommandBarButton = styled(DefaultCommandBarButton)(COMMAND_BAR_BUTTON_DEFAULT_STYLE);

const AuthorizedCommandBarDropdown = styled(DefaultAuthorizedCommandBarDropdown)(
  COMMAND_BAR_BUTTON_DEFAULT_STYLE,
);

class RecorderStepsCommandBar extends React.Component<StepsCommandBarProps> {
  render() {
    const {
      openPanel,
      selected,
      onRemoveClick,
      toggleDisable,
      toggleDragMode,
      toggleOptional,
      isDragEnabled,
      onMarkClick,
      toggleDataView,
      isDataViewVisible,
      toggleEditExpandedRows,
      isExpandedEditModeOn,
      onDrawingOverlayToggle,
      isDrawingOverlayEnabled,
      isEditingStep,
      quickActionsDisabled,
      newStepDisabled,
      disabled,
    } = this.props;
    const createableSteps = getCreateableManually();
    const disableAll = disabled !== undefined ? disabled : false;
    return (
      <div>
        <CommandBar data-testid={DataTestIds.COMMANDBAR_RECORDER_STEPS}>
          <AuthorizedCommandBarDropdown
            name="new"
            text={getTranslationKey("commandBar", "newStep")}
            icon={<FontAwesomeIcon icon={faPlus} />}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_NEW}
            disabled={
              disableAll ||
              (newStepDisabled !== undefined ? newStepDisabled : false) ||
              selected.length > 1
            }
          >
            {createableSteps.map((s) => (
              <CommandBarButton
                key={s.id}
                name={s.id}
                onClick={() => openPanel(s.id)}
                text={s.createableManually!.text}
                icon={<FontAwesomeIcon icon={s.createableManually!.icon} />}
                size="default"
                data-testid={DataTestIds.getNewStepButtonTestId(s.id)}
              />
            ))}
          </AuthorizedCommandBarDropdown>
          <CommandBarButton
            name="removeSelected"
            size="default"
            onClick={() => onRemoveClick(selected)}
            text={getTranslationKey("commandBar", "remove")}
            icon={<FontAwesomeIcon icon={faTrash} />}
            disabled={selected.length === 0 || isDragEnabled}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE_SELECTED}
          />
          <CommandBarButton
            far
            name="editExpanded"
            onClick={toggleEditExpandedRows}
            text={getTranslationKey("commandBar", isExpandedEditModeOn ? "save" : "editMode")}
            icon={isExpandedEditModeOn ? <SaveOutlined /> : <EditOutlined />}
            type={isExpandedEditModeOn ? "primary" : undefined}
            size="default"
            disabled={disableAll}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_EDIT_EXPANDED}
          />
          <CommandBarButton
            name="data"
            onClick={toggleDataView}
            size="default"
            type={isDataViewVisible ? "primary" : undefined}
            text={getTranslationKey("commandBar", "data")}
            icon={<BarsOutlined />}
            disabled={disableAll}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_DATA}
          />
          <AuthorizedCommandBarDropdown
            name="other"
            text={getTranslationKey("commandBar", "quickAction")}
            icon={<FontAwesomeIcon icon={faBolt} />}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_OTHER}
            disabled={
              disableAll || (quickActionsDisabled !== undefined ? quickActionsDisabled : false)
            }
          >
            <CommandBarButton
              name="enableSteps"
              onClick={() => toggleDisable(false)}
              text={getTranslationKey("commandBar", "enableSteps")}
              icon={<FontAwesomeIcon icon={faCheckCircle} />}
              size="default"
              data-testid={DataTestIds.COMMANDBAR_BUTTON_ENABLE_STEPS}
              disabled={selected.length === 0 || isDragEnabled}
            />
            <CommandBarButton
              name="disableSteps"
              onClick={() => toggleDisable(true)}
              text={getTranslationKey("commandBar", "disbaleSteps")}
              icon={<FontAwesomeIcon icon={faBan} />}
              size="default"
              disabled={selected.length === 0 || isDragEnabled}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_DISABLE_STEPS}
            />
            <CommandBarButton
              name="unsetStepsOptional"
              text={getTranslationKey("commandBar", "unsetStepsOptional")}
              size="default"
              icon={<FontAwesomeIcon icon={faDotCircle} />}
              onClick={() => toggleOptional(false)}
              disabled={selected.length === 0 || isDragEnabled}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_UNSET_OPTIONAL}
            />
            <CommandBarButton
              name="setStepsOptional"
              text={getTranslationKey("commandBar", "setStepsOptional")}
              size="default"
              icon={<FontAwesomeIcon icon={faCircle} />}
              onClick={() => toggleOptional(true)}
              disabled={selected.length === 0 || isDragEnabled}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_SET_OPTIONAL}
            />
            <CommandBarButton
              name="setRework"
              size="default"
              onClick={() => onMarkClick()}
              text={getTranslationKey(
                "commandBar",
                selected.length === 0 ? "resetRework" : "setRework",
              )}
              icon={<FontAwesomeIcon icon={faBookmark} />}
              disabled={selected.length > 1 || isDragEnabled}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_SET_REWORK}
            />
            <CommandBarButton
              name="reorder"
              onClick={toggleDragMode}
              text={
                !isDragEnabled
                  ? getTranslationKey("commandBar", "reorder")
                  : getTranslationKey("commandBar", "stopReorder")
              }
              type={isDragEnabled ? "primary" : undefined}
              icon={<FontAwesomeIcon icon={faHandRock} />}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_REORDER}
            />
            <CommandBarButton
              name="drawOverlay"
              onClick={onDrawingOverlayToggle}
              text={
                !isDrawingOverlayEnabled
                  ? getTranslationKey("commandBar", "enableDrawOverlay")
                  : getTranslationKey("commandBar", "disableDrawOverlay")
              }
              type={isDrawingOverlayEnabled ? "primary" : undefined}
              icon={<GatewayOutlined />}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_DRAW_OVERLAY}
            />
          </AuthorizedCommandBarDropdown>
        </CommandBar>
      </div>
    );
  }
}

export default RecorderStepsCommandBar;
