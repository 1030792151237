import * as React from "react";
import styled from "@emotion/styled";

interface INonIdealStateProps {
  text: string | React.ReactNode;
}

const Container = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});

const NonIdealState: React.FunctionComponent<INonIdealStateProps> = ({ text }) => {
  return (
    <Container>
      <h2> {text} </h2>
    </Container>
  );
};

export default NonIdealState;
