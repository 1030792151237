import styled from "@emotion/styled";
import * as React from "react";
import { TableHeaderProps } from "react-virtualized";
import { FormattedMessage } from "react-intl";
import { SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";

const Header = styled.div({
  display: "flex",
  alignItems: "center",
});

export interface IHeaderFilterProps {
  filterElement?: any;
}
type Props = TableHeaderProps;
const headerRenderer =
  (filterElement?: any) =>
  ({ dataKey, label, sortBy, sortDirection }: Props) => {
    const showSortIndicator = sortBy === dataKey;
    const header = (
      <span className="ReactVirtualized__Table__headerTruncatedText" key="label">
        <FormattedMessage id={(label as any) || "none"} />
      </span>
    );
    const children = [header];

    if (showSortIndicator) {
      if (sortDirection === "DESC") {
        children.push(<SortAscendingOutlined />);
      } else {
        children.push(<SortDescendingOutlined />);
      }
    }

    if (filterElement) {
      children.push(filterElement);
    }

    return <Header>{children}</Header>;
  };

export default headerRenderer;
