import * as React from "react";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import { SelectField } from "@ea/shared_components/Form/Fields/SelectField";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { TTL, TTL_LABELS } from "@ea/shared_types/types";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";

interface ICreateApiKeyFormState {}

interface IApiKeyProps {
  readOnly?: boolean;
  values: any;
}

type Props = IApiKeyProps & InjectedIntlProps;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 8 },
  },
};

class CreateApiKeyForm extends React.Component<Props, ICreateApiKeyFormState> {
  ttl;
  constructor(props: Props) {
    super(props);

    this.ttl = [
      {
        text: TTL_LABELS[TTL.T90],
        value: TTL.T90,
      },
      {
        text: TTL_LABELS[TTL.T180],
        value: TTL.T180,
      },
      {
        text: TTL_LABELS[TTL.T365],
        value: TTL.T365,
      },
    ];
  }

  render() {
    const { readOnly } = this.props;

    return (
      <FormLayout {...formItemLayout}>
        <InputField
          name="name"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("common", "placeholder", "name")}
          label={getTranslationKey("common", "label", "name")}
          data-testid={DataTestIds.FORM_INPUT_NAME}
        />
        <SelectField
          name="ttl"
          placeholder={getTranslationKey("apiKey", "placeholder", "ttl")}
          required
          label={getTranslationKey("apiKey", "label", "ttl")}
          options={this.ttl}
          data-testid={DataTestIds.FORM_SELECT_TTL}
        />
      </FormLayout>
    );
  }
}

export default injectIntl(CreateApiKeyForm);
