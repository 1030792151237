import React, { Component } from "react";

const Hideable =
  (condition: () => boolean) =>
  <OriginalProps extends any>(
    WrappedComponent: React.ComponentType<OriginalProps>,
  ): React.ComponentType<OriginalProps> => {
    class HideableComponent extends Component<OriginalProps> {
      render() {
        if (condition()) {
          return null;
        }
        return <WrappedComponent {...this.props} />;
      }
    }

    return HideableComponent;
  };

export default Hideable;
