import { Step, RecorderStep, PlayerStep } from "@ea/shared_types/types";
import { getStepCommand } from "@ea/runner_loader/commands.loader";

export const parseSelectRowValue = (value: any) => {
  const firstFilter = JSON.parse(value);
  return {
    fieldValue: firstFilter.value,
    fieldName: firstFilter.options[firstFilter.key],
  };
};

export const setSelectRowValue = (prevValue, value: string) => {
  let jsonStructure = JSON.parse(prevValue);

  jsonStructure = {
    ...jsonStructure,
    value,
  };

  return JSON.stringify(jsonStructure);
};

export const setKeyValue = (prevValue, key: any, value: string) => {
  let jsonStructure = JSON.parse(prevValue);
  jsonStructure = {
    ...jsonStructure,
    key,
    value,
  };

  return JSON.stringify(jsonStructure);
};

export const getStepLabelParams = (step: Step<any> | RecorderStep<any> | PlayerStep<any>) => {
  const command = getStepCommand(step);

  try {
    return (command?.getLabelParams && command?.getLabelParams(step)) || {};
  } catch (err) {
    console.warn(
      `Problem with fetching step label params for step id: ${
        "id" in step ? step.id : "no_id"
      } with command id: ${step.commandId}`,
    );
    return {};
  }
};

export const STEPS_COPY_STORAGE_KEY = "ea-steps-copypaste";
