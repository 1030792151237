import * as React from "react";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { getTranslationKey } from "@app/translations/translations.helpers";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faArrowCircleDown } from "@fortawesome/fontawesome-free-solid";
import { DataTestIds } from "@app/utils/dataTestIds";

interface ReportTemplatesCommandBarProps {
  onRemoveClick: (ids: number[]) => void;
  onEditClick: () => void;
  onNewClick: () => void;
  onDownloadClick: () => void;
  onReload: () => void;
  selected: any[];
}
class ReportTemplatesCommandBar extends React.Component<ReportTemplatesCommandBarProps> {
  public render() {
    const isDisabled = this.props.selected.length !== 1;
    return (
      <CommandBar data-testid={DataTestIds.COMMANDBAR_REPORT_TEMPLATE}>
        <CommandBarButton
          name="new"
          onClick={this.props.onNewClick}
          text={getTranslationKey("commandBar", "add")}
          icon="PlusOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_NEW}
        />
        <CommandBarButton
          name="edit"
          onClick={this.props.onEditClick}
          text={getTranslationKey("commandBar", "edit")}
          icon="EditOutlined"
          disabled={isDisabled}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_EDIT}
        />
        <CommandBarButton
          name="remove"
          onClick={() => this.props.onRemoveClick(this.props.selected)}
          text={getTranslationKey("commandBar", "remove")}
          icon="DeleteOutlined"
          disabled={isDisabled}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE}
        />
        <CommandBarButton
          name="download"
          onClick={this.props.onDownloadClick}
          text={getTranslationKey("commandBar", "download")}
          icon={<FontAwesomeIcon icon={faArrowCircleDown} />}
          disabled={isDisabled}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_DOWNLOAD}
        />
        <CommandBarButton
          name="refresh"
          onClick={this.props.onReload}
          text={getTranslationKey("commandBar", "refresh")}
          icon="ReloadOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
        />
      </CommandBar>
    );
  }
}
export default ReportTemplatesCommandBar;
