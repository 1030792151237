import * as React from "react";
import { RunnerMode } from "@ea/shared_types/types";
import RunnerTypeFormChunk from "./RunnerTypeFormChunk";
interface IRecordModeFormProps {
  values: any;
  change: (name: string, value: any) => void;
  readOnly?: boolean;
}

const RecordModeForm = (props: IRecordModeFormProps) => (
  <RunnerTypeFormChunk {...props} mode={RunnerMode.RECORDER} />
);

export default RecordModeForm;
