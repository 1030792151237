import * as React from "react";

interface ICommandBarItemProps {
  far?: boolean;
}

const CommandBarItem: React.FunctionComponent<ICommandBarItemProps> = (props) => {
  return (
    <div
      style={{
        alignSelf: props.far ? "flex-end" : "unset",
        marginLeft: props.far ? "auto" : "unset",
      }}
    >
      {props.children}
    </div>
  );
};

export default CommandBarItem;
