import * as React from "react";
import {
  PROJECT_AGGREGATED_JOB_LOGS_COLUMNS,
  AGGREGATED_JOB_LOGS_TABLES_CONFIG,
} from "@app/modules/logs/AggregatedJobLogs/aggregatedJobLogs.table";
import LogsContainer from "../logs/Logs.container";
import AggregatedJobLogs from "../logs/AggregatedJobLogs/AggregatedJobLogs.container";
import { PROJECT_LOGS_COLUMNS, LOGS_TABLES_CONFIG } from "../logs/logs.table";
import { API } from "@app/services/api/api";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { bindActionCreators, Dispatch } from "redux";
import { authActions } from "../auth/auth.actions";
import { RouteComponentProps, withRouter } from "react-router";
import { AggregatedJobLog } from "@ea/shared_types/types";
import styled from "@emotion/styled";

interface IProjectHistoryProps {
  projectId: number;
}
interface IProjectHistoryContainerState {
  persistentQuery: any;
  hideAggregatedLogsView: boolean;
  isLogsViewChanging: boolean;
  jobPersistentQuery: { projectId: number };
}

class ProjectHistoryContainer extends React.Component<
  IProjectHistoryProps & IConnectProps & RouteComponentProps<any>,
  IProjectHistoryContainerState
> {
  connectedTable: any;

  constructor(props: IProjectHistoryProps & IConnectProps & RouteComponentProps<any>) {
    super(props);

    this.state = {
      hideAggregatedLogsView: false,
      persistentQuery: {
        projectId: { inq: [props.projectId] },
      },
      jobPersistentQuery: { projectId: props.projectId },
      isLogsViewChanging: false,
    };
  }

  componentWillMount() {
    this.setState({
      persistentQuery: {
        projectId: { inq: [this.props.projectId] },
      },
      jobPersistentQuery: { projectId: this.props.projectId },
    });
  }

  componentDidUpdate(prevProps: IProjectHistoryProps) {
    if (prevProps.projectId !== this.props.projectId) {
      this.setState({
        persistentQuery: {
          projectId: { inq: [this.props.projectId] },
        },
        jobPersistentQuery: { projectId: this.props.projectId },
      });
    }
  }

  changeHideAggregatedLogsView = async () => {
    const { user } = this.props;
    if (user) {
      try {
        this.setState({
          isLogsViewChanging: true,
        });
        await API.editUser({
          ...user,
          settings: {
            ...user.settings,
            hideAggregatedLogsView: !user.settings.hideAggregatedLogsView,
          },
        });
        this.props.actions.auth.initDone({
          params: {
            ...user,
            isLoggedIn: true,
          },
          result: {
            roles: this.props.user!.roles,
            ...user,
            settings: {
              ...user.settings,
              hideAggregatedLogsView: !user.settings.hideAggregatedLogsView,
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
    this.setState({
      isLogsViewChanging: false,
    });
  };

  openJobDetails = (item?: AggregatedJobLog) => {
    if (item && item.jobId) {
      const { jobId } = item;
      this.props.history.push({
        pathname: `${this.props.location.pathname}/${jobId}/`,
      });
    }
  };

  render() {
    const { projectId, user, match } = this.props;
    const { persistentQuery, isLogsViewChanging, jobPersistentQuery } = this.state;
    const jobId = match.params.jobId;

    return jobId || (user && user.settings.hideAggregatedLogsView) ? (
      <LogsContainer
        columns={PROJECT_LOGS_COLUMNS}
        tableId={LOGS_TABLES_CONFIG.PROJECT_HISTORY.id(projectId)}
        preferencesId={LOGS_TABLES_CONFIG.PROJECT_HISTORY.preferencesId}
        persistentQuery={jobId ? { jobId, projectId: { inq: [projectId] } } : persistentQuery}
        changeHideAggregatedLogsView={this.changeHideAggregatedLogsView}
        isLogsViewChanging={isLogsViewChanging}
        disableViewChangeButton={!!jobId}
      />
    ) : (
      <AggregatedJobLogs
        columns={PROJECT_AGGREGATED_JOB_LOGS_COLUMNS}
        tableId={AGGREGATED_JOB_LOGS_TABLES_CONFIG.PROJECT_AGGREGATED_JOB_LOGS.id(projectId)}
        preferencesId={AGGREGATED_JOB_LOGS_TABLES_CONFIG.PROJECT_AGGREGATED_JOB_LOGS.preferencesId}
        persistentQuery={jobPersistentQuery}
        changeHideAggregatedLogsView={this.changeHideAggregatedLogsView}
        isLogsViewChanging={isLogsViewChanging}
        onRowDoubleClick={this.openJobDetails}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: IProjectHistoryProps) => ({
  actions: {
    auth: {
      ...bindActionCreators(authActions, dispatch),
    },
  },
});

const connectCreator = connect(
  (state: ApplicationState) => ({
    user: state.auth.user,
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(ProjectHistoryContainer));
