import { DownloadOutlined, FileOutlined } from "@ant-design/icons";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { CapturedFile, Log } from "@ea/shared_types/types";
import { Button, List, Modal } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
interface ICapturedFilesModalProps {
  visible: boolean;
  onClose: () => void;
  title: string | React.ReactNode;
  selectedLog?: Log;
}

interface ICapturedFilesModalState {
  files: CapturedFile[];
  loading: boolean;
}

type Props = ICapturedFilesModalProps;

class CapturedFilesModal extends React.Component<Props, ICapturedFilesModalState> {
  state = { files: [], loading: true };

  async componentDidUpdate(prevProps: ICapturedFilesModalProps) {
    const { visible: previousVisible } = prevProps;
    const { selectedLog } = this.props;

    if (this.props.visible && !previousVisible && selectedLog && selectedLog.id) {
      try {
        this.setState({ files: [], loading: true });
        const files = await API.getCapturedFiles({ id: selectedLog.id });
        this.setState({ files, loading: false });
      } catch (error) {
        this.setState({ loading: false });
        toast.error(
          <FormattedMessage id={getTranslationKey("messages", "error", "cannotFetchFiles")} />,
        );
      }
    }
  }

  onDownload = async (item) => {
    const { selectedLog } = this.props;
    if (selectedLog) {
      try {
        const file = await API.downloadCapturedFile({
          filename: item.name,
          sessionId: selectedLog.sessionId,
        });
      } catch (error) {
        console.log(error);
        toast.error(
          <FormattedMessage id={getTranslationKey("messages", "error", "fileDownload")} />,
        );
      }
    }
  };

  render() {
    const { visible, onClose, title } = this.props;
    const { loading, files } = this.state;
    return (
      <Modal
        title={title}
        visible={visible}
        closable={false}
        footer={[
          <Button key="close" type="primary" onClick={onClose}>
            <FormattedMessage id={getTranslationKey("commandBar", "close")} />
          </Button>,
        ]}
        bodyStyle={{ display: "flex", justifyContent: "center", flexDirection: "column" }}
      >
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={files}
          renderItem={(item: CapturedFile) => (
            <List.Item key={item.createdAt}>
              <List.Item.Meta
                title={item.name}
                description={item.createdAt}
                avatar={<FileOutlined />}
              />
              <Button icon={<DownloadOutlined />} onClick={() => this.onDownload(item)} />
            </List.Item>
          )}
        />
      </Modal>
    );
  }
}

export default CapturedFilesModal;
