import {
  createDataReducer,
  getDataReducerInitialState,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { Variable } from "@ea/shared_types/types";
import { moduleActions } from "@app/modules/variables/variables.actions";

const initialState = {
  ...getDataReducerInitialState<Variable>(),
};

export type VariableReducer = typeof initialState;

const dataReducer = createDataReducer<Variable>({
  ...moduleActions.table,
  ...moduleActions.data,
});

export const reducer = dataReducer(initialState);
