import React from "react";

import { ReadonlyFormItem, NodeFormItem } from "../Form.common";
import TreeSearchComponent, { ITreeSearchProps } from "../../common/TreeSearch";
import createFormField from "../createFormField";
import withReadonly from "./withReadonly";
import { Tree } from "antd";

const TreeSearchWithReadonly = withReadonly(TreeSearchComponent);

const TreeNode = Tree.TreeNode;

const generateNodes = (nodes: NodeFormItem[]) => {
  return nodes.map((n) => (
    <TreeNode title={n.name} key={`${n.id}`} disabled={n.disabled}>
      {n.children.length === 0 ? null : generateNodes(n.children)}
    </TreeNode>
  ));
};

type TreeSearchFormItemProps = ITreeSearchProps & { nodes: NodeFormItem[] } & ReadonlyFormItem;

const TreeSearch: React.SFC<TreeSearchFormItemProps> = ({ nodes, children, ...rest }) => (
  <TreeSearchWithReadonly {...rest}>{generateNodes(nodes)}</TreeSearchWithReadonly>
);

export const TreeSearchField = createFormField<TreeSearchFormItemProps>(
  TreeSearch,
  ({ name, onChange, value, onBlur, onFocus, ...rest }) => ({
    ...rest,
    onSelect: (checked) => {
      checked === undefined ? onChange(null) : onChange(checked);
    },
    onRemove: (v) => {
      onChange(value.filter((val) => val !== `${v}`));
    },
    value,
    onBlur: onBlur as any,
    onFocus: onFocus as any,
  }),
);
