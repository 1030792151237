import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { Variable, VariableType } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("VARIABLE_MODULE");

export const moduleActions = createDataModuleActions<Variable>(actionCreator);
const getVariableSectionActions = createTableActionsGetter(normalizeActions(moduleActions.table));

export const VARIABLES_SECTIONS = {
  SEQUENCE: "SEQUENCE",
  LOCAL: "LOCAL",
  DATA_SOURCE: "DATA_SOURCE",
  LINKED: "LINKED",
};

const variablesSections = {
  sequence: getVariableSectionActions("SEQUENCE"),
  local: getVariableSectionActions("LOCAL"),
  dataSource: getVariableSectionActions("DATA_SOURCE"),
  linked: getVariableSectionActions("LINKED"),
};

export const variableActions = {
  ...normalizeActions(moduleActions.data),
  loadLocal: (id: number) =>
    variablesSections.local.setFilters({
      values: {
        parentId: [id],
        parentType: "TaskScript",
        type: VariableType.Normal,
      },
    }),
  loadSequence: () =>
    variablesSections.sequence.setFilters({
      values: {
        parentType: "Sequence",
        type: VariableType.Sequence,
      },
    }),
  loadDataSource: (id: number) =>
    variablesSections.dataSource.setFilters({
      values: {
        parentId: [id],
        parentType: "TaskScript",
        type: VariableType.DataSource,
      },
    }),
  loadLinked: (ids: number[]) =>
    variablesSections.linked.setFilters({
      values: {
        parentId: ids,
        parentType: "TaskScript",
        type: VariableType.Normal,
      },
    }),
  clearLinked: () => variablesSections.linked.clearItems({}),
};

export const variableActionCreators = {
  ...moduleActions,
};
