import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { XAxis, YAxis, CartesianGrid, Tooltip, Bar, BarChart } from "recharts";
import { COLORS } from "@ea/shared_components/styles/consts";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { PayPerUseStatistic } from "@ea/shared_types/types";
import { DataTestIds } from "@app/utils/dataTestIds";
interface IPayPerUseBarChartProps {
  data: PayPerUseStatistic[];
  width: number;
  height: number;
}
export const PPU_CHART_ID = "ppu_chart_id";
const PayPerUseBarChart: React.FunctionComponent<IPayPerUseBarChartProps & InjectedIntlProps> = ({
  data,
  intl,
  width,
  height,
}) => {
  return (
    <div id={PPU_CHART_ID} data-testid={DataTestIds.CHART_PPU_STATISTICS}>
      <BarChart width={width} height={height} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          label={{
            value: intl.formatMessage({ id: getTranslationKey("license", "executedScriptCount") }),
            angle: -90,
          }}
        />
        <Tooltip
          formatter={(value, name, props) => [
            value,
            intl.formatMessage({ id: getTranslationKey("license", "scriptsCount") }),
          ]}
        />

        <Bar dataKey="scriptsCount" fill={COLORS.BLUE2} label={(props) => props.value} />
      </BarChart>
    </div>
  );
};

export default injectIntl(PayPerUseBarChart);
