import * as React from "react";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { Log, EXECUTION_STATE, CsvExportAdditionalFields } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";
import CommandBarDropdown from "@ea/shared_components/CommandBar/CommandBarDropdown";
import { CloudOutlined, CheckOutlined, BarChartOutlined } from "@ant-design/icons";
import { HideWhenDocumentationWizard } from "@app/modules/common/HideWhenDocumentationWizard";
import { DataTestIds } from "@app/utils/dataTestIds";

interface LogsCommandBarProps {
  onReload: () => void;
  onOpen?: () => void;
  onShowStatistics?: () => void;
  onTerminate?: () => void;
  selectedLogs?: number[];
  selectedLog?: Log;
  onReportGenerate?: () => void;
  scriptLogId?: number;
  selectedItems?: Log[];
  onGoTo?: (item?: Log) => void;
  onOpenScreenshotGallery?: () => void;
  screenshotSelectedMode?: boolean;
  onOpenErrorWarningList?: () => void;
  onExport?: () => void;
  onExportWithAdditionalFieldsClick?: () => void;
  onOpenCapturedFilesModal?: () => void;
  isExporting?: boolean;
  isGeneratingReport?: boolean;
  changeHideAggregatedLogsView?: () => void;
  onFilterDevopsExecutions?: () => void;
  isLogsViewChanging?: boolean;
  disableViewChangeButton?: boolean;
  showOnlyDevopsExecutions?: boolean;
  showActiveSessions?: boolean;
  toggleShowActiveSessions?: () => void;
  onPerformanceCountersClick?: () => void;
}
export const LogsCommandBar = (props: LogsCommandBarProps) => {
  const {
    selectedLog,
    selectedLogs,
    isGeneratingReport,
    onReportGenerate,
    scriptLogId,
    onShowStatistics,
    onReload,
    onTerminate,
    selectedItems = [],
    onOpenScreenshotGallery,
    screenshotSelectedMode,
    onOpenErrorWarningList,
    onGoTo,
    onExport,
    onExportWithAdditionalFieldsClick,
    isExporting,
    onOpen,
    onOpenCapturedFilesModal,
    changeHideAggregatedLogsView,
    isLogsViewChanging,
    disableViewChangeButton,
    onFilterDevopsExecutions,
    showOnlyDevopsExecutions,
    showActiveSessions,
    toggleShowActiveSessions,
    onPerformanceCountersClick,
  } = props;
  const azureDevops = selectedLog?.integrationMetadata?.azureDevops;
  const getDisabledState = () => {
    if (showActiveSessions && selectedItems.length > 0) {
      return false;
    } else {
      return !(
        selectedItems.length >= 1 &&
        !selectedItems.some((item) => item.state !== EXECUTION_STATE.RUNNING)
      );
    }
  };
  return (
    <CommandBar data-testid={DataTestIds.COMMANDBAR_LOGS}>
      <CommandBarButton
        name="refresh"
        onClick={onReload}
        text={getTranslationKey("commandBar", "refresh")}
        icon="ReloadOutlined"
        data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
      />
      {!disableViewChangeButton && !!changeHideAggregatedLogsView && (
        <CommandBarButton
          name="switch"
          onClick={changeHideAggregatedLogsView}
          text={getTranslationKey("commandBar", "aggregated")}
          icon="TableOutlined"
          loading={isLogsViewChanging}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_SHOW_AGGREGATED}
        />
      )}

      <CommandBarButton
        name="statistics"
        onClick={() => onShowStatistics && onShowStatistics!()}
        text={getTranslationKey("commandBar", "statistics")}
        icon="PieChartOutlined"
        disabled={!onShowStatistics}
        data-testid={DataTestIds.COMMANDBAR_BUTTON_STATISTIC}
      />
      <CommandBarButton
        name="generate"
        onClick={onReportGenerate}
        text={getTranslationKey("commandBar", "generate")}
        icon="FileOutlined"
        loading={isGeneratingReport}
        data-testid={DataTestIds.COMMANDBAR_BUTTON_GENERATE}
        disabled={
          scriptLogId && selectedLog?.state !== EXECUTION_STATE.RUNNING
            ? false
            : !onReportGenerate ||
              selectedLogs?.length !== 1 ||
              selectedLog?.state === EXECUTION_STATE.RUNNING
        }
      />

      {onTerminate && (
        <CommandBarButton
          name="terminate"
          onClick={onTerminate}
          text={getTranslationKey("commandBar", "terminate")}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_TERMINATE}
          icon="CloseOutlined"
          disabled={getDisabledState()}
        />
      )}
      {onOpenScreenshotGallery && (
        <CommandBarButton
          name="openScreenshotGallery"
          onClick={onOpenScreenshotGallery}
          disabled={screenshotSelectedMode && selectedLogs && selectedLogs.length !== 1}
          text={getTranslationKey("commandBar", "screenshotGallery")}
          icon="PictureOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_SCREENSHOT_GALLERY}
        />
      )}
      {onOpenErrorWarningList && (
        <CommandBarButton
          name="errorWarningList"
          onClick={onOpenErrorWarningList}
          disabled={selectedLogs!.length === 0}
          text={getTranslationKey("commandBar", "showErrors")}
          icon="ExclamationOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_ERROR_WARNING_LIST}
        />
      )}
      <CommandBarDropdown
        name="exportDropdown"
        text={getTranslationKey("commandBar", "exportToCsv")}
        icon="FileExcelOutlined"
        data-testid={DataTestIds.COMMANDBAR_BUTTON_EXPORT_CSV_DROPDOWN}
        loading={isExporting}
      >
        {onExport && (
          <CommandBarButton
            name="export"
            onClick={() => onExport()}
            text={getTranslationKey(
              "commandBar",
              (selectedLogs || [])?.length > 0 ? "exportSelectedToCsv" : "exportToCsv",
            )}
            icon="FileExcelOutlined"
            loading={isExporting}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_EXPORT}
          />
        )}
        {onExportWithAdditionalFieldsClick && (
          <CommandBarButton
            name="exportWithAdditional"
            onClick={onExportWithAdditionalFieldsClick}
            text={getTranslationKey("common", "header", "exportWithAdditional")}
            icon="FileExcelOutlined"
            loading={isExporting}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_EXPORT_CSV_ADDITIONAL}
          />
        )}
      </CommandBarDropdown>
      {onOpenCapturedFilesModal && (
        <CommandBarButton
          name="capturedFiles"
          onClick={onOpenCapturedFilesModal}
          text={getTranslationKey("commandBar", "capturedFiles")}
          icon="FileOutlined"
          disabled={!(selectedLogs && selectedLogs.length === 1)}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_CAPTURED_FILES}
        />
      )}
      <HideWhenDocumentationWizard>
        {() => (
          <CommandBarDropdown
            name="devOps"
            style={{ padding: "7px" }}
            text={getTranslationKey("commandBar", "azureDevOps")}
            icon={<CloudOutlined style={{ fontSize: 14 }} />}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_DEVOPS}
          >
            <CommandBarButton
              name="openTestCase"
              onClick={() => {
                window.open(azureDevops?.testCaseUrl, "_blank");
              }}
              text={getTranslationKey("commandBar", "openTestCase")}
              disabled={!azureDevops?.testCaseUrl}
              size="default"
              data-testid={DataTestIds.COMMANDBAR_BUTTON_OPEN_TEST_CASE}
            />
            <CommandBarButton
              name="openTestResult"
              onClick={() => {
                window.open(azureDevops?.testRunResultUrl, "_blank");
              }}
              text={getTranslationKey("commandBar", "openTestResult")}
              disabled={!azureDevops?.testRunResultUrl}
              size="default"
              data-testid={DataTestIds.COMMANDBAR_BUTTON_OPEN_TEST_RESULTS}
            />
            <CommandBarButton
              name="showDevopsExecutions"
              onClick={onFilterDevopsExecutions}
              text={getTranslationKey("commandBar", "showDevopsExecutions")}
              disabled={!onFilterDevopsExecutions}
              icon={
                showOnlyDevopsExecutions ? <CheckOutlined style={{ fontSize: 14 }} /> : undefined
              }
              size="default"
              data-testid={DataTestIds.COMMANDBAR_BUTTON_SHOW_DEVOPS_EXEC}
            />
          </CommandBarDropdown>
        )}
      </HideWhenDocumentationWizard>
      <CommandBarButton
        name="performanceCounters"
        onClick={onPerformanceCountersClick}
        text={getTranslationKey("commandBar", "performanceCounters")}
        disabled={selectedLogs ? selectedLogs!.length !== 1 : true}
        icon={<BarChartOutlined style={{ fontSize: 14 }} />}
        size="default"
        data-testid={DataTestIds.COMMANDBAR_BUTTON_KPI_STATS}
      />
      {toggleShowActiveSessions && (
        <CommandBarButton
          name="showActiveSession"
          onClick={toggleShowActiveSessions}
          text={getTranslationKey(
            "commandBar",
            showActiveSessions ? "showLogs" : "showActiveSessions",
          )}
          data-testid={
            showActiveSessions
              ? DataTestIds.COMMANDBAR_BUTTON_SHOW_ACTIVE_SESSIONS
              : DataTestIds.COMMANDBAR_BUTTON_SHOW_LOGS
          }
          icon="FileOutlined"
        />
      )}
      {onGoTo && (
        <CommandBarButton
          name="goTo"
          onClick={onGoTo}
          text={getTranslationKey("commandBar", "goTo")}
          icon="ExportOutlined"
          disabled={!(selectedLogs && selectedLogs.length === 1)}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_GOTO}
          far
        />
      )}
      {!scriptLogId && (
        <CommandBarButton
          name="open"
          onClick={onOpen}
          text={getTranslationKey("commandBar", "open")}
          icon="LoginOutlined"
          disabled={selectedLogs ? selectedLogs.length !== 1 : true}
          far={!onGoTo}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_OPEN}
        />
      )}
    </CommandBar>
  );
};
export default LogsCommandBar;
