import { Button, Modal, Spin } from "antd";
import { FORM_ERROR, FormApi } from "final-form";
import styled from "@emotion/styled";
import * as React from "react";
import { Form, FormRenderProps } from "react-final-form";
import { finalFormNormalizedSubmit } from "../Form/formHelpers";
import { PanelFormLayout } from "../Form/FormLayouts";
import LoaderArea from "../LoaderArea/LoaderArea";
import { MessageBar } from "../MessageBar";
import { FormattedMessage } from "react-intl";
import arrayMutators from "final-form-arrays";
import { FormHint, Hint, printFormValues } from "../Form/FormDebugElements";
import { MESSAGE_TYPE } from "@ea/shared_types/types";
import { DataTestIds } from "../utils/dataTestHelpers";

const Container = styled.div({
  flexGrow: 1,
  overflow: "auto",
  padding: "24px",
  fontSize: "14px",
  lineHeight: 1.5,
});

const FooterContainer = styled.div({
  borderTop: "1px solid #e8e8e8",
  padding: "10px 16px",
  borderRadius: "0 0 4px 4px",
  textAlign: "left",
});

export interface IModalProps {
  visible: boolean;
  headerText: string | React.ReactNode;
  okButtonText?: string | React.ReactNode;
  cancelButtonText?: string | React.ReactNode;
  onOkClick: (values: any) => void;
  onCancelClick: () => void;
  initialValues?: any;
  allowPrisitineSubmit?: boolean;
  width?: string | number;
  bodyStyle?: {};
  formStyle?: {};
  loading?: boolean;
  render: (
    props: FormApi & { values: any; isBusy: boolean; onError: (error: any) => void },
  ) => JSX.Element;
}

export interface IModalState {
  isSaving: boolean;
  error: string | undefined;
}

export class ModalWithFormIntegration extends React.Component<
  IModalProps & { form: FormRenderProps },
  IModalState
> {
  state = {
    isSaving: false,
    error: undefined,
  };

  componentDidCatch(error: Error) {
    this.setState({
      error: error.message,
    });
  }

  onError = (error) => {
    this.setState({
      error: error.message,
    });
  };

  onOkClick = async () => {};

  onCancelClick = () => {
    this.props.form.form.reset();
    this.props.onCancelClick();
  };

  renderFooterContent = () => {
    const { okButtonText, cancelButtonText, form, allowPrisitineSubmit } = this.props;
    const { submitting, pristine } = form;
    const { isSaving } = this.state;
    return [
      okButtonText !== null && (
        <Button
          key="ok"
          type="primary"
          htmlType="submit"
          loading={isSaving}
          disabled={allowPrisitineSubmit === true ? submitting : submitting || pristine}
          data-testid={DataTestIds.MODAL_BUTTON_SUBMIT}
        >
          {typeof okButtonText === "string" ? (
            <FormattedMessage id={okButtonText} defaultMessage="Ok" />
          ) : (
            okButtonText
          )}
        </Button>
      ),
      cancelButtonText !== null && (
        <Button
          style={{ marginLeft: "8px" }}
          key="cancel"
          onClick={this.onCancelClick}
          disabled={submitting}
          data-testid={DataTestIds.MODAL_BUTTON_CANCEL}
        >
          {typeof cancelButtonText === "string" ? (
            <FormattedMessage id={cancelButtonText} defaultMessage="Cancel" />
          ) : (
            cancelButtonText
          )}
        </Button>
      ),
    ];
  };

  render() {
    const {
      visible,
      headerText,
      form,
      render,
      onCancelClick,
      width,
      bodyStyle,
      formStyle,
      loading,
    } = this.props;
    const { submitError, submitting, values, handleSubmit, form: formApi } = form;
    return (
      <Modal
        visible={visible}
        footer={null}
        title={
          typeof headerText === "string" ? (
            <FormattedMessage id={headerText} defaultMessage="" />
          ) : (
            headerText
          )
        }
        bodyStyle={bodyStyle || { height: "100%", padding: 0 }}
        width={width || 860}
        onCancel={onCancelClick}
      >
        {loading ? (
          <Spin
            style={{ marginTop: "25%", marginBottom: "25%", marginLeft: "50%" }}
            tip="Loading..."
          />
        ) : (
          visible && (
            <form
              style={
                formStyle || {
                  display: "flex",
                  padding: 0,
                  flexDirection: "column",
                  height: "100%",
                }
              }
              onSubmit={(event) => {
                const submitResult = handleSubmit(event);

                if (submitResult === undefined) {
                  return;
                }

                (submitResult as any).then((result) => {
                  if (result === undefined || !result[FORM_ERROR]) {
                    formApi.reset();
                  }
                });
              }}
            >
              <Container>
                <LoaderArea isSaving={submitting} label="Saving...">
                  <PanelFormLayout>
                    {render({ ...form.form, isBusy: submitting, onError: this.onError, values })}
                  </PanelFormLayout>
                </LoaderArea>
              </Container>
              {submitError ? (
                <MessageBar
                  messages={[
                    {
                      type: MESSAGE_TYPE.ERROR,
                      text: submitError,
                    },
                  ]}
                />
              ) : null}
              <FooterContainer>{this.renderFooterContent()}</FooterContainer>
            </form>
          )
        )}
        {process.env.NODE_ENV !== "production" && (
          <FormHint>
            <Hint>{printFormValues(values)}</Hint>
          </FormHint>
        )}
      </Modal>
    );
  }
}

const BaseModalForm: React.SFC<IModalProps> = (props) => {
  return (
    <Form
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={finalFormNormalizedSubmit(props.onOkClick)}
      initialValues={props.initialValues}
      render={(formProps) => <ModalWithFormIntegration {...props} form={formProps} />}
    />
  );
};

export default BaseModalForm;
