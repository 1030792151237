import { API } from "@app/services/api/api";
import { CreateSchedulerJobMappingRequest } from "@app/services/api/api.models";
import { exportToCsv } from "@app/utils/exportCsv";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import { overrideColumnProps } from "@ea/shared_components/Table/common.tables";
import {
  BasicSchedulerRunParams,
  ExportToCsvModels,
  SchedulerJobMapping,
} from "@ea/shared_types/types";
import { Dispatch } from "redux";
import { getSchedulerFlowColumnsRenderers } from "./SchedulerFlowColumnsRenderers";
import { schedulerFlowActions } from "./schedulerFlow.actions";
import {
  SCHEDULER_FLOW_COLUMNS,
  SCHEDULER_FLOW_COLUMNS_CONFIG,
  SCHEDULER_FLOW_TABLES_CONFIG,
} from "./schedulerFlow.table";

export const SCHEDULER_FLOW_EXPORT_FIELDS = [
  "schedulerJobId",
  "taskScriptId",
  "lineNum",
  "name",
  "projectName",
  "envName",
  "envType",
  "repeatCount",
  "modifiedBy",
  "override",
  "overridenVirtualUser",
  "overridenEnvironment",
  "virtualUserPool",
];

export const exportToExcel = async ({ schedulerJobId, userId }) => {
  const params = { filter: { where: { schedulerJobId } } };

  await exportToCsv({
    params,
    fields: SCHEDULER_FLOW_EXPORT_FIELDS,
    modelName: ExportToCsvModels.SCHEDULER_FLOW,
    onExportStart: () => {},
    onExportFinish: () => {},
    userId,
    preferencesId: SCHEDULER_FLOW_TABLES_CONFIG.SINGLE_SCHEDULER_FLOW.preferencesId,
  });
};

export const saveMapping = async (params: CreateSchedulerJobMappingRequest) => {
  await API.createSchedulerJobMapping({
    schedulerJobId: params.schedulerJobId,
    schedulerJobMappings: params.schedulerJobMapping as any,
  });
};

export const getColumnConfig = (
  schedulerFlowColumnRenderers: ReturnType<typeof getSchedulerFlowColumnsRenderers>,
  virtualUserOptions: OptionType[],
  environmentOptions: OptionType[],
  virtualUserPoolOptions: OptionType[],
  dispatch: Dispatch<any>,
  readOnly: boolean,
  isDragEnabled: boolean,
  schedulerJobRunParams?: BasicSchedulerRunParams,
) => {
  return overrideColumnProps<SchedulerJobMapping>(SCHEDULER_FLOW_COLUMNS, [
    {
      dataIndex: "name",
      frameworkProps: {
        shouldCellUpdate: () => {
          return false;
        },
      },
      columnProps: {
        sortable: readOnly,
        filter: readOnly ? SCHEDULER_FLOW_COLUMNS_CONFIG.name.filter : undefined,
      },
    },
    {
      dataIndex: "lineNum",
      frameworkProps: {},
      columnProps: {
        sortable: readOnly,
        filter: readOnly ? SCHEDULER_FLOW_COLUMNS_CONFIG.lineNum.filter : undefined,
      },
    },
    {
      dataIndex: "taskScriptId",
      frameworkProps: {
        shouldCellUpdate: () => {
          return false;
        },
      },
      columnProps: {
        sortable: readOnly,
        filter: readOnly ? SCHEDULER_FLOW_COLUMNS_CONFIG.taskScriptId.filter : undefined,
      },
    },

    {
      dataIndex: "runParams.virtualUserId" as any,
      frameworkProps: {
        render: (_, record) =>
          schedulerFlowColumnRenderers.combineElements([
            schedulerFlowColumnRenderers.checkboxVirtualUserMode(
              "SINGLE",
              record,
              (value: SchedulerJobMapping) => {
                dispatch(schedulerFlowActions.commitEdit(value));
              },
              readOnly,
            ),
            schedulerFlowColumnRenderers.runParamsDropdownRenderer(
              record,
              "virtualUserId",
              virtualUserOptions,
              (value: SchedulerJobMapping) => {
                dispatch(schedulerFlowActions.commitEdit(value));
              },
              record.runParams?.virtualUserMode === "POOL" || readOnly,
              schedulerJobRunParams,
            ),
          ]),
        shouldCellUpdate: (record, prevRecord) => {
          return (
            !isDragEnabled &&
            (record.overrideRunParams ||
              record.overrideRunParams !== prevRecord.overrideRunParams ||
              record.runParams?.virtualUserId !== prevRecord.runParams?.virtualUserId ||
              record.runParams?.virtualUserMode !== prevRecord.runParams?.virtualUserMode)
          );
        },
      },
      columnProps: {
        sortable: false,
      },
    },
    {
      dataIndex: "runParams.virtualUserPoolId" as any,
      frameworkProps: {
        render: (_, record) =>
          schedulerFlowColumnRenderers.combineElements([
            schedulerFlowColumnRenderers.checkboxVirtualUserMode(
              "POOL",
              record,
              (value: SchedulerJobMapping) => {
                dispatch(schedulerFlowActions.commitEdit(value));
              },
              readOnly,
            ),
            schedulerFlowColumnRenderers.runParamsDropdownRenderer(
              record,
              "virtualUserPoolId",
              virtualUserPoolOptions,
              (value: SchedulerJobMapping) => {
                dispatch(schedulerFlowActions.commitEdit(value));
              },
              !record.runParams?.virtualUserMode ||
                record.runParams?.virtualUserMode === "SINGLE" ||
                readOnly,
              schedulerJobRunParams,
            ),
          ]),
        shouldCellUpdate: (record, prevRecord) => {
          return (
            !isDragEnabled &&
            (record.overrideRunParams ||
              record.overrideRunParams !== prevRecord.overrideRunParams ||
              record.runParams?.virtualUserPoolId !== prevRecord.runParams?.virtualUserPoolId ||
              record.runParams?.virtualUserMode !== prevRecord.runParams?.virtualUserMode)
          );
        },
      },
      columnProps: {
        sortable: false,
      },
    },
    {
      dataIndex: "runParams.environmentId" as any,
      frameworkProps: {
        render: (_, record) =>
          schedulerFlowColumnRenderers.runParamsDropdownRenderer(
            record,
            "environmentId",
            environmentOptions,
            (value: SchedulerJobMapping) => dispatch(schedulerFlowActions.commitEdit(value)),
            readOnly,
            schedulerJobRunParams,
          ),
        shouldCellUpdate: (record, prevRecord) => {
          return (
            !isDragEnabled &&
            (record.overrideRunParams ||
              record.overrideRunParams !== prevRecord.overrideRunParams ||
              record.runParams?.environmentId !== prevRecord.runParams?.environmentId)
          );
        },
      },
      columnProps: {
        sortable: false,
      },
    },
    {
      dataIndex: "overrideRunParams",
      frameworkProps: {
        render: (_, record) =>
          schedulerFlowColumnRenderers.checkboxRenderer(
            record,
            schedulerJobRunParams,
            (value: SchedulerJobMapping) => {
              dispatch(schedulerFlowActions.commitEdit(value));
            },
            readOnly,
          ),
      },
      columnProps: {
        sortable: false,
        filter: readOnly ? SCHEDULER_FLOW_COLUMNS_CONFIG.overrideRunParams.filter : undefined,
      },
    },
    {
      dataIndex: "projectId",
      frameworkProps: {
        render: (_, record) => schedulerFlowColumnRenderers.projectNameRenderer(record),
        shouldCellUpdate: () => {
          return false;
        },
      },
      columnProps: {
        sortable: readOnly,
      },
    },
    {
      dataIndex: "projectEnvName",
      frameworkProps: {
        render: (_, record) => schedulerFlowColumnRenderers.envNameRenderer(record),
        shouldCellUpdate: () => {
          return false;
        },
      },
      columnProps: {
        sortable: readOnly,
      },
    },
    {
      dataIndex: "projectEnvType",
      frameworkProps: {
        render: (_, record) => schedulerFlowColumnRenderers.envTypeRenderer(record),
        shouldCellUpdate: () => {
          return false;
        },
      },
      columnProps: {
        sortable: readOnly,
      },
    },
    {
      dataIndex: "repeatCount",
      frameworkProps: {
        render: (_, record) =>
          schedulerFlowColumnRenderers.repeatCountRenderer(
            record,
            (value: SchedulerJobMapping) => {
              dispatch(schedulerFlowActions.commitEdit(value));
            },
            readOnly,
          ),
        shouldCellUpdate: () => {
          return !isDragEnabled;
        },
      },
      columnProps: {
        sortable: readOnly,
        filter: readOnly ? SCHEDULER_FLOW_COLUMNS_CONFIG.repeatCount.filter : undefined,
      },
    },
  ]);
};

export const tableDisplayModes = {
  EDIT: "EDIT",
  READ: "READ",
} as const;

export type TableDisplayMode = (typeof tableDisplayModes)[keyof typeof tableDisplayModes];
