import { createModuleEpics, makeAsyncEpic } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { SchedulerJob, SchedulerMode, SchedulerRunTypes } from "@ea/shared_types/types";
import { moduleActions, schedulerActionCreators } from "./scheduler.actions";
import { schedulerDataSelectors } from "./scheduler.selectors";
import { SCHEDULER_COLUMNS_CONFIG, SCHEDULER_COLUMNS_CONFIG_GANTT } from "./scheduler.table";

const includeFilter = [
  {
    relation: "SchedulerJobMappings",
    scope: {
      fields: ["name", "schedulerJobId"],
    },
  },
  {
    relation: "SchedulerGroups",
    scope: {
      order: "lineNum ASC",
      include: {
        relation: "SchedulerGroupScriptMappings",
        scope: {
          include: {
            relation: "SchedulerScript",
            // prisma can't handle order on nested relations so this one is calculated manually on api side
            // if they resolve the issue then we can uncomment it
            // https://github.com/prisma/prisma/issues/15159
            // scope: {
            //   order: "lineNum ASC",
            // },
          },
        },
      },
    },
  },
];

const schedulerModuleEpics = {
  ...createModuleEpics<SchedulerJob>(moduleActions, {
    normalizeItem: (scheduler: SchedulerJob) => {
      if (scheduler.mode !== SchedulerMode.GANTT) {
        return scheduler;
      }

      scheduler.groups.forEach((group) => {
        group.scripts?.forEach((script) => {
          if (script.runParams.run.type === SchedulerRunTypes.TIME) {
            script.runParams.run.time = new Date(script.runParams.run.time);
          }
        });
      });

      return scheduler;
    },
    getRequestParams: (state, tableId) => {
      const tableParams = schedulerDataSelectors.getParamsSelector(state, tableId!);
      const baseOrder = "id DESC";
      const params = createRequestParams(
        tableParams,
        { ...SCHEDULER_COLUMNS_CONFIG, ...SCHEDULER_COLUMNS_CONFIG_GANTT } as any,
        {
          defaultOrder: [baseOrder],
        },
      );

      params.filter = {
        ...params.filter,
        include: includeFilter,
      };

      return params;
    },
    getSingleRequestParams: (params) => {
      params.filter = {
        ...params.filter,
        include: includeFilter,
      };

      return params;
    },
    api: {
      createItem: API.createSchedulerJob as any, // todo: NEW_TYPES
      deleteItem: API.deleteSchedulerJob,
      editItem: API.editSchedulerJob as any, // todo: NEW_TYPES
      getItems: API.getSchedulerJobs,
      getItemsCount: API.getSchedulerJobCount,
    },
  }),
  closeSchedulerEpic: makeAsyncEpic(schedulerActionCreators.close, (payload) =>
    API.closeSchedulerJob({ ids: payload }),
  ),
};

export default schedulerModuleEpics;
