export const numberSequenceFormatValidator = (value: string) => {
  if (!value) {
    return "Format is undefined";
  }

  const firstOccurrenceOfHash = value.indexOf("#");
  const lastOccurrenceOfHash = value.lastIndexOf("#");

  if (firstOccurrenceOfHash === -1 || lastOccurrenceOfHash === -1) {
    return "Sequence number must contain at least one #";
  }

  if (firstOccurrenceOfHash === lastOccurrenceOfHash) {
    return undefined;
  }

  const fromHashToHash = value.substring(firstOccurrenceOfHash, lastOccurrenceOfHash);
  const format = new RegExp("[^#]+");

  if (format.test(fromHashToHash)) {
    return "Wrong format, cannot break # strings";
  }
  return undefined;
};
