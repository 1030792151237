import { ApplicationState } from "@app/modules/app.reducers";
import { moduleActions } from "./runner.actions";
import { RunnerMode, RunnerState } from "@ea/shared_types/types";
import { API } from "@app/services/api/api";
import { ActionsObservable } from "redux-observable";
import { Store, Action } from "redux";
import { createEpicRequest } from "@ea/shared_components/redux/makeAsyncEpic";
import { makeAsyncEpic } from "../app.reducers";
import { runnerDataSelectors } from "./runner.selectors";
import {
  getTableParams,
  getTableParamsWithoutInitialData,
} from "@ea/shared_components/redux/reducers/common.data.reducer";
import { of } from "rxjs/observable/of";
import { ExecutionCache } from "@ea/shared_types/runner.common.types";
import { findStep } from "@ea/shared_types/utils/runner.data";
import { getRootStepsOrder } from "./runner.helpers";
import { ExecutionStep } from "@ea/shared_types";
import { PlayModes } from "@ea/shared_types/newRunner.types";

const runnerModuleEpics = {
  reloadFilters: (action$: ActionsObservable<Action>, store: Store<ApplicationState>) =>
    action$
      .ofType(
        moduleActions.table.setFilter,
        moduleActions.table.setFilters,
        moduleActions.table.clearFilters,
        moduleActions.table.setPersistentQuery,
      )
      .groupBy((_: any) => _.payload.tableId)
      .mergeMap((group$) =>
        group$
          .debounceTime(500)
          .map((_: any) => moduleActions.table.load.started({ tableId: _.payload.tableId })),
      ),
  load: createEpicRequest<ApplicationState>()(
    async (params, state) => {
      const sessionId = params.tableId;
      const mode = (state.runner.params[params.tableId] as any).mode;
      const result = await API.runner.init({
        sessionId,
      });

      return { params, result } as any;
    },
    {
      started: moduleActions.table.load.started,
      failed: moduleActions.table.load.failed,
      done: ({ params, result: data }, state) => {
        const sessionParams = getTableParamsWithoutInitialData<ExecutionStep, {}, RunnerState>(
          state.runner,
          {
            tableId: params.tableId,
          },
        );

        const flattenRunnerSteps = ({
          steps,
          flatSteps,
        }: Pick<
          ExecutionCache,
          "mode" | "steps" | "flatSteps" | "variables" | "scriptId" | "recorder"
        >) => {
          return Object.keys(flatSteps).map((key) => {
            const { path } = flatSteps[key];
            return findStep(steps, path);
          });
        };

        const flattenedSteps = flattenRunnerSteps(data);
        const executionItems =
          !(sessionParams as any).executionItems ||
          Object.keys((sessionParams as any).executionItems).length === 0
            ? flattenedSteps.reduce((container, curr) => {
                container[curr.execution.path] = curr.execution;

                return container;
              }, {})
            : (sessionParams as any).executionItems;

        const recorder =
          sessionParams.mode === RunnerMode.RECORDER
            ? data.recorder
            : {
                ...data.recorder,
                ...sessionParams.recorder,
              };

        const player =
          sessionParams.mode === RunnerMode.PLAYER
            ? data.player
            : {
                ...data.player,
                ...sessionParams.player,
              };

        return [
          moduleActions.table.load.done({
            result: flattenedSteps,
            params,
          }),
          moduleActions.table.setRunnerParams({
            playMode: {
              mode: PlayModes.NORMAL,
            },
            tableId: params.tableId,
            ...(data as any),
            mode: sessionParams.mode,
            order: getRootStepsOrder(data.steps),
            executionItems: executionItems,
            recorder,
            player,
            variables: sessionParams.variables,
          }),
        ];
      },
    },
  ),
  markDirty: (action$: ActionsObservable<Action>, store: Store<ApplicationState>) =>
    action$
      .ofType(
        moduleActions.table.addNewStep,
        moduleActions.table.updateStep,
        moduleActions.table.deleteWithUnselect,
      )
      .mergeMap((stepAction$: any) => {
        return of(
          moduleActions.table.setRunnerParams({
            tableId: stepAction$.payload.tableId,
            isDirty: true,
          }),
        );
      }),
  // syncOnUpdate epic reacts to actions that add or modify steps
  // this reaction is debounced for 3 seconds, so further actions will be dispatched only
  // after 3 seconds of input actions silence
  // in the following mergeMap we're checking if some steps are already being synced
  // if so, we're waiting for syncSteps.done action to appear in $actions stream
  // which allows us to dispatch a new syncSteps actions
  syncOnUpdate: (action$: ActionsObservable<Action>, store: Store<ApplicationState>) =>
    action$
      .ofType(
        moduleActions.table.addNewStep,
        moduleActions.table.updateStep,
        moduleActions.table.moveTo,
      )
      .debounceTime(3000)
      .mergeMap((stepAction$: any) => {
        const params = getTableParams(store.getState().runner, {
          tableId: stepAction$.payload.tableId,
        });
        if ((params as any).mode !== RunnerMode.PLAYER && !(params as any).syncing) {
          return of(moduleActions.table.syncSteps.started(stepAction$.payload));
        } else {
          return action$
            .ofType(moduleActions.table.syncSteps.done)
            .mergeMap(() => of(moduleActions.table.syncSteps.started(stepAction$.payload)))
            .take(1);
        }
      }),
  // syncOnRemove works the same way as the syncStepsOnUpdate epic although it instantly synchronizes removed steps
  syncOnRemove: (action$: ActionsObservable<Action>, store: Store<ApplicationState>) =>
    action$.ofType(moduleActions.table.deleteWithUnselect).mergeMap((stepAction$: any) => {
      const params = getTableParams(store.getState().runner, {
        tableId: stepAction$.payload.tableId,
      });
      if (!(params as any).syncing) {
        return of(
          moduleActions.table.syncSteps.started({
            tableId: stepAction$.payload.tableId,
            idsToRemove: stepAction$.payload.ids,
          }),
        );
      } else {
        return action$
          .ofType(moduleActions.table.syncSteps.done)
          .mergeMap(() =>
            of(
              moduleActions.table.syncSteps.started({
                tableId: stepAction$.payload.tableId,
                idsToRemove: stepAction$.payload.ids,
              }),
            ),
          )
          .take(1);
      }
    }),
  syncOnParamsUpdate: (action$: ActionsObservable<Action>, store: Store<ApplicationState>) =>
    action$.ofType(moduleActions.table.setRunnerRecorderParams).mergeMap((stepAction$: any) => {
      const params = getTableParams(store.getState().runner, {
        tableId: stepAction$.payload.tableId,
      });
      if (!(params as any).syncing) {
        return of(
          moduleActions.table.syncSteps.started({
            tableId: stepAction$.payload.tableId,
            recordingPath: (params as any).recorder.recordingPath,
          }),
        );
      } else {
        return action$
          .ofType(moduleActions.table.syncSteps.done)
          .mergeMap(() =>
            of(
              moduleActions.table.syncSteps.started({
                tableId: stepAction$.payload.tableId,
                recordingPath: (params as any).recorder.recordingPath,
              }),
            ),
          )
          .take(1);
      }
    }),
  toggleMode: makeAsyncEpic(moduleActions.table.toggleMode, async (payload, state) => {
    const { tableId } = payload;
    const params = getTableParams(state.runner, { tableId });
    const currentSteps = runnerDataSelectors.getOrderedDataSelector(state, tableId);
    const modifiedStepsToSync = currentSteps.filter((step) => step.synced === false);

    const response = await API.runner.recorder.syncSteps({
      steps: modifiedStepsToSync,
      order: params.order,
      idsToRemove: [],
      sessionId: tableId,
    });

    return { ...response, tableId } as any; // todo: new_types
  }),
  // sync epic performs actual synchronization calling API endpoint with new/modified/removed steps
  sync: makeAsyncEpic(moduleActions.table.syncSteps, async (payload, state) => {
    const { tableId, idsToRemove, recordingPath } = payload;
    const params = getTableParams(state.runner, { tableId });
    const sessionId = params.persistentQuery?.sessionId;
    const currentSteps = runnerDataSelectors.getOrderedDataSelector(state, tableId);
    const modifiedStepsToSync = currentSteps.filter((step) => step.synced === false);

    const response = await API.runner.recorder.syncSteps({
      steps: modifiedStepsToSync,
      order: params.order,
      idsToRemove,
      sessionId,
      recordingPath: recordingPath || (params as any).recorder.recordingPath,
    });

    return { ...response, tableId } as any; // todo: new_types
  }),
};

export default runnerModuleEpics;
