import { CascaderOptionType } from "antd/lib/cascader";
import * as React from "react";

import { VariablesGroup } from "../redux/common.models";
import PickerField from "../Form/Fields/PickerField";
import { scriptNameToLinkName } from "../utils/formatters";
import { DataTestIdProp } from "../utils/dataTestHelpers";

interface ICodePickerProps {
  variablesGroups: VariablesGroup[];
  name: string;
  onChange: any;
  readOnly?: boolean;
  label?: string;
  required?: boolean;
  DataTestIdProp?: string;
}

export function transformVariablesToPickerOptions({
  variablesGroups,
}: {
  variablesGroups?: VariablesGroup[];
}) {
  const parseVariableGroup = ({ variables: plainVariables, groups, id }: VariablesGroup) => {
    const plainOptions = plainVariables
      ? (plainVariables as any).map((variable) => ({
          value: variable.name,
          label: variable.name,
        }))
      : [];

    const nestedOptions = groups ? groups.map((group) => parseVariableGroup(group)) : [];

    return {
      label: id,
      value: scriptNameToLinkName(id),
      children: plainOptions.concat(nestedOptions),
    };
  };

  const variablesOptions = variablesGroups ? variablesGroups.map(parseVariableGroup) : [];

  return variablesOptions;
}

class CodePickerField extends React.Component<ICodePickerProps> {
  parseValue = (value: string | undefined) => {
    if (!value) {
      return undefined;
    }

    return value.substring(3, value.length - 2).split(".");
  };

  getValue = (value) => {
    if (value) {
      return "`${" + value.join(".") + "}`";
    }

    return value;
  };

  onChange = (value) => {
    this.setState({
      value,
    });
  };

  getOptions = (): CascaderOptionType[] => {
    const { variablesGroups } = this.props;

    return transformVariablesToPickerOptions({ variablesGroups });
  };

  render() {
    const options = this.getOptions();

    return (
      <PickerField
        onChange={this.props.onChange}
        name={this.props.name}
        format={this.parseValue}
        parse={this.getValue}
        label={this.props.label}
        required={this.props.required}
        options={options}
        placeholder={options.length === 0 ? "No variables to select" : "Please select variable"}
        data-testid={this.props[DataTestIdProp]}
      />
    );
  }
}

export default CodePickerField;
