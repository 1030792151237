import * as React from "react";

import BreadcrumbContainer from "@ea/shared_components/common/Breadcrumb.container";
import { BreadcrumbSection, MainSection, Page } from "@ea/shared_components/common/LayoutElements";
import SchedulerTableContainer from "@app/modules/scheduler/SchedulerTable.container";

interface ISchedulerProps {}

const Scheduler: React.FunctionComponent<ISchedulerProps> = (props) => {
  return (
    <Page>
      <BreadcrumbSection>
        <BreadcrumbContainer />
      </BreadcrumbSection>

      <MainSection>
        <SchedulerTableContainer />
      </MainSection>
    </Page>
  );
};

export default Scheduler;
