import { Button, Dropdown, Menu } from "antd";
import styled from "@emotion/styled";
import * as PropTypes from "prop-types";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouterChildContext } from "react-router";
import {
  DownOutlined,
  PlayCircleOutlined,
  EllipsisOutlined,
  PlusCircleOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { ApplicationState } from "@app/modules/app.reducers";
import ActionsContainer from "@app/modules/common/ActionsContainer";
import {
  getSelectedSelector,
  getSelectedStepSelector,
  getStepsOrderSelector,
  getStepsSelector,
} from "@app/modules/steps/steps.selectors";
import { STEPS_TABLE_ID } from "@app/modules/steps/steps.table";
import {
  ScriptStatus,
  RunnerMode,
  ROLES,
  StartForegroundSessionParams,
} from "@ea/shared_types/types";

import { scriptsActions } from "./scripts.actions";
import { getScriptWithBasicVariablesSelector } from "./scripts.selectors";
import WithAuthorization from "../common/WithAuthorization";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import {
  isCurrentUserDocumentationReader,
  currentUserSettingsSelector,
} from "@ea/shared_components/auth/auth.selectors";
import { formatScriptName } from "@app/utils/script";
import { startSession } from "@app/utils/start";
import { DataTestIds } from "@app/utils/dataTestIds";

const ButtonGroup = Button.Group;

interface IScriptDetailsProps {
  scriptId: number;
}

interface IScriptDetailsState {
  isOpen: boolean;
  publishedModalVisible: boolean;
  cloneModalVisible: boolean;
  publishedScript: any;
  playOptionsVisibility: boolean;
}

// todo: we do casting here because of the problems with emotion and typescript. This pr should fix it: https://github.com/emotion-js/emotion/pull/1514
const MenuButton = styled(Button)({
  borderStyle: "none",
  width: "100%",
}) as any as typeof Button;

const MenuItem = styled(Menu.Item)({
  padding: 0,
});

// todo: FIX doesn't compile
const AuthorizedButton = WithAuthorization([], [ROLES.freedocs])(Button as any) as any;
const AuthorizedMenuItem = WithAuthorization([], [ROLES.freedocs])(MenuItem);

class ScriptDetails extends React.Component<IConnectProps, IScriptDetailsState> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state: IScriptDetailsState = {
    isOpen: false,
    publishedModalVisible: false,
    publishedScript: {},
    playOptionsVisibility: false,
    cloneModalVisible: false,
  };

  context: RouterChildContext<any>;

  record = async (evt: React.MouseEvent, additional?: Partial<StartForegroundSessionParams>) => {
    const { script, userSettings } = this.props;
    const { playMode, ...defaultParams } = userSettings;
    const isCtrlEvt = evt.ctrlKey;

    if (script) {
      return await startSession(formatScriptName(script), {
        mode: RunnerMode.RECORDER,
        isBackground: false,
        script: script.id,
        ...defaultParams,
        ...additional,
        autoStart: undefined,
        isCtrlEvt,
      });
    }
  };

  rework = async (
    evt: React.MouseEvent,
    additionalParams?: Partial<StartForegroundSessionParams>,
  ) => {
    const { script, selectedSteps, stepsOrder, userSettings } = this.props;
    if (script) {
      const selectedStepOrderIndex = stepsOrder.findIndex((id) => id === selectedSteps[0]);
      const nextStepId = stepsOrder[selectedStepOrderIndex];
      const { playMode, ...defaultParams } = userSettings;
      const isCtrlEvt = evt.ctrlKey;

      const reworkConfig =
        selectedStepOrderIndex > -1 && nextStepId !== undefined
          ? {
              reworkStepId: nextStepId,
              reworkPreviousStepId: selectedSteps[0].toString(),
            }
          : {
              reworkStepId: stepsOrder[0],
              reworkPreviousStepId: stepsOrder[0].toString(),
            };

      return await startSession(formatScriptName(script), {
        mode: RunnerMode.RECORDER,
        script: script.id,
        isBackground: false,
        ...reworkConfig,
        ...defaultParams,
        ...additionalParams,
        autoStart: undefined,
        isCtrlEvt,
      });
    }
  };

  menu = (openPlayOptions, play, openRecordOptions) => {
    const { script, stepsOrder, selectedSteps } = this.props;

    if (!script) {
      return <Menu style={{ padding: 0 }} />;
    }

    const buttons = [
      <MenuItem key="play">
        <ButtonGroup style={{ width: "100%", display: "flex" }}>
          <Button
            type="default"
            icon={<PlayCircleOutlined />}
            onClick={play}
            style={{ borderStyle: "none", width: "100%" }}
            disabled={stepsOrder.length <= 2 || script.status === ScriptStatus.CLOSED}
            data-testid={DataTestIds.ACTION_BUTTON_PLAY}
          >
            <FormattedMessage id={getTranslationKey("commandBar", "play")} />
          </Button>
          <AuthorizedButton
            type="default"
            icon={<EllipsisOutlined />}
            onClick={openPlayOptions}
            style={{ borderStyle: "none" }}
            disabled={stepsOrder.length <= 2 || script.status === ScriptStatus.CLOSED}
            data-testid={DataTestIds.ACTION_BUTTON_PLAY_OPTIONS}
          />
        </ButtonGroup>
      </MenuItem>,
    ];

    if (script.status !== ScriptStatus.PUBLISHED && script.status !== ScriptStatus.CLOSED) {
      if (stepsOrder.length === 2 || stepsOrder.length === 0) {
        buttons.unshift(
          <MenuItem key="record">
            <ButtonGroup style={{ width: "100%", display: "flex" }}>
              <MenuButton
                type="default"
                key="record"
                icon={<VideoCameraOutlined />}
                onClick={(evt: React.MouseEvent) => this.record(evt)}
                data-testid={DataTestIds.ACTION_BUTTON_RECORD}
              >
                <FormattedMessage id={getTranslationKey("commandBar", "record")} />
              </MenuButton>
              <AuthorizedButton
                type="default"
                icon={<EllipsisOutlined />}
                onClick={openRecordOptions}
                style={{ borderStyle: "none" }}
                disabled={false}
                data-testid={DataTestIds.ACTION_BUTTON_RECORD_OPTIONS}
              />
            </ButtonGroup>
          </MenuItem>,
        );
      }
      if (stepsOrder.length > 2) {
        buttons.unshift(
          <AuthorizedMenuItem key="rework">
            <ButtonGroup style={{ width: "100%", display: "flex" }}>
              <MenuButton
                type="default"
                key="rework"
                icon={<PlusCircleOutlined />}
                onClick={(evt: React.MouseEvent) => this.rework(evt)}
                disabled={selectedSteps.length > 1}
                data-testid={DataTestIds.ACTION_BUTTON_REWORK}
              >
                <FormattedMessage id={getTranslationKey("commandBar", "rework")} />
              </MenuButton>
              <AuthorizedButton
                type="default"
                icon={<EllipsisOutlined />}
                onClick={openRecordOptions}
                style={{ borderStyle: "none" }}
                disabled={stepsOrder.length <= 2}
                data-testid={DataTestIds.ACTION_BUTTON_REWORK_OPTIONS}
              />
            </ButtonGroup>
          </AuthorizedMenuItem>,
        );
      }
    }

    return <Menu style={{ padding: 0 }}>{buttons}</Menu>;
  };

  buttons = ({
    openPlayOptions,
    play,
    openChangeStatusPanel,
    openClonePanel,
    openRecordOptions,
    onExportClick,
  }) => {
    const { script } = this.props;

    if (!script) {
      return null;
    }

    const Buttons: any[] = [];

    Buttons.push(
      <AuthorizedButton
        type="primary"
        key="changeStatus"
        onClick={() => openChangeStatusPanel()}
        data-testid={DataTestIds.ACTION_BUTTON_CHANGE_STATUS}
      >
        <FormattedMessage id={getTranslationKey("button", "changeStatus")} />
      </AuthorizedButton>,
    );

    if (script.status !== ScriptStatus.CLOSED) {
      Buttons.push(
        <AuthorizedButton
          type="primary"
          key="clone"
          onClick={() => openClonePanel()}
          data-testid={DataTestIds.ACTION_BUTTON_CLONE}
        >
          <FormattedMessage id={getTranslationKey("button", "clone")} />
        </AuthorizedButton>,
        <AuthorizedButton
          type="primary"
          key="export"
          onClick={() => onExportClick()}
          data-testid={DataTestIds.ACTION_BUTTON_EXPORT}
        >
          <FormattedMessage id={getTranslationKey("commandBar", "export")} />
        </AuthorizedButton>,
        <Dropdown
          key="start"
          overlay={this.menu(openPlayOptions, play, openRecordOptions)}
          trigger={["click"]}
        >
          <Button data-testid={DataTestIds.ACTION_BUTTON_START}>
            <FormattedMessage id={getTranslationKey("commandBar", "start")} />
            <DownOutlined />
          </Button>
        </Dropdown>,
      );
    }

    return Buttons;
  };

  render() {
    const { script, loadSingle, commitEdit, stepsOrder, isDocumentationReader } = this.props;
    if (!script || isDocumentationReader) {
      return null;
    }
    return (
      <ActionsContainer
        render={(args) => {
          return <>{this.buttons(args)}</>;
        }}
        script={script}
        loadSingle={loadSingle}
        commitEdit={commitEdit}
        run={stepsOrder.length > 2 ? this.rework : this.record}
      />
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: IScriptDetailsProps) => ({
    script: getScriptWithBasicVariablesSelector(state, props.scriptId),
    selectedSteps: getSelectedSelector(state, STEPS_TABLE_ID),
    selectedStep: getSelectedStepSelector(state, STEPS_TABLE_ID),
    steps: getStepsSelector(state, STEPS_TABLE_ID),
    stepsOrder: getStepsOrderSelector(state, STEPS_TABLE_ID),
    isDocumentationReader: isCurrentUserDocumentationReader(state),
    userSettings: currentUserSettingsSelector(state),
  }),
  {
    ...scriptsActions,
  },
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(ScriptDetails);
