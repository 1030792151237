import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import validators, { composeValidators } from "@ea/shared_components/Form/validators";
import { CodeTemplateGroup } from "@ea/shared_types/types";
import { FormApi } from "final-form";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
interface ICodeTemplateGroupsFormProps {
  values: CodeTemplateGroup;
  form: FormApi;
  isEdit: boolean;
}

const CodeTemplateGroupsForm = (props: ICodeTemplateGroupsFormProps & InjectedIntlProps) => {
  const { intl, isEdit } = props;
  const { whiteSpaceAndSpecialCharValidator, mustStartWithLetter } = validators(intl);
  return (
    <>
      <InputField
        name="name"
        required
        readOnly={isEdit}
        placeholder={getTranslationKey("common", "placeholder", "name")}
        label={getTranslationKey("common", "label", "name")}
        validate={composeValidators(whiteSpaceAndSpecialCharValidator, mustStartWithLetter)}
        data-testid={DataTestIds.FORM_INPUT_NAME}
      />
      <TextAreaField
        name="description"
        defaultValue={""}
        placeholder={getTranslationKey("common", "placeholder", "description")}
        autoSize={{ minRows: 2, maxRows: 6 }}
        label={getTranslationKey("common", "label", "description")}
        data-testid={DataTestIds.FORM_INPUT_DESCRIPTION}
      />
    </>
  );
};

export default injectIntl(CodeTemplateGroupsForm);
