import { GetAsTime } from "@app/modules/common/time";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { FormHint, Hint, printFormValues } from "@ea/shared_components/Form/FormDebugElements";
import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { isDevelopment } from "@ea/shared_components/utils/env";
import { AuditTrail } from "@ea/shared_types/types";
import * as React from "react";
import { FormattedMessage } from "react-intl";

const friendlyNames = {
  project: getTranslationKey("tablesFriendlyNames", "project"),
  projectitsrules: getTranslationKey("tablesFriendlyNames", "projectitsrules"),
  projectowners: getTranslationKey("tablesFriendlyNames", "projectowners"),
  projectsystemdictionary: getTranslationKey("tablesFriendlyNames", "projectsystemdictionary"),
  projectscheduler: getTranslationKey("tablesFriendlyNames", "projectscheduler"),
  projectusergroup: getTranslationKey("tablesFriendlyNames", "projectusergroup"),
  projectvirtualuser: getTranslationKey("tablesFriendlyNames", "projectvirtualuser"),
  systemdictionary: getTranslationKey("tablesFriendlyNames", "systemdictionary"),
  guardssteps: getTranslationKey("tablesFriendlyNames", "guardssteps"),
  guard: getTranslationKey("tablesFriendlyNames", "guard"),
  taskstep: getTranslationKey("tablesFriendlyNames", "taskstep"),
  taskscript: getTranslationKey("tablesFriendlyNames", "taskscript"),
  variable: getTranslationKey("tablesFriendlyNames", "variable"),
  taskscripttag: getTranslationKey("tablesFriendlyNames", "taskscripttag"),
  virtualuser: getTranslationKey("tablesFriendlyNames", "virtualuser"),
  globalvariable: getTranslationKey("tablesFriendlyNames", "globalvariable"),
};

const getFriendlyName = (name) => (friendlyNames[name] ? friendlyNames[name] : name);

export const AUDIT_TRAIL_COLUMNS_CONFIG: PlainObject<CustomColumn<AuditTrail>> = {
  tableName: {
    label: "table.tableName",
    dataIndex: "tableName",
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        { label: friendlyNames.project, value: "Project" },
        { label: friendlyNames.projectitsrules, value: "ProjectItsRules" },
        { label: friendlyNames.projectowners, value: "ProjectOwners" },
        { label: friendlyNames.projectsystemdictionary, value: "ProjectSystemDictionary" },
        { label: friendlyNames.projectscheduler, value: "ProjectScheduler" },
        { label: friendlyNames.projectusergroup, value: "ProjectUserGroup" },
        { label: friendlyNames.projectvirtualuser, value: "ProjectVirtualUser" },
        { label: friendlyNames.systemdictionary, value: "SystemDictionary" },
        { label: friendlyNames.guardssteps, value: "GuardsSteps" },
        { label: friendlyNames.guard, value: "Guard" },
        { label: friendlyNames.taskstep, value: "TaskStep" },
        { label: friendlyNames.taskscript, value: "TaskScript" },
        { label: friendlyNames.variable, value: "Variable" },
        { label: friendlyNames.taskscripttag, value: "TaskScriptTag" },
        { label: friendlyNames.virtualuser, value: "VirtualUser" },
        { label: friendlyNames.globalvariable, value: "GlobalVariable" },
      ],
    },
    render: (text) => <FormattedMessage id={getFriendlyName(text)} />,
  },
  rowId: {
    label: "table.rowId",
    dataIndex: "rowId",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  tstampStm: {
    label: "table.createdAt",
    dataIndex: "tstampStm",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "tstampStm" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
  username: {
    label: "table.username",
    dataIndex: "username",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  action: {
    label: "table.action",
    dataIndex: "action",
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        { label: "INSERT", value: "I" },
        { label: "UPDATE", value: "U" },
        { label: "DELETE", value: "D" },
      ],
    },
    render: (text, record, index) => {
      const getFullActionName = () => {
        switch (text) {
          case "I":
            return "INSERT";
          case "U":
            return "UPDATE";
          case "D":
            return "DELETE";
          default:
            return text;
        }
      };
      return <span>{getFullActionName()}</span>;
    },
  },
  rowData: {
    label: "table.rowData",
    dataIndex: "rowData",
    render: (text) => (
      <FormHint>
        <Hint>{printFormValues(text)}</Hint>
      </FormHint>
    ),
  },
  scriptId: {
    label: "Script id",
    dataIndex: "scriptId" as any,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  projectId: {
    label: "Project id",
    dataIndex: "projectId" as any,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
};

export const AUDIT_TRAIL_COLUMNS: ColumnConfig<AuditTrail>[] = [
  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.tableName,
    frameworkProps: {
      width: "45%",
    },
  },
  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.username,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.action,
    frameworkProps: {
      width: "10%",
    },
  },

  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.tstampStm,
    frameworkProps: {
      width: "15%",
    },
  },
];
export const AUDIT_TRAIL_SCRIPT_COLUMNS: ColumnConfig<AuditTrail>[] = [
  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.scriptId,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.tableName,
    frameworkProps: {
      width: "45%",
    },
  },
  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.username,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.action,
    frameworkProps: {
      width: "10%",
    },
  },

  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.tstampStm,
    frameworkProps: {
      width: "15%",
    },
  },
  ...(isDevelopment()
    ? [
        // {
        //   props: AUDIT_TRAIL_COLUMNS_CONFIG.rowData,
        //   frameworkProps: {
        //     width: "26%",
        //   },
        // },
      ]
    : []),
];
export const AUDIT_TRAIL_PROJECT_COLUMNS: ColumnConfig<AuditTrail>[] = [
  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.projectId,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.tableName,
    frameworkProps: {
      width: "45%",
    },
  },
  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.username,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.action,
    frameworkProps: {
      width: "10%",
    },
  },

  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.tstampStm,
    frameworkProps: {
      width: "15%",
    },
  },
];
export const AUDIT_TRAIL_SETTINGS_COLUMNS: ColumnConfig<AuditTrail>[] = [
  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.tableName,
    frameworkProps: {
      width: "45%",
    },
  },
  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.username,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.action,
    frameworkProps: {
      width: "10%",
    },
  },

  {
    props: AUDIT_TRAIL_COLUMNS_CONFIG.tstampStm,
    frameworkProps: {
      width: "15%",
    },
  },
];

export const AUDIT_TRAIL_TABLES_CONFIG = {
  AUDIT: {
    main: "MAIN_AUDIT_TRAIL",
    id: (id: number) => `AUDIT_${id}_TRAIL`,
    preferences: "PREFERENCES_AUDIT_TRAIL",
  },
  SCRIPT: {
    main: "MAIN_AUDIT_TRAIL_SCRIPT",
    id: (id: number) => `AUDIT_${id}_TRAIL_SCRIPT`,
    preferences: "PREFERENCES_AUDIT_TRAIL_SCRIPT",
  },
  PROJECT: {
    main: "MAIN_AUDIT_TRAIL_PROJECT",
    id: (id: number) => `AUDIT_${id}_TRAIL_PROJECT`,
    preferences: "PREFERENCES_AUDIT_TRAIL_PROJECT",
  },
  SETTINGS: {
    main: "MAIN_AUDIT_TRAIL_SETTINGS",
    id: (id: number) => `AUDIT_${id}_TRAIL_SETTINGS`,
    preferences: "PREFERENCES_AUDIT_TRAIL_SETTINGS",
  },
};
