import {
  createDataReducer,
  getDataReducerInitialState,
  getTableParams,
  setTableParams,
} from "@ea/shared_components/redux/reducers/common.data.reducer";

import { Log } from "@ea/shared_types/types";
import { logsActionCreators, moduleActions } from "@app/modules/logs/logs.actions";

const initialState = {
  ...getDataReducerInitialState<Log>(),
};

export type LogsReducer = typeof initialState;

const dataReducer = createDataReducer<Log>({
  ...moduleActions.table,
  ...moduleActions.data,
});

const logsDataReducer = dataReducer(initialState);

export const reducer = logsDataReducer.case(
  logsActionCreators.setIsGeneratingReport,
  (state, payload) => {
    const tableParams = getTableParams(state, payload);
    return setTableParams(state, payload, {
      ...tableParams,
      isGeneratingReport: payload.isGeneratingReport,
    });
  },
);
