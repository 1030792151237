import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { CodeTemplateWithGroup } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("CODE_TEMPLATES_MODULE");

export const moduleActions = createDataModuleActions<CodeTemplateWithGroup>(actionCreator);

export const getCodeTemplatesTableActions = createTableActionsGetter(
  normalizeActions(moduleActions.table),
);

export const codeTemplatesActionCreators = {
  ...moduleActions,
};

export const codeTemplatesActions = {
  ...normalizeActions(codeTemplatesActionCreators.data),
};
