import { RunnerPostMessages } from "@ea/shared_types/runner.common.types";

export function postMessageToChildren(message: RunnerPostMessages) {
  const allFrames: Window[] = [];

  function getFrames(w: Window) {
    for (let i = 0; i < w.frames.length; i++) {
      allFrames.push(w.frames[i]);
      getFrames(w.frames[i]);
    }
  }

  getFrames(window);

  allFrames.forEach((f) => {
    f.window.postMessage({ message }, "*");
  });
}

export function postMessageToEaApp(message: RunnerPostMessages) {
  const allFrames: Window[] = [];

  function getFrames(w: Window) {
    for (let i = 0; i < w.frames.length; i++) {
      allFrames.push(w.frames[i]);
      getFrames(w.frames[i]);
    }
  }

  getFrames(window);

  allFrames.forEach((f) => {
    f.postMessage({ message }, "*");
  });
}

export function listenOnIframeMessages(func: (message: RunnerPostMessages) => any) {
  const onMessage = (event) => {
    if (!event.data) {
      return;
    }
    const data: RunnerPostMessages = event.data;
    if (data.source !== "runner_manager") {
      return;
    }

    func(data);
  };

  window.addEventListener("message", onMessage, false);

  return () => {
    window.removeEventListener("message", onMessage, false);
  };
}
