import { ApplicationState } from "@app/modules/app.reducers";
import { getUsersTableActions, userActions } from "@app/modules/users/users.actions";
import { usersDataSelectors } from "@app/modules/users/users.selectors";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { FilterOption } from "@ea/shared_components/Form/Form.common";
import { PanelType } from "@ea/shared_components/Panel";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import styled from "@emotion/styled";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { toast } from "react-toastify";
import { Dispatch, bindActionCreators } from "redux";
import { authActions } from "../auth/auth.actions";
import { getIstTableActions } from "../issueTrackingTool/its.actions";
import { itsDataSelectors } from "../issueTrackingTool/its.selectors";
import { ITS_TABLES_CONFIG } from "../issueTrackingTool/its.table";
import UserBasicSettingsForm from "./components/UserBasicSettingsForm";
import UserPasswordForm from "./components/UserPasswordForm";
import CommandBar from "./components/UsersCommandBar";
import { USERS_COLUMNS, USERS_TABLES_CONFIG } from "./users.table";

interface IUsersTableProps {}
interface IUsersTableState {
  createWindowVisibility: boolean;
  editWindowVisibility: boolean;
  changePasswordVisibility: boolean;
  groups: FilterOption[];
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

const PASSWORD_PLACEHOLDER = "fake_password";

class UsersTableContainer extends React.Component<
  IUsersTableProps & IConnectProps & RouteComponentProps<any>,
  IUsersTableState
> {
  connectedTable: any;

  state = {
    createWindowVisibility: false,
    editWindowVisibility: false,
    changePasswordVisibility: false,
    groups: [],
  };

  async componentDidMount() {
    this.props.actions.its.load({});
    const groups = await API.getUserGroups({});
    this.setState({
      groups: groups.map((g) => ({ label: g.name, value: g.id })),
    });
  }

  openChangePasswordWindow = () => {
    this.setState({
      changePasswordVisibility: true,
    });
  };

  closeChangePasswordWindow = () => {
    this.setState({
      changePasswordVisibility: false,
    });
  };

  openEditWindow = () => {
    this.setState({
      editWindowVisibility: true,
    });
  };

  closeEditWindow = () => {
    this.setState({
      editWindowVisibility: false,
    });
  };

  openCreateWindow = () => {
    this.setState({
      createWindowVisibility: true,
    });
  };

  closeCreateWindow = () => {
    this.setState({
      createWindowVisibility: false,
    });
  };

  editUser = async (values) => {
    const { actions, user } = this.props;
    try {
      const editedUser = await API.editUser({
        ...values,
      });

      actions.commitEdit(editedUser);
      // in settings when user is editing his own account we also should
      // update values inside of auth user object
      if (user!.id === editedUser.id) {
        actions.auth.initDone({
          params: {
            ...user,
            ...values,
          },
          result: { ...user, ...editedUser },
        });
      }
      toast.success(
        <FormattedMessage id={getTranslationKey("messages", "success", "userSettingsEdited")} />,
      );
    } catch (error) {
      console.error(error);
      toast.error(
        <FormattedMessage id={getTranslationKey("messages", "error", "userSettingsEdited")} />,
      );
    }
    this.reload();
    this.closeEditWindow();
  };

  createUser = async (values) => {
    await API.createUser({
      ...values,
      // our user model requires password to be defined and it cannot be changed because a lot of loopback logic depends on that
      // we pass here fake_password that does nothing, during login phase we filter out users that are marked as SSO so it is impossible to
      // login using this user and password
      password: values.sso ? PASSWORD_PLACEHOLDER : values.password,
      email: values.sso ? values.username : values.email,
    });
    this.reload();
    this.closeCreateWindow();
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
  };

  changePassword = async (values) => {
    const { newPassword } = values;
    const { selectedUser } = this.props;

    if (selectedUser) {
      try {
        await API.changePassword({
          userId: selectedUser.id,
          newPassword,
        });
        toast.success(<FormattedMessage id={getTranslationKey("password", "passwordChanged")} />);
      } catch (error) {
        toast.error(<FormattedMessage id={getTranslationKey("password", "passwordChangeError")} />);
      }
    }
    this.closeChangePasswordWindow();
  };

  render() {
    const isEdit = this.state.editWindowVisibility;
    const panels = this.props.selectedUser ? (
      <>
        <PanelFormFinal
          visibility={this.state.changePasswordVisibility}
          panelType={PanelType.MEDIUM}
          headerText={getTranslationKey("password", "changePassword")}
          onCancelClick={this.closeChangePasswordWindow}
          onOkClick={this.changePassword}
          cancelButtonText={getTranslationKey("button", "close")}
          key="EditPassword"
          okButtonText={getTranslationKey("button", "save")}
          initialValues={this.props.selectedUser}
          render={({ change, values }) => (
            <UserPasswordForm values={values} change={change} adminMode />
          )}
        />
      </>
    ) : null;
    return (
      <Container>
        <CommandBar
          onNewClick={this.openCreateWindow}
          onPasswordClick={this.openChangePasswordWindow}
          onRemoveClick={this.remove}
          selected={this.props.selected}
          onEditClick={this.openEditWindow}
          onReload={this.reload}
          user={this.props.user}
        />
        <PanelFormFinal
          visibility={isEdit || this.state.createWindowVisibility}
          panelType={PanelType.MEDIUM}
          headerText={getTranslationKey("user", "header", isEdit ? "editUser" : "createUser")}
          onCancelClick={isEdit ? this.closeEditWindow : this.closeCreateWindow}
          onOkClick={isEdit ? this.editUser : this.createUser}
          key={isEdit ? "edit" : "create"}
          cancelButtonText={getTranslationKey("button", "close")}
          okButtonText={getTranslationKey("button", isEdit ? "save" : "create")}
          initialValues={isEdit ? this.props.selectedUser : undefined}
          render={({ change, values }) => (
            <UserBasicSettingsForm
              values={values}
              change={change}
              isEdit={isEdit}
              its={this.props.its}
            />
          )}
        />
        {panels}
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={USERS_COLUMNS}
          tableId={USERS_TABLES_CONFIG.MAIN.id()}
          preferencesId={USERS_TABLES_CONFIG.MAIN.preferencesId}
          stateKey={"users"}
          dynamicFilterValues={{
            groups: this.state.groups,
          }}
          tableActions={getUsersTableActions}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    auth: {
      ...bindActionCreators(authActions, dispatch),
    },
    ...bindActionCreators(userActions, dispatch),
    ...bindActionCreators(getUsersTableActions(USERS_TABLES_CONFIG.MAIN.id()), dispatch),
    its: {
      ...bindActionCreators(getIstTableActions(ITS_TABLES_CONFIG.MAIN.id()), dispatch),
    },
  },
});

const connectCreator = connect(
  (state: ApplicationState) => ({
    selected: usersDataSelectors.getSelectedSelector(state, USERS_TABLES_CONFIG.MAIN.id()),
    selectedUser: usersDataSelectors.getSelectedItemSelector(state, USERS_TABLES_CONFIG.MAIN.id()),
    user: state.auth.user,
    its: itsDataSelectors.getOrderedDataSelector(state, ITS_TABLES_CONFIG.MAIN.id()),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(UsersTableContainer));
