import * as React from "react";
import { Tag } from "antd";
import { COLORS } from "@app/styles/consts";
import { STORAGE_TYPE_LABELS } from "@ea/shared_types/types";
import { STORAGE_TYPE } from "@ea/shared_types/types";

interface IStatusFieldProps {
  type: STORAGE_TYPE;
}

const style = {
  cursor: "default",
};

const StorageTypeField: React.FunctionComponent<IStatusFieldProps> = ({ type }) => {
  if (!type) {
    return null;
  }

  return (
    <Tag style={style} color={COLORS.STORAGE_TYPE[type]}>
      {STORAGE_TYPE_LABELS[type]}
    </Tag>
  );
};

export default StorageTypeField;
