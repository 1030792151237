import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { AggregatedJobLog } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("JOB_LOGS_MODULE");

export const moduleActions = createDataModuleActions<AggregatedJobLog>(actionCreator);
export const getAggregatedJobLogsTableActions = createTableActionsGetter(
  normalizeActions(moduleActions.table),
);
export const aggregatedJobLogsActions = {
  ...normalizeActions(moduleActions.data),
};
export const aggregatedJobLogsActionCreators = {
  ...moduleActions,
};
