import { ColProps } from "antd/lib/grid/col";
import { getIn } from "final-form";
import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import { SelectField } from "@ea/shared_components/Form/Fields/SelectField";
import {
  ConditionTypes,
  Step,
  Variable,
  VariableType,
  GlobalVariable,
} from "@ea/shared_types/types";
import { getStepOptions, omitEndStep } from "@app/utils/getStepOptions";
import { Tooltip } from "antd";
import { FormattedMessage } from "react-intl";

type AssertConditionPickerProps = {
  prefix: string;
  variables: Variable[];
  globalConstant?: GlobalVariable[];
  globalMutable?: GlobalVariable[];
  steps: Step[];
  readOnly?: boolean;
  values: any;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  change: (name: string, value: any) => void;
};

const formItemStyle = {
  marginBottom: 0,
};

const generateOptions = (arr: (Variable | GlobalVariable)[] = []) =>
  arr.map((v) => ({
    key: v.id,
    text: v.name,
    value: v.id,
  }));

class AssertConditionPicker extends React.Component<AssertConditionPickerProps> {
  getConditionValueFormItem = () => {
    const { prefix, readOnly, variables, steps, values, globalMutable, globalConstant } =
      this.props;
    const filteredVariables = variables.filter((v) => v.type === VariableType.Normal);
    const type = getIn(values, `${prefix}.type`);
    const variableOptions = generateOptions(filteredVariables);
    const globalMutableOptions = generateOptions(globalMutable);
    const globalConstantOptions = generateOptions(globalConstant);

    const stepOptions = getStepOptions(omitEndStep(steps), "id");
    const selectedVariable = filteredVariables.find(
      (v) => v.id === getIn(values, `${prefix}.value`),
    );
    switch (type) {
      case ConditionTypes.CONST:
        return (
          <InputField
            name={`${prefix}.value`}
            readOnly={readOnly}
            parse={(value) => (value === null ? "" : value)}
            defaultValue={""}
          />
        );
      case ConditionTypes.VARIABLE:
        return (
          <Tooltip title={selectedVariable && selectedVariable.name}>
            <SelectField
              dropdownStyle={{ minWidth: "fit-content" }}
              name={`${prefix}.value`}
              readOnly={readOnly}
              required
              placeholder={getTranslationKey("step", "placeholder", "selectVariable")}
              options={variableOptions}
              notFoundContent={
                <FormattedMessage id={getTranslationKey("step", "label", "noVariables")} />
              }
            />
          </Tooltip>
        );
      case ConditionTypes.STEP_VALUE:
        return (
          <SelectField
            name={`${prefix}.value`}
            readOnly={readOnly}
            required
            placeholder={getTranslationKey("step", "placeholder", "selectStep")}
            options={stepOptions}
          />
        );
      case ConditionTypes.GLOBAL_CONSTANT:
        return (
          <SelectField
            name={`${prefix}.value`}
            readOnly={readOnly}
            required
            placeholder={getTranslationKey("step", "placeholder", "selectVariable")}
            options={globalConstantOptions}
          />
        );
      case ConditionTypes.GLOBAL_MUTABLE:
        return (
          <SelectField
            name={`${prefix}.value`}
            readOnly={readOnly}
            required
            placeholder={getTranslationKey("step", "placeholder", "selectVariable")}
            options={globalMutableOptions}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { prefix, change, readOnly } = this.props;

    return (
      <div>
        <SelectField
          name={`${prefix}.type`}
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("step", "placeholder", "selectType")}
          formItemRowStyle={formItemStyle}
          options={[
            {
              value: ConditionTypes.CONST,
              text: getTranslationKey("step", "assert", "conditionType", "const"),
            },
            {
              value: ConditionTypes.STEP_VALUE,
              text: getTranslationKey("step", "assert", "conditionType", "stepValue"),
            },
            {
              value: ConditionTypes.VARIABLE,
              text: getTranslationKey("step", "assert", "conditionType", "variable"),
            },
            {
              value: ConditionTypes.GLOBAL_CONSTANT,
              text: getTranslationKey("step", "assert", "conditionType", "globalConst"),
            },
            {
              value: ConditionTypes.GLOBAL_MUTABLE,
              text: getTranslationKey("step", "assert", "conditionType", "globalMutable"),
            },
          ]}
          onChange={() => {
            change(`${prefix}.value`, undefined);
          }}
        />
        {this.getConditionValueFormItem()}
      </div>
    );
  }
}

export default AssertConditionPicker;
