import styled from "@emotion/styled";
import * as React from "react";

import { CodeExecutionLog, CodeExecutionLogType } from "@ea/shared_types/runner.common.types";
import { InjectedIntlProps, injectIntl } from "react-intl";

interface IVariableLogProps {
  logs: CodeExecutionLog;
}

const VariableInfoContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  flex: 1,
  width: "100%",
  justifyContent: "space-between",
  marginBottom: "12px",
});

const VariableInfo = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  flexBasis: "150px",
  flexGrow: 1,
  alignItems: "center",
});

const Header = styled.h3({
  display: "flex",
  flexGrow: 0,
});

const Info = styled.p({
  display: "flex",
  flexGrow: 1,
  marginBottom: 0,
  flexDirection: "column",
  justifyContent: "center",
});

const splitIntoLines = (info) => {
  if (!info) {
    return "";
  }
  return typeof info === "string" ? (
    info.split("\n").map((variable, index) => <Info key={index}>{variable}</Info>)
  ) : (
    <Info key={0}>{JSON.stringify(info)}</Info>
  );
};

export const shouldDisplayVariableLogs = (log) => !!log;

const parseOutput = (output) =>
  Object.keys(output).map((variableName, index) => (
    <Info key={index}>{`${variableName} = ${output[variableName]}`}</Info>
  ));

const VariableLog: React.FunctionComponent<IVariableLogProps & InjectedIntlProps> = ({
  logs,
  intl,
}) => {
  if (!shouldDisplayVariableLogs(logs)) {
    return null;
  }

  return (
    <VariableInfoContainer>
      <VariableInfo>
        <Header>{intl.formatMessage({ id: "logs.header.inputHeader" })}</Header>
        {splitIntoLines(logs.input)}
      </VariableInfo>
      <VariableInfo>
        <Header>{intl.formatMessage({ id: "logs.header.executedCode" })}</Header>
        {splitIntoLines(logs.executedCode)}
      </VariableInfo>
      <VariableInfo>
        <Header>{intl.formatMessage({ id: "logs.header.outputValue" })}</Header>
        {logs.type !== CodeExecutionLogType.ASSIGN
          ? splitIntoLines(logs.output)
          : parseOutput(logs.output)}
      </VariableInfo>
    </VariableInfoContainer>
  );
};

export default injectIntl(VariableLog);
