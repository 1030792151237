import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "../../app.reducers";
import { runnerDataSelectors, getSessionParams } from "../runner.selectors";
import { WithTranslation, withTranslation } from "react-i18next";
import { VARIABLES_SECTIONS, variableActions } from "@app/modules/variables/variables.actions";
import { variableDataSelectors } from "@app/modules/variables/variables.selectors";
import { bindActionCreators, Dispatch } from "redux";
import { getRunnerTableActions } from "../runner.actions";
import RunnerStepsContainer from "../RunnerSteps.container";
import { Script, Project, RunnerMode, System, VirtualUser } from "@ea/shared_types/types";
import PlayerControlPanel from "./PlayerControlPanel";
import {
  RunnerPostMessages,
  RunnerPostMessageActions,
  RunnerPostSetInitialState,
} from "@ea/shared_types/runner.common.types";
import { postMessageToEaApp } from "../communication/iframes.communication";
import { runnerCommunicator } from "@ea/shared_types/utils/iframes.communication";
import {
  IFrameRunnerAsyncMessages,
  IFrameRunnerSyncMessages,
} from "@ea/shared_types/communication.types";
import { PlayMode } from "@ea/shared_types/newRunner.types";
import { drawStepOverlayOnStepSelect } from "../communication/iframes.utils";
import {
  RunnerBarContainer,
  RunnerDataContainer,
  SpinnerContainer,
} from "../RunnerComponentLayout";
import { Spin } from "antd";

interface IPlayerManagerContainerProps {
  sessionId: string;
  script: Script;
  project: Project;
  loadIFrame: () => void;
  onModeToggle: () => void;
  mode: RunnerMode;
  isDrawingOverlayEnabled: boolean;
  minimalistic: boolean;
  systems: System[];
  system?: System;
  virtualUsers: VirtualUser[];
  runnerLoadedFirstTime: boolean;
}

type ComponentProps = IPlayerManagerContainerProps & WithTranslation;

class PlayerManagerContainer extends React.Component<
  IPlayerManagerContainerProps & IConnectProps,
  { isSynced: boolean; isLoadingController: boolean }
> {
  destroyMessageListener: any;

  constructor(props) {
    super(props);
    this.state = {
      isLoadingController: false,
      isSynced: false,
    };
  }

  async componentDidMount() {
    await this.loadController();
    this.props.loadIFrame();
    this.handleCommunication();
  }

  componentDidUpdate(prevProps) {
    drawStepOverlayOnStepSelect(this.props, prevProps);
  }

  async loadController() {
    try {
      // Blocking control panel is turned off because switching is very fast. Uncomment line below to enable it
      // this.setState({
      //   isLoadingController: true,
      // });

      await runnerCommunicator.administration.sendPromise(
        IFrameRunnerSyncMessages.load_controller,
        RunnerMode.PLAYER,
      );
    } catch (err) {
      console.error(err);
    }

    this.setState({
      isLoadingController: false,
    });
  }

  componentWillMount() {
    runnerCommunicator.administration.registerMessageHandlerPromise(
      IFrameRunnerSyncMessages.get_initial_state,
      {
        key: "getInitialState",
        callback: async () => {
          return this.props.sessionParams;
        },
      },
    );
  }

  handleCommunication = () => {
    runnerCommunicator.administration.registerMessageHandler<IFrameRunnerAsyncMessages.player_position>(
      IFrameRunnerAsyncMessages.player_position,
      {
        key: "player_position",
        callback: (position) => {
          // todo: R2.0 why ts hints string | number | recorded step?
          const tmp: any = {
            player: {
              position,
            },
          };
          this.props.actions.setRunnerParams(tmp);
        },
      },
    );

    runnerCommunicator.administration.registerMessageHandler<IFrameRunnerAsyncMessages.player_step_status>(
      IFrameRunnerAsyncMessages.player_step_status,
      {
        key: "player_step_status",
        callback: ({ path, status }) => {
          // todo: R2.0 why ts hints string | number | recorded step?
          const { sessionParams } = this.props;
          const { executionItems } = sessionParams;
          executionItems[path].status = status;
          this.props.actions.setRunnerParams({ executionItems });
        },
      },
    );

    runnerCommunicator.administration.registerMessageHandler<IFrameRunnerAsyncMessages.player_state>(
      IFrameRunnerAsyncMessages.player_state,
      {
        key: "player_state",
        callback: (state) => {
          // todo: R2.0 why ts hints string | number | recorded step?
          const tmp: any = {
            player: {
              state,
            },
          };
          this.props.actions.setRunnerParams(tmp);
        },
      },
    );
  };

  onPause = () => {
    runnerCommunicator.administration.send(IFrameRunnerAsyncMessages.pause_player, {});
  };

  onReset = () => {
    runnerCommunicator.administration.send(IFrameRunnerAsyncMessages.restart_player, {});
    this.props.actions.reset({});
  };

  onPlay = (playMode?: PlayMode) => {
    runnerCommunicator.administration.send(IFrameRunnerAsyncMessages.start_player, playMode);
  };

  onMessage = (data: RunnerPostMessages) => {
    const { actions, sessionParams } = this.props;

    switch (data.action) {
      case RunnerPostMessageActions.get_initial_state:
        const message: RunnerPostSetInitialState = {
          action: RunnerPostMessageActions.set_initial_state,
          source: "runner_child",
          value: sessionParams,
        };
        postMessageToEaApp(message);
        return;
      case RunnerPostMessageActions.player_position: {
        const tmp: any = {
          player: {
            position: data.value.position,
          },
        };
        actions.setRunnerParams(tmp);
        return;
      }
      case RunnerPostMessageActions.player_state: {
        const tmp: any = {
          player: {
            state: data.value.state,
          },
        };
        actions.setRunnerParams(tmp);
        return;
      }

      default:
        return;
    }
  };

  render() {
    const {
      sessionId,
      script,
      project,
      onModeToggle,
      mode,
      systems,
      system,
      virtualUsers,
      minimalistic,
      runnerLoadedFirstTime,
    } = this.props;

    const { isLoadingController } = this.state;

    return (
      <>
        <RunnerBarContainer>
          {!runnerLoadedFirstTime ||
            (isLoadingController && (
              <SpinnerContainer>
                <Spin />
              </SpinnerContainer>
            ))}

          <PlayerControlPanel
            onReset={this.onReset}
            onPause={this.onPause}
            onPlay={this.onPlay}
            project={project}
            script={script}
            sessionId={sessionId}
            onModeToggle={onModeToggle}
            mode={mode}
            minimalistic={minimalistic}
          />
        </RunnerBarContainer>

        {!minimalistic && (
          <RunnerDataContainer>
            <RunnerStepsContainer
              sessionId={sessionId}
              isDragEnabled={false}
              script={script}
              system={system}
              isExpandedEditModeOn={false}
              systems={systems}
              virtualUsers={virtualUsers}
            />
          </RunnerDataContainer>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: ComponentProps) => ({
  actions: {
    ...bindActionCreators(getRunnerTableActions(props.sessionId), dispatch),
    variable: {
      ...bindActionCreators(variableActions, dispatch),
    },
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: ComponentProps) => ({
    steps: runnerDataSelectors.getOrderedDataSelector(state, props.sessionId),
    selectedItem: runnerDataSelectors.getSelectedItemSelector(state, props.sessionId),
    selectedItems: runnerDataSelectors.getSelectedItemsSelector(state, props.sessionId),
    selectedArray: runnerDataSelectors.getSelectedSelector(state, props.sessionId),
    variables: variableDataSelectors.getOrderedDataSelector(state, VARIABLES_SECTIONS.LOCAL),
    sessionParams: getSessionParams(state, props.sessionId),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withTranslation()(connectCreator(PlayerManagerContainer));
