import * as PropTypes from "prop-types";
import * as React from "react";
import { RouterChildContext } from "react-router";

import CreateEditScriptForm from "@app/modules/scripts/components/CreateEditScriptForm";
import { API } from "@app/services/api/api";
import { validateEnvironmentFormValues } from "@app/utils/systemDictionary";
import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { URL_SELECT_MODE, Script, ROLES } from "@ea/shared_types/types";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { getTranslationKey } from "@app/translations/translations.helpers";
import {
  currentUserIdSelector,
  currentUserRolesSelector,
} from "@ea/shared_components/auth/auth.selectors";
import { getProjectsTreeSelector } from "../projects/projects.selectors";
import { PROJECTS_TABLES_CONFIG } from "../projects/projects.tables";

import { getStartStep, getEndStep } from "@app/utils/constSteps";

interface CreateScriptContainerProps {
  visibility: boolean;
  onClose: () => void;
  onCreate?: (item: Script) => void;
  projectId?: number;
}

class CreateScriptContainer extends React.Component<IConnectProps> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  context: RouterChildContext<any>;

  createScript = async (values) => {
    const { userId, onCreate } = this.props;

    validateEnvironmentFormValues(values);
    const { urlSwitch, ...rest } = values;
    const result = await API.createScript({
      ...rest,
      isManualUrl: urlSwitch === URL_SELECT_MODE.MANUAL,
      creatorId: userId,
    });

    await API.createStep(
      getStartStep({
        taskScriptId: result.id,
        lineNum: 1,
      }) as any, // todo: NEW_TYPES
    );
    await API.createStep(
      getEndStep({ taskScriptId: result.id, lineNum: 2 }) as any, // todo: NEW_TYPES
    );

    if (onCreate) {
      onCreate(result);
    }

    this.context.router.history.push(`/scripts/${result.id}`);
  };

  getUrlOptions = () => {
    const { userRoles } = this.props;

    const options = [
      {
        text: getTranslationKey("common", "label", "manualUrl"),
        value: URL_SELECT_MODE.MANUAL,
      },
    ];

    if (!userRoles.includes(ROLES.freedocs)) {
      options.push({
        text: getTranslationKey("common", "label", "environment"),
        value: URL_SELECT_MODE.ENVIRONMENT,
      });
    }

    return options;
  };

  render() {
    const { visibility, userRoles, projects, projectId } = this.props;

    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.MEDIUM}
        headerText={getTranslationKey("scripts", "header", "create")}
        onCancelClick={this.props.onClose}
        onOkClick={this.createScript}
        cancelButtonText={getTranslationKey("button", "cancel")}
        okButtonText={getTranslationKey("button", "create")}
        initialValues={{
          projectId,
          defaultSystem: true,
          defaultVirtualUser: true,
          useVirtualUser: !userRoles.includes(ROLES.freedocs),
          urlSwitch: userRoles.includes(ROLES.freedocs)
            ? URL_SELECT_MODE.MANUAL
            : URL_SELECT_MODE.ENVIRONMENT,
        }}
        render={({ values, ...form }) => (
          <CreateEditScriptForm
            projects={projects}
            values={values}
            form={form}
            isCreating={true}
            urlOptions={this.getUrlOptions()}
          />
        )}
      />
    );
  }
}

const connectCreator = connect((state: ApplicationState, props: CreateScriptContainerProps) => ({
  ...props,
  userId: currentUserIdSelector(state),
  userRoles: currentUserRolesSelector(state),
  projects: getProjectsTreeSelector(state, PROJECTS_TABLES_CONFIG.TREE.id()),
}));

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(CreateScriptContainer);
