import {
  AnyZodObject,
  UnknownKeysParam,
  ZodObjectDef,
  ZodOptional,
  ZodRawShape,
  ZodType,
  ZodTypeAny,
  objectInputType,
  objectOutputType,
  objectUtil,
  z,
} from "zod";

// We overwrite some changed types from zod library to maintain backward compatilbility with our helpers
declare module "zod" {
  interface ZodObject<
    T extends ZodRawShape,
    UnknownKeys extends UnknownKeysParam = UnknownKeysParam,
    Catchall extends ZodTypeAny = ZodTypeAny,
    Output = objectOutputType<T, Catchall, UnknownKeys>,
    Input = objectInputType<T, Catchall, UnknownKeys>,
  > extends ZodType<Output, ZodObjectDef<T, UnknownKeys, Catchall>, Input> {
    omit<Mask extends { [k in keyof T]?: true }>(
      mask: Mask,
    ): ZodObject<Omit<T, keyof Mask>, UnknownKeys, Catchall>;
    partial<
      Mask extends {
        [k in keyof T]?: true;
      },
    >(
      mask: Mask,
    ): ZodObject<
      objectUtil.noNever<{
        [k in keyof T]: k extends keyof Mask ? ZodOptional<T[k]> : T[k];
      }>,
      UnknownKeys,
      Catchall
    >;
  }
}

export const GENERATED_BY_DB_PROPERTIES = {
  createdAt: true,
  updatedAt: true,
  modifiedBy: true,
  id: true,
} as const;

function makeOptionalPropsNullable<Schema extends z.AnyZodObject>(schema: Schema) {
  const entries = Object.entries(schema.shape) as [keyof Schema["shape"], z.ZodTypeAny][];
  const newProps = entries.reduce(
    (acc, [key, value]) => {
      acc[key] = value instanceof z.ZodOptional ? value.unwrap().optional().nullable() : value;
      return acc;
    },
    {} as {
      [key in keyof Schema["shape"]]: Schema["shape"][key] extends z.ZodOptional<infer T>
        ? z.ZodNullable<z.ZodOptional<T>>
        : Schema["shape"][key];
    },
  );
  return z.object(newProps);
}

function convertNullsToUndefinedInOptionalProps<Schema extends z.AnyZodObject>(schema: Schema) {
  const entries = Object.entries(schema.shape) as [keyof Schema["shape"], z.ZodTypeAny][];
  const newProps = entries.reduce(
    (acc, [key, value]) => {
      acc[key] =
        value instanceof z.ZodOptional
          ? value
              .unwrap()
              .optional()
              .nullable()
              .transform((v) => (v === null ? undefined : v))
          : value;
      return acc;
    },
    {} as {
      [key in keyof Schema["shape"]]: Schema["shape"][key] extends z.ZodOptional<infer T>
        ? z.ZodNullable<z.ZodOptional<T>>
        : Schema["shape"][key];
    },
  );
  return z.object(newProps);
}

function makeDefaultPropsOptional<Schema extends z.AnyZodObject>(schema: Schema) {
  const entries = Object.entries(schema.shape) as [keyof Schema["shape"], z.ZodTypeAny][];
  const newProps = entries.reduce(
    (acc, [key, value]) => {
      acc[key] = value instanceof z.ZodDefault ? value.optional() : (value as any);
      return acc;
    },
    {} as {
      [key in keyof Schema["shape"]]: Schema["shape"][key] extends z.ZodDefault<infer T>
        ? z.ZodOptional<z.ZodDefault<T>>
        : Schema["shape"][key];
    },
  );
  return z.object(newProps);
}

const generateEntitySchemas = <T extends AnyZodObject>(schema: T) => {
  const CreateSchema = ZodUtils.makeOptionalPropsNullable(schema).omit(GENERATED_BY_DB_PROPERTIES);
  return {
    general: schema,
    createData: ZodUtils.makeDefaultPropsOptional(CreateSchema),
    create: CreateSchema,
    update: ZodUtils.makeOptionalPropsNullable(schema).omit(GENERATED_BY_DB_PROPERTIES).partial(),
  };
};

export const ZodUtils = {
  makeOptionalPropsNullable,
  convertNullsToUndefinedInOptionalProps,
  makeDefaultPropsOptional,
  generateEntitySchemas,
};

export type InferZodSchemaGroup<T> = {
  [Key in keyof T]: T[Key] extends ZodType<any, any, any> ? z.infer<T[Key]> : never;
};
